import { Button, buttonClasses } from '@planne-software/uni/Button';
import { Stack, styled } from '@planne-software/uni/mui/material';
import { FixedSizeList } from 'react-window';

export const StyledHorizontalCalendar = styled(Stack)({
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 1,
});

export const StyledVirtualScroll = styled(FixedSizeList)({
  overflowX: 'auto',
  overflowY: 'hidden',
  scrollSnapType: 'x mandatory',
  scrollSnapAlign: 'start',
  scrollSnapStop: 'always',
  scrollBehavior: 'smooth',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '&::-webkit-scrollbar-track': {
    display: 'none',
  },
});

export const StyledArrowButton = styled(Button)(() => ({
  [`&.${buttonClasses.sm}`]: {
    padding: '0.5rem 0.5rem',
  },
}));
