import iMask, { AnyMaskedOptions } from 'imask';

export const createMask = (config: AnyMaskedOptions) => (val: string) => iMask.createMask(config).resolve(val);

const removeMask = (input: string) => input.replace(/\W/g, '');

const maskEmail = (email: string) => {
  const dotIndex = email.lastIndexOf('.');
  const afterMask = email.slice(dotIndex);
  const beforeMask = email.slice(0, 4);

  return [beforeMask, '***********', afterMask].join('');
};

const masks = {
  cpf: createMask({ mask: '000.000.000-00' }),
  cnpj: createMask({ mask: '00.000.000/0000-00' }),
  cep: createMask({ mask: '00000-000' }),
  cpf_cnpj: createMask({
    mask: [{ mask: '000.000.000-00' }, { mask: '00.000.000/0000-00' }],
    dispatch(value, masked) {
      const valueWithoutMask = removeMask(masked.value + value);

      if (valueWithoutMask.length <= 11) {
        return masked.compiledMasks[0];
      }

      return masked.compiledMasks[1];
    },
  }),
  bankAgency: createMask({ mask: [{ mask: '0000' }, { mask: '0000-0' }, { mask: '0000-00' }] }),
  bankAccount: createMask({
    mask: [
      { mask: '00000-0' },
      { mask: '000000-0' },
      { mask: '0000000-0' },
      { mask: '00000000-0' },
      { mask: '000000000-0' },
      { mask: '0000000000-0' },
      { mask: '00000000000-0' },
    ],
    dispatch(value, masked) {
      const currentValue = removeMask(masked.value + value);

      if (currentValue.length <= 6) {
        return masked.compiledMasks[0];
      }

      if (currentValue.length <= 7) {
        return masked.compiledMasks[1];
      }

      if (currentValue.length <= 8) {
        return masked.compiledMasks[2];
      }

      if (currentValue.length <= 9) {
        return masked.compiledMasks[3];
      }

      if (currentValue.length <= 10) {
        return masked.compiledMasks[4];
      }

      if (currentValue.length <= 11) {
        return masked.compiledMasks[5];
      }

      return masked.compiledMasks[6];
    },
  }),
  paymentCardNumber: createMask({ mask: [{ mask: '0000 0000 0000 0000' }, { mask: '0000 000000 00000' }] }),
  paymentCardValidity: createMask({ mask: '00/00' }),
  cvv: createMask({ mask: '0000' }),
  twoDigits: createMask({ mask: '00' }),
};

export { masks, removeMask, maskEmail };
