import { useEffect } from 'react';
import { Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import PageLayout from '@/layouts/PageLayout';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import FilterProvider from '@/contexts/FilterContext';
import RefundRequestList from '@/components/RefundRequests/List';
import FilterFields from '@/components/RefundRequests/Filter';
import { filterSchema } from './filterSchema';

export const RefundRequests = () => {
  const { track } = useAnalytics();
  const { t } = useTranslation(['refundRequests']);

  useEffect(() => {
    track('refundRequests/access');
  }, []);

  return (
    <FilterProvider schema={filterSchema(t)} contextName='refundRequests'>
      <Helmet>
        <title>{t('refund_requests_page_title')}</title>
      </Helmet>

      <PageLayout
        title={t('title')}
        slots={{ FilterFields }}
        slotProps={{ searchInput: { placeholder: t('filters.placeholder') } }}
      >
        <Grid container paddingY={{ xs: 2, sm: 4 }} paddingX={{ xs: 0, sm: 4, md: 6 }}>
          <RefundRequestList />
        </Grid>
      </PageLayout>
    </FilterProvider>
  );
};

export default RefundRequests;
