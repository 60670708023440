import { CSSObject, Theme, styled } from '@mui/material';
import Drawer from '@mui/material/SwipeableDrawer';

const openedMixin = (theme: Theme, drawerWidth: number): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: '4.2rem',
});

const unsafeProps: PropertyKey[] = ['drawerWidth', 'isDrawerExpanded'];

export const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => !unsafeProps.includes(prop),
})<{ isDrawerExpanded: boolean; drawerWidth: number }>(({ theme, isDrawerExpanded, drawerWidth }) => ({
  position: 'relative',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(isDrawerExpanded && {
    ...openedMixin(theme, drawerWidth),
    '& .MuiDrawer-paper': openedMixin(theme, drawerWidth),
  }),
  ...(!isDrawerExpanded && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));
