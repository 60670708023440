import { QueryFunctionContext } from '@tanstack/react-query';

import createPageRequest from '@/features/agenda/utils/pageRequest';
import { useSellerApi } from '@/hooks/useSellerApi';
import { Scheduling, SchedulingsApiGetAppSchedulingsRequest } from '@/services/SellerApi';

type Query = [
  key: string,
  appId: string | null,
  since: string,
  until: string,
  pageParam: number,
  productIds?: string[],
  pageSize?: number,
];

type QueryBase = [key: string, pageParam: number];

interface UseGetSchedulingsResponse {
  nextCursor: number | null;
  items: Scheduling[];
  total?: number;
}

const useGetSchedulings = () => {
  const { schedulingsApi } = useSellerApi();

  const request = async ({
    queryKey,
    signal,
    pageParam,
  }: QueryFunctionContext<Query>): Promise<UseGetSchedulingsResponse> => {
    const [keyString, appId, since, until, _, productIds, pageSize = 10] = queryKey;
    const isMissingValues = !since || !until || !appId;
    if (isMissingValues) {
      return {
        nextCursor: null,
        items: [],
      };
    }

    try {
      const allSchedulings = await createPageRequest<Scheduling, SchedulingsApiGetAppSchedulingsRequest, QueryBase>({
        apiMethod: (payload, options) => schedulingsApi.getAppSchedulings(payload, options),
        initialPayload: {
          appId,
          since,
          until,
          include: [
            'product',
            'tariffGroup.priceInfo',
            'product.defaultTariffGroupPriceInfo',
            'finalTariffGroup.priceInfo',
          ],
          ...(productIds && productIds.length > 0 ? { productIds } : {}),
        },
        pageSize,
        ...(pageParam ? { page: pageParam as number } : {}),
        queryKey: [keyString, pageParam as number],
        signal,
      });

      return allSchedulings;
    } catch (error) {
      console.error(error);
      return {
        nextCursor: null,
        items: [],
      };
    }
  };

  return { request };
};

export default useGetSchedulings;
