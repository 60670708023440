import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { Customer } from '@/services/SellerApi';
import ModalOnDesktopDrawerOnMobile from '@/components/Modal/ModalOnDesktopDrawerOnMobile';
import CustomerDetailWithCloseButton from '@/components/CustomerDetail/CustomerWithCloseButton';

interface OutletContextProps {
  customer: Customer;
}

export const CustomerModal = () => {
  const { customer } = useOutletContext<OutletContextProps>();

  const navigate = useNavigate();
  const { t } = useTranslation(['saleDetails']);

  const close = () => navigate('..', { replace: true });

  return (
    <ModalOnDesktopDrawerOnMobile width={643} isOpen={true} onClose={close} title={t('details.modals.customer.title')}>
      <CustomerDetailWithCloseButton onClose={close} customerId={customer.id} />
    </ModalOnDesktopDrawerOnMobile>
  );
};

export default CustomerModal;
