import accessTokenManager from '@/contexts/Auth/AccessTokenManager';
import { AuthContextType, AuthStatus } from '@/interfaces/auth';
import { AgreementDocument } from '@/services/sellerApiQuery/model';

const defaultAuthContextState: AuthContextType = {
  user: undefined,
  accessToken: accessTokenManager.getToken() ?? undefined,
  status: AuthStatus.UNAUTHENTICATED,
  loginError: null,
  authenticate: () => null,
  login: () => Promise.resolve(),
  logout: () => null,
  refresh: () => null,
  signup: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  recoverPassword: () => Promise.resolve(),
  resetPasswordCodeValidStatus: () => Promise.resolve(),
  getTermsOfUse: () => Promise.resolve({} as AgreementDocument),
  getPrivacyPolicy: () => Promise.resolve({} as AgreementDocument),
  isAccessTokenExpired: () => true,
  setAuthStatus: () => null,
};

export default defaultAuthContextState;
