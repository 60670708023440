import { ReactNode, useEffect, useMemo, useState } from 'react';

import useAnalytics from '@/hooks/analytics/useAnalytics';
import jwtDecode from 'jwt-decode';
import {
  getTermsOfUse,
  getPrivacyPolicy,
  authRecoverPassword,
  authResetPassword,
  authSignup,
  authValidateResetPasswordCode,
  useAuthRefresh,
  useAuthLogout,
  useAuthLogin,
} from '@/services/sellerApiQuery/api/auth/auth';
import { AuthContextType, AuthStatus } from '@/interfaces/auth';
import { AuthContext } from '@/contexts/Auth/AuthContext';
import defaultAuthContextState from '@/contexts/Auth/constants/defaultAuthContextState';
import { getGetOwnUserQueryKey, useGetOwnUser } from '@/services/sellerApiQuery/api/users/users';
import {
  AccessToken,
  AuthRecoverPasswordBody,
  AuthResetPasswordBody,
  LoginParams,
  SignUpParams,
} from '@/services/sellerApiQuery/model';
import { useQueryClient } from '@tanstack/react-query';
import { AnalyticsEvents } from '@/hooks/analytics/enum/analyticsEvents';
import accessTokenManager from '@/contexts/Auth/AccessTokenManager';

const TOKEN_TTL = 3000;
const TOKEN_CHECK_INTERVAL = 2000;

export type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const { track } = useAnalytics();
  const [accessToken, setAccessToken] = useState<AuthContextType['accessToken']>(defaultAuthContextState.accessToken);
  const [status, setStatus] = useState(defaultAuthContextState.status);
  const queryClient = useQueryClient();

  const authLoginQuery = useAuthLogin();

  const { mutate: authLogout } = useAuthLogout({
    mutation: {
      onSuccess: () => {
        clearAccessToken();
      },
    },
  });

  const canGetOwnUser = useMemo(() => {
    return !!accessToken && !!accessTokenManager.getToken();
  }, [accessToken]);

  const authRefreshQuery = useAuthRefresh();

  const queryKey = getGetOwnUserQueryKey();

  const {
    data: user,
    error: userError,
    isLoading: isUserLoading,
  } = useGetOwnUser({
    query: {
      enabled: canGetOwnUser,
      queryKey,
    },
  });

  const authenticate = (token?: AccessToken) => {
    if (token) {
      persistAccessToken(token);
    }
    setStatus(AuthStatus.AUTHENTICATED);
  };

  const persistAccessToken = (token: AccessToken) => {
    accessTokenManager.setToken(token);
    setAccessToken(token.token);
  };

  const clearAccessToken = () => {
    accessTokenManager.clearToken();
    setAccessToken(undefined);
    queryClient.clear();
    setStatus(AuthStatus.UNAUTHENTICATED);
  };

  const refresh = async () => {
    if (accessToken) {
      authRefreshQuery.mutate();
    }
  };

  const login = async (data: LoginParams) => {
    await authLoginQuery.mutateAsync({ data });
  };

  const onLoginSuccess = () => {
    if (authLoginQuery.data) {
      const { token } = authLoginQuery.data;
      authenticate(token);
      track(AnalyticsEvents.LOGIN_SUCCESS);
    }
  };

  const logout = async () => {
    authLogout();

    setStatus(AuthStatus.UNAUTHENTICATED);

    track(AnalyticsEvents.LOGOUT);
  };

  const signup = async (args: SignUpParams) => {
    const { name, email, password, acceptTerms } = args;
    const data = await authSignup({
      name,
      password,
      email,
      acceptTerms,
    });
    const { token } = data;
    authenticate(token);
    track(AnalyticsEvents.SIGNUP_SUCCESS);
  };

  const resetPassword = async (requestPayload: AuthResetPasswordBody) => {
    await authResetPassword(requestPayload);

    track(AnalyticsEvents.RESET_PASSWORD);
  };

  const resetPasswordCodeValidStatus = async (code: string) => {
    await authValidateResetPasswordCode(code);

    track(AnalyticsEvents.RESET_PASSWORD_CODE_VALID_STATUS);
  };

  const recoverPassword = async (requestPayload: AuthRecoverPasswordBody) => {
    const data = await authRecoverPassword(requestPayload);
    const { token } = data;

    authenticate(token);
    track(AnalyticsEvents.RECOVER_PASSWORD);
  };

  const getTermsOfUseRequest = async () => {
    const data = await getTermsOfUse();

    track(AnalyticsEvents.TERMS_OF_USE_LOADING_REQUEST_SUCCESS);

    return data;
  };

  const getPrivacyPolicyRequest = async () => {
    const data = await getPrivacyPolicy();

    track(AnalyticsEvents.PRIVACY_POLICY_LOADING_REQUEST_SUCCESS);

    return data;
  };

  const tokenExpiration = (token: string) => {
    const decodeToken = jwtDecode(token) as { exp: number };
    return new Date(decodeToken.exp * 1000);
  };

  const isAccessTokenExpired = (ttl?: number) => {
    if (!accessToken) {
      return true;
    }

    let currentTime = new Date();
    if (ttl) {
      currentTime = new Date(currentTime.getTime() + ttl);
    }
    return tokenExpiration(accessToken) < currentTime;
  };

  const checkAccessToken = () => {
    const needsRefresh = !authRefreshQuery.isPending && (!accessToken || isAccessTokenExpired(TOKEN_TTL));
    if (needsRefresh) {
      refresh();
    }
  };

  useEffect(
    function checkAuthRefreshData() {
      if (status === AuthStatus.UNAUTHENTICATED) {
        return () => null;
      }

      checkAccessToken();

      const intervalId = setInterval(checkAccessToken, TOKEN_CHECK_INTERVAL);
      return () => clearInterval(intervalId);
    },
    [status, accessToken],
  );

  useEffect(
    function checkAuthRefreshError() {
      if (authRefreshQuery.error) {
        clearAccessToken();
      }
    },
    [authRefreshQuery.error],
  );

  useEffect(
    function checkAuthRefreshData() {
      if (authRefreshQuery.data) {
        const { token, expiresAt } = authRefreshQuery.data as unknown as AccessToken;
        const newToken = { token, expiresAt };
        persistAccessToken(newToken);
      }
    },
    [authRefreshQuery.data],
  );

  useEffect(
    function checkUserError() {
      if (userError) {
        refresh();
      }
    },
    [user, accessToken, userError],
  );

  useEffect(
    function checkAuthRefreshError() {
      const shouldUnauthenticated = authRefreshQuery.isError && accessToken && !isUserLoading;
      if (shouldUnauthenticated) {
        clearAccessToken();
      }
    },
    [authRefreshQuery.isError, accessToken, isUserLoading],
  );

  useEffect(
    function checkUserStatus() {
      const shouldSetUserPending = () => !user && !isUserLoading && accessToken;
      const shouldAuthenticateUser = () => user && !isUserLoading && accessToken;

      if (shouldSetUserPending()) {
        setStatus(AuthStatus.USER_PENDING);
      }

      if (shouldAuthenticateUser()) {
        setStatus(AuthStatus.AUTHENTICATED);
      }
    },
    [user, isUserLoading, accessToken],
  );

  useEffect(
    function onLoginSuccessEffect() {
      const shouldLoginSuccess = authLoginQuery.isSuccess && authLoginQuery.data && !isUserLoading;
      if (shouldLoginSuccess && status === AuthStatus.UNAUTHENTICATED) {
        onLoginSuccess();
      }
    },
    [authLoginQuery.isSuccess, authLoginQuery.data, isUserLoading],
  );

  return (
    <AuthContext.Provider
      value={{
        user: user,
        loginError: authLoginQuery.error,
        login,
        logout,
        authenticate,
        accessToken,
        refresh,
        status,
        signup,
        resetPassword,
        recoverPassword,
        resetPasswordCodeValidStatus,
        getTermsOfUse: getTermsOfUseRequest,
        getPrivacyPolicy: getPrivacyPolicyRequest,
        isAccessTokenExpired,
        setAuthStatus: setStatus,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
