import axios, { AxiosInstance } from 'axios';
import { useMemo } from 'react';

import {
  AccountsApi,
  AdditionalsApi,
  AppsApi,
  ArrangedProvidersApi,
  ArrangedSaleItemsApi,
  AuthApi,
  AvailabilitiesApi,
  Configuration,
  CouponsApi,
  CustomersApi,
  DirectSalesApi,
  IncomeSchedulingsApi,
  PartnershipsApi,
  PoliciesApi,
  ProductsApi,
  RefundRequestsApi,
  SaleOriginsApi,
  SalesApi,
  SchedulingsApi,
  TagsApi,
  TariffGroupsApi,
  TransactionsApi,
  UsersApi,
  VouchersApi,
  EstablishmentTypesApi,
} from '@/services/SellerApi';
import { BASE_PATH, BaseAPI } from '@/services/SellerApi/base';
import useAuth from '@/contexts/Auth/hooks/useAuth';

const apiVersion = 1;

export const useSellerApi = (inputAccessToken?: string) => {
  const { accessToken } = useAuth();

  const createApiInstance = <T extends BaseAPI>(
    c: new (cfg: Configuration, basePath: string | undefined, axios: AxiosInstance | undefined) => T,
  ) => {
    const cfg = new Configuration({
      basePath: `${import.meta.env.VITE_SELLER_API_URL?.replace(/\/$/, '')}/${apiVersion}`,
      accessToken: inputAccessToken ?? accessToken ?? undefined,
      baseOptions: {
        withCredentials: true,
      },
    });

    const axiosInstance = axios.create();
    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          if (location?.pathname !== '/login') {
            window.location.href = '/login?expiredConnection=&redirectTo=' + window.location.pathname;
          }
        }

        return Promise.reject(error);
      },
    );

    return new c(cfg, BASE_PATH, axiosInstance);
  };

  const api = useMemo(
    () => ({
      authApi: createApiInstance(AuthApi),
      userApi: createApiInstance(UsersApi),
      accountApi: createApiInstance(AccountsApi),
      policiesApi: createApiInstance(PoliciesApi),
      appsApi: createApiInstance(AppsApi),
      partnershipsApi: createApiInstance(PartnershipsApi),
      arrangedSaleItemsApi: createApiInstance(ArrangedSaleItemsApi),
      refundRequestsApi: createApiInstance(RefundRequestsApi),
      arrangedProvidersApi: createApiInstance(ArrangedProvidersApi),
      salesApi: createApiInstance(SalesApi),
      couponsApi: createApiInstance(CouponsApi),
      productsApi: createApiInstance(ProductsApi),
      tagsApi: createApiInstance(TagsApi),
      tariffGroupsApi: createApiInstance(TariffGroupsApi),
      availabilitiesApi: createApiInstance(AvailabilitiesApi),
      directSalesApi: createApiInstance(DirectSalesApi),
      transactionsApi: createApiInstance(TransactionsApi),
      vouchersApi: createApiInstance(VouchersApi),
      customersApi: createApiInstance(CustomersApi),
      SaleOriginsApi: createApiInstance(SaleOriginsApi),
      incomeSchedulingsApi: createApiInstance(IncomeSchedulingsApi),
      schedulingsApi: createApiInstance(SchedulingsApi),
      additionalsApi: createApiInstance(AdditionalsApi),
      establishmentTypesApi: createApiInstance(EstablishmentTypesApi),
    }),
    [accessToken, inputAccessToken],
  );

  return api;
};
