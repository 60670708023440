import { Stack } from '@mui/material';
import { Edit05, Trash01, X } from '@untitled-ui/icons-react';
import { enqueueSnackbar } from 'notistack';
import { Fragment, Suspense, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '@/components/Button';
import Loading from '@/components/Loading';
import ModalOnDesktopDrawerOnMobile from '@/components/Modal/ModalOnDesktopDrawerOnMobile';
import { useSellerApi } from '@/hooks/useSellerApi';
import { Availability } from '@/services/SellerApi';
import theme from '@/theme';

import AvailabilityModalContent from './AvailabilityModalContent';
import AvailabilityModalTitle from './AvailabilityModalTitle';
import apiDateToDateObject from '@/utils/apiDateToDateObject';

// const LazyRemoveAvailabilityRecurrenceSelector = lazy(
//   () => import('@/features/agenda/components/RemoveAvailabilityRecurrenceSelector'),
// );
import LazyRemoveAvailabilityRecurrenceSelector from '@/features/agenda/components/RemoveAvailabilityRecurrenceSelector';

interface AvailabilityModalProps {
  availabilityId?: string;
  isOpen: boolean;
}
const AvailabilityModal = (props: AvailabilityModalProps) => {
  const { availabilityId: availabilityIdProp, isOpen } = props;
  const { t } = useTranslation(['agenda'], { keyPrefix: 'modal' });

  const [availability, setAvailability] = useState<Availability | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const { availabilityId: availabilityIdParam } = useParams();
  const navigate = useNavigate();

  const availabilityId = useMemo(
    () => availabilityIdProp ?? availabilityIdParam ?? '',
    [availabilityIdProp, availabilityIdParam],
  );

  const { availabilitiesApi } = useSellerApi();

  const onClose = () => {
    setRemoveModalOpen(false);
    navigate('..');
  };

  const getAvailability = async (id: string) => {
    setLoading(true);
    try {
      const { data } = await availabilitiesApi.getAvailability({
        availabilityId: id,
        include: [
          'products',
          'additionals.product',
          'productTariffTypes.product',
          'productTariffTypes.tariffType',
          'tariffs.type',
          'tariffs.product',
          'tariffs.group',
        ],
      });

      setAvailability(data);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Não foi possível carregar o limite', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const onDelete = () => {
    setRemoveModalOpen(true);
  };

  const onSuccessRemove = () => {
    onClose();
  };

  useEffect(() => {
    getAvailability(availabilityId);
  }, [availabilityId]);

  return (
    <Fragment>
      <ModalOnDesktopDrawerOnMobile
        isOpen={isOpen}
        onClose={() => onClose()}
        showCloseButton={false}
        title={<AvailabilityModalTitle />}
        width='31.25rem'
      >
        {loading ? (
          <Loading />
        ) : availability ? (
          <>
            <AvailabilityModalContent availability={availability} />

            <Stack sx={{ flexDirection: 'row', gap: theme.spacing(3), paddingTop: theme.spacing(4) }}>
              <Button variant='contained' color='primary' onClick={() => onClose()}>
                <X /> {t('close')}
              </Button>

              <Button variant='outlined' color='secondary' onClick={() => onDelete()} sx={{ marginLeft: 'auto' }}>
                <Trash01 />
                {t('delete')}
              </Button>
              <Button variant='outlined' color='secondary' href={`edit`}>
                <Edit05 />
                {t('edit')}
              </Button>
            </Stack>
          </>
        ) : null}
      </ModalOnDesktopDrawerOnMobile>
      <Suspense fallback={null}>
        {removeModalOpen ? (
          <LazyRemoveAvailabilityRecurrenceSelector
            availabilityId={availabilityId}
            isOpen={removeModalOpen}
            onClose={() => setRemoveModalOpen(false)}
            onSuccess={() => onSuccessRemove()}
            initialDate={availability?.appliesAt ? apiDateToDateObject(availability.appliesAt) : undefined}
          />
        ) : null}
      </Suspense>
    </Fragment>
  );
};

export default AvailabilityModal;
