import { Ref, forwardRef } from 'react';
import { Box, FormHelperText } from '@mui/material';

import ddiList from '@/data/ddi.json';
import { Label } from '@/components/Form/Input/styles';
import Input, { InputProps } from '@/components/Form/Input';

import { Wrapper } from './styles';
import DdiSelector from './DdiSelector';
import { createMask, removeMask } from '@/utils/masks';

export type DDIType = {
  mask: string;
  code: string;
  phone: string;
};

type Props = Omit<InputProps, 'onChange'> & {
  onChange: (value: string | null) => void;
  value: string;
};

const MOVEL_BR_PHONE_LENGTH = 11;

/**
 * @deprecated We're deprecating this component, use the new one instead
 * @see src/components/PhoneInput/index.tsx
 */
const PhoneInput = forwardRef((props: Props, ref: Ref<HTMLDivElement>) => {
  const { name, id = name, label, required, error, helperText, value, onChange, onBlur, disabled, ...rest } = props;

  const splitDdiIndex = value?.indexOf(' ') === -1 ? 3 : value?.indexOf(' ');

  const phone = value?.slice(splitDdiIndex).trim();
  const ddiNumber = value?.slice(0, splitDdiIndex === -1 ? 3 : splitDdiIndex).trim();

  const getDDI = (number: string) => {
    if (number) {
      return ddiList.find((data) => data.phone === number)!;
    }

    return ddiList.find((data) => data.code === 'BR')!;
  };

  const getMask = (mask: string | string[], inputValue: string) => {
    if (Array.isArray(mask)) {
      return removeMask(inputValue).length >= MOVEL_BR_PHONE_LENGTH ? mask[1] : mask[0];
    }

    return mask;
  };

  const handleOnChange = (ddi: string, inputValue: string) => {
    const mask = getMask(getDDI(ddiNumber).mask, inputValue);
    const newPhone = createMask({ mask })(inputValue);

    onChange(`${ddi} ${newPhone}`);
  };

  return (
    <Wrapper>
      {label ? (
        <Label variant='standard' required={required} aria-required={required} htmlFor={id}>
          {label}
        </Label>
      ) : null}
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <DdiSelector
          id='ddi'
          error={error}
          value={ddiNumber}
          onChange={(d) => handleOnChange(d?.phone ?? '', phone)}
          disabled={disabled}
        />
        <Input
          {...rest}
          value={phone}
          onBlur={onBlur}
          id={id}
          ref={ref}
          error={error}
          disabled={disabled}
          onChange={(e) => handleOnChange(ddiNumber, e.target.value)}
        />
      </Box>
      {helperText ? <FormHelperText error={error}>{helperText}</FormHelperText> : null}
    </Wrapper>
  );
});

export default PhoneInput;
