import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';

import { AgendaConfigState, DateRange } from '@/features/agenda/types';
import useGetAllAggregatedSchedulings from '@/features/agenda/usecases/useGetAllAggregatedSchedulings';
import useAccount from '@/hooks/useAccount';
import { useConfig } from '@/features/config/useConfig';
import normalizeArray from '@/utils/normalizeArray';

const useLoadAggregatedSchedulingsQuery = () => {
  const { config } = useConfig<AgendaConfigState>();
  const { selectedAccount } = useAccount();
  const { request: getAllSchedulings } = useGetAllAggregatedSchedulings();

  const useRequestQuery = (params: DateRange, previewSize: number | undefined, active = false) => {
    const { since, until } = params;
    const pageViewCropped = previewSize && previewSize > 10 ? 10 : previewSize;
    const sinceKey = since ? format(since, 'yyyy-MM-dd') : '';
    const untilKey = until ? format(until, 'yyyy-MM-dd') : '';

    const productsId = normalizeArray<string>(config?.products);
    const appId = selectedAccount?.appId as string | null;

    return useQuery({
      queryKey: ['aggregatedSchedulings', appId, sinceKey, untilKey, pageViewCropped, productsId],
      queryFn: getAllSchedulings,
      enabled: !!appId && !!since && !!until && active,
      staleTime: 1000,
    });
  };

  return [useRequestQuery];
};

export default useLoadAggregatedSchedulingsQuery;
