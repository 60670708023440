import { z } from 'zod';
import { cpf } from 'cpf-cnpj-validator';

const requiredField = z.string().nonempty({ message: 'Campo obrigatório' });
const notFequiredField = z.string().optional();

export const schema = (isCpf = false) =>
  z
    .object({
      ownerCpf: requiredField.refine((value) => cpf.isValid(value), {
        message: 'CPF inválido',
      }),
      ownerName: requiredField,
      ownerBirthdate: z
        .date({ required_error: 'Campo obrigatório', invalid_type_error: 'Data inválida.' })
        .min(new Date(1900, 0, 1), { message: 'Data inválida.' })
        .max(new Date(), { message: 'Data inválida.' }),

      zipCode: notFequiredField,
      street: notFequiredField,
      number: notFequiredField,
      complement: notFequiredField,
      uf: notFequiredField,
      city: notFequiredField,
      district: notFequiredField,
    })
    .superRefine((value, ctx) => {
      if (isCpf) {
        if (!value.zipCode) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['zipCode'],
            message: 'Campo obrigatório',
          });
        }

        if (!value.street) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['street'],
            message: 'Campo obrigatório',
          });
        }

        if (!value.number) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['number'],
            message: 'Campo obrigatório',
          });
        }

        if (!value.uf) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['uf'],
            message: 'Campo obrigatório',
          });
        }

        if (!value.city) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['city'],
            message: 'Campo obrigatório',
          });
        }

        if (!value.district) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['district'],
            message: 'Campo obrigatório',
          });
        }
      }
    });
