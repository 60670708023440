import { SchedulingFields } from '@/features/agenda/features/schedulingForm/types/formTypes';
import { Product, Scheduling, Recurrence, TariffGroup } from '@/services/SellerApi';
import apiDateToDateObject from '@/utils/apiDateToDateObject';

import formatMidnightString from './formatMidnightString';
import formatNumberToTimeString from './formatNumberToTimeString';
import getMinutesHoursDaysFromMinutes from '@/features/agenda/utils/getMinutesHoursDaysFromMinutes';

const schedulingToFormFields = (scheduling: Scheduling): SchedulingFields => {
  const beginDate = apiDateToDateObject(scheduling.beginDate);
  const consumableFromTime = formatMidnightString(scheduling.consumableFromTime || '00:00'); // Precisa validar se é 24:00 e mudar para 23:59
  const consumableToTime = formatMidnightString(scheduling.consumableToTime || '00:30'); // Precisa validar se é 24:00 e mudar para 23:59
  const durationMinutes = formatNumberToTimeString(scheduling.durationMinutes);
  const calendarIntervalMinutes = formatNumberToTimeString(scheduling.calendarIntervalMinutes || 30);
  const antecedenceHoursReferenceTime = scheduling.antecedenceHoursReferenceTime || undefined;
  const { days, hours, minutes } = getMinutesHoursDaysFromMinutes(scheduling.antecedenceMinutes ?? 0);

  const formFieldsData: SchedulingFields = {
    product: scheduling.product as Product,
    tariffGroup: scheduling.tariffGroup as TariffGroup,
    beginDate,
    recurrence: scheduling.recurrence as Recurrence,
    consumableFromTime,
    consumableToTime,
    durationMinutes,
    calendarIntervalMinutes,
    antecedenceHoursReferenceTime,
    antecedenceDays: days,
    antecedenceHours: hours,
    antecedenceMinutes: minutes,
    antecedenceTime: Boolean(days || hours || minutes),
    overrideConflicts: false,
  };

  return formFieldsData;
};

export default schedulingToFormFields;
