import removeDuplicates from '@/utils/removeDuplicates';
import { getAppProducts, getGetAppProductsQueryKey } from '@/services/sellerApiQuery/api/products/products';
import { GetAppProductsParams, Product } from '@/services/sellerApiQuery/model';
import { AxiosResponse } from 'axios';

type Query = ReturnType<typeof getGetAppProductsQueryKey>;

const useGetAllProducts =
  (appId: string) =>
  async ({ queryKey, signal }: { queryKey: Query; signal: AbortSignal }): Promise<Product[]> => {
    const [_, params] = queryKey;
    const { sellingModes } = params;
    const pageSize = 100;
    if (!appId) {
      throw new Error('appId is required');
    }
    const payload: GetAppProductsParams = {
      limit: pageSize,
      offset: 0,
      sellingModes,
    };

    try {
      const response = await getAppProducts(appId, { ...payload, offset: 0, limit: 1 }, { returnResponse: true });

      const { data, headers } = response as unknown as AxiosResponse<Product[]>;

      const totalCount = parseInt(headers['x-pagination-total-count'] ?? '0');
      const totalPages = Math.ceil(totalCount / pageSize);

      const promises = [];

      for (let page = 0; page <= totalPages - 1; page++) {
        promises.push(
          getAppProducts(
            appId,
            {
              ...payload,
              offset: page * pageSize,
            },
            { signal },
          ),
        );
      }

      const dataArray = await Promise.all(promises);

      const allPages = dataArray.reduce((accumulator: Product[], data) => {
        return accumulator.concat(data as unknown as Product[]);
      }, data);
      return removeDuplicates<Product>(allPages, 'id');
    } catch (error) {
      console.error(error);
      return [];
    }
  };

export default useGetAllProducts;
