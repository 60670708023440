import { Box } from '@mui/material';

import theme from '@/theme';
import Chip from '@/components/Chip';
import { PreviewItem } from '@/components/PreviewDrawer';

interface Props {
  list: string[];
  title: string;
}

export const PreviewItemChips = (props: Props) => {
  const { list = [], title } = props;

  return (
    <PreviewItem
      title={title}
      sx={{ alignItems: 'flex-start' }}
      content={() => (
        <Box display='flex' gap={0.5} flexDirection='column' alignItems='flex-end' pl={2}>
          {list.length ? (
            list.map((item) => (
              <Box key={item}>
                <Chip
                  size='small'
                  variant='outlined'
                  color={{
                    font: theme.palette.colors.blue[500],
                    background: theme.palette.colors.blue[100],
                  }}
                  label={item}
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '100%',
                    maxWidth: 200,
                  }}
                />
              </Box>
            ))
          ) : (
            <Chip
              size='small'
              variant='outlined'
              color={{
                font: theme.palette.colors.blue[500],
                background: theme.palette.colors.blue[100],
              }}
              label='-'
            />
          )}
        </Box>
      )}
    />
  );
};

export default PreviewItemChips;
