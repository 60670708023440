import { Availability } from '@/services/SellerApi';
type AvailabilityContentArchived = {
  isSomeArchived: boolean;
  productTariffType: boolean;
  product: boolean;
  tariff: boolean;
  tariffGroup: boolean;
};

const isAvailabilityContentArchived = (availability: Availability): AvailabilityContentArchived => {
  const isProductArchived = availability.products?.some((product) => product.active === false);

  const isTariffArchived = availability.tariffs?.some((tariff) => tariff.deletedAt);

  const isTariffGroupArchived = availability.tariffs?.some((tariff) => tariff.group?.deletedAt);

  const isProductTariffTypeArchived = availability.productTariffTypes?.some(
    (productTariffType) => productTariffType.product?.active === false,
  );

  const isSomeArchived =
    !!isProductTariffTypeArchived || !!isProductArchived || !!isTariffArchived || !!isTariffGroupArchived;

  return {
    isSomeArchived,
    productTariffType: !!isProductTariffTypeArchived,
    product: !!isProductArchived,
    tariff: !!isTariffArchived,
    tariffGroup: !!isTariffGroupArchived,
  };
};

export default isAvailabilityContentArchived;
