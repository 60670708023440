import { Alert as MUIAlert, AlertProps } from '@mui/material';
import { ReactNode } from 'react';

interface IAlert {
  (props: AlertProps & { message?: string | ReactNode }): JSX.Element;
  (props: AlertProps & { children?: ReactNode }): JSX.Element;
}

const Alert: IAlert = (props) => {
  const { ...rest } = props;
  return <>{'message' in props ? <MUIAlert {...rest}>{props.message}</MUIAlert> : <MUIAlert {...rest} />}</>;
};

export default Alert;
