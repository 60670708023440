import { PickersDay } from '@mui/x-date-pickers';
import DatePicker, { DatePickerProps } from '@/components/Form/DatePicker';
import { format, isBefore, startOfDay } from 'date-fns';

import { StyledLayout } from './styles';
import { CircularProgress, IconButton } from '@mui/material';
import { Calendar } from '@untitled-ui/icons-react';

interface Props extends DatePickerProps {
  dates: string[];
  validateDay?: (date: string) => boolean;
}

export const ReservationCalendar = (props: Props) => {
  const { onChange, value, label, dates, validateDay, ...rest } = props;

  return (
    <DatePicker
      fullWidth
      name='date'
      value={value}
      label={label}
      variant='mobile'
      format='dd/MM/yyyy'
      onChange={onChange}
      closeOnSelect
      showDaysOutsideCurrentMonth
      renderLoading={() => <CircularProgress color='primary' />}
      slotProps={{
        actionBar: { actions: [] },
        nextIconButton: { id: 'nextMonthButton' },
        previousIconButton: { id: 'previousMonthButton' },
        textField: {
          InputProps: {
            endAdornment: (
              <IconButton tabIndex={-1}>
                <Calendar />
              </IconButton>
            ),
          },
        },
      }}
      slots={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        layout: StyledLayout,
        toolbar: undefined,
        day: ({ className, day, ...rest }) => {
          if (!day) {
            return <PickersDay {...rest} day={day} className={className} />;
          }

          const dateISOString = format(day, 'yyyy-MM-dd');
          const isAvailable = dates.some((date) => date === dateISOString);

          function isFirstDateBeforeSecond(date1: Date, date2: Date): boolean {
            const startOfDayDate1 = startOfDay(date1);
            const startOfDayDate2 = startOfDay(date2);

            return isBefore(startOfDayDate1, startOfDayDate2);
          }

          let customClassName = '';

          if (isFirstDateBeforeSecond(day, new Date()) || !isAvailable) {
            customClassName = 'MuiPickersDay-custom-unavailableDay';
          }

          if (isAvailable && !isFirstDateBeforeSecond(day, new Date())) {
            customClassName = 'MuiPickersDay-custom-availableDay';
          }

          if (isAvailable && validateDay && !validateDay(dateISOString)) {
            customClassName = 'MuiPickersDay-custom-unavailableDay';
          }

          return <PickersDay {...rest} day={day} className={className + ' ' + customClassName} />;
        },
      }}
      {...rest}
    />
  );
};

export default ReservationCalendar;
