import { FilterOption } from '@/contexts/FilterContext';
import useAccount from '@/hooks/useAccount';
import { useSellerApi } from '@/hooks/useSellerApi';
import {
  Coupon,
  CouponsApiGetAppCouponsRequest,
  RefundRequestStatus,
  SaleOrigin,
  SaleOriginsApiGetAppSaleOriginsRequest,
  SaleStateEnum,
  Tag,
  TagsApiGetAppTagsRequest,
} from '@/services/SellerApi';
import { TFunction } from 'i18next';
import { useEffect, useState } from 'react';
import bugsnag from '@/services/bugsnag';
import { NotifiableError } from '@bugsnag/js';
import { SaleAttributes } from '@/common/sale';

export const useSaleFilterSchema = (t: TFunction<[string], undefined>) => {
  const { selectedAccount } = useAccount();

  const { couponsApi, SaleOriginsApi, tagsApi } = useSellerApi();

  const [coupons, setCoupons] = useState<FilterOption[]>([]);
  const [loadingCoupon, setLoadingCoupon] = useState(true);
  const [saleOrigins, setSaleOrigins] = useState<FilterOption[]>([]);
  const [loadingSaleOrigin, setLoadingSaleOrigin] = useState(true);

  const [appTags, setAppTags] = useState<FilterOption[]>([]);
  const [loadingAppTags, setLoadingAppTags] = useState(true);

  const getAppCoupons = async (appId: string) => {
    setLoadingCoupon(true);

    let page = 0;
    const limit = 100;
    let keepRunning = true;
    const allCoupons: Coupon[] = [];

    try {
      while (keepRunning) {
        const payload: CouponsApiGetAppCouponsRequest = {
          appId,
          limit: limit,
          offset: page * limit,
          filter: 'all',
        };

        const { data, headers } = await couponsApi.getAppCoupons(payload);

        allCoupons.push(...data);
        page++;

        const totalOfProducts = Number(headers['x-pagination-total-count'] ?? '0');

        if (allCoupons.length >= totalOfProducts) {
          keepRunning = false;
          break;
        }
      }

      setCoupons(allCoupons.map((coupon) => ({ label: coupon.code, value: coupon.id })));
    } catch (error) {
      bugsnag.notify(error as NotifiableError);
    } finally {
      setLoadingCoupon(false);
    }
  };

  const getAppSaleOrigins = async (appId: string) => {
    setLoadingSaleOrigin(true);

    let page = 0;
    const limit = 100;
    let keepRunning = true;
    const allSaleOrigins: SaleOrigin[] = [];

    try {
      while (keepRunning) {
        const payload: SaleOriginsApiGetAppSaleOriginsRequest = {
          appId,
          limit: limit,
          offset: page * limit,
        };

        const { data, headers } = await SaleOriginsApi.getAppSaleOrigins(payload);

        allSaleOrigins.push(...data);
        page++;

        const totalOfProducts = Number(headers['x-pagination-total-count'] ?? '0');

        if (allSaleOrigins.length >= totalOfProducts) {
          keepRunning = false;
          break;
        }
      }

      setSaleOrigins(allSaleOrigins.map((coupon) => ({ label: coupon.name, value: coupon.id })));
    } catch (error) {
      bugsnag.notify(error as NotifiableError);
    } finally {
      setLoadingSaleOrigin(false);
    }
  };

  const getAppTags = async (appId: string) => {
    let page = 0;
    const limit = 100;
    let keepRunning = true;
    const appTags: Tag[] = [];

    try {
      setLoadingAppTags(true);
      while (keepRunning) {
        const payload: TagsApiGetAppTagsRequest = {
          appId,
          limit: limit,
          offset: page * limit,
        };

        const { data, headers } = await tagsApi.getAppTags(payload);

        appTags.push(...data);
        page++;

        const totalOfProducts = Number(headers['x-pagination-total-count'] ?? '0');

        if (appTags.length >= totalOfProducts) {
          keepRunning = false;
          break;
        }
      }

      setAppTags(appTags.map((appTag) => ({ label: appTag.name, value: appTag.id })));
    } catch (error) {
      bugsnag.notify(error as NotifiableError);
    } finally {
      setLoadingAppTags(false);
    }
  };

  useEffect(() => {
    if (selectedAccount?.appId) {
      getAppTags(selectedAccount.appId);
      getAppCoupons(selectedAccount.appId);
      getAppSaleOrigins(selectedAccount.appId);
    }
  }, [selectedAccount?.appId]);

  return {
    currentState: {
      type: 'array',
      title: t('filter.currentState.label'),
      options: [
        {
          value: SaleStateEnum.PaymentComplete,
          label: t('status.payment_complete'),
        },
        {
          value: SaleStateEnum.PaymentChargeback,
          label: t('status.payment_chargeback'),
        },
        {
          value: SaleStateEnum.PaymentVoided,
          label: t('status.payment_voided'),
        },
        {
          value: SaleStateEnum.Expired,
          label: t('status.expired'),
        },
        {
          value: SaleStateEnum.Created,
          label: t('status.created'),
        },
        {
          value: SaleStateEnum.Canceled,
          label: t('status.canceled'),
        },
      ],
    },
    refundRequestStatus: {
      type: 'array',
      options: [{ label: t('filter.refund_request.label'), value: RefundRequestStatus.Pending }],
    },
    since: {
      type: 'date',
      format: 'dd/MM/yyyy',
      title: t('filter.sale_date_interval.since.label'),
      tooltip: t('filter.sale_date_interval.since.label'),
    },
    until: {
      type: 'date',
      format: 'dd/MM/yyyy',
      title: t('filter.sale_date_interval.until.label'),
      tooltip: t('filter.sale_date_interval.until.label'),
    },
    sources: {
      type: 'array',
      options: [
        { label: t('sources.site'), value: 'site' },
        { label: t('sources.direct_link'), value: 'direct_link' },
        { label: t('sources.direct_sale'), value: 'direct_sale' },
        { label: t('sources.resale'), value: 'resale' },
      ],
    },
    attributes: {
      type: 'array',
      options: Object.values(SaleAttributes).map((ota) => ({
        value: ota,
        label: t(`otas.${ota}`),
      })),
    },
    couponIds: {
      type: 'array',
      title: t('filter.couponIds.label'),
      options: coupons,
      loading: loadingCoupon,
      tooltip: t('filter.couponIds.label'),
    },
    id_sale_origin: {
      type: 'array',
      title: t('filter.saleOriginIds.label'),
      options: saleOrigins,
      loading: loadingSaleOrigin,
      tooltip: t('filter.saleOriginIds.label'),
    },
    tagIds: {
      type: 'array',
      title: t('filter.tags.label'),
      options: appTags,
      loading: loadingAppTags,
      tooltip: t('filter.tags.label'),
    },
  };
};

export default useSaleFilterSchema;
