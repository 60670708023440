import { Fragment } from 'react';
import { Grid, useMediaQuery } from '@mui/material';

import CustomerDetail from '.';
import Button from '../Button';
import theme from '@/theme';
import { useTranslation } from 'react-i18next';

interface Props {
  customerId?: string;
  onClose: () => void;
}

export const CustomerDetailWithCloseButton = (props: Props) => {
  const { onClose, customerId } = props;

  const { t } = useTranslation(['customer']);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Fragment>
      <CustomerDetail customerId={customerId} onClose={onClose} />

      {isMobile ? (
        <Grid item xs={12}>
          <Button color='primary' variant='contained' onClick={onClose} fullWidth>
            {t('details.close_button')}
          </Button>
        </Grid>
      ) : null}
    </Fragment>
  );
};

export default CustomerDetailWithCloseButton;
