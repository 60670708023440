import { zodResolver } from '@hookform/resolvers/zod';
import { Grid, Link, Paper, Typography } from '@mui/material';
import { useSplitTreatments } from '@splitsoftware/splitio-react';
import { KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import Alert from '@/components/Alert';
import Loading from '@/components/BackdropLoading';
import Button from '@/components/Button';
import Checkbox from '@/components/Form/Checkbox';
import Input from '@/components/Form/Input';
import PasswordInput from '@/components/Form/PasswordInput';
import { loginSchema } from '@/features/auth/components/Login/Form/schema';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import useAuth from '@/contexts/Auth/hooks/useAuth';
import useErrorHandler from '@/hooks/useErrorHandler';
import useQueryString from '@/hooks/useQueryString';
import { ErrorTypes } from '@/interfaces/errorTypes';
import { LoginParams } from '@/services/SellerApi';

import { SignupButtonContainer } from './styles';
import { AuthStatus } from '@/interfaces/auth';

export const Form = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [capsLockMessage, setCapsLockMessage] = useState('');
  const { redirectTo: redirectToFromURL, expiredConnection } = useQueryString<{
    redirectTo: string;
    expiredConnection: string;
  }>(['redirectTo', 'expiredConnection']);

  const { t } = useTranslation(['login', 'ui']);
  const { errorHandler } = useErrorHandler();
  const { t: translateGenericError } = useTranslation(['errors']);

  const {
    treatments: { signup_button },
  } = useSplitTreatments({
    names: ['signup_button'],
  });

  const { track } = useAnalytics();
  const { status, login, loginError } = useAuth();

  const { register, setError, handleSubmit, formState, clearErrors } = useForm<LoginParams>({
    mode: 'onSubmit',
    resolver: zodResolver(loginSchema),
  });
  const { errors, isSubmitting } = formState;

  const redirectUser = () => {
    const hasRedirectToState = location.state && location.state?.redirectTo;
    if (hasRedirectToState) {
      const { redirectTo } = location.state;
      location.state.redirectTo = null;

      navigate(redirectTo, { replace: true });
    } else if (redirectToFromURL) {
      navigate(redirectToFromURL, { replace: true });
    } else {
      navigate('/');
    }
  };

  const checkCapsLock = (event: KeyboardEvent<HTMLDivElement>) => {
    setCapsLockMessage(event.getModifierState('CapsLock') ? t('login_active_caps_lock_message') : '');
  };

  const showSignupButton = useMemo(() => {
    return signup_button.treatment === 'on';
  }, [signup_button]);

  useEffect(() => {
    if (status === AuthStatus.AUTHENTICATED && !loginError) {
      redirectUser();
    }
  }, [status]);

  useEffect(() => {
    if (loginError) {
      const pagePath = 'loginPage/loginRequest/error';
      const error = loginError;
      const { errors: handlerErrors, status, statusText } = errorHandler(error, pagePath);

      if (handlerErrors) {
        handlerErrors.forEach(({ type }) => {
          if (type === ErrorTypes.UNAUTHORIZED) {
            const message = t('login_form_invalid');
            setError('email', { message });
            setError('password', { message });
            track(pagePath, { message, status, statusText, error });
          } else {
            const message = translateGenericError('generic_default_error');
            setError('root.serverError', { message });
            track(pagePath, { message, status, statusText, error });
          }
        });
        return;
      }

      const message = translateGenericError('generic_default_error');
      setError('root.serverError', { message });
    }
  }, [loginError]);

  return (
    <Grid container>
      {expiredConnection === '' ? (
        <Grid item xs={12}>
          <Alert
            severity='warning'
            sx={{ marginBottom: 2 }}
            message={t('connection.expired_connection_alert', { ns: 'ui' })}
            onClose={() => clearErrors('root.serverError')}
          />
        </Grid>
      ) : null}

      {errors?.root?.serverError ? (
        <Grid item xs={12}>
          <Alert
            severity='error'
            sx={{ marginBottom: 2 }}
            message={errors.root?.serverError?.message}
            onClose={() => clearErrors('root.serverError')}
          />
        </Grid>
      ) : null}

      <Grid item alignContent='center' xs={12}>
        <Paper elevation={2} sx={{ position: 'relative' }}>
          <Grid
            component='form'
            container
            onSubmit={handleSubmit(login)}
            direction='column'
            rowSpacing={{
              xs: 1,
              sm: 2,
            }}
            sx={{
              padding: {
                xs: 2,
                md: 3,
              },
            }}
          >
            <Grid xs={12} item sx={{ paddingTop: '0 !important' }}>
              <Typography color='colors.blue.500' variant='h5' sx={{ lineHeight: 1 }}>
                {t('login_form_heading')}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Input
                fullWidth
                id='email'
                label='Email'
                autoComplete='email'
                autoFocus
                error={!!errors['email']}
                helperText={errors['email']?.message as string}
                disabled={isSubmitting}
                {...register('email')}
              />
            </Grid>

            <Grid item xs={12}>
              <PasswordInput
                fullWidth
                id='password'
                onKeyDown={checkCapsLock}
                error={!!errors['password']}
                label={t('login_form_input_password_label')}
                helperText={errors['password']?.message ?? capsLockMessage}
                disabled={isSubmitting}
                {...register('password')}
              />
            </Grid>

            <Grid item xs={12}>
              <Checkbox color='primary' label={t('login_form_keep_me_connected')} {...register('keepConnected')} />
            </Grid>

            <Grid item xs={12}>
              <Button disabled={isSubmitting} type='submit' fullWidth variant='contained'>
                {t('login_form_submit_button')}
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Link href='/reset-password' variant='regularRegular' sx={{ textDecoration: 'none' }}>
                {t('login_form_forgot_password')}
              </Link>
            </Grid>

            {showSignupButton ? (
              <SignupButtonContainer item xs={12}>
                <Grid container spacing={1} alignItems='center'>
                  <Grid item xs={12} lg={8}>
                    <Typography
                      variant='regularRegular'
                      sx={{
                        whiteSpace: 'nowrap',
                      }}
                      display={{ xs: 'none', sm: 'block' }}
                      color='colors.gray.700'
                    >
                      {t('login_form_signup_text')}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={12} lg={4}>
                    <Button fullWidth type='button' href='/signup' variant='outlined' sx={{ whiteSpace: 'nowrap' }}>
                      {t('login_form_signup_button')}
                    </Button>
                  </Grid>
                </Grid>
              </SignupButtonContainer>
            ) : null}
          </Grid>
          {isSubmitting ? <Loading /> : null}
        </Paper>
      </Grid>
    </Grid>
  );
};
