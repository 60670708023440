import { DetailedTariffGroup, Tariff } from '@/services/SellerApi';
import { Divider, Paper, Stack, Typography } from '@mui/material';
import Checkbox from '@/components/Form/Checkbox';
import { formatCents } from '@/utils';
import Chip from '@/components/Chip';
import { Fragment, useMemo } from 'react';
import { AdditionalsContainer } from '../AdditionalCard/styles';
import { ProductWithItems } from '../ProductItemPicker';

interface Props {
  product: ProductWithItems<Tariff, DetailedTariffGroup>;
  tariffGroups: DetailedTariffGroup[];
  selectedTariffs?: Tariff[];
  onClick?: (tariff: Tariff, product: ProductWithItems<Tariff, DetailedTariffGroup>) => void;
  disabled?: boolean;
}

const TariffCard = (props: Props) => {
  const { product, tariffGroups, selectedTariffs = [], onClick = () => {}, disabled } = props;

  const getTariffGroupPriceRange = (tariffGroup: DetailedTariffGroup) => {
    const prices = tariffGroup.tariffs.map((tariff) => tariff.priceCents);

    return `${formatCents(Math.min(...prices))} - ${formatCents(Math.max(...prices))}`;
  };

  const tariffGroupsWithTariffs = useMemo(
    () => tariffGroups.filter((tariffGroup) => tariffGroup.tariffs.length > 0),
    [tariffGroups],
  );

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    tariff: Tariff,
    product: ProductWithItems<Tariff, DetailedTariffGroup>,
  ) => {
    event.preventDefault();

    if (!disabled) {
      onClick(tariff, product);
    }
  };

  return (
    <Paper component={Stack} elevation={3} sx={{ p: '0.5rem' }}>
      <Stack flexDirection='row' justifyContent='space-between' alignItems='center' sx={{ mb: '0.5rem' }}>
        <Typography variant='smallBold'>{product.internalName ?? product.name}</Typography>
      </Stack>

      <AdditionalsContainer>
        {tariffGroupsWithTariffs?.map((tariffGroup, index) => (
          <Fragment key={tariffGroup.id}>
            <Stack flexDirection='row' justifyContent='space-between' alignItems='center' sx={{ mb: '0.5rem' }}>
              <Chip size='small' label={tariffGroup.name} />

              <Typography variant='extraSmallRegular'>{getTariffGroupPriceRange(tariffGroup)}</Typography>
            </Stack>

            {tariffGroup.tariffs.map((tariff) => (
              <Checkbox
                disableRipple
                key={tariff.id}
                checked={selectedTariffs.some((selectedTariff) => selectedTariff.id === tariff.id)}
                label={tariff.type?.name}
                onClick={(e) => handleClick(e, tariff, product)}
                disabled={disabled}
              />
            ))}

            {index < tariffGroupsWithTariffs.length - 1 ? <Divider sx={{ my: '0.5rem' }} /> : null}
          </Fragment>
        ))}
      </AdditionalsContainer>
    </Paper>
  );
};

export default TariffCard;
