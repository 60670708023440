import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { AgendaProvider } from '@/features/agenda/context/AgendaContext';
import useAgendaConfig from '@/features/agenda/store/useAgendaConfig';
import FilterProvider from '@/contexts/FilterContext';
import ConfigProvider from '@/features/config/context/provider';
import { AgendaConfigState } from '@/features/agenda/types';
import AgendaPageWithConfig from '@/features/agenda/components/AgendaPageWithConfig';

const AgendaPage = () => {
  const { t } = useTranslation(['agenda']);
  const configAgenda = useAgendaConfig();

  return (
    <ConfigProvider<AgendaConfigState> config={configAgenda}>
      <FilterProvider contextName={'agendaFilters'}>
        <AgendaProvider>
          <Helmet>
            <title>{t('page_title')}</title>
          </Helmet>
          <AgendaPageWithConfig />
        </AgendaProvider>
      </FilterProvider>
    </ConfigProvider>
  );
};

export default AgendaPage;
