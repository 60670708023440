import { Box, Link, Stack } from '@mui/material';
import MobileMenu from '@/components/MobileMenu';
import PreviewDrawer, { PreviewItem } from '@/components/PreviewDrawer';
import { colors } from '@/theme';
import { useTranslation } from 'react-i18next';
import { Sale, Tag } from '@/services/SellerApi';
import Button from '@/components/Button';
import { formatCents } from '@/utils';
import { format } from 'date-fns';
import SaleTagsPopup, { TagChip } from '../SaleTagsPopup';
import { Add } from '@mui/icons-material';

type PreviewAction<E> = {
  label: string;
  icon: JSX.Element;
  getLink?: (entity: E) => string;
  onClick?: (entity: E) => void;
};
type Props = {
  sale?: Sale;
  open: boolean;
  onClose: () => void;
  actions: PreviewAction<Sale>[];
  onTagModalClose: () => Promise<void>;
  renderSaleState: (sale: Sale) => JSX.Element;
  renderSaleSource: (sale: Sale) => JSX.Element;
  getTagNames: (saleTags: Tag[]) => string[] | null;
  renderAggregatedVouchers: (sale: Sale) => JSX.Element;
  tagAnchorEl: { saleId?: string | undefined; element: null | HTMLElement };
  onTagClick: (event: React.MouseEvent<HTMLButtonElement>, sale: Sale) => void;
};
export const MobilePreview = (props: Props) => {
  const {
    open,
    sale,
    onClose,
    actions,
    getTagNames,
    onTagClick,
    onTagModalClose,
    tagAnchorEl,
    renderSaleState,
    renderAggregatedVouchers,
    renderSaleSource,
  } = props;

  const { t } = useTranslation(['sales']);

  return (
    <PreviewDrawer open={open} onClose={onClose} showCloseButton={false}>
      {sale ? (
        <Box display='flex' flexDirection='column' gap={1}>
          <Stack paddingX='4px' direction='row' justifyContent='space-between' alignItems='center'>
            <Stack alignItems='flex-start'>
              <Link variant='smallSemiBold' href={sale.id}>
                # {sale.position}
              </Link>

              <Link
                target='_blank'
                variant='smallBold'
                href={`../customers/${sale.customer!.id}`}
                sx={{ cursor: 'pointer', color: colors.blue[500], textDecoration: 'underline' }}
              >
                {`${sale.customer!.firstName} ${sale.customer!.lastName}`}
              </Link>
            </Stack>

            <MobileMenu
              items={actions.map((action) => ({
                ...action,
                icon: action.icon,
                label: action.label,
                href: action.getLink?.(sale),
                onClick: () => action.onClick?.(sale),
              }))}
            />
          </Stack>

          <Box
            display='flex'
            flexDirection='column'
            sx={{
              '& .preview-item:not(:nth-of-type(even))': {
                background: colors.gray[50],
                borderRadius: '4px',
              },
            }}
          >
            <PreviewItem title={t('list.table.header.current_state')} content={() => renderSaleState(sale)} />

            <PreviewItem title={t('list.table.header.amount_cents')} content={formatCents(sale.amountCents ?? 0)} />

            <PreviewItem title={t('list.table.header.source')} content={() => renderSaleSource(sale)} />

            <PreviewItem
              content={() => renderAggregatedVouchers(sale)}
              title={t('list.table.header.aggregated_vouchers')}
            />

            <PreviewItem
              sx={{ alignItems: 'flex-start' }}
              title={t('list.table.header.tags')}
              content={() => {
                const tags = getTagNames(sale.tags!);

                return (
                  <Box display='flex' gap={0.5} flexDirection='column' alignItems='flex-end' pl={2}>
                    {!tags ? (
                      <TagChip
                        sx={{ '& .MuiChip-label': { display: 'flex' } }}
                        label={<Add fontSize='small' sx={{ width: '16px', height: '16px' }} />}
                        onClick={(e) => onTagClick(e as unknown as React.MouseEvent<HTMLButtonElement>, sale)}
                      />
                    ) : null}

                    {tags?.map((tag) => (
                      <TagChip
                        key={tag}
                        label={tag}
                        onClick={(e) => onTagClick(e as unknown as React.MouseEvent<HTMLButtonElement>, sale)}
                      />
                    ))}

                    <SaleTagsPopup
                      sale={sale}
                      tagAnchor={tagAnchorEl}
                      onClose={onTagModalClose}
                      onClick={onTagClick}
                      open={tagAnchorEl.saleId === sale.id}
                    />
                  </Box>
                );
              }}
            />

            <PreviewItem
              title={t('list.table.header.created_at')}
              content={format(new Date(sale.createdAt), 'dd/MM/yyyy - HH:mm')}
            />
          </Box>

          <Button variant='contained' onClick={onClose}>
            {t('list.table.actions.close')}
          </Button>
        </Box>
      ) : null}
    </PreviewDrawer>
  );
};

export default MobilePreview;
