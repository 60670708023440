import { Stack } from '@mui/material';
import { styled } from '@mui/system';

export const ErrorWrapper = styled(Stack)(() => ({
  padding: '1.5rem',
  justifyContent: 'start',
  alignItems: 'center',
  height: '100dvh',
}));

export const ErrorLogoWrapper = styled(Stack)(({ theme }) => ({
  alignSelf: 'flex-start',
  maxWidth: '9.1875rem',

  [theme.breakpoints.up('sm')]: {
    maxWidth: '12.3125rem',
  },
}));
