import { Alert, AlertColor, AlertTitle } from '@mui/material';
import { Ref, forwardRef } from 'react';
import { CustomContentProps, closeSnackbar } from 'notistack';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

type Props = CustomContentProps & {
  title?: string;
};

export const SnackbarContent = forwardRef((props: Props, ref: Ref<HTMLDivElement>) => {
  const { variant = 'success', message, title, id } = props;

  return (
    <Alert
      ref={ref}
      severity={variant as AlertColor}
      icon={<ErrorOutlineOutlinedIcon fontSize='small' />}
      onClose={() => closeSnackbar(id)}
    >
      {title ? <AlertTitle>{title}</AlertTitle> : null}
      {message}
    </Alert>
  );
});

export default SnackbarContent;
