import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import accountSelector from '@/locales/pt/accountSelector.json';
import appBar from '@/locales/pt/appBar.json';
import commissions from '@/locales/pt/commissions.json';
import coupons from '@/locales/pt/coupons.json';
import createApp from '@/locales/pt/createApp.json';
import customer from '@/locales/pt/customer.json';
import directSales from '@/locales/pt/directSales.json';
import errors from '@/locales/pt/errors.json';
import incomeSchedulings from '@/locales/pt/incomeSchedulings.json';
import layouts from '@/locales/pt/layouts.json';
import login from '@/locales/pt/login.json';
import partnership from '@/locales/pt/partnership.json';
import PartnershipPortalReservationForm from '@/locales/pt/PartnershipPortalReservationForm.json';
import payment from '@/locales/pt/payment.json';
import productShowCase from '@/locales/pt/productShowCase.json';
import receipt from '@/locales/pt/receipt.json';
import refundRequests from '@/locales/pt/refundRequests.json';
import reservationTariffSelector from '@/locales/pt/reservationTariffSelector.json';
import resetPassword from '@/locales/pt/resetPassword.json';
import saleDetails from '@/locales/pt/saleDetails.json';
import sales from '@/locales/pt/sales.json';
import showCaseCheckout from '@/locales/pt/showCaseCheckout.json';
import sidebar from '@/locales/pt/sidebar.json';
import signup from '@/locales/pt/signup.json';
import ui from '@/locales/pt/ui.json';
import vouchers from '@/locales/pt/vouchers.json';
import terms from '@/locales/pt/terms.json';
import products from '@/locales/pt/products.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      'pt-BR': {
        login,
        errors,
        signup,
        appBar,
        sidebar,
        accountSelector,
        createApp,
        resetPassword,
        partnership,
        ui,
        refundRequests,
        commissions,
        coupons,
        sales,
        saleDetails,
        productShowCase,
        layouts,
        directSales,
        reservationTariffSelector,
        PartnershipPortalReservationForm,
        payment,
        showCaseCheckout,
        vouchers,
        incomeSchedulings,
        receipt,
        customer,
        terms,
        products,
      },
    },
    lng: 'pt-BR', // if you're using a language detector, do not define the lng option
    fallbackLng: 'pt-BR',

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    returnNull: false,
  });

export default i18n;
