/* eslint-disable @typescript-eslint/no-explicit-any */
import getNestedValue from '@/features/config/context/utils/getNestedValue';
import setNestedValue from '@/features/config/context/utils/setNestedValue';

const getOrSetObjectByPath = (state: any, keyPath: string): any => {
  const result: any = {};

  const value = getNestedValue(state, keyPath);
  if (value !== undefined) {
    setNestedValue(result, keyPath, value);
  }

  return result;
};

export default getOrSetObjectByPath;
