import { CalendarView, ICalendarContext, WeekCalendarItem } from '@/contexts/Calendar/types';
import { ReactNode, useMemo } from 'react';
import { CalendarContext } from '@/contexts/Calendar/Context';

type Props = {
  children: ReactNode;
  activeDate: Date;
  calendarView: CalendarView;
  onDateChange?: (date: Date) => void;
  onCalendarViewChange?: (view: CalendarView) => void;
};

export const CalendarProvider = (props: Props) => {
  const { children, activeDate, calendarView, onDateChange = () => {}, onCalendarViewChange = () => {} } = props;

  const date = useMemo(() => {
    return activeDate ?? new Date();
  }, [activeDate]);

  const updateDate = (date: Date) => {
    onDateChange(date);
  };

  const updateView = (view: CalendarView) => {
    onCalendarViewChange(view);
  };

  const getMonthString = (forcedDate?: Date) => {
    const month = forcedDate ? forcedDate.getMonth() : date.getMonth();
    const year = forcedDate ? forcedDate.getFullYear() : date.getFullYear();
    return new Date(year, month, 1).toLocaleString('default', { month: 'long' });
  };

  const getWeekDates = (forcedDate?: Date): WeekCalendarItem[] => {
    const month = forcedDate ? forcedDate.getMonth() : date.getMonth();
    const year = forcedDate ? forcedDate.getFullYear() : date.getFullYear();
    const weekDates: WeekCalendarItem[] = [];
    const weekStart = 1;
    const firstDayOfMonth = new Date(year, month, 1);
    const lastDayOfMonth = new Date(year, month + 1, 0);
    const numWeeks = Math.ceil((firstDayOfMonth.getDay() + lastDayOfMonth.getDate()) / 7);
    for (let i = 0; i < numWeeks; i++) {
      const firstDayOfWeek = new Date(year, month, weekStart + i * 7 - firstDayOfMonth.getDay());
      const lastDayOfWeek = new Date(year, month, weekStart + i * 7 + 6 - firstDayOfMonth.getDay());
      weekDates.push({ firstDay: firstDayOfWeek, lastDay: lastDayOfWeek });
    }

    return weekDates;
  };

  // Get the first day of the range, based on the calendar view.
  // Always return the first day of the week, month or year.
  const firstDayOfRange = useMemo(() => {
    switch (calendarView) {
      case 30:
        return new Date(date.getFullYear(), date.getMonth(), 1);
      case 7:
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay());
      case 15:
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() - (date.getDate() % 15));
      case 365:
        return new Date(date.getFullYear(), 0, 1);
      default:
        // For custom ranges like 20 days, 10 days, 3 days, etc.
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() - ((date.getDate() - 1) % calendarView));
    }
  }, [date, calendarView]);

  // Get the last day of the range, based on the calendar view.
  // Always return the last day of the week, month or year.
  const lastDayOfRange = useMemo(() => {
    switch (calendarView) {
      case 30:
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
      case 7:
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() + (6 - date.getDay()));
      case 15:
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() + (14 - (date.getDate() % 15)));
      case 365:
        return new Date(date.getFullYear(), 11, 31);
      default:
        // For custom ranges like 20 days, 10 days, 3 days, etc.
        return new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() + (calendarView - ((date.getDate() - 1) % calendarView)) - 1,
        );
    }
  }, [date, calendarView]);

  const next = () => {
    if (calendarView === 30) {
      // Next Year
      updateDate(new Date(date.getFullYear(), date.getMonth() + 1, 1));
    } else {
      // Next week
      updateDate(new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7));
    }
  };

  const prev = () => {
    if (calendarView === 30) {
      // Previous Year
      updateDate(new Date(date.getFullYear(), date.getMonth() - 1, 1));
    } else {
      // Previous week
      updateDate(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7));
    }
  };

  const exportValue: ICalendarContext = {
    activeDate,
    calendarView,
    firstDayOfRange,
    lastDayOfRange,
    updateDate,
    updateView,
    getMonthString,
    getWeekDates,
    next,
    prev,
  };

  return <CalendarContext.Provider value={exportValue}>{children}</CalendarContext.Provider>;
};
