import { SxProps } from '@mui/material';
import { ImageWrapper, StyledImage } from './styles';
import emptyImage from '@/assets/Icons/empty-image.svg';

type Props = {
  src?: string;
  width?: number | string;
  height?: number | string;
  sx?: SxProps;
};

export const Image = (props: Props) => {
  const { src, width, height } = props;

  if (src) {
    return <StyledImage src={src} height={height} width={width} sx={props.sx} />;
  }

  return (
    <ImageWrapper>
      <img src={emptyImage} height={height} width={width} alt='' />
    </ImageWrapper>
  );
};
