import theme from '@/theme';
import BaseChip, { ChipProps } from '@/components/Chip';

export const VouchersChip = (props: ChipProps) => {
  return (
    <BaseChip
      size='small'
      variant='outlined'
      color={{
        font: theme.palette.colors.blue[500],
        background: theme.palette.colors.blue[100],
      }}
      {...props}
    />
  );
};

export default VouchersChip;
