import { FilterOption } from '@/contexts/FilterContext';
import useAccount from '@/hooks/useAccount';
import { useSellerApi } from '@/hooks/useSellerApi';
import {
  ArrangedProduct,
  AssociationModel,
  ProductsApiGetAppArrangedProductsRequest,
  SaleStateEnum,
} from '@/services/SellerApi';
import Bugsnag from '@/services/bugsnag';
import { NotifiableError } from '@bugsnag/js';

import { TFunction } from 'i18next';
import { useEffect, useState } from 'react';

export const useDirectSalesFilterSchema = (t: TFunction<[string], undefined>) => {
  const { selectedAccount } = useAccount();

  const { partnershipsApi, productsApi } = useSellerApi();

  const [arrangedProducts, setArrangedProducts] = useState<FilterOption[]>([]);
  const [loadingArrangedProducts, setLoadingArrangedProducts] = useState(true);

  const [loadingPartnershipProviders, setLoadingPartnershipProviders] = useState(true);
  const [partnershipProviders, setPartnershipProviders] = useState<FilterOption[]>([]);

  const getAppArrangedProducts = async (appId: string) => {
    setLoadingArrangedProducts(true);

    let page = 0;
    const limit = 100;
    let keepRunning = true;
    const allArrangedProducts: ArrangedProduct[] = [];

    try {
      while (keepRunning) {
        const payload: ProductsApiGetAppArrangedProductsRequest = {
          appId,
          limit: limit,
          offset: page * limit,
          associationModels: [AssociationModel.DirectPurchase],
          include: ['partnershipItem'],
        };

        const { data, headers } = await productsApi.getAppArrangedProducts(payload);

        allArrangedProducts.push(...data);
        page++;

        const totalOfProducts = Number(headers['x-pagination-total-count'] ?? '0');

        if (allArrangedProducts.length >= totalOfProducts) {
          keepRunning = false;
          break;
        }
      }

      const sortedProducts = allArrangedProducts.sort((a, b) => a.product.name!.localeCompare(b.product.name!));

      setArrangedProducts(
        sortedProducts.map((arrangedProduct) => ({
          label: arrangedProduct.product.name,
          value: arrangedProduct.product.id,
        })),
      );
    } catch (error) {
      Bugsnag.notify(error as NotifiableError);
      console.error(error);
    } finally {
      setLoadingArrangedProducts(false);
    }
  };

  const getAppPartnershipProviders = async (appId: string) => {
    const payload = {
      appId,
    };

    try {
      setLoadingPartnershipProviders(true);

      let page = 0;
      const limit = 100;
      const providers = [];
      let keepRunning = true;

      while (keepRunning) {
        const response = await partnershipsApi.getAppPartnershipProviders({
          ...payload,
          limit,
          offset: page * limit,
        });

        providers.push(...response.data);
        page++;

        const totalOfProducts = Number(response.headers['x-pagination-total-count'] ?? '0');

        if (providers.length >= totalOfProducts) {
          keepRunning = false;
          break;
        }
      }

      const sortedPartnershipProviders = providers.sort((a, b) => a.name!.localeCompare(b.name!));

      setPartnershipProviders(
        sortedPartnershipProviders.map((partnershipProvider) => ({
          label: partnershipProvider.name,
          value: partnershipProvider.id,
        })),
      );
    } catch (error) {
      Bugsnag.notify(error as NotifiableError);
      console.error(error);
    } finally {
      setLoadingPartnershipProviders(false);
    }
  };

  useEffect(() => {
    if (selectedAccount?.appId) {
      getAppArrangedProducts(selectedAccount.appId);
      getAppPartnershipProviders(selectedAccount.appId);
    }
  }, [selectedAccount?.appId]);

  return {
    currentState: {
      type: 'array',
      title: t('filter.status.title'),
      tooltip: t('filter.status.title'),
      options: [
        {
          value: SaleStateEnum.PaymentComplete,
          label: t('status.payment_complete', { ns: 'sales' }),
        },
        {
          value: SaleStateEnum.PaymentChargeback,
          label: t('status.payment_chargeback', { ns: 'sales' }),
        },
        {
          value: SaleStateEnum.Expired,
          label: t('status.expired', { ns: 'sales' }),
        },
        {
          value: SaleStateEnum.Created,
          label: t('status.created', { ns: 'sales' }),
        },
        {
          value: SaleStateEnum.Canceled,
          label: t('status.canceled', { ns: 'sales' }),
        },
      ],
    },
    since: {
      type: 'date',
      format: 'dd/MM/yyyy',
      title: t('filter.sale_date_interval.since.label'),
      tooltip: t('filter.sale_date_interval.since.label'),
    },
    until: {
      type: 'date',
      format: 'dd/MM/yyyy',
      title: t('filter.sale_date_interval.until.label'),
      tooltip: t('filter.sale_date_interval.until.label'),
    },
    appIds: {
      type: 'array',
      title: t('filter.provider_selector'),
      tooltip: t('filter.provider_selector'),
      loading: loadingPartnershipProviders,
      options: partnershipProviders,
    },
    productIds: {
      type: 'array',
      title: t('filter.product_selector'),
      loading: loadingArrangedProducts,
      options: arrangedProducts,
    },
  };
};

export default useDirectSalesFilterSchema;
