import { QueryFunctionContext } from '@tanstack/react-query';

import { useSellerApi } from '@/hooks/useSellerApi';
import { TariffGroup, TariffGroupsApiGetProductTariffGroupsRequest } from '@/services/SellerApi';
import { RequiredError } from '@/services/SellerApi/base';

type Query = [key: string, productId: string];

const useGetAllProductTariffGroups = () => {
  const { tariffGroupsApi } = useSellerApi();

  const request = async ({ queryKey }: QueryFunctionContext<Query>): Promise<TariffGroup[] | undefined> => {
    const [_, productId] = queryKey;
    const pageSize = 10;
    const payload: TariffGroupsApiGetProductTariffGroupsRequest = {
      productId,
      limit: pageSize,
      offset: 0,
      include: ['priceInfo'],
    };

    try {
      let fetchedTariffGroups: TariffGroup[] = [];
      let offset = 0;
      const limit = 100;
      let hasMore = true;

      while (hasMore) {
        const { data: products } = await tariffGroupsApi.getProductTariffGroups({
          ...payload,
          limit,
          offset,
        });

        fetchedTariffGroups = [...fetchedTariffGroups, ...products];
        offset += limit;
        hasMore = products.length === limit;
      }

      return fetchedTariffGroups;
    } catch (error) {
      console.error(error);
      return error instanceof RequiredError ? [] : undefined;
    }
  };

  return { request };
};

export default useGetAllProductTariffGroups;
