import { LabelAndPrice } from '@/features/agenda/types';
import { Availability, Product } from '@/services/SellerApi';

const getTitleAndPrice = (product: Product): LabelAndPrice | undefined => {
  return {
    id: `product-${product.id}`,
    label: `${product.internalName ?? product.name}`,
  };
};

const getAvailabilityProductsLabelPrice = (availability: Availability): LabelAndPrice[] | undefined => {
  if (availability.products) {
    const products = availability.products.map(getTitleAndPrice).filter(Boolean);

    if (products.length > 0) {
      return products as LabelAndPrice[];
    }
  }
  return undefined;
};

export default getAvailabilityProductsLabelPrice;
