import { z } from 'zod';
import { TFunction } from 'i18next';

export const schema = (t: TFunction<'sales'[], undefined>) => {
  const requiredFiledMessage = t('app_tags.modal.form.validation_error.required');

  const requiredField = z.string({ required_error: requiredFiledMessage }).min(1, { message: requiredFiledMessage });

  return z.object({ name: requiredField });
};
