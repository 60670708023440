export enum AnalyticsEvents {
  MENU_HELP_LINK_CLICK = 'menu/helpLinkClick',
  AVAILABILITY_CREATED = 'availability/create',
  AVAILABILITY_EDITED = 'availability/edit',
  AVAILABILITY_REMOVED = 'availability/remove',
  SCHEDULING_CREATED = 'scheduling/create',
  SCHEDULING_EDITED = 'scheduling/edit',
  SCHEDULING_REMOVE = 'scheduling/remove',
  SALE_GET_DETAILED_ITEMS_FAIL = 'Sale/getDetailedSaleItemsFail',
  GET_INCOME_SCHEDULINGS_FAIL = 'IncomeSchedulings/retrieveIncomeSchedulingsFail',
  GET_VOUCHERS_FAIL = 'SaleVouchers/retrieveSaleVouchersFail',
  GET_SALE_DETAIL = 'salesDetails/retrieveSaleSuccess',
  GET_SALE_DETAIL_FAIL = 'salesDetails/retrieveSaleFail',
  GET_RESERVATION_RESCHEDULE_SCHEDULINGS_FAIL = 'reschedule/getReservationRescheduleSchedulingsFail',
  SIGNUP_SUCCESS = 'auth/signupUserRequest/success',
  LOGOUT = 'LogoutButton/click',
  LOGIN_SUCCESS = 'auth/loginRequest/success',
  RESET_PASSWORD = 'auth/resetPasswordRequest/success',
  RESET_PASSWORD_CODE_VALID_STATUS = 'auth/resetPasswordCodeValidStatusRequest/success',
  RECOVER_PASSWORD = 'auth/recoverPasswordRequest/success',
  RESCHEDULE_SUCCESS = 'reschedule/success',
  RESCHEDULE_FAIL = 'reschedule/fail',
  TERMS_OF_USE_LOADING_REQUEST_SUCCESS = 'Terms/loadingRequest/success',
  PRIVACY_POLICY_LOADING_REQUEST_SUCCESS = 'PrivacyPolicy/loadingRequest/success',
  VOUCHER_CONSUME_FAIL = 'vouchers/consumeVoucherFail',
  VOUCHER_CONSUME_EXCEPTIONALLY_FAIL = 'vouchers/consumeVoucherExceptionallyFail',
  VOUCHER_CONSUME_EXCEPTIONALLY_SUCCESS = 'vouchers/consumeVoucherExceptionallySuccess',
  VOUCHER_CONSUME_SUCCESS = 'vouchers/consumeVoucherSuccess',
  COUPON_RESTORE_FAIL = 'Coupons/restoreCouponFail',
  COUPON_RESTORE_SUCCESS = 'Coupons/restoreCouponSuccess',
}
