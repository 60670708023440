import { LinkProps } from '@mui/material/Link';
import { ptBR } from '@mui/material/locale';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { backdropClasses } from '@mui/material';
import { ptBR as dataGridPtBr } from '@mui/x-data-grid';

import LinkBehavior from './components/LinkBehavior';

declare module '@mui/material/styles/createPalette' {
  export interface PaletteColor {
    100: string;
    500: string;
    600: string;
    700: string;
    900: string;
  }
}

type PaleteColor = {
  [key in keyof typeof colors]: {
    [key: number]: string;
  };
};

type CustomTypography = {
  largeBold: React.CSSProperties;
  largeSemiBold: React.CSSProperties;
  largeMedium: React.CSSProperties;

  regularBold: React.CSSProperties;
  regularSemiBold: React.CSSProperties;
  regularRegular: React.CSSProperties;
  regularMedium: React.CSSProperties;

  smallBold: React.CSSProperties;
  smallSemiBold: React.CSSProperties;
  smallRegular: React.CSSProperties;

  extraSmallBold: React.CSSProperties;
  extraSmallSemiBold: React.CSSProperties;
  extraSmallRegular: React.CSSProperties;
  extraSmallLight: React.CSSProperties;
};

// BorderRadius From DS: https://www.figma.com/design/yDePsPBoJLilnwuSvCBWaq/Uni-(v0.1)?node-id=145-724&m=dev
interface Radius {
  sm?: string;
  lg?: string;
  md?: string;
}

declare module '@mui/material/styles' {
  export interface Palette {
    colors: PaleteColor;
  }
  export interface PaletteOptions {
    colors: PaleteColor;
  }

  interface TypographyVariants extends CustomTypography {}

  interface TypographyVariantsOptions extends CustomTypography {}

  interface Theme {
    radius: Radius;
  }

  interface ThemeOptions {
    radius?: Radius;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    largeBold: true;
    largeSemiBold: true;
    largeMedium: true;

    regularBold: true;
    regularSemiBold: true;
    regularRegular: true;
    regularMedium: true;

    smallBold: true;
    smallSemiBold: true;
    smallRegular: true;

    extraSmallBold: true;
    extraSmallSemiBold: true;
    extraSmallRegular: true;
    extraSmallLight: true;
  }
}

// Create a theme color
export const colors = {
  blue: {
    50: '#EAEBFA',
    100: '#D6D7F5',
    200: '#ACAEEC',
    300: '#8386E2',
    400: '#595ED9',
    500: '#262BA6',
    600: '#1D207C',
    700: '#131552',
    800: '#131553',
    900: '#0A0B29',
  },

  gray: {
    50: '#F2F2F2',
    100: '#E6E6E6',
    200: '#CCCCCC',
    300: '#B3B3B3',
    400: '#999999',
    500: '#595959',
    600: '#666666',
    700: '#4D4D4D',
    800: '#333333',
    900: '#1A1A1A',
  },

  green: {
    50: '#E8FDF3',
    100: '#D1FAE7',
    400: '#45ED9F',
    500: '#12B76A',
    600: '#57e3a4',
    700: '#0E8B51',
  },

  white: {
    600: '#fff',
  },

  red: {
    50: '#FDE9E7',
    100: '#FBD2D0',
    500: '#F04438',
    700: '#8E130B',
  },

  yellow: {
    100: '#FDE9CE',
    500: '#F79009',
    700: '#945605',
  },
  // DS Colors
  primary: {
    main: '#262BA6',
    50: '#EAEBFA',
    100: '#D6D7F5',
    200: '#ACAEEC',
    300: '#8386E2',
    500: '#262BA6',
    600: '#1D207C',
    700: '#131552',
  },
  error: {
    50: '#FDE9E7',
    100: '#FBD2D0',
    400: '#F14D41',
    500: '#F04438',
    600: '#BE1A0E',
  },
  warning: {
    100: '#FDE9CE',
    400: '#F9A639',
    500: '#F79009',
    700: '#945605',
  },
  success: {
    100: '#D1FAE7',
    700: '#0E8B51',
  },
};

// Create a theme instance.
const theme = responsiveFontSizes(
  createTheme(
    {
      spacing: (factor: number) => `${0.25 * factor}rem`,
      palette: {
        colors,
        primary: {
          main: '#262BA6',
          500: '#262BA6',
          600: '#1D207C',
        },
        secondary: {
          main: '#001533',
          100: '#CCE1FF',
          600: '#0055CC',
          700: '#004099',
          900: '#001533',
        },
        // error: {
        //   main: '#FBD2D0',
        //   light: '#FBD2D0',
        //   contrastText: '#8E130B',
        //   dark: '#F8B7B4',
        // },
        // warning: {
        //   main: '#FDE9CE',
        //   light: '#FDE9CE',
        //   contrastText: '#945605',
        //   dark: '#FCDBB1',
        // },
        // success: {
        //   main: '#D1FAE7',
        //   light: '#D1FAE7',
        //   contrastText: '#0E8B51',
        //   dark: '#B6F7D8',
        // },
        // info: {
        //   main: '#CCE1FF',
        // },
      },
      typography: {
        fontFamily: 'lato',
        h1: {
          fontSize: '3.562rem',
          fontWeight: 700,
        },
        h2: {
          fontSize: '3.000rem',
          fontWeight: 700,
        },
        h3: {
          fontSize: '2.500rem',
          fontWeight: 700,
        },
        h4: {
          fontSize: '2.062rem',
          fontWeight: 700,
        },
        h5: {
          fontSize: '1.750rem',
          fontWeight: 700,
        },
        h6: {
          fontSize: '1.438rem',
          fontWeight: 700,
        },
        largeBold: {
          fontSize: '1.188rem',
          fontWeight: 700,
        },
        largeSemiBold: {
          fontSize: '1.188rem',
          fontWeight: 600,
        },
        largeMedium: {
          fontSize: '1.188rem',
          fontWeight: 500,
        },
        regularBold: {
          fontSize: '1.000rem',
          fontWeight: 700,
        },
        regularSemiBold: {
          fontSize: '1.000rem',
          fontWeight: 600,
        },
        regularRegular: {
          fontSize: '1.000rem',
          fontWeight: 400,
        },
        regularMedium: {
          fontSize: '1rem',
          fontWeight: 500,
        },
        smallBold: {
          fontSize: '0.812rem',
          fontWeight: 700,
        },
        smallSemiBold: {
          fontSize: '0.812rem',
          fontWeight: 600,
        },
        smallRegular: {
          fontSize: '0.812rem',
          fontWeight: 400,
        },
        extraSmallBold: {
          fontSize: '0.675rem',
          fontWeight: 700,
        },
        extraSmallSemiBold: {
          fontSize: '0.675rem',
          fontWeight: 600,
        },
        extraSmallRegular: {
          fontSize: '0.675rem',
          fontWeight: 400,
        },
        extraSmallLight: {
          fontSize: '0.675rem',
          fontWeight: 300,
        },
      },
      components: {
        MuiAppBar: {
          styleOverrides: {
            colorPrimary: {
              backgroundColor: '#262BA6',
            },
          },
        },
        MuiDrawer: {
          styleOverrides: {
            paper: {
              background: '#131553',
            },
          },
        },
        MuiModal: {
          styleOverrides: {
            root: {
              [`&:has(> div.${backdropClasses.root}[style*="opacity: 0"])`]: {
                pointerEvents: 'none',
              },
            },
          },
        },
        MuiCssBaseline: {
          styleOverrides: {
            body: {
              '@supports not selector(::-webkit-scrollbar)': {
                scrollbarColor: '#6b6b6b transparent',
              },
              '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
                backgroundColor: 'transparent',
                width: 7,
              },
              '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
                borderRadius: 8,
                backgroundColor: '#6b6b6b',
                minHeight: 24,
              },
              '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
                backgroundColor: '#959595',
              },
              '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
                backgroundColor: '#959595',
              },
              '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#959595',
              },
              '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
                backgroundColor: 'transparent',
              },
            },
          },
        },
        MuiLink: {
          defaultProps: {
            component: LinkBehavior,
          } as LinkProps,
        },
        MuiButtonBase: {
          defaultProps: {
            LinkComponent: LinkBehavior,
          },
        },
      },
      radius: {
        sm: '0.25rem',
        lg: '1rem',
        md: '0.5rem',
      },
    },
    ptBR,
    dataGridPtBr,
  ),
);

const breakpoints = theme.breakpoints.values;

type keys = keyof typeof breakpoints;
export const device = Object.keys(breakpoints).reduce(
  (obj, breakpoint) => ({ ...obj, [breakpoint]: `@media (min-width: ${breakpoints[breakpoint as keys]}px)` }),
  {},
) as typeof breakpoints;

export default theme;
