import { PublicApp } from '@/services/SellerApi';

import { Container, ProviderLogo, ProviderName } from './styles';

type Props = {
  provider: PublicApp;
};

export const ProviderInfo = (props: Props) => {
  const { provider } = props;

  return (
    <Container display='flex' alignItems='center'>
      {provider.logoUrl ? <ProviderLogo src={provider.logoUrl} /> : null}

      <ProviderName variant='extraSmallSemiBold'>{provider.name}</ProviderName>
    </Container>
  );
};

export default ProviderInfo;
