import { Sale as OldSale } from '@/services/SellerApi';
import { Sale } from '@/services/sellerApiQuery/model';

interface IsSaleNonRefundableFunction {
  /**
   * @deprecated
   * Use Sale from '@/services/sellerApiQuery/model' instead of Sale from '@/services/SellerApi'
   * */
  (sale?: OldSale): boolean;
  (sale?: Sale): boolean;
}

const isSaleNonRefundable: IsSaleNonRefundableFunction = (sale) => {
  return !!sale && sale.decisiveTransaction === null && sale.order?.decisiveTransaction === null;
};

export default isSaleNonRefundable;
