import { useLocation, useNavigate } from 'react-router-dom';

import { Toolbar, IconButton, useMediaQuery } from '@mui/material';

import useUI from '@/hooks/useUI';
import useAuth from '@/contexts/Auth/hooks/useAuth';
import useAnalytics from '@/hooks/analytics/useAnalytics';

import MenuIcon from '@mui/icons-material/Menu';

import { Content, Logo, LogoContainer, StyledMuiAppBar } from './styles';

import planneLogoDesktop from '@/assets/logos/planneLogoDesktop.svg';

import Button from '../Button';
import { useTranslation } from 'react-i18next';
import theme from '@/theme';
import { AuthStatus } from '@/interfaces/auth';

type AppBarProps = {
  showDrawerButton?: boolean;
};

export default function AppBar(props: AppBarProps) {
  const { showDrawerButton = true } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation(['appBar']);

  const { status } = useAuth();
  const { toggleDrawer, drawerWidth } = useUI();
  const { track } = useAnalytics();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerToggle = () => {
    track('appBar/drawerToggleButton/click');

    toggleDrawer();
  };

  return (
    <StyledMuiAppBar
      position='fixed'
      color='secondary'
      drawerWidth={drawerWidth}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar sx={{ height: 76 }}>
        {showDrawerButton && isMobile ? (
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={() => handleDrawerToggle()}
            sx={{ mr: 2, display: 'block' }}
          >
            <MenuIcon />
          </IconButton>
        ) : null}

        <LogoContainer href='/'>
          <Logo src={planneLogoDesktop} alt='Planne Logo' width={35} height={35} />
        </LogoContainer>

        <Content />

        {status !== AuthStatus.AUTHENTICATED ? (
          <>
            <Button
              variant='contained'
              color='primary'
              sx={{ mr: 1 }}
              onClick={() => navigate('/login', { state: { redirectTo: location } })}
            >
              {t('login_button')}
            </Button>

            <Button
              variant='outlined'
              color='inherit'
              onClick={() => navigate('/signup', { state: { redirectTo: location } })}
            >
              {t('signup_button')}
            </Button>
          </>
        ) : null}
      </Toolbar>
    </StyledMuiAppBar>
  );
}
