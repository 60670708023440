import { Box, Grid, styled } from '@mui/material';

export const SideContentWrapper = styled(Grid)(({ theme }) => ({
  borderLeft: `2px solid ${theme.palette.grey[200]}`,

  [theme.breakpoints.down('sm')]: {
    borderLeft: 'none',
  },
}));

export const SideContent = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: '1.5rem 1rem 1rem',
  display: 'flex',

  [theme.breakpoints.down('md')]: {
    padding: '2rem 0.5rem 0.5rem',
  },
}));

export const Container = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginBottom: '64px',
  },
}));
