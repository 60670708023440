import { QueryFunctionContext } from '@tanstack/react-query';

import createPaginatedRequest from '@/features/agenda/utils/paginatedRequest';
import { useSellerApi } from '@/hooks/useSellerApi';
import { AvailabilitiesApiGetAppAvailabilitiesRequest, Availability, AvailabilityTypeEnum } from '@/services/SellerApi';
import { RequiredError } from '@/services/SellerApi/base';
import removeDuplicates from '@/utils/removeDuplicates';

type Query = [
  key: string,
  appId: string | null,
  since: string,
  until: string,
  productIds?: string[],
  requestDelay?: number,
  types?: AvailabilityTypeEnum[],
];

const useGetAllAvailabilities = () => {
  const { availabilitiesApi } = useSellerApi();

  const request = async ({ queryKey, signal }: QueryFunctionContext<Query>): Promise<Availability[] | undefined> => {
    const [_, appId, since, until, productIds, requestDelay, types] = queryKey;
    const isMissingValues = !since || !until || !appId;
    if (isMissingValues) {
      return;
    }

    try {
      const allAvailabilities = await createPaginatedRequest<
        Availability,
        AvailabilitiesApiGetAppAvailabilitiesRequest
      >({
        apiMethod: (payload, options) => availabilitiesApi.getAppAvailabilities(payload, options),
        initialPayload: {
          appId,
          since,
          until,
          include: [
            'tariffs.type',
            'tariffs.product',
            'additionals',
            'additionals.product',
            'productTariffTypes.tariffType',
            'products',
          ],
          ...(productIds && productIds.length > 0 ? { affectedProductIds: productIds } : {}),
          ...(types && types.length > 0 ? { types } : {}),
        },
        pageSize: 10,
        requestDelay,
        signal,
      });

      return allAvailabilities ? removeDuplicates<Availability>(allAvailabilities, 'id') : undefined;
    } catch (error) {
      console.error(error);
      return error instanceof RequiredError ? [] : undefined;
    }
  };

  return { request };
};

export default useGetAllAvailabilities;
