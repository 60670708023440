import { useMutation, useQueryClient } from '@tanstack/react-query';

import useCreateUpdateScheduling from '@/features/agenda/features/schedulingForm/usecases/useCreateUpdateScheduling';

const useProductSchedulingMutation = () => {
  const { request: fetchSchedulingAction } = useCreateUpdateScheduling();
  const queryClient = useQueryClient();

  const useRequestMutation = () => {
    return useMutation({
      mutationFn: fetchSchedulingAction,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['aggregatedSchedulings'] });
        queryClient.invalidateQueries({ queryKey: ['schedulings'] });
        queryClient.invalidateQueries({ queryKey: ['relatedAvailabilities'] });
      },
    });
  };

  return [useRequestMutation];
};

export default useProductSchedulingMutation;
