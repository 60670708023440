import { IconButton, Link } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LogoContainer = styled(Link, {
  shouldForwardProp: () => true,
})(() => ({
  width: '100%',
  maxWidth: '194px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '16px',
}));

export const Logo = styled('img', {
  shouldForwardProp: () => true,
})(() => ({
  width: '100%',
  height: '100%',
}));

export const IconMenuContainer = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'onlyShowIcon',
})<{ onlyShowIcon: boolean }>(({ theme, onlyShowIcon }) => ({
  display: 'flex',
  justifyContent: 'center',

  ...(onlyShowIcon
    ? {
        width: '100%',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }
    : {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: 'auto',
      }),
}));
