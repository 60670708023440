import { Fragment } from 'react';
import { Grid, List } from '@mui/material';

import { StyledListItem } from './styles';
import CartItemCard from './CartItemCard';
import { CartItemWithId } from '@/contexts/PartnershipPortalCartContext';

type Props = {
  items: CartItemWithId[];
};
export const ListCartItems = (props: Props) => {
  const { items } = props;

  return (
    <Fragment>
      <Grid item xs={12}>
        <List sx={{ width: '100%' }}>
          {items.map((item) => (
            <StyledListItem key={item.id}>
              <CartItemCard cartItem={item} />
            </StyledListItem>
          ))}
        </List>
      </Grid>
    </Fragment>
  );
};

export default ListCartItems;
