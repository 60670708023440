import Button from '@/components/Button';
import theme from '@/theme';
import { Tooltip, useMediaQuery } from '@mui/material';
import { IconEye, IconEyeOff } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

interface Props {
  shouldShowRevoked: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const RevokedVoucherButton = (props: Props) => {
  const { shouldShowRevoked, onClick } = props;

  const { t } = useTranslation(['vouchers']);
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const iconSize = isTablet ? 16 : 24;
  const tooltipWidth = isTablet ? 110 : 140;

  return (
    <Tooltip
      arrow
      placement='top'
      enterTouchDelay={0}
      leaveTouchDelay={60000}
      title={t(`table.revoked.${shouldShowRevoked ? 'hide' : 'show'}`)}
      PopperProps={{ style: { zIndex: 99999, width: tooltipWidth, textAlign: 'center' } }}
    >
      <Button variant='outlined' onClick={onClick} sx={{ padding: '10px !important' }}>
        {shouldShowRevoked ? <IconEye size={iconSize} /> : <IconEyeOff size={iconSize} />}
      </Button>
    </Tooltip>
  );
};

export default RevokedVoucherButton;
