import ErrorComponentBody from './ErrorComponentBody';
import ErrorComponentHeader from './ErrorComponentHeader';
import ErrorComponentRoot from './ErrorComponentRoot';

const ErrorComponent = {
  Root: ErrorComponentRoot,
  Body: ErrorComponentBody,
  Header: ErrorComponentHeader,
};

export default ErrorComponent;
