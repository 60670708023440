import { Popover as BasePopover, styled } from '@mui/material';

export const Popover = styled(BasePopover, { shouldForwardProp: (prop) => prop !== 'hasPinedPopover' })<{
  hasPinedPopover: boolean;
}>(({ theme, hasPinedPopover }) => ({
  '& .MuiPaper-root': { width: 256 },
  pointerEvents: 'none',
  '& .MuiPopover-paper': {
    pointerEvents: 'auto',
  },
  '& .MuiBackdrop-root': {
    pointerEvents: hasPinedPopover ? 'auto' : 'none',
    left: hasPinedPopover ? theme.spacing(9) : 0,
  },
}));
