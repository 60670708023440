import { Container, Typography } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';

import Button from '@/components/Button';
import { IconWrapper, ModalWrapper } from './styles';

type Props = {
  onClose: () => void;
  description?: string;
  buttonText: string;
};

export const SuccessModalBody = (props: Props) => {
  const { onClose, buttonText, description } = props;

  return (
    <Container>
      <ModalWrapper>
        <IconWrapper>
          <CheckCircleOutline fontSize='large' />
        </IconWrapper>

        {description ? (
          <Typography color='colors.gray.700' sx={{ textAlign: 'center', width: '100%' }} variant='largeMedium'>
            {description}
          </Typography>
        ) : null}

        <Button type='button' variant='contained' onClick={onClose}>
          {buttonText}
        </Button>
      </ModalWrapper>
    </Container>
  );
};

export default SuccessModalBody;
