import { TFunction } from 'i18next';
import { ViewType } from '@/layouts/PartnershipLayout';
import {
  PartnershipStatus,
  PartnershipProposal,
  PartnershipsApiGetAppPartnershipProposalsRequest,
} from '@/services/SellerApi';
import useAccount from '@/hooks/useAccount';
import { useSellerApi } from '@/hooks/useSellerApi';
import { useEffect, useState } from 'react';
import { FilterOption } from '@/contexts/FilterContext';
import Bugsnag from '@/services/bugsnag';
import { NotifiableError } from '@bugsnag/js';

export const usePartnershipFilterSchema = (t: TFunction<[string], undefined>, viewType: ViewType) => {
  const { selectedAccount } = useAccount();
  const { partnershipsApi } = useSellerApi();

  const [loadingPartnershipProposal, setLoadingPartnershipProposal] = useState(true);
  const [partnershipProposals, setPartnershipProposals] = useState<FilterOption[]>([]);

  const getAppPartnershipProposals = async (appId: string) => {
    setLoadingPartnershipProposal(true);

    let page = 0;
    const limit = 100;
    let keepRunning = true;
    const allProducts: PartnershipProposal[] = [];

    try {
      while (keepRunning) {
        const payload: PartnershipsApiGetAppPartnershipProposalsRequest = {
          appId,
          limit: limit,
          offset: page * limit,
        };

        const { data, headers } = await partnershipsApi.getAppPartnershipProposals(payload);

        allProducts.push(...data);
        page++;

        const totalOfProducts = Number(headers['x-pagination-total-count'] ?? '0');

        if (allProducts.length >= totalOfProducts) {
          keepRunning = false;
          break;
        }
      }

      const sortedPartnershipProposal = allProducts.sort((a, b) => a.name!.localeCompare(b.name!));

      setPartnershipProposals(
        sortedPartnershipProposal.map((partnershipProposal) => ({
          label: partnershipProposal.name!,
          value: partnershipProposal.id,
        })),
      );
    } catch (error) {
      Bugsnag.notify(error as NotifiableError);
    } finally {
      setLoadingPartnershipProposal(false);
    }
  };

  const isProvider = viewType === 'provider';

  useEffect(() => {
    if (isProvider && selectedAccount?.appId) {
      getAppPartnershipProposals(selectedAccount.appId);
    }
  }, [selectedAccount?.appId, isProvider]);

  return {
    currentStatus: {
      type: 'array',
      title: t('filters.status.title'),
      tooltip: t('filters.status.title'),
      options: [
        { label: t('filters.status.pending'), value: PartnershipStatus.Pending },
        { label: t('filters.status.ongoing'), value: PartnershipStatus.Ongoing },
        { label: t('filters.status.declined'), value: PartnershipStatus.Declined },
      ],
    },
    programIds: {
      type: 'array',
      title: t('filters.program.title'),
      loading: loadingPartnershipProposal,
      options: partnershipProposals,
    },
  };
};

export default usePartnershipFilterSchema;
