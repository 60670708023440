import theme from '@/theme';
import { Box, styled } from '@mui/material';
import { ValueWithLabelProps } from '@/components/ValueWithLabel';

export const slotProps: ValueWithLabelProps['slotProps'] = {
  container: {
    gap: 0.5,
    flexDirection: 'row',
  },
  title: {
    variant: 'regularSemiBold',
    color: theme.palette.colors.gray[800],
  },
  value: {
    variant: 'regularRegular',
    color: theme.palette.colors.gray[800],
  },
};

export const FlexRow = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
}));

export const Container = styled(Box)(() => ({
  gap: '16px',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  padding: '16px 0px',
  marginBottom: '24px',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
}));

export const LeftContent = styled(Box)(() => ({
  gap: '4px',
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
}));

export const RightContent = styled(Box)(() => ({
  gap: '6px',
  width: '50%',
  display: 'flex',
  marginTop: 0,
  flexDirection: 'column',
  alignItems: 'flex-end',

  [theme.breakpoints.down('md')]: {
    marginTop: 1,
    alignItems: 'flex-start',
  },
}));

export const TagsContainer = styled(FlexRow)(() => ({
  gap: '8px',
  flexShrink: 0,
  flexWrap: 'wrap',
}));

export const StatusWrapper = styled(FlexRow)(() => ({
  gap: '8px',
  marginBottom: '8px',
}));

export const SaleChannal = styled(FlexRow)(({ theme }) => ({
  gap: '4px',
  color: theme.palette.colors.gray[700],
  padding: '2px 8px',
  borderRadius: '20px',
  backgroundColor: theme.palette.colors.gray[100],
}));

export const CouponTitle = styled(FlexRow)(() => ({}));

export const Utms = styled(Box)(() => ({
  display: 'flex',
  gap: '4px',
  flexWrap: 'wrap',
  width: '100%',
}));

export const Flag = styled('img')(() => ({
  width: '24px',
  marginLeft: '8px',
}));
