import { zodResolver } from '@hookform/resolvers/zod';
import { Grid, Link, Typography } from '@mui/material';
import { isAxiosError } from 'axios';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import Button from '@/components/Button';
import { signupSteps } from '@/components/CreateApp/signupSteps';
import Checkbox from '@/components/Form/Checkbox';
import Input from '@/components/Form/Input';
import PasswordInput from '@/components/Form/PasswordInput';
import { fields, schema } from '@/components/Signup/schema';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import useAuth from '@/contexts/Auth/hooks/useAuth';
import useErrorHandler from '@/hooks/useErrorHandler';
import { ErrorTypes } from '@/interfaces/errorTypes';
import RegisterLayout from '@/layouts/RegisterLayout';
import { SignUpParams } from '@/services/SellerApi';

export type FormFields = SignUpParams & {
  confirmEmail: string;
  confirmPassword: string;
};

type Path = keyof typeof fields;

const SignupForm = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation(['signup']);
  const { t: translateGenericError } = useTranslation(['errors']);

  const { signup } = useAuth();
  const { track } = useAnalytics();
  const { errorHandler } = useErrorHandler();

  const { register, handleSubmit, formState, setError, clearErrors } = useForm<FormFields>({
    mode: 'onSubmit',
    resolver: zodResolver(schema),
  });
  const { errors, isSubmitting } = formState;

  const onSubmit = async (data: SignUpParams) => {
    try {
      const { name, email, password, acceptTerms } = data;
      await signup({ name, email, password, acceptTerms });

      navigate('/app/create', { state: { ...location.state } });
    } catch (err) {
      const pagePath = 'signupPage/signupRequest/error';
      const { errors, status, statusText } = errorHandler(err, pagePath);

      if (errors) {
        errors.forEach(({ path, type, rule }) => {
          if (type === ErrorTypes.VALIDATION) {
            let message = t(rule);
            let field = path;

            if (path === 'email' && rule === 'unique') {
              field = 'root.serverError';
              message = 'email_already_registered';
            }

            setError(field as Path, { message });
          } else {
            setError('root.serverError', { message: rule });
          }
        });
        track(pagePath, {
          message: errors.map(({ rule }) => translateGenericError(rule)).join(', '),
          status,
          statusText,
          error: isAxiosError(err) ? err.response?.data : err,
        });

        return;
      }

      setError('root.serverError', { message: 'generic_default_error' });
    }
  };

  const handleGoBack = useCallback(() => {
    navigate('/login', { state: { ...location.state } });
  }, []);

  return (
    <RegisterLayout
      currentStep={0}
      onGoBack={handleGoBack}
      stepTitle={t(`${signupSteps[0].title}`)}
      steps={signupSteps.map((_, index) => index)}
      error={
        errors.root?.serverError?.message ? (
          <Trans
            i18nKey={translateGenericError(errors.root.serverError.message)}
            components={{
              anchor: <Link href='/login' />,
            }}
          />
        ) : null
      }
      loading={isSubmitting}
      title={t('user_signup_page_description')}
      onCloseAlert={() => clearErrors('root.serverError')}
    >
      <Grid container component='form' onSubmit={handleSubmit(onSubmit)} rowSpacing={2}>
        <Grid item xs={12}>
          <Input
            fullWidth
            id='name'
            label={t('signup_name_field')}
            required
            tooltipProps={{
              placement: 'right-start',
              title: t('signup_field_name_tooltip_description'),
            }}
            error={!!errors['name']}
            helperText={errors['name']?.message as string}
            {...register('name')}
          />
        </Grid>

        <Grid item xs={12}>
          <Input
            fullWidth
            id='email'
            required
            type='email'
            label={t('signup_email_field')}
            autoComplete='email'
            error={!!errors['email']}
            helperText={errors['email']?.message as string}
            {...register('email')}
          />
        </Grid>

        <Grid item xs={12}>
          <Input
            fullWidth
            id='confirmEmail'
            required
            label={t('signup_confirm_email_field')}
            autoComplete='email'
            onPaste={(event) => (process.env.APP_ENV !== 'development' ? event.preventDefault() : null)}
            error={!!errors['confirmEmail']}
            helperText={errors['confirmEmail']?.message as string}
            {...register('confirmEmail')}
          />
        </Grid>

        <Grid item xs={12}>
          <PasswordInput
            fullWidth
            id='password'
            required
            error={!!errors['password']}
            label={t('signup_password_field')}
            helperText={errors['password']?.message ?? t('signup_password_helper_text')}
            {...register('password')}
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordInput
            fullWidth
            required
            id='confirmPassword'
            error={!!errors['confirmPassword']}
            label={t('signup_confirm_password_field')}
            helperText={errors['confirmPassword']?.message as string}
            {...register('confirmPassword')}
          />
        </Grid>

        <Grid item marginY={{ xs: 0, md: 2 }} xs={12}>
          <Checkbox
            color='primary'
            {...register('acceptTerms')}
            error={!!errors['acceptTerms']}
            helperText={errors['acceptTerms']?.message as string}
            label={
              <Typography variant='smallRegular' sx={{ whiteSpace: 'normal' }}>
                {t('signup_terms_and_policy_prefix')} <Link href='terms-of-use'>{t('signup_terms')}</Link>
                {t('signup_terms_and_policy_conjuction')}
                <Link href='privacy-policy'>{t('signup_policy')}</Link>
              </Typography>
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Button type='submit' fullWidth variant='contained'>
            {t('signup_personal_step_submit_button')}
          </Button>
        </Grid>

        <Outlet />
      </Grid>
    </RegisterLayout>
  );
};

export default SignupForm;
