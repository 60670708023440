import { Grid } from '@mui/material';
import { styled } from '@mui/system';

import { StyledCalendarItemBase } from '@/features/agenda/components/DateAndActions/MonthSelector/SelectMonthOrWeekModal/styles';
import { colors } from '@/theme';

export const StyledCalendarGrid = styled(Grid)(() => ({
  maxWidth: '24rem',
}));

export const StyledCalendarMonthItem = styled(StyledCalendarItemBase)(({ theme, className }) => ({
  alignItems: 'flex-end',
  backgroundColor: className?.includes('selected') ? colors.gray['50'] : theme.palette.background.paper,

  [`& svg`]: {
    maxWidth: '100%',
  },
}));
