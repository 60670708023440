import { Stack } from '@mui/system';
import { IconRefresh } from '@tabler/icons-react';
import { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/Button';
import CustomRecurrenceModal from '@/features/agenda/components/RecurrenceModal';
import recurrenceToString from '@/features/agenda/features/schedulingForm/utils/recurrenceToString';
import { Recurrence } from '@/services/SellerApi';
import theme from '@/theme';

type Props = {
  recurrence?: Recurrence;
  onRecurrenceChange: (recurrence: Recurrence) => void;
  disabled?: boolean;
};

const RecurrenceButton = (props: Props) => {
  const { recurrence, onRecurrenceChange, disabled } = props;
  const [isRecurrenceModalOpen, setIsRecurrenceModalOpen] = useState(false);
  const { t } = useTranslation(['agenda']);

  const recurrenceLabel = useMemo(() => {
    return recurrenceToString(recurrence, t);
  }, [recurrence]);

  const isNeverRecurrence = useMemo(() => {
    return Object.keys(recurrence || {}).length === 0;
  }, [recurrence]);

  const handleRefreshRecurrence = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    onRecurrenceChange({} as Recurrence);
  };

  return (
    <Fragment>
      <Stack sx={{ justifyContent: 'end', height: '100%', alignItems: 'flex-start' }}>
        <Button
          disabled={disabled}
          onClick={() => setIsRecurrenceModalOpen(true)}
          sx={{ background: theme.palette.grey[50], gap: theme.spacing(3) }}
        >
          {t('recurrence.repeat')}: {recurrenceLabel}{' '}
          {!isNeverRecurrence ? <IconRefresh onClick={handleRefreshRecurrence} /> : null}
        </Button>
      </Stack>
      <CustomRecurrenceModal
        isOpen={isRecurrenceModalOpen}
        currentRecurrence={recurrence}
        onClose={() => setIsRecurrenceModalOpen(false)}
        onRecurrenceChange={onRecurrenceChange}
      />
    </Fragment>
  );
};

export default RecurrenceButton;
