import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import Signup from '@/components/Signup';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import { useTranslation } from 'react-i18next';

const SignupPage = () => {
  const { t } = useTranslation(['signup']);

  const { track } = useAnalytics();

  useEffect(() => {
    track('signupUserPage/access');
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('signup_page_title')}</title>
      </Helmet>

      <Signup />
    </>
  );
};

export default SignupPage;
