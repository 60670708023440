import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, Stack, Table, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import theme from '@/theme';
import { formatCents } from '@/utils';
import { DetailedSaleItem, Sale } from '@/services/SellerApi';
import { SaleInfo, StyledTableCell, TableContainer, TableFooter, TotalValueAndInstallments } from './style';
import { ReceiptPriceSummary } from '@/features/sales/pages/Receipt';
import { TableBody } from '@/features/sales/pages/Receipt/style';
import { format } from 'date-fns';
import CellsMounter, { CellText } from '../CellsMounter';

interface Props {
  sale: Sale;
  saleItems: DetailedSaleItem[];
  priceSummary: ReceiptPriceSummary[];
}

export const ReceiptMobileView = (props: Props) => {
  const { sale, saleItems, priceSummary } = props;

  const { t } = useTranslation(['receipt']);

  const tableHeads = [
    t('table.head.mobile.description'),
    t('table.head.mobile.type'),
    t('table.head.mobile.quantity'),
    t('table.head.mobile.unit_value'),
    t('table.head.mobile.subtotal'),
  ];

  const shouldRenderTable = saleItems.length;

  return (
    <Fragment>
      {shouldRenderTable ? (
        <TableContainer component={Paper}>
          {saleItems.map((saleItem) => (
            <Stack spacing={1} key={saleItem.id}>
              <Typography variant='regularBold' paddingLeft={1}>
                {saleItem.product?.internalName ?? saleItem.product?.name}
              </Typography>

              <Table>
                <TableHead sx={{ background: theme.palette.colors.gray[50] }}>
                  <TableRow>
                    {tableHeads.map((head) => (
                      <TableCell padding='none' align='left' key={head} sx={{ py: 0.5, paddingLeft: 1 }}>
                        <CellText p={0}>{head}</CellText>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow key={saleItem.id} sx={{ verticalAlign: 'baseline' }}>
                    <StyledTableCell padding='none' align='left' aria-label='description'>
                      <CellsMounter.Description saleItem={saleItem} cell={{ width: 60 }} />
                    </StyledTableCell>

                    <StyledTableCell padding='none' align='left' aria-label='type'>
                      <CellsMounter.Type saleItem={saleItem} />
                    </StyledTableCell>

                    <StyledTableCell padding='none' align='left' aria-label='quantity'>
                      <CellsMounter.Quantity saleItem={saleItem} />
                    </StyledTableCell>

                    <StyledTableCell padding='none' align='left' aria-label='unit value'>
                      <CellsMounter.UnityValue saleItem={saleItem} />
                    </StyledTableCell>

                    <StyledTableCell padding='none' align='left' aria-label='subtotal'>
                      <CellsMounter.Subtotal saleItem={saleItem} />
                    </StyledTableCell>
                  </TableRow>

                  <TableRow>
                    <StyledTableCell align='left' colSpan={3} sx={{ pb: 0 }}>
                      <CellText variant='smallBold'>{t('table.head.mobile.subtotal')}</CellText>
                    </StyledTableCell>

                    <StyledTableCell align='right' colSpan={2} sx={{ pb: 0 }}>
                      <CellText variant='smallBold'>{formatCents(saleItem.priceCents)}</CellText>
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Stack>
          ))}

          <Table>
            <TableBody>
              {priceSummary.map(({ title, value }) => (
                <TableRow key={title?.toString()} sx={{ verticalAlign: 'baseline' }}>
                  <TableCell align='right' colSpan={4} padding='none' sx={{ py: 0.5 }}>
                    <CellText variant='smallSemiBold' p={0}>
                      {title}
                    </CellText>
                  </TableCell>

                  <TableCell align='right' padding='none' sx={{ paddingRight: 2, py: 0.5 }}>
                    <CellText variant='smallSemiBold' p={0} sx={{ whiteSpace: 'nowrap' }}>
                      {value}
                    </CellText>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}

      <TableFooter component={Paper} sx={{ marginTop: shouldRenderTable ? '0px !important' : '16px !important' }}>
        <TotalValueAndInstallments>
          <Typography variant='regularBold' textAlign='right'>
            {t('table.footer.total') + ' ' + formatCents(sale.amountCents)}
          </Typography>

          {sale.decisiveTransaction?.installments ? (
            <Typography variant='smallSemiBold'>
              {t('table.footer.installments', { installments: sale.decisiveTransaction?.installments }) +
                ' ' +
                formatCents(sale.amountCents / sale.decisiveTransaction?.installments)}
            </Typography>
          ) : null}
        </TotalValueAndInstallments>

        <SaleInfo>
          <Typography variant='largeBold' textAlign='right'>
            {t('table.footer.position', { position: sale.position })}
          </Typography>

          <Typography variant='smallSemiBold'>
            {t('table.footer.date', { date: format(new Date(sale.createdAt), 'dd/MM/yyyy - HH:mm') })}
          </Typography>
        </SaleInfo>
      </TableFooter>
    </Fragment>
  );
};

export default ReceiptMobileView;
