import { AxiosResponse } from 'axios';

type BasePayload = {
  limit?: number;
  offset?: number;
};

type QueryBase = [key: string, pageParam: number];

type Params<P extends BasePayload, T, Q extends QueryBase> = {
  apiMethod: (payload: P, options?: { signal: AbortSignal }) => Promise<AxiosResponse<T[]>>;
  initialPayload?: Partial<P>;
  pageSize: number;
  queryKey: Q;
  signal: AbortSignal;
};

interface PageResponse<T> {
  nextCursor: number | null;
  items: T[];
  total?: number;
}

const createPageRequest = async <T, P extends BasePayload, Q extends QueryBase>(
  params: Params<P, T, Q>,
): Promise<PageResponse<T>> => {
  const { apiMethod, initialPayload, pageSize, queryKey, signal } = params;
  const [_, pageParam] = queryKey;

  const payload: P = {
    ...initialPayload,
    limit: pageSize,
    offset: pageParam,
  } as P;

  try {
    const { data, headers } = await apiMethod(payload, { signal });
    const totalCount = parseInt(headers['x-pagination-total-count'] ?? '0');
    const nextCursor = pageParam + pageSize < totalCount ? pageParam + pageSize : null;

    return {
      nextCursor,
      items: data,
      total: totalCount,
    };
  } catch (error) {
    console.error(error);
    return { nextCursor: null, items: [] };
  }
};

export default createPageRequest;
