import { ArrowBack } from '@mui/icons-material';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { ReactNode } from 'react';

import PlanneLogoMobile from '@/assets/logos/planneLogoMobile.svg';
import Alert from '@/components/Alert';
import Loading from '@/components/BackdropLoading';
import Button from '@/components/Button';
import Stepper from '@/components/Stepper';

import { Container } from './styles';
import ErrorBoundary from '@/components/Error/ErrorBoundary';
import ErrorTechnical from '@/components/Error/ErrorTechnical';

type Props = {
  title?: string;
  error?: ReactNode;
  steps?: number[];
  stepTitle: string;
  loading?: boolean;
  currentStep?: number;
  children: ReactNode;
  onGoBack: () => void;
  onCloseAlert?: () => void;
  breakpoints?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
};

const RegisterLayout = (props: Props) => {
  const {
    children,
    currentStep,
    steps,
    stepTitle,
    onGoBack,
    error,
    loading,
    onCloseAlert,
    title,
    breakpoints = {
      xs: 12,
      sm: 10,
      md: 8,
      lg: 5,
    },
  } = props;

  const { xs, sm, md, lg, xl } = breakpoints;

  return (
    <ErrorBoundary FallbackComponent={ErrorTechnical}>
      <Container>
        <Grid
          container
          alignItems='center'
          justifyContent='center'
          rowSpacing={{
            xs: 2,
            sm: 4,
            md: 5,
          }}
          sx={{ position: 'relative' }}
        >
          <Grid item xs={12}>
            <Grid container alignItems='center' justifyContent='center' rowSpacing={2}>
              <Grid item>
                <img src={PlanneLogoMobile} alt='Planne logo' width={241} height={52} />
              </Grid>

              {title ? (
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <Typography color='colors.gray.500' align='center' variant='largeBold'>
                    {title}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </Grid>

          <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
            <Paper elevation={2} sx={{ position: 'relative', paddingTop: 0.1 }}>
              <Grid
                container
                rowSpacing={{
                  xs: 1,
                  sm: 2,
                  md: 3,
                }}
                padding={{
                  xs: 2,
                  sm: 2,
                  md: 3,
                }}
              >
                {steps ? (
                  <Grid item xs={12}>
                    <Stepper steps={steps} activeStep={currentStep} />
                  </Grid>
                ) : null}

                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button sx={{ p: '0.875rem 0.685rem', marginRight: '1rem' }} variant='contained' onClick={onGoBack}>
                      <ArrowBack sx={{ fontSize: '1rem' }} />
                    </Button>
                    <Typography variant='h5' color='colors.gray.900'>
                      {stepTitle}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Grid container rowSpacing={2}>
                    {error ? (
                      <Grid item xs={12}>
                        <Alert severity='error' message={error} onClose={onCloseAlert} />
                      </Grid>
                    ) : null}

                    <Grid item xs={12}>
                      {children}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {loading ? <Loading /> : null}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </ErrorBoundary>
  );
};

export default RegisterLayout;
