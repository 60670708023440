import { Checkbox as MUICheckbox, CheckboxProps, FormControl, Stack, TooltipProps } from '@mui/material';
import { forwardRef, ReactNode, Ref } from 'react';

import TooltipInputElement from '@/components/InputTooltip/TooltipInputElement';

import { HelperText, StyledCheckbox } from './styles';

type Props = Omit<CheckboxProps, 'name'> & {
  label?: ReactNode;
  name?: string;
  error?: boolean;
  helperText?: string;
  tooltipProps?: Omit<TooltipProps, 'children' | 'title'> & { title?: ReactNode };
};

const Checkbox = forwardRef((props: Props, ref: Ref<unknown>) => {
  const { label, helperText, error, tooltipProps, ...rest } = props;

  return (
    <FormControl>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
        <StyledCheckbox ref={ref} control={<MUICheckbox {...rest} />} label={label} />
        {tooltipProps ? <TooltipInputElement {...tooltipProps}></TooltipInputElement> : null}
      </Stack>
      {helperText ? <HelperText error={error}>{helperText}</HelperText> : null}
    </FormControl>
  );
});

export default Checkbox;
