import { z } from 'zod';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import { BANK_ACCOUNT_REGEX, BANK_AGENCY_REGEX } from '@/utils/regex';
import { BankAccountType } from '@/services/SellerApi';

const requiredField = z.string().nonempty({ message: 'Campo obrigatório' });

export const schema = z.object({
  bankCode: z
    .object({
      code: requiredField,
      shortName: requiredField,
      longName: requiredField,
    })
    .refine((value) => !!value?.code, {
      message: 'Campo obrigatório',
    }),
  bankAgency: requiredField.regex(BANK_AGENCY_REGEX, {
    message: 'Agência inválida.',
  }),
  bankAccount: requiredField.regex(BANK_ACCOUNT_REGEX, {
    message: 'Número de conta inválida.',
  }),
  bankIdentifier: requiredField.refine((value) => cpf.isValid(value) || cnpj.isValid(value), {
    message: 'CPF ou CNPJ inválido',
  }),
  bankAccountType: z.enum([BankAccountType.ContaCorrente, BankAccountType.Poupanca], {
    message: 'Tipo de conta inválido',
  }),
  expectedMonthlyRevenueCents: z.string({ message: 'Campo obrigatório' }).superRefine((value, ctx) => {
    const unmaskedValue = value.replace(/\D/g, '');

    if (!unmaskedValue) {
      return ctx.addIssue({
        message: 'Campo obrigatório',
        code: 'custom',
      });
    }

    const numberValue = Number(unmaskedValue);

    if (numberValue < 0)
      return ctx.addIssue({
        message: 'Valor inválido',
        code: 'custom',
      });
  }),
});
