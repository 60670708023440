import { Box, CircularProgress, CircularProgressProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = CircularProgressProps & {
  justify?: 'flex-start' | 'center' | 'flex-end';
  align?: 'flex-start' | 'center' | 'flex-end';
  fullWidth?: boolean;
  showLabel?: boolean;
};

export const Loading = (props: Props) => {
  const { justify = 'center', align = 'center', fullWidth = true, showLabel = false, ...rest } = props;
  const { t } = useTranslation(['ui']);

  return (
    <Box
      display='flex'
      flexDirection={showLabel ? 'column' : 'row'}
      width={fullWidth ? '100%' : 'fit-content'}
      justifyContent={justify}
      alignItems={align}
    >
      <CircularProgress {...rest} />
      {showLabel ? (
        <Typography variant='smallRegular' data-testid='loading-label'>
          {t('loading')}
        </Typography>
      ) : null}
    </Box>
  );
};

export default Loading;
