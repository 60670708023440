import { styled } from '@mui/material/styles';
import { Box, Button as MuiButton, Typography } from '@mui/material';
import AppLogo from '@/components/AppLogo';
import { colors } from '@/theme';

export const Button = styled(MuiButton)(({ theme }) => ({
  color: theme.palette.colors.white[600],
  backgroundColor: theme.palette.secondary[900],
  textTransform: 'none',
  fontWeight: 500,
  justifyContent: 'flex-start',
  padding: '16px',
  display: 'flex',
  gap: '8px',
  flexDirection: 'column',
  alignItems: 'flex-start',

  '&:hover': {
    backgroundColor: theme.palette.secondary[900],
  },

  borderTop: `1px solid ${colors.blue[400]}`,
  borderBottom: `1px solid ${colors.blue[400]}`,
  borderRadius: 0,

  [theme.breakpoints.down('sm')]: {
    marginTop: '12px',
  },
}));

export const AppLogoContainer = styled(Box)(() => ({
  width: '40px',
  display: 'flex',
}));

export const StyledAppLogo = styled(AppLogo)(({ theme }) => ({
  color: theme.palette.colors.white[600],
  width: '100%',
  objectFit: 'cover',
}));

export const Name = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.white[600],
  flexGrow: 1,
  textAlign: 'left',
  flex: 1,
  whiteSpace: 'break-spaces',
}));

export const ButtonContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  width: '100%',

  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
  },
}));
