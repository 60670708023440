const normalizeArray = <T>(array?: unknown): T[] => {
  if (array === null || array === undefined) {
    return [];
  }

  if (Array.isArray(array)) {
    return array as T[];
  }

  if (array) {
    return [array as T];
  }

  return [];
};

export default normalizeArray;
