import { Fragment } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { Paper, Stack, Table, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import theme from '@/theme';
import { formatCents } from '@/utils';
import { TableBody } from '@/features/sales/pages/Receipt/style';
import CellsMounter, { CellText } from '../CellsMounter';
import { ReceiptPriceSummary } from '@/features/sales/pages/Receipt';
import { DetailedSaleItem, Sale } from '@/services/SellerApi';
import { PriceSummaryTableCellText, StackBetween, StackFlexEnd, TableFooter } from './style';

interface Props {
  sale: Sale;
  saleItems: DetailedSaleItem[];
  priceSummary: ReceiptPriceSummary[];
}

export const ReceiptDesktopView = (props: Props) => {
  const { sale, saleItems, priceSummary } = props;

  const { t } = useTranslation(['receipt']);

  const tableHeads = [
    t('table.head.desktop.product'),
    t('table.head.desktop.description'),
    t('table.head.desktop.type'),
    t('table.head.desktop.quantity'),
    t('table.head.desktop.unit_value'),
    t('table.head.desktop.subtotal'),
  ];

  return (
    <Fragment>
      <TableContainer component={Paper}>
        <Table>
          {saleItems.length ? (
            <Fragment>
              <TableHead>
                <TableRow
                  sx={{
                    background: theme.palette.colors.gray[100],
                    borderBottom: '2px solid' + theme.palette.colors.gray[200],
                  }}
                >
                  {tableHeads.map((head) => (
                    <TableCell align='left' key={head} sx={{ p: theme.spacing(1.5) }}>
                      <CellText p={0} variant='smallBold'>
                        {head}
                      </CellText>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {saleItems.map((saleItem) => (
                  <TableRow key={saleItem.id} sx={{ verticalAlign: 'baseline' }}>
                    <TableCell scope='row' align='left'>
                      <CellText variant='smallSemiBold' pt={0}>
                        {saleItem.product?.internalName ?? saleItem.product?.name}
                      </CellText>
                    </TableCell>

                    <TableCell align='left'>
                      <CellsMounter.Description saleItem={saleItem} cell={{ variant: 'smallSemiBold' }} />
                    </TableCell>

                    <TableCell align='left'>
                      <CellsMounter.Type saleItem={saleItem} cell={{ variant: 'smallSemiBold' }} />
                    </TableCell>

                    <TableCell align='left'>
                      <CellsMounter.Quantity saleItem={saleItem} cell={{ variant: 'smallSemiBold' }} />
                    </TableCell>

                    <TableCell align='left'>
                      <CellsMounter.UnityValue saleItem={saleItem} cell={{ variant: 'smallSemiBold' }} />

                      <CellText variant='smallSemiBold'>{t('table.body.subtotal')}</CellText>
                    </TableCell>

                    <TableCell align='left'>
                      <CellsMounter.Subtotal saleItem={saleItem} cell={{ variant: 'smallSemiBold' }} />

                      <CellText variant='smallSemiBold'>{formatCents(saleItem.priceCents)}</CellText>
                    </TableCell>
                  </TableRow>
                ))}

                <TableRow sx={{ verticalAlign: 'baseline' }}>
                  <TableCell colSpan={6} sx={{ px: 3, pb: 0 }}>
                    <Stack direction='row' alignItems='center' justifyContent='flex-end' spacing={4}>
                      <Stack>
                        {priceSummary.map(({ title }) => (
                          <Stack direction='row' alignItems='center' justifyContent='flex-end' key={title as string}>
                            <PriceSummaryTableCellText variant='regularRegular'>{title}</PriceSummaryTableCellText>
                          </Stack>
                        ))}
                      </Stack>

                      <Stack>
                        {priceSummary.map(({ value }) => (
                          <Stack
                            direction='row'
                            alignItems='center'
                            justifyContent='flex-end'
                            spacing={4}
                            key={value as string}
                          >
                            <PriceSummaryTableCellText variant='regularRegular'>{value}</PriceSummaryTableCellText>
                          </Stack>
                        ))}
                      </Stack>
                    </Stack>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={6} padding='none' sx={{ py: 0.5 }} />
                </TableRow>
              </TableBody>
            </Fragment>
          ) : null}

          <TableFooter>
            <TableRow>
              <TableCell colSpan={6} padding='none' sx={{ px: 2, pt: 2 }}>
                <StackBetween>
                  <Typography variant='h6'>{t('table.footer.position', { position: sale.position })}</Typography>

                  <StackFlexEnd direction='row' alignItems='flex-end' spacing={0.5} justifyContent='flex-end'>
                    <Typography variant='h6'>{t('table.footer.total')}</Typography>

                    <Typography variant='h4'>{formatCents(sale.amountCents)}</Typography>
                  </StackFlexEnd>
                </StackBetween>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={6}>
                <StackBetween>
                  <Typography variant='regularRegular'>
                    {t('table.footer.date', { date: format(new Date(sale.createdAt), 'dd/MM/yyyy - HH:mm') })}
                  </Typography>

                  {sale.decisiveTransaction?.installments ? (
                    <StackFlexEnd direction='row' alignItems='center' spacing={0.5} justifyContent='flex-end'>
                      <Typography variant='largeMedium'>
                        {t('table.footer.installments', { installments: sale.decisiveTransaction?.installments })}
                      </Typography>

                      <Typography variant='largeBold'>
                        {formatCents(sale.amountCents / sale.decisiveTransaction?.installments)}
                      </Typography>
                    </StackFlexEnd>
                  ) : null}
                </StackBetween>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

export default ReceiptDesktopView;
