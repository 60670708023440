import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { schema } from './schema';

import { Grid, Typography } from '@mui/material';

import useAnalytics from '@/hooks/analytics/useAnalytics';
import {
  DirectSaleTransaction,
  DirectSalesApiGetDirectSalePaymentOptionsRequest,
  PaymentInstallmentOption,
  PaymentMethodCode,
  PaymentOption,
  Sale,
  ErrorObject,
  PaymentCardParams,
  TransactionRequest,
} from '@/services/SellerApi';

import Input from '@/components/Form/Input';
import Button from '@/components/Button';

import { useSellerApi } from '@/hooks/useSellerApi';
import CardInput from '@/components/Form/CardInput';
import Select from '@/components/Form/Select';
import { formatCents } from '@/utils';
import Loading from '@/components/Loading';
import ModalOnDesktopDrawerOnMobile from '@/components/Modal/ModalOnDesktopDrawerOnMobile';
import TransactionErrorAlert from '../TransactionErrorAlert';
import { enqueueSnackbar } from 'notistack';
import BackdropLoading from '@/components/BackdropLoading';
import { AxiosError } from 'axios';
import usePaymentFingerprint from '@/hooks/usePaymentFingerprints';
import { TransactionErrorAlertModalTitle } from '../TransactionErrorAlert/styles';

type DefaultValueFields = {
  name: string;
  identification: string;
  cardNumber: string;
  cvv: string;
  validity: string;
  installment: PaymentInstallmentOption | null;
  postalCode: string;
};

type Props = {
  saleId: string;
  sale?: Sale;
  onSuccess?: (transaction: DirectSaleTransaction) => void;
  onFail?: (error: ErrorObject) => void;
};

const CardPaymentForm = (props: Props) => {
  const { saleId, onSuccess = () => {}, onFail = () => {} } = props;

  const { track } = useAnalytics();

  const { directSalesApi, salesApi } = useSellerApi();

  const { t } = useTranslation(['payment']);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPaymentOption, setLoadingPaymentOption] = useState<boolean>(true);
  const [paymentOption, setPaymentOption] = useState<PaymentOption | undefined>(undefined);
  const [transactionError, setTransactionError] = useState<'transaction' | 'antifraud' | 'expired' | undefined>(
    undefined,
  );

  usePaymentFingerprint(paymentOption ? [paymentOption] : []);

  const { register, formState, control, handleSubmit, setValue } = useForm<DefaultValueFields>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: zodResolver(schema(t, paymentOption)),
    defaultValues: {
      installment: null,
    },
  });
  const { errors } = formState;

  const getPaymentOptions = async (saleId: string) => {
    setLoadingPaymentOption(true);
    const params: DirectSalesApiGetDirectSalePaymentOptionsRequest = {
      saleId: saleId,
    };

    try {
      const { data } = await directSalesApi.getDirectSalePaymentOptions(params);

      const option = data.find((option) => {
        return option.paymentMethod.code === PaymentMethodCode.CreditCard;
      });

      setPaymentOption(option);
    } catch (error) {
      enqueueSnackbar(t('errors.could_not_retrieve_payment_option'), {
        variant: 'error',
      });
    } finally {
      setLoadingPaymentOption(false);
    }
  };

  const getInstallmentLabel = (option: PaymentInstallmentOption): string => {
    return `${option.division}x - ${formatCents(option.installmentCents)}`;
  };

  const onSubmit = async (formData: PaymentCardParams) => {
    setLoading(true);

    const transactionRequest: TransactionRequest = {
      paymentMethodId: paymentOption?.paymentMethod.id ?? '',
      paymentCard: formData,
      deviceInfo: {
        language: navigator.language,
        screenHeight: screen.height,
        screenWidth: screen.width,
        timeZoneOffset: new Date().getTimezoneOffset() / 60,
      }
    };

    try {
      const { data } = await salesApi.transactDirectSale({
        saleId,
        transactionRequest,
      });

      onSuccess(data);
    } catch (error) {
      const res = (error as AxiosError).response!.data as ErrorObject;

      if (res.errors?.length) {
        if (res.errors[0].extra?.type === 'AntiFraud') {
          setTransactionError('antifraud');
        }

        if (res.errors[0].extra?.type === 'Transaction') {
          setTransactionError('transaction');
        }

        // eslint-disable-next-line
        // @ts-ignore
        if (res.errors[0]?.type === 'saleMustHaveValidState') {
          setTransactionError('expired');
        }
      }

      enqueueSnackbar(t('errors.could_not_create_transaction'), {
        variant: 'error',
      });

      onFail(res);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!props.saleId) return;

    getPaymentOptions(props.saleId);

    track('paymentForm');
  }, []);

  useEffect(() => {
    setValue('installment', paymentOption?.installmentOptions[0] ?? null);
  }, [paymentOption]);

  return (
    // eslint-disable-next-line
    // @ts-ignore
    <Grid container spacing={2} component='form' onSubmit={handleSubmit(onSubmit)}>
      {loadingPaymentOption ? (
        <Grid item xs={12}>
          <Loading sx={{ my: '70px' }} />
        </Grid>
      ) : null}

      {loading ? (
        <Grid item xs={12}>
          <BackdropLoading />
        </Grid>
      ) : null}

      {!loadingPaymentOption && !paymentOption ? (
        <Typography variant='h6' sx={{ my: '50px' }}>
          {t('card_form.sale_without_credit_card_message')}
        </Typography>
      ) : null}

      {paymentOption ? (
        <>
          <Grid item xs={12}>
            <Input
              {...register('name')}
              fullWidth
              required
              label={t('card_form.name')}
              error={!!errors['name']}
              helperText={errors['name']?.message as string}
            />
          </Grid>

          <Grid item xs={12}>
            <Input
              {...register('identification')}
              fullWidth
              required
              label={t('card_form.identification')}
              mask={'cpf_cnpj'}
              error={!!errors['identification']}
              helperText={errors['identification']?.message as string}
            />
          </Grid>

          <Grid item xs={12}>
            <Input
              {...register('postalCode')}
              fullWidth
              required
              label={t('card_form.postal_code')}
              mask={'cep'}
              error={!!errors['postalCode']}
              helperText={errors['postalCode']?.message as string}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='cardNumber'
              control={control}
              render={({ field: { name, onChange, value, ...rest } }) => (
                <CardInput
                  fullWidth
                  required
                  label={t('card_form.card_number')}
                  brands={paymentOption.brands}
                  error={!!errors['cardNumber']}
                  helperText={errors['cardNumber']?.message as string}
                  name={name}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  {...rest}
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Input
              {...register('cvv')}
              fullWidth
              required
              label={t('card_form.cvv')}
              placeholder={t('card_form.cvv_placeholder')}
              mask={'cvv'}
              error={!!errors['cvv']}
              helperText={errors['cvv']?.message as string}
            />
          </Grid>

          <Grid item xs={6}>
            <Input
              {...register('validity')}
              fullWidth
              required
              label={t('card_form.valid_date')}
              mask={'paymentCardValidity'}
              placeholder={t('card_form.valid_date_placeholder')}
              error={!!errors['validity']}
              helperText={errors['validity']?.message as string}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='installment'
              control={control}
              render={({ field: { name, onChange, value, ...rest } }) => (
                <Select
                  fullWidth
                  required
                  name={name}
                  label={t('card_form.installments')}
                  disableClearable
                  loading={loadingPaymentOption}
                  loadingText={t('card_form.loadingInstallments')}
                  options={paymentOption.installmentOptions}
                  getOptionLabel={(option) => getInstallmentLabel(option as PaymentInstallmentOption)}
                  defaultValue={paymentOption.installmentOptions[0]}
                  error={!!errors[name]}
                  helperText={errors[name]?.message}
                  value={value}
                  onChange={(_, value) => onChange(value as PaymentInstallmentOption)}
                  {...rest}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Button type='submit' variant='contained' color='primary' fullWidth disabled={false}>
              {t('card_form.finishPayment')}
            </Button>
          </Grid>
        </>
      ) : null}

      <ModalOnDesktopDrawerOnMobile
        width={455}
        anchor='bottom'
        isOpen={!!transactionError}
        title={<TransactionErrorAlertModalTitle>{t('errors.rejected.title')}</TransactionErrorAlertModalTitle>}
        onClose={() => setTransactionError(undefined)}
      >
        {transactionError ? (
          <TransactionErrorAlert type={transactionError} onClose={() => setTransactionError(undefined)} />
        ) : null}
      </ModalOnDesktopDrawerOnMobile>
    </Grid>
  );
};

export default CardPaymentForm;
