import { LinkProps, TabProps as BaseTabProps, TabsProps as BaseTabsProps } from '@mui/material';

import { StyledTab, StyledTabs } from './style';

export type TabProps = BaseTabProps & { href?: string; target?: LinkProps['target'] };
export type TabsProps = {
  root?: BaseTabsProps;
  tabs: Array<TabProps>;
};

export const Tabs = (props: TabsProps) => {
  const { root, tabs } = props;

  return (
    <StyledTabs {...root}>
      {tabs.map((tab, index) => (
        <StyledTab key={index} {...tab} />
      ))}
    </StyledTabs>
  );
};

export default Tabs;
