import { PaymentFingerprint, PaymentFingerprintTypeEnum } from '@/services/SellerApi';
import { randomString } from '@/utils';

import DinersFlag from '@/assets/cardBrands/diners.svg';
import DiscoverFlag from '@/assets/cardBrands/discover.svg';
import HiperFlag from '@/assets/cardBrands/hiper.svg';
import HipercardFlag from '@/assets/cardBrands/hipercard.svg';
import JcbFlag from '@/assets/cardBrands/jcb.svg';
import MaestroFlag from '@/assets/cardBrands/maestro.svg';
import MirFlag from '@/assets/cardBrands/mir.svg';
import UnionPayFlag from '@/assets/cardBrands/unionpay.svg';
import VisaFlag from '@/assets/cardBrands/visa.svg';
import MastercardFlag from '@/assets/cardBrands/mastercard.svg';
import AmexFlag from '@/assets/cardBrands/amex.svg';
import EloFlag from '@/assets/cardBrands/elo.svg';
import AuraFlag from '@/assets/cardBrands/aura.svg';

export const setupFingerprints = (paymentFingerprints: PaymentFingerprint[]) => {
  const fingerprintId = randomString(5);

  paymentFingerprints.forEach(({ type, data }) => {
    switch (type) {
      case PaymentFingerprintTypeEnum.Cybersource:
        const script = document.createElement('script');
        script.src = data['url'];
        script.id = `cybersource-${fingerprintId}`;
        document.body.appendChild(script);

        break;

      case PaymentFingerprintTypeEnum.Clearsale:
        // Reference: https://api.clearsale.com.br/docs/behavior-analytics/sdk/browser
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        (function (a: any, b: any, c: any, d: any, e: any, f?: any, g?: any) {
          a['CsdpObject'] = e;
          (a[e] =
            a[e] ||
            function () {
              (a[e].q = a[e].q || []).push(arguments);
            }),
            (a[e].l = +new Date());
          (f = b.createElement(c)), (g = b.getElementsByTagName(c)[0]);
          f.async = 1;
          f.src = d;
          f.id = `clearsale-${fingerprintId}`;
          g.parentNode.insertBefore(f, g);
        })(window, document, 'script', '//device.clearsale.com.br/p/fp.js', `csdp${fingerprintId}`);
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const wdw: any = window;
        wdw[`csdp${fingerprintId}`]('app', data['app']);
        wdw[`csdp${fingerprintId}`]('sessionid', data['sessionid']);

        break;
    }
  });

  return fingerprintId;
};

export const unloadFingerprints = (paymentFingerprints: PaymentFingerprint[], fingerprintId: string) => {
  paymentFingerprints.forEach(({ type }) => {
    switch (type) {
      case PaymentFingerprintTypeEnum.Cybersource:
        const cybersourceScript = document.querySelector(`#cybersource-${fingerprintId}`);
        if (cybersourceScript) {
          cybersourceScript.remove();
        }
        break;

      case PaymentFingerprintTypeEnum.Clearsale:
        const clearsaleScript = document.querySelector(`#clearsale-${fingerprintId}`);
        if (clearsaleScript) {
          clearsaleScript.remove();
          // eslint-disable-next-line  @typescript-eslint/no-explicit-any
          const wdw: any = window;
          delete wdw[`csdp${fingerprintId}`];
        }
        break;
    }
  });
};

type PaymentBrandFlags = Record<string, string>;

export const paymentBrandFlags: PaymentBrandFlags = {
  diners: DinersFlag,
  discover: DiscoverFlag,
  hiper: HiperFlag,
  hipercard: HipercardFlag,
  jcb: JcbFlag,
  maestro: MaestroFlag,
  mir: MirFlag,
  unionpay: UnionPayFlag,
  visa: VisaFlag,
  mastercard: MastercardFlag,
  amex: AmexFlag,
  'american-express': AmexFlag,
  elo: EloFlag,
  aura: AuraFlag,
};
