import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatISO, isValid } from 'date-fns';

import ComposedDatePicker from '@/components/ComposedDatePicker';
import Select from '@/components/Form/Select';
import useFilter from '@/hooks/useFilter';
import { FilterOption } from '@/contexts/FilterContext';
import { parseDate } from '@/utils';

export const FilterFields = () => {
  const { selectedFilters, handleChangeFilters, schema, composeKey } = useFilter();

  const { t } = useTranslation(['productShowCase']);

  return (
    <Box display='flex' gap={2} flexDirection='column'>
      <Select
        fullWidth
        openOnFocus
        multiple
        options={schema!.providerIds.options!}
        loading={schema!.providerIds.loading!}
        label={t('filter.provider_selector_label')}
        getOptionLabel={(option) => (option as FilterOption).label}
        filterSelectedOptions
        isOptionEqualToValue={(option, value) => (option as FilterOption).value === value}
        onChange={(_, value) => {
          const newValue = value as FilterOption[];

          handleChangeFilters(
            'providerIds',
            newValue.map((p) => p.value),
          );
        }}
        value={
          (selectedFilters?.providerIds as string[])?.map(
            (providerId) => schema!.providerIds.options!.find((option) => option.value === providerId)!,
          ) ?? []
        }
      />

      <ComposedDatePicker
        since={parseDate(selectedFilters?.schedulingSince as string)}
        until={parseDate(selectedFilters?.schedulingUntil as string)}
        onChange={(date, key) => {
          if (isValid(date)) {
            handleChangeFilters(composeKey(key, 'scheduling'), formatISO(date as Date));
          }
        }}
        slotProps={{
          wrapper: { gap: 2 },
          since: { label: t('filter.since_label') },
          until: { label: t('filter.until_label') },
        }}
      />
    </Box>
  );
};

export default FilterFields;
