import { Tooltip } from '@mui/material';

import { Account } from '@/services/SellerApi';

import { StyledCard, Name, StyledAppLogo, AppLogoContainer } from './styles';

interface AccountCardProps {
  account: Account;
  selected: boolean;
  onClick: (app: Account) => void;
}

export const AccountCard = (props: AccountCardProps) => {
  const { account, selected, onClick } = props;

  return (
    <Tooltip title={account.app?.name} placement='top'>
      <StyledCard elevation={0} onClick={() => onClick(account)} selected={selected} tabIndex={1}>
        <AppLogoContainer>
          <StyledAppLogo app={account.app} />
        </AppLogoContainer>

        <Name>{account.app?.name}</Name>
      </StyledCard>
    </Tooltip>
  );
};

export default AccountCard;
