import { TFunction } from 'i18next';
import { RefundRequestStatus } from '@/services/SellerApi';

export const filterSchema = (t: TFunction<[string], undefined>) => ({
  status: {
    type: 'array',
    title: t('filters.status.title'),
    options: [
      { label: t('filters.status.pending'), value: RefundRequestStatus.Pending },
      { label: t('filters.status.approved'), value: RefundRequestStatus.Approved },
      { label: t('filters.status.denied'), value: RefundRequestStatus.Denied },
    ],
  },
});
