import { Dispatch, SetStateAction, useState } from 'react';
import { Grid, Chip, Link, Typography } from '@mui/material';
import { GridActionsCellItem, GridRenderCellParams, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { colors } from '@/theme';
import { downloadBlobFile, formatCents } from '@/utils';
import {
  DetailedDirectSaleItem,
  DirectSale,
  PaymentMethodCode,
  SaleStateEnum,
  VouchersApiGetDirectSaleVouchersPdfRequest,
} from '@/services/SellerApi';
import { PixOutlined } from '@mui/icons-material';
import DynamicChipList from '@/components/DynamicChipList';
import Table, { TableColDef } from '@/components/Table';
import { CreditCard02, DownloadCloud02 } from '@untitled-ui/icons-react';
import { enqueueSnackbar } from 'notistack';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import MobileDirectSalePreview from './MobileDirectSalePreview';
import { useSellerApi } from '@/hooks/useSellerApi';
import { DirectSaleWithItemsAndPaymentOption } from '..';
import { PaymentModals } from '@/components/Payment/PaymentModals';
import SaleStatusChip from '@/features/sales/components/SaleStatusChip';
import Loading from '@/components/Loading';
import { IconReceipt } from '@tabler/icons-react';

type Props = {
  directSales: DirectSale[];
  directSalesWithProductsAndPaymentMenthods: DirectSaleWithItemsAndPaymentOption[];
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  totalDirectSales: number;
  onRefreshList: () => Promise<void>;
  paginationModel: {
    page: number;
    pageSize: number;
  };
  setPaginationModel: Dispatch<
    SetStateAction<{
      page: number;
      pageSize: number;
    }>
  >;
};

const DirectSaleTable = (props: Props) => {
  const {
    directSales,
    loading,
    totalDirectSales,
    paginationModel,
    setPaginationModel,
    onRefreshList,
    directSalesWithProductsAndPaymentMenthods,
  } = props;

  const { track } = useAnalytics();
  const { vouchersApi } = useSellerApi();
  const { t } = useTranslation(['directSales']);

  const [drawerPreview, setDrawerPreview] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [selectedDirectSale, setSelectedDirectSale] = useState<DirectSaleWithItemsAndPaymentOption>();

  const [openPaymentSuccess, setOpenPaymentSuccess] = useState(false);
  const [openPixPaymentModal, setOpenPixPaymentModal] = useState(false);
  const [openCreditCardPaymentModal, setOpenCreditCardPaymentModal] = useState(false);
  const [openPostPaidPaymentModal, setOpenPostPaidPaymentModal] = useState(false);

  const findDirectSaleWithItemAndPaymentMethod = (directSaleId: string) => {
    const currentDswpap = directSalesWithProductsAndPaymentMenthods.find(
      (dswpap) => dswpap.directSale.id === directSaleId,
    )!;

    return currentDswpap;
  };
  const getProductsName = (detailedDirectSaleItem?: DetailedDirectSaleItem[]) => {
    if (!detailedDirectSaleItem || !detailedDirectSaleItem.length) return null;

    const productsName = detailedDirectSaleItem.map((item) => item?.product?.name);

    return productsName.filter((item) => item !== undefined) as string[];
  };

  const renderCurrentState = (currentState: SaleStateEnum) => (
    <SaleStatusChip size='small' status={currentState} label={t(`status.${currentState}`, { ns: 'sales' })} />
  );

  const downloadVoucher = async (dsWithItemAndPaymentMethod: DirectSaleWithItemsAndPaymentOption) => {
    if (dsWithItemAndPaymentMethod.directSale.currentState !== SaleStateEnum.PaymentComplete) {
      return;
    }

    const payload: VouchersApiGetDirectSaleVouchersPdfRequest = {
      saleId: dsWithItemAndPaymentMethod.directSale.id,
    };

    try {
      setLoadingDownload(true);
      const { data } = await vouchersApi.getDirectSaleVouchersPdf(payload, { responseType: 'blob' });

      downloadBlobFile(data, `voucher-${dsWithItemAndPaymentMethod.directSale.position}.pdf`);

      track('directSales/downloadVoucherFail', { payload });
    } catch (error) {
      enqueueSnackbar(t('list.could_not_download_voucher'), {
        variant: 'error',
      });

      track('directSales/downloadVoucherFail', { error, payload });
    } finally {
      setLoadingDownload(false);
    }
  };

  const getPartnershipLink = (partnershipId?: string) => {
    if (!partnershipId) return undefined;

    return `../partnership/${partnershipId}`;
  };

  const handlePaymentSuccess = () => {
    setOpenPixPaymentModal(false);
    setOpenCreditCardPaymentModal(false);
    setOpenPostPaidPaymentModal(false);
    setOpenPaymentSuccess(true);
  };

  const onOpenPixPaymentModal = (directSale: DirectSaleWithItemsAndPaymentOption) => {
    setOpenPixPaymentModal(true);
    setSelectedDirectSale(directSale);
  };
  const onOpenCreditCardPaymentModal = (directSale: DirectSaleWithItemsAndPaymentOption) => {
    setSelectedDirectSale(directSale);
    setOpenCreditCardPaymentModal(true);
  };
  const onOpenPostPaidPaymentModal = (directSale: DirectSaleWithItemsAndPaymentOption) => {
    setSelectedDirectSale(directSale);
    setOpenPostPaidPaymentModal(true);
  };

  const paymentMethodIsAvailable = (directSale: DirectSaleWithItemsAndPaymentOption, code: PaymentMethodCode) => {
    return !!directSale.paymentOptions?.items?.find((option) => option.paymentMethod.code === code);
  };

  const actions = [
    {
      icon: <PixOutlined />,
      label: t('list.table.actions.pay_with_pix'),
      onClick: onOpenPixPaymentModal,
      code: PaymentMethodCode.Pix,
    },
    {
      icon: <CreditCard02 />,
      label: t('list.table.actions.pay_with_card'),
      onClick: onOpenCreditCardPaymentModal,
      code: PaymentMethodCode.CreditCard,
    },
    {
      icon: <IconReceipt />,
      label: t('list.table.actions.pay_with_post_paid'),
      onClick: onOpenPostPaidPaymentModal,
      code: PaymentMethodCode.PostPaid,
    },
  ];

  const getActions = (directSale: DirectSale) => {
    if (directSale.currentState === SaleStateEnum.PaymentComplete) {
      return [
        <GridActionsCellItem
          label=''
          key='download-voucher'
          disabled={loadingDownload}
          icon={loadingDownload ? <Loading /> : <DownloadCloud02 color='inherit' />}
          onClick={() => downloadVoucher(findDirectSaleWithItemAndPaymentMethod(directSale.id))}
        />,
      ];
    }

    if (directSale.currentState === SaleStateEnum.Created) {
      const dsWithItemAndPaymentMethod = findDirectSaleWithItemAndPaymentMethod(directSale.id);

      if (dsWithItemAndPaymentMethod.paymentOptions?.loading) {
        return [<GridActionsCellItem key='action-loading' showInMenu label='' icon={<Loading />} />];
      }

      return actions.map((action) => (
        <GridActionsCellItem
          showInMenu
          key={action.label}
          icon={action.icon}
          label={action.label}
          onClick={() => action.onClick(findDirectSaleWithItemAndPaymentMethod(directSale.id))}
          disabled={!paymentMethodIsAvailable(findDirectSaleWithItemAndPaymentMethod(directSale.id), action.code)}
        />
      ));
    }

    return [];
  };

  const columns: TableColDef[] = [
    {
      headerName: t('list.table.position'),
      sortable: false,
      field: 'position',
      width: 60,
      valueGetter: (params: GridValueGetterParams) => params.row.position,
    },
    {
      flex: 1,
      headerName: t('list.table.code'),
      sortable: false,
      field: 'code',
      valueGetter: (params: GridValueGetterParams) => params.row.code,
    },
    {
      flex: 1,
      field: 'client',
      sortable: false,
      renderAsLeftTitleOnMobile: true,
      headerName: t('list.table.client'),
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.customer.firstName} ${params.row.customer.lastName}`,
    },
    {
      flex: 1,
      sortable: false,
      field: 'provider',
      enableOnMobile: true,
      headerName: t('list.table.provider'),
      renderCell: (params: GridRenderCellParams) => {
        const currentDswpap = findDirectSaleWithItemAndPaymentMethod(params.row.id);

        if (currentDswpap.directSaleItems?.loading) {
          return <Loading />;
        }

        const partnership = currentDswpap.directSaleItems?.items[0]?.partnershipItem?.partnership;

        return partnership?.provider?.name ? (
          <Link variant='smallBold' href={getPartnershipLink(partnership?.id)}>
            {partnership?.provider?.name}
          </Link>
        ) : (
          <Typography variant='smallBold'>-</Typography>
        );
      },
    },
    {
      width: 154,
      sortable: false,
      field: 'currentState',
      renderAsRightTitleOnMobile: true,
      headerName: t('list.table.status'),
      renderCell: (params: GridRenderCellParams) => renderCurrentState(params.row.currentState),
    },
    {
      width: 106,
      sortable: false,
      field: 'amountCents',
      enableOnMobile: true,
      headerName: t('list.table.amount_cents'),
      valueGetter: (params: GridValueGetterParams) => formatCents(params.row.amountCents ?? 0),
    },
    {
      flex: 1,
      sortable: false,
      field: 'productName',
      headerName: t('list.table.products'),
      renderCell: (params: GridRenderCellParams) => {
        const cellWidth = params.api.getColumn(params.field).computedWidth!;

        const currentDswpap = directSalesWithProductsAndPaymentMenthods.find(
          (dswpap) => dswpap.directSale.id === params.row.id,
        )!;

        if (currentDswpap.directSaleItems?.loading) {
          return <Loading />;
        }

        const products = getProductsName(currentDswpap.directSaleItems?.items);

        if (!products?.length) {
          return (
            <Chip
              size='small'
              color='primary'
              variant='outlined'
              label={t('list.table.no_products')}
              sx={{ background: colors.blue[100], border: 'none' }}
            />
          );
        }

        return <DynamicChipList containerWidth={cellWidth} list={products} />;
      },
    },
    {
      width: 150,
      sortable: false,
      field: 'createdAt',
      enableOnMobile: true,
      headerName: t('list.table.created_at'),
      valueGetter: (params: GridValueGetterParams) => format(new Date(params.row.createdAt), 'dd/MM/yyyy - HH:mm'),
    },
    {
      width: 60,
      type: 'actions',
      sortable: false,
      field: 'actions',
      headerName: t('list.table.actions.title'),
      getActions: (params: GridRowParams) => getActions(params.row),
    },
  ];

  return (
    <Grid item xs={12} paddingX={{ xs: 0 }}>
      <Table
        loading={loading}
        columns={columns}
        disableColumnMenu
        rows={directSales}
        paginationMode='server'
        rowCount={totalDirectSales}
        paginationModel={paginationModel}
        onPaginationModelChange={(newPaginationModel) => setPaginationModel(newPaginationModel)}
        onMobileClick={({ row }) => {
          setDrawerPreview(true);
          setSelectedDirectSale(findDirectSaleWithItemAndPaymentMethod(row.id));
        }}
      />

      <MobileDirectSalePreview
        actions={actions}
        open={drawerPreview}
        dsWithItemAndPaymentMethod={selectedDirectSale}
        getProductsName={getProductsName}
        onDownloadVoucher={downloadVoucher}
        renderCurrentState={renderCurrentState}
        onClose={() => setDrawerPreview(false)}
        getPartnershipLink={getPartnershipLink}
        paymentMethodIsAvailable={paymentMethodIsAvailable}
      />

      <PaymentModals
        directSale={selectedDirectSale?.directSale}
        openPaymentSuccess={openPaymentSuccess}
        openPixPaymentModal={openPixPaymentModal}
        openPostPaidPaymentModal={openPostPaidPaymentModal}
        openCreditCardPaymentModal={openCreditCardPaymentModal}
        handlePaymentSuccess={handlePaymentSuccess}
        missingPaymentCount={
          directSales.filter((directSale) => directSale.currentState === SaleStateEnum.Created).length
        }
        onClosePixPaymentModal={() => {
          setOpenPixPaymentModal(false);
          onRefreshList();
        }}
        onCloseCreditCardPaymentModal={() => {
          setOpenCreditCardPaymentModal(false);
          onRefreshList();
        }}
        onClosePostPaidPaymentModal={() => {
          setOpenPostPaidPaymentModal(false);
          onRefreshList();
        }}
        onClosePaymentSuccess={() => {
          setOpenPaymentSuccess(false);
          onRefreshList();
        }}
      />
    </Grid>
  );
};

export default DirectSaleTable;
