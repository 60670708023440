import useAuth from '@/contexts/Auth/hooks/useAuth';
import useAccount from '@/hooks/useAccount';
import { useMemo } from 'react';

type Props = {
  pageId: string;
};

const useGetLocalStoragePersistKey = (params: Props) => {
  const { pageId } = params;
  const { selectedAccount } = useAccount();
  const { user } = useAuth();

  const userId = useMemo(() => user?.id ?? 'unknownUser', [user?.id]);
  const appId = useMemo(() => selectedAccount?.appId, [selectedAccount?.appId]);

  return useMemo(() => `config.${appId}.${userId}/${pageId}`, [appId, pageId, userId]);
};

export default useGetLocalStoragePersistKey;
