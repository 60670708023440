import { z } from 'zod';
import { FormFields } from '.';

const requiredField = z.string().nonempty({ message: 'Campo obrigatório' });
export const fields = {
  name: '',
  email: '',
  confirmEmail: '',
  password: '',
  confirmPassword: '',
  acceptTerms: false,
};

export const schema = z
  .object({
    name: requiredField,
    email: requiredField.email({ message: 'Email inválido' }),
    confirmEmail: requiredField,
    password: requiredField.min(8, { message: 'A senha deve conter no mínimo 8 caracteres' }),
    confirmPassword: requiredField,
    acceptTerms: z.boolean().refine((value) => value === true, {
      message: 'Campo obrigatório',
    }),
  })
  .superRefine(({ email, confirmEmail }: FormFields, ctx: z.RefinementCtx) => {
    if (email && confirmEmail && email !== confirmEmail) {
      return ctx.addIssue({
        code: 'custom',
        message: 'Os emails não correspondem',
        path: ['confirmEmail'],
      });
    }
  })
  .superRefine(({ password, confirmPassword }: FormFields, ctx: z.RefinementCtx) => {
    if (password && confirmPassword && password !== confirmPassword) {
      return ctx.addIssue({
        code: 'custom',
        message: 'As senhas não correspondem',
        path: ['confirmPassword'],
      });
    }
  })
  .transform((data: FormFields) => {
    return {
      name: data.name,
      email: data.email,
      password: data.password,
      acceptTerms: data.acceptTerms,
    };
  });
