import { Chip } from '@mui/material';

import { TimerContainer } from './styles';
import theme from '@/theme';
import { DateAndTimeScheduleQuantity, ScheduleTime } from '@/services/SellerApi';

interface ModifiedScheduleTime extends ScheduleTime {
  occupation: DateAndTimeScheduleQuantity['occupation'];
  isNotAvailable: boolean;
}
interface Props {
  value?: string;
  onChange: (time: string) => void;
  scheduleTimes: ModifiedScheduleTime[];
}
export const ReservationTimeSelector = (props: Props) => {
  const { onChange, scheduleTimes, value } = props;

  const isTimeSelected = (time: string) => value === time;

  const getTimeStatus = (time: string, isNotAvailable: boolean) => {
    if (isNotAvailable) {
      return { color: theme.palette.colors.gray[400], background: theme.palette.colors.gray[100] };
    }

    if (isTimeSelected(time)) {
      return { color: theme.palette.common.white, background: theme.palette.colors.blue[500] };
    }

    return { color: theme.palette.colors.blue[500], background: theme.palette.colors.blue[100] };
  };

  return (
    <TimerContainer>
      {scheduleTimes.map(({ time, isNotAvailable }) => {
        const { background, color } = getTimeStatus(time, isNotAvailable);

        return (
          <Chip
            key={time}
            label={time}
            color='primary'
            clickable={false}
            variant='outlined'
            onClick={() => (isNotAvailable ? undefined : onChange(time))}
            sx={{
              color,
              background,
              border: 'none',
              cursor: isNotAvailable ? 'not-allowed' : 'pointer',
            }}
          />
        );
      })}
    </TimerContainer>
  );
};

export default ReservationTimeSelector;
