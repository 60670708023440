import { TFunction } from 'i18next';
import { format, formatISO } from 'date-fns';
import { z } from 'zod';
import {
  CouponConstraintType,
  CouponType,
  CouponUsageRestrictionsTypeEnum,
  CouponUsageType,
  Product,
  PublicProduct,
  RecurrenceWeekdayEnum,
} from '@/services/SellerApi';

interface FormUsageRestriction {
  type: {
    label: string;
    value: string;
  };
  fromDate: Date | null;
  toDate: Date | null;
  date: Date | null;
  weekdays: RecurrenceWeekdayEnum[];
}
export interface FormData {
  code: string;
  type: CouponType;
  discountAmountCents?: string;
  discountPercentage?: string;
  usageType: string;
  products?: PublicProduct[];
  maxUse?: string;
  maxUsePerUser?: string;
  description?: string;
  minAmountCents?: string;
  availableAt?: Date | null;
  expiresAt?: Date | null;
  usageRestrictions?: Array<FormUsageRestriction>;
}

export const schema = (t: TFunction<'coupons'[], undefined>) => {
  const requiredFiledMessage = t('coupon_form.validation.required_field');

  const noRequiredField = z.string().optional().nullable();
  const requiredField = z.string({ required_error: requiredFiledMessage }).nonempty({ message: requiredFiledMessage });

  const validateDiscount = (data: FormData, ctx: z.RefinementCtx) => {
    if (data.type === CouponType.Nominal && !data.discountAmountCents) {
      ctx.addIssue({
        path: ['discountAmountCents'],
        code: z.ZodIssueCode.custom,
        message: requiredFiledMessage,
      });
    }

    if (data.type === CouponType.Percentage && !data.discountPercentage) {
      ctx.addIssue({
        path: ['discountPercentage'],
        code: z.ZodIssueCode.custom,
        message: requiredFiledMessage,
      });
    }

    if (data.discountPercentage && (Number(data.discountPercentage) < 0 || Number(data.discountPercentage) > 100)) {
      ctx.addIssue({
        path: ['discountPercentage'],
        code: z.ZodIssueCode.custom,
        message: t('coupon_form.validation.invalid_percentage'),
      });
    }
  };

  const validateProducts = (data: FormData, ctx: z.RefinementCtx) => {
    if (
      ['exclude_product', 'include_product'].includes(data.usageType) &&
      !((data?.products as PublicProduct[]) || undefined)?.length
    ) {
      ctx.addIssue({
        path: ['products'],
        code: z.ZodIssueCode.custom,
        message: requiredFiledMessage,
      });
    }
  };

  const validateUsage = (data: FormData, ctx: z.RefinementCtx) => {
    if (data.maxUsePerUser && Number(data.maxUsePerUser) <= 0) {
      ctx.addIssue({
        path: ['maxUsePerUser'],
        code: z.ZodIssueCode.custom,
        message: t('coupon_form.validation.invalid_max_use_per_user'),
      });
    }

    if (data.maxUse && Number(data.maxUse) <= 0) {
      ctx.addIssue({
        path: ['maxUse'],
        code: z.ZodIssueCode.custom,
        message: t('coupon_form.validation.invalid_max_use'),
      });
    }
  };

  const validateDatePeriod = (data: FormData, ctx: z.RefinementCtx) => {
    if (data.availableAt && data.expiresAt && data.availableAt >= data.expiresAt) {
      ctx.addIssue({
        path: ['availableAt'],
        code: z.ZodIssueCode.invalid_date,
        message: t('coupon_form.validation.invalid_date_range'),
      });
    }
  };

  const validateMinAmountCents = (data: FormData, ctx: z.RefinementCtx) => {
    if (data.minAmountCents && Number(data.minAmountCents) < 0) {
      ctx.addIssue({
        path: ['minAmountCents'],
        code: z.ZodIssueCode.custom,
        message: t('coupon_form.validation.invalid_min_amount'),
      });
    }
  };

  const validateRestrictionsByRange = (restriction: FormUsageRestriction, ctx: z.RefinementCtx, index: number) => {
    if (restriction.type.value === CouponUsageRestrictionsTypeEnum.Range) {
      if (!restriction.fromDate && !restriction.toDate) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['usageRestrictions', index, 'fromDate'],
          message: t('coupon_form.validation.invalid_usage_restriction_date_range_at_least_one_required'),
        });

        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['usageRestrictions', index, 'toDate'],
          message: t('coupon_form.validation.invalid_usage_restriction_date_range_at_least_one_required'),
        });
      }

      if (restriction.fromDate && restriction.toDate && restriction.fromDate >= restriction.toDate) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('coupon_form.validation.invalid_date_range'),
          path: ['usageRestrictions', index, 'fromDate'],
        });
      }
    }
  };

  const validateUsageRestrictionByDate = (restriction: FormUsageRestriction, ctx: z.RefinementCtx, index: number) => {
    if (restriction.type.value === CouponUsageRestrictionsTypeEnum.DateOnly) {
      if (!restriction.date) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: requiredFiledMessage,
          path: ['usageRestrictions', index, 'date'],
        });
      }
    }
  };

  const validateUsageRestrictionByWeekdays = (
    restriction: FormUsageRestriction,
    ctx: z.RefinementCtx,
    index: number,
  ) => {
    if (restriction.type.value === CouponUsageRestrictionsTypeEnum.WeekDays) {
      if (!restriction.weekdays?.length) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: requiredFiledMessage,
          path: ['usageRestrictions', index, 'weekdays'],
        });
      }
    }
  };

  const validateUsageRestrictions = (data: FormData, ctx: z.RefinementCtx) => {
    if (data.usageRestrictions) {
      data.usageRestrictions.forEach((restriction, index: number) => {
        validateRestrictionsByRange(restriction, ctx, index);
        validateUsageRestrictionByDate(restriction, ctx, index);

        validateUsageRestrictionByWeekdays(restriction, ctx, index);
      });
    }
  };

  const dateField = z
    .union([
      z.undefined(),
      z.null(),
      z.date({
        required_error: requiredFiledMessage,
        invalid_type_error: t('coupon_form.validation.invalid_date'),
      }),
    ])
    .optional();

  return z
    .object({
      code: requiredField,
      type: requiredField,
      discountAmountCents: noRequiredField,
      discountPercentage: noRequiredField,
      usageType: requiredField,
      products: z.unknown().optional(),
      maxUse: noRequiredField,
      maxUsePerUser: noRequiredField,
      description: noRequiredField,
      minAmountCents: noRequiredField,
      availableAt: dateField,
      expiresAt: dateField,
      usageRestrictions: z
        .union([
          z.undefined(),
          z.null(),
          z.array(
            z.object({
              type: z.object(
                {
                  label: z.string(),
                  value: z.string(),
                },
                {
                  message: requiredFiledMessage,
                  required_error: requiredFiledMessage,
                  invalid_type_error: t('coupon_form.validation.invalid_usage_restriction_type'),
                },
              ),
              fromDate: dateField,
              toDate: dateField,
              date: dateField,
              weekdays: z.array(z.string()).nullable().optional(),
            }),
          ),
        ])
        .optional(),
    })
    .superRefine((data, ctx) => {
      validateDiscount(data as FormData, ctx);
      validateProducts(data as FormData, ctx);
      validateUsage(data as FormData, ctx);
      validateMinAmountCents(data as FormData, ctx);
      validateDatePeriod(data as FormData, ctx);
      validateUsageRestrictions(data as FormData, ctx);
    })
    .transform((data) => {
      const getProducts = (products: Product[]) => products.map((product) => String(product.id));
      const getDiscountPercentage = () => {
        if (data.type === CouponType.Percentage) {
          return Number(data.discountPercentage);
        }

        return null;
      };

      const getUsageRestrictions = () => {
        return data.usageRestrictions?.map((restriction) => ({
          type: restriction.type.value ?? null,
          fromDate: restriction.fromDate ? formatISO(restriction.fromDate) : null,
          toDate: restriction.toDate ? formatISO(restriction.toDate) : null,
          date: restriction.date ? format(restriction.date, 'yyy-MM-dd') : null,
          weekdays: restriction.weekdays ?? null,
        }));
      };

      const cents = 100;

      const usageType = ['exclude_product', 'include_product'].includes(data.usageType)
        ? CouponUsageType.Product
        : data.usageType;

      const constraintType =
        data.usageType === 'exclude_product'
          ? CouponConstraintType.Exclude
          : data.usageType === 'include_product'
            ? CouponConstraintType.Include
            : null;

      return {
        code: data.code,
        type: data.type,
        discountAmountCents:
          data.type === CouponType.Nominal ? Math.trunc(Number(data.discountAmountCents) * cents) : null,
        discountPercentage: getDiscountPercentage(),
        usageType: usageType,
        constraintType: constraintType,
        productIds: usageType === CouponUsageType.Product ? getProducts(data.products as Product[]) : null,
        maxUse: data.maxUse ? Number(data.maxUse) : null,
        maxUsePerUser: data.maxUsePerUser ? Number(data.maxUsePerUser) : null,
        availableAt: data.availableAt ? formatISO(data.availableAt) : null,
        expiresAt: data.expiresAt ? formatISO(data.expiresAt) : null,
        minAmountCents: data.minAmountCents ? Number(data.minAmountCents.replace(/\D/g, '')) : null,
        description: data.description ?? null,
        usageRestrictions: getUsageRestrictions(),
      };
    });
};
