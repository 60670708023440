import { ViewDataType } from '@/features/agenda/types';

const updateViewDataTypes = (checked: boolean, type: ViewDataType, viewDataTypes: ViewDataType[]) => {
  const viewDataTypesParsed = Array.isArray(viewDataTypes) ? viewDataTypes : [viewDataTypes];
  const newViewDataType = checked
    ? [...viewDataTypesParsed, type]
    : viewDataTypesParsed.filter((view) => view !== type);

  return newViewDataType;
};

export default updateViewDataTypes;
