import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Chip, ChipProps, List, ListItem, Tooltip, Typography } from '@mui/material';
import { colors } from '@/theme';

type Props = {
  containerWidth: number;
  list: string[];
  chipProps?: ChipProps;
};
export const DynamicChipList = (props: Props) => {
  const { containerWidth, list, chipProps } = props;
  const sortedList = list.sort((a, b) => b.length - a.length);
  const [visibleItems, setVisibleItems] = useState<string[]>([]);
  const [parentMaxWidth, setParentMaxWidth] = useState(containerWidth ?? 0);

  const calculateVisibleItems = useCallback(
    (paramContainerWidth: number) => {
      const itemsToBeVisible = [];
      const plusBadgeWidth = 36; // width of plus badge, which show the hidden items
      const fontSize = 13; // font size of chip texts
      const gap = 8; // gap between chips in pixels
      let totalWidth = 0;

      for (const item of sortedList) {
        let itemWidth = (item.length * fontSize) / 2 + gap;

        if (sortedList.length > 1) {
          itemWidth += plusBadgeWidth;
        }

        if (totalWidth + itemWidth < paramContainerWidth) {
          itemsToBeVisible.push(item);
          totalWidth += itemWidth;
        }
      }

      setVisibleItems(itemsToBeVisible);
      setParentMaxWidth(paramContainerWidth);
    },
    [sortedList],
  );

  const hiddenItems = useMemo(() => {
    return sortedList.filter((item) => !visibleItems.includes(item));
  }, [sortedList, visibleItems]);

  useEffect(() => {
    calculateVisibleItems(containerWidth);
  }, []);

  useEffect(() => {
    const chipList = document.querySelector('#dynamic-chip-list');
    const parent = chipList?.parentElement;

    if (parent) {
      window.addEventListener('resize', () => {
        if (Number(parent.clientWidth) !== parentMaxWidth) {
          calculateVisibleItems(Number(parent.clientWidth));
        }
      });

      return () => {
        parent?.removeEventListener('resize', () => {
          calculateVisibleItems(0);
        });
      };
    }
  }, [parentMaxWidth]);

  return (
    <Box display='flex' alignItems='center' gap={0.5} id='dynamic-chip-list'>
      {visibleItems.map((item) => (
        <Chip
          key={item}
          size='small'
          color='primary'
          variant='outlined'
          label={item}
          sx={{ background: colors.blue[100], border: 'none' }}
          {...chipProps}
        />
      ))}
      {hiddenItems.length ? (
        <Tooltip
          arrow
          placement='bottom'
          enterTouchDelay={0}
          leaveTouchDelay={60000}
          title={
            <List
              sx={{
                listStyleType: 'disc',
                pl: 2,
                '& .MuiListItem-root': {
                  display: 'list-item',
                },
              }}
            >
              {hiddenItems.map((value) => (
                <ListItem key={value} disableGutters sx={{ py: 0, display: 'list-item' }}>
                  <Typography variant='smallRegular'>{value}</Typography>
                </ListItem>
              ))}
            </List>
          }
        >
          <Chip
            size='small'
            color='primary'
            variant='outlined'
            label={`+${hiddenItems.length.toString()}`}
            sx={{ background: colors.blue[100], border: 'none' }}
            {...chipProps}
          />
        </Tooltip>
      ) : null}
    </Box>
  );
};

export default DynamicChipList;
