import { ReactElement } from 'react';

import { Grid } from '@mui/material';

import { ArtSection } from './styles';

type SideArtProps = {
  content?: ReactElement;
};

const SideArt = (props: SideArtProps) => {
  const { content } = props;

  return (
    <ArtSection container alignItems='center' justifyContent='center'>
      <Grid item>{content ? <Grid item>{content}</Grid> : null}</Grid>
    </ArtSection>
  );
};

export default SideArt;
