type Step = 'PERSONAL_DATA' | 'ESTABLISHMENT_DATA' | 'FINANCIAL_DATA' | 'OWNER_DATA';

type SignupStep = {
  step: Step;
  title: string;
  fields: string[];
};

export const EstablishmentTypeInitialValue = {
  name: '',
  id: '',
  mcc: '',
  updatedAt: '',
  createdAt: '',
};

export const signupSteps: SignupStep[] = [
  {
    title: 'personal_data',
    step: 'PERSONAL_DATA',
    fields: [],
  },
  {
    step: 'ESTABLISHMENT_DATA',
    title: 'establishment_data',
    fields: ['identifier', 'establishmentType', 'fantasyName', 'corporateName', 'contactPhone', 'openedAt'],
  },
  {
    step: 'FINANCIAL_DATA',
    title: 'financial_data',
    fields: [
      'bankIdentifier',
      'bankCode',
      'bankAgency',
      'bankAccount',
      'bankAccountType',
      'expectedMonthlyRevenueCents',
    ],
  },
  {
    step: 'OWNER_DATA',
    title: 'owner_data',
    fields: [
      'ownerName',
      'ownerCpf',
      'ownerBirthdate',
      'zipCode',
      'city',
      'uf',
      'street',
      'district',
      'number',
      'complement',
    ],
  },
];
