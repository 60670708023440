import { Grid, Typography } from '@mui/material';

import IconCalendarDays from '@/assets/Icons/icon-calendar-days.svg';
import SvgInjector from '@/components/SvgInjector';
import { useAgenda } from '@/features/agenda/hooks/useAgenda';
import { colors } from '@/theme';

import { StyledCalendarGrid, StyledCalendarMonthItem } from './styles';
interface MonthCalendarProps {
  activeYear: number;
}
interface MonthSlotProps {
  month: string;
  active?: boolean;
  onClick?: () => void;
}
const MonthSlot = ({ month, onClick, active = false }: MonthSlotProps) => {
  return (
    <StyledCalendarMonthItem data-testid='MonthSlot' className={active ? 'selected' : ''} onClick={onClick}>
      <Typography textAlign='right' color={colors.gray['500']} sx={{ textTransform: 'capitalize' }}>
        {month}
      </Typography>
      <SvgInjector src={IconCalendarDays} />
    </StyledCalendarMonthItem>
  );
};

const MonthCalendar = (props: MonthCalendarProps) => {
  const { activeYear } = props;
  const { getMonthString, activeDate: currentDate, updateDate } = useAgenda();
  const getYearMonths = (year: number) => {
    const months = [];
    for (let i = 0; i < 12; i++) {
      months.push(new Date(year, i, 1));
    }
    return months;
  };
  const getActiveMonth = (month: number) => {
    return currentDate.getMonth() === month && currentDate.getFullYear() === activeYear;
  };

  const changeSelectedMonth = (month: number) => {
    updateDate(new Date(activeYear, month, 1));
  };
  return (
    <StyledCalendarGrid container spacing={2} data-testid='MonthCalendar-root'>
      {getYearMonths(activeYear).map((month) => (
        <Grid item xs={3} key={month.toISOString()}>
          <MonthSlot
            active={getActiveMonth(month.getMonth())}
            month={getMonthString(month)}
            onClick={() => changeSelectedMonth(month.getMonth())}
          />
        </Grid>
      ))}
    </StyledCalendarGrid>
  );
};

export default MonthCalendar;
