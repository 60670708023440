import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Fragment, useEffect, useState } from 'react';
import { IconCalendarCheck } from '@tabler/icons-react';
import { GridValueGetterParams } from '@mui/x-data-grid';
import { Stack, Typography, useMediaQuery } from '@mui/material';

import theme from '@/theme';
import Button from '@/components/Button';
import Loading from '@/components/Loading';
import EmptyPage from '@/components/EmptyPage';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import { formatCents } from '@/utils';
import Table, { TableColDef } from '@/components/Table';
import { IncomeScheduling } from '@/services/SellerApi';
import MobileTableRowPreview from '@/features/sales/components/IncomeSchedulings/MobileTableRowPreview';
import OperationHeader from '@/features/sales/components/IncomeSchedulings/OperationHeader';
import InstallmentChip from '@/features/sales/components/IncomeSchedulings/InstallmentChip';
import { Header } from './style';
import { useSaleDetails } from '@/features/sales/hook/useSaleDetails';

export const IncomeSchedulings = () => {
  const { track } = useAnalytics();

  const { t } = useTranslation(['incomeSchedulings']);

  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const { sale, saleIncomeSchedulingsData } = useSaleDetails();

  const { id: saleId } = sale;

  const [openMobilePreview, setOpenMobilePreview] = useState(false);
  const [selectedRow, setSelectedRow] = useState<IncomeScheduling>();

  const { data, isLoading: loading, paginationModel, setPaginationModel } = saleIncomeSchedulingsData;
  const { data: incomeSchedulings = [], totalIncomeSchedulings = 0 } = data || {};

  const columns: TableColDef[] = [
    {
      flex: 1,
      field: 'id',
      sortable: false,
      maxWidth: 78,
      headerName: t('table.id'),
      renderAsLeftTitleOnMobile: true,
      valueGetter: ({ row }) => row.transaction?.sale?.position ?? '',
    },
    {
      flex: 1,
      field: 'eventType',
      sortable: false,

      renderHeader: () => <OperationHeader />,
      valueGetter: ({ row }) => t('event_type.' + row.eventType),
    },
    {
      flex: 1,
      sortable: false,
      maxWidth: 140,
      field: 'grossAmountCents',
      headerName: t('table.gross_value'),
      valueGetter: ({ row }) => formatCents(row.grossAmountCents),
    },
    {
      flex: 1,
      maxWidth: 140,
      field: 'amountCents',
      sortable: false,
      enableOnMobile: true,
      headerName: t('table.net_value'),
      valueGetter: ({ row }) => formatCents(row.amountCents),
    },
    {
      flex: 1,
      sortable: false,
      maxWidth: 140,
      enableOnMobile: true,
      field: 'installmentTotal',
      headerName: t('table.intstallments'),

      renderCell: ({ row }) => (
        <InstallmentChip installmentNumber={row.installmentNumber} installmentTotal={row.installmentTotal} />
      ),
    },
    {
      flex: 1,
      sortable: false,
      field: 'createdAt',
      headerName: t('table.operation_date'),
      valueGetter: ({ row }) => format(new Date(row.plannedAt), 'dd/MM/yyyy - HH:mm'),
    },
    {
      flex: 1,
      sortable: false,
      enableOnMobile: true,
      headerName: t('table.payment_date'),
      field: 'transaction.payment.createdAt',
      valueGetter: (params: GridValueGetterParams) =>
        format(new Date(params.row.performedAt ?? params.row.availableAt), 'dd/MM/yyyy'),
    },
  ];

  useEffect(() => {
    track('IncomeSchedulings/access', { saleId });
  }, []);

  return (
    <Fragment>
      {loading ? (
        <Stack mt={4}>
          <Loading />
        </Stack>
      ) : null}

      {!loading ? (
        <Stack spacing={3} mt={{ xs: 2, md: 1 }}>
          <Header direction={{ xs: 'column', md: 'row' }}>
            <Typography variant='h6'>{t('header.title')}</Typography>

            <Button
              color='primary'
              variant='outlined'
              fullWidth={isTablet}
              href='../../income-schedulings'
              startIcon={<IconCalendarCheck />}
            >
              {t('header.button_text')}
            </Button>
          </Header>

          {!loading && incomeSchedulings.length ? (
            <Fragment>
              <Table
                autoHeight
                rowHeight={61}
                columns={columns}
                loading={loading}
                rows={incomeSchedulings}
                hiddenMobile={{ mdUp: true }}
                rowCount={totalIncomeSchedulings}
                hiddenDesktop={{ mdDown: true }}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                onMobileClick={({ row }) => {
                  setSelectedRow(row as IncomeScheduling);
                  setOpenMobilePreview(true);
                }}
              />

              <MobileTableRowPreview
                open={openMobilePreview}
                selectedRow={selectedRow}
                onClose={() => setOpenMobilePreview(false)}
              />
            </Fragment>
          ) : null}
        </Stack>
      ) : null}

      {!loading && !incomeSchedulings.length ? (
        <Stack mt={4}>
          <EmptyPage description={t('empty_page.description')} />
        </Stack>
      ) : null}
    </Fragment>
  );
};

export default IncomeSchedulings;
