import { zodResolver } from '@hookform/resolvers/zod';
import { Grid } from '@mui/material';
import { isAxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';

import Button from '@/components/Button';
import SuccessModalBody from '@/components/CreateApp/SuccessModalBody';
import PasswordInput from '@/components/Form/PasswordInput';
import Modal from '@/components/Modal';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import useAuth from '@/contexts/Auth/hooks/useAuth';
import { useErrorHandler } from '@/hooks/useErrorHandler';
import useQueryString from '@/hooks/useQueryString';
import { ErrorTypes } from '@/interfaces/errorTypes';

import { schema } from './schema';

export type FormParams = {
  password: string;
  confirmPassword: string;
};

type Props = {
  onError: (error: string) => void;
  setLoading: (loading: boolean) => void;
};

type QueryParams = {
  code: string | null;
};

export const NewPassword = () => {
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const { recoverPassword } = useAuth();
  const { errorHandler } = useErrorHandler();
  const { t } = useTranslation(['resetPassword']);
  const [openModal, setOpenModal] = useState(false);
  const { code } = useQueryString<QueryParams>('code');
  const { t: translateGenericError } = useTranslation(['errors']);
  const { onError, setLoading } = useOutletContext<Props>();

  const { register, handleSubmit, formState, setError } = useForm<FormParams>({
    mode: 'onSubmit',
    resolver: zodResolver(schema),
  });
  const { errors, isSubmitting } = formState;

  const onSubmit = async (data: FormParams) => {
    try {
      setLoading(true);
      await recoverPassword({ newPassword: data.password, code: code! });
      setOpenModal(true);
    } catch (err) {
      const TrackPath = 'resetPasswordPage/resetPasswordRequest/error';
      const { errors: handlerErrors, status, statusText } = errorHandler(err, TrackPath);

      if (handlerErrors) {
        handlerErrors.forEach(({ type, rule }) => {
          if (type === ErrorTypes.VALIDATION) {
            const message = t(rule);
            setError('password', { message });
            setError('confirmPassword', { message });
          } else {
            onError(t(rule));
          }
        });

        track(TrackPath, {
          message: handlerErrors.map(({ rule }) => translateGenericError(rule)).join(', '),
          status,
          statusText,
          error: isAxiosError(err) ? err.response?.data : err,
        });

        return;
      }

      onError(translateGenericError('generic_default_error'));
    }
  };

  useEffect(() => {
    if (!code) {
      navigate('../code-validation');
    }
  }, [code, navigate]);

  const handleRedirectUserToHome = () => navigate('/');

  return (
    <>
      <Grid item xs={12}>
        <Grid container rowSpacing={2} component='form' onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={12}>
            <PasswordInput
              fullWidth
              id='password'
              required
              error={!!errors['password']}
              label={t('change_email_step_password_label')}
              helperText={errors['password']?.message ?? t('change_email_step_password_helper_text')}
              {...register('password')}
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordInput
              required
              fullWidth
              id='confirmPassword'
              error={!!errors['confirmPassword']}
              label={t('change_email_step_confirm_password_label')}
              helperText={errors['confirmPassword']?.message as string}
              {...register('confirmPassword')}
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <Button disabled={isSubmitting} type='submit' fullWidth variant='contained'>
              {t('change_email_step_submit_button')}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Modal open={openModal} showCloseButton={false} acceptButtonText={t('code_step_confirm_email_text')}>
        <SuccessModalBody
          buttonText={t('change_email_step_successfull_submition_button')}
          description={t('change_email_step_successfull_submition')}
          onClose={handleRedirectUserToHome}
        />
      </Modal>
    </>
  );
};

export default NewPassword;
