import { CancelOutlined } from '@mui/icons-material';
import { Box, Chip, ChipProps, Grid, Hidden, Menu, Typography } from '@mui/material';
import { Tag01 } from '@untitled-ui/icons-react';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';

import Button from '@/components/Button';
import Input from '@/components/Form/Input';
import Loading from '@/components/Loading';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import useAccount from '@/hooks/useAccount';
import { useSellerApi } from '@/hooks/useSellerApi';
import {
  Sale,
  SalesApiCreateSaleTagRequest,
  SalesApiDeleteSaleTagRequest,
  SaleTag,
  Tag,
  TagsApiCreateAppTagRequest,
  TagsApiGetAppTagsRequest,
} from '@/services/SellerApi';
import { colors } from '@/theme';
import removeDuplicates from '@/utils/removeDuplicates';

type Props = {
  onClose: () => void;
  tagAnchor: {
    saleId?: string;
    element: null | HTMLElement;
  };
  onClick?: (event: React.MouseEvent<HTMLButtonElement>, sale: Sale) => void;
  open: boolean;
  sale: Sale;
};

export const SaleTagsPopup = (props: Props) => {
  const { tagAnchor, onClose, open, sale } = props;

  const { track } = useAnalytics();
  const { t } = useTranslation(['sales']);
  const { selectedAccount } = useAccount();
  const { salesApi, tagsApi } = useSellerApi();

  const [loading, setLoading] = useState(true);
  const [saleTags, setSaleTags] = useState<SaleTag[]>([]);

  const [totalAppTags, setTotalAppTags] = useState(0);
  const [appTags, setAppTags] = useState<Tag[]>([]);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 20 });

  const [search, setSearch] = useState('');

  const getSaleTags = async (saleId: string, offset = 0, limit = 100) => {
    try {
      setLoading(true);
      const { data } = await salesApi.getSaleTags({
        saleId,
        offset,
        limit,
      });

      setSaleTags(data);
    } catch (error) {
      enqueueSnackbar(t('messages.could_not_retrieve_sale_tags'), { variant: 'error' });
      track('Sales/retrieveSaleTagsFail', { saleId: sale.id, error });
    } finally {
      setLoading(false);
    }
  };

  const removeAllSaleTags = (tagsToBeFiltered: Tag[]) => {
    if (!saleTags.length) return tagsToBeFiltered;

    return tagsToBeFiltered.filter((tag) => !saleTags.some((st) => st.tag.id === tag.id));
  };

  const unlinkTagFromSale = async (saleTag: SaleTag) => {
    const payload: SalesApiDeleteSaleTagRequest = { saleTagId: saleTag.id };

    try {
      await salesApi.deleteSaleTag(payload);

      setSaleTags(saleTags.filter((st) => st.id !== saleTag.id));

      if (!appTags.some((tag) => tag.id === saleTag.tag.id)) {
        setAppTags([...appTags, saleTag.tag]);
      }
    } catch (error) {
      enqueueSnackbar(t('messages.could_not_unlink_tag'), { variant: 'error' });
      track('Sales/deleteSaleTagFail', { payload, error });
    }
  };

  const linkTagToSale = async (tag: Tag) => {
    const payload: SalesApiCreateSaleTagRequest = {
      saleId: sale.id,
      createSaleTagParams: { tagId: tag.id },
    };

    try {
      const { data } = await salesApi.createSaleTag(payload);

      setSaleTags([...saleTags, data]);
    } catch (error) {
      enqueueSnackbar(t('messages.could_not_link_tag'), { variant: 'error' });
      track('Sales/createSaleTagFail', { payload, error });
    }
  };

  const createTag = async (tagName: string) => {
    const payload: TagsApiCreateAppTagRequest = {
      appId: selectedAccount?.appId || '',
      createAppTagParams: { name: tagName },
    };

    try {
      const { data } = await tagsApi.createAppTag(payload);

      await linkTagToSale(data);
    } catch (error) {
      enqueueSnackbar(t('messages.could_not_create_tag'), { variant: 'error' });
      track('Sales/createAppTagFail', { payload, error });
    }
  };

  const handleSubmit = () => {
    if (!search) return;

    const tagNameAlreadyExits = appTags.find((tag) => tag.name.toLocaleLowerCase() === search.toLocaleLowerCase());

    if (tagNameAlreadyExits) {
      linkTagToSale(tagNameAlreadyExits);
    } else {
      createTag(search);
    }

    setSearch('');
  };

  const getAppTags = async (search?: string, offset?: number) => {
    const payload: TagsApiGetAppTagsRequest = {
      appId: selectedAccount?.appId || '',
      limit: paginationModel.pageSize,
      offset: offset ?? paginationModel.page * paginationModel.pageSize,
      ...(search ? { search } : {}),
    };

    try {
      setLoading(true);
      const { data, headers } = await tagsApi.getAppTags(payload);

      setAppTags((previous) => {
        const allTags = search ? data : [...previous, ...data];

        return removeDuplicates<Tag>(removeAllSaleTags(allTags), 'id');
      });

      setTotalAppTags(parseInt(headers['x-pagination-total-count'] ?? '0'));

      track('Sales/retrieveAppTagsSuccess', { payload });
    } catch (error) {
      enqueueSnackbar(t('messages.could_not_retrieve_app_tags'), { variant: 'error' });
      track('Sales/retrieveAppTagsFail', { payload, error });
    } finally {
      setLoading(false);
    }
  };

  const loadNextPage = useCallback(() => {
    setPaginationModel({ page: paginationModel.page + 1, pageSize: paginationModel.pageSize });
  }, [paginationModel]);

  const hasNextPage = useMemo(
    () => totalAppTags > (paginationModel.page + 1) * paginationModel.pageSize,
    [totalAppTags, paginationModel],
  );

  const filteredAppTags = appTags
    .filter(
      (t) =>
        !saleTags.some((saleTag) => {
          return saleTag.tag.id === t.id;
        }),
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  const handleSearchValue = (value: string) => {
    setSearch(value);
    setPaginationModel({ page: 0, pageSize: 20 });
  };

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (tagAnchor.element && open) {
      if (search) {
        timeout = setTimeout(() => {
          getAppTags(search);
        }, 500);
      } else {
        getAppTags();
      }
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [open, paginationModel, search]);

  useEffect(() => {
    if (sale) {
      getSaleTags(sale.id);
    }
  }, []);

  return (
    <Menu
      open={open}
      elevation={4}
      onClose={onClose}
      anchorEl={tagAnchor.element}
      sx={{ '& .MuiList-root ': { padding: 0 } }}
      slotProps={{ root: { sx: { zIndex: 9999 } } }}
      disableAutoFocusItem={false}
    >
      <Grid container spacing={1} sx={{ maxWidth: '330px', p: 2 }}>
        {saleTags?.length ? (
          <Grid item xs={12} mb={1}>
            <Box display='flex' alignItems='flex-start' gap={1} flexWrap='wrap'>
              {saleTags.map((saleTag) => (
                <TagChip key={saleTag.id} label={saleTag.tag.name} onDelete={() => unlinkTagFromSale(saleTag)} />
              ))}
            </Box>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <Input
            fullWidth
            value={search}
            onChange={(e) => handleSearchValue(e.target.value)}
            placeholder={t('list.table.tags.search_or_create')}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit();
              }
            }}
            focused
          />
        </Grid>

        <Grid item xs={12}>
          <Button fullWidth type='submit' color='primary' variant='contained' onClick={handleSubmit}>
            {t('list.table.tags.add_tag')}
          </Button>
        </Grid>

        <Grid item xs={12} mt={1}>
          {!loading || appTags.length ? (
            <Typography
              component='p'
              variant='regularRegular'
              color={colors.gray[400]}
              sx={{
                gap: 1,
                display: 'flex',
                alignItems: 'center',
                mb: appTags.length ? 1 : 0,
                textTransform: appTags.length ? 'uppercase' : 'none',
              }}
            >
              {appTags.length
                ? t('list.table.tags.with_tags', { count: appTags.length })
                : t('list.table.tags.without_tags')}

              <Tag01 />
            </Typography>
          ) : null}

          {filteredAppTags.length > 0 ? (
            <Virtuoso
              data={filteredAppTags}
              totalCount={totalAppTags}
              style={{ height: '140px' }}
              endReached={() => {
                if (!hasNextPage) {
                  return;
                }

                loadNextPage();
              }}
              components={{ Footer: () => (hasNextPage ? <Loading /> : null) }}
              itemContent={(_, tag) => <TagChip sx={{ mb: 1 }} label={tag.name} onClick={() => linkTagToSale(tag)} />}
            />
          ) : null}

          {loading && !appTags.length ? <Loading /> : null}
        </Grid>

        <Hidden smUp>
          <Grid item xs={12} mt={1}>
            <Button fullWidth type='submit' color='primary' variant='outlined' onClick={onClose}>
              {t('list.table.tags.close_modal')}
            </Button>
          </Grid>
        </Hidden>
      </Grid>
    </Menu>
  );
};

export const TagChip = (props: ChipProps) => (
  <Chip
    {...props}
    size='small'
    color='primary'
    variant='outlined'
    deleteIcon={<CancelOutlined cursor='pointer' />}
    sx={{
      background: colors.blue[100],
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      ...props.sx,
    }}
  />
);

export default SaleTagsPopup;
