import { styled, AccordionSummary as MUIAccordionSummary, Box, Accordion as MUIAccordion } from '@mui/material';

export const AccordionSummary = styled(MUIAccordionSummary)<{ expanded?: boolean }>(({ theme, expanded }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0px !important',
  borderBottom: '2px solid',
  borderBottomColor: expanded ? theme.palette.colors.blue[500] : theme.palette.colors.gray[200],

  '& .MuiAccordionSummary-content': {
    marginBottom: 0,
  },
}));

export const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexFlow: 'row-reverse',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    with: 'fit-content',
    alignItems: 'flex-start',
  },
}));

export const CustomerInfo = styled(Box)(() => ({
  gap: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

export const Accordion = styled(MUIAccordion)(() => ({
  '&.Mui-disabled': {
    backgroundColor: 'transparent',
  },
}));

export const CoutryInfoWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 4,

  '& img': {
    width: '30px',
    height: '20px',
    objectFit: 'cover',
    maxWidth: '100%',
  },
}));
