import { Box, Hidden, IconButton, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Grid01, List, XClose } from '@untitled-ui/icons-react';
import { AccountSelectorListLayout } from '@/contexts/AccountContext';
import useAccount from '@/hooks/useAccount';

import Button from '@/components/Button';
import { Logout } from '@mui/icons-material';
import useAuth from '@/contexts/Auth/hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { Divider, TitleContainer } from './styles';
import theme from '@/theme';

export const ModalTitle = () => {

  const navigate = useNavigate();
  const { logout } = useAuth();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { setAccountSelectorListLayout, accountSelectorListLayout, setAccountSelectorOpen } = useAccount();

  const changeAccountSelectorListLayout = () => {
    setAccountSelectorListLayout(
      accountSelectorListLayout === AccountSelectorListLayout.Grid
        ? AccountSelectorListLayout.List
        : AccountSelectorListLayout.Grid,
    );
  };

  const handleLogout = () => {
    navigate('/login', { replace: true });
    logout();
  };

  const { t } = useTranslation(['accountSelector']);

  return (
    <Box display='flex' alignItems='center' justifyContent='space-between' width='100%'>
      <TitleContainer>
        <Typography variant='largeMedium' >{t('app_selector_title')}</Typography>
        {!isMobile &&
          <>
            <Divider />
            <Button startIcon={<Logout />} variant='text' size='medium' color='inherit' onClick={handleLogout}>
              {t('app_logout')}
            </Button>
          </>
        }
      </TitleContainer>

      <Box display='flex' alignItems='center' gap={1}>

        <Hidden smDown>
          <IconButton onClick={changeAccountSelectorListLayout}>
            {accountSelectorListLayout === AccountSelectorListLayout.Grid ? <List /> : <Grid01 />}
          </IconButton>
        </Hidden>

        <IconButton onClick={() => setAccountSelectorOpen(false)}>
          <XClose />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ModalTitle;
