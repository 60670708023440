import { Logo, LogoContainer } from './styles';
import LogoDarkDefault from '@/assets/logos/LogoDarkDefault.svg';
import LogoDarkShort from '@/assets/logos/LogoDarkShort.svg';
import LogoDefaultDefault from '@/assets/logos/LogoDefaultDefault.svg';
import LogoDefaultShort from '@/assets/logos/LogoDefaultShort.svg';

interface PlanneLogoProps {
  style?: 'default' | 'dark';
  size?: 'default' | 'short';
}

const PlanneLogo = (props: PlanneLogoProps) => {
  const { style = 'default', size = 'default' } = props;
  const availableLogos = {
    default: {
      default: {
        src: LogoDefaultDefault,
      },
      short: {
        src: LogoDefaultShort,
      },
    },
    dark: {
      default: {
        src: LogoDarkDefault,
      },
      short: {
        src: LogoDarkShort,
      },
    },
  };

  return (
    <LogoContainer href='/'>
      <Logo src={availableLogos[style][size].src} alt='Planne Logo' />
    </LogoContainer>
  );
};

export default PlanneLogo;
