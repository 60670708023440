import { Stack, styled } from '@mui/material';

export const StyleDateAndActionsContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(3, 4),

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2, 3),
  },
}));
