import { Ref, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, ToggleButton, ToggleButtonGroup, useMediaQuery } from '@mui/material';

import useAccount from '@/hooks/useAccount';
import { useTranslation } from 'react-i18next';
import useAnalytics from '@/hooks/analytics/useAnalytics';

import { Account, PlanSellerType } from '@/services/SellerApi';

import Input from '@/components/Form/Input';
import AccountCard from './AccountCard';

import { Divider, LogoutContainer, SearchWrapper } from './styles';
import { AccountSelectorListLayout } from '@/contexts/AccountContext';
import theme from '@/theme';
import useAuth from '@/contexts/Auth/hooks/useAuth';
import Button from '@/components/Button';
import { Logout } from '@mui/icons-material';

type AccountSelectorProps = {
  onSelect: (account: Account) => void;
  navigateAfterSelect?: boolean;
  parentRef?: Ref<HTMLDivElement>;
};

export const AccountSelector = (props: AccountSelectorProps) => {
  const { navigateAfterSelect = true, onSelect, parentRef } = props;

  const navigate = useNavigate();
  const { t } = useTranslation(['accountSelector']);
  const { track } = useAnalytics();
  const { logout } = useAuth();

  const { user } = useAuth();
  const { accounts, selectedAccount, selectAccount, accountSelectorListLayout, setAccountSelectorListLayout } =
    useAccount();

  const inputRef = useRef<HTMLInputElement>(null);

  const [search, setSearch] = useState<string>('');
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [sellerType, setSellerType] = useState<'all' | PlanSellerType>(user?.isSuperAdmin ? 'provider' : 'all');

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const downUpKeyStep = 3;
  const leftRightKeyStep = 1;

  const isGridLayout = accountSelectorListLayout === AccountSelectorListLayout.Grid;

  const handleAccountCardClick = (account: Account) => {
    track('accountSelector/select', {
      previousAccount: selectedAccount?.app?.name,
      accountSelected: account.app?.name,
    });

    selectAccount(account);

    onSelect(account);

    if (navigateAfterSelect) {
      navigate(`/${account.app?.code}`);
    }
  };

  const filteredAccounts = accounts
    .filter((account) => sellerType === 'all' || account.app?.plan?.sellerType === sellerType)
    .filter((account) => account.app?.name.toLowerCase().includes(search.toLowerCase()));

  const handleChangeSelectedItemIndex = (seletedItemIndex: number) => {
    setSelectedItemIndex(seletedItemIndex);

    const element = document.querySelector(`#account-card-${filteredAccounts[seletedItemIndex].id}`);
    if (!element) return;

    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();

      const step = isGridLayout ? downUpKeyStep : leftRightKeyStep;
      const nextSelectedItemIndex = selectedItemIndex + step;

      handleChangeSelectedItemIndex(nextSelectedItemIndex > filteredAccounts.length - 1 ? 0 : nextSelectedItemIndex);
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();

      const step = isGridLayout ? downUpKeyStep : leftRightKeyStep;

      if (selectedItemIndex >= step) {
        handleChangeSelectedItemIndex(selectedItemIndex - step);
      } else if (selectedItemIndex >= 0) {
        handleChangeSelectedItemIndex(filteredAccounts.length - 1);
      }
    } else if (e.key === 'ArrowRight' && isGridLayout) {
      e.preventDefault();

      if (selectedItemIndex < filteredAccounts.length - 1) {
        handleChangeSelectedItemIndex(selectedItemIndex + leftRightKeyStep);
      } else {
        handleChangeSelectedItemIndex(0);
      }
    } else if (e.key === 'ArrowLeft' && isGridLayout) {
      e.preventDefault();

      if (selectedItemIndex > 0) {
        handleChangeSelectedItemIndex(selectedItemIndex - leftRightKeyStep);
      } else {
        handleChangeSelectedItemIndex(filteredAccounts.length - 1);
      }
    } else if (e.key === 'Enter') {
      e.preventDefault();

      handleAccountCardClick(filteredAccounts[selectedItemIndex]);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (typeof parentRef === 'object' && parentRef !== null && 'current' in parentRef && parentRef.current) {
      parentRef.current.addEventListener('keydown', handleKeyDown);

      return () => {
        parentRef?.current?.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [selectedItemIndex, filteredAccounts]);

  useEffect(() => {
    if (isMobile) {
      setAccountSelectorListLayout(AccountSelectorListLayout.List);
    }
  }, [isMobile]);

  const handleLogout = () => {
    navigate('/login', { replace: true });
    logout();
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <SearchWrapper>
          <Input
            fullWidth
            value={search}
            inputRef={inputRef}
            placeholder={t('app_search_placeholder')}
            onChange={(e) => setSearch(e.target.value)}
          />
        </SearchWrapper>
      </Grid>

      {user?.isSuperAdmin ? (
        <Grid item xs={12} textAlign='center' sx={{ mb: 2 }}>
          <ToggleButtonGroup
            value={sellerType}
            size='small'
            exclusive
            onChange={(_, value) => setSellerType(value)}
            aria-label='accounts filter'
          >
            <ToggleButton value='all' aria-label='all apps' sx={{ textTransform: 'capitalize' }}>
              {t('sellerTypes.all')}
            </ToggleButton>
            <ToggleButton value='agent' aria-label='agent apps' sx={{ textTransform: 'capitalize' }}>
              {t('sellerTypes.agent')}
            </ToggleButton>
            <ToggleButton value='provider' aria-label='provider apps' sx={{ textTransform: 'capitalize' }}>
              {t('sellerTypes.provider')}
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <Grid container spacing={4}>
          {filteredAccounts.map((account, index) => (
            <Grid
              item
              xs={12}
              sm={isGridLayout ? 6 : 12}
              md={isGridLayout ? 4 : 12}
              id={`account-card-${account.id}`}
              key={`account-card-${account.id}`}
            >
              <AccountCard
                account={account}
                selected={selectedItemIndex === index}
                onClick={() => handleAccountCardClick(account)}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>

      {isMobile &&
        <LogoutContainer>
          <Divider />
          <Button startIcon={<Logout />} variant='text' size='medium' color='inherit' onClick={handleLogout}>
            {t('app_logout')}
          </Button>
        </LogoutContainer>
      }
    </Grid>
  );
};

export default AccountSelector;
