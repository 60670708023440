const isArray = Array.isArray;
const keyList = Object.keys;
const hasProp = Object.prototype.hasOwnProperty;

const isObject = (obj: unknown): obj is object => obj !== null && typeof obj === 'object';
const isSameRef = (a: unknown, b: unknown): boolean => a === b;

const isDeeplyEqual = (a: unknown, b: unknown): boolean => {
  if (isSameRef(a, b)) {
    return true;
  }

  if (isObject(a) && isObject(b)) {
    const arrA = isArray(a);
    const arrB = isArray(b);
    let i, length, key;

    if (arrA && arrB) {
      length = a.length;
      if (length !== b.length) {
        return false;
      }
      for (i = length; i-- !== 0; ) {
        if (
          !isDeeplyEqual(
            a[i] as Record<string | number | symbol, unknown>,
            b[i] as Record<string | number | symbol, unknown>,
          )
        ) {
          return false;
        }
      }
      return true;
    }

    if (arrA !== arrB) {
      return false;
    }

    const dateA = a instanceof Date,
      dateB = b instanceof Date;
    if (dateA !== dateB) {
      return false;
    }
    if (dateA && dateB) {
      return a.getTime() === b.getTime();
    }

    const regexpA = a instanceof RegExp,
      regexpB = b instanceof RegExp;
    if (regexpA !== regexpB) {
      return false;
    }
    if (regexpA && regexpB) {
      return a.toString() === b.toString();
    }

    const keys = keyList(a);
    length = keys.length;

    if (length !== keyList(b).length) {
      return false;
    }

    for (i = length; i-- !== 0; ) {
      if (!hasProp.call(b, keys[i])) {
        return false;
      }
    }

    for (i = length; i-- !== 0; ) {
      key = keys[i];
      if (!isDeeplyEqual((a as Record<string, unknown>)[key], (b as Record<string, unknown>)[key])) {
        return false;
      }
    }

    return true;
  }

  return Number.isNaN(a) && Number.isNaN(b);
};

export default isDeeplyEqual;
