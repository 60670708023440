import { LabelAndPrice } from '@/features/agenda/types';
import { Availability, ProductTariffType } from '@/services/SellerApi';

const getLabelAndPrice = (productTariffType: ProductTariffType): LabelAndPrice | undefined => {
  if (productTariffType.tariffType) {
    return {
      id: `productTariffType-${productTariffType.productId}-${productTariffType.tariffTypeId}`,
      label: productTariffType.tariffType?.name ?? '',
    };
  }
  return undefined;
};

const getAvailabilityProductTariffTypesLabelPrice = (availability: Availability): LabelAndPrice[] | undefined => {
  if (availability.productTariffTypes && availability.productTariffTypes.length > 0) {
    const productTariffTypes = availability.productTariffTypes.map(getLabelAndPrice).filter(Boolean);

    if (productTariffTypes.length > 0) {
      return productTariffTypes as LabelAndPrice[];
    }
  }
  return undefined;
};

export default getAvailabilityProductTariffTypesLabelPrice;
