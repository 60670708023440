import getUrlSearch from '@/features/config/context/utils/getUrlSearch';
import hasConfigOnUrl from '@/features/config/context/utils/hasParamsOnUrl';
import { UrlMapToState } from '@/features/config/types/urlMap';
import setNestedValue from '@/features/config/context/utils/setNestedValue';
import getNestedValue from '@/features/config/context/utils/getNestedValue';
import isSearchParamsEmpty from '@/features/config/context/utils/isSearchParamsEmpty';
import { ConfigContextStore } from '@/features/config/types/config';

type Params<T> = ConfigContextStore<T>;

const getLocalStorageAndSetUrlParams = <T>(
  searchParams: URLSearchParams,
  key: string,
  keysToPersistOnUrl: UrlMapToState<T>[],
) => {
  const configState = localStorage.getItem(key);
  const { state } = JSON.parse(configState!);

  keysToPersistOnUrl.forEach((urlParam) => {
    const configValue = getNestedValue(state, urlParam.stateKey as string);
    if (configValue) {
      if (isSearchParamsEmpty(configValue)) {
        searchParams.delete(urlParam.urlKey);
        return;
      }
      searchParams.set(urlParam.urlKey, configValue as string);
    }
  });

  window.history.replaceState(null, '', `?${searchParams.toString()}`);

  return { state };
};

const getUrlParamsAndSetLocalStorage = <T>(
  key: string,
  currentParams: Record<string, unknown>,
  stateKeys: string[],
  keysToPersistOnStorage: string[],
  keysToPersistOnUrl: UrlMapToState<T>[],
) => {
  const stateToSaveOnLocalStorage: { state: Record<string, Record<string, unknown>> } = {
    state: {},
  };

  keysToPersistOnStorage.forEach((keyToPersist) => {
    if (stateKeys.includes(keyToPersist)) {
      const currentParamKey = keysToPersistOnUrl.find((param) => param.stateKey === keyToPersist);
      if (currentParamKey && currentParams[currentParamKey.urlKey]) {
        setNestedValue(stateToSaveOnLocalStorage.state, keyToPersist, currentParams[currentParamKey.urlKey]);
      }
    }
  });

  if (Object.keys(stateToSaveOnLocalStorage.state).length > 0) {
    const stateToSaveString = JSON.stringify(stateToSaveOnLocalStorage);
    localStorage.setItem(key, stateToSaveString);
    return stateToSaveOnLocalStorage;
  }

  return { state: {} };
};

const syncStateWithStorageAndUrl = <T>(params: Params<T>) => {
  const { keys, localStorageKey } = params;
  const searchParams = getUrlSearch();
  const currentParams = Object.fromEntries(searchParams);
  const paramsToCheck = keys.filter((param) => param.urlKey);
  const keysToPersistOnUrl = paramsToCheck.map(
    (param) =>
      ({
        stateKey: param.stateKey as string,
        urlKey: param.urlKey,
      }) as UrlMapToState<T>,
  );

  if (hasConfigOnUrl(paramsToCheck.map((param) => param.urlKey as string))) {
    const stateKeys = paramsToCheck.map((param) => param.stateKey as string);
    const keysToPersistOnStorage = keys.filter((param) => param.persistOnLocalStorage).map((param) => param.stateKey);
    return getUrlParamsAndSetLocalStorage(
      localStorageKey,
      currentParams,
      stateKeys,
      keysToPersistOnStorage,
      keysToPersistOnUrl,
    );
  }

  if (localStorage.getItem(localStorageKey) !== null) {
    return getLocalStorageAndSetUrlParams(searchParams, localStorageKey, keysToPersistOnUrl);
  }

  return { state: {} };
};

export default syncStateWithStorageAndUrl;
