import { Stack } from '@mui/system';
import { useMemo } from 'react';

import { Product } from '@/services/SellerApi';
import { formatCents } from '@/utils';
import { SchedulingTariffGroup, TariffGroupPriceInfo } from '@/services/sellerApiQuery/model';

type Props = {
  product: Product;
  tariffGroup: SchedulingTariffGroup;
};

const getPriceString = (price?: TariffGroupPriceInfo) => {
  if (price?.minPriceCents && price?.maxPriceCents) {
    return `: ${formatCents(price?.minPriceCents)} - ${formatCents(price?.maxPriceCents)}`;
  }
  if (price?.defaultPriceCents) {
    return `: ${formatCents(price?.defaultPriceCents)}`;
  }
  return null;
};

const SchedulingWithPrice = (props: Props) => {
  const { product, tariffGroup } = props;

  const name = useMemo(() => product?.internalName ?? product?.name ?? '', [product]);
  const price = useMemo(() => tariffGroup?.priceInfo, [tariffGroup]);
  const tariffGroupName = useMemo(() => tariffGroup?.name, [tariffGroup]);

  const priceString = useMemo(() => getPriceString(price), [price]);

  return (
    <Stack sx={{ textAlign: 'left' }}>
      <div>{name}</div>
      {tariffGroupName && priceString ? (
        <div>
          {tariffGroupName}
          {priceString}
        </div>
      ) : null}
    </Stack>
  );
};

export default SchedulingWithPrice;
