import { ErrorWrapper } from './styles';

interface ErrorComponentRootProps {
  children: React.ReactNode;
}
export const ErrorComponentRoot = (props: ErrorComponentRootProps) => {
  const { children } = props;
  return <ErrorWrapper>{children}</ErrorWrapper>;
};

export default ErrorComponentRoot;
