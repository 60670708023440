import { AxiosError } from 'axios';

import useAnalytics from '@/hooks/analytics/useAnalytics';
import { ErrorTypes } from '@/interfaces/errorTypes';
import Bugsnag from '@/services/bugsnag';
import { NotifiableError } from '@bugsnag/js';
import { AnalyticsEvents } from '@/hooks/analytics/enum/analyticsEvents';

type ErrorExtra = Record<string, string | Array<string>> | null;

interface ErrorHandlerFunction {
  /**
   * @param err - Error object
   * @param pagePath - AnalyticsEvents enum
   * @returns ErrorHandleReturnType
   *
   */
  <T = string>(err: unknown, pagePath?: AnalyticsEvents): ErrorHandleReturnType<T>;
  /** @deprecated
   * Use errorHandler with AnalyticsEvents enum instead
   * */
  <T = string>(err: unknown, pagePath?: string): ErrorHandleReturnType<T>;
}

type GetErrorMessageReturnType<ER = string> = {
  extra?: ErrorExtra;
  path?: string;
  type: ErrorTypes;
  rule: ER;
};

type ErrorHandleReturnType<T = string> = {
  errors: GetErrorMessageReturnType<T>[] | undefined;
  status?: number;
  statusText?: string;
};

const ErrorValidationRules = {
  Unique: 'unique',
};

type CustomError = AxiosError<{ errors?: [{ path: string; type: string; extra?: ErrorExtra }] }>;

export const getGenericErrorMessage = (
  status: number | string | undefined,
): GetErrorMessageReturnType[] | undefined => {
  switch (status) {
    case 400:
    case 'BAD_REQUEST':
      return [{ rule: 'generic_bad_request', type: ErrorTypes.BAD_REQUEST }];

    case 401:
    case 'UNAUTHORIZED':
      return [{ rule: 'generic_unauthorized', type: ErrorTypes.UNAUTHORIZED }];

    case 403:
    case 'FORBIDDEN':
      return [{ rule: 'generic_forbidden', type: ErrorTypes.FORBIDDEN }];

    case 404:
    case 'NOT_FOUND':
      return [{ rule: 'generic_not_found', type: ErrorTypes.NOT_FOUND }];

    case 500:
    case 'UNPROCESSABLE':
      return [{ rule: 'generic_internal_server_error', type: ErrorTypes.UNPROCESSABLE }];

    default:
      undefined;
  }
};

export const validationErrors = <T = string>(err: CustomError): GetErrorMessageReturnType<T>[] | undefined => {
  if (err.response?.status === 422) {
    const errorData = err.response?.data;

    if (errorData?.errors) {
      return errorData.errors.map(
        ({ extra, path, type }) =>
          ({
            path,
            extra,
            rule: type,
            type: ErrorTypes.VALIDATION,
          }) as GetErrorMessageReturnType<T>,
      );
    }

    return undefined;
  }

  return undefined;
};

export const extractFieldNameFromPath = (path: string, step?: number) =>
  path
    .split('.')
    .slice(step ?? 0)
    .join('');

export const useErrorHandler = () => {
  const { track } = useAnalytics();

  const errorHandler: ErrorHandlerFunction = (err: unknown, pagePath?: string | AnalyticsEvents) => {
    const typedError = err as CustomError;

    const errorComplement = {
      status: typedError?.response?.status,
      statusText: typedError?.response?.statusText,
    };

    let errors = validationErrors(typedError);

    if (errors && errors?.length > 0) {
      return {
        errors,
        ...errorComplement,
      };
    }

    errors = getGenericErrorMessage(typedError?.response?.status);

    if (errors) {
      return {
        errors,
        ...errorComplement,
      };
    }

    track(pagePath ?? 'unrecognized-error-page', {
      error: typedError,
      ...errorComplement,
    });

    Bugsnag.notify(typedError);
    console.error(typedError);
    return {
      errors: undefined,
      ...errorComplement,
    };
  };

  const reportError = (error: unknown) => {
    Bugsnag.notify(error as NotifiableError);
    console.error(error);
  };

  return {
    errorHandler,
    reportError,
    ErrorValidationRules,
    extractFieldNameFromPath,
  };
};

export default useErrorHandler;
