import { Grid } from '@mui/material';
import { styled, css } from '@mui/material/styles';
import LoginWallpaper from '@/assets/wallpaper.svg';

export const ArtSection = styled(Grid)(
  ({ theme }) => css`
    background-image: url(${LoginWallpaper});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 50%;
    position: fixed;
    margin: 0;

    section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;

      div {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0 16px;
        gap: 8px;

        background: ${theme.palette.colors.green[600]};
      }
    }

    ${theme.breakpoints.values.md} {
      img {
        width: 350px;
        height: 87px;
      }
    }
  `,
);
