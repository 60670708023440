import { styled } from '@mui/material';

export const StyledList = styled('ul')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0),
  padding: 0,
  margin: 0,
}));

export const StyledItem = styled('li')(({ theme }) => ({
  ...theme.typography.extraSmallRegular,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  listStyle: 'none',
  width: '100%',
}));
