import { Box, BoxProps } from '@mui/material';
import { Label } from '@/components/Form/Input/styles';
import DatePicker from '@/components/Form/DatePicker';
import { isValid, setHours, setMinutes, setSeconds } from 'date-fns';
import { InputProps } from '../Form/Input';
import { Remove } from '@mui/icons-material';
import theme from '@/theme';

type SlotItemProps = {
  name?: string;
  label?: string;
  tooltipProps?: InputProps['tooltipProps'];
};

type SlotProps = {
  since?: SlotItemProps;
  until?: SlotItemProps;
  wrapper?: BoxProps;
};

type Props = {
  label?: string;
  since?: Date | null;
  until?: Date | null;
  slotProps?: SlotProps;
  onChange: (date: Date | null, inputName: string | 'since' | 'until') => void;
};

export const ComposedDatePicker = (props: Props) => {
  const { onChange, since, until, label, slotProps } = props;

  const hasSlotPropsLabel = slotProps?.since?.label || slotProps?.until?.label;

  const checkValidDate = (date: Date | null) => {
    if (!date) return false;

    if (!isValid(date)) return false;

    const year = String(date.getFullYear());

    if (year.length !== 4) {
      return false;
    }

    return true;
  };

  const handleChange = (date: Date | null, key: 'since' | 'until', inputName?: string) => {
    let newDate: Date | null = date;

    newDate = checkValidDate(date) ? date : null;

    if (newDate) {
      newDate =
        key === 'since'
          ? setHours(setMinutes(setSeconds(date as Date, 0), 0), 0)
          : setHours(setMinutes(setSeconds(date as Date, 59), 59), 23);
    }

    onChange(newDate, inputName ?? key);
  };

  return (
    <Box width='100%'>
      {label ? <Label>{label}</Label> : null}
      <Box display='flex' flexDirection='column' gap={1} mt={1} {...slotProps?.wrapper}>
        <DatePicker
          fullWidth
          value={since}
          onChange={(date) => handleChange(date, 'since', slotProps?.since?.name)}
          slotProps={{ field: { clearable: true } }}
          format='dd/MM/yyyy'
          {...slotProps?.since}
        />

        {slotProps?.wrapper?.flexDirection === 'row' ? (
          <Remove sx={{ color: theme.palette.colors.gray[400], marginTop: hasSlotPropsLabel ? 3 : 0 }} />
        ) : null}

        <DatePicker
          fullWidth
          value={until}
          onChange={(date) => handleChange(date, 'until', slotProps?.until?.name)}
          slotProps={{ field: { clearable: true } }}
          format='dd/MM/yyyy'
          {...slotProps?.until}
        />
      </Box>
    </Box>
  );
};

export default ComposedDatePicker;
