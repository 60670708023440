import { styled } from '@mui/material/styles';

export const TitleContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
}));

export const Divider = styled('div')(({ theme }) => ({
    width: '1px',
    height: '20px',
    padding: theme.spacing(1, 0),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.grey[400],
}));
