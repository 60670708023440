import { styled } from '@mui/material';
import { PickersLayout } from '@mui/x-date-pickers';

export const StyledLayout = styled(PickersLayout)(({ theme }) => ({
  '.MuiPickersDay-root': {
    fontWeight: 400,
    borderRadius: 0,
    color: theme.palette.common.black,
  },
  '.MuiPickersDay-today': {
    borderWidth: 1,
    border: '1px solid',
    borderColor: theme.palette.colors.blue[500],
  },
  '.MuiPickersDay-root.Mui-selected': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.colors.blue[500],
  },
  '.MuiPickersDay-root.Mui-selected:hover': {
    backgroundColor: theme.palette.colors.blue[500],
  },
  '.MuiPickersDay-dayOutsideMonth': {
    opacity: 0.38,
  },
  '.MuiPickersDay-custom-unavailableDay': {
    pointerEvents: 'none',
    cursor: 'not-allowed',
    backgroundColor: 'transparent',
  },
  '.MuiPickersDay-custom-availableDay': {
    fontSize: '0.875rem',
    color: theme.palette.colors.blue[700],
    backgroundColor: theme.palette.colors.blue[100],
  },
  '.MuiPickersDay-custom-availableDay:hover': {
    backgroundColor: theme.palette.colors.blue[100],
  },
}));
