import { useTranslation } from 'react-i18next';
import { Content, ReservationData, Tariffs, Title } from './styles';
import { FormControlLabel, Typography } from '@mui/material';
import Chip from '@/components/Chip';
import Switch from '@/components/Switch';
import { Stack, useMediaQuery } from '@mui/system';
import { IconCalendarCheck, IconHelp } from '@tabler/icons-react';
import Tooltip from '@/components/Tooltip';
import { Reservation, ReservationTariff, SellingMode } from '@/services/sellerApiQuery/model';
import { useMemo } from 'react';
import { getReservationDateTime } from '@/features/reservation/utils/getReservationDateTime';
import { format } from 'date-fns';
import Button from '@/components/Button';
import useAccount from '@/hooks/useAccount';
import theme from '@/theme';

type Props = {
  reservation: Reservation;
  reservationTariffs: ReservationTariff[];
  isChaosMode: boolean;
  onChaosModeChange: (isChaosMode: boolean) => void;
};

const ReservationDetails = (props: Props) => {
  const { reservation, reservationTariffs, isChaosMode, onChaosModeChange } = props;
  const { product } = reservation;

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { selectedAccount, hasPermission } = useAccount();
  const { t } = useTranslation(['reservation'], { keyPrefix: 'reschedule_form' });

  const reservationDateTime = useMemo(() => {
    const isDateOnly = product?.sellingMode === SellingMode.with_date_only;
    const formatString = isDateOnly ? 'dd/MM/yyyy' : 'dd/MM/yyyy HH:mm';
    return format(getReservationDateTime(reservation)!, formatString);
  }, [reservation, product?.sellingMode]);

  const tariffCount = 4;

  const UnavailableOptionsSwitch = () => (
    <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
      <FormControlLabel
        sx={{ mr: 1, ml: 0 }}
        label={t('details.select_unavailable_options')}
        control={
          <Switch
            checked={isChaosMode}
            onChange={(_, value: boolean) => onChaosModeChange(value)}
            disabled={!hasPermission('reservations', 'RescheduleReservation')}
            sx={{ mr: 2 }}
          />
        }
      />

      <Tooltip title={t('details.select_unavailable_options_tooltip')}>
        <IconHelp />
      </Tooltip>
    </Stack>
  );

  return (
    <Content>
      <Title>
        <Typography variant='largeSemiBold'>{t('title')}</Typography>

        {!isMobile ? <UnavailableOptionsSwitch /> : null}
      </Title>

      <Stack sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: '1rem' }}>
        <ReservationData>
          <Typography variant='regularSemiBold'>{product?.internalName ?? product?.name}</Typography>

          <Typography variant='smallRegular'>{t('details.date_time', { reservationDateTime })}</Typography>

          <Tariffs>
            <Typography variant='smallRegular'>{t('details.tariffs', { tariffCount })}</Typography>

            {reservationTariffs.map((reservationTariff) => (
              <Chip
                key={reservationTariff.id}
                label={`${reservationTariff.tariff?.type?.name} (${reservationTariff.quantity})`}
                size='small'
              />
            ))}
          </Tariffs>
        </ReservationData>

        <Stack sx={{ flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
          <Button
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            target='_blank'
            href={`/${selectedAccount?.app?.code}/reservations?highlighted_reservation_id=${reservation.id}&nd=true`}
            variant='outlined'
            startIcon={<IconCalendarCheck />}
            fullWidth
          >
            {t('details.view_reservation')}
          </Button>
        </Stack>

        {isMobile ? <UnavailableOptionsSwitch /> : null}
      </Stack>
    </Content>
  );
};

export default ReservationDetails;
