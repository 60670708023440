import { useTranslation } from 'react-i18next';
import { Box, Chip, CircularProgress, Paper, Typography, Tooltip } from '@mui/material';

import { colors } from '@/theme';
import { HelpOutline } from '@mui/icons-material';

type Props = {
  title: string;
  value: string;
  quantity?: number;
  helper?: boolean;
  valueColor?: string;
  loading?: boolean;
};
const BigNumberCard = (props: Props) => {
  const { title, value, quantity, helper, valueColor, loading = false } = props;

  const { t } = useTranslation(['commissions']);

  return (
    <Paper elevation={2}>
      {!loading ? (
        <Box p={2} display='flex' flexDirection='column' gap={0.5} justifyContent='flex-start'>
          <Box display='flex' alignItems='center'>
            <Typography variant='regularRegular' color={colors.gray[500]}>
              {title}
            </Typography>

            {helper ? (
              <Tooltip
                placement='top'
                arrow
                title={t('cards.commissions_card_tooltip_description')}
                leaveTouchDelay={60000}
                enterTouchDelay={0}
              >
                <HelpOutline sx={{ color: colors.gray[400], cursor: 'pointer', ml: 0.5 }} fontSize='small' />
              </Tooltip>
            ) : null}

            {quantity ? (
              <Chip
                size='small'
                color='primary'
                variant='outlined'
                label={quantity}
                sx={{ background: colors.blue[100], border: 'none', ml: 1 }}
              />
            ) : null}
          </Box>

          <Typography variant='regularBold' color={valueColor ?? colors.gray[800]}>
            {value}
          </Typography>
        </Box>
      ) : null}

      {loading ? (
        <Box display='flex' alignItems='center' justifyContent='center' height='100%' p={3} width='100%'>
          <CircularProgress color='primary' />
        </Box>
      ) : null}
    </Paper>
  );
};

export default BigNumberCard;
