// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setNestedValue = (obj: any, keyPath: string, newValue: any): void => {
  const keys = keyPath.split('.');

  keys.slice(0, -1).forEach((key) => {
    if (!(key in obj)) {
      obj[key] = {};
    }
    obj = obj[key];
  });

  obj[keys[keys.length - 1]] = newValue;

  return obj;
};

export default setNestedValue;
