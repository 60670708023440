import { useMemo, useCallback } from 'react';
import { Stack, StackProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import theme from '@/theme';
import Chip from '@/components/Chip';
import { RecurrenceWeekdayEnum } from '@/services/SellerApi';
import Checkbox from '@/components/Form/Checkbox';

interface Props {
  gap?: number;
  value?: RecurrenceWeekdayEnum[] | null;
  justifyContent?: StackProps['justifyContent'];
  onChange: (days: RecurrenceWeekdayEnum[]) => void;
}

const WeekDaysSelect = (props: Props) => {
  const { value, onChange, gap, justifyContent = 'space-between' } = props;

  const { t } = useTranslation(['ui']);

  const isAllSelected = useMemo(
    () => !!(value && value.length === Object.values(RecurrenceWeekdayEnum).length && value.length > 0),
    [value],
  );

  const daysOfWeek = useMemo(() => {
    const days = Object.values(RecurrenceWeekdayEnum);
    return days.map((day) => {
      return {
        label: t(`weekday.${day}`),
        value: day,
      };
    });
  }, []);

  const changeWeekdays = (day: RecurrenceWeekdayEnum) => {
    if (!value) {
      return [day];
    }

    if (value.includes(day)) {
      return value.filter((weekday) => weekday !== day);
    } else {
      return [...value, day];
    }
  };

  const weekdayChipStyle = (day: RecurrenceWeekdayEnum) => {
    let bgColor = theme.palette.primary[100];
    let textColor = theme.palette.primary[700];

    if (value && value.includes(day)) {
      bgColor = theme.palette.colors.primary[500];
      textColor = theme.palette.colors.primary[50];
    }

    return { background: bgColor, font: textColor };
  };

  const toggleAll = useCallback(() => {
    if (isAllSelected) {
      onChange([]);
    } else {
      onChange(Object.values(RecurrenceWeekdayEnum));
    }
  }, [isAllSelected, onChange]);

  return (
    <Stack sx={{ flexDirection: 'column', gap: 5 }}>
      <Stack direction='row' justifyContent={justifyContent} gap={gap}>
        {daysOfWeek.map((day) => (
          <Stack key={day.value} onClick={() => onChange(changeWeekdays(day.value))} sx={{ cursor: 'pointer' }}>
            <Chip
              size='small'
              label={day.label[0].toUpperCase()}
              color={weekdayChipStyle(day.value as unknown as RecurrenceWeekdayEnum)}
            />
          </Stack>
        ))}
      </Stack>
      <Stack sx={{ width: 'fit-content' }}>
        <Checkbox label={t('all')} checked={isAllSelected} size='small' onChange={toggleAll} />
      </Stack>
    </Stack>
  );
};

export default WeekDaysSelect;
