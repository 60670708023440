import { Stack, Typography } from '@mui/material';
import { IconBarrierBlock } from '@tabler/icons-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { StyledCard } from '@/features/agenda/components/SchedulingCalendar/AgendaWeeklyView/WeeklySlot/styles';
import isAvailabilityOccupationFull from '@/features/agenda/utils/availability/isAvailabilityOccupationFull';
import { getAvailabilityQuantitiesText } from '@/features/agenda/utils/getAvailabilityQuantitiesText';
import { Availability } from '@/services/SellerApi';
import AvailabilityItensList from '@/features/agenda/components/AvailabilityItensList';
import { StyledAvailabilityCard } from '@/features/agenda/components/SchedulingCalendar/styles';

type Props = {
  availability: Availability;
};

const WeeklySlotAvailability = (props: Props) => {
  const { availability } = props;

  const { t } = useTranslation(['agenda']);
  const { search } = useLocation();

  const isOccupationFull = useMemo(() => isAvailabilityOccupationFull(availability), [availability]);

  const getOccupation = useMemo(() => {
    return (
      <StyledCard.Title.Occupation
        full={isOccupationFull}
      >{`(${getAvailabilityQuantitiesText(availability)})`}</StyledCard.Title.Occupation>
    );
  }, [availability]);

  const getTime = useMemo(() => {
    if (availability.fromTime && availability.toTime) {
      return <Typography variant='extraSmallRegular'>{`${availability.fromTime} - ${availability.toTime}`}</Typography>;
    }
  }, [availability]);

  return (
    <StyledCard.Root href={`./availabilities/${availability.id}${search}`}>
      <StyledAvailabilityCard full={isOccupationFull}>
        <Stack>
          <IconBarrierBlock width={16} height={16} />
        </Stack>

        <StyledCard.Title.Text full={isOccupationFull}>
          {t(`filter.availability_type.${availability.type}`)}
        </StyledCard.Title.Text>
        {getOccupation}
      </StyledAvailabilityCard>
      <StyledCard.Items>
        {getTime}
        <AvailabilityItensList availability={availability} />
      </StyledCard.Items>
    </StyledCard.Root>
  );
};

export default WeeklySlotAvailability;
