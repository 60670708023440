import { Menu, MenuItem } from '@mui/material';
import { Stack, useMediaQuery } from '@mui/system';
import { IconDotsVertical } from '@tabler/icons-react';
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';

import Button from '@/components/Button';
import { Action } from '@/interfaces/actions';
import { IPageLayoutActions } from '@/layouts/PageLayout/PageLayoutTypes';
import { SecondaryAction } from '@/layouts/PageLayout/styles';
import theme from '@/theme';

type UnsafeProps = Record<string, unknown>;

interface PageLayoutActionsProps {
  actions?: IPageLayoutActions;
  handleOpenSecondaryMenu?: (event: React.MouseEvent<HTMLElement>) => void;
}

const clearUnsafeProps = (props: UnsafeProps) => {
  const safeProps = { ...props };
  delete safeProps.Icon;

  return safeProps;
};

const PrimaryChildren = (props: {
  allChildren: Action[];
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  open: boolean;
}) => {
  const { allChildren, anchorEl, handleClose, open } = props;
  return (
    <Menu
      id='basic-menu'
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      {allChildren.map((action, index) =>
        action ? (
          <MenuItem
            key={index}
            component={Link}
            to={action.href as string}
            sx={{ display: 'flex', gap: theme.spacing(2), alignItems: 'center' }}
            onClick={(event) =>
              action.onClick && action.onClick(event as unknown as React.MouseEvent<HTMLButtonElement>)
            }
          >
            {action.Icon ? action.Icon : null}

            {action.label || action.title}
          </MenuItem>
        ) : null,
      )}
    </Menu>
  );
};

const PrimaryAction = (primaryAction: Action) => {
  const hasChildren = primaryAction.children && primaryAction.children.length > 0;
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <Fragment>
      <Button
        color='primary'
        variant='contained'
        sx={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center', whiteSpace: 'nowrap' }}
        {...(hasChildren
          ? {
              onClick: handleClick,
              'aria-controls': open ? 'basic-menu' : undefined,
              'aria-haspopup': 'true',
              'aria-expanded': open ? 'true' : undefined,
            }
          : { ...clearUnsafeProps(primaryAction as unknown as UnsafeProps) })}
      >
        {primaryAction.Icon ? primaryAction.Icon : null}

        {isTablet ? null : primaryAction.label}
      </Button>

      {hasChildren ? (
        <PrimaryChildren
          allChildren={primaryAction.children!}
          anchorEl={anchorEl}
          handleClose={handleClose}
          open={open}
        />
      ) : null}
    </Fragment>
  );
};

const ActionsList = (props: PageLayoutActionsProps) => {
  const { actions, handleOpenSecondaryMenu } = props;
  return (
    <Stack direction='row' spacing={{ xs: 1, md: 2 }} alignItems='center'>
      {actions?.secondary?.length && actions.secondary.length === 1 ? (
        <SecondaryAction color='primary' {...clearUnsafeProps(actions.secondary[0] as UnsafeProps)}>
          {actions.secondary[0].Icon ? actions.secondary[0].Icon : null}

          {actions.secondary[0]?.label ? actions.secondary[0].label : null}
        </SecondaryAction>
      ) : handleOpenSecondaryMenu ? (
        <SecondaryAction color='primary' onClick={handleOpenSecondaryMenu}>
          <IconDotsVertical />
        </SecondaryAction>
      ) : null}

      {actions?.primary ? <PrimaryAction {...actions.primary} /> : null}
    </Stack>
  );
};

const PageLayoutActions = (props: PageLayoutActionsProps) => {
  const { actions, handleOpenSecondaryMenu } = props;

  return <ActionsList actions={actions} {...(handleOpenSecondaryMenu ? { handleOpenSecondaryMenu } : undefined)} />;
};

export default PageLayoutActions;
