import { z } from 'zod';
import { cnpj, cpf } from 'cpf-cnpj-validator';

import { removeMask } from '@/utils/masks';

const notFequiredField = z.string().optional();
const requiredField = z.string({ required_error: 'Campo obrigatório' }).nonempty({ message: 'Campo obrigatório' });

export const schema = z
  .object({
    contactPhone: notFequiredField.superRefine((value, ctx) => {
      if (!value) return;

      if (!removeMask(value)) {
        return ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Campo obrigatório',
        });
      }

      // the real phone number after DDI
      if (!value.split(' ')[1]) {
        return ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Número de telefone inválido',
        });
      }
    }),

    identifier: requiredField.refine((value) => cpf.isValid(value) || cnpj.isValid(value), {
      message: 'CPF ou CNPJ inválido',
    }),

    corporateName: notFequiredField,
    fantasyName: notFequiredField,
    establishmentType: z
      .object({
        name: notFequiredField,
        id: notFequiredField,
        mcc: notFequiredField,
        updatedAt: notFequiredField,
        createdAt: notFequiredField,
      })
      .optional(),
    openedAt: z
      .date({ required_error: 'Campo obrigatório', invalid_type_error: 'Data inválida.' })
      .min(new Date(1900, 0, 1), { message: 'Data inválida.' })
      .max(new Date(), { message: 'Data inválida.' })
      .optional(),
  })
  .superRefine((value, ctx) => {
    const isCPF = cpf.isValid(value.identifier);
    const isCNPJ = cnpj.isValid(value.identifier);

    if (isCPF || isCNPJ) {
      if (!value.establishmentType?.id && value.establishmentType?.id !== null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['establishmentType'],
          message: 'Campo obrigatório',
        });
      }

      if (!value.corporateName) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['corporateName'],
          message: 'Campo obrigatório',
        });
      }

      if (!value.contactPhone) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['contactPhone'],
          message: 'Campo obrigatório',
        });
      }
    }

    if (isCNPJ) {
      if (!value.fantasyName) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['fantasyName'],
          message: 'Campo obrigatório',
        });
      }

      if (!value.openedAt) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['openedAt'],
          message: 'Campo obrigatório',
        });
      }
    }
  });
