import { Box, Autocomplete, createFilterOptions } from '@mui/material';

import ddi from '@/data/ddi.json';
import Input from '@/components/Form/Input';

type DDIType = {
  mask: string | string[];
  code: string;
  phone: string;
  image: string;
  label: string;
  emoji: string;
  unicode: string;
};

type Props = {
  onChange: (value: DDIType | null) => void;
  error?: boolean;
  value: string;
  id?: string;
  disabled?: boolean;
};

const filterOptions = createFilterOptions({
  stringify: (option: DDIType) => `${option.phone} ${option.code} ${option.label} ${option.unicode}`,
});

const sortDDI = () => ddi.sort((a, b) => (a.phone && b.phone && a.phone > b.phone ? 1 : -1));

const sortedDDI = sortDDI();

export const DdiSelector = (props: Props) => {
  const { onChange, value, error, id, disabled } = props;
  const defaultDdi = sortedDDI.find((d) => d.phone === value);

  return (
    <Autocomplete
      id={id}
      sx={{
        width: 130,
        position: 'relative',
        '& .MuiInputBase-root': { height: 47, padding: '5px', paddingRight: '0px !important' },
      }}
      popupIcon={null}
      disableClearable
      options={sortedDDI}
      disabled={disabled}
      autoHighlight
      filterOptions={filterOptions}
      onChange={(_, value) => onChange(value)}
      defaultValue={defaultDdi}
      getOptionLabel={(option) => `${option.image} ${option.phone}`}
      renderOption={(props, option) => (
        <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img loading='lazy' width='20' src={option.image} alt={option.label} />
          <span>{option.phone}</span>
        </Box>
      )}
      renderInput={(params) => {
        const { inputProps, ...restOfParams } = params;
        const { value, ...restOfInputProps } = inputProps as { value: string };
        const [imageSrc, inputValue] = (value ?? '').split(' ');

        return (
          <>
            {imageSrc && imageSrc.startsWith('http') ? (
              <Box sx={{ position: 'absolute', zIndex: 1, top: 14, left: 12 }}>
                <img loading='lazy' width='24' src={imageSrc} alt={inputValue} />
              </Box>
            ) : null}
            <Input
              {...restOfParams}
              error={error}
              disabled={disabled}
              inputProps={{
                ...restOfInputProps,
                value: inputValue,
              }}
              sx={{
                '& fieldset': { borderRadius: '4px 0px 0px 4px' },
              }}
            />
          </>
        );
      }}
    />
  );
};

export default DdiSelector;
