import { useInfiniteQuery } from '@tanstack/react-query';
import { format } from 'date-fns';

import { AgendaConfigState, DateRange } from '@/features/agenda/types';
import useGetAvailabilities from '@/features/agenda/usecases/useGetAvailabilities';
import useAccount from '@/hooks/useAccount';
import { AvailabilityTypeEnum } from '@/services/SellerApi';
import { useConfig } from '@/features/config/useConfig';
import normalizeArray from '@/utils/normalizeArray';

const useLoadInfiniteAvailabilitiesQuery = () => {
  const { config } = useConfig<AgendaConfigState>();
  const { selectedAccount } = useAccount();
  const { request: getAvailabilities } = useGetAvailabilities();

  const useRequestQuery = (params: DateRange, active = false, pageSize = 10) => {
    const { since, until } = params;
    const sinceKey = since ? format(since, "yyyy-MM-dd'T00:00:00Z'") : '';
    const untilKey = until ? format(until, "yyyy-MM-dd'T00:00:00Z'") : '';

    const productsId = normalizeArray<string>(config?.products);
    const types = normalizeArray<AvailabilityTypeEnum>(config?.availabilityType);
    const appId = selectedAccount?.appId as string | null;

    return useInfiniteQuery({
      queryKey: ['availabilities', appId, sinceKey, untilKey, productsId, types, pageSize],
      queryFn: async ({ pageParam, signal }) => {
        const res = await getAvailabilities({
          queryKey: ['availabilities', appId, sinceKey, untilKey, pageParam, productsId, types, pageSize],
          signal,
          pageParam,
          meta: undefined,
        });
        return res;
      },
      initialPageParam: 0,
      getNextPageParam: (lastPage) => lastPage?.nextCursor ?? null,
      enabled: !!appId && !!since && !!until && active,
      staleTime: 1000,
    });
  };

  return [useRequestQuery];
};

export default useLoadInfiniteAvailabilitiesQuery;
