import { useTranslation } from 'react-i18next';

import { SellingMode } from '@/services/SellerApi';
import Chip from '@/components/Chip';
import { IconCalendar, IconCalendarOff, IconClockHour7 } from '@tabler/icons-react';
import { Stack } from '@mui/system';

interface ProductSellingModeChipProps {
  sellingMode: SellingMode;
  size?: 'small' | 'medium';
}

const ProductSellingModeChip = (props: ProductSellingModeChipProps) => {
  const { sellingMode, size = 'small' } = props;

  const { t } = useTranslation(['products']);

  const getIcon = (sellingMode: SellingMode) => {
    switch (sellingMode) {
      case 'with_date_and_time':
        return <IconClockHour7 size={16} />;
      case 'with_date_only':
        return <IconCalendar size={16} />;
      case 'without_date':
        return <IconCalendarOff size={16} />;
      default:
    }
  };

  const getLabel = (sellingMode: SellingMode) => {
    return (
      <Stack flexDirection='row' alignItems='center' gap={1}>
        {getIcon(sellingMode)}
        {t(`selling_mode.${sellingMode}`)}
      </Stack>
    );
  };

  return <Chip label={getLabel(sellingMode)} size={size} />;
};

export default ProductSellingModeChip;
