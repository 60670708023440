import { Box, Grid, Stack } from '@mui/material';
import { TooltipProps } from '@mui/material/Tooltip';
import { ReactNode } from 'react';

import { Label } from '@/components/Form/Input/styles';
import TooltipInputElement from '@/components/InputTooltip/TooltipInputElement';

type Props = {
  required?: boolean;
  label?: ReactNode;
  id?: string;
  tooltipProps?: Omit<TooltipProps, 'children' | 'title'> & { title?: ReactNode };
};

const InputTooltip = (props: Props) => {
  const { required, label, tooltipProps, id } = props;
  return (
    <Grid container direction='row' alignItems='center' justifyContent='space-between'>
      {Label ? (
        <Grid item>
          <Stack display='flex' alignItems='center' justifyContent='center' sx={{ minHeight: '1.25rem' }}>
            <Label variant='standard' required={required} aria-required={required} htmlFor={id}>
              {label}
            </Label>
          </Stack>
        </Grid>
      ) : null}

      {tooltipProps?.title ? (
        <Grid item>
          <Box display='flex' alignItems='center' justifyContent='center' minHeight='20'>
            <TooltipInputElement {...tooltipProps}></TooltipInputElement>
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );
};
export default InputTooltip;
