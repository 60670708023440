import { styled, css } from '@mui/material/styles';
import { Box } from '@mui/material';

export const IconWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: ${theme.palette.colors.blue[50]};
    border-radius: 100%;
    width: 182px;
    height: 182px;
  `,
);
