import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import BotErrorIcon from '@/assets/Icons/bot-error.svg';
import { Logout } from '@mui/icons-material';
import { Fragment } from 'react';
import useAccount from '@/hooks/useAccount';
import Button from '@/components/Button';
import AccountSelectorModalComponent from '@/components/AccountSelector/AccountSelectorModal';
import ErrorComponent from '@/components/Error/ErrorComponent';
import { ButtonContainer } from './styles';
import { useNavigate } from 'react-router-dom';
import useAuth from '@/contexts/Auth/hooks/useAuth';

export const ErrorNoAppFoundPage = () => {

  const navigate = useNavigate();
  const { logout } = useAuth();

  const { t } = useTranslation(['errors'], {
    keyPrefix: 'appError',
  });

  const { setAccountSelectorOpen } = useAccount();

  const handleOpenAccountSelector = () => {
    setAccountSelectorOpen(true);
  };

  const handleLogout = () => {
    navigate('/login', { replace: true });
    logout();
  };

  return (
    <Fragment>
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>
      <ErrorComponent.Root>
        <ErrorComponent.Header />
        <ErrorComponent.Body
          title={t('title')}
          subTitle={t('sub_title')}
          description={
            <Trans
              i18nKey={t('description')}
              components={{
                anchor: <a href='https://ajuda.planne.com.br/kb'>EMPTY</a>,
              }}
            />
          }
          icon={BotErrorIcon}
        >
          <ButtonContainer>
            <Button
              fullWidth
              variant='contained'
              size='small'
              color='primary'
              onClick={() => handleOpenAccountSelector()}
            >
              {t('button.app_selector')}
            </Button>
            <Button fullWidth variant='outlined' size='small' color='secondary' href='https://ajuda.planne.com.br/kb'>
              {t('button.help')}
            </Button>
            <Button fullWidth startIcon={<Logout />} variant='text' size='medium' color='primary' onClick={handleLogout}>
              {t('button.logout')}
            </Button>
          </ButtonContainer>
        </ErrorComponent.Body>
      </ErrorComponent.Root>
      <AccountSelectorModalComponent />
    </Fragment>
  );
};

export default ErrorNoAppFoundPage;
