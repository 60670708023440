import { FilterAltOutlined, InsightsOutlined } from '@mui/icons-material';
import { Box, CircularProgress, Grid, SxProps, Theme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyPage from '@/components/EmptyPage';
import BigNumberCard from '@/components/Partnership/Commissions/BigNumberCard';
import CommissionsTable from '@/components/Partnership/Commissions/Table';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import useAccount from '@/hooks/useAccount';
import useFilter from '@/hooks/useFilter';
import { useSellerApi } from '@/hooks/useSellerApi';
import {
  ArrangedSaleItem,
  ArrangedSaleItemAggregatedInfo,
  ArrangedSaleItemsApiGetAppArrangedSaleItemsRequest,
  AssociationModel,
  SaleStateEnum,
} from '@/services/SellerApi';
import { colors } from '@/theme';
import { formatCents } from '@/utils';

type Props = {
  currentState: SaleStateEnum[];
};

export const CommissionList = (props: Props) => {
  const { currentState } = props;

  const { track } = useAnalytics();
  const { appliedFilters } = useFilter();
  const { selectedAccount } = useAccount();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['commissions']);

  const [loadingArrangedSaleItems, setLoadingArrangedSaleItems] = useState(true);
  const [loadingAggregatedValues, setLoadingAggregatedValues] = useState(true);

  const { arrangedSaleItemsApi } = useSellerApi();
  const [totalArrangedSaleItems, setTotalArrangedSaleItems] = useState(0);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 20 });
  const [arrangedSaleItems, setArrangedSaleItems] = useState<ArrangedSaleItem[]>([]);
  const [arrangedSaleItemsAggregatedInfo, setArrangedSaleItemsAggregatedInfo] =
    useState<ArrangedSaleItemAggregatedInfo | null>(null);

  const isCompletedSaleItemList = currentState[0] === SaleStateEnum.PaymentComplete;

  const getArrangedSaleItems = async (payload: ArrangedSaleItemsApiGetAppArrangedSaleItemsRequest) => {
    try {
      setLoadingArrangedSaleItems(true);

      const arrangedSaleItemsResponse = await arrangedSaleItemsApi.getAppArrangedSaleItems(payload);

      setArrangedSaleItems(arrangedSaleItemsResponse.data);
      setTotalArrangedSaleItems(parseInt(arrangedSaleItemsResponse.headers['x-pagination-total-count']));

      track('arrangedSaleItems/retrieveArrangedSaleItemsListSuccess', { payload });
    } catch (error) {
      enqueueSnackbar(t('could_not_load_sale_items'), { variant: 'error' });
      track('arrangedSaleItems/retrieveArrangedSaleItemsListFail', { error });
    } finally {
      setLoadingArrangedSaleItems(false);
    }
  };

  const getArrangedSaleItemsAggregatedInfo = async (payload: ArrangedSaleItemsApiGetAppArrangedSaleItemsRequest) => {
    try {
      setLoadingAggregatedValues(true);

      const arrangedSaleItemsAggregatedInfoResponse =
        await arrangedSaleItemsApi.getAppArrangedSaleItemsAggregatedInfo(payload);

      setArrangedSaleItemsAggregatedInfo(arrangedSaleItemsAggregatedInfoResponse.data);
    } catch (error) {
      enqueueSnackbar(t('could_not_load_sale_items_aggregated_values'), { variant: 'error' });
      track('arrangedSaleItems/retrieveArrangedSaleItemsAggregatedInfoFail', { error });
    } finally {
      setLoadingAggregatedValues(false);
    }
  };

  const getEmptyPageIcon = (
    fontSize: 'small' | 'inherit' | 'large' | 'medium' | undefined,
    sx: SxProps<Theme> | undefined,
  ) => {
    if (Object.keys(appliedFilters).length) {
      return <FilterAltOutlined component='svg' fontSize={fontSize} sx={sx} />;
    }

    if (isCompletedSaleItemList) {
      return <InsightsOutlined fontSize={fontSize} sx={sx} />;
    }

    return undefined;
  };

  const getEmptyPageDescription = () => {
    if (Object.keys(appliedFilters).length) {
      return t('empty.filter');
    }

    return isCompletedSaleItemList ? t('empty.no_sale_items_was_found') : t('empty.no_canceled_sale_items_was_found');
  };

  useEffect(() => {
    const payload: ArrangedSaleItemsApiGetAppArrangedSaleItemsRequest = {
      appId: selectedAccount?.appId || '',
      include: [
        'sale',
        'product',
        'decisiveTransactionValues',
        'partnershipItem.product',
        'partnershipItem.partnership',
        'partnershipItem.partnership.agent',
        'partnershipItem.partnership.provider',
        'partnershipItem.partnership.publicProposal',
      ],
      currentState,
      limit: paginationModel.pageSize,
      offset: paginationModel.page * paginationModel.pageSize,
      sortBy: 'recent',
      associationModels: [AssociationModel.Affiliation],
      ...appliedFilters,
    };

    getArrangedSaleItems(payload);
    getArrangedSaleItemsAggregatedInfo(payload);
  }, [paginationModel, appliedFilters, currentState]);

  return (
    <Grid container paddingY={{ xs: 2, sm: 4 }} paddingX={{ xs: 0, sm: 4 }} alignContent='flex-start'>
      {arrangedSaleItems.length ? (
        <>
          {isCompletedSaleItemList && arrangedSaleItemsAggregatedInfo ? (
            <Grid item xs={12} mb={2} paddingX={{ xs: 2, md: 0 }}>
              <Grid container spacing={2} justifyContent='space-between'>
                <Grid item xs={12} md={6}>
                  <BigNumberCard
                    title={t('cards.total_sales')}
                    value={formatCents(arrangedSaleItemsAggregatedInfo.totalAmountCents)}
                    loading={loadingAggregatedValues}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <BigNumberCard
                    title={t('cards.total_commissions')}
                    valueColor={colors.green[500]}
                    value={formatCents(arrangedSaleItemsAggregatedInfo.commissionedAmountCents)}
                    helper
                    loading={loadingAggregatedValues}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : null}

          <CommissionsTable
            loading={loadingArrangedSaleItems}
            data={arrangedSaleItems}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            totalArrangedSaleItems={totalArrangedSaleItems}
          />
        </>
      ) : null}

      {!arrangedSaleItems.length && !loadingArrangedSaleItems ? (
        <Grid item xs={12} alignContent='center' justifyContent='center' height='60vh'>
          <EmptyPage
            Icon={getEmptyPageIcon('large', { width: 92, height: 92, color: colors.gray[800] })}
            description={getEmptyPageDescription()}
          />
        </Grid>
      ) : null}

      {!arrangedSaleItems.length && loadingArrangedSaleItems ? (
        <Grid item xs={12} textAlign='center'>
          <Box display='flex' alignItems='center' justifyContent='center' height='60vh' width='100%'>
            <CircularProgress color='primary' />
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default CommissionList;
