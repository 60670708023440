import { IconButton, ListItemIcon, ListItemText, Stack, Typography, useMediaQuery } from '@mui/material';
import List from '@mui/material/List';
import { IconLogout, IconMenu2 } from '@tabler/icons-react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import planneLogoDesktop from '@/assets/logos/planneLogoDesktop.svg';
import useAuth from '@/contexts/Auth/hooks/useAuth';
import { useSidebar } from '@/hooks/useSidebar';
import useUI from '@/hooks/useUI';
import theme from '@/theme';

import AccountSelectorButton from './AccountSelectorButton';
import MenuItem from './MenuItem';
import { StyledListItemButton } from './MenuItem/styles';
import mainMenuStructure, { NestedMenuItemProps } from './menuStructure';
import ModuleItem from './ModuleItem';
import { IconMenuContainer, Logo, LogoContainer } from './styles';

const SideBar = () => {
  const location = useLocation();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const { t } = useTranslation(['ui']);
  const { canView, isActive } = useSidebar();

  const { setIsDrawerExpanded, isDrawerExpanded, toggleDrawer } = useUI();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const [menuItemOpen, setMenuItemOpen] = useState<{ [key: string]: boolean }>({});

  const [hasPinedPopover, setHasPinedPopover] = useState(false);

  const [anchor, setAnchor] = useState<{ element: null | HTMLElement; menuItem: NestedMenuItemProps | null }>({
    element: null,
    menuItem: null,
  });

  const isOpen = (code: string) => menuItemOpen[code] ?? false;

  const toggleNestedMenuItem = (code: string) => {
    const newMenuItemOpen = { ...menuItemOpen };

    Object.keys(newMenuItemOpen).forEach((key) => {
      newMenuItemOpen[key] = false;
    });

    setMenuItemOpen({ ...newMenuItemOpen, [code]: !isOpen(code) });
  };

  const hasActiveChild = (menuItem: NestedMenuItemProps) => {
    return menuItem.children?.some((child) => isActive(child.to || ''));
  };

  const handleLogout = () => {
    navigate('/login', { replace: true });
    logout();
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>, item: NestedMenuItemProps) => {
    setAnchor({
      element: event.currentTarget,
      menuItem: item,
    });
  };

  const handlePopoverClose = () => {
    setAnchor({
      element: null,
      menuItem: null,
    });

    setHasPinedPopover(false);
  };

  const onlyShowIcon = useMemo(() => !isMobile && !isDrawerExpanded, [isMobile, isDrawerExpanded]);

  const renderMenuButton = () => {
    const onClick = isTablet ? () => toggleDrawer() : () => setIsDrawerExpanded((state) => !state);

    const IconMenu = <IconMenu2 size={24} color={theme.palette.common.white} />;

    if (isTablet) {
      return <IconButton onClick={onClick}>{IconMenu}</IconButton>;
    }

    return (
      <IconMenuContainer onClick={onClick} onlyShowIcon={onlyShowIcon}>
        {IconMenu}
      </IconMenuContainer>
    );
  };

  useEffect(() => {
    // will collapse and expand nested menu items based on the current route
    const newObj = Object.assign(
      {},
      ...(mainMenuStructure
        ?.filter((menu) => menu.type === 'nested')
        .map((item) => ({ [item.code]: hasActiveChild(item) })) ?? {}),
    );

    setMenuItemOpen(newObj);
  }, [location.pathname]);

  return (
    <>
      <Stack direction='row' height={76}>
        {renderMenuButton()}

        <LogoContainer href='/'>
          <Logo src={planneLogoDesktop} alt='Planne Logo' />
        </LogoContainer>
      </Stack>

      <AccountSelectorButton onlyShowAccountImage={onlyShowIcon} />

      <List sx={{ overflow: 'auto', paddingBottom: 0, overflowX: 'hidden' }}>
        {mainMenuStructure.map((item) =>
          item.type === 'nested' ? (
            item.children?.some((child) => canView(child)) ? (
              <ModuleItem
                key={item.code}
                item={item}
                anchor={anchor}
                open={isOpen(item.code)}
                onClick={toggleNestedMenuItem}
                hasPinedPopover={hasPinedPopover}
                changePinPopover={setHasPinedPopover}
                handlePopoverOpen={handlePopoverOpen}
                handlePopoverClose={handlePopoverClose}
              />
            ) : null
          ) : canView(item) ? (
            <MenuItem
              key={item.code}
              text={item.text}
              icon={item.icon}
              track={item.track}
              to={item.to}
              target={item.target}
              isActive={isActive(item.to)}
              onlyShowIcon={onlyShowIcon}
            />
          ) : null,
        )}

        <StyledListItemButton
          to='.'
          level={1}
          key='logout'
          target='_self'
          onlyShowIcon={onlyShowIcon}
          selected={false}
          component={NavLink}
          onClick={handleLogout}
          sx={{
            borderTop: '1px solid' + theme.palette.colors.blue[400],
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: onlyShowIcon ? 3 : 'auto',
              justifyContent: onlyShowIcon ? 'center' : 'initial',
            }}
          >
            <IconLogout />
          </ListItemIcon>

          <ListItemText primary={<Typography variant='regularRegular'>{t('sidebar.exit_button')}</Typography>} />
        </StyledListItemButton>
      </List>
    </>
  );
};

export default SideBar;
