import { styled, Stack, Grid } from '@mui/material';

export const VoucherImage = styled('img')(() => ({
  width: '100%',
  marginBottom: '1.5rem',
}));

export const ButtonsWrapper = styled(Stack)(({ theme }) => ({
  marginTop: 'auto',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  borderTop: '1px solid',
  borderColor: theme.palette.colors.gray[300],

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4, 0, 0),
  },

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(4, 8),
  },

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 4, 0),
  },
}));

export const GridContainer = styled(Grid)(({ theme }) => ({
  width: '100%',
  maxWidth: '820px',
  padding: theme.spacing(0),
  marginBottom: theme.spacing(6),

  [theme.breakpoints.down('lg')]: {
    width: 'unset',
  },

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(8),
    marginBottom: theme.spacing(0),
  },
}));
