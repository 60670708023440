/* eslint-disable @typescript-eslint/no-explicit-any */
const isInvalidKey = (obj: any, key: string): boolean => {
  return obj === undefined || obj === null || !(key in obj);
};

const getNestedValue = (obj: any, keyPath: string): any => {
  const keys = keyPath.split('.');
  let current = obj;
  for (const key of keys) {
    if (isInvalidKey(current, key)) {
      return undefined;
    }
    current = current[key];
  }

  return current;
};

export default getNestedValue;
