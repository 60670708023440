import { useContext } from 'react';
import { PartnershipPortalCartContext } from '@/contexts/PartnershipPortalCartContext';

export const usePartnershipPortalCart = () => {
  const context = useContext(PartnershipPortalCartContext);

  if (!context) {
    throw new Error('usePartnershipPortalCart must be used within a CartProvider');
  }

  return context;
};

export default usePartnershipPortalCart;
