import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Tooltip, Typography, useMediaQuery } from '@mui/material';

import { AlertText } from './styles';
import { formatCurrency, removeSpecialCharacters } from '@/utils';
import Button from '@/components/Button';
import Input from '@/components/Form/Input';
import { Coupon, CouponsApiUpdateArrangedCouponRequest, Partnership } from '@/services/SellerApi';
import theme from '@/theme';
import { useMemo, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import { schema } from './schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSellerApi } from '@/hooks/useSellerApi';
import useErrorHandler from '@/hooks/useErrorHandler';
import BackdropLoading from '@/components/BackdropLoading';
import Switch from '@/components/Switch';
import { IconHelp } from '@tabler/icons-react';
import { ErrorTypes } from '@/interfaces/errorTypes';

type Props = {
  partnership: Partnership;
  coupon: Coupon;
  onCancel: () => void;
  onSuccess: () => void;
};

export const PartnershipCouponForm = (props: Props) => {
  const { coupon, onCancel, onSuccess, partnership } = props;

  const { track } = useAnalytics();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(['partnership']);

  const { couponsApi } = useSellerApi();
  const { errorHandler, ErrorValidationRules, extractFieldNameFromPath } = useErrorHandler();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [discountPercentage, setDiscountPercentage] = useState(String(coupon.discountPercentage));

  const { handleSubmit, formState, control, setError } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: zodResolver(schema(t, partnership.maxPercentageDiscount)),
    defaultValues: {
      code: coupon.code,
      discountPercentage: String(coupon.discountPercentage),
      isPartnershipDefault: coupon.isPartnershipDefault,
    },
  });

  const { errors, isSubmitting } = formState;

  const fictitiousSalesValue = useMemo(() => {
    const maxPercentage = 100;

    if (!discountPercentage || Number(discountPercentage) > maxPercentage) {
      return undefined;
    }

    const fictitiousSalesValueForAlert = 200;
    const finalPrice = fictitiousSalesValueForAlert - (Number(discountPercentage) / 100) * fictitiousSalesValueForAlert;

    return {
      percentage: Number(discountPercentage),
      finalPrice: formatCurrency(finalPrice),
      fictitiousSalesValueForAlert: formatCurrency(fictitiousSalesValueForAlert),
    };
  }, [discountPercentage]);

  const submit = async (formData: FieldValues) => {
    const payload: CouponsApiUpdateArrangedCouponRequest = {
      couponId: coupon.id,
      updateArrangedCouponParams: {
        isPartnershipDefault: formData.isPartnershipDefault,
        code: formData.code,
        discountPercentage: formData.discountPercentage,
      },
    };

    try {
      setLoading(true);

      await couponsApi.updateArrangedCoupon(payload);

      enqueueSnackbar(t('detail.update_coupon_successfully'), { variant: 'success' });

      onSuccess();
    } catch (error) {
      const { errors } = errorHandler(error);

      let errorKeyTitle;
      let errorKeyMessage = 'detail.could_not_update_coupon';

      if (errors && errors.length) {
        errors.forEach(({ rule, type, path }) => {
          if (type === ErrorTypes.VALIDATION && rule === ErrorValidationRules.Unique) {
            const field = extractFieldNameFromPath(path!);

            errorKeyTitle = 'detail.coupon_code_already_exists';
            errorKeyMessage = 'detail.coupon_code_already_exists_message';

            setError(field as 'code', { message: t(errorKeyMessage) }, { shouldFocus: true });
          }
        });
      }

      enqueueSnackbar(t(errorKeyMessage), { variant: 'error', ...(errorKeyTitle ? { title: t(errorKeyTitle) } : {}) });

      track('partnershipDetail/editCouponFail', { payload, error });
    } finally {
      setLoading(false);
    }
  };

  const divulgationLink = `${partnership.provider?.siteUrl}?ac=${partnership?.affiliationCode}`;

  return (
    <>
      {loading ? <BackdropLoading /> : null}

      <Grid container component='form' spacing={4} position='relative' onSubmit={handleSubmit(submit)}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Controller
                name='code'
                control={control}
                render={({ field: { onChange, value, name, ...rest } }) => (
                  <Input
                    id={name}
                    name={name}
                    value={value || ''}
                    fullWidth
                    required
                    error={!!errors[`${name}`]}
                    label={t('detail.coupon_list.edit_form.code_field')}
                    helperText={errors[`${name}`]?.message as string}
                    onChange={(e) => {
                      e.target.value = removeSpecialCharacters(e.target.value.toUpperCase());
                      onChange(e);
                    }}
                    tooltipProps={{
                      placement: 'left',
                      PopperProps: { style: { zIndex: 99999, width: 180 } },
                      title: t('detail.coupon_list.edit_form.tooltip.code_field'),
                    }}
                    {...rest}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name='discountPercentage'
                control={control}
                render={({ field: { onChange, value, name, ...rest } }) => (
                  <Input
                    fullWidth
                    type='number'
                    id='discountPercentage'
                    value={value || ''}
                    inputProps={{ step: '0.01', inputMode: 'numeric' }}
                    required
                    label={t('detail.coupon_list.edit_form.percentage_field')}
                    error={!!errors['discountPercentage']}
                    helperText={errors['discountPercentage']?.message as string}
                    name={name}
                    onChange={(e) => {
                      e.target.value = Number(e.target.value ?? 0) < 0 ? '' : e.target.value;
                      setDiscountPercentage(e.target.value ?? '');
                      onChange(e);
                    }}
                    tooltipProps={{
                      placement: 'left',
                      PopperProps: {
                        style: { zIndex: 99999, width: 180 },
                      },
                      title: t('detail.coupon_list.edit_form.tooltip.percentage_field'),
                    }}
                    {...rest}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        {fictitiousSalesValue ? (
          <Grid item xs={12}>
            <AlertText variant='regularBold'>
              {t('detail.coupon_list.edit_form.alert_message', {
                finalPrice: fictitiousSalesValue.finalPrice,
                discountValue: fictitiousSalesValue.percentage,
                price: fictitiousSalesValue.fictitiousSalesValueForAlert,
              })}
            </AlertText>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <Box sx={{ backgroundColor: '#F2F2F2', padding: '0.5rem' }}>
            <Grid container justifyContent='space-between'>
              <Grid
                item
                xs={12}
                sm={12}
                md={9}
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                marginBottom={{ xs: '1rem', md: 0 }}
              >
                <Box sx={{ mr: 4, width: '100%', overflow: 'hidden', wordWrap: 'break-word' }}>
                  <Typography variant='smallBold' component='p'>
                    {t('detail.coupon_list.edit_form.divulgation_link')}
                  </Typography>

                  <Typography variant='smallRegular' component='p'>
                    {divulgationLink}
                  </Typography>
                </Box>

                <Tooltip
                  leaveTouchDelay={5000}
                  enterTouchDelay={0}
                  PopperProps={{ style: { zIndex: 9999 } }}
                  title={t('detail.coupon_list.edit_form.divulgation_link_tooltip')}
                  placement='top'
                  arrow
                >
                  <IconHelp size={30} />
                </Tooltip>
              </Grid>

              <Grid item display='flex' alignItems='center' justifyContent={{ sm: 'flex-end' }}>
                <Controller
                  name='isPartnershipDefault'
                  control={control}
                  render={({ field: { onChange, value, name, ...rest } }) => (
                    <>
                      <Switch sx={{ mr: 2 }} onChange={onChange} checked={!!value} name={name} {...rest} />
                      <Typography variant='smallRegular'>{t('detail.coupon_list.edit_form.link_coupon')}</Typography>
                    </>
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display='flex' alignItems='center' gap={1} flexDirection='row'>
            <Button
              type='submit'
              color='primary'
              variant='contained'
              fullWidth={isMobile}
              disabled={loading || isSubmitting}
              sx={{ padding: '0.75rem 1rem', width: isMobile ? '100%' : '87px' }}
            >
              {t('detail.coupon_list.edit_form.submit')}
            </Button>

            <Button
              color='primary'
              variant='outlined'
              onClick={onCancel}
              fullWidth={isMobile}
              disabled={loading || isSubmitting}
            >
              {t('detail.coupon_list.edit_form.cancel_button')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default PartnershipCouponForm;
