import { ButtonProps } from '@mui/material';
import { FinishPurchaseButton } from '../styles';
import usePartnershipPortalCart from '@/hooks/usePartnershipPortalCart';
import { useTranslation } from 'react-i18next';

export const PurchaseButton = (props: ButtonProps) => {
  const { t } = useTranslation('productShowCase');

  const { cartItems, loadingCart } = usePartnershipPortalCart();

  const hasProductsOnCart = cartItems.length > 0;
  const isCartValid = cartItems.every((cartItem) => cartItem.isValid);

  return (
    <FinishPurchaseButton
      fullWidth
      color='success'
      variant='contained'
      disabled={!hasProductsOnCart || !isCartValid || loadingCart}
      {...props}
    >
      {t('cart.mobile.finish_button')}
    </FinishPurchaseButton>
  );
};

export default PurchaseButton;
