import { Stack, styled } from '@mui/material';

export const Header = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  alignItems: 'flex-start',
  justifyContent: 'flex-start',

  [theme.breakpoints.up('md')]: {
    alignItems: 'center',
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    justifyContent: 'space-between',
  },
}));
