import Radio, { RadioProps } from '@mui/material/Radio';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';

import { Label, Wrapper } from '../Input/styles';
import { HelperText } from '../Checkbox/styles';
import { Ref, forwardRef } from 'react';

export type RadioOptionProps = Omit<FormControlLabelProps, 'control'> & {
  size?: RadioProps['size'];
};

type Props = RadioGroupProps & {
  id?: string;
  name: string;
  label?: string;
  error?: boolean;
  required?: boolean;
  helperText?: string;
  options: RadioOptionProps[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
};

export const RadioButtonsGroup = forwardRef((props: Props, ref: Ref<unknown>) => {
  const { label, name, error, helperText, options, id = name, required, onChange, value, ...restOfProps } = props;

  return (
    <Wrapper>
      {label ? (
        <Label htmlFor={id} required={required}>
          {label}
        </Label>
      ) : null}

      <RadioGroup
        onChange={onChange}
        ref={ref}
        aria-labelledby='radio-buttons-group-label'
        name={name}
        value={value}
        {...restOfProps}
      >
        {options.map(({ value, size = 'medium', ...rest }) => (
          <FormControlLabel {...rest} key={`radio_option_${value}`} value={value} control={<Radio size={size} />} />
        ))}
      </RadioGroup>

      {helperText ? <HelperText error={error}>{helperText}</HelperText> : null}
    </Wrapper>
  );
});

export default RadioButtonsGroup;
