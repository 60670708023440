import { Stack } from '@mui/material';
import { useEffect, useMemo } from 'react';

import Loading from '@/components/Loading';
import useLoadAvailabilitiesQuery from '@/features/agenda/queries/useLoadAvailabilitiesQuery';
import useLoadInfiniteSchedulingsQuery from '@/features/agenda/queries/useLoadInfiniteSchedulingsQuery';
import { AgendaConfigState, ViewDataType } from '@/features/agenda/types';
import { Scheduling } from '@/services/SellerApi';

import AgendaDayPopoverList from './AgendaDayPopoverList';
import { useConfig } from '@/features/config/useConfig';
interface AgendaDayPopoverContentProps {
  day: Date;
  onUpdate?: () => void;
}

const AgendaDayPopoverContent = (props: AgendaDayPopoverContentProps) => {
  const { day, onUpdate } = props;
  const { config } = useConfig<AgendaConfigState>();

  const viewDataType = useMemo(() => config?.viewDataType, [config]);
  const isViewData = useMemo(() => (viewDataKey: ViewDataType) => viewDataType?.includes(viewDataKey), [viewDataType]);
  const dateRange = useMemo(() => ({ since: day, until: day }), [day]);

  const [schedulingsQuery] = useLoadInfiniteSchedulingsQuery();
  const [availabilitiesQuery] = useLoadAvailabilitiesQuery();

  const {
    data: schedulingsResponse,
    isLoading: isSchedulingsLoading,
    hasNextPage: hasNextSchedulingsPage,
    fetchNextPage: fetchNextSchedulingsPage,
    isFetchingNextPage: isFetchingNextSchedulingsPage,
  } = schedulingsQuery(dateRange, isViewData(ViewDataType.SCHEDULING));

  const { data: availabilitiesData, isLoading: isAvailabilitiesLoading } = availabilitiesQuery(
    dateRange,
    isViewData(ViewDataType.AVAILABILITIES),
  );

  useEffect(() => {
    const isDataLoaded = schedulingsResponse || availabilitiesData;
    if (onUpdate && isDataLoaded) onUpdate();
  }, [schedulingsResponse, availabilitiesData]);

  const schedulings = useMemo(
    () =>
      (schedulingsResponse?.pages
        .map((page) => page?.items)
        .filter(Boolean)
        .flat() || []) as Scheduling[],
    [schedulingsResponse],
  );

  const loadMoreSchedulings = () => {
    if (hasNextSchedulingsPage && !isFetchingNextSchedulingsPage) {
      fetchNextSchedulingsPage();
    }
  };

  return (
    <Stack direction='column' spacing={1} sx={{ padding: '1rem' }}>
      {isSchedulingsLoading || isAvailabilitiesLoading ? (
        <Loading showLabel />
      ) : (
        <AgendaDayPopoverList
          schedulings={schedulings}
          availabilities={availabilitiesData || []}
          hasNextSchedulingsPage={hasNextSchedulingsPage}
          onLoadMoreSchedulings={loadMoreSchedulings}
        />
      )}
    </Stack>
  );
};

export default AgendaDayPopoverContent;
