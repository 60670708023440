import { Box, Grid, Hidden, HiddenProps, Paper, Stack, Typography } from '@mui/material';
import {
  DataGridProps,
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { createElement, Fragment, useMemo } from 'react';

import Pagination from '@/components/Pagination';
import { colors } from '@/theme';

import { DataGrid } from './styles';

interface GridValidRowModelProps {
  row: GridValidRowModel;
}

type HiddenMediaProps = Omit<HiddenProps, 'children' | 'implementation' | 'initialWidth'>;

type ResponsiveTableProps = DataGridProps & {
  enableActionColumn?: boolean;
  onMobileClick?: (params: GridValidRowModelProps) => void;
  renderMobile?: (params: GridValidRowModelProps) => JSX.Element;
  renderContentAboveHeader?: (params: GridValidRowModelProps) => JSX.Element;
  hiddenDesktop?: HiddenMediaProps;
  hiddenMobile?: HiddenMediaProps;
};

export type TableColDef = GridColDef & {
  enableOnMobile?: boolean;
  renderAsLeftTitleOnMobile?: boolean;
  renderAsRightTitleOnMobile?: boolean;
};

export const ResponsiveTable = (props: ResponsiveTableProps) => {
  const {
    rows,
    columns,
    onMobileClick,
    paginationModel,
    onPaginationModelChange,
    paginationMode,
    rowCount,
    renderMobile,
    renderContentAboveHeader,
    hiddenDesktop = { smDown: true },
    hiddenMobile = { smUp: true },
    disableColumnMenu = true,
    ...tableDatagridProps
  } = props;
  const pageSizeOptions = [5, 10, 20, 50, 100];
  const defaultRowsPerPage = 20;

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (onPaginationModelChange) {
      onPaginationModelChange(
        {
          pageSize: paginationModel?.pageSize ?? 0,
          page: newPage,
        },
        { reason: 'setPaginationModel' },
      );
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (onPaginationModelChange) {
      onPaginationModelChange(
        {
          pageSize: parseInt(event.target.value, 10),
          page: 0,
        },
        { reason: 'setPaginationModel' },
      );
    }
  };

  const renderCell = (row: GridRenderCellParams['row'], column: TableColDef) => {
    if (column.renderCell) {
      return <Fragment key={column.field}>{column.renderCell({ row } as GridRenderCellParams)}</Fragment>;
    }

    if (column.valueGetter) {
      return (
        <Typography
          variant={column.renderAsLeftTitleOnMobile ? 'smallBold' : 'smallSemiBold'}
          color={colors.gray[700]}
          key={column.field + row.id}
        >
          {column.valueGetter({ row } as GridValueGetterParams)}
        </Typography>
      );
    }

    return (
      <Typography
        variant={column.renderAsLeftTitleOnMobile ? 'smallBold' : 'smallSemiBold'}
        color={colors.gray[700]}
        key={column.field + row.id}
      >
        {row[column.field]}
      </Typography>
    );
  };

  const shouldDispayPagination = useMemo(
    () => (rowCount ?? 0) > (paginationModel?.pageSize ?? defaultRowsPerPage),
    [rowCount, paginationModel],
  );

  const mobileColumns = useMemo(() => columns.filter((column: TableColDef) => column.enableOnMobile), [columns]);

  const mobileHeaderColumns = useMemo(() => {
    const cols = (columns as TableColDef[]).filter(
      (column) => column.renderAsLeftTitleOnMobile || column.renderAsRightTitleOnMobile,
    );

    return cols.sort((a) => (a.renderAsLeftTitleOnMobile ? -1 : 1));
  }, [columns]);

  return (
    <Grid container>
      <Grid item xs={12} sx={{ minHeight: tableDatagridProps.autoHeight ? null : '400px' }}>
        <Hidden {...hiddenMobile}>
          <Fragment>
            {rows.map((row, idx) => {
              return renderMobile ? (
                <Paper elevation={2} key={idx} sx={{ mb: 1 }}>
                  <Box width='100%' padding={2}>
                    {createElement(renderMobile, { row })}
                  </Box>
                </Paper>
              ) : (
                <Stack
                  paddingX={2}
                  paddingY='4px'
                  key={idx}
                  sx={{
                    ...tableDatagridProps.sx,
                  }}
                >
                  {renderContentAboveHeader && createElement(renderContentAboveHeader, { row })}

                  <Stack
                    gap='4px'
                    key={idx}
                    direction='column'
                    onClick={() => onMobileClick?.({ row })}
                    data-mobile-identifier='mobile-table-row'
                    sx={{
                      cursor: 'pointer',
                      paddingBottom: '8px',
                      borderBottom: '1px solid',
                      borderBottomColor: colors.gray[200],
                    }}
                  >
                    {mobileHeaderColumns?.length ? (
                      <Stack p='4px' direction='row' alignItems='flex-start' justifyContent='space-between'>
                        {mobileHeaderColumns.map((mobileHeaderColumn: TableColDef) =>
                          renderCell(row, mobileHeaderColumn),
                        )}
                      </Stack>
                    ) : null}

                    {mobileColumns.map((column: TableColDef, index) => (
                      <Stack
                        key={index}
                        direction='row'
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{
                          width: '100%',
                          padding: '4px',
                          borderRadius: 1,
                          background: index % 2 === 0 ? colors.gray[50] : 'transparent',
                        }}
                      >
                        <Typography variant='smallRegular' color={colors.gray[700]}>
                          {column.headerName ?? column?.renderHeader?.({ row } as GridRenderCellParams)}
                        </Typography>

                        {renderCell(row, column)}
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              );
            })}

            {shouldDispayPagination ? (
              <Pagination
                count={rowCount ?? rows.length}
                onPageChange={handleChangePage}
                page={paginationModel?.page ?? 0}
                rowsPerPageOptions={pageSizeOptions}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPage={paginationModel?.pageSize ?? defaultRowsPerPage}
              />
            ) : null}
          </Fragment>
        </Hidden>

        <Hidden {...hiddenDesktop}>
          <DataGrid
            rows={rows}
            columns={columns}
            sx={{
              boxShadow: 0,
              border: 0,
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={pageSizeOptions}
            disableRowSelectionOnClick
            getRowSpacing={(params) => ({
              top: params.isFirstVisible ? 0 : 3,
              bottom: params.isLastVisible ? 0 : 3,
            })}
            paginationModel={paginationModel}
            onPaginationModelChange={onPaginationModelChange}
            paginationMode={paginationMode}
            rowCount={rowCount}
            slotProps={{
              pagination: {
                rowsPerPage: paginationModel?.pageSize ?? defaultRowsPerPage,
                hidden: !shouldDispayPagination,
              },
            }}
            disableColumnMenu={disableColumnMenu}
            {...tableDatagridProps}
          />
        </Hidden>
      </Grid>
    </Grid>
  );
};

export default ResponsiveTable;
