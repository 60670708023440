import { QueryFunctionContext } from '@tanstack/react-query';

import { useSellerApi } from '@/hooks/useSellerApi';
import { IncomeScheduling, IncomeSchedulingsApiGetSaleIncomeSchedulingsRequest } from '@/services/SellerApi';
import { AnalyticsEvents } from '@/hooks/analytics/enum/analyticsEvents';
import useAnalytics from '@/hooks/analytics/useAnalytics';

type Query = [key: string, saleId: string, limit: number, offset: number];
export interface IncomeSchedulingResponse {
  data: IncomeScheduling[];
  totalIncomeSchedulings: number;
}

const useGetSaleIncomeSchedulings = () => {
  const { incomeSchedulingsApi } = useSellerApi();
  const { track } = useAnalytics();

  const request = async ({ queryKey, signal }: QueryFunctionContext<Query>): Promise<IncomeSchedulingResponse> => {
    const [_, saleId, limit, offset] = queryKey;

    const payload: IncomeSchedulingsApiGetSaleIncomeSchedulingsRequest = {
      saleId,
      include: ['transaction', 'transaction.sale'],
      sortBy: 'oldestScheduledAt',
      limit,
      offset,
    };

    try {
      const { data, headers } = await incomeSchedulingsApi.getSaleIncomeSchedulings(payload, { signal });

      return {
        data,
        totalIncomeSchedulings: parseInt(headers['x-pagination-total-count'] ?? '0'),
      };
    } catch (error) {
      track(AnalyticsEvents.GET_INCOME_SCHEDULINGS_FAIL, { error, payload });

      return {
        data: [],
        totalIncomeSchedulings: 0,
      };
    }
  };

  return { request };
};

export default useGetSaleIncomeSchedulings;
