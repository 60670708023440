import { List, ListItem, Typography, useMediaQuery } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import theme from '@/theme';
import Tooltip from '@/components/Tooltip';
import { useTranslation } from 'react-i18next';
import { Container } from './style';

export const OperationHeader = () => {
  const { t } = useTranslation(['incomeSchedulings']);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const helpIconSize = isMobile ? 16 : 18;

  const tooltipItems = [
    t('table.event_type.tooltip_credit'),
    t('table.event_type.tooltip_refund'),
    t('table.event_type.tooltip_chargeback'),
  ];

  return (
    <Container>
      {t('table.event_type.title')}

      <Tooltip
        title={
          <List
            sx={{
              pl: 2,
              listStyleType: 'disc',
              '& .MuiListItem-root': {
                display: 'list-item',
              },
            }}
          >
            {tooltipItems.map((item) => (
              <ListItem key={item} disableGutters sx={{ py: 0, display: 'list-item' }}>
                <Typography variant='extraSmallRegular'>{item}</Typography>
              </ListItem>
            ))}
          </List>
        }
        textAlign='left'
      >
        <HelpOutlineIcon
          sx={{ color: theme.palette.colors.gray[400], cursor: 'pointer', width: helpIconSize, height: helpIconSize }}
        />
      </Tooltip>
    </Container>
  );
};

export default OperationHeader;
