import { ReactNode, useMemo } from 'react';

import { AgendaConfigState, ViewType } from '@/features/agenda/types';
import { useConfig } from '@/features/config/useConfig';
import { CalendarProvider } from '@/contexts/Calendar/Provider';
import { CalendarView } from '@/contexts/Calendar/types';
import apiDateToDateObject from '@/utils/apiDateToDateObject';

interface CalendarProviderProps {
  children: ReactNode;
}

const DEFAULT_VIEW = ViewType.WEEK;

export const AgendaProvider = ({ children }: CalendarProviderProps) => {
  const { config, patchConfig } = useConfig<AgendaConfigState>();

  const updateDate = (date: Date) => {
    const dateStringWithoutTime = date.toISOString().split('T')[0];
    patchConfig({ activeDate: dateStringWithoutTime });
  };

  const updateView = (view: ViewType) => {
    patchConfig({ viewType: view });
  };

  const calendarView = useMemo(() => {
    if (!config.viewType) return DEFAULT_VIEW;
    if (Number.isNaN(Number(config.viewType))) return DEFAULT_VIEW;
    return Number(config.viewType);
  }, [config.viewType]);

  const activeDate = useMemo(() => {
    return config.activeDate ? apiDateToDateObject(config.activeDate) : new Date();
  }, [config.activeDate]);

  return (
    <CalendarProvider
      activeDate={activeDate}
      calendarView={calendarView}
      onDateChange={updateDate}
      onCalendarViewChange={(calendarView: CalendarView) => updateView(calendarView)}
    >
      {children}
    </CalendarProvider>
  );
};
