import { Skeleton } from '@planne-software/uni/mui/material';
import { StyledDayCellSkeleton } from './styles';

const DayCellSkeleton = () => {
  return (
    <StyledDayCellSkeleton>
      <Skeleton variant='text' width={29} />
      <Skeleton variant='rounded' width={24} height={24} />
      <Skeleton variant='text' width={37} />
    </StyledDayCellSkeleton>
  );
};

export default DayCellSkeleton;
