import { TextFieldProps, TooltipProps } from '@mui/material';
import { forwardRef, Ref } from 'react';

import InputTooltip from '@/components/InputTooltip';
import { masks } from '@/utils/masks';

import { StyledTextField, Wrapper } from './styles';

type Props = TextFieldProps & {
  mask?: keyof typeof masks;
  tooltipProps?: Omit<TooltipProps, 'children' | 'title'> & { title?: React.ReactNode };
};

export const Input = forwardRef((props: Props, ref: Ref<HTMLDivElement>) => {
  const {
    name,
    id = name,
    label,
    required,
    autoComplete = 'cc-csc', // workaround to prevent browser autofill
    error,
    helperText,
    mask,
    onChange,
    tooltipProps,
    ...rest
  } = props;
  const helperTextId = `input-${id}-helper-text`;

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (mask) {
      ev.target.value = masks[mask](ev.target.value);
    }
    if (onChange) onChange(ev);
  };

  return (
    <Wrapper>
      {label || tooltipProps ? (
        <InputTooltip required={required} label={label} id={id} tooltipProps={tooltipProps} />
      ) : null}

      <StyledTextField
        id={id}
        name={name}
        aria-invalid={error}
        aria-describedby={error ? helperTextId : undefined}
        autoComplete={autoComplete}
        helperText={helperText}
        error={error}
        sx={error ? { margin: 0 } : { marginBottom: 0.5 }}
        ref={ref}
        {...rest}
        onChange={handleChange}
      />
    </Wrapper>
  );
});

export type { Props as InputProps };
export default Input;
