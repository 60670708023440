import { ConfigContext } from '@/features/config/context/context';
import { useContext } from 'react';

export function useConfig<T>(): T {
  const context = useContext(ConfigContext);

  if (!context) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }

  return context as T;
}
