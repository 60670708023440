import { IconButton } from '@mui/material';
import { Ref, forwardRef, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import Input, { InputProps } from '@/components/Form/Input';

type Props = InputProps & {
  initialShowPassword?: boolean;
};

export const PasswordInput = forwardRef((props: Props, ref: Ref<HTMLDivElement>) => {
  const { initialShowPassword = false, ...rest } = props;
  const [showPassword, setShowPassword] = useState(initialShowPassword);

  return (
    <Input
      autoComplete='current-password'
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <IconButton
            tabIndex={-1}
            aria-label='toggle password visibility'
            onClick={() => setShowPassword((state) => !state)}
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        ),
      }}
      {...rest}
      ref={ref}
    />
  );
});

export default PasswordInput;
