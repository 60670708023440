import { CircleRounded } from '@mui/icons-material';
import { Stack, useMediaQuery } from '@mui/system';
import { isSameDay } from 'date-fns';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import createScrollSnap from 'scroll-snap';

import Button from '@/components/Button';
import {
  StyledDayColumn,
  StyledDotsContainer,
  StyledHeaderItemContainer,
  StyledHeaderItemContent,
  StyledWeeklyContainer,
} from '@/features/agenda/components/SchedulingCalendar/AgendaWeeklyView/styles';
import WeeklyDay from '@/features/agenda/components/SchedulingCalendar/AgendaWeeklyView/WeeklyDay';
import { useAgenda } from '@/features/agenda/hooks/useAgenda';
import generateWeekDays from '@/features/agenda/utils/generateWeekDays';
import theme from '@/theme';
import clsx from 'clsx';
import isDayBeforeToday from '@/features/agenda/utils/isDayBeforeToday';

type DayColumnRef = HTMLElement & { fetchNextPage: () => void; hasNextPage: boolean };

const AgendaWeeklyView = () => {
  const { t } = useTranslation(['agenda'], { keyPrefix: 'weekly_view' });
  const { activeDate } = useAgenda();
  const containerRef = useRef<HTMLDivElement>(null);
  const daysComponentsRef = useRef<DayColumnRef[]>([]);
  const [hasMorePages, setHasMorePages] = useState(false);
  const todayColumnRef = useRef<HTMLDivElement>(null);
  const slideColumnRefs = useRef<Element[]>([]);
  const observer = useRef<IntersectionObserver | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const handlerNextPage = useCallback((hasNextPage: boolean) => {
    const updatedHasMorePages = daysComponentsRef.current.some((ref) => ref?.hasNextPage) || hasNextPage;
    setHasMorePages(updatedHasMorePages);
  }, []);

  const weekDays = useMemo(() => {
    return generateWeekDays(true, activeDate) as Date[];
  }, [activeDate]);

  const today = new Date();

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const dateFormatted = (date: Date) => {
    return `${date.toLocaleDateString('default', { weekday: 'short' })} - ${date.toLocaleDateString('default', { day: '2-digit', month: '2-digit' })}`;
  };

  const addDayComponentRef = (element: DayColumnRef, index: number) => {
    daysComponentsRef.current[index] = element;
  };

  const handleLoadMore = () => {
    daysComponentsRef.current.forEach((element) => element.fetchNextPage());
  };

  const setSlideColumnRefAsActive = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const { target: activeElement } = entry;

        const index = slideColumnRefs.current.indexOf(activeElement);
        setActiveIndex(index);
      }
    });
  };

  const goToDay = (date: Date, index: number) => {
    const elementIndex = slideColumnRefs.current.findIndex((element) => element.id === `day-${date.toISOString()}`);
    if (elementIndex) {
      slideColumnRefs.current[elementIndex].scrollIntoView({ behavior: 'smooth', block: 'center' });
      setActiveIndex(index);
    }
  };

  useEffect(() => {
    if (slideColumnRefs.current.length > 0) {
      observer.current = new IntersectionObserver(setSlideColumnRefAsActive, {
        root: containerRef.current,
        threshold: 0.5,
      });

      slideColumnRefs.current.forEach((element) => {
        observer.current?.observe(element);
      });

      return () => {
        observer.current?.disconnect();
      };
    }
  }, [slideColumnRefs.current]);

  useEffect(() => {
    if (containerRef.current) {
      createScrollSnap(containerRef.current, {
        timeout: 100,
        duration: 300,
        threshold: 0.5,
        snapStop: true,
      });
    }
  }, []);

  useEffect(() => {
    if (todayColumnRef.current) {
      todayColumnRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [todayColumnRef.current]);

  return (
    <Fragment>
      <StyledWeeklyContainer ref={containerRef}>
        {weekDays.map((day) => (
          <StyledHeaderItemContainer key={day.toISOString()}>
            <StyledHeaderItemContent active={isSameDay(day, today)} ref={isSameDay(day, today) ? todayColumnRef : null}>
              {dateFormatted(day)}
            </StyledHeaderItemContent>
          </StyledHeaderItemContainer>
        ))}

        {weekDays.map((day, index) => (
          <StyledDayColumn
            key={day.toISOString()}
            className={clsx({
              first: index === 0,
              last: index === weekDays.length - 1,
              beforeToday: isDayBeforeToday(day),
            })}
            id={`day-${day.toISOString()}`}
            ref={(element) => {
              if (element && !slideColumnRefs.current.includes(element)) {
                slideColumnRefs.current.push(element);
              }
            }}
          >
            <WeeklyDay
              day={day}
              ref={(element: DayColumnRef) => addDayComponentRef(element, index)}
              nexPageResult={handlerNextPage}
            />
          </StyledDayColumn>
        ))}
      </StyledWeeklyContainer>

      {hasMorePages && isDesktop ? (
        <Stack sx={{ width: '100%', alignItems: 'center', padding: theme.spacing(6) }}>
          <Button variant='outlined' onClick={handleLoadMore}>
            {t('load_more')}
          </Button>
        </Stack>
      ) : null}

      {!isDesktop ? (
        <StyledDotsContainer>
          <Stack
            component='ul'
            sx={{
              flexDirection: 'row',
              padding: theme.spacing(2),
              listStyle: 'none',
              gap: theme.spacing(1),
              borderRadius: theme.radius.lg,
              border: `1px solid ${theme.palette.colors.primary[100]}`,
            }}
          >
            {weekDays.map((date, index) => (
              <li key={date.toISOString()}>
                <Stack
                  component='button'
                  onClick={() => goToDay(date, index)}
                  sx={{
                    padding: 0,
                    border: 0,
                    background: 'none',
                    color:
                      activeIndex === index ? theme.palette.colors.primary[500] : theme.palette.colors.primary[100],
                  }}
                >
                  <CircleRounded sx={{ width: '8px', height: '8px' }} />
                </Stack>
              </li>
            ))}
          </Stack>
        </StyledDotsContainer>
      ) : null}
    </Fragment>
  );
};
export default AgendaWeeklyView;
