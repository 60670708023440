import { colors } from '@/theme';
import { Alert, Stack } from '@mui/material';
import { Theme, styled } from '@mui/system';

const serenityColors = (theme: Theme) => ({
  info: {
    font: theme.palette.colors.primary[500],
    background: theme.palette.colors.primary[100],
  },
  error: {
    font: colors.error[500],
    background: colors.error[100],
  },
  warning: {
    font: colors.warning[500],
    background: colors.warning[100],
  },
  success: {
    font: colors.success[700],
    background: colors.success[100],
  },
});

export const ToastWarper = styled(Alert)(({ severity, theme }) => {
  const color = severity && serenityColors(theme)[severity].font;
  const backgroundColor = severity && serenityColors(theme)[severity].background;
  return {
    color,
    backgroundColor,
  };
});
export const ToastTitle = styled(Stack)(() => ({
  flexDirection: 'row',
  gap: '0.25rem',
  alignItems: 'center',
}));
