import { styled } from '@mui/material';
import { GridActionsCellItem as MUIGridActionsCellItem, GridActionsCellItemProps } from '@mui/x-data-grid';
import { Ref } from 'react';

export type ExtendedGridActionsCellItemProps = GridActionsCellItemProps & {
  href?: string;
  target?: string;
  ref?: Ref<HTMLElement>;
};

export const GridActionsCellItem = styled(MUIGridActionsCellItem)<ExtendedGridActionsCellItemProps>(() => ({}));
