import accessTokenManager from '@/contexts/Auth/AccessTokenManager';
import Axios, { AxiosError, AxiosHeaders, AxiosRequestConfig, AxiosResponse } from 'axios';

export const AXIOS_INSTANCE = Axios.create({
  withCredentials: true,
});

export function customInstance<T>(config: AxiosRequestConfig, options?: AxiosRequestConfig & {returnResponse?: boolean}): Promise<T> 
export function customInstance<T>(config: AxiosRequestConfig, options?: AxiosRequestConfig & {returnResponse?: boolean}): Promise<AxiosResponse<T>> {
  const accessToken = accessTokenManager.getToken();

  const headers = new AxiosHeaders();

  if (accessToken) {
    headers.set('Authorization', `Bearer ${accessToken}`);
  }

  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({
    ...config,
    ...options,
    cancelToken: source.token,
    headers: {
      ...config.headers,
      ...headers,
      ...options?.headers,
    },
  }).then((response) => 
    // this is a workaround to return the response object instead of the data.
    options?.returnResponse ? response : response.data);

  // @ts-expect-error cancel is not defined
  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};

AXIOS_INSTANCE.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const accessToken = accessTokenManager.getToken();
    if (error.response?.status === 401 && accessToken) {
      accessTokenManager.clearToken();
      window.location.href = '/login?expiredConnection=&redirectTo=' + window.location.pathname;
    }
    return Promise.reject(error);
  },
);

export type ErrorType<Error> = AxiosError<Error>;
export type BodyType<BodyData> = BodyData;
export default customInstance;
