import { Card, Stack, Typography } from '@mui/material';
import { IconAlertTriangle, IconBarrierBlock, IconEdit, IconTrash } from '@tabler/icons-react';
import { Fragment, Suspense, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Chip from '@/components/Chip';
import Tooltip from '@/components/Tooltip';
import AvailabilitySubItens from '@/features/agenda/components/AvailabilitySubItens';
import {
  StyledActionButton,
  StyledActionLink,
  StyledCardContent,
} from '@/features/agenda/components/SchedulingModal/SchedulingModalContent/SchedulingAvailabilityRelated/AvailabilityCard/styles';
import { getAvailabilityQuantitiesText } from '@/features/agenda/utils/getAvailabilityQuantitiesText';
import isAvailabilityContentArchived from '@/features/agenda/utils/isAvailabilityContentArchived';
import { Availability, AvailabilityTypeEnum } from '@/services/SellerApi';
import theme from '@/theme';
import apiDateToDateObject from '@/utils/apiDateToDateObject';

// const LazyRemoveAvailabilityRecurrenceSelector = lazy(
//   () => import('@/features/agenda/components/RemoveAvailabilityRecurrenceSelector'),
// );
import LazyRemoveAvailabilityRecurrenceSelector from '@/features/agenda/components/RemoveAvailabilityRecurrenceSelector';

const AvailabilityCard = (props: { availability: Availability }) => {
  const { availability } = props;
  const { t } = useTranslation(['agenda']);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

  const isArchived = useMemo(
    () =>
      isAvailabilityContentArchived(availability).isSomeArchived &&
      availability.type !== AvailabilityTypeEnum.ProductTariffTypes,
    [availability],
  );

  const archivedTypeString = useMemo(() => {
    switch (availability.type) {
      case AvailabilityTypeEnum.Products:
        return t('availability_archived.per_products');
      case AvailabilityTypeEnum.Additionals:
        return t('availability_archived.additional');
      case AvailabilityTypeEnum.Tariffs:
        return t('availability_archived.tariff');
      case AvailabilityTypeEnum.ProductTariffTypes:
        return t('availability_archived.product_tariff_type');
      default:
        return '';
    }
  }, [availability, t]);

  const onOpenRemoveModal = () => {
    setIsRemoveModalOpen(true);
  };

  const onCloseRemoveModal = () => {
    setIsRemoveModalOpen(false);
  };

  return (
    <Fragment>
      <Card>
        <StyledCardContent>
          <Stack sx={{ gap: theme.spacing(2), position: 'relative' }}>
            <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Stack
                sx={{ flexDirection: 'row', justifyContent: 'center', alignContent: 'center', gap: theme.spacing(2) }}
              >
                <IconBarrierBlock />
                <Chip label={t(`filter.availability_type.${availability.type}`)} size='small' />
              </Stack>

              <Stack sx={{ flexDirection: 'column', alignItems: 'flex-end', gap: theme.spacing(1) }}>
                {availability.quantity ? (
                  <Typography variant='smallBold'>
                    {t('modal.scheduling.availability-related.quantity')}: {getAvailabilityQuantitiesText(availability)}
                  </Typography>
                ) : null}

                {isArchived ? (
                  <Chip
                    icon={
                      <IconAlertTriangle width='0.875rem' height='0.875rem' color={theme.palette.colors.warning[700]} />
                    }
                    label={archivedTypeString}
                    size='small'
                    color={{
                      font: theme.palette.colors.warning[700],
                      background: theme.palette.colors.warning[100],
                    }}
                  />
                ) : null}
              </Stack>
            </Stack>

            {availability.fromTime && availability.toTime ? (
              <Stack>
                <Typography variant='smallBold'>
                  {t('modal.scheduling.availability-related.start_end_time')}

                  <Typography variant='smallRegular'>
                    {availability.fromTime} - {availability.toTime}
                  </Typography>
                </Typography>
              </Stack>
            ) : null}

            <AvailabilitySubItens availability={availability} />

            <Stack sx={{ flexDirection: 'row' }}>
              <Stack sx={{ flexDirection: 'row', marginLeft: 'auto', gap: theme.spacing(1) }}>
                <StyledActionButton onClick={() => onOpenRemoveModal()}>
                  <Tooltip title={t('delete')} placement='left' width='auto'>
                    <IconTrash />
                  </Tooltip>
                </StyledActionButton>

                <StyledActionLink to={`../availabilities/${availability.id}/edit`}>
                  <Tooltip title={t('edit')} width='auto'>
                    <IconEdit />
                  </Tooltip>
                </StyledActionLink>
              </Stack>
            </Stack>
          </Stack>
        </StyledCardContent>
      </Card>

      <Suspense fallback={null}>
        {isRemoveModalOpen ? (
          <LazyRemoveAvailabilityRecurrenceSelector
            availabilityId={availability.id}
            isOpen={isRemoveModalOpen}
            onClose={() => onCloseRemoveModal()}
            initialDate={apiDateToDateObject(availability.appliesAt)}
          />
        ) : null}
      </Suspense>
    </Fragment>
  );
};

export default AvailabilityCard;
