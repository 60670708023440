import BackdropLoading from '@/components/BackdropLoading';
import PageLayout from '@/layouts/PageLayout';
import { Fragment, Suspense, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Stack } from '@mui/material';
import theme from '@/theme';
import CouponForm from '@/features/coupons/components/Form';
import { useSellerApi } from '@/hooks/useSellerApi';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { Coupon, CouponProduct, CouponUsageType } from '@/services/SellerApi';
import { BreadcrumbItemProps } from '@/components/Breadcrumbs';
import { enqueueSnackbar } from 'notistack';
import Bugsnag from '@/services/bugsnag';
import { NotifiableError } from '@bugsnag/js';
import useAccount from '@/hooks/useAccount';

const CouponFormPage = () => {
  const { t } = useTranslation(['coupons']);

  const { selectedAccount } = useAccount();
  const { couponsApi } = useSellerApi();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [coupon, setCoupon] = useState<Coupon | undefined>(undefined);
  const [couponProducts, setCouponProducts] = useState<CouponProduct[]>([]);

  const isEdit = !!id;

  const pageTitle = useMemo(() => {
    if (isEdit && coupon) {
      return `${t('page_form.edit_coupon_title')} - ${coupon.code}`;
    }

    return t('page_form.new_coupon_title');
  }, [isEdit, t, coupon]);

  const breadcrumbs: BreadcrumbItemProps[] = [
    {
      label: t('page_form.breadcrumb_coupons'),
      to: '../coupons',
    },
    {
      label: t(isEdit ? 'page_form.edit_coupon_title' : 'page_form.new_coupon_title'),
    },
  ];

  const fetchAllCouponProducts = async (couponId: string) => {
    let fetchedCouponProducts: CouponProduct[] = [];
    let offset = 0;
    const limit = 100;
    let hasMore = true;

    while (hasMore) {
      const { data: products } = await couponsApi.getCouponProducts({
        couponId,
        include: ['product'],
        limit,
        offset,
      });

      fetchedCouponProducts = [...fetchedCouponProducts, ...products];
      offset += limit;
      hasMore = products.length === limit;
    }

    return fetchedCouponProducts;
  };

  const fetchCoupon = async (couponId: string) => {
    setIsLoading(true);
    try {
      const { data: fetchedCoupon } = await couponsApi.getCoupon({ couponId });
      setCoupon(fetchedCoupon);

      if (fetchedCoupon.usageType === CouponUsageType.Product) {
        const fetchedCouponProducts = await fetchAllCouponProducts(couponId);

        setCouponProducts(fetchedCouponProducts);
      }
    } catch (error) {
      enqueueSnackbar(t('get_error'), { variant: 'error' });
      Bugsnag.notify(error as NotifiableError);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!id) return;

    fetchCoupon(id);
  }, [id]);

  return (
    <Fragment>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <PageLayout
        title={pageTitle}
        showFilterButton={false}
        showSearchInput={false}
        showGoBackButton={true}
        breadcrumbs={breadcrumbs}
      >
        <Suspense fallback={<BackdropLoading />}>
          {isLoading ? (
            <BackdropLoading />
          ) : (
            <Stack maxWidth={theme.spacing(210)} sx={{ width: '100%', padding: theme.spacing(6, 5) }}>
              <CouponForm onCancel={() => navigate('../coupons')} coupon={coupon} couponProducts={couponProducts} onSuccess={() => navigate(`/${selectedAccount?.app?.code}/coupons`)} />
            </Stack>
          )}
        </Suspense>
      </PageLayout>
    </Fragment>
  );
};

export default CouponFormPage;
