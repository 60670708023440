import { cnpj, cpf } from 'cpf-cnpj-validator';
import countries from '@/data/countries.json';

export const getCustomerDocumentFormatted = (document: string) => {
  if (cpf.isValid(document)) {
    return cpf.format(document);
  }

  if (cnpj.isValid(document)) {
    return cnpj.format(document);
  }

  return document;
};

export const findCountry = (countryCode: string) => {
  const country = countries.find((country) => country.alpha3 === countryCode);

  return country;
};
