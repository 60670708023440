import { Box, IconButton, Menu, MenuItem, Stack, StackProps, useMediaQuery } from '@mui/material';
import { IconMenu2 } from '@tabler/icons-react';
import { useMemo, useState } from 'react';

import Breadcrumbs, { BreadcrumbItemProps } from '@/components/Breadcrumbs';
import Button from '@/components/Button';
import Tabs, { TabsProps } from '@/components/Tabs';
import useUI from '@/hooks/useUI';
import BackButton from '@/layouts/PageLayout/BackButton';
import { IPageLayoutActions } from '@/layouts/PageLayout/PageLayoutTypes';
import theme from '@/theme';

import PageLayoutActions from './PageLayoutActions';
import PageLayoutFilterConfig, { FilterFieldsType } from './PageLayoutFilterConfig';

import { ChildrenProps } from '@/components/FilterDrawer';

import {
  Divider,
  Header,
  HeaderLeftContainer,
  HeaderMiddleContainer,
  HeaderRightContainer,
  HeaderTabsContainer,
  Title,
} from './styles';
import ErrorBoundary from '@/components/Error/ErrorBoundary';
import ErrorTechnical from '@/components/Error/ErrorTechnical';

export type PageLayoutProps = {
  children: React.ReactNode;
  title: React.ReactNode;
  actions?: IPageLayoutActions;
  onGoBack?: () => void;
  breadcrumbs?: Array<BreadcrumbItemProps>;
  slots?: {
    FilterFields?: FilterFieldsType;
  };
  showFilterButton?: boolean;
  showSearchInput?: boolean;
  slotProps?: {
    searchInput?: {
      placeholder?: string;
    };
    tab?: TabsProps;
    filterFields?: Partial<ChildrenProps>;
  };
  showGoBackButton?: boolean;
  showFilterActions?: boolean;
};

export const PageLayout = (props: PageLayoutProps) => {
  const {
    title,
    children,
    actions,
    breadcrumbs = undefined,
    showFilterButton = true,
    showSearchInput = true,
    showFilterActions = true,
    slots,
    slotProps,
    showGoBackButton = false,
    onGoBack,
  } = props;
  const hasTabs = useMemo(() => !!slotProps?.tab?.tabs.length, [slotProps?.tab]);
  const hasActions = useMemo(() => !!actions?.primary || !!actions?.secondary?.length, [actions]);
  const hasActionsSecondary = useMemo(() => !!actions?.secondary?.length && actions.secondary.length > 0, [actions]);
  const hasSearchOrFilter = useMemo(() => showSearchInput || showFilterButton, [showSearchInput, showFilterButton]);
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const { toggleDrawer } = useUI();

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const handleOpenSecondaryMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleCloseSecondaryMenu = () => {
    setAnchorElement(null);
  };

  const renderFilterAndSearch = (props: StackProps) => {
    const width = showSearchInput ? '100%' : 'auto';
    return (
      <Stack {...props} width={width}>
        <PageLayoutFilterConfig
          showSearchInput={showSearchInput}
          showFilterActions={showFilterActions}
          FilterFields={slots?.FilterFields}
          filterFieldsProps={slotProps?.filterFields}
          showFilterButton={showFilterButton}
          searchInput={slotProps?.searchInput}
        />
      </Stack>
    );
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorTechnical}>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', position: 'relative' }}>
        <Box sx={{ width: '100%' }}>
          <Header withSearch={showSearchInput}>
            <HeaderLeftContainer>
              {breadcrumbs && !isTablet ? (
                <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: theme.spacing(1) }}>
                  {showGoBackButton ? <BackButton iconSize={16} onGoBack={onGoBack} /> : null}

                  <Breadcrumbs breadcrumbsItem={breadcrumbs} />
                </Stack>
              ) : null}

              <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: theme.spacing(1) }}>
                {showGoBackButton && !breadcrumbs ? <BackButton onGoBack={onGoBack} /> : null}

                {!showGoBackButton && !breadcrumbs && isTablet ? (
                  <IconButton edge='start' color='primary' aria-label='open drawer' onClick={() => toggleDrawer()}>
                    <IconMenu2 />
                  </IconButton>
                ) : null}

                {showGoBackButton && isTablet ? <BackButton onGoBack={onGoBack} /> : null}

                <Title
                  component='h2'
                  variant={isTablet ? 'regularBold' : 'largeBold'}
                  title={title as string}
                  data-testid='header-title'
                >
                  {title}
                </Title>
              </Stack>
            </HeaderLeftContainer>

            {hasSearchOrFilter ? (
              <HeaderMiddleContainer withSearch={showSearchInput}>
                {renderFilterAndSearch({ minWidth: showSearchInput ? '220px' : 'auto' })}
                {!isTablet && hasActions && hasSearchOrFilter ? <Divider /> : null}
              </HeaderMiddleContainer>
            ) : null}

            {hasActions ? (
              <HeaderRightContainer sx={{ flexDirection: 'row' }}>
                {hasActions ? (
                  <PageLayoutActions
                    actions={actions}
                    {...(hasActionsSecondary ? { handleOpenSecondaryMenu } : undefined)}
                  />
                ) : null}
              </HeaderRightContainer>
            ) : null}

            {hasTabs ? (
              <HeaderTabsContainer>
                <Tabs root={slotProps!.tab!.root} tabs={[...slotProps!.tab!.tabs]} />
              </HeaderTabsContainer>
            ) : null}
          </Header>
        </Box>

        <Menu
          anchorEl={anchorElement}
          open={Boolean(anchorElement)}
          onClose={handleCloseSecondaryMenu}
          anchorOrigin={{ vertical: isTablet ? 96 : 46, horizontal: 'left' }}
        >
          {actions?.secondary?.map((action, index) => (
            <MenuItem key={index} component={Button} sx={{ gap: 1, alignItems: 'center' }} {...action}>
              {action.Icon ? action.Icon : null}
              {action.label}
            </MenuItem>
          ))}
        </Menu>

        <Box width='100%' sx={{ overflowY: 'auto', flexGrow: 1, display: 'flex' }}>
          {children}
        </Box>
      </Box>
    </ErrorBoundary>
  );
};

export default PageLayout;
