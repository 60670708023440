import { Stack } from '@mui/material';
import { IconCalendarMonth } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { Edit05, Trash01, X } from '@untitled-ui/icons-react';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import BackdropLoading from '@/components/BackdropLoading';
import Button from '@/components/Button';
import Loading from '@/components/Loading';
import ModalOnDesktopDrawerOnMobile from '@/components/Modal/ModalOnDesktopDrawerOnMobile';
import { RecurrenceSelectorModal } from '@/features/agenda/components/RecurrenceSelector';
import SchedulingModalContent from '@/features/agenda/components/SchedulingModal/SchedulingModalContent';
import SchedulingModalTitle from '@/features/agenda/components/SchedulingModal/SchedulingModalTitle';
import { AnalyticsEvents } from '@/hooks/analytics/enum/analyticsEvents';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import { usePreserveQueryParamsNavigate } from '@/hooks/usePreserveQueryParamsNavigate';
import { useSellerApi } from '@/hooks/useSellerApi';
import { Scheduling } from '@/services/SellerApi';
import theme from '@/theme';
import { UpdateRulesProps } from '@/features/agenda/types';
import { format } from 'date-fns';
import apiDateToDateObject from '@/utils/apiDateToDateObject';

interface Props {
  schedulingId?: string;
  isOpen: boolean;
}

const SchedulingModal = (props: Props) => {
  const { schedulingId, isOpen } = props;
  const { t } = useTranslation(['agenda']);
  const queryClient = useQueryClient();
  const navigate = usePreserveQueryParamsNavigate();

  const { schedulingsApi } = useSellerApi();
  const { track } = useAnalytics();
  const { schedulingId: schedulingIdParam } = useParams();
  const [loading, setLoading] = useState(false);
  const [scheduling, setScheduling] = useState<Scheduling | undefined>(undefined);

  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [removing, setRemoving] = useState(false);

  const onClose = () => {
    navigate('..');
  };

  const getScheduling = async (id: string) => {
    setLoading(true);
    try {
      const { data } = await schedulingsApi.getScheduling({
        schedulingId: id,
        include: [
          'product',
          'product.defaultTariffGroupPriceInfo',
          'finalTariffGroup.priceInfo',
          'tariffGroup.priceInfo',
        ],
      });

      setScheduling(data);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('modal.error.could_not_retrieve_scheduling'), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleRemove = async (data: UpdateRulesProps) => {
    setRemoveModalOpen(false);
    setRemoving(true);

    try {
      await schedulingsApi.deleteScheduling({
        schedulingId: schedulingId ?? schedulingIdParam ?? '',
        deleteSchedulingParams: {
          rule: data.rule,
          ruleUntilDate: data.ruleUntilDate ? format(data.ruleUntilDate, 'yyyy-MM-dd') : undefined,
        },
      });

      queryClient.invalidateQueries({ queryKey: ['aggregatedSchedulings'] });
      queryClient.invalidateQueries({ queryKey: ['schedulings'] });
      queryClient.invalidateQueries({ queryKey: ['availabilities'] });

      enqueueSnackbar(t('modal.remove_scheduling.success'), {
        variant: 'success',
      });

      track(AnalyticsEvents.SCHEDULING_REMOVE, {
        schedulingId,
        schedulingData: data,
      });

      onClose();
    } catch (error) {
      console.error(error);

      track(AnalyticsEvents.SCHEDULING_REMOVE, { schedulingId: scheduling!.id, data, error });

      enqueueSnackbar(t('modal.remove_scheduling.error'), {
        variant: 'error',
      });
    } finally {
      setRemoving(false);
    }
  };

  useEffect(() => {
    getScheduling(schedulingId ?? schedulingIdParam ?? '');
  }, [schedulingId, schedulingIdParam]);

  return (
    <>
      <ModalOnDesktopDrawerOnMobile
        isOpen={isOpen}
        onClose={() => onClose()}
        showCloseButton={false}
        title={<SchedulingModalTitle />}
        width='31.25rem'
      >
        {removing ? <BackdropLoading /> : null}

        {loading ? (
          <Loading />
        ) : scheduling ? (
          <>
            <SchedulingModalContent scheduling={scheduling} />

            <Stack sx={{ flexDirection: 'row', gap: theme.spacing(3), paddingTop: theme.spacing(4) }}>
              <Button variant='contained' color='primary' onClick={() => onClose()}>
                <X /> {t('modal.close')}
              </Button>

              <Button
                variant='outlined'
                color='secondary'
                onClick={() => setRemoveModalOpen(true)}
                sx={{ marginLeft: 'auto' }}
              >
                <Trash01 />
                {t('modal.delete')}
              </Button>
              <Button variant='outlined' color='secondary' href={`edit`}>
                <Edit05 />
                {t('modal.edit')}
              </Button>
            </Stack>
          </>
        ) : null}
      </ModalOnDesktopDrawerOnMobile>

      <RecurrenceSelectorModal
        title={
          <Stack flexDirection='row' alignItems='center' gap='0.5rem'>
            <IconCalendarMonth /> {t('modal.remove_scheduling.title')}
          </Stack>
        }
        description={t('modal.remove_scheduling.message')}
        onConfirm={(data: UpdateRulesProps) => handleRemove(data)}
        onCancel={() => setRemoveModalOpen(false)}
        isOpen={removeModalOpen}
        onClose={() => setRemoveModalOpen(false)}
        initialDate={apiDateToDateObject(scheduling?.appliesAt ?? '')}
      />
    </>
  );
};

export default SchedulingModal;
