import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { TariffGroup } from '@/services/SellerApi';
import useLoadProductTariffGroupsQuery from '@/queries/useLoadProductTariffGroupsQuery';
import Select from '@/components/Form/Select';

interface Props {
  productId: string;
  disabled?: boolean;
  disabledMessage?: string;
  value?: TariffGroup;
  onChange: (tariffGroup?: TariffGroup) => void;
}

const TariffGroupSelector = (props: Props) => {
  const { productId, disabledMessage, disabled = !productId, onChange, value } = props;

  const { t } = useTranslation(['ui']);

  const [getAllTariffGroupsDetails] = useLoadProductTariffGroupsQuery();

  const { data: tariffGroups, isLoading: tariffGroupsLoading } = getAllTariffGroupsDetails(productId);

  const handleSelectTariffGroup = (tariffGroup?: TariffGroup) => {
    onChange(tariffGroup?.id === 'no-id' ? undefined : tariffGroup);
  };

  const defaultTariffGroup = useMemo(() => {
    if (!tariffGroups) return null;

    return tariffGroups.find((tariffGroup) => tariffGroup.isDefault);
  }, [tariffGroups]);

  const selectedTariffGroup = useMemo(() => {
    if (!tariffGroups) return null;

    if (!value?.id) return { ...defaultTariffGroup, value: 'no-id' };

    if (value.deletedAt) return null;

    return tariffGroups.find((tariffGroup) => tariffGroup.id === value.id);
  }, [value, tariffGroups, defaultTariffGroup]);

  const isLoading = useMemo(() => tariffGroupsLoading, [tariffGroupsLoading]);

  const isDisabled = useMemo(
    () => disabled || isLoading || !productId || tariffGroups?.length === 0,
    [disabled, isLoading, productId, tariffGroups],
  );

  const selectPlaceholder = useMemo(() => {
    if (isLoading) return t('loading');
    if (!productId) return disabledMessage ?? t('tariff_group_selector.product_id_not_found');
    if (tariffGroups?.length === 0) return t('tariff_group_selector.empty_message');
    if (!selectedTariffGroup) return t('tariff_group_selector.tariff_group_error');
    return t('tariff_group_selector.select_tariff_group');
  }, [isLoading, productId, disabledMessage, tariffGroups, selectedTariffGroup]);

  const optionsWithDefaultOnTop = useMemo(() => {
    if (!tariffGroups) return [];

    const options: TariffGroup[] = tariffGroups;

    if (defaultTariffGroup) {
      options.push({ ...(defaultTariffGroup as TariffGroup), id: 'no-id' });
    }

    options.sort((a, b) => {
      if (a.id === 'no-id') return -1;
      if (b.id === 'no-id') return 1;
      return 0;
    });

    const uniqueOptions = options.filter((option, index, self) => index === self.findIndex((t) => t.id === option.id));

    return uniqueOptions;
  }, [tariffGroups, defaultTariffGroup]);

  const buildOptionLabel = (option: TariffGroup) => {
    return option.id === 'no-id'
      ? `(${t('tariff_group_selector.default_tariff_group_label')}) ${option.name}`
      : option.name;
  };

  return (
    <Select
      fullWidth
      disableClearable
      options={optionsWithDefaultOnTop}
      value={selectedTariffGroup}
      disabled={isDisabled}
      getOptionLabel={(option) => buildOptionLabel(option as TariffGroup)}
      onChange={(_, value) => {
        handleSelectTariffGroup(value as TariffGroup);
      }}
      getOptionKey={(option) => (option as TariffGroup).id}
      isOptionEqualToValue={(option, value) => (option as TariffGroup).id === (value as TariffGroup).id}
      placeholder={selectPlaceholder}
    />
  );
};

export default TariffGroupSelector;
