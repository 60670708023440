import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, CircularProgress, Divider, Grid, Typography } from '@mui/material';

import { colors } from '@/theme';
import ValueWithLabel from './ValueWithLabel';
import StatusChip from '../StatusChip';
import { createMask } from '@/utils/masks';
import PageLayout from '@/layouts/PageLayout';
import PartnershipItemList from '../ItemList';
import EmptyPage from '@/components/EmptyPage';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import { useSellerApi } from '@/hooks/useSellerApi';
import DivulgationQrCode from '../DivulgationQrCode';
import PartnershipCouponList from './PartnershipCouponList';
import PartnershipDivulgationLink from './PartnershipDivulgationLink';
import {
  AssociationModel,
  Partnership,
  PartnershipStatus,
  PartnershipsApiGetPartnershipRequest,
  PublicPartnershipItem,
} from '@/services/SellerApi';
import { Image } from '@/components/Image';
import { Helmet } from 'react-helmet-async';
import useAccount from '@/hooks/useAccount';

export const PartnershipDetail = () => {
  const { id: partnershipId = '' } = useParams<{ id: string }>();

  const { selectedAccount } = useAccount();
  const [partnership, setPartnership] = useState<Partnership>();

  const { track } = useAnalytics();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['partnership']);

  const navigate = useNavigate();
  const { partnershipsApi } = useSellerApi();

  const [loading, setLoading] = useState(true);
  const [partnershipItems, setPartnershipItems] = useState<PublicPartnershipItem[]>([]);

  const isAgent = partnership?.agentId === selectedAccount?.appId;
  const isProvider = partnership?.providerId === selectedAccount?.appId;
  const hasNoPermission = !isAgent && !isProvider && !loading;

  const partnershipParentRoute = isProvider ? '../partnership/agents' : '../partnership/providers';

  const getPartnership = async () => {
    const payload: PartnershipsApiGetPartnershipRequest = {
      partnershipId,
      include: ['provider.info', 'agent.info', 'publicProposal.provider.info'],
    };

    try {
      setLoading(true);

      const { data } = await partnershipsApi.getPartnership(payload);

      setPartnership(data);
    } catch (error) {
      enqueueSnackbar(t('detail.could_not_load_partnership_detail'), { variant: 'error' });

      track('partnershipDetail/retrievePartnershipDetailSuccess', { payload });
    } finally {
      setLoading(false);
    }
  };

  const acceptPartnership = async (partnership: Partnership) => {
    track('partnershipDetail/acceptPartnershipRequest', { partnershipId: partnership.id, partnership });

    try {
      setLoading(true);

      await partnershipsApi.approvePartnership({ partnershipId: partnership.id });

      getPartnership();
    } catch (error) {
      setLoading(false);

      enqueueSnackbar(t('list.could_not_accept'));

      track('partnershipDetail/acceptPartnershipFail', { partnershipId: partnership.id, partnership, error });
    }
  };

  const declinePartnership = async (partnership: Partnership) => {
    track('partnershipDetail/declinePartnershipRequest', { partnershipId: partnership.id, partnership });

    try {
      setLoading(true);

      await partnershipsApi.declinePartnership({ partnershipId: partnership.id });

      getPartnership();
    } catch (error) {
      setLoading(false);

      enqueueSnackbar(t('list.could_not_decline'));

      track('partnershipDetail/declinePartnershipFail', { partnershipId: partnership.id, partnership, error });
    }
  };

  const handleStatusChange = (partnership: Partnership, status: string) => {
    track('partnershipDetail/statusClick', { partnershipId: partnership.id, partnership });

    switch (status) {
      case PartnershipStatus.Ongoing:
        acceptPartnership(partnership);
        break;
      case PartnershipStatus.Declined:
        declinePartnership(partnership);
        break;
      default:
        break;
    }
  };

  const { divulgationLink, partnerRole } = useMemo(() => {
    const divulgationLink = `${partnership?.provider?.siteUrl}?ac=${partnership?.affiliationCode}`;

    const partnerRole = isProvider ? partnership?.agent : partnership?.provider;

    return {
      partnerRole,
      divulgationLink,
    };
  }, [partnership]);

  const breadcrumbs = useMemo(
    () => [
      {
        label: t(isProvider ? 'detail.breadcrumbs.affiliations' : 'detail.breadcrumbs.providers'),
        to: partnershipParentRoute,
      },
      ...(partnerRole ? [{ label: partnerRole.name, to: '.' }] : []),
    ],
    [partnerRole, partnershipParentRoute, t],
  );

  const title = t(
    `detail.${isProvider ? 'affiliated_partnership_detail_page_title' : 'provided_partnership_detail_page_title'}`,
  );

  useEffect(() => {
    getPartnership();
    track('partnershipDetail/access', { partnershipId });
  }, []);

  const hasDirectPurchaseInPartnershipItems = partnershipItems?.some((item) =>
    item.associationModels.includes(AssociationModel.DirectPurchase),
  );

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      {!hasNoPermission ? (
        <PageLayout
          breadcrumbs={breadcrumbs}
          title={partnerRole?.name ?? ''}
          showGoBackButton
          showFilterButton={false}
          showSearchInput={false}
        >
          <Grid
            container
            sx={{ mt: { xs: 4, md: 6 } }}
            gap={{ xs: 2, md: 4 }}
            px={{ xs: 4, md: 6 }}
            position='relative'
          >
            {loading ? (
              <Box
                sx={{
                  zIndex: 99999,
                  position: 'absolute',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                display='flex'
                width='96%'
                height='80%'
              >
                <CircularProgress />
              </Box>
            ) : null}

            {partnership ? (
              <>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container justifyContent='space-between' alignItems='center'>
                        <Grid item>
                          <Box display='flex' flexDirection={{ xs: 'column', md: 'row' }} gap={4}>
                            <Box display='flex' flexDirection='column' gap={4}>
                              <Box display='flex' justifyContent='center'>
                                <Image
                                  width={partnership.publicProposal?.provider?.logoUrl ? 69 : 40}
                                  src={partnership.publicProposal?.provider?.logoUrl ?? undefined}
                                />
                              </Box>

                              <Typography variant='largeBold' color={colors.gray[800]}>
                                {partnership.publicProposal?.name || ''}
                              </Typography>
                            </Box>

                            <Box display='flex' flexDirection='column' gap={1}>
                              <ValueWithLabel label={t('detail.name')} value={partnerRole!.info?.contact.name ?? ''} />

                              <ValueWithLabel
                                label={t('detail.phone')}
                                value={createMask({ mask: '+00 00 0 0000-0000' })(partnerRole!.contact.phone ?? '')}
                              />

                              <ValueWithLabel label={t('detail.email')} value={partnerRole!.contact.email ?? ''} />

                              {isProvider ? (
                                partnerRole!.info?.identifierType === 'cpf' ? (
                                  <ValueWithLabel
                                    label='CPF:'
                                    value={cpf.format(partnerRole!.info?.identifier ?? '')}
                                  />
                                ) : (
                                  <ValueWithLabel
                                    label='CPNJ:'
                                    value={cnpj.format(partnerRole!.info?.identifier ?? '')}
                                  />
                                )
                              ) : null}
                            </Box>
                          </Box>
                        </Grid>

                        <Grid item>
                          <StatusChip
                            value={partnership.currentStatus}
                            onChange={(status) => handleStatusChange(partnership, status)}
                            canChange={isProvider}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {partnershipItems.length && !hasDirectPurchaseInPartnershipItems ? (
                  <>
                    <Grid item xs={12}>
                      <Box display='flex' flexDirection='column' mt={1}>
                        <Typography variant='regularBold'>{t('detail.divulgation_section')}</Typography>
                        <Divider component='div' sx={{ mt: 1 }} />
                      </Box>
                    </Grid>

                    <Grid item xs={12} mt={{ xs: 0, md: -2 }}>
                      <Grid container spacing={{ xs: 2, lg: 4 }} justifyContent='center'>
                        {partnership.currentStatus === PartnershipStatus.Ongoing ? (
                          <Grid item xs={12} md={4} lg={3} xl={2}>
                            <Grid container justifyContent={{ xs: 'center', md: 'unset' }}>
                              <Grid item xs={8} sm={5} md={12} sx={{ textAlign: 'center' }}>
                                <DivulgationQrCode
                                  divulgationLink={divulgationLink}
                                  onDownload={() =>
                                    track('partnershipDetail/downloadQrCode', {
                                      partnershipId: partnership.id,
                                      partnership,
                                    })
                                  }
                                  fileName={partnership?.agent?.name}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : null}

                        <Grid item xs={12} md>
                          <Grid container spacing={2}>
                            <PartnershipDivulgationLink
                              link={divulgationLink}
                              isProvider={isProvider}
                              partnership={partnership}
                              onUpdateAffiliationCode={({ affiliationCode }) =>
                                setPartnership({ ...partnership, affiliationCode })
                              }
                            />

                            <PartnershipCouponList
                              partnership={partnership}
                              isProvider={isProvider}
                              partnershipId={partnershipId}
                              parentLoading={loading}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : null}

                <Grid item xs={12}>
                  <PartnershipItemList
                    parentLoading={loading}
                    partnership={partnership}
                    onPartnershipItemListLoaded={setPartnershipItems}
                    columnsToRender={hasDirectPurchaseInPartnershipItems ? ['items', 'price'] : ['items', 'commission']}
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
        </PageLayout>
      ) : null}

      {(!partnership && !loading) || hasNoPermission ? (
        <Grid container>
          <Grid item xs={12} mt={25}>
            <EmptyPage
              onClick={() => navigate(hasNoPermission ? '/' : partnershipParentRoute)}
              description={t(`detail.${hasNoPermission ? 'has_no_permission' : 'could_not_load_partnership_detail'}`)}
              buttonText={
                !hasNoPermission
                  ? t(
                      isProvider
                        ? 'detail.empty_page_button_text_affiliations'
                        : 'detail.empty_page_button_text_providers',
                    )
                  : undefined
              }
            />
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

export default PartnershipDetail;
