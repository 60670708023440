import { Availability } from '@/services/SellerApi';
import generateTimeIntervals from '@/features/agenda/utils/generateTimeIntervals';

const generateDefaultTimesAndQuantities = (
  availability: Availability | undefined,
  queryParams: Record<string, string>,
  appIntervalMinutes?: number,
) => {
  const fromTime = queryParams.fromTime;
  const toTime = queryParams.toTime;
  const calendarIntervalMinutes = queryParams.calendarIntervalMinutes;
  const quantity = availability?.quantity;

  const getIntervalMinutes = () => {
    if (!appIntervalMinutes) return parseInt(calendarIntervalMinutes);

    const isCalendarIntervalMinutesValid =
      calendarIntervalMinutes &&
      !isNaN(parseInt(calendarIntervalMinutes)) &&
      parseInt(calendarIntervalMinutes) % appIntervalMinutes === 0;

    return isCalendarIntervalMinutesValid ? parseInt(calendarIntervalMinutes) : appIntervalMinutes;
  };

  if (fromTime && toTime && calendarIntervalMinutes) {
    const intervalMinutes = getIntervalMinutes();
    const intervals = generateTimeIntervals(fromTime, toTime, intervalMinutes);

    return intervals.map((interval) => ({
      fromTime: interval.from,
      toTime: interval.to,
      quantity,
    }));
  }

  if (fromTime && toTime) {
    return [{ fromTime, toTime, quantity }];
  }

  if (availability) {
    return [
      {
        fromTime: availability.fromTime,
        toTime: availability.toTime,
        quantity,
      },
    ];
  }

  return [{ fromTime: '00:00', toTime: '23:59', quantity }];
};

export default generateDefaultTimesAndQuantities;
