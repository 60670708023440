import { format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { NotifiableError } from '@bugsnag/js';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import {
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Alert,
  Grid,
  Typography,
  useMediaQuery,
  Stack,
} from '@mui/material';

import theme from '@/theme';
import Bugsnag from '@/services/bugsnag';
import { useSellerApi } from '@/hooks/useSellerApi';
import Loading from '@/components/Loading';
import ModalOnDesktopDrawerOnMobile from '@/components/Modal/ModalOnDesktopDrawerOnMobile';
import { RefundRequest, RefundRequestsApiGetSaleRefundRequestsRequest } from '@/services/SellerApi';
import Button from '@/components/Button';
import { Accordion, AccordionSummary } from '../CustomerModal/style';
import { ClockFastForward } from '@untitled-ui/icons-react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RefundRequestStatusChip from '@/features/sales/components/SaleDetail/RefundRequestStatusChip';

interface OutletContextProps {
  saleId: string;
}

export const RefundRequestHistoriesModal = () => {
  const { saleId } = useOutletContext<OutletContextProps>();

  const navigate = useNavigate();
  const { t } = useTranslation(['saleDetails']);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { refundRequestsApi } = useSellerApi();
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState<boolean>(true);
  const [refundRequests, setRefundRequests] = useState<RefundRequest[]>([]);

  const close = () => {
    navigate('..', { replace: true });
  };

  const getSaleRefundRequests = async (saleId: string) => {
    setLoading(true);

    const payload: RefundRequestsApiGetSaleRefundRequestsRequest = {
      saleId,
    };

    try {
      const { data } = await refundRequestsApi.getSaleRefundRequests(payload);

      setRefundRequests(data);
    } catch (error) {
      enqueueSnackbar(t('snackbars.could_not_load_refund_request'), { variant: 'error' });

      Bugsnag.notify(error as NotifiableError);
    } finally {
      setLoading(false);
    }
  };

  const renderObservation = (refundRequest: RefundRequest) => {
    return refundRequest.observation ? (
      <>
        <Typography component='p' variant='regularBold' color={theme.palette.colors.gray[800]}>
          {t('details.modals.refund_request_history.observations')}
        </Typography>

        <Typography variant='regularRegular' color={theme.palette.colors.gray[700]}>
          {refundRequest.observation}
        </Typography>
      </>
    ) : (
      <Typography variant='regularRegular' color={theme.palette.colors.gray[700]}>
        {t('details.modals.refund_request_history.no_observations')}
      </Typography>
    );
  };

  const { lastHistory, otherHistories } = useMemo(() => {
    if (!refundRequests.length) {
      return {
        lastHistory: null,
        otherHistories: [],
      };
    }

    if (refundRequests.length === 1) {
      return {
        lastHistory: refundRequests[0],
        otherHistories: [],
      };
    }

    return {
      lastHistory: refundRequests[refundRequests.length - 1],
      otherHistories: refundRequests.slice(0, refundRequests.length - 1),
    };
  }, [refundRequests]);

  useEffect(() => {
    if (saleId) {
      getSaleRefundRequests(saleId);
    }
  }, [saleId]);

  return (
    <ModalOnDesktopDrawerOnMobile
      width={643}
      isOpen={true}
      onClose={close}
      title={t('details.modals.refund_request_history.title')}
    >
      {loading ? <Loading /> : null}

      {!loading && lastHistory ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack direction='row' justifyContent='space-between' alignItems='center' gap={2} flexWrap='wrap'>
              <Typography variant='largeBold' color={theme.palette.colors.gray[800]}>
                {format(new Date(lastHistory.createdAt), 'dd/MM/yyyy - HH:mm')}
              </Typography>

              <RefundRequestStatusChip
                status={lastHistory.status}
                label={t(`details.refund_request_status.${lastHistory.status}`)}
              />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            {renderObservation(lastHistory)}
          </Grid>

          <Grid item xs={12}>
            <Accordion
              elevation={0}
              expanded={expanded}
              disabled={!otherHistories.length}
              onChange={(_, value) => setExpanded(value)}
            >
              <AccordionSummary expanded={expanded}>
                <Button
                  fullWidth
                  variant='text'
                  startIcon={<ClockFastForward />}
                  color={expanded ? 'primary' : 'secondary'}
                  sx={{ color: expanded ? theme.palette.colors.blue[500] : theme.palette.colors.gray[700] }}
                >
                  {t('details.modals.refund_request_history.see_more')}
                </Button>
              </AccordionSummary>

              <AccordionDetails sx={{ padding: '0px !important' }}>
                {otherHistories.map((refundRequestHistory) => (
                  <Accordion key={refundRequestHistory.id} elevation={0}>
                    <MuiAccordionSummary
                      id={refundRequestHistory.id}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={refundRequestHistory.id}
                    >
                      <Stack direction='row' gap={{ xs: 1, md: 2 }}>
                        <Typography variant='regularSemiBold'>
                          {format(new Date(refundRequestHistory.createdAt), 'dd/MM/yyyy - HH:mm')}
                        </Typography>

                        <RefundRequestStatusChip
                          size='small'
                          status={refundRequestHistory.status}
                          label={t(`details.refund_request_status.${refundRequestHistory.status}`)}
                        />
                      </Stack>
                    </MuiAccordionSummary>

                    <AccordionDetails>{renderObservation(refundRequestHistory)}</AccordionDetails>
                  </Accordion>
                ))}
              </AccordionDetails>
            </Accordion>
          </Grid>

          {isMobile ? (
            <Grid item xs={12}>
              <Button color='primary' variant='contained' onClick={close} fullWidth>
                {t('details.modals.refund_request_history.close_button')}
              </Button>
            </Grid>
          ) : null}
        </Grid>
      ) : null}

      {!loading && !refundRequests.length ? (
        <Alert severity='error'>{t('snackbars.could_not_load_refund_request')}</Alert>
      ) : null}
    </ModalOnDesktopDrawerOnMobile>
  );
};

export default RefundRequestHistoriesModal;
