interface IRemoveDuplicates {
  <T>(arr: T[], key: keyof T): T[];
}
const removeDuplicates: IRemoveDuplicates = (arr, key) => {
  return arr.filter((value, index, self) => {
    return self.findIndex((t) => t[key as keyof typeof t] === value[key as keyof typeof value]) === index;
  });
};

export default removeDuplicates;
