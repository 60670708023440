import { Reservation } from '@/services/SellerApi';
import { Reservation as NewReservation } from '@/services/sellerApiQuery/model/reservation';

export const getReservationDateTime = (reservation: Reservation | NewReservation) => {
  if (!reservation?.scheduleDate) {
    return undefined;
  }

  const date = reservation?.scheduleDate;
  const time = reservation?.scheduleTime ?? '00:00';

  const dateTimeString = `${date}T${time}:00`;

  return new Date(dateTimeString);
};
