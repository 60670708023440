import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const CameraInstruction = styled(Typography)(({ theme }) => ({
  marginBottom: '10px',
  fontSize: '1rem',
  color: theme.palette.colors.gray[800],
  fontWeight: '400',
  lineHeight: '19px',
}));

export const QrCodeContainer = styled('div')(() => ({
  marginBottom: '1rem',
  textAlign: 'center',
}));

export const QrCode = styled('img')(() => ({
  width: '100%',
  maxWidth: '160px',
  height: 'auto',
}));

export const VerifyingPayment = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontSize: '13px',
  color: theme.palette.colors.gray[500],
  fontWeight: '400',
  lineHeight: '15px',
  marginBottom: '1rem',
}));

export const PixCodeBox = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '10px',
  width: '100%',
  padding: '8px 10px',
  borderRadius: '4px',
  border: '1px solid',
  borderColor: theme.palette.colors.gray[400],
  overflow: 'hidden',
}));

export const PixCode = styled(Typography)(({ theme }) => ({
  fontSize: '13px',
  color: theme.palette.colors.gray[400],
  fontWeight: '400',
  lineHeight: '16px',
  width: '100%',
  wordWrap: 'break-word',
}));

export const StepsTitle = styled(Typography)(() => ({
  marginBottom: '0.5rem',
  fontSize: '16px',
  color: '#000000',
  fontWeight: '700',
  lineHeight: '19px',
}));

export const Steps = styled('ol')(() => ({
  paddingLeft: '20px',
}));

export const Step = styled('li')(({ theme }) => ({
  marginBottom: '0.5rem',
  fontSize: '16px',
  color: theme.palette.colors.gray[800],
  fontWeight: '400',
  lineHeight: '19px',
}));

export const AlertTitle = styled(Typography)(({ theme }) => ({
  marginBottom: '0.5rem',
  fontSize: '16px',
  color: theme.palette.colors.gray[800],
  fontWeight: '400',
  lineHeight: '19px',
}));

export const Alerts = styled('ul')(() => ({
  paddingLeft: '20px',
}));

export const Alert = styled('li')(({ theme }) => ({
  marginBottom: '0.5rem',
  fontSize: '16px',
  color: theme.palette.colors.gray[800],
  fontWeight: '400',
  lineHeight: '19px',
}));
