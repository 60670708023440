import { ReactNode } from 'react';
import { Stack, Divider } from '@mui/material';

import ValueWithLabel from '@/components/ValueWithLabel';

interface DetailsRowProps {
  title: string;
  value: ReactNode;
  disabledDivider?: boolean;
}

const DetailsRow = (props: DetailsRowProps) => {
  const { title, value, disabledDivider } = props;

  return (
    <Stack gap={1}>
      <ValueWithLabel
        slotProps={{
          container: {
            gap: {
              xs: 4,
              md: 0.5,
            },
            flexDirection: { xs: 'row', md: 'column' },
            flexWrap: 'wrap',
            justifyContent: { xs: 'space-between', md: 'flex-start' },
          },
          title: { variant: 'smallSemiBold' },
          value: { variant: 'smallRegular', marginLeft: { xs: 4, md: 0 }, sx: { wordBreak: 'break-word' } },
        }}
        title={title}
        value={value}
      />

      {disabledDivider ? null : <Divider />}
    </Stack>
  );
};

export default DetailsRow;
