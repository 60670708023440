import { DayCellColor } from '@/features/reservation/components/RescheduleForm/RescheduleDateTimeSelector/CalendarSelector/HorizontalCalendar/DayCell/types';
import { styled, Theme } from '@planne-software/uni/mui/material';

type Token = keyof Theme['tokens']['colors'];

type Color = {
  border: Token;
  background: Token;
  backgroundHover: Token;
  backgroundDisabled: Token;
  text: Token;
  textHover: Token;
  textDisabled: Token;
};

const colors: Record<DayCellColor, Color> = {
  neutral: {
    border: 'border-color-neutral-stronger',
    background: 'fill-color-neutral',
    backgroundHover: 'fill-color-neutral-softer',
    backgroundDisabled: 'fill-color-neutral',
    text: 'text-color-neutral-strongest',
    textHover: 'text-color-neutral-strongest',
    textDisabled: 'text-color-neutral-softer',
  },
  primary: {
    border: 'color-primary-base',
    background: 'fill-color-primary-base',
    backgroundHover: 'fill-color-primary-strong',
    backgroundDisabled: 'fill-color-neutral-softest',
    text: 'text-color-neutral',
    textHover: 'text-color-neutral',
    textDisabled: 'text-color-neutral-base',
  },
  warning: {
    border: 'border-color-warning-strong',
    background: 'fill-color-warning-softest',
    backgroundHover: 'fill-color-warning-softer',
    backgroundDisabled: 'fill-color-neutral',
    text: 'text-color-warning-strongest',
    textHover: 'text-color-warning-strongest',
    textDisabled: 'text-color-neutral-soft',
  },

  success: {
    border: 'border-color-success-base',
    background: 'fill-color-success-softest',
    backgroundHover: 'fill-color-success-softer',
    backgroundDisabled: 'color-success-softest',
    text: 'text-color-success-strongest',
    textHover: 'text-color-success-strongest',
    textDisabled: 'text-color-neutral-base',
  },
  error: {
    border: 'border-color-alert-strong',
    background: 'fill-color-alert-softest',
    backgroundHover: 'fill-color-alert-softer',
    backgroundDisabled: 'fill-color-neutral-softer',
    text: 'text-color-alert-strongest',
    textHover: 'text-color-alert-strong',
    textDisabled: 'text-color-neutral-base',
  },
};

export const StyledDayCell = styled('button')<{ color: DayCellColor }>(
  ({ theme, color }: { theme: Theme; color: DayCellColor }) => ({
    display: 'flex',
    height: '5rem',
    width: '50px',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.tokens.spacing['spacing-1xmini']} ${theme.tokens.spacing['spacing-2xmini']}`,
    gap: theme.tokens.spacing['spacing-2xmini'],
    borderRadius: theme.tokens.radius['border-radius-xs'],
    border: `1px solid ${theme.tokens.colors[colors[color].border]}`,
    backgroundColor: theme.tokens.colors[colors[color].background],
    cursor: 'pointer',
    color: theme.tokens.colors[colors[color].text],
    '&:hover': {
      backgroundColor: theme.tokens.colors[colors[color].backgroundHover],
      color: theme.tokens.colors[colors[color].textHover],
    },
    '&.selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '&:disabled': {
      cursor: 'default',
      color: theme.tokens.colors[colors[color].textDisabled],
      backgroundColor: theme.tokens.colors[colors[color].backgroundDisabled],
      borderColor: theme.tokens.colors[colors[color].backgroundDisabled],
    },
    '&.outsideCurrentMonth': {
      color: theme.palette.text.disabled,
    },
    '&.isFirstVisibleCell': {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
    },
    '&.isLastVisibleCell': {
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
  }),
);

export const StyledWeekday = styled('span')(({ theme }) => ({
  ...theme.typography['text-mini-regular'],
  textTransform: 'capitalize',
}));

export const StyledDay = styled('span')(({ theme }) => ({
  ...theme.typography['text-md-semibold'],
}));

export const StyledDayFooter = styled('span')(({ theme }) => ({
  ...theme.typography['text-mini-regular'],
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));
