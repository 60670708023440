import { Fragment } from 'react';
import { Trash02 } from '@untitled-ui/icons-react';
import { SxProps, Typography } from '@mui/material';

import theme from '@/theme';
import { dateWithTimezone, formatCents } from '@/utils';
import Button from '@/components/Button';
import { Image } from '@/components/Image';
import { ProviderName, TariffWrapper, ActionWrapper, ProviderContent, InvalidProductWrapper } from '../styles';
import usePartnershipPortalCart from '@/hooks/usePartnershipPortalCart';
import { CartItemWithId } from '@/contexts/PartnershipPortalCartContext';
import { SellingMode } from '@/services/SellerApi';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

interface TextProps {
  sx: SxProps;
}

interface Props {
  cartItem: CartItemWithId;
  slotProps?: {
    productName?: TextProps;
    providerName?: TextProps;
    tariffWrapper?: TextProps;
    itemPrice?: TextProps;
    removeButton?: TextProps;
    InvalidProductWrapper?: TextProps;
  };
}

export const CartItemCard = (props: Props) => {
  const { cartItem, slotProps } = props;
  const { arrangedProduct } = cartItem;

  const { t } = useTranslation('productShowCase');

  const { removeItemFromCart, calculateCartItemTotalPrice } = usePartnershipPortalCart();

  const getProductSchedule = (item: CartItemWithId) => {
    const { scheduleDate, scheduleTime, arrangedProduct } = item;

    if (arrangedProduct.product.sellingMode === SellingMode.WithDateAndTime && scheduleDate && scheduleTime) {
      return format(dateWithTimezone(scheduleDate), 'dd/MM/yyyy') + ' - ' + scheduleTime!;
    }

    if (arrangedProduct.product.sellingMode === SellingMode.WithDateOnly && scheduleDate) {
      return format(dateWithTimezone(scheduleDate), 'dd/MM/yyyy');
    }

    return null;
  };

  return (
    <Fragment>
      <Typography variant='smallBold' color={theme.palette.colors.gray[700]} {...slotProps?.productName}>
        {arrangedProduct.product.name}
      </Typography>

      <ProviderContent>
        <Image width={32} src={arrangedProduct.partnershipItem?.partnership?.provider?.logoUrl ?? ''} />

        <ProviderName
          variant='extraSmallSemiBold'
          title={arrangedProduct.partnershipItem?.partnership?.provider?.name}
          color={theme.palette.colors.gray[700]}
          {...slotProps?.providerName}
        >
          {arrangedProduct.partnershipItem?.partnership?.provider?.name}
        </ProviderName>
      </ProviderContent>

      <TariffWrapper {...slotProps?.tariffWrapper}>
        <Typography component='span' fontSize='inherit' color='inherit' fontWeight='inherit'>
          {getProductSchedule(cartItem) ?? t('cart.no_schedule_label')}
        </Typography>

        {cartItem.tariffs.map((itemTariff) => (
          <Typography
            color='inherit'
            component='span'
            fontSize='inherit'
            fontWeight='inherit'
            key={itemTariff.tariff.id}
          >
            {itemTariff.tariff.type!.name}: {itemTariff.quantity}
          </Typography>
        ))}
      </TariffWrapper>

      {!cartItem.isValid ? (
        <InvalidProductWrapper variant='extraSmallRegular' {...slotProps?.InvalidProductWrapper}>
          {t('cart.invalid_product_message')}
        </InvalidProductWrapper>
      ) : null}

      <ActionWrapper>
        <Typography variant='regularBold' color={theme.palette.colors.gray[700]} {...slotProps?.itemPrice}>
          {formatCents(calculateCartItemTotalPrice(cartItem))}
        </Typography>

        <Button
          color='primary'
          variant='outlined'
          onClick={() => removeItemFromCart(cartItem.id)}
          sx={{ p: '6px 4px', ...slotProps?.removeButton }}
        >
          <Trash02 />
        </Button>
      </ActionWrapper>
    </Fragment>
  );
};
export default CartItemCard;
