import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const ProvidedByContainer = styled('div')(() => ({
  position: 'absolute',
  textAlign: 'center',
  bottom: '20px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const ProvidedBy = styled(Typography)(({ theme }) => ({
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '16px',
  letterSpacing: 0,
  color: theme.palette.colors.white[600],
  marginRight: '16px',
}));

export const PlanneLogo = styled('img')(() => ({
  height: '28px',
}));
