import { Coupon, CouponUsageRestrictionsTypeEnum, RecurrenceWeekdayEnum } from '@/services/SellerApi';
import { formatCents } from '@/utils';
import { TFunction } from 'i18next';
import { format } from 'date-fns';

export const getFormattedCouponDiscountValue = (coupon: Coupon) => {
  if (coupon.type === 'nominal') {
    return formatCents(coupon.discountAmountCents ?? 0);
  }

  if (coupon.type === 'percentage') {
    return `${coupon.discountPercentage ?? 0}%`;
  }

  return '-';
};

export const getMaxUseValueAndStatus = (
  coupon: Coupon,
  t: TFunction<'coupons'[], undefined>,
  isArchivedCouponList: boolean = false,
): { value: string; status: 'default' | 'error' | 'info' } => {
  if (!coupon.maxUse) {
    return { value: t('list.table.without_max_use'), status: 'default' };
  }

  if (coupon.maxUse <= (coupon?.usage ?? 0)) {
    return {
      value: `${coupon.usage ?? 0}/${coupon.maxUse}`,
      status: isArchivedCouponList ? 'default' : 'error',
    };
  }

  return {
    value: `${coupon.usage ?? 0}/${coupon.maxUse}`,
    status: isArchivedCouponList ? 'default' : 'info',
  };
};
const formatDateWithTime = (date: Date) => format(date, 'dd/MM/yyyy - HH:mm');

export const getRestrictionsValue = (coupon: Coupon, t: TFunction<'coupons'[], undefined>) => {
  if (!coupon.usageRestrictions?.length) {
    return null;
  }

  return coupon.usageRestrictions.map((restriction) => {
    if (restriction.type === CouponUsageRestrictionsTypeEnum.Range) {
      if (restriction.fromDate && restriction.toDate) {
        return t('list.table.restriction.range_with_two_valid', {
          from: formatDateWithTime(new Date(restriction.fromDate)),
          to: formatDateWithTime(new Date(restriction.toDate)),
        });
      }

      if (restriction.fromDate) {
        return t('list.table.restriction.range_only_with_from', {
          from: formatDateWithTime(new Date(restriction.fromDate)),
        });
      }

      if (restriction.toDate) {
        return t('list.table.restriction.range_only_with_to', {
          to: formatDateWithTime(new Date(restriction.toDate)),
        });
      }
    }

    if (restriction.type === CouponUsageRestrictionsTypeEnum.DateOnly && restriction.date) {
      return t('list.table.restriction.date_only', { date: format(new Date(restriction.date), 'dd/MM/yyyy') });
    }

    if (restriction.type === CouponUsageRestrictionsTypeEnum.WeekDays && restriction.weekdays?.length) {
      return t('list.table.restriction.weekdays', { weekDays: getWeekDaysValue(restriction.weekdays, t) });
    }
  });
};

export const getCouponProductsName = (coupon: Coupon) => {
  if (!coupon?.products?.length) {
    return null;
  }

  return coupon.products.map((product) => product.internalName ?? product.name);
};

export const getWeekDaysValue = (value: RecurrenceWeekdayEnum[] | null, t: TFunction<'coupon'[], undefined>) => {
  if (!value || value?.length === 0) {
    return '';
  }

  const weekend: RecurrenceWeekdayEnum[] = [RecurrenceWeekdayEnum.Saturday, RecurrenceWeekdayEnum.Sunday];

  const weekDays: RecurrenceWeekdayEnum[] = [
    RecurrenceWeekdayEnum.Monday,
    RecurrenceWeekdayEnum.Tuesday,
    RecurrenceWeekdayEnum.Wednesday,
    RecurrenceWeekdayEnum.Thursday,
    RecurrenceWeekdayEnum.Friday,
  ];

  if (value.length === weekDays.length && value.every((day) => weekDays.includes(day))) {
    return t('coupon_form.restrictions_by_date_of_use.week_days.weekdays');
  }

  if (value.length === weekend.length && value.every((day) => weekend.includes(day))) {
    return t('coupon_form.restrictions_by_date_of_use.week_days.weekends');
  }

  return value.map((day) => t(`coupon_form.restrictions_by_date_of_use.week_days.${day}`)).join(', ');
};
