import { Stack, styled, Typography, Link } from '@mui/material';
interface Props {
  active?: boolean;
  inactive?: boolean;
}

const unsafeProps: PropertyKey[] = ['active', 'inactive'];

export const StyledSlotHeader = styled(Typography, {
  shouldForwardProp: (prop) => !unsafeProps.includes(prop),
})<Props>(({ theme, active = false }) => ({
  display: 'flex',
  flexDirection: 'row',
  textTransform: 'capitalize',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'space-between',
  padding: '0.5rem',
  background: theme.palette.grey[50],
  borderRadius: `${theme.radius.md} ${theme.radius.md} 0 0`,
  borderBottom: `1px solid ${theme.palette.divider}`,

  ...(active && {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderBottomColor: theme.palette.primary.main,
    borderRadius: `${theme.radius.sm} ${theme.radius.sm} 0 0`,
  }),

  [theme.breakpoints.down('md')]: {
    borderRadius: '0',
    padding: '0.2rem',
    fontSize: theme.typography.extraSmallBold.fontSize,
  },
}));

export const StyledSlot = styled('div', {
  shouldForwardProp: (prop) => !unsafeProps.includes(prop),
})<Props>(({ theme, inactive = false, active = false }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'flex-start',
  width: '100%',
  height: '100%',

  ...(inactive && {
    opacity: 0.5,
  }),

  ...(active && {
    border: `1px solid ${theme.palette.primary.main}`,
  }),
  [theme.breakpoints.up('md')]: {
    minHeight: '20.5625rem',
    ...(active && {
      borderRadius: theme.radius.md,
    }),
  },
}));

export const StyledSlotTypeBase = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: theme.spacing(1),
}));

export const StyledSlotAvailabilities = styled(StyledSlotTypeBase)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const StyledViewMore = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  marginLeft: 'auto',
  textDecoration: 'underline',
  fontSize: theme.typography.smallBold.fontSize,
  fontWeight: theme.typography.smallBold.fontWeight,
  color: theme.palette.primary.main,
  cursor: 'pointer',
}));

export const StyledCardLink = styled(Link)(() => ({
  display: 'flex',
  textDecoration: 'none',
  flexDirection: 'column',
  color: 'inherit',
}));
