import { ConfigContext } from '@/features/config/context/context';
import { ConfigContextStore } from '@/features/config/types/config';

interface Props<S> {
  config: ConfigContextStore<S>;
  children: React.ReactNode;
}

const ConfigProvider = <S,>({ config, children }: Props<S>) => {
  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};

export default ConfigProvider;
