import { MouseEventHandler, ReactNode } from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, SxProps, Typography } from '@mui/material';
import { NestedList, StyledListItemButton } from './styles';

interface MenuItemProps {
  text: string;
  icon?: ReactNode;
  children: ReactNode[] | ReactNode;
  open: boolean;
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
  onlyShowIcon?: boolean;
  selected?: boolean;
  showExpandIcon?: boolean;
  sx?: SxProps;
}

export default function NestedMenuItem(props: MenuItemProps) {
  const {
    text,
    icon,
    children,
    open,
    onClick,
    onlyShowIcon = false,
    selected = open,
    showExpandIcon = true,
    sx,
  } = props;

  return (
    <>
      <StyledListItemButton onClick={onClick} selected={selected} onlyShowIcon={onlyShowIcon} sx={sx}>
        {icon ? (
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: onlyShowIcon ? 3 : 'auto',
              justifyContent: onlyShowIcon ? 'center' : 'initial',
            }}
          >
            {icon}
          </ListItemIcon>
        ) : null}

        <ListItemText primary={<Typography variant='regularSemiBold'>{text}</Typography>} />

        {showExpandIcon ? <>{open ? <ExpandLess /> : <ExpandMore />}</> : null}
      </StyledListItemButton>

      <Collapse in={open} timeout='auto' unmountOnExit>
        <NestedList disablePadding>{children}</NestedList>
      </Collapse>
    </>
  );
}
