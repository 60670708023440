import { Fragment, useMemo } from 'react';

import SlotTypeAvailability from '@/features/agenda/components/SchedulingCalendar/AgendaSlot/SlotTypeAvailability';
import SlotTypeScheduling from '@/features/agenda/components/SchedulingCalendar/AgendaSlot/SlotTypeScheduling';
import { SlotsPreviewProps } from '@/features/agenda/components/SchedulingCalendar/AgendaSlot/types';
import { AgendaConfigState, ViewDataType } from '@/features/agenda/types';
import { Availability, Scheduling } from '@/services/SellerApi';
import { useConfig } from '@/features/config/useConfig';

const SlotsPreview = (props: SlotsPreviewProps) => {
  const { availabilities, schedulings } = props;
  const { config } = useConfig<AgendaConfigState>();
  const availabilitySize = 30;
  const schedulingsSize = 50;
  const heightWithViewMore = 556;
  const getTotalItems = (size: number) => Math.floor(heightWithViewMore / size);

  const viewData = useMemo(() => config?.viewDataType, [config]);

  const mapItems = useMemo(() => {
    const totalAvailabilities = getTotalItems(availabilitySize);
    const totalSchedulings = getTotalItems(schedulingsSize);
    const isViewingBoth =
      viewData?.includes(ViewDataType.AVAILABILITIES) && viewData?.includes(ViewDataType.SCHEDULING);

    if (isViewingBoth) {
      const slicedAvailabilities = availabilities?.slice(0, totalAvailabilities) || [];
      const slicedSchedulings = schedulings?.slice(0, totalSchedulings) || [];
      return slicedAvailabilities.length >= totalAvailabilities
        ? slicedAvailabilities
        : [...slicedAvailabilities, ...slicedSchedulings];
    }

    if (viewData?.includes(ViewDataType.AVAILABILITIES)) {
      return availabilities?.slice(0, totalAvailabilities) || [];
    }

    if (viewData?.includes(ViewDataType.SCHEDULING)) {
      return schedulings?.slice(0, totalSchedulings) || [];
    }

    return [];
  }, [viewData, availabilities, schedulings]);

  return (
    <Fragment>
      {mapItems.map((item) => {
        if ((item as Availability).type) {
          return <SlotTypeAvailability key={item.id} availability={item as Availability} />;
        }
        return <SlotTypeScheduling key={item.id} scheduling={item as Scheduling} />;
      })}
    </Fragment>
  );
};

export default SlotsPreview;
