import { useTranslation } from 'react-i18next';

import { AvailabilityTypeEnum } from '@/services/SellerApi';
import Chip from '@/components/Chip';

interface AvailabilityModalProps {
  type: AvailabilityTypeEnum;
}

const AvailabilityTypeChip = (props: AvailabilityModalProps) => {
  const { type } = props;

  const { t } = useTranslation(['agenda'], { keyPrefix: 'availability' });

  const getTypeLabel = (type: AvailabilityTypeEnum) => {
    return t(`types.${type}`);
  };

  return <Chip label={getTypeLabel(type)} size='small' />;
};

export default AvailabilityTypeChip;
