import { Divider, Grid, Typography } from '@mui/material';
import { Content } from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  children?: React.ReactNode[];
}

const SelectedItems = (props: Props) => {
  const { children } = props;

  const { t } = useTranslation(['availability']);

  return (
    <Content>
      <Typography variant='regularBold'>{t('form.selected_items')}</Typography>

      <Divider sx={{ mb: '1rem' }} />

      <Grid container spacing={6}>
        {children}
      </Grid>
    </Content>
  );
};

export default SelectedItems;
