import { useTranslation } from 'react-i18next';
import { Hidden, Typography, useMediaQuery } from '@mui/material';

import theme, { colors } from '@/theme';
import { formatCents } from '@/utils';
import Button from '@/components/Button';
import emptyProductImage from '@/assets/Icons/empty-product-image.svg';
import {
  Image,
  CardInfo,
  Container,
  IconWrapper,
  ProductName,
  ProviderName,
  ThumbnailWrapper,
  ProviderContent,
  ProviderImageWrapper,
  PriceContent,
  Price,
} from './styles';

import { ArrangedProduct, PartnershipCommissionType } from '@/services/SellerApi';

type Props = {
  arrangedProduct: ArrangedProduct;
  onAddToCartClick?: () => void;
};

const ArrangedProductCard = (props: Props) => {
  const { arrangedProduct, onAddToCartClick } = props;
  const { product, partnershipItem } = arrangedProduct;
  const { name, thumbnailUrl, defaultTariffGroupPriceInfo } = product;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { t } = useTranslation(['productShowCase']);

  const providerName = partnershipItem?.partnership?.provider?.name ?? '';

  const productImage = (url?: string | null, emptyImageSize = 122) => {
    if (url) {
      return <Image src={url} />;
    }

    return (
      <IconWrapper size={emptyImageSize}>
        <img src={emptyProductImage} />
      </IconWrapper>
    );
  };

  const getProductPrice = () => {
    const defaultPriceCents = defaultTariffGroupPriceInfo!.defaultPriceCents;

    let discount = 0;

    if (arrangedProduct?.partnershipItem?.commissionType === PartnershipCommissionType.AmountCents) {
      discount = arrangedProduct.partnershipItem.commissionAmountCents!;
    }

    if (arrangedProduct?.partnershipItem?.commissionType === PartnershipCommissionType.PercentageFee) {
      discount = defaultPriceCents * arrangedProduct!.partnershipItem!.commissionPercentageFee!;
    }

    return defaultPriceCents - discount;
  };

  return (
    <Container elevation={3} onClick={isMobile ? onAddToCartClick : undefined}>
      <ThumbnailWrapper>{productImage(thumbnailUrl, isMobile ? 60 : 122)}</ThumbnailWrapper>

      <CardInfo>
        <ProductName component='p' variant={'largeBold'} title={name} mb='auto'>
          {name}
        </ProductName>

        <ProviderContent>
          <Hidden smDown>
            <ProviderImageWrapper>
              {productImage(partnershipItem?.partnership?.provider?.logoUrl, 21)}
            </ProviderImageWrapper>
          </Hidden>

          <ProviderName variant='smallBold' title={providerName}>
            {providerName}
          </ProviderName>
        </ProviderContent>

        <PriceContent>
          <Typography variant='extraSmallSemiBold'>{t('list.card.price_prefix')}</Typography>

          <Price variant='h6' color={colors.green[500]}>
            {formatCents(getProductPrice())}
          </Price>
        </PriceContent>

        <Hidden smDown>
          <Button variant='contained' color='primary' fullWidth onClick={onAddToCartClick}>
            {t('list.card.book_now')}
          </Button>
        </Hidden>
      </CardInfo>
    </Container>
  );
};

export default ArrangedProductCard;
