/* tslint:disable */
/* eslint-disable */
/**
 * Planne Seller API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AccessToken
 */
export interface AccessToken {
    /**
     * 
     * @type {string}
     * @memberof AccessToken
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof AccessToken
     */
    'expiresAt': string;
}
/**
 * 
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'appId': string;
    /**
     * 
     * @type {App}
     * @memberof Account
     */
    'app'?: App | null;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'roleId': string;
    /**
     * 
     * @type {Role}
     * @memberof Account
     */
    'role'?: Role | null;
}
/**
 * 
 * @export
 * @interface Additional
 */
export interface Additional {
    /**
     * 
     * @type {string}
     * @memberof Additional
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Additional
     */
    'productId': string;
    /**
     * 
     * @type {Product}
     * @memberof Additional
     */
    'product'?: Product;
    /**
     * 
     * @type {AdditionalType}
     * @memberof Additional
     */
    'type': AdditionalType;
    /**
     * 
     * @type {string}
     * @memberof Additional
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof Additional
     */
    'priceCents': number;
    /**
     * 
     * @type {string}
     * @memberof Additional
     */
    'description': string | null;
    /**
     * 
     * @type {Array<CustomData>}
     * @memberof Additional
     */
    'customData'?: Array<CustomData>;
    /**
     * 
     * @type {number}
     * @memberof Additional
     */
    'maxQuantity': number | null;
    /**
     * 
     * @type {string}
     * @memberof Additional
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Additional
     */
    'deletedAt'?: string | null;
}


/**
 * 
 * @export
 * @interface AdditionalQuantity
 */
export interface AdditionalQuantity {
    /**
     * 
     * @type {string}
     * @memberof AdditionalQuantity
     */
    'additionalId': string;
    /**
     * 
     * @type {OccupationInfo}
     * @memberof AdditionalQuantity
     */
    'occupation': OccupationInfo;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AdditionalType = {
    Person: 'per_person',
    PersonWithInput: 'per_person_with_input',
    Item: 'per_item'
} as const;

export type AdditionalType = typeof AdditionalType[keyof typeof AdditionalType];


/**
 * 
 * @export
 * @interface AggregatedRecurrenceCount
 */
export interface AggregatedRecurrenceCount {
    /**
     * 
     * @type {number}
     * @memberof AggregatedRecurrenceCount
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof AggregatedRecurrenceCount
     */
    'previous': number;
    /**
     * 
     * @type {number}
     * @memberof AggregatedRecurrenceCount
     */
    'next': number;
    /**
     * 
     * @type {number}
     * @memberof AggregatedRecurrenceCount
     */
    'futurePrevious': number;
}
/**
 * 
 * @export
 * @interface AgreementComponent
 */
export interface AgreementComponent {
    /**
     * 
     * @type {Array<AgreementElement>}
     * @memberof AgreementComponent
     */
    'elements': Array<AgreementElement>;
    /**
     * 
     * @type {string}
     * @memberof AgreementComponent
     */
    'namedStyleType': string;
    /**
     * 
     * @type {string}
     * @memberof AgreementComponent
     */
    'listId': string | null;
}
/**
 * 
 * @export
 * @interface AgreementDocument
 */
export interface AgreementDocument {
    /**
     * 
     * @type {AgreementDocumentContent}
     * @memberof AgreementDocument
     */
    'content': AgreementDocumentContent;
}
/**
 * 
 * @export
 * @interface AgreementDocumentContent
 */
export interface AgreementDocumentContent {
    /**
     * 
     * @type {Array<AgreementComponent>}
     * @memberof AgreementDocumentContent
     */
    'components': Array<AgreementComponent>;
}
/**
 * 
 * @export
 * @interface AgreementElement
 */
export interface AgreementElement {
    /**
     * 
     * @type {string}
     * @memberof AgreementElement
     */
    'text': string;
    /**
     * 
     * @type {boolean}
     * @memberof AgreementElement
     */
    'bold': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgreementElement
     */
    'underline': boolean;
    /**
     * 
     * @type {string}
     * @memberof AgreementElement
     */
    'linkUrl': string | null;
}
/**
 * 
 * @export
 * @interface App
 */
export interface App {
    /**
     * 
     * @type {string}
     * @memberof App
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof App
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof App
     */
    'logoUrl': string | null;
    /**
     * 
     * @type {AppAddress}
     * @memberof App
     */
    'address': AppAddress;
    /**
     * 
     * @type {AppContact}
     * @memberof App
     */
    'contact': AppContact;
    /**
     * 
     * @type {string}
     * @memberof App
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof App
     */
    'publicCode': string;
    /**
     * 
     * @type {string}
     * @memberof App
     */
    'publicApiToken': string;
    /**
     * 
     * @type {string}
     * @memberof App
     */
    'createdAt': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof App
     */
    'languages': Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof App
     */
    'planId': string;
    /**
     * 
     * @type {Plan}
     * @memberof App
     */
    'plan'?: Plan | null;
    /**
     * 
     * @type {number}
     * @memberof App
     */
    'minuteSetInterval': number;
}
/**
 * 
 * @export
 * @interface AppAddress
 */
export interface AppAddress {
    /**
     * 
     * @type {string}
     * @memberof AppAddress
     */
    'uf': string | null;
    /**
     * 
     * @type {string}
     * @memberof AppAddress
     */
    'city': string | null;
    /**
     * 
     * @type {string}
     * @memberof AppAddress
     */
    'district': string | null;
    /**
     * 
     * @type {string}
     * @memberof AppAddress
     */
    'street': string | null;
    /**
     * 
     * @type {string}
     * @memberof AppAddress
     */
    'number': string | null;
    /**
     * 
     * @type {string}
     * @memberof AppAddress
     */
    'complement'?: string | null;
}
/**
 * 
 * @export
 * @interface AppContact
 */
export interface AppContact {
    /**
     * 
     * @type {string}
     * @memberof AppContact
     */
    'email': string | null;
    /**
     * 
     * @type {string}
     * @memberof AppContact
     */
    'phone': string | null;
}
/**
 * 
 * @export
 * @interface AppEvent
 */
export interface AppEvent {
    /**
     * 
     * @type {string}
     * @memberof AppEvent
     */
    'id': string;
    /**
     * 
     * @type {AppEventTypeEnum}
     * @memberof AppEvent
     */
    'eventType': AppEventTypeEnum;
    /**
     * 
     * @type {AppEventMetadata}
     * @memberof AppEvent
     */
    'metadata': AppEventMetadata;
    /**
     * 
     * @type {string}
     * @memberof AppEvent
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AppEvent
     */
    'acknowledgedAt': string | null;
}


/**
 * 
 * @export
 * @interface AppEventIncomeSchedulingUpdate
 */
export interface AppEventIncomeSchedulingUpdate {
    /**
     * 
     * @type {string}
     * @memberof AppEventIncomeSchedulingUpdate
     */
    'transactionId': string;
    /**
     * 
     * @type {string}
     * @memberof AppEventIncomeSchedulingUpdate
     */
    'saleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppEventIncomeSchedulingUpdate
     */
    'paymentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppEventIncomeSchedulingUpdate
     */
    'directLinkId'?: string | null;
}
/**
 * @type AppEventMetadata
 * @export
 */
export type AppEventMetadata = AppEventIncomeSchedulingUpdate | AppEventReservationReschedule | AppEventSaleCreate | AppEventSaleItemReschedule | AppEventSaleStateChange | AppEventSaleTagCreate | AppEventSaleTagDelete | AppEventSaleUpdate | AppEventVoucherConsume | AppEventVoucherCreate | AppEventVoucherRevoke;

/**
 * 
 * @export
 * @interface AppEventReservationReschedule
 */
export interface AppEventReservationReschedule {
    /**
     * 
     * @type {string}
     * @memberof AppEventReservationReschedule
     */
    'reservationId': string;
    /**
     * 
     * @type {string}
     * @memberof AppEventReservationReschedule
     */
    'scheduleDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppEventReservationReschedule
     */
    'scheduleTime'?: string;
}
/**
 * 
 * @export
 * @interface AppEventSaleCreate
 */
export interface AppEventSaleCreate {
    /**
     * 
     * @type {string}
     * @memberof AppEventSaleCreate
     */
    'saleId': string;
}
/**
 * 
 * @export
 * @interface AppEventSaleItemReschedule
 */
export interface AppEventSaleItemReschedule {
    /**
     * 
     * @type {string}
     * @memberof AppEventSaleItemReschedule
     */
    'saleItemId': string;
    /**
     * 
     * @type {string}
     * @memberof AppEventSaleItemReschedule
     */
    'saleId': string;
    /**
     * 
     * @type {string}
     * @memberof AppEventSaleItemReschedule
     */
    'scheduleDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppEventSaleItemReschedule
     */
    'scheduleTime'?: string;
}
/**
 * 
 * @export
 * @interface AppEventSaleStateChange
 */
export interface AppEventSaleStateChange {
    /**
     * 
     * @type {string}
     * @memberof AppEventSaleStateChange
     */
    'saleId': string;
    /**
     * 
     * @type {SaleStateEnum}
     * @memberof AppEventSaleStateChange
     */
    'stateFrom': SaleStateEnum | null;
    /**
     * 
     * @type {SaleStateEnum}
     * @memberof AppEventSaleStateChange
     */
    'stateTo': SaleStateEnum;
}


/**
 * 
 * @export
 * @interface AppEventSaleTagCreate
 */
export interface AppEventSaleTagCreate {
    /**
     * 
     * @type {string}
     * @memberof AppEventSaleTagCreate
     */
    'saleTagId': string;
    /**
     * 
     * @type {string}
     * @memberof AppEventSaleTagCreate
     */
    'saleId': string;
    /**
     * 
     * @type {string}
     * @memberof AppEventSaleTagCreate
     */
    'tagId': string;
}
/**
 * 
 * @export
 * @interface AppEventSaleTagDelete
 */
export interface AppEventSaleTagDelete {
    /**
     * 
     * @type {string}
     * @memberof AppEventSaleTagDelete
     */
    'saleTagId': string;
    /**
     * 
     * @type {string}
     * @memberof AppEventSaleTagDelete
     */
    'saleId': string;
    /**
     * 
     * @type {string}
     * @memberof AppEventSaleTagDelete
     */
    'tagId': string;
}
/**
 * 
 * @export
 * @interface AppEventSaleUpdate
 */
export interface AppEventSaleUpdate {
    /**
     * 
     * @type {string}
     * @memberof AppEventSaleUpdate
     */
    'saleId': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AppEventTypeEnum = {
    SaleStateChange: 'SALE_STATE_CHANGE',
    IncomeSchedulingsUpdate: 'INCOME_SCHEDULINGS_UPDATE',
    SaleCreate: 'sale.create',
    SaleUpdate: 'sale.update',
    SaleItemReschedule: 'saleItem.reschedule',
    ReservationReschedule: 'reservation.reschedule',
    VoucherCreate: 'voucher.create',
    VoucherConsume: 'voucher.consume',
    VoucherRevoke: 'voucher.revoke',
    SaleTagCreate: 'saleTag.create',
    SaleTagDelete: 'saleTag.delete'
} as const;

export type AppEventTypeEnum = typeof AppEventTypeEnum[keyof typeof AppEventTypeEnum];


/**
 * 
 * @export
 * @interface AppEventVoucherConsume
 */
export interface AppEventVoucherConsume {
    /**
     * 
     * @type {string}
     * @memberof AppEventVoucherConsume
     */
    'voucherId': string;
    /**
     * 
     * @type {boolean}
     * @memberof AppEventVoucherConsume
     */
    'exceptionalConsumption': boolean;
}
/**
 * 
 * @export
 * @interface AppEventVoucherCreate
 */
export interface AppEventVoucherCreate {
    /**
     * 
     * @type {string}
     * @memberof AppEventVoucherCreate
     */
    'voucherId': string;
}
/**
 * 
 * @export
 * @interface AppEventVoucherRevoke
 */
export interface AppEventVoucherRevoke {
    /**
     * 
     * @type {string}
     * @memberof AppEventVoucherRevoke
     */
    'voucherId': string;
}
/**
 * 
 * @export
 * @interface AppInfo
 */
export interface AppInfo {
    /**
     * 
     * @type {string}
     * @memberof AppInfo
     */
    'identifier': string;
    /**
     * 
     * @type {IdentifierType}
     * @memberof AppInfo
     */
    'identifierType': IdentifierType;
    /**
     * 
     * @type {string}
     * @memberof AppInfo
     */
    'fantasyName': string;
    /**
     * 
     * @type {string}
     * @memberof AppInfo
     */
    'corporateName': string | null;
    /**
     * 
     * @type {string}
     * @memberof AppInfo
     */
    'merchantCategoryCode': string | null;
    /**
     * 
     * @type {string}
     * @memberof AppInfo
     */
    'establishmentType': string | null;
    /**
     * 
     * @type {string}
     * @memberof AppInfo
     */
    'establishmentTypeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppInfo
     */
    'openedAt': string | null;
    /**
     * 
     * @type {AppInfoContact}
     * @memberof AppInfo
     */
    'contact': AppInfoContact;
    /**
     * 
     * @type {AppInfoBank}
     * @memberof AppInfo
     */
    'bank': AppInfoBank | null;
    /**
     * 
     * @type {AppInfoOwner}
     * @memberof AppInfo
     */
    'owner': AppInfoOwner | null;
    /**
     * 
     * @type {AppInfoAddress}
     * @memberof AppInfo
     */
    'address': AppInfoAddress | null;
    /**
     * 
     * @type {AppInfoStatus}
     * @memberof AppInfo
     */
    'infoStatus': AppInfoStatus;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppInfo
     */
    'notificationEmails': Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AppInfo
     */
    'supportEmail': string | null;
    /**
     * 
     * @type {string}
     * @memberof AppInfo
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AppInfo
     */
    'updatedAt': string;
}


/**
 * 
 * @export
 * @interface AppInfoAddress
 */
export interface AppInfoAddress {
    /**
     * 
     * @type {string}
     * @memberof AppInfoAddress
     */
    'zipCode': string | null;
    /**
     * 
     * @type {string}
     * @memberof AppInfoAddress
     */
    'uf': string | null;
    /**
     * 
     * @type {string}
     * @memberof AppInfoAddress
     */
    'city': string | null;
    /**
     * 
     * @type {string}
     * @memberof AppInfoAddress
     */
    'district': string | null;
    /**
     * 
     * @type {string}
     * @memberof AppInfoAddress
     */
    'street': string | null;
    /**
     * 
     * @type {string}
     * @memberof AppInfoAddress
     */
    'number': string | null;
    /**
     * 
     * @type {string}
     * @memberof AppInfoAddress
     */
    'complement'?: string | null;
}
/**
 * 
 * @export
 * @interface AppInfoBank
 */
export interface AppInfoBank {
    /**
     * 
     * @type {string}
     * @memberof AppInfoBank
     */
    'code': string | null;
    /**
     * 
     * @type {string}
     * @memberof AppInfoBank
     */
    'agency': string | null;
    /**
     * 
     * @type {string}
     * @memberof AppInfoBank
     */
    'account': string | null;
    /**
     * 
     * @type {string}
     * @memberof AppInfoBank
     */
    'identifier': string | null;
    /**
     * 
     * @type {IdentifierType}
     * @memberof AppInfoBank
     */
    'identifierType': IdentifierType | null;
    /**
     * 
     * @type {BankAccountType}
     * @memberof AppInfoBank
     */
    'accountType'?: BankAccountType | null;
}


/**
 * 
 * @export
 * @interface AppInfoContact
 */
export interface AppInfoContact {
    /**
     * 
     * @type {string}
     * @memberof AppInfoContact
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppInfoContact
     */
    'phone': string;
}
/**
 * 
 * @export
 * @interface AppInfoOwner
 */
export interface AppInfoOwner {
    /**
     * 
     * @type {string}
     * @memberof AppInfoOwner
     */
    'name': string | null;
    /**
     * 
     * @type {string}
     * @memberof AppInfoOwner
     */
    'birthdate': string | null;
    /**
     * 
     * @type {string}
     * @memberof AppInfoOwner
     */
    'cpf': string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AppInfoStatus = {
    Pending: 'pending',
    Approved: 'approved',
    Processing: 'processing',
    Analysing: 'analysing'
} as const;

export type AppInfoStatus = typeof AppInfoStatus[keyof typeof AppInfoStatus];


/**
 * 
 * @export
 * @interface ArrangedProduct
 */
export interface ArrangedProduct {
    /**
     * 
     * @type {string}
     * @memberof ArrangedProduct
     */
    'productId': string;
    /**
     * 
     * @type {PublicProduct}
     * @memberof ArrangedProduct
     */
    'product': PublicProduct;
    /**
     * 
     * @type {string}
     * @memberof ArrangedProduct
     */
    'partnershipItemId'?: string;
    /**
     * 
     * @type {PublicPartnershipItem}
     * @memberof ArrangedProduct
     */
    'partnershipItem'?: PublicPartnershipItem;
}
/**
 * 
 * @export
 * @interface ArrangedSale
 */
export interface ArrangedSale {
    /**
     * 
     * @type {string}
     * @memberof ArrangedSale
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ArrangedSale
     */
    'position': number;
    /**
     * 
     * @type {string}
     * @memberof ArrangedSale
     */
    'code': string | null;
    /**
     * 
     * @type {SaleStateEnum}
     * @memberof ArrangedSale
     */
    'currentState': SaleStateEnum;
    /**
     * 
     * @type {string}
     * @memberof ArrangedSale
     */
    'createdAt': string;
}


/**
 * 
 * @export
 * @interface ArrangedSaleItem
 */
export interface ArrangedSaleItem {
    /**
     * 
     * @type {string}
     * @memberof ArrangedSaleItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ArrangedSaleItem
     */
    'productId': string;
    /**
     * 
     * @type {PublicProduct}
     * @memberof ArrangedSaleItem
     */
    'product'?: PublicProduct;
    /**
     * 
     * @type {string}
     * @memberof ArrangedSaleItem
     */
    'saleId': string;
    /**
     * 
     * @type {ArrangedSale}
     * @memberof ArrangedSaleItem
     */
    'sale'?: ArrangedSale;
    /**
     * 
     * @type {string}
     * @memberof ArrangedSaleItem
     */
    'partnershipItemId'?: string;
    /**
     * 
     * @type {PublicPartnershipItem}
     * @memberof ArrangedSaleItem
     */
    'partnershipItem'?: PublicPartnershipItem;
    /**
     * 
     * @type {string}
     * @memberof ArrangedSaleItem
     */
    'scheduleDate'?: string | null;
    /**
     * 
     * @type {ArrangedSaleItemDecisiveTransactionValues}
     * @memberof ArrangedSaleItem
     */
    'decisiveTransactionValues'?: ArrangedSaleItemDecisiveTransactionValues | null;
}
/**
 * 
 * @export
 * @interface ArrangedSaleItemAggregatedInfo
 */
export interface ArrangedSaleItemAggregatedInfo {
    /**
     * 
     * @type {number}
     * @memberof ArrangedSaleItemAggregatedInfo
     */
    'totalSaleItems': number;
    /**
     * 
     * @type {number}
     * @memberof ArrangedSaleItemAggregatedInfo
     */
    'totalAmountCents': number;
    /**
     * 
     * @type {number}
     * @memberof ArrangedSaleItemAggregatedInfo
     */
    'commissionedAmountCents': number;
}
/**
 * 
 * @export
 * @interface ArrangedSaleItemDecisiveTransactionValues
 */
export interface ArrangedSaleItemDecisiveTransactionValues {
    /**
     * 
     * @type {number}
     * @memberof ArrangedSaleItemDecisiveTransactionValues
     */
    'totalAmountCents': number;
    /**
     * 
     * @type {number}
     * @memberof ArrangedSaleItemDecisiveTransactionValues
     */
    'commissionedAmountCents': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AssociationModel = {
    Affiliation: 'affiliation',
    DirectPurchase: 'direct_purchase',
    Resale: 'resale'
} as const;

export type AssociationModel = typeof AssociationModel[keyof typeof AssociationModel];


/**
 * 
 * @export
 * @interface AuthRecoverPasswordRequest
 */
export interface AuthRecoverPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthRecoverPasswordRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AuthRecoverPasswordRequest
     */
    'newPassword': string;
}
/**
 * 
 * @export
 * @interface AuthResetPasswordRequest
 */
export interface AuthResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthResetPasswordRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface Availability
 */
export interface Availability {
    /**
     * 
     * @type {string}
     * @memberof Availability
     */
    'id': string;
    /**
     * 
     * @type {AvailabilityTypeEnum}
     * @memberof Availability
     */
    'type': AvailabilityTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Availability
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof Availability
     */
    'beginDate': string;
    /**
     * 
     * @type {Recurrence}
     * @memberof Availability
     */
    'recurrence'?: Recurrence | null;
    /**
     * 
     * @type {AggregatedRecurrenceCount}
     * @memberof Availability
     */
    'aggregatedRecurrenceCount'?: AggregatedRecurrenceCount;
    /**
     * 
     * @type {Array<Product>}
     * @memberof Availability
     */
    'products'?: Array<Product> | null;
    /**
     * 
     * @type {Array<Tariff>}
     * @memberof Availability
     */
    'tariffs'?: Array<Tariff> | null;
    /**
     * 
     * @type {Array<Additional>}
     * @memberof Availability
     */
    'additionals'?: Array<Additional> | null;
    /**
     * 
     * @type {Array<ProductTariffType>}
     * @memberof Availability
     */
    'productTariffTypes'?: Array<ProductTariffType> | null;
    /**
     * 
     * @type {AvailabilityOccupationInfo}
     * @memberof Availability
     */
    'occupation': AvailabilityOccupationInfo | null;
    /**
     * 
     * @type {string}
     * @memberof Availability
     */
    'appliesAt': string;
    /**
     * 
     * @type {string}
     * @memberof Availability
     */
    'fromTime': string;
    /**
     * 
     * @type {string}
     * @memberof Availability
     */
    'toTime': string;
    /**
     * 
     * @type {string}
     * @memberof Availability
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Availability
     */
    'updatedAt': string;
}


/**
 * 
 * @export
 * @interface AvailabilityDatePreview
 */
export interface AvailabilityDatePreview {
    /**
     * 
     * @type {string}
     * @memberof AvailabilityDatePreview
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof AvailabilityDatePreview
     */
    'total': number;
    /**
     * 
     * @type {Array<Availability>}
     * @memberof AvailabilityDatePreview
     */
    'availabilities': Array<Availability>;
}
/**
 * 
 * @export
 * @interface AvailabilityOccupationInfo
 */
export interface AvailabilityOccupationInfo {
    /**
     * 
     * @type {number}
     * @memberof AvailabilityOccupationInfo
     */
    'occupationRate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AvailabilityOccupationInfo
     */
    'occupiedAmount': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AvailabilityTypeEnum = {
    App: 'per_app',
    Products: 'per_products',
    Tariffs: 'per_tariffs',
    ProductTariffTypes: 'per_product_tariff_types',
    Additionals: 'per_additionals'
} as const;

export type AvailabilityTypeEnum = typeof AvailabilityTypeEnum[keyof typeof AvailabilityTypeEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const BankAccountType = {
    ContaCorrente: 'conta_corrente',
    Poupanca: 'poupanca'
} as const;

export type BankAccountType = typeof BankAccountType[keyof typeof BankAccountType];


/**
 * 
 * @export
 * @interface BankOption
 */
export interface BankOption {
    /**
     * 
     * @type {string}
     * @memberof BankOption
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof BankOption
     */
    'shortName': string;
    /**
     * 
     * @type {string}
     * @memberof BankOption
     */
    'longName': string;
}
/**
 * 
 * @export
 * @interface BasicCouponProduct
 */
export interface BasicCouponProduct {
    /**
     * 
     * @type {string}
     * @memberof BasicCouponProduct
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BasicCouponProduct
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BasicCouponProduct
     */
    'internalName': string | null;
}
/**
 * 
 * @export
 * @interface BasicSaleCouponProduct
 */
export interface BasicSaleCouponProduct {
    /**
     * 
     * @type {Product}
     * @memberof BasicSaleCouponProduct
     */
    'product'?: Product;
    /**
     * 
     * @type {number}
     * @memberof BasicSaleCouponProduct
     */
    'nominalDiscountCents': number;
    /**
     * 
     * @type {number}
     * @memberof BasicSaleCouponProduct
     */
    'percentageDiscount': number | null;
}
/**
 * 
 * @export
 * @interface Cart
 */
export interface Cart {
    /**
     * 
     * @type {string}
     * @memberof Cart
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Cart
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Cart
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface CategorizedResource
 */
export interface CategorizedResource {
    /**
     * 
     * @type {string}
     * @memberof CategorizedResource
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CategorizedResource
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CategorizedResource
     */
    'code': string | null;
    /**
     * 
     * @type {number}
     * @memberof CategorizedResource
     */
    'capacity': number;
    /**
     * 
     * @type {ResourceCategory}
     * @memberof CategorizedResource
     */
    'category'?: ResourceCategory | null;
    /**
     * 
     * @type {boolean}
     * @memberof CategorizedResource
     */
    'available': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ChargeTypeEnum = {
    FixedValue: 'fixed_value',
    TariffPercent: 'tariff_percent'
} as const;

export type ChargeTypeEnum = typeof ChargeTypeEnum[keyof typeof ChargeTypeEnum];


/**
 * 
 * @export
 * @interface ConsumeQrCodeVoucherParams
 */
export interface ConsumeQrCodeVoucherParams {
    /**
     * 
     * @type {string}
     * @memberof ConsumeQrCodeVoucherParams
     */
    'content': string;
}
/**
 * 
 * @export
 * @interface ConsumeVoucherParams
 */
export interface ConsumeVoucherParams {
    /**
     * 
     * @type {string}
     * @memberof ConsumeVoucherParams
     */
    'secret'?: string;
}
/**
 * 
 * @export
 * @interface Coupon
 */
export interface Coupon {
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    'code': string;
    /**
     * 
     * @type {CouponUsageType}
     * @memberof Coupon
     */
    'usageType': CouponUsageType;
    /**
     * 
     * @type {CouponType}
     * @memberof Coupon
     */
    'type': CouponType;
    /**
     * 
     * @type {number}
     * @memberof Coupon
     */
    'discountAmountCents'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Coupon
     */
    'discountPercentage'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Coupon
     */
    'maxUse'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    'expiresAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Coupon
     */
    'maxUsePerUser'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Coupon
     */
    'usage'?: number;
    /**
     * 
     * @type {Array<BasicCouponProduct>}
     * @memberof Coupon
     */
    'products'?: Array<BasicCouponProduct> | null;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    'partnershipId'?: string | null;
    /**
     * 
     * @type {Partnership}
     * @memberof Coupon
     */
    'partnership'?: Partnership;
    /**
     * 
     * @type {boolean}
     * @memberof Coupon
     */
    'isPartnershipDefault'?: boolean | null;
    /**
     * 
     * @type {CouponConstraintType}
     * @memberof Coupon
     */
    'constraintType'?: CouponConstraintType | null;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    'availableAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Coupon
     */
    'minAmountCents'?: number | null;
    /**
     * 
     * @type {Array<CouponUsageRestrictions>}
     * @memberof Coupon
     */
    'usageRestrictions'?: Array<CouponUsageRestrictions> | null;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    'deletedAt'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CouponConstraintType = {
    Include: 'include',
    Exclude: 'exclude'
} as const;

export type CouponConstraintType = typeof CouponConstraintType[keyof typeof CouponConstraintType];


/**
 * 
 * @export
 * @interface CouponProduct
 */
export interface CouponProduct {
    /**
     * 
     * @type {string}
     * @memberof CouponProduct
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CouponProduct
     */
    'couponId': string;
    /**
     * 
     * @type {string}
     * @memberof CouponProduct
     */
    'productId': string;
    /**
     * 
     * @type {Product}
     * @memberof CouponProduct
     */
    'product'?: Product;
    /**
     * 
     * @type {string}
     * @memberof CouponProduct
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CouponProduct
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CouponType = {
    Nominal: 'nominal',
    Percentage: 'percentage'
} as const;

export type CouponType = typeof CouponType[keyof typeof CouponType];


/**
 * 
 * @export
 * @interface CouponUsageRestrictions
 */
export interface CouponUsageRestrictions {
    /**
     * 
     * @type {string}
     * @memberof CouponUsageRestrictions
     */
    'type': CouponUsageRestrictionsTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CouponUsageRestrictions
     */
    'date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CouponUsageRestrictions
     */
    'fromDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CouponUsageRestrictions
     */
    'toDate'?: string | null;
    /**
     * 
     * @type {Array<RecurrenceWeekdayEnum>}
     * @memberof CouponUsageRestrictions
     */
    'weekdays'?: Array<RecurrenceWeekdayEnum> | null;
}

export const CouponUsageRestrictionsTypeEnum = {
    Range: 'range',
    DateOnly: 'date_only',
    WeekDays: 'week_days'
} as const;

export type CouponUsageRestrictionsTypeEnum = typeof CouponUsageRestrictionsTypeEnum[keyof typeof CouponUsageRestrictionsTypeEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const CouponUsageType = {
    General: 'general',
    Product: 'product'
} as const;

export type CouponUsageType = typeof CouponUsageType[keyof typeof CouponUsageType];


/**
 * 
 * @export
 * @interface CouponValidationResult
 */
export interface CouponValidationResult {
    /**
     * 
     * @type {boolean}
     * @memberof CouponValidationResult
     */
    'valid': boolean;
    /**
     * 
     * @type {Coupon}
     * @memberof CouponValidationResult
     */
    'coupon': Coupon;
}
/**
 * 
 * @export
 * @interface CreateAppParams
 */
export interface CreateAppParams {
    /**
     * 
     * @type {string}
     * @memberof CreateAppParams
     */
    'identifier': string;
    /**
     * 
     * @type {IdentifierType}
     * @memberof CreateAppParams
     */
    'identifierType': IdentifierType;
    /**
     * 
     * @type {string}
     * @memberof CreateAppParams
     */
    'fantasyName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppParams
     */
    'corporateName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppParams
     */
    'openedAt'?: string | null;
    /**
     * 
     * @type {AppInfoContact}
     * @memberof CreateAppParams
     */
    'contact': AppInfoContact;
    /**
     * 
     * @type {AppInfoBank}
     * @memberof CreateAppParams
     */
    'bank'?: AppInfoBank | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAppParams
     */
    'merchantCategoryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAppParams
     */
    'establishmentType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAppParams
     */
    'establishmentTypeId'?: string | null;
    /**
     * 
     * @type {AppInfoOwner}
     * @memberof CreateAppParams
     */
    'owner'?: AppInfoOwner;
    /**
     * 
     * @type {AppInfoAddress}
     * @memberof CreateAppParams
     */
    'address'?: AppInfoAddress | null;
    /**
     * 
     * @type {number}
     * @memberof CreateAppParams
     */
    'expectedMonthlyRevenueCents'?: number | null;
}


/**
 * 
 * @export
 * @interface CreateAppTagParams
 */
export interface CreateAppTagParams {
    /**
     * 
     * @type {string}
     * @memberof CreateAppTagParams
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateArrangedPartnershipParams
 */
export interface CreateArrangedPartnershipParams {
    /**
     * 
     * @type {string}
     * @memberof CreateArrangedPartnershipParams
     */
    'proposalId': string;
}
/**
 * 
 * @export
 * @interface CreateAvailabilityBatchParams
 */
export interface CreateAvailabilityBatchParams {
    /**
     * 
     * @type {Array<CreateAvailabilityParams>}
     * @memberof CreateAvailabilityBatchParams
     */
    'availabilities': Array<CreateAvailabilityParams>;
}
/**
 * 
 * @export
 * @interface CreateAvailabilityParams
 */
export interface CreateAvailabilityParams {
    /**
     * 
     * @type {AvailabilityTypeEnum}
     * @memberof CreateAvailabilityParams
     */
    'type': AvailabilityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAvailabilityParams
     */
    'beginDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAvailabilityParams
     * @deprecated
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAvailabilityParams
     */
    'fromTime': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAvailabilityParams
     */
    'toTime': string;
    /**
     * 
     * @type {number}
     * @memberof CreateAvailabilityParams
     */
    'quantity': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAvailabilityParams
     */
    'productIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAvailabilityParams
     */
    'tariffIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAvailabilityParams
     */
    'additionalIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<ProductTariffType>}
     * @memberof CreateAvailabilityParams
     */
    'productTariffTypes'?: Array<ProductTariffType> | null;
    /**
     * 
     * @type {Recurrence}
     * @memberof CreateAvailabilityParams
     */
    'recurrence'?: Recurrence | null;
}


/**
 * 
 * @export
 * @interface CreateCouponParams
 */
export interface CreateCouponParams {
    /**
     * 
     * @type {string}
     * @memberof CreateCouponParams
     */
    'code': string;
    /**
     * 
     * @type {CouponType}
     * @memberof CreateCouponParams
     */
    'type': CouponType;
    /**
     * 
     * @type {CouponUsageType}
     * @memberof CreateCouponParams
     */
    'usageType': CouponUsageType;
    /**
     * 
     * @type {number}
     * @memberof CreateCouponParams
     */
    'discountAmountCents'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateCouponParams
     */
    'discountPercentage'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateCouponParams
     */
    'maxUse'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCouponParams
     */
    'expiresAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateCouponParams
     */
    'maxUsePerUser'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCouponParams
     */
    'productIds'?: Array<string> | null;
    /**
     * 
     * @type {CouponConstraintType}
     * @memberof CreateCouponParams
     */
    'constraintType'?: CouponConstraintType | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCouponParams
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCouponParams
     */
    'availableAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateCouponParams
     */
    'minAmountCents'?: number | null;
    /**
     * 
     * @type {Array<CouponUsageRestrictions>}
     * @memberof CreateCouponParams
     */
    'usageRestrictions'?: Array<CouponUsageRestrictions> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCouponParams
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCouponParams
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCouponParams
     */
    'deletedAt'?: string | null;
}


/**
 * 
 * @export
 * @interface CreateSaleTagParams
 */
export interface CreateSaleTagParams {
    /**
     * 
     * @type {string}
     * @memberof CreateSaleTagParams
     */
    'tagId': string;
}
/**
 * 
 * @export
 * @interface CreateSchedulingParams
 */
export interface CreateSchedulingParams {
    /**
     * 
     * @type {string}
     * @memberof CreateSchedulingParams
     */
    'tariffGroupId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateSchedulingParams
     */
    'priceModifierId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateSchedulingParams
     */
    'beginDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSchedulingParams
     */
    'consumableFromTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateSchedulingParams
     */
    'consumableToTime'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateSchedulingParams
     */
    'calendarIntervalMinutes'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateSchedulingParams
     * @deprecated
     */
    'antecedenceHours'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateSchedulingParams
     */
    'antecedenceMinutes'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateSchedulingParams
     */
    'antecedenceHoursReferenceTime'?: string | null;
    /**
     * 
     * @type {Recurrence}
     * @memberof CreateSchedulingParams
     */
    'recurrence'?: Recurrence | null;
    /**
     * 
     * @type {number}
     * @memberof CreateSchedulingParams
     */
    'durationMinutes': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSchedulingParams
     */
    'overrideConflicts'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateWebhookParams
 */
export interface CreateWebhookParams {
    /**
     * 
     * @type {string}
     * @memberof CreateWebhookParams
     */
    'url': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateWebhookParams
     */
    'headers'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {Array<AppEventTypeEnum>}
     * @memberof CreateWebhookParams
     */
    'eventTypes': Array<AppEventTypeEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateWebhookParams
     */
    'enabled': boolean;
}
/**
 * 
 * @export
 * @interface CustomData
 */
export interface CustomData {
    /**
     * 
     * @type {string}
     * @memberof CustomData
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof CustomData
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'lastName': string;
    /**
     * 
     * @type {boolean}
     * @memberof Customer
     */
    'hasAttachedBuyer': boolean;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'email': string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'phone': string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'identity': string | null;
    /**
     * 
     * @type {IdentityTypeEnum}
     * @memberof Customer
     */
    'identityType': IdentityTypeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'corporateName': string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'countryCode': string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'birthdate': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Customer
     */
    'isExternal': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Customer
     */
    'agreedToTerms': boolean;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'createdAt': string;
}


/**
 * 
 * @export
 * @interface CustomerAuthentication
 */
export interface CustomerAuthentication {
    /**
     * 
     * @type {string}
     * @memberof CustomerAuthentication
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface CustomerParams
 */
export interface CustomerParams {
    /**
     * 
     * @type {string}
     * @memberof CustomerParams
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerParams
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerParams
     */
    'identity'?: string | null;
    /**
     * 
     * @type {IdentityTypeEnum}
     * @memberof CustomerParams
     */
    'identityType'?: IdentityTypeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerParams
     */
    'corporateName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerParams
     */
    'countryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerParams
     */
    'birthdate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerParams
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerParams
     */
    'phone'?: string | null;
}


/**
 * 
 * @export
 * @interface DateAndTimeAdditionalScheduleQuantity
 */
export interface DateAndTimeAdditionalScheduleQuantity {
    /**
     * 
     * @type {string}
     * @memberof DateAndTimeAdditionalScheduleQuantity
     */
    'additionalId': string;
    /**
     * 
     * @type {Array<DateAndTimeScheduleQuantity>}
     * @memberof DateAndTimeAdditionalScheduleQuantity
     */
    'occupationSchedule': Array<DateAndTimeScheduleQuantity>;
}
/**
 * 
 * @export
 * @interface DateAndTimeScheduleQuantity
 */
export interface DateAndTimeScheduleQuantity {
    /**
     * 
     * @type {string}
     * @memberof DateAndTimeScheduleQuantity
     */
    'time': string | null;
    /**
     * 
     * @type {number}
     * @memberof DateAndTimeScheduleQuantity
     */
    'minutes': number | null;
    /**
     * 
     * @type {OccupationInfo}
     * @memberof DateAndTimeScheduleQuantity
     */
    'occupation': OccupationInfo;
}
/**
 * 
 * @export
 * @interface DateAndTimeTariffScheduleQuantity
 */
export interface DateAndTimeTariffScheduleQuantity {
    /**
     * 
     * @type {string}
     * @memberof DateAndTimeTariffScheduleQuantity
     */
    'tariffId': string;
    /**
     * 
     * @type {Array<DateAndTimeScheduleQuantity>}
     * @memberof DateAndTimeTariffScheduleQuantity
     */
    'occupationSchedule': Array<DateAndTimeScheduleQuantity>;
}
/**
 * 
 * @export
 * @interface DecisiveTransaction
 */
export interface DecisiveTransaction {
    /**
     * 
     * @type {string}
     * @memberof DecisiveTransaction
     */
    'id': string;
    /**
     * 
     * @type {TransactionNormalizedStatus}
     * @memberof DecisiveTransaction
     */
    'normalizedStatus': TransactionNormalizedStatus;
    /**
     * 
     * @type {number}
     * @memberof DecisiveTransaction
     */
    'installments': number;
    /**
     * 
     * @type {string}
     * @memberof DecisiveTransaction
     */
    'paymentMethodId': string;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof DecisiveTransaction
     */
    'paymentMethod'?: PaymentMethod;
    /**
     * 
     * @type {string}
     * @memberof DecisiveTransaction
     */
    'brand'?: string | null;
    /**
     * 
     * @type {TransactableTypeEnum}
     * @memberof DecisiveTransaction
     */
    'transactableType': TransactableTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DecisiveTransaction
     */
    'transactableId': string;
    /**
     * 
     * @type {number}
     * @memberof DecisiveTransaction
     */
    'paymentMethodDiscountPercentage'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DecisiveTransaction
     */
    'paymentMethodInterestRatePercentage': number;
    /**
     * 
     * @type {number}
     * @memberof DecisiveTransaction
     */
    'paymentMethodInterestRateCents': number;
    /**
     * 
     * @type {string}
     * @memberof DecisiveTransaction
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof DecisiveTransaction
     */
    'updatedAt': string;
}


/**
 * 
 * @export
 * @interface DefaultTariff
 */
export interface DefaultTariff {
    /**
     * 
     * @type {string}
     * @memberof DefaultTariff
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DefaultTariff
     */
    'typeId': string;
    /**
     * 
     * @type {TariffType}
     * @memberof DefaultTariff
     */
    'type'?: TariffType;
    /**
     * 
     * @type {number}
     * @memberof DefaultTariff
     */
    'priceCents': number;
    /**
     * 
     * @type {number}
     * @memberof DefaultTariff
     */
    'oldPriceCents'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DefaultTariff
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DefaultTariff
     */
    'isDependent': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DefaultTariff
     */
    'isDefault': boolean;
}
/**
 * 
 * @export
 * @interface DeleteAvailabilityParams
 */
export interface DeleteAvailabilityParams {
    /**
     * 
     * @type {UpdateRuleEnum}
     * @memberof DeleteAvailabilityParams
     */
    'rule': UpdateRuleEnum;
    /**
     * 
     * @type {string}
     * @memberof DeleteAvailabilityParams
     */
    'ruleUntilDate'?: string | null;
}


/**
 * 
 * @export
 * @interface DeleteSchedulingParams
 */
export interface DeleteSchedulingParams {
    /**
     * 
     * @type {UpdateRuleEnum}
     * @memberof DeleteSchedulingParams
     */
    'rule': UpdateRuleEnum;
    /**
     * 
     * @type {string}
     * @memberof DeleteSchedulingParams
     */
    'ruleUntilDate'?: string | null;
}


/**
 * 
 * @export
 * @interface DetailedDirectSaleItem
 */
export interface DetailedDirectSaleItem {
    /**
     * 
     * @type {string}
     * @memberof DetailedDirectSaleItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DetailedDirectSaleItem
     */
    'productId': string;
    /**
     * 
     * @type {PublicProduct}
     * @memberof DetailedDirectSaleItem
     */
    'product'?: PublicProduct;
    /**
     * 
     * @type {string}
     * @memberof DetailedDirectSaleItem
     */
    'saleId': string;
    /**
     * 
     * @type {DirectSale}
     * @memberof DetailedDirectSaleItem
     */
    'sale'?: DirectSale;
    /**
     * 
     * @type {string}
     * @memberof DetailedDirectSaleItem
     */
    'scheduleDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DetailedDirectSaleItem
     */
    'scheduleTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DetailedDirectSaleItem
     */
    'reservationId'?: string | null;
    /**
     * 
     * @type {Array<SaleTariff>}
     * @memberof DetailedDirectSaleItem
     */
    'tariffs': Array<SaleTariff>;
    /**
     * 
     * @type {Array<SaleResource>}
     * @memberof DetailedDirectSaleItem
     */
    'resources': Array<SaleResource>;
    /**
     * 
     * @type {Array<SaleAdditional>}
     * @memberof DetailedDirectSaleItem
     */
    'additionals': Array<SaleAdditional>;
    /**
     * 
     * @type {string}
     * @memberof DetailedDirectSaleItem
     */
    'partnershipItemId': string;
    /**
     * 
     * @type {PublicPartnershipItem}
     * @memberof DetailedDirectSaleItem
     */
    'partnershipItem'?: PublicPartnershipItem;
    /**
     * 
     * @type {string}
     * @memberof DetailedDirectSaleItem
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof DetailedDirectSaleItem
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface DetailedSaleItem
 */
export interface DetailedSaleItem {
    /**
     * 
     * @type {string}
     * @memberof DetailedSaleItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DetailedSaleItem
     */
    'productId': string;
    /**
     * 
     * @type {Product}
     * @memberof DetailedSaleItem
     */
    'product'?: Product;
    /**
     * 
     * @type {string}
     * @memberof DetailedSaleItem
     */
    'saleId': string;
    /**
     * 
     * @type {Sale}
     * @memberof DetailedSaleItem
     */
    'sale'?: Sale;
    /**
     * 
     * @type {string}
     * @memberof DetailedSaleItem
     */
    'scheduleDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DetailedSaleItem
     */
    'scheduleTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DetailedSaleItem
     */
    'reservationId'?: string | null;
    /**
     * 
     * @type {Reservation}
     * @memberof DetailedSaleItem
     */
    'reservation'?: Reservation;
    /**
     * 
     * @type {Array<SaleTariff>}
     * @memberof DetailedSaleItem
     */
    'tariffs': Array<SaleTariff>;
    /**
     * 
     * @type {Array<SaleResource>}
     * @memberof DetailedSaleItem
     */
    'resources': Array<SaleResource>;
    /**
     * 
     * @type {Array<SaleAdditional>}
     * @memberof DetailedSaleItem
     */
    'additionals': Array<SaleAdditional>;
    /**
     * 
     * @type {string}
     * @memberof DetailedSaleItem
     */
    'partnershipItemId': string | null;
    /**
     * 
     * @type {PublicPartnershipItem}
     * @memberof DetailedSaleItem
     */
    'partnershipItem'?: PublicPartnershipItem;
    /**
     * 
     * @type {string}
     * @memberof DetailedSaleItem
     */
    'partnershipProviderId': string | null;
    /**
     * 
     * @type {PublicApp}
     * @memberof DetailedSaleItem
     */
    'partnershipProvider'?: PublicApp;
    /**
     * 
     * @type {string}
     * @memberof DetailedSaleItem
     */
    'partnershipAgentId': string | null;
    /**
     * 
     * @type {PublicApp}
     * @memberof DetailedSaleItem
     */
    'partnershipAgent'?: PublicApp;
    /**
     * 
     * @type {number}
     * @memberof DetailedSaleItem
     */
    'priceCents': number;
    /**
     * 
     * @type {BasicSaleCouponProduct}
     * @memberof DetailedSaleItem
     */
    'couponProduct'?: BasicSaleCouponProduct;
    /**
     * 
     * @type {string}
     * @memberof DetailedSaleItem
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof DetailedSaleItem
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface DetailedScheduling
 */
export interface DetailedScheduling {
    /**
     * 
     * @type {string}
     * @memberof DetailedScheduling
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DetailedScheduling
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof DetailedScheduling
     */
    'consumableFromTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DetailedScheduling
     */
    'consumableToTime'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DetailedScheduling
     */
    'calendarIntervalMinutes'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DetailedScheduling
     */
    'durationMinutes': number;
    /**
     * 
     * @type {number}
     * @memberof DetailedScheduling
     * @deprecated
     */
    'antecedenceHours'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DetailedScheduling
     */
    'antecedenceMinutes'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DetailedScheduling
     */
    'antecedenceHoursReferenceTime'?: string | null;
    /**
     * 
     * @type {Array<ScheduleTime>}
     * @memberof DetailedScheduling
     */
    'availableScheduleTimes'?: Array<ScheduleTime> | null;
    /**
     * 
     * @type {DetailedTariffGroup}
     * @memberof DetailedScheduling
     */
    'finalTariffGroup': DetailedTariffGroup;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedScheduling
     */
    'requiresSeatSelection': boolean;
    /**
     * 
     * @type {OccupationRate}
     * @memberof DetailedScheduling
     */
    'occupationRates': OccupationRate;
    /**
     * 
     * @type {Array<TariffScheduleUnitPrice>}
     * @memberof DetailedScheduling
     */
    'tariffScheduleUnitPrices': Array<TariffScheduleUnitPrice>;
}
/**
 * 
 * @export
 * @interface DetailedTariffGroup
 */
export interface DetailedTariffGroup {
    /**
     * 
     * @type {string}
     * @memberof DetailedTariffGroup
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DetailedTariffGroup
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof DetailedTariffGroup
     */
    'seatMapId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DetailedTariffGroup
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedTariffGroup
     */
    'isDefault': boolean;
    /**
     * 
     * @type {TariffGroupPriceInfo}
     * @memberof DetailedTariffGroup
     */
    'priceInfo'?: TariffGroupPriceInfo;
    /**
     * 
     * @type {string}
     * @memberof DetailedTariffGroup
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof DetailedTariffGroup
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof DetailedTariffGroup
     */
    'deletedAt'?: string;
    /**
     * 
     * @type {Array<Tariff>}
     * @memberof DetailedTariffGroup
     */
    'tariffs': Array<Tariff>;
}
/**
 * 
 * @export
 * @interface DeviceInfoParams
 */
export interface DeviceInfoParams {
    /**
     * 
     * @type {string}
     * @memberof DeviceInfoParams
     */
    'language': string;
    /**
     * 
     * @type {number}
     * @memberof DeviceInfoParams
     */
    'screenHeight': number;
    /**
     * 
     * @type {number}
     * @memberof DeviceInfoParams
     */
    'screenWidth': number;
    /**
     * 
     * @type {number}
     * @memberof DeviceInfoParams
     */
    'timeZoneOffset': number;
}
/**
 * 
 * @export
 * @interface DirectLink
 */
export interface DirectLink {
    /**
     * 
     * @type {string}
     * @memberof DirectLink
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DirectLink
     */
    'code': string;
    /**
     * 
     * @type {DirectLinkTypeEnum}
     * @memberof DirectLink
     */
    'type': DirectLinkTypeEnum;
    /**
     * 
     * @type {DirectLinkStatusEnum}
     * @memberof DirectLink
     */
    'status'?: DirectLinkStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DirectLink
     */
    'isExpired': boolean;
    /**
     * 
     * @type {number}
     * @memberof DirectLink
     */
    'totalAmountCents': number;
    /**
     * 
     * @type {string}
     * @memberof DirectLink
     */
    'customMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DirectLink
     */
    'enablePayment'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DirectLink
     */
    'customerId'?: string | null;
    /**
     * 
     * @type {Customer}
     * @memberof DirectLink
     */
    'customer'?: Customer | null;
    /**
     * 
     * @type {string}
     * @memberof DirectLink
     */
    'originId'?: string | null;
    /**
     * 
     * @type {SaleOrigin}
     * @memberof DirectLink
     */
    'origin'?: SaleOrigin | null;
    /**
     * 
     * @type {string}
     * @memberof DirectLink
     */
    'moldCartId'?: string | null;
    /**
     * 
     * @type {Cart}
     * @memberof DirectLink
     */
    'moldCart'?: Cart | null;
    /**
     * 
     * @type {string}
     * @memberof DirectLink
     */
    'saleId'?: string | null;
    /**
     * 
     * @type {Sale}
     * @memberof DirectLink
     */
    'sale'?: Sale | null;
    /**
     * 
     * @type {string}
     * @memberof DirectLink
     */
    'checkoutUrl': string;
    /**
     * 
     * @type {string}
     * @memberof DirectLink
     */
    'expiresAt': string;
    /**
     * 
     * @type {string}
     * @memberof DirectLink
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof DirectLink
     */
    'updatedAt'?: string;
}


/**
 * 
 * @export
 * @interface DirectLinkNotify
 */
export interface DirectLinkNotify {
    /**
     * 
     * @type {boolean}
     * @memberof DirectLinkNotify
     */
    'email': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DirectLinkNotify
     */
    'sms': boolean;
}
/**
 * 
 * @export
 * @interface DirectLinkParams
 */
export interface DirectLinkParams {
    /**
     * 
     * @type {DirectLinkTypeEnum}
     * @memberof DirectLinkParams
     */
    'type': DirectLinkTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DirectLinkParams
     */
    'saleOriginId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DirectLinkParams
     */
    'enablePayment'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DirectLinkParams
     */
    'customMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DirectLinkParams
     */
    'customerId'?: string | null;
    /**
     * 
     * @type {CustomerParams}
     * @memberof DirectLinkParams
     */
    'customer'?: CustomerParams;
    /**
     * 
     * @type {number}
     * @memberof DirectLinkParams
     */
    'maxInstallments'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DirectLinkParams
     */
    'minInstallmentAmountCents'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DirectLinkParams
     */
    'paymentMethodIds': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof DirectLinkParams
     */
    'trustMode'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DirectLinkParams
     */
    'amountCents'?: number | null;
    /**
     * 
     * @type {Array<SaleItemParams>}
     * @memberof DirectLinkParams
     */
    'items': Array<SaleItemParams>;
    /**
     * 
     * @type {string}
     * @memberof DirectLinkParams
     */
    'couponId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DirectLinkParams
     */
    'expiresAt': string;
    /**
     * 
     * @type {DirectLinkNotify}
     * @memberof DirectLinkParams
     */
    'notification'?: DirectLinkNotify;
    /**
     * 
     * @type {DirectLinkVerifications}
     * @memberof DirectLinkParams
     */
    'verifications'?: DirectLinkVerifications | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const DirectLinkStatusEnum = {
    Created: 'created',
    Expired: 'expired',
    PaymentComplete: 'payment_complete',
    Canceled: 'canceled',
    PaymentVoided: 'payment_voided',
    PaymentChargeback: 'payment_chargeback'
} as const;

export type DirectLinkStatusEnum = typeof DirectLinkStatusEnum[keyof typeof DirectLinkStatusEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const DirectLinkTypeEnum = {
    Sale: 'sale',
    Cart: 'cart',
    Payment: 'payment'
} as const;

export type DirectLinkTypeEnum = typeof DirectLinkTypeEnum[keyof typeof DirectLinkTypeEnum];


/**
 * 
 * @export
 * @interface DirectLinkVerifications
 */
export interface DirectLinkVerifications {
    /**
     * 
     * @type {boolean}
     * @memberof DirectLinkVerifications
     */
    'ignoreTariffDependency'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DirectLinkVerifications
     */
    'ignoreSchedulingAntecedence'?: boolean;
}
/**
 * 
 * @export
 * @interface DirectSale
 */
export interface DirectSale {
    /**
     * 
     * @type {string}
     * @memberof DirectSale
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DirectSale
     */
    'appId': string;
    /**
     * 
     * @type {PublicApp}
     * @memberof DirectSale
     */
    'app'?: PublicApp;
    /**
     * 
     * @type {number}
     * @memberof DirectSale
     */
    'position': number;
    /**
     * 
     * @type {string}
     * @memberof DirectSale
     */
    'code': string | null;
    /**
     * 
     * @type {string}
     * @memberof DirectSale
     */
    'customerId': string;
    /**
     * 
     * @type {Customer}
     * @memberof DirectSale
     */
    'customer'?: Customer;
    /**
     * 
     * @type {string}
     * @memberof DirectSale
     */
    'decisiveTransactionId'?: string | null;
    /**
     * 
     * @type {SaleStateEnum}
     * @memberof DirectSale
     */
    'currentState': SaleStateEnum;
    /**
     * 
     * @type {number}
     * @memberof DirectSale
     */
    'amountCents': number;
    /**
     * 
     * @type {SaleCoupon}
     * @memberof DirectSale
     */
    'coupon'?: SaleCoupon | null;
    /**
     * 
     * @type {SaleAggregatedVouchers}
     * @memberof DirectSale
     */
    'aggregatedVouchers'?: SaleAggregatedVouchers;
    /**
     * 
     * @type {string}
     * @memberof DirectSale
     */
    'expiresAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof DirectSale
     */
    'createdAt': string;
}


/**
 * 
 * @export
 * @interface DirectSaleParams
 */
export interface DirectSaleParams {
    /**
     * 
     * @type {string}
     * @memberof DirectSaleParams
     */
    'checkoutId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectSaleParams
     */
    'providerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectSaleParams
     */
    'customerId'?: string | null;
    /**
     * 
     * @type {CustomerParams}
     * @memberof DirectSaleParams
     */
    'customer'?: CustomerParams;
    /**
     * 
     * @type {Array<SaleItemParams>}
     * @memberof DirectSaleParams
     */
    'items': Array<SaleItemParams>;
}
/**
 * 
 * @export
 * @interface DirectSaleTransaction
 */
export interface DirectSaleTransaction {
    /**
     * 
     * @type {string}
     * @memberof DirectSaleTransaction
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DirectSaleTransaction
     */
    'paymentMethodId': string;
    /**
     * 
     * @type {PublicPaymentMethod}
     * @memberof DirectSaleTransaction
     */
    'paymentMethod'?: PublicPaymentMethod;
    /**
     * 
     * @type {boolean}
     * @memberof DirectSaleTransaction
     */
    'pending': boolean;
    /**
     * 
     * @type {string}
     * @memberof DirectSaleTransaction
     */
    'paymentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DirectSaleTransaction
     */
    'expiresAt'?: string | null;
    /**
     * 
     * @type {TransactionPix}
     * @memberof DirectSaleTransaction
     */
    'pix'?: TransactionPix | null;
    /**
     * 
     * @type {TransactionNormalizedStatus}
     * @memberof DirectSaleTransaction
     */
    'normalizedStatus': TransactionNormalizedStatus;
}


/**
 * 
 * @export
 * @interface ErrorField
 */
export interface ErrorField {
    /**
     * 
     * @type {string}
     * @memberof ErrorField
     */
    'type': ErrorFieldTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ErrorField
     */
    'path': string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ErrorField
     */
    'extra'?: { [key: string]: any; } | null;
}

export const ErrorFieldTypeEnum = {
    Required: 'required'
} as const;

export type ErrorFieldTypeEnum = typeof ErrorFieldTypeEnum[keyof typeof ErrorFieldTypeEnum];

/**
 * 
 * @export
 * @interface ErrorObject
 */
export interface ErrorObject {
    /**
     * 
     * @type {string}
     * @memberof ErrorObject
     */
    'status': ErrorObjectStatusEnum;
    /**
     * 
     * @type {Array<ErrorField>}
     * @memberof ErrorObject
     */
    'errors'?: Array<ErrorField>;
}

export const ErrorObjectStatusEnum = {
    BadRequest: 'BAD_REQUEST',
    Unauthorized: 'UNAUTHORIZED',
    Forbidden: 'FORBIDDEN',
    NotFound: 'NOT_FOUND',
    Unprocessable: 'UNPROCESSABLE',
    ClientClosedRequest: 'CLIENT_CLOSED_REQUEST'
} as const;

export type ErrorObjectStatusEnum = typeof ErrorObjectStatusEnum[keyof typeof ErrorObjectStatusEnum];

/**
 * 
 * @export
 * @interface EstablishmentType
 */
export interface EstablishmentType {
    /**
     * 
     * @type {string}
     * @memberof EstablishmentType
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EstablishmentType
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EstablishmentType
     */
    'mcc': string;
    /**
     * 
     * @type {string}
     * @memberof EstablishmentType
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof EstablishmentType
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface ExternalSaleParams
 */
export interface ExternalSaleParams {
    /**
     * 
     * @type {string}
     * @memberof ExternalSaleParams
     */
    'customerId'?: string | null;
    /**
     * 
     * @type {CustomerParams}
     * @memberof ExternalSaleParams
     */
    'customer'?: CustomerParams;
    /**
     * 
     * @type {Array<SaleItemParams>}
     * @memberof ExternalSaleParams
     */
    'items': Array<SaleItemParams>;
    /**
     * 
     * @type {number}
     * @memberof ExternalSaleParams
     */
    'amountCents'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExternalSaleParams
     */
    'attributes': Array<string>;
    /**
     * 
     * @type {ExternalSaleVerifications}
     * @memberof ExternalSaleParams
     */
    'verifications'?: ExternalSaleVerifications;
}
/**
 * 
 * @export
 * @interface ExternalSaleVerifications
 */
export interface ExternalSaleVerifications {
    /**
     * 
     * @type {boolean}
     * @memberof ExternalSaleVerifications
     */
    'ignoreSchedulings'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExternalSaleVerifications
     */
    'ignoreAvailabilities'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExternalSaleVerifications
     */
    'ignoreResources'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExternalSaleVerifications
     */
    'ignoreTariffDependency'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExternalSaleVerifications
     */
    'ignoreSchedulingAntecedence'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const IdentifierType = {
    Cpf: 'cpf',
    Cnpj: 'cnpj'
} as const;

export type IdentifierType = typeof IdentifierType[keyof typeof IdentifierType];


/**
 * 
 * @export
 * @enum {string}
 */

export const IdentityTypeEnum = {
    Cpf: 'cpf',
    Cnpj: 'cnpj',
    Identity: 'identity',
    Passport: 'passport'
} as const;

export type IdentityTypeEnum = typeof IdentityTypeEnum[keyof typeof IdentityTypeEnum];


/**
 * 
 * @export
 * @interface Image
 */
export interface Image {
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    'alternativeText': string;
    /**
     * 
     * @type {Array<ImageStyle>}
     * @memberof Image
     */
    'styles': Array<ImageStyle>;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface ImageStyle
 */
export interface ImageStyle {
    /**
     * 
     * @type {string}
     * @memberof ImageStyle
     */
    'style': string;
    /**
     * 
     * @type {string}
     * @memberof ImageStyle
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface IncomeScheduling
 */
export interface IncomeScheduling {
    /**
     * 
     * @type {string}
     * @memberof IncomeScheduling
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IncomeScheduling
     */
    'transactionId': string;
    /**
     * 
     * @type {Transaction}
     * @memberof IncomeScheduling
     */
    'transaction'?: Transaction;
    /**
     * 
     * @type {IncomeSchedulingEventType}
     * @memberof IncomeScheduling
     */
    'eventType': IncomeSchedulingEventType;
    /**
     * 
     * @type {number}
     * @memberof IncomeScheduling
     */
    'grossAmountCents': number;
    /**
     * 
     * @type {number}
     * @memberof IncomeScheduling
     */
    'amountCents': number;
    /**
     * 
     * @type {number}
     * @memberof IncomeScheduling
     */
    'grossDifferenceCents': number;
    /**
     * 
     * @type {number}
     * @memberof IncomeScheduling
     */
    'installmentTotal': number;
    /**
     * 
     * @type {number}
     * @memberof IncomeScheduling
     */
    'installmentNumber': number;
    /**
     * 
     * @type {string}
     * @memberof IncomeScheduling
     */
    'availableAt': string;
    /**
     * 
     * @type {string}
     * @memberof IncomeScheduling
     */
    'performedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof IncomeScheduling
     */
    'plannedAt': string;
    /**
     * 
     * @type {string}
     * @memberof IncomeScheduling
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof IncomeScheduling
     */
    'updatedAt': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const IncomeSchedulingEventType = {
    Credit: 'credit',
    Debit: 'debit',
    FeeCredit: 'fee_credit',
    FeeDebit: 'fee_debit',
    ChargebackDebit: 'chargeback_debit',
    RefundDebit: 'refund_debit',
    AntiFraudFeeDebit: 'anti_fraud_fee_debit'
} as const;

export type IncomeSchedulingEventType = typeof IncomeSchedulingEventType[keyof typeof IncomeSchedulingEventType];


/**
 * 
 * @export
 * @interface LoginParams
 */
export interface LoginParams {
    /**
     * 
     * @type {string}
     * @memberof LoginParams
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginParams
     */
    'password': string;
    /**
     * 
     * @type {boolean}
     * @memberof LoginParams
     */
    'keepConnected'?: boolean;
}
/**
 * 
 * @export
 * @interface MarketingData
 */
export interface MarketingData {
    /**
     * 
     * @type {Array<Utm>}
     * @memberof MarketingData
     */
    'utms': Array<Utm>;
}
/**
 * 
 * @export
 * @interface OccupationInfo
 */
export interface OccupationInfo {
    /**
     * 
     * @type {number}
     * @memberof OccupationInfo
     */
    'availableAmount': number | null;
    /**
     * 
     * @type {number}
     * @memberof OccupationInfo
     */
    'occupationRate': number | null;
}
/**
 * 
 * @export
 * @interface OccupationRate
 */
export interface OccupationRate {
    /**
     * 
     * @type {Array<DateAndTimeScheduleQuantity>}
     * @memberof OccupationRate
     */
    'scheduleQuantities': Array<DateAndTimeScheduleQuantity>;
    /**
     * 
     * @type {Array<DateAndTimeTariffScheduleQuantity>}
     * @memberof OccupationRate
     */
    'tariffScheduleQuantities': Array<DateAndTimeTariffScheduleQuantity>;
    /**
     * 
     * @type {Array<DateAndTimeAdditionalScheduleQuantity>}
     * @memberof OccupationRate
     */
    'additionalScheduleQuantities': Array<DateAndTimeAdditionalScheduleQuantity>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OrderStatus = {
    Created: 'created',
    Expired: 'expired',
    Confirmed: 'confirmed',
    PaymentVoided: 'payment_voided',
    PaymentChargedBack: 'payment_charged_back'
} as const;

export type OrderStatus = typeof OrderStatus[keyof typeof OrderStatus];


/**
 * 
 * @export
 * @interface Partnership
 */
export interface Partnership {
    /**
     * 
     * @type {string}
     * @memberof Partnership
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Partnership
     */
    'providerId': string;
    /**
     * 
     * @type {PublicApp}
     * @memberof Partnership
     */
    'provider'?: PublicApp;
    /**
     * 
     * @type {string}
     * @memberof Partnership
     */
    'agentId': string;
    /**
     * 
     * @type {PublicApp}
     * @memberof Partnership
     */
    'agent'?: PublicApp;
    /**
     * 
     * @type {string}
     * @memberof Partnership
     */
    'publicProposalId': string;
    /**
     * 
     * @type {PublicPartnershipProposal}
     * @memberof Partnership
     */
    'publicProposal'?: PublicPartnershipProposal;
    /**
     * 
     * @type {PartnershipStatus}
     * @memberof Partnership
     */
    'currentStatus': PartnershipStatus;
    /**
     * 
     * @type {string}
     * @memberof Partnership
     */
    'affiliationCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Partnership
     */
    'maxPercentageDiscount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Partnership
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Partnership
     */
    'updatedAt'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PartnershipCommissionType = {
    AmountCents: 'amount_cents',
    PercentageFee: 'percentage_fee'
} as const;

export type PartnershipCommissionType = typeof PartnershipCommissionType[keyof typeof PartnershipCommissionType];


/**
 * 
 * @export
 * @interface PartnershipProposal
 */
export interface PartnershipProposal {
    /**
     * 
     * @type {string}
     * @memberof PartnershipProposal
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PartnershipProposal
     */
    'shareCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartnershipProposal
     */
    'providerId': string;
    /**
     * 
     * @type {string}
     * @memberof PartnershipProposal
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartnershipProposal
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PartnershipProposal
     */
    'isPublic': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartnershipProposal
     */
    'allowsCounterProposal': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartnershipProposal
     */
    'autoApproves': boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnershipProposal
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PartnershipProposal
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PartnershipStatus = {
    Pending: 'pending',
    Ongoing: 'ongoing',
    Declined: 'declined',
    Deactivated: 'deactivated'
} as const;

export type PartnershipStatus = typeof PartnershipStatus[keyof typeof PartnershipStatus];


/**
 * 
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'customerName': string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'customerEmail': string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'customerIdentity': string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'customerPhone': string;
    /**
     * 
     * @type {Array<PaymentCustomerSelected>}
     * @memberof Payment
     */
    'customerSelected': Array<PaymentCustomerSelected>;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'partialAmountCents': number;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'maxInstallmentsSum': number;
    /**
     * 
     * @type {PaymentCurrentState}
     * @memberof Payment
     */
    'currentState': PaymentCurrentState;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'deadlineDate': string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'updatedAt': string;
}


/**
 * 
 * @export
 * @interface PaymentBrand
 */
export interface PaymentBrand {
    /**
     * 
     * @type {string}
     * @memberof PaymentBrand
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface PaymentCardParams
 */
export interface PaymentCardParams {
    /**
     * 
     * @type {string}
     * @memberof PaymentCardParams
     */
    'cardNumber': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentCardParams
     */
    'holder': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentCardParams
     */
    'holderIdentity': string | null;
    /**
     * 
     * @type {number}
     * @memberof PaymentCardParams
     */
    'installments': number;
    /**
     * 
     * @type {string}
     * @memberof PaymentCardParams
     */
    'securityCode': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentCardParams
     */
    'validityMonth': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentCardParams
     */
    'validityYear': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentCardParams
     */
    'postalCode'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PaymentCurrentState = {
    Created: 'created',
    Expired: 'expired',
    Pending: 'pending',
    PaymentComplete: 'payment_complete',
    PaymentVoided: 'payment_voided',
    PaymentChargeback: 'payment_chargeback'
} as const;

export type PaymentCurrentState = typeof PaymentCurrentState[keyof typeof PaymentCurrentState];


/**
 * 
 * @export
 * @enum {string}
 */

export const PaymentCustomerSelected = {
    Email: 'customer_email',
    Identity: 'customer_identity',
    Phone: 'customer_phone'
} as const;

export type PaymentCustomerSelected = typeof PaymentCustomerSelected[keyof typeof PaymentCustomerSelected];


/**
 * 
 * @export
 * @interface PaymentFingerprint
 */
export interface PaymentFingerprint {
    /**
     * 
     * @type {string}
     * @memberof PaymentFingerprint
     */
    'type': PaymentFingerprintTypeEnum;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof PaymentFingerprint
     */
    'data': { [key: string]: any; };
}

export const PaymentFingerprintTypeEnum = {
    Cybersource: 'cybersource',
    Clearsale: 'clearsale'
} as const;

export type PaymentFingerprintTypeEnum = typeof PaymentFingerprintTypeEnum[keyof typeof PaymentFingerprintTypeEnum];

/**
 * 
 * @export
 * @interface PaymentGateway
 */
export interface PaymentGateway {
    /**
     * 
     * @type {string}
     * @memberof PaymentGateway
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentGateway
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentGateway
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface PaymentInstallmentOption
 */
export interface PaymentInstallmentOption {
    /**
     * 
     * @type {number}
     * @memberof PaymentInstallmentOption
     */
    'division': number;
    /**
     * 
     * @type {number}
     * @memberof PaymentInstallmentOption
     */
    'amountCents': number;
    /**
     * 
     * @type {number}
     * @memberof PaymentInstallmentOption
     */
    'installmentCents': number;
}
/**
 * 
 * @export
 * @interface PaymentMethod
 */
export interface PaymentMethod {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    'id': string;
    /**
     * 
     * @type {PaymentMethodCode}
     * @memberof PaymentMethod
     */
    'code': PaymentMethodCode;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentMethod
     */
    'active': boolean;
    /**
     * 
     * @type {number}
     * @memberof PaymentMethod
     */
    'maxInstallmentsNum'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentMethod
     */
    'minInstallmentAmountCents': number;
    /**
     * 
     * @type {number}
     * @memberof PaymentMethod
     */
    'discountPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentMethod
     */
    'interestRatePercentage': number;
    /**
     * 
     * @type {number}
     * @memberof PaymentMethod
     */
    'interestRateExemptedInstallments': number;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    'updatedAt': string;
}


/**
 * 
 * @export
 * @interface PaymentMethodBrand
 */
export interface PaymentMethodBrand {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodBrand
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodBrand
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodBrand
     */
    'logoUrl'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PaymentMethodCode = {
    CreditCard: 'credit_card',
    DebitCard: 'debit_card',
    Pix: 'pix',
    PostPaid: 'post_paid'
} as const;

export type PaymentMethodCode = typeof PaymentMethodCode[keyof typeof PaymentMethodCode];


/**
 * 
 * @export
 * @interface PaymentOption
 */
export interface PaymentOption {
    /**
     * 
     * @type {PublicPaymentMethod}
     * @memberof PaymentOption
     */
    'paymentMethod': PublicPaymentMethod;
    /**
     * 
     * @type {string}
     * @memberof PaymentOption
     */
    'softDescriptor'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PaymentOption
     */
    'amountCents': number;
    /**
     * 
     * @type {Array<PaymentBrand>}
     * @memberof PaymentOption
     */
    'brands'?: Array<PaymentBrand>;
    /**
     * 
     * @type {Array<PaymentInstallmentOption>}
     * @memberof PaymentOption
     */
    'installmentOptions': Array<PaymentInstallmentOption>;
    /**
     * 
     * @type {Array<PaymentFingerprint>}
     * @memberof PaymentOption
     */
    'fingerprints'?: Array<PaymentFingerprint> | null;
}
/**
 * 
 * @export
 * @interface Plan
 */
export interface Plan {
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    'name': string;
    /**
     * 
     * @type {PlanSellerType}
     * @memberof Plan
     */
    'sellerType': PlanSellerType;
}


/**
 * 
 * @export
 * @interface PlanFee
 */
export interface PlanFee {
    /**
     * 
     * @type {string}
     * @memberof PlanFee
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PlanFee
     */
    'planId': string;
    /**
     * 
     * @type {TransactableTypeEnum}
     * @memberof PlanFee
     */
    'transactableType': TransactableTypeEnum;
    /**
     * 
     * @type {PaymentMethodCode}
     * @memberof PlanFee
     */
    'paymentMethodType': PaymentMethodCode;
    /**
     * 
     * @type {number}
     * @memberof PlanFee
     */
    'minInstallments': number;
    /**
     * 
     * @type {number}
     * @memberof PlanFee
     */
    'maxInstallments': number;
    /**
     * 
     * @type {number}
     * @memberof PlanFee
     */
    'generalFee': number;
    /**
     * 
     * @type {number}
     * @memberof PlanFee
     */
    'planRateCents': number;
    /**
     * 
     * @type {string}
     * @memberof PlanFee
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PlanFee
     */
    'updatedAt': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PlanFeeDistributionMode = {
    Proportional: 'proportional',
    ProviderBacked: 'provider_backed',
    AgentBacked: 'agent_backed'
} as const;

export type PlanFeeDistributionMode = typeof PlanFeeDistributionMode[keyof typeof PlanFeeDistributionMode];


/**
 * 
 * @export
 * @enum {string}
 */

export const PlanSellerType = {
    Provider: 'provider',
    Agent: 'agent'
} as const;

export type PlanSellerType = typeof PlanSellerType[keyof typeof PlanSellerType];


/**
 * 
 * @export
 * @interface PolicyRule
 */
export interface PolicyRule {
    /**
     * 
     * @type {string}
     * @memberof PolicyRule
     */
    'namespace': PolicyRuleNamespaceEnum;
    /**
     * 
     * @type {string}
     * @memberof PolicyRule
     */
    'action': string;
    /**
     * 
     * @type {string}
     * @memberof PolicyRule
     */
    'code': string;
}

export const PolicyRuleNamespaceEnum = {
    Apps: 'apps',
    Sales: 'sales',
    SaleItems: 'saleItems',
    TariffGroups: 'tariffGroups',
    Customers: 'customers',
    Reservations: 'reservations',
    Vouchers: 'vouchers',
    Products: 'products',
    SaleAdditionals: 'saleAdditionals',
    IncomeSchedulings: 'incomeSchedulings',
    RefundRequests: 'refundRequests',
    DirectLinks: 'directLinks',
    SiteConfigs: 'siteConfigs',
    PaymentMethods: 'paymentMethods',
    Availabilities: 'availabilities',
    SaleOrigins: 'saleOrigins',
    Schedulings: 'schedulings',
    Partnerships: 'partnerships',
    Coupons: 'coupons',
    Tags: 'tags',
    ReservationTariffs: 'reservationTariffs',
    ArrangedProducts: 'arrangedProducts',
    DirectSales: 'directSales',
    CouponProducts: 'couponProducts'
} as const;

export type PolicyRuleNamespaceEnum = typeof PolicyRuleNamespaceEnum[keyof typeof PolicyRuleNamespaceEnum];

/**
 * 
 * @export
 * @interface PriceConditionEntityParameter
 */
export interface PriceConditionEntityParameter {
    /**
     * 
     * @type {string}
     * @memberof PriceConditionEntityParameter
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PriceConditionEntityParameter
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PriceConditionEntityParameter
     */
    'tariffTypeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PriceConditionEntityParameter
     */
    'tariffGroupId'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PriceConditionOperator = {
    EvaluatingTariff: 'evaluating_tariff',
    CheckProducts: 'check_products',
    CheckTariffTypes: 'check_tariff_types',
    CheckTariffGroups: 'check_tariff_groups',
    ScheduleDatetimeGte: 'schedule_datetime_gte',
    ScheduleDatetimeLte: 'schedule_datetime_lte',
    PurchaseDatetimeGte: 'purchase_datetime_gte',
    PurchaseDatetimeLte: 'purchase_datetime_lte',
    ScheduleDateDiffGte: 'schedule_date_diff_gte',
    ScheduleDateDiffLte: 'schedule_date_diff_lte',
    TariffOccupationGte: 'tariff_occupation_gte',
    TariffOccupationLte: 'tariff_occupation_lte',
    ProductOccupationGte: 'product_occupation_gte',
    ProductOccupationLte: 'product_occupation_lte'
} as const;

export type PriceConditionOperator = typeof PriceConditionOperator[keyof typeof PriceConditionOperator];


/**
 * 
 * @export
 * @interface PriceModifier
 */
export interface PriceModifier {
    /**
     * 
     * @type {string}
     * @memberof PriceModifier
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PriceModifier
     */
    'name': string;
    /**
     * 
     * @type {Array<PriceModifierComponentType>}
     * @memberof PriceModifier
     */
    'componentTypes': Array<PriceModifierComponentType>;
    /**
     * 
     * @type {string}
     * @memberof PriceModifier
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PriceModifier
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof PriceModifier
     */
    'deletedAt': string | null;
}
/**
 * 
 * @export
 * @interface PriceModifierActuator
 */
export interface PriceModifierActuator {
    /**
     * 
     * @type {PriceMutatorOperator}
     * @memberof PriceModifierActuator
     */
    'operator': PriceMutatorOperator;
    /**
     * 
     * @type {number}
     * @memberof PriceModifierActuator
     */
    'valueCents'?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceModifierActuator
     */
    'valuePercent'?: number;
    /**
     * 
     * @type {RoundingMethod}
     * @memberof PriceModifierActuator
     */
    'roundingMethod'?: RoundingMethod;
    /**
     * 
     * @type {boolean}
     * @memberof PriceModifierActuator
     */
    'followOn'?: boolean;
}


/**
 * 
 * @export
 * @interface PriceModifierActuatorParams
 */
export interface PriceModifierActuatorParams {
    /**
     * 
     * @type {PriceMutatorOperator}
     * @memberof PriceModifierActuatorParams
     */
    'operator': PriceMutatorOperator;
    /**
     * 
     * @type {number}
     * @memberof PriceModifierActuatorParams
     */
    'valueCents'?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceModifierActuatorParams
     */
    'valuePercent'?: number;
    /**
     * 
     * @type {RoundingMethod}
     * @memberof PriceModifierActuatorParams
     */
    'roundingMethod'?: RoundingMethod;
    /**
     * 
     * @type {boolean}
     * @memberof PriceModifierActuatorParams
     */
    'followOn'?: boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PriceModifierComponentType = {
    Tariff: 'tariff'
} as const;

export type PriceModifierComponentType = typeof PriceModifierComponentType[keyof typeof PriceModifierComponentType];


/**
 * 
 * @export
 * @interface PriceModifierCondition
 */
export interface PriceModifierCondition {
    /**
     * 
     * @type {string}
     * @memberof PriceModifierCondition
     */
    'id': string;
    /**
     * 
     * @type {PriceConditionOperator}
     * @memberof PriceModifierCondition
     */
    'operator': PriceConditionOperator;
    /**
     * 
     * @type {boolean}
     * @memberof PriceModifierCondition
     */
    'negative': boolean;
    /**
     * 
     * @type {string}
     * @memberof PriceModifierCondition
     */
    'datetime'?: string;
    /**
     * 
     * @type {number}
     * @memberof PriceModifierCondition
     */
    'dateDiff'?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceModifierCondition
     */
    'occupationRate'?: number;
    /**
     * 
     * @type {Array<PriceConditionEntityParameter>}
     * @memberof PriceModifierCondition
     */
    'entityParameters'?: Array<PriceConditionEntityParameter>;
}


/**
 * 
 * @export
 * @interface PriceModifierConditionParams
 */
export interface PriceModifierConditionParams {
    /**
     * 
     * @type {PriceConditionOperator}
     * @memberof PriceModifierConditionParams
     */
    'operator': PriceConditionOperator;
    /**
     * 
     * @type {boolean}
     * @memberof PriceModifierConditionParams
     */
    'negative': boolean;
    /**
     * 
     * @type {string}
     * @memberof PriceModifierConditionParams
     */
    'datetime'?: string;
    /**
     * 
     * @type {number}
     * @memberof PriceModifierConditionParams
     */
    'dateDiff'?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceModifierConditionParams
     */
    'occupationRate'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PriceModifierConditionParams
     */
    'productIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PriceModifierConditionParams
     */
    'tariffTypeIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PriceModifierConditionParams
     */
    'tariffGroupIds'?: Array<string>;
}


/**
 * 
 * @export
 * @interface PriceModifierMutator
 */
export interface PriceModifierMutator {
    /**
     * 
     * @type {string}
     * @memberof PriceModifierMutator
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof PriceModifierMutator
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof PriceModifierMutator
     */
    'description': string | null;
    /**
     * 
     * @type {Array<PriceModifierCondition>}
     * @memberof PriceModifierMutator
     */
    'conditions'?: Array<PriceModifierCondition>;
    /**
     * 
     * @type {PriceModifierActuator}
     * @memberof PriceModifierMutator
     */
    'actuator': PriceModifierActuator;
}
/**
 * 
 * @export
 * @interface PriceModifierMutatorParams
 */
export interface PriceModifierMutatorParams {
    /**
     * 
     * @type {string}
     * @memberof PriceModifierMutatorParams
     */
    'description': string | null;
    /**
     * 
     * @type {Array<PriceModifierConditionParams>}
     * @memberof PriceModifierMutatorParams
     */
    'conditions': Array<PriceModifierConditionParams>;
    /**
     * 
     * @type {PriceModifierActuatorParams}
     * @memberof PriceModifierMutatorParams
     */
    'actuator': PriceModifierActuatorParams;
}
/**
 * 
 * @export
 * @interface PriceModifierParams
 */
export interface PriceModifierParams {
    /**
     * 
     * @type {string}
     * @memberof PriceModifierParams
     */
    'name': string;
    /**
     * 
     * @type {Array<PriceModifierComponentType>}
     * @memberof PriceModifierParams
     */
    'componentTypes': Array<PriceModifierComponentType>;
    /**
     * 
     * @type {Array<PriceModifierMutatorParams>}
     * @memberof PriceModifierParams
     */
    'mutators': Array<PriceModifierMutatorParams>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PriceMutatorOperator = {
    SetValueCents: 'set_value_cents',
    ModifyPercent: 'modify_percent',
    ModifyCents: 'modify_cents',
    ReturnValue: 'return_value',
    SetMaxCents: 'set_max_cents',
    SetMinCents: 'set_min_cents'
} as const;

export type PriceMutatorOperator = typeof PriceMutatorOperator[keyof typeof PriceMutatorOperator];


/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'id': string;
    /**
     * 
     * @type {SellingMode}
     * @memberof Product
     */
    'sellingMode': SellingMode;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'internalName': string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'shortDescription': string;
    /**
     * 
     * @type {ProductVoucherSettings}
     * @memberof Product
     */
    'voucherSettings': ProductVoucherSettings;
    /**
     * 
     * @type {ProductTariffSettings}
     * @memberof Product
     */
    'tariffSettings': ProductTariffSettings;
    /**
     * 
     * @type {string}
     * @memberof Product
     * @deprecated
     */
    'voucherGuidelines'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Product
     */
    'includedItems'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Product
     */
    'notIncludedItems'?: Array<string> | null;
    /**
     * 
     * @type {TariffDisplayOrder}
     * @memberof Product
     * @deprecated
     */
    'tariffDisplayOrder': TariffDisplayOrder | null;
    /**
     * 
     * @type {number}
     * @memberof Product
     * @deprecated
     */
    'minSaleTariffQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof Product
     * @deprecated
     */
    'maxSaleTariffQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof Product
     * @deprecated
     */
    'durationMinutes': number | null;
    /**
     * 
     * @type {number}
     * @memberof Product
     * @deprecated
     */
    'antecedenceMinutes': number | null;
    /**
     * 
     * @type {VoucherSplitPolicy}
     * @memberof Product
     * @deprecated
     */
    'voucherSplitPolicy': VoucherSplitPolicy;
    /**
     * 
     * @type {VoucherExpirationType}
     * @memberof Product
     * @deprecated
     */
    'expirationType': VoucherExpirationType | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     * @deprecated
     */
    'expirationDatetime': string | null;
    /**
     * 
     * @type {number}
     * @memberof Product
     * @deprecated
     */
    'expirationDays': number | null;
    /**
     * 
     * @type {TariffGroupPriceInfo}
     * @memberof Product
     */
    'defaultTariffGroupPriceInfo'?: TariffGroupPriceInfo | null;
    /**
     * 
     * @type {DefaultTariff}
     * @memberof Product
     */
    'defaultTariff'?: DefaultTariff | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'thumbnailUrl': string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'updatedAt': string;
}


/**
 * 
 * @export
 * @interface ProductCategory
 */
export interface ProductCategory {
    /**
     * 
     * @type {string}
     * @memberof ProductCategory
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductCategory
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductCategory
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductCategory
     */
    'deletedAt'?: string;
}
/**
 * 
 * @export
 * @interface ProductImage
 */
export interface ProductImage {
    /**
     * 
     * @type {string}
     * @memberof ProductImage
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductImage
     */
    'active': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductImage
     */
    'thumbnail': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductImage
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductImage
     */
    'imageId': string;
    /**
     * 
     * @type {Image}
     * @memberof ProductImage
     */
    'image': Image;
    /**
     * 
     * @type {string}
     * @memberof ProductImage
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductImage
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface ProductParams
 */
export interface ProductParams {
    /**
     * 
     * @type {boolean}
     * @memberof ProductParams
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductParams
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductParams
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ProductParams
     */
    'shortDescription': string;
    /**
     * 
     * @type {string}
     * @memberof ProductParams
     */
    'internalName'?: string | null;
    /**
     * 
     * @type {SellingMode}
     * @memberof ProductParams
     */
    'sellingMode': SellingMode;
    /**
     * 
     * @type {ProductVoucherSettings}
     * @memberof ProductParams
     */
    'voucherSettings': ProductVoucherSettings;
    /**
     * 
     * @type {ProductTariffSettings}
     * @memberof ProductParams
     */
    'tariffSettings': ProductTariffSettings;
}


/**
 * 
 * @export
 * @interface ProductTariffSettings
 */
export interface ProductTariffSettings {
    /**
     * 
     * @type {number}
     * @memberof ProductTariffSettings
     */
    'minSaleQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof ProductTariffSettings
     */
    'maxSaleQuantity': number;
    /**
     * 
     * @type {TariffDisplayOrder}
     * @memberof ProductTariffSettings
     */
    'displayOrder'?: TariffDisplayOrder | null;
    /**
     * 
     * @type {number}
     * @memberof ProductTariffSettings
     */
    'durationMinutes'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductTariffSettings
     */
    'antecedenceMinutes'?: number | null;
}


/**
 * 
 * @export
 * @interface ProductTariffType
 */
export interface ProductTariffType {
    /**
     * 
     * @type {string}
     * @memberof ProductTariffType
     */
    'productId': string;
    /**
     * 
     * @type {Product}
     * @memberof ProductTariffType
     */
    'product'?: Product;
    /**
     * 
     * @type {string}
     * @memberof ProductTariffType
     */
    'tariffTypeId': string;
    /**
     * 
     * @type {TariffType}
     * @memberof ProductTariffType
     */
    'tariffType'?: TariffType;
}
/**
 * 
 * @export
 * @interface ProductVoucherSettings
 */
export interface ProductVoucherSettings {
    /**
     * 
     * @type {VoucherSplitPolicy}
     * @memberof ProductVoucherSettings
     */
    'splitPolicy': VoucherSplitPolicy;
    /**
     * 
     * @type {VoucherExpirationType}
     * @memberof ProductVoucherSettings
     */
    'expirationType'?: VoucherExpirationType | null;
    /**
     * 
     * @type {string}
     * @memberof ProductVoucherSettings
     */
    'expirationDatetime'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductVoucherSettings
     */
    'expirationDays'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductVoucherSettings
     */
    'guidelines'?: string | null;
}


/**
 * 
 * @export
 * @interface PublicApp
 */
export interface PublicApp {
    /**
     * 
     * @type {string}
     * @memberof PublicApp
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PublicApp
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PublicApp
     */
    'logoUrl'?: string | null;
    /**
     * 
     * @type {AppContact}
     * @memberof PublicApp
     */
    'contact': AppContact;
    /**
     * 
     * @type {string}
     * @memberof PublicApp
     */
    'siteUrl'?: string | null;
    /**
     * 
     * @type {PublicAppInfo}
     * @memberof PublicApp
     */
    'info'?: PublicAppInfo | null;
}
/**
 * 
 * @export
 * @interface PublicAppInfo
 */
export interface PublicAppInfo {
    /**
     * 
     * @type {string}
     * @memberof PublicAppInfo
     */
    'identifier': string;
    /**
     * 
     * @type {IdentifierType}
     * @memberof PublicAppInfo
     */
    'identifierType': IdentifierType;
    /**
     * 
     * @type {string}
     * @memberof PublicAppInfo
     */
    'fantasyName': string;
    /**
     * 
     * @type {PublicAppInfoContact}
     * @memberof PublicAppInfo
     */
    'contact': PublicAppInfoContact;
    /**
     * 
     * @type {PublicAppInfoOwner}
     * @memberof PublicAppInfo
     */
    'owner'?: PublicAppInfoOwner | null;
    /**
     * 
     * @type {PublicAppInfoStatus}
     * @memberof PublicAppInfo
     */
    'infoStatus': PublicAppInfoStatus;
}


/**
 * 
 * @export
 * @interface PublicAppInfoContact
 */
export interface PublicAppInfoContact {
    /**
     * 
     * @type {string}
     * @memberof PublicAppInfoContact
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PublicAppInfoContact
     */
    'phone': string;
}
/**
 * 
 * @export
 * @interface PublicAppInfoOwner
 */
export interface PublicAppInfoOwner {
    /**
     * 
     * @type {string}
     * @memberof PublicAppInfoOwner
     */
    'name': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PublicAppInfoStatus = {
    Pending: 'pending',
    Approved: 'approved',
    Processing: 'processing',
    Analysing: 'analysing'
} as const;

export type PublicAppInfoStatus = typeof PublicAppInfoStatus[keyof typeof PublicAppInfoStatus];


/**
 * 
 * @export
 * @interface PublicPartnershipItem
 */
export interface PublicPartnershipItem {
    /**
     * 
     * @type {string}
     * @memberof PublicPartnershipItem
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof PublicPartnershipItem
     */
    'allProducts': boolean;
    /**
     * 
     * @type {string}
     * @memberof PublicPartnershipItem
     */
    'partnershipId'?: string | null;
    /**
     * 
     * @type {Partnership}
     * @memberof PublicPartnershipItem
     */
    'partnership'?: Partnership | null;
    /**
     * 
     * @type {string}
     * @memberof PublicPartnershipItem
     */
    'productId'?: string | null;
    /**
     * 
     * @type {PublicProduct}
     * @memberof PublicPartnershipItem
     */
    'product'?: PublicProduct | null;
    /**
     * 
     * @type {Array<AssociationModel>}
     * @memberof PublicPartnershipItem
     */
    'associationModels': Array<AssociationModel>;
    /**
     * 
     * @type {PartnershipCommissionType}
     * @memberof PublicPartnershipItem
     */
    'commissionType': PartnershipCommissionType;
    /**
     * 
     * @type {number}
     * @memberof PublicPartnershipItem
     */
    'commissionAmountCents'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PublicPartnershipItem
     */
    'commissionPercentageFee'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PublicPartnershipItem
     */
    'includePlanFees': boolean;
    /**
     * 
     * @type {PlanFeeDistributionMode}
     * @memberof PublicPartnershipItem
     */
    'planFeeDistributionMode': PlanFeeDistributionMode;
}


/**
 * 
 * @export
 * @interface PublicPartnershipProposal
 */
export interface PublicPartnershipProposal {
    /**
     * 
     * @type {string}
     * @memberof PublicPartnershipProposal
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PublicPartnershipProposal
     */
    'shareCode': string;
    /**
     * 
     * @type {string}
     * @memberof PublicPartnershipProposal
     */
    'providerId': string;
    /**
     * 
     * @type {PublicApp}
     * @memberof PublicPartnershipProposal
     */
    'provider'?: PublicApp;
    /**
     * 
     * @type {string}
     * @memberof PublicPartnershipProposal
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PublicPartnershipProposal
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PublicPartnershipProposal
     */
    'maxPercentageDiscount'?: number | null;
}
/**
 * 
 * @export
 * @interface PublicPaymentMethod
 */
export interface PublicPaymentMethod {
    /**
     * 
     * @type {string}
     * @memberof PublicPaymentMethod
     */
    'id': string;
    /**
     * 
     * @type {PaymentMethodCode}
     * @memberof PublicPaymentMethod
     */
    'code': PaymentMethodCode;
    /**
     * 
     * @type {string}
     * @memberof PublicPaymentMethod
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof PublicPaymentMethod
     */
    'discountPercentage'?: number | null;
}


/**
 * 
 * @export
 * @interface PublicProduct
 */
export interface PublicProduct {
    /**
     * 
     * @type {string}
     * @memberof PublicProduct
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PublicProduct
     */
    'appId': string;
    /**
     * 
     * @type {PublicApp}
     * @memberof PublicProduct
     */
    'app'?: PublicApp;
    /**
     * 
     * @type {SellingMode}
     * @memberof PublicProduct
     */
    'sellingMode': SellingMode;
    /**
     * 
     * @type {string}
     * @memberof PublicProduct
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PublicProduct
     */
    'thumbnailUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PublicProduct
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof PublicProduct
     */
    'shortDescription': string;
    /**
     * 
     * @type {string}
     * @memberof PublicProduct
     */
    'voucherGuidelines': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PublicProduct
     */
    'includedItems': Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PublicProduct
     */
    'notIncludedItems': Array<string> | null;
    /**
     * 
     * @type {TariffGroupPriceInfo}
     * @memberof PublicProduct
     */
    'defaultTariffGroupPriceInfo'?: TariffGroupPriceInfo | null;
    /**
     * 
     * @type {DefaultTariff}
     * @memberof PublicProduct
     */
    'defaultTariff'?: DefaultTariff | null;
}


/**
 * 
 * @export
 * @interface PublicProductImage
 */
export interface PublicProductImage {
    /**
     * 
     * @type {string}
     * @memberof PublicProductImage
     */
    'id': string;
    /**
     * 
     * @type {Image}
     * @memberof PublicProductImage
     */
    'image'?: Image;
    /**
     * 
     * @type {boolean}
     * @memberof PublicProductImage
     */
    'thumbnail': boolean;
}
/**
 * 
 * @export
 * @interface PublicScheduling
 */
export interface PublicScheduling {
    /**
     * 
     * @type {string}
     * @memberof PublicScheduling
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof PublicScheduling
     */
    'antecedenceMinutes': number | null;
    /**
     * 
     * @type {string}
     * @memberof PublicScheduling
     */
    'antecedenceHoursReferenceTime': string | null;
    /**
     * 
     * @type {number}
     * @memberof PublicScheduling
     */
    'durationMinutes': number;
    /**
     * 
     * @type {string}
     * @memberof PublicScheduling
     */
    'appliesAt': string;
}
/**
 * 
 * @export
 * @interface Recurrence
 */
export interface Recurrence {
    /**
     * 
     * @type {number}
     * @memberof Recurrence
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof Recurrence
     */
    'type': RecurrenceTypeEnum;
    /**
     * 
     * @type {Array<RecurrenceWeekdayEnum>}
     * @memberof Recurrence
     */
    'weekdays'?: Array<RecurrenceWeekdayEnum>;
    /**
     * 
     * @type {string}
     * @memberof Recurrence
     */
    'endDate': string;
}

export const RecurrenceTypeEnum = {
    Days: 'days',
    Weeks: 'weeks'
} as const;

export type RecurrenceTypeEnum = typeof RecurrenceTypeEnum[keyof typeof RecurrenceTypeEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const RecurrenceWeekdayEnum = {
    Sunday: 'sunday',
    Monday: 'monday',
    Tuesday: 'tuesday',
    Wednesday: 'wednesday',
    Thursday: 'thursday',
    Friday: 'friday',
    Saturday: 'saturday'
} as const;

export type RecurrenceWeekdayEnum = typeof RecurrenceWeekdayEnum[keyof typeof RecurrenceWeekdayEnum];


/**
 * 
 * @export
 * @interface RefundRequest
 */
export interface RefundRequest {
    /**
     * 
     * @type {string}
     * @memberof RefundRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RefundRequest
     */
    'saleId': string;
    /**
     * 
     * @type {Sale}
     * @memberof RefundRequest
     */
    'sale'?: Sale;
    /**
     * 
     * @type {RefundRequestStatus}
     * @memberof RefundRequest
     */
    'status': RefundRequestStatus;
    /**
     * 
     * @type {string}
     * @memberof RefundRequest
     */
    'observation': string | null;
    /**
     * 
     * @type {string}
     * @memberof RefundRequest
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof RefundRequest
     */
    'updatedAt': string;
}


/**
 * 
 * @export
 * @interface RefundRequestInsights
 */
export interface RefundRequestInsights {
    /**
     * 
     * @type {string}
     * @memberof RefundRequestInsights
     */
    'saleId': string;
    /**
     * 
     * @type {boolean}
     * @memberof RefundRequestInsights
     */
    'isCreationDateInsidePeriod': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RefundRequestInsights
     */
    'hasOpenRefundRequests': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RefundRequestInsights
     */
    'hasConsumedVouchers': boolean;
}
/**
 * 
 * @export
 * @interface RefundRequestParams
 */
export interface RefundRequestParams {
    /**
     * 
     * @type {string}
     * @memberof RefundRequestParams
     */
    'observation'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RefundRequestStatus = {
    Pending: 'pending',
    Approved: 'approved',
    Denied: 'denied'
} as const;

export type RefundRequestStatus = typeof RefundRequestStatus[keyof typeof RefundRequestStatus];


/**
 * 
 * @export
 * @interface RequestAttempt
 */
export interface RequestAttempt {
    /**
     * 
     * @type {string}
     * @memberof RequestAttempt
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof RequestAttempt
     */
    'httpStatus': number | null;
    /**
     * 
     * @type {string}
     * @memberof RequestAttempt
     */
    'response': string | null;
}
/**
 * 
 * @export
 * @interface RescheduleReservationParams
 */
export interface RescheduleReservationParams {
    /**
     * 
     * @type {string}
     * @memberof RescheduleReservationParams
     */
    'scheduleDate': string;
    /**
     * 
     * @type {string}
     * @memberof RescheduleReservationParams
     */
    'scheduleTime'?: string | null;
    /**
     * 
     * @type {RescheduleReservationVerifications}
     * @memberof RescheduleReservationParams
     */
    'verifications'?: RescheduleReservationVerifications;
}
/**
 * 
 * @export
 * @interface RescheduleReservationVerifications
 */
export interface RescheduleReservationVerifications {
    /**
     * 
     * @type {boolean}
     * @memberof RescheduleReservationVerifications
     */
    'ignoreSchedulings'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RescheduleReservationVerifications
     */
    'ignoreAvailabilities'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RescheduleReservationVerifications
     */
    'ignoreResources'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RescheduleReservationVerifications
     */
    'ignoreSchedulingAntecedence'?: boolean;
}
/**
 * 
 * @export
 * @interface RescheduleScheduling
 */
export interface RescheduleScheduling {
    /**
     * 
     * @type {string}
     * @memberof RescheduleScheduling
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RescheduleScheduling
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof RescheduleScheduling
     */
    'consumableFromTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RescheduleScheduling
     */
    'consumableToTime'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RescheduleScheduling
     */
    'calendarIntervalMinutes'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RescheduleScheduling
     */
    'durationMinutes': number;
    /**
     * 
     * @type {number}
     * @memberof RescheduleScheduling
     * @deprecated
     */
    'antecedenceHours'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RescheduleScheduling
     */
    'antecedenceMinutes'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RescheduleScheduling
     */
    'antecedenceHoursReferenceTime'?: string | null;
    /**
     * 
     * @type {Array<ScheduleTime>}
     * @memberof RescheduleScheduling
     */
    'availableScheduleTimes'?: Array<ScheduleTime> | null;
    /**
     * 
     * @type {Array<RescheduleSchedulingAvailability>}
     * @memberof RescheduleScheduling
     */
    'scheduleAvailabilities': Array<RescheduleSchedulingAvailability>;
}
/**
 * 
 * @export
 * @interface RescheduleSchedulingAvailability
 */
export interface RescheduleSchedulingAvailability {
    /**
     * 
     * @type {string}
     * @memberof RescheduleSchedulingAvailability
     */
    'time': string | null;
    /**
     * 
     * @type {number}
     * @memberof RescheduleSchedulingAvailability
     */
    'minutes': number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RescheduleSchedulingAvailability
     */
    'available': boolean;
}
/**
 * 
 * @export
 * @interface Reservation
 */
export interface Reservation {
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    'productId': string;
    /**
     * 
     * @type {Product}
     * @memberof Reservation
     */
    'product'?: Product;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    'saleId': string | null;
    /**
     * 
     * @type {Sale}
     * @memberof Reservation
     */
    'sale'?: Sale | null;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    'scheduleDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    'scheduleTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface ReservationTariff
 */
export interface ReservationTariff {
    /**
     * 
     * @type {string}
     * @memberof ReservationTariff
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ReservationTariff
     */
    'tariffId': string;
    /**
     * 
     * @type {Tariff}
     * @memberof ReservationTariff
     */
    'tariff'?: Tariff;
    /**
     * 
     * @type {number}
     * @memberof ReservationTariff
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof ReservationTariff
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ReservationTariff
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface Resource
 */
export interface Resource {
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'code': string | null;
    /**
     * 
     * @type {number}
     * @memberof Resource
     */
    'capacity': number;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface ResourceCategory
 */
export interface ResourceCategory {
    /**
     * 
     * @type {string}
     * @memberof ResourceCategory
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceCategory
     */
    'name': string;
    /**
     * 
     * @type {ChargeTypeEnum}
     * @memberof ResourceCategory
     */
    'completeCapacityChargeType'?: ChargeTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ResourceCategory
     */
    'minCapacityUnitCents'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceCategory
     */
    'allowUnderAllocation': boolean;
    /**
     * 
     * @type {number}
     * @memberof ResourceCategory
     */
    'minCapacityPercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ResourceCategory
     */
    'tariffPercentage'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceCategory
     */
    'chargeTypeTariffReferenceId'?: string | null;
    /**
     * 
     * @type {Tariff}
     * @memberof ResourceCategory
     */
    'chargeTypeTariffReference'?: Tariff | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceCategory
     */
    'color': string;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceCategory
     */
    'blocked': boolean;
}


/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof Role
     */
    'isManaged': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RoundingMethod = {
    Round: 'round',
    Ceil: 'ceil',
    Floor: 'floor'
} as const;

export type RoundingMethod = typeof RoundingMethod[keyof typeof RoundingMethod];


/**
 * 
 * @export
 * @interface Sale
 */
export interface Sale {
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    'appId': string;
    /**
     * 
     * @type {number}
     * @memberof Sale
     */
    'position': number;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    'code': string | null;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    'customerId': string;
    /**
     * 
     * @type {Customer}
     * @memberof Sale
     */
    'customer'?: Customer;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    'originId': string | null;
    /**
     * 
     * @type {SaleOrigin}
     * @memberof Sale
     */
    'origin'?: SaleOrigin | null;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    'orderId': string | null;
    /**
     * 
     * @type {SharedOrder}
     * @memberof Sale
     */
    'order'?: SharedOrder | null;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    'decisiveTransactionId'?: string | null;
    /**
     * 
     * @type {DecisiveTransaction}
     * @memberof Sale
     */
    'decisiveTransaction'?: DecisiveTransaction | null;
    /**
     * 
     * @type {SaleStateEnum}
     * @memberof Sale
     */
    'currentState': SaleStateEnum;
    /**
     * 
     * @type {number}
     * @memberof Sale
     */
    'amountCents': number;
    /**
     * 
     * @type {number}
     * @memberof Sale
     */
    'partialAmountCents': number;
    /**
     * 
     * @type {SaleCoupon}
     * @memberof Sale
     */
    'coupon'?: SaleCoupon | null;
    /**
     * 
     * @type {SaleAggregatedVouchers}
     * @memberof Sale
     */
    'aggregatedVouchers'?: SaleAggregatedVouchers;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Sale
     */
    'tags'?: Array<Tag>;
    /**
     * 
     * @type {SaleAggregatedRefundRequests}
     * @memberof Sale
     */
    'aggregatedRefundRequests'?: SaleAggregatedRefundRequests;
    /**
     * 
     * @type {boolean}
     * @memberof Sale
     */
    'isExternal': boolean;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    'directLinkId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Sale
     */
    'attributes': Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    'agentId'?: string | null;
    /**
     * 
     * @type {MarketingData}
     * @memberof Sale
     */
    'marketingData': MarketingData | null;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    'expiresAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    'createdAt': string;
}


/**
 * 
 * @export
 * @interface SaleAdditional
 */
export interface SaleAdditional {
    /**
     * 
     * @type {string}
     * @memberof SaleAdditional
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SaleAdditional
     */
    'saleItemId': string;
    /**
     * 
     * @type {string}
     * @memberof SaleAdditional
     */
    'additionalId': string;
    /**
     * 
     * @type {Additional}
     * @memberof SaleAdditional
     */
    'additional'?: Additional;
    /**
     * 
     * @type {number}
     * @memberof SaleAdditional
     */
    'unitPriceCents': number;
    /**
     * 
     * @type {number}
     * @memberof SaleAdditional
     */
    'unitQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof SaleAdditional
     */
    'priceCents': number;
    /**
     * 
     * @type {SaleAdditionalData}
     * @memberof SaleAdditional
     */
    'data': SaleAdditionalData;
    /**
     * 
     * @type {string}
     * @memberof SaleAdditional
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface SaleAdditionalData
 */
export interface SaleAdditionalData {
    /**
     * 
     * @type {string}
     * @memberof SaleAdditionalData
     */
    'inputLabel': string | null;
    /**
     * 
     * @type {string}
     * @memberof SaleAdditionalData
     */
    'inputValue': string | null;
}
/**
 * 
 * @export
 * @interface SaleAdditionalParams
 */
export interface SaleAdditionalParams {
    /**
     * 
     * @type {string}
     * @memberof SaleAdditionalParams
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SaleAdditionalParams
     */
    'inputValue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SaleAdditionalParams
     */
    'quantity'?: number | null;
}
/**
 * 
 * @export
 * @interface SaleAggregatedRefundRequests
 */
export interface SaleAggregatedRefundRequests {
    /**
     * 
     * @type {number}
     * @memberof SaleAggregatedRefundRequests
     */
    'open': number;
    /**
     * 
     * @type {number}
     * @memberof SaleAggregatedRefundRequests
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface SaleAggregatedVouchers
 */
export interface SaleAggregatedVouchers {
    /**
     * 
     * @type {number}
     * @memberof SaleAggregatedVouchers
     */
    'consumedVouchersCount': number;
    /**
     * 
     * @type {number}
     * @memberof SaleAggregatedVouchers
     */
    'vouchersCount': number;
}
/**
 * 
 * @export
 * @interface SaleCoupon
 */
export interface SaleCoupon {
    /**
     * 
     * @type {string}
     * @memberof SaleCoupon
     */
    'code': string;
    /**
     * 
     * @type {number}
     * @memberof SaleCoupon
     */
    'discountAmountCents'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleCoupon
     */
    'discountPercentage': number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleCoupon
     */
    'fullAmountCents': number;
    /**
     * 
     * @type {number}
     * @memberof SaleCoupon
     */
    'discountedAmountCents': number;
    /**
     * 
     * @type {CouponUsageType}
     * @memberof SaleCoupon
     */
    'usageType': CouponUsageType;
    /**
     * 
     * @type {CouponType}
     * @memberof SaleCoupon
     */
    'type': CouponType;
    /**
     * 
     * @type {Array<BasicSaleCouponProduct>}
     * @memberof SaleCoupon
     */
    'products'?: Array<BasicSaleCouponProduct> | null;
}


/**
 * 
 * @export
 * @interface SaleItem
 */
export interface SaleItem {
    /**
     * 
     * @type {string}
     * @memberof SaleItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SaleItem
     */
    'productId': string;
    /**
     * 
     * @type {Product}
     * @memberof SaleItem
     */
    'product'?: Product;
    /**
     * 
     * @type {string}
     * @memberof SaleItem
     */
    'scheduleDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaleItem
     */
    'scheduleTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaleItem
     * @deprecated
     */
    'formattedScheduleTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaleItem
     */
    'reservationId'?: string | null;
    /**
     * 
     * @type {Reservation}
     * @memberof SaleItem
     */
    'reservation'?: Reservation;
    /**
     * 
     * @type {number}
     * @memberof SaleItem
     */
    'priceCents': number;
    /**
     * 
     * @type {string}
     * @memberof SaleItem
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SaleItem
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface SaleItemParams
 */
export interface SaleItemParams {
    /**
     * 
     * @type {string}
     * @memberof SaleItemParams
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof SaleItemParams
     */
    'partnershipItemId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaleItemParams
     */
    'scheduleDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaleItemParams
     */
    'scheduleTime'?: string | null;
    /**
     * 
     * @type {Array<SaleTariffParams>}
     * @memberof SaleItemParams
     */
    'tariffs': Array<SaleTariffParams>;
    /**
     * 
     * @type {Array<SaleResourceParams>}
     * @memberof SaleItemParams
     */
    'resources'?: Array<SaleResourceParams> | null;
    /**
     * 
     * @type {boolean}
     * @memberof SaleItemParams
     */
    'autoAllocateResources'?: boolean | null;
    /**
     * 
     * @type {Array<SaleAdditionalParams>}
     * @memberof SaleItemParams
     */
    'additionals'?: Array<SaleAdditionalParams> | null;
}
/**
 * 
 * @export
 * @interface SaleOrigin
 */
export interface SaleOrigin {
    /**
     * 
     * @type {string}
     * @memberof SaleOrigin
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SaleOrigin
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SaleOrigin
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof SaleOrigin
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SaleOrigin
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface SaleResource
 */
export interface SaleResource {
    /**
     * 
     * @type {string}
     * @memberof SaleResource
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SaleResource
     */
    'saleItemId': string;
    /**
     * 
     * @type {string}
     * @memberof SaleResource
     */
    'resourceId': string;
    /**
     * 
     * @type {Resource}
     * @memberof SaleResource
     */
    'resource'?: Resource;
    /**
     * 
     * @type {number}
     * @memberof SaleResource
     */
    'priceCents': number;
    /**
     * 
     * @type {string}
     * @memberof SaleResource
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface SaleResourceParams
 */
export interface SaleResourceParams {
    /**
     * 
     * @type {string}
     * @memberof SaleResourceParams
     */
    'id': string;
    /**
     * 
     * @type {Array<SaleResourceTariffParams>}
     * @memberof SaleResourceParams
     */
    'tariffs': Array<SaleResourceTariffParams>;
}
/**
 * 
 * @export
 * @interface SaleResourceTariffParams
 */
export interface SaleResourceTariffParams {
    /**
     * 
     * @type {string}
     * @memberof SaleResourceTariffParams
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof SaleResourceTariffParams
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface SaleStateChange
 */
export interface SaleStateChange {
    /**
     * 
     * @type {string}
     * @memberof SaleStateChange
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SaleStateChange
     */
    'saleId': string;
    /**
     * 
     * @type {Sale}
     * @memberof SaleStateChange
     */
    'sale'?: Sale;
    /**
     * 
     * @type {SaleStateEnum}
     * @memberof SaleStateChange
     */
    'stateFrom': SaleStateEnum | null;
    /**
     * 
     * @type {SaleStateEnum}
     * @memberof SaleStateChange
     */
    'stateTo': SaleStateEnum;
    /**
     * 
     * @type {string}
     * @memberof SaleStateChange
     */
    'createdAt': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const SaleStateEnum = {
    Created: 'created',
    Pending: 'pending',
    Expired: 'expired',
    PaymentComplete: 'payment_complete',
    PaymentVoided: 'payment_voided',
    PaymentChargeback: 'payment_chargeback',
    Canceled: 'canceled'
} as const;

export type SaleStateEnum = typeof SaleStateEnum[keyof typeof SaleStateEnum];


/**
 * 
 * @export
 * @interface SaleTag
 */
export interface SaleTag {
    /**
     * 
     * @type {string}
     * @memberof SaleTag
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SaleTag
     */
    'tagId': string;
    /**
     * 
     * @type {string}
     * @memberof SaleTag
     */
    'saleId': string;
    /**
     * 
     * @type {Tag}
     * @memberof SaleTag
     */
    'tag': Tag;
    /**
     * 
     * @type {string}
     * @memberof SaleTag
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SaleTag
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface SaleTariff
 */
export interface SaleTariff {
    /**
     * 
     * @type {string}
     * @memberof SaleTariff
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SaleTariff
     */
    'saleItemId': string;
    /**
     * 
     * @type {string}
     * @memberof SaleTariff
     */
    'tariffId': string;
    /**
     * 
     * @type {Tariff}
     * @memberof SaleTariff
     */
    'tariff'?: Tariff;
    /**
     * 
     * @type {number}
     * @memberof SaleTariff
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof SaleTariff
     */
    'unitPriceCents': number;
    /**
     * 
     * @type {string}
     * @memberof SaleTariff
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface SaleTariffParams
 */
export interface SaleTariffParams {
    /**
     * 
     * @type {string}
     * @memberof SaleTariffParams
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof SaleTariffParams
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface ScheduleTime
 */
export interface ScheduleTime {
    /**
     * 
     * @type {string}
     * @memberof ScheduleTime
     */
    'time': string;
    /**
     * 
     * @type {number}
     * @memberof ScheduleTime
     */
    'minutes': number;
}
/**
 * 
 * @export
 * @interface Scheduling
 */
export interface Scheduling {
    /**
     * 
     * @type {string}
     * @memberof Scheduling
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Scheduling
     */
    'beginDate': string;
    /**
     * 
     * @type {string}
     * @memberof Scheduling
     */
    'consumableFromTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Scheduling
     */
    'consumableToTime'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Scheduling
     */
    'calendarIntervalMinutes'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Scheduling
     * @deprecated
     */
    'antecedenceHours'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Scheduling
     */
    'antecedenceMinutes'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Scheduling
     */
    'antecedenceHoursReferenceTime'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Scheduling
     */
    'durationMinutes': number;
    /**
     * 
     * @type {Recurrence}
     * @memberof Scheduling
     */
    'recurrence'?: Recurrence | null;
    /**
     * 
     * @type {string}
     * @memberof Scheduling
     */
    'tariffGroupId': string | null;
    /**
     * 
     * @type {TariffGroup}
     * @memberof Scheduling
     */
    'tariffGroup'?: TariffGroup | null;
    /**
     * 
     * @type {string}
     * @memberof Scheduling
     */
    'finalTariffGroupId'?: string | null;
    /**
     * 
     * @type {TariffGroup}
     * @memberof Scheduling
     */
    'finalTariffGroup'?: TariffGroup | null;
    /**
     * 
     * @type {string}
     * @memberof Scheduling
     */
    'productId': string;
    /**
     * 
     * @type {Product}
     * @memberof Scheduling
     */
    'product'?: Product;
    /**
     * 
     * @type {string}
     * @memberof Scheduling
     */
    'priceModifierId': string | null;
    /**
     * 
     * @type {AggregatedRecurrenceCount}
     * @memberof Scheduling
     */
    'aggregatedRecurrenceCount'?: AggregatedRecurrenceCount;
    /**
     * 
     * @type {string}
     * @memberof Scheduling
     */
    'appliesAt': string;
    /**
     * 
     * @type {string}
     * @memberof Scheduling
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Scheduling
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface SchedulingDatePreview
 */
export interface SchedulingDatePreview {
    /**
     * 
     * @type {string}
     * @memberof SchedulingDatePreview
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof SchedulingDatePreview
     */
    'total': number;
    /**
     * 
     * @type {Array<Scheduling>}
     * @memberof SchedulingDatePreview
     */
    'schedulings': Array<Scheduling>;
}
/**
 * 
 * @export
 * @interface SeatMapImage
 */
export interface SeatMapImage {
    /**
     * 
     * @type {string}
     * @memberof SeatMapImage
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SeatMapImage
     */
    'alternativeText'?: string;
    /**
     * 
     * @type {Array<ImageStyle>}
     * @memberof SeatMapImage
     */
    'styles'?: Array<ImageStyle>;
}
/**
 * 
 * @export
 * @interface SeatMapOverview
 */
export interface SeatMapOverview {
    /**
     * 
     * @type {string}
     * @memberof SeatMapOverview
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SeatMapOverview
     */
    'name': string;
    /**
     * 
     * @type {Array<SeatMapOverviewArea>}
     * @memberof SeatMapOverview
     */
    'areas': Array<SeatMapOverviewArea>;
}
/**
 * 
 * @export
 * @interface SeatMapOverviewArea
 */
export interface SeatMapOverviewArea {
    /**
     * 
     * @type {string}
     * @memberof SeatMapOverviewArea
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SeatMapOverviewArea
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof SeatMapOverviewArea
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof SeatMapOverviewArea
     */
    'height': number;
    /**
     * 
     * @type {SeatMapImage}
     * @memberof SeatMapOverviewArea
     */
    'backgroundImage'?: SeatMapImage;
    /**
     * 
     * @type {Array<SeatMapOverviewSlot>}
     * @memberof SeatMapOverviewArea
     */
    'slots': Array<SeatMapOverviewSlot>;
}
/**
 * 
 * @export
 * @interface SeatMapOverviewSlot
 */
export interface SeatMapOverviewSlot {
    /**
     * 
     * @type {string}
     * @memberof SeatMapOverviewSlot
     */
    'id': string;
    /**
     * 
     * @type {SlotTypeEnum}
     * @memberof SeatMapOverviewSlot
     */
    'type': SlotTypeEnum;
    /**
     * 
     * @type {ShapeTypeEnum}
     * @memberof SeatMapOverviewSlot
     */
    'shapeType': ShapeTypeEnum;
    /**
     * 
     * @type {SeatMapSlotRect}
     * @memberof SeatMapOverviewSlot
     */
    'rect'?: SeatMapSlotRect;
    /**
     * 
     * @type {SeatMapSlotCircle}
     * @memberof SeatMapOverviewSlot
     */
    'circle'?: SeatMapSlotCircle;
    /**
     * 
     * @type {CategorizedResource}
     * @memberof SeatMapOverviewSlot
     */
    'resource'?: CategorizedResource;
}


/**
 * 
 * @export
 * @interface SeatMapSlotCircle
 */
export interface SeatMapSlotCircle {
    /**
     * 
     * @type {number}
     * @memberof SeatMapSlotCircle
     */
    'top': number;
    /**
     * 
     * @type {number}
     * @memberof SeatMapSlotCircle
     */
    'left': number;
    /**
     * 
     * @type {number}
     * @memberof SeatMapSlotCircle
     */
    'radius': number;
}
/**
 * 
 * @export
 * @interface SeatMapSlotRect
 */
export interface SeatMapSlotRect {
    /**
     * 
     * @type {number}
     * @memberof SeatMapSlotRect
     */
    'top': number;
    /**
     * 
     * @type {number}
     * @memberof SeatMapSlotRect
     */
    'left': number;
    /**
     * 
     * @type {number}
     * @memberof SeatMapSlotRect
     */
    'angle': number;
    /**
     * 
     * @type {number}
     * @memberof SeatMapSlotRect
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof SeatMapSlotRect
     */
    'height': number;
}
/**
 * 
 * @export
 * @interface SecureRescheduleReservationParams
 */
export interface SecureRescheduleReservationParams {
    /**
     * 
     * @type {string}
     * @memberof SecureRescheduleReservationParams
     */
    'scheduleDate': string;
    /**
     * 
     * @type {string}
     * @memberof SecureRescheduleReservationParams
     */
    'scheduleTime'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SellingMode = {
    WithDateAndTime: 'with_date_and_time',
    WithDateOnly: 'with_date_only',
    WithoutDate: 'without_date'
} as const;

export type SellingMode = typeof SellingMode[keyof typeof SellingMode];


/**
 * 
 * @export
 * @interface SendVoucherParams
 */
export interface SendVoucherParams {
    /**
     * 
     * @type {boolean}
     * @memberof SendVoucherParams
     */
    'email'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SendVoucherParams
     */
    'sms'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ShapeTypeEnum = {
    Rect: 'rect',
    Circle: 'circle'
} as const;

export type ShapeTypeEnum = typeof ShapeTypeEnum[keyof typeof ShapeTypeEnum];


/**
 * 
 * @export
 * @interface SharedOrder
 */
export interface SharedOrder {
    /**
     * 
     * @type {string}
     * @memberof SharedOrder
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SharedOrder
     */
    'appId': string;
    /**
     * 
     * @type {string}
     * @memberof SharedOrder
     */
    'code': string;
    /**
     * 
     * @type {SharedTransaction}
     * @memberof SharedOrder
     */
    'decisiveTransaction'?: SharedTransaction | null;
    /**
     * 
     * @type {string}
     * @memberof SharedOrder
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface SharedPaymentMethod
 */
export interface SharedPaymentMethod {
    /**
     * 
     * @type {PaymentMethodCode}
     * @memberof SharedPaymentMethod
     */
    'code': PaymentMethodCode;
}


/**
 * 
 * @export
 * @interface SharedTransaction
 */
export interface SharedTransaction {
    /**
     * 
     * @type {number}
     * @memberof SharedTransaction
     */
    'installments': number;
    /**
     * 
     * @type {SharedPaymentMethod}
     * @memberof SharedTransaction
     */
    'paymentMethod'?: SharedPaymentMethod;
    /**
     * 
     * @type {string}
     * @memberof SharedTransaction
     */
    'brand'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SharedTransaction
     */
    'paymentMethodInterestRatePercentage': number;
    /**
     * 
     * @type {number}
     * @memberof SharedTransaction
     */
    'paymentMethodInterestRateCents': number;
    /**
     * 
     * @type {number}
     * @memberof SharedTransaction
     */
    'paymentMethodDiscountPercentage': number | null;
}
/**
 * 
 * @export
 * @interface SignUpParams
 */
export interface SignUpParams {
    /**
     * 
     * @type {string}
     * @memberof SignUpParams
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpParams
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpParams
     */
    'password': string;
    /**
     * 
     * @type {boolean}
     * @memberof SignUpParams
     */
    'acceptTerms': boolean;
}
/**
 * 
 * @export
 * @interface SimpleProduct
 */
export interface SimpleProduct {
    /**
     * 
     * @type {string}
     * @memberof SimpleProduct
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleProduct
     */
    'name': string;
    /**
     * 
     * @type {Image}
     * @memberof SimpleProduct
     */
    'thumbnail'?: Image;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SlotTypeEnum = {
    Resource: 'resource'
} as const;

export type SlotTypeEnum = typeof SlotTypeEnum[keyof typeof SlotTypeEnum];


/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'updatedAt': string | null;
}
/**
 * 
 * @export
 * @interface Tariff
 */
export interface Tariff {
    /**
     * 
     * @type {string}
     * @memberof Tariff
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Tariff
     */
    'typeId': string;
    /**
     * 
     * @type {TariffType}
     * @memberof Tariff
     */
    'type'?: TariffType;
    /**
     * 
     * @type {string}
     * @memberof Tariff
     */
    'productId': string;
    /**
     * 
     * @type {Product}
     * @memberof Tariff
     */
    'product'?: Product;
    /**
     * 
     * @type {string}
     * @memberof Tariff
     */
    'groupId'?: string;
    /**
     * 
     * @type {TariffGroup}
     * @memberof Tariff
     */
    'group'?: TariffGroup;
    /**
     * 
     * @type {number}
     * @memberof Tariff
     */
    'priceCents': number;
    /**
     * 
     * @type {number}
     * @memberof Tariff
     */
    'oldPriceCents'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Tariff
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Tariff
     */
    'isDependent': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Tariff
     */
    'isDefault': boolean;
    /**
     * 
     * @type {Array<CustomData>}
     * @memberof Tariff
     */
    'customData'?: Array<CustomData>;
    /**
     * 
     * @type {string}
     * @memberof Tariff
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Tariff
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Tariff
     */
    'deletedAt': string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TariffDisplayOrder = {
    Alphabetical: 'alphabetical',
    CreatedDate: 'created_date',
    LowerPrice: 'lower_price',
    HigherPrice: 'higher_price'
} as const;

export type TariffDisplayOrder = typeof TariffDisplayOrder[keyof typeof TariffDisplayOrder];


/**
 * 
 * @export
 * @interface TariffGroup
 */
export interface TariffGroup {
    /**
     * 
     * @type {string}
     * @memberof TariffGroup
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TariffGroup
     */
    'productId': string;
    /**
     * 
     * @type {Product}
     * @memberof TariffGroup
     */
    'product'?: Product;
    /**
     * 
     * @type {string}
     * @memberof TariffGroup
     */
    'seatMapId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TariffGroup
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof TariffGroup
     */
    'isDefault': boolean;
    /**
     * 
     * @type {TariffGroupPriceInfo}
     * @memberof TariffGroup
     */
    'priceInfo'?: TariffGroupPriceInfo;
    /**
     * 
     * @type {string}
     * @memberof TariffGroup
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof TariffGroup
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof TariffGroup
     */
    'deletedAt': string | null;
}
/**
 * 
 * @export
 * @interface TariffGroupParams
 */
export interface TariffGroupParams {
    /**
     * 
     * @type {string}
     * @memberof TariffGroupParams
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface TariffGroupPriceInfo
 */
export interface TariffGroupPriceInfo {
    /**
     * 
     * @type {number}
     * @memberof TariffGroupPriceInfo
     */
    'defaultPriceCents': number;
    /**
     * 
     * @type {number}
     * @memberof TariffGroupPriceInfo
     */
    'maxPriceCents': number;
    /**
     * 
     * @type {number}
     * @memberof TariffGroupPriceInfo
     */
    'minPriceCents': number;
}
/**
 * 
 * @export
 * @interface TariffParams
 */
export interface TariffParams {
    /**
     * 
     * @type {string}
     * @memberof TariffParams
     */
    'tariffTypeId': string;
    /**
     * 
     * @type {number}
     * @memberof TariffParams
     */
    'priceCents': number;
    /**
     * 
     * @type {boolean}
     * @memberof TariffParams
     */
    'isDependent': boolean;
    /**
     * 
     * @type {number}
     * @memberof TariffParams
     */
    'oldPriceCents'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TariffParams
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface TariffQuantity
 */
export interface TariffQuantity {
    /**
     * 
     * @type {string}
     * @memberof TariffQuantity
     */
    'tariffId': string;
    /**
     * 
     * @type {OccupationInfo}
     * @memberof TariffQuantity
     */
    'occupation': OccupationInfo;
}
/**
 * 
 * @export
 * @interface TariffScheduleUnitPrice
 */
export interface TariffScheduleUnitPrice {
    /**
     * 
     * @type {string}
     * @memberof TariffScheduleUnitPrice
     */
    'time': string | null;
    /**
     * 
     * @type {Array<TariffUnitPrice>}
     * @memberof TariffScheduleUnitPrice
     */
    'tariffs': Array<TariffUnitPrice>;
}
/**
 * 
 * @export
 * @interface TariffType
 */
export interface TariffType {
    /**
     * 
     * @type {string}
     * @memberof TariffType
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TariffType
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TariffType
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof TariffType
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface TariffTypeParams
 */
export interface TariffTypeParams {
    /**
     * 
     * @type {string}
     * @memberof TariffTypeParams
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface TariffUnitPrice
 */
export interface TariffUnitPrice {
    /**
     * 
     * @type {string}
     * @memberof TariffUnitPrice
     */
    'tariffId': string;
    /**
     * 
     * @type {number}
     * @memberof TariffUnitPrice
     */
    'priceCents': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TransactableTypeEnum = {
    Sales: 'sales',
    Payments: 'payments',
    DirectLinks: 'direct_links'
} as const;

export type TransactableTypeEnum = typeof TransactableTypeEnum[keyof typeof TransactableTypeEnum];


/**
 * 
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    'amountCents': number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    'installments': number;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'paymentMethodId': string;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof Transaction
     */
    'paymentMethod'?: PaymentMethod;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'paymentGatewayId': string;
    /**
     * 
     * @type {PaymentGateway}
     * @memberof Transaction
     */
    'paymentGateway'?: PaymentGateway;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'saleId': string | null;
    /**
     * 
     * @type {Sale}
     * @memberof Transaction
     */
    'sale'?: Sale | null;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'paymentId': string | null;
    /**
     * 
     * @type {Payment}
     * @memberof Transaction
     */
    'payment'?: Payment | null;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'directLinkId': string | null;
    /**
     * 
     * @type {DirectLink}
     * @memberof Transaction
     */
    'directLink'?: DirectLink | null;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'orderId': string | null;
    /**
     * 
     * @type {SharedOrder}
     * @memberof Transaction
     */
    'order'?: SharedOrder | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TransactionNormalizedStatus = {
    Aborted: 'Aborted',
    Confirmed: 'Confirmed',
    Denied: 'Denied',
    Failed: 'Failed',
    Ignored: 'Ignored',
    Pending: 'Pending',
    Refunded: 'Refunded'
} as const;

export type TransactionNormalizedStatus = typeof TransactionNormalizedStatus[keyof typeof TransactionNormalizedStatus];


/**
 * 
 * @export
 * @interface TransactionPix
 */
export interface TransactionPix {
    /**
     * 
     * @type {string}
     * @memberof TransactionPix
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof TransactionPix
     */
    'qrCode': string;
    /**
     * 
     * @type {TransactionReceiverInfo}
     * @memberof TransactionPix
     */
    'receiverInfo': TransactionReceiverInfo;
}
/**
 * 
 * @export
 * @interface TransactionReceiverInfo
 */
export interface TransactionReceiverInfo {
    /**
     * 
     * @type {string}
     * @memberof TransactionReceiverInfo
     */
    'financialInstitution': string;
    /**
     * 
     * @type {string}
     * @memberof TransactionReceiverInfo
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof TransactionReceiverInfo
     */
    'cnpj': string;
}
/**
 * 
 * @export
 * @interface TransactionRequest
 */
export interface TransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof TransactionRequest
     */
    'paymentMethodId': string;
    /**
     * 
     * @type {string}
     * @memberof TransactionRequest
     */
    'returnUrl'?: string;
    /**
     * 
     * @type {PaymentCardParams}
     * @memberof TransactionRequest
     */
    'paymentCard'?: PaymentCardParams;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof TransactionRequest
     */
    'externalAuthentication'?: { [key: string]: any; };
    /**
     * 
     * @type {DeviceInfoParams}
     * @memberof TransactionRequest
     */
    'deviceInfo': DeviceInfoParams;
}
/**
 * 
 * @export
 * @interface UpdateAppInfoAddress
 */
export interface UpdateAppInfoAddress {
    /**
     * 
     * @type {string}
     * @memberof UpdateAppInfoAddress
     */
    'zipCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppInfoAddress
     */
    'uf'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppInfoAddress
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppInfoAddress
     */
    'district'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppInfoAddress
     */
    'street'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppInfoAddress
     */
    'number'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppInfoAddress
     */
    'complement'?: string;
}
/**
 * 
 * @export
 * @interface UpdateAppInfoBank
 */
export interface UpdateAppInfoBank {
    /**
     * 
     * @type {string}
     * @memberof UpdateAppInfoBank
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppInfoBank
     */
    'agency'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppInfoBank
     */
    'account'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppInfoBank
     */
    'identifier'?: string;
    /**
     * 
     * @type {IdentifierType}
     * @memberof UpdateAppInfoBank
     */
    'identifierType'?: IdentifierType;
    /**
     * 
     * @type {BankAccountType}
     * @memberof UpdateAppInfoBank
     */
    'accountType'?: BankAccountType;
}


/**
 * 
 * @export
 * @interface UpdateAppInfoContact
 */
export interface UpdateAppInfoContact {
    /**
     * 
     * @type {string}
     * @memberof UpdateAppInfoContact
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppInfoContact
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface UpdateAppInfoOwner
 */
export interface UpdateAppInfoOwner {
    /**
     * 
     * @type {string}
     * @memberof UpdateAppInfoOwner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppInfoOwner
     */
    'birthdate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppInfoOwner
     */
    'cpf'?: string;
}
/**
 * 
 * @export
 * @interface UpdateAppParams
 */
export interface UpdateAppParams {
    /**
     * 
     * @type {string}
     * @memberof UpdateAppParams
     */
    'identifier'?: string;
    /**
     * 
     * @type {IdentifierType}
     * @memberof UpdateAppParams
     */
    'identifierType'?: IdentifierType;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppParams
     */
    'fantasyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppParams
     */
    'corporateName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppParams
     */
    'openedAt'?: string;
    /**
     * 
     * @type {UpdateAppInfoContact}
     * @memberof UpdateAppParams
     */
    'contact'?: UpdateAppInfoContact;
    /**
     * 
     * @type {UpdateAppInfoBank}
     * @memberof UpdateAppParams
     */
    'bank'?: UpdateAppInfoBank;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppParams
     */
    'merchantCategoryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppParams
     */
    'establishmentType'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppParams
     */
    'establishmentTypeId'?: string;
    /**
     * 
     * @type {UpdateAppInfoOwner}
     * @memberof UpdateAppParams
     */
    'owner'?: UpdateAppInfoOwner;
    /**
     * 
     * @type {UpdateAppInfoAddress}
     * @memberof UpdateAppParams
     */
    'address'?: UpdateAppInfoAddress;
    /**
     * 
     * @type {number}
     * @memberof UpdateAppParams
     */
    'expectedMonthlyRevenueCents'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAppParams
     */
    'submitForAnalysis': boolean;
}


/**
 * 
 * @export
 * @interface UpdateArrangedCouponParams
 */
export interface UpdateArrangedCouponParams {
    /**
     * 
     * @type {string}
     * @memberof UpdateArrangedCouponParams
     */
    'code': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateArrangedCouponParams
     */
    'discountPercentage': number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateArrangedCouponParams
     */
    'isPartnershipDefault': boolean;
}
/**
 * 
 * @export
 * @interface UpdateArrangedPartnershipParams
 */
export interface UpdateArrangedPartnershipParams {
    /**
     * 
     * @type {string}
     * @memberof UpdateArrangedPartnershipParams
     */
    'affiliationCode': string;
}
/**
 * 
 * @export
 * @interface UpdateAvailabilityParams
 */
export interface UpdateAvailabilityParams {
    /**
     * 
     * @type {number}
     * @memberof UpdateAvailabilityParams
     */
    'quantity'?: number;
    /**
     * 
     * @type {UpdateRuleEnum}
     * @memberof UpdateAvailabilityParams
     */
    'rule': UpdateRuleEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAvailabilityParams
     */
    'ruleUntilDate'?: string | null;
}


/**
 * 
 * @export
 * @interface UpdatePartnershipParams
 */
export interface UpdatePartnershipParams {
    /**
     * 
     * @type {PartnershipStatus}
     * @memberof UpdatePartnershipParams
     */
    'status': PartnershipStatus;
    /**
     * 
     * @type {string}
     * @memberof UpdatePartnershipParams
     */
    'affiliationCode': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const UpdateRuleEnum = {
    All: 'all',
    ThisAndFuture: 'this_and_future',
    ThisAndFollowing: 'this_and_following',
    ThisOnly: 'this_only',
    ThisAndFollowingUntil: 'this_and_following_until'
} as const;

export type UpdateRuleEnum = typeof UpdateRuleEnum[keyof typeof UpdateRuleEnum];


/**
 * 
 * @export
 * @interface UpdateSchedulingParams
 */
export interface UpdateSchedulingParams {
    /**
     * 
     * @type {string}
     * @memberof UpdateSchedulingParams
     */
    'beginDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSchedulingParams
     */
    'appliesAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSchedulingParams
     */
    'tariffGroupId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateSchedulingParams
     */
    'priceModifierId'?: string | null;
    /**
     * 
     * @type {Recurrence}
     * @memberof UpdateSchedulingParams
     */
    'recurrence'?: Recurrence | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateSchedulingParams
     */
    'consumableFromTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateSchedulingParams
     */
    'consumableToTime'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateSchedulingParams
     */
    'durationMinutes'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateSchedulingParams
     */
    'calendarIntervalMinutes'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateSchedulingParams
     * @deprecated
     */
    'antecedenceHours'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateSchedulingParams
     */
    'antecedenceMinutes'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateSchedulingParams
     */
    'antecedenceHoursReferenceTime'?: string | null;
    /**
     * 
     * @type {UpdateRuleEnum}
     * @memberof UpdateSchedulingParams
     */
    'rule': UpdateRuleEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateSchedulingParams
     */
    'ruleUntilDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSchedulingParams
     */
    'overrideConflicts'?: boolean;
}


/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isSuperAdmin': boolean;
}
/**
 * 
 * @export
 * @interface UserWithToken
 */
export interface UserWithToken {
    /**
     * 
     * @type {User}
     * @memberof UserWithToken
     */
    'user': User;
    /**
     * 
     * @type {AccessToken}
     * @memberof UserWithToken
     */
    'token': AccessToken;
}
/**
 * 
 * @export
 * @interface Utm
 */
export interface Utm {
    /**
     * 
     * @type {string}
     * @memberof Utm
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof Utm
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface ValidateCouponParams
 */
export interface ValidateCouponParams {
    /**
     * 
     * @type {string}
     * @memberof ValidateCouponParams
     */
    'code': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ValidateCouponParams
     */
    'productIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ValidateCouponParams
     */
    'customerId'?: string | null;
}
/**
 * 
 * @export
 * @interface Voucher
 */
export interface Voucher {
    /**
     * 
     * @type {string}
     * @memberof Voucher
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Voucher
     */
    'productId': string;
    /**
     * 
     * @type {Product}
     * @memberof Voucher
     */
    'product'?: Product;
    /**
     * 
     * @type {string}
     * @memberof Voucher
     */
    'saleId': string;
    /**
     * 
     * @type {Sale}
     * @memberof Voucher
     */
    'sale'?: Sale;
    /**
     * 
     * @type {string}
     * @memberof Voucher
     */
    'saleItemId': string | null;
    /**
     * 
     * @type {string}
     * @memberof Voucher
     */
    'predecessorId': string | null;
    /**
     * 
     * @type {string}
     * @memberof Voucher
     */
    'scheduleDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Voucher
     */
    'scheduleTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Voucher
     * @deprecated
     */
    'formattedScheduleTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Voucher
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof Voucher
     */
    'secret': string;
    /**
     * 
     * @type {string}
     * @memberof Voucher
     */
    'barcode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Voucher
     */
    'qrCodeContent': string;
    /**
     * 
     * @type {number}
     * @memberof Voucher
     */
    'finalAmountCents': number | null;
    /**
     * 
     * @type {number}
     * @memberof Voucher
     */
    'fullAmountCents': number | null;
    /**
     * 
     * @type {Array<VoucherTariff>}
     * @memberof Voucher
     */
    'tariffs': Array<VoucherTariff>;
    /**
     * 
     * @type {Array<VoucherAdditional>}
     * @memberof Voucher
     */
    'additionals': Array<VoucherAdditional>;
    /**
     * 
     * @type {Array<VoucherResource>}
     * @memberof Voucher
     */
    'resources': Array<VoucherResource>;
    /**
     * 
     * @type {string}
     * @memberof Voucher
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Voucher
     */
    'consumedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Voucher
     */
    'revokedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Voucher
     */
    'exceptionallyConsumedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Voucher
     */
    'expiresAt'?: string | null;
}
/**
 * 
 * @export
 * @interface VoucherAdditional
 */
export interface VoucherAdditional {
    /**
     * 
     * @type {string}
     * @memberof VoucherAdditional
     * @deprecated
     */
    'additionalId': string;
    /**
     * 
     * @type {Additional}
     * @memberof VoucherAdditional
     * @deprecated
     */
    'additional'?: Additional;
    /**
     * 
     * @type {string}
     * @memberof VoucherAdditional
     */
    'saleAdditionalId': string;
    /**
     * 
     * @type {SaleAdditional}
     * @memberof VoucherAdditional
     */
    'saleAdditional'?: SaleAdditional;
    /**
     * 
     * @type {number}
     * @memberof VoucherAdditional
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof VoucherAdditional
     */
    'unitPriceCents': number;
    /**
     * 
     * @type {number}
     * @memberof VoucherAdditional
     */
    'fullPriceCents': number;
    /**
     * 
     * @type {number}
     * @memberof VoucherAdditional
     */
    'finalPriceCents': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const VoucherExpirationType = {
    Date: 'fixed_date',
    Days: 'fixed_days'
} as const;

export type VoucherExpirationType = typeof VoucherExpirationType[keyof typeof VoucherExpirationType];


/**
 * 
 * @export
 * @interface VoucherResource
 */
export interface VoucherResource {
    /**
     * 
     * @type {string}
     * @memberof VoucherResource
     */
    'saleResourceId': string;
    /**
     * 
     * @type {SaleResource}
     * @memberof VoucherResource
     */
    'saleResource'?: SaleResource;
    /**
     * 
     * @type {number}
     * @memberof VoucherResource
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof VoucherResource
     */
    'unitPriceCents': number;
    /**
     * 
     * @type {number}
     * @memberof VoucherResource
     */
    'fullPriceCents': number;
    /**
     * 
     * @type {number}
     * @memberof VoucherResource
     */
    'finalPriceCents': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const VoucherSplitPolicy = {
    Normal: 'normal',
    Unit: 'unit'
} as const;

export type VoucherSplitPolicy = typeof VoucherSplitPolicy[keyof typeof VoucherSplitPolicy];


/**
 * 
 * @export
 * @interface VoucherTariff
 */
export interface VoucherTariff {
    /**
     * 
     * @type {string}
     * @memberof VoucherTariff
     * @deprecated
     */
    'tariffId': string;
    /**
     * 
     * @type {Tariff}
     * @memberof VoucherTariff
     * @deprecated
     */
    'tariff'?: Tariff;
    /**
     * 
     * @type {string}
     * @memberof VoucherTariff
     */
    'saleTariffId': string;
    /**
     * 
     * @type {SaleTariff}
     * @memberof VoucherTariff
     */
    'saleTariff'?: SaleTariff;
    /**
     * 
     * @type {number}
     * @memberof VoucherTariff
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof VoucherTariff
     */
    'unitPriceCents': number;
    /**
     * 
     * @type {number}
     * @memberof VoucherTariff
     */
    'fullPriceCents': number;
    /**
     * 
     * @type {number}
     * @memberof VoucherTariff
     */
    'finalPriceCents': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof VoucherTariff
     */
    'discountPerUnit': Array<number>;
}
/**
 * 
 * @export
 * @interface Webhook
 */
export interface Webhook {
    /**
     * 
     * @type {string}
     * @memberof Webhook
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Webhook
     */
    'appId': string;
    /**
     * 
     * @type {string}
     * @memberof Webhook
     */
    'url': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Webhook
     */
    'headers': { [key: string]: string; } | null;
    /**
     * 
     * @type {Array<AppEventTypeEnum>}
     * @memberof Webhook
     */
    'eventTypes': Array<AppEventTypeEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof Webhook
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof Webhook
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Webhook
     */
    'updatedAt': string;
}

/**
 * AccountsApi - axios parameter creator
 * @export
 */
export const AccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<GetOwnUserAccountsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnUserAccounts: async (include?: Array<GetOwnUserAccountsIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountsApi - functional programming interface
 * @export
 */
export const AccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<GetOwnUserAccountsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOwnUserAccounts(include?: Array<GetOwnUserAccountsIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Account>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOwnUserAccounts(include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.getOwnUserAccounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AccountsApi - factory interface
 * @export
 */
export const AccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountsApiFp(configuration)
    return {
        /**
         * 
         * @param {AccountsApiGetOwnUserAccountsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnUserAccounts(requestParameters: AccountsApiGetOwnUserAccountsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<Account>> {
            return localVarFp.getOwnUserAccounts(requestParameters.include, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getOwnUserAccounts operation in AccountsApi.
 * @export
 * @interface AccountsApiGetOwnUserAccountsRequest
 */
export interface AccountsApiGetOwnUserAccountsRequest {
    /**
     * 
     * @type {Array<'app' | 'app.plan' | 'role'>}
     * @memberof AccountsApiGetOwnUserAccounts
     */
    readonly include?: Array<GetOwnUserAccountsIncludeEnum>
}

/**
 * AccountsApi - object-oriented interface
 * @export
 * @class AccountsApi
 * @extends {BaseAPI}
 */
export class AccountsApi extends BaseAPI {
    /**
     * 
     * @param {AccountsApiGetOwnUserAccountsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getOwnUserAccounts(requestParameters: AccountsApiGetOwnUserAccountsRequest = {}, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).getOwnUserAccounts(requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetOwnUserAccountsIncludeEnum = {
    App: 'app',
    AppPlan: 'app.plan',
    Role: 'role'
} as const;
export type GetOwnUserAccountsIncludeEnum = typeof GetOwnUserAccountsIncludeEnum[keyof typeof GetOwnUserAccountsIncludeEnum];


/**
 * AdditionalsApi - axios parameter creator
 * @export
 */
export const AdditionalsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} additionalId 
         * @param {Array<GetAdditionalIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdditional: async (additionalId: string, include?: Array<GetAdditionalIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'additionalId' is not null or undefined
            assertParamExists('getAdditional', 'additionalId', additionalId)
            const localVarPath = `/additionals/{additionalId}`
                .replace(`{${"additionalId"}}`, encodeURIComponent(String(additionalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdditionalsApi - functional programming interface
 * @export
 */
export const AdditionalsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdditionalsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} additionalId 
         * @param {Array<GetAdditionalIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdditional(additionalId: string, include?: Array<GetAdditionalIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Additional>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdditional(additionalId, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdditionalsApi.getAdditional']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdditionalsApi - factory interface
 * @export
 */
export const AdditionalsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdditionalsApiFp(configuration)
    return {
        /**
         * 
         * @param {AdditionalsApiGetAdditionalRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdditional(requestParameters: AdditionalsApiGetAdditionalRequest, options?: RawAxiosRequestConfig): AxiosPromise<Additional> {
            return localVarFp.getAdditional(requestParameters.additionalId, requestParameters.include, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAdditional operation in AdditionalsApi.
 * @export
 * @interface AdditionalsApiGetAdditionalRequest
 */
export interface AdditionalsApiGetAdditionalRequest {
    /**
     * 
     * @type {string}
     * @memberof AdditionalsApiGetAdditional
     */
    readonly additionalId: string

    /**
     * 
     * @type {Array<'product'>}
     * @memberof AdditionalsApiGetAdditional
     */
    readonly include?: Array<GetAdditionalIncludeEnum>
}

/**
 * AdditionalsApi - object-oriented interface
 * @export
 * @class AdditionalsApi
 * @extends {BaseAPI}
 */
export class AdditionalsApi extends BaseAPI {
    /**
     * 
     * @param {AdditionalsApiGetAdditionalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalsApi
     */
    public getAdditional(requestParameters: AdditionalsApiGetAdditionalRequest, options?: RawAxiosRequestConfig) {
        return AdditionalsApiFp(this.configuration).getAdditional(requestParameters.additionalId, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAdditionalIncludeEnum = {
    Product: 'product'
} as const;
export type GetAdditionalIncludeEnum = typeof GetAdditionalIncludeEnum[keyof typeof GetAdditionalIncludeEnum];


/**
 * AppsApi - axios parameter creator
 * @export
 */
export const AppsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAppParams} createAppParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApp: async (createAppParams: CreateAppParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAppParams' is not null or undefined
            assertParamExists('createApp', 'createAppParams', createAppParams)
            const localVarPath = `/apps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAppParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {Array<GetAppIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApp: async (appId: string, include?: Array<GetAppIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getApp', 'appId', appId)
            const localVarPath = `/apps/{appId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppInfo: async (appId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppInfo', 'appId', appId)
            const localVarPath = `/apps/{appId}/info`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bankOptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {UpdateAppParams} updateAppParams 
         * @param {Array<UpdateAppInfoIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppInfo: async (appId: string, updateAppParams: UpdateAppParams, include?: Array<UpdateAppInfoIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('updateAppInfo', 'appId', appId)
            // verify required parameter 'updateAppParams' is not null or undefined
            assertParamExists('updateAppInfo', 'updateAppParams', updateAppParams)
            const localVarPath = `/apps/{appId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAppParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppsApi - functional programming interface
 * @export
 */
export const AppsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateAppParams} createAppParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createApp(createAppParams: CreateAppParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<App>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createApp(createAppParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppsApi.createApp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {Array<GetAppIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApp(appId: string, include?: Array<GetAppIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<App>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApp(appId, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppsApi.getApp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppInfo(appId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppInfo(appId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppsApi.getAppInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BankOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBankOptions(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppsApi.getBankOptions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {UpdateAppParams} updateAppParams 
         * @param {Array<UpdateAppInfoIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAppInfo(appId: string, updateAppParams: UpdateAppParams, include?: Array<UpdateAppInfoIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAppInfo(appId, updateAppParams, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppsApi.updateAppInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AppsApi - factory interface
 * @export
 */
export const AppsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppsApiFp(configuration)
    return {
        /**
         * 
         * @param {AppsApiCreateAppRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApp(requestParameters: AppsApiCreateAppRequest, options?: RawAxiosRequestConfig): AxiosPromise<App> {
            return localVarFp.createApp(requestParameters.createAppParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AppsApiGetAppRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApp(requestParameters: AppsApiGetAppRequest, options?: RawAxiosRequestConfig): AxiosPromise<App> {
            return localVarFp.getApp(requestParameters.appId, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AppsApiGetAppInfoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppInfo(requestParameters: AppsApiGetAppInfoRequest, options?: RawAxiosRequestConfig): AxiosPromise<AppInfo> {
            return localVarFp.getAppInfo(requestParameters.appId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankOptions(options?: RawAxiosRequestConfig): AxiosPromise<Array<BankOption>> {
            return localVarFp.getBankOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AppsApiUpdateAppInfoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppInfo(requestParameters: AppsApiUpdateAppInfoRequest, options?: RawAxiosRequestConfig): AxiosPromise<AppInfo> {
            return localVarFp.updateAppInfo(requestParameters.appId, requestParameters.updateAppParams, requestParameters.include, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createApp operation in AppsApi.
 * @export
 * @interface AppsApiCreateAppRequest
 */
export interface AppsApiCreateAppRequest {
    /**
     * 
     * @type {CreateAppParams}
     * @memberof AppsApiCreateApp
     */
    readonly createAppParams: CreateAppParams
}

/**
 * Request parameters for getApp operation in AppsApi.
 * @export
 * @interface AppsApiGetAppRequest
 */
export interface AppsApiGetAppRequest {
    /**
     * 
     * @type {string}
     * @memberof AppsApiGetApp
     */
    readonly appId: string

    /**
     * 
     * @type {Array<'plan' | 'info'>}
     * @memberof AppsApiGetApp
     */
    readonly include?: Array<GetAppIncludeEnum>
}

/**
 * Request parameters for getAppInfo operation in AppsApi.
 * @export
 * @interface AppsApiGetAppInfoRequest
 */
export interface AppsApiGetAppInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof AppsApiGetAppInfo
     */
    readonly appId: string
}

/**
 * Request parameters for updateAppInfo operation in AppsApi.
 * @export
 * @interface AppsApiUpdateAppInfoRequest
 */
export interface AppsApiUpdateAppInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof AppsApiUpdateAppInfo
     */
    readonly appId: string

    /**
     * 
     * @type {UpdateAppParams}
     * @memberof AppsApiUpdateAppInfo
     */
    readonly updateAppParams: UpdateAppParams

    /**
     * 
     * @type {Array<'plan' | 'info'>}
     * @memberof AppsApiUpdateAppInfo
     */
    readonly include?: Array<UpdateAppInfoIncludeEnum>
}

/**
 * AppsApi - object-oriented interface
 * @export
 * @class AppsApi
 * @extends {BaseAPI}
 */
export class AppsApi extends BaseAPI {
    /**
     * 
     * @param {AppsApiCreateAppRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public createApp(requestParameters: AppsApiCreateAppRequest, options?: RawAxiosRequestConfig) {
        return AppsApiFp(this.configuration).createApp(requestParameters.createAppParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AppsApiGetAppRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getApp(requestParameters: AppsApiGetAppRequest, options?: RawAxiosRequestConfig) {
        return AppsApiFp(this.configuration).getApp(requestParameters.appId, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AppsApiGetAppInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getAppInfo(requestParameters: AppsApiGetAppInfoRequest, options?: RawAxiosRequestConfig) {
        return AppsApiFp(this.configuration).getAppInfo(requestParameters.appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getBankOptions(options?: RawAxiosRequestConfig) {
        return AppsApiFp(this.configuration).getBankOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AppsApiUpdateAppInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public updateAppInfo(requestParameters: AppsApiUpdateAppInfoRequest, options?: RawAxiosRequestConfig) {
        return AppsApiFp(this.configuration).updateAppInfo(requestParameters.appId, requestParameters.updateAppParams, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAppIncludeEnum = {
    Plan: 'plan',
    Info: 'info'
} as const;
export type GetAppIncludeEnum = typeof GetAppIncludeEnum[keyof typeof GetAppIncludeEnum];
/**
 * @export
 */
export const UpdateAppInfoIncludeEnum = {
    Plan: 'plan',
    Info: 'info'
} as const;
export type UpdateAppInfoIncludeEnum = typeof UpdateAppInfoIncludeEnum[keyof typeof UpdateAppInfoIncludeEnum];


/**
 * ArrangedProvidersApi - axios parameter creator
 * @export
 */
export const ArrangedProvidersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetAppArrangedProvidersIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppArrangedProviders: async (appId: string, offset?: number, limit?: number, include?: Array<GetAppArrangedProvidersIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppArrangedProviders', 'appId', appId)
            const localVarPath = `/apps/{appId}/arrangedProviders`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArrangedProvidersApi - functional programming interface
 * @export
 */
export const ArrangedProvidersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArrangedProvidersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetAppArrangedProvidersIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppArrangedProviders(appId: string, offset?: number, limit?: number, include?: Array<GetAppArrangedProvidersIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicApp>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppArrangedProviders(appId, offset, limit, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArrangedProvidersApi.getAppArrangedProviders']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ArrangedProvidersApi - factory interface
 * @export
 */
export const ArrangedProvidersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArrangedProvidersApiFp(configuration)
    return {
        /**
         * 
         * @param {ArrangedProvidersApiGetAppArrangedProvidersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppArrangedProviders(requestParameters: ArrangedProvidersApiGetAppArrangedProvidersRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<PublicApp>> {
            return localVarFp.getAppArrangedProviders(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAppArrangedProviders operation in ArrangedProvidersApi.
 * @export
 * @interface ArrangedProvidersApiGetAppArrangedProvidersRequest
 */
export interface ArrangedProvidersApiGetAppArrangedProvidersRequest {
    /**
     * 
     * @type {string}
     * @memberof ArrangedProvidersApiGetAppArrangedProviders
     */
    readonly appId: string

    /**
     * 
     * @type {number}
     * @memberof ArrangedProvidersApiGetAppArrangedProviders
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof ArrangedProvidersApiGetAppArrangedProviders
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<'info'>}
     * @memberof ArrangedProvidersApiGetAppArrangedProviders
     */
    readonly include?: Array<GetAppArrangedProvidersIncludeEnum>
}

/**
 * ArrangedProvidersApi - object-oriented interface
 * @export
 * @class ArrangedProvidersApi
 * @extends {BaseAPI}
 */
export class ArrangedProvidersApi extends BaseAPI {
    /**
     * 
     * @param {ArrangedProvidersApiGetAppArrangedProvidersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArrangedProvidersApi
     */
    public getAppArrangedProviders(requestParameters: ArrangedProvidersApiGetAppArrangedProvidersRequest, options?: RawAxiosRequestConfig) {
        return ArrangedProvidersApiFp(this.configuration).getAppArrangedProviders(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAppArrangedProvidersIncludeEnum = {
    Info: 'info'
} as const;
export type GetAppArrangedProvidersIncludeEnum = typeof GetAppArrangedProvidersIncludeEnum[keyof typeof GetAppArrangedProvidersIncludeEnum];


/**
 * ArrangedSaleItemsApi - axios parameter creator
 * @export
 */
export const ArrangedSaleItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<SaleStateEnum>} [currentState] 
         * @param {Array<string>} [providerIds] 
         * @param {Array<string>} [partnershipProposalIds] 
         * @param {string | null} [since] 
         * @param {string | null} [until] 
         * @param {string | null} [sinceScheduleDate] 
         * @param {string | null} [untilScheduleDate] 
         * @param {GetAppArrangedSaleItemsSortByEnum} [sortBy] 
         * @param {Array<GetAppArrangedSaleItemsIncludeEnum>} [include] 
         * @param {Array<AssociationModel>} [associationModels] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppArrangedSaleItems: async (appId: string, offset?: number, limit?: number, currentState?: Array<SaleStateEnum>, providerIds?: Array<string>, partnershipProposalIds?: Array<string>, since?: string | null, until?: string | null, sinceScheduleDate?: string | null, untilScheduleDate?: string | null, sortBy?: GetAppArrangedSaleItemsSortByEnum, include?: Array<GetAppArrangedSaleItemsIncludeEnum>, associationModels?: Array<AssociationModel>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppArrangedSaleItems', 'appId', appId)
            const localVarPath = `/apps/{appId}/arrangedSaleItems`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (currentState) {
                localVarQueryParameter['currentState'] = currentState.join(COLLECTION_FORMATS.csv);
            }

            if (providerIds) {
                localVarQueryParameter['providerIds'] = providerIds.join(COLLECTION_FORMATS.csv);
            }

            if (partnershipProposalIds) {
                localVarQueryParameter['partnershipProposalIds'] = partnershipProposalIds.join(COLLECTION_FORMATS.csv);
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString() :
                    since;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = (until as any instanceof Date) ?
                    (until as any).toISOString() :
                    until;
            }

            if (sinceScheduleDate !== undefined) {
                localVarQueryParameter['sinceScheduleDate'] = (sinceScheduleDate as any instanceof Date) ?
                    (sinceScheduleDate as any).toISOString() :
                    sinceScheduleDate;
            }

            if (untilScheduleDate !== undefined) {
                localVarQueryParameter['untilScheduleDate'] = (untilScheduleDate as any instanceof Date) ?
                    (untilScheduleDate as any).toISOString() :
                    untilScheduleDate;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (associationModels) {
                localVarQueryParameter['associationModels'] = associationModels.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {Array<SaleStateEnum>} [currentState] 
         * @param {Array<string>} [providerIds] 
         * @param {Array<string>} [partnershipProposalIds] 
         * @param {string | null} [since] 
         * @param {string | null} [until] 
         * @param {string | null} [sinceScheduleDate] 
         * @param {string | null} [untilScheduleDate] 
         * @param {Array<AssociationModel>} [associationModels] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppArrangedSaleItemsAggregatedInfo: async (appId: string, currentState?: Array<SaleStateEnum>, providerIds?: Array<string>, partnershipProposalIds?: Array<string>, since?: string | null, until?: string | null, sinceScheduleDate?: string | null, untilScheduleDate?: string | null, associationModels?: Array<AssociationModel>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppArrangedSaleItemsAggregatedInfo', 'appId', appId)
            const localVarPath = `/apps/{appId}/arrangedSaleItemsAggregatedInfo`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (currentState) {
                localVarQueryParameter['currentState'] = currentState.join(COLLECTION_FORMATS.csv);
            }

            if (providerIds) {
                localVarQueryParameter['providerIds'] = providerIds.join(COLLECTION_FORMATS.csv);
            }

            if (partnershipProposalIds) {
                localVarQueryParameter['partnershipProposalIds'] = partnershipProposalIds.join(COLLECTION_FORMATS.csv);
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString() :
                    since;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = (until as any instanceof Date) ?
                    (until as any).toISOString() :
                    until;
            }

            if (sinceScheduleDate !== undefined) {
                localVarQueryParameter['sinceScheduleDate'] = (sinceScheduleDate as any instanceof Date) ?
                    (sinceScheduleDate as any).toISOString() :
                    sinceScheduleDate;
            }

            if (untilScheduleDate !== undefined) {
                localVarQueryParameter['untilScheduleDate'] = (untilScheduleDate as any instanceof Date) ?
                    (untilScheduleDate as any).toISOString() :
                    untilScheduleDate;
            }

            if (associationModels) {
                localVarQueryParameter['associationModels'] = associationModels.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArrangedSaleItemsApi - functional programming interface
 * @export
 */
export const ArrangedSaleItemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArrangedSaleItemsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<SaleStateEnum>} [currentState] 
         * @param {Array<string>} [providerIds] 
         * @param {Array<string>} [partnershipProposalIds] 
         * @param {string | null} [since] 
         * @param {string | null} [until] 
         * @param {string | null} [sinceScheduleDate] 
         * @param {string | null} [untilScheduleDate] 
         * @param {GetAppArrangedSaleItemsSortByEnum} [sortBy] 
         * @param {Array<GetAppArrangedSaleItemsIncludeEnum>} [include] 
         * @param {Array<AssociationModel>} [associationModels] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppArrangedSaleItems(appId: string, offset?: number, limit?: number, currentState?: Array<SaleStateEnum>, providerIds?: Array<string>, partnershipProposalIds?: Array<string>, since?: string | null, until?: string | null, sinceScheduleDate?: string | null, untilScheduleDate?: string | null, sortBy?: GetAppArrangedSaleItemsSortByEnum, include?: Array<GetAppArrangedSaleItemsIncludeEnum>, associationModels?: Array<AssociationModel>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ArrangedSaleItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppArrangedSaleItems(appId, offset, limit, currentState, providerIds, partnershipProposalIds, since, until, sinceScheduleDate, untilScheduleDate, sortBy, include, associationModels, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArrangedSaleItemsApi.getAppArrangedSaleItems']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {Array<SaleStateEnum>} [currentState] 
         * @param {Array<string>} [providerIds] 
         * @param {Array<string>} [partnershipProposalIds] 
         * @param {string | null} [since] 
         * @param {string | null} [until] 
         * @param {string | null} [sinceScheduleDate] 
         * @param {string | null} [untilScheduleDate] 
         * @param {Array<AssociationModel>} [associationModels] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppArrangedSaleItemsAggregatedInfo(appId: string, currentState?: Array<SaleStateEnum>, providerIds?: Array<string>, partnershipProposalIds?: Array<string>, since?: string | null, until?: string | null, sinceScheduleDate?: string | null, untilScheduleDate?: string | null, associationModels?: Array<AssociationModel>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArrangedSaleItemAggregatedInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppArrangedSaleItemsAggregatedInfo(appId, currentState, providerIds, partnershipProposalIds, since, until, sinceScheduleDate, untilScheduleDate, associationModels, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArrangedSaleItemsApi.getAppArrangedSaleItemsAggregatedInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ArrangedSaleItemsApi - factory interface
 * @export
 */
export const ArrangedSaleItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArrangedSaleItemsApiFp(configuration)
    return {
        /**
         * 
         * @param {ArrangedSaleItemsApiGetAppArrangedSaleItemsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppArrangedSaleItems(requestParameters: ArrangedSaleItemsApiGetAppArrangedSaleItemsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<ArrangedSaleItem>> {
            return localVarFp.getAppArrangedSaleItems(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.currentState, requestParameters.providerIds, requestParameters.partnershipProposalIds, requestParameters.since, requestParameters.until, requestParameters.sinceScheduleDate, requestParameters.untilScheduleDate, requestParameters.sortBy, requestParameters.include, requestParameters.associationModels, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ArrangedSaleItemsApiGetAppArrangedSaleItemsAggregatedInfoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppArrangedSaleItemsAggregatedInfo(requestParameters: ArrangedSaleItemsApiGetAppArrangedSaleItemsAggregatedInfoRequest, options?: RawAxiosRequestConfig): AxiosPromise<ArrangedSaleItemAggregatedInfo> {
            return localVarFp.getAppArrangedSaleItemsAggregatedInfo(requestParameters.appId, requestParameters.currentState, requestParameters.providerIds, requestParameters.partnershipProposalIds, requestParameters.since, requestParameters.until, requestParameters.sinceScheduleDate, requestParameters.untilScheduleDate, requestParameters.associationModels, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAppArrangedSaleItems operation in ArrangedSaleItemsApi.
 * @export
 * @interface ArrangedSaleItemsApiGetAppArrangedSaleItemsRequest
 */
export interface ArrangedSaleItemsApiGetAppArrangedSaleItemsRequest {
    /**
     * 
     * @type {string}
     * @memberof ArrangedSaleItemsApiGetAppArrangedSaleItems
     */
    readonly appId: string

    /**
     * 
     * @type {number}
     * @memberof ArrangedSaleItemsApiGetAppArrangedSaleItems
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof ArrangedSaleItemsApiGetAppArrangedSaleItems
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<SaleStateEnum>}
     * @memberof ArrangedSaleItemsApiGetAppArrangedSaleItems
     */
    readonly currentState?: Array<SaleStateEnum>

    /**
     * 
     * @type {Array<string>}
     * @memberof ArrangedSaleItemsApiGetAppArrangedSaleItems
     */
    readonly providerIds?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ArrangedSaleItemsApiGetAppArrangedSaleItems
     */
    readonly partnershipProposalIds?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof ArrangedSaleItemsApiGetAppArrangedSaleItems
     */
    readonly since?: string | null

    /**
     * 
     * @type {string}
     * @memberof ArrangedSaleItemsApiGetAppArrangedSaleItems
     */
    readonly until?: string | null

    /**
     * 
     * @type {string}
     * @memberof ArrangedSaleItemsApiGetAppArrangedSaleItems
     */
    readonly sinceScheduleDate?: string | null

    /**
     * 
     * @type {string}
     * @memberof ArrangedSaleItemsApiGetAppArrangedSaleItems
     */
    readonly untilScheduleDate?: string | null

    /**
     * 
     * @type {'recent'}
     * @memberof ArrangedSaleItemsApiGetAppArrangedSaleItems
     */
    readonly sortBy?: GetAppArrangedSaleItemsSortByEnum

    /**
     * 
     * @type {Array<'product' | 'sale' | 'partnershipItem.product' | 'partnershipItem.partnership' | 'partnershipItem.partnership.provider' | 'partnershipItem.partnership.agent' | 'partnershipItem.partnership.publicProposal' | 'decisiveTransactionValues'>}
     * @memberof ArrangedSaleItemsApiGetAppArrangedSaleItems
     */
    readonly include?: Array<GetAppArrangedSaleItemsIncludeEnum>

    /**
     * 
     * @type {Array<AssociationModel>}
     * @memberof ArrangedSaleItemsApiGetAppArrangedSaleItems
     */
    readonly associationModels?: Array<AssociationModel>
}

/**
 * Request parameters for getAppArrangedSaleItemsAggregatedInfo operation in ArrangedSaleItemsApi.
 * @export
 * @interface ArrangedSaleItemsApiGetAppArrangedSaleItemsAggregatedInfoRequest
 */
export interface ArrangedSaleItemsApiGetAppArrangedSaleItemsAggregatedInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof ArrangedSaleItemsApiGetAppArrangedSaleItemsAggregatedInfo
     */
    readonly appId: string

    /**
     * 
     * @type {Array<SaleStateEnum>}
     * @memberof ArrangedSaleItemsApiGetAppArrangedSaleItemsAggregatedInfo
     */
    readonly currentState?: Array<SaleStateEnum>

    /**
     * 
     * @type {Array<string>}
     * @memberof ArrangedSaleItemsApiGetAppArrangedSaleItemsAggregatedInfo
     */
    readonly providerIds?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ArrangedSaleItemsApiGetAppArrangedSaleItemsAggregatedInfo
     */
    readonly partnershipProposalIds?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof ArrangedSaleItemsApiGetAppArrangedSaleItemsAggregatedInfo
     */
    readonly since?: string | null

    /**
     * 
     * @type {string}
     * @memberof ArrangedSaleItemsApiGetAppArrangedSaleItemsAggregatedInfo
     */
    readonly until?: string | null

    /**
     * 
     * @type {string}
     * @memberof ArrangedSaleItemsApiGetAppArrangedSaleItemsAggregatedInfo
     */
    readonly sinceScheduleDate?: string | null

    /**
     * 
     * @type {string}
     * @memberof ArrangedSaleItemsApiGetAppArrangedSaleItemsAggregatedInfo
     */
    readonly untilScheduleDate?: string | null

    /**
     * 
     * @type {Array<AssociationModel>}
     * @memberof ArrangedSaleItemsApiGetAppArrangedSaleItemsAggregatedInfo
     */
    readonly associationModels?: Array<AssociationModel>
}

/**
 * ArrangedSaleItemsApi - object-oriented interface
 * @export
 * @class ArrangedSaleItemsApi
 * @extends {BaseAPI}
 */
export class ArrangedSaleItemsApi extends BaseAPI {
    /**
     * 
     * @param {ArrangedSaleItemsApiGetAppArrangedSaleItemsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArrangedSaleItemsApi
     */
    public getAppArrangedSaleItems(requestParameters: ArrangedSaleItemsApiGetAppArrangedSaleItemsRequest, options?: RawAxiosRequestConfig) {
        return ArrangedSaleItemsApiFp(this.configuration).getAppArrangedSaleItems(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.currentState, requestParameters.providerIds, requestParameters.partnershipProposalIds, requestParameters.since, requestParameters.until, requestParameters.sinceScheduleDate, requestParameters.untilScheduleDate, requestParameters.sortBy, requestParameters.include, requestParameters.associationModels, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ArrangedSaleItemsApiGetAppArrangedSaleItemsAggregatedInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArrangedSaleItemsApi
     */
    public getAppArrangedSaleItemsAggregatedInfo(requestParameters: ArrangedSaleItemsApiGetAppArrangedSaleItemsAggregatedInfoRequest, options?: RawAxiosRequestConfig) {
        return ArrangedSaleItemsApiFp(this.configuration).getAppArrangedSaleItemsAggregatedInfo(requestParameters.appId, requestParameters.currentState, requestParameters.providerIds, requestParameters.partnershipProposalIds, requestParameters.since, requestParameters.until, requestParameters.sinceScheduleDate, requestParameters.untilScheduleDate, requestParameters.associationModels, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAppArrangedSaleItemsSortByEnum = {
    Recent: 'recent'
} as const;
export type GetAppArrangedSaleItemsSortByEnum = typeof GetAppArrangedSaleItemsSortByEnum[keyof typeof GetAppArrangedSaleItemsSortByEnum];
/**
 * @export
 */
export const GetAppArrangedSaleItemsIncludeEnum = {
    Product: 'product',
    Sale: 'sale',
    PartnershipItemProduct: 'partnershipItem.product',
    PartnershipItemPartnership: 'partnershipItem.partnership',
    PartnershipItemPartnershipProvider: 'partnershipItem.partnership.provider',
    PartnershipItemPartnershipAgent: 'partnershipItem.partnership.agent',
    PartnershipItemPartnershipPublicProposal: 'partnershipItem.partnership.publicProposal',
    DecisiveTransactionValues: 'decisiveTransactionValues'
} as const;
export type GetAppArrangedSaleItemsIncludeEnum = typeof GetAppArrangedSaleItemsIncludeEnum[keyof typeof GetAppArrangedSaleItemsIncludeEnum];


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LoginParams} loginParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogin: async (loginParams: LoginParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginParams' is not null or undefined
            assertParamExists('authLogin', 'loginParams', loginParams)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogout: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthRecoverPasswordRequest} authRecoverPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRecoverPassword: async (authRecoverPasswordRequest: AuthRecoverPasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authRecoverPasswordRequest' is not null or undefined
            assertParamExists('authRecoverPassword', 'authRecoverPasswordRequest', authRecoverPasswordRequest)
            const localVarPath = `/auth/recoverPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authRecoverPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRefresh: async (refreshToken: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshToken' is not null or undefined
            assertParamExists('authRefresh', 'refreshToken', refreshToken)
            const localVarPath = `/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthResetPasswordRequest} authResetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authResetPassword: async (authResetPasswordRequest: AuthResetPasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authResetPasswordRequest' is not null or undefined
            assertParamExists('authResetPassword', 'authResetPasswordRequest', authResetPasswordRequest)
            const localVarPath = `/auth/resetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authResetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SignUpParams} signUpParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignup: async (signUpParams: SignUpParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signUpParams' is not null or undefined
            assertParamExists('authSignup', 'signUpParams', signUpParams)
            const localVarPath = `/auth/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signUpParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authValidateResetPasswordCode: async (code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('authValidateResetPasswordCode', 'code', code)
            const localVarPath = `/auth/passwordResetCodes/{code}/valid`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppBuyerPrivacyPolicy: async (appId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppBuyerPrivacyPolicy', 'appId', appId)
            const localVarPath = `/apps/{appId}/site/privacyPolicy`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppBuyerTermsOfUse: async (appId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppBuyerTermsOfUse', 'appId', appId)
            const localVarPath = `/apps/{appId}/site/termsOfUse`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrivacyPolicy: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/docs/privacyPolicy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTermsOfUse: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/docs/termsOfUse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {LoginParams} loginParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authLogin(loginParams: LoginParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserWithToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authLogin(loginParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authLogin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authLogout(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authLogout(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authLogout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AuthRecoverPasswordRequest} authRecoverPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authRecoverPassword(authRecoverPasswordRequest: AuthRecoverPasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserWithToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authRecoverPassword(authRecoverPasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authRecoverPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authRefresh(refreshToken: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authRefresh(refreshToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authRefresh']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AuthResetPasswordRequest} authResetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authResetPassword(authResetPasswordRequest: AuthResetPasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authResetPassword(authResetPasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authResetPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SignUpParams} signUpParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authSignup(signUpParams: SignUpParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserWithToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authSignup(signUpParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authSignup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authValidateResetPasswordCode(code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authValidateResetPasswordCode(code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authValidateResetPasswordCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppBuyerPrivacyPolicy(appId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgreementDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppBuyerPrivacyPolicy(appId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.getAppBuyerPrivacyPolicy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppBuyerTermsOfUse(appId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgreementDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppBuyerTermsOfUse(appId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.getAppBuyerTermsOfUse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrivacyPolicy(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgreementDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrivacyPolicy(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.getPrivacyPolicy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTermsOfUse(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgreementDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTermsOfUse(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.getTermsOfUse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {AuthApiAuthLoginRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogin(requestParameters: AuthApiAuthLoginRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserWithToken> {
            return localVarFp.authLogin(requestParameters.loginParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogout(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.authLogout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthApiAuthRecoverPasswordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRecoverPassword(requestParameters: AuthApiAuthRecoverPasswordRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserWithToken> {
            return localVarFp.authRecoverPassword(requestParameters.authRecoverPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthApiAuthRefreshRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRefresh(requestParameters: AuthApiAuthRefreshRequest, options?: RawAxiosRequestConfig): AxiosPromise<AccessToken> {
            return localVarFp.authRefresh(requestParameters.refreshToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthApiAuthResetPasswordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authResetPassword(requestParameters: AuthApiAuthResetPasswordRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.authResetPassword(requestParameters.authResetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthApiAuthSignupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignup(requestParameters: AuthApiAuthSignupRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserWithToken> {
            return localVarFp.authSignup(requestParameters.signUpParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthApiAuthValidateResetPasswordCodeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authValidateResetPasswordCode(requestParameters: AuthApiAuthValidateResetPasswordCodeRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.authValidateResetPasswordCode(requestParameters.code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthApiGetAppBuyerPrivacyPolicyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppBuyerPrivacyPolicy(requestParameters: AuthApiGetAppBuyerPrivacyPolicyRequest, options?: RawAxiosRequestConfig): AxiosPromise<AgreementDocument> {
            return localVarFp.getAppBuyerPrivacyPolicy(requestParameters.appId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthApiGetAppBuyerTermsOfUseRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppBuyerTermsOfUse(requestParameters: AuthApiGetAppBuyerTermsOfUseRequest, options?: RawAxiosRequestConfig): AxiosPromise<AgreementDocument> {
            return localVarFp.getAppBuyerTermsOfUse(requestParameters.appId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrivacyPolicy(options?: RawAxiosRequestConfig): AxiosPromise<AgreementDocument> {
            return localVarFp.getPrivacyPolicy(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTermsOfUse(options?: RawAxiosRequestConfig): AxiosPromise<AgreementDocument> {
            return localVarFp.getTermsOfUse(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for authLogin operation in AuthApi.
 * @export
 * @interface AuthApiAuthLoginRequest
 */
export interface AuthApiAuthLoginRequest {
    /**
     * 
     * @type {LoginParams}
     * @memberof AuthApiAuthLogin
     */
    readonly loginParams: LoginParams
}

/**
 * Request parameters for authRecoverPassword operation in AuthApi.
 * @export
 * @interface AuthApiAuthRecoverPasswordRequest
 */
export interface AuthApiAuthRecoverPasswordRequest {
    /**
     * 
     * @type {AuthRecoverPasswordRequest}
     * @memberof AuthApiAuthRecoverPassword
     */
    readonly authRecoverPasswordRequest: AuthRecoverPasswordRequest
}

/**
 * Request parameters for authRefresh operation in AuthApi.
 * @export
 * @interface AuthApiAuthRefreshRequest
 */
export interface AuthApiAuthRefreshRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthApiAuthRefresh
     */
    readonly refreshToken: string
}

/**
 * Request parameters for authResetPassword operation in AuthApi.
 * @export
 * @interface AuthApiAuthResetPasswordRequest
 */
export interface AuthApiAuthResetPasswordRequest {
    /**
     * 
     * @type {AuthResetPasswordRequest}
     * @memberof AuthApiAuthResetPassword
     */
    readonly authResetPasswordRequest: AuthResetPasswordRequest
}

/**
 * Request parameters for authSignup operation in AuthApi.
 * @export
 * @interface AuthApiAuthSignupRequest
 */
export interface AuthApiAuthSignupRequest {
    /**
     * 
     * @type {SignUpParams}
     * @memberof AuthApiAuthSignup
     */
    readonly signUpParams: SignUpParams
}

/**
 * Request parameters for authValidateResetPasswordCode operation in AuthApi.
 * @export
 * @interface AuthApiAuthValidateResetPasswordCodeRequest
 */
export interface AuthApiAuthValidateResetPasswordCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthApiAuthValidateResetPasswordCode
     */
    readonly code: string
}

/**
 * Request parameters for getAppBuyerPrivacyPolicy operation in AuthApi.
 * @export
 * @interface AuthApiGetAppBuyerPrivacyPolicyRequest
 */
export interface AuthApiGetAppBuyerPrivacyPolicyRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthApiGetAppBuyerPrivacyPolicy
     */
    readonly appId: string
}

/**
 * Request parameters for getAppBuyerTermsOfUse operation in AuthApi.
 * @export
 * @interface AuthApiGetAppBuyerTermsOfUseRequest
 */
export interface AuthApiGetAppBuyerTermsOfUseRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthApiGetAppBuyerTermsOfUse
     */
    readonly appId: string
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {AuthApiAuthLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authLogin(requestParameters: AuthApiAuthLoginRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authLogin(requestParameters.loginParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authLogout(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authLogout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiAuthRecoverPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authRecoverPassword(requestParameters: AuthApiAuthRecoverPasswordRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authRecoverPassword(requestParameters.authRecoverPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiAuthRefreshRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authRefresh(requestParameters: AuthApiAuthRefreshRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authRefresh(requestParameters.refreshToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiAuthResetPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authResetPassword(requestParameters: AuthApiAuthResetPasswordRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authResetPassword(requestParameters.authResetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiAuthSignupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authSignup(requestParameters: AuthApiAuthSignupRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authSignup(requestParameters.signUpParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiAuthValidateResetPasswordCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authValidateResetPasswordCode(requestParameters: AuthApiAuthValidateResetPasswordCodeRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authValidateResetPasswordCode(requestParameters.code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiGetAppBuyerPrivacyPolicyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getAppBuyerPrivacyPolicy(requestParameters: AuthApiGetAppBuyerPrivacyPolicyRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).getAppBuyerPrivacyPolicy(requestParameters.appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiGetAppBuyerTermsOfUseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getAppBuyerTermsOfUse(requestParameters: AuthApiGetAppBuyerTermsOfUseRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).getAppBuyerTermsOfUse(requestParameters.appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getPrivacyPolicy(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).getPrivacyPolicy(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getTermsOfUse(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).getTermsOfUse(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AvailabilitiesApi - axios parameter creator
 * @export
 */
export const AvailabilitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} appId 
         * @param {CreateAvailabilityParams} createAvailabilityParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppAvailability: async (appId: string, createAvailabilityParams: CreateAvailabilityParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('createAppAvailability', 'appId', appId)
            // verify required parameter 'createAvailabilityParams' is not null or undefined
            assertParamExists('createAppAvailability', 'createAvailabilityParams', createAvailabilityParams)
            const localVarPath = `/apps/{appId}/availabilities`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAvailabilityParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {CreateAvailabilityBatchParams} createAvailabilityBatchParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppAvailabilityBatch: async (appId: string, createAvailabilityBatchParams: CreateAvailabilityBatchParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('createAppAvailabilityBatch', 'appId', appId)
            // verify required parameter 'createAvailabilityBatchParams' is not null or undefined
            assertParamExists('createAppAvailabilityBatch', 'createAvailabilityBatchParams', createAvailabilityBatchParams)
            const localVarPath = `/apps/{appId}/availabilities/batch`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAvailabilityBatchParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} availabilityId 
         * @param {DeleteAvailabilityParams} deleteAvailabilityParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAvailability: async (availabilityId: string, deleteAvailabilityParams: DeleteAvailabilityParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'availabilityId' is not null or undefined
            assertParamExists('deleteAvailability', 'availabilityId', availabilityId)
            // verify required parameter 'deleteAvailabilityParams' is not null or undefined
            assertParamExists('deleteAvailability', 'deleteAvailabilityParams', deleteAvailabilityParams)
            const localVarPath = `/availabilities/{availabilityId}`
                .replace(`{${"availabilityId"}}`, encodeURIComponent(String(availabilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteAvailabilityParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {string | null} since 
         * @param {string | null} until 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<AvailabilityTypeEnum>} [types] 
         * @param {Array<string>} [affectedProductIds] 
         * @param {Array<GetAppAggregatedAvailabilitiesIncludeEnum>} [include] 
         * @param {number} [previewCount] 
         * @param {GetAppAggregatedAvailabilitiesSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppAggregatedAvailabilities: async (appId: string, since: string | null, until: string | null, offset?: number, limit?: number, types?: Array<AvailabilityTypeEnum>, affectedProductIds?: Array<string>, include?: Array<GetAppAggregatedAvailabilitiesIncludeEnum>, previewCount?: number, sortBy?: GetAppAggregatedAvailabilitiesSortByEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppAggregatedAvailabilities', 'appId', appId)
            // verify required parameter 'since' is not null or undefined
            assertParamExists('getAppAggregatedAvailabilities', 'since', since)
            // verify required parameter 'until' is not null or undefined
            assertParamExists('getAppAggregatedAvailabilities', 'until', until)
            const localVarPath = `/apps/{appId}/aggregatedAvailabilities`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString().substring(0,10) :
                    since;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = (until as any instanceof Date) ?
                    (until as any).toISOString().substring(0,10) :
                    until;
            }

            if (types) {
                localVarQueryParameter['types'] = types.join(COLLECTION_FORMATS.csv);
            }

            if (affectedProductIds) {
                localVarQueryParameter['affectedProductIds'] = affectedProductIds.join(COLLECTION_FORMATS.csv);
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (previewCount !== undefined) {
                localVarQueryParameter['previewCount'] = previewCount;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {string} since 
         * @param {string} until 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<AvailabilityTypeEnum>} [types] 
         * @param {Array<string>} [affectedProductIds] 
         * @param {Array<GetAppAvailabilitiesIncludeEnum>} [include] 
         * @param {GetAppAvailabilitiesSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppAvailabilities: async (appId: string, since: string, until: string, offset?: number, limit?: number, types?: Array<AvailabilityTypeEnum>, affectedProductIds?: Array<string>, include?: Array<GetAppAvailabilitiesIncludeEnum>, sortBy?: GetAppAvailabilitiesSortByEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppAvailabilities', 'appId', appId)
            // verify required parameter 'since' is not null or undefined
            assertParamExists('getAppAvailabilities', 'since', since)
            // verify required parameter 'until' is not null or undefined
            assertParamExists('getAppAvailabilities', 'until', until)
            const localVarPath = `/apps/{appId}/availabilities`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString() :
                    since;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = (until as any instanceof Date) ?
                    (until as any).toISOString() :
                    until;
            }

            if (types) {
                localVarQueryParameter['types'] = types.join(COLLECTION_FORMATS.csv);
            }

            if (affectedProductIds) {
                localVarQueryParameter['affectedProductIds'] = affectedProductIds.join(COLLECTION_FORMATS.csv);
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} availabilityId 
         * @param {Array<GetAvailabilityIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailability: async (availabilityId: string, include?: Array<GetAvailabilityIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'availabilityId' is not null or undefined
            assertParamExists('getAvailability', 'availabilityId', availabilityId)
            const localVarPath = `/availabilities/{availabilityId}`
                .replace(`{${"availabilityId"}}`, encodeURIComponent(String(availabilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} availabilityId 
         * @param {UpdateAvailabilityParams} updateAvailabilityParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAvailability: async (availabilityId: string, updateAvailabilityParams: UpdateAvailabilityParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'availabilityId' is not null or undefined
            assertParamExists('updateAvailability', 'availabilityId', availabilityId)
            // verify required parameter 'updateAvailabilityParams' is not null or undefined
            assertParamExists('updateAvailability', 'updateAvailabilityParams', updateAvailabilityParams)
            const localVarPath = `/availabilities/{availabilityId}`
                .replace(`{${"availabilityId"}}`, encodeURIComponent(String(availabilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAvailabilityParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AvailabilitiesApi - functional programming interface
 * @export
 */
export const AvailabilitiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AvailabilitiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} appId 
         * @param {CreateAvailabilityParams} createAvailabilityParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAppAvailability(appId: string, createAvailabilityParams: CreateAvailabilityParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Availability>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAppAvailability(appId, createAvailabilityParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AvailabilitiesApi.createAppAvailability']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {CreateAvailabilityBatchParams} createAvailabilityBatchParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAppAvailabilityBatch(appId: string, createAvailabilityBatchParams: CreateAvailabilityBatchParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Availability>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAppAvailabilityBatch(appId, createAvailabilityBatchParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AvailabilitiesApi.createAppAvailabilityBatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} availabilityId 
         * @param {DeleteAvailabilityParams} deleteAvailabilityParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAvailability(availabilityId: string, deleteAvailabilityParams: DeleteAvailabilityParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAvailability(availabilityId, deleteAvailabilityParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AvailabilitiesApi.deleteAvailability']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {string | null} since 
         * @param {string | null} until 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<AvailabilityTypeEnum>} [types] 
         * @param {Array<string>} [affectedProductIds] 
         * @param {Array<GetAppAggregatedAvailabilitiesIncludeEnum>} [include] 
         * @param {number} [previewCount] 
         * @param {GetAppAggregatedAvailabilitiesSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppAggregatedAvailabilities(appId: string, since: string | null, until: string | null, offset?: number, limit?: number, types?: Array<AvailabilityTypeEnum>, affectedProductIds?: Array<string>, include?: Array<GetAppAggregatedAvailabilitiesIncludeEnum>, previewCount?: number, sortBy?: GetAppAggregatedAvailabilitiesSortByEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AvailabilityDatePreview>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppAggregatedAvailabilities(appId, since, until, offset, limit, types, affectedProductIds, include, previewCount, sortBy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AvailabilitiesApi.getAppAggregatedAvailabilities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {string} since 
         * @param {string} until 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<AvailabilityTypeEnum>} [types] 
         * @param {Array<string>} [affectedProductIds] 
         * @param {Array<GetAppAvailabilitiesIncludeEnum>} [include] 
         * @param {GetAppAvailabilitiesSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppAvailabilities(appId: string, since: string, until: string, offset?: number, limit?: number, types?: Array<AvailabilityTypeEnum>, affectedProductIds?: Array<string>, include?: Array<GetAppAvailabilitiesIncludeEnum>, sortBy?: GetAppAvailabilitiesSortByEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Availability>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppAvailabilities(appId, since, until, offset, limit, types, affectedProductIds, include, sortBy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AvailabilitiesApi.getAppAvailabilities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} availabilityId 
         * @param {Array<GetAvailabilityIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailability(availabilityId: string, include?: Array<GetAvailabilityIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Availability>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailability(availabilityId, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AvailabilitiesApi.getAvailability']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} availabilityId 
         * @param {UpdateAvailabilityParams} updateAvailabilityParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAvailability(availabilityId: string, updateAvailabilityParams: UpdateAvailabilityParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Availability>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAvailability(availabilityId, updateAvailabilityParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AvailabilitiesApi.updateAvailability']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AvailabilitiesApi - factory interface
 * @export
 */
export const AvailabilitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AvailabilitiesApiFp(configuration)
    return {
        /**
         * 
         * @param {AvailabilitiesApiCreateAppAvailabilityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppAvailability(requestParameters: AvailabilitiesApiCreateAppAvailabilityRequest, options?: RawAxiosRequestConfig): AxiosPromise<Availability> {
            return localVarFp.createAppAvailability(requestParameters.appId, requestParameters.createAvailabilityParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AvailabilitiesApiCreateAppAvailabilityBatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppAvailabilityBatch(requestParameters: AvailabilitiesApiCreateAppAvailabilityBatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Availability>> {
            return localVarFp.createAppAvailabilityBatch(requestParameters.appId, requestParameters.createAvailabilityBatchParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AvailabilitiesApiDeleteAvailabilityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAvailability(requestParameters: AvailabilitiesApiDeleteAvailabilityRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteAvailability(requestParameters.availabilityId, requestParameters.deleteAvailabilityParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AvailabilitiesApiGetAppAggregatedAvailabilitiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppAggregatedAvailabilities(requestParameters: AvailabilitiesApiGetAppAggregatedAvailabilitiesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<AvailabilityDatePreview>> {
            return localVarFp.getAppAggregatedAvailabilities(requestParameters.appId, requestParameters.since, requestParameters.until, requestParameters.offset, requestParameters.limit, requestParameters.types, requestParameters.affectedProductIds, requestParameters.include, requestParameters.previewCount, requestParameters.sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AvailabilitiesApiGetAppAvailabilitiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppAvailabilities(requestParameters: AvailabilitiesApiGetAppAvailabilitiesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Availability>> {
            return localVarFp.getAppAvailabilities(requestParameters.appId, requestParameters.since, requestParameters.until, requestParameters.offset, requestParameters.limit, requestParameters.types, requestParameters.affectedProductIds, requestParameters.include, requestParameters.sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AvailabilitiesApiGetAvailabilityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailability(requestParameters: AvailabilitiesApiGetAvailabilityRequest, options?: RawAxiosRequestConfig): AxiosPromise<Availability> {
            return localVarFp.getAvailability(requestParameters.availabilityId, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AvailabilitiesApiUpdateAvailabilityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAvailability(requestParameters: AvailabilitiesApiUpdateAvailabilityRequest, options?: RawAxiosRequestConfig): AxiosPromise<Availability> {
            return localVarFp.updateAvailability(requestParameters.availabilityId, requestParameters.updateAvailabilityParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createAppAvailability operation in AvailabilitiesApi.
 * @export
 * @interface AvailabilitiesApiCreateAppAvailabilityRequest
 */
export interface AvailabilitiesApiCreateAppAvailabilityRequest {
    /**
     * 
     * @type {string}
     * @memberof AvailabilitiesApiCreateAppAvailability
     */
    readonly appId: string

    /**
     * 
     * @type {CreateAvailabilityParams}
     * @memberof AvailabilitiesApiCreateAppAvailability
     */
    readonly createAvailabilityParams: CreateAvailabilityParams
}

/**
 * Request parameters for createAppAvailabilityBatch operation in AvailabilitiesApi.
 * @export
 * @interface AvailabilitiesApiCreateAppAvailabilityBatchRequest
 */
export interface AvailabilitiesApiCreateAppAvailabilityBatchRequest {
    /**
     * 
     * @type {string}
     * @memberof AvailabilitiesApiCreateAppAvailabilityBatch
     */
    readonly appId: string

    /**
     * 
     * @type {CreateAvailabilityBatchParams}
     * @memberof AvailabilitiesApiCreateAppAvailabilityBatch
     */
    readonly createAvailabilityBatchParams: CreateAvailabilityBatchParams
}

/**
 * Request parameters for deleteAvailability operation in AvailabilitiesApi.
 * @export
 * @interface AvailabilitiesApiDeleteAvailabilityRequest
 */
export interface AvailabilitiesApiDeleteAvailabilityRequest {
    /**
     * 
     * @type {string}
     * @memberof AvailabilitiesApiDeleteAvailability
     */
    readonly availabilityId: string

    /**
     * 
     * @type {DeleteAvailabilityParams}
     * @memberof AvailabilitiesApiDeleteAvailability
     */
    readonly deleteAvailabilityParams: DeleteAvailabilityParams
}

/**
 * Request parameters for getAppAggregatedAvailabilities operation in AvailabilitiesApi.
 * @export
 * @interface AvailabilitiesApiGetAppAggregatedAvailabilitiesRequest
 */
export interface AvailabilitiesApiGetAppAggregatedAvailabilitiesRequest {
    /**
     * 
     * @type {string}
     * @memberof AvailabilitiesApiGetAppAggregatedAvailabilities
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof AvailabilitiesApiGetAppAggregatedAvailabilities
     */
    readonly since: string | null

    /**
     * 
     * @type {string}
     * @memberof AvailabilitiesApiGetAppAggregatedAvailabilities
     */
    readonly until: string | null

    /**
     * 
     * @type {number}
     * @memberof AvailabilitiesApiGetAppAggregatedAvailabilities
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof AvailabilitiesApiGetAppAggregatedAvailabilities
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<AvailabilityTypeEnum>}
     * @memberof AvailabilitiesApiGetAppAggregatedAvailabilities
     */
    readonly types?: Array<AvailabilityTypeEnum>

    /**
     * 
     * @type {Array<string>}
     * @memberof AvailabilitiesApiGetAppAggregatedAvailabilities
     */
    readonly affectedProductIds?: Array<string>

    /**
     * 
     * @type {Array<'availabilities.products' | 'availabilities.products.defaultTariffGroupPriceInfo' | 'availabilities.additionals' | 'availabilities.additionals.product' | 'availabilities.productTariffTypes' | 'availabilities.productTariffTypes.product' | 'availabilities.productTariffTypes.tariffType' | 'availabilities.tariffs' | 'availabilities.tariffs.type' | 'availabilities.aggregatedRecurrenceCount'>}
     * @memberof AvailabilitiesApiGetAppAggregatedAvailabilities
     */
    readonly include?: Array<GetAppAggregatedAvailabilitiesIncludeEnum>

    /**
     * 
     * @type {number}
     * @memberof AvailabilitiesApiGetAppAggregatedAvailabilities
     */
    readonly previewCount?: number

    /**
     * 
     * @type {'recent' | 'startTime'}
     * @memberof AvailabilitiesApiGetAppAggregatedAvailabilities
     */
    readonly sortBy?: GetAppAggregatedAvailabilitiesSortByEnum
}

/**
 * Request parameters for getAppAvailabilities operation in AvailabilitiesApi.
 * @export
 * @interface AvailabilitiesApiGetAppAvailabilitiesRequest
 */
export interface AvailabilitiesApiGetAppAvailabilitiesRequest {
    /**
     * 
     * @type {string}
     * @memberof AvailabilitiesApiGetAppAvailabilities
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof AvailabilitiesApiGetAppAvailabilities
     */
    readonly since: string

    /**
     * 
     * @type {string}
     * @memberof AvailabilitiesApiGetAppAvailabilities
     */
    readonly until: string

    /**
     * 
     * @type {number}
     * @memberof AvailabilitiesApiGetAppAvailabilities
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof AvailabilitiesApiGetAppAvailabilities
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<AvailabilityTypeEnum>}
     * @memberof AvailabilitiesApiGetAppAvailabilities
     */
    readonly types?: Array<AvailabilityTypeEnum>

    /**
     * 
     * @type {Array<string>}
     * @memberof AvailabilitiesApiGetAppAvailabilities
     */
    readonly affectedProductIds?: Array<string>

    /**
     * 
     * @type {Array<'products' | 'products.defaultTariffGroupPriceInfo' | 'additionals' | 'additionals.product' | 'productTariffTypes' | 'productTariffTypes.product' | 'productTariffTypes.tariffType' | 'tariffs' | 'tariffs.type' | 'tariffs.group' | 'tariffs.product' | 'aggregatedRecurrenceCount'>}
     * @memberof AvailabilitiesApiGetAppAvailabilities
     */
    readonly include?: Array<GetAppAvailabilitiesIncludeEnum>

    /**
     * 
     * @type {'recent' | 'startTime'}
     * @memberof AvailabilitiesApiGetAppAvailabilities
     */
    readonly sortBy?: GetAppAvailabilitiesSortByEnum
}

/**
 * Request parameters for getAvailability operation in AvailabilitiesApi.
 * @export
 * @interface AvailabilitiesApiGetAvailabilityRequest
 */
export interface AvailabilitiesApiGetAvailabilityRequest {
    /**
     * 
     * @type {string}
     * @memberof AvailabilitiesApiGetAvailability
     */
    readonly availabilityId: string

    /**
     * 
     * @type {Array<'products' | 'products.defaultTariffGroupPriceInfo' | 'additionals' | 'additionals.product' | 'productTariffTypes' | 'productTariffTypes.product' | 'productTariffTypes.tariffType' | 'tariffs' | 'tariffs.type' | 'tariffs.group' | 'tariffs.product' | 'aggregatedRecurrenceCount'>}
     * @memberof AvailabilitiesApiGetAvailability
     */
    readonly include?: Array<GetAvailabilityIncludeEnum>
}

/**
 * Request parameters for updateAvailability operation in AvailabilitiesApi.
 * @export
 * @interface AvailabilitiesApiUpdateAvailabilityRequest
 */
export interface AvailabilitiesApiUpdateAvailabilityRequest {
    /**
     * 
     * @type {string}
     * @memberof AvailabilitiesApiUpdateAvailability
     */
    readonly availabilityId: string

    /**
     * 
     * @type {UpdateAvailabilityParams}
     * @memberof AvailabilitiesApiUpdateAvailability
     */
    readonly updateAvailabilityParams: UpdateAvailabilityParams
}

/**
 * AvailabilitiesApi - object-oriented interface
 * @export
 * @class AvailabilitiesApi
 * @extends {BaseAPI}
 */
export class AvailabilitiesApi extends BaseAPI {
    /**
     * 
     * @param {AvailabilitiesApiCreateAppAvailabilityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvailabilitiesApi
     */
    public createAppAvailability(requestParameters: AvailabilitiesApiCreateAppAvailabilityRequest, options?: RawAxiosRequestConfig) {
        return AvailabilitiesApiFp(this.configuration).createAppAvailability(requestParameters.appId, requestParameters.createAvailabilityParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AvailabilitiesApiCreateAppAvailabilityBatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvailabilitiesApi
     */
    public createAppAvailabilityBatch(requestParameters: AvailabilitiesApiCreateAppAvailabilityBatchRequest, options?: RawAxiosRequestConfig) {
        return AvailabilitiesApiFp(this.configuration).createAppAvailabilityBatch(requestParameters.appId, requestParameters.createAvailabilityBatchParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AvailabilitiesApiDeleteAvailabilityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvailabilitiesApi
     */
    public deleteAvailability(requestParameters: AvailabilitiesApiDeleteAvailabilityRequest, options?: RawAxiosRequestConfig) {
        return AvailabilitiesApiFp(this.configuration).deleteAvailability(requestParameters.availabilityId, requestParameters.deleteAvailabilityParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AvailabilitiesApiGetAppAggregatedAvailabilitiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvailabilitiesApi
     */
    public getAppAggregatedAvailabilities(requestParameters: AvailabilitiesApiGetAppAggregatedAvailabilitiesRequest, options?: RawAxiosRequestConfig) {
        return AvailabilitiesApiFp(this.configuration).getAppAggregatedAvailabilities(requestParameters.appId, requestParameters.since, requestParameters.until, requestParameters.offset, requestParameters.limit, requestParameters.types, requestParameters.affectedProductIds, requestParameters.include, requestParameters.previewCount, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AvailabilitiesApiGetAppAvailabilitiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvailabilitiesApi
     */
    public getAppAvailabilities(requestParameters: AvailabilitiesApiGetAppAvailabilitiesRequest, options?: RawAxiosRequestConfig) {
        return AvailabilitiesApiFp(this.configuration).getAppAvailabilities(requestParameters.appId, requestParameters.since, requestParameters.until, requestParameters.offset, requestParameters.limit, requestParameters.types, requestParameters.affectedProductIds, requestParameters.include, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AvailabilitiesApiGetAvailabilityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvailabilitiesApi
     */
    public getAvailability(requestParameters: AvailabilitiesApiGetAvailabilityRequest, options?: RawAxiosRequestConfig) {
        return AvailabilitiesApiFp(this.configuration).getAvailability(requestParameters.availabilityId, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AvailabilitiesApiUpdateAvailabilityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvailabilitiesApi
     */
    public updateAvailability(requestParameters: AvailabilitiesApiUpdateAvailabilityRequest, options?: RawAxiosRequestConfig) {
        return AvailabilitiesApiFp(this.configuration).updateAvailability(requestParameters.availabilityId, requestParameters.updateAvailabilityParams, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAppAggregatedAvailabilitiesIncludeEnum = {
    Products: 'availabilities.products',
    ProductsDefaultTariffGroupPriceInfo: 'availabilities.products.defaultTariffGroupPriceInfo',
    Additionals: 'availabilities.additionals',
    AdditionalsProduct: 'availabilities.additionals.product',
    ProductTariffTypes: 'availabilities.productTariffTypes',
    ProductTariffTypesProduct: 'availabilities.productTariffTypes.product',
    ProductTariffTypesTariffType: 'availabilities.productTariffTypes.tariffType',
    Tariffs: 'availabilities.tariffs',
    TariffsType: 'availabilities.tariffs.type',
    AggregatedRecurrenceCount: 'availabilities.aggregatedRecurrenceCount'
} as const;
export type GetAppAggregatedAvailabilitiesIncludeEnum = typeof GetAppAggregatedAvailabilitiesIncludeEnum[keyof typeof GetAppAggregatedAvailabilitiesIncludeEnum];
/**
 * @export
 */
export const GetAppAggregatedAvailabilitiesSortByEnum = {
    Recent: 'recent',
    StartTime: 'startTime'
} as const;
export type GetAppAggregatedAvailabilitiesSortByEnum = typeof GetAppAggregatedAvailabilitiesSortByEnum[keyof typeof GetAppAggregatedAvailabilitiesSortByEnum];
/**
 * @export
 */
export const GetAppAvailabilitiesIncludeEnum = {
    Products: 'products',
    ProductsDefaultTariffGroupPriceInfo: 'products.defaultTariffGroupPriceInfo',
    Additionals: 'additionals',
    AdditionalsProduct: 'additionals.product',
    ProductTariffTypes: 'productTariffTypes',
    ProductTariffTypesProduct: 'productTariffTypes.product',
    ProductTariffTypesTariffType: 'productTariffTypes.tariffType',
    Tariffs: 'tariffs',
    TariffsType: 'tariffs.type',
    TariffsGroup: 'tariffs.group',
    TariffsProduct: 'tariffs.product',
    AggregatedRecurrenceCount: 'aggregatedRecurrenceCount'
} as const;
export type GetAppAvailabilitiesIncludeEnum = typeof GetAppAvailabilitiesIncludeEnum[keyof typeof GetAppAvailabilitiesIncludeEnum];
/**
 * @export
 */
export const GetAppAvailabilitiesSortByEnum = {
    Recent: 'recent',
    StartTime: 'startTime'
} as const;
export type GetAppAvailabilitiesSortByEnum = typeof GetAppAvailabilitiesSortByEnum[keyof typeof GetAppAvailabilitiesSortByEnum];
/**
 * @export
 */
export const GetAvailabilityIncludeEnum = {
    Products: 'products',
    ProductsDefaultTariffGroupPriceInfo: 'products.defaultTariffGroupPriceInfo',
    Additionals: 'additionals',
    AdditionalsProduct: 'additionals.product',
    ProductTariffTypes: 'productTariffTypes',
    ProductTariffTypesProduct: 'productTariffTypes.product',
    ProductTariffTypesTariffType: 'productTariffTypes.tariffType',
    Tariffs: 'tariffs',
    TariffsType: 'tariffs.type',
    TariffsGroup: 'tariffs.group',
    TariffsProduct: 'tariffs.product',
    AggregatedRecurrenceCount: 'aggregatedRecurrenceCount'
} as const;
export type GetAvailabilityIncludeEnum = typeof GetAvailabilityIncludeEnum[keyof typeof GetAvailabilityIncludeEnum];


/**
 * CouponsApi - axios parameter creator
 * @export
 */
export const CouponsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} appId 
         * @param {CreateCouponParams} createCouponParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppCoupon: async (appId: string, createCouponParams: CreateCouponParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('createAppCoupon', 'appId', appId)
            // verify required parameter 'createCouponParams' is not null or undefined
            assertParamExists('createAppCoupon', 'createCouponParams', createCouponParams)
            const localVarPath = `/apps/{appId}/coupons`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCouponParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} couponId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCoupon: async (couponId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'couponId' is not null or undefined
            assertParamExists('deleteCoupon', 'couponId', couponId)
            const localVarPath = `/coupons/{couponId}`
                .replace(`{${"couponId"}}`, encodeURIComponent(String(couponId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {GetAppCouponsFilterEnum} [filter] 
         * @param {string} [search] 
         * @param {Array<GetAppCouponsIncludeEnum>} [include] 
         * @param {Array<string>} [productIds] 
         * @param {string | null} [validSince] 
         * @param {string | null} [validUntil] 
         * @param {boolean} [belongsToPartnership] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppCoupons: async (appId: string, offset?: number, limit?: number, filter?: GetAppCouponsFilterEnum, search?: string, include?: Array<GetAppCouponsIncludeEnum>, productIds?: Array<string>, validSince?: string | null, validUntil?: string | null, belongsToPartnership?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppCoupons', 'appId', appId)
            const localVarPath = `/apps/{appId}/coupons`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (productIds) {
                localVarQueryParameter['productIds'] = productIds.join(COLLECTION_FORMATS.csv);
            }

            if (validSince !== undefined) {
                localVarQueryParameter['validSince'] = (validSince as any instanceof Date) ?
                    (validSince as any).toISOString() :
                    validSince;
            }

            if (validUntil !== undefined) {
                localVarQueryParameter['validUntil'] = (validUntil as any instanceof Date) ?
                    (validUntil as any).toISOString() :
                    validUntil;
            }

            if (belongsToPartnership !== undefined) {
                localVarQueryParameter['belongsToPartnership'] = belongsToPartnership;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} couponId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoupon: async (couponId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'couponId' is not null or undefined
            assertParamExists('getCoupon', 'couponId', couponId)
            const localVarPath = `/coupons/{couponId}`
                .replace(`{${"couponId"}}`, encodeURIComponent(String(couponId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} couponId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetCouponProductsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCouponProducts: async (couponId: string, offset?: number, limit?: number, include?: Array<GetCouponProductsIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'couponId' is not null or undefined
            assertParamExists('getCouponProducts', 'couponId', couponId)
            const localVarPath = `/coupons/{couponId}/products`
                .replace(`{${"couponId"}}`, encodeURIComponent(String(couponId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} couponId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreCoupon: async (couponId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'couponId' is not null or undefined
            assertParamExists('restoreCoupon', 'couponId', couponId)
            const localVarPath = `/coupons/{couponId}/restore`
                .replace(`{${"couponId"}}`, encodeURIComponent(String(couponId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} couponId 
         * @param {UpdateArrangedCouponParams} updateArrangedCouponParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArrangedCoupon: async (couponId: string, updateArrangedCouponParams: UpdateArrangedCouponParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'couponId' is not null or undefined
            assertParamExists('updateArrangedCoupon', 'couponId', couponId)
            // verify required parameter 'updateArrangedCouponParams' is not null or undefined
            assertParamExists('updateArrangedCoupon', 'updateArrangedCouponParams', updateArrangedCouponParams)
            const localVarPath = `/arrangedCoupons/{couponId}`
                .replace(`{${"couponId"}}`, encodeURIComponent(String(couponId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateArrangedCouponParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} couponId 
         * @param {CreateCouponParams} createCouponParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCoupon: async (couponId: string, createCouponParams: CreateCouponParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'couponId' is not null or undefined
            assertParamExists('updateCoupon', 'couponId', couponId)
            // verify required parameter 'createCouponParams' is not null or undefined
            assertParamExists('updateCoupon', 'createCouponParams', createCouponParams)
            const localVarPath = `/coupons/{couponId}`
                .replace(`{${"couponId"}}`, encodeURIComponent(String(couponId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCouponParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {ValidateCouponParams} validateCouponParams 
         * @param {Array<ValidateAppCouponCodeIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAppCouponCode: async (appId: string, validateCouponParams: ValidateCouponParams, include?: Array<ValidateAppCouponCodeIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('validateAppCouponCode', 'appId', appId)
            // verify required parameter 'validateCouponParams' is not null or undefined
            assertParamExists('validateAppCouponCode', 'validateCouponParams', validateCouponParams)
            const localVarPath = `/apps/{appId}/coupons/validate`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validateCouponParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CouponsApi - functional programming interface
 * @export
 */
export const CouponsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CouponsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} appId 
         * @param {CreateCouponParams} createCouponParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAppCoupon(appId: string, createCouponParams: CreateCouponParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Coupon>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAppCoupon(appId, createCouponParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CouponsApi.createAppCoupon']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} couponId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCoupon(couponId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCoupon(couponId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CouponsApi.deleteCoupon']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {GetAppCouponsFilterEnum} [filter] 
         * @param {string} [search] 
         * @param {Array<GetAppCouponsIncludeEnum>} [include] 
         * @param {Array<string>} [productIds] 
         * @param {string | null} [validSince] 
         * @param {string | null} [validUntil] 
         * @param {boolean} [belongsToPartnership] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppCoupons(appId: string, offset?: number, limit?: number, filter?: GetAppCouponsFilterEnum, search?: string, include?: Array<GetAppCouponsIncludeEnum>, productIds?: Array<string>, validSince?: string | null, validUntil?: string | null, belongsToPartnership?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Coupon>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppCoupons(appId, offset, limit, filter, search, include, productIds, validSince, validUntil, belongsToPartnership, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CouponsApi.getAppCoupons']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} couponId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCoupon(couponId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Coupon>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCoupon(couponId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CouponsApi.getCoupon']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} couponId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetCouponProductsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCouponProducts(couponId: string, offset?: number, limit?: number, include?: Array<GetCouponProductsIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CouponProduct>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCouponProducts(couponId, offset, limit, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CouponsApi.getCouponProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} couponId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreCoupon(couponId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Coupon>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restoreCoupon(couponId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CouponsApi.restoreCoupon']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} couponId 
         * @param {UpdateArrangedCouponParams} updateArrangedCouponParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateArrangedCoupon(couponId: string, updateArrangedCouponParams: UpdateArrangedCouponParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Coupon>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateArrangedCoupon(couponId, updateArrangedCouponParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CouponsApi.updateArrangedCoupon']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} couponId 
         * @param {CreateCouponParams} createCouponParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCoupon(couponId: string, createCouponParams: CreateCouponParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Coupon>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCoupon(couponId, createCouponParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CouponsApi.updateCoupon']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {ValidateCouponParams} validateCouponParams 
         * @param {Array<ValidateAppCouponCodeIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateAppCouponCode(appId: string, validateCouponParams: ValidateCouponParams, include?: Array<ValidateAppCouponCodeIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponValidationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateAppCouponCode(appId, validateCouponParams, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CouponsApi.validateAppCouponCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CouponsApi - factory interface
 * @export
 */
export const CouponsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CouponsApiFp(configuration)
    return {
        /**
         * 
         * @param {CouponsApiCreateAppCouponRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppCoupon(requestParameters: CouponsApiCreateAppCouponRequest, options?: RawAxiosRequestConfig): AxiosPromise<Coupon> {
            return localVarFp.createAppCoupon(requestParameters.appId, requestParameters.createCouponParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CouponsApiDeleteCouponRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCoupon(requestParameters: CouponsApiDeleteCouponRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteCoupon(requestParameters.couponId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CouponsApiGetAppCouponsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppCoupons(requestParameters: CouponsApiGetAppCouponsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Coupon>> {
            return localVarFp.getAppCoupons(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.filter, requestParameters.search, requestParameters.include, requestParameters.productIds, requestParameters.validSince, requestParameters.validUntil, requestParameters.belongsToPartnership, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CouponsApiGetCouponRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoupon(requestParameters: CouponsApiGetCouponRequest, options?: RawAxiosRequestConfig): AxiosPromise<Coupon> {
            return localVarFp.getCoupon(requestParameters.couponId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CouponsApiGetCouponProductsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCouponProducts(requestParameters: CouponsApiGetCouponProductsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<CouponProduct>> {
            return localVarFp.getCouponProducts(requestParameters.couponId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CouponsApiRestoreCouponRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreCoupon(requestParameters: CouponsApiRestoreCouponRequest, options?: RawAxiosRequestConfig): AxiosPromise<Coupon> {
            return localVarFp.restoreCoupon(requestParameters.couponId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CouponsApiUpdateArrangedCouponRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArrangedCoupon(requestParameters: CouponsApiUpdateArrangedCouponRequest, options?: RawAxiosRequestConfig): AxiosPromise<Coupon> {
            return localVarFp.updateArrangedCoupon(requestParameters.couponId, requestParameters.updateArrangedCouponParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CouponsApiUpdateCouponRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCoupon(requestParameters: CouponsApiUpdateCouponRequest, options?: RawAxiosRequestConfig): AxiosPromise<Coupon> {
            return localVarFp.updateCoupon(requestParameters.couponId, requestParameters.createCouponParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CouponsApiValidateAppCouponCodeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAppCouponCode(requestParameters: CouponsApiValidateAppCouponCodeRequest, options?: RawAxiosRequestConfig): AxiosPromise<CouponValidationResult> {
            return localVarFp.validateAppCouponCode(requestParameters.appId, requestParameters.validateCouponParams, requestParameters.include, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createAppCoupon operation in CouponsApi.
 * @export
 * @interface CouponsApiCreateAppCouponRequest
 */
export interface CouponsApiCreateAppCouponRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiCreateAppCoupon
     */
    readonly appId: string

    /**
     * 
     * @type {CreateCouponParams}
     * @memberof CouponsApiCreateAppCoupon
     */
    readonly createCouponParams: CreateCouponParams
}

/**
 * Request parameters for deleteCoupon operation in CouponsApi.
 * @export
 * @interface CouponsApiDeleteCouponRequest
 */
export interface CouponsApiDeleteCouponRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiDeleteCoupon
     */
    readonly couponId: string
}

/**
 * Request parameters for getAppCoupons operation in CouponsApi.
 * @export
 * @interface CouponsApiGetAppCouponsRequest
 */
export interface CouponsApiGetAppCouponsRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiGetAppCoupons
     */
    readonly appId: string

    /**
     * 
     * @type {number}
     * @memberof CouponsApiGetAppCoupons
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof CouponsApiGetAppCoupons
     */
    readonly limit?: number

    /**
     * 
     * @type {'active' | 'all' | 'archived'}
     * @memberof CouponsApiGetAppCoupons
     */
    readonly filter?: GetAppCouponsFilterEnum

    /**
     * 
     * @type {string}
     * @memberof CouponsApiGetAppCoupons
     */
    readonly search?: string

    /**
     * 
     * @type {Array<'products' | 'usage' | 'partnership' | 'partnership.provider' | 'partnership.agent' | 'partnership.proposal'>}
     * @memberof CouponsApiGetAppCoupons
     */
    readonly include?: Array<GetAppCouponsIncludeEnum>

    /**
     * 
     * @type {Array<string>}
     * @memberof CouponsApiGetAppCoupons
     */
    readonly productIds?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof CouponsApiGetAppCoupons
     */
    readonly validSince?: string | null

    /**
     * 
     * @type {string}
     * @memberof CouponsApiGetAppCoupons
     */
    readonly validUntil?: string | null

    /**
     * 
     * @type {boolean}
     * @memberof CouponsApiGetAppCoupons
     */
    readonly belongsToPartnership?: boolean
}

/**
 * Request parameters for getCoupon operation in CouponsApi.
 * @export
 * @interface CouponsApiGetCouponRequest
 */
export interface CouponsApiGetCouponRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiGetCoupon
     */
    readonly couponId: string
}

/**
 * Request parameters for getCouponProducts operation in CouponsApi.
 * @export
 * @interface CouponsApiGetCouponProductsRequest
 */
export interface CouponsApiGetCouponProductsRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiGetCouponProducts
     */
    readonly couponId: string

    /**
     * 
     * @type {number}
     * @memberof CouponsApiGetCouponProducts
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof CouponsApiGetCouponProducts
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<'product'>}
     * @memberof CouponsApiGetCouponProducts
     */
    readonly include?: Array<GetCouponProductsIncludeEnum>
}

/**
 * Request parameters for restoreCoupon operation in CouponsApi.
 * @export
 * @interface CouponsApiRestoreCouponRequest
 */
export interface CouponsApiRestoreCouponRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiRestoreCoupon
     */
    readonly couponId: string
}

/**
 * Request parameters for updateArrangedCoupon operation in CouponsApi.
 * @export
 * @interface CouponsApiUpdateArrangedCouponRequest
 */
export interface CouponsApiUpdateArrangedCouponRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiUpdateArrangedCoupon
     */
    readonly couponId: string

    /**
     * 
     * @type {UpdateArrangedCouponParams}
     * @memberof CouponsApiUpdateArrangedCoupon
     */
    readonly updateArrangedCouponParams: UpdateArrangedCouponParams
}

/**
 * Request parameters for updateCoupon operation in CouponsApi.
 * @export
 * @interface CouponsApiUpdateCouponRequest
 */
export interface CouponsApiUpdateCouponRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiUpdateCoupon
     */
    readonly couponId: string

    /**
     * 
     * @type {CreateCouponParams}
     * @memberof CouponsApiUpdateCoupon
     */
    readonly createCouponParams: CreateCouponParams
}

/**
 * Request parameters for validateAppCouponCode operation in CouponsApi.
 * @export
 * @interface CouponsApiValidateAppCouponCodeRequest
 */
export interface CouponsApiValidateAppCouponCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiValidateAppCouponCode
     */
    readonly appId: string

    /**
     * 
     * @type {ValidateCouponParams}
     * @memberof CouponsApiValidateAppCouponCode
     */
    readonly validateCouponParams: ValidateCouponParams

    /**
     * 
     * @type {Array<'coupon.products' | 'coupon.usage'>}
     * @memberof CouponsApiValidateAppCouponCode
     */
    readonly include?: Array<ValidateAppCouponCodeIncludeEnum>
}

/**
 * CouponsApi - object-oriented interface
 * @export
 * @class CouponsApi
 * @extends {BaseAPI}
 */
export class CouponsApi extends BaseAPI {
    /**
     * 
     * @param {CouponsApiCreateAppCouponRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public createAppCoupon(requestParameters: CouponsApiCreateAppCouponRequest, options?: RawAxiosRequestConfig) {
        return CouponsApiFp(this.configuration).createAppCoupon(requestParameters.appId, requestParameters.createCouponParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CouponsApiDeleteCouponRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public deleteCoupon(requestParameters: CouponsApiDeleteCouponRequest, options?: RawAxiosRequestConfig) {
        return CouponsApiFp(this.configuration).deleteCoupon(requestParameters.couponId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CouponsApiGetAppCouponsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public getAppCoupons(requestParameters: CouponsApiGetAppCouponsRequest, options?: RawAxiosRequestConfig) {
        return CouponsApiFp(this.configuration).getAppCoupons(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.filter, requestParameters.search, requestParameters.include, requestParameters.productIds, requestParameters.validSince, requestParameters.validUntil, requestParameters.belongsToPartnership, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CouponsApiGetCouponRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public getCoupon(requestParameters: CouponsApiGetCouponRequest, options?: RawAxiosRequestConfig) {
        return CouponsApiFp(this.configuration).getCoupon(requestParameters.couponId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CouponsApiGetCouponProductsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public getCouponProducts(requestParameters: CouponsApiGetCouponProductsRequest, options?: RawAxiosRequestConfig) {
        return CouponsApiFp(this.configuration).getCouponProducts(requestParameters.couponId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CouponsApiRestoreCouponRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public restoreCoupon(requestParameters: CouponsApiRestoreCouponRequest, options?: RawAxiosRequestConfig) {
        return CouponsApiFp(this.configuration).restoreCoupon(requestParameters.couponId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CouponsApiUpdateArrangedCouponRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public updateArrangedCoupon(requestParameters: CouponsApiUpdateArrangedCouponRequest, options?: RawAxiosRequestConfig) {
        return CouponsApiFp(this.configuration).updateArrangedCoupon(requestParameters.couponId, requestParameters.updateArrangedCouponParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CouponsApiUpdateCouponRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public updateCoupon(requestParameters: CouponsApiUpdateCouponRequest, options?: RawAxiosRequestConfig) {
        return CouponsApiFp(this.configuration).updateCoupon(requestParameters.couponId, requestParameters.createCouponParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CouponsApiValidateAppCouponCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public validateAppCouponCode(requestParameters: CouponsApiValidateAppCouponCodeRequest, options?: RawAxiosRequestConfig) {
        return CouponsApiFp(this.configuration).validateAppCouponCode(requestParameters.appId, requestParameters.validateCouponParams, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAppCouponsFilterEnum = {
    Active: 'active',
    All: 'all',
    Archived: 'archived'
} as const;
export type GetAppCouponsFilterEnum = typeof GetAppCouponsFilterEnum[keyof typeof GetAppCouponsFilterEnum];
/**
 * @export
 */
export const GetAppCouponsIncludeEnum = {
    Products: 'products',
    Usage: 'usage',
    Partnership: 'partnership',
    PartnershipProvider: 'partnership.provider',
    PartnershipAgent: 'partnership.agent',
    PartnershipProposal: 'partnership.proposal'
} as const;
export type GetAppCouponsIncludeEnum = typeof GetAppCouponsIncludeEnum[keyof typeof GetAppCouponsIncludeEnum];
/**
 * @export
 */
export const GetCouponProductsIncludeEnum = {
    Product: 'product'
} as const;
export type GetCouponProductsIncludeEnum = typeof GetCouponProductsIncludeEnum[keyof typeof GetCouponProductsIncludeEnum];
/**
 * @export
 */
export const ValidateAppCouponCodeIncludeEnum = {
    Products: 'coupon.products',
    Usage: 'coupon.usage'
} as const;
export type ValidateAppCouponCodeIncludeEnum = typeof ValidateAppCouponCodeIncludeEnum[keyof typeof ValidateAppCouponCodeIncludeEnum];


/**
 * CustomersApi - axios parameter creator
 * @export
 */
export const CustomersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateCustomer: async (customerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('authenticateCustomer', 'customerId', customerId)
            const localVarPath = `/customers/{customerId}/authenticate`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {CustomerParams} customerParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppExternalCustomer: async (appId: string, customerParams: CustomerParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('createAppExternalCustomer', 'appId', appId)
            // verify required parameter 'customerParams' is not null or undefined
            assertParamExists('createAppExternalCustomer', 'customerParams', customerParams)
            const localVarPath = `/apps/{appId}/externalCustomers`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {GetAppCustomersSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppCustomers: async (appId: string, offset?: number, limit?: number, search?: string, sortBy?: GetAppCustomersSortByEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppCustomers', 'appId', appId)
            const localVarPath = `/apps/{appId}/customers`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomer: async (customerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getCustomer', 'customerId', customerId)
            const localVarPath = `/customers/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomersApi - functional programming interface
 * @export
 */
export const CustomersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateCustomer(customerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerAuthentication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticateCustomer(customerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomersApi.authenticateCustomer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {CustomerParams} customerParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAppExternalCustomer(appId: string, customerParams: CustomerParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAppExternalCustomer(appId, customerParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomersApi.createAppExternalCustomer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {GetAppCustomersSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppCustomers(appId: string, offset?: number, limit?: number, search?: string, sortBy?: GetAppCustomersSortByEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Customer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppCustomers(appId, offset, limit, search, sortBy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomersApi.getAppCustomers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomer(customerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomer(customerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomersApi.getCustomer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CustomersApi - factory interface
 * @export
 */
export const CustomersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomersApiFp(configuration)
    return {
        /**
         * 
         * @param {CustomersApiAuthenticateCustomerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateCustomer(requestParameters: CustomersApiAuthenticateCustomerRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomerAuthentication> {
            return localVarFp.authenticateCustomer(requestParameters.customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomersApiCreateAppExternalCustomerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppExternalCustomer(requestParameters: CustomersApiCreateAppExternalCustomerRequest, options?: RawAxiosRequestConfig): AxiosPromise<Customer> {
            return localVarFp.createAppExternalCustomer(requestParameters.appId, requestParameters.customerParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomersApiGetAppCustomersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppCustomers(requestParameters: CustomersApiGetAppCustomersRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Customer>> {
            return localVarFp.getAppCustomers(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.search, requestParameters.sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomersApiGetCustomerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomer(requestParameters: CustomersApiGetCustomerRequest, options?: RawAxiosRequestConfig): AxiosPromise<Customer> {
            return localVarFp.getCustomer(requestParameters.customerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for authenticateCustomer operation in CustomersApi.
 * @export
 * @interface CustomersApiAuthenticateCustomerRequest
 */
export interface CustomersApiAuthenticateCustomerRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomersApiAuthenticateCustomer
     */
    readonly customerId: string
}

/**
 * Request parameters for createAppExternalCustomer operation in CustomersApi.
 * @export
 * @interface CustomersApiCreateAppExternalCustomerRequest
 */
export interface CustomersApiCreateAppExternalCustomerRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomersApiCreateAppExternalCustomer
     */
    readonly appId: string

    /**
     * 
     * @type {CustomerParams}
     * @memberof CustomersApiCreateAppExternalCustomer
     */
    readonly customerParams: CustomerParams
}

/**
 * Request parameters for getAppCustomers operation in CustomersApi.
 * @export
 * @interface CustomersApiGetAppCustomersRequest
 */
export interface CustomersApiGetAppCustomersRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomersApiGetAppCustomers
     */
    readonly appId: string

    /**
     * 
     * @type {number}
     * @memberof CustomersApiGetAppCustomers
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof CustomersApiGetAppCustomers
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof CustomersApiGetAppCustomers
     */
    readonly search?: string

    /**
     * 
     * @type {'recent'}
     * @memberof CustomersApiGetAppCustomers
     */
    readonly sortBy?: GetAppCustomersSortByEnum
}

/**
 * Request parameters for getCustomer operation in CustomersApi.
 * @export
 * @interface CustomersApiGetCustomerRequest
 */
export interface CustomersApiGetCustomerRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomersApiGetCustomer
     */
    readonly customerId: string
}

/**
 * CustomersApi - object-oriented interface
 * @export
 * @class CustomersApi
 * @extends {BaseAPI}
 */
export class CustomersApi extends BaseAPI {
    /**
     * 
     * @param {CustomersApiAuthenticateCustomerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public authenticateCustomer(requestParameters: CustomersApiAuthenticateCustomerRequest, options?: RawAxiosRequestConfig) {
        return CustomersApiFp(this.configuration).authenticateCustomer(requestParameters.customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiCreateAppExternalCustomerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public createAppExternalCustomer(requestParameters: CustomersApiCreateAppExternalCustomerRequest, options?: RawAxiosRequestConfig) {
        return CustomersApiFp(this.configuration).createAppExternalCustomer(requestParameters.appId, requestParameters.customerParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiGetAppCustomersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public getAppCustomers(requestParameters: CustomersApiGetAppCustomersRequest, options?: RawAxiosRequestConfig) {
        return CustomersApiFp(this.configuration).getAppCustomers(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.search, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiGetCustomerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public getCustomer(requestParameters: CustomersApiGetCustomerRequest, options?: RawAxiosRequestConfig) {
        return CustomersApiFp(this.configuration).getCustomer(requestParameters.customerId, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAppCustomersSortByEnum = {
    Recent: 'recent'
} as const;
export type GetAppCustomersSortByEnum = typeof GetAppCustomersSortByEnum[keyof typeof GetAppCustomersSortByEnum];


/**
 * DetailedSchedulingsApi - axios parameter creator
 * @export
 */
export const DetailedSchedulingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} productId 
         * @param {string} since 
         * @param {string} until 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetProductDetailedSchedulingsIncludeEnum>} [include] 
         * @param {boolean} [ignoreAntecedence] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductDetailedSchedulings: async (productId: string, since: string, until: string, offset?: number, limit?: number, include?: Array<GetProductDetailedSchedulingsIncludeEnum>, ignoreAntecedence?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductDetailedSchedulings', 'productId', productId)
            // verify required parameter 'since' is not null or undefined
            assertParamExists('getProductDetailedSchedulings', 'since', since)
            // verify required parameter 'until' is not null or undefined
            assertParamExists('getProductDetailedSchedulings', 'until', until)
            const localVarPath = `/products/{productId}/detailedSchedulings`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString().substring(0,10) :
                    since;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = (until as any instanceof Date) ?
                    (until as any).toISOString().substring(0,10) :
                    until;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (ignoreAntecedence !== undefined) {
                localVarQueryParameter['ignoreAntecedence'] = ignoreAntecedence;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DetailedSchedulingsApi - functional programming interface
 * @export
 */
export const DetailedSchedulingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DetailedSchedulingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} productId 
         * @param {string} since 
         * @param {string} until 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetProductDetailedSchedulingsIncludeEnum>} [include] 
         * @param {boolean} [ignoreAntecedence] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductDetailedSchedulings(productId: string, since: string, until: string, offset?: number, limit?: number, include?: Array<GetProductDetailedSchedulingsIncludeEnum>, ignoreAntecedence?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DetailedScheduling>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductDetailedSchedulings(productId, since, until, offset, limit, include, ignoreAntecedence, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DetailedSchedulingsApi.getProductDetailedSchedulings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DetailedSchedulingsApi - factory interface
 * @export
 */
export const DetailedSchedulingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DetailedSchedulingsApiFp(configuration)
    return {
        /**
         * 
         * @param {DetailedSchedulingsApiGetProductDetailedSchedulingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductDetailedSchedulings(requestParameters: DetailedSchedulingsApiGetProductDetailedSchedulingsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<DetailedScheduling>> {
            return localVarFp.getProductDetailedSchedulings(requestParameters.productId, requestParameters.since, requestParameters.until, requestParameters.offset, requestParameters.limit, requestParameters.include, requestParameters.ignoreAntecedence, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getProductDetailedSchedulings operation in DetailedSchedulingsApi.
 * @export
 * @interface DetailedSchedulingsApiGetProductDetailedSchedulingsRequest
 */
export interface DetailedSchedulingsApiGetProductDetailedSchedulingsRequest {
    /**
     * 
     * @type {string}
     * @memberof DetailedSchedulingsApiGetProductDetailedSchedulings
     */
    readonly productId: string

    /**
     * 
     * @type {string}
     * @memberof DetailedSchedulingsApiGetProductDetailedSchedulings
     */
    readonly since: string

    /**
     * 
     * @type {string}
     * @memberof DetailedSchedulingsApiGetProductDetailedSchedulings
     */
    readonly until: string

    /**
     * 
     * @type {number}
     * @memberof DetailedSchedulingsApiGetProductDetailedSchedulings
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof DetailedSchedulingsApiGetProductDetailedSchedulings
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<'finalTariffGroup.tariffs.type'>}
     * @memberof DetailedSchedulingsApiGetProductDetailedSchedulings
     */
    readonly include?: Array<GetProductDetailedSchedulingsIncludeEnum>

    /**
     * 
     * @type {boolean}
     * @memberof DetailedSchedulingsApiGetProductDetailedSchedulings
     */
    readonly ignoreAntecedence?: boolean
}

/**
 * DetailedSchedulingsApi - object-oriented interface
 * @export
 * @class DetailedSchedulingsApi
 * @extends {BaseAPI}
 */
export class DetailedSchedulingsApi extends BaseAPI {
    /**
     * 
     * @param {DetailedSchedulingsApiGetProductDetailedSchedulingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetailedSchedulingsApi
     */
    public getProductDetailedSchedulings(requestParameters: DetailedSchedulingsApiGetProductDetailedSchedulingsRequest, options?: RawAxiosRequestConfig) {
        return DetailedSchedulingsApiFp(this.configuration).getProductDetailedSchedulings(requestParameters.productId, requestParameters.since, requestParameters.until, requestParameters.offset, requestParameters.limit, requestParameters.include, requestParameters.ignoreAntecedence, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetProductDetailedSchedulingsIncludeEnum = {
    FinalTariffGroupTariffsType: 'finalTariffGroup.tariffs.type'
} as const;
export type GetProductDetailedSchedulingsIncludeEnum = typeof GetProductDetailedSchedulingsIncludeEnum[keyof typeof GetProductDetailedSchedulingsIncludeEnum];


/**
 * DirectLinksApi - axios parameter creator
 * @export
 */
export const DirectLinksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} directLinkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelDirectLink: async (directLinkId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'directLinkId' is not null or undefined
            assertParamExists('cancelDirectLink', 'directLinkId', directLinkId)
            const localVarPath = `/directLinks/{directLinkId}`
                .replace(`{${"directLinkId"}}`, encodeURIComponent(String(directLinkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {DirectLinkParams} directLinkParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppDirectLink: async (appId: string, directLinkParams: DirectLinkParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('createAppDirectLink', 'appId', appId)
            // verify required parameter 'directLinkParams' is not null or undefined
            assertParamExists('createAppDirectLink', 'directLinkParams', directLinkParams)
            const localVarPath = `/apps/{appId}/directLinks`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(directLinkParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetAppDirectLinksIncludeEnum>} [include] 
         * @param {Array<DirectLinkTypeEnum>} [types] 
         * @param {Array<string>} [ids] 
         * @param {GetAppDirectLinksSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppDirectLinks: async (appId: string, offset?: number, limit?: number, include?: Array<GetAppDirectLinksIncludeEnum>, types?: Array<DirectLinkTypeEnum>, ids?: Array<string>, sortBy?: GetAppDirectLinksSortByEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppDirectLinks', 'appId', appId)
            const localVarPath = `/apps/{appId}/directLinks`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (types) {
                localVarQueryParameter['types'] = types;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids.join(COLLECTION_FORMATS.csv);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} directLinkId 
         * @param {Array<GetDirectLinkIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDirectLink: async (directLinkId: string, include?: Array<GetDirectLinkIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'directLinkId' is not null or undefined
            assertParamExists('getDirectLink', 'directLinkId', directLinkId)
            const localVarPath = `/directLinks/{directLinkId}`
                .replace(`{${"directLinkId"}}`, encodeURIComponent(String(directLinkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DirectLinksApi - functional programming interface
 * @export
 */
export const DirectLinksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DirectLinksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} directLinkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelDirectLink(directLinkId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelDirectLink(directLinkId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DirectLinksApi.cancelDirectLink']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {DirectLinkParams} directLinkParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAppDirectLink(appId: string, directLinkParams: DirectLinkParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAppDirectLink(appId, directLinkParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DirectLinksApi.createAppDirectLink']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetAppDirectLinksIncludeEnum>} [include] 
         * @param {Array<DirectLinkTypeEnum>} [types] 
         * @param {Array<string>} [ids] 
         * @param {GetAppDirectLinksSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppDirectLinks(appId: string, offset?: number, limit?: number, include?: Array<GetAppDirectLinksIncludeEnum>, types?: Array<DirectLinkTypeEnum>, ids?: Array<string>, sortBy?: GetAppDirectLinksSortByEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DirectLink>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppDirectLinks(appId, offset, limit, include, types, ids, sortBy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DirectLinksApi.getAppDirectLinks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} directLinkId 
         * @param {Array<GetDirectLinkIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDirectLink(directLinkId: string, include?: Array<GetDirectLinkIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDirectLink(directLinkId, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DirectLinksApi.getDirectLink']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DirectLinksApi - factory interface
 * @export
 */
export const DirectLinksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DirectLinksApiFp(configuration)
    return {
        /**
         * 
         * @param {DirectLinksApiCancelDirectLinkRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelDirectLink(requestParameters: DirectLinksApiCancelDirectLinkRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cancelDirectLink(requestParameters.directLinkId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DirectLinksApiCreateAppDirectLinkRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppDirectLink(requestParameters: DirectLinksApiCreateAppDirectLinkRequest, options?: RawAxiosRequestConfig): AxiosPromise<DirectLink> {
            return localVarFp.createAppDirectLink(requestParameters.appId, requestParameters.directLinkParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DirectLinksApiGetAppDirectLinksRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppDirectLinks(requestParameters: DirectLinksApiGetAppDirectLinksRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<DirectLink>> {
            return localVarFp.getAppDirectLinks(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.include, requestParameters.types, requestParameters.ids, requestParameters.sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DirectLinksApiGetDirectLinkRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDirectLink(requestParameters: DirectLinksApiGetDirectLinkRequest, options?: RawAxiosRequestConfig): AxiosPromise<DirectLink> {
            return localVarFp.getDirectLink(requestParameters.directLinkId, requestParameters.include, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for cancelDirectLink operation in DirectLinksApi.
 * @export
 * @interface DirectLinksApiCancelDirectLinkRequest
 */
export interface DirectLinksApiCancelDirectLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof DirectLinksApiCancelDirectLink
     */
    readonly directLinkId: string
}

/**
 * Request parameters for createAppDirectLink operation in DirectLinksApi.
 * @export
 * @interface DirectLinksApiCreateAppDirectLinkRequest
 */
export interface DirectLinksApiCreateAppDirectLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof DirectLinksApiCreateAppDirectLink
     */
    readonly appId: string

    /**
     * 
     * @type {DirectLinkParams}
     * @memberof DirectLinksApiCreateAppDirectLink
     */
    readonly directLinkParams: DirectLinkParams
}

/**
 * Request parameters for getAppDirectLinks operation in DirectLinksApi.
 * @export
 * @interface DirectLinksApiGetAppDirectLinksRequest
 */
export interface DirectLinksApiGetAppDirectLinksRequest {
    /**
     * 
     * @type {string}
     * @memberof DirectLinksApiGetAppDirectLinks
     */
    readonly appId: string

    /**
     * 
     * @type {number}
     * @memberof DirectLinksApiGetAppDirectLinks
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof DirectLinksApiGetAppDirectLinks
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<'customer' | 'sale' | 'sale.coupon' | 'sale.coupon.products' | 'sale.customer' | 'sale.origin' | 'moldCart' | 'moldCart.coupon' | 'origin'>}
     * @memberof DirectLinksApiGetAppDirectLinks
     */
    readonly include?: Array<GetAppDirectLinksIncludeEnum>

    /**
     * 
     * @type {Array<DirectLinkTypeEnum>}
     * @memberof DirectLinksApiGetAppDirectLinks
     */
    readonly types?: Array<DirectLinkTypeEnum>

    /**
     * 
     * @type {Array<string>}
     * @memberof DirectLinksApiGetAppDirectLinks
     */
    readonly ids?: Array<string>

    /**
     * 
     * @type {'recent'}
     * @memberof DirectLinksApiGetAppDirectLinks
     */
    readonly sortBy?: GetAppDirectLinksSortByEnum
}

/**
 * Request parameters for getDirectLink operation in DirectLinksApi.
 * @export
 * @interface DirectLinksApiGetDirectLinkRequest
 */
export interface DirectLinksApiGetDirectLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof DirectLinksApiGetDirectLink
     */
    readonly directLinkId: string

    /**
     * 
     * @type {Array<'customer' | 'sale' | 'sale.coupon' | 'sale.coupon.products' | 'sale.customer' | 'sale.origin' | 'moldCart' | 'moldCart.coupon' | 'origin'>}
     * @memberof DirectLinksApiGetDirectLink
     */
    readonly include?: Array<GetDirectLinkIncludeEnum>
}

/**
 * DirectLinksApi - object-oriented interface
 * @export
 * @class DirectLinksApi
 * @extends {BaseAPI}
 */
export class DirectLinksApi extends BaseAPI {
    /**
     * 
     * @param {DirectLinksApiCancelDirectLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLinksApi
     */
    public cancelDirectLink(requestParameters: DirectLinksApiCancelDirectLinkRequest, options?: RawAxiosRequestConfig) {
        return DirectLinksApiFp(this.configuration).cancelDirectLink(requestParameters.directLinkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DirectLinksApiCreateAppDirectLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLinksApi
     */
    public createAppDirectLink(requestParameters: DirectLinksApiCreateAppDirectLinkRequest, options?: RawAxiosRequestConfig) {
        return DirectLinksApiFp(this.configuration).createAppDirectLink(requestParameters.appId, requestParameters.directLinkParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DirectLinksApiGetAppDirectLinksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLinksApi
     */
    public getAppDirectLinks(requestParameters: DirectLinksApiGetAppDirectLinksRequest, options?: RawAxiosRequestConfig) {
        return DirectLinksApiFp(this.configuration).getAppDirectLinks(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.include, requestParameters.types, requestParameters.ids, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DirectLinksApiGetDirectLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLinksApi
     */
    public getDirectLink(requestParameters: DirectLinksApiGetDirectLinkRequest, options?: RawAxiosRequestConfig) {
        return DirectLinksApiFp(this.configuration).getDirectLink(requestParameters.directLinkId, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAppDirectLinksIncludeEnum = {
    Customer: 'customer',
    Sale: 'sale',
    SaleCoupon: 'sale.coupon',
    SaleCouponProducts: 'sale.coupon.products',
    SaleCustomer: 'sale.customer',
    SaleOrigin: 'sale.origin',
    MoldCart: 'moldCart',
    MoldCartCoupon: 'moldCart.coupon',
    Origin: 'origin'
} as const;
export type GetAppDirectLinksIncludeEnum = typeof GetAppDirectLinksIncludeEnum[keyof typeof GetAppDirectLinksIncludeEnum];
/**
 * @export
 */
export const GetAppDirectLinksSortByEnum = {
    Recent: 'recent'
} as const;
export type GetAppDirectLinksSortByEnum = typeof GetAppDirectLinksSortByEnum[keyof typeof GetAppDirectLinksSortByEnum];
/**
 * @export
 */
export const GetDirectLinkIncludeEnum = {
    Customer: 'customer',
    Sale: 'sale',
    SaleCoupon: 'sale.coupon',
    SaleCouponProducts: 'sale.coupon.products',
    SaleCustomer: 'sale.customer',
    SaleOrigin: 'sale.origin',
    MoldCart: 'moldCart',
    MoldCartCoupon: 'moldCart.coupon',
    Origin: 'origin'
} as const;
export type GetDirectLinkIncludeEnum = typeof GetDirectLinkIncludeEnum[keyof typeof GetDirectLinkIncludeEnum];


/**
 * DirectSalesApi - axios parameter creator
 * @export
 */
export const DirectSalesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<SaleStateEnum>} [currentState] 
         * @param {string | null} [since] 
         * @param {string | null} [until] 
         * @param {Array<string>} [appIds] 
         * @param {Array<string>} [productIds] 
         * @param {Array<GetAppDirectSalesIncludeEnum>} [include] 
         * @param {GetAppDirectSalesSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppDirectSales: async (appId: string, offset?: number, limit?: number, currentState?: Array<SaleStateEnum>, since?: string | null, until?: string | null, appIds?: Array<string>, productIds?: Array<string>, include?: Array<GetAppDirectSalesIncludeEnum>, sortBy?: GetAppDirectSalesSortByEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppDirectSales', 'appId', appId)
            const localVarPath = `/apps/{appId}/directSales`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (currentState) {
                localVarQueryParameter['currentState'] = currentState.join(COLLECTION_FORMATS.csv);
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString() :
                    since;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = (until as any instanceof Date) ?
                    (until as any).toISOString() :
                    until;
            }

            if (appIds) {
                localVarQueryParameter['appIds'] = appIds.join(COLLECTION_FORMATS.csv);
            }

            if (productIds) {
                localVarQueryParameter['productIds'] = productIds.join(COLLECTION_FORMATS.csv);
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} saleId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetDetailedDirectSaleItemsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedDirectSaleItems: async (saleId: string, offset?: number, limit?: number, include?: Array<GetDetailedDirectSaleItemsIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('getDetailedDirectSaleItems', 'saleId', saleId)
            const localVarPath = `/directSales/{saleId}/detailedItems`
                .replace(`{${"saleId"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} saleId 
         * @param {Array<GetDirectSaleIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDirectSale: async (saleId: string, include?: Array<GetDirectSaleIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('getDirectSale', 'saleId', saleId)
            const localVarPath = `/directSales/{saleId}`
                .replace(`{${"saleId"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} saleId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDirectSalePaymentOptions: async (saleId: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('getDirectSalePaymentOptions', 'saleId', saleId)
            const localVarPath = `/directSales/{saleId}/paymentOptions`
                .replace(`{${"saleId"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DirectSalesApi - functional programming interface
 * @export
 */
export const DirectSalesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DirectSalesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<SaleStateEnum>} [currentState] 
         * @param {string | null} [since] 
         * @param {string | null} [until] 
         * @param {Array<string>} [appIds] 
         * @param {Array<string>} [productIds] 
         * @param {Array<GetAppDirectSalesIncludeEnum>} [include] 
         * @param {GetAppDirectSalesSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppDirectSales(appId: string, offset?: number, limit?: number, currentState?: Array<SaleStateEnum>, since?: string | null, until?: string | null, appIds?: Array<string>, productIds?: Array<string>, include?: Array<GetAppDirectSalesIncludeEnum>, sortBy?: GetAppDirectSalesSortByEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DirectSale>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppDirectSales(appId, offset, limit, currentState, since, until, appIds, productIds, include, sortBy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DirectSalesApi.getAppDirectSales']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} saleId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetDetailedDirectSaleItemsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetailedDirectSaleItems(saleId: string, offset?: number, limit?: number, include?: Array<GetDetailedDirectSaleItemsIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DetailedDirectSaleItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetailedDirectSaleItems(saleId, offset, limit, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DirectSalesApi.getDetailedDirectSaleItems']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} saleId 
         * @param {Array<GetDirectSaleIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDirectSale(saleId: string, include?: Array<GetDirectSaleIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectSale>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDirectSale(saleId, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DirectSalesApi.getDirectSale']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} saleId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDirectSalePaymentOptions(saleId: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PaymentOption>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDirectSalePaymentOptions(saleId, offset, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DirectSalesApi.getDirectSalePaymentOptions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DirectSalesApi - factory interface
 * @export
 */
export const DirectSalesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DirectSalesApiFp(configuration)
    return {
        /**
         * 
         * @param {DirectSalesApiGetAppDirectSalesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppDirectSales(requestParameters: DirectSalesApiGetAppDirectSalesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<DirectSale>> {
            return localVarFp.getAppDirectSales(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.currentState, requestParameters.since, requestParameters.until, requestParameters.appIds, requestParameters.productIds, requestParameters.include, requestParameters.sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DirectSalesApiGetDetailedDirectSaleItemsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedDirectSaleItems(requestParameters: DirectSalesApiGetDetailedDirectSaleItemsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<DetailedDirectSaleItem>> {
            return localVarFp.getDetailedDirectSaleItems(requestParameters.saleId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DirectSalesApiGetDirectSaleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDirectSale(requestParameters: DirectSalesApiGetDirectSaleRequest, options?: RawAxiosRequestConfig): AxiosPromise<DirectSale> {
            return localVarFp.getDirectSale(requestParameters.saleId, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DirectSalesApiGetDirectSalePaymentOptionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDirectSalePaymentOptions(requestParameters: DirectSalesApiGetDirectSalePaymentOptionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<PaymentOption>> {
            return localVarFp.getDirectSalePaymentOptions(requestParameters.saleId, requestParameters.offset, requestParameters.limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAppDirectSales operation in DirectSalesApi.
 * @export
 * @interface DirectSalesApiGetAppDirectSalesRequest
 */
export interface DirectSalesApiGetAppDirectSalesRequest {
    /**
     * 
     * @type {string}
     * @memberof DirectSalesApiGetAppDirectSales
     */
    readonly appId: string

    /**
     * 
     * @type {number}
     * @memberof DirectSalesApiGetAppDirectSales
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof DirectSalesApiGetAppDirectSales
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<SaleStateEnum>}
     * @memberof DirectSalesApiGetAppDirectSales
     */
    readonly currentState?: Array<SaleStateEnum>

    /**
     * 
     * @type {string}
     * @memberof DirectSalesApiGetAppDirectSales
     */
    readonly since?: string | null

    /**
     * 
     * @type {string}
     * @memberof DirectSalesApiGetAppDirectSales
     */
    readonly until?: string | null

    /**
     * 
     * @type {Array<string>}
     * @memberof DirectSalesApiGetAppDirectSales
     */
    readonly appIds?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof DirectSalesApiGetAppDirectSales
     */
    readonly productIds?: Array<string>

    /**
     * 
     * @type {Array<'app' | 'customer' | 'aggregatedVouchers'>}
     * @memberof DirectSalesApiGetAppDirectSales
     */
    readonly include?: Array<GetAppDirectSalesIncludeEnum>

    /**
     * 
     * @type {'recent'}
     * @memberof DirectSalesApiGetAppDirectSales
     */
    readonly sortBy?: GetAppDirectSalesSortByEnum
}

/**
 * Request parameters for getDetailedDirectSaleItems operation in DirectSalesApi.
 * @export
 * @interface DirectSalesApiGetDetailedDirectSaleItemsRequest
 */
export interface DirectSalesApiGetDetailedDirectSaleItemsRequest {
    /**
     * 
     * @type {string}
     * @memberof DirectSalesApiGetDetailedDirectSaleItems
     */
    readonly saleId: string

    /**
     * 
     * @type {number}
     * @memberof DirectSalesApiGetDetailedDirectSaleItems
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof DirectSalesApiGetDetailedDirectSaleItems
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<'product' | 'sale' | 'partnershipItem.product' | 'partnershipItem.partnership' | 'partnershipItem.partnership.provider' | 'partnershipItem.partnership.agent' | 'partnershipItem.partnership.publicProposal'>}
     * @memberof DirectSalesApiGetDetailedDirectSaleItems
     */
    readonly include?: Array<GetDetailedDirectSaleItemsIncludeEnum>
}

/**
 * Request parameters for getDirectSale operation in DirectSalesApi.
 * @export
 * @interface DirectSalesApiGetDirectSaleRequest
 */
export interface DirectSalesApiGetDirectSaleRequest {
    /**
     * 
     * @type {string}
     * @memberof DirectSalesApiGetDirectSale
     */
    readonly saleId: string

    /**
     * 
     * @type {Array<'app' | 'customer' | 'aggregatedVouchers'>}
     * @memberof DirectSalesApiGetDirectSale
     */
    readonly include?: Array<GetDirectSaleIncludeEnum>
}

/**
 * Request parameters for getDirectSalePaymentOptions operation in DirectSalesApi.
 * @export
 * @interface DirectSalesApiGetDirectSalePaymentOptionsRequest
 */
export interface DirectSalesApiGetDirectSalePaymentOptionsRequest {
    /**
     * 
     * @type {string}
     * @memberof DirectSalesApiGetDirectSalePaymentOptions
     */
    readonly saleId: string

    /**
     * 
     * @type {number}
     * @memberof DirectSalesApiGetDirectSalePaymentOptions
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof DirectSalesApiGetDirectSalePaymentOptions
     */
    readonly limit?: number
}

/**
 * DirectSalesApi - object-oriented interface
 * @export
 * @class DirectSalesApi
 * @extends {BaseAPI}
 */
export class DirectSalesApi extends BaseAPI {
    /**
     * 
     * @param {DirectSalesApiGetAppDirectSalesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectSalesApi
     */
    public getAppDirectSales(requestParameters: DirectSalesApiGetAppDirectSalesRequest, options?: RawAxiosRequestConfig) {
        return DirectSalesApiFp(this.configuration).getAppDirectSales(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.currentState, requestParameters.since, requestParameters.until, requestParameters.appIds, requestParameters.productIds, requestParameters.include, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DirectSalesApiGetDetailedDirectSaleItemsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectSalesApi
     */
    public getDetailedDirectSaleItems(requestParameters: DirectSalesApiGetDetailedDirectSaleItemsRequest, options?: RawAxiosRequestConfig) {
        return DirectSalesApiFp(this.configuration).getDetailedDirectSaleItems(requestParameters.saleId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DirectSalesApiGetDirectSaleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectSalesApi
     */
    public getDirectSale(requestParameters: DirectSalesApiGetDirectSaleRequest, options?: RawAxiosRequestConfig) {
        return DirectSalesApiFp(this.configuration).getDirectSale(requestParameters.saleId, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DirectSalesApiGetDirectSalePaymentOptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectSalesApi
     */
    public getDirectSalePaymentOptions(requestParameters: DirectSalesApiGetDirectSalePaymentOptionsRequest, options?: RawAxiosRequestConfig) {
        return DirectSalesApiFp(this.configuration).getDirectSalePaymentOptions(requestParameters.saleId, requestParameters.offset, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAppDirectSalesIncludeEnum = {
    App: 'app',
    Customer: 'customer',
    AggregatedVouchers: 'aggregatedVouchers'
} as const;
export type GetAppDirectSalesIncludeEnum = typeof GetAppDirectSalesIncludeEnum[keyof typeof GetAppDirectSalesIncludeEnum];
/**
 * @export
 */
export const GetAppDirectSalesSortByEnum = {
    Recent: 'recent'
} as const;
export type GetAppDirectSalesSortByEnum = typeof GetAppDirectSalesSortByEnum[keyof typeof GetAppDirectSalesSortByEnum];
/**
 * @export
 */
export const GetDetailedDirectSaleItemsIncludeEnum = {
    Product: 'product',
    Sale: 'sale',
    PartnershipItemProduct: 'partnershipItem.product',
    PartnershipItemPartnership: 'partnershipItem.partnership',
    PartnershipItemPartnershipProvider: 'partnershipItem.partnership.provider',
    PartnershipItemPartnershipAgent: 'partnershipItem.partnership.agent',
    PartnershipItemPartnershipPublicProposal: 'partnershipItem.partnership.publicProposal'
} as const;
export type GetDetailedDirectSaleItemsIncludeEnum = typeof GetDetailedDirectSaleItemsIncludeEnum[keyof typeof GetDetailedDirectSaleItemsIncludeEnum];
/**
 * @export
 */
export const GetDirectSaleIncludeEnum = {
    App: 'app',
    Customer: 'customer',
    AggregatedVouchers: 'aggregatedVouchers'
} as const;
export type GetDirectSaleIncludeEnum = typeof GetDirectSaleIncludeEnum[keyof typeof GetDirectSaleIncludeEnum];


/**
 * EstablishmentTypesApi - axios parameter creator
 * @export
 */
export const EstablishmentTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstablishmentTypes: async (offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/establishmentTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EstablishmentTypesApi - functional programming interface
 * @export
 */
export const EstablishmentTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EstablishmentTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEstablishmentTypes(offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EstablishmentType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEstablishmentTypes(offset, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EstablishmentTypesApi.getEstablishmentTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EstablishmentTypesApi - factory interface
 * @export
 */
export const EstablishmentTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EstablishmentTypesApiFp(configuration)
    return {
        /**
         * 
         * @param {EstablishmentTypesApiGetEstablishmentTypesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstablishmentTypes(requestParameters: EstablishmentTypesApiGetEstablishmentTypesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<EstablishmentType>> {
            return localVarFp.getEstablishmentTypes(requestParameters.offset, requestParameters.limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getEstablishmentTypes operation in EstablishmentTypesApi.
 * @export
 * @interface EstablishmentTypesApiGetEstablishmentTypesRequest
 */
export interface EstablishmentTypesApiGetEstablishmentTypesRequest {
    /**
     * 
     * @type {number}
     * @memberof EstablishmentTypesApiGetEstablishmentTypes
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof EstablishmentTypesApiGetEstablishmentTypes
     */
    readonly limit?: number
}

/**
 * EstablishmentTypesApi - object-oriented interface
 * @export
 * @class EstablishmentTypesApi
 * @extends {BaseAPI}
 */
export class EstablishmentTypesApi extends BaseAPI {
    /**
     * 
     * @param {EstablishmentTypesApiGetEstablishmentTypesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstablishmentTypesApi
     */
    public getEstablishmentTypes(requestParameters: EstablishmentTypesApiGetEstablishmentTypesRequest = {}, options?: RawAxiosRequestConfig) {
        return EstablishmentTypesApiFp(this.configuration).getEstablishmentTypes(requestParameters.offset, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EventsApi - axios parameter creator
 * @export
 */
export const EventsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} appEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acknowledgeAppEvent: async (appEventId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appEventId' is not null or undefined
            assertParamExists('acknowledgeAppEvent', 'appEventId', appEventId)
            const localVarPath = `/events/{appEventId}/ack`
                .replace(`{${"appEventId"}}`, encodeURIComponent(String(appEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string | null} [since] 
         * @param {string | null} [until] 
         * @param {GetAppEventsSortByEnum} [sortBy] 
         * @param {Array<AppEventTypeEnum>} [eventTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppEvents: async (appId: string, offset?: number, limit?: number, since?: string | null, until?: string | null, sortBy?: GetAppEventsSortByEnum, eventTypes?: Array<AppEventTypeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppEvents', 'appId', appId)
            const localVarPath = `/apps/{appId}/events`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString() :
                    since;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = (until as any instanceof Date) ?
                    (until as any).toISOString() :
                    until;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (eventTypes) {
                localVarQueryParameter['eventTypes'] = eventTypes.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventsApi - functional programming interface
 * @export
 */
export const EventsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} appEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acknowledgeAppEvent(appEventId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppEvent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acknowledgeAppEvent(appEventId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventsApi.acknowledgeAppEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string | null} [since] 
         * @param {string | null} [until] 
         * @param {GetAppEventsSortByEnum} [sortBy] 
         * @param {Array<AppEventTypeEnum>} [eventTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppEvents(appId: string, offset?: number, limit?: number, since?: string | null, until?: string | null, sortBy?: GetAppEventsSortByEnum, eventTypes?: Array<AppEventTypeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppEvent>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppEvents(appId, offset, limit, since, until, sortBy, eventTypes, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventsApi.getAppEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EventsApi - factory interface
 * @export
 */
export const EventsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventsApiFp(configuration)
    return {
        /**
         * 
         * @param {EventsApiAcknowledgeAppEventRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acknowledgeAppEvent(requestParameters: EventsApiAcknowledgeAppEventRequest, options?: RawAxiosRequestConfig): AxiosPromise<AppEvent> {
            return localVarFp.acknowledgeAppEvent(requestParameters.appEventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EventsApiGetAppEventsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppEvents(requestParameters: EventsApiGetAppEventsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<AppEvent>> {
            return localVarFp.getAppEvents(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.since, requestParameters.until, requestParameters.sortBy, requestParameters.eventTypes, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for acknowledgeAppEvent operation in EventsApi.
 * @export
 * @interface EventsApiAcknowledgeAppEventRequest
 */
export interface EventsApiAcknowledgeAppEventRequest {
    /**
     * 
     * @type {string}
     * @memberof EventsApiAcknowledgeAppEvent
     */
    readonly appEventId: string
}

/**
 * Request parameters for getAppEvents operation in EventsApi.
 * @export
 * @interface EventsApiGetAppEventsRequest
 */
export interface EventsApiGetAppEventsRequest {
    /**
     * 
     * @type {string}
     * @memberof EventsApiGetAppEvents
     */
    readonly appId: string

    /**
     * 
     * @type {number}
     * @memberof EventsApiGetAppEvents
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof EventsApiGetAppEvents
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof EventsApiGetAppEvents
     */
    readonly since?: string | null

    /**
     * 
     * @type {string}
     * @memberof EventsApiGetAppEvents
     */
    readonly until?: string | null

    /**
     * 
     * @type {'recent' | 'oldest'}
     * @memberof EventsApiGetAppEvents
     */
    readonly sortBy?: GetAppEventsSortByEnum

    /**
     * 
     * @type {Array<AppEventTypeEnum>}
     * @memberof EventsApiGetAppEvents
     */
    readonly eventTypes?: Array<AppEventTypeEnum>
}

/**
 * EventsApi - object-oriented interface
 * @export
 * @class EventsApi
 * @extends {BaseAPI}
 */
export class EventsApi extends BaseAPI {
    /**
     * 
     * @param {EventsApiAcknowledgeAppEventRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public acknowledgeAppEvent(requestParameters: EventsApiAcknowledgeAppEventRequest, options?: RawAxiosRequestConfig) {
        return EventsApiFp(this.configuration).acknowledgeAppEvent(requestParameters.appEventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EventsApiGetAppEventsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public getAppEvents(requestParameters: EventsApiGetAppEventsRequest, options?: RawAxiosRequestConfig) {
        return EventsApiFp(this.configuration).getAppEvents(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.since, requestParameters.until, requestParameters.sortBy, requestParameters.eventTypes, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAppEventsSortByEnum = {
    Recent: 'recent',
    Oldest: 'oldest'
} as const;
export type GetAppEventsSortByEnum = typeof GetAppEventsSortByEnum[keyof typeof GetAppEventsSortByEnum];


/**
 * ImagesApi - axios parameter creator
 * @export
 */
export const ImagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} imageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImage: async (imageId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('getImage', 'imageId', imageId)
            const localVarPath = `/images/{imageId}`
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImagesApi - functional programming interface
 * @export
 */
export const ImagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} imageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImage(imageId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Image>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImage(imageId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImagesApi.getImage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ImagesApi - factory interface
 * @export
 */
export const ImagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImagesApiFp(configuration)
    return {
        /**
         * 
         * @param {ImagesApiGetImageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImage(requestParameters: ImagesApiGetImageRequest, options?: RawAxiosRequestConfig): AxiosPromise<Image> {
            return localVarFp.getImage(requestParameters.imageId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getImage operation in ImagesApi.
 * @export
 * @interface ImagesApiGetImageRequest
 */
export interface ImagesApiGetImageRequest {
    /**
     * 
     * @type {string}
     * @memberof ImagesApiGetImage
     */
    readonly imageId: string
}

/**
 * ImagesApi - object-oriented interface
 * @export
 * @class ImagesApi
 * @extends {BaseAPI}
 */
export class ImagesApi extends BaseAPI {
    /**
     * 
     * @param {ImagesApiGetImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public getImage(requestParameters: ImagesApiGetImageRequest, options?: RawAxiosRequestConfig) {
        return ImagesApiFp(this.configuration).getImage(requestParameters.imageId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * IncomeSchedulingsApi - axios parameter creator
 * @export
 */
export const IncomeSchedulingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string | null} [since] 
         * @param {string | null} [until] 
         * @param {GetAppIncomeSchedulingsDateFieldEnum} [dateField] 
         * @param {GetAppIncomeSchedulingsSortByEnum} [sortBy] 
         * @param {GetAppIncomeSchedulingsSortDirectionEnum} [sortDirection] 
         * @param {Array<GetAppIncomeSchedulingsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppIncomeSchedulings: async (appId: string, offset?: number, limit?: number, since?: string | null, until?: string | null, dateField?: GetAppIncomeSchedulingsDateFieldEnum, sortBy?: GetAppIncomeSchedulingsSortByEnum, sortDirection?: GetAppIncomeSchedulingsSortDirectionEnum, include?: Array<GetAppIncomeSchedulingsIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppIncomeSchedulings', 'appId', appId)
            const localVarPath = `/apps/{appId}/incomeSchedulings`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString() :
                    since;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = (until as any instanceof Date) ?
                    (until as any).toISOString() :
                    until;
            }

            if (dateField !== undefined) {
                localVarQueryParameter['dateField'] = dateField;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} saleId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetSaleIncomeSchedulingsIncludeEnum>} [include] 
         * @param {GetSaleIncomeSchedulingsSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSaleIncomeSchedulings: async (saleId: string, offset?: number, limit?: number, include?: Array<GetSaleIncomeSchedulingsIncludeEnum>, sortBy?: GetSaleIncomeSchedulingsSortByEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('getSaleIncomeSchedulings', 'saleId', saleId)
            const localVarPath = `/sales/{saleId}/incomeSchedulings`
                .replace(`{${"saleId"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} transactionId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetTransactionIncomeSchedulingsIncludeEnum>} [include] 
         * @param {GetTransactionIncomeSchedulingsSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionIncomeSchedulings: async (transactionId: string, offset?: number, limit?: number, include?: Array<GetTransactionIncomeSchedulingsIncludeEnum>, sortBy?: GetTransactionIncomeSchedulingsSortByEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('getTransactionIncomeSchedulings', 'transactionId', transactionId)
            const localVarPath = `/transactions/{transactionId}/incomeSchedulings`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IncomeSchedulingsApi - functional programming interface
 * @export
 */
export const IncomeSchedulingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IncomeSchedulingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string | null} [since] 
         * @param {string | null} [until] 
         * @param {GetAppIncomeSchedulingsDateFieldEnum} [dateField] 
         * @param {GetAppIncomeSchedulingsSortByEnum} [sortBy] 
         * @param {GetAppIncomeSchedulingsSortDirectionEnum} [sortDirection] 
         * @param {Array<GetAppIncomeSchedulingsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppIncomeSchedulings(appId: string, offset?: number, limit?: number, since?: string | null, until?: string | null, dateField?: GetAppIncomeSchedulingsDateFieldEnum, sortBy?: GetAppIncomeSchedulingsSortByEnum, sortDirection?: GetAppIncomeSchedulingsSortDirectionEnum, include?: Array<GetAppIncomeSchedulingsIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IncomeScheduling>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppIncomeSchedulings(appId, offset, limit, since, until, dateField, sortBy, sortDirection, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IncomeSchedulingsApi.getAppIncomeSchedulings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} saleId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetSaleIncomeSchedulingsIncludeEnum>} [include] 
         * @param {GetSaleIncomeSchedulingsSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSaleIncomeSchedulings(saleId: string, offset?: number, limit?: number, include?: Array<GetSaleIncomeSchedulingsIncludeEnum>, sortBy?: GetSaleIncomeSchedulingsSortByEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IncomeScheduling>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSaleIncomeSchedulings(saleId, offset, limit, include, sortBy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IncomeSchedulingsApi.getSaleIncomeSchedulings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} transactionId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetTransactionIncomeSchedulingsIncludeEnum>} [include] 
         * @param {GetTransactionIncomeSchedulingsSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactionIncomeSchedulings(transactionId: string, offset?: number, limit?: number, include?: Array<GetTransactionIncomeSchedulingsIncludeEnum>, sortBy?: GetTransactionIncomeSchedulingsSortByEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IncomeScheduling>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransactionIncomeSchedulings(transactionId, offset, limit, include, sortBy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IncomeSchedulingsApi.getTransactionIncomeSchedulings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * IncomeSchedulingsApi - factory interface
 * @export
 */
export const IncomeSchedulingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IncomeSchedulingsApiFp(configuration)
    return {
        /**
         * 
         * @param {IncomeSchedulingsApiGetAppIncomeSchedulingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppIncomeSchedulings(requestParameters: IncomeSchedulingsApiGetAppIncomeSchedulingsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<IncomeScheduling>> {
            return localVarFp.getAppIncomeSchedulings(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.since, requestParameters.until, requestParameters.dateField, requestParameters.sortBy, requestParameters.sortDirection, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IncomeSchedulingsApiGetSaleIncomeSchedulingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSaleIncomeSchedulings(requestParameters: IncomeSchedulingsApiGetSaleIncomeSchedulingsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<IncomeScheduling>> {
            return localVarFp.getSaleIncomeSchedulings(requestParameters.saleId, requestParameters.offset, requestParameters.limit, requestParameters.include, requestParameters.sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IncomeSchedulingsApiGetTransactionIncomeSchedulingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionIncomeSchedulings(requestParameters: IncomeSchedulingsApiGetTransactionIncomeSchedulingsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<IncomeScheduling>> {
            return localVarFp.getTransactionIncomeSchedulings(requestParameters.transactionId, requestParameters.offset, requestParameters.limit, requestParameters.include, requestParameters.sortBy, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAppIncomeSchedulings operation in IncomeSchedulingsApi.
 * @export
 * @interface IncomeSchedulingsApiGetAppIncomeSchedulingsRequest
 */
export interface IncomeSchedulingsApiGetAppIncomeSchedulingsRequest {
    /**
     * 
     * @type {string}
     * @memberof IncomeSchedulingsApiGetAppIncomeSchedulings
     */
    readonly appId: string

    /**
     * 
     * @type {number}
     * @memberof IncomeSchedulingsApiGetAppIncomeSchedulings
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof IncomeSchedulingsApiGetAppIncomeSchedulings
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof IncomeSchedulingsApiGetAppIncomeSchedulings
     */
    readonly since?: string | null

    /**
     * 
     * @type {string}
     * @memberof IncomeSchedulingsApiGetAppIncomeSchedulings
     */
    readonly until?: string | null

    /**
     * 
     * @type {'scheduledAt' | 'plannedAt' | 'createdAt'}
     * @memberof IncomeSchedulingsApiGetAppIncomeSchedulings
     */
    readonly dateField?: GetAppIncomeSchedulingsDateFieldEnum

    /**
     * 
     * @type {'oldestScheduledAt' | 'createdAt' | 'updatedAt'}
     * @memberof IncomeSchedulingsApiGetAppIncomeSchedulings
     */
    readonly sortBy?: GetAppIncomeSchedulingsSortByEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof IncomeSchedulingsApiGetAppIncomeSchedulings
     */
    readonly sortDirection?: GetAppIncomeSchedulingsSortDirectionEnum

    /**
     * 
     * @type {Array<'transaction' | 'transaction.paymentMethod' | 'transaction.paymentGateway' | 'transaction.sale' | 'transaction.sale.customer' | 'transaction.payment' | 'transaction.directLink' | 'transaction.order'>}
     * @memberof IncomeSchedulingsApiGetAppIncomeSchedulings
     */
    readonly include?: Array<GetAppIncomeSchedulingsIncludeEnum>
}

/**
 * Request parameters for getSaleIncomeSchedulings operation in IncomeSchedulingsApi.
 * @export
 * @interface IncomeSchedulingsApiGetSaleIncomeSchedulingsRequest
 */
export interface IncomeSchedulingsApiGetSaleIncomeSchedulingsRequest {
    /**
     * 
     * @type {string}
     * @memberof IncomeSchedulingsApiGetSaleIncomeSchedulings
     */
    readonly saleId: string

    /**
     * 
     * @type {number}
     * @memberof IncomeSchedulingsApiGetSaleIncomeSchedulings
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof IncomeSchedulingsApiGetSaleIncomeSchedulings
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<'transaction' | 'transaction.paymentMethod' | 'transaction.paymentGateway' | 'transaction.sale' | 'transaction.sale.customer' | 'transaction.payment' | 'transaction.directLink' | 'transaction.order'>}
     * @memberof IncomeSchedulingsApiGetSaleIncomeSchedulings
     */
    readonly include?: Array<GetSaleIncomeSchedulingsIncludeEnum>

    /**
     * 
     * @type {'oldestScheduledAt' | 'createdAt' | 'updatedAt'}
     * @memberof IncomeSchedulingsApiGetSaleIncomeSchedulings
     */
    readonly sortBy?: GetSaleIncomeSchedulingsSortByEnum
}

/**
 * Request parameters for getTransactionIncomeSchedulings operation in IncomeSchedulingsApi.
 * @export
 * @interface IncomeSchedulingsApiGetTransactionIncomeSchedulingsRequest
 */
export interface IncomeSchedulingsApiGetTransactionIncomeSchedulingsRequest {
    /**
     * 
     * @type {string}
     * @memberof IncomeSchedulingsApiGetTransactionIncomeSchedulings
     */
    readonly transactionId: string

    /**
     * 
     * @type {number}
     * @memberof IncomeSchedulingsApiGetTransactionIncomeSchedulings
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof IncomeSchedulingsApiGetTransactionIncomeSchedulings
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<'transaction' | 'transaction.paymentMethod' | 'transaction.paymentGateway' | 'transaction.sale' | 'transaction.sale.customer' | 'transaction.payment' | 'transaction.directLink' | 'transaction.order'>}
     * @memberof IncomeSchedulingsApiGetTransactionIncomeSchedulings
     */
    readonly include?: Array<GetTransactionIncomeSchedulingsIncludeEnum>

    /**
     * 
     * @type {'oldestScheduledAt' | 'createdAt' | 'updatedAt'}
     * @memberof IncomeSchedulingsApiGetTransactionIncomeSchedulings
     */
    readonly sortBy?: GetTransactionIncomeSchedulingsSortByEnum
}

/**
 * IncomeSchedulingsApi - object-oriented interface
 * @export
 * @class IncomeSchedulingsApi
 * @extends {BaseAPI}
 */
export class IncomeSchedulingsApi extends BaseAPI {
    /**
     * 
     * @param {IncomeSchedulingsApiGetAppIncomeSchedulingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomeSchedulingsApi
     */
    public getAppIncomeSchedulings(requestParameters: IncomeSchedulingsApiGetAppIncomeSchedulingsRequest, options?: RawAxiosRequestConfig) {
        return IncomeSchedulingsApiFp(this.configuration).getAppIncomeSchedulings(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.since, requestParameters.until, requestParameters.dateField, requestParameters.sortBy, requestParameters.sortDirection, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IncomeSchedulingsApiGetSaleIncomeSchedulingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomeSchedulingsApi
     */
    public getSaleIncomeSchedulings(requestParameters: IncomeSchedulingsApiGetSaleIncomeSchedulingsRequest, options?: RawAxiosRequestConfig) {
        return IncomeSchedulingsApiFp(this.configuration).getSaleIncomeSchedulings(requestParameters.saleId, requestParameters.offset, requestParameters.limit, requestParameters.include, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IncomeSchedulingsApiGetTransactionIncomeSchedulingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncomeSchedulingsApi
     */
    public getTransactionIncomeSchedulings(requestParameters: IncomeSchedulingsApiGetTransactionIncomeSchedulingsRequest, options?: RawAxiosRequestConfig) {
        return IncomeSchedulingsApiFp(this.configuration).getTransactionIncomeSchedulings(requestParameters.transactionId, requestParameters.offset, requestParameters.limit, requestParameters.include, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAppIncomeSchedulingsDateFieldEnum = {
    ScheduledAt: 'scheduledAt',
    PlannedAt: 'plannedAt',
    CreatedAt: 'createdAt'
} as const;
export type GetAppIncomeSchedulingsDateFieldEnum = typeof GetAppIncomeSchedulingsDateFieldEnum[keyof typeof GetAppIncomeSchedulingsDateFieldEnum];
/**
 * @export
 */
export const GetAppIncomeSchedulingsSortByEnum = {
    OldestScheduledAt: 'oldestScheduledAt',
    CreatedAt: 'createdAt',
    UpdatedAt: 'updatedAt'
} as const;
export type GetAppIncomeSchedulingsSortByEnum = typeof GetAppIncomeSchedulingsSortByEnum[keyof typeof GetAppIncomeSchedulingsSortByEnum];
/**
 * @export
 */
export const GetAppIncomeSchedulingsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetAppIncomeSchedulingsSortDirectionEnum = typeof GetAppIncomeSchedulingsSortDirectionEnum[keyof typeof GetAppIncomeSchedulingsSortDirectionEnum];
/**
 * @export
 */
export const GetAppIncomeSchedulingsIncludeEnum = {
    Transaction: 'transaction',
    TransactionPaymentMethod: 'transaction.paymentMethod',
    TransactionPaymentGateway: 'transaction.paymentGateway',
    TransactionSale: 'transaction.sale',
    TransactionSaleCustomer: 'transaction.sale.customer',
    TransactionPayment: 'transaction.payment',
    TransactionDirectLink: 'transaction.directLink',
    TransactionOrder: 'transaction.order'
} as const;
export type GetAppIncomeSchedulingsIncludeEnum = typeof GetAppIncomeSchedulingsIncludeEnum[keyof typeof GetAppIncomeSchedulingsIncludeEnum];
/**
 * @export
 */
export const GetSaleIncomeSchedulingsIncludeEnum = {
    Transaction: 'transaction',
    TransactionPaymentMethod: 'transaction.paymentMethod',
    TransactionPaymentGateway: 'transaction.paymentGateway',
    TransactionSale: 'transaction.sale',
    TransactionSaleCustomer: 'transaction.sale.customer',
    TransactionPayment: 'transaction.payment',
    TransactionDirectLink: 'transaction.directLink',
    TransactionOrder: 'transaction.order'
} as const;
export type GetSaleIncomeSchedulingsIncludeEnum = typeof GetSaleIncomeSchedulingsIncludeEnum[keyof typeof GetSaleIncomeSchedulingsIncludeEnum];
/**
 * @export
 */
export const GetSaleIncomeSchedulingsSortByEnum = {
    OldestScheduledAt: 'oldestScheduledAt',
    CreatedAt: 'createdAt',
    UpdatedAt: 'updatedAt'
} as const;
export type GetSaleIncomeSchedulingsSortByEnum = typeof GetSaleIncomeSchedulingsSortByEnum[keyof typeof GetSaleIncomeSchedulingsSortByEnum];
/**
 * @export
 */
export const GetTransactionIncomeSchedulingsIncludeEnum = {
    Transaction: 'transaction',
    TransactionPaymentMethod: 'transaction.paymentMethod',
    TransactionPaymentGateway: 'transaction.paymentGateway',
    TransactionSale: 'transaction.sale',
    TransactionSaleCustomer: 'transaction.sale.customer',
    TransactionPayment: 'transaction.payment',
    TransactionDirectLink: 'transaction.directLink',
    TransactionOrder: 'transaction.order'
} as const;
export type GetTransactionIncomeSchedulingsIncludeEnum = typeof GetTransactionIncomeSchedulingsIncludeEnum[keyof typeof GetTransactionIncomeSchedulingsIncludeEnum];
/**
 * @export
 */
export const GetTransactionIncomeSchedulingsSortByEnum = {
    OldestScheduledAt: 'oldestScheduledAt',
    CreatedAt: 'createdAt',
    UpdatedAt: 'updatedAt'
} as const;
export type GetTransactionIncomeSchedulingsSortByEnum = typeof GetTransactionIncomeSchedulingsSortByEnum[keyof typeof GetTransactionIncomeSchedulingsSortByEnum];


/**
 * PartnershipsApi - axios parameter creator
 * @export
 */
export const PartnershipsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} partnershipId 
         * @param {Array<ApprovePartnershipIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approvePartnership: async (partnershipId: string, include?: Array<ApprovePartnershipIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnershipId' is not null or undefined
            assertParamExists('approvePartnership', 'partnershipId', partnershipId)
            const localVarPath = `/partnerships/{partnershipId}/approve`
                .replace(`{${"partnershipId"}}`, encodeURIComponent(String(partnershipId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {CreateArrangedPartnershipParams} createArrangedPartnershipParams 
         * @param {Array<CreateAppArrangedPartnershipIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppArrangedPartnership: async (appId: string, createArrangedPartnershipParams: CreateArrangedPartnershipParams, include?: Array<CreateAppArrangedPartnershipIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('createAppArrangedPartnership', 'appId', appId)
            // verify required parameter 'createArrangedPartnershipParams' is not null or undefined
            assertParamExists('createAppArrangedPartnership', 'createArrangedPartnershipParams', createArrangedPartnershipParams)
            const localVarPath = `/apps/{appId}/validArrangedPartnerships`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArrangedPartnershipParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} partnershipId 
         * @param {Array<DeclinePartnershipIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declinePartnership: async (partnershipId: string, include?: Array<DeclinePartnershipIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnershipId' is not null or undefined
            assertParamExists('declinePartnership', 'partnershipId', partnershipId)
            const localVarPath = `/partnerships/{partnershipId}/decline`
                .replace(`{${"partnershipId"}}`, encodeURIComponent(String(partnershipId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<PartnershipStatus>} [currentStatus] 
         * @param {GetAppArrangedPartnershipsSortByEnum} [sortBy] 
         * @param {Array<GetAppArrangedPartnershipsIncludeEnum>} [include] 
         * @param {string} [search] 
         * @param {Array<AssociationModel>} [associationModels] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppArrangedPartnerships: async (appId: string, offset?: number, limit?: number, currentStatus?: Array<PartnershipStatus>, sortBy?: GetAppArrangedPartnershipsSortByEnum, include?: Array<GetAppArrangedPartnershipsIncludeEnum>, search?: string, associationModels?: Array<AssociationModel>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppArrangedPartnerships', 'appId', appId)
            const localVarPath = `/apps/{appId}/arrangedPartnerships`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (currentStatus) {
                localVarQueryParameter['currentStatus'] = currentStatus.join(COLLECTION_FORMATS.csv);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (associationModels) {
                localVarQueryParameter['associationModels'] = associationModels.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppPartnershipProposals: async (appId: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppPartnershipProposals', 'appId', appId)
            const localVarPath = `/apps/{appId}/partnershipProposals`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {Array<GetAppPartnershipProvidersIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppPartnershipProviders: async (appId: string, offset?: number, limit?: number, search?: string, include?: Array<GetAppPartnershipProvidersIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppPartnershipProviders', 'appId', appId)
            const localVarPath = `/apps/{appId}/partnershipProviders`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<PartnershipStatus>} [currentStatus] 
         * @param {GetAppProvidedPartnershipsSortByEnum} [sortBy] 
         * @param {Array<GetAppProvidedPartnershipsIncludeEnum>} [include] 
         * @param {string} [search] 
         * @param {Array<string>} [partnershipProposalIds] 
         * @param {Array<AssociationModel>} [associationModels] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppProvidedPartnerships: async (appId: string, offset?: number, limit?: number, currentStatus?: Array<PartnershipStatus>, sortBy?: GetAppProvidedPartnershipsSortByEnum, include?: Array<GetAppProvidedPartnershipsIncludeEnum>, search?: string, partnershipProposalIds?: Array<string>, associationModels?: Array<AssociationModel>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppProvidedPartnerships', 'appId', appId)
            const localVarPath = `/apps/{appId}/providedPartnerships`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (currentStatus) {
                localVarQueryParameter['currentStatus'] = currentStatus.join(COLLECTION_FORMATS.csv);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (partnershipProposalIds) {
                localVarQueryParameter['partnershipProposalIds'] = partnershipProposalIds.join(COLLECTION_FORMATS.csv);
            }

            if (associationModels) {
                localVarQueryParameter['associationModels'] = associationModels.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetAppScheduledProductsIncludeEnum>} [include] 
         * @param {string} [schedulingSince] 
         * @param {string} [schedulingUntil] 
         * @param {string} [search] 
         * @param {GetAppScheduledProductsSortByEnum} [sortBy] 
         * @param {Array<SellingMode>} [sellingModes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppScheduledProducts: async (appId: string, offset?: number, limit?: number, include?: Array<GetAppScheduledProductsIncludeEnum>, schedulingSince?: string, schedulingUntil?: string, search?: string, sortBy?: GetAppScheduledProductsSortByEnum, sellingModes?: Array<SellingMode>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppScheduledProducts', 'appId', appId)
            const localVarPath = `/apps/{appId}/scheduledProducts`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (schedulingSince !== undefined) {
                localVarQueryParameter['schedulingSince'] = (schedulingSince as any instanceof Date) ?
                    (schedulingSince as any).toISOString().substring(0,10) :
                    schedulingSince;
            }

            if (schedulingUntil !== undefined) {
                localVarQueryParameter['schedulingUntil'] = (schedulingUntil as any instanceof Date) ?
                    (schedulingUntil as any).toISOString().substring(0,10) :
                    schedulingUntil;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sellingModes) {
                localVarQueryParameter['sellingModes'] = sellingModes.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<PartnershipStatus>} [currentStatus] 
         * @param {GetAppValidArrangedPartnershipsSortByEnum} [sortBy] 
         * @param {Array<GetAppValidArrangedPartnershipsIncludeEnum>} [include] 
         * @param {string} [search] 
         * @param {Array<AssociationModel>} [associationModels] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppValidArrangedPartnerships: async (appId: string, offset?: number, limit?: number, currentStatus?: Array<PartnershipStatus>, sortBy?: GetAppValidArrangedPartnershipsSortByEnum, include?: Array<GetAppValidArrangedPartnershipsIncludeEnum>, search?: string, associationModels?: Array<AssociationModel>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppValidArrangedPartnerships', 'appId', appId)
            const localVarPath = `/apps/{appId}/validArrangedPartnerships`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (currentStatus) {
                localVarQueryParameter['currentStatus'] = currentStatus.join(COLLECTION_FORMATS.csv);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (associationModels) {
                localVarQueryParameter['associationModels'] = associationModels.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<PartnershipStatus>} [currentStatus] 
         * @param {GetAppValidProvidedPartnershipsSortByEnum} [sortBy] 
         * @param {Array<GetAppValidProvidedPartnershipsIncludeEnum>} [include] 
         * @param {string} [search] 
         * @param {Array<string>} [partnershipProposalIds] 
         * @param {Array<AssociationModel>} [associationModels] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppValidProvidedPartnerships: async (appId: string, offset?: number, limit?: number, currentStatus?: Array<PartnershipStatus>, sortBy?: GetAppValidProvidedPartnershipsSortByEnum, include?: Array<GetAppValidProvidedPartnershipsIncludeEnum>, search?: string, partnershipProposalIds?: Array<string>, associationModels?: Array<AssociationModel>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppValidProvidedPartnerships', 'appId', appId)
            const localVarPath = `/apps/{appId}/validProvidedPartnerships`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (currentStatus) {
                localVarQueryParameter['currentStatus'] = currentStatus.join(COLLECTION_FORMATS.csv);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (partnershipProposalIds) {
                localVarQueryParameter['partnershipProposalIds'] = partnershipProposalIds.join(COLLECTION_FORMATS.csv);
            }

            if (associationModels) {
                localVarQueryParameter['associationModels'] = associationModels.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} partnershipId 
         * @param {Array<GetPartnershipIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnership: async (partnershipId: string, include?: Array<GetPartnershipIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnershipId' is not null or undefined
            assertParamExists('getPartnership', 'partnershipId', partnershipId)
            const localVarPath = `/partnerships/{partnershipId}`
                .replace(`{${"partnershipId"}}`, encodeURIComponent(String(partnershipId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} partnershipId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetPartnershipCouponsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnershipCoupons: async (partnershipId: string, offset?: number, limit?: number, include?: Array<GetPartnershipCouponsIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnershipId' is not null or undefined
            assertParamExists('getPartnershipCoupons', 'partnershipId', partnershipId)
            const localVarPath = `/partnerships/{partnershipId}/coupons`
                .replace(`{${"partnershipId"}}`, encodeURIComponent(String(partnershipId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} partnershipId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetPartnershipItemsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnershipItems: async (partnershipId: string, offset?: number, limit?: number, include?: Array<GetPartnershipItemsIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnershipId' is not null or undefined
            assertParamExists('getPartnershipItems', 'partnershipId', partnershipId)
            const localVarPath = `/partnerships/{partnershipId}/items`
                .replace(`{${"partnershipId"}}`, encodeURIComponent(String(partnershipId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} partnershipId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string | null} [since] 
         * @param {string | null} [until] 
         * @param {Array<GetProvidedPartnershipSaleItemsIncludeEnum>} [include] 
         * @param {GetProvidedPartnershipSaleItemsSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvidedPartnershipSaleItems: async (partnershipId: string, offset?: number, limit?: number, since?: string | null, until?: string | null, include?: Array<GetProvidedPartnershipSaleItemsIncludeEnum>, sortBy?: GetProvidedPartnershipSaleItemsSortByEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnershipId' is not null or undefined
            assertParamExists('getProvidedPartnershipSaleItems', 'partnershipId', partnershipId)
            const localVarPath = `/providedPartnerships/{partnershipId}/detailedSaleItems`
                .replace(`{${"partnershipId"}}`, encodeURIComponent(String(partnershipId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString() :
                    since;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = (until as any instanceof Date) ?
                    (until as any).toISOString() :
                    until;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicPartnershipProposalCode 
         * @param {Array<GetPublicPartnershipProposalIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicPartnershipProposal: async (publicPartnershipProposalCode: string, include?: Array<GetPublicPartnershipProposalIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicPartnershipProposalCode' is not null or undefined
            assertParamExists('getPublicPartnershipProposal', 'publicPartnershipProposalCode', publicPartnershipProposalCode)
            const localVarPath = `/publicPartnershipProposals/{publicPartnershipProposalCode}`
                .replace(`{${"publicPartnershipProposalCode"}}`, encodeURIComponent(String(publicPartnershipProposalCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicPartnershipProposalCode 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetPublicPartnershipProposalItemsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicPartnershipProposalItems: async (publicPartnershipProposalCode: string, offset?: number, limit?: number, include?: Array<GetPublicPartnershipProposalItemsIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicPartnershipProposalCode' is not null or undefined
            assertParamExists('getPublicPartnershipProposalItems', 'publicPartnershipProposalCode', publicPartnershipProposalCode)
            const localVarPath = `/publicPartnershipProposals/{publicPartnershipProposalCode}/items`
                .replace(`{${"publicPartnershipProposalCode"}}`, encodeURIComponent(String(publicPartnershipProposalCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} partnershipId 
         * @param {UpdateArrangedPartnershipParams} updateArrangedPartnershipParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArrangedPartnership: async (partnershipId: string, updateArrangedPartnershipParams: UpdateArrangedPartnershipParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnershipId' is not null or undefined
            assertParamExists('updateArrangedPartnership', 'partnershipId', partnershipId)
            // verify required parameter 'updateArrangedPartnershipParams' is not null or undefined
            assertParamExists('updateArrangedPartnership', 'updateArrangedPartnershipParams', updateArrangedPartnershipParams)
            const localVarPath = `/arrangedPartnerships/{partnershipId}`
                .replace(`{${"partnershipId"}}`, encodeURIComponent(String(partnershipId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateArrangedPartnershipParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} partnershipId 
         * @param {UpdatePartnershipParams} updatePartnershipParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnership: async (partnershipId: string, updatePartnershipParams: UpdatePartnershipParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnershipId' is not null or undefined
            assertParamExists('updatePartnership', 'partnershipId', partnershipId)
            // verify required parameter 'updatePartnershipParams' is not null or undefined
            assertParamExists('updatePartnership', 'updatePartnershipParams', updatePartnershipParams)
            const localVarPath = `/partnerships/{partnershipId}`
                .replace(`{${"partnershipId"}}`, encodeURIComponent(String(partnershipId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePartnershipParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnershipsApi - functional programming interface
 * @export
 */
export const PartnershipsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnershipsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} partnershipId 
         * @param {Array<ApprovePartnershipIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approvePartnership(partnershipId: string, include?: Array<ApprovePartnershipIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Partnership>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approvePartnership(partnershipId, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PartnershipsApi.approvePartnership']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {CreateArrangedPartnershipParams} createArrangedPartnershipParams 
         * @param {Array<CreateAppArrangedPartnershipIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAppArrangedPartnership(appId: string, createArrangedPartnershipParams: CreateArrangedPartnershipParams, include?: Array<CreateAppArrangedPartnershipIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Partnership>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAppArrangedPartnership(appId, createArrangedPartnershipParams, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PartnershipsApi.createAppArrangedPartnership']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} partnershipId 
         * @param {Array<DeclinePartnershipIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async declinePartnership(partnershipId: string, include?: Array<DeclinePartnershipIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Partnership>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.declinePartnership(partnershipId, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PartnershipsApi.declinePartnership']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<PartnershipStatus>} [currentStatus] 
         * @param {GetAppArrangedPartnershipsSortByEnum} [sortBy] 
         * @param {Array<GetAppArrangedPartnershipsIncludeEnum>} [include] 
         * @param {string} [search] 
         * @param {Array<AssociationModel>} [associationModels] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppArrangedPartnerships(appId: string, offset?: number, limit?: number, currentStatus?: Array<PartnershipStatus>, sortBy?: GetAppArrangedPartnershipsSortByEnum, include?: Array<GetAppArrangedPartnershipsIncludeEnum>, search?: string, associationModels?: Array<AssociationModel>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Partnership>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppArrangedPartnerships(appId, offset, limit, currentStatus, sortBy, include, search, associationModels, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PartnershipsApi.getAppArrangedPartnerships']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppPartnershipProposals(appId: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnershipProposal>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppPartnershipProposals(appId, offset, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PartnershipsApi.getAppPartnershipProposals']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {Array<GetAppPartnershipProvidersIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppPartnershipProviders(appId: string, offset?: number, limit?: number, search?: string, include?: Array<GetAppPartnershipProvidersIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicApp>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppPartnershipProviders(appId, offset, limit, search, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PartnershipsApi.getAppPartnershipProviders']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<PartnershipStatus>} [currentStatus] 
         * @param {GetAppProvidedPartnershipsSortByEnum} [sortBy] 
         * @param {Array<GetAppProvidedPartnershipsIncludeEnum>} [include] 
         * @param {string} [search] 
         * @param {Array<string>} [partnershipProposalIds] 
         * @param {Array<AssociationModel>} [associationModels] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppProvidedPartnerships(appId: string, offset?: number, limit?: number, currentStatus?: Array<PartnershipStatus>, sortBy?: GetAppProvidedPartnershipsSortByEnum, include?: Array<GetAppProvidedPartnershipsIncludeEnum>, search?: string, partnershipProposalIds?: Array<string>, associationModels?: Array<AssociationModel>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Partnership>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppProvidedPartnerships(appId, offset, limit, currentStatus, sortBy, include, search, partnershipProposalIds, associationModels, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PartnershipsApi.getAppProvidedPartnerships']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetAppScheduledProductsIncludeEnum>} [include] 
         * @param {string} [schedulingSince] 
         * @param {string} [schedulingUntil] 
         * @param {string} [search] 
         * @param {GetAppScheduledProductsSortByEnum} [sortBy] 
         * @param {Array<SellingMode>} [sellingModes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppScheduledProducts(appId: string, offset?: number, limit?: number, include?: Array<GetAppScheduledProductsIncludeEnum>, schedulingSince?: string, schedulingUntil?: string, search?: string, sortBy?: GetAppScheduledProductsSortByEnum, sellingModes?: Array<SellingMode>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Product>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppScheduledProducts(appId, offset, limit, include, schedulingSince, schedulingUntil, search, sortBy, sellingModes, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PartnershipsApi.getAppScheduledProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<PartnershipStatus>} [currentStatus] 
         * @param {GetAppValidArrangedPartnershipsSortByEnum} [sortBy] 
         * @param {Array<GetAppValidArrangedPartnershipsIncludeEnum>} [include] 
         * @param {string} [search] 
         * @param {Array<AssociationModel>} [associationModels] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppValidArrangedPartnerships(appId: string, offset?: number, limit?: number, currentStatus?: Array<PartnershipStatus>, sortBy?: GetAppValidArrangedPartnershipsSortByEnum, include?: Array<GetAppValidArrangedPartnershipsIncludeEnum>, search?: string, associationModels?: Array<AssociationModel>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Partnership>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppValidArrangedPartnerships(appId, offset, limit, currentStatus, sortBy, include, search, associationModels, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PartnershipsApi.getAppValidArrangedPartnerships']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<PartnershipStatus>} [currentStatus] 
         * @param {GetAppValidProvidedPartnershipsSortByEnum} [sortBy] 
         * @param {Array<GetAppValidProvidedPartnershipsIncludeEnum>} [include] 
         * @param {string} [search] 
         * @param {Array<string>} [partnershipProposalIds] 
         * @param {Array<AssociationModel>} [associationModels] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppValidProvidedPartnerships(appId: string, offset?: number, limit?: number, currentStatus?: Array<PartnershipStatus>, sortBy?: GetAppValidProvidedPartnershipsSortByEnum, include?: Array<GetAppValidProvidedPartnershipsIncludeEnum>, search?: string, partnershipProposalIds?: Array<string>, associationModels?: Array<AssociationModel>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Partnership>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppValidProvidedPartnerships(appId, offset, limit, currentStatus, sortBy, include, search, partnershipProposalIds, associationModels, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PartnershipsApi.getAppValidProvidedPartnerships']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} partnershipId 
         * @param {Array<GetPartnershipIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPartnership(partnershipId: string, include?: Array<GetPartnershipIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Partnership>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPartnership(partnershipId, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PartnershipsApi.getPartnership']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} partnershipId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetPartnershipCouponsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPartnershipCoupons(partnershipId: string, offset?: number, limit?: number, include?: Array<GetPartnershipCouponsIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Coupon>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPartnershipCoupons(partnershipId, offset, limit, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PartnershipsApi.getPartnershipCoupons']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} partnershipId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetPartnershipItemsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPartnershipItems(partnershipId: string, offset?: number, limit?: number, include?: Array<GetPartnershipItemsIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicPartnershipItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPartnershipItems(partnershipId, offset, limit, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PartnershipsApi.getPartnershipItems']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} partnershipId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string | null} [since] 
         * @param {string | null} [until] 
         * @param {Array<GetProvidedPartnershipSaleItemsIncludeEnum>} [include] 
         * @param {GetProvidedPartnershipSaleItemsSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProvidedPartnershipSaleItems(partnershipId: string, offset?: number, limit?: number, since?: string | null, until?: string | null, include?: Array<GetProvidedPartnershipSaleItemsIncludeEnum>, sortBy?: GetProvidedPartnershipSaleItemsSortByEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DetailedSaleItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProvidedPartnershipSaleItems(partnershipId, offset, limit, since, until, include, sortBy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PartnershipsApi.getProvidedPartnershipSaleItems']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} publicPartnershipProposalCode 
         * @param {Array<GetPublicPartnershipProposalIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicPartnershipProposal(publicPartnershipProposalCode: string, include?: Array<GetPublicPartnershipProposalIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicPartnershipProposal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicPartnershipProposal(publicPartnershipProposalCode, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PartnershipsApi.getPublicPartnershipProposal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} publicPartnershipProposalCode 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetPublicPartnershipProposalItemsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicPartnershipProposalItems(publicPartnershipProposalCode: string, offset?: number, limit?: number, include?: Array<GetPublicPartnershipProposalItemsIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicPartnershipItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicPartnershipProposalItems(publicPartnershipProposalCode, offset, limit, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PartnershipsApi.getPublicPartnershipProposalItems']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} partnershipId 
         * @param {UpdateArrangedPartnershipParams} updateArrangedPartnershipParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateArrangedPartnership(partnershipId: string, updateArrangedPartnershipParams: UpdateArrangedPartnershipParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Partnership>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateArrangedPartnership(partnershipId, updateArrangedPartnershipParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PartnershipsApi.updateArrangedPartnership']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} partnershipId 
         * @param {UpdatePartnershipParams} updatePartnershipParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePartnership(partnershipId: string, updatePartnershipParams: UpdatePartnershipParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Partnership>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePartnership(partnershipId, updatePartnershipParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PartnershipsApi.updatePartnership']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PartnershipsApi - factory interface
 * @export
 */
export const PartnershipsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnershipsApiFp(configuration)
    return {
        /**
         * 
         * @param {PartnershipsApiApprovePartnershipRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approvePartnership(requestParameters: PartnershipsApiApprovePartnershipRequest, options?: RawAxiosRequestConfig): AxiosPromise<Partnership> {
            return localVarFp.approvePartnership(requestParameters.partnershipId, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PartnershipsApiCreateAppArrangedPartnershipRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppArrangedPartnership(requestParameters: PartnershipsApiCreateAppArrangedPartnershipRequest, options?: RawAxiosRequestConfig): AxiosPromise<Partnership> {
            return localVarFp.createAppArrangedPartnership(requestParameters.appId, requestParameters.createArrangedPartnershipParams, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PartnershipsApiDeclinePartnershipRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declinePartnership(requestParameters: PartnershipsApiDeclinePartnershipRequest, options?: RawAxiosRequestConfig): AxiosPromise<Partnership> {
            return localVarFp.declinePartnership(requestParameters.partnershipId, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PartnershipsApiGetAppArrangedPartnershipsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppArrangedPartnerships(requestParameters: PartnershipsApiGetAppArrangedPartnershipsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Partnership>> {
            return localVarFp.getAppArrangedPartnerships(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.currentStatus, requestParameters.sortBy, requestParameters.include, requestParameters.search, requestParameters.associationModels, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PartnershipsApiGetAppPartnershipProposalsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppPartnershipProposals(requestParameters: PartnershipsApiGetAppPartnershipProposalsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<PartnershipProposal>> {
            return localVarFp.getAppPartnershipProposals(requestParameters.appId, requestParameters.offset, requestParameters.limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PartnershipsApiGetAppPartnershipProvidersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppPartnershipProviders(requestParameters: PartnershipsApiGetAppPartnershipProvidersRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<PublicApp>> {
            return localVarFp.getAppPartnershipProviders(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.search, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PartnershipsApiGetAppProvidedPartnershipsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppProvidedPartnerships(requestParameters: PartnershipsApiGetAppProvidedPartnershipsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Partnership>> {
            return localVarFp.getAppProvidedPartnerships(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.currentStatus, requestParameters.sortBy, requestParameters.include, requestParameters.search, requestParameters.partnershipProposalIds, requestParameters.associationModels, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PartnershipsApiGetAppScheduledProductsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppScheduledProducts(requestParameters: PartnershipsApiGetAppScheduledProductsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Product>> {
            return localVarFp.getAppScheduledProducts(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.include, requestParameters.schedulingSince, requestParameters.schedulingUntil, requestParameters.search, requestParameters.sortBy, requestParameters.sellingModes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PartnershipsApiGetAppValidArrangedPartnershipsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppValidArrangedPartnerships(requestParameters: PartnershipsApiGetAppValidArrangedPartnershipsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Partnership>> {
            return localVarFp.getAppValidArrangedPartnerships(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.currentStatus, requestParameters.sortBy, requestParameters.include, requestParameters.search, requestParameters.associationModels, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PartnershipsApiGetAppValidProvidedPartnershipsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppValidProvidedPartnerships(requestParameters: PartnershipsApiGetAppValidProvidedPartnershipsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Partnership>> {
            return localVarFp.getAppValidProvidedPartnerships(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.currentStatus, requestParameters.sortBy, requestParameters.include, requestParameters.search, requestParameters.partnershipProposalIds, requestParameters.associationModels, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PartnershipsApiGetPartnershipRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnership(requestParameters: PartnershipsApiGetPartnershipRequest, options?: RawAxiosRequestConfig): AxiosPromise<Partnership> {
            return localVarFp.getPartnership(requestParameters.partnershipId, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PartnershipsApiGetPartnershipCouponsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnershipCoupons(requestParameters: PartnershipsApiGetPartnershipCouponsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Coupon>> {
            return localVarFp.getPartnershipCoupons(requestParameters.partnershipId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PartnershipsApiGetPartnershipItemsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnershipItems(requestParameters: PartnershipsApiGetPartnershipItemsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<PublicPartnershipItem>> {
            return localVarFp.getPartnershipItems(requestParameters.partnershipId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PartnershipsApiGetProvidedPartnershipSaleItemsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvidedPartnershipSaleItems(requestParameters: PartnershipsApiGetProvidedPartnershipSaleItemsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<DetailedSaleItem>> {
            return localVarFp.getProvidedPartnershipSaleItems(requestParameters.partnershipId, requestParameters.offset, requestParameters.limit, requestParameters.since, requestParameters.until, requestParameters.include, requestParameters.sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PartnershipsApiGetPublicPartnershipProposalRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicPartnershipProposal(requestParameters: PartnershipsApiGetPublicPartnershipProposalRequest, options?: RawAxiosRequestConfig): AxiosPromise<PublicPartnershipProposal> {
            return localVarFp.getPublicPartnershipProposal(requestParameters.publicPartnershipProposalCode, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PartnershipsApiGetPublicPartnershipProposalItemsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicPartnershipProposalItems(requestParameters: PartnershipsApiGetPublicPartnershipProposalItemsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<PublicPartnershipItem>> {
            return localVarFp.getPublicPartnershipProposalItems(requestParameters.publicPartnershipProposalCode, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PartnershipsApiUpdateArrangedPartnershipRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArrangedPartnership(requestParameters: PartnershipsApiUpdateArrangedPartnershipRequest, options?: RawAxiosRequestConfig): AxiosPromise<Partnership> {
            return localVarFp.updateArrangedPartnership(requestParameters.partnershipId, requestParameters.updateArrangedPartnershipParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PartnershipsApiUpdatePartnershipRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnership(requestParameters: PartnershipsApiUpdatePartnershipRequest, options?: RawAxiosRequestConfig): AxiosPromise<Partnership> {
            return localVarFp.updatePartnership(requestParameters.partnershipId, requestParameters.updatePartnershipParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for approvePartnership operation in PartnershipsApi.
 * @export
 * @interface PartnershipsApiApprovePartnershipRequest
 */
export interface PartnershipsApiApprovePartnershipRequest {
    /**
     * 
     * @type {string}
     * @memberof PartnershipsApiApprovePartnership
     */
    readonly partnershipId: string

    /**
     * 
     * @type {Array<'provider' | 'provider.info' | 'agent' | 'agent.info' | 'publicProposal' | 'publicProposal.provider' | 'publicProposal.provider.info'>}
     * @memberof PartnershipsApiApprovePartnership
     */
    readonly include?: Array<ApprovePartnershipIncludeEnum>
}

/**
 * Request parameters for createAppArrangedPartnership operation in PartnershipsApi.
 * @export
 * @interface PartnershipsApiCreateAppArrangedPartnershipRequest
 */
export interface PartnershipsApiCreateAppArrangedPartnershipRequest {
    /**
     * 
     * @type {string}
     * @memberof PartnershipsApiCreateAppArrangedPartnership
     */
    readonly appId: string

    /**
     * 
     * @type {CreateArrangedPartnershipParams}
     * @memberof PartnershipsApiCreateAppArrangedPartnership
     */
    readonly createArrangedPartnershipParams: CreateArrangedPartnershipParams

    /**
     * 
     * @type {Array<'provider' | 'provider.info' | 'agent' | 'agent.info' | 'publicProposal' | 'publicProposal.provider' | 'publicProposal.provider.info'>}
     * @memberof PartnershipsApiCreateAppArrangedPartnership
     */
    readonly include?: Array<CreateAppArrangedPartnershipIncludeEnum>
}

/**
 * Request parameters for declinePartnership operation in PartnershipsApi.
 * @export
 * @interface PartnershipsApiDeclinePartnershipRequest
 */
export interface PartnershipsApiDeclinePartnershipRequest {
    /**
     * 
     * @type {string}
     * @memberof PartnershipsApiDeclinePartnership
     */
    readonly partnershipId: string

    /**
     * 
     * @type {Array<'provider' | 'provider.info' | 'agent' | 'agent.info' | 'publicProposal' | 'publicProposal.provider' | 'publicProposal.provider.info'>}
     * @memberof PartnershipsApiDeclinePartnership
     */
    readonly include?: Array<DeclinePartnershipIncludeEnum>
}

/**
 * Request parameters for getAppArrangedPartnerships operation in PartnershipsApi.
 * @export
 * @interface PartnershipsApiGetAppArrangedPartnershipsRequest
 */
export interface PartnershipsApiGetAppArrangedPartnershipsRequest {
    /**
     * 
     * @type {string}
     * @memberof PartnershipsApiGetAppArrangedPartnerships
     */
    readonly appId: string

    /**
     * 
     * @type {number}
     * @memberof PartnershipsApiGetAppArrangedPartnerships
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof PartnershipsApiGetAppArrangedPartnerships
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<PartnershipStatus>}
     * @memberof PartnershipsApiGetAppArrangedPartnerships
     */
    readonly currentStatus?: Array<PartnershipStatus>

    /**
     * 
     * @type {'recent'}
     * @memberof PartnershipsApiGetAppArrangedPartnerships
     */
    readonly sortBy?: GetAppArrangedPartnershipsSortByEnum

    /**
     * 
     * @type {Array<'provider' | 'provider.info' | 'agent' | 'agent.info' | 'publicProposal' | 'publicProposal.provider' | 'publicProposal.provider.info'>}
     * @memberof PartnershipsApiGetAppArrangedPartnerships
     */
    readonly include?: Array<GetAppArrangedPartnershipsIncludeEnum>

    /**
     * 
     * @type {string}
     * @memberof PartnershipsApiGetAppArrangedPartnerships
     */
    readonly search?: string

    /**
     * 
     * @type {Array<AssociationModel>}
     * @memberof PartnershipsApiGetAppArrangedPartnerships
     */
    readonly associationModels?: Array<AssociationModel>
}

/**
 * Request parameters for getAppPartnershipProposals operation in PartnershipsApi.
 * @export
 * @interface PartnershipsApiGetAppPartnershipProposalsRequest
 */
export interface PartnershipsApiGetAppPartnershipProposalsRequest {
    /**
     * 
     * @type {string}
     * @memberof PartnershipsApiGetAppPartnershipProposals
     */
    readonly appId: string

    /**
     * 
     * @type {number}
     * @memberof PartnershipsApiGetAppPartnershipProposals
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof PartnershipsApiGetAppPartnershipProposals
     */
    readonly limit?: number
}

/**
 * Request parameters for getAppPartnershipProviders operation in PartnershipsApi.
 * @export
 * @interface PartnershipsApiGetAppPartnershipProvidersRequest
 */
export interface PartnershipsApiGetAppPartnershipProvidersRequest {
    /**
     * 
     * @type {string}
     * @memberof PartnershipsApiGetAppPartnershipProviders
     */
    readonly appId: string

    /**
     * 
     * @type {number}
     * @memberof PartnershipsApiGetAppPartnershipProviders
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof PartnershipsApiGetAppPartnershipProviders
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof PartnershipsApiGetAppPartnershipProviders
     */
    readonly search?: string

    /**
     * 
     * @type {Array<'plan' | 'info'>}
     * @memberof PartnershipsApiGetAppPartnershipProviders
     */
    readonly include?: Array<GetAppPartnershipProvidersIncludeEnum>
}

/**
 * Request parameters for getAppProvidedPartnerships operation in PartnershipsApi.
 * @export
 * @interface PartnershipsApiGetAppProvidedPartnershipsRequest
 */
export interface PartnershipsApiGetAppProvidedPartnershipsRequest {
    /**
     * 
     * @type {string}
     * @memberof PartnershipsApiGetAppProvidedPartnerships
     */
    readonly appId: string

    /**
     * 
     * @type {number}
     * @memberof PartnershipsApiGetAppProvidedPartnerships
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof PartnershipsApiGetAppProvidedPartnerships
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<PartnershipStatus>}
     * @memberof PartnershipsApiGetAppProvidedPartnerships
     */
    readonly currentStatus?: Array<PartnershipStatus>

    /**
     * 
     * @type {'recent'}
     * @memberof PartnershipsApiGetAppProvidedPartnerships
     */
    readonly sortBy?: GetAppProvidedPartnershipsSortByEnum

    /**
     * 
     * @type {Array<'provider' | 'provider.info' | 'agent' | 'agent.info' | 'publicProposal' | 'publicProposal.provider' | 'publicProposal.provider.info'>}
     * @memberof PartnershipsApiGetAppProvidedPartnerships
     */
    readonly include?: Array<GetAppProvidedPartnershipsIncludeEnum>

    /**
     * 
     * @type {string}
     * @memberof PartnershipsApiGetAppProvidedPartnerships
     */
    readonly search?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof PartnershipsApiGetAppProvidedPartnerships
     */
    readonly partnershipProposalIds?: Array<string>

    /**
     * 
     * @type {Array<AssociationModel>}
     * @memberof PartnershipsApiGetAppProvidedPartnerships
     */
    readonly associationModels?: Array<AssociationModel>
}

/**
 * Request parameters for getAppScheduledProducts operation in PartnershipsApi.
 * @export
 * @interface PartnershipsApiGetAppScheduledProductsRequest
 */
export interface PartnershipsApiGetAppScheduledProductsRequest {
    /**
     * 
     * @type {string}
     * @memberof PartnershipsApiGetAppScheduledProducts
     */
    readonly appId: string

    /**
     * 
     * @type {number}
     * @memberof PartnershipsApiGetAppScheduledProducts
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof PartnershipsApiGetAppScheduledProducts
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<'defaultTariff' | 'defaultTariffGroupPriceInfo'>}
     * @memberof PartnershipsApiGetAppScheduledProducts
     */
    readonly include?: Array<GetAppScheduledProductsIncludeEnum>

    /**
     * 
     * @type {string}
     * @memberof PartnershipsApiGetAppScheduledProducts
     */
    readonly schedulingSince?: string

    /**
     * 
     * @type {string}
     * @memberof PartnershipsApiGetAppScheduledProducts
     */
    readonly schedulingUntil?: string

    /**
     * 
     * @type {string}
     * @memberof PartnershipsApiGetAppScheduledProducts
     */
    readonly search?: string

    /**
     * 
     * @type {'recent' | 'name'}
     * @memberof PartnershipsApiGetAppScheduledProducts
     */
    readonly sortBy?: GetAppScheduledProductsSortByEnum

    /**
     * 
     * @type {Array<SellingMode>}
     * @memberof PartnershipsApiGetAppScheduledProducts
     */
    readonly sellingModes?: Array<SellingMode>
}

/**
 * Request parameters for getAppValidArrangedPartnerships operation in PartnershipsApi.
 * @export
 * @interface PartnershipsApiGetAppValidArrangedPartnershipsRequest
 */
export interface PartnershipsApiGetAppValidArrangedPartnershipsRequest {
    /**
     * 
     * @type {string}
     * @memberof PartnershipsApiGetAppValidArrangedPartnerships
     */
    readonly appId: string

    /**
     * 
     * @type {number}
     * @memberof PartnershipsApiGetAppValidArrangedPartnerships
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof PartnershipsApiGetAppValidArrangedPartnerships
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<PartnershipStatus>}
     * @memberof PartnershipsApiGetAppValidArrangedPartnerships
     */
    readonly currentStatus?: Array<PartnershipStatus>

    /**
     * 
     * @type {'recent'}
     * @memberof PartnershipsApiGetAppValidArrangedPartnerships
     */
    readonly sortBy?: GetAppValidArrangedPartnershipsSortByEnum

    /**
     * 
     * @type {Array<'provider' | 'provider.info' | 'agent' | 'agent.info' | 'publicProposal' | 'publicProposal.provider' | 'publicProposal.provider.info'>}
     * @memberof PartnershipsApiGetAppValidArrangedPartnerships
     */
    readonly include?: Array<GetAppValidArrangedPartnershipsIncludeEnum>

    /**
     * 
     * @type {string}
     * @memberof PartnershipsApiGetAppValidArrangedPartnerships
     */
    readonly search?: string

    /**
     * 
     * @type {Array<AssociationModel>}
     * @memberof PartnershipsApiGetAppValidArrangedPartnerships
     */
    readonly associationModels?: Array<AssociationModel>
}

/**
 * Request parameters for getAppValidProvidedPartnerships operation in PartnershipsApi.
 * @export
 * @interface PartnershipsApiGetAppValidProvidedPartnershipsRequest
 */
export interface PartnershipsApiGetAppValidProvidedPartnershipsRequest {
    /**
     * 
     * @type {string}
     * @memberof PartnershipsApiGetAppValidProvidedPartnerships
     */
    readonly appId: string

    /**
     * 
     * @type {number}
     * @memberof PartnershipsApiGetAppValidProvidedPartnerships
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof PartnershipsApiGetAppValidProvidedPartnerships
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<PartnershipStatus>}
     * @memberof PartnershipsApiGetAppValidProvidedPartnerships
     */
    readonly currentStatus?: Array<PartnershipStatus>

    /**
     * 
     * @type {'recent'}
     * @memberof PartnershipsApiGetAppValidProvidedPartnerships
     */
    readonly sortBy?: GetAppValidProvidedPartnershipsSortByEnum

    /**
     * 
     * @type {Array<'provider' | 'provider.info' | 'agent' | 'agent.info' | 'publicProposal' | 'publicProposal.provider' | 'publicProposal.provider.info'>}
     * @memberof PartnershipsApiGetAppValidProvidedPartnerships
     */
    readonly include?: Array<GetAppValidProvidedPartnershipsIncludeEnum>

    /**
     * 
     * @type {string}
     * @memberof PartnershipsApiGetAppValidProvidedPartnerships
     */
    readonly search?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof PartnershipsApiGetAppValidProvidedPartnerships
     */
    readonly partnershipProposalIds?: Array<string>

    /**
     * 
     * @type {Array<AssociationModel>}
     * @memberof PartnershipsApiGetAppValidProvidedPartnerships
     */
    readonly associationModels?: Array<AssociationModel>
}

/**
 * Request parameters for getPartnership operation in PartnershipsApi.
 * @export
 * @interface PartnershipsApiGetPartnershipRequest
 */
export interface PartnershipsApiGetPartnershipRequest {
    /**
     * 
     * @type {string}
     * @memberof PartnershipsApiGetPartnership
     */
    readonly partnershipId: string

    /**
     * 
     * @type {Array<'provider' | 'provider.info' | 'agent' | 'agent.info' | 'publicProposal' | 'publicProposal.provider' | 'publicProposal.provider.info'>}
     * @memberof PartnershipsApiGetPartnership
     */
    readonly include?: Array<GetPartnershipIncludeEnum>
}

/**
 * Request parameters for getPartnershipCoupons operation in PartnershipsApi.
 * @export
 * @interface PartnershipsApiGetPartnershipCouponsRequest
 */
export interface PartnershipsApiGetPartnershipCouponsRequest {
    /**
     * 
     * @type {string}
     * @memberof PartnershipsApiGetPartnershipCoupons
     */
    readonly partnershipId: string

    /**
     * 
     * @type {number}
     * @memberof PartnershipsApiGetPartnershipCoupons
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof PartnershipsApiGetPartnershipCoupons
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<'products' | 'usage'>}
     * @memberof PartnershipsApiGetPartnershipCoupons
     */
    readonly include?: Array<GetPartnershipCouponsIncludeEnum>
}

/**
 * Request parameters for getPartnershipItems operation in PartnershipsApi.
 * @export
 * @interface PartnershipsApiGetPartnershipItemsRequest
 */
export interface PartnershipsApiGetPartnershipItemsRequest {
    /**
     * 
     * @type {string}
     * @memberof PartnershipsApiGetPartnershipItems
     */
    readonly partnershipId: string

    /**
     * 
     * @type {number}
     * @memberof PartnershipsApiGetPartnershipItems
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof PartnershipsApiGetPartnershipItems
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<'product' | 'product.defaultTariffGroupPriceInfo' | 'product.defaultTariff'>}
     * @memberof PartnershipsApiGetPartnershipItems
     */
    readonly include?: Array<GetPartnershipItemsIncludeEnum>
}

/**
 * Request parameters for getProvidedPartnershipSaleItems operation in PartnershipsApi.
 * @export
 * @interface PartnershipsApiGetProvidedPartnershipSaleItemsRequest
 */
export interface PartnershipsApiGetProvidedPartnershipSaleItemsRequest {
    /**
     * 
     * @type {string}
     * @memberof PartnershipsApiGetProvidedPartnershipSaleItems
     */
    readonly partnershipId: string

    /**
     * 
     * @type {number}
     * @memberof PartnershipsApiGetProvidedPartnershipSaleItems
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof PartnershipsApiGetProvidedPartnershipSaleItems
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof PartnershipsApiGetProvidedPartnershipSaleItems
     */
    readonly since?: string | null

    /**
     * 
     * @type {string}
     * @memberof PartnershipsApiGetProvidedPartnershipSaleItems
     */
    readonly until?: string | null

    /**
     * 
     * @type {Array<'product' | 'sale' | 'partnershipItem.product' | 'partnershipItem.partnership' | 'partnershipItem.partnership.provider' | 'partnershipItem.partnership.agent' | 'partnershipItem.partnership.publicProposal' | 'reservation' | 'reservation.product' | 'partnershipProvider' | 'partnershipAgent'>}
     * @memberof PartnershipsApiGetProvidedPartnershipSaleItems
     */
    readonly include?: Array<GetProvidedPartnershipSaleItemsIncludeEnum>

    /**
     * 
     * @type {'recent'}
     * @memberof PartnershipsApiGetProvidedPartnershipSaleItems
     */
    readonly sortBy?: GetProvidedPartnershipSaleItemsSortByEnum
}

/**
 * Request parameters for getPublicPartnershipProposal operation in PartnershipsApi.
 * @export
 * @interface PartnershipsApiGetPublicPartnershipProposalRequest
 */
export interface PartnershipsApiGetPublicPartnershipProposalRequest {
    /**
     * 
     * @type {string}
     * @memberof PartnershipsApiGetPublicPartnershipProposal
     */
    readonly publicPartnershipProposalCode: string

    /**
     * 
     * @type {Array<'provider' | 'provider.info'>}
     * @memberof PartnershipsApiGetPublicPartnershipProposal
     */
    readonly include?: Array<GetPublicPartnershipProposalIncludeEnum>
}

/**
 * Request parameters for getPublicPartnershipProposalItems operation in PartnershipsApi.
 * @export
 * @interface PartnershipsApiGetPublicPartnershipProposalItemsRequest
 */
export interface PartnershipsApiGetPublicPartnershipProposalItemsRequest {
    /**
     * 
     * @type {string}
     * @memberof PartnershipsApiGetPublicPartnershipProposalItems
     */
    readonly publicPartnershipProposalCode: string

    /**
     * 
     * @type {number}
     * @memberof PartnershipsApiGetPublicPartnershipProposalItems
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof PartnershipsApiGetPublicPartnershipProposalItems
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<'product' | 'product.defaultTariffGroupPriceInfo' | 'product.defaultTariff'>}
     * @memberof PartnershipsApiGetPublicPartnershipProposalItems
     */
    readonly include?: Array<GetPublicPartnershipProposalItemsIncludeEnum>
}

/**
 * Request parameters for updateArrangedPartnership operation in PartnershipsApi.
 * @export
 * @interface PartnershipsApiUpdateArrangedPartnershipRequest
 */
export interface PartnershipsApiUpdateArrangedPartnershipRequest {
    /**
     * 
     * @type {string}
     * @memberof PartnershipsApiUpdateArrangedPartnership
     */
    readonly partnershipId: string

    /**
     * 
     * @type {UpdateArrangedPartnershipParams}
     * @memberof PartnershipsApiUpdateArrangedPartnership
     */
    readonly updateArrangedPartnershipParams: UpdateArrangedPartnershipParams
}

/**
 * Request parameters for updatePartnership operation in PartnershipsApi.
 * @export
 * @interface PartnershipsApiUpdatePartnershipRequest
 */
export interface PartnershipsApiUpdatePartnershipRequest {
    /**
     * 
     * @type {string}
     * @memberof PartnershipsApiUpdatePartnership
     */
    readonly partnershipId: string

    /**
     * 
     * @type {UpdatePartnershipParams}
     * @memberof PartnershipsApiUpdatePartnership
     */
    readonly updatePartnershipParams: UpdatePartnershipParams
}

/**
 * PartnershipsApi - object-oriented interface
 * @export
 * @class PartnershipsApi
 * @extends {BaseAPI}
 */
export class PartnershipsApi extends BaseAPI {
    /**
     * 
     * @param {PartnershipsApiApprovePartnershipRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnershipsApi
     */
    public approvePartnership(requestParameters: PartnershipsApiApprovePartnershipRequest, options?: RawAxiosRequestConfig) {
        return PartnershipsApiFp(this.configuration).approvePartnership(requestParameters.partnershipId, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PartnershipsApiCreateAppArrangedPartnershipRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnershipsApi
     */
    public createAppArrangedPartnership(requestParameters: PartnershipsApiCreateAppArrangedPartnershipRequest, options?: RawAxiosRequestConfig) {
        return PartnershipsApiFp(this.configuration).createAppArrangedPartnership(requestParameters.appId, requestParameters.createArrangedPartnershipParams, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PartnershipsApiDeclinePartnershipRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnershipsApi
     */
    public declinePartnership(requestParameters: PartnershipsApiDeclinePartnershipRequest, options?: RawAxiosRequestConfig) {
        return PartnershipsApiFp(this.configuration).declinePartnership(requestParameters.partnershipId, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PartnershipsApiGetAppArrangedPartnershipsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnershipsApi
     */
    public getAppArrangedPartnerships(requestParameters: PartnershipsApiGetAppArrangedPartnershipsRequest, options?: RawAxiosRequestConfig) {
        return PartnershipsApiFp(this.configuration).getAppArrangedPartnerships(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.currentStatus, requestParameters.sortBy, requestParameters.include, requestParameters.search, requestParameters.associationModels, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PartnershipsApiGetAppPartnershipProposalsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnershipsApi
     */
    public getAppPartnershipProposals(requestParameters: PartnershipsApiGetAppPartnershipProposalsRequest, options?: RawAxiosRequestConfig) {
        return PartnershipsApiFp(this.configuration).getAppPartnershipProposals(requestParameters.appId, requestParameters.offset, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PartnershipsApiGetAppPartnershipProvidersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnershipsApi
     */
    public getAppPartnershipProviders(requestParameters: PartnershipsApiGetAppPartnershipProvidersRequest, options?: RawAxiosRequestConfig) {
        return PartnershipsApiFp(this.configuration).getAppPartnershipProviders(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.search, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PartnershipsApiGetAppProvidedPartnershipsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnershipsApi
     */
    public getAppProvidedPartnerships(requestParameters: PartnershipsApiGetAppProvidedPartnershipsRequest, options?: RawAxiosRequestConfig) {
        return PartnershipsApiFp(this.configuration).getAppProvidedPartnerships(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.currentStatus, requestParameters.sortBy, requestParameters.include, requestParameters.search, requestParameters.partnershipProposalIds, requestParameters.associationModels, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PartnershipsApiGetAppScheduledProductsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnershipsApi
     */
    public getAppScheduledProducts(requestParameters: PartnershipsApiGetAppScheduledProductsRequest, options?: RawAxiosRequestConfig) {
        return PartnershipsApiFp(this.configuration).getAppScheduledProducts(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.include, requestParameters.schedulingSince, requestParameters.schedulingUntil, requestParameters.search, requestParameters.sortBy, requestParameters.sellingModes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PartnershipsApiGetAppValidArrangedPartnershipsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnershipsApi
     */
    public getAppValidArrangedPartnerships(requestParameters: PartnershipsApiGetAppValidArrangedPartnershipsRequest, options?: RawAxiosRequestConfig) {
        return PartnershipsApiFp(this.configuration).getAppValidArrangedPartnerships(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.currentStatus, requestParameters.sortBy, requestParameters.include, requestParameters.search, requestParameters.associationModels, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PartnershipsApiGetAppValidProvidedPartnershipsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnershipsApi
     */
    public getAppValidProvidedPartnerships(requestParameters: PartnershipsApiGetAppValidProvidedPartnershipsRequest, options?: RawAxiosRequestConfig) {
        return PartnershipsApiFp(this.configuration).getAppValidProvidedPartnerships(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.currentStatus, requestParameters.sortBy, requestParameters.include, requestParameters.search, requestParameters.partnershipProposalIds, requestParameters.associationModels, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PartnershipsApiGetPartnershipRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnershipsApi
     */
    public getPartnership(requestParameters: PartnershipsApiGetPartnershipRequest, options?: RawAxiosRequestConfig) {
        return PartnershipsApiFp(this.configuration).getPartnership(requestParameters.partnershipId, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PartnershipsApiGetPartnershipCouponsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnershipsApi
     */
    public getPartnershipCoupons(requestParameters: PartnershipsApiGetPartnershipCouponsRequest, options?: RawAxiosRequestConfig) {
        return PartnershipsApiFp(this.configuration).getPartnershipCoupons(requestParameters.partnershipId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PartnershipsApiGetPartnershipItemsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnershipsApi
     */
    public getPartnershipItems(requestParameters: PartnershipsApiGetPartnershipItemsRequest, options?: RawAxiosRequestConfig) {
        return PartnershipsApiFp(this.configuration).getPartnershipItems(requestParameters.partnershipId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PartnershipsApiGetProvidedPartnershipSaleItemsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnershipsApi
     */
    public getProvidedPartnershipSaleItems(requestParameters: PartnershipsApiGetProvidedPartnershipSaleItemsRequest, options?: RawAxiosRequestConfig) {
        return PartnershipsApiFp(this.configuration).getProvidedPartnershipSaleItems(requestParameters.partnershipId, requestParameters.offset, requestParameters.limit, requestParameters.since, requestParameters.until, requestParameters.include, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PartnershipsApiGetPublicPartnershipProposalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnershipsApi
     */
    public getPublicPartnershipProposal(requestParameters: PartnershipsApiGetPublicPartnershipProposalRequest, options?: RawAxiosRequestConfig) {
        return PartnershipsApiFp(this.configuration).getPublicPartnershipProposal(requestParameters.publicPartnershipProposalCode, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PartnershipsApiGetPublicPartnershipProposalItemsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnershipsApi
     */
    public getPublicPartnershipProposalItems(requestParameters: PartnershipsApiGetPublicPartnershipProposalItemsRequest, options?: RawAxiosRequestConfig) {
        return PartnershipsApiFp(this.configuration).getPublicPartnershipProposalItems(requestParameters.publicPartnershipProposalCode, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PartnershipsApiUpdateArrangedPartnershipRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnershipsApi
     */
    public updateArrangedPartnership(requestParameters: PartnershipsApiUpdateArrangedPartnershipRequest, options?: RawAxiosRequestConfig) {
        return PartnershipsApiFp(this.configuration).updateArrangedPartnership(requestParameters.partnershipId, requestParameters.updateArrangedPartnershipParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PartnershipsApiUpdatePartnershipRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnershipsApi
     */
    public updatePartnership(requestParameters: PartnershipsApiUpdatePartnershipRequest, options?: RawAxiosRequestConfig) {
        return PartnershipsApiFp(this.configuration).updatePartnership(requestParameters.partnershipId, requestParameters.updatePartnershipParams, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ApprovePartnershipIncludeEnum = {
    Provider: 'provider',
    ProviderInfo: 'provider.info',
    Agent: 'agent',
    AgentInfo: 'agent.info',
    PublicProposal: 'publicProposal',
    PublicProposalProvider: 'publicProposal.provider',
    PublicProposalProviderInfo: 'publicProposal.provider.info'
} as const;
export type ApprovePartnershipIncludeEnum = typeof ApprovePartnershipIncludeEnum[keyof typeof ApprovePartnershipIncludeEnum];
/**
 * @export
 */
export const CreateAppArrangedPartnershipIncludeEnum = {
    Provider: 'provider',
    ProviderInfo: 'provider.info',
    Agent: 'agent',
    AgentInfo: 'agent.info',
    PublicProposal: 'publicProposal',
    PublicProposalProvider: 'publicProposal.provider',
    PublicProposalProviderInfo: 'publicProposal.provider.info'
} as const;
export type CreateAppArrangedPartnershipIncludeEnum = typeof CreateAppArrangedPartnershipIncludeEnum[keyof typeof CreateAppArrangedPartnershipIncludeEnum];
/**
 * @export
 */
export const DeclinePartnershipIncludeEnum = {
    Provider: 'provider',
    ProviderInfo: 'provider.info',
    Agent: 'agent',
    AgentInfo: 'agent.info',
    PublicProposal: 'publicProposal',
    PublicProposalProvider: 'publicProposal.provider',
    PublicProposalProviderInfo: 'publicProposal.provider.info'
} as const;
export type DeclinePartnershipIncludeEnum = typeof DeclinePartnershipIncludeEnum[keyof typeof DeclinePartnershipIncludeEnum];
/**
 * @export
 */
export const GetAppArrangedPartnershipsSortByEnum = {
    Recent: 'recent'
} as const;
export type GetAppArrangedPartnershipsSortByEnum = typeof GetAppArrangedPartnershipsSortByEnum[keyof typeof GetAppArrangedPartnershipsSortByEnum];
/**
 * @export
 */
export const GetAppArrangedPartnershipsIncludeEnum = {
    Provider: 'provider',
    ProviderInfo: 'provider.info',
    Agent: 'agent',
    AgentInfo: 'agent.info',
    PublicProposal: 'publicProposal',
    PublicProposalProvider: 'publicProposal.provider',
    PublicProposalProviderInfo: 'publicProposal.provider.info'
} as const;
export type GetAppArrangedPartnershipsIncludeEnum = typeof GetAppArrangedPartnershipsIncludeEnum[keyof typeof GetAppArrangedPartnershipsIncludeEnum];
/**
 * @export
 */
export const GetAppPartnershipProvidersIncludeEnum = {
    Plan: 'plan',
    Info: 'info'
} as const;
export type GetAppPartnershipProvidersIncludeEnum = typeof GetAppPartnershipProvidersIncludeEnum[keyof typeof GetAppPartnershipProvidersIncludeEnum];
/**
 * @export
 */
export const GetAppProvidedPartnershipsSortByEnum = {
    Recent: 'recent'
} as const;
export type GetAppProvidedPartnershipsSortByEnum = typeof GetAppProvidedPartnershipsSortByEnum[keyof typeof GetAppProvidedPartnershipsSortByEnum];
/**
 * @export
 */
export const GetAppProvidedPartnershipsIncludeEnum = {
    Provider: 'provider',
    ProviderInfo: 'provider.info',
    Agent: 'agent',
    AgentInfo: 'agent.info',
    PublicProposal: 'publicProposal',
    PublicProposalProvider: 'publicProposal.provider',
    PublicProposalProviderInfo: 'publicProposal.provider.info'
} as const;
export type GetAppProvidedPartnershipsIncludeEnum = typeof GetAppProvidedPartnershipsIncludeEnum[keyof typeof GetAppProvidedPartnershipsIncludeEnum];
/**
 * @export
 */
export const GetAppScheduledProductsIncludeEnum = {
    DefaultTariff: 'defaultTariff',
    DefaultTariffGroupPriceInfo: 'defaultTariffGroupPriceInfo'
} as const;
export type GetAppScheduledProductsIncludeEnum = typeof GetAppScheduledProductsIncludeEnum[keyof typeof GetAppScheduledProductsIncludeEnum];
/**
 * @export
 */
export const GetAppScheduledProductsSortByEnum = {
    Recent: 'recent',
    Name: 'name'
} as const;
export type GetAppScheduledProductsSortByEnum = typeof GetAppScheduledProductsSortByEnum[keyof typeof GetAppScheduledProductsSortByEnum];
/**
 * @export
 */
export const GetAppValidArrangedPartnershipsSortByEnum = {
    Recent: 'recent'
} as const;
export type GetAppValidArrangedPartnershipsSortByEnum = typeof GetAppValidArrangedPartnershipsSortByEnum[keyof typeof GetAppValidArrangedPartnershipsSortByEnum];
/**
 * @export
 */
export const GetAppValidArrangedPartnershipsIncludeEnum = {
    Provider: 'provider',
    ProviderInfo: 'provider.info',
    Agent: 'agent',
    AgentInfo: 'agent.info',
    PublicProposal: 'publicProposal',
    PublicProposalProvider: 'publicProposal.provider',
    PublicProposalProviderInfo: 'publicProposal.provider.info'
} as const;
export type GetAppValidArrangedPartnershipsIncludeEnum = typeof GetAppValidArrangedPartnershipsIncludeEnum[keyof typeof GetAppValidArrangedPartnershipsIncludeEnum];
/**
 * @export
 */
export const GetAppValidProvidedPartnershipsSortByEnum = {
    Recent: 'recent'
} as const;
export type GetAppValidProvidedPartnershipsSortByEnum = typeof GetAppValidProvidedPartnershipsSortByEnum[keyof typeof GetAppValidProvidedPartnershipsSortByEnum];
/**
 * @export
 */
export const GetAppValidProvidedPartnershipsIncludeEnum = {
    Provider: 'provider',
    ProviderInfo: 'provider.info',
    Agent: 'agent',
    AgentInfo: 'agent.info',
    PublicProposal: 'publicProposal',
    PublicProposalProvider: 'publicProposal.provider',
    PublicProposalProviderInfo: 'publicProposal.provider.info'
} as const;
export type GetAppValidProvidedPartnershipsIncludeEnum = typeof GetAppValidProvidedPartnershipsIncludeEnum[keyof typeof GetAppValidProvidedPartnershipsIncludeEnum];
/**
 * @export
 */
export const GetPartnershipIncludeEnum = {
    Provider: 'provider',
    ProviderInfo: 'provider.info',
    Agent: 'agent',
    AgentInfo: 'agent.info',
    PublicProposal: 'publicProposal',
    PublicProposalProvider: 'publicProposal.provider',
    PublicProposalProviderInfo: 'publicProposal.provider.info'
} as const;
export type GetPartnershipIncludeEnum = typeof GetPartnershipIncludeEnum[keyof typeof GetPartnershipIncludeEnum];
/**
 * @export
 */
export const GetPartnershipCouponsIncludeEnum = {
    Products: 'products',
    Usage: 'usage'
} as const;
export type GetPartnershipCouponsIncludeEnum = typeof GetPartnershipCouponsIncludeEnum[keyof typeof GetPartnershipCouponsIncludeEnum];
/**
 * @export
 */
export const GetPartnershipItemsIncludeEnum = {
    Product: 'product',
    ProductDefaultTariffGroupPriceInfo: 'product.defaultTariffGroupPriceInfo',
    ProductDefaultTariff: 'product.defaultTariff'
} as const;
export type GetPartnershipItemsIncludeEnum = typeof GetPartnershipItemsIncludeEnum[keyof typeof GetPartnershipItemsIncludeEnum];
/**
 * @export
 */
export const GetProvidedPartnershipSaleItemsIncludeEnum = {
    Product: 'product',
    Sale: 'sale',
    PartnershipItemProduct: 'partnershipItem.product',
    PartnershipItemPartnership: 'partnershipItem.partnership',
    PartnershipItemPartnershipProvider: 'partnershipItem.partnership.provider',
    PartnershipItemPartnershipAgent: 'partnershipItem.partnership.agent',
    PartnershipItemPartnershipPublicProposal: 'partnershipItem.partnership.publicProposal',
    Reservation: 'reservation',
    ReservationProduct: 'reservation.product',
    PartnershipProvider: 'partnershipProvider',
    PartnershipAgent: 'partnershipAgent'
} as const;
export type GetProvidedPartnershipSaleItemsIncludeEnum = typeof GetProvidedPartnershipSaleItemsIncludeEnum[keyof typeof GetProvidedPartnershipSaleItemsIncludeEnum];
/**
 * @export
 */
export const GetProvidedPartnershipSaleItemsSortByEnum = {
    Recent: 'recent'
} as const;
export type GetProvidedPartnershipSaleItemsSortByEnum = typeof GetProvidedPartnershipSaleItemsSortByEnum[keyof typeof GetProvidedPartnershipSaleItemsSortByEnum];
/**
 * @export
 */
export const GetPublicPartnershipProposalIncludeEnum = {
    Provider: 'provider',
    ProviderInfo: 'provider.info'
} as const;
export type GetPublicPartnershipProposalIncludeEnum = typeof GetPublicPartnershipProposalIncludeEnum[keyof typeof GetPublicPartnershipProposalIncludeEnum];
/**
 * @export
 */
export const GetPublicPartnershipProposalItemsIncludeEnum = {
    Product: 'product',
    ProductDefaultTariffGroupPriceInfo: 'product.defaultTariffGroupPriceInfo',
    ProductDefaultTariff: 'product.defaultTariff'
} as const;
export type GetPublicPartnershipProposalItemsIncludeEnum = typeof GetPublicPartnershipProposalItemsIncludeEnum[keyof typeof GetPublicPartnershipProposalItemsIncludeEnum];


/**
 * PaymentMethodsApi - axios parameter creator
 * @export
 */
export const PaymentMethodsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppPaymentMethods: async (appId: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppPaymentMethods', 'appId', appId)
            const localVarPath = `/apps/{appId}/paymentMethods`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentMethodsApi - functional programming interface
 * @export
 */
export const PaymentMethodsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentMethodsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppPaymentMethods(appId: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PaymentMethod>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppPaymentMethods(appId, offset, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentMethodsApi.getAppPaymentMethods']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PaymentMethodsApi - factory interface
 * @export
 */
export const PaymentMethodsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentMethodsApiFp(configuration)
    return {
        /**
         * 
         * @param {PaymentMethodsApiGetAppPaymentMethodsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppPaymentMethods(requestParameters: PaymentMethodsApiGetAppPaymentMethodsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<PaymentMethod>> {
            return localVarFp.getAppPaymentMethods(requestParameters.appId, requestParameters.offset, requestParameters.limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAppPaymentMethods operation in PaymentMethodsApi.
 * @export
 * @interface PaymentMethodsApiGetAppPaymentMethodsRequest
 */
export interface PaymentMethodsApiGetAppPaymentMethodsRequest {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodsApiGetAppPaymentMethods
     */
    readonly appId: string

    /**
     * 
     * @type {number}
     * @memberof PaymentMethodsApiGetAppPaymentMethods
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof PaymentMethodsApiGetAppPaymentMethods
     */
    readonly limit?: number
}

/**
 * PaymentMethodsApi - object-oriented interface
 * @export
 * @class PaymentMethodsApi
 * @extends {BaseAPI}
 */
export class PaymentMethodsApi extends BaseAPI {
    /**
     * 
     * @param {PaymentMethodsApiGetAppPaymentMethodsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentMethodsApi
     */
    public getAppPaymentMethods(requestParameters: PaymentMethodsApiGetAppPaymentMethodsRequest, options?: RawAxiosRequestConfig) {
        return PaymentMethodsApiFp(this.configuration).getAppPaymentMethods(requestParameters.appId, requestParameters.offset, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PlansApi - axios parameter creator
 * @export
 */
export const PlansApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} planId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<string>} [ids] 
         * @param {Array<TransactableTypeEnum>} [types] 
         * @param {Array<PaymentMethodCode>} [paymentMethodCodes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlanFees: async (planId: string, offset?: number, limit?: number, ids?: Array<string>, types?: Array<TransactableTypeEnum>, paymentMethodCodes?: Array<PaymentMethodCode>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planId' is not null or undefined
            assertParamExists('getPlanFees', 'planId', planId)
            const localVarPath = `/plans/{planId}/fees`
                .replace(`{${"planId"}}`, encodeURIComponent(String(planId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids.join(COLLECTION_FORMATS.csv);
            }

            if (types) {
                localVarQueryParameter['types'] = types;
            }

            if (paymentMethodCodes) {
                localVarQueryParameter['paymentMethodCodes'] = paymentMethodCodes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlansApi - functional programming interface
 * @export
 */
export const PlansApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlansApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} planId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<string>} [ids] 
         * @param {Array<TransactableTypeEnum>} [types] 
         * @param {Array<PaymentMethodCode>} [paymentMethodCodes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlanFees(planId: string, offset?: number, limit?: number, ids?: Array<string>, types?: Array<TransactableTypeEnum>, paymentMethodCodes?: Array<PaymentMethodCode>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlanFee>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlanFees(planId, offset, limit, ids, types, paymentMethodCodes, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PlansApi.getPlanFees']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PlansApi - factory interface
 * @export
 */
export const PlansApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlansApiFp(configuration)
    return {
        /**
         * 
         * @param {PlansApiGetPlanFeesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlanFees(requestParameters: PlansApiGetPlanFeesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<PlanFee>> {
            return localVarFp.getPlanFees(requestParameters.planId, requestParameters.offset, requestParameters.limit, requestParameters.ids, requestParameters.types, requestParameters.paymentMethodCodes, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getPlanFees operation in PlansApi.
 * @export
 * @interface PlansApiGetPlanFeesRequest
 */
export interface PlansApiGetPlanFeesRequest {
    /**
     * 
     * @type {string}
     * @memberof PlansApiGetPlanFees
     */
    readonly planId: string

    /**
     * 
     * @type {number}
     * @memberof PlansApiGetPlanFees
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof PlansApiGetPlanFees
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof PlansApiGetPlanFees
     */
    readonly ids?: Array<string>

    /**
     * 
     * @type {Array<TransactableTypeEnum>}
     * @memberof PlansApiGetPlanFees
     */
    readonly types?: Array<TransactableTypeEnum>

    /**
     * 
     * @type {Array<PaymentMethodCode>}
     * @memberof PlansApiGetPlanFees
     */
    readonly paymentMethodCodes?: Array<PaymentMethodCode>
}

/**
 * PlansApi - object-oriented interface
 * @export
 * @class PlansApi
 * @extends {BaseAPI}
 */
export class PlansApi extends BaseAPI {
    /**
     * 
     * @param {PlansApiGetPlanFeesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlansApi
     */
    public getPlanFees(requestParameters: PlansApiGetPlanFeesRequest, options?: RawAxiosRequestConfig) {
        return PlansApiFp(this.configuration).getPlanFees(requestParameters.planId, requestParameters.offset, requestParameters.limit, requestParameters.ids, requestParameters.types, requestParameters.paymentMethodCodes, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PoliciesApi - axios parameter creator
 * @export
 */
export const PoliciesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolePolicies: async (roleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('getRolePolicies', 'roleId', roleId)
            const localVarPath = `/roles/{roleId}/policies`
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PoliciesApi - functional programming interface
 * @export
 */
export const PoliciesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PoliciesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRolePolicies(roleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PolicyRule>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRolePolicies(roleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PoliciesApi.getRolePolicies']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PoliciesApi - factory interface
 * @export
 */
export const PoliciesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PoliciesApiFp(configuration)
    return {
        /**
         * 
         * @param {PoliciesApiGetRolePoliciesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolePolicies(requestParameters: PoliciesApiGetRolePoliciesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<PolicyRule>> {
            return localVarFp.getRolePolicies(requestParameters.roleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getRolePolicies operation in PoliciesApi.
 * @export
 * @interface PoliciesApiGetRolePoliciesRequest
 */
export interface PoliciesApiGetRolePoliciesRequest {
    /**
     * 
     * @type {string}
     * @memberof PoliciesApiGetRolePolicies
     */
    readonly roleId: string
}

/**
 * PoliciesApi - object-oriented interface
 * @export
 * @class PoliciesApi
 * @extends {BaseAPI}
 */
export class PoliciesApi extends BaseAPI {
    /**
     * 
     * @param {PoliciesApiGetRolePoliciesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoliciesApi
     */
    public getRolePolicies(requestParameters: PoliciesApiGetRolePoliciesRequest, options?: RawAxiosRequestConfig) {
        return PoliciesApiFp(this.configuration).getRolePolicies(requestParameters.roleId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PriceModifierMutatorsApi - axios parameter creator
 * @export
 */
export const PriceModifierMutatorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} priceModifierId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetPriceModifierMutatorsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriceModifierMutators: async (priceModifierId: string, offset?: number, limit?: number, include?: Array<GetPriceModifierMutatorsIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'priceModifierId' is not null or undefined
            assertParamExists('getPriceModifierMutators', 'priceModifierId', priceModifierId)
            const localVarPath = `/priceModifiers/{priceModifierId}/mutators`
                .replace(`{${"priceModifierId"}}`, encodeURIComponent(String(priceModifierId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PriceModifierMutatorsApi - functional programming interface
 * @export
 */
export const PriceModifierMutatorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PriceModifierMutatorsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} priceModifierId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetPriceModifierMutatorsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPriceModifierMutators(priceModifierId: string, offset?: number, limit?: number, include?: Array<GetPriceModifierMutatorsIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PriceModifierMutator>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPriceModifierMutators(priceModifierId, offset, limit, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PriceModifierMutatorsApi.getPriceModifierMutators']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PriceModifierMutatorsApi - factory interface
 * @export
 */
export const PriceModifierMutatorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PriceModifierMutatorsApiFp(configuration)
    return {
        /**
         * 
         * @param {PriceModifierMutatorsApiGetPriceModifierMutatorsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriceModifierMutators(requestParameters: PriceModifierMutatorsApiGetPriceModifierMutatorsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<PriceModifierMutator>> {
            return localVarFp.getPriceModifierMutators(requestParameters.priceModifierId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getPriceModifierMutators operation in PriceModifierMutatorsApi.
 * @export
 * @interface PriceModifierMutatorsApiGetPriceModifierMutatorsRequest
 */
export interface PriceModifierMutatorsApiGetPriceModifierMutatorsRequest {
    /**
     * 
     * @type {string}
     * @memberof PriceModifierMutatorsApiGetPriceModifierMutators
     */
    readonly priceModifierId: string

    /**
     * 
     * @type {number}
     * @memberof PriceModifierMutatorsApiGetPriceModifierMutators
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof PriceModifierMutatorsApiGetPriceModifierMutators
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<'conditions' | 'conditions.entityParameters' | 'conditions.entityParameters.product' | 'conditions.entityParameters.tariffType' | 'conditions.entityParameters.tariffGroup'>}
     * @memberof PriceModifierMutatorsApiGetPriceModifierMutators
     */
    readonly include?: Array<GetPriceModifierMutatorsIncludeEnum>
}

/**
 * PriceModifierMutatorsApi - object-oriented interface
 * @export
 * @class PriceModifierMutatorsApi
 * @extends {BaseAPI}
 */
export class PriceModifierMutatorsApi extends BaseAPI {
    /**
     * 
     * @param {PriceModifierMutatorsApiGetPriceModifierMutatorsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceModifierMutatorsApi
     */
    public getPriceModifierMutators(requestParameters: PriceModifierMutatorsApiGetPriceModifierMutatorsRequest, options?: RawAxiosRequestConfig) {
        return PriceModifierMutatorsApiFp(this.configuration).getPriceModifierMutators(requestParameters.priceModifierId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetPriceModifierMutatorsIncludeEnum = {
    Conditions: 'conditions',
    ConditionsEntityParameters: 'conditions.entityParameters',
    ConditionsEntityParametersProduct: 'conditions.entityParameters.product',
    ConditionsEntityParametersTariffType: 'conditions.entityParameters.tariffType',
    ConditionsEntityParametersTariffGroup: 'conditions.entityParameters.tariffGroup'
} as const;
export type GetPriceModifierMutatorsIncludeEnum = typeof GetPriceModifierMutatorsIncludeEnum[keyof typeof GetPriceModifierMutatorsIncludeEnum];


/**
 * PriceModifiersApi - axios parameter creator
 * @export
 */
export const PriceModifiersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} appId 
         * @param {PriceModifierParams} priceModifierParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppPriceModifier: async (appId: string, priceModifierParams: PriceModifierParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('createAppPriceModifier', 'appId', appId)
            // verify required parameter 'priceModifierParams' is not null or undefined
            assertParamExists('createAppPriceModifier', 'priceModifierParams', priceModifierParams)
            const localVarPath = `/apps/{appId}/priceModifiers`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(priceModifierParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppPriceModifiers: async (appId: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppPriceModifiers', 'appId', appId)
            const localVarPath = `/apps/{appId}/priceModifiers`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} priceModifierId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriceModifier: async (priceModifierId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'priceModifierId' is not null or undefined
            assertParamExists('getPriceModifier', 'priceModifierId', priceModifierId)
            const localVarPath = `/priceModifiers/{priceModifierId}`
                .replace(`{${"priceModifierId"}}`, encodeURIComponent(String(priceModifierId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PriceModifiersApi - functional programming interface
 * @export
 */
export const PriceModifiersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PriceModifiersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} appId 
         * @param {PriceModifierParams} priceModifierParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAppPriceModifier(appId: string, priceModifierParams: PriceModifierParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceModifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAppPriceModifier(appId, priceModifierParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PriceModifiersApi.createAppPriceModifier']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppPriceModifiers(appId: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PriceModifier>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppPriceModifiers(appId, offset, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PriceModifiersApi.getAppPriceModifiers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} priceModifierId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPriceModifier(priceModifierId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceModifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPriceModifier(priceModifierId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PriceModifiersApi.getPriceModifier']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PriceModifiersApi - factory interface
 * @export
 */
export const PriceModifiersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PriceModifiersApiFp(configuration)
    return {
        /**
         * 
         * @param {PriceModifiersApiCreateAppPriceModifierRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppPriceModifier(requestParameters: PriceModifiersApiCreateAppPriceModifierRequest, options?: RawAxiosRequestConfig): AxiosPromise<PriceModifier> {
            return localVarFp.createAppPriceModifier(requestParameters.appId, requestParameters.priceModifierParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PriceModifiersApiGetAppPriceModifiersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppPriceModifiers(requestParameters: PriceModifiersApiGetAppPriceModifiersRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<PriceModifier>> {
            return localVarFp.getAppPriceModifiers(requestParameters.appId, requestParameters.offset, requestParameters.limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PriceModifiersApiGetPriceModifierRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriceModifier(requestParameters: PriceModifiersApiGetPriceModifierRequest, options?: RawAxiosRequestConfig): AxiosPromise<PriceModifier> {
            return localVarFp.getPriceModifier(requestParameters.priceModifierId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createAppPriceModifier operation in PriceModifiersApi.
 * @export
 * @interface PriceModifiersApiCreateAppPriceModifierRequest
 */
export interface PriceModifiersApiCreateAppPriceModifierRequest {
    /**
     * 
     * @type {string}
     * @memberof PriceModifiersApiCreateAppPriceModifier
     */
    readonly appId: string

    /**
     * 
     * @type {PriceModifierParams}
     * @memberof PriceModifiersApiCreateAppPriceModifier
     */
    readonly priceModifierParams: PriceModifierParams
}

/**
 * Request parameters for getAppPriceModifiers operation in PriceModifiersApi.
 * @export
 * @interface PriceModifiersApiGetAppPriceModifiersRequest
 */
export interface PriceModifiersApiGetAppPriceModifiersRequest {
    /**
     * 
     * @type {string}
     * @memberof PriceModifiersApiGetAppPriceModifiers
     */
    readonly appId: string

    /**
     * 
     * @type {number}
     * @memberof PriceModifiersApiGetAppPriceModifiers
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof PriceModifiersApiGetAppPriceModifiers
     */
    readonly limit?: number
}

/**
 * Request parameters for getPriceModifier operation in PriceModifiersApi.
 * @export
 * @interface PriceModifiersApiGetPriceModifierRequest
 */
export interface PriceModifiersApiGetPriceModifierRequest {
    /**
     * 
     * @type {string}
     * @memberof PriceModifiersApiGetPriceModifier
     */
    readonly priceModifierId: string
}

/**
 * PriceModifiersApi - object-oriented interface
 * @export
 * @class PriceModifiersApi
 * @extends {BaseAPI}
 */
export class PriceModifiersApi extends BaseAPI {
    /**
     * 
     * @param {PriceModifiersApiCreateAppPriceModifierRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceModifiersApi
     */
    public createAppPriceModifier(requestParameters: PriceModifiersApiCreateAppPriceModifierRequest, options?: RawAxiosRequestConfig) {
        return PriceModifiersApiFp(this.configuration).createAppPriceModifier(requestParameters.appId, requestParameters.priceModifierParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PriceModifiersApiGetAppPriceModifiersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceModifiersApi
     */
    public getAppPriceModifiers(requestParameters: PriceModifiersApiGetAppPriceModifiersRequest, options?: RawAxiosRequestConfig) {
        return PriceModifiersApiFp(this.configuration).getAppPriceModifiers(requestParameters.appId, requestParameters.offset, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PriceModifiersApiGetPriceModifierRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceModifiersApi
     */
    public getPriceModifier(requestParameters: PriceModifiersApiGetPriceModifierRequest, options?: RawAxiosRequestConfig) {
        return PriceModifiersApiFp(this.configuration).getPriceModifier(requestParameters.priceModifierId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProductImagesApi - axios parameter creator
 * @export
 */
export const ProductImagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} appId 
         * @param {string} productId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetAppSellableProductImagesIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppSellableProductImages: async (appId: string, productId: string, offset?: number, limit?: number, include?: Array<GetAppSellableProductImagesIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppSellableProductImages', 'appId', appId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getAppSellableProductImages', 'productId', productId)
            const localVarPath = `/apps/{appId}/sellableProducts/{productId}/images`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductImagesApi - functional programming interface
 * @export
 */
export const ProductImagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductImagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} appId 
         * @param {string} productId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetAppSellableProductImagesIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppSellableProductImages(appId: string, productId: string, offset?: number, limit?: number, include?: Array<GetAppSellableProductImagesIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicProductImage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppSellableProductImages(appId, productId, offset, limit, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductImagesApi.getAppSellableProductImages']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProductImagesApi - factory interface
 * @export
 */
export const ProductImagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductImagesApiFp(configuration)
    return {
        /**
         * 
         * @param {ProductImagesApiGetAppSellableProductImagesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppSellableProductImages(requestParameters: ProductImagesApiGetAppSellableProductImagesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<PublicProductImage>> {
            return localVarFp.getAppSellableProductImages(requestParameters.appId, requestParameters.productId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAppSellableProductImages operation in ProductImagesApi.
 * @export
 * @interface ProductImagesApiGetAppSellableProductImagesRequest
 */
export interface ProductImagesApiGetAppSellableProductImagesRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductImagesApiGetAppSellableProductImages
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof ProductImagesApiGetAppSellableProductImages
     */
    readonly productId: string

    /**
     * 
     * @type {number}
     * @memberof ProductImagesApiGetAppSellableProductImages
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof ProductImagesApiGetAppSellableProductImages
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<'image'>}
     * @memberof ProductImagesApiGetAppSellableProductImages
     */
    readonly include?: Array<GetAppSellableProductImagesIncludeEnum>
}

/**
 * ProductImagesApi - object-oriented interface
 * @export
 * @class ProductImagesApi
 * @extends {BaseAPI}
 */
export class ProductImagesApi extends BaseAPI {
    /**
     * 
     * @param {ProductImagesApiGetAppSellableProductImagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductImagesApi
     */
    public getAppSellableProductImages(requestParameters: ProductImagesApiGetAppSellableProductImagesRequest, options?: RawAxiosRequestConfig) {
        return ProductImagesApiFp(this.configuration).getAppSellableProductImages(requestParameters.appId, requestParameters.productId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAppSellableProductImagesIncludeEnum = {
    Image: 'image'
} as const;
export type GetAppSellableProductImagesIncludeEnum = typeof GetAppSellableProductImagesIncludeEnum[keyof typeof GetAppSellableProductImagesIncludeEnum];


/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} appId 
         * @param {ProductParams} productParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppProduct: async (appId: string, productParams: ProductParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('createAppProduct', 'appId', appId)
            // verify required parameter 'productParams' is not null or undefined
            assertParamExists('createAppProduct', 'productParams', productParams)
            const localVarPath = `/apps/{appId}/products`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetAppArrangedProductsIncludeEnum>} [include] 
         * @param {string} [schedulingSince] 
         * @param {string} [schedulingUntil] 
         * @param {Array<AssociationModel>} [associationModels] 
         * @param {Array<string>} [providerIds] 
         * @param {string} [search] 
         * @param {GetAppArrangedProductsSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppArrangedProducts: async (appId: string, offset?: number, limit?: number, include?: Array<GetAppArrangedProductsIncludeEnum>, schedulingSince?: string, schedulingUntil?: string, associationModels?: Array<AssociationModel>, providerIds?: Array<string>, search?: string, sortBy?: GetAppArrangedProductsSortByEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppArrangedProducts', 'appId', appId)
            const localVarPath = `/apps/{appId}/arrangedProducts`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (schedulingSince !== undefined) {
                localVarQueryParameter['schedulingSince'] = (schedulingSince as any instanceof Date) ?
                    (schedulingSince as any).toISOString().substring(0,10) :
                    schedulingSince;
            }

            if (schedulingUntil !== undefined) {
                localVarQueryParameter['schedulingUntil'] = (schedulingUntil as any instanceof Date) ?
                    (schedulingUntil as any).toISOString().substring(0,10) :
                    schedulingUntil;
            }

            if (associationModels) {
                localVarQueryParameter['associationModels'] = associationModels.join(COLLECTION_FORMATS.csv);
            }

            if (providerIds) {
                localVarQueryParameter['providerIds'] = providerIds.join(COLLECTION_FORMATS.csv);
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {Array<string>} [ids] 
         * @param {boolean} [activeOnly] 
         * @param {Array<SellingMode>} [sellingModes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppProducts: async (appId: string, offset?: number, limit?: number, search?: string, ids?: Array<string>, activeOnly?: boolean, sellingModes?: Array<SellingMode>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppProducts', 'appId', appId)
            const localVarPath = `/apps/{appId}/products`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids.join(COLLECTION_FORMATS.csv);
            }

            if (activeOnly !== undefined) {
                localVarQueryParameter['activeOnly'] = activeOnly;
            }

            if (sellingModes) {
                localVarQueryParameter['sellingModes'] = sellingModes.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetAppSellableProductsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppSellableProducts: async (appId: string, offset?: number, limit?: number, include?: Array<GetAppSellableProductsIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppSellableProducts', 'appId', appId)
            const localVarPath = `/apps/{appId}/sellableProducts`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {string} arrangedProductId 
         * @param {Array<GetArrangedProductIncludeEnum>} [include] 
         * @param {Array<AssociationModel>} [associationModels] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArrangedProduct: async (appId: string, arrangedProductId: string, include?: Array<GetArrangedProductIncludeEnum>, associationModels?: Array<AssociationModel>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getArrangedProduct', 'appId', appId)
            // verify required parameter 'arrangedProductId' is not null or undefined
            assertParamExists('getArrangedProduct', 'arrangedProductId', arrangedProductId)
            const localVarPath = `/apps/{appId}/arrangedProducts/{arrangedProductId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"arrangedProductId"}}`, encodeURIComponent(String(arrangedProductId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (associationModels) {
                localVarQueryParameter['associationModels'] = associationModels.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProduct: async (productId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProduct', 'productId', productId)
            const localVarPath = `/products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetProductAdditionalsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductAdditionals: async (productId: string, offset?: number, limit?: number, include?: Array<GetProductAdditionalsIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductAdditionals', 'productId', productId)
            const localVarPath = `/products/{productId}/additionals`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductCategories: async (productId: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductCategories', 'productId', productId)
            const localVarPath = `/products/{productId}/categories`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetProductDetailedTariffGroupsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductDetailedTariffGroups: async (productId: string, offset?: number, limit?: number, include?: Array<GetProductDetailedTariffGroupsIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductDetailedTariffGroups', 'productId', productId)
            const localVarPath = `/products/{productId}/detailedTariffGroups`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductImages: async (productId: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductImages', 'productId', productId)
            const localVarPath = `/products/{productId}/images`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetProductRelatedTariffTypesIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductRelatedTariffTypes: async (productId: string, offset?: number, limit?: number, include?: Array<GetProductRelatedTariffTypesIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductRelatedTariffTypes', 'productId', productId)
            const localVarPath = `/products/{productId}/relatedTariffTypes`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} appId 
         * @param {ProductParams} productParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAppProduct(appId: string, productParams: ProductParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAppProduct(appId, productParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.createAppProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetAppArrangedProductsIncludeEnum>} [include] 
         * @param {string} [schedulingSince] 
         * @param {string} [schedulingUntil] 
         * @param {Array<AssociationModel>} [associationModels] 
         * @param {Array<string>} [providerIds] 
         * @param {string} [search] 
         * @param {GetAppArrangedProductsSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppArrangedProducts(appId: string, offset?: number, limit?: number, include?: Array<GetAppArrangedProductsIncludeEnum>, schedulingSince?: string, schedulingUntil?: string, associationModels?: Array<AssociationModel>, providerIds?: Array<string>, search?: string, sortBy?: GetAppArrangedProductsSortByEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ArrangedProduct>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppArrangedProducts(appId, offset, limit, include, schedulingSince, schedulingUntil, associationModels, providerIds, search, sortBy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.getAppArrangedProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {Array<string>} [ids] 
         * @param {boolean} [activeOnly] 
         * @param {Array<SellingMode>} [sellingModes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppProducts(appId: string, offset?: number, limit?: number, search?: string, ids?: Array<string>, activeOnly?: boolean, sellingModes?: Array<SellingMode>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Product>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppProducts(appId, offset, limit, search, ids, activeOnly, sellingModes, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.getAppProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetAppSellableProductsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppSellableProducts(appId: string, offset?: number, limit?: number, include?: Array<GetAppSellableProductsIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicProduct>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppSellableProducts(appId, offset, limit, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.getAppSellableProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {string} arrangedProductId 
         * @param {Array<GetArrangedProductIncludeEnum>} [include] 
         * @param {Array<AssociationModel>} [associationModels] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArrangedProduct(appId: string, arrangedProductId: string, include?: Array<GetArrangedProductIncludeEnum>, associationModels?: Array<AssociationModel>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArrangedProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArrangedProduct(appId, arrangedProductId, include, associationModels, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.getArrangedProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProduct(productId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProduct(productId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.getProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} productId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetProductAdditionalsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductAdditionals(productId: string, offset?: number, limit?: number, include?: Array<GetProductAdditionalsIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Additional>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductAdditionals(productId, offset, limit, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.getProductAdditionals']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} productId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductCategories(productId: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductCategories(productId, offset, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.getProductCategories']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} productId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetProductDetailedTariffGroupsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductDetailedTariffGroups(productId: string, offset?: number, limit?: number, include?: Array<GetProductDetailedTariffGroupsIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DetailedTariffGroup>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductDetailedTariffGroups(productId, offset, limit, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.getProductDetailedTariffGroups']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} productId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductImages(productId: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductImage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductImages(productId, offset, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.getProductImages']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} productId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetProductRelatedTariffTypesIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductRelatedTariffTypes(productId: string, offset?: number, limit?: number, include?: Array<GetProductRelatedTariffTypesIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TariffType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductRelatedTariffTypes(productId, offset, limit, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.getProductRelatedTariffTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsApiFp(configuration)
    return {
        /**
         * 
         * @param {ProductsApiCreateAppProductRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppProduct(requestParameters: ProductsApiCreateAppProductRequest, options?: RawAxiosRequestConfig): AxiosPromise<Product> {
            return localVarFp.createAppProduct(requestParameters.appId, requestParameters.productParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductsApiGetAppArrangedProductsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppArrangedProducts(requestParameters: ProductsApiGetAppArrangedProductsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<ArrangedProduct>> {
            return localVarFp.getAppArrangedProducts(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.include, requestParameters.schedulingSince, requestParameters.schedulingUntil, requestParameters.associationModels, requestParameters.providerIds, requestParameters.search, requestParameters.sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductsApiGetAppProductsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppProducts(requestParameters: ProductsApiGetAppProductsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Product>> {
            return localVarFp.getAppProducts(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.search, requestParameters.ids, requestParameters.activeOnly, requestParameters.sellingModes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductsApiGetAppSellableProductsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppSellableProducts(requestParameters: ProductsApiGetAppSellableProductsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<PublicProduct>> {
            return localVarFp.getAppSellableProducts(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductsApiGetArrangedProductRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArrangedProduct(requestParameters: ProductsApiGetArrangedProductRequest, options?: RawAxiosRequestConfig): AxiosPromise<ArrangedProduct> {
            return localVarFp.getArrangedProduct(requestParameters.appId, requestParameters.arrangedProductId, requestParameters.include, requestParameters.associationModels, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductsApiGetProductRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProduct(requestParameters: ProductsApiGetProductRequest, options?: RawAxiosRequestConfig): AxiosPromise<Product> {
            return localVarFp.getProduct(requestParameters.productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductsApiGetProductAdditionalsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductAdditionals(requestParameters: ProductsApiGetProductAdditionalsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Additional>> {
            return localVarFp.getProductAdditionals(requestParameters.productId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductsApiGetProductCategoriesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductCategories(requestParameters: ProductsApiGetProductCategoriesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<ProductCategory>> {
            return localVarFp.getProductCategories(requestParameters.productId, requestParameters.offset, requestParameters.limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductsApiGetProductDetailedTariffGroupsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductDetailedTariffGroups(requestParameters: ProductsApiGetProductDetailedTariffGroupsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<DetailedTariffGroup>> {
            return localVarFp.getProductDetailedTariffGroups(requestParameters.productId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductsApiGetProductImagesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductImages(requestParameters: ProductsApiGetProductImagesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<ProductImage>> {
            return localVarFp.getProductImages(requestParameters.productId, requestParameters.offset, requestParameters.limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductsApiGetProductRelatedTariffTypesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductRelatedTariffTypes(requestParameters: ProductsApiGetProductRelatedTariffTypesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<TariffType>> {
            return localVarFp.getProductRelatedTariffTypes(requestParameters.productId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createAppProduct operation in ProductsApi.
 * @export
 * @interface ProductsApiCreateAppProductRequest
 */
export interface ProductsApiCreateAppProductRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductsApiCreateAppProduct
     */
    readonly appId: string

    /**
     * 
     * @type {ProductParams}
     * @memberof ProductsApiCreateAppProduct
     */
    readonly productParams: ProductParams
}

/**
 * Request parameters for getAppArrangedProducts operation in ProductsApi.
 * @export
 * @interface ProductsApiGetAppArrangedProductsRequest
 */
export interface ProductsApiGetAppArrangedProductsRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductsApiGetAppArrangedProducts
     */
    readonly appId: string

    /**
     * 
     * @type {number}
     * @memberof ProductsApiGetAppArrangedProducts
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiGetAppArrangedProducts
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<'partnershipItem' | 'partnershipItem.partnership' | 'partnershipItem.partnership.provider' | 'partnershipItem.partnership.agent' | 'partnershipItem.partnership.publicProposal' | 'defaultTariffGroupPriceInfo' | 'defaultTariff'>}
     * @memberof ProductsApiGetAppArrangedProducts
     */
    readonly include?: Array<GetAppArrangedProductsIncludeEnum>

    /**
     * 
     * @type {string}
     * @memberof ProductsApiGetAppArrangedProducts
     */
    readonly schedulingSince?: string

    /**
     * 
     * @type {string}
     * @memberof ProductsApiGetAppArrangedProducts
     */
    readonly schedulingUntil?: string

    /**
     * 
     * @type {Array<AssociationModel>}
     * @memberof ProductsApiGetAppArrangedProducts
     */
    readonly associationModels?: Array<AssociationModel>

    /**
     * 
     * @type {Array<string>}
     * @memberof ProductsApiGetAppArrangedProducts
     */
    readonly providerIds?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof ProductsApiGetAppArrangedProducts
     */
    readonly search?: string

    /**
     * 
     * @type {'recent' | 'name'}
     * @memberof ProductsApiGetAppArrangedProducts
     */
    readonly sortBy?: GetAppArrangedProductsSortByEnum
}

/**
 * Request parameters for getAppProducts operation in ProductsApi.
 * @export
 * @interface ProductsApiGetAppProductsRequest
 */
export interface ProductsApiGetAppProductsRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductsApiGetAppProducts
     */
    readonly appId: string

    /**
     * 
     * @type {number}
     * @memberof ProductsApiGetAppProducts
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiGetAppProducts
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof ProductsApiGetAppProducts
     */
    readonly search?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ProductsApiGetAppProducts
     */
    readonly ids?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof ProductsApiGetAppProducts
     */
    readonly activeOnly?: boolean

    /**
     * 
     * @type {Array<SellingMode>}
     * @memberof ProductsApiGetAppProducts
     */
    readonly sellingModes?: Array<SellingMode>
}

/**
 * Request parameters for getAppSellableProducts operation in ProductsApi.
 * @export
 * @interface ProductsApiGetAppSellableProductsRequest
 */
export interface ProductsApiGetAppSellableProductsRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductsApiGetAppSellableProducts
     */
    readonly appId: string

    /**
     * 
     * @type {number}
     * @memberof ProductsApiGetAppSellableProducts
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiGetAppSellableProducts
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<'defaultTariff'>}
     * @memberof ProductsApiGetAppSellableProducts
     */
    readonly include?: Array<GetAppSellableProductsIncludeEnum>
}

/**
 * Request parameters for getArrangedProduct operation in ProductsApi.
 * @export
 * @interface ProductsApiGetArrangedProductRequest
 */
export interface ProductsApiGetArrangedProductRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductsApiGetArrangedProduct
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof ProductsApiGetArrangedProduct
     */
    readonly arrangedProductId: string

    /**
     * 
     * @type {Array<'partnershipItem' | 'partnershipItem.partnership' | 'partnershipItem.partnership.provider' | 'partnershipItem.partnership.agent' | 'partnershipItem.partnership.publicProposal' | 'defaultTariffGroupPriceInfo' | 'defaultTariff'>}
     * @memberof ProductsApiGetArrangedProduct
     */
    readonly include?: Array<GetArrangedProductIncludeEnum>

    /**
     * 
     * @type {Array<AssociationModel>}
     * @memberof ProductsApiGetArrangedProduct
     */
    readonly associationModels?: Array<AssociationModel>
}

/**
 * Request parameters for getProduct operation in ProductsApi.
 * @export
 * @interface ProductsApiGetProductRequest
 */
export interface ProductsApiGetProductRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductsApiGetProduct
     */
    readonly productId: string
}

/**
 * Request parameters for getProductAdditionals operation in ProductsApi.
 * @export
 * @interface ProductsApiGetProductAdditionalsRequest
 */
export interface ProductsApiGetProductAdditionalsRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductsApiGetProductAdditionals
     */
    readonly productId: string

    /**
     * 
     * @type {number}
     * @memberof ProductsApiGetProductAdditionals
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiGetProductAdditionals
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<'product'>}
     * @memberof ProductsApiGetProductAdditionals
     */
    readonly include?: Array<GetProductAdditionalsIncludeEnum>
}

/**
 * Request parameters for getProductCategories operation in ProductsApi.
 * @export
 * @interface ProductsApiGetProductCategoriesRequest
 */
export interface ProductsApiGetProductCategoriesRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductsApiGetProductCategories
     */
    readonly productId: string

    /**
     * 
     * @type {number}
     * @memberof ProductsApiGetProductCategories
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiGetProductCategories
     */
    readonly limit?: number
}

/**
 * Request parameters for getProductDetailedTariffGroups operation in ProductsApi.
 * @export
 * @interface ProductsApiGetProductDetailedTariffGroupsRequest
 */
export interface ProductsApiGetProductDetailedTariffGroupsRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductsApiGetProductDetailedTariffGroups
     */
    readonly productId: string

    /**
     * 
     * @type {number}
     * @memberof ProductsApiGetProductDetailedTariffGroups
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiGetProductDetailedTariffGroups
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<'tariffs' | 'tariffs.type' | 'tariffs.group' | 'tariffs.product' | 'priceInfo'>}
     * @memberof ProductsApiGetProductDetailedTariffGroups
     */
    readonly include?: Array<GetProductDetailedTariffGroupsIncludeEnum>
}

/**
 * Request parameters for getProductImages operation in ProductsApi.
 * @export
 * @interface ProductsApiGetProductImagesRequest
 */
export interface ProductsApiGetProductImagesRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductsApiGetProductImages
     */
    readonly productId: string

    /**
     * 
     * @type {number}
     * @memberof ProductsApiGetProductImages
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiGetProductImages
     */
    readonly limit?: number
}

/**
 * Request parameters for getProductRelatedTariffTypes operation in ProductsApi.
 * @export
 * @interface ProductsApiGetProductRelatedTariffTypesRequest
 */
export interface ProductsApiGetProductRelatedTariffTypesRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductsApiGetProductRelatedTariffTypes
     */
    readonly productId: string

    /**
     * 
     * @type {number}
     * @memberof ProductsApiGetProductRelatedTariffTypes
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiGetProductRelatedTariffTypes
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<'app'>}
     * @memberof ProductsApiGetProductRelatedTariffTypes
     */
    readonly include?: Array<GetProductRelatedTariffTypesIncludeEnum>
}

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * 
     * @param {ProductsApiCreateAppProductRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public createAppProduct(requestParameters: ProductsApiCreateAppProductRequest, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).createAppProduct(requestParameters.appId, requestParameters.productParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiGetAppArrangedProductsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getAppArrangedProducts(requestParameters: ProductsApiGetAppArrangedProductsRequest, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getAppArrangedProducts(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.include, requestParameters.schedulingSince, requestParameters.schedulingUntil, requestParameters.associationModels, requestParameters.providerIds, requestParameters.search, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiGetAppProductsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getAppProducts(requestParameters: ProductsApiGetAppProductsRequest, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getAppProducts(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.search, requestParameters.ids, requestParameters.activeOnly, requestParameters.sellingModes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiGetAppSellableProductsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getAppSellableProducts(requestParameters: ProductsApiGetAppSellableProductsRequest, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getAppSellableProducts(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiGetArrangedProductRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getArrangedProduct(requestParameters: ProductsApiGetArrangedProductRequest, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getArrangedProduct(requestParameters.appId, requestParameters.arrangedProductId, requestParameters.include, requestParameters.associationModels, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiGetProductRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getProduct(requestParameters: ProductsApiGetProductRequest, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getProduct(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiGetProductAdditionalsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getProductAdditionals(requestParameters: ProductsApiGetProductAdditionalsRequest, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getProductAdditionals(requestParameters.productId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiGetProductCategoriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getProductCategories(requestParameters: ProductsApiGetProductCategoriesRequest, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getProductCategories(requestParameters.productId, requestParameters.offset, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiGetProductDetailedTariffGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getProductDetailedTariffGroups(requestParameters: ProductsApiGetProductDetailedTariffGroupsRequest, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getProductDetailedTariffGroups(requestParameters.productId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiGetProductImagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getProductImages(requestParameters: ProductsApiGetProductImagesRequest, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getProductImages(requestParameters.productId, requestParameters.offset, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiGetProductRelatedTariffTypesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getProductRelatedTariffTypes(requestParameters: ProductsApiGetProductRelatedTariffTypesRequest, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getProductRelatedTariffTypes(requestParameters.productId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAppArrangedProductsIncludeEnum = {
    PartnershipItem: 'partnershipItem',
    PartnershipItemPartnership: 'partnershipItem.partnership',
    PartnershipItemPartnershipProvider: 'partnershipItem.partnership.provider',
    PartnershipItemPartnershipAgent: 'partnershipItem.partnership.agent',
    PartnershipItemPartnershipPublicProposal: 'partnershipItem.partnership.publicProposal',
    DefaultTariffGroupPriceInfo: 'defaultTariffGroupPriceInfo',
    DefaultTariff: 'defaultTariff'
} as const;
export type GetAppArrangedProductsIncludeEnum = typeof GetAppArrangedProductsIncludeEnum[keyof typeof GetAppArrangedProductsIncludeEnum];
/**
 * @export
 */
export const GetAppArrangedProductsSortByEnum = {
    Recent: 'recent',
    Name: 'name'
} as const;
export type GetAppArrangedProductsSortByEnum = typeof GetAppArrangedProductsSortByEnum[keyof typeof GetAppArrangedProductsSortByEnum];
/**
 * @export
 */
export const GetAppSellableProductsIncludeEnum = {
    DefaultTariff: 'defaultTariff'
} as const;
export type GetAppSellableProductsIncludeEnum = typeof GetAppSellableProductsIncludeEnum[keyof typeof GetAppSellableProductsIncludeEnum];
/**
 * @export
 */
export const GetArrangedProductIncludeEnum = {
    PartnershipItem: 'partnershipItem',
    PartnershipItemPartnership: 'partnershipItem.partnership',
    PartnershipItemPartnershipProvider: 'partnershipItem.partnership.provider',
    PartnershipItemPartnershipAgent: 'partnershipItem.partnership.agent',
    PartnershipItemPartnershipPublicProposal: 'partnershipItem.partnership.publicProposal',
    DefaultTariffGroupPriceInfo: 'defaultTariffGroupPriceInfo',
    DefaultTariff: 'defaultTariff'
} as const;
export type GetArrangedProductIncludeEnum = typeof GetArrangedProductIncludeEnum[keyof typeof GetArrangedProductIncludeEnum];
/**
 * @export
 */
export const GetProductAdditionalsIncludeEnum = {
    Product: 'product'
} as const;
export type GetProductAdditionalsIncludeEnum = typeof GetProductAdditionalsIncludeEnum[keyof typeof GetProductAdditionalsIncludeEnum];
/**
 * @export
 */
export const GetProductDetailedTariffGroupsIncludeEnum = {
    Tariffs: 'tariffs',
    TariffsType: 'tariffs.type',
    TariffsGroup: 'tariffs.group',
    TariffsProduct: 'tariffs.product',
    PriceInfo: 'priceInfo'
} as const;
export type GetProductDetailedTariffGroupsIncludeEnum = typeof GetProductDetailedTariffGroupsIncludeEnum[keyof typeof GetProductDetailedTariffGroupsIncludeEnum];
/**
 * @export
 */
export const GetProductRelatedTariffTypesIncludeEnum = {
    App: 'app'
} as const;
export type GetProductRelatedTariffTypesIncludeEnum = typeof GetProductRelatedTariffTypesIncludeEnum[keyof typeof GetProductRelatedTariffTypesIncludeEnum];


/**
 * RefundRequestsApi - axios parameter creator
 * @export
 */
export const RefundRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} saleId 
         * @param {RefundRequestParams} refundRequestParams 
         * @param {string | null} [search] 
         * @param {Array<RefundRequestStatus> | null} [status] 
         * @param {string | null} [since] 
         * @param {string | null} [until] 
         * @param {Array<CreateSaleRefundRequestIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSaleRefundRequest: async (saleId: string, refundRequestParams: RefundRequestParams, search?: string | null, status?: Array<RefundRequestStatus> | null, since?: string | null, until?: string | null, include?: Array<CreateSaleRefundRequestIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('createSaleRefundRequest', 'saleId', saleId)
            // verify required parameter 'refundRequestParams' is not null or undefined
            assertParamExists('createSaleRefundRequest', 'refundRequestParams', refundRequestParams)
            const localVarPath = `/sales/{saleId}/refundRequests`
                .replace(`{${"saleId"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString() :
                    since;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = (until as any instanceof Date) ?
                    (until as any).toISOString() :
                    until;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refundRequestParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string | null} [search] 
         * @param {Array<RefundRequestStatus> | null} [status] 
         * @param {string | null} [since] 
         * @param {string | null} [until] 
         * @param {Array<GetAppRefundRequestsIncludeEnum>} [include] 
         * @param {GetAppRefundRequestsSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppRefundRequests: async (appId: string, offset?: number, limit?: number, search?: string | null, status?: Array<RefundRequestStatus> | null, since?: string | null, until?: string | null, include?: Array<GetAppRefundRequestsIncludeEnum>, sortBy?: GetAppRefundRequestsSortByEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppRefundRequests', 'appId', appId)
            const localVarPath = `/apps/{appId}/refundRequests`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString() :
                    since;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = (until as any instanceof Date) ?
                    (until as any).toISOString() :
                    until;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} refundRequestId 
         * @param {Array<GetRefundRequestIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRefundRequest: async (refundRequestId: string, include?: Array<GetRefundRequestIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refundRequestId' is not null or undefined
            assertParamExists('getRefundRequest', 'refundRequestId', refundRequestId)
            const localVarPath = `/refundRequests/{refundRequestId}`
                .replace(`{${"refundRequestId"}}`, encodeURIComponent(String(refundRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} saleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSaleRefundInsights: async (saleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('getSaleRefundInsights', 'saleId', saleId)
            const localVarPath = `/sales/{saleId}/refund/insights`
                .replace(`{${"saleId"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} saleId 
         * @param {string | null} [search] 
         * @param {Array<RefundRequestStatus> | null} [status] 
         * @param {string | null} [since] 
         * @param {string | null} [until] 
         * @param {Array<GetSaleRefundRequestsIncludeEnum>} [include] 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSaleRefundRequests: async (saleId: string, search?: string | null, status?: Array<RefundRequestStatus> | null, since?: string | null, until?: string | null, include?: Array<GetSaleRefundRequestsIncludeEnum>, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('getSaleRefundRequests', 'saleId', saleId)
            const localVarPath = `/sales/{saleId}/refundRequests`
                .replace(`{${"saleId"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString() :
                    since;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = (until as any instanceof Date) ?
                    (until as any).toISOString() :
                    until;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RefundRequestsApi - functional programming interface
 * @export
 */
export const RefundRequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RefundRequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} saleId 
         * @param {RefundRequestParams} refundRequestParams 
         * @param {string | null} [search] 
         * @param {Array<RefundRequestStatus> | null} [status] 
         * @param {string | null} [since] 
         * @param {string | null} [until] 
         * @param {Array<CreateSaleRefundRequestIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSaleRefundRequest(saleId: string, refundRequestParams: RefundRequestParams, search?: string | null, status?: Array<RefundRequestStatus> | null, since?: string | null, until?: string | null, include?: Array<CreateSaleRefundRequestIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefundRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSaleRefundRequest(saleId, refundRequestParams, search, status, since, until, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RefundRequestsApi.createSaleRefundRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string | null} [search] 
         * @param {Array<RefundRequestStatus> | null} [status] 
         * @param {string | null} [since] 
         * @param {string | null} [until] 
         * @param {Array<GetAppRefundRequestsIncludeEnum>} [include] 
         * @param {GetAppRefundRequestsSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppRefundRequests(appId: string, offset?: number, limit?: number, search?: string | null, status?: Array<RefundRequestStatus> | null, since?: string | null, until?: string | null, include?: Array<GetAppRefundRequestsIncludeEnum>, sortBy?: GetAppRefundRequestsSortByEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RefundRequest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppRefundRequests(appId, offset, limit, search, status, since, until, include, sortBy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RefundRequestsApi.getAppRefundRequests']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} refundRequestId 
         * @param {Array<GetRefundRequestIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRefundRequest(refundRequestId: string, include?: Array<GetRefundRequestIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefundRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRefundRequest(refundRequestId, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RefundRequestsApi.getRefundRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} saleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSaleRefundInsights(saleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefundRequestInsights>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSaleRefundInsights(saleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RefundRequestsApi.getSaleRefundInsights']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} saleId 
         * @param {string | null} [search] 
         * @param {Array<RefundRequestStatus> | null} [status] 
         * @param {string | null} [since] 
         * @param {string | null} [until] 
         * @param {Array<GetSaleRefundRequestsIncludeEnum>} [include] 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSaleRefundRequests(saleId: string, search?: string | null, status?: Array<RefundRequestStatus> | null, since?: string | null, until?: string | null, include?: Array<GetSaleRefundRequestsIncludeEnum>, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RefundRequest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSaleRefundRequests(saleId, search, status, since, until, include, offset, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RefundRequestsApi.getSaleRefundRequests']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RefundRequestsApi - factory interface
 * @export
 */
export const RefundRequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RefundRequestsApiFp(configuration)
    return {
        /**
         * 
         * @param {RefundRequestsApiCreateSaleRefundRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSaleRefundRequest(requestParameters: RefundRequestsApiCreateSaleRefundRequestRequest, options?: RawAxiosRequestConfig): AxiosPromise<RefundRequest> {
            return localVarFp.createSaleRefundRequest(requestParameters.saleId, requestParameters.refundRequestParams, requestParameters.search, requestParameters.status, requestParameters.since, requestParameters.until, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RefundRequestsApiGetAppRefundRequestsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppRefundRequests(requestParameters: RefundRequestsApiGetAppRefundRequestsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<RefundRequest>> {
            return localVarFp.getAppRefundRequests(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.search, requestParameters.status, requestParameters.since, requestParameters.until, requestParameters.include, requestParameters.sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RefundRequestsApiGetRefundRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRefundRequest(requestParameters: RefundRequestsApiGetRefundRequestRequest, options?: RawAxiosRequestConfig): AxiosPromise<RefundRequest> {
            return localVarFp.getRefundRequest(requestParameters.refundRequestId, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RefundRequestsApiGetSaleRefundInsightsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSaleRefundInsights(requestParameters: RefundRequestsApiGetSaleRefundInsightsRequest, options?: RawAxiosRequestConfig): AxiosPromise<RefundRequestInsights> {
            return localVarFp.getSaleRefundInsights(requestParameters.saleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RefundRequestsApiGetSaleRefundRequestsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSaleRefundRequests(requestParameters: RefundRequestsApiGetSaleRefundRequestsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<RefundRequest>> {
            return localVarFp.getSaleRefundRequests(requestParameters.saleId, requestParameters.search, requestParameters.status, requestParameters.since, requestParameters.until, requestParameters.include, requestParameters.offset, requestParameters.limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createSaleRefundRequest operation in RefundRequestsApi.
 * @export
 * @interface RefundRequestsApiCreateSaleRefundRequestRequest
 */
export interface RefundRequestsApiCreateSaleRefundRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof RefundRequestsApiCreateSaleRefundRequest
     */
    readonly saleId: string

    /**
     * 
     * @type {RefundRequestParams}
     * @memberof RefundRequestsApiCreateSaleRefundRequest
     */
    readonly refundRequestParams: RefundRequestParams

    /**
     * 
     * @type {string}
     * @memberof RefundRequestsApiCreateSaleRefundRequest
     */
    readonly search?: string | null

    /**
     * 
     * @type {Array<RefundRequestStatus>}
     * @memberof RefundRequestsApiCreateSaleRefundRequest
     */
    readonly status?: Array<RefundRequestStatus> | null

    /**
     * 
     * @type {string}
     * @memberof RefundRequestsApiCreateSaleRefundRequest
     */
    readonly since?: string | null

    /**
     * 
     * @type {string}
     * @memberof RefundRequestsApiCreateSaleRefundRequest
     */
    readonly until?: string | null

    /**
     * 
     * @type {Array<'sale' | 'sale.customer' | 'sale.aggregatedVouchers'>}
     * @memberof RefundRequestsApiCreateSaleRefundRequest
     */
    readonly include?: Array<CreateSaleRefundRequestIncludeEnum>
}

/**
 * Request parameters for getAppRefundRequests operation in RefundRequestsApi.
 * @export
 * @interface RefundRequestsApiGetAppRefundRequestsRequest
 */
export interface RefundRequestsApiGetAppRefundRequestsRequest {
    /**
     * 
     * @type {string}
     * @memberof RefundRequestsApiGetAppRefundRequests
     */
    readonly appId: string

    /**
     * 
     * @type {number}
     * @memberof RefundRequestsApiGetAppRefundRequests
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof RefundRequestsApiGetAppRefundRequests
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof RefundRequestsApiGetAppRefundRequests
     */
    readonly search?: string | null

    /**
     * 
     * @type {Array<RefundRequestStatus>}
     * @memberof RefundRequestsApiGetAppRefundRequests
     */
    readonly status?: Array<RefundRequestStatus> | null

    /**
     * 
     * @type {string}
     * @memberof RefundRequestsApiGetAppRefundRequests
     */
    readonly since?: string | null

    /**
     * 
     * @type {string}
     * @memberof RefundRequestsApiGetAppRefundRequests
     */
    readonly until?: string | null

    /**
     * 
     * @type {Array<'sale' | 'sale.customer' | 'sale.aggregatedVouchers'>}
     * @memberof RefundRequestsApiGetAppRefundRequests
     */
    readonly include?: Array<GetAppRefundRequestsIncludeEnum>

    /**
     * 
     * @type {'recent'}
     * @memberof RefundRequestsApiGetAppRefundRequests
     */
    readonly sortBy?: GetAppRefundRequestsSortByEnum
}

/**
 * Request parameters for getRefundRequest operation in RefundRequestsApi.
 * @export
 * @interface RefundRequestsApiGetRefundRequestRequest
 */
export interface RefundRequestsApiGetRefundRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof RefundRequestsApiGetRefundRequest
     */
    readonly refundRequestId: string

    /**
     * 
     * @type {Array<'sale' | 'sale.customer' | 'sale.aggregatedVouchers'>}
     * @memberof RefundRequestsApiGetRefundRequest
     */
    readonly include?: Array<GetRefundRequestIncludeEnum>
}

/**
 * Request parameters for getSaleRefundInsights operation in RefundRequestsApi.
 * @export
 * @interface RefundRequestsApiGetSaleRefundInsightsRequest
 */
export interface RefundRequestsApiGetSaleRefundInsightsRequest {
    /**
     * 
     * @type {string}
     * @memberof RefundRequestsApiGetSaleRefundInsights
     */
    readonly saleId: string
}

/**
 * Request parameters for getSaleRefundRequests operation in RefundRequestsApi.
 * @export
 * @interface RefundRequestsApiGetSaleRefundRequestsRequest
 */
export interface RefundRequestsApiGetSaleRefundRequestsRequest {
    /**
     * 
     * @type {string}
     * @memberof RefundRequestsApiGetSaleRefundRequests
     */
    readonly saleId: string

    /**
     * 
     * @type {string}
     * @memberof RefundRequestsApiGetSaleRefundRequests
     */
    readonly search?: string | null

    /**
     * 
     * @type {Array<RefundRequestStatus>}
     * @memberof RefundRequestsApiGetSaleRefundRequests
     */
    readonly status?: Array<RefundRequestStatus> | null

    /**
     * 
     * @type {string}
     * @memberof RefundRequestsApiGetSaleRefundRequests
     */
    readonly since?: string | null

    /**
     * 
     * @type {string}
     * @memberof RefundRequestsApiGetSaleRefundRequests
     */
    readonly until?: string | null

    /**
     * 
     * @type {Array<'sale' | 'sale.customer' | 'sale.aggregatedVouchers'>}
     * @memberof RefundRequestsApiGetSaleRefundRequests
     */
    readonly include?: Array<GetSaleRefundRequestsIncludeEnum>

    /**
     * 
     * @type {number}
     * @memberof RefundRequestsApiGetSaleRefundRequests
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof RefundRequestsApiGetSaleRefundRequests
     */
    readonly limit?: number
}

/**
 * RefundRequestsApi - object-oriented interface
 * @export
 * @class RefundRequestsApi
 * @extends {BaseAPI}
 */
export class RefundRequestsApi extends BaseAPI {
    /**
     * 
     * @param {RefundRequestsApiCreateSaleRefundRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefundRequestsApi
     */
    public createSaleRefundRequest(requestParameters: RefundRequestsApiCreateSaleRefundRequestRequest, options?: RawAxiosRequestConfig) {
        return RefundRequestsApiFp(this.configuration).createSaleRefundRequest(requestParameters.saleId, requestParameters.refundRequestParams, requestParameters.search, requestParameters.status, requestParameters.since, requestParameters.until, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RefundRequestsApiGetAppRefundRequestsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefundRequestsApi
     */
    public getAppRefundRequests(requestParameters: RefundRequestsApiGetAppRefundRequestsRequest, options?: RawAxiosRequestConfig) {
        return RefundRequestsApiFp(this.configuration).getAppRefundRequests(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.search, requestParameters.status, requestParameters.since, requestParameters.until, requestParameters.include, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RefundRequestsApiGetRefundRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefundRequestsApi
     */
    public getRefundRequest(requestParameters: RefundRequestsApiGetRefundRequestRequest, options?: RawAxiosRequestConfig) {
        return RefundRequestsApiFp(this.configuration).getRefundRequest(requestParameters.refundRequestId, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RefundRequestsApiGetSaleRefundInsightsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefundRequestsApi
     */
    public getSaleRefundInsights(requestParameters: RefundRequestsApiGetSaleRefundInsightsRequest, options?: RawAxiosRequestConfig) {
        return RefundRequestsApiFp(this.configuration).getSaleRefundInsights(requestParameters.saleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RefundRequestsApiGetSaleRefundRequestsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefundRequestsApi
     */
    public getSaleRefundRequests(requestParameters: RefundRequestsApiGetSaleRefundRequestsRequest, options?: RawAxiosRequestConfig) {
        return RefundRequestsApiFp(this.configuration).getSaleRefundRequests(requestParameters.saleId, requestParameters.search, requestParameters.status, requestParameters.since, requestParameters.until, requestParameters.include, requestParameters.offset, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const CreateSaleRefundRequestIncludeEnum = {
    Sale: 'sale',
    SaleCustomer: 'sale.customer',
    SaleAggregatedVouchers: 'sale.aggregatedVouchers'
} as const;
export type CreateSaleRefundRequestIncludeEnum = typeof CreateSaleRefundRequestIncludeEnum[keyof typeof CreateSaleRefundRequestIncludeEnum];
/**
 * @export
 */
export const GetAppRefundRequestsIncludeEnum = {
    Sale: 'sale',
    SaleCustomer: 'sale.customer',
    SaleAggregatedVouchers: 'sale.aggregatedVouchers'
} as const;
export type GetAppRefundRequestsIncludeEnum = typeof GetAppRefundRequestsIncludeEnum[keyof typeof GetAppRefundRequestsIncludeEnum];
/**
 * @export
 */
export const GetAppRefundRequestsSortByEnum = {
    Recent: 'recent'
} as const;
export type GetAppRefundRequestsSortByEnum = typeof GetAppRefundRequestsSortByEnum[keyof typeof GetAppRefundRequestsSortByEnum];
/**
 * @export
 */
export const GetRefundRequestIncludeEnum = {
    Sale: 'sale',
    SaleCustomer: 'sale.customer',
    SaleAggregatedVouchers: 'sale.aggregatedVouchers'
} as const;
export type GetRefundRequestIncludeEnum = typeof GetRefundRequestIncludeEnum[keyof typeof GetRefundRequestIncludeEnum];
/**
 * @export
 */
export const GetSaleRefundRequestsIncludeEnum = {
    Sale: 'sale',
    SaleCustomer: 'sale.customer',
    SaleAggregatedVouchers: 'sale.aggregatedVouchers'
} as const;
export type GetSaleRefundRequestsIncludeEnum = typeof GetSaleRefundRequestsIncludeEnum[keyof typeof GetSaleRefundRequestsIncludeEnum];


/**
 * RequestAttemptsApi - axios parameter creator
 * @export
 */
export const RequestAttemptsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} webhookId 
         * @param {Array<AppEventTypeEnum>} [eventTypes] 
         * @param {string | null} [since] 
         * @param {string | null} [until] 
         * @param {number} [limit] 
         * @param {string | null} [cursor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebhookRequestAttempts: async (webhookId: string, eventTypes?: Array<AppEventTypeEnum>, since?: string | null, until?: string | null, limit?: number, cursor?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'webhookId' is not null or undefined
            assertParamExists('getWebhookRequestAttempts', 'webhookId', webhookId)
            const localVarPath = `/webhooks/{webhookId}/requestAttempts`
                .replace(`{${"webhookId"}}`, encodeURIComponent(String(webhookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (eventTypes) {
                localVarQueryParameter['eventTypes'] = eventTypes.join(COLLECTION_FORMATS.csv);
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString() :
                    since;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = (until as any instanceof Date) ?
                    (until as any).toISOString() :
                    until;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RequestAttemptsApi - functional programming interface
 * @export
 */
export const RequestAttemptsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RequestAttemptsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} webhookId 
         * @param {Array<AppEventTypeEnum>} [eventTypes] 
         * @param {string | null} [since] 
         * @param {string | null} [until] 
         * @param {number} [limit] 
         * @param {string | null} [cursor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWebhookRequestAttempts(webhookId: string, eventTypes?: Array<AppEventTypeEnum>, since?: string | null, until?: string | null, limit?: number, cursor?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RequestAttempt>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWebhookRequestAttempts(webhookId, eventTypes, since, until, limit, cursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RequestAttemptsApi.getWebhookRequestAttempts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RequestAttemptsApi - factory interface
 * @export
 */
export const RequestAttemptsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RequestAttemptsApiFp(configuration)
    return {
        /**
         * 
         * @param {RequestAttemptsApiGetWebhookRequestAttemptsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebhookRequestAttempts(requestParameters: RequestAttemptsApiGetWebhookRequestAttemptsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<RequestAttempt>> {
            return localVarFp.getWebhookRequestAttempts(requestParameters.webhookId, requestParameters.eventTypes, requestParameters.since, requestParameters.until, requestParameters.limit, requestParameters.cursor, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getWebhookRequestAttempts operation in RequestAttemptsApi.
 * @export
 * @interface RequestAttemptsApiGetWebhookRequestAttemptsRequest
 */
export interface RequestAttemptsApiGetWebhookRequestAttemptsRequest {
    /**
     * 
     * @type {string}
     * @memberof RequestAttemptsApiGetWebhookRequestAttempts
     */
    readonly webhookId: string

    /**
     * 
     * @type {Array<AppEventTypeEnum>}
     * @memberof RequestAttemptsApiGetWebhookRequestAttempts
     */
    readonly eventTypes?: Array<AppEventTypeEnum>

    /**
     * 
     * @type {string}
     * @memberof RequestAttemptsApiGetWebhookRequestAttempts
     */
    readonly since?: string | null

    /**
     * 
     * @type {string}
     * @memberof RequestAttemptsApiGetWebhookRequestAttempts
     */
    readonly until?: string | null

    /**
     * 
     * @type {number}
     * @memberof RequestAttemptsApiGetWebhookRequestAttempts
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof RequestAttemptsApiGetWebhookRequestAttempts
     */
    readonly cursor?: string | null
}

/**
 * RequestAttemptsApi - object-oriented interface
 * @export
 * @class RequestAttemptsApi
 * @extends {BaseAPI}
 */
export class RequestAttemptsApi extends BaseAPI {
    /**
     * 
     * @param {RequestAttemptsApiGetWebhookRequestAttemptsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestAttemptsApi
     */
    public getWebhookRequestAttempts(requestParameters: RequestAttemptsApiGetWebhookRequestAttemptsRequest, options?: RawAxiosRequestConfig) {
        return RequestAttemptsApiFp(this.configuration).getWebhookRequestAttempts(requestParameters.webhookId, requestParameters.eventTypes, requestParameters.since, requestParameters.until, requestParameters.limit, requestParameters.cursor, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReservationsApi - axios parameter creator
 * @export
 */
export const ReservationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} reservationId 
         * @param {Array<GetReservationIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReservation: async (reservationId: string, include?: Array<GetReservationIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('getReservation', 'reservationId', reservationId)
            const localVarPath = `/reservations/{reservationId}`
                .replace(`{${"reservationId"}}`, encodeURIComponent(String(reservationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reservationId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetReservationTariffsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReservationTariffs: async (reservationId: string, offset?: number, limit?: number, include?: Array<GetReservationTariffsIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('getReservationTariffs', 'reservationId', reservationId)
            const localVarPath = `/reservations/{reservationId}/tariffs`
                .replace(`{${"reservationId"}}`, encodeURIComponent(String(reservationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} saleId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetSaleReservationsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSaleReservations: async (saleId: string, offset?: number, limit?: number, include?: Array<GetSaleReservationsIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('getSaleReservations', 'saleId', saleId)
            const localVarPath = `/sales/{saleId}/reservations`
                .replace(`{${"saleId"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reservationId 
         * @param {RescheduleReservationParams} rescheduleReservationParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rescheduleReservation: async (reservationId: string, rescheduleReservationParams: RescheduleReservationParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('rescheduleReservation', 'reservationId', reservationId)
            // verify required parameter 'rescheduleReservationParams' is not null or undefined
            assertParamExists('rescheduleReservation', 'rescheduleReservationParams', rescheduleReservationParams)
            const localVarPath = `/reservations/{reservationId}/reschedule`
                .replace(`{${"reservationId"}}`, encodeURIComponent(String(reservationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rescheduleReservationParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reservationId 
         * @param {SecureRescheduleReservationParams} secureRescheduleReservationParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secureRescheduleReservation: async (reservationId: string, secureRescheduleReservationParams: SecureRescheduleReservationParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('secureRescheduleReservation', 'reservationId', reservationId)
            // verify required parameter 'secureRescheduleReservationParams' is not null or undefined
            assertParamExists('secureRescheduleReservation', 'secureRescheduleReservationParams', secureRescheduleReservationParams)
            const localVarPath = `/reservations/{reservationId}/secureReschedule`
                .replace(`{${"reservationId"}}`, encodeURIComponent(String(reservationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(secureRescheduleReservationParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReservationsApi - functional programming interface
 * @export
 */
export const ReservationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReservationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} reservationId 
         * @param {Array<GetReservationIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReservation(reservationId: string, include?: Array<GetReservationIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reservation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReservation(reservationId, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReservationsApi.getReservation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} reservationId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetReservationTariffsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReservationTariffs(reservationId: string, offset?: number, limit?: number, include?: Array<GetReservationTariffsIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReservationTariff>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReservationTariffs(reservationId, offset, limit, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReservationsApi.getReservationTariffs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} saleId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetSaleReservationsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSaleReservations(saleId: string, offset?: number, limit?: number, include?: Array<GetSaleReservationsIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Reservation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSaleReservations(saleId, offset, limit, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReservationsApi.getSaleReservations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} reservationId 
         * @param {RescheduleReservationParams} rescheduleReservationParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rescheduleReservation(reservationId: string, rescheduleReservationParams: RescheduleReservationParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reservation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rescheduleReservation(reservationId, rescheduleReservationParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReservationsApi.rescheduleReservation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} reservationId 
         * @param {SecureRescheduleReservationParams} secureRescheduleReservationParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async secureRescheduleReservation(reservationId: string, secureRescheduleReservationParams: SecureRescheduleReservationParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reservation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.secureRescheduleReservation(reservationId, secureRescheduleReservationParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReservationsApi.secureRescheduleReservation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReservationsApi - factory interface
 * @export
 */
export const ReservationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReservationsApiFp(configuration)
    return {
        /**
         * 
         * @param {ReservationsApiGetReservationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReservation(requestParameters: ReservationsApiGetReservationRequest, options?: RawAxiosRequestConfig): AxiosPromise<Reservation> {
            return localVarFp.getReservation(requestParameters.reservationId, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReservationsApiGetReservationTariffsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReservationTariffs(requestParameters: ReservationsApiGetReservationTariffsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<ReservationTariff>> {
            return localVarFp.getReservationTariffs(requestParameters.reservationId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReservationsApiGetSaleReservationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSaleReservations(requestParameters: ReservationsApiGetSaleReservationsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Reservation>> {
            return localVarFp.getSaleReservations(requestParameters.saleId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReservationsApiRescheduleReservationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rescheduleReservation(requestParameters: ReservationsApiRescheduleReservationRequest, options?: RawAxiosRequestConfig): AxiosPromise<Reservation> {
            return localVarFp.rescheduleReservation(requestParameters.reservationId, requestParameters.rescheduleReservationParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReservationsApiSecureRescheduleReservationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secureRescheduleReservation(requestParameters: ReservationsApiSecureRescheduleReservationRequest, options?: RawAxiosRequestConfig): AxiosPromise<Reservation> {
            return localVarFp.secureRescheduleReservation(requestParameters.reservationId, requestParameters.secureRescheduleReservationParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getReservation operation in ReservationsApi.
 * @export
 * @interface ReservationsApiGetReservationRequest
 */
export interface ReservationsApiGetReservationRequest {
    /**
     * 
     * @type {string}
     * @memberof ReservationsApiGetReservation
     */
    readonly reservationId: string

    /**
     * 
     * @type {Array<'product' | 'product.thumbnail' | 'sale' | 'sale.customer'>}
     * @memberof ReservationsApiGetReservation
     */
    readonly include?: Array<GetReservationIncludeEnum>
}

/**
 * Request parameters for getReservationTariffs operation in ReservationsApi.
 * @export
 * @interface ReservationsApiGetReservationTariffsRequest
 */
export interface ReservationsApiGetReservationTariffsRequest {
    /**
     * 
     * @type {string}
     * @memberof ReservationsApiGetReservationTariffs
     */
    readonly reservationId: string

    /**
     * 
     * @type {number}
     * @memberof ReservationsApiGetReservationTariffs
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof ReservationsApiGetReservationTariffs
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<'tariff' | 'tariff.type' | 'tariff.group'>}
     * @memberof ReservationsApiGetReservationTariffs
     */
    readonly include?: Array<GetReservationTariffsIncludeEnum>
}

/**
 * Request parameters for getSaleReservations operation in ReservationsApi.
 * @export
 * @interface ReservationsApiGetSaleReservationsRequest
 */
export interface ReservationsApiGetSaleReservationsRequest {
    /**
     * 
     * @type {string}
     * @memberof ReservationsApiGetSaleReservations
     */
    readonly saleId: string

    /**
     * 
     * @type {number}
     * @memberof ReservationsApiGetSaleReservations
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof ReservationsApiGetSaleReservations
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<'product' | 'product.thumbnail' | 'sale' | 'sale.customer'>}
     * @memberof ReservationsApiGetSaleReservations
     */
    readonly include?: Array<GetSaleReservationsIncludeEnum>
}

/**
 * Request parameters for rescheduleReservation operation in ReservationsApi.
 * @export
 * @interface ReservationsApiRescheduleReservationRequest
 */
export interface ReservationsApiRescheduleReservationRequest {
    /**
     * 
     * @type {string}
     * @memberof ReservationsApiRescheduleReservation
     */
    readonly reservationId: string

    /**
     * 
     * @type {RescheduleReservationParams}
     * @memberof ReservationsApiRescheduleReservation
     */
    readonly rescheduleReservationParams: RescheduleReservationParams
}

/**
 * Request parameters for secureRescheduleReservation operation in ReservationsApi.
 * @export
 * @interface ReservationsApiSecureRescheduleReservationRequest
 */
export interface ReservationsApiSecureRescheduleReservationRequest {
    /**
     * 
     * @type {string}
     * @memberof ReservationsApiSecureRescheduleReservation
     */
    readonly reservationId: string

    /**
     * 
     * @type {SecureRescheduleReservationParams}
     * @memberof ReservationsApiSecureRescheduleReservation
     */
    readonly secureRescheduleReservationParams: SecureRescheduleReservationParams
}

/**
 * ReservationsApi - object-oriented interface
 * @export
 * @class ReservationsApi
 * @extends {BaseAPI}
 */
export class ReservationsApi extends BaseAPI {
    /**
     * 
     * @param {ReservationsApiGetReservationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationsApi
     */
    public getReservation(requestParameters: ReservationsApiGetReservationRequest, options?: RawAxiosRequestConfig) {
        return ReservationsApiFp(this.configuration).getReservation(requestParameters.reservationId, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReservationsApiGetReservationTariffsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationsApi
     */
    public getReservationTariffs(requestParameters: ReservationsApiGetReservationTariffsRequest, options?: RawAxiosRequestConfig) {
        return ReservationsApiFp(this.configuration).getReservationTariffs(requestParameters.reservationId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReservationsApiGetSaleReservationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationsApi
     */
    public getSaleReservations(requestParameters: ReservationsApiGetSaleReservationsRequest, options?: RawAxiosRequestConfig) {
        return ReservationsApiFp(this.configuration).getSaleReservations(requestParameters.saleId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReservationsApiRescheduleReservationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationsApi
     */
    public rescheduleReservation(requestParameters: ReservationsApiRescheduleReservationRequest, options?: RawAxiosRequestConfig) {
        return ReservationsApiFp(this.configuration).rescheduleReservation(requestParameters.reservationId, requestParameters.rescheduleReservationParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReservationsApiSecureRescheduleReservationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationsApi
     */
    public secureRescheduleReservation(requestParameters: ReservationsApiSecureRescheduleReservationRequest, options?: RawAxiosRequestConfig) {
        return ReservationsApiFp(this.configuration).secureRescheduleReservation(requestParameters.reservationId, requestParameters.secureRescheduleReservationParams, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetReservationIncludeEnum = {
    Product: 'product',
    ProductThumbnail: 'product.thumbnail',
    Sale: 'sale',
    SaleCustomer: 'sale.customer'
} as const;
export type GetReservationIncludeEnum = typeof GetReservationIncludeEnum[keyof typeof GetReservationIncludeEnum];
/**
 * @export
 */
export const GetReservationTariffsIncludeEnum = {
    Tariff: 'tariff',
    TariffType: 'tariff.type',
    TariffGroup: 'tariff.group'
} as const;
export type GetReservationTariffsIncludeEnum = typeof GetReservationTariffsIncludeEnum[keyof typeof GetReservationTariffsIncludeEnum];
/**
 * @export
 */
export const GetSaleReservationsIncludeEnum = {
    Product: 'product',
    ProductThumbnail: 'product.thumbnail',
    Sale: 'sale',
    SaleCustomer: 'sale.customer'
} as const;
export type GetSaleReservationsIncludeEnum = typeof GetSaleReservationsIncludeEnum[keyof typeof GetSaleReservationsIncludeEnum];


/**
 * ResourcesApi - axios parameter creator
 * @export
 */
export const ResourcesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} reservationId 
         * @param {Array<GetReservationRelocationSeatMapIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReservationRelocationSeatMap: async (reservationId: string, include?: Array<GetReservationRelocationSeatMapIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('getReservationRelocationSeatMap', 'reservationId', reservationId)
            const localVarPath = `/reservations/{reservationId}/relocationSeatMap`
                .replace(`{${"reservationId"}}`, encodeURIComponent(String(reservationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResourcesApi - functional programming interface
 * @export
 */
export const ResourcesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ResourcesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} reservationId 
         * @param {Array<GetReservationRelocationSeatMapIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReservationRelocationSeatMap(reservationId: string, include?: Array<GetReservationRelocationSeatMapIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SeatMapOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReservationRelocationSeatMap(reservationId, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ResourcesApi.getReservationRelocationSeatMap']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ResourcesApi - factory interface
 * @export
 */
export const ResourcesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ResourcesApiFp(configuration)
    return {
        /**
         * 
         * @param {ResourcesApiGetReservationRelocationSeatMapRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReservationRelocationSeatMap(requestParameters: ResourcesApiGetReservationRelocationSeatMapRequest, options?: RawAxiosRequestConfig): AxiosPromise<SeatMapOverview> {
            return localVarFp.getReservationRelocationSeatMap(requestParameters.reservationId, requestParameters.include, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getReservationRelocationSeatMap operation in ResourcesApi.
 * @export
 * @interface ResourcesApiGetReservationRelocationSeatMapRequest
 */
export interface ResourcesApiGetReservationRelocationSeatMapRequest {
    /**
     * 
     * @type {string}
     * @memberof ResourcesApiGetReservationRelocationSeatMap
     */
    readonly reservationId: string

    /**
     * 
     * @type {Array<'areas.slots.resource.category' | 'areas.slots.resource.category.chargeTypeTariffReference' | 'areas.slots.resource.category.chargeTypeTariffReference.type'>}
     * @memberof ResourcesApiGetReservationRelocationSeatMap
     */
    readonly include?: Array<GetReservationRelocationSeatMapIncludeEnum>
}

/**
 * ResourcesApi - object-oriented interface
 * @export
 * @class ResourcesApi
 * @extends {BaseAPI}
 */
export class ResourcesApi extends BaseAPI {
    /**
     * 
     * @param {ResourcesApiGetReservationRelocationSeatMapRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourcesApi
     */
    public getReservationRelocationSeatMap(requestParameters: ResourcesApiGetReservationRelocationSeatMapRequest, options?: RawAxiosRequestConfig) {
        return ResourcesApiFp(this.configuration).getReservationRelocationSeatMap(requestParameters.reservationId, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetReservationRelocationSeatMapIncludeEnum = {
    Category: 'areas.slots.resource.category',
    CategoryChargeTypeTariffReference: 'areas.slots.resource.category.chargeTypeTariffReference',
    CategoryChargeTypeTariffReferenceType: 'areas.slots.resource.category.chargeTypeTariffReference.type'
} as const;
export type GetReservationRelocationSeatMapIncludeEnum = typeof GetReservationRelocationSeatMapIncludeEnum[keyof typeof GetReservationRelocationSeatMapIncludeEnum];


/**
 * SaleOriginsApi - axios parameter creator
 * @export
 */
export const SaleOriginsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<string>} [ids] 
         * @param {string} [search] 
         * @param {GetAppSaleOriginsSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppSaleOrigins: async (appId: string, offset?: number, limit?: number, ids?: Array<string>, search?: string, sortBy?: GetAppSaleOriginsSortByEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppSaleOrigins', 'appId', appId)
            const localVarPath = `/apps/{appId}/saleOrigins`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids.join(COLLECTION_FORMATS.csv);
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SaleOriginsApi - functional programming interface
 * @export
 */
export const SaleOriginsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SaleOriginsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<string>} [ids] 
         * @param {string} [search] 
         * @param {GetAppSaleOriginsSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppSaleOrigins(appId: string, offset?: number, limit?: number, ids?: Array<string>, search?: string, sortBy?: GetAppSaleOriginsSortByEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SaleOrigin>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppSaleOrigins(appId, offset, limit, ids, search, sortBy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SaleOriginsApi.getAppSaleOrigins']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SaleOriginsApi - factory interface
 * @export
 */
export const SaleOriginsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SaleOriginsApiFp(configuration)
    return {
        /**
         * 
         * @param {SaleOriginsApiGetAppSaleOriginsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppSaleOrigins(requestParameters: SaleOriginsApiGetAppSaleOriginsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<SaleOrigin>> {
            return localVarFp.getAppSaleOrigins(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.ids, requestParameters.search, requestParameters.sortBy, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAppSaleOrigins operation in SaleOriginsApi.
 * @export
 * @interface SaleOriginsApiGetAppSaleOriginsRequest
 */
export interface SaleOriginsApiGetAppSaleOriginsRequest {
    /**
     * 
     * @type {string}
     * @memberof SaleOriginsApiGetAppSaleOrigins
     */
    readonly appId: string

    /**
     * 
     * @type {number}
     * @memberof SaleOriginsApiGetAppSaleOrigins
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof SaleOriginsApiGetAppSaleOrigins
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof SaleOriginsApiGetAppSaleOrigins
     */
    readonly ids?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof SaleOriginsApiGetAppSaleOrigins
     */
    readonly search?: string

    /**
     * 
     * @type {'recent'}
     * @memberof SaleOriginsApiGetAppSaleOrigins
     */
    readonly sortBy?: GetAppSaleOriginsSortByEnum
}

/**
 * SaleOriginsApi - object-oriented interface
 * @export
 * @class SaleOriginsApi
 * @extends {BaseAPI}
 */
export class SaleOriginsApi extends BaseAPI {
    /**
     * 
     * @param {SaleOriginsApiGetAppSaleOriginsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleOriginsApi
     */
    public getAppSaleOrigins(requestParameters: SaleOriginsApiGetAppSaleOriginsRequest, options?: RawAxiosRequestConfig) {
        return SaleOriginsApiFp(this.configuration).getAppSaleOrigins(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.ids, requestParameters.search, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAppSaleOriginsSortByEnum = {
    Recent: 'recent'
} as const;
export type GetAppSaleOriginsSortByEnum = typeof GetAppSaleOriginsSortByEnum[keyof typeof GetAppSaleOriginsSortByEnum];


/**
 * SaleStateChangesApi - axios parameter creator
 * @export
 */
export const SaleStateChangesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string | null} [since] 
         * @param {string | null} [until] 
         * @param {Array<GetAppSaleStateChangesIncludeEnum>} [include] 
         * @param {GetAppSaleStateChangesSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppSaleStateChanges: async (appId: string, offset?: number, limit?: number, since?: string | null, until?: string | null, include?: Array<GetAppSaleStateChangesIncludeEnum>, sortBy?: GetAppSaleStateChangesSortByEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppSaleStateChanges', 'appId', appId)
            const localVarPath = `/apps/{appId}/saleStateChanges`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString() :
                    since;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = (until as any instanceof Date) ?
                    (until as any).toISOString() :
                    until;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SaleStateChangesApi - functional programming interface
 * @export
 */
export const SaleStateChangesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SaleStateChangesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string | null} [since] 
         * @param {string | null} [until] 
         * @param {Array<GetAppSaleStateChangesIncludeEnum>} [include] 
         * @param {GetAppSaleStateChangesSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppSaleStateChanges(appId: string, offset?: number, limit?: number, since?: string | null, until?: string | null, include?: Array<GetAppSaleStateChangesIncludeEnum>, sortBy?: GetAppSaleStateChangesSortByEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SaleStateChange>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppSaleStateChanges(appId, offset, limit, since, until, include, sortBy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SaleStateChangesApi.getAppSaleStateChanges']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SaleStateChangesApi - factory interface
 * @export
 */
export const SaleStateChangesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SaleStateChangesApiFp(configuration)
    return {
        /**
         * 
         * @param {SaleStateChangesApiGetAppSaleStateChangesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppSaleStateChanges(requestParameters: SaleStateChangesApiGetAppSaleStateChangesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<SaleStateChange>> {
            return localVarFp.getAppSaleStateChanges(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.since, requestParameters.until, requestParameters.include, requestParameters.sortBy, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAppSaleStateChanges operation in SaleStateChangesApi.
 * @export
 * @interface SaleStateChangesApiGetAppSaleStateChangesRequest
 */
export interface SaleStateChangesApiGetAppSaleStateChangesRequest {
    /**
     * 
     * @type {string}
     * @memberof SaleStateChangesApiGetAppSaleStateChanges
     */
    readonly appId: string

    /**
     * 
     * @type {number}
     * @memberof SaleStateChangesApiGetAppSaleStateChanges
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof SaleStateChangesApiGetAppSaleStateChanges
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof SaleStateChangesApiGetAppSaleStateChanges
     */
    readonly since?: string | null

    /**
     * 
     * @type {string}
     * @memberof SaleStateChangesApiGetAppSaleStateChanges
     */
    readonly until?: string | null

    /**
     * 
     * @type {Array<'sale' | 'sale.customer'>}
     * @memberof SaleStateChangesApiGetAppSaleStateChanges
     */
    readonly include?: Array<GetAppSaleStateChangesIncludeEnum>

    /**
     * 
     * @type {'recent' | 'oldest'}
     * @memberof SaleStateChangesApiGetAppSaleStateChanges
     */
    readonly sortBy?: GetAppSaleStateChangesSortByEnum
}

/**
 * SaleStateChangesApi - object-oriented interface
 * @export
 * @class SaleStateChangesApi
 * @extends {BaseAPI}
 */
export class SaleStateChangesApi extends BaseAPI {
    /**
     * 
     * @param {SaleStateChangesApiGetAppSaleStateChangesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleStateChangesApi
     */
    public getAppSaleStateChanges(requestParameters: SaleStateChangesApiGetAppSaleStateChangesRequest, options?: RawAxiosRequestConfig) {
        return SaleStateChangesApiFp(this.configuration).getAppSaleStateChanges(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.since, requestParameters.until, requestParameters.include, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAppSaleStateChangesIncludeEnum = {
    Sale: 'sale',
    SaleCustomer: 'sale.customer'
} as const;
export type GetAppSaleStateChangesIncludeEnum = typeof GetAppSaleStateChangesIncludeEnum[keyof typeof GetAppSaleStateChangesIncludeEnum];
/**
 * @export
 */
export const GetAppSaleStateChangesSortByEnum = {
    Recent: 'recent',
    Oldest: 'oldest'
} as const;
export type GetAppSaleStateChangesSortByEnum = typeof GetAppSaleStateChangesSortByEnum[keyof typeof GetAppSaleStateChangesSortByEnum];


/**
 * SalesApi - axios parameter creator
 * @export
 */
export const SalesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} saleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelExternalSale: async (saleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('cancelExternalSale', 'saleId', saleId)
            const localVarPath = `/externalSales/{saleId}`
                .replace(`{${"saleId"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {DirectSaleParams} directSaleParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppDirectSale: async (appId: string, directSaleParams: DirectSaleParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('createAppDirectSale', 'appId', appId)
            // verify required parameter 'directSaleParams' is not null or undefined
            assertParamExists('createAppDirectSale', 'directSaleParams', directSaleParams)
            const localVarPath = `/apps/{appId}/directSales`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(directSaleParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {ExternalSaleParams} externalSaleParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppExternalSale: async (appId: string, externalSaleParams: ExternalSaleParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('createAppExternalSale', 'appId', appId)
            // verify required parameter 'externalSaleParams' is not null or undefined
            assertParamExists('createAppExternalSale', 'externalSaleParams', externalSaleParams)
            const localVarPath = `/apps/{appId}/externalSales`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(externalSaleParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} saleId 
         * @param {CreateSaleTagParams} createSaleTagParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSaleTag: async (saleId: string, createSaleTagParams: CreateSaleTagParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('createSaleTag', 'saleId', saleId)
            // verify required parameter 'createSaleTagParams' is not null or undefined
            assertParamExists('createSaleTag', 'createSaleTagParams', createSaleTagParams)
            const localVarPath = `/sales/{saleId}/tags`
                .replace(`{${"saleId"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSaleTagParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} saleTagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSaleTag: async (saleTagId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleTagId' is not null or undefined
            assertParamExists('deleteSaleTag', 'saleTagId', saleTagId)
            const localVarPath = `/saleTags/{saleTagId}`
                .replace(`{${"saleTagId"}}`, encodeURIComponent(String(saleTagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<SaleStateEnum>} [currentState] 
         * @param {string | null} [since] 
         * @param {string | null} [until] 
         * @param {Array<RefundRequestStatus>} [refundRequestStatus] 
         * @param {Array<string>} [tagIds] 
         * @param {Array<GetAppSalesIncludeEnum>} [include] 
         * @param {string} [search] 
         * @param {Array<string>} [couponIds] 
         * @param {Array<string>} [saleOriginIds] 
         * @param {Array<GetAppSalesSourcesEnum>} [sources] 
         * @param {Array<string>} [attributes] 
         * @param {GetAppSalesSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppSales: async (appId: string, offset?: number, limit?: number, currentState?: Array<SaleStateEnum>, since?: string | null, until?: string | null, refundRequestStatus?: Array<RefundRequestStatus>, tagIds?: Array<string>, include?: Array<GetAppSalesIncludeEnum>, search?: string, couponIds?: Array<string>, saleOriginIds?: Array<string>, sources?: Array<GetAppSalesSourcesEnum>, attributes?: Array<string>, sortBy?: GetAppSalesSortByEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppSales', 'appId', appId)
            const localVarPath = `/apps/{appId}/sales`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (currentState) {
                localVarQueryParameter['currentState'] = currentState.join(COLLECTION_FORMATS.csv);
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString() :
                    since;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = (until as any instanceof Date) ?
                    (until as any).toISOString() :
                    until;
            }

            if (refundRequestStatus) {
                localVarQueryParameter['refundRequestStatus'] = refundRequestStatus;
            }

            if (tagIds) {
                localVarQueryParameter['tagIds'] = tagIds.join(COLLECTION_FORMATS.csv);
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (couponIds) {
                localVarQueryParameter['couponIds'] = couponIds.join(COLLECTION_FORMATS.csv);
            }

            if (saleOriginIds) {
                localVarQueryParameter['saleOriginIds'] = saleOriginIds.join(COLLECTION_FORMATS.csv);
            }

            if (sources) {
                localVarQueryParameter['sources'] = sources.join(COLLECTION_FORMATS.csv);
            }

            if (attributes) {
                localVarQueryParameter['attributes'] = attributes.join(COLLECTION_FORMATS.csv);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<SaleStateEnum>} [currentState] 
         * @param {string | null} [since] 
         * @param {string | null} [until] 
         * @param {Array<GetCustomerSalesIncludeEnum>} [include] 
         * @param {GetCustomerSalesSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerSales: async (customerId: string, offset?: number, limit?: number, currentState?: Array<SaleStateEnum>, since?: string | null, until?: string | null, include?: Array<GetCustomerSalesIncludeEnum>, sortBy?: GetCustomerSalesSortByEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getCustomerSales', 'customerId', customerId)
            const localVarPath = `/customers/{customerId}/sales`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (currentState) {
                localVarQueryParameter['currentState'] = currentState.join(COLLECTION_FORMATS.csv);
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString() :
                    since;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = (until as any instanceof Date) ?
                    (until as any).toISOString() :
                    until;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} saleId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetDetailedSaleItemsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedSaleItems: async (saleId: string, offset?: number, limit?: number, include?: Array<GetDetailedSaleItemsIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('getDetailedSaleItems', 'saleId', saleId)
            const localVarPath = `/sales/{saleId}/detailedItems`
                .replace(`{${"saleId"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {Array<GetOrderOwnSaleIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderOwnSale: async (orderId: string, include?: Array<GetOrderOwnSaleIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('getOrderOwnSale', 'orderId', orderId)
            const localVarPath = `/orders/{orderId}/ownSale`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} saleId 
         * @param {Array<GetSaleIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSale: async (saleId: string, include?: Array<GetSaleIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('getSale', 'saleId', saleId)
            const localVarPath = `/sales/{saleId}`
                .replace(`{${"saleId"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} saleId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetSaleItemsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSaleItems: async (saleId: string, offset?: number, limit?: number, include?: Array<GetSaleItemsIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('getSaleItems', 'saleId', saleId)
            const localVarPath = `/sales/{saleId}/items`
                .replace(`{${"saleId"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} saleId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSaleTags: async (saleId: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('getSaleTags', 'saleId', saleId)
            const localVarPath = `/sales/{saleId}/tags`
                .replace(`{${"saleId"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} saleId 
         * @param {TransactionRequest} transactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactDirectSale: async (saleId: string, transactionRequest: TransactionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('transactDirectSale', 'saleId', saleId)
            // verify required parameter 'transactionRequest' is not null or undefined
            assertParamExists('transactDirectSale', 'transactionRequest', transactionRequest)
            const localVarPath = `/directSales/{saleId}/transact`
                .replace(`{${"saleId"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(transactionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SalesApi - functional programming interface
 * @export
 */
export const SalesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SalesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} saleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelExternalSale(saleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sale>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelExternalSale(saleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesApi.cancelExternalSale']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {DirectSaleParams} directSaleParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAppDirectSale(appId: string, directSaleParams: DirectSaleParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectSale>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAppDirectSale(appId, directSaleParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesApi.createAppDirectSale']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {ExternalSaleParams} externalSaleParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAppExternalSale(appId: string, externalSaleParams: ExternalSaleParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sale>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAppExternalSale(appId, externalSaleParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesApi.createAppExternalSale']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} saleId 
         * @param {CreateSaleTagParams} createSaleTagParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSaleTag(saleId: string, createSaleTagParams: CreateSaleTagParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaleTag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSaleTag(saleId, createSaleTagParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesApi.createSaleTag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} saleTagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSaleTag(saleTagId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSaleTag(saleTagId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesApi.deleteSaleTag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<SaleStateEnum>} [currentState] 
         * @param {string | null} [since] 
         * @param {string | null} [until] 
         * @param {Array<RefundRequestStatus>} [refundRequestStatus] 
         * @param {Array<string>} [tagIds] 
         * @param {Array<GetAppSalesIncludeEnum>} [include] 
         * @param {string} [search] 
         * @param {Array<string>} [couponIds] 
         * @param {Array<string>} [saleOriginIds] 
         * @param {Array<GetAppSalesSourcesEnum>} [sources] 
         * @param {Array<string>} [attributes] 
         * @param {GetAppSalesSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppSales(appId: string, offset?: number, limit?: number, currentState?: Array<SaleStateEnum>, since?: string | null, until?: string | null, refundRequestStatus?: Array<RefundRequestStatus>, tagIds?: Array<string>, include?: Array<GetAppSalesIncludeEnum>, search?: string, couponIds?: Array<string>, saleOriginIds?: Array<string>, sources?: Array<GetAppSalesSourcesEnum>, attributes?: Array<string>, sortBy?: GetAppSalesSortByEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Sale>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppSales(appId, offset, limit, currentState, since, until, refundRequestStatus, tagIds, include, search, couponIds, saleOriginIds, sources, attributes, sortBy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesApi.getAppSales']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} customerId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<SaleStateEnum>} [currentState] 
         * @param {string | null} [since] 
         * @param {string | null} [until] 
         * @param {Array<GetCustomerSalesIncludeEnum>} [include] 
         * @param {GetCustomerSalesSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerSales(customerId: string, offset?: number, limit?: number, currentState?: Array<SaleStateEnum>, since?: string | null, until?: string | null, include?: Array<GetCustomerSalesIncludeEnum>, sortBy?: GetCustomerSalesSortByEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Sale>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerSales(customerId, offset, limit, currentState, since, until, include, sortBy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesApi.getCustomerSales']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} saleId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetDetailedSaleItemsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetailedSaleItems(saleId: string, offset?: number, limit?: number, include?: Array<GetDetailedSaleItemsIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DetailedSaleItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetailedSaleItems(saleId, offset, limit, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesApi.getDetailedSaleItems']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {Array<GetOrderOwnSaleIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderOwnSale(orderId: string, include?: Array<GetOrderOwnSaleIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sale>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderOwnSale(orderId, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesApi.getOrderOwnSale']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} saleId 
         * @param {Array<GetSaleIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSale(saleId: string, include?: Array<GetSaleIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sale>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSale(saleId, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesApi.getSale']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} saleId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetSaleItemsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSaleItems(saleId: string, offset?: number, limit?: number, include?: Array<GetSaleItemsIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SaleItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSaleItems(saleId, offset, limit, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesApi.getSaleItems']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} saleId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSaleTags(saleId: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SaleTag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSaleTags(saleId, offset, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesApi.getSaleTags']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} saleId 
         * @param {TransactionRequest} transactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactDirectSale(saleId: string, transactionRequest: TransactionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectSaleTransaction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactDirectSale(saleId, transactionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalesApi.transactDirectSale']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SalesApi - factory interface
 * @export
 */
export const SalesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SalesApiFp(configuration)
    return {
        /**
         * 
         * @param {SalesApiCancelExternalSaleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelExternalSale(requestParameters: SalesApiCancelExternalSaleRequest, options?: RawAxiosRequestConfig): AxiosPromise<Sale> {
            return localVarFp.cancelExternalSale(requestParameters.saleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SalesApiCreateAppDirectSaleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppDirectSale(requestParameters: SalesApiCreateAppDirectSaleRequest, options?: RawAxiosRequestConfig): AxiosPromise<DirectSale> {
            return localVarFp.createAppDirectSale(requestParameters.appId, requestParameters.directSaleParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SalesApiCreateAppExternalSaleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppExternalSale(requestParameters: SalesApiCreateAppExternalSaleRequest, options?: RawAxiosRequestConfig): AxiosPromise<Sale> {
            return localVarFp.createAppExternalSale(requestParameters.appId, requestParameters.externalSaleParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SalesApiCreateSaleTagRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSaleTag(requestParameters: SalesApiCreateSaleTagRequest, options?: RawAxiosRequestConfig): AxiosPromise<SaleTag> {
            return localVarFp.createSaleTag(requestParameters.saleId, requestParameters.createSaleTagParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SalesApiDeleteSaleTagRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSaleTag(requestParameters: SalesApiDeleteSaleTagRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteSaleTag(requestParameters.saleTagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SalesApiGetAppSalesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppSales(requestParameters: SalesApiGetAppSalesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Sale>> {
            return localVarFp.getAppSales(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.currentState, requestParameters.since, requestParameters.until, requestParameters.refundRequestStatus, requestParameters.tagIds, requestParameters.include, requestParameters.search, requestParameters.couponIds, requestParameters.saleOriginIds, requestParameters.sources, requestParameters.attributes, requestParameters.sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SalesApiGetCustomerSalesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerSales(requestParameters: SalesApiGetCustomerSalesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Sale>> {
            return localVarFp.getCustomerSales(requestParameters.customerId, requestParameters.offset, requestParameters.limit, requestParameters.currentState, requestParameters.since, requestParameters.until, requestParameters.include, requestParameters.sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SalesApiGetDetailedSaleItemsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedSaleItems(requestParameters: SalesApiGetDetailedSaleItemsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<DetailedSaleItem>> {
            return localVarFp.getDetailedSaleItems(requestParameters.saleId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SalesApiGetOrderOwnSaleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderOwnSale(requestParameters: SalesApiGetOrderOwnSaleRequest, options?: RawAxiosRequestConfig): AxiosPromise<Sale> {
            return localVarFp.getOrderOwnSale(requestParameters.orderId, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SalesApiGetSaleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSale(requestParameters: SalesApiGetSaleRequest, options?: RawAxiosRequestConfig): AxiosPromise<Sale> {
            return localVarFp.getSale(requestParameters.saleId, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SalesApiGetSaleItemsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSaleItems(requestParameters: SalesApiGetSaleItemsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<SaleItem>> {
            return localVarFp.getSaleItems(requestParameters.saleId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SalesApiGetSaleTagsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSaleTags(requestParameters: SalesApiGetSaleTagsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<SaleTag>> {
            return localVarFp.getSaleTags(requestParameters.saleId, requestParameters.offset, requestParameters.limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SalesApiTransactDirectSaleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactDirectSale(requestParameters: SalesApiTransactDirectSaleRequest, options?: RawAxiosRequestConfig): AxiosPromise<DirectSaleTransaction> {
            return localVarFp.transactDirectSale(requestParameters.saleId, requestParameters.transactionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for cancelExternalSale operation in SalesApi.
 * @export
 * @interface SalesApiCancelExternalSaleRequest
 */
export interface SalesApiCancelExternalSaleRequest {
    /**
     * 
     * @type {string}
     * @memberof SalesApiCancelExternalSale
     */
    readonly saleId: string
}

/**
 * Request parameters for createAppDirectSale operation in SalesApi.
 * @export
 * @interface SalesApiCreateAppDirectSaleRequest
 */
export interface SalesApiCreateAppDirectSaleRequest {
    /**
     * 
     * @type {string}
     * @memberof SalesApiCreateAppDirectSale
     */
    readonly appId: string

    /**
     * 
     * @type {DirectSaleParams}
     * @memberof SalesApiCreateAppDirectSale
     */
    readonly directSaleParams: DirectSaleParams
}

/**
 * Request parameters for createAppExternalSale operation in SalesApi.
 * @export
 * @interface SalesApiCreateAppExternalSaleRequest
 */
export interface SalesApiCreateAppExternalSaleRequest {
    /**
     * 
     * @type {string}
     * @memberof SalesApiCreateAppExternalSale
     */
    readonly appId: string

    /**
     * 
     * @type {ExternalSaleParams}
     * @memberof SalesApiCreateAppExternalSale
     */
    readonly externalSaleParams: ExternalSaleParams
}

/**
 * Request parameters for createSaleTag operation in SalesApi.
 * @export
 * @interface SalesApiCreateSaleTagRequest
 */
export interface SalesApiCreateSaleTagRequest {
    /**
     * 
     * @type {string}
     * @memberof SalesApiCreateSaleTag
     */
    readonly saleId: string

    /**
     * 
     * @type {CreateSaleTagParams}
     * @memberof SalesApiCreateSaleTag
     */
    readonly createSaleTagParams: CreateSaleTagParams
}

/**
 * Request parameters for deleteSaleTag operation in SalesApi.
 * @export
 * @interface SalesApiDeleteSaleTagRequest
 */
export interface SalesApiDeleteSaleTagRequest {
    /**
     * 
     * @type {string}
     * @memberof SalesApiDeleteSaleTag
     */
    readonly saleTagId: string
}

/**
 * Request parameters for getAppSales operation in SalesApi.
 * @export
 * @interface SalesApiGetAppSalesRequest
 */
export interface SalesApiGetAppSalesRequest {
    /**
     * 
     * @type {string}
     * @memberof SalesApiGetAppSales
     */
    readonly appId: string

    /**
     * 
     * @type {number}
     * @memberof SalesApiGetAppSales
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof SalesApiGetAppSales
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<SaleStateEnum>}
     * @memberof SalesApiGetAppSales
     */
    readonly currentState?: Array<SaleStateEnum>

    /**
     * 
     * @type {string}
     * @memberof SalesApiGetAppSales
     */
    readonly since?: string | null

    /**
     * 
     * @type {string}
     * @memberof SalesApiGetAppSales
     */
    readonly until?: string | null

    /**
     * 
     * @type {Array<RefundRequestStatus>}
     * @memberof SalesApiGetAppSales
     */
    readonly refundRequestStatus?: Array<RefundRequestStatus>

    /**
     * 
     * @type {Array<string>}
     * @memberof SalesApiGetAppSales
     */
    readonly tagIds?: Array<string>

    /**
     * 
     * @type {Array<'coupon' | 'coupon.products' | 'coupon.products.product' | 'customer' | 'origin' | 'order' | 'order.decisiveTransaction' | 'order.decisiveTransaction.paymentMethod' | 'aggregatedVouchers' | 'tags' | 'aggregatedRefundRequests' | 'decisiveTransaction' | 'decisiveTransaction.paymentMethod'>}
     * @memberof SalesApiGetAppSales
     */
    readonly include?: Array<GetAppSalesIncludeEnum>

    /**
     * 
     * @type {string}
     * @memberof SalesApiGetAppSales
     */
    readonly search?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof SalesApiGetAppSales
     */
    readonly couponIds?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof SalesApiGetAppSales
     */
    readonly saleOriginIds?: Array<string>

    /**
     * 
     * @type {Array<'site' | 'direct_link' | 'direct_sale' | 'external' | 'resale'>}
     * @memberof SalesApiGetAppSales
     */
    readonly sources?: Array<GetAppSalesSourcesEnum>

    /**
     * 
     * @type {Array<string>}
     * @memberof SalesApiGetAppSales
     */
    readonly attributes?: Array<string>

    /**
     * 
     * @type {'recent' | 'latestUpdated'}
     * @memberof SalesApiGetAppSales
     */
    readonly sortBy?: GetAppSalesSortByEnum
}

/**
 * Request parameters for getCustomerSales operation in SalesApi.
 * @export
 * @interface SalesApiGetCustomerSalesRequest
 */
export interface SalesApiGetCustomerSalesRequest {
    /**
     * 
     * @type {string}
     * @memberof SalesApiGetCustomerSales
     */
    readonly customerId: string

    /**
     * 
     * @type {number}
     * @memberof SalesApiGetCustomerSales
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof SalesApiGetCustomerSales
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<SaleStateEnum>}
     * @memberof SalesApiGetCustomerSales
     */
    readonly currentState?: Array<SaleStateEnum>

    /**
     * 
     * @type {string}
     * @memberof SalesApiGetCustomerSales
     */
    readonly since?: string | null

    /**
     * 
     * @type {string}
     * @memberof SalesApiGetCustomerSales
     */
    readonly until?: string | null

    /**
     * 
     * @type {Array<'coupon' | 'coupon.products' | 'coupon.products.product' | 'customer' | 'origin' | 'order' | 'order.decisiveTransaction' | 'order.decisiveTransaction.paymentMethod' | 'aggregatedVouchers' | 'tags' | 'aggregatedRefundRequests' | 'decisiveTransaction' | 'decisiveTransaction.paymentMethod'>}
     * @memberof SalesApiGetCustomerSales
     */
    readonly include?: Array<GetCustomerSalesIncludeEnum>

    /**
     * 
     * @type {'recent'}
     * @memberof SalesApiGetCustomerSales
     */
    readonly sortBy?: GetCustomerSalesSortByEnum
}

/**
 * Request parameters for getDetailedSaleItems operation in SalesApi.
 * @export
 * @interface SalesApiGetDetailedSaleItemsRequest
 */
export interface SalesApiGetDetailedSaleItemsRequest {
    /**
     * 
     * @type {string}
     * @memberof SalesApiGetDetailedSaleItems
     */
    readonly saleId: string

    /**
     * 
     * @type {number}
     * @memberof SalesApiGetDetailedSaleItems
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof SalesApiGetDetailedSaleItems
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<'product' | 'product.thumbnail' | 'sale' | 'sale.customer' | 'sale.decisiveTransaction' | 'reservation' | 'tariffs.tariff' | 'tariffs.tariff.type' | 'resources.resource' | 'additionals.additional' | 'partnershipItem' | 'partnershipItem.partnership' | 'partnershipAgent' | 'partnershipProvider' | 'couponProduct'>}
     * @memberof SalesApiGetDetailedSaleItems
     */
    readonly include?: Array<GetDetailedSaleItemsIncludeEnum>
}

/**
 * Request parameters for getOrderOwnSale operation in SalesApi.
 * @export
 * @interface SalesApiGetOrderOwnSaleRequest
 */
export interface SalesApiGetOrderOwnSaleRequest {
    /**
     * 
     * @type {string}
     * @memberof SalesApiGetOrderOwnSale
     */
    readonly orderId: string

    /**
     * 
     * @type {Array<'coupon' | 'coupon.products' | 'coupon.products.product' | 'customer' | 'origin' | 'order' | 'order.decisiveTransaction' | 'order.decisiveTransaction.paymentMethod' | 'aggregatedVouchers' | 'tags' | 'aggregatedRefundRequests' | 'decisiveTransaction' | 'decisiveTransaction.paymentMethod'>}
     * @memberof SalesApiGetOrderOwnSale
     */
    readonly include?: Array<GetOrderOwnSaleIncludeEnum>
}

/**
 * Request parameters for getSale operation in SalesApi.
 * @export
 * @interface SalesApiGetSaleRequest
 */
export interface SalesApiGetSaleRequest {
    /**
     * 
     * @type {string}
     * @memberof SalesApiGetSale
     */
    readonly saleId: string

    /**
     * 
     * @type {Array<'coupon' | 'coupon.products' | 'coupon.products.product' | 'customer' | 'origin' | 'order' | 'order.decisiveTransaction' | 'order.decisiveTransaction.paymentMethod' | 'aggregatedVouchers' | 'tags' | 'aggregatedRefundRequests' | 'decisiveTransaction' | 'decisiveTransaction.paymentMethod'>}
     * @memberof SalesApiGetSale
     */
    readonly include?: Array<GetSaleIncludeEnum>
}

/**
 * Request parameters for getSaleItems operation in SalesApi.
 * @export
 * @interface SalesApiGetSaleItemsRequest
 */
export interface SalesApiGetSaleItemsRequest {
    /**
     * 
     * @type {string}
     * @memberof SalesApiGetSaleItems
     */
    readonly saleId: string

    /**
     * 
     * @type {number}
     * @memberof SalesApiGetSaleItems
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof SalesApiGetSaleItems
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<'product' | 'product.thumbnail' | 'reservation'>}
     * @memberof SalesApiGetSaleItems
     */
    readonly include?: Array<GetSaleItemsIncludeEnum>
}

/**
 * Request parameters for getSaleTags operation in SalesApi.
 * @export
 * @interface SalesApiGetSaleTagsRequest
 */
export interface SalesApiGetSaleTagsRequest {
    /**
     * 
     * @type {string}
     * @memberof SalesApiGetSaleTags
     */
    readonly saleId: string

    /**
     * 
     * @type {number}
     * @memberof SalesApiGetSaleTags
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof SalesApiGetSaleTags
     */
    readonly limit?: number
}

/**
 * Request parameters for transactDirectSale operation in SalesApi.
 * @export
 * @interface SalesApiTransactDirectSaleRequest
 */
export interface SalesApiTransactDirectSaleRequest {
    /**
     * 
     * @type {string}
     * @memberof SalesApiTransactDirectSale
     */
    readonly saleId: string

    /**
     * 
     * @type {TransactionRequest}
     * @memberof SalesApiTransactDirectSale
     */
    readonly transactionRequest: TransactionRequest
}

/**
 * SalesApi - object-oriented interface
 * @export
 * @class SalesApi
 * @extends {BaseAPI}
 */
export class SalesApi extends BaseAPI {
    /**
     * 
     * @param {SalesApiCancelExternalSaleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public cancelExternalSale(requestParameters: SalesApiCancelExternalSaleRequest, options?: RawAxiosRequestConfig) {
        return SalesApiFp(this.configuration).cancelExternalSale(requestParameters.saleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SalesApiCreateAppDirectSaleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public createAppDirectSale(requestParameters: SalesApiCreateAppDirectSaleRequest, options?: RawAxiosRequestConfig) {
        return SalesApiFp(this.configuration).createAppDirectSale(requestParameters.appId, requestParameters.directSaleParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SalesApiCreateAppExternalSaleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public createAppExternalSale(requestParameters: SalesApiCreateAppExternalSaleRequest, options?: RawAxiosRequestConfig) {
        return SalesApiFp(this.configuration).createAppExternalSale(requestParameters.appId, requestParameters.externalSaleParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SalesApiCreateSaleTagRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public createSaleTag(requestParameters: SalesApiCreateSaleTagRequest, options?: RawAxiosRequestConfig) {
        return SalesApiFp(this.configuration).createSaleTag(requestParameters.saleId, requestParameters.createSaleTagParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SalesApiDeleteSaleTagRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public deleteSaleTag(requestParameters: SalesApiDeleteSaleTagRequest, options?: RawAxiosRequestConfig) {
        return SalesApiFp(this.configuration).deleteSaleTag(requestParameters.saleTagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SalesApiGetAppSalesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public getAppSales(requestParameters: SalesApiGetAppSalesRequest, options?: RawAxiosRequestConfig) {
        return SalesApiFp(this.configuration).getAppSales(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.currentState, requestParameters.since, requestParameters.until, requestParameters.refundRequestStatus, requestParameters.tagIds, requestParameters.include, requestParameters.search, requestParameters.couponIds, requestParameters.saleOriginIds, requestParameters.sources, requestParameters.attributes, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SalesApiGetCustomerSalesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public getCustomerSales(requestParameters: SalesApiGetCustomerSalesRequest, options?: RawAxiosRequestConfig) {
        return SalesApiFp(this.configuration).getCustomerSales(requestParameters.customerId, requestParameters.offset, requestParameters.limit, requestParameters.currentState, requestParameters.since, requestParameters.until, requestParameters.include, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SalesApiGetDetailedSaleItemsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public getDetailedSaleItems(requestParameters: SalesApiGetDetailedSaleItemsRequest, options?: RawAxiosRequestConfig) {
        return SalesApiFp(this.configuration).getDetailedSaleItems(requestParameters.saleId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SalesApiGetOrderOwnSaleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public getOrderOwnSale(requestParameters: SalesApiGetOrderOwnSaleRequest, options?: RawAxiosRequestConfig) {
        return SalesApiFp(this.configuration).getOrderOwnSale(requestParameters.orderId, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SalesApiGetSaleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public getSale(requestParameters: SalesApiGetSaleRequest, options?: RawAxiosRequestConfig) {
        return SalesApiFp(this.configuration).getSale(requestParameters.saleId, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SalesApiGetSaleItemsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public getSaleItems(requestParameters: SalesApiGetSaleItemsRequest, options?: RawAxiosRequestConfig) {
        return SalesApiFp(this.configuration).getSaleItems(requestParameters.saleId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SalesApiGetSaleTagsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public getSaleTags(requestParameters: SalesApiGetSaleTagsRequest, options?: RawAxiosRequestConfig) {
        return SalesApiFp(this.configuration).getSaleTags(requestParameters.saleId, requestParameters.offset, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SalesApiTransactDirectSaleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public transactDirectSale(requestParameters: SalesApiTransactDirectSaleRequest, options?: RawAxiosRequestConfig) {
        return SalesApiFp(this.configuration).transactDirectSale(requestParameters.saleId, requestParameters.transactionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAppSalesIncludeEnum = {
    Coupon: 'coupon',
    CouponProducts: 'coupon.products',
    CouponProductsProduct: 'coupon.products.product',
    Customer: 'customer',
    Origin: 'origin',
    Order: 'order',
    OrderDecisiveTransaction: 'order.decisiveTransaction',
    OrderDecisiveTransactionPaymentMethod: 'order.decisiveTransaction.paymentMethod',
    AggregatedVouchers: 'aggregatedVouchers',
    Tags: 'tags',
    AggregatedRefundRequests: 'aggregatedRefundRequests',
    DecisiveTransaction: 'decisiveTransaction',
    DecisiveTransactionPaymentMethod: 'decisiveTransaction.paymentMethod'
} as const;
export type GetAppSalesIncludeEnum = typeof GetAppSalesIncludeEnum[keyof typeof GetAppSalesIncludeEnum];
/**
 * @export
 */
export const GetAppSalesSourcesEnum = {
    Site: 'site',
    DirectLink: 'direct_link',
    DirectSale: 'direct_sale',
    External: 'external',
    Resale: 'resale'
} as const;
export type GetAppSalesSourcesEnum = typeof GetAppSalesSourcesEnum[keyof typeof GetAppSalesSourcesEnum];
/**
 * @export
 */
export const GetAppSalesSortByEnum = {
    Recent: 'recent',
    LatestUpdated: 'latestUpdated'
} as const;
export type GetAppSalesSortByEnum = typeof GetAppSalesSortByEnum[keyof typeof GetAppSalesSortByEnum];
/**
 * @export
 */
export const GetCustomerSalesIncludeEnum = {
    Coupon: 'coupon',
    CouponProducts: 'coupon.products',
    CouponProductsProduct: 'coupon.products.product',
    Customer: 'customer',
    Origin: 'origin',
    Order: 'order',
    OrderDecisiveTransaction: 'order.decisiveTransaction',
    OrderDecisiveTransactionPaymentMethod: 'order.decisiveTransaction.paymentMethod',
    AggregatedVouchers: 'aggregatedVouchers',
    Tags: 'tags',
    AggregatedRefundRequests: 'aggregatedRefundRequests',
    DecisiveTransaction: 'decisiveTransaction',
    DecisiveTransactionPaymentMethod: 'decisiveTransaction.paymentMethod'
} as const;
export type GetCustomerSalesIncludeEnum = typeof GetCustomerSalesIncludeEnum[keyof typeof GetCustomerSalesIncludeEnum];
/**
 * @export
 */
export const GetCustomerSalesSortByEnum = {
    Recent: 'recent'
} as const;
export type GetCustomerSalesSortByEnum = typeof GetCustomerSalesSortByEnum[keyof typeof GetCustomerSalesSortByEnum];
/**
 * @export
 */
export const GetDetailedSaleItemsIncludeEnum = {
    Product: 'product',
    ProductThumbnail: 'product.thumbnail',
    Sale: 'sale',
    SaleCustomer: 'sale.customer',
    SaleDecisiveTransaction: 'sale.decisiveTransaction',
    Reservation: 'reservation',
    TariffsTariff: 'tariffs.tariff',
    TariffsTariffType: 'tariffs.tariff.type',
    ResourcesResource: 'resources.resource',
    AdditionalsAdditional: 'additionals.additional',
    PartnershipItem: 'partnershipItem',
    PartnershipItemPartnership: 'partnershipItem.partnership',
    PartnershipAgent: 'partnershipAgent',
    PartnershipProvider: 'partnershipProvider',
    CouponProduct: 'couponProduct'
} as const;
export type GetDetailedSaleItemsIncludeEnum = typeof GetDetailedSaleItemsIncludeEnum[keyof typeof GetDetailedSaleItemsIncludeEnum];
/**
 * @export
 */
export const GetOrderOwnSaleIncludeEnum = {
    Coupon: 'coupon',
    CouponProducts: 'coupon.products',
    CouponProductsProduct: 'coupon.products.product',
    Customer: 'customer',
    Origin: 'origin',
    Order: 'order',
    OrderDecisiveTransaction: 'order.decisiveTransaction',
    OrderDecisiveTransactionPaymentMethod: 'order.decisiveTransaction.paymentMethod',
    AggregatedVouchers: 'aggregatedVouchers',
    Tags: 'tags',
    AggregatedRefundRequests: 'aggregatedRefundRequests',
    DecisiveTransaction: 'decisiveTransaction',
    DecisiveTransactionPaymentMethod: 'decisiveTransaction.paymentMethod'
} as const;
export type GetOrderOwnSaleIncludeEnum = typeof GetOrderOwnSaleIncludeEnum[keyof typeof GetOrderOwnSaleIncludeEnum];
/**
 * @export
 */
export const GetSaleIncludeEnum = {
    Coupon: 'coupon',
    CouponProducts: 'coupon.products',
    CouponProductsProduct: 'coupon.products.product',
    Customer: 'customer',
    Origin: 'origin',
    Order: 'order',
    OrderDecisiveTransaction: 'order.decisiveTransaction',
    OrderDecisiveTransactionPaymentMethod: 'order.decisiveTransaction.paymentMethod',
    AggregatedVouchers: 'aggregatedVouchers',
    Tags: 'tags',
    AggregatedRefundRequests: 'aggregatedRefundRequests',
    DecisiveTransaction: 'decisiveTransaction',
    DecisiveTransactionPaymentMethod: 'decisiveTransaction.paymentMethod'
} as const;
export type GetSaleIncludeEnum = typeof GetSaleIncludeEnum[keyof typeof GetSaleIncludeEnum];
/**
 * @export
 */
export const GetSaleItemsIncludeEnum = {
    Product: 'product',
    ProductThumbnail: 'product.thumbnail',
    Reservation: 'reservation'
} as const;
export type GetSaleItemsIncludeEnum = typeof GetSaleItemsIncludeEnum[keyof typeof GetSaleItemsIncludeEnum];


/**
 * SchedulingsApi - axios parameter creator
 * @export
 */
export const SchedulingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} productId 
         * @param {CreateSchedulingParams} createSchedulingParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductScheduling: async (productId: string, createSchedulingParams: CreateSchedulingParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('createProductScheduling', 'productId', productId)
            // verify required parameter 'createSchedulingParams' is not null or undefined
            assertParamExists('createProductScheduling', 'createSchedulingParams', createSchedulingParams)
            const localVarPath = `/products/{productId}/schedulings`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSchedulingParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} schedulingId 
         * @param {DeleteSchedulingParams} deleteSchedulingParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScheduling: async (schedulingId: string, deleteSchedulingParams: DeleteSchedulingParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schedulingId' is not null or undefined
            assertParamExists('deleteScheduling', 'schedulingId', schedulingId)
            // verify required parameter 'deleteSchedulingParams' is not null or undefined
            assertParamExists('deleteScheduling', 'deleteSchedulingParams', deleteSchedulingParams)
            const localVarPath = `/schedulings/{schedulingId}`
                .replace(`{${"schedulingId"}}`, encodeURIComponent(String(schedulingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteSchedulingParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {string | null} since 
         * @param {string | null} until 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<string>} [productIds] 
         * @param {Array<GetAppAggregatedSchedulingsIncludeEnum>} [include] 
         * @param {number} [previewCount] 
         * @param {GetAppAggregatedSchedulingsSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppAggregatedSchedulings: async (appId: string, since: string | null, until: string | null, offset?: number, limit?: number, productIds?: Array<string>, include?: Array<GetAppAggregatedSchedulingsIncludeEnum>, previewCount?: number, sortBy?: GetAppAggregatedSchedulingsSortByEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppAggregatedSchedulings', 'appId', appId)
            // verify required parameter 'since' is not null or undefined
            assertParamExists('getAppAggregatedSchedulings', 'since', since)
            // verify required parameter 'until' is not null or undefined
            assertParamExists('getAppAggregatedSchedulings', 'until', until)
            const localVarPath = `/apps/{appId}/aggregatedSchedulings`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString().substring(0,10) :
                    since;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = (until as any instanceof Date) ?
                    (until as any).toISOString().substring(0,10) :
                    until;
            }

            if (productIds) {
                localVarQueryParameter['productIds'] = productIds.join(COLLECTION_FORMATS.csv);
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (previewCount !== undefined) {
                localVarQueryParameter['previewCount'] = previewCount;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {string | null} since 
         * @param {string | null} until 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<string>} [productIds] 
         * @param {Array<GetAppSchedulingsIncludeEnum>} [include] 
         * @param {GetAppSchedulingsSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppSchedulings: async (appId: string, since: string | null, until: string | null, offset?: number, limit?: number, productIds?: Array<string>, include?: Array<GetAppSchedulingsIncludeEnum>, sortBy?: GetAppSchedulingsSortByEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppSchedulings', 'appId', appId)
            // verify required parameter 'since' is not null or undefined
            assertParamExists('getAppSchedulings', 'since', since)
            // verify required parameter 'until' is not null or undefined
            assertParamExists('getAppSchedulings', 'until', until)
            const localVarPath = `/apps/{appId}/schedulings`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString().substring(0,10) :
                    since;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = (until as any instanceof Date) ?
                    (until as any).toISOString().substring(0,10) :
                    until;
            }

            if (productIds) {
                localVarQueryParameter['productIds'] = productIds.join(COLLECTION_FORMATS.csv);
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppSellableProductNextScheduling: async (appId: string, productId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppSellableProductNextScheduling', 'appId', appId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getAppSellableProductNextScheduling', 'productId', productId)
            const localVarPath = `/apps/{appId}/sellableProducts/{productId}/nextScheduling`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {string} arrangedProductId 
         * @param {string} since 
         * @param {string} until 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetArrangedProductDetailedSchedulingsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArrangedProductDetailedSchedulings: async (appId: string, arrangedProductId: string, since: string, until: string, offset?: number, limit?: number, include?: Array<GetArrangedProductDetailedSchedulingsIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getArrangedProductDetailedSchedulings', 'appId', appId)
            // verify required parameter 'arrangedProductId' is not null or undefined
            assertParamExists('getArrangedProductDetailedSchedulings', 'arrangedProductId', arrangedProductId)
            // verify required parameter 'since' is not null or undefined
            assertParamExists('getArrangedProductDetailedSchedulings', 'since', since)
            // verify required parameter 'until' is not null or undefined
            assertParamExists('getArrangedProductDetailedSchedulings', 'until', until)
            const localVarPath = `/apps/{appId}/arrangedProducts/{arrangedProductId}/detailedSchedulings`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"arrangedProductId"}}`, encodeURIComponent(String(arrangedProductId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString().substring(0,10) :
                    since;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = (until as any instanceof Date) ?
                    (until as any).toISOString().substring(0,10) :
                    until;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {Array<GetProductNextSchedulingIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductNextScheduling: async (productId: string, include?: Array<GetProductNextSchedulingIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductNextScheduling', 'productId', productId)
            const localVarPath = `/products/{productId}/nextScheduling`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reservationId 
         * @param {string} rescheduleFrom 
         * @param {string} rescheduleUntil 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReservationRescheduleSchedulings: async (reservationId: string, rescheduleFrom: string, rescheduleUntil: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('getReservationRescheduleSchedulings', 'reservationId', reservationId)
            // verify required parameter 'rescheduleFrom' is not null or undefined
            assertParamExists('getReservationRescheduleSchedulings', 'rescheduleFrom', rescheduleFrom)
            // verify required parameter 'rescheduleUntil' is not null or undefined
            assertParamExists('getReservationRescheduleSchedulings', 'rescheduleUntil', rescheduleUntil)
            const localVarPath = `/reservations/{reservationId}/rescheduleSchedulings`
                .replace(`{${"reservationId"}}`, encodeURIComponent(String(reservationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (rescheduleFrom !== undefined) {
                localVarQueryParameter['rescheduleFrom'] = (rescheduleFrom as any instanceof Date) ?
                    (rescheduleFrom as any).toISOString().substring(0,10) :
                    rescheduleFrom;
            }

            if (rescheduleUntil !== undefined) {
                localVarQueryParameter['rescheduleUntil'] = (rescheduleUntil as any instanceof Date) ?
                    (rescheduleUntil as any).toISOString().substring(0,10) :
                    rescheduleUntil;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} schedulingId 
         * @param {Array<GetSchedulingIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduling: async (schedulingId: string, include?: Array<GetSchedulingIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schedulingId' is not null or undefined
            assertParamExists('getScheduling', 'schedulingId', schedulingId)
            const localVarPath = `/schedulings/{schedulingId}`
                .replace(`{${"schedulingId"}}`, encodeURIComponent(String(schedulingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} schedulingId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetSchedulingRelatedAvailabilitiesIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchedulingRelatedAvailabilities: async (schedulingId: string, offset?: number, limit?: number, include?: Array<GetSchedulingRelatedAvailabilitiesIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schedulingId' is not null or undefined
            assertParamExists('getSchedulingRelatedAvailabilities', 'schedulingId', schedulingId)
            const localVarPath = `/schedulings/{schedulingId}/relatedAvailabilities`
                .replace(`{${"schedulingId"}}`, encodeURIComponent(String(schedulingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} schedulingId 
         * @param {UpdateSchedulingParams} updateSchedulingParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScheduling: async (schedulingId: string, updateSchedulingParams: UpdateSchedulingParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schedulingId' is not null or undefined
            assertParamExists('updateScheduling', 'schedulingId', schedulingId)
            // verify required parameter 'updateSchedulingParams' is not null or undefined
            assertParamExists('updateScheduling', 'updateSchedulingParams', updateSchedulingParams)
            const localVarPath = `/schedulings/{schedulingId}`
                .replace(`{${"schedulingId"}}`, encodeURIComponent(String(schedulingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSchedulingParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SchedulingsApi - functional programming interface
 * @export
 */
export const SchedulingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SchedulingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} productId 
         * @param {CreateSchedulingParams} createSchedulingParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProductScheduling(productId: string, createSchedulingParams: CreateSchedulingParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scheduling>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProductScheduling(productId, createSchedulingParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchedulingsApi.createProductScheduling']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} schedulingId 
         * @param {DeleteSchedulingParams} deleteSchedulingParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteScheduling(schedulingId: string, deleteSchedulingParams: DeleteSchedulingParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteScheduling(schedulingId, deleteSchedulingParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchedulingsApi.deleteScheduling']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {string | null} since 
         * @param {string | null} until 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<string>} [productIds] 
         * @param {Array<GetAppAggregatedSchedulingsIncludeEnum>} [include] 
         * @param {number} [previewCount] 
         * @param {GetAppAggregatedSchedulingsSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppAggregatedSchedulings(appId: string, since: string | null, until: string | null, offset?: number, limit?: number, productIds?: Array<string>, include?: Array<GetAppAggregatedSchedulingsIncludeEnum>, previewCount?: number, sortBy?: GetAppAggregatedSchedulingsSortByEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SchedulingDatePreview>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppAggregatedSchedulings(appId, since, until, offset, limit, productIds, include, previewCount, sortBy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchedulingsApi.getAppAggregatedSchedulings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {string | null} since 
         * @param {string | null} until 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<string>} [productIds] 
         * @param {Array<GetAppSchedulingsIncludeEnum>} [include] 
         * @param {GetAppSchedulingsSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppSchedulings(appId: string, since: string | null, until: string | null, offset?: number, limit?: number, productIds?: Array<string>, include?: Array<GetAppSchedulingsIncludeEnum>, sortBy?: GetAppSchedulingsSortByEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Scheduling>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppSchedulings(appId, since, until, offset, limit, productIds, include, sortBy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchedulingsApi.getAppSchedulings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppSellableProductNextScheduling(appId: string, productId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicScheduling>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppSellableProductNextScheduling(appId, productId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchedulingsApi.getAppSellableProductNextScheduling']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {string} arrangedProductId 
         * @param {string} since 
         * @param {string} until 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetArrangedProductDetailedSchedulingsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArrangedProductDetailedSchedulings(appId: string, arrangedProductId: string, since: string, until: string, offset?: number, limit?: number, include?: Array<GetArrangedProductDetailedSchedulingsIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DetailedScheduling>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArrangedProductDetailedSchedulings(appId, arrangedProductId, since, until, offset, limit, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchedulingsApi.getArrangedProductDetailedSchedulings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} productId 
         * @param {Array<GetProductNextSchedulingIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductNextScheduling(productId: string, include?: Array<GetProductNextSchedulingIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scheduling>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductNextScheduling(productId, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchedulingsApi.getProductNextScheduling']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} reservationId 
         * @param {string} rescheduleFrom 
         * @param {string} rescheduleUntil 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReservationRescheduleSchedulings(reservationId: string, rescheduleFrom: string, rescheduleUntil: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RescheduleScheduling>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReservationRescheduleSchedulings(reservationId, rescheduleFrom, rescheduleUntil, offset, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchedulingsApi.getReservationRescheduleSchedulings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} schedulingId 
         * @param {Array<GetSchedulingIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScheduling(schedulingId: string, include?: Array<GetSchedulingIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scheduling>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScheduling(schedulingId, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchedulingsApi.getScheduling']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} schedulingId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetSchedulingRelatedAvailabilitiesIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSchedulingRelatedAvailabilities(schedulingId: string, offset?: number, limit?: number, include?: Array<GetSchedulingRelatedAvailabilitiesIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Availability>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSchedulingRelatedAvailabilities(schedulingId, offset, limit, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchedulingsApi.getSchedulingRelatedAvailabilities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} schedulingId 
         * @param {UpdateSchedulingParams} updateSchedulingParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateScheduling(schedulingId: string, updateSchedulingParams: UpdateSchedulingParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scheduling>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateScheduling(schedulingId, updateSchedulingParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchedulingsApi.updateScheduling']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SchedulingsApi - factory interface
 * @export
 */
export const SchedulingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SchedulingsApiFp(configuration)
    return {
        /**
         * 
         * @param {SchedulingsApiCreateProductSchedulingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductScheduling(requestParameters: SchedulingsApiCreateProductSchedulingRequest, options?: RawAxiosRequestConfig): AxiosPromise<Scheduling> {
            return localVarFp.createProductScheduling(requestParameters.productId, requestParameters.createSchedulingParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SchedulingsApiDeleteSchedulingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScheduling(requestParameters: SchedulingsApiDeleteSchedulingRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteScheduling(requestParameters.schedulingId, requestParameters.deleteSchedulingParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SchedulingsApiGetAppAggregatedSchedulingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppAggregatedSchedulings(requestParameters: SchedulingsApiGetAppAggregatedSchedulingsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<SchedulingDatePreview>> {
            return localVarFp.getAppAggregatedSchedulings(requestParameters.appId, requestParameters.since, requestParameters.until, requestParameters.offset, requestParameters.limit, requestParameters.productIds, requestParameters.include, requestParameters.previewCount, requestParameters.sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SchedulingsApiGetAppSchedulingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppSchedulings(requestParameters: SchedulingsApiGetAppSchedulingsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Scheduling>> {
            return localVarFp.getAppSchedulings(requestParameters.appId, requestParameters.since, requestParameters.until, requestParameters.offset, requestParameters.limit, requestParameters.productIds, requestParameters.include, requestParameters.sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SchedulingsApiGetAppSellableProductNextSchedulingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppSellableProductNextScheduling(requestParameters: SchedulingsApiGetAppSellableProductNextSchedulingRequest, options?: RawAxiosRequestConfig): AxiosPromise<PublicScheduling> {
            return localVarFp.getAppSellableProductNextScheduling(requestParameters.appId, requestParameters.productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SchedulingsApiGetArrangedProductDetailedSchedulingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArrangedProductDetailedSchedulings(requestParameters: SchedulingsApiGetArrangedProductDetailedSchedulingsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<DetailedScheduling>> {
            return localVarFp.getArrangedProductDetailedSchedulings(requestParameters.appId, requestParameters.arrangedProductId, requestParameters.since, requestParameters.until, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SchedulingsApiGetProductNextSchedulingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductNextScheduling(requestParameters: SchedulingsApiGetProductNextSchedulingRequest, options?: RawAxiosRequestConfig): AxiosPromise<Scheduling> {
            return localVarFp.getProductNextScheduling(requestParameters.productId, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SchedulingsApiGetReservationRescheduleSchedulingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReservationRescheduleSchedulings(requestParameters: SchedulingsApiGetReservationRescheduleSchedulingsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<RescheduleScheduling>> {
            return localVarFp.getReservationRescheduleSchedulings(requestParameters.reservationId, requestParameters.rescheduleFrom, requestParameters.rescheduleUntil, requestParameters.offset, requestParameters.limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SchedulingsApiGetSchedulingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduling(requestParameters: SchedulingsApiGetSchedulingRequest, options?: RawAxiosRequestConfig): AxiosPromise<Scheduling> {
            return localVarFp.getScheduling(requestParameters.schedulingId, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SchedulingsApiGetSchedulingRelatedAvailabilitiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchedulingRelatedAvailabilities(requestParameters: SchedulingsApiGetSchedulingRelatedAvailabilitiesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Availability>> {
            return localVarFp.getSchedulingRelatedAvailabilities(requestParameters.schedulingId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SchedulingsApiUpdateSchedulingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScheduling(requestParameters: SchedulingsApiUpdateSchedulingRequest, options?: RawAxiosRequestConfig): AxiosPromise<Scheduling> {
            return localVarFp.updateScheduling(requestParameters.schedulingId, requestParameters.updateSchedulingParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createProductScheduling operation in SchedulingsApi.
 * @export
 * @interface SchedulingsApiCreateProductSchedulingRequest
 */
export interface SchedulingsApiCreateProductSchedulingRequest {
    /**
     * 
     * @type {string}
     * @memberof SchedulingsApiCreateProductScheduling
     */
    readonly productId: string

    /**
     * 
     * @type {CreateSchedulingParams}
     * @memberof SchedulingsApiCreateProductScheduling
     */
    readonly createSchedulingParams: CreateSchedulingParams
}

/**
 * Request parameters for deleteScheduling operation in SchedulingsApi.
 * @export
 * @interface SchedulingsApiDeleteSchedulingRequest
 */
export interface SchedulingsApiDeleteSchedulingRequest {
    /**
     * 
     * @type {string}
     * @memberof SchedulingsApiDeleteScheduling
     */
    readonly schedulingId: string

    /**
     * 
     * @type {DeleteSchedulingParams}
     * @memberof SchedulingsApiDeleteScheduling
     */
    readonly deleteSchedulingParams: DeleteSchedulingParams
}

/**
 * Request parameters for getAppAggregatedSchedulings operation in SchedulingsApi.
 * @export
 * @interface SchedulingsApiGetAppAggregatedSchedulingsRequest
 */
export interface SchedulingsApiGetAppAggregatedSchedulingsRequest {
    /**
     * 
     * @type {string}
     * @memberof SchedulingsApiGetAppAggregatedSchedulings
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof SchedulingsApiGetAppAggregatedSchedulings
     */
    readonly since: string | null

    /**
     * 
     * @type {string}
     * @memberof SchedulingsApiGetAppAggregatedSchedulings
     */
    readonly until: string | null

    /**
     * 
     * @type {number}
     * @memberof SchedulingsApiGetAppAggregatedSchedulings
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof SchedulingsApiGetAppAggregatedSchedulings
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof SchedulingsApiGetAppAggregatedSchedulings
     */
    readonly productIds?: Array<string>

    /**
     * 
     * @type {Array<'schedulings.tariffGroup' | 'schedulings.tariffGroup.priceInfo' | 'schedulings.tariffGroup.seatMap' | 'schedulings.finalTariffGroup' | 'schedulings.finalTariffGroup.priceInfo' | 'schedulings.finalTariffGroup.seatMap' | 'schedulings.product' | 'schedulings.product.defaultTariffGroupPriceInfo' | 'schedulings.aggregatedRecurrenceCount'>}
     * @memberof SchedulingsApiGetAppAggregatedSchedulings
     */
    readonly include?: Array<GetAppAggregatedSchedulingsIncludeEnum>

    /**
     * 
     * @type {number}
     * @memberof SchedulingsApiGetAppAggregatedSchedulings
     */
    readonly previewCount?: number

    /**
     * 
     * @type {'recent' | 'startTimeAndName'}
     * @memberof SchedulingsApiGetAppAggregatedSchedulings
     */
    readonly sortBy?: GetAppAggregatedSchedulingsSortByEnum
}

/**
 * Request parameters for getAppSchedulings operation in SchedulingsApi.
 * @export
 * @interface SchedulingsApiGetAppSchedulingsRequest
 */
export interface SchedulingsApiGetAppSchedulingsRequest {
    /**
     * 
     * @type {string}
     * @memberof SchedulingsApiGetAppSchedulings
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof SchedulingsApiGetAppSchedulings
     */
    readonly since: string | null

    /**
     * 
     * @type {string}
     * @memberof SchedulingsApiGetAppSchedulings
     */
    readonly until: string | null

    /**
     * 
     * @type {number}
     * @memberof SchedulingsApiGetAppSchedulings
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof SchedulingsApiGetAppSchedulings
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof SchedulingsApiGetAppSchedulings
     */
    readonly productIds?: Array<string>

    /**
     * 
     * @type {Array<'tariffGroup' | 'tariffGroup.priceInfo' | 'finalTariffGroup' | 'finalTariffGroup.priceInfo' | 'product' | 'product.defaultTariffGroupPriceInfo' | 'aggregatedRecurrenceCount'>}
     * @memberof SchedulingsApiGetAppSchedulings
     */
    readonly include?: Array<GetAppSchedulingsIncludeEnum>

    /**
     * 
     * @type {'recent' | 'startTimeAndName'}
     * @memberof SchedulingsApiGetAppSchedulings
     */
    readonly sortBy?: GetAppSchedulingsSortByEnum
}

/**
 * Request parameters for getAppSellableProductNextScheduling operation in SchedulingsApi.
 * @export
 * @interface SchedulingsApiGetAppSellableProductNextSchedulingRequest
 */
export interface SchedulingsApiGetAppSellableProductNextSchedulingRequest {
    /**
     * 
     * @type {string}
     * @memberof SchedulingsApiGetAppSellableProductNextScheduling
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof SchedulingsApiGetAppSellableProductNextScheduling
     */
    readonly productId: string
}

/**
 * Request parameters for getArrangedProductDetailedSchedulings operation in SchedulingsApi.
 * @export
 * @interface SchedulingsApiGetArrangedProductDetailedSchedulingsRequest
 */
export interface SchedulingsApiGetArrangedProductDetailedSchedulingsRequest {
    /**
     * 
     * @type {string}
     * @memberof SchedulingsApiGetArrangedProductDetailedSchedulings
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof SchedulingsApiGetArrangedProductDetailedSchedulings
     */
    readonly arrangedProductId: string

    /**
     * 
     * @type {string}
     * @memberof SchedulingsApiGetArrangedProductDetailedSchedulings
     */
    readonly since: string

    /**
     * 
     * @type {string}
     * @memberof SchedulingsApiGetArrangedProductDetailedSchedulings
     */
    readonly until: string

    /**
     * 
     * @type {number}
     * @memberof SchedulingsApiGetArrangedProductDetailedSchedulings
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof SchedulingsApiGetArrangedProductDetailedSchedulings
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<'finalTariffGroup.tariffs.type'>}
     * @memberof SchedulingsApiGetArrangedProductDetailedSchedulings
     */
    readonly include?: Array<GetArrangedProductDetailedSchedulingsIncludeEnum>
}

/**
 * Request parameters for getProductNextScheduling operation in SchedulingsApi.
 * @export
 * @interface SchedulingsApiGetProductNextSchedulingRequest
 */
export interface SchedulingsApiGetProductNextSchedulingRequest {
    /**
     * 
     * @type {string}
     * @memberof SchedulingsApiGetProductNextScheduling
     */
    readonly productId: string

    /**
     * 
     * @type {Array<'tariffGroup' | 'tariffGroup.priceInfo' | 'finalTariffGroup' | 'finalTariffGroup.priceInfo' | 'product' | 'product.defaultTariffGroupPriceInfo' | 'aggregatedRecurrenceCount'>}
     * @memberof SchedulingsApiGetProductNextScheduling
     */
    readonly include?: Array<GetProductNextSchedulingIncludeEnum>
}

/**
 * Request parameters for getReservationRescheduleSchedulings operation in SchedulingsApi.
 * @export
 * @interface SchedulingsApiGetReservationRescheduleSchedulingsRequest
 */
export interface SchedulingsApiGetReservationRescheduleSchedulingsRequest {
    /**
     * 
     * @type {string}
     * @memberof SchedulingsApiGetReservationRescheduleSchedulings
     */
    readonly reservationId: string

    /**
     * 
     * @type {string}
     * @memberof SchedulingsApiGetReservationRescheduleSchedulings
     */
    readonly rescheduleFrom: string

    /**
     * 
     * @type {string}
     * @memberof SchedulingsApiGetReservationRescheduleSchedulings
     */
    readonly rescheduleUntil: string

    /**
     * 
     * @type {number}
     * @memberof SchedulingsApiGetReservationRescheduleSchedulings
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof SchedulingsApiGetReservationRescheduleSchedulings
     */
    readonly limit?: number
}

/**
 * Request parameters for getScheduling operation in SchedulingsApi.
 * @export
 * @interface SchedulingsApiGetSchedulingRequest
 */
export interface SchedulingsApiGetSchedulingRequest {
    /**
     * 
     * @type {string}
     * @memberof SchedulingsApiGetScheduling
     */
    readonly schedulingId: string

    /**
     * 
     * @type {Array<'tariffGroup' | 'tariffGroup.priceInfo' | 'finalTariffGroup' | 'finalTariffGroup.priceInfo' | 'product' | 'product.defaultTariffGroupPriceInfo' | 'aggregatedRecurrenceCount'>}
     * @memberof SchedulingsApiGetScheduling
     */
    readonly include?: Array<GetSchedulingIncludeEnum>
}

/**
 * Request parameters for getSchedulingRelatedAvailabilities operation in SchedulingsApi.
 * @export
 * @interface SchedulingsApiGetSchedulingRelatedAvailabilitiesRequest
 */
export interface SchedulingsApiGetSchedulingRelatedAvailabilitiesRequest {
    /**
     * 
     * @type {string}
     * @memberof SchedulingsApiGetSchedulingRelatedAvailabilities
     */
    readonly schedulingId: string

    /**
     * 
     * @type {number}
     * @memberof SchedulingsApiGetSchedulingRelatedAvailabilities
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof SchedulingsApiGetSchedulingRelatedAvailabilities
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<'products' | 'products.defaultTariffGroupPriceInfo' | 'additionals' | 'additionals.product' | 'productTariffTypes' | 'productTariffTypes.product' | 'productTariffTypes.tariffType' | 'tariffs' | 'tariffs.type' | 'tariffs.group' | 'tariffs.product' | 'aggregatedRecurrenceCount'>}
     * @memberof SchedulingsApiGetSchedulingRelatedAvailabilities
     */
    readonly include?: Array<GetSchedulingRelatedAvailabilitiesIncludeEnum>
}

/**
 * Request parameters for updateScheduling operation in SchedulingsApi.
 * @export
 * @interface SchedulingsApiUpdateSchedulingRequest
 */
export interface SchedulingsApiUpdateSchedulingRequest {
    /**
     * 
     * @type {string}
     * @memberof SchedulingsApiUpdateScheduling
     */
    readonly schedulingId: string

    /**
     * 
     * @type {UpdateSchedulingParams}
     * @memberof SchedulingsApiUpdateScheduling
     */
    readonly updateSchedulingParams: UpdateSchedulingParams
}

/**
 * SchedulingsApi - object-oriented interface
 * @export
 * @class SchedulingsApi
 * @extends {BaseAPI}
 */
export class SchedulingsApi extends BaseAPI {
    /**
     * 
     * @param {SchedulingsApiCreateProductSchedulingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulingsApi
     */
    public createProductScheduling(requestParameters: SchedulingsApiCreateProductSchedulingRequest, options?: RawAxiosRequestConfig) {
        return SchedulingsApiFp(this.configuration).createProductScheduling(requestParameters.productId, requestParameters.createSchedulingParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SchedulingsApiDeleteSchedulingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulingsApi
     */
    public deleteScheduling(requestParameters: SchedulingsApiDeleteSchedulingRequest, options?: RawAxiosRequestConfig) {
        return SchedulingsApiFp(this.configuration).deleteScheduling(requestParameters.schedulingId, requestParameters.deleteSchedulingParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SchedulingsApiGetAppAggregatedSchedulingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulingsApi
     */
    public getAppAggregatedSchedulings(requestParameters: SchedulingsApiGetAppAggregatedSchedulingsRequest, options?: RawAxiosRequestConfig) {
        return SchedulingsApiFp(this.configuration).getAppAggregatedSchedulings(requestParameters.appId, requestParameters.since, requestParameters.until, requestParameters.offset, requestParameters.limit, requestParameters.productIds, requestParameters.include, requestParameters.previewCount, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SchedulingsApiGetAppSchedulingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulingsApi
     */
    public getAppSchedulings(requestParameters: SchedulingsApiGetAppSchedulingsRequest, options?: RawAxiosRequestConfig) {
        return SchedulingsApiFp(this.configuration).getAppSchedulings(requestParameters.appId, requestParameters.since, requestParameters.until, requestParameters.offset, requestParameters.limit, requestParameters.productIds, requestParameters.include, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SchedulingsApiGetAppSellableProductNextSchedulingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulingsApi
     */
    public getAppSellableProductNextScheduling(requestParameters: SchedulingsApiGetAppSellableProductNextSchedulingRequest, options?: RawAxiosRequestConfig) {
        return SchedulingsApiFp(this.configuration).getAppSellableProductNextScheduling(requestParameters.appId, requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SchedulingsApiGetArrangedProductDetailedSchedulingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulingsApi
     */
    public getArrangedProductDetailedSchedulings(requestParameters: SchedulingsApiGetArrangedProductDetailedSchedulingsRequest, options?: RawAxiosRequestConfig) {
        return SchedulingsApiFp(this.configuration).getArrangedProductDetailedSchedulings(requestParameters.appId, requestParameters.arrangedProductId, requestParameters.since, requestParameters.until, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SchedulingsApiGetProductNextSchedulingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulingsApi
     */
    public getProductNextScheduling(requestParameters: SchedulingsApiGetProductNextSchedulingRequest, options?: RawAxiosRequestConfig) {
        return SchedulingsApiFp(this.configuration).getProductNextScheduling(requestParameters.productId, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SchedulingsApiGetReservationRescheduleSchedulingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulingsApi
     */
    public getReservationRescheduleSchedulings(requestParameters: SchedulingsApiGetReservationRescheduleSchedulingsRequest, options?: RawAxiosRequestConfig) {
        return SchedulingsApiFp(this.configuration).getReservationRescheduleSchedulings(requestParameters.reservationId, requestParameters.rescheduleFrom, requestParameters.rescheduleUntil, requestParameters.offset, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SchedulingsApiGetSchedulingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulingsApi
     */
    public getScheduling(requestParameters: SchedulingsApiGetSchedulingRequest, options?: RawAxiosRequestConfig) {
        return SchedulingsApiFp(this.configuration).getScheduling(requestParameters.schedulingId, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SchedulingsApiGetSchedulingRelatedAvailabilitiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulingsApi
     */
    public getSchedulingRelatedAvailabilities(requestParameters: SchedulingsApiGetSchedulingRelatedAvailabilitiesRequest, options?: RawAxiosRequestConfig) {
        return SchedulingsApiFp(this.configuration).getSchedulingRelatedAvailabilities(requestParameters.schedulingId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SchedulingsApiUpdateSchedulingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulingsApi
     */
    public updateScheduling(requestParameters: SchedulingsApiUpdateSchedulingRequest, options?: RawAxiosRequestConfig) {
        return SchedulingsApiFp(this.configuration).updateScheduling(requestParameters.schedulingId, requestParameters.updateSchedulingParams, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAppAggregatedSchedulingsIncludeEnum = {
    TariffGroup: 'schedulings.tariffGroup',
    TariffGroupPriceInfo: 'schedulings.tariffGroup.priceInfo',
    TariffGroupSeatMap: 'schedulings.tariffGroup.seatMap',
    FinalTariffGroup: 'schedulings.finalTariffGroup',
    FinalTariffGroupPriceInfo: 'schedulings.finalTariffGroup.priceInfo',
    FinalTariffGroupSeatMap: 'schedulings.finalTariffGroup.seatMap',
    Product: 'schedulings.product',
    ProductDefaultTariffGroupPriceInfo: 'schedulings.product.defaultTariffGroupPriceInfo',
    AggregatedRecurrenceCount: 'schedulings.aggregatedRecurrenceCount'
} as const;
export type GetAppAggregatedSchedulingsIncludeEnum = typeof GetAppAggregatedSchedulingsIncludeEnum[keyof typeof GetAppAggregatedSchedulingsIncludeEnum];
/**
 * @export
 */
export const GetAppAggregatedSchedulingsSortByEnum = {
    Recent: 'recent',
    StartTimeAndName: 'startTimeAndName'
} as const;
export type GetAppAggregatedSchedulingsSortByEnum = typeof GetAppAggregatedSchedulingsSortByEnum[keyof typeof GetAppAggregatedSchedulingsSortByEnum];
/**
 * @export
 */
export const GetAppSchedulingsIncludeEnum = {
    TariffGroup: 'tariffGroup',
    TariffGroupPriceInfo: 'tariffGroup.priceInfo',
    FinalTariffGroup: 'finalTariffGroup',
    FinalTariffGroupPriceInfo: 'finalTariffGroup.priceInfo',
    Product: 'product',
    ProductDefaultTariffGroupPriceInfo: 'product.defaultTariffGroupPriceInfo',
    AggregatedRecurrenceCount: 'aggregatedRecurrenceCount'
} as const;
export type GetAppSchedulingsIncludeEnum = typeof GetAppSchedulingsIncludeEnum[keyof typeof GetAppSchedulingsIncludeEnum];
/**
 * @export
 */
export const GetAppSchedulingsSortByEnum = {
    Recent: 'recent',
    StartTimeAndName: 'startTimeAndName'
} as const;
export type GetAppSchedulingsSortByEnum = typeof GetAppSchedulingsSortByEnum[keyof typeof GetAppSchedulingsSortByEnum];
/**
 * @export
 */
export const GetArrangedProductDetailedSchedulingsIncludeEnum = {
    FinalTariffGroupTariffsType: 'finalTariffGroup.tariffs.type'
} as const;
export type GetArrangedProductDetailedSchedulingsIncludeEnum = typeof GetArrangedProductDetailedSchedulingsIncludeEnum[keyof typeof GetArrangedProductDetailedSchedulingsIncludeEnum];
/**
 * @export
 */
export const GetProductNextSchedulingIncludeEnum = {
    TariffGroup: 'tariffGroup',
    TariffGroupPriceInfo: 'tariffGroup.priceInfo',
    FinalTariffGroup: 'finalTariffGroup',
    FinalTariffGroupPriceInfo: 'finalTariffGroup.priceInfo',
    Product: 'product',
    ProductDefaultTariffGroupPriceInfo: 'product.defaultTariffGroupPriceInfo',
    AggregatedRecurrenceCount: 'aggregatedRecurrenceCount'
} as const;
export type GetProductNextSchedulingIncludeEnum = typeof GetProductNextSchedulingIncludeEnum[keyof typeof GetProductNextSchedulingIncludeEnum];
/**
 * @export
 */
export const GetSchedulingIncludeEnum = {
    TariffGroup: 'tariffGroup',
    TariffGroupPriceInfo: 'tariffGroup.priceInfo',
    FinalTariffGroup: 'finalTariffGroup',
    FinalTariffGroupPriceInfo: 'finalTariffGroup.priceInfo',
    Product: 'product',
    ProductDefaultTariffGroupPriceInfo: 'product.defaultTariffGroupPriceInfo',
    AggregatedRecurrenceCount: 'aggregatedRecurrenceCount'
} as const;
export type GetSchedulingIncludeEnum = typeof GetSchedulingIncludeEnum[keyof typeof GetSchedulingIncludeEnum];
/**
 * @export
 */
export const GetSchedulingRelatedAvailabilitiesIncludeEnum = {
    Products: 'products',
    ProductsDefaultTariffGroupPriceInfo: 'products.defaultTariffGroupPriceInfo',
    Additionals: 'additionals',
    AdditionalsProduct: 'additionals.product',
    ProductTariffTypes: 'productTariffTypes',
    ProductTariffTypesProduct: 'productTariffTypes.product',
    ProductTariffTypesTariffType: 'productTariffTypes.tariffType',
    Tariffs: 'tariffs',
    TariffsType: 'tariffs.type',
    TariffsGroup: 'tariffs.group',
    TariffsProduct: 'tariffs.product',
    AggregatedRecurrenceCount: 'aggregatedRecurrenceCount'
} as const;
export type GetSchedulingRelatedAvailabilitiesIncludeEnum = typeof GetSchedulingRelatedAvailabilitiesIncludeEnum[keyof typeof GetSchedulingRelatedAvailabilitiesIncludeEnum];


/**
 * SeatMapsApi - axios parameter creator
 * @export
 */
export const SeatMapsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} schedulingId 
         * @param {Array<GetSchedulingSeatMapOverviewIncludeEnum>} [include] 
         * @param {string} [scheduleTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchedulingSeatMapOverview: async (schedulingId: string, include?: Array<GetSchedulingSeatMapOverviewIncludeEnum>, scheduleTime?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schedulingId' is not null or undefined
            assertParamExists('getSchedulingSeatMapOverview', 'schedulingId', schedulingId)
            const localVarPath = `/schedulings/{schedulingId}/seatMapOverview`
                .replace(`{${"schedulingId"}}`, encodeURIComponent(String(schedulingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (scheduleTime !== undefined) {
                localVarQueryParameter['scheduleTime'] = scheduleTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SeatMapsApi - functional programming interface
 * @export
 */
export const SeatMapsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SeatMapsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} schedulingId 
         * @param {Array<GetSchedulingSeatMapOverviewIncludeEnum>} [include] 
         * @param {string} [scheduleTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSchedulingSeatMapOverview(schedulingId: string, include?: Array<GetSchedulingSeatMapOverviewIncludeEnum>, scheduleTime?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SeatMapOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSchedulingSeatMapOverview(schedulingId, include, scheduleTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SeatMapsApi.getSchedulingSeatMapOverview']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SeatMapsApi - factory interface
 * @export
 */
export const SeatMapsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SeatMapsApiFp(configuration)
    return {
        /**
         * 
         * @param {SeatMapsApiGetSchedulingSeatMapOverviewRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchedulingSeatMapOverview(requestParameters: SeatMapsApiGetSchedulingSeatMapOverviewRequest, options?: RawAxiosRequestConfig): AxiosPromise<SeatMapOverview> {
            return localVarFp.getSchedulingSeatMapOverview(requestParameters.schedulingId, requestParameters.include, requestParameters.scheduleTime, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getSchedulingSeatMapOverview operation in SeatMapsApi.
 * @export
 * @interface SeatMapsApiGetSchedulingSeatMapOverviewRequest
 */
export interface SeatMapsApiGetSchedulingSeatMapOverviewRequest {
    /**
     * 
     * @type {string}
     * @memberof SeatMapsApiGetSchedulingSeatMapOverview
     */
    readonly schedulingId: string

    /**
     * 
     * @type {Array<'areas.slots.resource.category' | 'areas.slots.resource.category.chargeTypeTariffReference' | 'areas.slots.resource.category.chargeTypeTariffReference.type'>}
     * @memberof SeatMapsApiGetSchedulingSeatMapOverview
     */
    readonly include?: Array<GetSchedulingSeatMapOverviewIncludeEnum>

    /**
     * 
     * @type {string}
     * @memberof SeatMapsApiGetSchedulingSeatMapOverview
     */
    readonly scheduleTime?: string
}

/**
 * SeatMapsApi - object-oriented interface
 * @export
 * @class SeatMapsApi
 * @extends {BaseAPI}
 */
export class SeatMapsApi extends BaseAPI {
    /**
     * 
     * @param {SeatMapsApiGetSchedulingSeatMapOverviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeatMapsApi
     */
    public getSchedulingSeatMapOverview(requestParameters: SeatMapsApiGetSchedulingSeatMapOverviewRequest, options?: RawAxiosRequestConfig) {
        return SeatMapsApiFp(this.configuration).getSchedulingSeatMapOverview(requestParameters.schedulingId, requestParameters.include, requestParameters.scheduleTime, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetSchedulingSeatMapOverviewIncludeEnum = {
    Category: 'areas.slots.resource.category',
    CategoryChargeTypeTariffReference: 'areas.slots.resource.category.chargeTypeTariffReference',
    CategoryChargeTypeTariffReferenceType: 'areas.slots.resource.category.chargeTypeTariffReference.type'
} as const;
export type GetSchedulingSeatMapOverviewIncludeEnum = typeof GetSchedulingSeatMapOverviewIncludeEnum[keyof typeof GetSchedulingSeatMapOverviewIncludeEnum];


/**
 * SiteConfigsApi - axios parameter creator
 * @export
 */
export const SiteConfigsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppSiteTemplateData: async (appId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppSiteTemplateData', 'appId', appId)
            const localVarPath = `/apps/{appId}/site/templateData`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SiteConfigsApi - functional programming interface
 * @export
 */
export const SiteConfigsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SiteConfigsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} appId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppSiteTemplateData(appId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppSiteTemplateData(appId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SiteConfigsApi.getAppSiteTemplateData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SiteConfigsApi - factory interface
 * @export
 */
export const SiteConfigsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SiteConfigsApiFp(configuration)
    return {
        /**
         * 
         * @param {SiteConfigsApiGetAppSiteTemplateDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppSiteTemplateData(requestParameters: SiteConfigsApiGetAppSiteTemplateDataRequest, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: any; }> {
            return localVarFp.getAppSiteTemplateData(requestParameters.appId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAppSiteTemplateData operation in SiteConfigsApi.
 * @export
 * @interface SiteConfigsApiGetAppSiteTemplateDataRequest
 */
export interface SiteConfigsApiGetAppSiteTemplateDataRequest {
    /**
     * 
     * @type {string}
     * @memberof SiteConfigsApiGetAppSiteTemplateData
     */
    readonly appId: string
}

/**
 * SiteConfigsApi - object-oriented interface
 * @export
 * @class SiteConfigsApi
 * @extends {BaseAPI}
 */
export class SiteConfigsApi extends BaseAPI {
    /**
     * 
     * @param {SiteConfigsApiGetAppSiteTemplateDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteConfigsApi
     */
    public getAppSiteTemplateData(requestParameters: SiteConfigsApiGetAppSiteTemplateDataRequest, options?: RawAxiosRequestConfig) {
        return SiteConfigsApiFp(this.configuration).getAppSiteTemplateData(requestParameters.appId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TagsApi - axios parameter creator
 * @export
 */
export const TagsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} appId 
         * @param {CreateAppTagParams} createAppTagParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppTag: async (appId: string, createAppTagParams: CreateAppTagParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('createAppTag', 'appId', appId)
            // verify required parameter 'createAppTagParams' is not null or undefined
            assertParamExists('createAppTag', 'createAppTagParams', createAppTagParams)
            const localVarPath = `/apps/{appId}/tags`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAppTagParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTag: async (tagId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('deleteTag', 'tagId', tagId)
            const localVarPath = `/tags/{tagId}`
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppTags: async (appId: string, offset?: number, limit?: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppTags', 'appId', appId)
            const localVarPath = `/apps/{appId}/tags`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tagId 
         * @param {CreateAppTagParams} createAppTagParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTag: async (tagId: string, createAppTagParams: CreateAppTagParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('updateTag', 'tagId', tagId)
            // verify required parameter 'createAppTagParams' is not null or undefined
            assertParamExists('updateTag', 'createAppTagParams', createAppTagParams)
            const localVarPath = `/tags/{tagId}`
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAppTagParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TagsApi - functional programming interface
 * @export
 */
export const TagsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TagsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} appId 
         * @param {CreateAppTagParams} createAppTagParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAppTag(appId: string, createAppTagParams: CreateAppTagParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAppTag(appId, createAppTagParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagsApi.createAppTag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTag(tagId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTag(tagId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagsApi.deleteTag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppTags(appId: string, offset?: number, limit?: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Tag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppTags(appId, offset, limit, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagsApi.getAppTags']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} tagId 
         * @param {CreateAppTagParams} createAppTagParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTag(tagId: string, createAppTagParams: CreateAppTagParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTag(tagId, createAppTagParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagsApi.updateTag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TagsApi - factory interface
 * @export
 */
export const TagsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TagsApiFp(configuration)
    return {
        /**
         * 
         * @param {TagsApiCreateAppTagRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppTag(requestParameters: TagsApiCreateAppTagRequest, options?: RawAxiosRequestConfig): AxiosPromise<Tag> {
            return localVarFp.createAppTag(requestParameters.appId, requestParameters.createAppTagParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TagsApiDeleteTagRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTag(requestParameters: TagsApiDeleteTagRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteTag(requestParameters.tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TagsApiGetAppTagsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppTags(requestParameters: TagsApiGetAppTagsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Tag>> {
            return localVarFp.getAppTags(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TagsApiUpdateTagRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTag(requestParameters: TagsApiUpdateTagRequest, options?: RawAxiosRequestConfig): AxiosPromise<Tag> {
            return localVarFp.updateTag(requestParameters.tagId, requestParameters.createAppTagParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createAppTag operation in TagsApi.
 * @export
 * @interface TagsApiCreateAppTagRequest
 */
export interface TagsApiCreateAppTagRequest {
    /**
     * 
     * @type {string}
     * @memberof TagsApiCreateAppTag
     */
    readonly appId: string

    /**
     * 
     * @type {CreateAppTagParams}
     * @memberof TagsApiCreateAppTag
     */
    readonly createAppTagParams: CreateAppTagParams
}

/**
 * Request parameters for deleteTag operation in TagsApi.
 * @export
 * @interface TagsApiDeleteTagRequest
 */
export interface TagsApiDeleteTagRequest {
    /**
     * 
     * @type {string}
     * @memberof TagsApiDeleteTag
     */
    readonly tagId: string
}

/**
 * Request parameters for getAppTags operation in TagsApi.
 * @export
 * @interface TagsApiGetAppTagsRequest
 */
export interface TagsApiGetAppTagsRequest {
    /**
     * 
     * @type {string}
     * @memberof TagsApiGetAppTags
     */
    readonly appId: string

    /**
     * 
     * @type {number}
     * @memberof TagsApiGetAppTags
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof TagsApiGetAppTags
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof TagsApiGetAppTags
     */
    readonly search?: string
}

/**
 * Request parameters for updateTag operation in TagsApi.
 * @export
 * @interface TagsApiUpdateTagRequest
 */
export interface TagsApiUpdateTagRequest {
    /**
     * 
     * @type {string}
     * @memberof TagsApiUpdateTag
     */
    readonly tagId: string

    /**
     * 
     * @type {CreateAppTagParams}
     * @memberof TagsApiUpdateTag
     */
    readonly createAppTagParams: CreateAppTagParams
}

/**
 * TagsApi - object-oriented interface
 * @export
 * @class TagsApi
 * @extends {BaseAPI}
 */
export class TagsApi extends BaseAPI {
    /**
     * 
     * @param {TagsApiCreateAppTagRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public createAppTag(requestParameters: TagsApiCreateAppTagRequest, options?: RawAxiosRequestConfig) {
        return TagsApiFp(this.configuration).createAppTag(requestParameters.appId, requestParameters.createAppTagParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TagsApiDeleteTagRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public deleteTag(requestParameters: TagsApiDeleteTagRequest, options?: RawAxiosRequestConfig) {
        return TagsApiFp(this.configuration).deleteTag(requestParameters.tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TagsApiGetAppTagsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public getAppTags(requestParameters: TagsApiGetAppTagsRequest, options?: RawAxiosRequestConfig) {
        return TagsApiFp(this.configuration).getAppTags(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TagsApiUpdateTagRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public updateTag(requestParameters: TagsApiUpdateTagRequest, options?: RawAxiosRequestConfig) {
        return TagsApiFp(this.configuration).updateTag(requestParameters.tagId, requestParameters.createAppTagParams, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TariffGroupsApi - axios parameter creator
 * @export
 */
export const TariffGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} productId 
         * @param {TariffGroupParams} tariffGroupParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductTariffGroup: async (productId: string, tariffGroupParams: TariffGroupParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('createProductTariffGroup', 'productId', productId)
            // verify required parameter 'tariffGroupParams' is not null or undefined
            assertParamExists('createProductTariffGroup', 'tariffGroupParams', tariffGroupParams)
            const localVarPath = `/products/{productId}/tariffGroups`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tariffGroupParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {string} arrangedProductId 
         * @param {Array<GetArrangedProductDetailedDefaultTariffGroupIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArrangedProductDetailedDefaultTariffGroup: async (appId: string, arrangedProductId: string, include?: Array<GetArrangedProductDetailedDefaultTariffGroupIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getArrangedProductDetailedDefaultTariffGroup', 'appId', appId)
            // verify required parameter 'arrangedProductId' is not null or undefined
            assertParamExists('getArrangedProductDetailedDefaultTariffGroup', 'arrangedProductId', arrangedProductId)
            const localVarPath = `/apps/{appId}/arrangedProducts/{arrangedProductId}/detailedDefaultTariffGroup`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"arrangedProductId"}}`, encodeURIComponent(String(arrangedProductId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {Array<GetProductDetailedDefaultTariffGroupIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductDetailedDefaultTariffGroup: async (productId: string, include?: Array<GetProductDetailedDefaultTariffGroupIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductDetailedDefaultTariffGroup', 'productId', productId)
            const localVarPath = `/products/{productId}/detailedDefaultTariffGroup`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetProductTariffGroupsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductTariffGroups: async (productId: string, offset?: number, limit?: number, include?: Array<GetProductTariffGroupsIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductTariffGroups', 'productId', productId)
            const localVarPath = `/products/{productId}/tariffGroups`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tariffGroupId 
         * @param {Array<GetTariffGroupIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffGroup: async (tariffGroupId: string, include?: Array<GetTariffGroupIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tariffGroupId' is not null or undefined
            assertParamExists('getTariffGroup', 'tariffGroupId', tariffGroupId)
            const localVarPath = `/tariffGroups/{tariffGroupId}`
                .replace(`{${"tariffGroupId"}}`, encodeURIComponent(String(tariffGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TariffGroupsApi - functional programming interface
 * @export
 */
export const TariffGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TariffGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} productId 
         * @param {TariffGroupParams} tariffGroupParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProductTariffGroup(productId: string, tariffGroupParams: TariffGroupParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TariffGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProductTariffGroup(productId, tariffGroupParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TariffGroupsApi.createProductTariffGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {string} arrangedProductId 
         * @param {Array<GetArrangedProductDetailedDefaultTariffGroupIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArrangedProductDetailedDefaultTariffGroup(appId: string, arrangedProductId: string, include?: Array<GetArrangedProductDetailedDefaultTariffGroupIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedTariffGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArrangedProductDetailedDefaultTariffGroup(appId, arrangedProductId, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TariffGroupsApi.getArrangedProductDetailedDefaultTariffGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} productId 
         * @param {Array<GetProductDetailedDefaultTariffGroupIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductDetailedDefaultTariffGroup(productId: string, include?: Array<GetProductDetailedDefaultTariffGroupIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedTariffGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductDetailedDefaultTariffGroup(productId, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TariffGroupsApi.getProductDetailedDefaultTariffGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} productId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetProductTariffGroupsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductTariffGroups(productId: string, offset?: number, limit?: number, include?: Array<GetProductTariffGroupsIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TariffGroup>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductTariffGroups(productId, offset, limit, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TariffGroupsApi.getProductTariffGroups']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} tariffGroupId 
         * @param {Array<GetTariffGroupIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTariffGroup(tariffGroupId: string, include?: Array<GetTariffGroupIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TariffGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTariffGroup(tariffGroupId, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TariffGroupsApi.getTariffGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TariffGroupsApi - factory interface
 * @export
 */
export const TariffGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TariffGroupsApiFp(configuration)
    return {
        /**
         * 
         * @param {TariffGroupsApiCreateProductTariffGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductTariffGroup(requestParameters: TariffGroupsApiCreateProductTariffGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<TariffGroup> {
            return localVarFp.createProductTariffGroup(requestParameters.productId, requestParameters.tariffGroupParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TariffGroupsApiGetArrangedProductDetailedDefaultTariffGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArrangedProductDetailedDefaultTariffGroup(requestParameters: TariffGroupsApiGetArrangedProductDetailedDefaultTariffGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<DetailedTariffGroup> {
            return localVarFp.getArrangedProductDetailedDefaultTariffGroup(requestParameters.appId, requestParameters.arrangedProductId, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TariffGroupsApiGetProductDetailedDefaultTariffGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductDetailedDefaultTariffGroup(requestParameters: TariffGroupsApiGetProductDetailedDefaultTariffGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<DetailedTariffGroup> {
            return localVarFp.getProductDetailedDefaultTariffGroup(requestParameters.productId, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TariffGroupsApiGetProductTariffGroupsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductTariffGroups(requestParameters: TariffGroupsApiGetProductTariffGroupsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<TariffGroup>> {
            return localVarFp.getProductTariffGroups(requestParameters.productId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TariffGroupsApiGetTariffGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffGroup(requestParameters: TariffGroupsApiGetTariffGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<TariffGroup> {
            return localVarFp.getTariffGroup(requestParameters.tariffGroupId, requestParameters.include, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createProductTariffGroup operation in TariffGroupsApi.
 * @export
 * @interface TariffGroupsApiCreateProductTariffGroupRequest
 */
export interface TariffGroupsApiCreateProductTariffGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof TariffGroupsApiCreateProductTariffGroup
     */
    readonly productId: string

    /**
     * 
     * @type {TariffGroupParams}
     * @memberof TariffGroupsApiCreateProductTariffGroup
     */
    readonly tariffGroupParams: TariffGroupParams
}

/**
 * Request parameters for getArrangedProductDetailedDefaultTariffGroup operation in TariffGroupsApi.
 * @export
 * @interface TariffGroupsApiGetArrangedProductDetailedDefaultTariffGroupRequest
 */
export interface TariffGroupsApiGetArrangedProductDetailedDefaultTariffGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof TariffGroupsApiGetArrangedProductDetailedDefaultTariffGroup
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof TariffGroupsApiGetArrangedProductDetailedDefaultTariffGroup
     */
    readonly arrangedProductId: string

    /**
     * 
     * @type {Array<'tariffs' | 'tariffs.type' | 'tariffs.group' | 'tariffs.product' | 'priceInfo'>}
     * @memberof TariffGroupsApiGetArrangedProductDetailedDefaultTariffGroup
     */
    readonly include?: Array<GetArrangedProductDetailedDefaultTariffGroupIncludeEnum>
}

/**
 * Request parameters for getProductDetailedDefaultTariffGroup operation in TariffGroupsApi.
 * @export
 * @interface TariffGroupsApiGetProductDetailedDefaultTariffGroupRequest
 */
export interface TariffGroupsApiGetProductDetailedDefaultTariffGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof TariffGroupsApiGetProductDetailedDefaultTariffGroup
     */
    readonly productId: string

    /**
     * 
     * @type {Array<'tariffs' | 'tariffs.type' | 'tariffs.group' | 'tariffs.product' | 'priceInfo'>}
     * @memberof TariffGroupsApiGetProductDetailedDefaultTariffGroup
     */
    readonly include?: Array<GetProductDetailedDefaultTariffGroupIncludeEnum>
}

/**
 * Request parameters for getProductTariffGroups operation in TariffGroupsApi.
 * @export
 * @interface TariffGroupsApiGetProductTariffGroupsRequest
 */
export interface TariffGroupsApiGetProductTariffGroupsRequest {
    /**
     * 
     * @type {string}
     * @memberof TariffGroupsApiGetProductTariffGroups
     */
    readonly productId: string

    /**
     * 
     * @type {number}
     * @memberof TariffGroupsApiGetProductTariffGroups
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof TariffGroupsApiGetProductTariffGroups
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<'product' | 'priceInfo'>}
     * @memberof TariffGroupsApiGetProductTariffGroups
     */
    readonly include?: Array<GetProductTariffGroupsIncludeEnum>
}

/**
 * Request parameters for getTariffGroup operation in TariffGroupsApi.
 * @export
 * @interface TariffGroupsApiGetTariffGroupRequest
 */
export interface TariffGroupsApiGetTariffGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof TariffGroupsApiGetTariffGroup
     */
    readonly tariffGroupId: string

    /**
     * 
     * @type {Array<'product' | 'priceInfo'>}
     * @memberof TariffGroupsApiGetTariffGroup
     */
    readonly include?: Array<GetTariffGroupIncludeEnum>
}

/**
 * TariffGroupsApi - object-oriented interface
 * @export
 * @class TariffGroupsApi
 * @extends {BaseAPI}
 */
export class TariffGroupsApi extends BaseAPI {
    /**
     * 
     * @param {TariffGroupsApiCreateProductTariffGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TariffGroupsApi
     */
    public createProductTariffGroup(requestParameters: TariffGroupsApiCreateProductTariffGroupRequest, options?: RawAxiosRequestConfig) {
        return TariffGroupsApiFp(this.configuration).createProductTariffGroup(requestParameters.productId, requestParameters.tariffGroupParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TariffGroupsApiGetArrangedProductDetailedDefaultTariffGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TariffGroupsApi
     */
    public getArrangedProductDetailedDefaultTariffGroup(requestParameters: TariffGroupsApiGetArrangedProductDetailedDefaultTariffGroupRequest, options?: RawAxiosRequestConfig) {
        return TariffGroupsApiFp(this.configuration).getArrangedProductDetailedDefaultTariffGroup(requestParameters.appId, requestParameters.arrangedProductId, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TariffGroupsApiGetProductDetailedDefaultTariffGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TariffGroupsApi
     */
    public getProductDetailedDefaultTariffGroup(requestParameters: TariffGroupsApiGetProductDetailedDefaultTariffGroupRequest, options?: RawAxiosRequestConfig) {
        return TariffGroupsApiFp(this.configuration).getProductDetailedDefaultTariffGroup(requestParameters.productId, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TariffGroupsApiGetProductTariffGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TariffGroupsApi
     */
    public getProductTariffGroups(requestParameters: TariffGroupsApiGetProductTariffGroupsRequest, options?: RawAxiosRequestConfig) {
        return TariffGroupsApiFp(this.configuration).getProductTariffGroups(requestParameters.productId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TariffGroupsApiGetTariffGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TariffGroupsApi
     */
    public getTariffGroup(requestParameters: TariffGroupsApiGetTariffGroupRequest, options?: RawAxiosRequestConfig) {
        return TariffGroupsApiFp(this.configuration).getTariffGroup(requestParameters.tariffGroupId, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetArrangedProductDetailedDefaultTariffGroupIncludeEnum = {
    Tariffs: 'tariffs',
    TariffsType: 'tariffs.type',
    TariffsGroup: 'tariffs.group',
    TariffsProduct: 'tariffs.product',
    PriceInfo: 'priceInfo'
} as const;
export type GetArrangedProductDetailedDefaultTariffGroupIncludeEnum = typeof GetArrangedProductDetailedDefaultTariffGroupIncludeEnum[keyof typeof GetArrangedProductDetailedDefaultTariffGroupIncludeEnum];
/**
 * @export
 */
export const GetProductDetailedDefaultTariffGroupIncludeEnum = {
    Tariffs: 'tariffs',
    TariffsType: 'tariffs.type',
    TariffsGroup: 'tariffs.group',
    TariffsProduct: 'tariffs.product',
    PriceInfo: 'priceInfo'
} as const;
export type GetProductDetailedDefaultTariffGroupIncludeEnum = typeof GetProductDetailedDefaultTariffGroupIncludeEnum[keyof typeof GetProductDetailedDefaultTariffGroupIncludeEnum];
/**
 * @export
 */
export const GetProductTariffGroupsIncludeEnum = {
    Product: 'product',
    PriceInfo: 'priceInfo'
} as const;
export type GetProductTariffGroupsIncludeEnum = typeof GetProductTariffGroupsIncludeEnum[keyof typeof GetProductTariffGroupsIncludeEnum];
/**
 * @export
 */
export const GetTariffGroupIncludeEnum = {
    Product: 'product',
    PriceInfo: 'priceInfo'
} as const;
export type GetTariffGroupIncludeEnum = typeof GetTariffGroupIncludeEnum[keyof typeof GetTariffGroupIncludeEnum];


/**
 * TariffTypesApi - axios parameter creator
 * @export
 */
export const TariffTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} appId 
         * @param {TariffTypeParams} tariffTypeParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppTariffType: async (appId: string, tariffTypeParams: TariffTypeParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('createAppTariffType', 'appId', appId)
            // verify required parameter 'tariffTypeParams' is not null or undefined
            assertParamExists('createAppTariffType', 'tariffTypeParams', tariffTypeParams)
            const localVarPath = `/apps/{appId}/tariffTypes`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tariffTypeParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppTariffTypes: async (appId: string, offset?: number, limit?: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppTariffTypes', 'appId', appId)
            const localVarPath = `/apps/{appId}/tariffTypes`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tariffTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffType: async (tariffTypeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tariffTypeId' is not null or undefined
            assertParamExists('getTariffType', 'tariffTypeId', tariffTypeId)
            const localVarPath = `/tariffTypes/{tariffTypeId}`
                .replace(`{${"tariffTypeId"}}`, encodeURIComponent(String(tariffTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TariffTypesApi - functional programming interface
 * @export
 */
export const TariffTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TariffTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} appId 
         * @param {TariffTypeParams} tariffTypeParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAppTariffType(appId: string, tariffTypeParams: TariffTypeParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TariffType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAppTariffType(appId, tariffTypeParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TariffTypesApi.createAppTariffType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppTariffTypes(appId: string, offset?: number, limit?: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TariffType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppTariffTypes(appId, offset, limit, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TariffTypesApi.getAppTariffTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} tariffTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTariffType(tariffTypeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TariffType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTariffType(tariffTypeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TariffTypesApi.getTariffType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TariffTypesApi - factory interface
 * @export
 */
export const TariffTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TariffTypesApiFp(configuration)
    return {
        /**
         * 
         * @param {TariffTypesApiCreateAppTariffTypeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppTariffType(requestParameters: TariffTypesApiCreateAppTariffTypeRequest, options?: RawAxiosRequestConfig): AxiosPromise<TariffType> {
            return localVarFp.createAppTariffType(requestParameters.appId, requestParameters.tariffTypeParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TariffTypesApiGetAppTariffTypesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppTariffTypes(requestParameters: TariffTypesApiGetAppTariffTypesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<TariffType>> {
            return localVarFp.getAppTariffTypes(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TariffTypesApiGetTariffTypeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffType(requestParameters: TariffTypesApiGetTariffTypeRequest, options?: RawAxiosRequestConfig): AxiosPromise<TariffType> {
            return localVarFp.getTariffType(requestParameters.tariffTypeId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createAppTariffType operation in TariffTypesApi.
 * @export
 * @interface TariffTypesApiCreateAppTariffTypeRequest
 */
export interface TariffTypesApiCreateAppTariffTypeRequest {
    /**
     * 
     * @type {string}
     * @memberof TariffTypesApiCreateAppTariffType
     */
    readonly appId: string

    /**
     * 
     * @type {TariffTypeParams}
     * @memberof TariffTypesApiCreateAppTariffType
     */
    readonly tariffTypeParams: TariffTypeParams
}

/**
 * Request parameters for getAppTariffTypes operation in TariffTypesApi.
 * @export
 * @interface TariffTypesApiGetAppTariffTypesRequest
 */
export interface TariffTypesApiGetAppTariffTypesRequest {
    /**
     * 
     * @type {string}
     * @memberof TariffTypesApiGetAppTariffTypes
     */
    readonly appId: string

    /**
     * 
     * @type {number}
     * @memberof TariffTypesApiGetAppTariffTypes
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof TariffTypesApiGetAppTariffTypes
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof TariffTypesApiGetAppTariffTypes
     */
    readonly search?: string
}

/**
 * Request parameters for getTariffType operation in TariffTypesApi.
 * @export
 * @interface TariffTypesApiGetTariffTypeRequest
 */
export interface TariffTypesApiGetTariffTypeRequest {
    /**
     * 
     * @type {string}
     * @memberof TariffTypesApiGetTariffType
     */
    readonly tariffTypeId: string
}

/**
 * TariffTypesApi - object-oriented interface
 * @export
 * @class TariffTypesApi
 * @extends {BaseAPI}
 */
export class TariffTypesApi extends BaseAPI {
    /**
     * 
     * @param {TariffTypesApiCreateAppTariffTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TariffTypesApi
     */
    public createAppTariffType(requestParameters: TariffTypesApiCreateAppTariffTypeRequest, options?: RawAxiosRequestConfig) {
        return TariffTypesApiFp(this.configuration).createAppTariffType(requestParameters.appId, requestParameters.tariffTypeParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TariffTypesApiGetAppTariffTypesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TariffTypesApi
     */
    public getAppTariffTypes(requestParameters: TariffTypesApiGetAppTariffTypesRequest, options?: RawAxiosRequestConfig) {
        return TariffTypesApiFp(this.configuration).getAppTariffTypes(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TariffTypesApiGetTariffTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TariffTypesApi
     */
    public getTariffType(requestParameters: TariffTypesApiGetTariffTypeRequest, options?: RawAxiosRequestConfig) {
        return TariffTypesApiFp(this.configuration).getTariffType(requestParameters.tariffTypeId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TariffsApi - axios parameter creator
 * @export
 */
export const TariffsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tariffGroupId 
         * @param {TariffParams} tariffParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTariffGroupTariff: async (tariffGroupId: string, tariffParams: TariffParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tariffGroupId' is not null or undefined
            assertParamExists('createTariffGroupTariff', 'tariffGroupId', tariffGroupId)
            // verify required parameter 'tariffParams' is not null or undefined
            assertParamExists('createTariffGroupTariff', 'tariffParams', tariffParams)
            const localVarPath = `/tariffGroups/{tariffGroupId}/tariffs`
                .replace(`{${"tariffGroupId"}}`, encodeURIComponent(String(tariffGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tariffParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tariffId 
         * @param {Array<GetTariffIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariff: async (tariffId: string, include?: Array<GetTariffIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tariffId' is not null or undefined
            assertParamExists('getTariff', 'tariffId', tariffId)
            const localVarPath = `/tariffs/{tariffId}`
                .replace(`{${"tariffId"}}`, encodeURIComponent(String(tariffId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tariffGroupId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetTariffGroupTariffsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffGroupTariffs: async (tariffGroupId: string, offset?: number, limit?: number, include?: Array<GetTariffGroupTariffsIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tariffGroupId' is not null or undefined
            assertParamExists('getTariffGroupTariffs', 'tariffGroupId', tariffGroupId)
            const localVarPath = `/tariffGroups/{tariffGroupId}/tariffs`
                .replace(`{${"tariffGroupId"}}`, encodeURIComponent(String(tariffGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TariffsApi - functional programming interface
 * @export
 */
export const TariffsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TariffsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tariffGroupId 
         * @param {TariffParams} tariffParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTariffGroupTariff(tariffGroupId: string, tariffParams: TariffParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tariff>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTariffGroupTariff(tariffGroupId, tariffParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TariffsApi.createTariffGroupTariff']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} tariffId 
         * @param {Array<GetTariffIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTariff(tariffId: string, include?: Array<GetTariffIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tariff>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTariff(tariffId, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TariffsApi.getTariff']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} tariffGroupId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetTariffGroupTariffsIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTariffGroupTariffs(tariffGroupId: string, offset?: number, limit?: number, include?: Array<GetTariffGroupTariffsIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Tariff>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTariffGroupTariffs(tariffGroupId, offset, limit, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TariffsApi.getTariffGroupTariffs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TariffsApi - factory interface
 * @export
 */
export const TariffsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TariffsApiFp(configuration)
    return {
        /**
         * 
         * @param {TariffsApiCreateTariffGroupTariffRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTariffGroupTariff(requestParameters: TariffsApiCreateTariffGroupTariffRequest, options?: RawAxiosRequestConfig): AxiosPromise<Tariff> {
            return localVarFp.createTariffGroupTariff(requestParameters.tariffGroupId, requestParameters.tariffParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TariffsApiGetTariffRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariff(requestParameters: TariffsApiGetTariffRequest, options?: RawAxiosRequestConfig): AxiosPromise<Tariff> {
            return localVarFp.getTariff(requestParameters.tariffId, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TariffsApiGetTariffGroupTariffsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffGroupTariffs(requestParameters: TariffsApiGetTariffGroupTariffsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Tariff>> {
            return localVarFp.getTariffGroupTariffs(requestParameters.tariffGroupId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createTariffGroupTariff operation in TariffsApi.
 * @export
 * @interface TariffsApiCreateTariffGroupTariffRequest
 */
export interface TariffsApiCreateTariffGroupTariffRequest {
    /**
     * 
     * @type {string}
     * @memberof TariffsApiCreateTariffGroupTariff
     */
    readonly tariffGroupId: string

    /**
     * 
     * @type {TariffParams}
     * @memberof TariffsApiCreateTariffGroupTariff
     */
    readonly tariffParams: TariffParams
}

/**
 * Request parameters for getTariff operation in TariffsApi.
 * @export
 * @interface TariffsApiGetTariffRequest
 */
export interface TariffsApiGetTariffRequest {
    /**
     * 
     * @type {string}
     * @memberof TariffsApiGetTariff
     */
    readonly tariffId: string

    /**
     * 
     * @type {Array<'type' | 'group'>}
     * @memberof TariffsApiGetTariff
     */
    readonly include?: Array<GetTariffIncludeEnum>
}

/**
 * Request parameters for getTariffGroupTariffs operation in TariffsApi.
 * @export
 * @interface TariffsApiGetTariffGroupTariffsRequest
 */
export interface TariffsApiGetTariffGroupTariffsRequest {
    /**
     * 
     * @type {string}
     * @memberof TariffsApiGetTariffGroupTariffs
     */
    readonly tariffGroupId: string

    /**
     * 
     * @type {number}
     * @memberof TariffsApiGetTariffGroupTariffs
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof TariffsApiGetTariffGroupTariffs
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<'type' | 'group'>}
     * @memberof TariffsApiGetTariffGroupTariffs
     */
    readonly include?: Array<GetTariffGroupTariffsIncludeEnum>
}

/**
 * TariffsApi - object-oriented interface
 * @export
 * @class TariffsApi
 * @extends {BaseAPI}
 */
export class TariffsApi extends BaseAPI {
    /**
     * 
     * @param {TariffsApiCreateTariffGroupTariffRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TariffsApi
     */
    public createTariffGroupTariff(requestParameters: TariffsApiCreateTariffGroupTariffRequest, options?: RawAxiosRequestConfig) {
        return TariffsApiFp(this.configuration).createTariffGroupTariff(requestParameters.tariffGroupId, requestParameters.tariffParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TariffsApiGetTariffRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TariffsApi
     */
    public getTariff(requestParameters: TariffsApiGetTariffRequest, options?: RawAxiosRequestConfig) {
        return TariffsApiFp(this.configuration).getTariff(requestParameters.tariffId, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TariffsApiGetTariffGroupTariffsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TariffsApi
     */
    public getTariffGroupTariffs(requestParameters: TariffsApiGetTariffGroupTariffsRequest, options?: RawAxiosRequestConfig) {
        return TariffsApiFp(this.configuration).getTariffGroupTariffs(requestParameters.tariffGroupId, requestParameters.offset, requestParameters.limit, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetTariffIncludeEnum = {
    Type: 'type',
    Group: 'group'
} as const;
export type GetTariffIncludeEnum = typeof GetTariffIncludeEnum[keyof typeof GetTariffIncludeEnum];
/**
 * @export
 */
export const GetTariffGroupTariffsIncludeEnum = {
    Type: 'type',
    Group: 'group'
} as const;
export type GetTariffGroupTariffsIncludeEnum = typeof GetTariffGroupTariffsIncludeEnum[keyof typeof GetTariffGroupTariffsIncludeEnum];


/**
 * TransactionsApi - axios parameter creator
 * @export
 */
export const TransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} transactionId 
         * @param {Array<GetDirectSaleTransactionIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDirectSaleTransaction: async (transactionId: string, include?: Array<GetDirectSaleTransactionIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('getDirectSaleTransaction', 'transactionId', transactionId)
            const localVarPath = `/directSaleTransactions/{transactionId}`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionsApi - functional programming interface
 * @export
 */
export const TransactionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} transactionId 
         * @param {Array<GetDirectSaleTransactionIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDirectSaleTransaction(transactionId: string, include?: Array<GetDirectSaleTransactionIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectSaleTransaction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDirectSaleTransaction(transactionId, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionsApi.getDirectSaleTransaction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TransactionsApi - factory interface
 * @export
 */
export const TransactionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionsApiFp(configuration)
    return {
        /**
         * 
         * @param {TransactionsApiGetDirectSaleTransactionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDirectSaleTransaction(requestParameters: TransactionsApiGetDirectSaleTransactionRequest, options?: RawAxiosRequestConfig): AxiosPromise<DirectSaleTransaction> {
            return localVarFp.getDirectSaleTransaction(requestParameters.transactionId, requestParameters.include, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getDirectSaleTransaction operation in TransactionsApi.
 * @export
 * @interface TransactionsApiGetDirectSaleTransactionRequest
 */
export interface TransactionsApiGetDirectSaleTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof TransactionsApiGetDirectSaleTransaction
     */
    readonly transactionId: string

    /**
     * 
     * @type {Array<'paymentMethod'>}
     * @memberof TransactionsApiGetDirectSaleTransaction
     */
    readonly include?: Array<GetDirectSaleTransactionIncludeEnum>
}

/**
 * TransactionsApi - object-oriented interface
 * @export
 * @class TransactionsApi
 * @extends {BaseAPI}
 */
export class TransactionsApi extends BaseAPI {
    /**
     * 
     * @param {TransactionsApiGetDirectSaleTransactionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public getDirectSaleTransaction(requestParameters: TransactionsApiGetDirectSaleTransactionRequest, options?: RawAxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).getDirectSaleTransaction(requestParameters.transactionId, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetDirectSaleTransactionIncludeEnum = {
    PaymentMethod: 'paymentMethod'
} as const;
export type GetDirectSaleTransactionIncludeEnum = typeof GetDirectSaleTransactionIncludeEnum[keyof typeof GetDirectSaleTransactionIncludeEnum];


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOwnUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOwnUser(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getOwnUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnUser(options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.getOwnUser(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getOwnUser(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getOwnUser(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VouchersApi - axios parameter creator
 * @export
 */
export const VouchersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} appId 
         * @param {ConsumeQrCodeVoucherParams} consumeQrCodeVoucherParams 
         * @param {Array<ConsumeAppQrCodeVoucherIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumeAppQrCodeVoucher: async (appId: string, consumeQrCodeVoucherParams: ConsumeQrCodeVoucherParams, include?: Array<ConsumeAppQrCodeVoucherIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('consumeAppQrCodeVoucher', 'appId', appId)
            // verify required parameter 'consumeQrCodeVoucherParams' is not null or undefined
            assertParamExists('consumeAppQrCodeVoucher', 'consumeQrCodeVoucherParams', consumeQrCodeVoucherParams)
            const localVarPath = `/apps/{appId}/qrCodeVoucher/consume`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consumeQrCodeVoucherParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} voucherId 
         * @param {ConsumeVoucherParams} consumeVoucherParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumeVoucher: async (voucherId: string, consumeVoucherParams: ConsumeVoucherParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'voucherId' is not null or undefined
            assertParamExists('consumeVoucher', 'voucherId', voucherId)
            // verify required parameter 'consumeVoucherParams' is not null or undefined
            assertParamExists('consumeVoucher', 'consumeVoucherParams', consumeVoucherParams)
            const localVarPath = `/vouchers/{voucherId}/consume`
                .replace(`{${"voucherId"}}`, encodeURIComponent(String(voucherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consumeVoucherParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} voucherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumeVoucherExceptionally: async (voucherId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'voucherId' is not null or undefined
            assertParamExists('consumeVoucherExceptionally', 'voucherId', voucherId)
            const localVarPath = `/vouchers/{voucherId}/consumeExceptionally`
                .replace(`{${"voucherId"}}`, encodeURIComponent(String(voucherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {string} content 
         * @param {Array<GetAppQrCodeVoucherIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppQrCodeVoucher: async (appId: string, content: string, include?: Array<GetAppQrCodeVoucherIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppQrCodeVoucher', 'appId', appId)
            // verify required parameter 'content' is not null or undefined
            assertParamExists('getAppQrCodeVoucher', 'content', content)
            const localVarPath = `/apps/{appId}/qrCodeVoucher`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [since] 
         * @param {string} [until] 
         * @param {GetAppVouchersRevokedStatusEnum} [revokedStatus] 
         * @param {GetAppVouchersConsumedStatusEnum} [consumedStatus] 
         * @param {GetAppVouchersSearchStatusEnum} [searchStatus] 
         * @param {string | null} [searchTerm] 
         * @param {GetAppVouchersSortByEnum} [sortBy] 
         * @param {Array<GetAppVouchersIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppVouchers: async (appId: string, offset?: number, limit?: number, since?: string, until?: string, revokedStatus?: GetAppVouchersRevokedStatusEnum, consumedStatus?: GetAppVouchersConsumedStatusEnum, searchStatus?: GetAppVouchersSearchStatusEnum, searchTerm?: string | null, sortBy?: GetAppVouchersSortByEnum, include?: Array<GetAppVouchersIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppVouchers', 'appId', appId)
            const localVarPath = `/apps/{appId}/vouchers`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString().substring(0,10) :
                    since;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = (until as any instanceof Date) ?
                    (until as any).toISOString().substring(0,10) :
                    until;
            }

            if (revokedStatus !== undefined) {
                localVarQueryParameter['revokedStatus'] = revokedStatus;
            }

            if (consumedStatus !== undefined) {
                localVarQueryParameter['consumedStatus'] = consumedStatus;
            }

            if (searchStatus !== undefined) {
                localVarQueryParameter['searchStatus'] = searchStatus;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} saleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDirectSaleVouchersPdf: async (saleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('getDirectSaleVouchersPdf', 'saleId', saleId)
            const localVarPath = `/directSales/{saleId}/vouchers/pdf`
                .replace(`{${"saleId"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reservationId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetReservationVouchersIncludeEnum>} [include] 
         * @param {GetReservationVouchersRevokedStatusEnum} [revokedStatus] 
         * @param {GetReservationVouchersConsumedStatusEnum} [consumedStatus] 
         * @param {GetReservationVouchersSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReservationVouchers: async (reservationId: string, offset?: number, limit?: number, include?: Array<GetReservationVouchersIncludeEnum>, revokedStatus?: GetReservationVouchersRevokedStatusEnum, consumedStatus?: GetReservationVouchersConsumedStatusEnum, sortBy?: GetReservationVouchersSortByEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('getReservationVouchers', 'reservationId', reservationId)
            const localVarPath = `/reservations/{reservationId}/vouchers`
                .replace(`{${"reservationId"}}`, encodeURIComponent(String(reservationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (revokedStatus !== undefined) {
                localVarQueryParameter['revokedStatus'] = revokedStatus;
            }

            if (consumedStatus !== undefined) {
                localVarQueryParameter['consumedStatus'] = consumedStatus;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} saleId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetSaleVouchersIncludeEnum>} [include] 
         * @param {GetSaleVouchersRevokedStatusEnum} [revokedStatus] 
         * @param {GetSaleVouchersConsumedStatusEnum} [consumedStatus] 
         * @param {GetSaleVouchersSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSaleVouchers: async (saleId: string, offset?: number, limit?: number, include?: Array<GetSaleVouchersIncludeEnum>, revokedStatus?: GetSaleVouchersRevokedStatusEnum, consumedStatus?: GetSaleVouchersConsumedStatusEnum, sortBy?: GetSaleVouchersSortByEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('getSaleVouchers', 'saleId', saleId)
            const localVarPath = `/sales/{saleId}/vouchers`
                .replace(`{${"saleId"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (revokedStatus !== undefined) {
                localVarQueryParameter['revokedStatus'] = revokedStatus;
            }

            if (consumedStatus !== undefined) {
                localVarQueryParameter['consumedStatus'] = consumedStatus;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} voucherId 
         * @param {Array<GetVoucherIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoucher: async (voucherId: string, include?: Array<GetVoucherIncludeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'voucherId' is not null or undefined
            assertParamExists('getVoucher', 'voucherId', voucherId)
            const localVarPath = `/vouchers/{voucherId}`
                .replace(`{${"voucherId"}}`, encodeURIComponent(String(voucherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} voucherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoucherPdf: async (voucherId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'voucherId' is not null or undefined
            assertParamExists('getVoucherPdf', 'voucherId', voucherId)
            const localVarPath = `/vouchers/{voucherId}/pdf`
                .replace(`{${"voucherId"}}`, encodeURIComponent(String(voucherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} saleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSaleVouchersToCustomer: async (saleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('sendSaleVouchersToCustomer', 'saleId', saleId)
            const localVarPath = `/sales/{saleId}/vouchers/sendToCustomer`
                .replace(`{${"saleId"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} voucherId 
         * @param {SendVoucherParams} sendVoucherParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendVoucher: async (voucherId: string, sendVoucherParams: SendVoucherParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'voucherId' is not null or undefined
            assertParamExists('sendVoucher', 'voucherId', voucherId)
            // verify required parameter 'sendVoucherParams' is not null or undefined
            assertParamExists('sendVoucher', 'sendVoucherParams', sendVoucherParams)
            const localVarPath = `/vouchers/{voucherId}/send`
                .replace(`{${"voucherId"}}`, encodeURIComponent(String(voucherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendVoucherParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VouchersApi - functional programming interface
 * @export
 */
export const VouchersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VouchersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} appId 
         * @param {ConsumeQrCodeVoucherParams} consumeQrCodeVoucherParams 
         * @param {Array<ConsumeAppQrCodeVoucherIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consumeAppQrCodeVoucher(appId: string, consumeQrCodeVoucherParams: ConsumeQrCodeVoucherParams, include?: Array<ConsumeAppQrCodeVoucherIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Voucher>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consumeAppQrCodeVoucher(appId, consumeQrCodeVoucherParams, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VouchersApi.consumeAppQrCodeVoucher']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} voucherId 
         * @param {ConsumeVoucherParams} consumeVoucherParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consumeVoucher(voucherId: string, consumeVoucherParams: ConsumeVoucherParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Voucher>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consumeVoucher(voucherId, consumeVoucherParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VouchersApi.consumeVoucher']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} voucherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consumeVoucherExceptionally(voucherId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Voucher>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consumeVoucherExceptionally(voucherId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VouchersApi.consumeVoucherExceptionally']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {string} content 
         * @param {Array<GetAppQrCodeVoucherIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppQrCodeVoucher(appId: string, content: string, include?: Array<GetAppQrCodeVoucherIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Voucher>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppQrCodeVoucher(appId, content, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VouchersApi.getAppQrCodeVoucher']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [since] 
         * @param {string} [until] 
         * @param {GetAppVouchersRevokedStatusEnum} [revokedStatus] 
         * @param {GetAppVouchersConsumedStatusEnum} [consumedStatus] 
         * @param {GetAppVouchersSearchStatusEnum} [searchStatus] 
         * @param {string | null} [searchTerm] 
         * @param {GetAppVouchersSortByEnum} [sortBy] 
         * @param {Array<GetAppVouchersIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppVouchers(appId: string, offset?: number, limit?: number, since?: string, until?: string, revokedStatus?: GetAppVouchersRevokedStatusEnum, consumedStatus?: GetAppVouchersConsumedStatusEnum, searchStatus?: GetAppVouchersSearchStatusEnum, searchTerm?: string | null, sortBy?: GetAppVouchersSortByEnum, include?: Array<GetAppVouchersIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Voucher>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppVouchers(appId, offset, limit, since, until, revokedStatus, consumedStatus, searchStatus, searchTerm, sortBy, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VouchersApi.getAppVouchers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} saleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDirectSaleVouchersPdf(saleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDirectSaleVouchersPdf(saleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VouchersApi.getDirectSaleVouchersPdf']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} reservationId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetReservationVouchersIncludeEnum>} [include] 
         * @param {GetReservationVouchersRevokedStatusEnum} [revokedStatus] 
         * @param {GetReservationVouchersConsumedStatusEnum} [consumedStatus] 
         * @param {GetReservationVouchersSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReservationVouchers(reservationId: string, offset?: number, limit?: number, include?: Array<GetReservationVouchersIncludeEnum>, revokedStatus?: GetReservationVouchersRevokedStatusEnum, consumedStatus?: GetReservationVouchersConsumedStatusEnum, sortBy?: GetReservationVouchersSortByEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Voucher>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReservationVouchers(reservationId, offset, limit, include, revokedStatus, consumedStatus, sortBy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VouchersApi.getReservationVouchers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} saleId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<GetSaleVouchersIncludeEnum>} [include] 
         * @param {GetSaleVouchersRevokedStatusEnum} [revokedStatus] 
         * @param {GetSaleVouchersConsumedStatusEnum} [consumedStatus] 
         * @param {GetSaleVouchersSortByEnum} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSaleVouchers(saleId: string, offset?: number, limit?: number, include?: Array<GetSaleVouchersIncludeEnum>, revokedStatus?: GetSaleVouchersRevokedStatusEnum, consumedStatus?: GetSaleVouchersConsumedStatusEnum, sortBy?: GetSaleVouchersSortByEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Voucher>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSaleVouchers(saleId, offset, limit, include, revokedStatus, consumedStatus, sortBy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VouchersApi.getSaleVouchers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} voucherId 
         * @param {Array<GetVoucherIncludeEnum>} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVoucher(voucherId: string, include?: Array<GetVoucherIncludeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Voucher>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVoucher(voucherId, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VouchersApi.getVoucher']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} voucherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVoucherPdf(voucherId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVoucherPdf(voucherId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VouchersApi.getVoucherPdf']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} saleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendSaleVouchersToCustomer(saleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendSaleVouchersToCustomer(saleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VouchersApi.sendSaleVouchersToCustomer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} voucherId 
         * @param {SendVoucherParams} sendVoucherParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendVoucher(voucherId: string, sendVoucherParams: SendVoucherParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendVoucher(voucherId, sendVoucherParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VouchersApi.sendVoucher']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VouchersApi - factory interface
 * @export
 */
export const VouchersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VouchersApiFp(configuration)
    return {
        /**
         * 
         * @param {VouchersApiConsumeAppQrCodeVoucherRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumeAppQrCodeVoucher(requestParameters: VouchersApiConsumeAppQrCodeVoucherRequest, options?: RawAxiosRequestConfig): AxiosPromise<Voucher> {
            return localVarFp.consumeAppQrCodeVoucher(requestParameters.appId, requestParameters.consumeQrCodeVoucherParams, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VouchersApiConsumeVoucherRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumeVoucher(requestParameters: VouchersApiConsumeVoucherRequest, options?: RawAxiosRequestConfig): AxiosPromise<Voucher> {
            return localVarFp.consumeVoucher(requestParameters.voucherId, requestParameters.consumeVoucherParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VouchersApiConsumeVoucherExceptionallyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumeVoucherExceptionally(requestParameters: VouchersApiConsumeVoucherExceptionallyRequest, options?: RawAxiosRequestConfig): AxiosPromise<Voucher> {
            return localVarFp.consumeVoucherExceptionally(requestParameters.voucherId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VouchersApiGetAppQrCodeVoucherRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppQrCodeVoucher(requestParameters: VouchersApiGetAppQrCodeVoucherRequest, options?: RawAxiosRequestConfig): AxiosPromise<Voucher> {
            return localVarFp.getAppQrCodeVoucher(requestParameters.appId, requestParameters.content, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VouchersApiGetAppVouchersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppVouchers(requestParameters: VouchersApiGetAppVouchersRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Voucher>> {
            return localVarFp.getAppVouchers(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.since, requestParameters.until, requestParameters.revokedStatus, requestParameters.consumedStatus, requestParameters.searchStatus, requestParameters.searchTerm, requestParameters.sortBy, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VouchersApiGetDirectSaleVouchersPdfRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDirectSaleVouchersPdf(requestParameters: VouchersApiGetDirectSaleVouchersPdfRequest, options?: RawAxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.getDirectSaleVouchersPdf(requestParameters.saleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VouchersApiGetReservationVouchersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReservationVouchers(requestParameters: VouchersApiGetReservationVouchersRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Voucher>> {
            return localVarFp.getReservationVouchers(requestParameters.reservationId, requestParameters.offset, requestParameters.limit, requestParameters.include, requestParameters.revokedStatus, requestParameters.consumedStatus, requestParameters.sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VouchersApiGetSaleVouchersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSaleVouchers(requestParameters: VouchersApiGetSaleVouchersRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Voucher>> {
            return localVarFp.getSaleVouchers(requestParameters.saleId, requestParameters.offset, requestParameters.limit, requestParameters.include, requestParameters.revokedStatus, requestParameters.consumedStatus, requestParameters.sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VouchersApiGetVoucherRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoucher(requestParameters: VouchersApiGetVoucherRequest, options?: RawAxiosRequestConfig): AxiosPromise<Voucher> {
            return localVarFp.getVoucher(requestParameters.voucherId, requestParameters.include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VouchersApiGetVoucherPdfRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoucherPdf(requestParameters: VouchersApiGetVoucherPdfRequest, options?: RawAxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.getVoucherPdf(requestParameters.voucherId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VouchersApiSendSaleVouchersToCustomerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSaleVouchersToCustomer(requestParameters: VouchersApiSendSaleVouchersToCustomerRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sendSaleVouchersToCustomer(requestParameters.saleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VouchersApiSendVoucherRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendVoucher(requestParameters: VouchersApiSendVoucherRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sendVoucher(requestParameters.voucherId, requestParameters.sendVoucherParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for consumeAppQrCodeVoucher operation in VouchersApi.
 * @export
 * @interface VouchersApiConsumeAppQrCodeVoucherRequest
 */
export interface VouchersApiConsumeAppQrCodeVoucherRequest {
    /**
     * 
     * @type {string}
     * @memberof VouchersApiConsumeAppQrCodeVoucher
     */
    readonly appId: string

    /**
     * 
     * @type {ConsumeQrCodeVoucherParams}
     * @memberof VouchersApiConsumeAppQrCodeVoucher
     */
    readonly consumeQrCodeVoucherParams: ConsumeQrCodeVoucherParams

    /**
     * 
     * @type {Array<'product' | 'sale' | 'sale.customer' | 'sale.order' | 'sale.order.decisiveTransaction' | 'sale.order.decisiveTransaction.paymentMethod' | 'sale.decisiveTransaction' | 'sale.decisiveTransaction.paymentMethod' | 'tariffs.tariff' | 'tariffs.tariff.type' | 'tariffs.tariff.group' | 'tariffs.saleTariff' | 'tariffs.saleTariff.tariff' | 'tariffs.saleTariff.tariff.type' | 'tariffs.saleTariff.tariff.group' | 'additionals.additional' | 'additionals.saleAdditional' | 'resources.saleResource.resource'>}
     * @memberof VouchersApiConsumeAppQrCodeVoucher
     */
    readonly include?: Array<ConsumeAppQrCodeVoucherIncludeEnum>
}

/**
 * Request parameters for consumeVoucher operation in VouchersApi.
 * @export
 * @interface VouchersApiConsumeVoucherRequest
 */
export interface VouchersApiConsumeVoucherRequest {
    /**
     * 
     * @type {string}
     * @memberof VouchersApiConsumeVoucher
     */
    readonly voucherId: string

    /**
     * 
     * @type {ConsumeVoucherParams}
     * @memberof VouchersApiConsumeVoucher
     */
    readonly consumeVoucherParams: ConsumeVoucherParams
}

/**
 * Request parameters for consumeVoucherExceptionally operation in VouchersApi.
 * @export
 * @interface VouchersApiConsumeVoucherExceptionallyRequest
 */
export interface VouchersApiConsumeVoucherExceptionallyRequest {
    /**
     * 
     * @type {string}
     * @memberof VouchersApiConsumeVoucherExceptionally
     */
    readonly voucherId: string
}

/**
 * Request parameters for getAppQrCodeVoucher operation in VouchersApi.
 * @export
 * @interface VouchersApiGetAppQrCodeVoucherRequest
 */
export interface VouchersApiGetAppQrCodeVoucherRequest {
    /**
     * 
     * @type {string}
     * @memberof VouchersApiGetAppQrCodeVoucher
     */
    readonly appId: string

    /**
     * 
     * @type {string}
     * @memberof VouchersApiGetAppQrCodeVoucher
     */
    readonly content: string

    /**
     * 
     * @type {Array<'product' | 'sale' | 'sale.customer' | 'sale.order' | 'sale.order.decisiveTransaction' | 'sale.order.decisiveTransaction.paymentMethod' | 'sale.decisiveTransaction' | 'sale.decisiveTransaction.paymentMethod' | 'tariffs.tariff' | 'tariffs.tariff.type' | 'tariffs.tariff.group' | 'tariffs.saleTariff' | 'tariffs.saleTariff.tariff' | 'tariffs.saleTariff.tariff.type' | 'tariffs.saleTariff.tariff.group' | 'additionals.additional' | 'additionals.saleAdditional' | 'resources.saleResource.resource'>}
     * @memberof VouchersApiGetAppQrCodeVoucher
     */
    readonly include?: Array<GetAppQrCodeVoucherIncludeEnum>
}

/**
 * Request parameters for getAppVouchers operation in VouchersApi.
 * @export
 * @interface VouchersApiGetAppVouchersRequest
 */
export interface VouchersApiGetAppVouchersRequest {
    /**
     * 
     * @type {string}
     * @memberof VouchersApiGetAppVouchers
     */
    readonly appId: string

    /**
     * 
     * @type {number}
     * @memberof VouchersApiGetAppVouchers
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof VouchersApiGetAppVouchers
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof VouchersApiGetAppVouchers
     */
    readonly since?: string

    /**
     * 
     * @type {string}
     * @memberof VouchersApiGetAppVouchers
     */
    readonly until?: string

    /**
     * 
     * @type {'all' | 'active' | 'revoked'}
     * @memberof VouchersApiGetAppVouchers
     */
    readonly revokedStatus?: GetAppVouchersRevokedStatusEnum

    /**
     * 
     * @type {'all' | 'open' | 'consumed' | 'exceptional'}
     * @memberof VouchersApiGetAppVouchers
     */
    readonly consumedStatus?: GetAppVouchersConsumedStatusEnum

    /**
     * 
     * @type {'all' | 'open' | 'consumed' | 'revoked'}
     * @memberof VouchersApiGetAppVouchers
     */
    readonly searchStatus?: GetAppVouchersSearchStatusEnum

    /**
     * 
     * @type {string}
     * @memberof VouchersApiGetAppVouchers
     */
    readonly searchTerm?: string | null

    /**
     * 
     * @type {'oldest' | 'recent' | 'consumption'}
     * @memberof VouchersApiGetAppVouchers
     */
    readonly sortBy?: GetAppVouchersSortByEnum

    /**
     * 
     * @type {Array<'product' | 'sale' | 'sale.customer' | 'sale.order' | 'sale.order.decisiveTransaction' | 'sale.order.decisiveTransaction.paymentMethod' | 'sale.decisiveTransaction' | 'sale.decisiveTransaction.paymentMethod' | 'tariffs.tariff' | 'tariffs.tariff.type' | 'tariffs.tariff.group' | 'tariffs.saleTariff' | 'tariffs.saleTariff.tariff' | 'tariffs.saleTariff.tariff.type' | 'tariffs.saleTariff.tariff.group' | 'additionals.additional' | 'additionals.saleAdditional' | 'resources.saleResource.resource'>}
     * @memberof VouchersApiGetAppVouchers
     */
    readonly include?: Array<GetAppVouchersIncludeEnum>
}

/**
 * Request parameters for getDirectSaleVouchersPdf operation in VouchersApi.
 * @export
 * @interface VouchersApiGetDirectSaleVouchersPdfRequest
 */
export interface VouchersApiGetDirectSaleVouchersPdfRequest {
    /**
     * 
     * @type {string}
     * @memberof VouchersApiGetDirectSaleVouchersPdf
     */
    readonly saleId: string
}

/**
 * Request parameters for getReservationVouchers operation in VouchersApi.
 * @export
 * @interface VouchersApiGetReservationVouchersRequest
 */
export interface VouchersApiGetReservationVouchersRequest {
    /**
     * 
     * @type {string}
     * @memberof VouchersApiGetReservationVouchers
     */
    readonly reservationId: string

    /**
     * 
     * @type {number}
     * @memberof VouchersApiGetReservationVouchers
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof VouchersApiGetReservationVouchers
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<'product' | 'sale' | 'sale.customer' | 'sale.order' | 'sale.order.decisiveTransaction' | 'sale.order.decisiveTransaction.paymentMethod' | 'sale.decisiveTransaction' | 'sale.decisiveTransaction.paymentMethod' | 'tariffs.tariff' | 'tariffs.tariff.type' | 'tariffs.tariff.group' | 'tariffs.saleTariff' | 'tariffs.saleTariff.tariff' | 'tariffs.saleTariff.tariff.type' | 'tariffs.saleTariff.tariff.group' | 'additionals.additional' | 'additionals.saleAdditional' | 'resources.saleResource.resource'>}
     * @memberof VouchersApiGetReservationVouchers
     */
    readonly include?: Array<GetReservationVouchersIncludeEnum>

    /**
     * 
     * @type {'all' | 'active' | 'revoked'}
     * @memberof VouchersApiGetReservationVouchers
     */
    readonly revokedStatus?: GetReservationVouchersRevokedStatusEnum

    /**
     * 
     * @type {'all' | 'open' | 'consumed' | 'exceptional'}
     * @memberof VouchersApiGetReservationVouchers
     */
    readonly consumedStatus?: GetReservationVouchersConsumedStatusEnum

    /**
     * 
     * @type {'oldest' | 'recent' | 'consumption'}
     * @memberof VouchersApiGetReservationVouchers
     */
    readonly sortBy?: GetReservationVouchersSortByEnum
}

/**
 * Request parameters for getSaleVouchers operation in VouchersApi.
 * @export
 * @interface VouchersApiGetSaleVouchersRequest
 */
export interface VouchersApiGetSaleVouchersRequest {
    /**
     * 
     * @type {string}
     * @memberof VouchersApiGetSaleVouchers
     */
    readonly saleId: string

    /**
     * 
     * @type {number}
     * @memberof VouchersApiGetSaleVouchers
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof VouchersApiGetSaleVouchers
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<'product' | 'sale' | 'sale.customer' | 'sale.order' | 'sale.order.decisiveTransaction' | 'sale.order.decisiveTransaction.paymentMethod' | 'sale.decisiveTransaction' | 'sale.decisiveTransaction.paymentMethod' | 'tariffs.tariff' | 'tariffs.tariff.type' | 'tariffs.tariff.group' | 'tariffs.saleTariff' | 'tariffs.saleTariff.tariff' | 'tariffs.saleTariff.tariff.type' | 'tariffs.saleTariff.tariff.group' | 'additionals.additional' | 'additionals.saleAdditional' | 'resources.saleResource.resource'>}
     * @memberof VouchersApiGetSaleVouchers
     */
    readonly include?: Array<GetSaleVouchersIncludeEnum>

    /**
     * 
     * @type {'all' | 'active' | 'revoked'}
     * @memberof VouchersApiGetSaleVouchers
     */
    readonly revokedStatus?: GetSaleVouchersRevokedStatusEnum

    /**
     * 
     * @type {'all' | 'open' | 'consumed' | 'exceptional'}
     * @memberof VouchersApiGetSaleVouchers
     */
    readonly consumedStatus?: GetSaleVouchersConsumedStatusEnum

    /**
     * 
     * @type {'oldest' | 'recent' | 'consumption'}
     * @memberof VouchersApiGetSaleVouchers
     */
    readonly sortBy?: GetSaleVouchersSortByEnum
}

/**
 * Request parameters for getVoucher operation in VouchersApi.
 * @export
 * @interface VouchersApiGetVoucherRequest
 */
export interface VouchersApiGetVoucherRequest {
    /**
     * 
     * @type {string}
     * @memberof VouchersApiGetVoucher
     */
    readonly voucherId: string

    /**
     * 
     * @type {Array<'product' | 'sale' | 'sale.customer' | 'sale.order' | 'sale.order.decisiveTransaction' | 'sale.order.decisiveTransaction.paymentMethod' | 'sale.decisiveTransaction' | 'sale.decisiveTransaction.paymentMethod' | 'tariffs.tariff' | 'tariffs.tariff.type' | 'tariffs.tariff.group' | 'tariffs.saleTariff' | 'tariffs.saleTariff.tariff' | 'tariffs.saleTariff.tariff.type' | 'tariffs.saleTariff.tariff.group' | 'additionals.additional' | 'additionals.saleAdditional' | 'resources.saleResource.resource'>}
     * @memberof VouchersApiGetVoucher
     */
    readonly include?: Array<GetVoucherIncludeEnum>
}

/**
 * Request parameters for getVoucherPdf operation in VouchersApi.
 * @export
 * @interface VouchersApiGetVoucherPdfRequest
 */
export interface VouchersApiGetVoucherPdfRequest {
    /**
     * 
     * @type {string}
     * @memberof VouchersApiGetVoucherPdf
     */
    readonly voucherId: string
}

/**
 * Request parameters for sendSaleVouchersToCustomer operation in VouchersApi.
 * @export
 * @interface VouchersApiSendSaleVouchersToCustomerRequest
 */
export interface VouchersApiSendSaleVouchersToCustomerRequest {
    /**
     * 
     * @type {string}
     * @memberof VouchersApiSendSaleVouchersToCustomer
     */
    readonly saleId: string
}

/**
 * Request parameters for sendVoucher operation in VouchersApi.
 * @export
 * @interface VouchersApiSendVoucherRequest
 */
export interface VouchersApiSendVoucherRequest {
    /**
     * 
     * @type {string}
     * @memberof VouchersApiSendVoucher
     */
    readonly voucherId: string

    /**
     * 
     * @type {SendVoucherParams}
     * @memberof VouchersApiSendVoucher
     */
    readonly sendVoucherParams: SendVoucherParams
}

/**
 * VouchersApi - object-oriented interface
 * @export
 * @class VouchersApi
 * @extends {BaseAPI}
 */
export class VouchersApi extends BaseAPI {
    /**
     * 
     * @param {VouchersApiConsumeAppQrCodeVoucherRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public consumeAppQrCodeVoucher(requestParameters: VouchersApiConsumeAppQrCodeVoucherRequest, options?: RawAxiosRequestConfig) {
        return VouchersApiFp(this.configuration).consumeAppQrCodeVoucher(requestParameters.appId, requestParameters.consumeQrCodeVoucherParams, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VouchersApiConsumeVoucherRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public consumeVoucher(requestParameters: VouchersApiConsumeVoucherRequest, options?: RawAxiosRequestConfig) {
        return VouchersApiFp(this.configuration).consumeVoucher(requestParameters.voucherId, requestParameters.consumeVoucherParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VouchersApiConsumeVoucherExceptionallyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public consumeVoucherExceptionally(requestParameters: VouchersApiConsumeVoucherExceptionallyRequest, options?: RawAxiosRequestConfig) {
        return VouchersApiFp(this.configuration).consumeVoucherExceptionally(requestParameters.voucherId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VouchersApiGetAppQrCodeVoucherRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public getAppQrCodeVoucher(requestParameters: VouchersApiGetAppQrCodeVoucherRequest, options?: RawAxiosRequestConfig) {
        return VouchersApiFp(this.configuration).getAppQrCodeVoucher(requestParameters.appId, requestParameters.content, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VouchersApiGetAppVouchersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public getAppVouchers(requestParameters: VouchersApiGetAppVouchersRequest, options?: RawAxiosRequestConfig) {
        return VouchersApiFp(this.configuration).getAppVouchers(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.since, requestParameters.until, requestParameters.revokedStatus, requestParameters.consumedStatus, requestParameters.searchStatus, requestParameters.searchTerm, requestParameters.sortBy, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VouchersApiGetDirectSaleVouchersPdfRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public getDirectSaleVouchersPdf(requestParameters: VouchersApiGetDirectSaleVouchersPdfRequest, options?: RawAxiosRequestConfig) {
        return VouchersApiFp(this.configuration).getDirectSaleVouchersPdf(requestParameters.saleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VouchersApiGetReservationVouchersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public getReservationVouchers(requestParameters: VouchersApiGetReservationVouchersRequest, options?: RawAxiosRequestConfig) {
        return VouchersApiFp(this.configuration).getReservationVouchers(requestParameters.reservationId, requestParameters.offset, requestParameters.limit, requestParameters.include, requestParameters.revokedStatus, requestParameters.consumedStatus, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VouchersApiGetSaleVouchersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public getSaleVouchers(requestParameters: VouchersApiGetSaleVouchersRequest, options?: RawAxiosRequestConfig) {
        return VouchersApiFp(this.configuration).getSaleVouchers(requestParameters.saleId, requestParameters.offset, requestParameters.limit, requestParameters.include, requestParameters.revokedStatus, requestParameters.consumedStatus, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VouchersApiGetVoucherRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public getVoucher(requestParameters: VouchersApiGetVoucherRequest, options?: RawAxiosRequestConfig) {
        return VouchersApiFp(this.configuration).getVoucher(requestParameters.voucherId, requestParameters.include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VouchersApiGetVoucherPdfRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public getVoucherPdf(requestParameters: VouchersApiGetVoucherPdfRequest, options?: RawAxiosRequestConfig) {
        return VouchersApiFp(this.configuration).getVoucherPdf(requestParameters.voucherId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VouchersApiSendSaleVouchersToCustomerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public sendSaleVouchersToCustomer(requestParameters: VouchersApiSendSaleVouchersToCustomerRequest, options?: RawAxiosRequestConfig) {
        return VouchersApiFp(this.configuration).sendSaleVouchersToCustomer(requestParameters.saleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VouchersApiSendVoucherRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public sendVoucher(requestParameters: VouchersApiSendVoucherRequest, options?: RawAxiosRequestConfig) {
        return VouchersApiFp(this.configuration).sendVoucher(requestParameters.voucherId, requestParameters.sendVoucherParams, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ConsumeAppQrCodeVoucherIncludeEnum = {
    Product: 'product',
    Sale: 'sale',
    SaleCustomer: 'sale.customer',
    SaleOrder: 'sale.order',
    SaleOrderDecisiveTransaction: 'sale.order.decisiveTransaction',
    SaleOrderDecisiveTransactionPaymentMethod: 'sale.order.decisiveTransaction.paymentMethod',
    SaleDecisiveTransaction: 'sale.decisiveTransaction',
    SaleDecisiveTransactionPaymentMethod: 'sale.decisiveTransaction.paymentMethod',
    TariffsTariff: 'tariffs.tariff',
    TariffsTariffType: 'tariffs.tariff.type',
    TariffsTariffGroup: 'tariffs.tariff.group',
    TariffsSaleTariff: 'tariffs.saleTariff',
    TariffsSaleTariffTariff: 'tariffs.saleTariff.tariff',
    TariffsSaleTariffTariffType: 'tariffs.saleTariff.tariff.type',
    TariffsSaleTariffTariffGroup: 'tariffs.saleTariff.tariff.group',
    AdditionalsAdditional: 'additionals.additional',
    AdditionalsSaleAdditional: 'additionals.saleAdditional',
    ResourcesSaleResourceResource: 'resources.saleResource.resource'
} as const;
export type ConsumeAppQrCodeVoucherIncludeEnum = typeof ConsumeAppQrCodeVoucherIncludeEnum[keyof typeof ConsumeAppQrCodeVoucherIncludeEnum];
/**
 * @export
 */
export const GetAppQrCodeVoucherIncludeEnum = {
    Product: 'product',
    Sale: 'sale',
    SaleCustomer: 'sale.customer',
    SaleOrder: 'sale.order',
    SaleOrderDecisiveTransaction: 'sale.order.decisiveTransaction',
    SaleOrderDecisiveTransactionPaymentMethod: 'sale.order.decisiveTransaction.paymentMethod',
    SaleDecisiveTransaction: 'sale.decisiveTransaction',
    SaleDecisiveTransactionPaymentMethod: 'sale.decisiveTransaction.paymentMethod',
    TariffsTariff: 'tariffs.tariff',
    TariffsTariffType: 'tariffs.tariff.type',
    TariffsTariffGroup: 'tariffs.tariff.group',
    TariffsSaleTariff: 'tariffs.saleTariff',
    TariffsSaleTariffTariff: 'tariffs.saleTariff.tariff',
    TariffsSaleTariffTariffType: 'tariffs.saleTariff.tariff.type',
    TariffsSaleTariffTariffGroup: 'tariffs.saleTariff.tariff.group',
    AdditionalsAdditional: 'additionals.additional',
    AdditionalsSaleAdditional: 'additionals.saleAdditional',
    ResourcesSaleResourceResource: 'resources.saleResource.resource'
} as const;
export type GetAppQrCodeVoucherIncludeEnum = typeof GetAppQrCodeVoucherIncludeEnum[keyof typeof GetAppQrCodeVoucherIncludeEnum];
/**
 * @export
 */
export const GetAppVouchersRevokedStatusEnum = {
    All: 'all',
    Active: 'active',
    Revoked: 'revoked'
} as const;
export type GetAppVouchersRevokedStatusEnum = typeof GetAppVouchersRevokedStatusEnum[keyof typeof GetAppVouchersRevokedStatusEnum];
/**
 * @export
 */
export const GetAppVouchersConsumedStatusEnum = {
    All: 'all',
    Open: 'open',
    Consumed: 'consumed',
    Exceptional: 'exceptional'
} as const;
export type GetAppVouchersConsumedStatusEnum = typeof GetAppVouchersConsumedStatusEnum[keyof typeof GetAppVouchersConsumedStatusEnum];
/**
 * @export
 */
export const GetAppVouchersSearchStatusEnum = {
    All: 'all',
    Open: 'open',
    Consumed: 'consumed',
    Revoked: 'revoked'
} as const;
export type GetAppVouchersSearchStatusEnum = typeof GetAppVouchersSearchStatusEnum[keyof typeof GetAppVouchersSearchStatusEnum];
/**
 * @export
 */
export const GetAppVouchersSortByEnum = {
    Oldest: 'oldest',
    Recent: 'recent',
    Consumption: 'consumption'
} as const;
export type GetAppVouchersSortByEnum = typeof GetAppVouchersSortByEnum[keyof typeof GetAppVouchersSortByEnum];
/**
 * @export
 */
export const GetAppVouchersIncludeEnum = {
    Product: 'product',
    Sale: 'sale',
    SaleCustomer: 'sale.customer',
    SaleOrder: 'sale.order',
    SaleOrderDecisiveTransaction: 'sale.order.decisiveTransaction',
    SaleOrderDecisiveTransactionPaymentMethod: 'sale.order.decisiveTransaction.paymentMethod',
    SaleDecisiveTransaction: 'sale.decisiveTransaction',
    SaleDecisiveTransactionPaymentMethod: 'sale.decisiveTransaction.paymentMethod',
    TariffsTariff: 'tariffs.tariff',
    TariffsTariffType: 'tariffs.tariff.type',
    TariffsTariffGroup: 'tariffs.tariff.group',
    TariffsSaleTariff: 'tariffs.saleTariff',
    TariffsSaleTariffTariff: 'tariffs.saleTariff.tariff',
    TariffsSaleTariffTariffType: 'tariffs.saleTariff.tariff.type',
    TariffsSaleTariffTariffGroup: 'tariffs.saleTariff.tariff.group',
    AdditionalsAdditional: 'additionals.additional',
    AdditionalsSaleAdditional: 'additionals.saleAdditional',
    ResourcesSaleResourceResource: 'resources.saleResource.resource'
} as const;
export type GetAppVouchersIncludeEnum = typeof GetAppVouchersIncludeEnum[keyof typeof GetAppVouchersIncludeEnum];
/**
 * @export
 */
export const GetReservationVouchersIncludeEnum = {
    Product: 'product',
    Sale: 'sale',
    SaleCustomer: 'sale.customer',
    SaleOrder: 'sale.order',
    SaleOrderDecisiveTransaction: 'sale.order.decisiveTransaction',
    SaleOrderDecisiveTransactionPaymentMethod: 'sale.order.decisiveTransaction.paymentMethod',
    SaleDecisiveTransaction: 'sale.decisiveTransaction',
    SaleDecisiveTransactionPaymentMethod: 'sale.decisiveTransaction.paymentMethod',
    TariffsTariff: 'tariffs.tariff',
    TariffsTariffType: 'tariffs.tariff.type',
    TariffsTariffGroup: 'tariffs.tariff.group',
    TariffsSaleTariff: 'tariffs.saleTariff',
    TariffsSaleTariffTariff: 'tariffs.saleTariff.tariff',
    TariffsSaleTariffTariffType: 'tariffs.saleTariff.tariff.type',
    TariffsSaleTariffTariffGroup: 'tariffs.saleTariff.tariff.group',
    AdditionalsAdditional: 'additionals.additional',
    AdditionalsSaleAdditional: 'additionals.saleAdditional',
    ResourcesSaleResourceResource: 'resources.saleResource.resource'
} as const;
export type GetReservationVouchersIncludeEnum = typeof GetReservationVouchersIncludeEnum[keyof typeof GetReservationVouchersIncludeEnum];
/**
 * @export
 */
export const GetReservationVouchersRevokedStatusEnum = {
    All: 'all',
    Active: 'active',
    Revoked: 'revoked'
} as const;
export type GetReservationVouchersRevokedStatusEnum = typeof GetReservationVouchersRevokedStatusEnum[keyof typeof GetReservationVouchersRevokedStatusEnum];
/**
 * @export
 */
export const GetReservationVouchersConsumedStatusEnum = {
    All: 'all',
    Open: 'open',
    Consumed: 'consumed',
    Exceptional: 'exceptional'
} as const;
export type GetReservationVouchersConsumedStatusEnum = typeof GetReservationVouchersConsumedStatusEnum[keyof typeof GetReservationVouchersConsumedStatusEnum];
/**
 * @export
 */
export const GetReservationVouchersSortByEnum = {
    Oldest: 'oldest',
    Recent: 'recent',
    Consumption: 'consumption'
} as const;
export type GetReservationVouchersSortByEnum = typeof GetReservationVouchersSortByEnum[keyof typeof GetReservationVouchersSortByEnum];
/**
 * @export
 */
export const GetSaleVouchersIncludeEnum = {
    Product: 'product',
    Sale: 'sale',
    SaleCustomer: 'sale.customer',
    SaleOrder: 'sale.order',
    SaleOrderDecisiveTransaction: 'sale.order.decisiveTransaction',
    SaleOrderDecisiveTransactionPaymentMethod: 'sale.order.decisiveTransaction.paymentMethod',
    SaleDecisiveTransaction: 'sale.decisiveTransaction',
    SaleDecisiveTransactionPaymentMethod: 'sale.decisiveTransaction.paymentMethod',
    TariffsTariff: 'tariffs.tariff',
    TariffsTariffType: 'tariffs.tariff.type',
    TariffsTariffGroup: 'tariffs.tariff.group',
    TariffsSaleTariff: 'tariffs.saleTariff',
    TariffsSaleTariffTariff: 'tariffs.saleTariff.tariff',
    TariffsSaleTariffTariffType: 'tariffs.saleTariff.tariff.type',
    TariffsSaleTariffTariffGroup: 'tariffs.saleTariff.tariff.group',
    AdditionalsAdditional: 'additionals.additional',
    AdditionalsSaleAdditional: 'additionals.saleAdditional',
    ResourcesSaleResourceResource: 'resources.saleResource.resource'
} as const;
export type GetSaleVouchersIncludeEnum = typeof GetSaleVouchersIncludeEnum[keyof typeof GetSaleVouchersIncludeEnum];
/**
 * @export
 */
export const GetSaleVouchersRevokedStatusEnum = {
    All: 'all',
    Active: 'active',
    Revoked: 'revoked'
} as const;
export type GetSaleVouchersRevokedStatusEnum = typeof GetSaleVouchersRevokedStatusEnum[keyof typeof GetSaleVouchersRevokedStatusEnum];
/**
 * @export
 */
export const GetSaleVouchersConsumedStatusEnum = {
    All: 'all',
    Open: 'open',
    Consumed: 'consumed',
    Exceptional: 'exceptional'
} as const;
export type GetSaleVouchersConsumedStatusEnum = typeof GetSaleVouchersConsumedStatusEnum[keyof typeof GetSaleVouchersConsumedStatusEnum];
/**
 * @export
 */
export const GetSaleVouchersSortByEnum = {
    Oldest: 'oldest',
    Recent: 'recent',
    Consumption: 'consumption'
} as const;
export type GetSaleVouchersSortByEnum = typeof GetSaleVouchersSortByEnum[keyof typeof GetSaleVouchersSortByEnum];
/**
 * @export
 */
export const GetVoucherIncludeEnum = {
    Product: 'product',
    Sale: 'sale',
    SaleCustomer: 'sale.customer',
    SaleOrder: 'sale.order',
    SaleOrderDecisiveTransaction: 'sale.order.decisiveTransaction',
    SaleOrderDecisiveTransactionPaymentMethod: 'sale.order.decisiveTransaction.paymentMethod',
    SaleDecisiveTransaction: 'sale.decisiveTransaction',
    SaleDecisiveTransactionPaymentMethod: 'sale.decisiveTransaction.paymentMethod',
    TariffsTariff: 'tariffs.tariff',
    TariffsTariffType: 'tariffs.tariff.type',
    TariffsTariffGroup: 'tariffs.tariff.group',
    TariffsSaleTariff: 'tariffs.saleTariff',
    TariffsSaleTariffTariff: 'tariffs.saleTariff.tariff',
    TariffsSaleTariffTariffType: 'tariffs.saleTariff.tariff.type',
    TariffsSaleTariffTariffGroup: 'tariffs.saleTariff.tariff.group',
    AdditionalsAdditional: 'additionals.additional',
    AdditionalsSaleAdditional: 'additionals.saleAdditional',
    ResourcesSaleResourceResource: 'resources.saleResource.resource'
} as const;
export type GetVoucherIncludeEnum = typeof GetVoucherIncludeEnum[keyof typeof GetVoucherIncludeEnum];


/**
 * WebhooksApi - axios parameter creator
 * @export
 */
export const WebhooksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} appId 
         * @param {CreateWebhookParams} createWebhookParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppWebhook: async (appId: string, createWebhookParams: CreateWebhookParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('createAppWebhook', 'appId', appId)
            // verify required parameter 'createWebhookParams' is not null or undefined
            assertParamExists('createAppWebhook', 'createWebhookParams', createWebhookParams)
            const localVarPath = `/apps/{appId}/webhooks`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWebhookParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} webhookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWebhook: async (webhookId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'webhookId' is not null or undefined
            assertParamExists('deleteWebhook', 'webhookId', webhookId)
            const localVarPath = `/webhooks/{webhookId}`
                .replace(`{${"webhookId"}}`, encodeURIComponent(String(webhookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<string>} [ids] 
         * @param {Array<AppEventTypeEnum>} [eventTypes] 
         * @param {GetAppWebhooksEnabledStatusEnum} [enabledStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppWebhooks: async (appId: string, offset?: number, limit?: number, ids?: Array<string>, eventTypes?: Array<AppEventTypeEnum>, enabledStatus?: GetAppWebhooksEnabledStatusEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getAppWebhooks', 'appId', appId)
            const localVarPath = `/apps/{appId}/webhooks`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids.join(COLLECTION_FORMATS.csv);
            }

            if (eventTypes) {
                localVarQueryParameter['eventTypes'] = eventTypes.join(COLLECTION_FORMATS.csv);
            }

            if (enabledStatus !== undefined) {
                localVarQueryParameter['enabledStatus'] = enabledStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} webhookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebhook: async (webhookId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'webhookId' is not null or undefined
            assertParamExists('getWebhook', 'webhookId', webhookId)
            const localVarPath = `/webhooks/{webhookId}`
                .replace(`{${"webhookId"}}`, encodeURIComponent(String(webhookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication UserAuthBearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication UserAuthClientId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Id", configuration)

            // authentication UserAuthBasic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication UserAuthClientSecret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Client-Secret", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebhooksApi - functional programming interface
 * @export
 */
export const WebhooksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebhooksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} appId 
         * @param {CreateWebhookParams} createWebhookParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAppWebhook(appId: string, createWebhookParams: CreateWebhookParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Webhook>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAppWebhook(appId, createWebhookParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebhooksApi.createAppWebhook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} webhookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWebhook(webhookId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWebhook(webhookId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebhooksApi.deleteWebhook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} appId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {Array<string>} [ids] 
         * @param {Array<AppEventTypeEnum>} [eventTypes] 
         * @param {GetAppWebhooksEnabledStatusEnum} [enabledStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppWebhooks(appId: string, offset?: number, limit?: number, ids?: Array<string>, eventTypes?: Array<AppEventTypeEnum>, enabledStatus?: GetAppWebhooksEnabledStatusEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Webhook>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppWebhooks(appId, offset, limit, ids, eventTypes, enabledStatus, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebhooksApi.getAppWebhooks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} webhookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWebhook(webhookId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Webhook>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWebhook(webhookId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebhooksApi.getWebhook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WebhooksApi - factory interface
 * @export
 */
export const WebhooksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebhooksApiFp(configuration)
    return {
        /**
         * 
         * @param {WebhooksApiCreateAppWebhookRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppWebhook(requestParameters: WebhooksApiCreateAppWebhookRequest, options?: RawAxiosRequestConfig): AxiosPromise<Webhook> {
            return localVarFp.createAppWebhook(requestParameters.appId, requestParameters.createWebhookParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WebhooksApiDeleteWebhookRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWebhook(requestParameters: WebhooksApiDeleteWebhookRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteWebhook(requestParameters.webhookId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WebhooksApiGetAppWebhooksRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppWebhooks(requestParameters: WebhooksApiGetAppWebhooksRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Webhook>> {
            return localVarFp.getAppWebhooks(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.ids, requestParameters.eventTypes, requestParameters.enabledStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WebhooksApiGetWebhookRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebhook(requestParameters: WebhooksApiGetWebhookRequest, options?: RawAxiosRequestConfig): AxiosPromise<Webhook> {
            return localVarFp.getWebhook(requestParameters.webhookId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createAppWebhook operation in WebhooksApi.
 * @export
 * @interface WebhooksApiCreateAppWebhookRequest
 */
export interface WebhooksApiCreateAppWebhookRequest {
    /**
     * 
     * @type {string}
     * @memberof WebhooksApiCreateAppWebhook
     */
    readonly appId: string

    /**
     * 
     * @type {CreateWebhookParams}
     * @memberof WebhooksApiCreateAppWebhook
     */
    readonly createWebhookParams: CreateWebhookParams
}

/**
 * Request parameters for deleteWebhook operation in WebhooksApi.
 * @export
 * @interface WebhooksApiDeleteWebhookRequest
 */
export interface WebhooksApiDeleteWebhookRequest {
    /**
     * 
     * @type {string}
     * @memberof WebhooksApiDeleteWebhook
     */
    readonly webhookId: string
}

/**
 * Request parameters for getAppWebhooks operation in WebhooksApi.
 * @export
 * @interface WebhooksApiGetAppWebhooksRequest
 */
export interface WebhooksApiGetAppWebhooksRequest {
    /**
     * 
     * @type {string}
     * @memberof WebhooksApiGetAppWebhooks
     */
    readonly appId: string

    /**
     * 
     * @type {number}
     * @memberof WebhooksApiGetAppWebhooks
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof WebhooksApiGetAppWebhooks
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof WebhooksApiGetAppWebhooks
     */
    readonly ids?: Array<string>

    /**
     * 
     * @type {Array<AppEventTypeEnum>}
     * @memberof WebhooksApiGetAppWebhooks
     */
    readonly eventTypes?: Array<AppEventTypeEnum>

    /**
     * 
     * @type {'all' | 'enabled' | 'disabled'}
     * @memberof WebhooksApiGetAppWebhooks
     */
    readonly enabledStatus?: GetAppWebhooksEnabledStatusEnum
}

/**
 * Request parameters for getWebhook operation in WebhooksApi.
 * @export
 * @interface WebhooksApiGetWebhookRequest
 */
export interface WebhooksApiGetWebhookRequest {
    /**
     * 
     * @type {string}
     * @memberof WebhooksApiGetWebhook
     */
    readonly webhookId: string
}

/**
 * WebhooksApi - object-oriented interface
 * @export
 * @class WebhooksApi
 * @extends {BaseAPI}
 */
export class WebhooksApi extends BaseAPI {
    /**
     * 
     * @param {WebhooksApiCreateAppWebhookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public createAppWebhook(requestParameters: WebhooksApiCreateAppWebhookRequest, options?: RawAxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).createAppWebhook(requestParameters.appId, requestParameters.createWebhookParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebhooksApiDeleteWebhookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public deleteWebhook(requestParameters: WebhooksApiDeleteWebhookRequest, options?: RawAxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).deleteWebhook(requestParameters.webhookId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebhooksApiGetAppWebhooksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public getAppWebhooks(requestParameters: WebhooksApiGetAppWebhooksRequest, options?: RawAxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).getAppWebhooks(requestParameters.appId, requestParameters.offset, requestParameters.limit, requestParameters.ids, requestParameters.eventTypes, requestParameters.enabledStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebhooksApiGetWebhookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public getWebhook(requestParameters: WebhooksApiGetWebhookRequest, options?: RawAxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).getWebhook(requestParameters.webhookId, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAppWebhooksEnabledStatusEnum = {
    All: 'all',
    Enabled: 'enabled',
    Disabled: 'disabled'
} as const;
export type GetAppWebhooksEnabledStatusEnum = typeof GetAppWebhooksEnabledStatusEnum[keyof typeof GetAppWebhooksEnabledStatusEnum];


