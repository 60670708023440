import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import PageLayout from '@/layouts/PageLayout';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import FilterProvider from '@/contexts/FilterContext';
import DirectSaleList from '@/components/DirectSales';

import FilterFields from '@/components/DirectSales/DirectSalesFilterFields';
import { useDirectSalesFilterSchema } from './useDirectSalesFilterSchema';

export const DirectSales = () => {
  const { track } = useAnalytics();
  const { t } = useTranslation(['directSales', 'sales']);
  const filterSchema = useDirectSalesFilterSchema(t);

  useEffect(() => {
    track('directSales/access');
  }, []);

  return (
    <FilterProvider schema={filterSchema} contextName='directSales'>
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>

      <PageLayout
        title={t('layout_page_title')}
        slotProps={{
          searchInput: {
            placeholder: t('filter.searchbox.placeholder'),
          },
        }}
        slots={{ FilterFields }}
      >
        <DirectSaleList />
      </PageLayout>
    </FilterProvider>
  );
};

export default DirectSales;
