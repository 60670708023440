import '@/services/i18n';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { setDefaultOptions } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { HelmetProvider } from 'react-helmet-async';

import AccountProvider from './contexts/AccountContext';
import AuthProvider from './contexts/Auth/AuthProvider';
import QueryProvider from './contexts/QueryContext';
import UIProvider from './contexts/UIContext';
import SnackbarProvider from './Providers/SnackbarProvider';
import useRouter from './routes';
import theme from './theme';
import { RouterProvider } from 'react-router-dom';

console.log(`Planne Dashboard Version: ${import.meta.env.PACKAGE_VERSION}`);

setDefaultOptions({ locale: ptBR });
export const App = () => {
  const router = useRouter();
  return (
    <div className='App'>
      <HelmetProvider>
        <QueryProvider>
          <AuthProvider>
            <AccountProvider>
              <ThemeProvider theme={theme}>
                <SnackbarProvider>
                  <UIProvider>
                    <CssBaseline />
                    <RouterProvider
                      router={router}
                      future={{
                        v7_startTransition: true,
                      }}
                    />
                  </UIProvider>
                </SnackbarProvider>
              </ThemeProvider>
            </AccountProvider>
          </AuthProvider>
        </QueryProvider>
      </HelmetProvider>
    </div>
  );
};

export default App;
