import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import { SwapHorizOutlined } from '@mui/icons-material';
import { Box, CircularProgress, Grid } from '@mui/material';
import { useCallback, useLayoutEffect, useState } from 'react';

import { colors } from '@/theme';
import useAccount from '@/hooks/useAccount';
import EmptyPage from '@/components/EmptyPage';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import { useSellerApi } from '@/hooks/useSellerApi';
import RefundTable from '@/components/RefundRequests/Table';
import { RefundRequest, RefundRequestsApiGetAppRefundRequestsRequest } from '@/services/SellerApi';
import useFilter from '@/hooks/useFilter';

export const RefundRequestList = () => {
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const { t } = useTranslation(['refundRequests']);
  const { refundRequestsApi } = useSellerApi();
  const { selectedAccount } = useAccount();
  const { appliedFilters, search, isFiltersLoaded } = useFilter();

  const [loading, setLoading] = useState(true);
  const [totalRefunds, setTotalRefunds] = useState(0);
  const [refunds, setRefunds] = useState<RefundRequest[]>([]);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 20 });

  const loadRefundRequests = useCallback(async () => {
    const limit = paginationModel.pageSize;
    const offset = paginationModel.page * paginationModel.pageSize;

    const payload: RefundRequestsApiGetAppRefundRequestsRequest = {
      appId: selectedAccount?.appId ?? '',
      include: ['sale', 'sale.aggregatedVouchers', 'sale.customer'],
      limit,
      offset,
      sortBy: 'recent',
      ...appliedFilters,
      ...(search ? { search } : {}),
    };

    try {
      setLoading(true);
      const { data, headers } = await refundRequestsApi.getAppRefundRequests(payload);

      setRefunds(data);
      setTotalRefunds(parseInt(headers['x-pagination-total-count'] ?? '0'));
      track('refundRequests/retrieveRequestRefundSuccess', { payload });
    } catch (error) {
      enqueueSnackbar(t('could_not_load_request_refund'), { variant: 'error' });
      track('refundRequests/retrieveRequestRefundFail', { payload });
    } finally {
      setLoading(false);
    }
  }, [paginationModel, selectedAccount?.appId, refundRequestsApi, track, search]);

  useLayoutEffect(() => {
    if (isFiltersLoaded) {
      loadRefundRequests();
    }
  }, [paginationModel, appliedFilters, search, isFiltersLoaded]);

  return (
    <>
      {refunds.length ? (
        <RefundTable
          data={refunds}
          loading={loading}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          totalRefunds={totalRefunds}
        />
      ) : null}

      {!refunds.length && !loading ? (
        <Grid item xs={12} alignContent='center' justifyContent='center' height='100%'>
          <EmptyPage
            Icon={<SwapHorizOutlined fontSize='large' sx={{ width: 92, height: 92, color: colors.gray[800] }} />}
            description={
              Object.keys(appliedFilters).length
                ? t('empty.no_filtered_refund_requests_found')
                : t('empty.no_refund_requests_found')
            }
            buttonText={t('empty.redirect_button')}
            onClick={() => navigate('../sales')}
          />
        </Grid>
      ) : null}

      {!refunds.length && loading ? (
        <Grid item xs={12} textAlign='center'>
          <Box display='flex' alignItems='center' justifyContent='center' height='60vh' width='100%'>
            <CircularProgress color='primary' />
          </Box>
        </Grid>
      ) : null}

      <Outlet
        context={{
          refreshRefundRequestList: loadRefundRequests,
        }}
      />
    </>
  );
};

export default RefundRequestList;
