import getMinutesHoursDaysFromMinutes from '@/features/agenda/utils/getMinutesHoursDaysFromMinutes';

const buildMinutesHoursDaysString = (totalMinutes: number): string => {
  const time = getMinutesHoursDaysFromMinutes(totalMinutes);
  const { days, hours, minutes } = time;
  const daysString = days ? `${days}d` : '';
  const hoursString = hours ? `${hours}hrs` : '';
  const minutesString = minutes ? `${minutes}min` : '';

  return `${daysString} ${hoursString} ${minutesString}`;
};

export default buildMinutesHoursDaysString;
