import Checkbox from '@/components/Form/Checkbox';
import ProductSellingModeChip from '@/features/products/components/ProductSellingModeChip';
import { Product } from '@/services/SellerApi';
import { Paper, Typography } from '@mui/material';
import { Stack } from '@mui/system';

interface Props {
  product: Product;
  isSelected?: boolean;
  onClick?: (product: Product) => void;
  disabled?: boolean;
}

const ProductCard = (props: Props) => {
  const { product, isSelected = false, onClick = () => {}, disabled } = props;

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (!disabled) {
      onClick(product);
    }
  };

  return (
    <Paper
      component={Stack}
      elevation={3}
      sx={{ p: '0.5rem', cursor: 'pointer' }}
      justifyContent='space-between'
      flexDirection='row'
      onClick={(e) => handleClick(e)}
    >
      <Checkbox
        disableRipple
        checked={isSelected}
        label={<Typography variant='smallBold'>{product.internalName ?? product.name}</Typography>}
        disabled={disabled}
      />

      <ProductSellingModeChip sellingMode={product.sellingMode} />
    </Paper>
  );
};

export default ProductCard;
