import { forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';

/**
 * DO NOT IMPORT THIS COMPONENT DIRECTLY.
 * If you want a React Router Dom Link, just use MUI link as usual, it will render this component by default.
 * The same thing happens if you want a button link component, just use MUI Button component and pass href prop.
 */

export const LinkBehavior = forwardRef<HTMLAnchorElement, Omit<LinkProps, 'to'> & { href: LinkProps['to'] }>(
  (props, ref) => {
    const { href, ...rest } = props;
    return <Link aria-label='custom-react-router-link' ref={ref} to={href} {...rest} />;
  },
);

export default LinkBehavior;
