import { Skeleton } from '@mui/material';
import { Fragment } from 'react';

import { StyledCard } from '@/features/agenda/components/SchedulingCalendar/AgendaWeeklyView/WeeklySlot/styles';

type Props = {
  repeat?: number;
};

const WeeklySlotSkeleton = (props: Props) => {
  const { repeat = 1 } = props;

  if (repeat) {
    return (
      <Fragment>
        {[...Array(repeat)].map((_, index) => (
          <StyledCard.Root key={index}>
            <Skeleton variant='text' width='100%' height={20} />
            <StyledCard.Items>
              <Skeleton variant='text' width='100%' height={20} />
              <Skeleton variant='text' width='100%' height={20} />
            </StyledCard.Items>
          </StyledCard.Root>
        ))}
      </Fragment>
    );
  }

  return null;
};

export default WeeklySlotSkeleton;
