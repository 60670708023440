import { StepIconProps } from '@mui/material/StepIcon';
import { StepCircle, StyledStep, StyledStepConnector, StyledStepLabel, StyledStepper } from './styles';

function StepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <StepCircle completed={completed} active={active} className={className}>
      {props.icon}
    </StepCircle>
  );
}

interface StepperProps {
  steps: number[];
  activeStep?: number;
}

export const Stepper = (props: StepperProps) => {
  const { steps, activeStep } = props;

  return (
    <StyledStepper
      alternativeLabel
      activeStep={activeStep}
      connector={<StyledStepConnector />}
      sx={{ alignItems: 'center' }}
    >
      {steps.map((label) => (
        <StyledStep key={label}>
          <StyledStepLabel StepIconComponent={StepIcon} />
        </StyledStep>
      ))}
    </StyledStepper>
  );
};

export default Stepper;
