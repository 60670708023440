import { styled } from '@mui/material/styles';

export const Flag = styled('img')(() => ({
  width: '24px',
}));

export const InputAdornmentFlag = styled('img')(() => ({
  width: '35px',
}));

export const BrandList = styled('ul')(() => ({
  padding: '0',
  margin: '0',
  listStyle: 'none',
}));

export const Brand = styled('li')(() => ({
  marginTop: '8px',
  marginRight: '3px',
  display: 'inline-block',
}));
