/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Planne Seller API
 * OpenAPI spec version: 0.1.0
 */

export type AvailabilityTypeEnum = typeof AvailabilityTypeEnum[keyof typeof AvailabilityTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AvailabilityTypeEnum = {
  per_app: 'per_app',
  per_products: 'per_products',
  per_tariffs: 'per_tariffs',
  per_product_tariff_types: 'per_product_tariff_types',
  per_additionals: 'per_additionals',
} as const;
