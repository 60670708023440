import { zodResolver } from '@hookform/resolvers/zod';
import { Grid, radioClasses } from '@mui/material';
import { IconDeviceFloppy } from '@tabler/icons-react';
import { X } from '@untitled-ui/icons-react';
import { format } from 'date-fns';
import { enqueueSnackbar } from 'notistack';
import { Fragment, Suspense, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '@/components/Button';

import BackdropLoading from '@/components/BackdropLoading';
import CustomDatePicker from '@/components/Form/DatePicker';
import Select from '@/components/Form/Select';
import {
  CreateUpdateAvailabilityParams,
  TimesAndQuantities,
} from '@/features/agenda/features/availability/types/formTypes';
import intervalTimeOptions from '@/features/agenda/utils/intervalTimeOptions';
import useAccount from '@/hooks/useAccount';
import useRouteQueryParams from '@/hooks/useRouteQueryParams';
import { useSellerApi } from '@/hooks/useSellerApi';
import {
  Availability,
  AvailabilityTypeEnum,
  CreateAvailabilityParams,
  Recurrence,
  UpdateAvailabilityParams,
  UpdateRuleEnum,
} from '@/services/SellerApi';
import { dateWithTimezone } from '@/utils';

import AvailabilityTypePickers from './AvailabilityTypePickers';
import availabilityFormSchema from './schema';
import useErrorHandler from '@/hooks/useErrorHandler';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import { UpdateRulesProps } from '@/features/agenda/types';
import apiDateToDateObject from '@/utils/apiDateToDateObject';
import theme from '@/theme';
import RadioButtonsGroup, { RadioOptionProps } from '@/components/Form/RadioButtonsGroup';
import FormSession from './FormSession';
import RadioLabel from './RadioLabel';
import DynamicTime from './DynamicTime';
import generateDefaultTimesAndQuantities from '@/features/agenda/features/availability/utils/generateDefaultTimesAndQuantities';
import { TYPE_OF_TIMES } from '@/features/agenda/features/availability/utils/constants';
import RecurrenceButton from '@/features/agenda/components/RecurrenceModal/RecurrenceButton';
import { AnalyticsEvents } from '@/hooks/analytics/enum/analyticsEvents';

// const LazyRecurrenceSelector = lazy(() => import('@/features/agenda/components/RecurrenceSelector'));
import LazyRecurrenceSelector from '@/features/agenda/components/RecurrenceSelector';

type Props = {
  availability?: Availability;
};

export const AvailabilityForm = (params: Props) => {
  const { availability } = params;

  const navigate = useNavigate();
  const { t } = useTranslation(['availability']);
  const { errorHandler, reportError } = useErrorHandler();
  const { track } = useAnalytics();

  const { searchParams } = useRouteQueryParams();

  const { availabilitiesApi } = useSellerApi();

  const { selectedAccount } = useAccount();

  const [loading, setLoading] = useState(false);
  const [updateRuleConfirm, setUpdateRuleConfirm] = useState<boolean>(false);

  const isEdit = !!availability;

  const minimumDate = useMemo(() => {
    if (availability) {
      return apiDateToDateObject(availability.beginDate);
    }

    const today = new Date();

    return new Date(today.setDate(today.getDate() - 1));
  }, [availability]);

  const defaultType = useMemo(() => {
    if (availability) {
      return availability.type;
    }

    const typeParam = searchParams.get('type');
    const productIdsParam = searchParams.get('productIds');

    if (typeParam === AvailabilityTypeEnum.Products || productIdsParam) {
      return AvailabilityTypeEnum.Products;
    }

    return AvailabilityTypeEnum.App;
  }, [availability, searchParams]);

  const getDefaultBeginDate = (date: string | undefined) => {
    if (!date) return new Date();

    const dt = dateWithTimezone(date);

    return dt > new Date() ? dt : new Date();
  };

  const getDefaultRecurrence = (recurrence: string | undefined) => {
    if (!recurrence) return availability?.recurrence;

    const parsedRecurrence = JSON.parse(recurrence) as Recurrence;

    const recurrenceEndDateWithTimezone = dateWithTimezone(parsedRecurrence.endDate);

    if (recurrenceEndDateWithTimezone > new Date()) {
      return parsedRecurrence;
    }

    return undefined;
  };

  const defaultValues = useMemo(() => {
    const queryParams = Object.fromEntries(searchParams.entries());

    const hasAvailabilityTime = availability && availability.fromTime !== '00:00' && availability.toTime !== '23:59';

    const hasQueryTime = queryParams.fromTime && queryParams.toTime;

    return {
      type: defaultType,
      beginDate: getDefaultBeginDate(queryParams.beginDate),
      appliesAt: availability ? dateWithTimezone(availability?.appliesAt) : undefined,
      products: availability?.products ?? [],
      tariffs: availability?.tariffs ?? [],
      additionals: availability?.additionals ?? [],
      productTariffTypes: availability?.productTariffTypes ?? [],
      recurrence: getDefaultRecurrence(queryParams?.recurrence),
      timeType: hasAvailabilityTime || hasQueryTime ? TYPE_OF_TIMES.many : TYPE_OF_TIMES.unique,
      timesAndQuantities: generateDefaultTimesAndQuantities(
        availability,
        queryParams,
        selectedAccount?.app?.minuteSetInterval,
      ),
    };
  }, [searchParams, availability]);

  const { handleSubmit, formState, control, setValue, setError, watch } = useForm<CreateUpdateAvailabilityParams>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues,
    resolver: zodResolver(availabilityFormSchema(t, isEdit)),
  });

  const { errors } = formState;
  const formValues = watch();

  const emitError = (errorResponse: unknown) => {
    reportError(errorResponse);
    const { errors } = errorHandler(errorResponse);

    if (errors && Array.isArray(errors)) {
      errors.forEach((error) => {
        if (error.path) {
          const message = t(`form.error.fields.${error.path}.${error.rule}`);

          setError(error.path, {
            type: 'manual',
            message,
          });

          enqueueSnackbar(message, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(isEdit ? t('form.error.update') : t('form.error.create'), {
            variant: 'error',
          });
        }
      });
    }
  };

  const createAvailability = async (params: CreateAvailabilityParams[]) => {
    try {
      setLoading(true);

      const { data } = await availabilitiesApi.createAppAvailabilityBatch({
        appId: selectedAccount?.app?.id ?? '',
        createAvailabilityBatchParams: {
          availabilities: params,
        },
      });

      enqueueSnackbar(t('form.success.create'), { variant: 'success' });

      navigate(`../agenda/availabilities/${data[0].id}?viewData=availabilities`);
    } catch (error) {
      track(AnalyticsEvents.AVAILABILITY_CREATED, {
        payload: params,
        error,
      });
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  const updateAvailability = async (params: UpdateAvailabilityParams) => {
    try {
      setLoading(true);

      const { data } = await availabilitiesApi.updateAvailability({
        availabilityId: availability?.id ?? '',
        updateAvailabilityParams: params,
      });

      enqueueSnackbar(t('form.success.update'), {
        variant: 'success',
      });

      track(AnalyticsEvents.AVAILABILITY_EDITED, {
        availabilityId: data.id,
        old: availability,
        new: data,
        payload: params,
      });

      navigate(`../agenda/availabilities/${data.id}?viewData=availabilities`);
    } catch (error) {
      emitError(error);

      track(AnalyticsEvents.AVAILABILITY_EDITED, {
        availabilityId: availability?.id,
        old: availability,
        payload: params,
        error,
      });
    } finally {
      setLoading(false);
    }
  };

  const onUpdateSave = async (data: UpdateRulesProps) => {
    setUpdateRuleConfirm(false);

    const payload: UpdateAvailabilityParams = {
      quantity: parseInt(String(formValues.timesAndQuantities![0].quantity)),
      rule: data.rule,
      ruleUntilDate: data.ruleUntilDate ? format(data.ruleUntilDate, 'yyyy-MM-dd') : undefined,
    };

    await updateAvailability(payload);
  };

  const submit = async (data: CreateUpdateAvailabilityParams) => {
    if (isEdit) {
      setUpdateRuleConfirm(true);
    } else {
      const payload: CreateAvailabilityParams[] = data.timesAndQuantities.map((item) => ({
        type: data.type,
        beginDate: format(data.beginDate, 'yyyy-MM-dd'),
        recurrence: data.recurrence,
        productIds: data.productIds,
        tariffIds: data.tariffIds,
        additionalIds: data.additionalIds,
        productTariffTypes: data.productTariffTypes,
        fromTime: item.fromTime,
        toTime: item.toTime,
        quantity: parseInt(String(item.quantity)),
      }));

      await createAvailability(payload);
    }
  };

  const limitTypeOptions = Object.values(AvailabilityTypeEnum);

  const timeOptions = useMemo(() => {
    const productSchedulingInterval = selectedAccount?.app?.minuteSetInterval ?? 30;
    return intervalTimeOptions(productSchedulingInterval);
  }, []);

  const endTimeOptions = useMemo(() => {
    // Remove the first option to avoid the same time in the start and end time
    return timeOptions.slice(1);
  }, [timeOptions]);

  const handleChangeTimeType = (value: string) => {
    if (value === TYPE_OF_TIMES.unique && formValues.timesAndQuantities!.length > 1) {
      const lastTime = formValues.timesAndQuantities![0];

      setValue('timesAndQuantities', [{ fromTime: '00:00', toTime: '23:59', quantity: lastTime.quantity ?? 0 }]);
    } else if (value === TYPE_OF_TIMES.many && formValues.timesAndQuantities!.length === 1) {
      setValue('timesAndQuantities', [{ fromTime: timeOptions[0], toTime: timeOptions[1], quantity: 0 }]);
    }
  };

  const onReplicateQuantity = () => {
    const quantity = formValues!.timesAndQuantities![0].quantity;

    const newTimesAndQuantities = formValues!.timesAndQuantities!.map((item) => ({
      ...item,
      quantity,
    }));

    setValue('timesAndQuantities', newTimesAndQuantities as TimesAndQuantities[]);
  };

  const clearTypeValues = () => {
    setValue('products', []);
    setValue('additionals', []);
    setValue('tariffs', []);
    setValue('productTariffTypes', []);
  };

  const radioOptions: RadioOptionProps[] = useMemo(
    () => [
      {
        size: 'small',
        disabled: isEdit,
        value: TYPE_OF_TIMES.unique,
        label: <RadioLabel label={t('form.unique_time_label')} helperText={t('form.unique_time_helper_text')} />,
      },
      {
        size: 'small',
        disabled: isEdit,
        value: TYPE_OF_TIMES.many,
        label: <RadioLabel label={t('form.many_times_label')} helperText={t('form.many_times_helper_text')} />,
      },
    ],
    [isEdit],
  );

  return (
    <Fragment>
      <Grid container spacing={6} component='form' onSubmit={handleSubmit(submit)}>
        {loading ? <BackdropLoading /> : null}

        <Grid item xs={12}>
          <Grid container spacing={6}>
            <FormSession title={t('form.limit_types')}>
              <Grid item xs={12} md={6} lg={4}>
                <Controller
                  name='type'
                  control={control}
                  render={({ field: { onChange, value, name, ...rest } }) => (
                    <Select
                      label={t('form.limit_types_field_label')}
                      name={name}
                      options={limitTypeOptions}
                      fullWidth
                      disableClearable
                      getOptionLabel={(option) => t(`type.${option}`)}
                      value={value}
                      onChange={(_, value) => {
                        clearTypeValues();
                        onChange(value as AvailabilityTypeEnum);
                      }}
                      error={!!errors[name]}
                      helperText={errors[name]?.message as string}
                      disabled={isEdit}
                      {...rest}
                    />
                  )}
                />
              </Grid>
            </FormSession>

            <FormSession title={t('form.occurrence')}>
              <Grid item xs={12} md={6} lg={4}>
                <Controller
                  name='beginDate'
                  control={control}
                  defaultValue={formValues.appliesAt}
                  render={({ field: { onChange, value, name, ...rest } }) => (
                    <CustomDatePicker
                      fullWidth
                      name={name}
                      value={value}
                      closeOnSelect
                      disabled={isEdit}
                      format='dd/MM/yyyy'
                      minDate={minimumDate}
                      error={!!errors[name]}
                      label={t('form.start_date')}
                      onChange={(date) => onChange(date)}
                      helperText={errors[name]?.message as string}
                      tooltipProps={{
                        placement: 'top',
                        title: t('form.begin_date_tooltip'),
                      }}
                      {...rest}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <RecurrenceButton
                  disabled={!!isEdit}
                  recurrence={formValues.recurrence as Recurrence}
                  onRecurrenceChange={(recurrence) => setValue('recurrence', recurrence)}
                />
              </Grid>
            </FormSession>

            <FormSession title={t('form.times_and_quantity')} tooltipTitle={t('form.times_and_quantity_tooltip_text')}>
              <Grid item xs={12} marginBottom={4} paddingTop='1rem !important'>
                <Controller
                  name='timeType'
                  control={control}
                  render={({ field: { onChange, value, name, ...rest } }) => (
                    <RadioButtonsGroup
                      row
                      required
                      name={name}
                      id='timeType'
                      value={value || ''}
                      aria-disabled={isEdit}
                      onChange={(e) => {
                        handleChangeTimeType(e.target.value);
                        onChange(e);
                      }}
                      error={!!errors[name]}
                      helperText={errors[name]?.message as string}
                      sx={{
                        gap: theme.spacing(4),
                        [`& .${radioClasses.root}`]: { padding: '0rem 0.5rem !important' },
                        '& .MuiFormControlLabel-root': { alignItems: 'flex-start', marginRight: 0 },
                      }}
                      options={radioOptions}
                      {...rest}
                    />
                  )}
                />
              </Grid>

              <DynamicTime
                errors={errors}
                control={control}
                formValues={formValues}
                timeOptions={timeOptions}
                endTimeOptions={endTimeOptions}
                isEdit={isEdit}
                onReplicateQuantity={onReplicateQuantity}
              />
            </FormSession>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <AvailabilityTypePickers
            availabilityType={formValues.type}
            control={control}
            formState={formState}
            isEdit={isEdit}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent={'flex-end'} spacing={2}>
            <Grid item>
              <Button color='primary' variant='outlined' disabled={loading} startIcon={<X />} href='../agenda'>
                {t('form.buttons.cancel')}
              </Button>
            </Grid>

            <Grid item>
              <Button
                type='submit'
                color='primary'
                variant='contained'
                disabled={loading}
                startIcon={<IconDeviceFloppy />}
              >
                {t('form.buttons.save')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Suspense fallback={null}>
        {updateRuleConfirm ? (
          <LazyRecurrenceSelector
            isOpen={updateRuleConfirm}
            description={`${t('form.recurrence.description')}:`}
            title={t('form.recurrence.title')}
            acceptedUpdateRules={[
              UpdateRuleEnum.ThisOnly,
              UpdateRuleEnum.ThisAndFollowing,
              UpdateRuleEnum.ThisAndFollowingUntil,
              UpdateRuleEnum.All,
            ]}
            onCancel={() => setUpdateRuleConfirm(false)}
            onConfirm={(data) => onUpdateSave(data)}
            onClose={() => setUpdateRuleConfirm(false)}
            initialDate={formValues.appliesAt}
          />
        ) : null}
      </Suspense>
    </Fragment>
  );
};

export default AvailabilityForm;
