import axios, { AxiosResponse } from 'axios';

interface GetAddressByCepResponse {
  cep: string;
  state: string;
  city: string;
  neighborhood: string;
  street: string;
  service: string;
}

interface GetAddressByCepParams {
  cep: string;
}

const getAddressByCep = async (params: GetAddressByCepParams): Promise<AxiosResponse<GetAddressByCepResponse>> => {
  const { cep } = params;
  const response = await axios.get(`https://brasilapi.com.br/api/cep/v2/${cep}`);

  return response;
};

const BrasilApi = {
  getAddressByCep,
};

export default BrasilApi;
