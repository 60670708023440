import { useTranslation } from 'react-i18next';

import EmptyPage from '@/components/EmptyPage';
import FilterEmptyPageIcon from '@/assets/Icons/filter-empty-page-icon.svg';

export const EmptyFilterPage = () => {
  const { t } = useTranslation(['ui']);

  return (
    <EmptyPage
      description={t('filter.empty_page_message')}
      Icon={<img src={FilterEmptyPageIcon} alt='Icon' />}
      showCicleBelowIcon={false}
    />
  );
};

export default EmptyFilterPage;
