/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Planne Seller API
 * OpenAPI spec version: 0.1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  Additional,
  ArrangedProduct,
  DetailedTariffGroup,
  GetAppArrangedProductsParams,
  GetAppProductsParams,
  GetAppSellableProductsParams,
  GetArrangedProductParams,
  GetProductAdditionalsParams,
  GetProductCategoriesParams,
  GetProductDetailedTariffGroupsParams,
  GetProductImagesParams,
  GetProductRelatedTariffTypesParams,
  NotFoundResponseResponse,
  Product,
  ProductCategory,
  ProductImage,
  ProductParams,
  PublicProduct,
  TariffType,
  UnprocessableResponseResponse
} from '../../model'
import { customInstance } from '../../mutator/custom-instance';
import type { ErrorType, BodyType } from '../../mutator/custom-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const getAppProducts = (
    appId: string,
    params?: GetAppProductsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<Product[]>(
      {url: `/apps/${appId}/products`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetAppProductsQueryKey = (appId: string,
    params?: GetAppProductsParams,) => {
    return [`/apps/${appId}/products`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAppProductsQueryOptions = <TData = Awaited<ReturnType<typeof getAppProducts>>, TError = ErrorType<NotFoundResponseResponse>>(appId: string,
    params?: GetAppProductsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAppProducts>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAppProductsQueryKey(appId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAppProducts>>> = ({ signal }) => getAppProducts(appId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(appId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAppProducts>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAppProductsQueryResult = NonNullable<Awaited<ReturnType<typeof getAppProducts>>>
export type GetAppProductsQueryError = ErrorType<NotFoundResponseResponse>



export function useGetAppProducts<TData = Awaited<ReturnType<typeof getAppProducts>>, TError = ErrorType<NotFoundResponseResponse>>(
 appId: string,
    params?: GetAppProductsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAppProducts>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetAppProductsQueryOptions(appId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const createAppProduct = (
    appId: string,
    productParams: BodyType<ProductParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<Product>(
      {url: `/apps/${appId}/products`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: productParams, signal
    },
      options);
    }
  


export const getCreateAppProductMutationOptions = <TError = ErrorType<NotFoundResponseResponse | UnprocessableResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAppProduct>>, TError,{appId: string;data: BodyType<ProductParams>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createAppProduct>>, TError,{appId: string;data: BodyType<ProductParams>}, TContext> => {
    
const mutationKey = ['createAppProduct'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAppProduct>>, {appId: string;data: BodyType<ProductParams>}> = (props) => {
          const {appId,data} = props ?? {};

          return  createAppProduct(appId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateAppProductMutationResult = NonNullable<Awaited<ReturnType<typeof createAppProduct>>>
    export type CreateAppProductMutationBody = BodyType<ProductParams>
    export type CreateAppProductMutationError = ErrorType<NotFoundResponseResponse | UnprocessableResponseResponse>

    export const useCreateAppProduct = <TError = ErrorType<NotFoundResponseResponse | UnprocessableResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAppProduct>>, TError,{appId: string;data: BodyType<ProductParams>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof createAppProduct>>,
        TError,
        {appId: string;data: BodyType<ProductParams>},
        TContext
      > => {

      const mutationOptions = getCreateAppProductMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getProduct = (
    productId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<Product>(
      {url: `/products/${productId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetProductQueryKey = (productId: string,) => {
    return [`/products/${productId}`] as const;
    }

    
export const getGetProductQueryOptions = <TData = Awaited<ReturnType<typeof getProduct>>, TError = ErrorType<NotFoundResponseResponse>>(productId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProduct>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProductQueryKey(productId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProduct>>> = ({ signal }) => getProduct(productId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProduct>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProductQueryResult = NonNullable<Awaited<ReturnType<typeof getProduct>>>
export type GetProductQueryError = ErrorType<NotFoundResponseResponse>



export function useGetProduct<TData = Awaited<ReturnType<typeof getProduct>>, TError = ErrorType<NotFoundResponseResponse>>(
 productId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProduct>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetProductQueryOptions(productId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getProductImages = (
    productId: string,
    params?: GetProductImagesParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ProductImage[]>(
      {url: `/products/${productId}/images`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetProductImagesQueryKey = (productId: string,
    params?: GetProductImagesParams,) => {
    return [`/products/${productId}/images`, ...(params ? [params]: [])] as const;
    }

    
export const getGetProductImagesQueryOptions = <TData = Awaited<ReturnType<typeof getProductImages>>, TError = ErrorType<NotFoundResponseResponse>>(productId: string,
    params?: GetProductImagesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProductImages>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProductImagesQueryKey(productId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductImages>>> = ({ signal }) => getProductImages(productId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProductImages>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProductImagesQueryResult = NonNullable<Awaited<ReturnType<typeof getProductImages>>>
export type GetProductImagesQueryError = ErrorType<NotFoundResponseResponse>



export function useGetProductImages<TData = Awaited<ReturnType<typeof getProductImages>>, TError = ErrorType<NotFoundResponseResponse>>(
 productId: string,
    params?: GetProductImagesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProductImages>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetProductImagesQueryOptions(productId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppArrangedProducts = (
    appId: string,
    params?: GetAppArrangedProductsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ArrangedProduct[]>(
      {url: `/apps/${appId}/arrangedProducts`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetAppArrangedProductsQueryKey = (appId: string,
    params?: GetAppArrangedProductsParams,) => {
    return [`/apps/${appId}/arrangedProducts`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAppArrangedProductsQueryOptions = <TData = Awaited<ReturnType<typeof getAppArrangedProducts>>, TError = ErrorType<NotFoundResponseResponse>>(appId: string,
    params?: GetAppArrangedProductsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAppArrangedProducts>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAppArrangedProductsQueryKey(appId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAppArrangedProducts>>> = ({ signal }) => getAppArrangedProducts(appId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(appId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAppArrangedProducts>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAppArrangedProductsQueryResult = NonNullable<Awaited<ReturnType<typeof getAppArrangedProducts>>>
export type GetAppArrangedProductsQueryError = ErrorType<NotFoundResponseResponse>



export function useGetAppArrangedProducts<TData = Awaited<ReturnType<typeof getAppArrangedProducts>>, TError = ErrorType<NotFoundResponseResponse>>(
 appId: string,
    params?: GetAppArrangedProductsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAppArrangedProducts>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetAppArrangedProductsQueryOptions(appId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getArrangedProduct = (
    appId: string,
    arrangedProductId: string,
    params?: GetArrangedProductParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ArrangedProduct>(
      {url: `/apps/${appId}/arrangedProducts/${arrangedProductId}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetArrangedProductQueryKey = (appId: string,
    arrangedProductId: string,
    params?: GetArrangedProductParams,) => {
    return [`/apps/${appId}/arrangedProducts/${arrangedProductId}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetArrangedProductQueryOptions = <TData = Awaited<ReturnType<typeof getArrangedProduct>>, TError = ErrorType<NotFoundResponseResponse>>(appId: string,
    arrangedProductId: string,
    params?: GetArrangedProductParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getArrangedProduct>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetArrangedProductQueryKey(appId,arrangedProductId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getArrangedProduct>>> = ({ signal }) => getArrangedProduct(appId,arrangedProductId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(appId && arrangedProductId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getArrangedProduct>>, TError, TData> & { queryKey: QueryKey }
}

export type GetArrangedProductQueryResult = NonNullable<Awaited<ReturnType<typeof getArrangedProduct>>>
export type GetArrangedProductQueryError = ErrorType<NotFoundResponseResponse>



export function useGetArrangedProduct<TData = Awaited<ReturnType<typeof getArrangedProduct>>, TError = ErrorType<NotFoundResponseResponse>>(
 appId: string,
    arrangedProductId: string,
    params?: GetArrangedProductParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getArrangedProduct>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetArrangedProductQueryOptions(appId,arrangedProductId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppSellableProducts = (
    appId: string,
    params?: GetAppSellableProductsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<PublicProduct[]>(
      {url: `/apps/${appId}/sellableProducts`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetAppSellableProductsQueryKey = (appId: string,
    params?: GetAppSellableProductsParams,) => {
    return [`/apps/${appId}/sellableProducts`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAppSellableProductsQueryOptions = <TData = Awaited<ReturnType<typeof getAppSellableProducts>>, TError = ErrorType<NotFoundResponseResponse>>(appId: string,
    params?: GetAppSellableProductsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAppSellableProducts>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAppSellableProductsQueryKey(appId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAppSellableProducts>>> = ({ signal }) => getAppSellableProducts(appId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(appId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAppSellableProducts>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAppSellableProductsQueryResult = NonNullable<Awaited<ReturnType<typeof getAppSellableProducts>>>
export type GetAppSellableProductsQueryError = ErrorType<NotFoundResponseResponse>



export function useGetAppSellableProducts<TData = Awaited<ReturnType<typeof getAppSellableProducts>>, TError = ErrorType<NotFoundResponseResponse>>(
 appId: string,
    params?: GetAppSellableProductsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAppSellableProducts>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetAppSellableProductsQueryOptions(appId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getProductDetailedTariffGroups = (
    productId: string,
    params?: GetProductDetailedTariffGroupsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<DetailedTariffGroup[]>(
      {url: `/products/${productId}/detailedTariffGroups`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetProductDetailedTariffGroupsQueryKey = (productId: string,
    params?: GetProductDetailedTariffGroupsParams,) => {
    return [`/products/${productId}/detailedTariffGroups`, ...(params ? [params]: [])] as const;
    }

    
export const getGetProductDetailedTariffGroupsQueryOptions = <TData = Awaited<ReturnType<typeof getProductDetailedTariffGroups>>, TError = ErrorType<NotFoundResponseResponse>>(productId: string,
    params?: GetProductDetailedTariffGroupsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProductDetailedTariffGroups>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProductDetailedTariffGroupsQueryKey(productId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductDetailedTariffGroups>>> = ({ signal }) => getProductDetailedTariffGroups(productId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProductDetailedTariffGroups>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProductDetailedTariffGroupsQueryResult = NonNullable<Awaited<ReturnType<typeof getProductDetailedTariffGroups>>>
export type GetProductDetailedTariffGroupsQueryError = ErrorType<NotFoundResponseResponse>



export function useGetProductDetailedTariffGroups<TData = Awaited<ReturnType<typeof getProductDetailedTariffGroups>>, TError = ErrorType<NotFoundResponseResponse>>(
 productId: string,
    params?: GetProductDetailedTariffGroupsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProductDetailedTariffGroups>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetProductDetailedTariffGroupsQueryOptions(productId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getProductAdditionals = (
    productId: string,
    params?: GetProductAdditionalsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<Additional[]>(
      {url: `/products/${productId}/additionals`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetProductAdditionalsQueryKey = (productId: string,
    params?: GetProductAdditionalsParams,) => {
    return [`/products/${productId}/additionals`, ...(params ? [params]: [])] as const;
    }

    
export const getGetProductAdditionalsQueryOptions = <TData = Awaited<ReturnType<typeof getProductAdditionals>>, TError = ErrorType<NotFoundResponseResponse>>(productId: string,
    params?: GetProductAdditionalsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProductAdditionals>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProductAdditionalsQueryKey(productId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductAdditionals>>> = ({ signal }) => getProductAdditionals(productId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProductAdditionals>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProductAdditionalsQueryResult = NonNullable<Awaited<ReturnType<typeof getProductAdditionals>>>
export type GetProductAdditionalsQueryError = ErrorType<NotFoundResponseResponse>



export function useGetProductAdditionals<TData = Awaited<ReturnType<typeof getProductAdditionals>>, TError = ErrorType<NotFoundResponseResponse>>(
 productId: string,
    params?: GetProductAdditionalsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProductAdditionals>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetProductAdditionalsQueryOptions(productId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getProductRelatedTariffTypes = (
    productId: string,
    params?: GetProductRelatedTariffTypesParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<TariffType[]>(
      {url: `/products/${productId}/relatedTariffTypes`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetProductRelatedTariffTypesQueryKey = (productId: string,
    params?: GetProductRelatedTariffTypesParams,) => {
    return [`/products/${productId}/relatedTariffTypes`, ...(params ? [params]: [])] as const;
    }

    
export const getGetProductRelatedTariffTypesQueryOptions = <TData = Awaited<ReturnType<typeof getProductRelatedTariffTypes>>, TError = ErrorType<NotFoundResponseResponse>>(productId: string,
    params?: GetProductRelatedTariffTypesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProductRelatedTariffTypes>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProductRelatedTariffTypesQueryKey(productId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductRelatedTariffTypes>>> = ({ signal }) => getProductRelatedTariffTypes(productId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProductRelatedTariffTypes>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProductRelatedTariffTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getProductRelatedTariffTypes>>>
export type GetProductRelatedTariffTypesQueryError = ErrorType<NotFoundResponseResponse>



export function useGetProductRelatedTariffTypes<TData = Awaited<ReturnType<typeof getProductRelatedTariffTypes>>, TError = ErrorType<NotFoundResponseResponse>>(
 productId: string,
    params?: GetProductRelatedTariffTypesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProductRelatedTariffTypes>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetProductRelatedTariffTypesQueryOptions(productId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getProductCategories = (
    productId: string,
    params?: GetProductCategoriesParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ProductCategory[]>(
      {url: `/products/${productId}/categories`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetProductCategoriesQueryKey = (productId: string,
    params?: GetProductCategoriesParams,) => {
    return [`/products/${productId}/categories`, ...(params ? [params]: [])] as const;
    }

    
export const getGetProductCategoriesQueryOptions = <TData = Awaited<ReturnType<typeof getProductCategories>>, TError = ErrorType<NotFoundResponseResponse>>(productId: string,
    params?: GetProductCategoriesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProductCategories>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProductCategoriesQueryKey(productId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductCategories>>> = ({ signal }) => getProductCategories(productId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProductCategories>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProductCategoriesQueryResult = NonNullable<Awaited<ReturnType<typeof getProductCategories>>>
export type GetProductCategoriesQueryError = ErrorType<NotFoundResponseResponse>



export function useGetProductCategories<TData = Awaited<ReturnType<typeof getProductCategories>>, TError = ErrorType<NotFoundResponseResponse>>(
 productId: string,
    params?: GetProductCategoriesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProductCategories>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetProductCategoriesQueryOptions(productId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



