import { Content, Wrapper } from './styles';

type ShowCaseLayoutProps = {
  children: React.ReactNode;
  paperWidth?: string;
  paperHeight?: string;
};

export const BackgroundArtLayout = (props: ShowCaseLayoutProps) => {
  const { paperWidth = '600px', paperHeight = '80vh', children } = props;

  return (
    <Wrapper>
      <Content width={paperWidth} height={paperHeight}>
        {children}
      </Content>
    </Wrapper>
  );
};

export default BackgroundArtLayout;
