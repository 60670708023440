import { Stack, useMediaQuery } from '@mui/material';
import { ChevronDown, ChevronLeft, ChevronRight } from '@untitled-ui/icons-react';
import { Fragment, useMemo, useState } from 'react';
import { isSameWeek } from 'date-fns';

import { useAgenda } from '@/features/agenda/hooks/useAgenda';
import getDateStartAndEndString from '@/features/agenda/utils/getDateStartAndEndString';
import theme from '@/theme';

import SelectMonthOrWeekModal from './SelectMonthOrWeekModal';
import { ClearButton, StyledMonthSelectorButton, StyledMonthSelectorTitle } from './styles';
import { ViewType } from '@/features/agenda/types';

const MonthSelector = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const { getMonthString, calendarView: view, next, prev, getWeekDates, activeDate: currentDate } = useAgenda();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const weekDates = useMemo(() => getWeekDates(currentDate), [currentDate]);

  const currentWeek = useMemo(() => {
    return weekDates.find((week) => isSameWeek(currentDate, week.lastDay, { weekStartsOn: 0 }));
  }, [currentDate, weekDates]);

  const getActiveTitle = useMemo(() => {
    return view === ViewType.MONTH ? (
      <StyledMonthSelectorTitle variant='regularRegular'>
        <span>{getMonthString()}</span> - <span>{currentDate.getFullYear()}</span>
      </StyledMonthSelectorTitle>
    ) : (
      currentWeek && (
        <StyledMonthSelectorTitle variant={isDesktop ? 'regularRegular' : 'smallRegular'}>
          {getDateStartAndEndString(currentWeek.firstDay, currentWeek.lastDay, !isDesktop)}
        </StyledMonthSelectorTitle>
      )
    );
  }, [view, currentDate, currentWeek, isDesktop]);

  return (
    <Stack
      sx={{
        gap: theme.spacing(1),
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
        width: isDesktop ? 'auto' : '100%',
      }}
    >
      {!isDesktop ? (
        <Fragment>
          <ClearButton component='button' onClick={prev}>
            <ChevronLeft />
          </ClearButton>
          <ClearButton component='button' onClick={next}>
            <ChevronRight />
          </ClearButton>
        </Fragment>
      ) : null}

      <StyledMonthSelectorButton
        color='primary'
        onClick={handleClick}
        variant='outlined'
        aria-controls={open ? 'date-actions-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={!!open || undefined}
        sx={!isDesktop ? { marginLeft: 'auto' } : {}}
      >
        {getActiveTitle}
        {isDesktop ? <ChevronDown /> : null}
      </StyledMonthSelectorButton>

      <SelectMonthOrWeekModal isOpen={open} onClose={handleClose} anchorEl={anchorEl} />
    </Stack>
  );
};

export default MonthSelector;
