import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';

import ModalOnDesktopDrawerOnMobile from '@/components/Modal/ModalOnDesktopDrawerOnMobile';
import AppTagsForm from '@/features/sales/components/AppTagsForm';

export const NewTag = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['sales']);
  const [openModal, setOpenModal] = useState(true);
  const { refreshAppTags } = useOutletContext<{ refreshAppTags: () => void }>();

  const close = () => {
    setOpenModal(false);
    navigate('../', { replace: true });
  };
  const success = () => {
    close();
    refreshAppTags?.();
  };

  return (
    <ModalOnDesktopDrawerOnMobile isOpen={openModal} onClose={close} title={t('app_tags.modal.new.title')} width={564}>
      <AppTagsForm onCancel={close} onSuccess={success} />
    </ModalOnDesktopDrawerOnMobile>
  );
};

export default NewTag;
