import { Grid, Link } from '@mui/material';
import ProductCard from './ProductCard';
import { Product, SellingMode } from '@/services/SellerApi';
import Loading from '@/components/Loading';
import { useTranslation } from 'react-i18next';
import SelectedItems from '../SelectedItems';
import Alert from '@/components/Alert';
import Input from '@/components/Form/Input';
import { useEffect, useState } from 'react';
import { useSellerApi } from '@/hooks/useSellerApi';
import useAccount from '@/hooks/useAccount';
import useProductList from '../../../../hooks/useProductList';

type Props = {
  onChange?: (products: Product[]) => void;
  value?: Product[];
  defaultProductIds?: string[];
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
};

const ProductPicker = (props: Props) => {
  const { onChange = () => {}, value = [], defaultProductIds = [], error = false, helperText, disabled } = props;

  const { t } = useTranslation(['availability']);

  const { productsApi } = useSellerApi();
  const { selectedAccount } = useAccount();
  const { products, loading, hasMore, changeSearch, loadMore } = useProductList([
    SellingMode.WithDateOnly,
    SellingMode.WithDateAndTime,
  ]);

  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);

  const isProductSelected = (product: Product) => {
    return selectedProducts.some((selectedProduct) => selectedProduct.id === product.id);
  };

  const handleSelect = (product: Product) => {
    let productsCopy = [...selectedProducts];

    if (isProductSelected(product)) {
      productsCopy = productsCopy.filter((selectedProduct) => selectedProduct.id !== product.id);
    } else {
      productsCopy = [...productsCopy, product];
    }

    setSelectedProducts(productsCopy);
    onChange(productsCopy.map((product) => product));
  };

  const filteredProducts = products.filter((product) => !isProductSelected(product));

  const onSearchChange = (value: string) => {
    changeSearch(value);
  };

  const loadProductIds = async (productIds: string[]) => {
    const productsNotLoaded = productIds.filter(
      (productId) => !selectedProducts.some((product) => product.id === productId),
    );

    if (productsNotLoaded.length > 0) {
      const { data } = await productsApi.getAppProducts({
        appId: selectedAccount?.app?.id ?? '',
        offset: 0,
        limit: 100,
        activeOnly: false,
        ids: productsNotLoaded,
      });

      if (data.length) {
        setSelectedProducts([...selectedProducts, ...data]);
        onChange([...selectedProducts, ...data]);
      }
    }
  };

  useEffect(() => {
    loadProductIds([...defaultProductIds, ...value.map((product) => product.id)]);
  }, [defaultProductIds]);

  const renderProductCards = (products: Product[]) => {
    return products.map((product) => (
      <Grid item xs={12} sm={6} md={6} lg={4} key={product.id}>
        <ProductCard
          product={product}
          onClick={() => handleSelect(product)}
          isSelected={isProductSelected(product)}
          disabled={disabled}
        />
      </Grid>
    ));
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <SelectedItems>{renderProductCards(selectedProducts)}</SelectedItems>
      </Grid>

      {!disabled ? (
        <>
          <Grid item xs={12}>
            <Grid container justifyContent='flex-end' spacing={6}>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Input
                  label={t('form.filters.label')}
                  placeholder={t('form.filters.placeholder')}
                  onChange={({ target }) => onSearchChange(target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={6}>
              {renderProductCards(filteredProducts)}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent='center'>
              <Grid item>
                {loading ? <Loading /> : null}

                {!loading && hasMore ? <Link onClick={() => loadMore()}>{t('form.load_more')}</Link> : null}
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : null}

      {helperText ? (
        <Grid item xs={12}>
          <Alert severity={error ? 'error' : 'info'}>{helperText}</Alert>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default ProductPicker;
