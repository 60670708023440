import { styled } from '@mui/material/styles';
import { DataGrid as MuiDatagrid } from '@mui/x-data-grid';

export const DataGrid = styled(MuiDatagrid)(({ theme }) => ({
  '& .MuiDataGrid-cell': {
    borderBottom: 0,
  },

  '& .MuiDataGrid-columnHeaders': {
    borderBottom: 0,
  },

  '& .MuiDataGrid-columnHeader': {
    borderBottom: 0,
  },

  '& .MuiDataGrid-row': {
    backgroundColor: theme.palette.colors.gray[50],

    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.colors.white[600],
    },

    '&:first-of-type': {
      borderTop: `1px solid ${theme.palette.colors.gray[400]}`,
    },
  },

  '& .MuiDataGrid-cellContent': {
    color: theme.palette.colors.gray[700],
    fontSize: '0.8125rem',
    fontWeight: 700,
  },
  '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },

  '& .MuiDataGrid-footerContainer': {
    marginTop: '1rem',
    border: 'none',
  },

  '& .MuiTablePagination-selectLabel': {
    color: theme.palette.colors.blue[500],
    padding: '0.625rem 1rem',
    borderRadius: '4px',
    fontSize: '1rem',
    border: `1px solid ${theme.palette.colors.blue[500]}`,
  },

  '& .MuiTablePagination-displayedRows': {
    color: theme.palette.colors.white[600],
    padding: '0.625rem 1rem',
    borderRadius: '4px',
    background: theme.palette.colors.blue[500],
    fontSize: '1rem',
  },

  '& .MuiSelect-select': {
    border: `1px solid ${theme.palette.colors.blue[500]}`,
    borderRadius: '4px',
    color: theme.palette.colors.blue[500],
    padding: '0.625rem 1rem',
  },

  '& .MuiTablePagination-actions': {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',

    button: {
      border: `1px solid ${theme.palette.colors.blue[500]}`,
      borderRadius: '4px',
      color: theme.palette.colors.blue[500],
      padding: '0.625rem 1rem',
    },

    'button:disabled': {
      cursor: 'not-allowed',
      background: theme.palette.colors.blue[50],
      color: theme.palette.colors.blue[100],
      borderColor: theme.palette.colors.blue[50],
    },
  },
}));
