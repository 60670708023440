import { styled, css } from '@mui/material/styles';

export const Wrapper = styled('div')(
  () => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    width: 100%;
    gap: 10px;

    .MuiFormHelperText-root {
      margin-top: -4px;
    }

    #ddi {
      text-align: center;
      margin-left: 16px;
    }

    fieldset {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  `,
);
