import { Typography } from '@mui/material';

import theme from '@/theme';

interface Props {
  label: string;
  helperText: string;
}

const RadioLabel = (props: Props) => {
  const { label, helperText } = props;

  return (
    <Typography component='p' width='100%' maxWidth={270} variant='smallRegular' color={theme.palette.colors.gray[700]}>
      {label}
      <Typography component='p' variant='smallRegular' color={theme.palette.colors.gray[400]}>
        {helperText}
      </Typography>
    </Typography>
  );
};

export default RadioLabel;
