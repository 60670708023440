import { DefinedUseQueryResult, useQuery } from '@tanstack/react-query';

import useAccount from '@/hooks/useAccount';
import useGetAllProducts from '@/usecases/useGetAllProducts';
import { Product, SellingMode } from '@/services/SellerApi';
import { getGetAppProductsQueryKey } from '@/services/sellerApiQuery/api/products/products';

const useLoadProductsQuery = (sellingModes?: SellingMode[], initialState?: unknown) => {
  const { selectedAccount } = useAccount();

  const useRequestQuery = (active = true) => {
    const appId = selectedAccount?.appId || '';

    const queryKey = getGetAppProductsQueryKey(appId, {
      sellingModes,
    });

    return useQuery({
      queryKey,
      queryFn: useGetAllProducts(appId),
      initialData: initialState,
      enabled: !!appId && active,
    }) as DefinedUseQueryResult<Product[], Error>;
  };

  return [useRequestQuery];
};

export default useLoadProductsQuery;
