import MobileMenu from '@/components/MobileMenu';
import PreviewDrawer, { PreviewItem } from '@/components/PreviewDrawer';
import { colors } from '@/theme';
import { Box, IconButton, Link, Typography } from '@mui/material';
import { DownloadCloud02 } from '@untitled-ui/icons-react';
import { useTranslation } from 'react-i18next';
import { DetailedDirectSaleItem, PaymentMethodCode, SaleStateEnum } from '@/services/SellerApi';
import Button from '@/components/Button';
import { Chip } from './styles';
import { formatCents } from '@/utils';
import { format } from 'date-fns';
import { DirectSaleWithItemsAndPaymentOption } from '..';

interface Action {
  label: string;
  icon: JSX.Element;
  code: PaymentMethodCode;
  onClick: (directSale: DirectSaleWithItemsAndPaymentOption) => void;
}

interface Props {
  open: boolean;
  actions: Action[];
  onClose: () => void;
  dsWithItemAndPaymentMethod?: DirectSaleWithItemsAndPaymentOption;
  onDownloadVoucher: (directSale: DirectSaleWithItemsAndPaymentOption) => void;
  getProductsName: (detailedDirectSaleItem?: DetailedDirectSaleItem[]) => string[] | null;
  renderCurrentState: (currentState: SaleStateEnum) => JSX.Element;
  getPartnershipLink: (partnershipId?: string) => string | undefined;
  paymentMethodIsAvailable: (directSale: DirectSaleWithItemsAndPaymentOption, code: PaymentMethodCode) => boolean;
}
export const Preview = (props: Props) => {
  const {
    open,
    onClose,
    dsWithItemAndPaymentMethod,
    onDownloadVoucher,
    getProductsName,
    actions,
    renderCurrentState,
    getPartnershipLink,
    paymentMethodIsAvailable,
  } = props;

  const { t } = useTranslation(['directSales']);

  const currentState = dsWithItemAndPaymentMethod?.directSale.currentState;

  return (
    <PreviewDrawer open={open} onClose={onClose} showCloseButton={false}>
      {dsWithItemAndPaymentMethod?.directSale ? (
        <Box display='flex' flexDirection='column' gap={2}>
          <Box display='flex' alignItems='center' flexDirection='row' justifyContent={'space-between'}>
            <Typography variant='largeMedium' color={colors.gray[500]}>
              {dsWithItemAndPaymentMethod.directSale.customer?.firstName}{' '}
              {dsWithItemAndPaymentMethod.directSale.customer?.lastName}
            </Typography>

            {currentState === SaleStateEnum.PaymentComplete ? (
              <IconButton onClick={() => onDownloadVoucher(dsWithItemAndPaymentMethod)}>
                <DownloadCloud02 />
              </IconButton>
            ) : null}

            {currentState === SaleStateEnum.Created && (
              <MobileMenu
                items={actions.map((action) => ({
                  ...action,
                  onClick: () => action.onClick(dsWithItemAndPaymentMethod),
                  disabled: !paymentMethodIsAvailable(dsWithItemAndPaymentMethod, action.code),
                }))}
              />
            )}
          </Box>

          <Box
            display='flex'
            flexDirection='column'
            sx={{
              '& .preview-item:not(:nth-of-type(even))': {
                background: colors.gray[50],
                borderRadius: '4px',
              },
            }}
          >
            <PreviewItem
              title='#'
              content={() => (
                <Typography variant='smallBold'>{dsWithItemAndPaymentMethod.directSale.position}</Typography>
              )}
            />

            <PreviewItem
              title={t('list.table.status')}
              content={() => renderCurrentState(dsWithItemAndPaymentMethod.directSale.currentState)}
            />

            <PreviewItem
              title={t('list.table.amount_cents')}
              content={formatCents(dsWithItemAndPaymentMethod.directSale.amountCents ?? 0)}
            />

            <PreviewItem
              title={t('list.table.provider')}
              content={() => (
                <Link
                  variant='smallBold'
                  href={getPartnershipLink(
                    dsWithItemAndPaymentMethod.directSaleItems?.items[0]?.partnershipItem?.partnership?.id,
                  )}
                >
                  {dsWithItemAndPaymentMethod.directSaleItems?.items[0]?.partnershipItem?.partnership?.provider?.name}
                </Link>
              )}
            />

            <PreviewItem
              sx={{ alignItems: 'flex-start' }}
              title={t('list.table.products')}
              content={() => {
                const products = getProductsName(dsWithItemAndPaymentMethod.directSaleItems?.items);

                if (!products?.length) {
                  return <Chip label='-' size='small' color='primary' variant='outlined' />;
                }

                return (
                  <Box display='flex' gap={0.5} flexDirection='row' flexWrap='wrap' justifyContent='flex-end' pl={2}>
                    {products.map((product) => (
                      <Chip
                        key={product}
                        size='small'
                        color='primary'
                        label={product}
                        variant='outlined'
                        sx={{ background: colors.blue[100], border: 'none' }}
                      />
                    ))}
                  </Box>
                );
              }}
            />

            <PreviewItem
              title={t('list.table.created_at')}
              content={format(new Date(dsWithItemAndPaymentMethod.directSale.createdAt), 'dd/MM/yyyy - HH:mm')}
            />
          </Box>

          <Button variant='contained' onClick={onClose}>
            {t('list.table.actions.close')}
          </Button>
        </Box>
      ) : null}
    </PreviewDrawer>
  );
};

export default Preview;
