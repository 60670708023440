import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

export function start() {
  if (import.meta.env.VITE_BUGSNAG_API_KEY) {
    Bugsnag.start({
      apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
      plugins: [new BugsnagPluginReact()],
      releaseStage: import.meta.env.VITE_APP_ENV,
      appVersion: import.meta.env.PACKAGE_VERSION,
      enabledReleaseStages: ['production', 'staging'],
    });
  }
}
export default Bugsnag;
