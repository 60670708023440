import { Stack, Theme, useMediaQuery } from '@mui/material';
import { isSameDay, isSameMonth } from 'date-fns';
import { Fragment, Suspense, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SlotsPreview from '@/features/agenda/components/SchedulingCalendar/AgendaSlot/PreviewSlot';
import SlotSkeleton from '@/features/agenda/components/SchedulingCalendar/AgendaSlot/SlotSkeleton';
import SlotsPreviewMobile from '@/features/agenda/components/SchedulingCalendar/AgendaSlot/SlotsPreviewMobile';
import { useAgenda } from '@/features/agenda/hooks/useAgenda';
import { AgendaConfigState, ViewDataType } from '@/features/agenda/types';
import { Availability, Scheduling } from '@/services/SellerApi';

import { StyledSlot, StyledSlotHeader, StyledViewMore } from './styles';
import theme from '@/theme';
import { useConfig } from '@/features/config/useConfig';

// const LazyAgendaDayPopover = lazy(
//   () => import('@/features/agenda/components/SchedulingCalendar/AgendaDayPopover'),
// );
import LazyAgendaDayPopover from '@/features/agenda/components/SchedulingCalendar/AgendaDayPopover';

interface Props {
  date: Date;
  schedulings?: Scheduling[];
  availabilities?: Availability[];
  total: { schedulings: number; availabilities: number };
  isLoading?: boolean;
  sizes?: { [key: string]: number };
}

const AgendaSlot = (props: Props) => {
  const { date, isLoading = false, schedulings, availabilities, total, sizes } = props;
  const { activeDate: active } = useAgenda();

  const { config } = useConfig<AgendaConfigState>();

  const contentRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation(['agenda']);
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isSmallDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const PreviewComponent = isTablet ? SlotsPreviewMobile : SlotsPreview;
  const today = new Date();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const viewData = useMemo(() => config?.viewDataType, [config]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const isActive = useMemo(() => isSameDay(date, today), [date, today]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const totalOnViewMore = useMemo(() => {
    let totalSum = 0;
    if (viewData?.includes(ViewDataType.AVAILABILITIES)) {
      totalSum += total.availabilities;
    }
    if (viewData?.includes(ViewDataType.SCHEDULING)) {
      totalSum += total.schedulings;
    }
    return totalSum;
  }, [total.availabilities, total.schedulings, viewData, schedulings, availabilities]);

  const headerMonth = useCallback((date: Date) => {
    return (
      <Fragment>
        {date.getDate() === 1 && !isSmallDesktop ? (
          <span>{date.toLocaleDateString('default', { month: 'long' })}</span>
        ) : null}

        <Stack sx={!isTablet ? { marginLeft: 'auto' } : {}}>
          {date.toLocaleDateString('default', { day: '2-digit', month: '2-digit' })}
        </Stack>
      </Fragment>
    );
  }, []);

  return (
    <Fragment>
      <StyledSlot inactive={!isSameMonth(date, active)} active={isActive} onClick={isTablet ? handleClick : undefined}>
        <StyledSlotHeader variant='smallBold' active={isActive}>
          {headerMonth(date)}
        </StyledSlotHeader>
        <Stack
          ref={contentRef}
          sx={{
            flexDirection: 'column',
            height: '100%',
            overflow: 'hidden',
            padding: theme.spacing(2),
            gap: theme.spacing(2),
          }}
        >
          {isLoading ? (
            <SlotSkeleton />
          ) : (
            <Fragment>
              <PreviewComponent sizes={sizes} availabilities={availabilities} schedulings={schedulings} />

              {totalOnViewMore && !isTablet ? (
                <StyledViewMore onClick={handleClick}>{t('view_all', { count: totalOnViewMore })}</StyledViewMore>
              ) : null}
            </Fragment>
          )}
        </Stack>
      </StyledSlot>
      <Suspense fallback={null}>
        <LazyAgendaDayPopover date={date} isOpen={open} anchorEl={anchorEl} onClose={handleClose} />
      </Suspense>
    </Fragment>
  );
};

export default AgendaSlot;
