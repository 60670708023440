import { Visibility } from '@mui/icons-material';
import { Box, Chip, Grid, IconButton, Link, Typography, useMediaQuery } from '@mui/material';
import { GridActionsCellItem, GridRenderCellParams, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';
import { Edit05, FileX03, FlipBackward, User01 } from '@untitled-ui/icons-react';
import { enqueueSnackbar } from 'notistack';
import { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from '@/components/Button';
import DynamicChipList from '@/components/DynamicChipList';
import ExtendedGridActionsCellItem from '@/components/ExtendedGridActionsCellItem';
import ModalOnDesktopDrawerOnMobile from '@/components/Modal/ModalOnDesktopDrawerOnMobile';
import { StyledChip } from '@/components/Partnership/StatusChip/style';
import Table, { TableColDef } from '@/components/Table';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import useAccount from '@/hooks/useAccount';
import useErrorHandler from '@/hooks/useErrorHandler';
import { useSellerApi } from '@/hooks/useSellerApi';
import { ErrorTypes } from '@/interfaces/errorTypes';
import { Coupon, CouponConstraintType, CouponsApiGetAppCouponsRequest } from '@/services/SellerApi';
import theme, { colors } from '@/theme';

import {
  getCouponProductsName,
  getFormattedCouponDiscountValue,
  getMaxUseValueAndStatus,
} from '@/features/coupons/common';
import { formatDate } from '@/utils';

type Props = {
  totalCoupons: number;
  coupons: Coupon[];
  loading: boolean;
  onRefresh: () => Promise<void>;
  currentStatus?: CouponsApiGetAppCouponsRequest['filter'];
  paginationModel: {
    page: number;
    pageSize: number;
  };
  setPaginationModel: Dispatch<
    SetStateAction<{
      page: number;
      pageSize: number;
    }>
  >;
  setLoading: Dispatch<SetStateAction<boolean>>;
  belongsToPartnership: boolean;
};

const CouponTable = (props: Props) => {
  const {
    totalCoupons,
    coupons,
    loading,
    belongsToPartnership,
    setLoading,
    currentStatus,
    paginationModel,
    setPaginationModel,
    onRefresh,
  } = props;

  const navigate = useNavigate();
  const { track } = useAnalytics();
  const { couponsApi } = useSellerApi();
  const { selectedAccount, hasPermission } = useAccount();
  const { t } = useTranslation(['coupons']);
  const { errorHandler } = useErrorHandler();
  const [selectedCoupon, setSelectedCoupon] = useState<Coupon>();
  const [openRestoreModal, setOpenRestoreModal] = useState(false);

  const isArchivedCouponList = currentStatus === 'archived';
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getPartnershipDetailLink = (id: string) => {
    return `/${selectedAccount?.app?.code}/partnership/${id}`;
  };

  const viewCoupon = (couponId: string) => {
    window.open(`/${selectedAccount?.app?.code}/sales?couponIds=${couponId}`, '_blank', 'noopener,noreferrer');
  };

  const deleteCoupon = async (couponId: string) => {
    const payload = { couponId };
    try {
      setLoading(true);

      await couponsApi.deleteCoupon(payload);

      track('Coupons/deleteCouponSuccess', { payload });

      navigate('../coupons/canceled');

      enqueueSnackbar(t('snackbar.coupon_deleted_successfully'), {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(t('snackbar.could_not_delete_coupon'), {
        variant: 'error',
      });

      track('Coupons/deleteCouponFail', { error });
    } finally {
      setLoading(false);
    }
  };

  const restoreCoupon = async (couponId: string) => {
    const payload = { couponId };
    try {
      setLoading(true);

      await couponsApi.restoreCoupon(payload);

      track('Coupons/restoreCouponSuccess', { payload });

      enqueueSnackbar(t('snackbar.coupon_restored_successfully'), {
        variant: 'success',
        title: t('snackbar.coupon_restored_successfully_title'),
      });

      onRefresh();
    } catch (error) {
      const { errors } = errorHandler(error);

      let snackbarTitle;
      let snackbarMessage: string | ReactNode = t('snackbar.could_not_restore_coupon');

      if (errors?.length && errors[0].type === ErrorTypes.VALIDATION) {
        snackbarMessage = t('snackbar.could_not_restore_existed_coupon_code');
        snackbarTitle = t('snackbar.could_not_restore_existed_coupon_code_title');
      }

      enqueueSnackbar(snackbarMessage, {
        variant: 'error',
        title: snackbarTitle,
      });

      track('Coupons/restoreCouponFail', { error });
    } finally {
      setLoading(false);
      setOpenRestoreModal(false);

      setSelectedCoupon(undefined);
    }
  };

  const getActions = (coupon: Coupon) => [
    ...(!belongsToPartnership
      ? [
          <ExtendedGridActionsCellItem
            icon={<Edit05 />}
            key='coupon-edit'
            label={t('list.table.actions.edit')}
            href={`${coupon.id}/edit`}
            showInMenu
            component={Link}
          />,
        ]
      : []),

    <GridActionsCellItem
      icon={<Visibility />}
      key='coupon-view'
      onClick={() => viewCoupon(coupon.id)}
      label={t('list.table.actions.view')}
      showInMenu
    />,

    ...(belongsToPartnership && coupon.partnership
      ? [
          <ExtendedGridActionsCellItem
            href={getPartnershipDetailLink(coupon.partnership?.id ?? '')}
            component={Link}
            key='coupon-partnership'
            icon={<User01 />}
            label={t('list.table.actions.agent')}
            showInMenu
          />,
        ]
      : []),

    ...(!belongsToPartnership
      ? [
          <GridActionsCellItem
            icon={<FileX03 />}
            key='coupon-delete'
            onClick={() => deleteCoupon(coupon.id)}
            label={t('list.table.actions.cancel')}
            showInMenu
          />,
        ]
      : []),
  ];

  const columns: TableColDef[] = [
    {
      field: 'code',
      headerName: t('list.table.code'),
      sortable: false,
      renderAsLeftTitleOnMobile: true,
      flex: 1,
      ...(belongsToPartnership ? { maxWidth: 300 } : {}),
    },
    ...(belongsToPartnership
      ? [
          {
            field: 'agent',
            headerName: t('list.table.agent'),
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Link variant='smallBold' href={getPartnershipDetailLink(params.row.partnership?.id ?? '')}>
                  {params.row.partnership?.agent?.name}
                </Link>
              );
            },
            sortable: false,
            flex: 1,
            maxWidth: 300,
            enableOnMobile: true,
          },
        ]
      : []),

    ...(!belongsToPartnership
      ? [
          {
            field: 'type',
            headerName: t('list.table.discount_type.title'),
            valueGetter: (params: GridValueGetterParams) => t(`list.table.discount_type.${params.row.type}`),
            sortable: false,
            flex: 1,
          },
        ]
      : []),

    {
      field: 'discount',
      headerName: t('list.table.discount'),
      valueGetter: (params: GridValueGetterParams) => getFormattedCouponDiscountValue(params.row),
      sortable: false,
      flex: 1,
      enableOnMobile: true,
      maxWidth: 120,
    },
    {
      field: 'product.name',
      headerName: t('list.table.products'),
      renderCell: (params: GridRenderCellParams) => {
        let cellWidth = params.api.getColumn(params.field).computedWidth!;
        const products = getCouponProductsName(params.row);

        if (!products) {
          return (
            <Chip
              size='small'
              color='primary'
              variant='outlined'
              label={t('list.table.no_products')}
              sx={{ background: colors.blue[100], border: 'none' }}
            />
          );
        }

        if (params.row.constraintType === CouponConstraintType.Exclude) {
          cellWidth = cellWidth - 70; // 70px is the size of the Exceto em label
        }

        return (
          <>
            {params.row.constraintType === CouponConstraintType.Exclude ? (
              <Typography variant='body2' color='textSecondary' marginRight={2}>
                {t('list.table.constraint_type_exclude')}
              </Typography>
            ) : null}

            <DynamicChipList
              {...(isArchivedCouponList
                ? {
                    chipProps: {
                      color: 'default',
                      sx: {
                        background: colors.gray[100],
                        border: 'none',
                      },
                    },
                  }
                : {})}
              containerWidth={cellWidth}
              list={products}
            />
          </>
        );
      },
      sortable: false,
      flex: 1,
      minWidth: 70,
    },

    ...(!belongsToPartnership
      ? [
          {
            field: 'maxUse',
            headerName: t('list.table.max_use'),
            renderCell: (params: GridRenderCellParams) => {
              const { status, value } = getMaxUseValueAndStatus(params.row, t, isArchivedCouponList);

              return <StyledChip label={value} color={status} size='small' />;
            },
            flex: 1,
            sortable: false,
            enableOnMobile: true,
            maxWidth: 120,
          },
          {
            field: 'availableAt',
            headerName: t('list.table.available_at'),
            valueGetter: (params: GridValueGetterParams) =>
              params.row.availableAt ? formatDate(new Date(params.row.availableAt), 'dd/MM/yyyy - HH:mm') : '-',
            sortable: false,
            flex: 1,
            enableOnMobile: true,
          },
          {
            field: 'expiresAt',
            headerName: t('list.table.expiresAt'),
            valueGetter: (params: GridValueGetterParams) =>
              params.row.expiresAt ? formatDate(new Date(params.row.expiresAt), 'dd/MM/yyyy - HH:mm') : '-',
            sortable: false,
            flex: 1,
            enableOnMobile: true,
          },
        ]
      : []),

    {
      field: 'actions',
      headerName: t('list.table.actions.title'),
      type: 'actions',
      sortable: false,
      width: 60,
      ...(isArchivedCouponList
        ? {
            renderCell: (params: GridRenderCellParams) => (
              <IconButton
                onClick={() => {
                  setOpenRestoreModal(true);
                  setSelectedCoupon(params.row);
                }}
              >
                <FlipBackward />
              </IconButton>
            ),
          }
        : { getActions: (params: GridRowParams) => getActions(params.row) }),
    },
  ];

  return (
    <Grid item xs={12} paddingX={{ xs: 0 }}>
      <Table
        rows={coupons}
        columns={columns}
        paginationModel={paginationModel}
        paginationMode='server'
        disableColumnMenu
        loading={loading}
        rowCount={totalCoupons}
        onPaginationModelChange={(newPaginationModel) => setPaginationModel(newPaginationModel)}
        onMobileClick={({ row }) => {
          if (hasPermission('coupons', 'GetCoupon')) {
            navigate(row.id);
          }
        }}
      />

      <ModalOnDesktopDrawerOnMobile
        width={400}
        anchor='bottom'
        isOpen={openRestoreModal}
        onClose={() => setOpenRestoreModal(false)}
        title={t('canceled.restore_coupon_modal.title')}
      >
        <Box display='flex' alignItems='center' gap={1} flexDirection='row'>
          <Button
            color='primary'
            variant='contained'
            fullWidth={isMobile}
            disabled={loading}
            onClick={() => restoreCoupon(selectedCoupon?.id ?? '')}
          >
            {t('canceled.restore_coupon_modal.actions.yes')}
          </Button>

          <Button
            color='primary'
            variant='outlined'
            onClick={() => setOpenRestoreModal(false)}
            fullWidth={isMobile}
            disabled={loading}
          >
            {t('canceled.restore_coupon_modal.actions.no')}
          </Button>
        </Box>
      </ModalOnDesktopDrawerOnMobile>
    </Grid>
  );
};

export default CouponTable;
