import { ErrorSection } from '@/components/Error/ErrorSection';

interface ErrorComponentBodyProps {
  icon: string;
  title: string;
  subTitle: string;
  description: string | JSX.Element;
  children: React.ReactNode;
}

const ErrorComponentBody = (props: ErrorComponentBodyProps) => {
  const { icon, title, subTitle, description, children } = props;
  return (
    <ErrorSection.Root>
      <ErrorSection.Icon ErrorIcon={icon} alternativeText='Error Icon' />
      <ErrorSection.Body title={title} subTitle={subTitle} description={description}>
        {children}
      </ErrorSection.Body>
    </ErrorSection.Root>
  );
};

export default ErrorComponentBody;
