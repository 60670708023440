import { AppPolicyRules } from '@/interfaces/rules';
import { PolicyRule } from '@/services/SellerApi';

const validatePolicies = (
  policies: PolicyRule[] | AppPolicyRules | undefined,
  permissionCheckCb: { (namespace: string, action: string): boolean },
): boolean => {
  if (!policies) {
    return true;
  }
  if (Array.isArray(policies)) {
    return policies.every((policy) => permissionCheckCb(policy.namespace, policy.action));
  }

  const { AND = [], OR = [] } = policies;

  if (AND.length > 0) {
    return AND.every((policy) => permissionCheckCb(policy.namespace, policy.action));
  }

  if (OR.length > 0) {
    return OR.some((policy) => permissionCheckCb(policy.namespace, policy.action));
  }

  return true;
};

export default validatePolicies;
