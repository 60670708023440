import { Popover, PopoverActions, Stack, Typography } from '@mui/material';
import { XClose } from '@untitled-ui/icons-react';
import { Suspense, useEffect, useRef } from 'react';
import { useMatch } from 'react-router-dom';

import Button from '@/components/Button';
import Loading from '@/components/Loading';
import theme, { colors } from '@/theme';

// const LazyAgendaDayPopoverContent = lazy(() => import('./AgendaDayPopoverContent'));
import LazyAgendaDayPopoverContent from './AgendaDayPopoverContent';
interface Props {
  date: Date;
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
}
const AgendaDayPopover = (props: Props) => {
  const { date, onClose, isOpen, anchorEl } = props;
  const actionRef = useRef<PopoverActions>(null);

  const isAvailabilityModalOpen = useMatch('/:appId/agenda/availabilities/:availabilityId');
  const isSchedulingModalOpen = useMatch('/:appId/agenda/schedulings/:schedulingId');

  useEffect(() => {
    if (isAvailabilityModalOpen || isSchedulingModalOpen) {
      onClose();
    }
  }, [isAvailabilityModalOpen, isSchedulingModalOpen, onClose]);

  return (
    <Popover open={!!isOpen} anchorEl={anchorEl} onClose={onClose} action={actionRef}>
      <Stack sx={{ width: '17.1875rem' }}>
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '0.25rem',
            paddingX: theme.spacing(5),
            paddingTop: theme.spacing(5),
          }}
        >
          <Typography variant='largeMedium' sx={{ textTransform: 'capitalize' }}>
            {date.toLocaleString('default', { weekday: 'short' })}
          </Typography>
          <Typography variant='largeBold'>{date.getDate()}</Typography>
          <Button color='primary' variant='text' onClick={onClose} sx={{ marginLeft: 'auto' }}>
            <XClose color={colors.gray['700']} />
          </Button>
        </Stack>
        <Suspense fallback={<Loading />}>
          <LazyAgendaDayPopoverContent day={date} onUpdate={() => actionRef?.current?.updatePosition()} />
        </Suspense>
      </Stack>
    </Popover>
  );
};

export default AgendaDayPopover;
