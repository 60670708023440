import { ReactNode } from 'react';
import { Plus, Minus } from '@untitled-ui/icons-react';
import { IconButton, Typography } from '@mui/material';

import theme from '@/theme';
import { Container } from './styles';

type Props = {
  value: number;
  onChange: (value: number) => void;
  minButtonDisabled?: boolean;
  maxButtonDisabled?: boolean;
};

interface ButtonProps {
  disabled?: boolean;
  onClick: () => void;
  children: ReactNode;
}

const Button = (props: ButtonProps) => {
  const { onClick, children, disabled } = props;

  return (
    <IconButton sx={{ p: 0 }} color='primary' onClick={onClick} disabled={disabled}>
      {children}
    </IconButton>
  );
};

const Counter = (props: Props) => {
  const { value = 0, onChange, maxButtonDisabled, minButtonDisabled } = props;

  return (
    <Container>
      <Button onClick={() => onChange(value - 1)} disabled={minButtonDisabled}>
        <Minus />
      </Button>

      <Typography variant='h6' color={theme.palette.colors.gray[800]}>
        {value}
      </Typography>

      <Button onClick={() => onChange(value + 1)} disabled={maxButtonDisabled}>
        <Plus />
      </Button>
    </Container>
  );
};

export default Counter;
