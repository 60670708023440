import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Modal from '@/components/Modal';
import TermDocument from '@/components/TermDocument';

type Props = {
  documentType: 'terms_of_use' | 'privacy_policy';
};

export const TermModal = ({ documentType }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['signup']);

  return (
    <Modal open={true} width={889} showCloseButton onClose={() => navigate('/signup')} title={t(documentType)}>
      <TermDocument documentType={documentType} />
    </Modal>
  );
};

export default TermModal;
