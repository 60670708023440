import { Fragment, ReactNode, useState } from 'react';
import { Link, LinkProps, Menu, MenuItem, Typography } from '@mui/material';
import { DotsVertical } from '@untitled-ui/icons-react';

import { colors } from '@/theme';
import Button from '@/components/Button';

type Item = {
  icon: ReactNode;
  label: string;
  value?: string;
  onClick?: () => void;
  href?: string;
  target?: LinkProps['target'];
  disabled?: boolean;
};

type MobileMenuProps = {
  items: Item[];
};

export const MobileMenu = (props: MobileMenuProps) => {
  const { items } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Button onClick={handleClick} variant='outlined' color='primary' sx={{ padding: '0.5rem' }}>
        <DotsVertical />
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ '& .MuiList-root ': { padding: 0 }, zIndex: 9999 }}
      >
        {items.map((item) => (
          <MenuItem
            key={item.label}
            value={item.value}
            onClick={item.onClick}
            sx={{ display: 'flex', gap: 1 }}
            disabled={item.disabled}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            component={item.href ? Link : undefined}
            {...item}
          >
            {item.icon}
            <Typography variant='regularRegular' color={colors.gray[500]}>
              {item.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};

export default MobileMenu;
