import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';

import ArrangedProductCard from './ArrangedProductCard';
import { useSellerApi } from '@/hooks/useSellerApi';
import { ArrangedProduct, AssociationModel, ProductsApiGetAppArrangedProductsRequest } from '@/services/SellerApi';
import useAccount from '@/hooks/useAccount';
import { useEffect, useState } from 'react';
import { initialPaginationState } from '@/utils';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import { enqueueSnackbar } from 'notistack';
import EmptyPage from '@/components/EmptyPage';
import Pagination from '@/components/Pagination';
import { PaginationContainer } from '@/components/Partnership/PartnershipDetail/PartnershipCouponList/styles';
import EmptyFilterPage from '@/components/FilterDrawer/EmptyFilterPage';
import productShowCaseEmptyPageIcon from '@/assets/Icons/product-showcase-empty-page-icon.svg';
import { CartItem } from '@/contexts/PartnershipPortalCartContext';
import usePartnershipPortalCart from '@/hooks/usePartnershipPortalCart';
import { Outlet, useNavigate } from 'react-router-dom';
import useFilter from '@/hooks/useFilter';

export const ProductShowCase = () => {
  const { track } = useAnalytics();
  const { selectedAccount } = useAccount();
  const { productsApi } = useSellerApi();
  const { t } = useTranslation(['productShowCase']);

  const navigate = useNavigate();
  const { addItemToCart } = usePartnershipPortalCart();

  const [loading, setLoading] = useState(true);
  const [totalArrangedProducts, setTotalArrangedProducts] = useState(0);
  const [arrangedProducts, setArrangedProducts] = useState<ArrangedProduct[]>([]);
  const [paginationModel, setPaginationModel] = useState(initialPaginationState);

  const { appliedFilters, search } = useFilter();

  const getAppArrangedProducts = async (appId: string) => {
    setLoading(true);

    const payload: ProductsApiGetAppArrangedProductsRequest = {
      appId,
      limit: paginationModel.pageSize,
      offset: paginationModel.page * paginationModel.pageSize,
      include: [
        'partnershipItem',
        'partnershipItem.partnership',
        'partnershipItem.partnership.provider',
        'partnershipItem.partnership.publicProposal',
        'defaultTariffGroupPriceInfo',
      ],
      associationModels: [AssociationModel.DirectPurchase],
      ...(search ? { search } : {}),
      ...appliedFilters,
    };

    try {
      const { headers, data } = await productsApi.getAppArrangedProducts(payload);

      setArrangedProducts(data as unknown as ArrangedProduct[]);
      setTotalArrangedProducts(parseInt(headers['x-pagination-total-count'] ?? '0'));

      track('productShowCase/retrieveArrangedProductsSuccess', { payload });
    } catch (error) {
      enqueueSnackbar(t('snackbar.could_not_load_arranged_products'), { variant: 'error' });
      track('productShowCase/retrieveArrangedProductsFail', { payload });
    } finally {
      setLoading(false);
    }
  };

  const onAddItemToCart = (item: CartItem) => {
    track('productShowCase/addToCart', { payload: item });

    addItemToCart(item);
    navigate('.');
  };

  const onPurchaseNow = (item: CartItem) => {
    track('productShowCase/purchaseNow', { payload: item });

    addItemToCart(item);
    navigate('checkout', { replace: true });
  };

  useEffect(() => {
    if (selectedAccount) {
      getAppArrangedProducts(selectedAccount?.appId);
    }
  }, [paginationModel, search, selectedAccount, appliedFilters]);

  return (
    <Grid container paddingX={{ xs: 2, md: 3, lg: 4 }} spacing={2} mt={1} mb={2}>
      {arrangedProducts.length > 0 ? (
        <>
          <Grid item xs={12}>
            <Typography variant='smallSemiBold'>
              {t('list.products_quantity', { quantity: totalArrangedProducts })}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3} justifyContent={{ xs: 'center', md: 'flex-start' }}>
              {arrangedProducts.map((arrangedProduct) => (
                <Grid
                  item
                  xs={12}
                  sm={10}
                  md={6}
                  lg={4}
                  xl={3}
                  key={arrangedProduct.productId}
                  display={{ xs: 'flex', md: 'unset' }}
                  justifyContent={{ xs: 'center', md: 'flex-start' }}
                >
                  <ArrangedProductCard
                    arrangedProduct={arrangedProduct}
                    onAddToCartClick={() => navigate(`${arrangedProduct.productId}`)}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>

          {totalArrangedProducts > paginationModel.pageSize ? (
            <PaginationContainer item xs={12}>
              <Pagination
                page={paginationModel.page}
                count={totalArrangedProducts}
                rowsPerPageOptions={[5, 10, 20, 50]}
                rowsPerPage={paginationModel.pageSize}
                onRowsPerPageChange={(event) => {
                  setPaginationModel({ pageSize: parseInt(event.target.value, 10), page: 0 });
                }}
                onPageChange={(_, newPage) => {
                  setPaginationModel({ ...paginationModel, page: newPage });
                }}
              />
            </PaginationContainer>
          ) : null}
        </>
      ) : null}

      {!arrangedProducts.length && !loading ? (
        <Grid item xs={12} alignContent='center' justifyContent='center' mt={3}>
          {Object.keys(appliedFilters).length || search ? (
            <EmptyFilterPage />
          ) : (
            <EmptyPage
              description={t('empty_page.description')}
              Icon={<img src={productShowCaseEmptyPageIcon} alt={t('empty_page.alt')} />}
              showCicleBelowIcon={false}
            />
          )}
        </Grid>
      ) : null}

      {!arrangedProducts.length && loading ? (
        <Grid item xs={12} textAlign='center'>
          <Box display='flex' alignItems='center' justifyContent='center' height='60vh' width='100%'>
            <CircularProgress color='primary' />
          </Box>
        </Grid>
      ) : null}

      <Outlet context={{ onAddToCart: onAddItemToCart, onPurchaseNow }} />
    </Grid>
  );
};

export default ProductShowCase;
