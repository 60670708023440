import { useMemo } from 'react';
import { VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import PopoverAvailabilitySlot from '@/features/agenda/components/SchedulingCalendar/AgendaDayPopover/AgendaDayPopoverContent/AgendaDayPopoverList/AgendaDayPopoverSlot/PopoverAvailabilitySlot';
import PopoverSchedulingSlot from '@/features/agenda/components/SchedulingCalendar/AgendaDayPopover/AgendaDayPopoverContent/AgendaDayPopoverList/AgendaDayPopoverSlot/PopoverSchedulingSlot';
import { Availability, Scheduling } from '@/services/SellerApi';
import { StyledSlotContainer } from '@/features/agenda/components/SchedulingCalendar/AgendaDayPopover/AgendaDayPopoverContent/AgendaDayPopoverList/styles';

interface AgendaDayPopoverListProps {
  schedulings: Scheduling[];
  availabilities: Availability[];
  hasNextSchedulingsPage: boolean;
  onLoadMoreSchedulings?: () => void;
}

const AgendaDayPopoverList = (props: AgendaDayPopoverListProps) => {
  const { schedulings, availabilities, hasNextSchedulingsPage = true, onLoadMoreSchedulings } = props;

  const isItemLoaded = (index: number) => index < itemCount;

  const itemCount = useMemo(() => {
    const currentTotal = (schedulings?.length || 0) + (availabilities?.length || 0);
    return hasNextSchedulingsPage ? currentTotal + 1 : currentTotal;
  }, [schedulings, availabilities, hasNextSchedulingsPage]);

  const loadMoreItems = () => {
    if (onLoadMoreSchedulings && hasNextSchedulingsPage) {
      onLoadMoreSchedulings();
    }
    return Promise.resolve();
  };

  return (
    // See: https://react-window.vercel.app/#/examples/list/variable-size
    // And this: https://www.npmjs.com/package/react-window-infinite-loader

    <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={1000} loadMoreItems={loadMoreItems}>
      {({ onItemsRendered, ref }) => (
        <VariableSizeList
          height={390}
          width={242}
          itemCount={itemCount}
          itemSize={(_) => 49}
          onItemsRendered={onItemsRendered}
          ref={ref}
        >
          {({ index, style }) => {
            if (index < availabilities.length) {
              return (
                <StyledSlotContainer sx={style}>
                  <PopoverAvailabilitySlot availability={availabilities[index]} />
                </StyledSlotContainer>
              );
            }
            if (schedulings[index - availabilities.length]) {
              return (
                <StyledSlotContainer sx={style}>
                  <PopoverSchedulingSlot scheduling={schedulings[index - availabilities.length]} />
                </StyledSlotContainer>
              );
            }
            return null;
          }}
        </VariableSizeList>
      )}
    </InfiniteLoader>
  );
};

export default AgendaDayPopoverList;
