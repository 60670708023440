import { Typography } from '@mui/material';
import { IconCalendarMonth } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import { StyledModalTitle } from '@/features/agenda/components/SchedulingModal/styles';

const SchedulingModalTitle = () => {
  const { t } = useTranslation(['agenda'], { keyPrefix: 'modal' });

  return (
    <StyledModalTitle>
      <IconCalendarMonth />
      <Typography variant='h6'>{t('title')}</Typography>
    </StyledModalTitle>
  );
};

export default SchedulingModalTitle;
