import { AppBar, Toolbar } from '@mui/material';
import Button from '@/components/Button';
import { colors } from '@/theme';
import { useTranslation } from 'react-i18next';

type Props = {
  applyButtonText?: string;
  cancelButtonText?: string;
  resetButtonText?: string;
  handleApplyFilter: () => void;
  handleCloseFilters: () => void;
  handleResetFilters: () => void;
  selectedFiltersLength: number;
  appliedFiltersLength: number;
};

const FilterActions = (props: Props) => {
  const { t } = useTranslation(['ui']);
  const {
    applyButtonText = t('filter.applyButtonText'),
    cancelButtonText = t('filter.cancelButtonText'),
    resetButtonText = t('filter.resetButtonText'),
    handleApplyFilter,
    handleCloseFilters,
    handleResetFilters,
    selectedFiltersLength,
    appliedFiltersLength,
  } = props;
  return (
    <AppBar position='static' sx={{ background: colors.white[600] }} component='footer'>
      <Toolbar sx={{ flexDirection: 'column', gap: 1, padding: { xs: 2, lg: 3 } }}>
        <Button
          color='primary'
          variant='contained'
          onClick={handleApplyFilter}
          disabled={selectedFiltersLength === 0}
          fullWidth
        >
          {applyButtonText}
        </Button>

        <Button
          variant='outlined'
          fullWidth
          color='primary'
          onClick={() => handleResetFilters()}
          disabled={appliedFiltersLength === 0}
        >
          {resetButtonText}
        </Button>

        <Button variant='outlined' fullWidth color='primary' onClick={handleCloseFilters}>
          {cancelButtonText}
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default FilterActions;
