import { ErrorSectionIconWrapper } from './styles';

interface ErrorSectionIconProps {
  ErrorIcon: string;
  alternativeText: string;
}

const ErrorSectionIcon = (props: ErrorSectionIconProps) => {
  const { ErrorIcon, alternativeText } = props;
  return (
    <ErrorSectionIconWrapper>
      <img src={ErrorIcon} alt={alternativeText} />
    </ErrorSectionIconWrapper>
  );
};

export default ErrorSectionIcon;
