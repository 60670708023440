import { Stack } from '@mui/material';
import { Fragment, Suspense, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { BreadcrumbItemProps } from '@/components/Breadcrumbs';
import translatePT from '@/features/agenda/features/schedulingForm/locales/pt.json';
import translateSchedulingsPT from '@/features/agenda/locales/pt.json';
import { useSellerApi } from '@/hooks/useSellerApi';
import PageLayout from '@/layouts/PageLayout';
import i18n from '@/services/i18n';
import { Scheduling } from '@/services/SellerApi';
import theme from '@/theme';
import apiDateToDateObject from '@/utils/apiDateToDateObject';
import BackdropLoading from '@/components/BackdropLoading';
import { enqueueSnackbar } from 'notistack';
import Bugsnag from '@/services/bugsnag';
import { NotifiableError } from '@bugsnag/js';
import useAccount from '@/hooks/useAccount';

// const LazyCreateAndEditSchedulingForm = lazy(
//   () => import('@/features/agenda/features/schedulingForm/components/CreateAndEditSchedulingForm'),
// );
import LazyCreateAndEditSchedulingForm from '@/features/agenda/features/schedulingForm/components/CreateAndEditSchedulingForm';

const SchedulingFormPage = () => {
  // TODO: Move this to a Router Resolver
  i18n.addResourceBundle('pt-BR', 'agenda', translateSchedulingsPT);
  i18n.addResourceBundle('pt-BR', 'scheduling-form', translatePT);

  const { t } = useTranslation(['scheduling-form']);
  const { schedulingsApi } = useSellerApi();
  const [scheduling, setScheduling] = useState<Scheduling | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshAccountsLoading, setIsRefreshAccountsLoading] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { refreshAccounts } = useAccount();

  const isEdit = !!id;
  const pageTitle = useMemo(() => {
    if (isEdit && scheduling) {
      return `${t('edit_scheduling_title')} - ${apiDateToDateObject(scheduling.appliesAt).toLocaleDateString(
        undefined,
        {
          year: 'numeric',
          day: 'numeric',
          month: 'numeric',
        },
      )}`;
    }
    return t('new_scheduling_title');
  }, [isEdit, t, scheduling]);

  const breadcrumbs: BreadcrumbItemProps[] = [
    {
      label: t('breadcrumb_calendar'),
      to: '../agenda',
    },
    {
      label: t(isEdit ? 'edit_scheduling_title' : 'new_scheduling_title'),
    },
  ];

  const fetchScheduling = async (schedulingId: string) => {
    setIsLoading(true);
    try {
      const schedulingData = await schedulingsApi.getScheduling({
        schedulingId,
        include: ['product', 'tariffGroup'],
      });

      setScheduling(schedulingData.data);
    } catch (error) {
      enqueueSnackbar(t('get_error'), { variant: 'error' });
      Bugsnag.notify(error as NotifiableError);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAccounts = async () => {
    setIsRefreshAccountsLoading(true);
    try {
      await refreshAccounts();
    } finally {
      setIsRefreshAccountsLoading(false);
    }
  };

  useEffect(() => {
    fetchAccounts();
  }, []);

  useEffect(() => {
    if (!id) return;
    fetchScheduling(id);
  }, [id]);

  return (
    <Fragment>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PageLayout
        title={pageTitle}
        showFilterButton={false}
        showSearchInput={false}
        showGoBackButton={true}
        breadcrumbs={breadcrumbs}
      >
        <Suspense fallback={<BackdropLoading />}>
          {isLoading || isRefreshAccountsLoading ? (
            <BackdropLoading />
          ) : (
            <Stack sx={{ width: '100%', paddingX: theme.spacing(5), paddingY: theme.spacing(6) }}>
              <LazyCreateAndEditSchedulingForm scheduling={scheduling} />
            </Stack>
          )}
        </Suspense>
      </PageLayout>
    </Fragment>
  );
};

export default SchedulingFormPage;
