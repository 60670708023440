import { useMediaQuery } from '@mui/system';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import Chip from '@/components/Chip';
import {
  StyledMobileMoreButton,
  WeeklyDayContainer,
} from '@/features/agenda/components/SchedulingCalendar/AgendaWeeklyView/WeeklyDay/styles';
import WeeklySlot from '@/features/agenda/components/SchedulingCalendar/AgendaWeeklyView/WeeklySlot';
import WeeklySlotSkeleton from '@/features/agenda/components/SchedulingCalendar/AgendaWeeklyView/WeeklySlot/WeeklySlotSkeleton';
import { useAgenda } from '@/features/agenda/hooks/useAgenda';
import useLoadInfiniteAvailabilitiesQuery from '@/features/agenda/queries/useLoadInfiniteAvailabilitiesQuery';
import useLoadInfiniteSchedulingsQuery from '@/features/agenda/queries/useLoadInfiniteSchedulingsQuery';
import { AgendaConfigState, ViewDataType } from '@/features/agenda/types';
import { Scheduling } from '@/services/SellerApi';
import theme from '@/theme';
import { useConfig } from '@/features/config/useConfig';

type Props = {
  day: Date;
  nexPageResult: (hasNextPage: boolean) => void;
};

const AVAILABLE_PAGE_SIZE = 20;
const SCHEDULING_PAGE_SIZE = 20;

const WeeklyDay = forwardRef(({ day, nexPageResult }: Props, ref) => {
  const { activeDate } = useAgenda();
  const { config } = useConfig<AgendaConfigState>();
  const [schedulingsQuery] = useLoadInfiniteSchedulingsQuery();
  const [availabilitiesQuery] = useLoadInfiniteAvailabilitiesQuery();

  const viewDataType = useMemo(() => config?.viewDataType, [config]);

  const isSchedulingEnabled = useMemo(() => viewDataType?.includes(ViewDataType.SCHEDULING), [viewDataType]);
  const isAvailabilityEnabled = useMemo(() => viewDataType?.includes(ViewDataType.AVAILABILITIES), [viewDataType]);
  const dateRange = useMemo(() => ({ since: day, until: day }), [activeDate]);
  const [totalAvailabilities, setTotalAvailabilities] = useState<number | undefined>(undefined);
  const [totalSchedulings, setTotalSchedulings] = useState<number | undefined>(undefined);
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const {
    data: availabilitiesData,
    isLoading: isAvailabilitiesLoading,
    hasNextPage: hasNextAvailabilitiesPage,
    fetchNextPage: fetchNextAvailabilitiesPage,
    isFetchingNextPage: isFetchingNextAvailabilitiesPage,
  } = availabilitiesQuery(dateRange, isAvailabilityEnabled, AVAILABLE_PAGE_SIZE);

  const availabilities = useMemo(() => {
    if (availabilitiesData) {
      return availabilitiesData.pages
        .map((page) => {
          setTotalAvailabilities(page?.total || 0);
          return page?.items;
        })
        .flat();
    }
  }, [availabilitiesData]);

  const canShowAvailabilities = useMemo(
    () => availabilities && isAvailabilityEnabled,
    [availabilities, isAvailabilityEnabled],
  );

  const canShowScheduling = useMemo(() => {
    if (isSchedulingEnabled) {
      if (canShowAvailabilities && !hasNextAvailabilitiesPage) {
        return true;
      }
      if (!canShowAvailabilities) {
        return true;
      }
    }
    return false;
  }, [hasNextAvailabilitiesPage, isSchedulingEnabled, canShowAvailabilities]);

  const {
    data: schedulingsData,
    isLoading: isSchedulingsLoading,
    hasNextPage: hasNextSchedulingsPage,
    fetchNextPage: fetchNextSchedulingsPage,
    isFetchingNextPage: isFetchingNextSchedulingsPage,
  } = schedulingsQuery(dateRange, isSchedulingEnabled, SCHEDULING_PAGE_SIZE);

  const isLoading = useMemo(
    () =>
      isAvailabilitiesLoading ||
      isSchedulingsLoading ||
      isFetchingNextAvailabilitiesPage ||
      isFetchingNextSchedulingsPage,
    [isAvailabilitiesLoading, isSchedulingsLoading, isFetchingNextAvailabilitiesPage, isFetchingNextSchedulingsPage],
  );

  const hasNextPage = useMemo(
    () => hasNextAvailabilitiesPage || hasNextSchedulingsPage,
    [hasNextAvailabilitiesPage, hasNextSchedulingsPage],
  );

  const schedulings = useMemo(
    () =>
      (schedulingsData?.pages
        .map((page) => {
          setTotalSchedulings(page?.total || 0);
          return page?.items;
        })
        .flat() || []) as Scheduling[],
    [schedulingsData],
  );

  const nextPageLabel = useMemo(
    () =>
      `+${(totalAvailabilities || 0) + (totalSchedulings || 0) - (schedulings.length + (availabilities?.length || 0))}`,
    [totalAvailabilities, totalSchedulings, schedulings, availabilities],
  );

  const fetchNextPage = () => {
    fetchNextAvailabilitiesPage();
    if (canShowScheduling) {
      fetchNextSchedulingsPage();
    }
  };

  useEffect(() => {
    nexPageResult(hasNextPage);
  }, [hasNextPage]);

  useImperativeHandle(ref, () => ({
    fetchNextPage,
    hasNextPage,
  }));

  return (
    <WeeklyDayContainer>
      {canShowAvailabilities
        ? availabilities!.map((availability) => <WeeklySlot key={availability.id} availability={availability} />)
        : null}

      {canShowScheduling
        ? schedulings.map((scheduling) => <WeeklySlot key={scheduling.id} scheduling={scheduling} />)
        : null}

      {isLoading ? <WeeklySlotSkeleton /> : null}

      {hasNextPage && !isDesktop ? (
        <StyledMobileMoreButton onClick={() => fetchNextPage()} disabled={isDesktop}>
          <Chip label={nextPageLabel} size='medium' sx={{ width: '100%' }} />
        </StyledMobileMoreButton>
      ) : null}
    </WeeklyDayContainer>
  );
});

export default WeeklyDay;
