import { DetailedSaleItem, Sale, SalesApiGetSaleRequest, Voucher } from '@/services/SellerApi';
import { QueryObserverResult, UseQueryResult } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { createContext, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { IncomeSchedulingResponse } from '../useCases/useGetSaleIncomeSchedulings';
import { initialPaginationState } from '@/utils';
import { DetailedSaleItemsResponse } from '../useCases/useGetSaleItems';
import { useSellerApi } from '@/hooks/useSellerApi';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import useLoadSaleItems from '../queries/useLoadSaleItems';
import useLoadSaleVouchers from '../queries/useLoadSaleVouchers';
import useLoadSaleReceipt from '../queries/useLoadSaleReceipt';
import useLoadSaleIncomeSchedulings from '../queries/useLoadSaleIncomeSchedulings';
import Bugsnag from '@/services/bugsnag';
import { NotifiableError } from '@bugsnag/js';
import { enqueueSnackbar } from 'notistack';
import { AnalyticsEvents } from '@/hooks/analytics/enum/analyticsEvents';

interface PaginationModal {
  paginationModel: typeof initialPaginationState;
  setPaginationModel: Dispatch<SetStateAction<typeof initialPaginationState>>;
}

export type SaleItemsData = UseQueryResult<DetailedSaleItemsResponse | undefined, Error> & PaginationModal;

interface SaleDetailContextType {
  sale: Sale;
  refetchSale: () => Promise<void>;
  refetchSaleItems: () => Promise<QueryObserverResult<DetailedSaleItemsResponse | undefined, Error>>;
  loading: boolean;
  saleVouchersData: UseQueryResult<Voucher[] | undefined, Error>;
  saleItemsData: SaleItemsData;
  saleReceiptData: UseQueryResult<DetailedSaleItem[] | undefined, Error>;
  saleIncomeSchedulingsData: UseQueryResult<IncomeSchedulingResponse | undefined, Error> & PaginationModal;
}

// eslint-disable-next-line react-refresh/only-export-components
export const Context = createContext<SaleDetailContextType | undefined>(undefined);

interface SaleDetailContextProviderProps {
  children: React.ReactNode;
}

const SaleDetailContextProvider = ({ children }: SaleDetailContextProviderProps) => {
  const { track } = useAnalytics();
  const { salesApi } = useSellerApi();
  const { t } = useTranslation(['saleDetails']);
  const { saleId } = useParams<{ saleId: string }>();

  const [sale, setSale] = useState<Sale>();
  const [loading, setLoading] = useState(true);
  const [saleItemsPaginationModel, setSaleItemsPaginationModel] = useState(initialPaginationState);
  const [incomeSchedulingsPaginationModel, setIncomeSchedulingsPaginationModel] = useState(initialPaginationState);

  const [getSaleItems] = useLoadSaleItems();
  const [getSaleVouchers] = useLoadSaleVouchers();
  const [getSaleReceipt] = useLoadSaleReceipt();
  const [getSaleIncomeSchedulings] = useLoadSaleIncomeSchedulings();

  const saleItemsData = getSaleItems(
    saleId ?? '',
    saleItemsPaginationModel.pageSize,
    saleItemsPaginationModel.page * saleItemsPaginationModel.pageSize,
  );

  const saleVouchersData = getSaleVouchers(saleId ?? '');
  const saleReceiptData = getSaleReceipt(saleId ?? '');

  const saleIncomeSchedulingsData = getSaleIncomeSchedulings(
    saleId ?? '',
    incomeSchedulingsPaginationModel.pageSize,
    incomeSchedulingsPaginationModel.page * incomeSchedulingsPaginationModel.pageSize,
  );

  const getSale = async (saleId: string) => {
    setLoading(true);

    const payload: SalesApiGetSaleRequest = {
      saleId,
      include: [
        'customer',
        'aggregatedVouchers',
        'aggregatedRefundRequests',
        'tags',
        'origin',
        'coupon.products',
        'decisiveTransaction',
        'decisiveTransaction.paymentMethod',
        'coupon.products.product',
        'order',
        'order.decisiveTransaction',
        'order.decisiveTransaction.paymentMethod',
      ],
    };

    try {
      const { data } = await salesApi.getSale(payload);

      setSale(data);

      track(AnalyticsEvents.GET_SALE_DETAIL, { payload });
    } catch (error) {
      enqueueSnackbar(t('snackbars.could_not_load_sale'), { variant: 'error' });

      track(AnalyticsEvents.GET_SALE_DETAIL_FAIL, { payload, error });

      Bugsnag.notify(error as NotifiableError);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (saleId) {
      getSale(saleId);
    } else {
      setLoading(false);
    }
  }, [saleId]);

  const value = useMemo(
    () => ({
      sale: sale!,
      refetchSale: () => getSale(saleId ?? ''),
      refetchSaleItems: () => saleItemsData.refetch(),
      loading,
      saleVouchersData,
      saleReceiptData,
      saleItemsData: {
        ...saleItemsData,
        paginationModel: saleItemsPaginationModel,
        setPaginationModel: setSaleItemsPaginationModel,
      },
      saleIncomeSchedulingsData: {
        ...saleIncomeSchedulingsData,
        paginationModel: incomeSchedulingsPaginationModel,
        setPaginationModel: setIncomeSchedulingsPaginationModel,
      },
    }),
    [sale, loading, saleVouchersData, saleReceiptData, saleItemsData, saleIncomeSchedulingsData],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default SaleDetailContextProvider;
