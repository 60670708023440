import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery } from '@mui/material';
import { IconCheck, IconCircleCheck } from '@tabler/icons-react';

import theme from '@/theme';
import Button from '@/components/Button';
import VouchersChip from '../VouchersChip';
import { Voucher } from '@/services/SellerApi';
import useAccount from '@/hooks/useAccount';
import Tooltip from '@/components/Tooltip';

interface Props {
  voucher: Voucher;
  consumeVoucher: (voucher: Voucher) => void;
}
export const ConsumeCell = (props: Props) => {
  const { voucher, consumeVoucher } = props;

  const { hasPermission } = useAccount();
  const { t } = useTranslation(['vouchers']);
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  if (voucher.revokedAt) {
    return (
      <VouchersChip
        size='small'
        label={t('table.consumption.revoked')}
        color={{
          font: theme.palette.colors.gray[700],
          background: theme.palette.colors.gray[isTablet ? 200 : 100],
        }}
      />
    );
  }

  if (voucher.consumedAt || voucher.exceptionallyConsumedAt) {
    return (
      <Box sx={{ width: 160 }}>
        <VouchersChip
          icon={
            <Box>
              <IconCircleCheck size={20} color={theme.palette.colors.blue[500]} />
            </Box>
          }
          sx={{
            height: 'auto',
            '& .MuiChip-label': {
              display: 'block',
              whiteSpace: 'normal',
            },
            padding: '4px',
          }}
          label={`${
            voucher.exceptionallyConsumedAt && !voucher.consumedAt
              ? t('table.consumption.consumed_exceptionally') + ' - '
              : ''
          }
          ${format(new Date(voucher.consumedAt ?? voucher.exceptionallyConsumedAt ?? ''), 'dd/MM/yyyy HH:mm')}`}
        />
      </Box>
    );
  }

  const renderButton = (disabled?: boolean) => (
    <Button
      color='primary'
      variant='contained'
      startIcon={<IconCheck />}
      sx={{ paddingY: '10px' }}
      onClick={() => consumeVoucher(voucher)}
      disabled={disabled}
    >
      {t('table.consumption.button')}
    </Button>
  );

  return !hasPermission('vouchers', 'ConsumeVoucher') ? (
    <Tooltip width={210} title={t('forbidden_tooltip')}>
      {renderButton(!hasPermission('vouchers', 'ConsumeVoucher'))}
    </Tooltip>
  ) : (
    renderButton()
  );
};

export default ConsumeCell;
