import { Fragment, ReactNode } from 'react';
import { Grid, Stack, Typography, Divider } from '@mui/material';
import { IconHelp } from '@tabler/icons-react';
import theme from '@/theme';

import Tooltip from '@/components/Tooltip';

interface Props {
  children: ReactNode;
  title: string;
  tooltipTitle?: string;
}

const FormSession = (props: Props) => {
  const { title, children, tooltipTitle } = props;

  return (
    <Fragment>
      <Grid item xs={12} sx={{ gap: '0.5rem' }}>
        <Stack sx={{ gap: '0.5rem' }}>
          <Stack width='100%' direction='row' justifyContent='space-between' alignItems='center'>
            <Typography color={theme.palette.colors.gray[700]} variant='regularBold'>
              {title}
            </Typography>

            {tooltipTitle ? (
              <Tooltip placement='right' title={tooltipTitle} textAlign='left'>
                <IconHelp color={theme.palette.colors.gray[700]} size={24} />
              </Tooltip>
            ) : null}
          </Stack>
          <Divider />
        </Stack>
      </Grid>

      {children}
    </Fragment>
  );
};

export default FormSession;
