import { useEffect, useState } from 'react';
import { Grid, Link } from '@mui/material';
import { Product, SellingMode } from '@/services/SellerApi';
import { useSellerApi } from '@/hooks/useSellerApi';
import Loading from '@/components/Loading';
import { useTranslation } from 'react-i18next';
import SelectedItems from '../SelectedItems';
import Alert from '@/components/Alert';
import Input from '@/components/Form/Input';
import useAccount from '@/hooks/useAccount';
import useProductList from '@/features/agenda/features/availability/hooks/useProductList';
import { uniq } from 'lodash';

type ItemCardProps<T, F> = {
  product: ProductWithItems<T, F>;
  items: F[];
  selectedItems: T[];
  disabled: boolean;
  onClick: (item: T, product: ProductWithItems<T, F>) => void;
};

type Props<T, F> = {
  value?: T[];
  onChange?: (items: T[]) => void;
  disabled?: boolean;
  getProductItems: (productId: string) => Promise<F[] | undefined>;
  getItemProductId: (item: T) => string;
  productHasSelectedItems: (product: ProductWithItems<T, F>, selectedItems: T[]) => boolean;
  itemCard: (props: ItemCardProps<T, F>) => JSX.Element;
  isItemEquals: (item1: T, item2: T) => boolean;
  error?: boolean;
  helperText?: string;
};

export type ProductWithItems<T, F> = Product & { items: T[] | NonNullable<Awaited<T>> | F[] | NonNullable<Awaited<F>> };

const ProductItemPicker = <T, F>(props: Props<T, F>) => {
  const {
    getProductItems,
    getItemProductId,
    productHasSelectedItems,
    itemCard,
    onChange = () => {},
    isItemEquals,
    value = [],
    error = false,
    helperText,
    disabled = false,
  } = props;

  const { t } = useTranslation(['availability']);

  const { products, loading, hasMore, search, changeSearch, loadMore } = useProductList([
    SellingMode.WithDateOnly,
    SellingMode.WithDateAndTime,
  ]);

  const { selectedAccount } = useAccount();
  const { productsApi } = useSellerApi();

  const [selectedProductsWithItems, setSelectedProductsWithItems] = useState<ProductWithItems<T, F>[]>([]);
  const [productsWithItems, setProductsWithItems] = useState<ProductWithItems<T, F>[]>([]);
  const [loadingItems, setLoadingItems] = useState(false);

  const getCreateProductWithItems = async (
    products: Product[],
    setValue: (value: ProductWithItems<T, F>[]) => void,
    value: ProductWithItems<T, F>[],
  ) => {
    setLoadingItems(true);
    const itemsPromises = products.map((product) => getProductItems(product.id));

    const items = await Promise.all(itemsPromises);

    setValue([...value, ...products.map((product, index) => ({ ...product, items: items[index] ?? [] }))]);
    setLoadingItems(false);
  };

  const productsWithoutSelectedItems = productsWithItems.filter((product) => !productHasSelectedItems(product, value));

  const isItemSelected = (item: T) => {
    return value.some((selectedItem) => isItemEquals(selectedItem, item));
  };

  const handleSelect = (item: T, product: ProductWithItems<T, F>) => {
    let items = [...value];
    let selectedProducts = [...selectedProductsWithItems];

    if (isItemSelected(item)) {
      items = items.filter((selectedItem) => !isItemEquals(selectedItem, item));
    } else {
      items = [...items, item];
    }

    const productIds = items.map((i) => getItemProductId(i));
    const selectedProductIds = selectedProducts.map((p) => p.id);

    if (productIds.includes(product.id) && !selectedProductIds.includes(product.id)) {
      selectedProducts = [...selectedProducts, product];
    }

    if (!productIds.includes(product.id) && selectedProductIds.includes(product.id)) {
      selectedProducts = selectedProducts.filter((selectedProduct) => selectedProduct.id !== product.id);
    }

    setSelectedProductsWithItems(selectedProducts);
    onChange(items);
  };

  const onSearchChange = (value: string) => {
    changeSearch(value);
  };

  const loadMissing = async () => {
    const productIds = productsWithItems.map((product) => product.id);

    const selectedItemsProductIds = uniq(value.map((i) => getItemProductId(i))) ?? [];

    const productsToFetch = selectedItemsProductIds.filter((productId) => !productIds.includes(productId));
    const productsToAdd = productsWithItems.filter((product) => value.some((i) => getItemProductId(i) === product.id));

    for (const product of productsToAdd) {
      setSelectedProductsWithItems([...selectedProductsWithItems, product]);
    }

    if (productsToFetch.length > 0) {
      const { data } = await productsApi.getAppProducts({
        appId: selectedAccount?.app?.id ?? '',
        offset: 0,
        limit: 100,
        activeOnly: false,
        ids: productsToFetch.map((id) => id),
      });

      if (data.length) {
        getCreateProductWithItems(data, setSelectedProductsWithItems, selectedProductsWithItems);
      }
    }
  };

  const renderItemCard = (product: ProductWithItems<T, F>, key: string) => {
    return (
      <Grid item xs={12} sm={6} md={6} lg={4} key={key}>
        {itemCard({
          product: product,
          items: product.items as F[],
          selectedItems: value,
          disabled: disabled,
          onClick: handleSelect,
        })}
      </Grid>
    );
  };

  useEffect(() => {
    if (products.length > 0) {
      getCreateProductWithItems(products, setProductsWithItems, productsWithItems);
    }
  }, [products]);

  useEffect(() => {
    setProductsWithItems([]);
  }, [search]);

  useEffect(() => {
    loadMissing();
  }, []);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <SelectedItems>
          {selectedProductsWithItems.map((product) => renderItemCard(product, `selected-${product.id}`))}
        </SelectedItems>
      </Grid>

      {!disabled ? (
        <>
          <Grid item xs={12}>
            <Grid container justifyContent='flex-end' spacing={6}>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Input
                  label={t('form.filters.label')}
                  placeholder={t('form.filters.placeholder')}
                  onChange={({ target }) => onSearchChange(target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={6}>
              {productsWithoutSelectedItems.map((product) =>
                (product.items as T[]).length > 0 ? renderItemCard(product, `to-select-${product.id}`) : null,
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent='center'>
              <Grid item>
                {loading || loadingItems ? <Loading /> : null}

                {!loading && hasMore ? <Link onClick={() => loadMore()}>{t('form.load_more')}</Link> : null}
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : null}

      {helperText ? (
        <Grid item xs={12}>
          <Alert severity={error ? 'error' : 'info'}>{helperText}</Alert>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default ProductItemPicker;
