import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import BotErrorIcon from '@/assets/Icons/bot-error.svg';
import Button from '@/components/Button';
import ErrorComponent from '@/components/Error/ErrorComponent';
import MobileHeaderLayout from '@/layouts/MobileHeaderLayout';

export const Unauthorized = () => {
  const { t } = useTranslation(['errors'], {
    keyPrefix: 'unauthorizedError',
  });

  return (
    <MobileHeaderLayout>
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>

      <ErrorComponent.Root>
        <ErrorComponent.Body
          title={t('title')}
          subTitle={t('sub_title')}
          description={
            <Trans
              i18nKey={t('description')}
              components={{
                anchor: <a href='https://ajuda.planne.com.br/kb'>EMPTY</a>,
              }}
            />
          }
          icon={BotErrorIcon}
        >
          <Button fullWidth variant='contained' size='small' color='primary' href='https://ajuda.planne.com.br/kb'>
            {t('button.help')}
          </Button>
          <Button fullWidth variant='outlined' size='small' color='primary' href='/'>
            {t('button.home')}
          </Button>
        </ErrorComponent.Body>
      </ErrorComponent.Root>
    </MobileHeaderLayout>
  );
};

export default Unauthorized;
