import { Typography } from '@mui/material';
import { IconBarrierBlock } from '@tabler/icons-react';
import { StyledModalTitle } from './styles';

const AvailabilityModalTitle = () => {
  return (
    <StyledModalTitle>
      <IconBarrierBlock />

      <Typography variant='h6'>Limite</Typography>
    </StyledModalTitle>
  );
};

export default AvailabilityModalTitle;
