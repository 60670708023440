import { Availability } from '@/services/SellerApi';

export const getAvailabilityQuantitiesText = (availability: Availability) => {
  const { occupation, quantity } = availability;

  if (occupation?.occupiedAmount !== null && (occupation?.occupiedAmount ?? 0) >= 0) {
    return `${occupation?.occupiedAmount}/${quantity}`;
  }

  return quantity;
};
