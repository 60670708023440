import { Box, styled } from '@mui/material';

export const ImageWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'error',
})<{ error?: boolean }>(({ theme, error = false }) => ({
  width: '57px',
  height: '38px',
  display: 'flex',
  marginLeft: '-8px',
  marginRight: '2px',
  alignItems: 'center',
  justifyContent: 'center',
  borderRight: '1px solid',
  borderColor: error ? theme.palette.error.main : theme.palette.grey[400],
}));
