import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatISO, isValid } from 'date-fns';

import ComposedDatePicker from '@/components/ComposedDatePicker';
import useFilter from '@/hooks/useFilter';
import Select from '@/components/Form/Select';
import { FilterOption } from '@/contexts/FilterContext';
import { limitTags } from '@/utils';

export const CouponFilterFields = () => {
  const { selectedFilters, handleChangeFilters, schema, composeKey } = useFilter();

  const { t } = useTranslation(['coupons']);

  const dateParser = (value?: string | null) => {
    if (!value) return null;

    return new Date(value);
  };

  return (
    <Box display='flex' gap={2} flexDirection='column'>
      <Select
        fullWidth
        multiple
        checkbox
        selectAllOption={!!schema!.productIds?.options?.length}
        disableCloseOnSelect
        limitTags={limitTags}
        loading={schema!.productIds.loading}
        loadingText={t('coupon_form.products_ids_loading_text')}
        options={schema!.productIds.options ?? []}
        onChange={(_, value) => {
          const newValue = value as FilterOption[];

          handleChangeFilters(
            'productIds',
            newValue.map((p) => p.value),
          );
        }}
        value={
          schema!.productIds?.options?.length
            ? ((selectedFilters?.productIds as string[])?.map(
                (productId) => schema!.productIds.options!.find((option) => option.value === productId)!,
              ) ?? [])
            : []
        }
        label={t('filter.product_selector_label')}
        placeholder={t('coupon_form.products_ids_placeholder')}
        getOptionLabel={(option) => (option as FilterOption).label}
      />

      <ComposedDatePicker
        since={dateParser(selectedFilters?.validSince as string)}
        until={dateParser(selectedFilters?.validUntil as string)}
        onChange={(date, key) => {
          if (isValid(date)) {
            handleChangeFilters(composeKey(key, 'valid'), formatISO(date as Date));
          }
        }}
        slotProps={{
          since: {
            label: t('filter.since.label'),
            tooltipProps: {
              placement: 'left',
              title: t('filter.since.tooltip_helper_description'),
            },
          },
          until: {
            label: t('filter.until.label'),
            tooltipProps: {
              placement: 'left',
              title: t('filter.until.tooltip_helper_description'),
            },
          },
          wrapper: {
            gap: 2,
          },
        }}
      />
    </Box>
  );
};

export default CouponFilterFields;
