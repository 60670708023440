import { AgendaConfigState, ViewDataType, ViewType } from '@/features/agenda/types';
import { usePersistenceProxy } from '@/features/config/context/persistenceProxy';
import { ConfigContextStore, UseConfig } from '@/features/config/types/config';
import useConfigManager from '@/features/config/useConfigManager';
import useGetLocalStoragePersistKey from '@/features/config/useGetLocalStoragePersistKey';
import normalizeArray from '@/utils/normalizeArray';

interface AgendaConfigStore extends ConfigContextStore<AgendaConfigState> {}

const baseConfig = {
  products: [],
  viewDataType: [ViewDataType.SCHEDULING],
  availabilityType: [],
  activeDate: new Date().toISOString().split('T')[0],
  viewType: ViewType.WEEK,
};

const useAgendaConfig = (): AgendaConfigStore => {
  const key = useGetLocalStoragePersistKey({
    pageId: 'agenda',
  });

  const context = {
    localStorageKey: key,
    keys: [
      {
        stateKey: 'config.products',
        urlKey: 'products',
        persistOnLocalStorage: true,
        formatFn: (value: string) => normalizeArray(value.split(',')),
      },
      {
        stateKey: 'config.viewDataType',
        urlKey: 'viewDataType',
        persistOnLocalStorage: true,
        formatFn: (value: string) => normalizeArray(value.split(',')),
      },
      {
        stateKey: 'config.availabilityType',
        urlKey: 'availabilityType',
        persistOnLocalStorage: true,
        formatFn: (value: string) => normalizeArray(value.split(',')),
      },
      {
        stateKey: 'config.activeDate',
        urlKey: 'date',
        persistOnLocalStorage: true,
      },
      {
        stateKey: 'config.viewType',
        urlKey: 'view',
        persistOnLocalStorage: true,
      },
    ],
  } as AgendaConfigStore;

  const configWithProxy = usePersistenceProxy<UseConfig<AgendaConfigStore>>({
    localStorageKey: context.localStorageKey,
    keys: context.keys,
    config: baseConfig,
    defaultConfig: baseConfig,
  });

  const { config, patchConfig, clearConfig, defaultConfig } = useConfigManager<AgendaConfigStore>(configWithProxy);

  return {
    ...context,
    config,
    patchConfig,
    clearConfig,
    defaultConfig,
  } as ConfigContextStore<AgendaConfigState>;
};

export default useAgendaConfig;
