import { useTranslation } from 'react-i18next';
import Select from '@/components/Form/Select';
import { formatISO, isValid } from 'date-fns';
import { Fragment } from 'react';
import ComposedDatePicker from '@/components/ComposedDatePicker';
import useFilter from '@/hooks/useFilter';
import { FilterOption } from '@/contexts/FilterContext';

const Filters = () => {
  const { t } = useTranslation(['commissions']);

  const { selectedFilters, handleChangeFilters, schema } = useFilter();

  const dateParser = (value?: string | null) => {
    if (!value) return null;

    return new Date(value);
  };

  return (
    <Fragment>
      <Select
        multiple
        fullWidth
        openOnFocus
        filterSelectedOptions
        label={t('filter.provider_label')}
        loading={schema!.providerIds?.loading}
        options={schema!.providerIds?.options ?? []}
        getOptionLabel={(option) => (option as FilterOption).label}
        isOptionEqualToValue={(option, value) => (option as FilterOption).value === value}
        onChange={(_, value) => {
          const newValue = value as FilterOption[];

          handleChangeFilters(
            'providerIds',
            newValue.map((p) => p.value),
          );
        }}
        value={
          schema!.providerIds.options
            ? (selectedFilters?.providerIds as string[])?.map(
                (productId) => schema!.providerIds.options!.find((option) => option.value === productId)!,
              ) ?? []
            : []
        }
      />

      <ComposedDatePicker
        since={dateParser(selectedFilters?.since as string)}
        until={dateParser(selectedFilters?.until as string)}
        onChange={(date, inputName) => {
          if (isValid(date)) {
            handleChangeFilters(inputName, formatISO(date as Date));
          }
        }}
        slotProps={{
          since: {
            label: t('filter.sale_date_since_label'),
          },
          until: {
            label: t('filter.sale_date_until_label'),
          },
        }}
      />

      <ComposedDatePicker
        since={dateParser(selectedFilters?.sinceScheduleDate as string)}
        until={dateParser(selectedFilters?.untilScheduleDate as string)}
        slotProps={{
          since: {
            label: t('filter.schedule_date_since_label'),
            name: 'sinceScheduleDate',
          },
          until: {
            label: t('filter.schedule_date_until_label'),
            name: 'untilScheduleDate',
          },
        }}
        onChange={(date, inputName) => {
          if (isValid(date)) {
            handleChangeFilters(inputName, formatISO(date as Date));
          }
        }}
      />
    </Fragment>
  );
};

export default Filters;
