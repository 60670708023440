import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAuth from '@/contexts/Auth/hooks/useAuth';
import Alert from '@/components/Alert';
import Loading from '@/components/Loading';
import Document from '@/components/Document';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import useErrorHandler from '@/hooks/useErrorHandler';
import { AgreementDocument } from '@/services/SellerApi';

type Props = {
  documentType: 'terms_of_use' | 'privacy_policy';
};

export const TermDocument = (props: Props) => {
  const { documentType } = props;

  const { track } = useAnalytics();
  const { errorHandler } = useErrorHandler();
  const [loading, setLoading] = useState(true);
  const { getPrivacyPolicy, getTermsOfUse } = useAuth();
  const [error, setError] = useState<string | null>(null);
  const { t: errorTranslation } = useTranslation(['errors']);
  const [document, setDocument] = useState<AgreementDocument | null>(null);

  const loadDocument = async () => {
    try {
      setLoading(true);
      const termsToLoad = documentType === 'terms_of_use' ? getTermsOfUse : getPrivacyPolicy;
      const data = await termsToLoad();
      setDocument(data);
    } catch (err) {
      const trackPath = 'Document/loadDocument/error';
      const { errors } = errorHandler(err, trackPath);

      if (errors) {
        const message = errorTranslation(errors[0].rule);
        setError(message);
        track(trackPath, { message });
        return;
      }

      setError(errorTranslation('generic_default_error'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (documentType) {
      loadDocument();
    }
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {document ? <Document document={document} /> : null}
          {error ? <Alert severity='error' message={error} /> : null}
        </>
      )}
    </>
  );
};

export default TermDocument;
