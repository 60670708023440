import { SplitFactoryProvider } from '@splitsoftware/splitio-react';
import Cookies from 'js-cookie';
import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { v4 as UUID } from 'uuid';

import useAuth from '@/contexts/Auth/hooks/useAuth';
import { useSellerApi } from '@/hooks/useSellerApi';
import { Account, PolicyRule, PolicyRuleNamespaceEnum } from '@/services/SellerApi';
import { AuthStatus } from '@/interfaces/auth';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import isDeeplyEqual from '@/utils/isDeeplyEqual';

const lastAppUsedCookieName = import.meta.env.VITE_LAST_APP_USED_COOKIE_NAME ?? '@planne/lastAppUsed';

export type AccountSelectorListLayoutType = 'grid' | 'list';

// eslint-disable-next-line react-refresh/only-export-components
export const AccountSelectorListLayout: { [key: string]: AccountSelectorListLayoutType } = {
  Grid: 'grid',
  List: 'list',
};

interface HasPermissionFunction {
  (namespace: PolicyRuleNamespaceEnum, action: string): boolean;
  /** @deprecated
   * Use namespace with PolicyRuleNamespaceEnum enum instead
   */
  (namespace: string, action: string): boolean;
}

export type AccountContextType = {
  initialized: boolean;
  selectedAccount: Account | null;
  accounts: Account[];
  accountSelectorOpen: boolean;
  policies: PolicyRule[];
  setAccountSelectorOpen: (open: boolean) => void;
  selectAccount: (app: Account) => void;
  selectAccountWithCode: (code: string) => void;
  setAccounts: (accounts: Account[]) => void;
  getAccounts: () => Promise<Account[]>;
  getAccountPolicies: (account: Account) => Promise<PolicyRule[]>;
  hasPermission: HasPermissionFunction;
  refreshAccounts: () => Promise<void>;
  accountSelectorListLayout: AccountSelectorListLayoutType;
  setAccountSelectorListLayout: (layout: AccountSelectorListLayoutType) => void;
};

const defaultState: AccountContextType = {
  initialized: false,
  selectedAccount: null,
  accounts: [],
  accountSelectorOpen: false,
  policies: [],
  setAccountSelectorOpen: () => {},
  selectAccount: () => {},
  selectAccountWithCode: () => {},
  setAccounts: () => {},
  getAccounts: () => Promise.resolve([]),
  getAccountPolicies: () => Promise.resolve([]),
  hasPermission: () => false,
  refreshAccounts: () => Promise.resolve(),
  accountSelectorListLayout: AccountSelectorListLayout.Grid,
  setAccountSelectorListLayout: () => {},
};

// eslint-disable-next-line react-refresh/only-export-components
export const AccountContext = createContext<AccountContextType>(defaultState);

export type AccountProviderProps = {
  children: ReactNode;
};

export const AccountProvider = ({ children }: AccountProviderProps) => {
  const { status: authStatus, user, accessToken } = useAuth();
  const { accountApi, policiesApi } = useSellerApi();

  const [selectedAccount, setSelectedAccount] = useState<Account | null>(defaultState.accounts[0]);
  const [accounts, setAccounts] = useState<Account[]>(defaultState.accounts);
  const [accountSelectorOpen, setAccountSelectorOpen] = useState<boolean>(defaultState.accountSelectorOpen);
  const [initialized, setInitialized] = useState<boolean>(defaultState.initialized);
  const [policies, setPolicies] = useState<PolicyRule[]>(defaultState.policies);

  const [accountSelectorListLayout, setAccountSelectorListLayout] = useState<AccountSelectorListLayoutType>(
    defaultState.accountSelectorListLayout,
  );

  const { identify } = useAnalytics();

  const getAccounts = async () => {
    const { data } = await accountApi.getOwnUserAccounts({ include: ['app.plan', 'role', 'app'] });

    const filteredData = data.filter((acc) => acc.app?.code);

    const userApps: string[] = [];

    filteredData.map((account) => {
      userApps.push(account.app!.name);
    });

    if (user) {
      identify(user.email as string, {
        name: user.name,
        $email: user.email,
        id: user.id,
        createdAt: user.createdAt,
        apps: userApps,
      });
    }

    return filteredData.sort((a, b) => a.app!.name.localeCompare(b.app!.name));
  };

  const refreshAccounts = async () => {
    const accs = await getAccounts();

    setAccounts(accs);

    if (selectedAccount) {
      const account = accs.find((acc) => acc.id === selectedAccount.id);
      if (account) {
        selectAccount(account);
      }
    }
  };

  const getAccountPolicies = async (account: Account) => {
    const { data } = await policiesApi.getRolePolicies({ roleId: account!.role!.id });

    return data;
  };

  const selectAccountWithCode = async (code: string) => {
    const account = accounts.find((acc) => acc.app?.code === code);

    if (account) {
      await selectAccount(account);
    }
  };

  const selectAccount = async (account: Account) => {
    const newPolicies = await getAccountPolicies(account);

    if (selectedAccount) {
      const hasSamePolicies = isDeeplyEqual(policies, newPolicies);
      const isSameAccount = isDeeplyEqual(selectedAccount, account);
      if (isSameAccount && hasSamePolicies) {
        return;
      }
    }

    Cookies.set(lastAppUsedCookieName, account.app?.code ?? '', { expires: 365 * 24 * 60 * 60 });

    setSelectedAccount(account);
    setPolicies(newPolicies);
  };

  const hasPermission = (namespace: string, action: string) =>
    policies.some((policy) => policy.namespace === namespace && (policy.action === action || policy.action === '*'));

  const initialize = async () => {
    if (authStatus === AuthStatus.AUTHENTICATED) {
      const accs = await getAccounts();

      setAccounts(accs);

      if (accs.length > 0) {
        const lastAppCodeUsed = Cookies.get(lastAppUsedCookieName);

        if (lastAppCodeUsed) {
          const account = accs.find((acc) => acc.app?.code === lastAppCodeUsed);

          if (account) {
            await selectAccount(account);
          } else {
            await selectAccount(accs[0]);
          }
        } else {
          await selectAccount(accs[0]);
        }
      }

      setInitialized(true);
    } else {
      setAccounts([]);
      setPolicies([]);
      setSelectedAccount(null);
      setInitialized(false);
    }
  };

  useEffect(() => {
    initialize();
  }, [authStatus, user, accessToken]);

  const sdkConfig: SplitIO.IBrowserSettings = useMemo(() => {
    const config = {
      core: {
        authorizationKey: import.meta.env.VITE_SPLIT_IO_TOKEN,
        key: selectedAccount?.id ?? UUID(),
      },
    };

    return config;
  }, [selectedAccount]);

  const splitIoAttributes: SplitIO.Attributes = useMemo(
    () => ({
      appName: selectedAccount?.app?.name || '',
      appId: selectedAccount?.app?.id || '',
      appCode: selectedAccount?.app?.code || '',
      roleId: selectedAccount?.role?.id || '',
      roleName: selectedAccount?.role?.name || '',
      accountId: selectedAccount?.id || '',
      planId: selectedAccount?.app?.plan?.id || '',
      planName: selectedAccount?.app?.plan?.name || '',
      sellerType: selectedAccount?.app?.plan?.sellerType || '',
      isSuperAdmin: user?.isSuperAdmin || false,
    }),
    [selectedAccount],
  );

  return (
    <AccountContext.Provider
      value={{
        initialized,
        selectedAccount,
        accounts,
        policies,
        accountSelectorOpen,
        selectAccount,
        selectAccountWithCode,
        setAccounts,
        getAccounts,
        getAccountPolicies,
        setAccountSelectorOpen,
        hasPermission,
        refreshAccounts,
        accountSelectorListLayout,
        setAccountSelectorListLayout,
      }}
    >
      <SplitFactoryProvider config={sdkConfig} attributes={splitIoAttributes}>
        <>{children}</>
      </SplitFactoryProvider>
    </AccountContext.Provider>
  );
};

export default AccountProvider;
