import { IconButton } from '@mui/material';
import { IconArrowLeft } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

interface Props {
  iconSize?: string | number;
  onGoBack?: () => void;
}

const BackButton = (props: Props) => {
  const navigate = useNavigate();
  const { iconSize, onGoBack = () => navigate(-1) } = props;
  return (
    <IconButton
      onClick={onGoBack}
      size='small'
      color='primary'
      sx={{ padding: 0, fontSize: '16px' }}
      data-testid='header-back-button'
    >
      <IconArrowLeft size={iconSize ?? 24} />
    </IconButton>
  );
};

export default BackButton;
