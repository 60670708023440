import { Menu, MenuItem } from '@mui/material';
import { ChevronDown } from '@untitled-ui/icons-react';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/Button';
import { useAgenda } from '@/features/agenda/hooks/useAgenda';
import { ViewType } from '@/features/agenda/types';

const RangeSelector = () => {
  const ranges = [
    { label: 'date.month', value: ViewType.MONTH },
    { label: 'date.week', value: ViewType.WEEK },
  ];
  const { calendarView: view, updateView } = useAgenda();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [activeRange, setActiveRange] = useState(ranges[0]);
  const { t } = useTranslation(['agenda']);

  useEffect(() => {
    setActiveRange(ranges.find((range) => range.value === view) || ranges[0]);
  }, [view]);

  return (
    <Fragment>
      <Button
        color='primary'
        sx={{ p: '0.625rem' }}
        onClick={handleClick}
        variant='outlined'
        aria-controls={open ? 'date-actions-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={!!open || undefined}
      >
        {t(activeRange!.label)}
        <ChevronDown />
      </Button>
      <Menu
        id='date-actions-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {ranges.map((range) => (
          <MenuItem
            key={range.value}
            selected={range.value === activeRange?.value}
            onClick={() => {
              setActiveRange(range);
              updateView(range.value);
              handleClose();
            }}
          >
            {t(range.label)}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};

export default RangeSelector;
