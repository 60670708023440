export enum ErrorTypes {
  'BAD_REQUEST',
  'UNAUTHORIZED',
  'FORBIDDEN',
  'NOT_FOUND',
  'UNPROCESSABLE',
  'VALIDATION',
  'DEFAULT',
  'CONFLICT_DATES' = 'conflictDates',
  'INVALID_VALUE' = 'invalidValue',
  'INVALID_SELLING_MODE' = 'invalidSellingMode',
  'LT_FIELD' = 'ltfield',
  'MIN' = 'min',
}
