import { Stack, Typography, useMediaQuery } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { NotifiableError } from '@bugsnag/js';
import { useTranslation } from 'react-i18next';
import { Fragment, ReactNode, useLayoutEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IconX, IconEdit, IconArrowBackUp } from '@tabler/icons-react';

import { Coupon, CouponProduct, CouponsApiGetAppCouponsRequest, CouponUsageType } from '@/services/SellerApi';
import { useSellerApi } from '@/hooks/useSellerApi';
import ModalOnDesktopDrawerOnMobile from '@/components/Modal/ModalOnDesktopDrawerOnMobile';
import { StyledChip } from '@/components/Partnership/StatusChip/style';
import Bugsnag from '@/services/bugsnag';
import BackdropLoading from '@/components/BackdropLoading';
import theme from '@/theme';
import {
  getFormattedCouponDiscountValue,
  getMaxUseValueAndStatus,
  getRestrictionsValue,
} from '@/features/coupons/common';
import DetailsRow from './DetailsRow';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import { AnalyticsEvents } from '@/hooks/analytics/enum/analyticsEvents';
import { ErrorTypes } from '@/interfaces/errorTypes';
import useErrorHandler from '@/hooks/useErrorHandler';
import Button from '@/components/Button';
import { formatDate } from '@/utils';

interface Props {
  context: CouponsApiGetAppCouponsRequest['filter'];
}

export const CouponDetails = (props: Props) => {
  const { context } = props;

  const navigate = useNavigate();
  const { couponId } = useParams<{ couponId: string }>();

  const { t } = useTranslation(['coupons']);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { track } = useAnalytics();
  const { couponsApi } = useSellerApi();
  const { errorHandler } = useErrorHandler();

  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [coupon, setCoupon] = useState<Coupon | undefined>(undefined);
  const [couponProducts, setCouponProducts] = useState<CouponProduct[]>([]);

  const [isRestoring, setIsRestoring] = useState(false);
  const [openRestoreModal, setOpenRestoreModal] = useState(false);

  const isArchivedCouponList = context === 'archived';

  const fetchAllCouponProducts = async (couponId: string) => {
    let fetchedCouponProducts: CouponProduct[] = [];
    let offset = 0;
    const limit = 100;
    let hasMore = true;

    while (hasMore) {
      const { data: products } = await couponsApi.getCouponProducts({
        couponId,
        include: ['product'],
        limit,
        offset,
      });

      fetchedCouponProducts = [...fetchedCouponProducts, ...products];
      offset += limit;
      hasMore = products.length === limit;
    }

    return fetchedCouponProducts;
  };

  const fetchCoupon = async (couponId: string) => {
    setIsLoading(true);
    try {
      const { data: fetchedCoupon } = await couponsApi.getCoupon({ couponId });
      setCoupon(fetchedCoupon);

      if (fetchedCoupon.usageType === CouponUsageType.Product) {
        const fetchedCouponProducts = await fetchAllCouponProducts(couponId);

        setCouponProducts(fetchedCouponProducts);
      }
    } catch (error) {
      enqueueSnackbar(t('get_error'), { variant: 'error' });

      Bugsnag.notify(error as NotifiableError);
    } finally {
      setIsLoading(false);
    }
  };

  const restoreCoupon = async (couponId: string) => {
    const payload = { couponId };
    try {
      setIsRestoring(true);

      await couponsApi.restoreCoupon(payload);

      track(AnalyticsEvents.COUPON_RESTORE_SUCCESS, { payload });

      enqueueSnackbar(t('snackbar.coupon_restored_successfully'), {
        variant: 'success',
        title: t('snackbar.coupon_restored_successfully_title'),
      });

      navigate('..');
    } catch (error) {
      const { errors } = errorHandler(error);

      let snackbarTitle;
      let snackbarMessage: string | ReactNode = t('snackbar.could_not_restore_coupon');

      if (errors?.length && errors[0].type === ErrorTypes.VALIDATION) {
        snackbarMessage = t('snackbar.could_not_restore_existed_coupon_code');
        snackbarTitle = t('snackbar.could_not_restore_existed_coupon_code_title');
      }

      enqueueSnackbar(snackbarMessage, {
        variant: 'error',
        title: snackbarTitle,
      });

      track(AnalyticsEvents.COUPON_RESTORE_FAIL, { error });
      Bugsnag.notify(error as NotifiableError);
    } finally {
      setIsRestoring(false);
      setOpenRestoreModal(false);
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);

    navigate('..');
  };

  const handleConfirm = () => {
    if (isArchivedCouponList) {
      setOpenRestoreModal(true);
      return;
    }

    navigate(`../${couponId}/edit`);
  };

  const productsName = useMemo(() => {
    if (couponProducts.length) {
      return couponProducts.map((cp) => cp.product!.internalName ?? cp.product!.name);
    }

    return null;
  }, [couponProducts]);

  const restrictions = useMemo(() => {
    if (coupon) {
      return getRestrictionsValue(coupon, t);
    }

    return null;
  }, [coupon]);

  const ConfirmButtonIcon = isArchivedCouponList ? IconArrowBackUp : IconEdit;

  useLayoutEffect(() => {
    setIsModalOpen(true);

    if (couponId) {
      fetchCoupon(couponId);
    }

    return () => {
      setIsModalOpen(false);
      setOpenRestoreModal(false);
    };
  }, []);

  return (
    <Fragment>
      <ModalOnDesktopDrawerOnMobile
        width={640}
        isOpen={isModalOpen}
        onClose={handleClose}
        title={t('coupon_details.page_title')}
      >
        {isLoading ? <BackdropLoading /> : null}

        {!isLoading && !coupon ? (
          <Typography variant='largeMedium' color={theme.palette.colors.gray[700]}>
            {t('coupon_details.could_not_load')}
          </Typography>
        ) : null}

        {!isLoading && coupon ? (
          <Stack width='100%'>
            <Stack width='100%' gap={3}>
              <DetailsRow title={t('coupon_details.coupon')} value={coupon.code} />

              <DetailsRow title={t('coupon_details.discount')} value={getFormattedCouponDiscountValue(coupon)} />

              <DetailsRow
                title={t('coupon_details.quantity')}
                value={(() => {
                  const { status, value } = getMaxUseValueAndStatus(coupon, t);

                  return <StyledChip label={value} color={status} size='small' />;
                })()}
              />

              <DetailsRow
                title={t('coupon_details.restrictions')}
                value={(() => {
                  if (!restrictions) {
                    return '-';
                  }

                  return (
                    <Stack
                      gap={2}
                      direction='row'
                      alignItems='center'
                      justifyContent={{ xs: 'flex-end', md: 'flex-start' }}
                      flexWrap='wrap'
                    >
                      {restrictions.map((restriction) => (
                        <StyledChip key={restriction} size='small' color='secondary' label={restriction} />
                      ))}
                    </Stack>
                  );
                })()}
              />

              <DetailsRow
                title={t('coupon_details.products')}
                value={(() => {
                  if (!productsName) {
                    return '-';
                  }

                  return (
                    <Stack
                      gap={2}
                      direction='row'
                      alignItems='center'
                      justifyContent={{ xs: 'flex-end', md: 'flex-start' }}
                      flexWrap='wrap'
                    >
                      {productsName.map((product) => (
                        <StyledChip key={product} size='small' color='default' label={product} />
                      ))}
                    </Stack>
                  );
                })()}
              />

              <DetailsRow
                title={t('coupon_details.available_at')}
                value={coupon.availableAt ? formatDate(new Date(coupon.availableAt), 'dd/MM/yyyy - HH:mm') : '-'}
              />

              <DetailsRow
                title={t('coupon_details.expires_at')}
                value={coupon.expiresAt ? formatDate(new Date(coupon.expiresAt), 'dd/MM/yyyy - HH:mm') : '-'}
              />

              <DetailsRow title={t('coupon_details.anotations')} value={coupon.description ?? '-'} />
            </Stack>

            <Stack
              width='100%'
              direction={{ xs: 'column-reverse', sm: 'row' }}
              justifyContent='flex-end'
              gap={3}
              mt={6}
            >
              <Button
                color='primary'
                variant='outlined'
                startIcon={<IconX size={20} />}
                onClick={handleClose}
                disabled={isLoading}
                sx={{ whiteSpace: 'nowrap' }}
              >
                {t('coupon_details.actions.cancel')}
              </Button>

              <Button
                type='submit'
                color='primary'
                variant='contained'
                fullWidth={isMobile}
                onClick={handleConfirm}
                startIcon={<ConfirmButtonIcon size={20} />}
              >
                {t(`coupon_details.actions.${isArchivedCouponList ? 'restore' : 'edit'}`)}
              </Button>
            </Stack>
          </Stack>
        ) : null}
      </ModalOnDesktopDrawerOnMobile>

      {couponId && isArchivedCouponList ? (
        <ModalOnDesktopDrawerOnMobile
          width={400}
          isOpen={openRestoreModal}
          onClose={() => setOpenRestoreModal(false)}
          title={t('coupon_details.restore_modal_title')}
        >
          <Stack direction={{ xs: 'column-reverse', sm: 'row' }} alignItems='center' justifyContent='flex-end' gap={3}>
            <Button
              color='primary'
              variant='outlined'
              fullWidth={isMobile}
              disabled={isRestoring}
              onClick={() => setOpenRestoreModal(false)}
            >
              {t('coupon_details.actions.cancel')}
            </Button>

            <Button
              color='primary'
              variant='contained'
              fullWidth={isMobile}
              disabled={isRestoring}
              startIcon={<IconArrowBackUp size={20} />}
              onClick={() => restoreCoupon(couponId)}
            >
              {t('coupon_details.actions.restore')}
            </Button>
          </Stack>
        </ModalOnDesktopDrawerOnMobile>
      ) : null}
    </Fragment>
  );
};

export default CouponDetails;
