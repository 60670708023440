import { useState } from 'react';
import { number } from 'card-validator';
import { InputAdornment } from '@mui/material';
import { PaymentBrand } from '@/services/SellerApi';
import { Input, InputProps } from '@/components/Form/Input';
import { Brand, BrandList, Flag, InputAdornmentFlag } from './styles';
import { paymentBrandFlags } from '@/common/payment';

type Props = InputProps & {
  brands?: PaymentBrand[];
};

const CardInput = (props: Props) => {
  const { brands, onChange, ...rest } = props;

  const [brand, setBrand] = useState<string | null>(null);

  return (
    <>
      <Input
        {...rest}
        mask={'paymentCardNumber'}
        InputProps={{
          startAdornment: brand ? (
            <InputAdornment position='start' sx={{ marginRight: 0 }}>
              <InputAdornmentFlag src={paymentBrandFlags[brand]} alt={brand} />
            </InputAdornment>
          ) : null,
        }}
        onChange={(e) => {
          const cardInfo = number(e.target.value.replace(/\D/g, ''));

          if (cardInfo.card) {
            setBrand(cardInfo.card.type);
          }

          onChange?.(e);
        }}
      />

      <BrandList>
        {brands?.map((brand) => (
          <Brand key={brand.code}>
            <Flag src={paymentBrandFlags[brand.code]} alt={brand.code} />
          </Brand>
        ))}
      </BrandList>
    </>
  );
};

export default CardInput;
