import { useTranslation } from 'react-i18next';
import { AppBar, Box, List, SxProps, Toolbar, Typography } from '@mui/material';

import theme from '@/theme';
import { formatCents } from '@/utils';
import usePartnershipPortalCart from '@/hooks/usePartnershipPortalCart';
import { Fragment, useState } from 'react';
import ModalOnDesktopDrawerOnMobile from '@/components/Modal/ModalOnDesktopDrawerOnMobile';
import { StyledListItem } from '../ListCartItems/styles';
import PurchaseButton from '../PurchaseButton';
import CartItemCard from '../ListCartItems/CartItemCard';
import { ShoppingCart } from '../ShoppingCart';

interface CustomAppBarProps {
  children: React.ReactNode;
  toolBarSX?: SxProps;
  appBarSX?: SxProps;
  onClick?: () => void;
}

const CustomAppBar = (props: CustomAppBarProps) => {
  const { children, toolBarSX, appBarSX, onClick } = props;

  return (
    <AppBar
      elevation={0}
      position='fixed'
      onClick={onClick}
      sx={{ background: theme.palette.common.white, bottom: 0, top: 'auto', height: 92, ...appBarSX }}
    >
      <Toolbar sx={toolBarSX}>{children}</Toolbar>
    </AppBar>
  );
};

export const MobileCart = () => {
  const { t } = useTranslation('productShowCase');
  const [openList, setOpenList] = useState(false);

  const { cartItems, calculateTotalPriceOfAllCartItems } = usePartnershipPortalCart();

  const hasProductsOnCart = cartItems.length > 0;

  if (!hasProductsOnCart) {
    return null;
  }

  return (
    <Fragment>
      <ModalOnDesktopDrawerOnMobile
        isOpen={openList}
        onClose={() => setOpenList(false)}
        title={
          <Box display='flex' flexDirection='row' alignItems='center' gap={2} width='100%'>
            <ShoppingCart />

            <Typography variant='h6' color={theme.palette.colors.gray[700]}>
              {t('cart.mobile.modal_title')}
            </Typography>
          </Box>
        }
      >
        {hasProductsOnCart ? (
          <List sx={{ mb: 7 }}>
            {cartItems.map((cartItem) => (
              <StyledListItem key={cartItem.id}>
                <CartItemCard
                  cartItem={cartItem}
                  slotProps={{
                    itemPrice: { sx: { ...theme.typography.largeBold } },
                    productName: { sx: { ...theme.typography.largeBold } },
                    providerName: { sx: { ...theme.typography.smallBold } },
                    tariffWrapper: { sx: { ...theme.typography.regularRegular } },
                    InvalidProductWrapper: { sx: { ...theme.typography.smallRegular } },
                  }}
                />
              </StyledListItem>
            ))}
          </List>
        ) : null}

        <CustomAppBar>
          <Box width='100%' sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Box
              sx={{
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                flexDirection: 'row',
                display: 'flex',
                width: '100%',
                gap: 2,
              }}
            >
              <Typography variant='regularBold' color={theme.palette.colors.gray[700]}>
                {t('cart.mobile.subtotal')}
              </Typography>

              <Typography variant='h5' color={theme.palette.colors.gray[700]}>
                {formatCents(calculateTotalPriceOfAllCartItems(cartItems))}
              </Typography>
            </Box>

            <PurchaseButton href='checkout'>{t('cart.mobile.finish_button')}</PurchaseButton>
          </Box>
        </CustomAppBar>
      </ModalOnDesktopDrawerOnMobile>

      <CustomAppBar onClick={() => setOpenList(true)} appBarSX={{ height: 76 }} toolBarSX={{ padding: 2 }}>
        <Box justifyContent='space-between' flexDirection='row' alignItems='center' display='flex' width='100%' gap={2}>
          <ShoppingCart />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Typography variant='smallSemiBold' color={theme.palette.colors.gray[800]}>
              {t('cart.mobile.subtotal')}
            </Typography>

            <Typography variant='h6' color={theme.palette.colors.gray[800]}>
              {formatCents(calculateTotalPriceOfAllCartItems(cartItems))}
            </Typography>
          </Box>

          <PurchaseButton
            onClick={(e) => {
              e.stopPropagation();
              // go to checkout
            }}
          >
            {t('cart.mobile.finish_button')}
          </PurchaseButton>
        </Box>
      </CustomAppBar>
    </Fragment>
  );
};

export default MobileCart;
