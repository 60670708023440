import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import PageLayout from '@/layouts/PageLayout';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import usePartnershipPortalCart from '@/hooks/usePartnershipPortalCart';
import ShowCaseLayout from '@/layouts/ShowCaseLayout';
import PurchaseSummary from '@/components/PartnershipPortal/PurchaseSummary';
import CustomerForm, { SubmitFormData } from '@/components/PartnershipPortal/CustomerForm';
import { Box, Container, Hidden, Typography } from '@mui/material';
import { MobileBox } from './styles';
import { CartItemWithId } from '@/contexts/PartnershipPortalCartContext';
import { groupBy } from 'lodash';
import { useSellerApi } from '@/hooks/useSellerApi';
import { CustomerParams, DirectSale, ErrorObject } from '@/services/SellerApi';
import useAccount from '@/hooks/useAccount';
import SaleList from './SaleList';
import { AxiosError, AxiosResponse } from 'axios';
import BackdropLoading from '@/components/BackdropLoading';
import Button from '@/components/Button';

type SettledAxiosPromise<T> = {
  status: 'fulfilled' | 'rejected';
  value?: AxiosResponse<T>;
  reason?: AxiosError<ErrorObject>;
};

export type DirectSalesCreationResult = {
  items: CartItemWithId[];
  status: SettledAxiosPromise<DirectSale>['status'];
  value?: DirectSale;
  error?: ErrorObject;
};

export const ShowCaseCheckoutPage = () => {
  const { track } = useAnalytics();
  const { t } = useTranslation(['showCaseCheckout']);
  const { salesApi } = useSellerApi();
  const { selectedAccount } = useAccount();

  const { cartItems, checkoutId, clearCart } = usePartnershipPortalCart();
  const [step, setStep] = useState<'checkout' | 'customer' | 'sales' | 'payment'>('checkout');
  const [loadingSales, setLoadingSales] = useState<boolean>(false);
  const [directSalesCreationResult, setDirectSalesCreationResult] = useState<DirectSalesCreationResult[]>([]);
  const [checkoutItems, setCheckoutItems] = useState<CartItemWithId[]>([]);
  const [customer, setCustomer] = useState<CustomerParams | undefined>(undefined);
  const [customerId, setCustomerId] = useState<string | undefined>(undefined);

  const productShowCaseAddress = `/${selectedAccount?.app?.code}/partnership/productShowCase`;

  const breadcrumbs = [
    {
      label: t('page_breadcrumb_showcase'),
      to: productShowCaseAddress,
    },
    {
      label: t('page_title_customer'),
    },
    ...(step === 'payment'
      ? [
          {
            label: t('page_title_payment'),
          },
        ]
      : []),
  ];

  const handleCustomerFormSubmit = (data: SubmitFormData) => {
    track('showCaseCheckoutPage/customer_selected', { customer: data });

    if (data.customer) {
      setCustomerId(data.customer.id);
    } else {
      setCustomer({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        identity: data.identity,
        identityType: data.identityType,
        corporateName: data.corporateName,
        countryCode: data.countryCode,
      });
    }

    setStep('sales');
  };

  const createSale = async (
    providerId: string,
    checkoutId: string,
    customerId: string,
    customer: CustomerParams,
    cartItems: CartItemWithId[],
  ) => {
    const payload = {
      appId: selectedAccount?.app?.id ?? '',
      directSaleParams: {
        checkoutId: checkoutId,
        providerId: providerId,
        customerId: customerId,
        customer: customer,
        items: cartItems.map((item) => ({
          productId: item.arrangedProduct.productId,
          partnershipItemId: item.arrangedProduct.partnershipItemId,
          scheduleDate: item.scheduleDate,
          scheduleTime: item.scheduleTime,
          tariffs: item.tariffs.map((tariff) => ({
            id: tariff.tariff.id,
            quantity: tariff.quantity,
          })),
          autoAllocateResources: item.requiresSeatSelection,
        })),
      },
    };

    return salesApi.createAppDirectSale(payload);
  };

  const createSales = async () => {
    setLoadingSales(true);

    const groupedItems = groupBy(checkoutItems, (item) => item.arrangedProduct.product.appId);

    const promises = Object.keys(groupedItems).map((providerId) =>
      createSale(providerId, checkoutId, customerId!, customer!, groupedItems[providerId]),
    );

    const results = (await Promise.allSettled(promises)) as unknown as SettledAxiosPromise<DirectSale>[];

    const categorizedResults = results.map((value, index) => {
      const appId = Object.keys(groupedItems)[index];

      return {
        items: groupedItems[appId],
        status: value.status,
        value: value.value?.data,
        error: value.reason?.response?.data,
      };
    });

    setDirectSalesCreationResult(categorizedResults);

    setStep('payment');

    setLoadingSales(false);
  };

  const checkoutCart = async () => {
    setCheckoutItems(cartItems);

    setStep('customer');
  };

  useEffect(() => {
    track('showCaseCheckoutPage/access', { cartItems: cartItems });

    if (cartItems.length) {
      checkoutCart();
    }
  }, []);

  useEffect(() => {
    if (step === 'sales') {
      createSales();

      clearCart();
    }
  }, [step]);

  return (
    <>
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>

      <PageLayout
        title={step === 'customer' ? t('page_title_customer') : t('page_title_payment')}
        breadcrumbs={breadcrumbs}
        showSearchInput={false}
        showFilterButton={false}
        showGoBackButton
      >
        <ShowCaseLayout sideContent={<PurchaseSummary items={checkoutItems} />}>
          {loadingSales ? <BackdropLoading /> : null}

          {step === 'checkout' && !cartItems.length ? (
            <Box sx={{ mt: '100px' }} textAlign='center'>
              <Typography variant='h6' textAlign='center' sx={{ mb: '20px' }}>
                {t('empty_cart_message')}
              </Typography>

              <Button variant='contained' href={productShowCaseAddress}>
                {t('empty_cart_go_to_showcase')}
              </Button>
            </Box>
          ) : null}

          {step === 'customer' && !loadingSales ? <CustomerForm onSubmit={handleCustomerFormSubmit} /> : null}

          {step === 'payment' && !loadingSales ? <SaleList salesResults={directSalesCreationResult} /> : null}

          <Hidden smUp>
            <Container>
              <MobileBox>
                <PurchaseSummary items={checkoutItems} />
              </MobileBox>
            </Container>
          </Hidden>
        </ShowCaseLayout>
      </PageLayout>
    </>
  );
};

export default ShowCaseCheckoutPage;
