import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';

import useRouteQueryParams from '@/hooks/useRouteQueryParams';
import {
  FilterContextProps,
  FilterOption,
  Filters,
  FilterSchemas,
  FiltersUpdateParams,
  HandleChangeFiltersFunction,
} from '@/interfaces/filterContext';
import useAccount from '@/hooks/useAccount';
import useAuth from './Auth/hooks/useAuth';

// eslint-disable-next-line react-refresh/only-export-components
export const FilterContext = createContext<FilterContextProps<FilterSchemas>>({} as FilterContextProps<FilterSchemas>);

type ProviderProps<T extends FilterSchemas> = {
  children: ReactNode;
  schema?: T;
  defaultValue?: Partial<Filters<T>>;
  clearValue?: Partial<Filters<T>>;
  contextName: string;
};

const FilterProvider = <T extends FilterSchemas>(props: ProviderProps<T>) => {
  const { children, schema, defaultValue, clearValue, contextName } = props;
  const [selectedFilters, setSelectedFilters] = useState<Partial<Filters<T>>>({});
  const [appliedFilters, setAppliedFilters] = useState<Partial<Filters<T>>>({});
  const [showFilters, setShowFilters] = useState(false);
  const [search, setSearch] = useState<string | undefined>();
  const [isFiltersLoaded, setFiltersLoaded] = useState<boolean>(false);
  const { selectedAccount } = useAccount();
  const { user } = useAuth();

  const { setUrlParams, deleteUrlParams, searchParams, updateUrlParams } = useRouteQueryParams();

  const LOCAL_STORAGE_KEY = useMemo(
    () => `filters.${selectedAccount?.appId}.${user?.id}/${contextName}`,
    [selectedAccount, user, contextName],
  );

  const getFiltersFromLocalStorage = () => {
    return localStorage.getItem(LOCAL_STORAGE_KEY);
  };

  const saveFiltersToLocalStorage = (filters: Partial<Filters<T>>) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(filters));
  };

  const closeFilters = () => {
    setShowFilters(false);
  };

  const onReset = () => {
    if (clearValue) {
      clearFiltersToValue();
      return;
    }
    setSelectedFilters({});
    setAppliedFilters({});
    saveFiltersToLocalStorage({});

    removeAllRegisteredFiltersFromQueryParams();
  };

  const handleApplyFilter = () => {
    const selectedAndSanitizedFilters = sanitizeFilters(selectedFilters);
    setAppliedFilters(selectedAndSanitizedFilters);
    updateUrlParams(selectedAndSanitizedFilters, Object.keys(appliedFilters));
    saveFiltersToLocalStorage(selectedAndSanitizedFilters);

    closeFilters();
  };

  const removeAllRegisteredFiltersFromQueryParams = () => {
    deleteUrlParams(Object.keys(schema!));
  };

  const setFilterQueryParams = (filters: Filters<T>) => {
    if (Object.keys(filters).length === 0) {
      removeAllRegisteredFiltersFromQueryParams();
      return;
    }

    setUrlParams(filters as Record<string, string>);
  };

  const clearFiltersToValue = () => {
    const clearFiltersValue = clearValue;
    const newFilters = { ...appliedFilters };

    for (const key in newFilters) {
      if (clearFiltersValue && !clearFiltersValue[key]) {
        newFilters[key] = undefined;
      } else {
        newFilters[key] = clearFiltersValue![key];
      }
    }

    setAppliedFilters(newFilters);
    setSelectedFilters(newFilters);
    updateUrlParams(newFilters);
    saveFiltersToLocalStorage(newFilters);
  };

  const setAppliedFilterFromQueryParams = () => {
    const existentQueryParams = Object.fromEntries(searchParams.entries());
    let exists = false;
    let selectedFiltersFromQueryParams = { ...selectedFilters };
    let appliedFiltersFromQueryParams = { ...appliedFilters };

    for (const filterKey in existentQueryParams) {
      let filterValue: string | string[] = existentQueryParams[filterKey];

      if (filterKey === 'search') {
        setSearch(filterValue);
        continue;
      }

      const filterSchema = schema ? schema[filterKey] : undefined;

      if (!filterSchema) {
        continue;
      }

      if (filterSchema.type === 'array' && filterValue.length > 0) {
        filterValue = filterValue
          .split(',')
          .filter((value) =>
            filterSchema.options?.length ? filterSchema.options?.some((option) => option.value === value) : true,
          );
      }

      if (filterValue) {
        selectedFiltersFromQueryParams = { ...selectedFiltersFromQueryParams, [filterKey]: filterValue };
        appliedFiltersFromQueryParams = { ...appliedFiltersFromQueryParams, [filterKey]: filterValue };

        exists = true;
      }
    }

    setSelectedFilters(selectedFiltersFromQueryParams);
    setAppliedFilters(appliedFiltersFromQueryParams);

    return exists;
  };

  const composeKey = (key: string, prefix: string) => {
    const splitKey = key.split('');

    return `${prefix + splitKey[0].toLocaleUpperCase() + splitKey.slice(1).join('')}`;
  };

  const deleteValue = (filterKey: keyof T | string, value: string) => {
    const filterSchema = schema ? schema[filterKey] : undefined;

    if (!filterSchema) {
      return;
    }

    const copy = { ...selectedFilters };
    const filterValue = copy[filterKey];

    if (filterSchema.type === 'array' && Array.isArray(filterValue)) {
      const newFilter = filterValue.filter((v) => v !== value);

      if (newFilter.length === 0) {
        delete copy[filterKey];
      } else {
        copy[filterKey] = newFilter;
      }
    } else {
      delete copy[filterKey];
    }

    setFilters(copy as Filters<T>);
  };

  const setFilters = (filters: Filters<T>) => {
    setSelectedFilters(filters);
    setAppliedFilters(filters);
    setFilterQueryParams(filters);
  };

  const handleChangeFilters: HandleChangeFiltersFunction<T> = (
    params: FiltersUpdateParams<T> | string | number | symbol,
    value?: string | string[],
    apply: boolean = false,
  ) => {
    if (typeof params !== 'string' && 'filters' in (params as FiltersUpdateParams<T>)) {
      const { filters, applyFilters } = params as FiltersUpdateParams<T>;

      let newFilters = { ...selectedFilters, ...filters };
      newFilters = sanitizeFilters(newFilters) as Filters<T>;

      setSelectedFilters(newFilters);

      if (applyFilters) {
        setAppliedFilters(newFilters);
        setFilterQueryParams(newFilters);
        saveFiltersToLocalStorage(newFilters);
      }
      return;
    }

    // @deprecated - all calls should be using the new signature
    const filterKey = params as string;

    let filters = undefined;
    if (value === undefined) {
      const copy = { ...selectedFilters };

      delete copy[filterKey];

      filters = copy;
    } else {
      filters = { ...selectedFilters, [filterKey]: value };
    }

    setSelectedFilters(filters);

    if (apply) {
      setAppliedFilters(filters);
      setFilterQueryParams(filters as Filters<T>);
      saveFiltersToLocalStorage(filters);
    }
    // @deprecated - end
  };

  const handleChangeShowFilters = (value: boolean) => setShowFilters(value);

  const onSearch = (value?: string) => {
    if (value) {
      setSearch(value);
      setFilterQueryParams({ search: value } as Filters<T>);
    } else {
      setSearch(undefined);
      deleteUrlParams(['search']);
    }
  };

  const removeAppliedFilterKey = (key: string) => {
    const newAppliedFilters = { ...appliedFilters };

    delete newAppliedFilters[key];

    setAppliedFilters(newAppliedFilters);
  };

  const sanitizeFilters = (filters: Partial<Filters<T>>): Partial<Filters<T>> => {
    const sanitizedFilters = { ...filters };

    for (const key in sanitizedFilters) {
      if (key in sanitizedFilters && Array.isArray(sanitizedFilters[key])) {
        if (sanitizedFilters[key].length === 0) {
          delete sanitizedFilters[key];
        }
      }
    }
    return sanitizedFilters;
  };

  const selectedFilterOption = (filterKey: string) => {
    return (
      (selectedFilters?.[filterKey] as string[])?.map(
        (status) => schema![filterKey].options!.find((option) => option.value === status)!,
      ) ?? []
    );
  };

  useEffect(() => {
    const existsQueryFilters = setAppliedFilterFromQueryParams();
    const filtersFromLocalStorage = getFiltersFromLocalStorage();

    if (!existsQueryFilters) {
      if (filtersFromLocalStorage) {
        setFilters(JSON.parse(filtersFromLocalStorage) as Filters<T>);
      } else if (defaultValue) {
        setFilters(defaultValue as Filters<T>);
      }
    }

    setFiltersLoaded(true);
  }, []);

  return (
    <FilterContext.Provider
      value={{
        search,
        schema,
        selectedFilters,
        selectedFilterOption,
        appliedFilters,
        showFilters,
        isFiltersLoaded,
        closeFilters,
        onReset,
        handleChangeFilters: handleChangeFilters as HandleChangeFiltersFunction<FilterSchemas>,
        handleApplyFilter,
        handleChangeShowFilters,
        deleteValue,
        onSearch,
        composeKey,
        removeAppliedFilterKey,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export type {
  /** @deprecated Deprecated. Import `FilterOption` from `interfaces/filterContext.ts` instead */
  FilterOption,
};
export default FilterProvider;
