import { Suspense, useEffect, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import useUI from '@/hooks/useUI';
import useAccount from '@/hooks/useAccount';

import ResponsiveDrawer from '@/components/Drawer';

import { Main } from './styles';
import AccountSelectorModalComponent from '@/components/AccountSelector/AccountSelectorModal';
import ErrorBoundary from '@/components/Error/ErrorBoundary';
import Loading from '@/components/Loading';
import ErrorTechnical from '@/components/Error/ErrorTechnical';

// const ErrorNoAppFoundPage = lazy(() => import('@/pages/ErrorNoAppFound'));
import ErrorNoAppFoundPage from '@/pages/ErrorNoAppFound';

export default function Dashboard() {
  const { appCode } = useParams();
  const { drawerWidth, drawerOpen, isDrawerExpanded } = useUI();
  const { accounts, selectAccountWithCode, initialized } = useAccount();

  const canAccessApp = useMemo(
    () => accounts.some((account) => account.app?.code === appCode),
    [accounts, appCode, initialized],
  );

  useEffect(() => {
    if (initialized && appCode) {
      selectAccountWithCode(appCode);
    }
  }, [initialized, appCode]);

  return !canAccessApp && initialized ? (
    <Suspense fallback={<Loading />}>
      <ErrorNoAppFoundPage />
    </Suspense>
  ) : (
    <Box>
      <ResponsiveDrawer />

      <Main open={drawerOpen} drawerWidth={drawerWidth} isDrawerExpanded={isDrawerExpanded}>
        <ErrorBoundary FallbackComponent={ErrorTechnical}>
          <Outlet />
        </ErrorBoundary>
      </Main>

      <AccountSelectorModalComponent />
    </Box>
  );
}
