import { AlertTitle, Stack, Typography } from '@mui/material';

import {
  StyledList,
  StyledListItem,
} from '@/features/agenda/features/schedulingForm/components/CreateAndEditSchedulingForm/DateConflictAlert/styles';
import formatDateListToConflictMessage from '@/features/agenda/features/schedulingForm/utils/formatDateListToConflictMessage';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

type Props = {
  conflictingDates: Date[];
};

export const AlertContent = (props: Props) => {
  const { conflictingDates } = props;

  const { t } = useTranslation(['scheduling-form'], { keyPrefix: 'dateConflict' });

  const dates = useMemo(() => formatDateListToConflictMessage(conflictingDates, t), [conflictingDates, t]);

  return (
    <>
      <AlertTitle>{t('title')}</AlertTitle>

      <Typography>{t('message')}</Typography>

      <StyledList>
        {dates.map((date) => (
          <StyledListItem key={date.index}>
            <Stack sx={{ textTransform: 'capitalize' }}>{date.text[0]}: </Stack>
            <Stack>{date.text[1]}</Stack>
          </StyledListItem>
        ))}
      </StyledList>
    </>
  );
};

export default AlertContent;
