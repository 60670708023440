import { format } from 'date-fns';
import { Fragment, useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { Add, SwapHorizOutlined, HelpOutline } from '@mui/icons-material';
import { uniqBy } from 'lodash';

import theme from '@/theme';
import {
  Container,
  CouponTitle,
  LeftContent,
  RightContent,
  SaleChannal,
  slotProps,
  StatusWrapper,
  TagsContainer,
  Utms,
  Flag,
} from './style';
import { formatCents } from '@/utils';
import { AssociationModel, CouponType, Sale, SaleStateEnum } from '@/services/SellerApi';
import { useTranslation } from 'react-i18next';
import Button from '@/components/Button';
import ValueWithLabel from '@/components/ValueWithLabel';
import SaleTagsPopup, { TagChip } from '@/features/sales/components/SaleTagsPopup';
import { StyledChip } from '@/components/Partnership/StatusChip/style';
import { getAggregatedVouchersValueAndStatus } from '../utils';
import RefundRequestStatusChip from './RefundRequestStatusChip';
import SaleStatusChip from '../SaleStatusChip';
import { getSaleSource } from '@/common/sale';
import useAccount from '@/hooks/useAccount';
import { paymentBrandFlags } from '@/common/payment';
import Chip from '@/components/Chip';
import { SaleItemsData } from '../../context/saleDetailContext';
import isSaleNonRefundable from '@/features/sales/utils/isSaleNonRefundable';

interface Props {
  refresh: () => Promise<void>;
  sale: Sale;
  saleItemsData: SaleItemsData;
}

export const SaleDetail = (props: Props) => {
  const { refresh, sale, saleItemsData } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const { hasPermission } = useAccount();
  const { t } = useTranslation(['saleDetails']);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [tagAnchor, setTagAnchor] = useState<null | HTMLElement>(null);

  const renderAggregatedVouchers = (sale: Sale) => {
    const { status, value } = getAggregatedVouchersValueAndStatus(sale);

    return <StyledChip label={value} color={status} size='small' />;
  };

  const { data } = saleItemsData;
  const { data: saleItems = [] } = data ?? {};

  const saleSource = useMemo(() => getSaleSource(sale, saleItems), [sale, saleItems]);
  const SaleSourceIcon = saleSource.icon;
  const customerFullName = useMemo(() => `${sale.customer?.firstName} ${sale.customer?.lastName}`, [sale.customer]);
  const isNonRefundable = useMemo(() => isSaleNonRefundable(sale), [sale]);

  const { order } = sale;
  const decisiveTransaction = order?.decisiveTransaction || sale.decisiveTransaction;

  const formattedUtms = useMemo(() => {
    return (
      <Utms>
        {sale.marketingData?.utms?.map((utm) => (
          <Chip
            key={`utm-${utm.key}-${utm.value}`}
            label={`${utm.key}: ${utm.value}`}
            size='small'
            color={{
              font: 'rgba(0, 0, 0, 0.87)',
              background: 'rgba(0, 0, 0, 0.08)',
            }}
          />
        ))}
      </Utms>
    );
  }, [sale.marketingData?.utms]);

  return (
    <Stack px={{ xs: 2, md: 0 }}>
      <Container>
        <LeftContent>
          <Typography variant='h6'>{t('details.title')}</Typography>

          <ValueWithLabel slotProps={slotProps} title={t('details.sale_number')} value={sale.position} />

          <ValueWithLabel
            slotProps={slotProps}
            title={t('details.customer')}
            value={
              hasPermission('customers', 'GetCustomer') ? (
                <Link variant='regularRegular' href={`${location.pathname}/customer`}>
                  {customerFullName}
                </Link>
              ) : (
                customerFullName
              )
            }
          />

          <ValueWithLabel
            slotProps={slotProps}
            title={t('details.sale_date')}
            value={format(new Date(sale.createdAt), 'dd/MM/yyyy - HH:mm')}
          />

          <ValueWithLabel slotProps={slotProps} title={t('details.voucher')} value={renderAggregatedVouchers(sale)} />

          {sale.origin ? (
            <ValueWithLabel slotProps={slotProps} title={t('details.sale_origin')} value={sale.origin.name} />
          ) : null}

          <ValueWithLabel
            slotProps={slotProps}
            title={t('details.sale_channel.title')}
            value={
              <SaleChannal>
                <SaleSourceIcon size={14} />
                <Typography variant='regularRegular' sx={{ fontSize: '14px' }}>
                  {saleSource.formatted}
                </Typography>
              </SaleChannal>
            }
          />

          {sale.marketingData?.utms && sale.marketingData?.utms?.length > 0 ? (
            <ValueWithLabel slotProps={slotProps} title={t('details.utms')} value={formattedUtms} />
          ) : null}

          <ValueWithLabel
            slotProps={slotProps}
            title={t('details.tags')}
            value={
              <>
                {!sale.tags?.length ? (
                  <TagChip
                    sx={{ '& .MuiChip-label': { display: 'flex' } }}
                    label={<Add fontSize='small' sx={{ width: '16px', height: '16px' }} />}
                    onClick={(e) => setTagAnchor(e.currentTarget)}
                  />
                ) : (
                  <TagsContainer>
                    {sale.tags?.map((tag) => (
                      <TagChip
                        key={tag.id}
                        label={tag.name}
                        sx={{ '& .MuiChip-label': { display: 'flex' } }}
                        onClick={(e) => setTagAnchor(e.currentTarget)}
                      />
                    ))}
                  </TagsContainer>
                )}
              </>
            }
          />
        </LeftContent>

        <RightContent>
          <StatusWrapper>
            <SaleStatusChip status={sale.currentState} label={t(`details.sale_status.${sale.currentState}`)} />

            {sale.aggregatedRefundRequests?.open ? (
              <Tooltip
                arrow
                placement='top'
                enterTouchDelay={0}
                leaveTouchDelay={60000}
                title={t('details.refund_request_tooltip')}
                PopperProps={{ style: { zIndex: 99999, width: 100, textAlign: 'center' } }}
              >
                <RefundRequestStatusChip status='pending' label={t('details.refund_request_status.pending')} />
              </Tooltip>
            ) : null}
          </StatusWrapper>

          {sale?.coupon?.code ? (
            <ValueWithLabel
              slotProps={slotProps}
              title={t('details.coupon.title')}
              value={
                <CouponTitle>
                  <Fragment>
                    {sale?.coupon.code}{' '}
                    {`(${
                      sale?.coupon.type === CouponType.Percentage
                        ? sale?.coupon.discountPercentage + '%'
                        : formatCents(sale?.coupon.discountedAmountCents)
                    } ${' '}
                  ${t('details.coupon.discount')})`}
                  </Fragment>

                  <Tooltip
                    arrow
                    enterTouchDelay={0}
                    leaveTouchDelay={60000}
                    title={t(`details.coupon.tooltip.${sale.coupon.usageType}`, {
                      productsName: uniqBy(sale.coupon.products, 'id')
                        .map((product) => product.product?.internalName ?? product.product?.name)
                        .join(', '),
                    })}
                    PopperProps={{ style: { zIndex: 99999, minWidth: 190, textAlign: 'center' } }}
                  >
                    <HelpOutline
                      fontSize='small'
                      sx={{
                        ml: 0.5,
                        height: '16px',
                        cursor: 'pointer',
                        color: theme.palette.colors.gray[700],
                      }}
                    />
                  </Tooltip>
                </CouponTitle>
              }
            />
          ) : null}

          <ValueWithLabel
            slotProps={{
              value: { variant: 'h4', color: theme.palette.colors.gray[800] },
              container: {
                ...slotProps?.container,
                alignItems: 'flex-end',
              },
              title: { variant: 'largeSemiBold', color: theme.palette.colors.gray[800] },
            }}
            title={t('details.total')}
            value={formatCents(sale.amountCents)}
          />

          {saleSource.source !== AssociationModel.Resale && decisiveTransaction?.paymentMethod ? (
            <Stack direction='row'>
              <Typography variant='regularSemiBold'>
                {t('details.payment_method.title')}
                <Typography component='span' ml={0.5} variant='regularRegular'>
                  {t(`details.payment_method.${decisiveTransaction.paymentMethod.code}`)}{' '}
                  {decisiveTransaction.installments}x{' '}
                  {decisiveTransaction.paymentMethodInterestRateCents ? t('details.payment_method.with_interest') : ''}
                </Typography>
              </Typography>

              {decisiveTransaction?.brand && paymentBrandFlags[decisiveTransaction.brand] && (
                <Flag src={paymentBrandFlags['mastercard']} alt={decisiveTransaction.brand} />
              )}
            </Stack>
          ) : null}

          <Stack
            sx={{
              flexDirection: 'row',
              gap: theme.spacing(2),
              width: isMobile ? '100%' : 'max-content',
            }}
          >
            {sale.aggregatedRefundRequests?.total ? (
              <Button
                color='primary'
                variant='outlined'
                fullWidth={isMobile}
                sx={{ minWidth: 'fit-content' }}
                onClick={() => navigate(`${location.pathname}/refund-request-history`)}
                disabled={!hasPermission('refundRequests', 'GetSaleRefundRequests')}
              >
                {t('details.refund_history_button')}
              </Button>
            ) : null}

            <Tooltip
              arrow
              placement='left'
              enterTouchDelay={0}
              leaveTouchDelay={60000}
              title={isNonRefundable ? t('details.no_refund_request_tooltip') : null}
              PopperProps={{ style: { zIndex: 99999, textAlign: 'center' } }}
            >
              <span>
                <Button
                  disabled={
                    sale.currentState !== SaleStateEnum.PaymentComplete ||
                    Boolean(sale?.aggregatedRefundRequests?.open) ||
                    isNonRefundable ||
                    !hasPermission('refundRequests', 'CreateSaleRefundRequest')
                  }
                  variant='outlined'
                  color='primary'
                  fullWidth={isMobile}
                  onClick={() => navigate('refund-request')}
                >
                  <SwapHorizOutlined sx={{ mr: 1 }} />
                  {t('details.refund_button')}
                </Button>
              </span>
            </Tooltip>
          </Stack>
        </RightContent>
      </Container>

      <SaleTagsPopup
        sale={sale}
        open={!!tagAnchor}
        onClose={() => {
          setTagAnchor(null);
          refresh();
        }}
        tagAnchor={{ element: tagAnchor, saleId: sale.id }}
      />
    </Stack>
  );
};

export default SaleDetail;
