import { Stack, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Alert from '@/components/Alert';
import theme, { colors } from '@/theme';
import Button from '@/components/Button';
import { useEffect, useMemo, useState } from 'react';
import ModalOnDesktopDrawerOnMobile from '@/components/Modal/ModalOnDesktopDrawerOnMobile';
import Checkbox from '@/components/Form/Checkbox';
import { IconX, IconCheck } from '@tabler/icons-react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { SchedulingFields } from '@/features/agenda/features/schedulingForm/types/formTypes';
import AlertContent from './AlertContent';

type Props = {
  schedulingForm: UseFormReturn<SchedulingFields, unknown, undefined>;
  dates: Date[];
  loading?: boolean;
  onStartResolving: () => void;
  onCancelResolving: () => void;
};

const DateConflictAlert = (props: Props) => {
  const { schedulingForm, dates, loading, onStartResolving, onCancelResolving } = props;
  const { t } = useTranslation(['scheduling-form'], { keyPrefix: 'dateConflict' });
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [conflictModalOpen, setConflictModalOpen] = useState(false);

  const { control, formState, watch } = schedulingForm;

  const watchOverrideConflicts = useMemo(() => watch('overrideConflicts'), [watch('overrideConflicts')]);

  const { errors } = formState;

  useEffect(() => {
    if (loading) {
      setConflictModalOpen(false);
    }
  }, [loading]);

  return (
    <>
      <Alert severity='error' sx={{ color: colors.error[500] }}>
        <AlertContent conflictingDates={dates} />

        <Button
          variant='contained'
          color='primary'
          sx={{ mt: 2 }}
          onClick={() => {
            setConflictModalOpen(true);
            onStartResolving();
          }}
        >
          {t('resolve')}
        </Button>
      </Alert>

      <ModalOnDesktopDrawerOnMobile
        isOpen={conflictModalOpen}
        onClose={() => setConflictModalOpen(false)}
        title={t('modal.title')}
        width='580px'
        showCloseButton={false}
      >
        <Stack component='form' sx={{ gap: '1rem' }}>
          <Alert severity='error' sx={{ color: colors.error[500] }}>
            <AlertContent conflictingDates={dates} />
          </Alert>

          <Typography>{t('modal.message')}</Typography>

          <Alert icon={false} severity='error' sx={{ color: colors.error[500] }}>
            <Typography>{t('modal.irreversible')}</Typography>
          </Alert>

          <Controller
            name='overrideConflicts'
            defaultValue={false}
            control={control}
            render={({ field: { name, value, ...rest } }) => (
              <Checkbox
                label={t('modal.confirm_message')}
                checked={value}
                error={!!errors[`${name}`]}
                helperText={errors[`${name}`]?.message as string}
                {...rest}
              />
            )}
          />

          <Stack sx={{ flexDirection: 'row', gap: '0.5rem', justifyContent: 'flex-end' }}>
            <Button
              variant='outlined'
              color='primary'
              fullWidth={isMobile}
              startIcon={<IconX />}
              onClick={() => {
                setConflictModalOpen(false);
                onCancelResolving();
              }}
            >
              {t('modal.cancel')}
            </Button>

            <Button
              type='submit'
              variant='contained'
              color='primary'
              fullWidth={isMobile}
              startIcon={<IconCheck />}
              disabled={!watchOverrideConflicts}
            >
              {t('modal.confirm')}
            </Button>
          </Stack>
        </Stack>
      </ModalOnDesktopDrawerOnMobile>
    </>
  );
};

export default DateConflictAlert;
