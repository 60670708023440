import { useTranslation } from 'react-i18next';
import { Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import { GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';

import theme from '@/theme';
import { Actions } from '@/features/sales/pages/Vouchers';
import { TicketIcon } from '@/features/sales/pages/Vouchers/style';
import ConsumeCell from '../ConsumeCell';
import VouchersChip from '../VouchersChip';
import Table, { TableColDef } from '@/components/Table';
import DynamicChipList from '@/components/DynamicChipList';
import RevokedVoucherButton from '../RevokedVoucherButton';
import ExtendedGridActionsCellItem from '@/components/ExtendedGridActionsCellItem';
import { Voucher } from '@/services/SellerApi';
import { getProductSellingModeDisplay } from '@/common/product';
import {
  getAdditionalLabels,
  getTariffsQuantityLabels,
  getResourceLabels,
  sortVouchersByPredecessorId,
} from '@/common/vouchers';
import { useState } from 'react';

interface Props {
  actions: Actions;
  loading: boolean;
  vouchers: Voucher[];
  consumeVoucher: (voucher: Voucher) => void;
}

export const SaleVoucherDesktopView = (props: Props) => {
  const { actions, vouchers, consumeVoucher, loading } = props;

  const { t } = useTranslation(['vouchers']);

  const productName = vouchers[0]?.product?.internalName ?? vouchers[0]?.product?.name ?? '';
  const hasResources = vouchers[0]?.resources?.length ?? 0 > 0;
  const hasAdditionals = vouchers[0]?.additionals?.length ?? 0 > 0;
  const hasRevokedVouchers = vouchers.some((voucher) => !!voucher.revokedAt);

  const [shouldShowRevokedVouchers, setShouldShowRevokedVouchers] = useState(false);

  const renderDynamicCell = (list: string[], params: GridRenderCellParams) => {
    const cellWidth = params.api.getColumn(params.field).computedWidth!;

    if (!list.length) {
      return <VouchersChip label='-' />;
    }

    return <DynamicChipList list={list} containerWidth={cellWidth} />;
  };

  const getRows = () => {
    return shouldShowRevokedVouchers
      ? sortVouchersByPredecessorId(vouchers)
      : vouchers.filter((voucher) => !voucher.revokedAt);
  };

  const getActions = (voucher: Voucher) =>
    actions(voucher).map((action) => (
      <ExtendedGridActionsCellItem
        showInMenu
        key={action.label}
        {...action}
        disabled={!!action?.disabled}
        sx={{ width: '228px', whiteSpace: 'wrap' }}
      />
    ));

  const columns: TableColDef[] = [
    {
      flex: 1,
      minWidth: 160,
      sortable: false,
      field: 'consumption',
      headerName: t('table.consumption.title'),
      renderCell: ({ row }: GridRenderCellParams) => <ConsumeCell consumeVoucher={consumeVoucher} voucher={row} />,
    },
    {
      width: 112,
      field: 'code',
      sortable: false,
      headerName: t('table.code'),
      renderCell: ({ row }: GridRenderCellParams) => {
        return (
          <Typography
            variant='smallBold'
            color={
              shouldShowRevokedVouchers && !!row.revokedAt
                ? theme.palette.colors.gray[400]
                : theme.palette.colors.gray[700]
            }
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {row.code}
          </Typography>
        );
      },
    },
    {
      flex: 1,
      minWidth: 70,
      sortable: false,
      field: 'tariffs',
      headerName: t('table.tariff'),
      renderCell: (params: GridRenderCellParams) =>
        renderDynamicCell(getTariffsQuantityLabels(params.row.tariffs), params),
    },
    ...(hasResources
      ? [
          {
            flex: 1,
            field: 'resource',
            sortable: false,
            headerName: t('table.resource'),
            renderCell: (params: GridRenderCellParams) =>
              renderDynamicCell(getResourceLabels(params.row.resources), params),
          },
        ]
      : []),
    ...(hasAdditionals
      ? [
          {
            flex: 1,
            sortable: false,
            field: 'additionals',
            headerName: t('table.additionals'),
            renderCell: (params: GridRenderCellParams) =>
              renderDynamicCell(getAdditionalLabels(params.row.additionals), params),
          },
        ]
      : []),
    {
      type: 'actions',
      sortable: false,
      field: 'actions',
      headerName: t('table.actions.title'),
      getActions: ({ row }: GridRowParams) => (row.revokedAt ? [] : getActions(row)),
    },
  ];

  const getVoucherReservationDisplay = () => {
    const voucher = vouchers.find((voucher) => !voucher.revokedAt) || vouchers[0];

    if (!voucher) {
      return '-';
    }

    return getProductSellingModeDisplay(voucher.product!.sellingMode, voucher.scheduleDate, voucher.scheduleTime);
  };

  return (
    <Card>
      <CardContent sx={{ paddingX: 0, paddingBottom: '0px !important' }}>
        <Stack direction='row' justifyContent='space-between' paddingX={2}>
          <Stack direction='row' gap={2} alignItems='center'>
            <TicketIcon />
            <Stack direction='row' gap={1} alignItems='center'>
              <Typography variant='largeBold'>{productName}</Typography>
              <Typography variant='regularSemiBold'>{getVoucherReservationDisplay()}</Typography>
            </Stack>
          </Stack>

          {hasRevokedVouchers ? (
            <RevokedVoucherButton
              onClick={() => setShouldShowRevokedVouchers((state) => !state)}
              shouldShowRevoked={shouldShowRevokedVouchers}
            />
          ) : null}
        </Stack>

        <Divider orientation='horizontal' sx={{ marginTop: 2, color: theme.palette.colors.gray[200] }} />

        <Table
          autoHeight
          rowHeight={64}
          rows={getRows()}
          loading={loading}
          columns={columns}
          localeText={{ noRowsLabel: t('table.no_active_vouchers') }}
        />
      </CardContent>
    </Card>
  );
};

export default SaleVoucherDesktopView;
