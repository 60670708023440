import { z } from 'zod';

export const MAX_OBSERVATION_LENGTH = 500;

export const schema = z
  .object({
    observation: z.string().optional(),
  })
  .superRefine((value, ctx) => {
    if (value?.observation && value.observation.length > MAX_OBSERVATION_LENGTH) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['observation'],
        message: `Observação deve ter no máximo ${MAX_OBSERVATION_LENGTH} caracteres`,
      });
    }
  });
