import { Dispatch, ReactNode, SetStateAction, createContext, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import theme from '@/theme';

export type UIContextType = {
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
  drawerWidth: number;
  shouldCloseDrawerAfterNavigate: boolean;
  setIsDrawerExpanded: Dispatch<SetStateAction<boolean>>;
  isDrawerExpanded: boolean;
  toggleDrawer: () => void;
};

const defaultState: UIContextType = {
  drawerOpen: false,
  setDrawerOpen: () => {},
  drawerWidth: 254,
  shouldCloseDrawerAfterNavigate: false,
  isDrawerExpanded: true,
  setIsDrawerExpanded: () => {},
  toggleDrawer: () => {},
};

// eslint-disable-next-line react-refresh/only-export-components
export const UIContext = createContext<UIContextType>(defaultState);

export type UIProviderProps = {
  children: ReactNode;
};

export const UIProvider = ({ children }: UIProviderProps) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(defaultState.drawerOpen);
  const [isDrawerExpanded, setIsDrawerExpanded] = useState(defaultState.isDrawerExpanded);

  const drawerWidth = 254;

  const shouldCloseDrawerAfterNavigate = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  return (
    <UIContext.Provider
      value={{
        drawerOpen,
        setDrawerOpen,
        drawerWidth,
        isDrawerExpanded,
        setIsDrawerExpanded,
        shouldCloseDrawerAfterNavigate,
        toggleDrawer,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

export default UIProvider;
