import { colors } from '@/theme';
import { Box, BoxProps, Typography, TypographyProps } from '@mui/material';
import { ElementType, ReactNode } from 'react';

type Text = Omit<TypographyProps, 'children'> & { component?: ElementType };

export type ValueWithLabelProps = {
  title: string;
  value: string | ReactNode;
  slotProps?: {
    title?: Text;
    value?: Text;
    container?: Omit<BoxProps, 'children'>;
  };
};

export const ValueWithLabel = (props: ValueWithLabelProps) => {
  const { title, value, slotProps } = props;

  return (
    <Box display='flex' flexDirection='column' {...slotProps?.container}>
      <Typography variant='smallBold' color={colors.gray[700]} component='div' {...slotProps?.title}>
        {title}
      </Typography>
      <Typography variant='smallRegular' color={colors.gray[700]} component='div' {...slotProps?.value}>
        {value}
      </Typography>
    </Box>
  );
};

export default ValueWithLabel;
