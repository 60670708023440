import CloseIcon from '@mui/icons-material/Close';
import { Backdrop, Fade, Grid, IconButton } from '@mui/material';
import { forwardRef, Ref } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/Button';

import { Actions, CloseButtonWrapper, Content, ModalPaper, StyledModal, Title } from './styles';
import { GenericModalProps } from './types';

export const Modal = forwardRef((props: GenericModalProps, ref: Ref<HTMLDivElement>) => {
  const { t } = useTranslation(['ui']);

  const {
    title,
    open,
    onCancel = () => {},
    onClose = () => {},
    onAccept = () => {},
    showCloseButton = true,
    showCancelButton = false,
    showAcceptButton = false,
    cancelButtonText = t('modal.cancel'),
    acceptButtonText = t('modal.confirm'),
    width = '',
    children,
    keepMounted = false,
    sx,
    slotProps,
  } = props;

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      keepMounted={keepMounted}
      ref={ref}
      sx={sx}
    >
      <Fade in={open}>
        <ModalPaper container width={width} {...slotProps?.ModalPaper}>
          {showCloseButton ? (
            <CloseButtonWrapper item xs={12}>
              <IconButton aria-label={t('modal.close')} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </CloseButtonWrapper>
          ) : null}

          <Grid item xs={12}>
            <Grid container>
              {title ? (
                <Grid item xs={12}>
                  <Title>{title}</Title>
                </Grid>
              ) : null}

              {children ? (
                <Content item xs={12} sx={slotProps?.Content?.sx}>
                  {children}
                </Content>
              ) : null}

              {showAcceptButton || showCancelButton ? (
                <Grid item xs={12}>
                  <Actions container spacing={2} justifyContent='flex-start'>
                    {showAcceptButton ? (
                      <Grid item>
                        <Button variant='contained' color='primary' onClick={onAccept}>
                          {acceptButtonText}
                        </Button>
                      </Grid>
                    ) : null}

                    {showCancelButton ? (
                      <Grid item>
                        <Button variant='outlined' color='primary' onClick={onCancel}>
                          {cancelButtonText}
                        </Button>
                      </Grid>
                    ) : null}
                  </Actions>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </ModalPaper>
      </Fade>
    </StyledModal>
  );
});

export default Modal;
