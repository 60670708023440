import { Suspense, useMemo } from 'react';

import Loading from '@/components/Loading';
import { useAgenda } from '@/features/agenda/hooks/useAgenda';
import { ViewType } from '@/features/agenda/types';

// const LazyAgendaMonthlyView = lazy(
//   () => import('@/features/agenda/components/SchedulingCalendar/AgendaMonthlyView'),
// );
// const LayAgendaWeeklyView = lazy(() => import('@/features/agenda/components/SchedulingCalendar/AgendaWeeklyView'));
import LazyAgendaMonthlyView from '@/features/agenda/components/SchedulingCalendar/AgendaMonthlyView';
import LayAgendaWeeklyView from '@/features/agenda/components/SchedulingCalendar/AgendaWeeklyView';

const SchedulingCalendar = () => {
  const { calendarView: view } = useAgenda();
  const isMonthView = useMemo(() => view === ViewType.MONTH, [view]);

  return (
    <Suspense fallback={<Loading />}>{isMonthView ? <LazyAgendaMonthlyView /> : <LayAgendaWeeklyView />}</Suspense>
  );
};

export default SchedulingCalendar;
