import { ReactNode } from 'react';

import { FilterOption, FilterSchemas } from '@/interfaces/filterContext';
import { Availability, Scheduling, UpdateRuleEnum } from '@/services/SellerApi';
import { ConfigFields, UseConfig } from '@/features/config/types/config';
import { AvailabilityTypeEnum } from '@/services/sellerApiQuery/model';

export enum ViewType {
  MONTH = 30,
  WEEK = 7,
}

export enum ViewDataType {
  SCHEDULING = 'scheduling',
  AVAILABILITIES = 'availabilities',
}

export interface AgendaConfigFields extends ConfigFields {
  products: string[];
  viewDataType: ViewDataType[];
  availabilityType: AvailabilityTypeEnum[];
  activeDate: string;
  viewType: ViewType;
}

export interface AgendaConfigState extends UseConfig<AgendaConfigFields> {}

export interface AgendaSchema extends FilterSchemas {
  products: {
    type: 'array';
    title: string;
    loading: boolean;
    options: FilterOption[];
  };
  viewDataType: {
    type: 'array';
    title: string;
  };
  availabilityType: {
    type: 'array';
    title: string;
    options: FilterOption[];
  };
}

export interface WeekCalendarItem {
  firstDay: Date;
  lastDay: Date;
}

export interface DateRange {
  since?: Date;
  until?: Date;
}

export interface DateSlot {
  date: Date;
  schedulings?: Scheduling[];
  availabilities?: Availability[];
  total: {
    schedulings: number;
    availabilities: number;
  };
}

export type TimeObject = {
  days: number;
  hours: number;
  minutes: number;
};
export type LabelAndPrice = {
  id: string;
  label: string;
  price?: string | ReactNode;
};

export type UpdateRulesProps = {
  ruleUntilDate?: Date;
  rule: UpdateRuleEnum;
};
