import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';

export const ReceiptAmountCard = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '16px',
  marginBottom: '24px',
  fontSize: '1.188rem',
  backgroundColor: theme.palette.colors.gray[50],
  fontWeight: '500',
  lineHeight: '22px',
  padding: '16px',
}));
