import { UseConfig } from '@/features/config/types/config';
import { useCallback } from 'react';

const useConfigManager = <Fields,>(state: UseConfig<Fields>) => {
  const patchConfig = useCallback((patch: Partial<Fields>) => {
    state.config = {
      ...state.config,
      ...patch,
    };
  }, []);

  const clearConfig = useCallback(() => {
    state.config = {
      ...state.defaultConfig,
    };
  }, []);

  return { config: state?.config ?? {}, patchConfig, clearConfig, defaultConfig: state.defaultConfig };
};
export default useConfigManager;
