import { Box, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import AppLogo from '@/components/AppLogo';

interface StyledCardProps {
  selected?: boolean;
}

export const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<StyledCardProps>(({ theme, selected }) => ({
  padding: '8px 16px',
  background: theme.palette.colors.gray[50],
  borderWidth: selected ? '2px' : '1px',
  borderStyle: 'solid',
  borderColor: selected ? theme.palette.colors.blue[600] : theme.palette.colors.gray[400],
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  height: '58px',
  gap: '8px',
}));

export const AppLogoContainer = styled(Box)(() => ({
  width: '40px',
  display: 'flex',
}));

export const StyledAppLogo = styled(AppLogo)(({ theme }) => ({
  width: '100%',
  objectFit: 'cover',
  color: theme.palette.colors.gray[500],
}));

export const Name = styled('h3')(({ theme }) => ({
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '19px',
  color: theme.palette.colors.gray[700],
  margin: 0,
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  whiteSpace: 'pre-wrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flex: 1,
  wordBreak: 'break-all',
}));
