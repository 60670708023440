import { Outlet } from 'react-router-dom';

import AppBar from '@/components/AppBar';
import { MainHeader } from '@/layouts/Dashboard/styles';
import { Box } from '@mui/material';
import { ProposalLayoutContainer } from './styles';
import ErrorBoundary from '@/components/Error/ErrorBoundary';
import ErrorTechnical from '@/components/Error/ErrorTechnical';

export const ProposalLayout = () => {
  return (
    <Box>
      <AppBar showDrawerButton={false} />

      <ProposalLayoutContainer>
        <MainHeader />

        <ErrorBoundary FallbackComponent={ErrorTechnical}>
          <Outlet />
        </ErrorBoundary>
      </ProposalLayoutContainer>
    </Box>
  );
};

export default ProposalLayout;
