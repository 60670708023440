import { Link } from '@mui/material';
import { styled } from '@mui/system';

export const LogoContainer = styled(Link, {
  shouldForwardProp: () => true,
})(() => ({
  width: '100%',
  maxWidth: '194px',
  display: 'flex',
}));

export const Logo = styled('img', {
  shouldForwardProp: () => true,
})(() => ({
  width: '100%',
  height: '100%',
}));
