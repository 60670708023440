import { zodResolver } from '@hookform/resolvers/zod';
import { Typography } from '@mui/material';
import { Box, Stack, useMediaQuery } from '@mui/system';
import { IconCheck, IconX } from '@tabler/icons-react';
import { ReactNode, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Loading from '@/components/Loading';
import ModalOnDesktopDrawerOnMobile from '@/components/Modal/ModalOnDesktopDrawerOnMobile';
import { UpdateRuleEnum } from '@/services/SellerApi';
import theme from '@/theme';

import { schema } from './schema';
import { DateContainer, StyledButton, StyledRadioButtonsGroup } from './styles';
import CustomDatePicker from '@/components/Form/DatePicker';
import { UpdateRulesProps } from '@/features/agenda/types';

interface Props {
  isOpen: boolean;
  isLoading?: boolean;
  title?: string | ReactNode;
  description?: string | ReactNode | boolean;
  acceptedUpdateRules?: UpdateRuleEnum[];
  onConfirm?: (data: UpdateRulesProps) => void;
  onCancel?: () => void;
  onClose: () => void;
  initialDate?: Date;
}

export const RecurrenceSelectorModal = (props: Props) => {
  const { t } = useTranslation(['agenda']);
  const {
    isOpen,
    isLoading = false,
    title,
    description = `${t('recurrence_selector.label')}:`,
    acceptedUpdateRules = [
      UpdateRuleEnum.ThisOnly,
      UpdateRuleEnum.ThisAndFollowing,
      UpdateRuleEnum.ThisAndFollowingUntil,
      UpdateRuleEnum.All,
    ],
    onConfirm = () => {},
    onCancel = () => {},
    onClose = () => {},
    initialDate,
  } = props;

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { handleSubmit, formState, control, reset, watch } = useForm<UpdateRulesProps>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: zodResolver(schema(t)),
  });
  const { errors } = formState;

  const options = useMemo(
    () =>
      acceptedUpdateRules.map((option) => ({
        value: option,
        label: t(`recurrence_selector.options.${option}`),
      })),
    [acceptedUpdateRules, t],
  );

  const ruleWatch = watch('rule');
  const isRuleUntilDate = useMemo(() => ruleWatch === UpdateRuleEnum.ThisAndFollowingUntil, [ruleWatch]);

  const onSubmit = (data: UpdateRulesProps) => {
    onConfirm(data);
    reset();
  };

  const minimumDate = () => {
    if (!initialDate) return new Date();
    const nextDay = new Date(initialDate);
    nextDay.setDate(nextDay.getDate() + 1);
    return nextDay;
  };

  return (
    <ModalOnDesktopDrawerOnMobile isOpen={isOpen} onClose={() => onClose()} title={title} width='25rem'>
      <Box component='form' onSubmit={handleSubmit(onSubmit)}>
        {isLoading && (
          <Stack sx={{ position: 'absolute', inset: 0, justifyContent: 'center' }}>
            <Loading />
          </Stack>
        )}
        {description && (
          <Typography component='p' variant='smallSemiBold' sx={{ marginBottom: '1rem' }}>
            {description}
          </Typography>
        )}

        <Box sx={{ marginBottom: '1rem' }}>
          <Controller
            name='rule'
            control={control}
            render={({ field: { onChange, value, name, ...rest } }) => (
              <StyledRadioButtonsGroup
                required
                id={name}
                name={name}
                value={value || ''}
                onChange={onChange}
                error={!!errors[name]}
                helperText={errors[name]?.message as string}
                options={options}
                {...rest}
              />
            )}
          />
        </Box>

        {isRuleUntilDate && (
          <DateContainer>
            <Controller
              control={control}
              defaultValue={undefined}
              name='ruleUntilDate'
              render={({ field: { value, name, onChange, ...rest } }) => (
                <CustomDatePicker
                  name={name}
                  format='dd/MM/yyyy'
                  closeOnSelect
                  value={value || null}
                  error={!!errors[`${name}`]}
                  fullWidth
                  minDate={minimumDate()}
                  onChange={(date) => {
                    onChange(date as Date | undefined);
                  }}
                  label={t('recurrence_selector.until')}
                  helperText={(errors[`${name}`]?.message as string) ?? ''}
                  tooltipProps={{
                    placement: 'top',
                  }}
                  {...rest}
                />
              )}
            />
          </DateContainer>
        )}

        <Stack
          flexDirection={isMobile ? 'column-reverse' : 'row'}
          justifyContent='flex-end'
          gap='0.5rem'
          flexWrap='wrap'
        >
          <StyledButton
            variant='outlined'
            color='secondary'
            disabled={isLoading}
            onClick={() => onCancel()}
            startIcon={<IconX />}
            fullWidth={isMobile}
          >
            {t('recurrence_selector.cancel')}
          </StyledButton>

          <StyledButton
            type='submit'
            variant='contained'
            color='primary'
            disabled={isLoading}
            startIcon={isMobile ? <IconCheck /> : undefined}
            endIcon={!isMobile ? <IconCheck /> : undefined}
            fullWidth={isMobile}
          >
            {t('recurrence_selector.confirm')}
          </StyledButton>
        </Stack>
      </Box>
    </ModalOnDesktopDrawerOnMobile>
  );
};

export default RecurrenceSelectorModal;
