import { Popover, PopoverOrigin, Stack, Typography } from '@mui/material';
import { ChevronLeft, ChevronRight, XClose } from '@untitled-ui/icons-react';
import { useEffect, useMemo, useState } from 'react';

import Button from '@/components/Button';
import { useAgenda } from '@/features/agenda/hooks/useAgenda';
import theme, { colors } from '@/theme';

import MonthCalendar from './MonthCalendar';
import { StyledArrowButton } from './styles';
import WeekCalendar from './WeekCalendar';
import { ViewType } from '@/features/agenda/types';

interface Props {
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

const SelectMonthOrWeekModal = (props: Props) => {
  const { onClose, isOpen, anchorEl } = props;

  const { activeDate: currentDate, calendarView: view } = useAgenda();
  const [activeDate, setActiveDate] = useState<Date>(currentDate);
  const anchorOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  };

  const next = () => {
    if (view === ViewType.MONTH) {
      // Next Year
      setActiveDate(new Date(activeDate.getFullYear() + 1, activeDate.getMonth(), activeDate.getDate()));
    } else {
      // Next week
      setActiveDate(new Date(activeDate.getFullYear(), activeDate.getMonth() + 1, activeDate.getDate()));
    }
  };

  const prev = () => {
    if (view === ViewType.MONTH) {
      // Previous Year
      setActiveDate(new Date(activeDate.getFullYear() - 1, activeDate.getMonth(), activeDate.getDate()));
    } else {
      // Previous week
      setActiveDate(new Date(activeDate.getFullYear(), activeDate.getMonth() - 1, activeDate.getDate()));
    }
  };

  const iconColors = colors.gray['700'];

  const activeTitle = useMemo(() => {
    return view === ViewType.MONTH
      ? activeDate.getFullYear().toString()
      : `${activeDate.toLocaleString('default', { month: 'long' })} ${activeDate.getFullYear()}`;
  }, [activeDate, view]);

  useEffect(() => {
    setActiveDate(currentDate);
  }, [currentDate]);

  return (
    <Popover open={!!isOpen} anchorEl={anchorEl} onClose={onClose} anchorOrigin={anchorOrigin}>
      <Stack sx={{ flexDirection: 'column', padding: '1rem', gap: theme.spacing(4) }}>
        <Stack sx={{ flexDirection: 'row' }}>
          <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: theme.spacing(4) }}>
            <StyledArrowButton color='primary' variant='text' onClick={prev}>
              <ChevronLeft color={iconColors} />
            </StyledArrowButton>
            <StyledArrowButton color='primary' variant='text' onClick={next}>
              <ChevronRight color={iconColors} />
            </StyledArrowButton>
            <Typography variant='regularBold' textTransform='capitalize'>
              {activeTitle}
            </Typography>
          </Stack>

          <Button color='primary' variant='text' onClick={onClose} sx={{ marginLeft: 'auto' }}>
            <XClose color={iconColors} />
          </Button>
        </Stack>

        {view === ViewType.MONTH ? (
          <MonthCalendar activeYear={activeDate.getFullYear()} />
        ) : (
          <WeekCalendar activeDate={activeDate} />
        )}
      </Stack>
    </Popover>
  );
};

export default SelectMonthOrWeekModal;
