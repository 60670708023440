import { TFunction } from 'i18next';

import { Recurrence, RecurrenceTypeEnum } from '@/services/SellerApi';
import apiDateToDateObject from '@/utils/apiDateToDateObject';

interface IGetRecurrenceStringReturn {
  recurrence: string;
  weekDays: string | undefined;
  to: string | undefined;
}

export const getRecurrenceString = (
  recurrence: Recurrence,
  t: TFunction<[string], undefined>,
  translateScope: string,
): IGetRecurrenceStringReturn => {
  const { type, amount, weekdays } = recurrence;
  const recurrenceString =
    type === RecurrenceTypeEnum.Days
      ? t(`${translateScope}daily`, { count: amount })
      : t(`${translateScope}weekly`, { count: amount });

  let weekdaysStringJoined;
  if (weekdays) {
    const validWeekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const weekdaysString = weekdays.map((weekday: string) => {
      const date = new Date();
      const findWeekday = validWeekdays.findIndex(
        (validWeekday) => validWeekday.toLowerCase() === weekday.toLowerCase(),
      );
      date.setDate(date.getDate() + ((findWeekday - date.getDay() + 7) % 7));
      return date.toLocaleDateString(undefined, { weekday: 'short' });
    });

    // join all weekdays into a single string
    weekdaysStringJoined = weekdaysString.join(', ');
  }

  const toString = recurrence.endDate
    ? `${t('to')}: ${apiDateToDateObject(recurrence.endDate).toLocaleDateString()}`
    : undefined;

  return {
    recurrence: recurrenceString,
    weekDays: weekdaysStringJoined,
    to: toString,
  };
};
