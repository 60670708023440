import { AlertProps } from '@mui/material';
import { ToastTitle, ToastWarper } from './styles';

interface ToastProps {
  children: React.ReactNode;
  state?: 'default' | 'error' | 'warning' | 'success';
  onClose?: () => void;
}

const Toast = (props: ToastProps) => {
  const { children, state = 'default', onClose } = props;

  const severity = {
    default: 'info',
    error: 'error',
    warning: 'warning',
    success: 'success',
  }[state] as AlertProps['severity'];

  return (
    <ToastWarper icon={false} severity={severity} onClose={onClose} data-testid='toast-warper'>
      <ToastTitle>{children}</ToastTitle>
    </ToastWarper>
  );
};

export default Toast;
