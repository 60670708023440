import { LabelAndPrice } from '@/features/agenda/types';
import { Availability, Tariff } from '@/services/SellerApi';
import { formatCents } from '@/utils';
const getTariffPrice = (tariff: Tariff): string | undefined => {
  return tariff.priceCents ? formatCents(tariff.priceCents) : formatCents(0);
};

const getTariffLabelAndPrice = (tariff: Tariff): LabelAndPrice | undefined => {
  if (tariff.type && tariff.product?.id) {
    const price = getTariffPrice(tariff);

    return {
      id: `tariff-${tariff.id}-${tariff.product.id}-${tariff.type.id}`,
      label: tariff.type?.name ?? '',
      price,
    };
  }
  return undefined;
};

const getAvailabilityTariffsLabelPrice = (availability: Availability): LabelAndPrice[] | undefined => {
  if (availability.tariffs && availability.tariffs.length > 0) {
    const tariffs = availability.tariffs.map(getTariffLabelAndPrice).filter(Boolean);

    if (tariffs.length > 0) {
      return tariffs as LabelAndPrice[];
    }
  }
  return undefined;
};

export default getAvailabilityTariffsLabelPrice;
