import { Stack, Typography, styled } from '@mui/material';

export const DividerWithTitle = styled(Typography)(({ theme }) => ({
  gap: 2,
  display: 'flex',
  padding: '8px 0px',
  alignSelf: 'stretch',
  color: theme.palette.colors.gray[800],
  alignItems: 'flex-start',
  borderBottom: `1px solid ${theme.palette.colors.gray[300]}`,
}));

export const Actions = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  alignItems: 'center',
  justifyContent: 'flex-end',
}));
