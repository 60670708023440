import { PolicyRule, PolicyRuleNamespace } from '@/services/sellerApiQuery/model';

export type PolicyCode = `${PolicyRuleNamespace}:${string}`;

const policyCodeToRule = (policyCode: PolicyCode): PolicyRule => {
  const [namespace, action] = policyCode.split(':') as [PolicyRuleNamespace, string];
  return {
    namespace,
    action,
    code: policyCode,
  };
};

export default policyCodeToRule;
