import { Helmet } from 'react-helmet-async';
import Login from '@/features/auth/components/Login';
import { useEffect } from 'react';
import useAnalytics from '@/hooks/analytics/useAnalytics';

const LoginPage = () => {
  const { track } = useAnalytics();

  useEffect(() => {
    track('loginPage/access');
  }, []);

  return (
    <>
      <Helmet>
        <title>Planne | Login</title>
      </Helmet>

      <Login />
    </>
  );
};

export default LoginPage;
