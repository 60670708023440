import { SaleStateEnum } from '@/services/SellerApi';
import theme from '@/theme';
import { styled, darken, Chip } from '@mui/material';

export const statusMap: Record<SaleStateEnum, { text: string; background: string }> = {
  payment_chargeback: {
    text: theme.palette.colors.red[700],
    background: theme.palette.colors.red[100],
  },
  payment_voided: {
    text: theme.palette.colors.red[700],
    background: theme.palette.colors.red[100],
  },
  canceled: {
    text: theme.palette.colors.red[700],
    background: theme.palette.colors.red[100],
  },
  payment_complete: {
    text: theme.palette.colors.green[700],
    background: theme.palette.colors.green[100],
  },
  expired: {
    text: 'rgba(0, 0, 0, 0.87)',
    background: 'rgba(0, 0, 0, 0.08)',
  },
  created: {
    text: theme.palette.secondary[700],
    background: theme.palette.secondary[100],
  },
  pending: {
    text: theme.palette.colors.yellow[700],
    background: theme.palette.colors.yellow[100],
  },
};

export type StyledChipProps = {
  status: SaleStateEnum;
};

export const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'status',
})<StyledChipProps>(({ status }) => ({
  backgroundColor: statusMap[status].background,
  color: statusMap[status].text,

  '&:hover': {
    backgroundColor: darken(statusMap[status].background, 0.08),
  },
}));
