import { Box, Typography } from '@mui/material';

import { colors } from '@/theme';

export const SaleDatailItem = (props: { title: string; value: string }) => {
  const { title, value } = props;

  return (
    <Box>
      <Typography variant='regularBold' color={colors.gray[700]}>
        {title}
      </Typography>

      <Typography variant='regularRegular' color={colors.gray[700]}>
        {value}
      </Typography>
    </Box>
  );
};

export default SaleDatailItem;
