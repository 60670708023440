import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import PageLayout from '@/layouts/PageLayout';
import FilterProvider from '@/contexts/FilterContext';
import { CouponList } from '@/features/coupons/components/CouponList';
import CouponFilterFields from '@/features/coupons/components/CouponFilterFields';
import useCouponFilterSchema from '../CouponList/useCouponFilterSchema';

export const CanceledCoupons = () => {
  const { t } = useTranslation(['coupons']);
  const filterSchema = useCouponFilterSchema(t);

  const navigate = useNavigate();

  const breadcrumbs = [
    {
      label: t('canceled.breadcrumbs.available_coupons'),
      to: '../coupons',
    },
    {
      label: t('canceled.breadcrumbs.canceled_coupons'),
      to: '.',
    },
  ];

  return (
    <FilterProvider schema={filterSchema} contextName='couponsCanceled'>
      <Helmet>
        <title>{t('canceled.page_title')}</title>
      </Helmet>

      <PageLayout
        breadcrumbs={breadcrumbs}
        title={t('canceled.layout_title')}
        showGoBackButton
        onGoBack={() => navigate('../coupons')}
        slots={{ FilterFields: CouponFilterFields }}
        slotProps={{
          searchInput: {
            placeholder: t('filter.search_input_placeholder'),
          },
        }}
      >
        <CouponList currentStatus='archived' />
      </PageLayout>
    </FilterProvider>
  );
};

export default CanceledCoupons;
