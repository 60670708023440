import { Box, Typography, styled } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginBottom: '8px',
  },
}));

export const ProviderLogo = styled('img')(() => ({
  borderRadius: '4px',
  height: '32px',
  marginRight: '8px',
}));

export const ProviderName = styled(Typography)(() => ({}));
