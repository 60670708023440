import { AutocompleteProps } from '@mui/material';
import { forwardRef, Ref } from 'react';
import { FieldErrors } from 'react-hook-form';

import { InputProps } from '@/components/Form/Input';
import Select from '@/components/Form/Select';

type Props = Omit<AutocompleteProps<unknown, boolean, boolean, boolean>, 'renderInput'> &
  Omit<InputProps, 'onChange'> & {
    tooltipTitle?: string;
    errors: FieldErrors;
    onChange: (value: unknown) => void;
  };

const SelectInputTime = forwardRef((props: Props, ref: Ref<HTMLDivElement>) => {
  const { onChange, errors, name, tooltipTitle, ...rest } = props;
  return (
    <Select
      fullWidth
      disableClearable
      error={!!errors[`${name}`]}
      onChange={(_, value) => {
        onChange(value);
      }}
      helperText={(errors[`${name}`]?.message as string) ?? ''}
      {...(tooltipTitle ? { tooltipProps: { placement: 'top', title: tooltipTitle } } : {})}
      {...rest}
      ref={ref}
    />
  );
});

export default SelectInputTime;
