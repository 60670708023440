import { Box, styled } from '@mui/material';

export const TimerContainer = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  alignItems: 'flex-start',
  alignContent: 'flex-start',
  gap: '6px',
  flexWrap: 'wrap',
  overflow: 'hidden',
}));
