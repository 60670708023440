import { Grid, GridProps, Modal } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CloseButtonWrapper = styled(Grid)(({ theme }) => ({
  textAlign: 'right',
  position: 'fixed',
  top: '16px',
  right: '16px',
  zIndex: 10,

  [theme.breakpoints.up('sm')]: {
    position: 'absolute',
  },
}));

interface ModalPaperProps {
  width?: string | number;
}

export const ModalPaper = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'width',
})<ModalPaperProps>(({ theme, width }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  width: '100%',
  height: '100%',
  padding: '20px 24px',
  borderRadius: '0',
  position: 'relative',
  overflowY: 'auto',
  overflowX: 'hidden',
  display: 'flex',

  [theme.breakpoints.up('sm')]: {
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#DADADA',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#B5B5B5',
      borderRadius: '10px',
      '&:hover': {
        background: '#888',
      },
    },
    padding: '20px 24px',
    height: 'fit-content',
    maxHeight: '95vh',
    maxWidth: width || '400px',
    borderRadius: '4px',
    alignItems: 'center',
  },
}));

export const StyledModal = styled(Modal)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'scroll',
}));

export const Title = styled('h2')(() => ({
  fontSize: '23px',
  color: '#000000',
  fontWeight: 700,
  margin: 0,
  paddingRight: '24px',
  marginBottom: '8px',
}));

export const Content = styled(Grid)<GridProps>(() => ({
  fontSize: '14px',
  color: '#505050',
}));

export const Actions = styled(Grid)(() => ({
  marginTop: 10,
  '& .MuiButton-label': {
    fontSize: '12px',
  },
}));
