import { CardContent } from '@mui/material';
import { styled, Theme } from '@mui/system';
import { Link } from 'react-router-dom';

const ActionBase = (theme: Theme) => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  };
};

export const StyledActionButton = styled('button')(({ theme }) => ({
  ...ActionBase(theme),
}));

export const StyledActionLink = styled(Link)(({ theme }) => ({
  ...ActionBase(theme),
}));

export const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(4),

  '&:last-child': {
    paddingBottom: theme.spacing(4),
  },
}));
