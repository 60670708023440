import { styled } from '@mui/material/styles';

export const Content = styled('div')(({ theme }) => ({
  padding: theme.spacing(6),
  width: '100%',

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(4),
  },
}));
