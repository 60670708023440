import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import RefundRequestForm from '@/components/RefundRequests/RefundRequestForm';
import ModalOnDesktopDrawerOnMobile from '@/components/Modal/ModalOnDesktopDrawerOnMobile';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const RequestRefundModal = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['refundRequests']);
  const { saleId } = useParams<{ saleId: string }>();
  const [openModal, setOpenModal] = useState(!!saleId);
  const { refreshRefundRequestList } = useOutletContext<{ refreshRefundRequestList: () => void }>();

  const close = () => {
    setOpenModal(false);
    navigate('../', { replace: true });
  };
  const success = () => {
    close();
    refreshRefundRequestList();
  };

  return (
    <ModalOnDesktopDrawerOnMobile isOpen={openModal} onClose={close} title={t('modal.title')} width={564}>
      <RefundRequestForm onSuccess={success} saleId={saleId ?? ''} onCancel={close} />
    </ModalOnDesktopDrawerOnMobile>
  );
};

export default RequestRefundModal;
