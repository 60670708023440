import { Typography } from '@mui/material';
import { ReactNode } from 'react';
import { ErrorSectionBodyActions, ErrorSectionBodyWrapper } from './styles';

interface ErrorSectionBodyProps {
  title: string;
  subTitle: string;
  description: string | ReactNode;
  children: ReactNode;
}

const ErrorSectionBody = (props: ErrorSectionBodyProps) => {
  const { title, subTitle, description, children } = props;
  return (
    <ErrorSectionBodyWrapper>
      <Typography variant='h4' data-testid='error-title'>
        {title}
      </Typography>
      <Typography variant='largeSemiBold' data-testid='error-subtitle'>
        {subTitle}
      </Typography>
      <Typography>{description}</Typography>

      <ErrorSectionBodyActions>{children}</ErrorSectionBodyActions>
    </ErrorSectionBodyWrapper>
  );
};

export default ErrorSectionBody;
