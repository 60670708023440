import { defaultCountries, parseCountry, buildCountryData } from 'react-international-phone';

export const countries = defaultCountries.map((country) => {
  const parsedCountry = parseCountry(country);

  if (parsedCountry.iso2 === 'ar') {
    parsedCountry.format = {
      default: '..............',
    };
  }

  return buildCountryData(parsedCountry);
});
