import { AssociationModel, DetailedSaleItem, Sale } from '@/services/SellerApi';
import i18n from '@/services/i18n';
import { IconBrowser, IconWorld, IconLink, IconUsersGroup, IconBuildingStore } from '@tabler/icons-react';

export enum SaleAttributes {
  Brocker = 'brocker',
  TcheOfertas = 'tcheofertas',
  Zarpou = 'zarpou',
  ViagensPromo = 'viagenspromo',
  GuiaPass = 'guiapass',
  Lacador = 'lacador',
  PrimeGourmet = 'primegourmet',
  Voppi = 'voppi',
  TourFacil = 'tourfacil',
  WineBus = 'winebus',
  ImoveTurismo = 'imoveturismo',
  EasyLive = 'easylive',
  ParksNet = 'parksnet',
  TurismoSerraGaucha = 'turismoserragaucha',
  TurismoGramado = 'turismogramado',
  Turistur = 'turistur',
  TuddoGramado = 'tuddogramado',
  MasterIngressos = 'masteringressos',
  MMCTurismo = 'mmcturismo',
}

export type SaleSource = {
  source: string;
  otas?: string[];
  formatted: string;
  icon: typeof IconWorld;
};

export const getSaleSource = (sale: Sale, saleItems?: DetailedSaleItem[]): SaleSource => {
  let source = 'site';

  const isResale = saleItems?.some((element) =>
    element.partnershipItem?.associationModels?.includes(AssociationModel.Resale),
  );

  const isOrderAppDifferentFromSaleApp = sale.order && sale.order.appId != sale.appId;

  if (sale.directLinkId) {
    source = 'direct_link';
  } else if (sale.agentId) {
    source = 'direct_sale';
  } else if (sale.isExternal) {
    source = 'external';
  } else if (isResale || isOrderAppDifferentFromSaleApp) {
    source = 'resale';
  }

  const icon: typeof IconWorld | undefined = {
    site: IconWorld,
    direct_link: IconLink,
    external: IconBrowser,
    direct_sale: IconUsersGroup,
    resale: IconBuildingStore,
  }[source];

  const attributes = sale.attributes;
  const formattedSource = i18n.t(`sources.${source}`, { ns: 'sales' });
  const formattedOtas = attributes
    ? attributes.map((attribute) => i18n.t(`otas.${attribute}`, { ns: 'sales' }))
    : [i18n.t(`otas.not_provided`, { ns: 'sales' })];

  return {
    source: source,
    otas: attributes ?? undefined,
    formatted: source === 'external' ? `${formattedSource} - ${formattedOtas.join(', ')}` : `${formattedSource}`,
    icon: icon!,
  };
};
