import { Box, Grid, Link, Tooltip, Typography } from '@mui/material';
import { Dispatch, Fragment, SetStateAction, useState } from 'react';
import { GridRenderCellParams, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { StyledChip } from '@/components/Partnership/StatusChip/style';
import { formatCents } from '@/utils';
import { CoinsStacked03, File02, SwitchHorizontal01, Ticket01, User01 } from '@untitled-ui/icons-react';
import { Sale, Tag } from '@/services/SellerApi';
import { Add, SwapHorizOutlined, Visibility } from '@mui/icons-material';

import Table, { TableColDef } from '@/components/Table';
import theme, { colors } from '@/theme';
import Preview from './MobilePreview';
import DynamicChipList from '@/components/DynamicChipList';
import SaleTagsPopup, { TagChip } from '../SaleTagsPopup';
import { getAggregatedVouchersValueAndStatus } from '../utils';
import { getSaleSource } from '@/common/sale';
import SaleStatusChip from '../SaleStatusChip';
import ExtendedGridActionsCellItem from '@/components/ExtendedGridActionsCellItem';
import useAccount from '@/hooks/useAccount';
import ModalOnDesktopDrawerOnMobile from '@/components/Modal/ModalOnDesktopDrawerOnMobile';
import CustomerDetailWithCloseButton from '@/components/CustomerDetail/CustomerWithCloseButton';

type Props = {
  totalSales: number;
  sales: Sale[];
  loading: boolean;
  paginationModel: {
    page: number;
    pageSize: number;
  };
  setPaginationModel: Dispatch<
    SetStateAction<{
      page: number;
      pageSize: number;
    }>
  >;
  onRefresh: () => Promise<void>;
  setLoading: Dispatch<SetStateAction<boolean>>;
};

const SalesTableStructure = (props: Props) => {
  const { totalSales, sales, loading, paginationModel, setPaginationModel, onRefresh } = props;

  const { t } = useTranslation(['sales']);
  const { hasPermission } = useAccount();

  const [selectedSaleId, setSelectedSaleId] = useState<string>();
  const [openPreviewDrawer, setOpenPreviewDrawer] = useState(false);
  const [openCustomerModal, setOpenCustomerModal] = useState(false);

  const [tagAnchor, setAnchorEl] = useState<{ saleId?: string; element: null | HTMLElement }>({
    element: null,
    saleId: undefined,
  });

  const findSelectedSale = (id?: string) => {
    return sales.find((sale) => sale.id === id);
  };

  const handleCloseCustomerModal = () => {
    setOpenCustomerModal(false);
    setSelectedSaleId(undefined);

    if (openPreviewDrawer) {
      setOpenPreviewDrawer(false);
    }
  };

  const handleOpenCustomerModal = (saleId: string) => {
    setOpenCustomerModal(true);
    setSelectedSaleId(saleId);
  };

  const handleTagClick = (event: React.MouseEvent<HTMLButtonElement>, sale: Sale) => {
    setAnchorEl({ saleId: sale.id, element: event.currentTarget });
  };

  const handleTagClose = async () => {
    setAnchorEl({
      saleId: undefined,
      element: null,
    });

    await onRefresh();
  };

  const getTagNames = (tags: Tag[]) => {
    if (!tags?.length) {
      return null;
    }

    return tags.map((tag) => tag.name);
  };

  const renderSaleState = (sale: Sale) => (
    <Box display='flex' alignItems='center' gap={1}>
      <SaleStatusChip size='small' status={sale.currentState} label={t(`status.${sale.currentState}`)} />

      {sale.aggregatedRefundRequests?.open ? (
        <Tooltip
          arrow
          placement='top'
          enterTouchDelay={0}
          leaveTouchDelay={60000}
          title={t('list.table.row.refund_request_tooltip')}
          PopperProps={{ style: { zIndex: 99999, width: 128, textAlign: 'center' } }}
        >
          <StyledChip
            size='small'
            color='info'
            variant='outlined'
            label={<SwapHorizOutlined fontSize='small' />}
            sx={{ border: 'none', '& .MuiChip-label': { display: 'flex' } }}
          />
        </Tooltip>
      ) : null}
    </Box>
  );

  const renderAggregatedVouchers = (sale: Sale) => {
    const { status, value } = getAggregatedVouchersValueAndStatus(sale);

    return <StyledChip label={value} color={status} size='small' />;
  };

  const actions = (sale: Sale) => [
    {
      icon: <Visibility />,
      getLink: (sale: Sale) => sale.id,
      label: t('list.table.actions.view'),
    },
    {
      icon: <User01 />,
      label: t('list.table.actions.customer'),
      onClick: (sale: Sale) => handleOpenCustomerModal(sale.id),
    },
    {
      icon: <Ticket01 />,
      label: t('list.table.actions.vouchers'),
      getLink: (sale: Sale) => `${sale.id}/vouchers`,
    },
    {
      disabled: !hasPermission('sales', 'GetSale'),
      icon: <File02 />,
      label: t('list.table.actions.extract'),
      getLink: (sale: Sale) => `${sale.id}/receipt`,
    },
    {
      icon: <CoinsStacked03 />,
      label: t('list.table.actions.Receivables'),
      getLink: (sale: Sale) => `${sale.id}/income-schedulings`,
      disabled: !hasPermission('incomeSchedulings', 'GetSaleIncomeSchedulings'),
    },
    {
      icon: <SwitchHorizontal01 />,
      label: t('list.table.actions.refund'),
      getLink: (sale: Sale) => `${sale.id}/refund`,
      disabled:
        !hasPermission('refundRequests', 'CreateSaleRefundRequest') || Boolean(sale?.aggregatedRefundRequests?.open),
    },
  ];

  const getActions = (sale: Sale) =>
    actions(sale).map((action) => (
      <ExtendedGridActionsCellItem
        {...action}
        key={action.label}
        showInMenu
        href={action.getLink?.(sale)}
        onClick={() => action.onClick?.(sale)}
        component={action.getLink?.(sale) ? Link : 'button'}
      />
    ));

  const renderSaleSource = (sale: Sale) => {
    const source = getSaleSource(sale);
    const color = theme.palette.colors.gray[500];
    const Icon = source.icon;

    return (
      <Tooltip
        arrow
        placement='top'
        enterTouchDelay={0}
        leaveTouchDelay={60000}
        title={source.formatted}
        PopperProps={{ style: { zIndex: 9999 } }}
      >
        <Icon color={color} />
      </Tooltip>
    );
  };

  const columns: TableColDef[] = [
    {
      field: 'position',
      headerName: t('list.table.header.position'),
      sortable: false,
      width: 60,
      renderCell: (params: GridRenderCellParams) => {
        return params.row.position;
      },
    },
    {
      flex: 1,
      field: 'code',
      headerName: t('list.table.header.code'),
      sortable: false,
      minWidth: 130,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Link
            variant='smallBold'
            href={params.row.id}
            onClick={(e) => e.stopPropagation()}
            title={params.row.code}
            sx={{
              cursor: 'pointer',
              color: colors.blue[500],
              textDecoration: 'underline',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {params.row.code}
          </Link>
        );
      },
    },
    {
      flex: 1,
      sortable: false,
      field: 'customer.name',
      headerName: t('list.table.header.custumer_name'),
      renderAsLeftTitleOnMobile: true,
      renderCell: (params: GridRenderCellParams) => {
        const customerName = `${params.row.customer.firstName} ${params.row.customer.lastName}`;

        return (
          <Link
            variant='smallBold'
            onClick={(e) => {
              e.stopPropagation();

              handleOpenCustomerModal(params.row.id);
            }}
            sx={{ cursor: 'pointer', color: colors.blue[500], textDecoration: 'underline' }}
          >
            {customerName}
          </Link>
        );
      },
    },
    {
      flex: 1,
      sortable: false,
      field: 'currentState',
      minWidth: 180,
      renderAsRightTitleOnMobile: true,
      headerName: t('list.table.header.current_state'),
      renderCell: (params: GridRenderCellParams) => renderSaleState(params.row),
    },
    {
      field: 'amountCents',
      headerName: t('list.table.header.amount_cents'),
      valueGetter: (params: GridValueGetterParams) => formatCents(params.row.amountCents ?? 0),
      sortable: false,
      enableOnMobile: true,
      maxWidth: 200,
    },
    {
      field: 'source',
      headerName: t('list.table.header.source'),
      valueGetter: (params: GridValueGetterParams) => getSaleSource(params.row),
      renderCell: (params: GridRenderCellParams) => renderSaleSource(params.row),
      sortable: false,
      maxWidth: 150,
      width: 120,
      align: 'center',
    },
    {
      field: 'aggregatedVouchers',
      headerName: t('list.table.header.aggregated_vouchers'),
      renderCell: (params: GridRenderCellParams) => renderAggregatedVouchers(params.row),
      maxWidth: 80,
      sortable: false,
      enableOnMobile: true,
    },
    {
      field: 'tags',
      headerName: t('list.table.header.tags'),
      renderCell: (params: GridRenderCellParams) => {
        const cellWidth = params.api.getColumn(params.field).computedWidth!;
        const tagNames = getTagNames(params.row.tags);

        return (
          <Fragment>
            {!tagNames ? (
              <TagChip
                sx={{ '& .MuiChip-label': { display: 'flex' } }}
                label={<Add fontSize='small' sx={{ width: '16px', height: '16px' }} />}
                onClick={(e) => handleTagClick(e as unknown as React.MouseEvent<HTMLButtonElement>, params.row)}
              />
            ) : null}

            {tagNames?.length ? (
              <DynamicChipList
                chipProps={{
                  onClick: (e) => handleTagClick(e as unknown as React.MouseEvent<HTMLButtonElement>, params.row),
                  sx: {
                    border: 'none',
                    cursor: 'pointer',
                    borderRadius: '4px',
                    background: colors.blue[100],
                    '&:hover': { background: colors.blue[100] },
                  },
                }}
                list={tagNames}
                containerWidth={cellWidth}
              />
            ) : null}

            {tagAnchor.saleId === params.row.id ? (
              <SaleTagsPopup
                sale={params.row}
                tagAnchor={tagAnchor}
                onClose={handleTagClose}
                onClick={handleTagClick}
                open={tagAnchor.saleId === params.row.id}
              />
            ) : null}
          </Fragment>
        );
      },
      sortable: false,
      flex: 1,
      minWidth: 70,
    },
    {
      field: 'createdAt',
      headerName: t('list.table.header.created_at'),
      valueGetter: (params: GridValueGetterParams) => format(new Date(params.row.createdAt), 'dd/MM/yyyy - HH:mm'),
      sortable: false,
      flex: 1,
      enableOnMobile: true,
    },
    {
      width: 60,
      type: 'actions',
      sortable: false,
      field: 'actions',
      headerName: t('list.table.header.actions'),
      getActions: (params: GridRowParams) => getActions(params.row),
    },
  ];

  return (
    <Grid item xs={12} paddingX={{ xs: 0 }}>
      <Table
        rows={sales}
        loading={loading}
        columns={columns}
        disableColumnMenu
        rowCount={totalSales}
        paginationMode='server'
        paginationModel={paginationModel}
        onMobileClick={({ row }) => {
          setOpenPreviewDrawer(true);
          setSelectedSaleId(row.id);
        }}
        renderContentAboveHeader={({ row }) => (
          <Typography variant='smallSemiBold'>
            {t('list.table.row.position', { position: row.position })} |
            <Link href={row.id} sx={{ ml: '4px' }}>
              # {row.code}
            </Link>
          </Typography>
        )}
        onPaginationModelChange={(newPaginationModel) => setPaginationModel(newPaginationModel)}
      />

      <Preview
        actions={actions(findSelectedSale(selectedSaleId)!)}
        sale={findSelectedSale(selectedSaleId)}
        open={openPreviewDrawer}
        renderSaleState={renderSaleState}
        renderSaleSource={renderSaleSource}
        onClose={() => {
          setSelectedSaleId(undefined);
          setOpenPreviewDrawer(false);
        }}
        renderAggregatedVouchers={renderAggregatedVouchers}
        getTagNames={getTagNames}
        tagAnchorEl={tagAnchor}
        onTagClick={handleTagClick}
        onTagModalClose={handleTagClose}
      />

      <ModalOnDesktopDrawerOnMobile
        width={643}
        isOpen={openCustomerModal}
        onClose={handleCloseCustomerModal}
        title={t('details.title', { ns: 'customer' })}
      >
        <CustomerDetailWithCloseButton
          onClose={handleCloseCustomerModal}
          customerId={findSelectedSale(selectedSaleId)?.customerId}
        />
      </ModalOnDesktopDrawerOnMobile>
    </Grid>
  );
};

export default SalesTableStructure;
