import { Stack } from '@mui/material';
import { IconCircleFilled } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import { getRecurrenceString } from '@/features/agenda/utils/getRecurrenceString';
import { Scheduling } from '@/services/SellerApi';

type Props = {
  scheduling: Scheduling | null;
};
export const RecurrenceDetails = (props: Props) => {
  const { scheduling } = props;
  const { t } = useTranslation(['agenda'], { keyPrefix: 'modal' });

  if (!scheduling?.recurrence) return null;

  const recurrence = getRecurrenceString(scheduling.recurrence, t, 'recurrence.');

  return (
    <>
      {recurrence.recurrence}
      {recurrence.weekDays ? (
        <>
          {' '}
          <IconCircleFilled width='0.3125rem' height='0.3125rem' /> {recurrence.weekDays}
        </>
      ) : null}
      {recurrence.to ? (
        <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: '0.25rem' }}>
          <IconCircleFilled width='0.3125rem' height='0.3125rem' /> {recurrence.to}
        </Stack>
      ) : null}
    </>
  );
};
