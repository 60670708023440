import { Trans, useTranslation } from 'react-i18next';
import OpsErrorIcon from '@/assets/Icons/ops-error.svg';
import Button from '@/components/Button';
import MobileHeaderLayout from '@/layouts/MobileHeaderLayout';
import ErrorComponent from '@/components/Error/ErrorComponent';

interface ErrorTechnicalProps {
  error?: Error;
  info?: React.ErrorInfo;
  clearError?: () => void;
  hasHeader?: boolean;
}

export const ErrorTechnical = (props: ErrorTechnicalProps) => {
  const { hasHeader = false } = props;

  const { t } = useTranslation(['errors'], {
    keyPrefix: 'technicalError',
  });

  return (
    <MobileHeaderLayout showHeader={!hasHeader}>
      <ErrorComponent.Root>
        {hasHeader && <ErrorComponent.Header />}
        <ErrorComponent.Body
          title={t('title')}
          subTitle={t('sub_title')}
          description={
            <Trans
              i18nKey={t('description')}
              components={{
                anchor: <a href='https://ajuda.planne.com.br/kb'>EMPTY</a>,
              }}
            />
          }
          icon={OpsErrorIcon}
        >
          <Button fullWidth variant='contained' size='small' color='primary' href='https://ajuda.planne.com.br/kb'>
            {t('button.help')}
          </Button>
          <Button fullWidth variant='outlined' size='small' color='primary' href='/'>
            {t('button.home')}
          </Button>
        </ErrorComponent.Body>
      </ErrorComponent.Root>
    </MobileHeaderLayout>
  );
};

export default ErrorTechnical;
