import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

export const Actions = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '1rem',

  borderTop: '1px solid #D2D2D2',
  padding: '1.5rem',

  position: 'sticky',
  bottom: 0,
  background: 'white',

  [theme.breakpoints.down('sm')]: {
    padding: '1rem',
  },
}));

export const Content = styled(Box)(() => ({}));

export const Form = styled(Box)(() => ({
}));
