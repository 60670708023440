import { forwardRef, Ref, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Select, { SelectProps } from '@/components/Form/Select';
import { limitTags } from '@/utils';

export type GenericEntity = {
  id: string;
  name: string;
};

type Value = GenericEntity & {
  label: string;
  value: GenericEntity;
};

export type ItemSelectorProps<T extends GenericEntity> = Omit<SelectProps, 'onChange' | 'options'> & {
  multiple?: boolean;
  onChange: (value: T[] | null) => void;
  items?: T[];
  loading?: boolean;
  getItemLabel: (item: T) => string;
};

export const ItemSelector = forwardRef(
  <T extends GenericEntity>(props: ItemSelectorProps<T>, ref: Ref<HTMLDivElement>) => {
    const { onChange, value, name, label, multiple = false, items, loading, getItemLabel, ...rest } = props;

    const { t } = useTranslation(['coupons']);

    const isOptionEqualToValue = (option: T, value: T | T[]) => {
      if (value === null) return false;
      if (multiple) {
        return (value as T[]).some((item) => item.id === option.id);
      }
      return (value as T).id === option.id;
    };

    const formatOptions = (items: T[]) => {
      return items.map((item) => ({
        ...item,
        label: getItemLabel(item),
        value: item,
      }));
    };

    const options = useMemo(() => {
      if (items) {
        const sortedItems = items.sort((a, b) => getItemLabel(a).localeCompare(getItemLabel(b)));
        return formatOptions(sortedItems);
      }
      return [];
    }, [items]);

    const selectedValue = (value: T | T[]): Value | Value[] | null => {
      if (items && value) {
        if (Array.isArray(value) && multiple) {
          return items
            .filter((item) => value.some((v) => v.id === item.id))
            .map((item) => ({
              ...item,
              label: getItemLabel(item),
              value: item,
            }));
        } else if ((value as T).id) {
          const item = items.find((item) => item.id === (value as T).id);
          if (item) {
            return {
              ...item,
              label: getItemLabel(item),
              value: item,
            };
          }
        }
      }
      return null;
    };

    return (
      <Select
        fullWidth
        id={name}
        loading={loading}
        loadingText={t('loading_text')}
        options={options}
        onChange={(_, value) => onChange(value as T[])}
        value={selectedValue(value as T | T[])}
        label={label}
        disableCloseOnSelect={multiple}
        limitTags={limitTags}
        isOptionEqualToValue={(option, value) => isOptionEqualToValue(option as T, value as T)}
        getOptionLabel={(option) => (option as GenericEntity)?.name}
        getOptionKey={(option) => (option as GenericEntity)?.id}
        placeholder={t('placeholder')}
        slotProps={{
          popper: {
            sx: {
              zIndex: 9999,
            },
          },
        }}
        {...rest}
        ref={ref}
      />
    );
  },
);

export default ItemSelector;
