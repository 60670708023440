import { device } from '@/theme';
import { styled, css } from '@mui/material/styles';
import { Grid } from '@mui/material';

export const Container = styled(Grid)(
  () => css`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 8px;
    width: 100%;
    max-width: 343px;

    ${device.sm} {
      max-width: 368px;
      margin: 0 8px !important;
    }

    ${device.md} {
      padding: 24px;
      gap: 16px;
    }
  `,
);

export const SignupButtonContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
`;
