import { isValid } from 'date-fns';
import { TFunction } from 'i18next';
import { z } from 'zod';

import { App, Product, SellingMode, TariffGroup } from '@/services/SellerApi';
import { convertDaysHoursMinutesToTotalMinutes } from '../../utils/convertDaysHoursMinutesToTotalMinutes';
import intervalTimeOptions from '@/features/agenda/utils/intervalTimeOptions';
import stringHoursToMinutes from '@/features/agenda/utils/stringHoursToMinutes';

const schedulingFormSchema = (
  t: TFunction,
  app: App,
  conflictDates: Date[],
  isResolvingConflicts: boolean,
  appInterval: number,
) => {
  const noRequiredField = z.string().optional();
  const antecedenceFieldValidation = z.coerce
    .number({ message: t('form.validation.invalid_antecedence_hours') })
    .int({ message: t('form.validation.invalid_antecedence_value') })
    .min(0, { message: t('form.validation.invalid_antecedence_hours') });

  return z
    .object({
      beginDate: z.date().refine((date) => isValid(date), {
        message: t('form.validation.invalid_beginDate'),
      }),
      recurrence: z.unknown().optional(),
      durationMinutes: noRequiredField,
      consumableFromTime: noRequiredField,
      consumableToTime: noRequiredField,
      calendarIntervalMinutes: noRequiredField,
      antecedenceDays: antecedenceFieldValidation,
      antecedenceHours: antecedenceFieldValidation,
      antecedenceMinutes: antecedenceFieldValidation,
      antecedenceHoursReferenceTime: noRequiredField,
      tariffGroup: z.unknown(),
      product: z.unknown(),
      overrideConflicts: z
        .boolean()
        .refine((override) => conflictDates.length === 0 || (isResolvingConflicts && override), {
          message: t('form.validation.override_existing_confirmation_required'),
        })
        .optional(),
    })
    .superRefine((data, ctx) => {
      const product = data.product as Product;
      if (!product?.id) {
        ctx.addIssue({
          path: ['product'],
          code: z.ZodIssueCode.custom,
          message: t('form.validation.required_product'),
        });
      }

      if (data.antecedenceHours < 0 && data.antecedenceMinutes < 0 && data.antecedenceDays < 0) {
        ctx.addIssue({
          path: ['antecedenceMinutes'],
          code: z.ZodIssueCode.custom,
          message: t('form.validation.invalid_antecedence_hours'),
        });
      }

      if (data.consumableFromTime && data.consumableToTime && product.sellingMode !== SellingMode.WithDateOnly) {
        const consumableFromTime = parseInt(data.consumableFromTime.replace(':', ''));
        const consumableToTime = parseInt(data.consumableToTime.replace(':', ''));

        if (consumableFromTime >= consumableToTime) {
          ctx.addIssue({
            path: ['consumableFromTime'],
            code: z.ZodIssueCode.custom,
            message: t('form.validation.consumable_time_start_end'),
          });
        }

        const fromTime = stringHoursToMinutes(data.consumableFromTime);
        const toTime = stringHoursToMinutes(data.consumableToTime);

        const fromTimeInInterval = fromTime % appInterval === 0;

        if (!fromTimeInInterval) {
          ctx.addIssue({
            path: ['consumableFromTime'],
            code: z.ZodIssueCode.custom,
            message: t('form.validation.consumable_from_time_interval'),
          });
        }

        const toTimeInInterval = toTime % appInterval === 0;

        if (!toTimeInInterval) {
          ctx.addIssue({
            path: ['consumableToTime'],
            code: z.ZodIssueCode.custom,
            message: t('form.validation.consumable_to_time_interval'),
          });
        }
      }

      if (data.durationMinutes) {
        const duration = stringHoursToMinutes(data.durationMinutes);

        const durationInInterval = duration % appInterval === 0;

        if (!durationInInterval) {
          ctx.addIssue({
            path: ['durationMinutes'],
            code: z.ZodIssueCode.custom,
            message: t('form.reservation.duration_invalid_interval'),
          });
        }
      }

      if (data.calendarIntervalMinutes && product.sellingMode !== SellingMode.WithDateOnly) {
        const calendarInterval = stringHoursToMinutes(data.calendarIntervalMinutes);

        const calendarIntervalInInterval = calendarInterval % appInterval === 0;

        if (!calendarIntervalInInterval) {
          ctx.addIssue({
            path: ['calendarIntervalMinutes'],
            code: z.ZodIssueCode.custom,
            message: t('form.reservation.calendar_interval_invalid_interval'),
          });
        }
      }
    })
    .transform((data) => {
      const product = data.product as Product;
      const tariffGroup = data.tariffGroup as TariffGroup;
      const finalData = { ...data };

      const antecedenceMinutes = convertDaysHoursMinutesToTotalMinutes(
        data.antecedenceDays,
        data.antecedenceHours,
        data.antecedenceMinutes,
      );

      finalData.antecedenceHoursReferenceTime = undefined;

      // AntecedenceHoursReferenceTime is only allow when the product has SellingMode.WithDateOnly
      // On API this is called "antecedenceHoursReferenceMinutes" too
      if (product.sellingMode === SellingMode.WithDateOnly) {
        finalData.antecedenceHoursReferenceTime =
          data.antecedenceHoursReferenceTime ?? getLastReferenceTime(app.minuteSetInterval);
      }

      return {
        ...finalData,
        antecedenceMinutes,
        tariffGroupId: String(tariffGroup?.id),
        productId: String(product?.id),
      };
    });
};

const getLastReferenceTime = (interval: number) => {
  const options = intervalTimeOptions(interval);
  const lastOption = options[options.length - 2];
  return lastOption;
};

export default schedulingFormSchema;
