import { App } from '@/services/SellerApi';
import { Image01 } from '@untitled-ui/icons-react';

interface AppLogoProps {
  app: App | null | undefined;
  className?: string;
}

export const AppLogo = (props: AppLogoProps) => {
  const { app, className } = props;

  if (app?.logoUrl) {
    return <img src={app.logoUrl} width={35} height={35} alt={`${app.name} Logo`} className={className} />;
  }

  return <Image01 className={className} />;
};

export default AppLogo;
