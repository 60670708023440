import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import Select from '@/components/Form/Select';
import useFilter from '@/hooks/useFilter';
import { PartnershipStatus } from '@/services/SellerApi';
import { FilterOption } from '@/contexts/FilterContext';
import IndeterminateCheckboxGroup from '@/components/IndeterminateCheckboxGroup';

type Props = {
  isProvider: boolean;
};

const PartnershipFilter = (props: Props) => {
  const { isProvider } = props;

  const { t } = useTranslation(['partnership']);

  const { selectedFilters, handleChangeFilters, schema } = useFilter();

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='flex-start'
      justifyContent='space-between'
      gap={{ xs: 2, md: 4 }}
    >
      <Box>
        <Typography variant='regularBold'>{t('filters.status.title')}</Typography>

        <IndeterminateCheckboxGroup
          label={t('filters.status.all')}
          options={[
            {
              value: PartnershipStatus.Ongoing,
              label: t('filters.status.ongoing'),
            },
            {
              value: PartnershipStatus.Pending,
              label: t('filters.status.pending'),
            },
            {
              value: PartnershipStatus.Declined,
              label: t('filters.status.declined'),
            },
          ]}
          values={
            (selectedFilters?.currentStatus as string[])?.map(
              (status) => schema!.currentStatus.options!.find((option) => option.value === status)!,
            ) ?? []
          }
          onChange={(values) => {
            handleChangeFilters(
              'currentStatus',
              values.map((status) => status.value),
            );
          }}
        />
      </Box>

      {isProvider ? (
        <Select
          multiple
          fullWidth
          openOnFocus
          filterSelectedOptions
          label={t('filters.program.label')}
          loading={schema!.programIds.loading}
          options={schema!.programIds.options ?? []}
          placeholder={t('filters.program.placeholder')}
          getOptionLabel={(option) => (option as FilterOption).label}
          isOptionEqualToValue={(option, value) => (option as FilterOption).value === value}
          onChange={(_, value) => {
            const newValue = value as FilterOption[];

            handleChangeFilters(
              'programIds',
              newValue.map((p) => p.value),
            );
          }}
          value={
            schema!.programIds.options
              ? (selectedFilters?.programIds as string[])?.map(
                  (productId) => schema!.programIds.options!.find((option) => option.value === productId)!,
                ) ?? []
              : []
          }
        />
      ) : null}
    </Box>
  );
};

export default PartnershipFilter;
