import { Grid, Stack } from '@mui/material';
import { Minus } from '@untitled-ui/icons-react';
import { forwardRef, Ref, useMemo } from 'react';
import { Control, Controller, FormState, RefCallBack } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import SelectInputTime from '@/features/agenda/features/schedulingForm/components/SelectInputTime';
import { SchedulingFields } from '@/features/agenda/features/schedulingForm/types/formTypes';
import intervalTimeOptions from '@/features/agenda/utils/intervalTimeOptions';
import { Product } from '@/services/SellerApi';
import useAccount from '@/hooks/useAccount';

type Props = {
  product: Product;
  control: Control<SchedulingFields>;
  formState: FormState<SchedulingFields>;
};
const TimeRange = forwardRef((props: Props, ref: Ref<HTMLElement>) => {
  const { product, control, formState } = props;
  const { t } = useTranslation(['scheduling-form'], { keyPrefix: 'form' });
  const { selectedAccount } = useAccount();

  const timeOptions = useMemo(() => {
    const productSchedulingInterval = selectedAccount?.app?.minuteSetInterval || 30;
    return intervalTimeOptions(productSchedulingInterval!);
  }, [product]);

  const endTimeOptions = useMemo(() => {
    // Remove the first option to avoid the same time in the start and end time
    return timeOptions.slice(1);
  }, [timeOptions]);

  const { errors } = formState;

  return (
    <Grid item xs={12} md={4}>
      <Stack sx={{ flexDirection: 'row', width: '100%' }}>
        <Controller
          name='consumableFromTime'
          control={control}
          render={({ field: { ...rest } }) => (
            <SelectInputTime
              label={t('time_start')}
              options={timeOptions}
              errors={errors}
              {...rest}
              ref={ref as RefCallBack}
            />
          )}
        />
        <Stack sx={{ justifyContent: 'center', paddingTop: '1.72rem', maxHeight: '4.4375em' }}>
          <Minus />
        </Stack>
        <Controller
          name='consumableToTime'
          control={control}
          render={({ field: { ...rest } }) => (
            <SelectInputTime
              label={t('time_end')}
              options={endTimeOptions}
              errors={errors}
              {...rest}
              ref={ref as RefCallBack}
            />
          )}
        />
      </Stack>
    </Grid>
  );
});

export default TimeRange;
