import { inputBaseClasses, InputLabel, TextField } from '@mui/material';
import { css, styled } from '@mui/material/styles';

export const Wrapper = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    width: 100%;
    gap: 10px;

    .MuiFormHelperText-root {
      margin-left: 0;
      margin-right: 4px;
      margin-top: 4px;
      line-height: 1.06rem;
    }

    .MuiInputBase-root.Mui-error.Mui-focused {
      box-shadow: 0px 0px 0px 4px #fbd2d0;
    }

    input {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: ${theme.palette.colors.gray[500]};

      ::placeholder {
        font-weight: 400;
        font-size: 16px;
        color: ${theme.palette.colors.gray[400]};
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset;
    }

    input:-webkit-autofill {
      -webkit-text-fill-color: ${theme.palette.colors.gray[500]};
    }
  `,
);

export const Label = styled(InputLabel)(
  ({ theme }) => css`
    font-size: 13px;
    font-weight: 600;
    line-height: 1;
    color: ${theme.palette.colors.gray[700]};
  `,
);

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& input': {
    padding: '10px 12px',
  },

  '& .MuiInputBase-root.Mui-disabled': {
    backgroundColor: theme.palette.colors.gray[50],
  },

  [`& .${inputBaseClasses.root}`]: {
    background: theme.palette.common.white,
  },

  marginBottom: '0 !important',
}));
