import { useSnackbar } from 'notistack';
import { NotifiableError } from '@bugsnag/js';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import useAnalytics from '@/hooks/analytics/useAnalytics';
import useGetSaleReceipt from '../useCases/useGetSaleReceipt';
import Bugsnag from '@/services/bugsnag';
import useAccount from '@/hooks/useAccount';
import { AnalyticsEvents } from '@/hooks/analytics/enum/analyticsEvents';

const useLoadSaleReceipt = () => {
  const { request: getSaleReceipt } = useGetSaleReceipt();
  const { t } = useTranslation(['receipt']);
  const { track } = useAnalytics();
  const { enqueueSnackbar } = useSnackbar();
  const { hasPermission } = useAccount();

  const useRequestQuery = (saleId: string) => {
    return useQuery({
      queryKey: ['sale-receipt', saleId],
      queryFn: getSaleReceipt,
      enabled: !!saleId && hasPermission('saleItems', 'GetDetailedSaleItems'),
      staleTime: Infinity,
      throwOnError: (error) => {
        track(AnalyticsEvents.SALE_GET_DETAILED_ITEMS_FAIL, { error });
        enqueueSnackbar(t('snackbars.could_not_get_sale_items'), { variant: 'error' });

        Bugsnag.notify(error as NotifiableError);

        return false;
      },
    });
  };

  return [useRequestQuery];
};

export default useLoadSaleReceipt;
