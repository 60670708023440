import { QueryFunctionContext } from '@tanstack/react-query';

import { useSellerApi } from '@/hooks/useSellerApi';
import { DetailedSaleItem, SalesApiGetDetailedSaleItemsRequest } from '@/services/SellerApi';
import { RequiredError } from '@/services/SellerApi/base';
import removeDuplicates from '@/utils/removeDuplicates';

type Query = [key: string, saleId: string];

const useGetSaleReceipt = () => {
  const { salesApi } = useSellerApi();

  const request = async ({
    queryKey,
    signal,
  }: QueryFunctionContext<Query>): Promise<DetailedSaleItem[] | undefined> => {
    const [_, saleId] = queryKey;

    const limit = 10;
    const payload: SalesApiGetDetailedSaleItemsRequest = {
      limit,
      saleId,
      include: [
        'resources.resource',
        'additionals.additional',
        'reservation',
        'tariffs.tariff.type',
        'product',
        'sale',
      ],
    };

    try {
      const { data, headers } = await salesApi.getDetailedSaleItems(
        {
          ...payload,
          offset: 0,
          limit: 1,
        },
        { signal },
      );

      const totalCount = parseInt(headers['x-pagination-total-count'] ?? '0');
      const totalPages = Math.ceil(totalCount / limit);

      const promises = [];

      for (let page = 0; page <= totalPages - 1; page++) {
        promises.push(
          salesApi.getDetailedSaleItems(
            {
              ...payload,
              limit,
              offset: page * limit,
            },
            { signal },
          ),
        );
      }

      const dataArray = await Promise.all(promises);

      const allSaleReceipt = dataArray.reduce((accumulator: DetailedSaleItem[], response) => {
        return accumulator.concat(response.data);
      }, data);

      return removeDuplicates(allSaleReceipt, 'id');
    } catch (error) {
      return error instanceof RequiredError ? [] : undefined;
    }
  };

  return { request };
};

export default useGetSaleReceipt;
