import countries from '@/data/countries.json';

export type CountryData = (typeof countries)[0] | undefined;

export enum MERCOSUL_COUNTRY_CODE {
  BRAZIL = 'BRA',
  ARGENTINA = 'ARG',
  URUGUAY = 'URY',
  PARAGUAY = 'PRY',
}

export const getNationalityByAlpha3 = (alpha3: string): CountryData =>
  countries.find((country) => country.alpha3 === alpha3);

export const isCountryFromMercosulExcludingBrazil = (alpha3: string) =>
  [MERCOSUL_COUNTRY_CODE.ARGENTINA, MERCOSUL_COUNTRY_CODE.URUGUAY, MERCOSUL_COUNTRY_CODE.PARAGUAY].includes(
    alpha3 as MERCOSUL_COUNTRY_CODE,
  );
