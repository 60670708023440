import { groupBy } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { NotifiableError } from '@bugsnag/js';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { Hidden, Stack, Typography } from '@mui/material';
import { IconMail } from '@tabler/icons-react';
import { ElementType, Fragment, ReactElement, useMemo, useState } from 'react';
import { CheckCircleBroken, MessageChatSquare } from '@untitled-ui/icons-react';

import theme from '@/theme';
import Chip from '@/components/Chip';
import Bugsnag from '@/services/bugsnag';
import Loading from '@/components/Loading';
import EmptyPage from '@/components/EmptyPage';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import { useSellerApi } from '@/hooks/useSellerApi';
import CouponEmptyPageIcon from '@/assets/Icons/coupon-empty-page-icon.svg';
import { Voucher, VouchersApiSendVoucherRequest } from '@/services/SellerApi';
import SaleVoucherMobileView from '@/features/sales/components/Vouchers/MobileView';
import SaleVoucherDesktopView from '@/features/sales/components/Vouchers/DesktopView';
import ModalOnDesktopDrawerOnMobile from '@/components/Modal/ModalOnDesktopDrawerOnMobile';
import ConsumeVoucherForm from '@/features/sales/components/Vouchers/ConsumeVoucherForm';
import useAccount from '@/hooks/useAccount';
import { useSaleDetails } from '@/features/sales/hook/useSaleDetails';

export type Actions = (voucher: Voucher) => {
  label: string;
  href?: string;
  target?: string;
  icon: JSX.Element;
  disabled: boolean;
  onClick?: () => void;
  component?: ElementType<ReactElement> | undefined;
}[];

export const SaleVouchers = () => {
  const { saleVouchersData, sale, refetchSale } = useSaleDetails();
  const { aggregatedRefundRequests, isExternal, id: saleId } = sale;
  const { data: vouchers = [], isLoading: loading, refetch } = saleVouchersData;

  const { track } = useAnalytics();
  const { vouchersApi } = useSellerApi();
  const { hasPermission } = useAccount();
  const { t } = useTranslation(['vouchers']);

  const [sendingVoucher, setSendingVoucher] = useState(false);

  const [selectedVoucher, setSelectedVoucher] = useState<Voucher>();
  const [isExceptionalConsumption, setIsExceptionalConsumption] = useState(false);

  const sendVoucher = async (voucherId: string, channel: 'email' | 'sms') => {
    setSendingVoucher(true);

    const payload: VouchersApiSendVoucherRequest = {
      voucherId,
      sendVoucherParams: { email: channel === 'email', sms: channel === 'sms' },
    };

    try {
      await vouchersApi.sendVoucher(payload);

      enqueueSnackbar(t(`snackbars.voucher_sent_by_${channel}`), { variant: 'success' });

      track('vouchers/sendVoucherSuccess', { payload });
    } catch (error) {
      enqueueSnackbar(t('snackbars.could_not_send_voucher'), { variant: 'error' });

      track('vouchers/sendVoucherFail', { payload, error });

      Bugsnag.notify(error as NotifiableError);
    } finally {
      setSendingVoucher(false);
    }
  };

  const closeModal = async () => {
    setSelectedVoucher(undefined);
    setIsExceptionalConsumption(false);
    await refetch();
  };

  const actions = (voucher: Voucher) => [
    voucher.exceptionallyConsumedAt && !voucher.consumedAt
      ? {
          icon: <CheckCircleBroken />,
          disabled: voucher.consumedAt || !hasPermission('vouchers', 'ConsumeVoucher'),
          onClick: () => setSelectedVoucher(voucher),
          label: t('table.actions.add_secret_code'),
        }
      : {
          icon: <CheckCircleBroken />,
          label: t('table.actions.exceptional_consumption'),
          onClick: () => {
            setSelectedVoucher(voucher);
            setIsExceptionalConsumption(true);
          },
          disabled:
            !!voucher.consumedAt ||
            !!voucher.exceptionallyConsumedAt ||
            !hasPermission('vouchers', 'ConsumeVoucherExceptionally'),
        },
    {
      disabled: sendingVoucher || !hasPermission('vouchers', 'SendVoucher') || isExternal,
      icon: <MessageChatSquare />,
      onClick: () => sendVoucher(voucher.id, 'sms'),
      label: t('table.actions.send_voucher_via_sms'),
    },
    {
      icon: <IconMail />,
      disabled: sendingVoucher || !hasPermission('vouchers', 'SendVoucher') || isExternal,
      onClick: () => sendVoucher(voucher.id, 'email'),
      label: t('table.actions.send_voucher_via_email'),
    },
  ];

  const groupedVouchers = useMemo(() => groupBy(vouchers, (voucher) => voucher.saleItemId), [vouchers]);

  return (
    <Fragment>
      {!loading && vouchers.length ? (
        <Stack px={{ xs: 2, md: 0 }}>
          <Stack direction='row' gap={1} alignItems='center' mb={{ xs: 2, md: 3 }} mt={1}>
            <Typography variant='h6'>{t('badge_text')}</Typography>

            <Chip
              size='small'
              variant='outlined'
              label={vouchers.length}
              color={{
                font: theme.palette.colors.blue[500],
                background: theme.palette.colors.blue[100],
              }}
            />
          </Stack>

          <Stack direction='column' gap={2}>
            {Object.entries(groupedVouchers).map(([key, vouchers]) => (
              <Fragment key={key}>
                <Hidden mdDown>
                  <SaleVoucherDesktopView
                    key={key}
                    loading={loading}
                    vouchers={vouchers}
                    actions={actions as Actions}
                    consumeVoucher={setSelectedVoucher}
                  />
                </Hidden>

                <Hidden mdUp>
                  <SaleVoucherMobileView
                    vouchers={vouchers}
                    actions={actions as Actions}
                    consumeVoucher={setSelectedVoucher}
                  />
                </Hidden>
              </Fragment>
            ))}
          </Stack>

          <ModalOnDesktopDrawerOnMobile
            width={503}
            onClose={closeModal}
            isOpen={!!selectedVoucher}
            title={t('consume_modal.title', { code: selectedVoucher?.code })}
          >
            {selectedVoucher ? (
              <ConsumeVoucherForm
                onCancel={closeModal}
                onSuccess={closeModal}
                voucher={selectedVoucher}
                sale={{ aggregatedRefundRequests }}
                exceptional={isExceptionalConsumption}
              />
            ) : null}
          </ModalOnDesktopDrawerOnMobile>
        </Stack>
      ) : null}

      {loading ? (
        <Stack mt={4}>
          <Loading />
        </Stack>
      ) : null}

      {!loading && !vouchers.length ? (
        <Stack mt={4}>
          <EmptyPage
            showCicleBelowIcon={false}
            Icon={<img src={CouponEmptyPageIcon} alt='Empty Icon' />}
            description={t('empty_page.description')}
          />
        </Stack>
      ) : null}

      <Outlet context={{ ...sale, saleId, onSuccess: refetchSale }} />
    </Fragment>
  );
};

export default SaleVouchers;
