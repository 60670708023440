import { Customer, Voucher, VoucherAdditional, VoucherResource, VoucherTariff } from '@/services/SellerApi';
import { getWhatsappUrl } from '@/utils';
import i18n from '@/services/i18n';

export const getTariffsQuantityLabels = (tariffs: VoucherTariff[]) => {
  return tariffs.map((t) => {
    return `${t.tariff!.type!.name}: ${t.quantity}`;
  });
};

export const sendVoucherViaWhatsapp = (voucher: Voucher, customer: Customer) => {
  return getWhatsappUrl(
    customer!.phone!,
    i18n.t(`${customer!.firstName} - senha: ${voucher.secret}, código do voucher: ${voucher.code}`, { ns: 'vouchers' }),
  );
};

export const getAdditionalLabels = (additionals: VoucherAdditional[]) => {
  return additionals?.map((additional) => {
    if (additional.saleAdditional?.data.inputLabel && additional.saleAdditional?.data.inputValue) {
      return `${additional.additional?.title} (${additional.saleAdditional?.data.inputLabel}: ${additional.saleAdditional?.data.inputValue}) (${additional.quantity})`;
    }

    return `${additional.additional?.title} (${additional.quantity})`;
  });
};

export const getResourceLabels = (resources: VoucherResource[]) => {
  return resources?.map((r) => {
    return `${r.saleResource?.resource?.name ?? ''}${
      r.saleResource?.resource?.code ? ' (' + r.saleResource?.resource?.code + ')' : ''
    }`;
  });
};

const findVouchersPredecessors = (current: Voucher, remainings: Voucher[]): Voucher[] => {
  if (remainings.length === 0) return [];

  const currentPredecessor = remainings.find((voucher) => voucher.predecessorId === current.id);

  if (!currentPredecessor) {
    return [current];
  }

  const remainingsWithoutCurrent = remainings.filter((voucher) => voucher.id !== current.id);
  return [...findVouchersPredecessors(currentPredecessor, remainingsWithoutCurrent), current];
};

export const sortVouchersByPredecessorId = (vouchers: Voucher[]) => {
  const voucherWithoutPredecessor = vouchers.filter((voucher) => !voucher.predecessorId);
  const voucherWithPredecessor = vouchers.filter((voucher) => voucher.predecessorId);

  if (voucherWithPredecessor.length) {
    const sortedVouchers = [];

    for (const currentVoucher of voucherWithoutPredecessor) {
      sortedVouchers.push(
        ...findVouchersPredecessors(currentVoucher, voucherWithPredecessor).sort((a, b) => {
          if (a.revokedAt && !b.revokedAt) {
            return 1;
          } else if (!a.revokedAt && b.revokedAt) {
            return -1;
          } else {
            return 0;
          }
        }),
      );
    }

    return sortedVouchers;
  }

  return voucherWithoutPredecessor;
};
