/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Planne Seller API
 * OpenAPI spec version: 0.1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  Availability,
  CreateSchedulingParams,
  DeleteSchedulingParams,
  DetailedScheduling,
  GetAppAggregatedSchedulingsParams,
  GetAppSchedulingsParams,
  GetArrangedProductDetailedSchedulingsParams,
  GetProductNextSchedulingParams,
  GetReservationRescheduleSchedulingsParams,
  GetSchedulingParams,
  GetSchedulingRelatedAvailabilitiesParams,
  NotFoundResponseResponse,
  PublicScheduling,
  RescheduleScheduling,
  Scheduling,
  SchedulingDatePreview,
  UnprocessableResponseResponse,
  UpdateSchedulingParams
} from '../../model'
import { customInstance } from '../../mutator/custom-instance';
import type { ErrorType, BodyType } from '../../mutator/custom-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const getProductNextScheduling = (
    productId: string,
    params?: GetProductNextSchedulingParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<Scheduling>(
      {url: `/products/${productId}/nextScheduling`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetProductNextSchedulingQueryKey = (productId: string,
    params?: GetProductNextSchedulingParams,) => {
    return [`/products/${productId}/nextScheduling`, ...(params ? [params]: [])] as const;
    }

    
export const getGetProductNextSchedulingQueryOptions = <TData = Awaited<ReturnType<typeof getProductNextScheduling>>, TError = ErrorType<NotFoundResponseResponse>>(productId: string,
    params?: GetProductNextSchedulingParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProductNextScheduling>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProductNextSchedulingQueryKey(productId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductNextScheduling>>> = ({ signal }) => getProductNextScheduling(productId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProductNextScheduling>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProductNextSchedulingQueryResult = NonNullable<Awaited<ReturnType<typeof getProductNextScheduling>>>
export type GetProductNextSchedulingQueryError = ErrorType<NotFoundResponseResponse>



export function useGetProductNextScheduling<TData = Awaited<ReturnType<typeof getProductNextScheduling>>, TError = ErrorType<NotFoundResponseResponse>>(
 productId: string,
    params?: GetProductNextSchedulingParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProductNextScheduling>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetProductNextSchedulingQueryOptions(productId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getArrangedProductDetailedSchedulings = (
    appId: string,
    arrangedProductId: string,
    params: GetArrangedProductDetailedSchedulingsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<DetailedScheduling[]>(
      {url: `/apps/${appId}/arrangedProducts/${arrangedProductId}/detailedSchedulings`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetArrangedProductDetailedSchedulingsQueryKey = (appId: string,
    arrangedProductId: string,
    params: GetArrangedProductDetailedSchedulingsParams,) => {
    return [`/apps/${appId}/arrangedProducts/${arrangedProductId}/detailedSchedulings`, ...(params ? [params]: [])] as const;
    }

    
export const getGetArrangedProductDetailedSchedulingsQueryOptions = <TData = Awaited<ReturnType<typeof getArrangedProductDetailedSchedulings>>, TError = ErrorType<NotFoundResponseResponse>>(appId: string,
    arrangedProductId: string,
    params: GetArrangedProductDetailedSchedulingsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getArrangedProductDetailedSchedulings>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetArrangedProductDetailedSchedulingsQueryKey(appId,arrangedProductId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getArrangedProductDetailedSchedulings>>> = ({ signal }) => getArrangedProductDetailedSchedulings(appId,arrangedProductId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(appId && arrangedProductId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getArrangedProductDetailedSchedulings>>, TError, TData> & { queryKey: QueryKey }
}

export type GetArrangedProductDetailedSchedulingsQueryResult = NonNullable<Awaited<ReturnType<typeof getArrangedProductDetailedSchedulings>>>
export type GetArrangedProductDetailedSchedulingsQueryError = ErrorType<NotFoundResponseResponse>



export function useGetArrangedProductDetailedSchedulings<TData = Awaited<ReturnType<typeof getArrangedProductDetailedSchedulings>>, TError = ErrorType<NotFoundResponseResponse>>(
 appId: string,
    arrangedProductId: string,
    params: GetArrangedProductDetailedSchedulingsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getArrangedProductDetailedSchedulings>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetArrangedProductDetailedSchedulingsQueryOptions(appId,arrangedProductId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppSellableProductNextScheduling = (
    appId: string,
    productId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<PublicScheduling>(
      {url: `/apps/${appId}/sellableProducts/${productId}/nextScheduling`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAppSellableProductNextSchedulingQueryKey = (appId: string,
    productId: string,) => {
    return [`/apps/${appId}/sellableProducts/${productId}/nextScheduling`] as const;
    }

    
export const getGetAppSellableProductNextSchedulingQueryOptions = <TData = Awaited<ReturnType<typeof getAppSellableProductNextScheduling>>, TError = ErrorType<NotFoundResponseResponse | UnprocessableResponseResponse>>(appId: string,
    productId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAppSellableProductNextScheduling>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAppSellableProductNextSchedulingQueryKey(appId,productId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAppSellableProductNextScheduling>>> = ({ signal }) => getAppSellableProductNextScheduling(appId,productId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(appId && productId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAppSellableProductNextScheduling>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAppSellableProductNextSchedulingQueryResult = NonNullable<Awaited<ReturnType<typeof getAppSellableProductNextScheduling>>>
export type GetAppSellableProductNextSchedulingQueryError = ErrorType<NotFoundResponseResponse | UnprocessableResponseResponse>



export function useGetAppSellableProductNextScheduling<TData = Awaited<ReturnType<typeof getAppSellableProductNextScheduling>>, TError = ErrorType<NotFoundResponseResponse | UnprocessableResponseResponse>>(
 appId: string,
    productId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAppSellableProductNextScheduling>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetAppSellableProductNextSchedulingQueryOptions(appId,productId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getReservationRescheduleSchedulings = (
    reservationId: string,
    params: GetReservationRescheduleSchedulingsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<RescheduleScheduling[]>(
      {url: `/reservations/${reservationId}/rescheduleSchedulings`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetReservationRescheduleSchedulingsQueryKey = (reservationId: string,
    params: GetReservationRescheduleSchedulingsParams,) => {
    return [`/reservations/${reservationId}/rescheduleSchedulings`, ...(params ? [params]: [])] as const;
    }

    
export const getGetReservationRescheduleSchedulingsQueryOptions = <TData = Awaited<ReturnType<typeof getReservationRescheduleSchedulings>>, TError = ErrorType<NotFoundResponseResponse | UnprocessableResponseResponse>>(reservationId: string,
    params: GetReservationRescheduleSchedulingsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getReservationRescheduleSchedulings>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetReservationRescheduleSchedulingsQueryKey(reservationId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getReservationRescheduleSchedulings>>> = ({ signal }) => getReservationRescheduleSchedulings(reservationId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(reservationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getReservationRescheduleSchedulings>>, TError, TData> & { queryKey: QueryKey }
}

export type GetReservationRescheduleSchedulingsQueryResult = NonNullable<Awaited<ReturnType<typeof getReservationRescheduleSchedulings>>>
export type GetReservationRescheduleSchedulingsQueryError = ErrorType<NotFoundResponseResponse | UnprocessableResponseResponse>



export function useGetReservationRescheduleSchedulings<TData = Awaited<ReturnType<typeof getReservationRescheduleSchedulings>>, TError = ErrorType<NotFoundResponseResponse | UnprocessableResponseResponse>>(
 reservationId: string,
    params: GetReservationRescheduleSchedulingsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getReservationRescheduleSchedulings>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetReservationRescheduleSchedulingsQueryOptions(reservationId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppAggregatedSchedulings = (
    appId: string,
    params: GetAppAggregatedSchedulingsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<SchedulingDatePreview[]>(
      {url: `/apps/${appId}/aggregatedSchedulings`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetAppAggregatedSchedulingsQueryKey = (appId: string,
    params: GetAppAggregatedSchedulingsParams,) => {
    return [`/apps/${appId}/aggregatedSchedulings`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAppAggregatedSchedulingsQueryOptions = <TData = Awaited<ReturnType<typeof getAppAggregatedSchedulings>>, TError = ErrorType<NotFoundResponseResponse>>(appId: string,
    params: GetAppAggregatedSchedulingsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAppAggregatedSchedulings>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAppAggregatedSchedulingsQueryKey(appId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAppAggregatedSchedulings>>> = ({ signal }) => getAppAggregatedSchedulings(appId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(appId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAppAggregatedSchedulings>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAppAggregatedSchedulingsQueryResult = NonNullable<Awaited<ReturnType<typeof getAppAggregatedSchedulings>>>
export type GetAppAggregatedSchedulingsQueryError = ErrorType<NotFoundResponseResponse>



export function useGetAppAggregatedSchedulings<TData = Awaited<ReturnType<typeof getAppAggregatedSchedulings>>, TError = ErrorType<NotFoundResponseResponse>>(
 appId: string,
    params: GetAppAggregatedSchedulingsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAppAggregatedSchedulings>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetAppAggregatedSchedulingsQueryOptions(appId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppSchedulings = (
    appId: string,
    params: GetAppSchedulingsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<Scheduling[]>(
      {url: `/apps/${appId}/schedulings`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetAppSchedulingsQueryKey = (appId: string,
    params: GetAppSchedulingsParams,) => {
    return [`/apps/${appId}/schedulings`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAppSchedulingsQueryOptions = <TData = Awaited<ReturnType<typeof getAppSchedulings>>, TError = ErrorType<NotFoundResponseResponse>>(appId: string,
    params: GetAppSchedulingsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAppSchedulings>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAppSchedulingsQueryKey(appId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAppSchedulings>>> = ({ signal }) => getAppSchedulings(appId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(appId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAppSchedulings>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAppSchedulingsQueryResult = NonNullable<Awaited<ReturnType<typeof getAppSchedulings>>>
export type GetAppSchedulingsQueryError = ErrorType<NotFoundResponseResponse>



export function useGetAppSchedulings<TData = Awaited<ReturnType<typeof getAppSchedulings>>, TError = ErrorType<NotFoundResponseResponse>>(
 appId: string,
    params: GetAppSchedulingsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAppSchedulings>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetAppSchedulingsQueryOptions(appId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getScheduling = (
    schedulingId: string,
    params?: GetSchedulingParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<Scheduling>(
      {url: `/schedulings/${schedulingId}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetSchedulingQueryKey = (schedulingId: string,
    params?: GetSchedulingParams,) => {
    return [`/schedulings/${schedulingId}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSchedulingQueryOptions = <TData = Awaited<ReturnType<typeof getScheduling>>, TError = ErrorType<NotFoundResponseResponse>>(schedulingId: string,
    params?: GetSchedulingParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getScheduling>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSchedulingQueryKey(schedulingId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getScheduling>>> = ({ signal }) => getScheduling(schedulingId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(schedulingId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getScheduling>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSchedulingQueryResult = NonNullable<Awaited<ReturnType<typeof getScheduling>>>
export type GetSchedulingQueryError = ErrorType<NotFoundResponseResponse>



export function useGetScheduling<TData = Awaited<ReturnType<typeof getScheduling>>, TError = ErrorType<NotFoundResponseResponse>>(
 schedulingId: string,
    params?: GetSchedulingParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getScheduling>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetSchedulingQueryOptions(schedulingId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const updateScheduling = (
    schedulingId: string,
    updateSchedulingParams: BodyType<UpdateSchedulingParams>,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<Scheduling>(
      {url: `/schedulings/${schedulingId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateSchedulingParams
    },
      options);
    }
  


export const getUpdateSchedulingMutationOptions = <TError = ErrorType<NotFoundResponseResponse | UnprocessableResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateScheduling>>, TError,{schedulingId: string;data: BodyType<UpdateSchedulingParams>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateScheduling>>, TError,{schedulingId: string;data: BodyType<UpdateSchedulingParams>}, TContext> => {
    
const mutationKey = ['updateScheduling'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateScheduling>>, {schedulingId: string;data: BodyType<UpdateSchedulingParams>}> = (props) => {
          const {schedulingId,data} = props ?? {};

          return  updateScheduling(schedulingId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateSchedulingMutationResult = NonNullable<Awaited<ReturnType<typeof updateScheduling>>>
    export type UpdateSchedulingMutationBody = BodyType<UpdateSchedulingParams>
    export type UpdateSchedulingMutationError = ErrorType<NotFoundResponseResponse | UnprocessableResponseResponse>

    export const useUpdateScheduling = <TError = ErrorType<NotFoundResponseResponse | UnprocessableResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateScheduling>>, TError,{schedulingId: string;data: BodyType<UpdateSchedulingParams>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateScheduling>>,
        TError,
        {schedulingId: string;data: BodyType<UpdateSchedulingParams>},
        TContext
      > => {

      const mutationOptions = getUpdateSchedulingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const deleteScheduling = (
    schedulingId: string,
    deleteSchedulingParams: BodyType<DeleteSchedulingParams>,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/schedulings/${schedulingId}`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: deleteSchedulingParams
    },
      options);
    }
  


export const getDeleteSchedulingMutationOptions = <TError = ErrorType<NotFoundResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteScheduling>>, TError,{schedulingId: string;data: BodyType<DeleteSchedulingParams>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteScheduling>>, TError,{schedulingId: string;data: BodyType<DeleteSchedulingParams>}, TContext> => {
    
const mutationKey = ['deleteScheduling'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteScheduling>>, {schedulingId: string;data: BodyType<DeleteSchedulingParams>}> = (props) => {
          const {schedulingId,data} = props ?? {};

          return  deleteScheduling(schedulingId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteSchedulingMutationResult = NonNullable<Awaited<ReturnType<typeof deleteScheduling>>>
    export type DeleteSchedulingMutationBody = BodyType<DeleteSchedulingParams>
    export type DeleteSchedulingMutationError = ErrorType<NotFoundResponseResponse>

    export const useDeleteScheduling = <TError = ErrorType<NotFoundResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteScheduling>>, TError,{schedulingId: string;data: BodyType<DeleteSchedulingParams>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteScheduling>>,
        TError,
        {schedulingId: string;data: BodyType<DeleteSchedulingParams>},
        TContext
      > => {

      const mutationOptions = getDeleteSchedulingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getSchedulingRelatedAvailabilities = (
    schedulingId: string,
    params?: GetSchedulingRelatedAvailabilitiesParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<Availability[]>(
      {url: `/schedulings/${schedulingId}/relatedAvailabilities`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetSchedulingRelatedAvailabilitiesQueryKey = (schedulingId: string,
    params?: GetSchedulingRelatedAvailabilitiesParams,) => {
    return [`/schedulings/${schedulingId}/relatedAvailabilities`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSchedulingRelatedAvailabilitiesQueryOptions = <TData = Awaited<ReturnType<typeof getSchedulingRelatedAvailabilities>>, TError = ErrorType<NotFoundResponseResponse>>(schedulingId: string,
    params?: GetSchedulingRelatedAvailabilitiesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSchedulingRelatedAvailabilities>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSchedulingRelatedAvailabilitiesQueryKey(schedulingId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSchedulingRelatedAvailabilities>>> = ({ signal }) => getSchedulingRelatedAvailabilities(schedulingId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(schedulingId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSchedulingRelatedAvailabilities>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSchedulingRelatedAvailabilitiesQueryResult = NonNullable<Awaited<ReturnType<typeof getSchedulingRelatedAvailabilities>>>
export type GetSchedulingRelatedAvailabilitiesQueryError = ErrorType<NotFoundResponseResponse>



export function useGetSchedulingRelatedAvailabilities<TData = Awaited<ReturnType<typeof getSchedulingRelatedAvailabilities>>, TError = ErrorType<NotFoundResponseResponse>>(
 schedulingId: string,
    params?: GetSchedulingRelatedAvailabilitiesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSchedulingRelatedAvailabilities>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetSchedulingRelatedAvailabilitiesQueryOptions(schedulingId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const createProductScheduling = (
    productId: string,
    createSchedulingParams: BodyType<CreateSchedulingParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<Scheduling>(
      {url: `/products/${productId}/schedulings`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createSchedulingParams, signal
    },
      options);
    }
  


export const getCreateProductSchedulingMutationOptions = <TError = ErrorType<UnprocessableResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProductScheduling>>, TError,{productId: string;data: BodyType<CreateSchedulingParams>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createProductScheduling>>, TError,{productId: string;data: BodyType<CreateSchedulingParams>}, TContext> => {
    
const mutationKey = ['createProductScheduling'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createProductScheduling>>, {productId: string;data: BodyType<CreateSchedulingParams>}> = (props) => {
          const {productId,data} = props ?? {};

          return  createProductScheduling(productId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateProductSchedulingMutationResult = NonNullable<Awaited<ReturnType<typeof createProductScheduling>>>
    export type CreateProductSchedulingMutationBody = BodyType<CreateSchedulingParams>
    export type CreateProductSchedulingMutationError = ErrorType<UnprocessableResponseResponse>

    export const useCreateProductScheduling = <TError = ErrorType<UnprocessableResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProductScheduling>>, TError,{productId: string;data: BodyType<CreateSchedulingParams>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof createProductScheduling>>,
        TError,
        {productId: string;data: BodyType<CreateSchedulingParams>},
        TContext
      > => {

      const mutationOptions = getCreateProductSchedulingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    