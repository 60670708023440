import Chip from '@/components/Chip';
import Tooltip from '@/components/Tooltip';
import getRightSchedulingTariffGroupFromApi from '@/features/agenda/utils/getRightSchedulingTariffGroupFromApi';
import { Scheduling } from '@/services/SellerApi';
import theme from '@/theme';
import { formatCents } from '@/utils';
import { Stack, Typography } from '@mui/material';
import { IconAlertTriangle, IconCircleCheck, IconInfoCircle } from '@tabler/icons-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  scheduling: Scheduling;
};

const TariffGroupDetails = (props: Props) => {
  const { scheduling } = props;
  const { t } = useTranslation(['agenda'], { keyPrefix: 'modal' });

  const isSchedulingTariffGroupUndefined = useMemo(() => !scheduling.tariffGroup, [scheduling]);
  const tariffGroup = useMemo(() => getRightSchedulingTariffGroupFromApi(scheduling), [scheduling]);
  const isTariffGroupArchived = useMemo(() => tariffGroup?.deletedAt, [tariffGroup]);
  const prices = useMemo(() => tariffGroup?.priceInfo, [scheduling]);
  const hasPrices = useMemo(() => prices?.minPriceCents && prices?.maxPriceCents, [prices]);

  return (
    <Stack sx={{ alignItems: 'flex-end' }}>
      {isSchedulingTariffGroupUndefined ? (
        <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: theme.spacing(1) }}>
          <Chip
            label={
              <Stack sx={{ flexDirection: 'row', gap: theme.spacing(1), alignItems: 'center' }}>
                <IconCircleCheck width='0.875rem' height='0.875rem' />
                {t('scheduling.principal_tariff_group.label')}
              </Stack>
            }
            size='small'
            sx={{ padding: '0.5rem', marginLeft: 'auto' }}
          />
          <Tooltip title={t('scheduling.principal_tariff_group.tooltip')} sx={{ cursor: 'pointer' }} width='auto'>
            <IconInfoCircle width='1.25rem' height='1.25rem' />
          </Tooltip>
        </Stack>
      ) : null}
      <Typography
        color={isTariffGroupArchived ? theme.palette.colors.warning['700'] : undefined}
        sx={{ display: 'flex', justifyItems: 'center', alignItems: 'center', gap: theme.spacing(1) }}
      >
        {tariffGroup?.name}
        {isTariffGroupArchived ? (
          <Tooltip title={t('scheduling.tariff_group_archived.title')} sx={{ cursor: 'pointer' }} width='auto'>
            <IconAlertTriangle size='1rem' color={theme.palette.colors.warning['700']} />
          </Tooltip>
        ) : null}
      </Typography>
      <Typography variant='smallRegular'>
        {hasPrices
          ? `${formatCents(prices?.minPriceCents || 0)} - ${formatCents(prices?.maxPriceCents || 0)}`
          : `${formatCents(0)}`}
      </Typography>
    </Stack>
  );
};

export default TariffGroupDetails;
