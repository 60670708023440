import { styled } from '@mui/material';

interface MainProps {
  open?: boolean;
  drawerWidth: number;
  isDrawerExpanded: boolean;
}

const unsafeProps: PropertyKey[] = ['open', 'drawerWidth', 'isDrawerExpanded'];

export const Main = styled('main', {
  shouldForwardProp: (prop) => !unsafeProps.includes(prop),
})<MainProps>((props) => {
  const { theme, open, drawerWidth, isDrawerExpanded = false } = props;

  return {
    zIndex: theme.zIndex.drawer + 1,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: '100vh',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: isDrawerExpanded ? drawerWidth : '4.2rem',

    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,

      [theme.breakpoints.up('sm')]: {
        marginLeft: drawerWidth,
      },
    }),

    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  };
});

export const MainHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  height: 76,
  justifyContent: 'flex-end',
}));
