import { Box, Typography } from '@mui/material';

import theme from '@/theme';
import { formatCents } from '@/utils';
import Counter from '@/components/Counter';
import { Tariff as TariffInterface } from '@/services/SellerApi/api';
import { DependentTariffError, Tariff, TariffContainer, TariffDetails, TariffInformation } from './styles';
import { useTranslation } from 'react-i18next';

interface TariffProps extends TariffInterface {
  remaingQuantityToSelect: number;
  finalPriceCents: number;
  priceBasedOnThePartnershipCommission: number;
}

export interface SelectedTariffsType extends TariffProps {
  selectedQuantity: number;
}

interface Props {
  tariffs: TariffProps[];
  selectedTariffs: SelectedTariffsType[];
  onChangeSelectedTariffs: (tariffs: SelectedTariffsType[]) => void;
}

export const ReservationTariffSelector = (props: Props) => {
  const { tariffs, selectedTariffs, onChangeSelectedTariffs } = props;

  const { t } = useTranslation(['reservationTariffSelector']);

  const getTariffSelectedQuantity = (tariffId: string, tariffTypeId: string) => {
    if (!selectedTariffs?.length) {
      return 0;
    }

    const matchingTariff = selectedTariffs.find((t) => t.id === tariffId && t.typeId === tariffTypeId);

    return matchingTariff?.selectedQuantity ?? 0;
  };

  const updateTariffSelectedQuantity = (tariffId: string, typeId: string, quantity: number) => {
    if (quantity === 0) {
      onChangeSelectedTariffs(selectedTariffs?.filter((t) => t.id !== tariffId && t.typeId !== typeId));
      return;
    }

    const matchingTariff = selectedTariffs?.find((t) => t.id === tariffId && t.typeId === typeId);

    if (!matchingTariff) {
      const targetTariff = tariffs.find((t) => t.id === tariffId && t.typeId === typeId);

      if (!targetTariff) {
        return;
      }

      onChangeSelectedTariffs([
        ...selectedTariffs,
        {
          ...targetTariff,
          selectedQuantity: quantity,
        },
      ]);

      return;
    }

    const newSelectedTariffs = selectedTariffs?.map((t) => {
      if (t.typeId === typeId && t.id === tariffId) {
        return {
          ...t,
          selectedQuantity: quantity,
        };
      }
      return t;
    });

    onChangeSelectedTariffs(newSelectedTariffs);
  };

  const checkDependenceError = (tariffTypeId: string) => {
    if (!selectedTariffs || !Array.isArray(selectedTariffs) || selectedTariffs.length === 0) {
      return false;
    }

    const hasSelectedNonDependentTariff = selectedTariffs.some((tariff) => {
      return tariff.selectedQuantity > 0 && !tariff.isDependent;
    });

    if (!hasSelectedNonDependentTariff) {
      const tariffType = selectedTariffs.find((tariff) => tariff.typeId === tariffTypeId);

      return tariffType && tariffType.selectedQuantity > 0 && tariffType.isDependent;
    }

    return false;
  };

  return (
    <TariffContainer>
      {tariffs.map((tariff) => {
        const hasDependenceError = checkDependenceError(tariff.type!.id);
        const tariffQuantity = getTariffSelectedQuantity(tariff.id, tariff.type!.id);

        return (
          <Tariff key={tariff.id}>
            <TariffDetails>
              <Box>
                <Typography component='h3' variant='regularBold' color={theme.palette.colors.gray[800]}>
                  {tariff.type?.name}

                  {tariff.isDependent ? (
                    <Typography component='span' ml={0.5} variant='smallRegular' fontStyle='italic'>
                      {t('dependent_tariff')}
                    </Typography>
                  ) : null}
                </Typography>

                <Box display='flex' flexDirection='column'>
                  <Typography variant='regularRegular' color={theme.palette.colors.gray[800]}>
                    {t('price_prefix', { price: formatCents(tariff.priceBasedOnThePartnershipCommission) })}
                  </Typography>
                </Box>
              </Box>

              <Counter
                value={tariffQuantity}
                minButtonDisabled={tariffQuantity === 0}
                maxButtonDisabled={tariff.remaingQuantityToSelect === 0}
                onChange={(quantity) => updateTariffSelectedQuantity(tariff.id, tariff.type!.id, quantity)}
              />
            </TariffDetails>

            {tariff.description || hasDependenceError ? (
              <TariffInformation>
                {tariff.description ? (
                  <Typography variant='extraSmallRegular' color={theme.palette.colors.gray[800]}>
                    {tariff.description}
                  </Typography>
                ) : null}

                {hasDependenceError ? (
                  <DependentTariffError variant='extraSmallRegular'>
                    {t('dependent_tariff_selection_error')}
                  </DependentTariffError>
                ) : null}
              </TariffInformation>
            ) : null}
          </Tariff>
        );
      })}
    </TariffContainer>
  );
};

export default ReservationTariffSelector;
