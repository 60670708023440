import { styled, Tab, tabClasses, Tabs } from '@mui/material';

export const StyledTabs = styled(Tabs)(() => ({
  '& .MuiTabScrollButton-root': {
    width: 24,
  },

  '& .MuiTabs-indicator': {
    height: '2px',
  },

  '& .MuiTabs-scroller': {
    overflow: 'hidden',
  },
  [`& .${tabClasses.root}`]: {
    minHeight: 'auto',
  },
}));

export const StyledTab = styled(Tab)(() => ({
  textTransform: 'unset',
  fontWeight: 400,
}));
