import {
  TableCell,
  styled,
  TableContainer as BaseTableContainer,
  TableFooter as BaseTableFooter,
  Stack,
} from '@mui/material';
import { ElementType } from 'react';

interface Props {
  component: ElementType;
}

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddintBottom: theme.spacing(0),
  paddingTop: theme.spacing(1.5),
}));

export const TableContainer = styled(BaseTableContainer)<Props>(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  flexDirection: 'column',
  paddingBottom: theme.spacing(0),
  padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
}));

export const TableFooter = styled(BaseTableFooter)<Props>(({ theme }) => ({
  borderTop: '2px solid' + theme.palette.colors.gray[200],
}));

export const TotalValueAndInstallments = styled(Stack)(({ theme }) => ({
  alignItems: 'flex-end',
  padding: theme.spacing(2),
  background: theme.palette.colors.gray[100],
}));

export const SaleInfo = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  padding: theme.spacing(2),
}));
