import { forwardRef } from 'react';

import { ExtendedGridActionsCellItemProps, GridActionsCellItem } from './style';

export const ExtendedGridActionsCellItem = forwardRef<never, ExtendedGridActionsCellItemProps>(
  (props: ExtendedGridActionsCellItemProps, ref) => {
    return <GridActionsCellItem {...props} ref={ref} />;
  },
);

export default ExtendedGridActionsCellItem;
