import { ReactElement } from 'react';

import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Container, Grid } from '@mui/material';

import SideArt from './SideArt';

import PlanneLogoImage from '@/assets/logos/planneLogoDesktop.svg';

import { PlanneLogo, ProvidedBy, ProvidedByContainer } from './styles';
import HeaderBar from './HeaderBar';
import theme from '@/theme';
import ErrorBoundary from '@/components/Error/ErrorBoundary';
import ErrorTechnical from '@/components/Error/ErrorTechnical';

type SideArtLayoutProps = {
  artContent?: ReactElement;
  children?: ReactElement | ReactElement[];
  showProvidedBy?: boolean;
};

export const SideArtLayout = (props: SideArtLayoutProps) => {
  const { artContent, children, showProvidedBy = false } = props;

  const { t } = useTranslation(['layouts']);

  return (
    <ErrorBoundary FallbackComponent={ErrorTechnical}>
      <Grid container component='main' sx={{ flex: 'auto' }}>
        <Grid md={6} item display={{ xs: 'none', md: 'flex' }} sx={{ position: 'relative' }}>
          <SideArt content={artContent} />

          {showProvidedBy ? (
            <ProvidedByContainer>
              <ProvidedBy>{t('side_art_layout.provided_by')}</ProvidedBy>{' '}
              <PlanneLogo src={PlanneLogoImage} alt='Planne' />
            </ProvidedByContainer>
          ) : null}
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            position: 'relative',
            [theme.breakpoints.up('sm')]: {
              paddingTop: '80px',
            },
          }}
        >
          <HeaderBar />

          <Container disableGutters>{children ? children : <Outlet />}</Container>
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
};

export default SideArtLayout;
