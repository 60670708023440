import { QueryFunctionContext } from '@tanstack/react-query';

import { useSellerApi } from '@/hooks/useSellerApi';
import { DetailedSaleItem, SalesApiGetDetailedSaleItemsRequest } from '@/services/SellerApi';
import { RequiredError } from '@/services/SellerApi/base';

type Query = [key: string, saleId: string, limit: number, offset: number];
export interface DetailedSaleItemsResponse {
  data: DetailedSaleItem[];
  totalSaleItems: number;
}

const useGetSaleItems = () => {
  const { salesApi } = useSellerApi();

  const request = async ({
    queryKey,
    signal,
  }: QueryFunctionContext<Query>): Promise<DetailedSaleItemsResponse | undefined> => {
    const [_, saleId, limit, offset] = queryKey;

    const payload: SalesApiGetDetailedSaleItemsRequest = {
      saleId: saleId,
      include: [
        'resources.resource',
        'additionals.additional',
        'reservation',
        'tariffs.tariff.type',
        'partnershipAgent',
        'product.thumbnail',
        'partnershipProvider',
        'partnershipItem.partnership',
      ],
      limit,
      offset,
    };

    try {
      const { data, headers } = await salesApi.getDetailedSaleItems(payload, { signal });

      return {
        data,
        totalSaleItems: parseInt(headers['x-pagination-total-count'] ?? '0'),
      };
    } catch (error) {
      return error instanceof RequiredError ? Promise.reject(error) : undefined;
    }
  };

  return { request };
};

export default useGetSaleItems;
