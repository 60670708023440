import { Box, radioClasses, styled } from '@mui/material';

import Button from '@/components/Button';
import RadioButtonsGroup from '@/components/Form/RadioButtonsGroup';

export const StyledRadioButtonsGroup = styled(RadioButtonsGroup)(() => ({
  [`& .${radioClasses.root}`]: {
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
  },
}));

export const StyledButton = styled(Button)(() => ({
  paddingX: '1rem',
}));

export const DateContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(4),
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(4),
  alignSelf: 'stretch',
  borderRadius: theme.radius.sm,
  backgroundColor: theme.palette.colors.gray[50],
  marginBottom: theme.spacing(4),
}));
