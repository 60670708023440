import { styled } from '@mui/material/styles';

export const IframeContainer = styled('div', { shouldForwardProp: () => true })(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'flex-start',
  position: 'relative',
  flexGrow: 1,
}));

export const Iframe = styled('iframe', { shouldForwardProp: () => true })(() => ({
  width: '100%',
  border: 'none',
}));
