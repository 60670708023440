import { Stack } from '@planne-software/uni/mui/material';
import DayCellSkeleton from '../DayCell/DayCellSkeleton';

type Props = {
  total?: number;
};
const HorizontalCalendarSkeleton = (props: Props) => {
  const { total = 11 } = props;
  return (
    <Stack sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <Stack sx={{ width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 3 }}>
        {[...Array(total)].map((_, index) => (
          <DayCellSkeleton key={index} />
        ))}
      </Stack>
    </Stack>
  );
};

export default HorizontalCalendarSkeleton;
