import { format } from 'date-fns';

import { SchedulingFields } from '@/features/agenda/features/schedulingForm/types/formTypes';
import formatMidnightString from '@/features/agenda/features/schedulingForm/utils/formatMidnightString';
import stringHoursToMinutes from '@/features/agenda/utils/stringHoursToMinutes';
import { CreateSchedulingParams, SellingMode, UpdateSchedulingParams } from '@/services/SellerApi';

export function formFieldsToApiFields<Params extends CreateSchedulingParams | UpdateSchedulingParams>(
  fieldsData: SchedulingFields,
): Params {
  const productDateAndTimeFields = () => {
    if (fieldsData.product.sellingMode === SellingMode.WithDateAndTime) {
      return {
        consumableFromTime: formatMidnightString(fieldsData.consumableFromTime, true),
        consumableToTime: formatMidnightString(fieldsData.consumableToTime, true),
        calendarIntervalMinutes: stringHoursToMinutes(fieldsData.calendarIntervalMinutes),
      };
    }
  };

  const recurrence = () => {
    if (fieldsData.recurrence && Object.keys(fieldsData.recurrence).length > 0) {
      if (fieldsData.recurrence.weekdays?.length === 0) {
        delete fieldsData.recurrence.weekdays;
      }
      return {
        recurrence: fieldsData.recurrence,
      };
    }
  };

  const tariffGroup = () => {
    if (fieldsData.tariffGroup !== undefined) {
      return {
        tariffGroupId: fieldsData.tariffGroup?.id || null,
      };
    }

    if (fieldsData?.id) {
      return {
        tariffGroupId: null,
      };
    }
  };

  const params = {
    beginDate: format(fieldsData.beginDate, 'yyyy-MM-dd'),
    durationMinutes: stringHoursToMinutes(fieldsData.durationMinutes),
    antecedenceMinutes: fieldsData.antecedenceMinutes,
    antecedenceHoursReferenceTime: fieldsData.antecedenceHoursReferenceTime,
    overrideConflicts: fieldsData.overrideConflicts,
    ...tariffGroup(),
    ...recurrence(),
    ...productDateAndTimeFields(),
  } as Params;

  return params;
}
