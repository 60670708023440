import { Box, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import AvailabilityTypeChip from '@/features/agenda/components/AvailabilityTypeChip';
import { InfoItem, InfoList } from '@/features/agenda/styles/infoList';
import { getAvailabilityQuantitiesText } from '@/features/agenda/utils/getAvailabilityQuantitiesText';
import { Availability } from '@/services/SellerApi';
import theme from '@/theme';
import { dateWithTimezone } from '@/utils';

import AvailabilitySubItens from '@/features/agenda/components/AvailabilitySubItens';
import isAvailabilityContentArchived from '@/features/agenda/utils/isAvailabilityContentArchived';
import Alert from '@/components/Alert';

interface AvailabilityModalProps {
  availability: Availability;
}

const AvailabilityModalContent = (props: AvailabilityModalProps) => {
  const { availability } = props;

  const { t } = useTranslation(['agenda'], { keyPrefix: 'availability' });

  const isArchived = useMemo(() => isAvailabilityContentArchived(availability), [availability]);
  const archivedItemKey = useMemo(() => {
    if (isArchived.product) {
      return 'product_archived';
    }
    if (isArchived.tariff) {
      return 'tariff_archived';
    }
    if (isArchived.tariffGroup) {
      return 'tariff_group_archived';
    }
  }, [isArchived]);

  const dateString = useMemo(() => {
    if (availability?.appliesAt) {
      const dataString = dateWithTimezone(availability.appliesAt).toLocaleDateString('pt-BR', {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      });

      return dataString.charAt(0).toUpperCase() + dataString.slice(1);
    }
    return '';
  }, [availability]);

  return (
    <Stack flexDirection='column' justifyContent='space-between' sx={{ paddingBottom: theme.spacing(4) }}>
      <Typography variant='smallSemiBold'>{dateString}</Typography>

      <InfoList>
        <InfoItem>
          <Typography variant='smallBold'>{t('detail_modal.type')}:</Typography>

          <AvailabilityTypeChip type={availability.type} />
        </InfoItem>

        <InfoItem>
          <Typography variant='smallBold'>{t('detail_modal.quantity')}:</Typography>

          <Typography variant='extraSmallSemiBold'>{getAvailabilityQuantitiesText(availability)}</Typography>
        </InfoItem>

        <InfoItem>
          <Typography variant='smallBold'>{t('detail_modal.time_range')}:</Typography>

          <Typography variant='extraSmallSemiBold'>
            {availability.fromTime} - {availability.toTime}
          </Typography>
        </InfoItem>
      </InfoList>

      <AvailabilitySubItens availability={availability} />

      {isArchived.isSomeArchived && !isArchived.productTariffType ? (
        <Alert severity='warning' sx={{ marginTop: theme.spacing(3), borderRadius: '0.5rem' }}>
          <Stack sx={{ gap: theme.spacing(2) }}>
            <Typography variant='regularBold'>{t(`${archivedItemKey}.title`)}</Typography>
            <Box>{t(`${archivedItemKey}.description`)}</Box>
          </Stack>
        </Alert>
      ) : null}
    </Stack>
  );
};

export default AvailabilityModalContent;
