import { TimeIntervalStatus } from '@/features/reservation/types';
import { Badge } from '@planne-software/uni/Badge';
import { ListItem } from '@planne-software/uni/ListItem';
import { Select, RenderOption, SelectOption, SelectProps } from '@planne-software/uni/Select';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  options: SelectOption[];
} & SelectProps<SelectOption>;

const TimeSelectInput = forwardRef((props: Props, ref: React.Ref<HTMLInputElement>) => {
  const { options, value, onClearInput, ...rest } = props;
  const { t } = useTranslation(['reservation'], { keyPrefix: 'reschedule_form' });

  const renderOption: RenderOption<SelectOption, HTMLSpanElement> = (props, option) => {
    const { label, value, status } = option;
    const { key, 'aria-selected': selected, ...rest } = props;

    const getColor = () => {
      switch (status) {
        case TimeIntervalStatus.AVAILABLE:
          return 'success';
        case TimeIntervalStatus.UNAVAILABLE:
          return 'error';
        default:
          return 'default';
      }
    };

    return (
      <ListItem
        key={`${key}-${value}`}
        {...rest}
        component='li'
        label={label}
        selected={Boolean(selected)}
        endSlot={({ ...rest }) => (
          <Badge color={getColor()} label={t(`time_status.${status.toLowerCase()}`)} size='small' {...rest} />
        )}
      />
    );
  };

  const getOptionLabel = (option: string | SelectOption) => {
    return typeof option === 'object' && 'label' in option ? option.label : option;
  };

  return (
    <Select
      {...rest}
      placeholder={t('select_time')}
      noOptionsText={t('no_time_options')}
      options={options.map((time) => ({
        label: time.label,
        value: time.label,
        status: time.status,
      }))}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, value: SelectOption) =>
        typeof value === 'string' ? option.value === value : option.value === value.value
      }
      preview={value?.toString()}
      value={value}
      renderSearch={null}
      renderOption={renderOption}
      inputRef={ref}
      slotProps={{
        tagProps: {
          onDelete: (_: React.SyntheticEvent) => onClearInput && onClearInput(),
        },
      }}
    />
  );
});

export default TimeSelectInput;
