import { styled, css, Box } from '@mui/material';

export const IconWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.palette.colors.green[50]};
    border-radius: 100%;
    width: 64px;
    height: 64px;
    svg {
      color: ${theme.palette.colors.green[500]};
    }
  `,
);

export const ModalWrapper = styled(Box)(
  () => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
    gap: 1.5rem;
  `,
);
