import { Reservation, SellingMode } from '@/services/sellerApiQuery/model';
import apiDateToDateObject from '@/utils/apiDateToDateObject';
import { isValid, isSameDay } from 'date-fns';
import { z } from 'zod';

const rescheduleFormSchema = (reservation?: Reservation) => {
  const noRequiredField = z.string().optional();

  return z
    .object({
      scheduleDate: z.date().refine((date) => {
        if (!isValid(date)) {
          return false;
        }
        if (reservation?.product?.sellingMode === SellingMode.with_date_only) {
          if (reservation?.scheduleDate && isSameDay(date, apiDateToDateObject(reservation?.scheduleDate))) {
            return false;
          }
        }
        return true;
      }),
      scheduleTime: noRequiredField.refine((time) => {
        if (reservation?.product?.sellingMode === SellingMode.with_date_and_time) {
          if (time && !/^(0\d|1\d|2\d):[0-5]\d$/.test(time)) {
            return true;
          }
          if (reservation && time === reservation.scheduleTime) {
            return false;
          }
        }
        return true;
      }),
    })
    .transform((data) => {
      const scheduleDate = data.scheduleDate.toISOString().split('T')[0];
      return {
        ...data,
        scheduleDate,
      };
    });
};

export default rescheduleFormSchema;
