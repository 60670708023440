import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';

import { AgendaConfigState, DateRange } from '@/features/agenda/types';
import useGetAllAggregatedAvailabilities from '@/features/agenda/usecases/useGetAllAggregatedAvailabilities';
import useAccount from '@/hooks/useAccount';
import { AvailabilityTypeEnum } from '@/services/SellerApi';
import { useConfig } from '@/features/config/useConfig';
import normalizeArray from '@/utils/normalizeArray';

const useLoadAggregatedAvailabilitiesQuery = () => {
  const { config } = useConfig<AgendaConfigState>();
  const { selectedAccount } = useAccount();
  const { request: getAllAvailabilities } = useGetAllAggregatedAvailabilities();

  const useRequestQuery = (params: DateRange, previewSize: number | undefined, active = false) => {
    const { since, until } = params;
    const pageViewCropped = previewSize && previewSize > 10 ? 10 : previewSize;
    const sinceKey = since ? format(since, 'yyyy-MM-dd') : '';
    const untilKey = until ? format(until, 'yyyy-MM-dd') : '';
    const productsId = normalizeArray<string>(config?.products);
    const types = normalizeArray<AvailabilityTypeEnum>(config?.availabilityType);
    const appId = selectedAccount?.appId as string | null;

    return useQuery({
      queryKey: ['aggregatedAvailabilities', appId, sinceKey, untilKey, pageViewCropped, productsId, types],
      queryFn: getAllAvailabilities,
      enabled: !!appId && !!since && !!until && active,
      staleTime: 1000,
    });
  };

  return [useRequestQuery];
};

export default useLoadAggregatedAvailabilitiesQuery;
