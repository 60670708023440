import { useMediaQuery, Theme } from '@mui/material';

export const useBreakpoints = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md')) && !isMobile;

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return { isMobile, isTablet, isDesktop };
};

export default useBreakpoints;
