import { Link, styled } from '@mui/material';

export const StyledAction = styled(Link)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  padding: 0,
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',

  '& svg': {
    stroke: theme.palette.grey[500],
  },

  '&:hover svg': {
    stroke: theme.palette.grey[700],
  },
}));
