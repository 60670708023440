import { Stack, styled } from '@mui/material';

export const MobileVoucherWrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
  padding: theme.spacing(1),
  borderBottom: '1px solid',
  borderBottomColor: theme.palette.colors.gray[200],

  '&:last-of-type': {
    marginBottom: 0,
  },
}));

export const MobileVoucherDetail = styled(Stack)<{ isRevoked: boolean }>(({ theme, isRevoked }) => ({
  '& .preview-item:not(:nth-of-type(even))': {
    background: theme.palette.colors.gray[isRevoked ? 100 : 50],
    borderRadius: '4px',
  },

  '& .preview-item': {
    ...(isRevoked
      ? {
          span: {
            color: theme.palette.colors.gray[isRevoked ? 400 : 700],
          },
        }
      : {}),
  },
}));

export const AccordionSummaryContent = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  paddingRight: theme.spacing(1),
}));
