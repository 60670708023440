import { styled } from '@mui/material/styles';

import { Input as BaseInput } from '@/components/Form/Input';

export const Input = styled(BaseInput)(({ theme, value }) => ({
  flex: 1,
  background: theme.palette.colors.gray[50],
  transition: 'all 0.3s ease-in-out',

  '& fieldset': {
    border: '0',
    borderRadius: '16px',
  },

  '& .MuiInputBase-input': {
    fontStyle: value ? 'normal' : 'italic',
    border: 'none !important',
    backgroundColor: theme.palette.colors.gray[50] + '!important',
    transition: 'all 0.3s ease-in-out',
  },

  '& .MuiInputBase-input:hover': {
    fontStyle: 'normal',
    backgroundColor: theme.palette.colors.gray[100] + '!important',
    fontWeight: 600,
  },
  '& .MuiInputBase-input:focus': {
    fontStyle: 'normal',
  },

  '&:hover': {
    backgroundColor: theme.palette.colors.gray[100] + '!important',
  },

  '& .MuiInputBase-root': {
    paddingRight: '8px',
  },
}));
