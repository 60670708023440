import { FilterOption } from '@/contexts/FilterContext';
import useAccount from '@/hooks/useAccount';
import { useSellerApi } from '@/hooks/useSellerApi';
import Bugsnag from '@/services/bugsnag';
import { NotifiableError } from '@bugsnag/js';

import { TFunction } from 'i18next';
import { useEffect, useState } from 'react';

export const useProductShowcaseFilterSchema = (t: TFunction<[string], undefined>) => {
  const { selectedAccount } = useAccount();

  const { partnershipsApi } = useSellerApi();

  const [loadingPartnershipProviders, setLoadingPartnershipProviders] = useState(true);
  const [partnershipProviders, setPartnershipProviders] = useState<FilterOption[]>([]);

  const getAppPartnershipProviders = async (appId: string) => {
    const payload = {
      appId,
    };

    try {
      setLoadingPartnershipProviders(true);

      let page = 0;
      const limit = 100;
      const providers = [];
      let keepRunning = true;

      while (keepRunning) {
        const response = await partnershipsApi.getAppPartnershipProviders({
          ...payload,
          limit,
          offset: page * limit,
        });

        providers.push(...response.data);
        page++;

        const totalOfProducts = Number(response.headers['x-pagination-total-count'] ?? '0');

        if (providers.length >= totalOfProducts) {
          keepRunning = false;
          break;
        }
      }

      const sortedPartnershipProviders = providers.sort((a, b) => a.name!.localeCompare(b.name!));

      setPartnershipProviders(
        sortedPartnershipProviders.map((partnershipProvider) => ({
          label: partnershipProvider.name,
          value: partnershipProvider.id,
        })),
      );
    } catch (error) {
      Bugsnag.notify(error as NotifiableError);
      console.error(error);
    } finally {
      setLoadingPartnershipProviders(false);
    }
  };

  useEffect(() => {
    if (selectedAccount?.appId) {
      getAppPartnershipProviders(selectedAccount.appId);
    }
  }, [selectedAccount?.appId]);

  return {
    schedulingSince: {
      type: 'date',
      format: 'dd/MM/yyyy',
      title: t('filter.since_label'),
      tooltip: t('filter.since_label'),
    },
    schedulingUntil: {
      type: 'date',
      format: 'dd/MM/yyyy',
      title: t('filter.until_label'),
      tooltip: t('filter.until_label'),
    },
    providerIds: {
      type: 'array',
      title: t('filter.provider_selector_label'),
      tooltip: t('filter.provider_selector_label'),
      loading: loadingPartnershipProviders,
      options: partnershipProviders,
    },
  };
};

export default useProductShowcaseFilterSchema;
