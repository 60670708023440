import { Route } from 'react-router';
import { Fragment, Suspense } from 'react';
import CouponList from '@/features/coupons/pages/CouponList';
import CanceledCoupons from '@/features/coupons/pages/ArchivedCouponList';
import ProtectedRoute from '@/routes/ProtectedRoute';

import Loading from '@/components/Loading';

import CouponDetails from '@/features/coupons/pages/CouponDetails';
// const CouponDetails = lazy(() => import('@/features/coupons/pages/CouponDetails'));

import CouponFormPage from '@/features/coupons/pages/CouponForm';
// const CouponFormPage = lazy(() => import('@/features/coupons/pages/CouponForm'));

export const CouponRoutes = () => {
  return (
    <Fragment>
      <Route
        path='coupons'
        element={
          <ProtectedRoute
            policies={[
              {
                namespace: 'coupons',
                action: 'GetAppCoupons',
                code: 'coupons:GetAppCoupons',
              },
            ]}
            element={<CouponList />}
          />
        }
      >
        <Route
          path=':couponId'
          element={
            <ProtectedRoute
              policies={{
                AND: [
                  {
                    namespace: 'coupons',
                    action: 'GetCoupon',
                    code: 'coupons:GetCoupon',
                  },
                  {
                    namespace: 'couponProducts',
                    action: 'GetCouponProducts',
                    code: 'couponProducts:GetCouponProducts',
                  },
                ],
              }}
              element={
                <Suspense fallback={<Loading />}>
                  <CouponDetails context='active' />
                </Suspense>
              }
            />
          }
        />
      </Route>

      <Route
        path='coupons/create'
        element={
          <ProtectedRoute
            policies={{
              AND: [
                {
                  namespace: 'coupons',
                  action: 'CreateAppCoupon',
                  code: 'coupons:CreateAppCoupon',
                },
              ],
            }}
            element={<CouponFormPage />}
          />
        }
      />

      <Route
        path='coupons/:id/edit'
        element={
          <ProtectedRoute
            policies={{
              AND: [
                {
                  namespace: 'coupons',
                  action: 'CreateAppCoupon',
                  code: 'coupons:CreateAppCoupon',
                },
              ],
            }}
            element={<CouponFormPage />}
          />
        }
      />

      <Route
        path='coupons/canceled'
        element={
          <ProtectedRoute
            policies={[
              {
                namespace: 'coupons',
                action: 'GetAppCoupons',
                code: 'coupons:GetAppCoupons',
              },
            ]}
            element={<CanceledCoupons />}
          />
        }
      >
        <Route
          path=':couponId'
          element={
            <ProtectedRoute
              policies={{
                AND: [
                  {
                    namespace: 'coupons',
                    action: 'GetCoupon',
                    code: 'coupons:GetCoupon',
                  },
                  {
                    namespace: 'couponProducts',
                    action: 'GetCouponProducts',
                    code: 'couponProducts:GetCouponProducts',
                  },
                ],
              }}
              element={
                <Suspense fallback={<Loading />}>
                  <CouponDetails context='archived' />
                </Suspense>
              }
            />
          }
        />
      </Route>
    </Fragment>
  );
};
