import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import PageLayout from '@/layouts/PageLayout';
import FilterProvider from '@/contexts/FilterContext';
import CommissionList from '@/components/Partnership/Commissions/CommissionList';
import Filters from '@/components/Partnership/Commissions/Filters';
import useCommissionFilterSchema from '@/components/Partnership/Commissions/Filters/useCommissionFilterSchema';
import { IconShoppingCartX } from '@tabler/icons-react';

export const PartnershipCommissions = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['commissions']);

  const schema = useCommissionFilterSchema(t);

  return (
    <FilterProvider schema={schema} contextName='commissions'>
      <Helmet>
        <title>{t('commissions_page_title')}</title>
      </Helmet>

      <PageLayout
        title={t('title')}
        actions={{
          secondary: [
            {
              onClick: () => navigate('canceled'),
              Icon: <IconShoppingCartX />,
            },
          ],
        }}
        showSearchInput={false}
        slots={{ FilterFields: Filters }}
      >
        <CommissionList currentState={['payment_complete']} />
      </PageLayout>
    </FilterProvider>
  );
};

export default PartnershipCommissions;
