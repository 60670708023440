import { isValid } from 'date-fns';
import { TFunction } from 'i18next';
import { z } from 'zod';

import { RecurrenceWeekdayEnum, RecurrenceTypeEnum } from '@/services/SellerApi';

const recurrenceFormSchema = (t: TFunction<'scheduling-form'[]>, startDate: Date) => {
  const types = Object.values(RecurrenceTypeEnum) as [string, ...string[]];
  const weekdays = Object.values(RecurrenceWeekdayEnum) as [string, ...string[]];

  return z
    .object({
      amount: z.coerce
        .number({ invalid_type_error: t('recurrence.modal.validation.invalid_amount') })
        .int()
        .positive({ message: t('recurrence.modal.validation.invalid_amount') })
        .min(1, { message: t('recurrence.modal.validation.invalid_amount') })
        .max(31),
      type: z.enum(types),
      weekdays: z.array(z.enum(weekdays)).optional(),
      endDate: z.date().refine((date) => isValid(date) && date > startDate, {
        message: t('recurrence.modal.validation.date_before_start'),
      }),
    })
    .superRefine((value, context) => {
      if (value.type === RecurrenceTypeEnum.Weeks && value.weekdays!.length < 1) {
        return context.addIssue({
          path: ['weekdays'],
          code: 'too_small',
          message: t('recurrence.modal.validation.weekday_too_small'),
          type: 'array',
          minimum: 1,
          inclusive: true,
        });
      }
    });
};

export default recurrenceFormSchema;
