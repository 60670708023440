import { z } from 'zod';
import { FormParams } from '.';

const requiredField = z.string().nonempty({ message: 'Campo obrigatório' });

export const schema = z
  .object({
    password: requiredField.min(8, { message: 'A senha deve conter no mínimo 8 caracteres' }),
    confirmPassword: requiredField,
  })
  .superRefine(({ password, confirmPassword }: FormParams, ctx: z.RefinementCtx) => {
    if (password && confirmPassword && password !== confirmPassword) {
      return ctx.addIssue({
        code: 'custom',
        message: 'As senhas não correspondem',
        path: ['confirmPassword'],
      });
    }
  })
  .transform((data: FormParams) => {
    return {
      password: data.password,
    };
  });
