import Button from '@/components/Button';
import { Badge, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useFilter from '@/hooks/useFilter';
import { IconFilter } from '@tabler/icons-react';
import { useMemo } from 'react';

type Props = {
  text?: string;
  variant?: 'outlined' | 'contained' | 'text';
  fullWidth?: boolean;
  filterCount?: number;
};

const FilterButton = (props: Props) => {
  const { variant = 'outlined', fullWidth = false, filterCount } = props;

  const { showFilters, handleChangeShowFilters, appliedFilters } = useFilter();
  const { t } = useTranslation(['ui']);

  const totalAppliedFilters = useMemo(() => {
    if (filterCount) {
      return filterCount;
    }

    return Object.keys(appliedFilters).length > 0 ? Object.keys(appliedFilters).length : undefined;
  }, [appliedFilters, filterCount]);

  return (
    <Tooltip leaveTouchDelay={1000} title={t('filter.filter_button_tooltip')} placement='top' arrow>
      <Badge color='error' badgeContent={totalAppliedFilters}>
        <Button
          color='primary'
          fullWidth={fullWidth}
          sx={{ p: '0.625rem' }}
          onClick={() => handleChangeShowFilters(!showFilters)}
          variant={totalAppliedFilters ? 'contained' : variant}
        >
          <IconFilter />
        </Button>
      </Badge>
    </Tooltip>
  );
};

export default FilterButton;
