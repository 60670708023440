const intervalTimeOptions = (interval: number, startOnInterval = false, returnMidnight = false) => {
  const options = [];

  if (interval < 1) {
    throw new Error('Interval must be greater than 0');
  }

  for (let i = 0; i <= 24; i++) {
    if (i >= 24) {
      options.push(returnMidnight ? '24:00' : '23:59');
      break;
    }
    for (let j = 0; j < 60; j += interval) {
      const hour = i.toString().padStart(2, '0');
      const minute = j.toString().padStart(2, '0');
      options.push(`${hour}:${minute}`);
    }
  }
  if (startOnInterval) {
    options.shift();
  }
  return options;
};

export default intervalTimeOptions;
