import { styled } from '@mui/material';

export const StyledSchedulingCalendarContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(7, minmax(0, 1fr))',
  gridTemplateRows: '3rem auto',
  backgroundColor: theme.palette.background.paper,
  rowGap: theme.spacing(4),
  gridTemplateAreas: `
  "header"
  "day"`,
  height: '100%',
  width: '100%',

  [theme.breakpoints.down('md')]: {
    rowGap: theme.spacing(0),
  },
}));

export const StyledHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.divider}`,
  borderTop: `1px solid ${theme.palette.divider}`,
  textTransform: 'capitalize',
}));

export const StyledDay = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.radius.md,
  color: theme.palette.text.primary,
  margin: theme.spacing(0, 2),

  [theme.breakpoints.down('md')]: {
    margin: 0,
    borderRadius: 0,
    borderRight: 0,
    borderBottom: 0,
  },

  [`&.beforeToday`]: {
    opacity: 0.4,
  },
}));
