import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconShoppingBag } from '@tabler/icons-react';
import { Grid } from '@mui/material';

import theme from '@/theme';
import useAccount from '@/hooks/useAccount';
import EmptyPage from '@/components/EmptyPage';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import { initialPaginationState } from '@/utils';
import EmptyFilterPage from '@/components/FilterDrawer/EmptyFilterPage';
import {
  DetailedDirectSaleItem,
  DirectSale,
  DirectSalesApiGetAppDirectSalesRequest,
  DirectSalesApiGetDetailedDirectSaleItemsRequest,
  DirectSalesApiGetDirectSalePaymentOptionsRequest,
  PaymentOption,
  SaleStateEnum,
} from '@/services/SellerApi';
import DirectSaleTable from './DirectSalesTable';
import { useSellerApi } from '@/hooks/useSellerApi';
import Bugsnag from '@/services/bugsnag';
import { NotifiableError } from '@bugsnag/js';
import useFilter from '@/hooks/useFilter';
import Loading from '@/components/Loading';

export interface DirectSaleWithItemsAndPaymentOption {
  directSale: DirectSale;
  paymentOptions?: {
    loading: boolean;
    items: PaymentOption[];
  };
  directSaleItems?: {
    loading: boolean;
    items: DetailedDirectSaleItem[];
  };
}

export const DirectSaleList = () => {
  const { track } = useAnalytics();
  const { selectedAccount } = useAccount();
  const { t } = useTranslation(['directSales']);
  const { directSalesApi } = useSellerApi();

  const { search, appliedFilters } = useFilter();

  const [loading, setLoading] = useState(true);
  const [totalDirectSales, setTotalDirectSales] = useState(0);
  const [directSales, setDirectSales] = useState<DirectSale[]>([]);
  const [directSalesWithProductsAndPaymentMenthods, setDirectSalesWithProductsAndPaymentMenthods] = useState<
    DirectSaleWithItemsAndPaymentOption[]
  >([]);
  const [paginationModel, setPaginationModel] = useState(initialPaginationState);

  const getAppDirectSales = async () => {
    const payload: DirectSalesApiGetAppDirectSalesRequest = {
      include: ['customer', 'aggregatedVouchers', 'app'],
      appId: selectedAccount!.appId,
      limit: paginationModel.pageSize,
      offset: paginationModel.page * paginationModel.pageSize,
      ...(search ? { search } : {}),
      ...appliedFilters,
    };

    try {
      setLoading(true);
      const { data, headers } = await directSalesApi.getAppDirectSales(payload);

      setDirectSales(data);
      setDirectSalesWithProductsAndPaymentMenthods(
        data.map((p) => ({
          directSale: p,
          directSaleItems: { loading: true, items: [] },
          paymentOptions: { loading: true, items: [] },
        })),
      );

      setTotalDirectSales(parseInt(headers['x-pagination-total-count'] ?? '0'));

      track('directSales/retrieveDirectSalesSuccess', { payload });
    } catch (error) {
      enqueueSnackbar(t('list.could_not_load_direct_sales'), { variant: 'error' });
      track('directSales/retrieveDirectSalesFail', { payload });
    } finally {
      setLoading(false);
    }
  };

  const updateDetailedDirectSaleItemsAndPaymentOptions = async () => {
    const updateState = (
      directSale: DirectSale,
      directSaleItems: DetailedDirectSaleItem[] | undefined,
      paymentOptions: PaymentOption[] | undefined,
    ) => {
      const directSalesWithItems = {
        directSale,
        directSaleItems: {
          loading: false,
          items: directSaleItems ?? [],
        },
        paymentOptions: {
          loading: false,
          items: paymentOptions ?? [],
        },
      };

      setDirectSalesWithProductsAndPaymentMenthods((previousState) => {
        const itemWithoutTargetOne = previousState.filter((item) => item.directSale.id !== directSale.id);

        return [...itemWithoutTargetOne, directSalesWithItems];
      });
    };

    for (const directSalesWithProductAndPaymentMethod of directSalesWithProductsAndPaymentMenthods) {
      if (directSalesWithProductAndPaymentMethod.directSale.currentState === SaleStateEnum.Created) {
        Promise.allSettled([
          getDetailedDirectSaleItems(directSalesWithProductAndPaymentMethod.directSale.id),
          getDirectSalePaymentOptions(directSalesWithProductAndPaymentMethod.directSale.id),
        ]).then(([directSaleItemsResult, paymentOptionsResult]) => {
          const directSaleItems = directSaleItemsResult.status === 'fulfilled' ? directSaleItemsResult.value : [];
          const paymentOptions = paymentOptionsResult.status === 'fulfilled' ? paymentOptionsResult.value : [];

          updateState(directSalesWithProductAndPaymentMethod.directSale, directSaleItems, paymentOptions);
        });

        continue;
      }

      getDetailedDirectSaleItems(directSalesWithProductAndPaymentMethod.directSale.id).then(
        (directSaleItemsResult) => {
          updateState(directSalesWithProductAndPaymentMethod.directSale, directSaleItemsResult, []);
        },
      );
    }
  };

  const getDetailedDirectSaleItems = async (directSaleId: string) => {
    const payload: DirectSalesApiGetDetailedDirectSaleItemsRequest = {
      saleId: directSaleId,
      include: [
        'product',
        'partnershipItem.product',
        'partnershipItem.partnership',
        'partnershipItem.partnership.provider',
      ],
    };

    try {
      const { data } = await directSalesApi.getDetailedDirectSaleItems(payload);

      return data;
    } catch (error) {
      enqueueSnackbar(t('list.could_not_load_direct_sale_items'), { variant: 'error' });

      track('directSales/retrieveDirectSaleItemFail', { payload });
      Bugsnag.notify(error as NotifiableError);
    }
  };

  const getDirectSalePaymentOptions = async (directSaleId: string) => {
    const payload: DirectSalesApiGetDirectSalePaymentOptionsRequest = {
      saleId: directSaleId,
    };

    try {
      const { data } = await directSalesApi.getDirectSalePaymentOptions(payload);

      return data;
    } catch (error) {
      enqueueSnackbar(t('list.could_not_load_direct_sale_payment_options'), { variant: 'error' });

      track('directSales/retrieveDirectSalePaymentOptionFail', { payload });
      Bugsnag.notify(error as NotifiableError);
    }
  };

  useEffect(() => {
    if (selectedAccount?.appId) {
      getAppDirectSales();
    }
  }, [paginationModel, search, selectedAccount?.appId, appliedFilters]);

  useEffect(() => {
    if (directSales.length && directSalesWithProductsAndPaymentMenthods.length) {
      updateDetailedDirectSaleItemsAndPaymentOptions();
    }
  }, [directSales]);

  return (
    <Grid container paddingX={{ xs: 0, sm: 4 }}>
      {directSales.length ? (
        <Grid item xs={12}>
          <DirectSaleTable
            loading={loading}
            setLoading={setLoading}
            directSales={directSales}
            onRefreshList={getAppDirectSales}
            paginationModel={paginationModel}
            totalDirectSales={totalDirectSales}
            setPaginationModel={setPaginationModel}
            directSalesWithProductsAndPaymentMenthods={directSalesWithProductsAndPaymentMenthods}
          />
        </Grid>
      ) : null}

      {!directSales.length && !loading ? (
        <Grid item xs={12} alignContent='center' justifyContent='center' mt={3}>
          {Object.keys(appliedFilters).length || search ? (
            <EmptyFilterPage />
          ) : (
            <EmptyPage
              Icon={<IconShoppingBag size={92} color={theme.palette.colors.blue[500]} />}
              description={t('list.empty_page.description')}
            />
          )}
        </Grid>
      ) : null}

      {!directSales.length && loading ? (
        <Grid item xs={12} textAlign='center' mt={4}>
          <Loading />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default DirectSaleList;
