import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import PageLayout from '@/layouts/PageLayout';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import FilterProvider from '@/contexts/FilterContext';
import ShowCaseLayout from '@/layouts/ShowCaseLayout';
import ProductShowCase from '@/components/ProductShowCase';
import FilterFields from '@/components/ProductShowCase/FilterFields';
import SideCart from '@/components/SideCart';
import { Hidden } from '@mui/material';
import MobileCart from '@/components/SideCart/MobileCart';
import { useProductShowcaseFilterSchema } from './filterSchema';

export const ProductShowCasePage = () => {
  const { track } = useAnalytics();
  const { t } = useTranslation(['productShowCase']);
  const filterSchema = useProductShowcaseFilterSchema(t);

  useEffect(() => {
    track('productShowCasePage/access');
  }, []);

  return (
    <FilterProvider schema={filterSchema} contextName='productShowCase'>
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>

      <PageLayout
        slots={{ FilterFields }}
        title={t('page_layout_title')}
        slotProps={{ searchInput: { placeholder: t('filter.search_input_placeholder') } }}
      >
        <ShowCaseLayout sideContent={<SideCart />}>
          <ProductShowCase />

          <Hidden smUp>
            <MobileCart />
          </Hidden>
        </ShowCaseLayout>
      </PageLayout>
    </FilterProvider>
  );
};

export default ProductShowCasePage;
