import { Theme } from '@mui/material';
import { useMediaQuery } from '@mui/system';
import { isSameDay } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  StyledDay,
  StyledSchedulingCalendarContainer,
} from '@/features/agenda/components/SchedulingCalendar/AgendaMonthlyView/styles';
import AgendaSlot from '@/features/agenda/components/SchedulingCalendar/AgendaSlot';
import {
  StyledHeaderItemContainer,
  StyledHeaderItemContent,
} from '@/features/agenda/components/SchedulingCalendar/AgendaWeeklyView/styles';
import { useAgenda } from '@/features/agenda/hooks/useAgenda';
import useLoadAggregatedAvailabilitiesQuery from '@/features/agenda/queries/useLoadAggregatedAvailabilitiesQuery';
import useLoadAggregatedSchedulingsQuery from '@/features/agenda/queries/useLoadAggregatedSchedulingsQuery';
import { AgendaConfigState, DateRange, DateSlot, ViewDataType } from '@/features/agenda/types';
import generateCalendarMonthDays from '@/features/agenda/utils/generateCalendarMonthDays';
import generateWeekDays from '@/features/agenda/utils/generateWeekDays';
import { AvailabilityDatePreview, SchedulingDatePreview } from '@/services/SellerApi';
import apiDateToDateObject from '@/utils/apiDateToDateObject';
import { useConfig } from '@/features/config/useConfig';
import clsx from 'clsx';
import isDayBeforeToday from '@/features/agenda/utils/isDayBeforeToday';

const PAGE_SIZE = 10;

const AgendaMonthlyView = () => {
  const [dates, setDates] = useState<DateSlot[]>([]);
  const [queryParams, setQueryParams] = useState<DateRange>({ since: undefined, until: undefined });
  const { activeDate } = useAgenda();
  const { config } = useConfig<AgendaConfigState>();
  const viewDataType = useMemo(() => config?.viewDataType, [config]);
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [schedulingsQuery] = useLoadAggregatedSchedulingsQuery();
  const [availabilitiesQuery] = useLoadAggregatedAvailabilitiesQuery();

  const isViewData = (viewDataKey: ViewDataType) => viewDataType?.includes(viewDataKey);
  const weekDays = useMemo(() => {
    return generateWeekDays(undefined, undefined, { weekday: !isTablet ? 'long' : 'short' }) as string[];
  }, [activeDate, isTablet]);

  const {
    data: schedulings,
    isLoading: isSchedulingsLoading,
    isError: isSchedulingsError,
    error: schedulingsError,
  } = schedulingsQuery(queryParams, PAGE_SIZE, isViewData(ViewDataType.SCHEDULING));

  const {
    data: availabilities,
    isLoading: isAvailabilitiesLoading,
    isError: isAvailabilitiesError,
    error: availabilitiesError,
  } = availabilitiesQuery(queryParams, PAGE_SIZE, isViewData(ViewDataType.AVAILABILITIES));

  const listDates = useCallback(() => {
    const calendar = generateCalendarMonthDays(activeDate);

    if (calendar.length > 0) {
      setDates(calendar);
      setQueryParams({
        since: calendar[0].date,
        until: calendar[calendar.length - 1].date,
      });
    }
  }, [activeDate]);

  const updateDates = useCallback(
    (schedulings?: SchedulingDatePreview[], availabilities?: AvailabilityDatePreview[]) => {
      const newDates = dates.map((date) => {
        const newDate = date;

        if (schedulings) {
          const schedulingsForDate = schedulings?.filter((item) =>
            isSameDay(apiDateToDateObject(item.date), date.date),
          );
          const schedulingsPreview = schedulingsForDate.map((item) => item.schedulings) || [];
          newDate.schedulings = schedulingsPreview[0];
          if (schedulingsForDate.length > 0) {
            newDate.total.schedulings = schedulingsForDate[0].total;
          } else {
            newDate.total.schedulings = 0;
          }
        }

        if (availabilities) {
          const availabilitiesForDate = availabilities?.filter((item) =>
            isSameDay(apiDateToDateObject(item.date), date.date),
          );
          const availabilitiesPreview = availabilitiesForDate.map((item) => item.availabilities) || [];
          newDate.availabilities = availabilitiesPreview[0];

          if (availabilitiesForDate.length > 0) {
            newDate.total.availabilities = availabilitiesForDate[0].total;
          } else {
            newDate.total.availabilities = 0;
          }
        }
        return newDate;
      });

      setDates(newDates);
    },
    [dates],
  );

  useEffect(() => {
    listDates();
  }, [activeDate]);

  useEffect(() => {
    if (schedulings) {
      updateDates(schedulings);
    }
    if (isSchedulingsError) {
      console.error(schedulingsError);
    }
  }, [schedulings, isSchedulingsLoading, isSchedulingsError, schedulingsError]);

  useEffect(() => {
    if (availabilities) {
      updateDates(undefined, availabilities);
    }
    if (isAvailabilitiesError) {
      console.error(availabilitiesError);
    }
  }, [availabilities, isAvailabilitiesLoading, isAvailabilitiesError, availabilitiesError]);

  return (
    <StyledSchedulingCalendarContainer>
      {weekDays.map((day) => (
        <StyledHeaderItemContainer key={day}>
          <StyledHeaderItemContent>{day}</StyledHeaderItemContent>
        </StyledHeaderItemContainer>
      ))}

      {dates.map((date) => (
        <StyledDay key={date.date.toISOString()} className={clsx({ beforeToday: isDayBeforeToday(date.date) })}>
          <AgendaSlot
            date={date.date}
            isLoading={isSchedulingsLoading || isAvailabilitiesLoading}
            schedulings={date.schedulings}
            availabilities={date.availabilities}
            total={date.total}
          />
        </StyledDay>
      ))}
    </StyledSchedulingCalendarContainer>
  );
};

export default AgendaMonthlyView;
