import { RefundRequest, RefundRequestStatus } from '@/services/SellerApi';
import { SwapHorizOutlined } from '@mui/icons-material';
import { Chip } from './style';

interface RefundRequestStatusChipProps {
  status: RefundRequest['status'];
  label: string;
  size?: 'medium' | 'small';
}

export const RefundRequestStatusChip = (props: RefundRequestStatusChipProps) => {
  const { status, label, ...rest } = props;

  const getRefundRequestStatusColor = (status: RefundRequestStatus) => {
    switch (status) {
      case 'pending':
        return 'info';
      case 'approved':
        return 'success';

      default:
        return 'error';
    }
  };

  return (
    <Chip
      label={label}
      variant='outlined'
      color={getRefundRequestStatusColor(status)}
      icon={<SwapHorizOutlined fontSize='small' />}
      {...rest}
    />
  );
};

export default RefundRequestStatusChip;
