/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ptBR } from 'date-fns/locale';
import { Ref, forwardRef } from 'react';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, DatePickerProps as MUIDatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { ptBR as localeText } from '@mui/x-date-pickers/locales';

import Input, { InputProps } from '@/components/Form/Input';

type Props = Omit<MUIDatePickerProps<Date>, 'renderInput'> & {
  renderInput?: (props: InputProps) => JSX.Element;
  error?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  name?: string;
  required?: boolean;
  onBlur?: () => void;
  autoComplete?: string;
  tooltipProps?: InputProps['tooltipProps'];
  variant?: 'mobile' | 'desktop';
  placeholder?: string;
};

export const CustomDatePicker = forwardRef((props: Props, ref: Ref<HTMLDivElement>) => {
  const {
    onChange,
    label,
    error = false,
    format = 'dd-MM-yyyy',
    name,
    fullWidth = false,
    helperText,
    value,
    required = false,
    onBlur,
    onClose,
    autoComplete = 'cc-csc', // workaround to prevent browser autofill
    tooltipProps,
    slotProps,
    slots,
    placeholder,
    variant = 'desktop',
    ...rest
  } = props;

  const Component = variant === 'desktop' ? DatePicker : MobileDatePicker;
  const { textField = {}, ...restOfSlotProps } = slotProps || {};

  return (
    <LocalizationProvider
      adapterLocale={ptBR}
      dateAdapter={AdapterDateFns}
      localeText={localeText.components.MuiLocalizationProvider.defaultProps.localeText}
    >
      <Component
        ref={ref}
        value={value}
        label={label}
        onChange={onChange}
        format={format}
        slotProps={{
          dialog: {
            sx: {
              zIndex: 9999,
            },
          },
          popper: {
            sx: {
              zIndex: 9999,
            },
          },
          textField: {
            InputProps: {
              name,
              onBlur,
              autoComplete,
            },
            error,
            helperText,
            fullWidth,
            required,
            label,
            placeholder,
            // @ts-ignore
            tooltipProps,
            ...textField,
          },
          ...restOfSlotProps,
        }}
        slots={{
          textField: Input,
          ...slots,
        }}
        onClose={() => {
          if (onClose) {
            onClose();
          }
          if (onBlur) {
            onBlur();
          }
        }}
        {...rest}
      />
    </LocalizationProvider>
  );
});

export type { Props as DatePickerProps };

export default CustomDatePicker;
