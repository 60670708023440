import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { AppBar, Box, Drawer, IconButton, Toolbar, Typography } from '@mui/material';
import { createElement, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useFilter from '@/hooks/useFilter';
import { Filters } from '@/interfaces/filterContext';
import { colors } from '@/theme';

import { FilterContent } from './styles';
import FilterActions from '@/components/FilterDrawer/FilterActions';

export type ChildrenProps = {
  handleChangeFilters: (key: string, value: string | string[]) => void;
  filters: Filters;
  closeFilters?: () => void;
  onReset?: () => void;
};

type Props = {
  children?: (props: ChildrenProps) => JSX.Element;
  applyButtonText?: string;
  cancelButtonText?: string;
  resetButtonText?: string;
  title?: string;
  childrenProps?: Partial<ChildrenProps>;
  legacyActions?: boolean;
  handleReset?: () => void;
};

const FilterDrawer = (props: Props) => {
  const { t } = useTranslation(['ui']);
  const {
    selectedFilters,
    appliedFilters,
    showFilters,
    closeFilters,
    onReset,
    handleChangeFilters,
    handleApplyFilter,
  } = useFilter();

  const {
    children,
    title = t('filter.title'),
    applyButtonText = t('filter.applyButtonText'),
    cancelButtonText = t('filter.cancelButtonText'),
    resetButtonText = t('filter.resetButtonText'),
    legacyActions = true,
    handleReset,
    childrenProps,
  } = props;

  const handleCloseFilters = () => {
    closeFilters();
  };

  const handleResetFilters = () => {
    onReset();
    if (handleReset) {
      handleReset();
    }
  };

  const appliedFiltersLength = useMemo(
    () => Object.keys(childrenProps?.filters || appliedFilters).length,
    [appliedFilters, childrenProps?.filters],
  );

  const selectedFiltersLength = useMemo(() => Object.keys(selectedFilters).length, [selectedFilters]);

  const childrenCreation = children
    ? createElement(children, {
        onReset,
        filters: childrenProps?.filters || selectedFilters,
        closeFilters,
        handleChangeFilters,
      })
    : null;

  return (
    <Drawer
      anchor='right'
      onClose={closeFilters}
      open={showFilters}
      sx={{ zIndex: 9999, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: { xs: '100%', sm: '430px' } } }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100vh',
          background: colors.white[600],
        }}
        display='flex'
        flexDirection='column'
      >
        <AppBar position='static' sx={{ background: colors.white[600] }}>
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            <Typography variant='h6' color={colors.gray[800]}>
              {title}
            </Typography>

            <IconButton onClick={closeFilters}>
              <CloseOutlinedIcon sx={{ color: colors.gray[800] }} fontSize='medium' />
            </IconButton>
          </Toolbar>
        </AppBar>

        {legacyActions ? (
          <Fragment>
            <FilterContent gap={3} flex={1} padding={{ xs: 2, lg: 3 }} display='flex' flexDirection='column'>
              {children ? <Fragment>{childrenCreation}</Fragment> : null}
            </FilterContent>

            <FilterActions
              applyButtonText={applyButtonText}
              cancelButtonText={cancelButtonText}
              resetButtonText={resetButtonText}
              handleApplyFilter={handleApplyFilter}
              handleCloseFilters={handleCloseFilters}
              handleResetFilters={handleResetFilters}
              selectedFiltersLength={selectedFiltersLength}
              appliedFiltersLength={appliedFiltersLength}
            />
          </Fragment>
        ) : (
          childrenCreation
        )}
      </Box>
    </Drawer>
  );
};

export default FilterDrawer;
