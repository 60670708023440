import { colors } from '@/theme';
import { MouseEventHandler, ReactNode } from 'react';
import Button from '@/components/Button';
import { Box, SxProps as MUISxProps, Typography } from '@mui/material';

import { IconWrapper } from './styles';

interface SxProps {
  sx: MUISxProps;
}

type Props = {
  buttonText?: ReactNode;
  description: string;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  Icon?: ReactNode;
  showCicleBelowIcon?: boolean;
  slotProps?: {
    button?: SxProps;
    iconWrapper?: SxProps;
    description?: SxProps;
  };
};

export const EmptyPage = (props: Props) => {
  const { Icon, buttonText, description, onClick, showCicleBelowIcon = true, slotProps } = props;

  return (
    <Box display='flex' gap={2} justifyContent='center' alignItems='center' flexDirection='column'>
      {Icon ? (
        <IconWrapper {...slotProps?.iconWrapper}>
          {Icon}
          {showCicleBelowIcon ? (
            <svg xmlns='http://www.w3.org/2000/svg' width='86' height='14' viewBox='0 0 86 14' fill='none'>
              <ellipse cx='43' cy='7' rx='42.5' ry='7' fill='#D9D9D9' />
            </svg>
          ) : null}
        </IconWrapper>
      ) : null}

      <Typography
        align='center'
        maxWidth={308}
        color={colors.gray[800]}
        variant='regularSemiBold'
        {...slotProps?.iconWrapper}
      >
        {description}
      </Typography>

      {buttonText ? (
        <Button variant='contained' color='primary' onClick={onClick} {...slotProps?.button}>
          {buttonText}
        </Button>
      ) : null}
    </Box>
  );
};

export default EmptyPage;
