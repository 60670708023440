import { Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { Message, Description } from './styles';
import Button from '@/components/Button';
import { IconAlertTriangle } from '@tabler/icons-react';
import theme from '@/theme';

type Props = {
  type: 'transaction' | 'antifraud' | 'expired' | 'insufficientAmount';
  onClose: () => void;
};

const TransactionErrorAlert = (props: Props) => {
  const { type, onClose } = props;

  const { t } = useTranslation(['payment']);

  const getDescription = () => {
    switch (type) {
      case 'transaction':
        return t('errors.rejected.description_transaction');
      case 'antifraud':
        return t('errors.rejected.description_antifraud');
      case 'expired':
        return '';
      case 'insufficientAmount':
        return t('errors.rejected.description_insufficient_amount');
    }
  };

  const getMessage = () => {
    switch (type) {
      case 'transaction':
      case 'antifraud':
        return t('errors.rejected.message');
      case 'expired':
        return t('errors.rejected.description_expired');
      case 'insufficientAmount':
        return t('errors.rejected.message_insufficient_amount');
    }
  };

  return (
    <>
      <Typography textAlign='center'>
        <IconAlertTriangle color={theme.palette.colors.warning[400]} size={96} />
      </Typography>

      <Message variant='h6'>{getMessage()}</Message>

      <Description>{getDescription()}</Description>

      <Button fullWidth variant='contained' onClick={onClose}>
        {t('errors.rejected.accept')}
      </Button>
    </>
  );
};

export default TransactionErrorAlert;
