import { ReactSVG } from 'react-svg';
interface SvgInjectorProps {
  src: string;
}
const SvgInjector = (props: SvgInjectorProps) => {
  const { src } = props;

  return <ReactSVG src={src} />;
};

export default SvgInjector;
