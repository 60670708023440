import { ChipProps } from '@mui/material';
import { SaleStateEnum } from '@/services/SellerApi';

import { StyledChip } from './style';

interface SaleStatusChipProps extends Omit<ChipProps, 'color'> {
  status: SaleStateEnum;
}

export const SaleStatusChip = (props: SaleStatusChipProps) => {
  const { status, ...rest } = props;

  return <StyledChip status={status} {...rest} />;
};

export default SaleStatusChip;
