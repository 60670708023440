import { Grid } from '@mui/material';
import { styled } from '@mui/system';

import Button from '@/components/Button';
import { colors } from '@/theme';

export const StyledCalendarItemBase = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  borderRadius: '4px',
  padding: '0.5rem',
  cursor: 'pointer',
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: colors.gray['50'],
  },
}));

export const StyledArrowButton = styled(Button)(() => ({
  padding: '0',
}));
