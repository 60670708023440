import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { Box, CircularProgress, Grid } from '@mui/material';

import useAccount from '@/hooks/useAccount';
import EmptyPage from '@/components/EmptyPage';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import { useSellerApi } from '@/hooks/useSellerApi';
import { Sale, SalesApiGetAppSalesRequest } from '@/services/SellerApi';
import SalesEmptyPageIcon from '@/assets/Icons/sales-empty-page-icon.svg';
import EmptyFilterPage from '@/components/FilterDrawer/EmptyFilterPage';
import SalesTableStructure from '../SalesTableStructure';
import { Outlet } from 'react-router-dom';
import { initialPaginationState } from '@/utils';
import useFilter from '@/hooks/useFilter';

export const SalesList = () => {
  const { track } = useAnalytics();
  const { salesApi } = useSellerApi();
  const { t } = useTranslation(['sales']);
  const { selectedAccount } = useAccount();
  const { appliedFilters, search, isFiltersLoaded } = useFilter();

  const [loading, setLoading] = useState(true);
  const [sales, setSales] = useState<Sale[]>([]);
  const [totalSales, setTotalSales] = useState(0);
  const [paginationModel, setPaginationModel] = useState(initialPaginationState);

  const loadSales = useCallback(async () => {
    const limit = paginationModel.pageSize;
    const offset = paginationModel.page * paginationModel.pageSize;
    const payload: SalesApiGetAppSalesRequest = {
      appId: selectedAccount?.appId ?? '',
      include: ['aggregatedVouchers', 'customer', 'aggregatedRefundRequests', 'tags', 'order'],
      limit,
      offset,
      ...(search ? { search } : {}),
      ...appliedFilters,
      ...(appliedFilters.id_sale_origin ? { saleOriginIds: appliedFilters.id_sale_origin as string[] } : {}),
    };

    try {
      setLoading(true);
      const { data, headers } = await salesApi.getAppSales(payload);

      setSales(data);
      setTotalSales(parseInt(headers['x-pagination-total-count'] ?? '0'));
      track('Sales/retrieveSalesSuccess', { payload });
    } catch (error) {
      enqueueSnackbar(t('messages.could_not_load_sales'), { variant: 'error' });
      track('Sales/retrieveSalesFail', { payload });
    } finally {
      setLoading(false);
    }
  }, [paginationModel, selectedAccount?.appId, salesApi, track, search, isFiltersLoaded]);

  useEffect(() => {
    if (isFiltersLoaded) {
      loadSales();
    }
  }, [paginationModel, isFiltersLoaded]);

  useEffect(() => {
    setPaginationModel({ ...initialPaginationState });
  }, [appliedFilters, search]);

  return (
    <Fragment>
      {sales.length ? (
        <SalesTableStructure
          sales={sales}
          loading={loading}
          onRefresh={loadSales}
          totalSales={totalSales}
          setLoading={setLoading}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
        />
      ) : null}

      {!sales.length && !loading ? (
        <Grid item xs={12} alignContent='center' justifyContent='center'>
          {Object.keys(appliedFilters).length || search ? (
            <EmptyFilterPage />
          ) : (
            <EmptyPage
              showCicleBelowIcon={false}
              Icon={<img src={SalesEmptyPageIcon} alt='icon' />}
              description={t('messages.no_sales_found')}
            />
          )}
        </Grid>
      ) : null}

      {!sales.length && loading ? (
        <Grid item xs={12} textAlign='center'>
          <Box display='flex' alignItems='center' justifyContent='center' height='60vh' width='100%'>
            <CircularProgress color='primary' />
          </Box>
        </Grid>
      ) : null}

      <Outlet context={{ onSuccess: loadSales }} />
    </Fragment>
  );
};

export default SalesList;
