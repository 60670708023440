import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Stack } from '@mui/material';

import { StyledCard } from '@/features/agenda/components/SchedulingCalendar/AgendaWeeklyView/WeeklySlot/styles';
import { Scheduling } from '@/services/SellerApi';
import { useTranslation } from 'react-i18next';
import isSchedulingForAllDay from '@/utils/isSchedulingForAllDay';
import { StyledAvailabilityCard } from '@/features/agenda/components/SchedulingCalendar/styles';
import { IconAlertTriangle, IconCircleCheck } from '@tabler/icons-react';
import theme from '@/theme';
import getRightSchedulingTariffGroupFromApi from '@/features/agenda/utils/getRightSchedulingTariffGroupFromApi';

type Props = {
  scheduling: Scheduling;
};

const WeeklySlotScheduling = ({ scheduling }: Props) => {
  const { search } = useLocation();
  const { t } = useTranslation(['agenda']);

  const isAllDay = useMemo(
    () => isSchedulingForAllDay(scheduling),
    [scheduling.product?.sellingMode, scheduling.consumableFromTime, scheduling.consumableToTime],
  );

  const isSchedulingTariffGroupUndefined = useMemo(() => !scheduling.tariffGroup, [scheduling]);
  const tariffGroup = useMemo(() => getRightSchedulingTariffGroupFromApi(scheduling), [scheduling]);
  const isTariffGroupArchived = useMemo(() => !!tariffGroup?.deletedAt, [tariffGroup]);

  const principalIcon = useMemo(() => {
    if (isSchedulingTariffGroupUndefined && !isTariffGroupArchived) {
      return (
        <Stack>
          <IconCircleCheck size='0.875rem' />
        </Stack>
      );
    }

    if (isTariffGroupArchived) {
      return (
        <Stack>
          <IconAlertTriangle size='0.875rem' />
        </Stack>
      );
    }
  }, [isSchedulingTariffGroupUndefined, isTariffGroupArchived]);

  const getSubTitle = useMemo(() => {
    if (isAllDay) {
      return <StyledCard.Item>{t('all_day')}</StyledCard.Item>;
    }
    if (scheduling.consumableFromTime && scheduling.consumableToTime) {
      return <StyledCard.Item>{`${scheduling.consumableFromTime} - ${scheduling.consumableToTime}`}</StyledCard.Item>;
    }
  }, [scheduling]);

  const getTariffGroupName = useMemo(() => {
    if (tariffGroup?.name) {
      return (
        <StyledCard.Item
          sx={{
            alignItems: 'center',
            gap: theme.spacing(1),
            color: isTariffGroupArchived ? theme.palette.colors.warning[700] : 'inherit',
          }}
        >
          {principalIcon}
          <Box
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {tariffGroup.name}
          </Box>
        </StyledCard.Item>
      );
    }
  }, [tariffGroup]);

  return (
    <StyledCard.Root href={`./schedulings/${scheduling.id}${search}`}>
      <StyledAvailabilityCard>
        <StyledCard.Title.Text>{scheduling.product?.internalName ?? scheduling.product?.name}</StyledCard.Title.Text>
      </StyledAvailabilityCard>
      <StyledCard.Items>
        {getSubTitle}
        {getTariffGroupName}
      </StyledCard.Items>
    </StyledCard.Root>
  );
};

export default WeeklySlotScheduling;
