import { Grid, Typography, styled } from '@mui/material';

export const PaginationContainer = styled(Grid)(({ theme }) => ({
  '& .MuiTablePagination-selectLabel': {
    display: 'none',

    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      padding: '0.625rem 1rem',
    },
  },

  '& .MuiSelect-select': {
    padding: '0.4rem',
    fontSize: '0.825rem',

    [theme.breakpoints.up('sm')]: {
      padding: '0.5rem',
      fontSize: '1rem',
    },

    [theme.breakpoints.up('md')]: {
      padding: '0.625rem 1rem',
    },
  },

  '& .MuiTablePagination-displayedRows': {
    padding: '0.4rem',

    [theme.breakpoints.up('sm')]: {
      padding: '0.5rem',
    },

    [theme.breakpoints.up('md')]: {
      padding: '0.625rem 1rem',
    },
  },

  '& .MuiTablePagination-actions': {
    gap: '4px',

    [theme.breakpoints.up('md')]: {
      gap: '8px',
    },

    button: {
      padding: '0.4rem',
      fontSize: '0.825rem',

      [theme.breakpoints.up('sm')]: {
        padding: '0.5rem',
        fontSize: '1rem',
      },

      [theme.breakpoints.up('md')]: {
        padding: '0.625rem 1rem',
      },
    },
  },
}));

export const CouponsTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: '0.3rem',
  color: theme.palette.colors.gray[500],
}));
