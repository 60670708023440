import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { ShoppingCart03, UserCheck02 } from '@untitled-ui/icons-react';

import PageLayout from '@/layouts/PageLayout';
import { AssociationModel } from '@/services/SellerApi';
import FilterFields from '@/components/Partnership/PartnershipList/Filter';

export type ViewType = 'agent' | 'provider';
type Props = {
  viewType: ViewType;
  children: React.ReactNode;
};

export const PartnershipLayout = (props: Props) => {
  const { viewType, children } = props;

  const searchParams = new URL(window.location.href).search;

  const isProvider = viewType === 'provider';

  const navigate = useNavigate();
  const { t } = useTranslation(['partnership']);
  const pageTitle = isProvider ? t('list.partnership_provider_page_title') : t('list.partnership_agent_page_title');

  const tabs = [
    {
      label: (
        <Box sx={{ gap: 1, display: 'flex', alignItems: 'center' }}>
          <Typography component='p' sx={{ display: 'flex', alignItems: 'center', gap: 1, whiteSpace: 'nowrap' }}>
            <UserCheck02 />
            {t('list.tabs.affiliation')}
          </Typography>
        </Box>
      ),
      value: AssociationModel.Affiliation,
    },
    {
      label: (
        <Box sx={{ gap: 1, display: 'flex', alignItems: 'center' }}>
          <Typography component='p' sx={{ display: 'flex', alignItems: 'center', gap: 1, whiteSpace: 'nowrap' }}>
            <ShoppingCart03 />
            {t('list.tabs.direct_purchase')}
          </Typography>
        </Box>
      ),
      value: AssociationModel.DirectPurchase,
    },
  ];

  return (
    <PageLayout
      title={pageTitle}
      slots={{
        FilterFields: (params) => <FilterFields isProvider={isProvider} {...params} />,
      }}
      slotProps={{
        searchInput: {
          placeholder: t(isProvider ? 'filters.affiliations_placeholder' : 'filters.providers_placeholder'),
        },
        tab: {
          root: {
            variant: 'fullWidth',
            value: location.pathname.split('/').slice(-1)[0] ?? AssociationModel.Affiliation,
            onChange: (_, value) => navigate(`./${value}${searchParams}`),
          },
          tabs,
        },
      }}
    >
      {children}
    </PageLayout>
  );
};

export default PartnershipLayout;
