import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import { Box, Grid, css, styled } from '@mui/material';

import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import RegisterLayout from '@/layouts/RegisterLayout';

export const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(['resetPassword']);
  const [email, setEmail] = useState<string | null>(null);

  const handleGoBack = () => navigate(-1);

  useEffect(() => {
    track('resetPasswordPage/access');
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('reset_password_page_title')}</title>
      </Helmet>
      <Wrapper>
        <RegisterLayout
          error={error}
          loading={loading}
          onGoBack={handleGoBack}
          onCloseAlert={() => setError('')}
          stepTitle={t('steps_title')}
        >
          <Grid container rowSpacing={{ xs: 1, sm: 2 }}>
            <Outlet
              context={{
                email,
                setLoading,
                onError: setError,
                onSubmitEmail: setEmail,
                onChangeEmail: handleGoBack,
              }}
            />
          </Grid>
        </RegisterLayout>
      </Wrapper>
    </>
  );
};

const Wrapper = styled(Box)(
  () => css`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiContainer-root {
      margin: 0;
    }
  `,
);

export default ResetPasswordPage;
