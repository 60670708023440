import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Select, { SelectProps } from '@/components/Form/Select';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import useAccount from '@/hooks/useAccount';
import { useSellerApi } from '@/hooks/useSellerApi';
import { Product, ProductsApiGetAppProductsRequest } from '@/services/SellerApi';
import { limitTags } from '@/utils';

type Props = Omit<SelectProps, 'onChange' | 'options'> & {
  onChange: (value: Product[] | null) => void;
};

export const ProductSelector = (props: Props) => {
  const { onChange, value, name, label, ...rest } = props;

  const { track } = useAnalytics();
  const { productsApi } = useSellerApi();
  const { selectedAccount } = useAccount();
  const { t } = useTranslation(['coupons']);

  const [products, setProducts] = useState<Product[]>([]);
  const [loadingProducts, setLoadingProducts] = useState(false);

  const isOptionEqualToValue = (option: Product, value: Product) => option.id === value.id;

  const transformProducts = (producst: Product[]) => {
    return producst.map((product) => ({ ...product, label: product.name, value: product.id }));
  };

  const getAppProducts = async () => {
    const payload: ProductsApiGetAppProductsRequest = {
      appId: selectedAccount?.appId || '',
    };

    try {
      setLoadingProducts(true);

      let page = 0;
      const limit = 100;
      const products = [];
      let keepRunning = true;

      while (keepRunning) {
        const response = await productsApi.getAppProducts({
          ...payload,
          limit,
          offset: page * limit,
        });

        products.push(...response.data);
        page++;

        const totalOfProducts = Number(response.headers['x-pagination-total-count'] ?? '0');

        if (products.length >= totalOfProducts) {
          keepRunning = false;
          break;
        }
      }

      const sortedProducts = products.sort((a, b) => a.name!.localeCompare(b.name!));

      setProducts(transformProducts(sortedProducts));
      track('products/retrieveAppProductsListSuccess', { payload });
    } catch (error) {
      enqueueSnackbar(t('snackbar.could_not_load_app_products'), { variant: 'error' });
      track('products/retrieveAppProductsListFail', { error, payload });
    } finally {
      setLoadingProducts(false);
    }
  };

  useEffect(() => {
    if (!products.length) {
      getAppProducts();
    }
  }, []);

  return (
    <Select
      fullWidth
      multiple
      id={name}
      selectAllOption
      checkbox
      loading={loadingProducts}
      loadingText={t('coupon_form.products_ids_loading_text')}
      options={products}
      onChange={(_, value) => onChange(value as Product[])}
      value={transformProducts(value as Product[])}
      label={label}
      disableCloseOnSelect
      limitTags={limitTags}
      isOptionEqualToValue={(option, value) => isOptionEqualToValue(option as Product, value as Product)}
      placeholder={t('coupon_form.products_ids_placeholder')}
      slotProps={{
        popper: {
          sx: {
            zIndex: 9999,
          },
        },
      }}
      {...rest}
    />
  );
};

export default ProductSelector;
