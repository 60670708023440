import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const Wrapper = styled('div')(({ theme }) => ({
  padding: '1.5rem',
  borderRadius: '16px',
  color: theme.palette.colors.white[600],
  maxWidth: '518px',
  margin: '0 30px',

  border: '1px solid #FFF',
  background:
    'radial-gradient(189.38% 222.84% at -48.46% -58.92%, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%)',
  backdropFilter: 'blur(2.5px)',

  '& ul': {
    paddingLeft: '28px',
    marginBottom: '24px',
  },

  '& li': {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '26px',
    letterSpacing: 0,
  },
}));

export const BenefitList = styled('ul')(() => ({
  paddingLeft: '28px',
  margin: 0,
  marginBottom: '24px',
}));
export const BenefitListItem = styled('li')(() => ({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '26px',
  letterSpacing: 0,
}));
export const Benefit = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '26px',
  letterSpacing: 0,
}));

export const Title = styled(Typography)(() => ({
  fontSize: '33px',
  fontWeight: 700,
  lineHeight: '40px',
  letterSpacing: 0,
  marginBottom: '16px',
}));
export const SubTitle = styled(Typography)(() => ({
  fontSize: '19px',
  fontWeight: 500,
  lineHeight: '23px',
  letterSpacing: 0,
  marginBottom: '24px',
}));
export const BenefitsTitle = styled(Typography)(() => ({
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '28px',
  letterSpacing: 0,
  marginBottom: '10px',
}));
export const CtaTitle = styled(Typography)(() => ({
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '28px',
  letterSpacing: 0,
}));
export const CtaText = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '28px',
  letterSpacing: 0,
}));
