import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, List } from '@mui/material';
import { IconShoppingCart } from '@tabler/icons-react';

import theme from '@/theme';
import usePartnershipPortalCart from '@/hooks/usePartnershipPortalCart';
import EmptyPage from '@/components/EmptyPage';
import { Loading } from '@/components/Loading';
import { StyledListItem } from './styles';
import CartItemCard from './CartItemCard';

export const ListCartItems = () => {
  const { t } = useTranslation('productShowCase');

  const { cartItems, loadingCart } = usePartnershipPortalCart();

  const hasProductsOnCart = cartItems.length > 0;

  return (
    <Fragment>
      {hasProductsOnCart && !loadingCart ? (
        <Grid item xs={12}>
          <List sx={{ width: '100%' }}>
            {cartItems.map((item) => (
              <StyledListItem key={item.id}>
                <CartItemCard cartItem={item} />
              </StyledListItem>
            ))}
          </List>
        </Grid>
      ) : null}

      {loadingCart ? (
        <Grid item xs={12} alignContent='center' justifyContent='center' mt={4}>
          <Loading />
        </Grid>
      ) : null}

      {!loadingCart && !hasProductsOnCart ? (
        <Grid item xs={12} alignContent='center' justifyContent='center' mt={4}>
          <EmptyPage
            slotProps={{
              description: { sx: { width: '154px' } },
              iconWrapper: {
                sx: {
                  width: '120px',
                  height: '120px',
                  backgroundColor: 'transparent',
                },
              },
            }}
            description={t('cart.empty_description')}
            Icon={<IconShoppingCart color={theme.palette.colors.blue[500]} size={60} />}
          />
        </Grid>
      ) : null}
    </Fragment>
  );
};

export default ListCartItems;
