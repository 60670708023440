import { TFunction } from 'i18next';

import { Recurrence, RecurrenceTypeEnum } from '@/services/SellerApi';
import apiDateToDateObject from '@/utils/apiDateToDateObject';
import i18n from '@/services/i18n';

const recurrenceToString = (recurrence: Recurrence | undefined, t: TFunction) => {
  let finalString = t('recurrence.recurrence_full_string.never');
  if (recurrence?.type === RecurrenceTypeEnum.Days) {
    finalString = t('recurrence.recurrence_full_string.daily', { count: recurrence.amount });
  }

  if (recurrence?.type === RecurrenceTypeEnum.Weeks) {
    finalString = t('recurrence.recurrence_full_string.weekly', { count: recurrence.amount });
  }

  if (recurrence?.weekdays?.length) {
    if (recurrence.weekdays.length === 7) {
      finalString += `, ${t('recurrence.recurrence_full_string.everyday')}`;
    } else {
      finalString += `, ${t('recurrence.recurrence_full_string.on')} ${recurrence.weekdays.map((day) => t(`recurrence.weekday.${day}`)).join(', ')}`;
    }
  }

  if (recurrence?.endDate) {
    const dateString = apiDateToDateObject(recurrence.endDate).toLocaleDateString(i18n.language);

    finalString += `, ${t('recurrence.recurrence_full_string.until')} ${dateString}`;
  }

  return finalString;
};

export default recurrenceToString;
