import { useTranslation } from 'react-i18next';
import { cnpj } from 'cpf-cnpj-validator';

import { Box, Grid, Link, Stack, Typography, useMediaQuery } from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { schema } from './schema';
import Input from '@/components/Form/Input';
import PhoneInput from '@/components/PhoneInput';
import Select from '@/components/Form/Select';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSellerApi } from '@/hooks/useSellerApi';
import { Customer, IdentityTypeEnum } from '@/services/SellerApi';
import useAccount from '@/hooks/useAccount';
import Button from '@/components/Button';
import { ArrowRight, SearchMd } from '@untitled-ui/icons-react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { debounce } from 'lodash';
import ModalOnDesktopDrawerOnMobile from '@/components/Modal/ModalOnDesktopDrawerOnMobile';
import { IconTicket } from '@tabler/icons-react';
import theme from '@/theme';
import { ButtonsWrapper, GridContainer, VoucherImage } from './styles';
import VoucherModel from '@/assets/voucher-model-1.jpg';
import Checkbox from '@/components/Form/Checkbox';
import NationalitySelector from '@/components/NationalitySelector';
import RadioButtonsGroup from '@/components/Form/RadioButtonsGroup';
import {
  MERCOSUL_COUNTRY_CODE,
  CountryData,
  getNationalityByAlpha3,
  isCountryFromMercosulExcludingBrazil,
} from '@/common/countries';

type CustomerFormValue = {
  customer: Customer | null;
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
  identity?: string;
  nationality?: CountryData;
  identityType?: IdentityTypeEnum;
  corporateName?: string | null;
};

export interface SubmitFormData extends Omit<CustomerFormValue, 'nationality'> {
  countryCode?: string;
}

type Props = {
  onSubmit: (data: SubmitFormData) => void;
};

export const CustomerForm = (props: Props) => {
  const { onSubmit } = props;
  const { t } = useTranslation(['showCaseCheckout']);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { customersApi } = useSellerApi();
  const { selectedAccount } = useAccount();

  const [loadingCustomers, setLoadingCustomers] = useState<boolean>(false);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [customersPage, setCustomersPage] = useState<number>(0);
  const [openVoucherModel, setOpenVoucherModel] = useState<boolean>(false);
  const [skipConsumerForm, setSkipConsumerForm] = useState<boolean>(false);

  const customersPageLimit = 100;

  const { register, formState, control, handleSubmit, watch, setValue, reset, clearErrors } =
    useForm<CustomerFormValue>({
      mode: 'onSubmit',
      reValidateMode: 'onBlur',
      resolver: zodResolver(schema(t)),
      defaultValues: {
        phone: '+55',
        customer: null,
        nationality: getNationalityByAlpha3(MERCOSUL_COUNTRY_CODE.BRAZIL),
      },
    });

  const { errors } = formState;

  const watchCustomer = useMemo(() => watch('customer'), [watch('customer')]);
  const watchIdentity = useMemo(() => watch('identity'), [watch('identity')]);
  const watchIdentityType = useMemo(() => watch('identityType'), [watch('identityType')]);
  const watchNationality = useMemo(() => watch('nationality'), [watch('nationality')]);

  const isForeignIdentity = useMemo(() => {
    return watchNationality?.alpha3 && watchNationality.alpha3 !== MERCOSUL_COUNTRY_CODE.BRAZIL;
  }, [watchNationality]);

  const shouldDisplayIdentityType = useMemo(
    () =>
      isForeignIdentity && watchNationality?.alpha3 && isCountryFromMercosulExcludingBrazil(watchNationality.alpha3),
    [isForeignIdentity, watchNationality],
  );

  const getAppCustomers = async (page: number, limit: number, search?: string) => {
    setLoadingCustomers(true);

    try {
      const { data } = await customersApi.getAppCustomers({
        appId: selectedAccount?.app?.id ?? '',
        offset: page * limit,
        limit: limit,
        search,
      });

      const filteredData = data.filter((customer) => customer.firstName !== 'Não informado' && customer.email !== null);

      setCustomers([...customers, ...filteredData]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCustomers(false);
    }
  };

  const loadMoreCustomers = () => {
    setCustomersPage(customersPage + 1);

    getAppCustomers(customersPage + 1, customersPageLimit);
  };

  const getCustomerLabel = (customer: Customer) => `${customer.firstName} ${customer.lastName} - ${customer.email}`;

  const submitForm = (data: CustomerFormValue) => {
    onSubmit(data);
  };

  const submitWithoutValidation = () => {
    onSubmit({
      customer: watchCustomer,
      firstName: skipConsumerForm ? 'Não informado' : watchCustomer!.firstName,
      lastName: skipConsumerForm ? '' : watchCustomer!.lastName,
      email: watchCustomer?.email ?? undefined,
      phone: watchCustomer?.phone ?? undefined,
      identity: watchCustomer?.identity ?? undefined,
      identityType: watchCustomer?.identityType ?? undefined,
      countryCode: watchCustomer?.countryCode ?? undefined,
      corporateName: watchCustomer?.corporateName ?? undefined,
    });
  };

  const debouncedSearch = useCallback(
    debounce((nextValue) => {
      getAppCustomers(0, customersPageLimit, nextValue ? nextValue : undefined);
    }, 1000),
    [],
  );

  const onCustomersSearchChange = (value: string) => {
    setCustomers([]);
    setCustomersPage(0);
    setLoadingCustomers(true);

    debouncedSearch(value);
  };

  const handleChangeNationality = (nationality: CountryData) => {
    const identityType = cnpj.isValid(watchIdentity ?? '') ? IdentityTypeEnum.Cnpj : IdentityTypeEnum.Cpf;

    if (!nationality) {
      setValue('phone', '+55');
      setValue('identityType', identityType);
      return;
    }

    if (isCountryFromMercosulExcludingBrazil((nationality?.alpha3 ?? '') as MERCOSUL_COUNTRY_CODE)) {
      setValue('identityType', IdentityTypeEnum.Identity);
    } else if (nationality?.alpha3 !== MERCOSUL_COUNTRY_CODE.BRAZIL) {
      setValue('identityType', IdentityTypeEnum.Passport);
    } else {
      setValue('identityType', identityType);
    }

    if (nationality?.dialCode) {
      setValue('phone', `+${nationality.dialCode}`);
    }
  };

  const documentLabelKey = useMemo(() => {
    if (watchIdentityType === IdentityTypeEnum.Identity && isForeignIdentity) {
      return 'foreign_identity';
    }

    if (watchIdentityType === IdentityTypeEnum.Passport && isForeignIdentity) {
      return 'passport';
    }

    return 'identity';
  }, [watchIdentityType]);

  useEffect(() => {
    getAppCustomers(customersPage, customersPageLimit);
  }, []);

  useEffect(() => {
    if (watchCustomer) {
      setValue('firstName', watchCustomer.firstName);
      setValue('lastName', watchCustomer.lastName);
      setValue('email', watchCustomer.email ?? '');
      setValue('phone', watchCustomer.phone ?? '');
      setValue('identity', watchCustomer.identity ?? '');
      setValue('identityType', watchCustomer.identityType ?? undefined);
      setValue('nationality', getNationalityByAlpha3(watchCustomer?.countryCode ?? ''));
      setValue('corporateName', watchCustomer.corporateName);
      clearErrors();
    } else {
      reset();
    }
  }, [watchCustomer]);

  return (
    <Stack
      component='form'
      onSubmit={handleSubmit(submitForm)}
      direction='column'
      display='flex'
      flex={1}
      height={{ xs: 'auto', sm: '100%' }}
      padding={{
        xs: theme.spacing(4),
        md: theme.spacing(0),
      }}
    >
      <GridContainer container rowSpacing={6} columnSpacing={5}>
        <Grid item xs={12}>
          <Box display='flex'>
            <InfoOutlinedIcon fontSize='small' sx={{ mr: '8px' }} />

            <Typography variant='smallRegular' color={theme.palette.colors.gray[600]} lineHeight='normal'>
              {t('customer_form.voucher_info')}{' '}
              <Link onClick={() => setOpenVoucherModel(true)}>{t('customer_form.voucher_info_link')} </Link>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Controller
            name='customer'
            control={control}
            render={({ field: { name, onChange, value, ...rest } }) => (
              <Select
                fullWidth
                name={name}
                loading={loadingCustomers}
                options={customers}
                onChange={(_, value) => onChange(value as Customer)}
                value={value}
                popupIcon={<SearchMd style={{ color: theme.palette.colors.gray[400] }} />}
                label={t('customer_form.customers')}
                placeholder={t('customer_form.customers_placeholder')}
                getOptionLabel={(option) => getCustomerLabel(option as Customer)}
                getOptionKey={(option) => (option as Customer).id}
                ListboxProps={{
                  onScroll: (event: React.SyntheticEvent) => {
                    const listboxNode = event.currentTarget;
                    if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                      loadMoreCustomers();
                    }
                  },
                }}
                onInputChange={(_, value) => {
                  onCustomersSearchChange(value);
                }}
                disabled={skipConsumerForm}
                slotProps={{
                  popupIndicator: {
                    sx: {
                      transition: 'none',
                      transform: 'none',
                    },
                  },
                }}
                {...rest}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Input
            {...register('firstName')}
            fullWidth
            required
            disabled={!!watchCustomer || skipConsumerForm}
            label={t('customer_form.first_name')}
            error={!!errors['firstName']}
            helperText={errors['firstName']?.message as string}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Input
            {...register('lastName')}
            fullWidth
            required
            disabled={!!watchCustomer || skipConsumerForm}
            label={t('customer_form.last_name')}
            error={!!errors['lastName']}
            helperText={errors['lastName']?.message as string}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Input
            {...register('email')}
            fullWidth
            required
            disabled={!!watchCustomer || skipConsumerForm}
            label={t('customer_form.email')}
            error={!!errors['email']}
            helperText={errors['email']?.message as string}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name='nationality'
            control={control}
            render={({ field: { name, onChange, value, ...rest } }) => (
              <NationalitySelector
                required
                fullWidth
                name={name}
                value={value}
                label={t('customer_form.nationality')}
                disabled={skipConsumerForm || !!watchCustomer}
                error={!!errors[name]}
                helperText={errors[name]?.message as string}
                onChange={(_, value) => {
                  onChange(value);
                  handleChangeNationality(value as CountryData);
                }}
                placeholder={t('customer_form.nationality_placeholder')}
                {...rest}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Stack>
            <Input
              {...register('identity')}
              name='identity'
              fullWidth
              required
              disabled={!!watchCustomer || skipConsumerForm}
              label={t(`customer_form.${documentLabelKey}`)}
              mask={isForeignIdentity ? undefined : 'cpf_cnpj'}
              error={!!errors['identity']}
              helperText={errors['identity']?.message as string}
            />

            {shouldDisplayIdentityType ? (
              <Controller
                name='identityType'
                control={control}
                render={({ field: { onChange, value, name, ...rest } }) => (
                  <RadioButtonsGroup
                    required
                    id='type'
                    name={name}
                    value={value || ''}
                    onChange={onChange}
                    error={!!errors[name]}
                    helperText={errors[name]?.message as string}
                    row
                    options={[
                      {
                        value: IdentityTypeEnum.Identity,
                        label: t('customer_form.foreign_identity_type_label'),
                        slotProps: { typography: { variant: 'smallRegular', color: theme.palette.colors.gray[700] } },
                        size: 'small',
                      },
                      {
                        value: IdentityTypeEnum.Passport,
                        label: t('customer_form.passport'),
                        slotProps: { typography: { variant: 'smallRegular', color: theme.palette.colors.gray[700] } },
                        size: 'small',
                      },
                    ]}
                    {...rest}
                  />
                )}
              />
            ) : null}
          </Stack>
        </Grid>

        {!isForeignIdentity && cnpj.isValid(watchIdentity ?? '') ? (
          <Grid item xs={12} md={6}>
            <Controller
              name='corporateName'
              control={control}
              render={({ field: { name, ...rest } }) => (
                <Input
                  name={name}
                  fullWidth
                  required
                  disabled={!!watchCustomer || skipConsumerForm}
                  label={t(`customer_form.corporate_name`)}
                  error={!!errors[name]}
                  helperText={errors[name]?.message as string}
                  {...rest}
                />
              )}
            />
          </Grid>
        ) : null}

        <Grid item xs={12} md={!isForeignIdentity && cnpj.isValid(watchIdentity ?? '') ? 12 : 6}>
          <Controller
            name='phone'
            control={control}
            render={({ field: { name, onChange, value = '', ...rest } }) => (
              <PhoneInput
                fullWidth
                id={name}
                disabled={!!watchCustomer || skipConsumerForm}
                label={t('customer_form.phone')}
                required
                error={!!errors[`${name}`]}
                helperText={errors[`${name}`]?.message}
                onChange={(value) => onChange(value ?? '')}
                value={value}
                {...rest}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Checkbox
            color='primary'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSkipConsumerForm(event.target.checked);
              clearErrors();
              reset();
            }}
            label={t('customer_form.skip_customer_form')}
            checked={skipConsumerForm}
          />
        </Grid>
      </GridContainer>

      <ButtonsWrapper>
        {watchCustomer || skipConsumerForm ? (
          <Button
            variant='contained'
            startIcon={<ArrowRight />}
            fullWidth={isMobile}
            onClick={() => {
              submitWithoutValidation();
            }}
          >
            {t('customer_form.submit')}
          </Button>
        ) : (
          <Button variant='contained' type='submit' startIcon={<ArrowRight />} fullWidth={isMobile}>
            {t('customer_form.submit')}
          </Button>
        )}
      </ButtonsWrapper>

      <ModalOnDesktopDrawerOnMobile
        width={514}
        onClose={() => setOpenVoucherModel(false)}
        title={
          <Box display='flex' alignItems='flex-start'>
            <IconTicket size='32px' color={theme.palette.primary.main} style={{ marginRight: '16px' }} />
            {t('customer_form.voucher_modal.title')}
          </Box>
        }
        isOpen={openVoucherModel}
      >
        <VoucherImage src={VoucherModel} />

        <Typography variant='extraSmallRegular' color={theme.palette.colors.gray[800]}>
          {t('customer_form.voucher_modal.observation')}
        </Typography>
      </ModalOnDesktopDrawerOnMobile>
    </Stack>
  );
};

export default CustomerForm;
