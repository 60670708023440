import syncStateWithStorageAndUrl from '@/features/config/context/utils/syncStateWithStorageAndUrl';
import updateStateAndPersist from '@/features/config/context/utils/updateStateAndPersist';
import { ConfigContextStore } from '@/features/config/types/config';
import { useMemo, useState } from 'react';

type ProxyContext<T> = ConfigContextStore<T> & {
  [key: string]: unknown;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const processResult = <T>(result: any, context: ProxyContext<T>) => {
  if (result && context.keys) {
    Object.keys(result).forEach((key) => {
      const keyIndex = context.keys.findIndex((k) => k.urlKey === key);
      if (keyIndex !== -1 && context.keys[keyIndex].formatFn) {
        result[key] = context.keys[keyIndex].formatFn(result[key]);
      }
    });
  }
};

export const usePersistenceProxy = <T>(context: ProxyContext<T>) => {
  const { localStorageKey, keys, ...rest } = context;
  const [state, setState] = useState(rest);

  const proxyHandler = {
    get: (context: ProxyContext<T>, prop: string) => {
      const syncedResult = syncStateWithStorageAndUrl({
        localStorageKey,
        keys,
      });
      const result = syncedResult.state[prop];
      if (result && context.keys) {
        processResult(result, context);
      }

      const newState = {
        ...context,
        [prop]:
          typeof context[prop] === 'function'
            ? context[prop]
            : {
                ...(typeof context[prop] === 'object' && context[prop] !== null ? context[prop] : {}),
                ...(typeof result === 'object' && result !== null ? result : {}),
              },
      };

      const reflect = Reflect.get(newState, prop);
      return reflect;
    },
    set(context: ProxyContext<T>, prop: string, value: unknown) {
      const resultString = updateStateAndPersist({
        localStorageKey,
        keys,
        newValue: { state: { ...(context as T), [prop]: value } },
      });

      const result = resultString.state as object;
      setState({ ...context, ...result });
      return true;
    },
  };

  return useMemo(() => new Proxy(context, proxyHandler) as T, [state, setState]);
};
