import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import IndeterminateCheckboxGroup from '@/components/IndeterminateCheckboxGroup';
import { Fragment } from 'react';
import Checkbox from '@/components/Form/Checkbox';
import ComposedDatePicker from '@/components/ComposedDatePicker';
import { formatISO, isValid } from 'date-fns';
import useFilter from '@/hooks/useFilter';
import Select from '@/components/Form/Select';
import { FilterOption } from '@/contexts/FilterContext';

const FilterFields = () => {
  const { selectedFilters, handleChangeFilters, schema } = useFilter();

  const { t } = useTranslation(['sales']);

  const dateParser = (value?: string | null) => {
    if (!value) return null;

    return new Date(value);
  };

  const isOptionEqualToValue = (option: FilterOption, currentOption: FilterOption) =>
    option.value === currentOption.value;

  const onSelectChange = (filterKey: string, values: FilterOption[]) => {
    handleChangeFilters(
      filterKey,
      values.map((status) => status.value),
    );
  };

  const mapValuesArray = (key: string) => {
    return (
      (selectedFilters?.[key] as string[])?.map(
        (status) => schema![key].options!.find((option) => option.value === status)!,
      ) ?? []
    );
  };

  return (
    <Fragment>
      <Box display='flex' flexDirection='column' gap={{ xs: 1, md: 2 }}>
        <Typography variant='regularBold'>{t('status.title')}</Typography>

        <IndeterminateCheckboxGroup
          label={t('status.all')}
          options={schema!.currentState.options!}
          values={mapValuesArray('currentState')}
          onChange={(values) => onSelectChange('currentState', values)}
        />
      </Box>

      <Box display='flex' flexDirection='column' gap={{ xs: 1, md: 2 }}>
        <Typography variant='regularBold'>{t('filter.refund_request.title')}</Typography>

        {schema!.refundRequestStatus.options?.map((option) => (
          <Checkbox
            key={option.value}
            color='primary'
            onChange={({ target }) => {
              handleChangeFilters('refundRequestStatus', target.checked ? [option.value] : undefined);
            }}
            label={option.label}
            checked={selectedFilters?.refundRequestStatus?.includes(option.value)}
          />
        ))}
      </Box>

      <ComposedDatePicker
        since={dateParser(selectedFilters?.since as string)}
        until={dateParser(selectedFilters?.until as string)}
        onChange={(date, key) => {
          if (isValid(date)) {
            handleChangeFilters(key, formatISO(date as Date));
          }
        }}
        slotProps={{
          wrapper: { gap: 2 },
          since: { label: t('filter.sale_date_interval.since.label') },
          until: { label: t('filter.sale_date_interval.until.label') },
        }}
      />

      <Select
        fullWidth
        openOnFocus
        multiple
        loading={schema!.id_sale_origin.loading}
        options={schema!.id_sale_origin.options ?? []}
        label={t('filter.saleOriginIds.label')}
        getOptionLabel={(option) => (option as FilterOption).label}
        filterSelectedOptions
        isOptionEqualToValue={(option, currentOption) =>
          isOptionEqualToValue(option as FilterOption, currentOption as FilterOption)
        }
        onChange={(_, value) => onSelectChange('id_sale_origin', value as FilterOption[])}
        value={mapValuesArray('id_sale_origin')}
      />

      <Box display='flex' flexDirection='column' gap={{ xs: 1, md: 2 }}>
        <Typography variant='regularBold'>{t('filter.sources.title')}</Typography>

        {schema!.sources.options?.map((option) => (
          <Checkbox
            key={option.value}
            color='primary'
            onChange={({ target }) => {
              handleChangeFilters('sources', target.checked ? [option.value] : undefined);
            }}
            label={option.label}
            checked={selectedFilters?.sources?.includes(option.value)}
          />
        ))}

        <Select
          fullWidth
          openOnFocus
          multiple
          loading={schema!.tagIds.loading}
          options={schema!.attributes.options ?? []}
          label={t('filter.integration.title')}
          getOptionLabel={(option) => (option as FilterOption).label}
          filterSelectedOptions
          isOptionEqualToValue={(option, currentOption) =>
            isOptionEqualToValue(option as FilterOption, currentOption as FilterOption)
          }
          onChange={(_, value) => onSelectChange('attributes', value as FilterOption[])}
          value={mapValuesArray('attributes')}
        />

        <Select
          fullWidth
          openOnFocus
          multiple
          loading={schema!.tagIds.loading}
          options={schema!.tagIds.options ?? []}
          label={t('filter.tags.title')}
          getOptionLabel={(option) => (option as FilterOption).label}
          filterSelectedOptions
          isOptionEqualToValue={(option, currentOption) =>
            isOptionEqualToValue(option as FilterOption, currentOption as FilterOption)
          }
          onChange={(_, value) => onSelectChange('tagIds', value as FilterOption[])}
          value={mapValuesArray('tagIds')}
        />

        <Select
          fullWidth
          openOnFocus
          multiple
          loading={schema!.couponIds.loading}
          options={schema!.couponIds.options ?? []}
          label={t('filter.couponIds.title')}
          getOptionLabel={(option) => (option as FilterOption)?.label ?? ''}
          getOptionKey={(option) => (option as FilterOption)?.value ?? ''}
          filterSelectedOptions
          isOptionEqualToValue={(option, currentOption) =>
            isOptionEqualToValue(option as FilterOption, currentOption as FilterOption)
          }
          onChange={(_, value) => onSelectChange('couponIds', value as FilterOption[])}
          value={mapValuesArray('couponIds')}
        />
      </Box>
    </Fragment>
  );
};

export default FilterFields;
