import { Box, styled } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '44px',
  padding: '10px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  borderRadius: '360px',
  border: '2px solid ' + theme.palette.colors.blue[100],
}));
