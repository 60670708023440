import {
  Additional,
  AvailabilityTypeEnum,
  DetailedTariffGroup,
  Product,
  ProductsApiGetProductAdditionalsRequest,
  ProductsApiGetProductDetailedTariffGroupsRequest,
  ProductsApiGetProductRelatedTariffTypesRequest,
  ProductTariffType,
  Tariff,
  TariffType,
} from '@/services/SellerApi';
import { useMemo } from 'react';
import { Control, Controller, FormState } from 'react-hook-form';
import useRouteQueryParams from '@/hooks/useRouteQueryParams';
import { CreateUpdateAvailabilityParams } from '@/features/agenda/features/availability/types/formTypes';
import ProductItemPicker, { ProductWithItems } from './ProductItemPicker';
import { t } from 'i18next';
import { enqueueSnackbar } from 'notistack';
import { useSellerApi } from '@/hooks/useSellerApi';
import AdditionalCard from './AdditionalCard';
import ProductTariffTypeCard from './ProductTariffTypeCard';
import TariffCard from './TariffCard';
import ProductPicker from './ProductPicker';

type Props = {
  availabilityType?: AvailabilityTypeEnum;
  control: Control<CreateUpdateAvailabilityParams, unknown>;
  formState: FormState<CreateUpdateAvailabilityParams>;
  isEdit?: boolean;
};

export const AvailabilityTypePickers = (params: Props) => {
  const { availabilityType, control, formState, isEdit } = params;
  const { errors } = formState;

  const { searchParams } = useRouteQueryParams();
  const { productsApi } = useSellerApi();

  const queryProductIds = useMemo(() => searchParams.get('productIds')?.split(',') ?? [], [searchParams]);

  const getProductAdditionals = async (productId: string) => {
    const payload: ProductsApiGetProductAdditionalsRequest = {
      productId: productId,
      include: ['product'],
    };

    try {
      let page = 0;
      const limit = 100;
      let keepRunning = true;

      const additionals: Additional[] = [];

      while (keepRunning) {
        const response = await productsApi.getProductAdditionals({
          ...payload,
          limit,
          offset: page * limit,
        });

        additionals.push(...response.data);
        page++;

        const totalOfAdditionals = Number(response.headers['x-pagination-total-count'] ?? '0');

        if (additionals.length >= totalOfAdditionals) {
          keepRunning = false;
        }
      }

      return additionals;
    } catch (error) {
      enqueueSnackbar(t('could_not_load_product_additionals'), { variant: 'error' });
    }
  };

  const productHasSelectedAdditionals = (product: Product, selectedItems: Additional[]) => {
    return selectedItems.some((additional) => additional.productId === product.id);
  };

  const getProductTariffGroups = async (productId: string) => {
    const payload: ProductsApiGetProductDetailedTariffGroupsRequest = {
      productId: productId,
      include: ['tariffs.type', 'tariffs.product'],
    };

    try {
      let page = 0;
      const limit = 100;
      let keepRunning = true;

      const tariffGroups: DetailedTariffGroup[] = [];

      while (keepRunning) {
        const response = await productsApi.getProductDetailedTariffGroups({
          ...payload,
          limit,
          offset: page * limit,
        });

        tariffGroups.push(...response.data);
        page++;

        const totalOfTariffs = Number(response.headers['x-pagination-total-count'] ?? '0');

        if (tariffGroups.length >= totalOfTariffs) {
          keepRunning = false;
        }
      }

      return tariffGroups;
    } catch (error) {
      enqueueSnackbar(t('could_not_load_product_tariffs_groups'), { variant: 'error' });
    }
  };

  const productHasSelectedTariffs = (
    product: ProductWithItems<Tariff, DetailedTariffGroup>,
    selectedItems: Tariff[],
  ) => {
    const selectedTariffIds = selectedItems.map((selectedTariff) => selectedTariff.id);
    return (product.items as DetailedTariffGroup[]).some((tariffGroup) =>
      tariffGroup.tariffs.some((tariff) => selectedTariffIds.includes(tariff.id)),
    );
  };

  const getProductTariffTypes = async (productId: string) => {
    const payload: ProductsApiGetProductRelatedTariffTypesRequest = {
      productId: productId,
    };

    try {
      let page = 0;
      const limit = 100;
      let keepRunning = true;

      const tariffTypes: TariffType[] = [];

      while (keepRunning) {
        const response = await productsApi.getProductRelatedTariffTypes({
          ...payload,
          limit,
          offset: page * limit,
        });

        tariffTypes.push(...response.data);
        page++;

        const totalOfTariffTypes = Number(response.headers['x-pagination-total-count'] ?? '0');

        if (tariffTypes.length >= totalOfTariffTypes) {
          keepRunning = false;
        }
      }

      return tariffTypes;
    } catch (error) {
      enqueueSnackbar(t('could_not_load_product_tariff_types'), { variant: 'error' });
    }
  };

  const productHasSelectedTariffTypes = (product: Product, selectedItems: ProductTariffType[]) =>
    selectedItems.some((productTariffType) => productTariffType.productId === product.id);

  const getSelectorForType = (selectedType: AvailabilityTypeEnum) => {
    switch (selectedType) {
      case AvailabilityTypeEnum.App:
        return null;
      case AvailabilityTypeEnum.Products:
        return (
          <Controller
            name='products'
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <ProductPicker
                onChange={onChange}
                value={value ?? undefined}
                error={!!errors[name]}
                defaultProductIds={queryProductIds ?? []}
                helperText={errors[name]?.message as string}
                disabled={isEdit}
              />
            )}
          />
        );
      case AvailabilityTypeEnum.Additionals:
        return (
          <Controller
            name='additionals'
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <ProductItemPicker
                key={AvailabilityTypeEnum.Additionals}
                onChange={onChange}
                value={value}
                error={!!errors[name]}
                helperText={errors[name]?.message as string}
                disabled={isEdit}
                getItemProductId={(additional) => additional.productId}
                getProductItems={(productId) => getProductAdditionals(productId)}
                productHasSelectedItems={(product, selectedItems) =>
                  productHasSelectedAdditionals(product, selectedItems)
                }
                isItemEquals={(item1, item2) => item1.id === item2.id}
                itemCard={({ items, selectedItems, onClick, ...rest }) => (
                  <AdditionalCard
                    additionals={items}
                    selectedAdditionals={selectedItems}
                    onClick={(additional, product) => onClick(additional, product)}
                    {...rest}
                  />
                )}
              />
            )}
          />
        );
      case AvailabilityTypeEnum.Tariffs:
        return (
          <Controller
            name='tariffs'
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <ProductItemPicker
                key={AvailabilityTypeEnum.Tariffs}
                onChange={onChange}
                value={value}
                error={!!errors[name]}
                helperText={errors[name]?.message as string}
                disabled={isEdit}
                getItemProductId={(tariff) => tariff.productId}
                getProductItems={(productId) => getProductTariffGroups(productId)}
                productHasSelectedItems={(product, selectedItems) => productHasSelectedTariffs(product, selectedItems)}
                isItemEquals={(item1, item2) => item1.id === item2.id}
                itemCard={({ items, selectedItems, onClick, ...rest }) => (
                  <TariffCard
                    tariffGroups={items}
                    selectedTariffs={selectedItems}
                    onClick={(tariff, product) => onClick(tariff, product)}
                    {...rest}
                  />
                )}
              />
            )}
          />
        );
      case AvailabilityTypeEnum.ProductTariffTypes:
        return (
          <Controller
            name='productTariffTypes'
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <ProductItemPicker
                key={AvailabilityTypeEnum.ProductTariffTypes}
                onChange={onChange}
                value={value}
                error={!!errors[name]}
                helperText={errors[name]?.message as string}
                disabled={isEdit}
                getItemProductId={(productTariffType) => productTariffType.productId}
                isItemEquals={(item1, item2) =>
                  item1.tariffTypeId === item2.tariffTypeId && item1.productId === item2.productId
                }
                getProductItems={(productId) => getProductTariffTypes(productId)}
                productHasSelectedItems={(product, selectedItems) =>
                  productHasSelectedTariffTypes(product, selectedItems)
                }
                itemCard={({ items, selectedItems, onClick, product, ...rest }) => (
                  <ProductTariffTypeCard
                    product={product}
                    tariffTypes={items}
                    selectedProductTariffTypes={selectedItems}
                    onClick={(productTariffType, product) => onClick(productTariffType, product)}
                    {...rest}
                  />
                )}
              />
            )}
          />
        );
    }
  };

  return availabilityType ? getSelectorForType(availabilityType) : null;
};

export default AvailabilityTypePickers;
