import { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { Outlet, useParams } from 'react-router-dom';
import { Grid, Typography, useMediaQuery, Link, Box } from '@mui/material';

import theme from '@/theme';
import PageLayout from '@/layouts/PageLayout';
import EmptyPage from '@/components/EmptyPage';
import { useSellerApi } from '@/hooks/useSellerApi';
import { CoinsStacked03, File02, List, Ticket01 } from '@untitled-ui/icons-react';
import { SaleStateEnum, VouchersApiSendSaleVouchersToCustomerRequest } from '@/services/SellerApi';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import { enqueueSnackbar } from 'notistack';
import Bugsnag from '@/services/bugsnag';
import { NotifiableError } from '@bugsnag/js';
import Loading from '@/components/Loading';
import useAccount from '@/hooks/useAccount';
import Tooltip from '@/components/Tooltip';
import useActiveTabInRoute from '@/hooks/useActiveTabInRoute';
import Tabs, { TabProps } from '@/components/Tabs';
import SaleDetail from '@/features/sales/components/SaleDetail';
import SaleDetailContextProvider from '@/features/sales/context/saleDetailContext';
import { useSaleDetails } from '@/features/sales/hook/useSaleDetails';

export const SalesDetailsPage = () => {
  const { t } = useTranslation(['saleDetails']);
  const { saleId } = useParams<{ saleId: string }>();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { track } = useAnalytics();
  const { hasPermission } = useAccount();
  const { vouchersApi } = useSellerApi();

  const activeTab = useActiveTabInRoute(['', 'vouchers', 'receipt', 'income-schedulings']);

  const { sale, loading, refetchSale, saleItemsData } = useSaleDetails();

  const [sendingVoucher, setSendingVoucher] = useState(false);

  const sendSaleVoucher = async (saleId: string) => {
    setSendingVoucher(true);

    const payload: VouchersApiSendSaleVouchersToCustomerRequest = {
      saleId,
    };

    try {
      await vouchersApi.sendSaleVouchersToCustomer(payload);

      enqueueSnackbar(t('snackbars.vouchers_sent'), { variant: 'success' });

      track('salesDetails/sendVouchersToCustomerSuccess', { payload });
    } catch (error) {
      enqueueSnackbar(t('snackbars.could_not_send_vouchers'), { variant: 'error' });

      track('salesDetails/sendVouchersToCustomerFail', { payload, error });

      Bugsnag.notify(error as NotifiableError);
    } finally {
      setSendingVoucher(false);
    }
  };

  const renderTabLabel = (label: string, tooltip?: string, permission?: boolean) => {
    const renderLabel = () => (
      <Typography component='p' textTransform='none' variant='regularRegular'>
        {label}
      </Typography>
    );

    return permission ? (
      <Tooltip width={210} title={tooltip}>
        {renderLabel()}
      </Tooltip>
    ) : (
      renderLabel()
    );
  };

  const tabsDefaultProps: TabProps = {
    component: Link,
    iconPosition: 'start',
    sx: { whiteSpace: 'nowrap' },
  };

  const tabs: Array<TabProps> = [
    {
      ...tabsDefaultProps,
      value: '',
      icon: <List />,
      href: `../sales/${saleId}`,
      label: renderTabLabel(t('page_layout.tabs.items')),
    },
    {
      ...tabsDefaultProps,
      value: 'vouchers',
      icon: <Ticket01 />,
      href: `../sales/${saleId}/vouchers`,
      disabled: !hasPermission('vouchers', 'GetSaleVouchers'),
      label: renderTabLabel(
        t('page_layout.tabs.vouchers'),
        t('forbidden_tooltip'),
        !hasPermission('vouchers', 'GetSaleVouchers'),
      ),
    },
    {
      ...tabsDefaultProps,
      icon: <File02 />,
      value: 'receipt',
      href: `../sales/${saleId}/receipt`,
      disabled: !hasPermission('sales', 'GetSale'),
      label: renderTabLabel(t('page_layout.tabs.receipt'), t('forbidden_tooltip'), !hasPermission('sales', 'GetSale')),
    },
    {
      ...tabsDefaultProps,
      icon: <CoinsStacked03 />,
      value: 'income-schedulings',
      href: `../sales/${saleId}/income-schedulings`,
      disabled: !hasPermission('incomeSchedulings', 'GetSaleIncomeSchedulings'),
      label: renderTabLabel(
        t('page_layout.tabs.income_schedulings'),
        t('forbidden_tooltip'),
        !hasPermission('incomeSchedulings', 'GetSaleIncomeSchedulings'),
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>

      <PageLayout
        showGoBackButton
        showSearchInput={false}
        showFilterButton={false}
        title={t('page_layout.title', { code: sale ? `#${sale.code}` : undefined })}
        actions={{
          ...(sale &&
            sale.currentState === SaleStateEnum.PaymentComplete && {
              primary: {
                disabled:
                  !sale ||
                  sale.isExternal ||
                  sendingVoucher ||
                  !hasPermission('vouchers', 'SendSaleVouchersToCustomer'),
                label: t('page_layout.send_voucher_to_client'),
                title: t('page_layout.send_voucher_to_client'),
                onClick: () => sendSaleVoucher(sale?.id ?? ''),
                Icon: <MailOutlinedIcon sx={{ flexShrink: 0 }} />,
              },
            }),
        }}
        breadcrumbs={[
          {
            label: t('page_layout.breadcrumbs.sales'),
            to: '../sales',
          },
          {
            label: t('page_layout.breadcrumbs.sale_details', { code: sale ? `#${sale.code}` : undefined }),
            to: '.',
          },
        ]}
      >
        <Grid container paddingX={{ xs: 0, sm: 4, md: 6 }} paddingY={{ xs: 2, md: 4 }} sx={{ mt: -2 }}>
          <Grid item xs={12}>
            {loading ? <Loading /> : null}

            {!loading && saleId && sale ? (
              <Fragment>
                <SaleDetail sale={sale} saleItemsData={saleItemsData} refresh={refetchSale} />

                <Box sx={{ borderBottom: 1, borderColor: theme.palette.colors.gray[200] }}>
                  <Tabs
                    tabs={tabs}
                    root={{
                      value: activeTab,
                      scrollButtons: 'auto',
                      allowScrollButtonsMobile: true,
                      variant: isMobile ? 'scrollable' : 'fullWidth',
                    }}
                  />
                </Box>

                <Box sx={{ marginTop: 6 }}>
                  <Outlet />
                </Box>
              </Fragment>
            ) : null}

            {!loading && !sale ? (
              <EmptyPage
                onClick={refetchSale}
                description={t('empty.no_sale_found')}
                buttonText={t('empty.no_sale_found_button')}
              />
            ) : null}
          </Grid>
        </Grid>
      </PageLayout>
    </>
  );
};

const Wrapper = () => {
  return (
    <SaleDetailContextProvider>
      <SalesDetailsPage />
    </SaleDetailContextProvider>
  );
};

export default Wrapper;
