import { useTranslation } from 'react-i18next';
import { Alert, Grid } from '@mui/material';
import { Message } from './styles';
import { CheckCircleOutline, InfoOutlined } from '@mui/icons-material';
import Button from '@/components/Button';
import useAccount from '@/hooks/useAccount';
import { useSellerApi } from '@/hooks/useSellerApi';
import { downloadBlobFile } from '@/utils';

type Props = {
  saleId: string;
  missingPaymentCount: number;
  onFinishPurchases?: () => void;
};

export const PaymentSuccessMessage = (props: Props) => {
  const { saleId, missingPaymentCount, onFinishPurchases = () => {} } = props;

  const { vouchersApi } = useSellerApi();

  const { t } = useTranslation(['showCaseCheckout']);
  const { selectedAccount } = useAccount();

  const getAlertSeverity = () => {
    if (props.missingPaymentCount > 0) {
      return 'warning';
    }

    return 'success';
  };

  const handleFinishPurchases = () => {
    onFinishPurchases();
  };

  const handleDownloadVoucher = async () => {
    const { data } = await vouchersApi.getDirectSaleVouchersPdf({ saleId }, { responseType: 'blob' });

    downloadBlobFile(data, 'voucher.pdf');
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Message dangerouslySetInnerHTML={{ __html: t('success.message') }} />
      </Grid>

      <Grid item xs={12}>
        <Alert
          severity={getAlertSeverity()}
          iconMapping={{
            success: <CheckCircleOutline fontSize='inherit' />,
            warning: <InfoOutlined fontSize='inherit' />,
          }}
          sx={{ mb: '24px' }}
        >
          {props.missingPaymentCount > 0 ? (
            <>{t('success.pending_purchases', { number: missingPaymentCount })}</>
          ) : (
            <>{t('success.all_finished')}</>
          )}
        </Alert>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            {props.missingPaymentCount > 0 ? (
              <Button variant='contained' onClick={() => handleFinishPurchases()}>
                {t('success.finish_purchases')}
              </Button>
            ) : (
              <Button variant='contained' href={`/${selectedAccount?.app?.code}/partnership/directSales`}>
                {t('success.my_purchases')}
              </Button>
            )}
          </Grid>

          <Grid item>
            <Button variant='outlined' onClick={() => handleDownloadVoucher()}>
              {t('success.download_voucher')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaymentSuccessMessage;
