import { styled } from '@mui/material';

import { colors } from '@/theme';

const StyledList = styled('ul')(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  listStyleType: 'disc',
  margin: 0,
}));

const StyledListItem = styled('li')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  listStyleType: 'disc',
  gap: theme.spacing(1),
  '&::before': {
    content: '""',
    display: 'inline-block',
    width: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: '50%',
    backgroundColor: colors.error[500],
    marginRight: theme.spacing(1),
  },
}));

export { StyledList, StyledListItem };
