import { format } from 'date-fns';
import { Fragment, useMemo } from 'react';
import { Box, Grid, Hidden, Link, Stack, Typography, useMediaQuery } from '@mui/material';
import { get } from 'lodash';

import theme from '@/theme';
import {
  Card,
  CardContent,
  CardContentInfo,
  CardMedia,
  FlexRow,
  ProducNameAndDateWrapper,
  StyledImage,
  StyledImageWrapper,
  slotProps,
} from './style';
import { dateWithTimezone, formatCents } from '@/utils';
import Button from '@/components/Button';
import {
  PublicPartnershipItem,
  AssociationModel,
  DetailedSaleItem,
  PartnershipCommissionType,
  Sale,
  SaleAdditional,
  SaleStateEnum,
  SellingMode,
} from '@/services/SellerApi';
import { useTranslation } from 'react-i18next';
import ValueWithLabel from '@/components/ValueWithLabel';
import { StyledChip } from '@/components/Partnership/StatusChip/style';
import { CalendarCheck01 } from '@untitled-ui/icons-react';
import Chip from '@/components/Chip';

import Pagination from '@/components/Pagination';
import Loading from '@/components/Loading';
import { PaginationContainer } from '@/components/Partnership/PartnershipDetail/PartnershipCouponList/styles';
import emptyImage from '@/assets/Icons/empty-image.svg';
import { SaleItemsData } from '@/features/sales/context/saleDetailContext';
import { IconCalendarClock } from '@tabler/icons-react';
import { getReservationDateTime } from '@/features/reservation/utils/getReservationDateTime';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { getSaleSource } from '@/common/sale';
import { precisePercentageMultiplication } from '@/common/partnership';

interface SaleItemsProps {
  sale: Sale;
  saleItemsData: SaleItemsData;
}

export const SaleItems = (props: SaleItemsProps) => {
  const { sale, saleItemsData } = props;
  const { t } = useTranslation(['saleDetails']);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { isFeatureEnabled } = useFeatureFlag();

  const { data, isLoading: loading, paginationModel, setPaginationModel } = saleItemsData;
  const { data: saleItems = [], totalSaleItems = 0 } = data ?? {};

  const saleSource = useMemo(() => getSaleSource(sale, saleItems), [sale, saleItems]);

  const formattedSaleItemInfoBasedOnThePath = (array: unknown[], key: string, additionalKey: string) => {
    const items: unknown[] = [];

    array.forEach((item) => {
      const mainData = get(item, key);
      const additionalData = get(item, additionalKey);

      if (!mainData) {
        return;
      }

      items.push(get(item, key) + (additionalData ? `(${additionalData})` : ''));
    });

    return items.join(', ');
  };

  const getPartnershipDiscountValue = (partnershipItem: PublicPartnershipItem) => {
    if (partnershipItem.commissionType === PartnershipCommissionType.AmountCents) {
      return formatCents(partnershipItem.commissionAmountCents! / 100);
    }

    return `${precisePercentageMultiplication(partnershipItem.commissionPercentageFee!)}%`;
  };

  const getSaleItemSchedule = (saleItem: DetailedSaleItem) => {
    if (saleItem.scheduleDate && !saleItem.scheduleTime) {
      return format(dateWithTimezone(saleItem.scheduleDate), 'dd/MM/yyyy');
    }

    if (saleItem.scheduleDate && saleItem.scheduleTime) {
      return `${format(dateWithTimezone(saleItem.scheduleDate), 'dd/MM/yyyy')} - ${saleItem.scheduleTime}`;
    }

    return t('list.card.without_date');
  };

  const getAdditionalsText = (additionals: SaleAdditional[]) => {
    return additionals
      ?.map((additional) => {
        if (additional.data.inputLabel && additional.data.inputValue) {
          return `${additional.additional?.title} (${additional.data.inputLabel}: ${additional.data.inputValue}) (${additional.unitQuantity})`;
        }

        return `${additional.additional?.title} (${additional.unitQuantity})`;
      })
      .join(' • ');
  };

  const isRescheduleDisabled = (saleItem: DetailedSaleItem) => {
    const reservationDate = getReservationDateTime(saleItem.reservation!);

    if (!reservationDate) {
      return true;
    }

    return (
      saleItem.product?.sellingMode === SellingMode.WithoutDate || sale.currentState !== SaleStateEnum.PaymentComplete
    );
  };

  return (
    <Grid container spacing={3} mb={2}>
      {!loading && saleItems.length ? (
        <Fragment>
          <Grid item xs={12}>
            <FlexRow width='100%' gap={1}>
              <Typography variant='h6'>{t('list.itens_quantity')}</Typography>

              <Chip
                size='small'
                variant='outlined'
                label={totalSaleItems}
                color={{
                  font: theme.palette.colors.blue[500],
                  background: theme.palette.colors.blue[100],
                }}
              />
            </FlexRow>
          </Grid>

          {saleItems.map((saleItem) => (
            <Grid item xs={12} key={saleItem.id}>
              <Card elevation={3}>
                <CardMedia>
                  <StyledImageWrapper>
                    <StyledImage
                      hasNoImage={!saleItem.product?.thumbnailUrl}
                      src={saleItem.product?.thumbnailUrl ?? emptyImage}
                    />
                  </StyledImageWrapper>
                </CardMedia>

                <CardContent>
                  <CardContentInfo>
                    <ProducNameAndDateWrapper>
                      <Stack>
                        <Typography variant='largeBold' color={theme.palette.colors.gray[800]}>
                          {saleItem.product?.internalName ?? saleItem.product?.name}
                        </Typography>

                        {sale.currentState === SaleStateEnum.Created ||
                        sale.currentState === SaleStateEnum.PaymentComplete ? (
                          <Hidden smUp>
                            <Box>
                              <StyledChip size='small' label={getSaleItemSchedule(saleItem)} color='info' />
                            </Box>
                          </Hidden>
                        ) : null}
                      </Stack>

                      {sale.currentState === SaleStateEnum.Created ||
                      sale.currentState === SaleStateEnum.PaymentComplete ? (
                        <FlexRow gap={{ xs: 1, lg: 2 }} flexWrap='wrap' justifyContent='flex-end'>
                          <Hidden smDown>
                            <StyledChip size='small' label={getSaleItemSchedule(saleItem)} color='info' />
                          </Hidden>
                        </FlexRow>
                      ) : null}
                    </ProducNameAndDateWrapper>

                    <Box display='flex' flexDirection='column' gap={1}>
                      <ValueWithLabel
                        slotProps={slotProps}
                        title={t('list.card.tariff')}
                        value={formattedSaleItemInfoBasedOnThePath(saleItem.tariffs, 'tariff.type.name', 'quantity')}
                      />

                      {saleItem.resources?.length ? (
                        <ValueWithLabel
                          slotProps={slotProps}
                          title={t('list.card.place')}
                          value={formattedSaleItemInfoBasedOnThePath(
                            saleItem.resources,
                            'resource.name',
                            'resource.code',
                          )}
                        />
                      ) : null}

                      {saleItem.additionals?.length ? (
                        <ValueWithLabel
                          slotProps={slotProps}
                          title={t('list.card.additionals')}
                          value={getAdditionalsText(saleItem.additionals)}
                        />
                      ) : null}

                      {saleSource.source !== AssociationModel.Resale &&
                      saleItem.partnershipItem?.id &&
                      saleItem.partnershipAgent ? (
                        <Stack direction={{ xs: 'column', md: 'row' }} gap={{ xs: 1, md: 2 }}>
                          <ValueWithLabel
                            slotProps={slotProps}
                            title={t(`list.card.${sale.agentId ? 'agent' : 'affiliate'}`)}
                            value={
                              <Link href={`../partnership/${saleItem.partnershipItem.partnershipId}`}>
                                {saleItem.partnershipAgent.name}
                              </Link>
                            }
                          />

                          {!sale.agentId ? (
                            <ValueWithLabel
                              slotProps={slotProps}
                              title={t('list.card.commission')}
                              value={getPartnershipDiscountValue(saleItem.partnershipItem)}
                            />
                          ) : null}
                        </Stack>
                      ) : null}
                    </Box>
                  </CardContentInfo>

                  <Stack
                    mt='auto'
                    sx={{
                      flexDirection: { xs: 'column', sm: 'row' },
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      paddingY: '0.25rem',
                    }}
                  >
                    <ValueWithLabel
                      slotProps={{
                        container: {
                          ...slotProps?.container,
                          alignItems: 'flex-end',
                          width: { xs: '100%', sm: 'auto' },
                          mb: { xs: '1rem', sm: 0 },
                        },
                        title: {
                          ...slotProps?.title,
                          variant: 'largeSemiBold',
                        },
                        value: {
                          ...slotProps?.value,
                          variant: 'h6',
                          sx: { lineHeight: '1.4' },
                        },
                      }}
                      title={t('list.card.value')}
                      value={formatCents(saleItem.priceCents)}
                    />

                    <Stack sx={{ flexDirection: 'row', gap: '0.5rem', width: { xs: '100%', sm: 'auto' } }}>
                      <Button
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        target='_blank'
                        color='primary'
                        variant='outlined'
                        href={`../reservations?highlighted_reservation_id=${saleItem.reservationId}&nd=true`}
                        startIcon={<CalendarCheck01 />}
                        fullWidth={isMobile}
                      >
                        {t('list.card.see_reservation_button')}
                      </Button>

                      {isFeatureEnabled('sale_reschedule_modal') ? (
                        <Button
                          color='primary'
                          variant='contained'
                          href={`./reservation/${saleItem.reservationId}/reschedule`}
                          startIcon={<IconCalendarClock />}
                          fullWidth={isMobile}
                          disabled={isRescheduleDisabled(saleItem)}
                        >
                          {t('list.card.reschedule')}
                        </Button>
                      ) : null}
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Fragment>
      ) : null}

      {loading ? (
        <Grid item xs={12} mt={3}>
          <Loading />
        </Grid>
      ) : null}

      {totalSaleItems > paginationModel.pageSize ? (
        <PaginationContainer item xs={12}>
          <Pagination
            count={totalSaleItems}
            page={paginationModel.page}
            rowsPerPageOptions={[5, 10, 20, 50]}
            rowsPerPage={paginationModel.pageSize}
            onRowsPerPageChange={(event) => {
              setPaginationModel({ pageSize: parseInt(event.target.value, 10), page: 0 });
            }}
            onPageChange={(_, newPage) => {
              setPaginationModel({ ...paginationModel, page: newPage });
            }}
          />
        </PaginationContainer>
      ) : null}
    </Grid>
  );
};

export default SaleItems;
