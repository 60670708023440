import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip, TooltipProps } from '@mui/material';
import { ReactNode } from 'react';

import { colors } from '@/theme';

type Props = Omit<TooltipProps, 'children' | 'title'> & { title?: ReactNode };

const TooltipInputElement = (props: Props) => {
  return (
    <Tooltip
      arrow
      enterTouchDelay={0}
      leaveTouchDelay={60000}
      title={props.title}
      PopperProps={{ style: { zIndex: 99999, minWidth: 190, textAlign: 'center' } }}
      {...props}
    >
      <HelpOutlineIcon sx={{ color: colors.gray[400], cursor: 'pointer' }} fontSize='small' />
    </Tooltip>
  );
};

export default TooltipInputElement;
