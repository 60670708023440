import { Stack, styled } from '@mui/system';

export const ErrorSectionRootStyle = styled(Stack)(({ theme }) => ({
  gap: '2rem',
  flexDirection: 'column',
  margin: 'auto',
  maxWidth: '51rem',
  [theme.breakpoints.up('sm')]: {
    gap: '5.875rem',
    flexDirection: 'row-reverse',
  },
}));

export const ErrorSectionIconWrapper = styled(Stack)(({ theme }) => ({
  maxWidth: '9.40631rem',
  alignSelf: 'center',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '15.615rem',
  },
}));

export const ErrorSectionBodyWrapper = styled(Stack)(({ theme }) => ({
  gap: '1rem',
  justifyContent: 'center',
  width: '100%',
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    gap: '1.5rem',
    textAlign: 'left',
  },
}));

export const ErrorSectionBodyActions = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));
