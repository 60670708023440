import { Box, Hidden } from '@mui/material';
import MobileMenu from '@/components/MobileMenu';
import PreviewDrawer from '@/components/PreviewDrawer';
import { useTranslation } from 'react-i18next';
import { Tag } from '@/services/SellerApi';
import Button from '@/components/Button';

type Props = {
  actions: {
    icon: JSX.Element;
    label: string;
    onClick: (tag: Tag) => void;
  }[];
  tag?: Tag;
  open: boolean;
  renderTag: (tag: Tag) => JSX.Element;
  onClose: () => void;
};
export const MobilePreview = (props: Props) => {
  const { open, onClose, actions, renderTag, tag } = props;

  const { t } = useTranslation(['sales']);

  return (
    <Hidden smUp>
      <PreviewDrawer open={open} onClose={onClose} showCloseButton={false}>
        {tag ? (
          <Box display='flex' flexDirection='column' gap={2}>
            <Box display='flex' alignItems='center' flexDirection='row' justifyContent={'space-between'}>
              {renderTag(tag)}

              <MobileMenu
                items={actions.map((action) => ({
                  icon: action.icon,
                  label: action.label,
                  onClick: () => action.onClick(tag),
                }))}
              />
            </Box>

            <Button variant='contained' onClick={onClose}>
              {t('list.table.actions.close')}
            </Button>
          </Box>
        ) : null}
      </PreviewDrawer>
    </Hidden>
  );
};

export default MobilePreview;
