/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Planne Seller API
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery
} from '@tanstack/react-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  GetSchedulingSeatMapOverviewParams,
  NotFoundResponseResponse,
  SeatMapOverview,
  UnprocessableResponseResponse
} from '../../model'
import { customInstance } from '../../mutator/custom-instance';
import type { ErrorType } from '../../mutator/custom-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const getSchedulingSeatMapOverview = (
    schedulingId: string,
    params?: GetSchedulingSeatMapOverviewParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<SeatMapOverview>(
      {url: `/schedulings/${schedulingId}/seatMapOverview`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetSchedulingSeatMapOverviewQueryKey = (schedulingId: string,
    params?: GetSchedulingSeatMapOverviewParams,) => {
    return [`/schedulings/${schedulingId}/seatMapOverview`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSchedulingSeatMapOverviewQueryOptions = <TData = Awaited<ReturnType<typeof getSchedulingSeatMapOverview>>, TError = ErrorType<NotFoundResponseResponse | UnprocessableResponseResponse>>(schedulingId: string,
    params?: GetSchedulingSeatMapOverviewParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSchedulingSeatMapOverview>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSchedulingSeatMapOverviewQueryKey(schedulingId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSchedulingSeatMapOverview>>> = ({ signal }) => getSchedulingSeatMapOverview(schedulingId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(schedulingId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSchedulingSeatMapOverview>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSchedulingSeatMapOverviewQueryResult = NonNullable<Awaited<ReturnType<typeof getSchedulingSeatMapOverview>>>
export type GetSchedulingSeatMapOverviewQueryError = ErrorType<NotFoundResponseResponse | UnprocessableResponseResponse>



export function useGetSchedulingSeatMapOverview<TData = Awaited<ReturnType<typeof getSchedulingSeatMapOverview>>, TError = ErrorType<NotFoundResponseResponse | UnprocessableResponseResponse>>(
 schedulingId: string,
    params?: GetSchedulingSeatMapOverviewParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSchedulingSeatMapOverview>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetSchedulingSeatMapOverviewQueryOptions(schedulingId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



