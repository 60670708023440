import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import BackgroundArtLayout from '@/layouts/BackgroundArtLayout';

import TermDocument from '@/components/TermDocument';
import { TermsBox, Title } from './styles';

type Props = {
  documentType: 'terms_of_use' | 'privacy_policy';
};

const TermsOfUse = (props: Props) => {
  const { documentType } = props;

  const { t } = useTranslation(['terms']);

  return (
    <>
      <Helmet>
        <title>{t(documentType)}</title>
      </Helmet>

      <BackgroundArtLayout>
        <Title variant='h6'>{t(documentType)}</Title>

        <TermsBox>
          <TermDocument documentType={documentType} />
        </TermsBox>
      </BackgroundArtLayout>
    </>
  );
};

export default TermsOfUse;
