import { Box, Stack, Typography } from '@mui/material';

import theme from '@/theme';
import Button from '@/components/Button';
import PreviewDrawer, { PreviewItem } from '@/components/PreviewDrawer';
import { IncomeScheduling } from '@/services/SellerApi';
import { useTranslation } from 'react-i18next';
import OperationHeader from '../OperationHeader';
import { formatCents } from '@/utils';
import { format } from 'date-fns';
import InstallmentChip from '../InstallmentChip';

interface Props {
  open: boolean;
  onClose: () => void;
  selectedRow?: IncomeScheduling;
}
export const MobileTableRowPreview = (props: Props) => {
  const { open, onClose, selectedRow } = props;
  const { t } = useTranslation(['incomeSchedulings']);

  return (
    <PreviewDrawer open={open} onClose={onClose} showCloseButton={false}>
      {selectedRow ? (
        <Box display='flex' flexDirection='column' gap={1}>
          <Stack direction='row' mb={1} justifyContent='space-between' alignItems='center'>
            <Typography variant='smallSemiBold'>{selectedRow.transaction?.sale?.position ?? ''}</Typography>
          </Stack>

          <Box
            display='flex'
            flexDirection='column'
            sx={{
              '& .preview-item:not(:nth-of-type(even))': {
                background: theme.palette.colors.gray[50],
                borderRadius: '4px',
              },
            }}
          >
            <PreviewItem title={<OperationHeader />} content={t('event_type.' + selectedRow.eventType)} />

            <PreviewItem title={t('table.gross_value')} content={formatCents(selectedRow.grossAmountCents)} />

            <PreviewItem title={t('table.net_value')} content={formatCents(selectedRow.amountCents)} />

            <PreviewItem
              title={t('table.intstallments')}
              content={() => (
                <InstallmentChip
                  installmentNumber={selectedRow.installmentNumber}
                  installmentTotal={selectedRow.installmentTotal}
                />
              )}
            />

            <PreviewItem
              title={t('table.operation_date')}
              content={format(new Date(selectedRow.plannedAt), 'dd/MM/yyyy - HH:mm')}
            />

            <PreviewItem
              title={t('table.payment_date')}
              content={format(new Date(selectedRow.performedAt ?? selectedRow.availableAt), 'dd/MM/yyyy')}
            />
          </Box>

          <Button variant='contained' onClick={onClose}>
            {t('table.actions.close')}
          </Button>
        </Box>
      ) : null}
    </PreviewDrawer>
  );
};

export default MobileTableRowPreview;
