import { styled } from '@mui/material';

export const StyledAvailabilitySubItem = styled('li')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderBottom: '1px solid',
  borderColor: theme.palette.grey[200],
  gap: theme.spacing(2),
  padding: theme.spacing(2, 0),

  '&:last-child': {
    borderBottom: 'none',
  },
}));
