import { v4 as uuid } from 'uuid';
import { Product, ProductTariffType, TariffType } from '@/services/SellerApi';
import { Paper, Stack, Typography } from '@mui/material';
import Checkbox from '@/components/Form/Checkbox';
import { AdditionalsContainer } from '../AdditionalCard/styles';
import { ProductWithItems } from '../ProductItemPicker';

interface Props {
  product: ProductWithItems<ProductTariffType, TariffType>;
  tariffTypes: TariffType[];
  selectedProductTariffTypes: ProductTariffType[];
  onClick?: (productTariffType: ProductTariffType, product: ProductWithItems<ProductTariffType, TariffType>) => void;
  disabled?: boolean;
}

const ProductTariffTypeCard = (props: Props) => {
  const { product, tariffTypes, selectedProductTariffTypes, onClick = () => {}, disabled } = props;

  const isProductTariffTypeSelected = (productTariffType: ProductTariffType) => {
    return selectedProductTariffTypes.some(
      (selectedProductTariffType) =>
        selectedProductTariffType.tariffTypeId === productTariffType.tariffTypeId &&
        selectedProductTariffType.productId === productTariffType.productId,
    );
  };

  const getProductTariffType = (tariffType: TariffType, product: Product) => ({
    tariffTypeId: tariffType.id,
    productId: product.id,
    tariffType,
    product,
  });

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    tariffType: TariffType,
    product: ProductWithItems<ProductTariffType, TariffType>,
  ) => {
    event.preventDefault();

    if (!disabled) {
      onClick(getProductTariffType(tariffType, product), product);
    }
  };

  return (
    <Paper component={Stack} elevation={3} sx={{ p: '0.5rem' }}>
      <Stack flexDirection='row' justifyContent='space-between' alignItems='center' sx={{ mb: '0.5rem' }}>
        <Typography variant='smallBold'>{product.internalName ?? product.name}</Typography>
      </Stack>

      <AdditionalsContainer>
        {tariffTypes?.map((tariffType) => (
          <Checkbox
            disableRipple
            key={uuid()}
            checked={isProductTariffTypeSelected(getProductTariffType(tariffType, product))}
            label={tariffType.name}
            onClick={(e) => handleClick(e, tariffType, product)}
            disabled={disabled}
          />
        ))}
      </AdditionalsContainer>
    </Paper>
  );
};

export default ProductTariffTypeCard;
