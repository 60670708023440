import { styled } from '@mui/material/styles';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { Box } from '@mui/system';

export const PartnershipTitle = styled(Typography)(() => ({
  color: 'black',
  fontSize: '1.5rem',
  fontWeight: '600',
}));

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: theme.palette.colors.gray[100],
}));

export const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  '&.Mui-expanded': {
    minHeight: '54px',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '12px 0',
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  paddingTop: '0',
}));

export const ProgramTitle = styled(Typography)(() => ({
  color: 'black',
  fontSize: '1rem',
  fontWeight: '600',
}));

export const DataTitle = styled(Typography)(() => ({
  color: 'black',
  fontSize: '1rem',
  fontWeight: '600',
}));

export const ItemsTitle = styled(Typography)(() => ({
  color: 'black',
  fontSize: '1rem',
  fontWeight: '600',
  marginBottom: '8px',
}));

export const ItemsHeader = styled(Typography)(() => ({
  color: 'black',
  fontSize: '0.875rem',
  fontWeight: '600',
}));

export const ItemName = styled(Typography)(() => ({
  color: 'black',
  fontSize: '0.875rem',
  fontWeight: '600',
}));

export const ItemComission = styled(Typography)(() => ({
  color: 'black',
  fontSize: '0.875rem',
  fontWeight: '400',
  textAlign: 'center',
}));

export const ItemImage = styled('img')(() => ({
  width: '100%',
  display: 'block',
  marginRight: '8px',
}));

export const DataLabel = styled(Typography)(() => ({
  color: 'black',
  fontSize: '0.8125rem',
  fontWeight: '800',
}));

export const DataValue = styled(Typography)(() => ({
  color: 'black',
  fontSize: '1rem',
  fontWeight: '400',
  marginBottom: '1rem',
}));

export const ActivationDate = styled(Typography)(() => ({
  color: 'black',
  fontSize: '0.8125rem',
  fontWeight: '400',
}));

export const DivulgationLinkContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const DivulgationLink = styled(Typography)(() => ({
  color: 'black',
  fontSize: '1rem',
  fontWeight: '400',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const DivulgationLinkActionsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  width: '100%',
  overflow: 'hidden',
  borderRadius: '4px',
  height: '46px',
  border: `1px solid ${theme.palette.colors.gray[400]}`,
  textOverflow: 'ellipsis',
}));

export const DivulgationLinkActionsText = styled(Typography)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  background: theme.palette.colors.gray[50],
  color: theme.palette.colors.gray[400],
  flex: 1,
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  textOverflow: 'ellipsis',
  width: '100%',
}));

export const AlertText = styled(Typography)(({ theme }) => ({
  background: theme.palette.colors.blue[100],
  borderRadius: '4px',
  padding: '1rem',
  display: 'flex',
  alignSelf: 'stretch',
  alignItems: 'center',
  color: theme.palette.colors.blue[500],
}));
