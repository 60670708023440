import { Grid } from '@mui/material';
import { GridColDef, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid';
import { Eye } from '@untitled-ui/icons-react';
import { format } from 'date-fns';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/Button';
import CommissionDrawer from '@/components/Partnership/Commissions/CommissionDrawer';
import Table from '@/components/Table';
import ValueWithLabel from '@/components/ValueWithLabel';
import { ArrangedSaleItem } from '@/services/SellerApi';
import { dateWithTimezone, formatCents } from '@/utils';
import { precisePercentageMultiplication } from '@/common/partnership';

type Props = {
  totalArrangedSaleItems: number;
  data: ArrangedSaleItem[];
  loading: boolean;
  paginationModel: {
    page: number;
    pageSize: number;
  };
  setPaginationModel: Dispatch<
    SetStateAction<{
      page: number;
      pageSize: number;
    }>
  >;
};

const CommissionsTable = (props: Props) => {
  const { totalArrangedSaleItems, data, loading, paginationModel, setPaginationModel } = props;
  const { t } = useTranslation(['commissions']);
  const [selectedCommission, setSelectedCommission] = useState<ArrangedSaleItem | null>(null);

  const handleSelectCommission = (saleItem: ArrangedSaleItem) => {
    setSelectedCommission(saleItem);
  };

  const handleClearSelection = () => {
    setSelectedCommission(null);
  };

  const getCommisionPercentageFee = (saleItem: ArrangedSaleItem) => {
    const partnershipItem = saleItem?.partnershipItem;

    const value =
      partnershipItem?.commissionType === 'percentage_fee'
        ? `${precisePercentageMultiplication(partnershipItem?.commissionPercentageFee ?? 0)}%`
        : formatCents(partnershipItem?.commissionAmountCents ?? 0);

    return value;
  };

  const getFormattedCommission = (date: string | Date | null | undefined) =>
    date ? format(new Date(date), 'dd/MM/yyyy') : '-';

  const getFormattedScheduleDate = (date?: string) => (date ? format(dateWithTimezone(date), 'dd/MM/yyyy') : '-');

  const columns: GridColDef[] = [
    {
      field: 'sale.position',
      headerName: t('table.position'),
      valueGetter: (params: GridValueGetterParams) => params.row.sale.position,
      width: 60,
      sortable: false,
    },
    {
      field: 'sale.code',
      headerName: t('table.code'),
      valueGetter: (params: GridValueGetterParams) => params.row.sale.code,
      flex: 1,
      sortable: false,
    },
    {
      field: 'product.name',
      headerName: t('table.product'),
      valueGetter: (params: GridValueGetterParams) => params.row.product.name,
      flex: 1,
      sortable: false,
    },
    {
      field: 'partnershipItem.partnership.provider.name',
      headerName: t('table.provider'),
      valueGetter: (params: GridValueGetterParams) => params.row.partnershipItem?.partnership?.provider?.name ?? '-',
      flex: 1,
      sortable: false,
    },
    {
      field: 'decisiveTransactionValues.totalAmountCents',
      headerName: t('table.sale_value'),
      valueGetter: (params: GridValueGetterParams) => params.row.decisiveTransactionValues.totalAmountCents,
      valueFormatter: (params: GridValueFormatterParams) => formatCents(params.value),
      sortable: false,
      flex: 1,
    },
    {
      field: 'partnershipItem.commissionPercentageFee',
      headerName: t('table.commission'),
      valueGetter: (params: GridValueGetterParams) => getCommisionPercentageFee(params.row),
      sortable: false,
      flex: 1,
    },
    {
      field: 'decisiveTransactionValues.commissionedAmountCents',
      headerName: t('table.commission_value'),
      valueGetter: (params: GridValueGetterParams) => params.row.decisiveTransactionValues.commissionedAmountCents,
      valueFormatter: (params: GridValueFormatterParams) => formatCents(params.value),
      sortable: false,
      flex: 1,
    },
    {
      field: 'sale.createdAt',
      headerName: t('table.sale_date'),
      valueGetter: (params: GridValueGetterParams) => getFormattedCommission(params.row.sale.createdAt),
      sortable: false,
      flex: 1,
    },
    {
      field: 'scheduleDate',
      headerName: t('table.schedule_date'),
      valueGetter: (params: GridValueGetterParams) => getFormattedScheduleDate(params.row.scheduleDate),
      sortable: false,
      flex: 1,
    },
  ];

  return (
    <Grid item xs={12} paddingX={{ xs: 2, sm: 0 }}>
      <Table
        rows={data}
        columns={columns}
        paginationModel={paginationModel}
        paginationMode='server'
        disableColumnMenu
        onPaginationModelChange={(newPaginationModel) => setPaginationModel(newPaginationModel)}
        loading={loading}
        rowCount={totalArrangedSaleItems}
        renderMobile={({ row }) => (
          <Grid container rowGap={0.5}>
            <Grid item xs={4.5}>
              <ValueWithLabel title='#' value={row.sale.code} />
            </Grid>

            <Grid item xs={4.5}>
              <ValueWithLabel title={t('table.product')} value={row.product.name} />
            </Grid>

            <Grid item xs={3} textAlign='right'>
              <Button
                onClick={() => handleSelectCommission(row as ArrangedSaleItem)}
                variant='outlined'
                color='primary'
                sx={{ padding: '0.625 rem 0.5rem' }}
              >
                <Eye />
              </Button>
            </Grid>

            <Grid item xs={4.5}>
              <ValueWithLabel
                title={t('table.commission_value')}
                value={formatCents(row.decisiveTransactionValues.commissionedAmountCents)}
              />
            </Grid>

            <Grid item xs={4.5}>
              <ValueWithLabel title={t('table.sale_date')} value={format(new Date(row.sale.createdAt), 'dd/MM/yyyy')} />
            </Grid>
          </Grid>
        )}
      />

      <CommissionDrawer
        getCommisionPercentageFee={getCommisionPercentageFee}
        getFormattedCommission={getFormattedCommission}
        getFormattedScheduleDate={getFormattedScheduleDate}
        selectedCommission={selectedCommission}
        onClose={handleClearSelection}
      />
    </Grid>
  );
};

export default CommissionsTable;
