import PlanneLogoComponent from '@/components/PlanneLogo';
import { ErrorLogoWrapper } from './styles';
import { Fragment } from 'react';

interface ErrorComponentHeaderProps {
  showLogo?: boolean;
  children?: React.ReactNode;
}
export const ErrorComponentHeader = (props: ErrorComponentHeaderProps) => {
  const { showLogo = true, children } = props;
  return (
    <Fragment>
      {children}
      {showLogo && (
        <ErrorLogoWrapper>
          <PlanneLogoComponent style='dark' />
        </ErrorLogoWrapper>
      )}
    </Fragment>
  );
};

export default ErrorComponentHeader;
