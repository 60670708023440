import { Stack } from '@mui/material';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { BreadcrumbItemProps } from '@/components/Breadcrumbs';
import Loading from '@/components/Loading';
import AvailabilityForm from '@/features/agenda/features/availability/components/AvailabilityForm';
import translatePT from '@/features/agenda/features/availability/locales/pt.json';
import translateSchedulingsPT from '@/features/agenda/locales/pt.json';
import { useSellerApi } from '@/hooks/useSellerApi';
import PageLayout from '@/layouts/PageLayout';
import i18n from '@/services/i18n';
import { Availability } from '@/services/SellerApi';
import theme from '@/theme';
import apiDateToDateObject from '@/utils/apiDateToDateObject';

const AvailabilityFormPage = () => {
  // TODO: Move this to a Router Resolver
  i18n.addResourceBundle('pt-BR', 'agenda', translateSchedulingsPT);
  i18n.addResourceBundle('pt-BR', 'availability', translatePT);

  const { id } = useParams<{ id: string }>();
  const isEdit = !!id;

  const { t } = useTranslation(['availability'], { keyPrefix: 'form' });

  const { availabilitiesApi } = useSellerApi();

  const [availability, setAvailability] = useState<Availability | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const pageTitle = useMemo(() => {
    if (isEdit && availability) {
      return `${t('edit_availability_title')} - ${apiDateToDateObject(availability.appliesAt).toLocaleDateString(
        undefined,
        {
          year: 'numeric',
          day: 'numeric',
          month: 'numeric',
        },
      )}`;
    }
    return t('new_availability_title');
  }, [isEdit, t, availability]);

  const breadcrumbs: BreadcrumbItemProps[] = [
    {
      label: t('breadcrumb_calendar'),
      to: '../agenda',
    },
    {
      label: t(isEdit ? 'edit_availability_title' : 'new_availability_title'),
    },
  ];

  const fetchAvailability = async (availabilityId: string) => {
    setLoading(true);
    const availabilityData = await availabilitiesApi.getAvailability({
      availabilityId,
      include: [
        'products',
        'additionals.product',
        'productTariffTypes.product',
        'productTariffTypes.tariffType',
        'tariffs.type',
        'tariffs.product',
        'aggregatedRecurrenceCount',
      ],
    });

    setAvailability(availabilityData.data);
    setLoading(false);
  };

  useEffect(() => {
    if (!id) return;
    fetchAvailability(id);
  }, [id]);

  return (
    <Fragment>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PageLayout
        title={pageTitle}
        showFilterButton={false}
        showSearchInput={false}
        showGoBackButton={true}
        breadcrumbs={breadcrumbs}
      >
        <Stack sx={{ width: '100%', paddingX: theme.spacing(5), paddingY: theme.spacing(6) }}>
          {(isEdit && !availability) || loading ? <Loading /> : <AvailabilityForm availability={availability} />}
        </Stack>
      </PageLayout>
    </Fragment>
  );
};

export default AvailabilityFormPage;
