import { styled } from '@mui/material';

export const StyledModalTitle = styled('div')(({ theme }) => ({
  ...theme.typography.h6,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
  paddingBottom: '0',
  paddingRight: '0',
}));

export const StyledListItem = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  alignItems: 'center',
}));
