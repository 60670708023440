import { Stack, Typography } from '@mui/material';
import { IconAlertTriangle } from '@tabler/icons-react';

import { StyledAvailabilitySubItem } from '@/features/agenda/components/AvailabilitySubItens/AvailabilitySubItem/styles';
import { Additional, Product, ProductTariffType, Tariff } from '@/services/SellerApi';
import theme from '@/theme';
import { formatCents } from '@/utils';

type Props = {
  additional?: Additional;
  product?: Product;
  tariff?: Tariff;
  productTariffType?: ProductTariffType;
};

const AvailabilitySubItem = (props: Props) => {
  const { additional, product, tariff, productTariffType } = props;

  const archivedColor = theme.palette.colors.warning[700];

  if (productTariffType) {
    return (
      <StyledAvailabilitySubItem>
        <Typography variant='smallSemiBold'>{productTariffType.tariffType?.name}</Typography>

        <Typography variant='extraSmallRegular' sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {productTariffType.product?.internalName ?? productTariffType.product?.name}
        </Typography>
      </StyledAvailabilitySubItem>
    );
  }

  if (product) {
    return (
      <StyledAvailabilitySubItem sx={{ flexDirection: 'row', alignItems: 'center' }}>
        <Typography variant='smallSemiBold' sx={{ color: product.active ? 'inherit' : archivedColor }}>
          {product.internalName ?? product.name}
        </Typography>

        {!product.active ? <IconAlertTriangle size='1rem' color={archivedColor} /> : null}
      </StyledAvailabilitySubItem>
    );
  }

  if (tariff) {
    const isTariffGroupArchived = !!tariff.group?.deletedAt;
    const isTariffArchived = !!tariff.deletedAt && !isTariffGroupArchived;
    const formattedPrice = formatCents(tariff.priceCents);

    return (
      <StyledAvailabilitySubItem sx={{ gap: theme.spacing(1) }}>
        <Stack
          sx={{
            flexDirection: 'row',
            gap: theme.spacing(1),
          }}
        >
          <Typography
            component='span'
            variant='smallSemiBold'
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: isTariffArchived || isTariffGroupArchived ? archivedColor : 'inherit',
              gap: theme.spacing(0.5),
            }}
          >
            {tariff.type?.name} - {formattedPrice}
            {isTariffArchived ? <IconAlertTriangle size='0.812rem' /> : null}
          </Typography>
          {tariff.group?.name ? (
            <Typography
              variant='smallSemiBold'
              component='span'
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: theme.spacing(0.5),
                color: isTariffGroupArchived ? archivedColor : 'inherit',
              }}
            >
              • {tariff.group?.name} {isTariffGroupArchived ? <IconAlertTriangle size='0.812rem' /> : null}
            </Typography>
          ) : null}
        </Stack>

        <Typography variant='extraSmallRegular' sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {tariff.product?.internalName ?? tariff.product?.name}
        </Typography>
      </StyledAvailabilitySubItem>
    );
  }

  if (additional) {
    return (
      <StyledAvailabilitySubItem>
        <Typography variant='smallSemiBold'>{additional.title}</Typography>
        {additional.product ? (
          <Typography variant='extraSmallRegular' title={additional.product.name ?? additional.product.internalName}>
            {additional.product.internalName ?? additional.product.name}
          </Typography>
        ) : null}
      </StyledAvailabilitySubItem>
    );
  }
  return null;
};

export default AvailabilitySubItem;
