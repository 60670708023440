import { styled } from '@mui/material';

const unsafeProps: PropertyKey[] = ['active'];
interface IStyledHeaderItemContentProps {
  active?: boolean;
}

export const StyledWeeklyContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  overflowX: 'auto',
  scrollSnapType: 'x mandatory',
  scrollSnapAlign: 'start',
  display: 'grid',
  gridTemplateColumns: 'repeat(7, 1fr)',
  gridTemplateRows: 'min-content',
  width: '100vw',
  height: '100%',
  paddingBottom: theme.spacing(4),

  [theme.breakpoints.up('md')]: {
    width: '100%',
    gridTemplateColumns: 'repeat(7, minmax(0, 1fr))',
    gridTemplateAreas: `
    "header header header header header header header"
    "day day day day day day day"`,
  },
}));

export const StyledHeaderItemContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.divider}`,
  borderTop: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.grey[50],
  textTransform: 'capitalize',
}));

export const StyledHeaderItemContent = styled('span', {
  shouldForwardProp: (prop) => !unsafeProps.includes(prop),
})<IStyledHeaderItemContentProps>(({ theme, active = false }) => ({
  ...theme.typography.smallBold,
  margin: '0.25rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  borderRadius: theme.radius.sm,
  width: '70vw',
  ...(active && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  }),
  [theme.breakpoints.up('md')]: {
    width: '100%',
  },
}));

export const StyledDayColumn = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  scrollSnapAlign: 'center',
  width: '80vw',

  padding: theme.spacing(2),

  [theme.breakpoints.up('md')]: {
    width: '100%',
  },

  [`&.first`]: {
    paddingLeft: theme.spacing(3),
  },

  [`&.last`]: {
    paddingRight: theme.spacing(3),
  },

  [`&.beforeToday`]: {
    opacity: 0.4,
  },
}));

export const StyledDotsContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  listStyle: 'none',
  padding: 0,
  width: '100%',
}));
