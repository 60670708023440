import useUI from '@/hooks/useUI';
import Drawer from '@mui/material/SwipeableDrawer';
import SideBar from '@/components/SideBar';
import { StyledDrawer } from './style';

export default function PersistentDrawerLeft() {
  const { drawerOpen, toggleDrawer, drawerWidth, isDrawerExpanded } = useUI();

  const handleDrawerToggle = () => {
    toggleDrawer();
  };

  return (
    <>
      <Drawer
        variant='temporary'
        open={drawerOpen}
        onOpen={() => handleDrawerToggle()}
        onClose={() => handleDrawerToggle()}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: { xs: '100%', sm: drawerWidth } },
        }}
      >
        <SideBar />
      </Drawer>

      <StyledDrawer
        open={drawerOpen}
        variant='permanent'
        drawerWidth={drawerWidth}
        isDrawerExpanded={isDrawerExpanded}
        onOpen={() => handleDrawerToggle()}
        onClose={() => handleDrawerToggle()}
        sx={{ display: { xs: 'none', md: 'block' } }}
      >
        <SideBar />
      </StyledDrawer>
    </>
  );
}
