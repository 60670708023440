import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Visibility, SyncAltOutlined } from '@mui/icons-material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { Menu, MenuItem, SvgIconTypeMap, Typography } from '@mui/material';
import { DotsVertical } from '@untitled-ui/icons-react';

import { colors } from '@/theme';
import Button from '@/components/Button';
import useAccount from '@/hooks/useAccount';

type MobileMenuProps = {
  onViewRefund: () => void;
  onRequestRefund: () => void;
  isAvailableForRefund: boolean;
};

export const MobileMenu = (props: MobileMenuProps) => {
  const { onRequestRefund, onViewRefund, isAvailableForRefund } = props;

  const { hasPermission } = useAccount();

  const { t } = useTranslation(['refundRequests']);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Button onClick={handleClick} variant='outlined' color='primary' sx={{ padding: '0.5rem' }}>
        <DotsVertical />
      </Button>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} sx={{ '& .MuiList-root ': { padding: 0 } }}>
        <Item Icon={Visibility} label={t('table.actions.view_sale')} onClick={onViewRefund} />

        {isAvailableForRefund && hasPermission('refundRequests', 'CreateSaleRefundRequest') ? (
          <Item
            Icon={SyncAltOutlined}
            onClick={onRequestRefund}
            value='pending'
            label={t('table.actions.request_refund')}
          />
        ) : null}
      </Menu>
    </Fragment>
  );
};

type ItemProps = {
  onClick: () => void;
  Icon: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & { muiName: string };
  label: string;
  value?: string;
};
const Item = (props: ItemProps) => {
  const { label, onClick, Icon, value } = props;

  return (
    <MenuItem value={value} onClick={onClick} sx={{ display: 'flex', gap: 1 }}>
      <Icon sx={{ color: colors.gray[500] }} />
      <Typography variant='regularRegular' color={colors.gray[500]}>
        {label}
      </Typography>
    </MenuItem>
  );
};

export default MobileMenu;
