import { Fragment, Suspense } from 'react';
import { Route } from 'react-router-dom';

import SaleItems from '@/features/sales/components/Items';

import Sales from '@/features/sales/pages/List';
import SaleDetails from '@/features/sales/pages/Details';
import IncomeSchedulings from '@/features/sales/pages/IncomeSchedulings';
import SaleReceipt from '@/features/sales/pages/Receipt';
import SaleAppTags from '@/features/sales/pages/Tags';
import NewTag from '@/features/sales/pages/Tags/New';
import Vouchers from '@/features/sales/pages/Vouchers';
import ProtectedRoute from '@/routes/ProtectedRoute';
import RescheduleModal from '../reservation/components/RescheduleModal';

// const SaleDetailCustomerModal = lazy(() => import('@/features/sales/pages/Details/CustomerModal'));
// const SaleRefundRequestModal = lazy(() => import('@/features/sales/pages/SaleRefundRequestModal'));
// const RefundRequestHistoryModal = lazy(() => import('@/features/sales/pages/Details/RefundRequestHistoryModal'));

import SaleDetailCustomerModal from '@/features/sales/pages/Details/CustomerModal';
import SaleRefundRequestModal from '@/features/sales/pages/SaleRefundRequestModal';
import RefundRequestHistoryModal from '@/features/sales/pages/Details/RefundRequestHistoryModal';

export const SaleRoutes = () => {
  return (
    <Fragment>
      <Route
        path='sales'
        element={
          <ProtectedRoute
            policies={{
              OR: [
                {
                  namespace: 'sales',
                  action: 'GetAppSales',
                  code: 'sales:GetAppSales',
                },
              ],
            }}
            element={<Sales />}
          />
        }
      >
        <Route
          path=':saleId/refund'
          element={
            <ProtectedRoute
              policies={{
                OR: [
                  {
                    namespace: 'refundRequests',
                    action: 'CreateSaleRefundRequest',
                    code: 'refundRequests:CreateSaleRefundRequest',
                  },
                ],
              }}
              element={
                <Suspense fallback={null}>
                  <SaleRefundRequestModal />
                </Suspense>
              }
            />
          }
        />
      </Route>

      <Route
        path='sales/tags'
        element={
          <ProtectedRoute
            policies={{
              OR: [
                {
                  namespace: 'tags',
                  action: 'GetAppTags',
                  code: 'tags:GetAppTags',
                },
                {
                  namespace: 'tags',
                  action: 'UpdateTag',
                  code: 'tags:UpdateTag',
                },
                {
                  namespace: 'tags',
                  action: 'DeleteTag',
                  code: 'tags:DeleteTag',
                },
              ],
            }}
            element={<SaleAppTags />}
          />
        }
      >
        <Route path='create' element={<NewTag />} />
      </Route>

      <Route
        path='sales/:saleId'
        element={
          <ProtectedRoute
            policies={{
              OR: [
                {
                  namespace: 'sales',
                  action: 'GetSale',
                  code: 'sales:GetSale',
                },
              ],
            }}
            element={<SaleDetails />}
          />
        }
      >
        <Route path='' element={<SaleItems />}>
          {SaleDetailCommonRoutes}

          <Route
            path='reservation/:reservationId/reschedule'
            element={
              <ProtectedRoute
                policies={{
                  OR: [
                    {
                      namespace: 'reservations',
                      action: 'RescheduleReservation',
                      code: 'reservations:RescheduleReservation',
                    },
                    {
                      namespace: 'reservations',
                      action: 'SecureRescheduleReservation',
                      code: 'reservations:SecureRescheduleReservation',
                    },
                  ],
                }}
                element={<RescheduleModal />}
              />
            }
          />
        </Route>

        <Route
          path='vouchers'
          element={
            <ProtectedRoute
              policies={{
                OR: [
                  {
                    namespace: 'vouchers',
                    action: 'GetSaleVouchers',
                    code: 'vouchers:GetSaleVouchers',
                  },
                ],
              }}
              element={<Vouchers />}
            />
          }
        >
          {SaleDetailCommonRoutes}
        </Route>

        <Route
          path='receipt'
          element={
            <ProtectedRoute
              policies={{
                OR: [
                  {
                    action: 'GetSale',
                    namespace: 'sales',
                    code: 'sales:GetSale',
                  },
                ],
              }}
              element={<SaleReceipt />}
            />
          }
        >
          {SaleDetailCommonRoutes}
        </Route>

        <Route
          path='income-schedulings'
          element={
            <ProtectedRoute
              policies={{
                OR: [
                  {
                    namespace: 'incomeSchedulings',
                    action: 'GetSaleIncomeSchedulings',
                    code: 'incomeSchedulings:GetSaleIncomeSchedulings',
                  },
                ],
              }}
              element={<IncomeSchedulings />}
            />
          }
        >
          {SaleDetailCommonRoutes}
        </Route>
      </Route>
    </Fragment>
  );
};

const SaleDetailCommonRoutes = [
  <Route
    key='customer'
    path='customer'
    element={
      <ProtectedRoute
        policies={{
          OR: [
            {
              namespace: 'customers',
              action: 'GetCustomer',
              code: 'customers:GetCustomer',
            },
          ],
        }}
        element={
          <Suspense fallback={null}>
            <SaleDetailCustomerModal />
          </Suspense>
        }
      />
    }
  />,
  <Route
    key='refund-request'
    path='refund-request'
    element={
      <ProtectedRoute
        policies={{
          OR: [
            {
              namespace: 'refundRequests',
              action: 'CreateSaleRefundRequest',
              code: 'refundRequests:CreateSaleRefundRequest',
            },
          ],
        }}
        element={
          <Suspense fallback={null}>
            <SaleRefundRequestModal />
          </Suspense>
        }
      />
    }
  />,
  <Route
    key='refund-request-history'
    path='refund-request-history'
    element={
      <ProtectedRoute
        policies={{
          OR: [
            {
              namespace: 'refundRequests',
              action: 'GetRefundRequests',
              code: 'refundRequests:GetRefundRequests',
            },
          ],
        }}
        element={
          <Suspense fallback={null}>
            <RefundRequestHistoryModal />
          </Suspense>
        }
      />
    }
  />,
];
