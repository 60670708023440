import { Stack } from '@mui/system';
import { IconCalendarWeek } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import RecurrenceSelectorModal from '@/features/agenda/components/RecurrenceSelector';
import { AnalyticsEvents } from '@/hooks/analytics/enum/analyticsEvents';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import { useSellerApi } from '@/hooks/useSellerApi';
import { UpdateRulesProps } from '@/features/agenda/types';
import { format } from 'date-fns';

type Props = {
  availabilityId: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  onCanceled?: () => void;
  initialDate?: Date;
};

const RemoveAvailabilityRecurrenceSelector = (props: Props) => {
  const { availabilityId, isOpen, onClose, onCanceled = () => {}, onSuccess = () => {}, initialDate } = props;

  const { t } = useTranslation(['agenda'], { keyPrefix: 'modal' });
  const [isLoading, setIsLoading] = useState(false);

  const { track } = useAnalytics();
  const { availabilitiesApi } = useSellerApi();
  const queryClient = useQueryClient();

  const handleClose = () => {
    onClose();
  };

  const handleSuccess = () => {
    onSuccess();
    handleClose();
  };

  const handleCancel = () => {
    onCanceled();
    handleClose();
  };

  const handleError = () => {
    enqueueSnackbar(t('remove_availability.error'), {
      variant: 'error',
    });
    handleClose();
  };

  const handleRemove = async (data: UpdateRulesProps) => {
    try {
      setIsLoading(true);

      await availabilitiesApi.deleteAvailability({
        availabilityId,
        deleteAvailabilityParams: {
          rule: data.rule,
          ruleUntilDate: data.ruleUntilDate ? format(data.ruleUntilDate, 'yyyy-MM-dd') : undefined,
        },
      });

      queryClient.invalidateQueries({ queryKey: ['aggregatedAvailabilities'] });
      queryClient.invalidateQueries({ queryKey: ['availabilities'] });
      queryClient.resetQueries({ queryKey: ['relatedAvailabilities'] });

      enqueueSnackbar(t('remove_availability.success'), {
        variant: 'success',
      });

      track(AnalyticsEvents.AVAILABILITY_REMOVED, { availabilityId, data });

      handleSuccess();
    } catch (error) {
      console.error(error);

      track(AnalyticsEvents.AVAILABILITY_REMOVED, { availabilityId, data, error });
      handleError();
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <RecurrenceSelectorModal
      title={
        <Stack flexDirection='row' alignItems='center' gap='0.5rem'>
          <IconCalendarWeek /> {t('remove_availability.title')}
        </Stack>
      }
      isOpen={isOpen}
      isLoading={isLoading}
      onConfirm={(data: UpdateRulesProps) => handleRemove(data)}
      onCancel={() => handleCancel()}
      onClose={() => handleClose()}
      initialDate={initialDate}
    />
  );
};

export default RemoveAvailabilityRecurrenceSelector;
