import useAccount from '@/hooks/useAccount';
import { useSellerApi } from '@/hooks/useSellerApi';
import { Product, SellingMode } from '@/services/SellerApi';
import { t } from 'i18next';
import { debounce } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useState, useEffect, useCallback } from 'react';

const useProductList = (sellingModes?: SellingMode[]) => {
  const { productsApi } = useSellerApi();
  const { selectedAccount } = useAccount();

  const [search, setSearch] = useState<string | undefined>(undefined);

  const [products, setProducts] = useState<Product[]>([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState<number | undefined>(undefined);

  const limit = 21;

  const getProducts = async (page: number, limit: number, searchTerm?: string) => {
    setLoading(true);

    try {
      const { data, headers } = await productsApi.getAppProducts({
        appId: selectedAccount?.app?.id ?? '',
        offset: page * limit,
        limit,
        search: searchTerm || undefined,
        activeOnly: true,
        sellingModes,
      });

      setProducts([...products, ...data]);
      setTotalCount(Number(headers['x-pagination-total-count']));
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('form.product_list.get_error'), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const hasMore = totalCount ? products.length < totalCount : true;

  const loadMore = async () => {
    setPage(page + 1);

    getProducts(page + 1, limit, search);
  };

  const debouncedSearch = useCallback(
    debounce((nextValue) => {
      getProducts(page, limit, nextValue);
    }, 1000),
    [],
  );

  const changeSearch = (value: string) => {
    setSearch(value);

    debouncedSearch(value);
  };

  useEffect(() => {
    setProducts([]);
    setPage(0);
    setLoading(true);
  }, [search]);

  useEffect(() => {
    getProducts(page, limit, search);
  }, []);

  return {
    products,
    loading,
    hasMore,
    search,
    changeSearch,
    loadMore,
  };
};

export default useProductList;
