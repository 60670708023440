import { styled } from '@mui/material/styles';
import { Chip } from '@mui/material';

const colorMap = {
  error: {
    text: '#8E130B',
    background: '#FBD2D0',
    hover: '#f7a9a5',
  },
  warning: {
    text: '#945605',
    background: '#FDE9CE',
    hover: '#fbd5a1',
  },
  success: {
    text: '#0E8B51',
    background: '#D1FAE7',
    hover: '#a7f5d1',
  },
  default: {
    text: 'rgba(0, 0, 0, 0.87)',
    background: 'rgba(0, 0, 0, 0.08)',
    hover: 'rgba(0, 0, 0, 0.2)',
  },
  info: {
    text: '#004099',
    background: '#CCE1FF',
    hover: '#CCEFFF',
  },
  secondary: {
    text: '#4E10CF',
    background: '#F3E5FB',
    hover: '#E0BDF6',
  },
};

export type StyledChipProps = {
  color: keyof typeof colorMap;
};

export const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'color',
})<StyledChipProps>(({ color }) => ({
  backgroundColor: colorMap[color].background,
  color: colorMap[color].text,

  '&:hover': {
    backgroundColor: colorMap[color].hover,
  },
}));
