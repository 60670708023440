import {
  AccessToken,
  AgreementDocument,
  ErrorObject,
  LoginParams,
  SignUpParams,
  User,
} from '@/services/sellerApiQuery/model';
import { AuthRecoverPasswordRequest, AuthResetPasswordRequest } from '@/services/SellerApi';
import { AxiosError } from 'axios';

export enum AuthStatus {
  AUTHENTICATED,
  UNAUTHENTICATED,
  USER_PENDING,
}

export type AuthContextType = {
  accessToken?: string;
  user: User | undefined;
  status: AuthStatus;
  loginError: AxiosError<ErrorObject, unknown> | null;
  setAuthStatus: (status: AuthStatus) => void;
  authenticate: (token?: AccessToken) => void;
  login: (data: LoginParams) => Promise<void>;
  logout: () => void;
  refresh: () => void;
  signup: (data: SignUpParams) => Promise<void>;
  resetPassword: (data: AuthResetPasswordRequest) => Promise<void>;
  resetPasswordCodeValidStatus: (data: string) => Promise<void>;
  recoverPassword: (data: AuthRecoverPasswordRequest) => Promise<void>;
  getTermsOfUse: () => Promise<AgreementDocument>;
  getPrivacyPolicy: () => Promise<AgreementDocument>;
  isAccessTokenExpired: (ttl?: number) => boolean;
};
