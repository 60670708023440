import { useQuery } from '@tanstack/react-query';

import useGetRelatedAvailabilitiesOfAggregation from '@/features/agenda/usecases/useGetRelatedAvailabilitiesOfAggregation';

const useLoadRelatedAvailabilitiesQuery = () => {
  const { request: getAllAvailabilities } = useGetRelatedAvailabilitiesOfAggregation();

  const useRequestQuery = (schedulingId: string, active = false) => {
    return useQuery({
      queryKey: ['relatedAvailabilities', schedulingId],
      queryFn: getAllAvailabilities,
      enabled: !!schedulingId && active,
    });
  };

  return [useRequestQuery];
};

export default useLoadRelatedAvailabilitiesQuery;
