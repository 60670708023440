import { useTranslation } from 'react-i18next';

import Select from '@/components/Form/Select';
import { FilterOption } from '@/interfaces/filterContext';
import { useCallback, useMemo } from 'react';
import { Product, SellingMode } from '@/services/sellerApiQuery/model';
import useLoadProductsQuery from '@/queries/useLoadProductsQuery';
type Props = {
  value?: string | string[] | FilterOption[];
  initialState?: Product[];
  onChange?: (event: unknown, value: unknown) => void;
};
const ProductSelector = (props: Props) => {
  const { onChange = () => undefined, initialState = [], value } = props;
  const { t } = useTranslation(['agenda']);

  const [productsQuery] = useLoadProductsQuery(
    [SellingMode.with_date_and_time, SellingMode.with_date_only],
    initialState,
  );

  const { data: products, isLoading: productsLoading } = productsQuery(true);

  const productsOptions = useMemo(() => {
    return (
      products?.map((product) => ({
        label: product.internalName ?? product.name,
        value: product.id,
      })) || []
    );
  }, [products]);

  const selectValue = useMemo(() => {
    if (Array.isArray(value)) {
      return productsOptions.filter((p) => (value as string[]).includes(p.value));
    }
    if (value) {
      return productsOptions.filter((p) => p.value === value);
    }
    return [];
  }, [productsOptions, value]);

  const isOptionEqualToValue = useCallback((option: unknown, value: unknown) => {
    if (typeof value === 'string') {
      return (option as FilterOption).value === value;
    }

    if (Array.isArray(value)) {
      return (value as FilterOption[])?.some((v: FilterOption) => v.value === (option as FilterOption).value);
    }

    return (option as FilterOption).value === (value as FilterOption).value;
  }, []);

  const handleProductFilterChange = useCallback(
    (_: unknown, value: unknown) => {
      const ids = (value as FilterOption[])?.map((v) => v.value);
      const newValue = productsOptions?.filter((p) => ids.includes(p.value));
      onChange(_, newValue);
    },
    [onChange, productsOptions],
  );

  return (
    <Select
      fullWidth
      multiple
      checkbox
      limitTags={1}
      disableCloseOnSelect
      placeholder={t('filter.quick_product.placeholder')}
      loading={productsLoading}
      loadingText={t('filter.loading')}
      options={productsOptions ?? []}
      onChange={handleProductFilterChange}
      value={selectValue ?? []}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={(option) => (option as FilterOption)?.label}
      getOptionKey={(option) => (option as FilterOption)?.value}
    />
  );
};

export default ProductSelector;
