import { keepPreviousData, useQuery } from '@tanstack/react-query';
import useGetSaleIncomeSchedulings from '../useCases/useGetSaleIncomeSchedulings';

const useLoadSaleIncomeSchedulings = () => {
  const { request: getSaleIncomeSchedulings } = useGetSaleIncomeSchedulings();

  const useRequestQuery = (saleId: string, limit: number, offset: number) => {
    return useQuery({
      queryKey: ['sale-income-schedulings', saleId, limit, offset],
      queryFn: getSaleIncomeSchedulings,
      enabled: !!saleId,
      staleTime: Infinity,
      placeholderData: keepPreviousData,
    });
  };

  return [useRequestQuery];
};

export default useLoadSaleIncomeSchedulings;
