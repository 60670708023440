import { useContext } from 'react';

import { FilterContext } from '@/contexts/FilterContext';
import { FilterContextProps, FilterSchemas } from '@/interfaces/filterContext';

/**
 * @deprecated Use `useConfig` instead.
 * In future versions, this hook will change to `useFilterDrawer`, which will be responsible for managing the filter drawer state only.
 */
export const useFilter = <T extends FilterSchemas>() => {
  const ctx = useContext(FilterContext);

  if (!ctx) {
    throw new Error('useFilter must be used within a FilterProvider');
  }

  return ctx as unknown as FilterContextProps<T>;
};

export default useFilter;
