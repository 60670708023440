import AutorenewIcon from '@mui/icons-material/Autorenew';

import { useTranslation } from 'react-i18next';
import useAccount from '@/hooks/useAccount';
import useAnalytics from '@/hooks/analytics/useAnalytics';

import { AppLogoContainer, Button, ButtonContent, Name, StyledAppLogo } from './styles';
import { Box, Tooltip } from '@mui/material';
import useUI from '@/hooks/useUI';

interface Props {
  onlyShowAccountImage?: boolean;
}

export const AccountSelectorButton = (props: Props) => {
  const { onlyShowAccountImage } = props;

  const { t } = useTranslation(['sidebar']);
  const { track } = useAnalytics();

  const { selectedAccount, setAccountSelectorOpen } = useAccount();
  const { isDrawerExpanded, drawerOpen } = useUI();

  const handleButtonClick = () => {
    track('sideBar/accounSelector/click', { accountSelected: selectedAccount?.app?.name });

    setAccountSelectorOpen(true);
  };

  return (
    <Tooltip title={selectedAccount?.app?.name} placement='top'>
      <Button onClick={() => handleButtonClick()}>
        <ButtonContent>
          <Box gap={1} display='flex' flexDirection='row' alignItems='center'>
            <AppLogoContainer sx={{ mr: onlyShowAccountImage ? 3 : 'auto' }}>
              {selectedAccount ? (
                <StyledAppLogo app={selectedAccount.app} />
              ) : (
                <AutorenewIcon sx={{ width: onlyShowAccountImage ? '100%' : 'auto' }} />
              )}
            </AppLogoContainer>

            {isDrawerExpanded || drawerOpen ? (
              <Name>{selectedAccount ? selectedAccount.app?.name : t('account_selector_button_text')}</Name>
            ) : null}
          </Box>
        </ButtonContent>
      </Button>
    </Tooltip>
  );
};

export default AccountSelectorButton;
