import { useEffect, useMemo } from 'react';
import { PaymentOption } from '@/services/SellerApi';
import { setupFingerprints, unloadFingerprints } from '@/common/payment';

export const usePaymentFingerprint = (paymentOptions?: PaymentOption[]) => {
  const fingerprints = useMemo(() => {
    const uniqueTypes = new Set();

    // Use flatMap to map over each paymentOption and flatten the fingerprints arrays
    const fingerprints = paymentOptions?.flatMap((po) => po.fingerprints!);

    // Use filter to filter out any fingerprints that have a type that already exists in the uniqueTypes set
    return (
      fingerprints?.filter((fingerprint) => {
        const isUnique = !uniqueTypes.has(fingerprint.type);

        if (isUnique) {
          uniqueTypes.add(fingerprint.type);
        }

        return isUnique;
      }) ?? []
    );
  }, [paymentOptions]);

  useEffect(() => {
    let fingerprintId = '';

    if (fingerprints.length) {
      fingerprintId = setupFingerprints(fingerprints);
    }

    return () => {
      if (fingerprints.length && fingerprintId) {
        unloadFingerprints(fingerprints, fingerprintId);
      }
    };
  }, [fingerprints]);

  return null;
};

export default usePaymentFingerprint;
