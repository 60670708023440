import { Hidden } from '@mui/material';
import { ReactNode } from 'react';

import Modal from '@/components/Modal';
import PreviewDrawer, { PreviewDrawerProps } from '@/components/PreviewDrawer';

import { GenericModalProps } from './types';

type Props = Pick<GenericModalProps, 'slotProps' | 'sx' | 'showCloseButton'> & {
  isOpen: boolean;
  onClose?: () => void;
  width?: string | number;
  children?: React.ReactNode;
  title?: ReactNode;
  anchor?: PreviewDrawerProps['anchor'];
  keepMounted?: boolean;
};

export const ModalOnDesktopDrawerOnMobile = (props: Props) => {
  const { isOpen, onClose = () => {}, width, children, title, anchor = 'bottom', slotProps, ...rest } = props;

  return (
    <>
      <Hidden smDown>
        <Modal
          open={isOpen}
          width={width}
          onClose={onClose}
          title={title}
          slotProps={slotProps}
          {...rest}
        >
          {children}
        </Modal>
      </Hidden>
      <Hidden smUp>
        <PreviewDrawer anchor={anchor} open={isOpen} onClose={onClose} title={title} slotProps={slotProps} {...rest}>
          {children}
        </PreviewDrawer>
      </Hidden>
    </>
  );
};

export default ModalOnDesktopDrawerOnMobile;
