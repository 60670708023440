import { useLocation } from 'react-router-dom';
import { useLayoutEffect, useState } from 'react';

export const useActiveTabInRoute = (tabRoutes: string[]) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<string>('');

  useLayoutEffect(() => {
    const sortedRoutes = [...tabRoutes].sort((a, b) => b.length - a.length);

    for (const route of sortedRoutes) {
      if (location.pathname.includes(route)) {
        setActiveTab(route);
        return;
      }
    }

    setActiveTab('');
  }, [location.pathname, tabRoutes]);

  return activeTab;
};

export default useActiveTabInRoute;
