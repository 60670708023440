import { forwardRef, Ref } from 'react';

import ItemSelector, { GenericEntity, ItemSelectorProps } from '@/components/ItemSelector';
import useLoadProductsQuery from '@/queries/useLoadProductsQuery';
import { Product, SellingMode } from '@/services/sellerApiQuery/model';

type Props = Omit<ItemSelectorProps<GenericEntity>, 'items' | 'getItemLabel'> & {
  sellingModes?: SellingMode[];
};

const ProductSelector = forwardRef((props: Props, ref: Ref<HTMLDivElement>) => {
  const { sellingModes, ...rest } = props;
  const [productsQuery] = useLoadProductsQuery(sellingModes);

  const { data: productsData, isLoading } = productsQuery();

  const getName = (product: GenericEntity) => {
    const p = product as Product;
    return p.internalName ?? p.name;
  };

  return (
    <ItemSelector
      {...rest}
      items={productsData || []}
      loading={isLoading}
      getItemLabel={(product) => getName(product)}
      ref={ref}
    />
  );
});

export default ProductSelector;
