import { styled } from '@mui/material/styles';
import LoginWallpaper from '@/assets/wallpaper.svg';
import { Paper, PaperProps } from '@mui/material';

export const Wrapper = styled('div')(() => ({
  backgroundImage: `url(${LoginWallpaper})`,
  backgroundRepeat: 'repeat-x',
  minHeight: '100vh',
  width: '100%',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

}));

const unsafeProps: PropertyKey[] = ['width', 'height'];

interface ContentProps extends PaperProps {
  width: string;
  height: string;
}

export const Content = styled(Paper, {
  shouldForwardProp: (prop) => !unsafeProps.includes(prop),
})<ContentProps>(({ theme, width, height }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: width,
  maxHeight: height,
  overflowY: 'auto',
  padding: theme.spacing(6),
  borderRadius: '18px',
  margin: '1rem'
}));
