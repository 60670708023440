import { TFunction } from 'i18next';
import { z } from 'zod';

export const schema = (t: TFunction<'schedulings'[], undefined>) => {
  const requiredFiledMessage = t('recurrence_selector.required_field');

  const requiredField = z.string({ required_error: requiredFiledMessage }).nonempty({ message: requiredFiledMessage });

  return z
    .object({
      rule: requiredField,
      ruleUntilDate: z.date().optional(),
    })
};
