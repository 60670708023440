import { TFunction } from 'i18next';

const formatDateListToConflictMessage = (dates: Date[], t: TFunction<[string], 'dateConflict'>) => {
  const finalStrings: { index: string; text: string[] }[] = [];
  const finalDates: Record<number, Record<number, number[]>> = {};

  dates.map((date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    if (!finalDates[year]) {
      finalDates[year] = {};
    }
    if (!finalDates[year][month]) {
      finalDates[year][month] = [];
    }
    finalDates[year][month].push(day);
  });

  const isFullMonth = (days: number[], year: string, month: string) => {
    return days.length === new Date(Number(year), Number(month), 0).getDate();
  };

  Object.entries(finalDates).map(([year, months]) => {
    Object.entries(months).map(([month, days]) => {
      const monthName = new Intl.DateTimeFormat('default', { month: 'long' }).format(
        new Date(Number(year), Number(month) - 1),
      );
      finalStrings.push({
        index: `${year}-${month}`,
        text: [
          `${monthName}/${year}`,
          `${
            isFullMonth(days, year, month) ? t('fullMonth') : t('day', { count: days.length, days: days.join(', ') })
          }`,
        ],
      });
    });
  });

  return finalStrings;
};

export default formatDateListToConflictMessage;
