import { downloadQrCode } from '@/utils';
import { QrCode } from './styles';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button from '@/components/Button';
import { DownloadCloud02 } from '@untitled-ui/icons-react';

type DivulgationQrCodeProps = {
  divulgationLink: string;
  onDownload?: () => void;
  fileName?: string;
};

export const DivulgationQrCode = (props: DivulgationQrCodeProps) => {
  const { divulgationLink, onDownload, fileName } = props;

  const { t } = useTranslation(['partnership']);

  const handleDownloadQrCode = () => {
    downloadQrCode('QRCode', fileName);

    onDownload?.();
  };

  return (
    <Grid container sx={{ textAlign: 'center' }} spacing={1}>
      <Grid item xs={12}>
        <QrCode value={divulgationLink} style={{ height: 'auto', maxWidth: '100%', width: '100%' }} id='QRCode' />
      </Grid>

      <Grid item xs={12}>
        <Button
          color='primary'
          variant='contained'
          endIcon={<DownloadCloud02 />}
          onClick={() => handleDownloadQrCode()}
        >
          {t('qr_code.download')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default DivulgationQrCode;
