import { ShareOutlined } from '@mui/icons-material';
import { Box, CircularProgress, Grid, IconButton, InputBase, Paper, Tooltip, Typography } from '@mui/material';
import { Check, Edit05 } from '@untitled-ui/icons-react';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/Button';
import CopyToClipboardButton from '@/components/CopyToClipboardButton';
import {
  DivulgationLinkActionsContainer,
  DivulgationLinkActionsText,
} from '@/components/Partnership/PartnershipDetail/styles';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import useErrorHandler from '@/hooks/useErrorHandler';
import { useSellerApi } from '@/hooks/useSellerApi';
import { ErrorTypes } from '@/interfaces/errorTypes';
import { Partnership, PartnershipsApiUpdateArrangedPartnershipRequest } from '@/services/SellerApi';
import { colors } from '@/theme';
import { removeSpecialCharacters, serializeToQueryString } from '@/utils';

type Props = {
  link: string;
  isProvider: boolean;
  partnership: Partnership;
  onUpdateAffiliationCode: (partnership: Partnership) => void;
};

export const PartnershipDivulgationLink = (props: Props) => {
  const { partnership, link, isProvider, onUpdateAffiliationCode } = props;

  const { track } = useAnalytics();
  const { t } = useTranslation(['partnership']);

  const { partnershipsApi } = useSellerApi();
  const { errorHandler, ErrorValidationRules } = useErrorHandler();

  const [loading, setLoading] = useState(false);

  const [newPartnershipAffiliationCode, setNewPartnershipAffiliationCode] = useState(partnership.affiliationCode ?? '');
  const [showPartnershipAffiliationCodeForm, setShowPartnershipAffiliationCodeForm] = useState(false);

  const shareOnWhatsapp = (partnership: Partnership) => {
    const message = `Acesse o produto através do link ${link}`;

    const params = {
      text: message,
      ...(isProvider && partnership.agent?.contact?.phone ? { phone: partnership.agent!.contact!.phone } : {}),
    };

    track('partnershipDetail/shareQrCode', {
      partnershipId: partnership.id,
      partnership,
    });

    window.open(`https://api.whatsapp.com/send?${serializeToQueryString(params)}`, '_blank')?.focus();
  };

  const handleEditAffiliationCode = async () => {
    const payload: PartnershipsApiUpdateArrangedPartnershipRequest = {
      partnershipId: partnership.id,
      updateArrangedPartnershipParams: {
        affiliationCode: newPartnershipAffiliationCode,
      },
    };

    try {
      setLoading(true);

      const { data } = await partnershipsApi.updateArrangedPartnership(payload);

      enqueueSnackbar(t('detail.affiliation_code_updated_successfully'), { variant: 'success' });

      onUpdateAffiliationCode(data);
    } catch (error) {
      const { errors } = errorHandler(error);

      let errorKeyTitle;
      let errorKey = 'detail.could_not_update_affiliation_code';

      if (errors && errors.length) {
        errors.forEach(({ rule, type }) => {
          if (type === ErrorTypes.VALIDATION && rule === ErrorValidationRules.Unique) {
            errorKey = 'detail.affiliation_code_already_exists_message';
            errorKeyTitle = 'detail.affiliation_code_already_exists';
          }
        });
      }

      enqueueSnackbar(t(errorKey), { variant: 'error', ...(errorKeyTitle ? { title: t(errorKeyTitle) } : {}) });

      track('partnershipDetail/editAffiliationCodeFail', { partnershipId: partnership.id, error });
      setNewPartnershipAffiliationCode(partnership.affiliationCode ?? '');
    } finally {
      setLoading(false);
      setShowPartnershipAffiliationCodeForm(false);
    }
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={3} position='relative'>
        <Grid item xs={12} md={isProvider ? 12 : 8}>
          <Typography component='p' variant='smallSemiBold' mb={1}>
            {t('detail.divulgation_link')}
          </Typography>

          <DivulgationLinkActionsContainer>
            <DivulgationLinkActionsText variant='regularRegular'>{link}</DivulgationLinkActionsText>

            <Box p={1} sx={{ borderLeft: `1px solid ${colors.gray[400]}` }}>
              <CopyToClipboardButton
                text={link}
                color='default'
                onCopy={() => {
                  track('partnershipDetail/copyDivulgationLink', {
                    partnershipId: partnership.id,
                    partnership,
                    text: link,
                  });
                }}
              />
            </Box>

            <Box p={1} sx={{ borderLeft: `1px solid ${colors.gray[400]}` }}>
              <Tooltip title={t('detail.share_on_whatsapp')} placement='top' arrow>
                <IconButton onClick={() => shareOnWhatsapp(partnership)} size='small'>
                  <ShareOutlined fontSize='small' />
                </IconButton>
              </Tooltip>
            </Box>
          </DivulgationLinkActionsContainer>
        </Grid>

        {!isProvider ? (
          <Grid item xs={12} md={4}>
            <Typography variant='smallSemiBold' mb={1} component='p'>
              {t('detail.personal_code')}
            </Typography>

            <Paper
              component='div'
              elevation={0}
              sx={{ display: 'flex', alignItems: 'center', border: `1px solid ${colors.gray[400]}` }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                value={newPartnershipAffiliationCode}
                disabled={!showPartnershipAffiliationCodeForm || loading}
                onChange={(e) =>
                  setNewPartnershipAffiliationCode(removeSpecialCharacters(e.target.value.toUpperCase()))
                }
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleEditAffiliationCode();
                  }
                }}
              />

              {showPartnershipAffiliationCodeForm ? (
                <Button
                  color='primary'
                  onClick={handleEditAffiliationCode}
                  variant={'contained'}
                  sx={{
                    p: '10px',
                    borderRadius: 0,
                    borderLeft: `1px solid ${colors.gray[400]}`,
                  }}
                  disabled={loading}
                >
                  <Check />
                </Button>
              ) : (
                <Button
                  color='secondary'
                  onClick={() => setShowPartnershipAffiliationCodeForm(true)}
                  variant='text'
                  sx={{
                    p: '10px',
                    borderRadius: 0,
                    borderLeft: `1px solid ${colors.gray[400]}`,
                  }}
                  disabled={loading}
                >
                  <Edit05 />
                </Button>
              )}
            </Paper>
          </Grid>
        ) : null}

        {loading ? (
          <Box display='flex' position='absolute' top='50%' left='50%'>
            <CircularProgress />
          </Box>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default PartnershipDivulgationLink;
