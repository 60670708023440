import { ReactNode } from 'react';
import { IconMenu2 } from '@tabler/icons-react';
import { Box, Hidden, IconButton } from '@mui/material';
import PlanneLogo from '@/components/PlanneLogo';
import useUI from '@/hooks/useUI';
import { StyledMobileHeader, StyledMobileHeaderRoot } from './styles';

interface MobileHeaderLayoutProps {
  children: ReactNode;
  showHeader?: boolean;
}
const MobileHeaderLayout = (props: MobileHeaderLayoutProps) => {
  const { children, showHeader = true } = props;
  const { toggleDrawer } = useUI();

  return (
    <StyledMobileHeaderRoot className={showHeader ? 'with-header' : ''}>
      {showHeader && (
        <Hidden mdUp>
          <StyledMobileHeader>
            <IconButton edge='start' color='primary' aria-label='open drawer' onClick={() => toggleDrawer()}>
              <IconMenu2 />
            </IconButton>
            <PlanneLogo size='default' style='dark' />
          </StyledMobileHeader>
        </Hidden>
      )}
      <Box>{children}</Box>
    </StyledMobileHeaderRoot>
  );
};

export default MobileHeaderLayout;
