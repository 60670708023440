import { Stack } from '@mui/system';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import getAvailabilityAdditionalsLabelPrice from '@/features/agenda/utils/availability/getAvailabilityAdditionalsLabelPrice';
import getAvailabilityProductsLabelPrice from '@/features/agenda/utils/availability/getAvailabilityProductsLabelPrice';
import getAvailabilityProductTariffTypesLabelPrice from '@/features/agenda/utils/availability/getAvailabilityProductTariffTypesLabelPrice';
import getAvailabilityTariffsLabelPrice from '@/features/agenda/utils/availability/getAvailabilityTariffsLabelPrice';
import { Availability } from '@/services/SellerApi';
import theme from '@/theme';

type Props = {
  availability: Availability;
};
const AvailabilityWithPrice = (props: Props) => {
  const { availability } = props;
  const { t } = useTranslation(['agenda']);

  const items = useMemo(() => {
    return [
      ...(getAvailabilityTariffsLabelPrice(availability) ?? []),
      ...(getAvailabilityAdditionalsLabelPrice(availability) ?? []),
      ...(getAvailabilityProductTariffTypesLabelPrice(availability) ?? []),
      ...(getAvailabilityProductsLabelPrice(availability) ?? []),
    ];
  }, [availability]);

  const title = t(`filter.availability_type.${availability.type}`);

  return (
    <Stack sx={{ textAlign: 'left' }}>
      <Stack component='span'>{title}</Stack>
      {items.length > 0 ? (
        <Stack component='ul' sx={{ padding: theme.spacing(1) }}>
          {items?.map((item) => (
            <Stack
              component='li'
              key={item.id}
              sx={{ display: 'list-item', listStyle: 'inside', listStyleType: 'disc' }}
            >
              {item.label}
              {item.price ? <Fragment>: {item.price}</Fragment> : null}
            </Stack>
          ))}
        </Stack>
      ) : null}
    </Stack>
  );
};

export default AvailabilityWithPrice;
