import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import { start } from '@/services/bugsnag/index.ts';
import ErrorBoundary from '@/components/Error/ErrorBoundary';
import { AXIOS_INSTANCE } from '@/services/sellerApiQuery/mutator/custom-instance.ts';

start();

/**
 * This is a workaround to set the base URL for the mutator axios instance.
 * Is necessary because the orval generated process does not support `import.meta.` syntax.
 * Is a temporary solution until the orval team implements this feature.
 * @TODO: stay looking on https://github.com/orval-labs/orval/issues/1185#issuecomment-2332323427
 * */
AXIOS_INSTANCE.defaults.baseURL = `${import.meta.env.VITE_SELLER_API_URL as string}/1`;
AXIOS_INSTANCE.defaults.paramsSerializer = (params) => {
  return new URLSearchParams(params).toString();
};

const Root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
);

export default Root;
