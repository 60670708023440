/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Planne Seller API
 * OpenAPI spec version: 0.1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  AccessToken,
  AgreementDocument,
  AuthRecoverPasswordBody,
  AuthResetPasswordBody,
  LoginParams,
  NotFoundResponseResponse,
  SignUpParams,
  UnauthorizedResponseResponse,
  UnprocessableResponseResponse,
  UserWithTokenResponseResponse
} from '../../model'
import { customInstance } from '../../mutator/custom-instance';
import type { ErrorType, BodyType } from '../../mutator/custom-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const authSignup = (
    signUpParams: BodyType<SignUpParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<UserWithTokenResponseResponse>(
      {url: `/auth/signup`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: signUpParams, signal
    },
      options);
    }
  


export const getAuthSignupMutationOptions = <TError = ErrorType<UnauthorizedResponseResponse | UnprocessableResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authSignup>>, TError,{data: BodyType<SignUpParams>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof authSignup>>, TError,{data: BodyType<SignUpParams>}, TContext> => {
    
const mutationKey = ['authSignup'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof authSignup>>, {data: BodyType<SignUpParams>}> = (props) => {
          const {data} = props ?? {};

          return  authSignup(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AuthSignupMutationResult = NonNullable<Awaited<ReturnType<typeof authSignup>>>
    export type AuthSignupMutationBody = BodyType<SignUpParams>
    export type AuthSignupMutationError = ErrorType<UnauthorizedResponseResponse | UnprocessableResponseResponse>

    export const useAuthSignup = <TError = ErrorType<UnauthorizedResponseResponse | UnprocessableResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authSignup>>, TError,{data: BodyType<SignUpParams>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof authSignup>>,
        TError,
        {data: BodyType<SignUpParams>},
        TContext
      > => {

      const mutationOptions = getAuthSignupMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const authLogin = (
    loginParams: BodyType<LoginParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<UserWithTokenResponseResponse>(
      {url: `/auth/login`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: loginParams, signal
    },
      options);
    }
  


export const getAuthLoginMutationOptions = <TError = ErrorType<UnauthorizedResponseResponse | UnprocessableResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authLogin>>, TError,{data: BodyType<LoginParams>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof authLogin>>, TError,{data: BodyType<LoginParams>}, TContext> => {
    
const mutationKey = ['authLogin'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof authLogin>>, {data: BodyType<LoginParams>}> = (props) => {
          const {data} = props ?? {};

          return  authLogin(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AuthLoginMutationResult = NonNullable<Awaited<ReturnType<typeof authLogin>>>
    export type AuthLoginMutationBody = BodyType<LoginParams>
    export type AuthLoginMutationError = ErrorType<UnauthorizedResponseResponse | UnprocessableResponseResponse>

    export const useAuthLogin = <TError = ErrorType<UnauthorizedResponseResponse | UnprocessableResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authLogin>>, TError,{data: BodyType<LoginParams>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof authLogin>>,
        TError,
        {data: BodyType<LoginParams>},
        TContext
      > => {

      const mutationOptions = getAuthLoginMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const authRefresh = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<AccessToken>(
      {url: `/auth/refresh`, method: 'POST', signal
    },
      options);
    }
  


export const getAuthRefreshMutationOptions = <TError = ErrorType<UnauthorizedResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authRefresh>>, TError,void, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof authRefresh>>, TError,void, TContext> => {
    
const mutationKey = ['authRefresh'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof authRefresh>>, void> = () => {
          

          return  authRefresh(requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AuthRefreshMutationResult = NonNullable<Awaited<ReturnType<typeof authRefresh>>>
    
    export type AuthRefreshMutationError = ErrorType<UnauthorizedResponseResponse>

    export const useAuthRefresh = <TError = ErrorType<UnauthorizedResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authRefresh>>, TError,void, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof authRefresh>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getAuthRefreshMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const authLogout = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<void>(
      {url: `/auth/logout`, method: 'POST', signal
    },
      options);
    }
  


export const getAuthLogoutMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authLogout>>, TError,void, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof authLogout>>, TError,void, TContext> => {
    
const mutationKey = ['authLogout'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof authLogout>>, void> = () => {
          

          return  authLogout(requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AuthLogoutMutationResult = NonNullable<Awaited<ReturnType<typeof authLogout>>>
    
    export type AuthLogoutMutationError = ErrorType<unknown>

    export const useAuthLogout = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authLogout>>, TError,void, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof authLogout>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getAuthLogoutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getTermsOfUse = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<AgreementDocument>(
      {url: `/auth/docs/termsOfUse`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTermsOfUseQueryKey = () => {
    return [`/auth/docs/termsOfUse`] as const;
    }

    
export const getGetTermsOfUseQueryOptions = <TData = Awaited<ReturnType<typeof getTermsOfUse>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTermsOfUse>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTermsOfUseQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTermsOfUse>>> = ({ signal }) => getTermsOfUse(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTermsOfUse>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTermsOfUseQueryResult = NonNullable<Awaited<ReturnType<typeof getTermsOfUse>>>
export type GetTermsOfUseQueryError = ErrorType<unknown>



export function useGetTermsOfUse<TData = Awaited<ReturnType<typeof getTermsOfUse>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTermsOfUse>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTermsOfUseQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getPrivacyPolicy = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<AgreementDocument>(
      {url: `/auth/docs/privacyPolicy`, method: 'GET', signal
    },
      options);
    }
  

export const getGetPrivacyPolicyQueryKey = () => {
    return [`/auth/docs/privacyPolicy`] as const;
    }

    
export const getGetPrivacyPolicyQueryOptions = <TData = Awaited<ReturnType<typeof getPrivacyPolicy>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPrivacyPolicy>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPrivacyPolicyQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPrivacyPolicy>>> = ({ signal }) => getPrivacyPolicy(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPrivacyPolicy>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPrivacyPolicyQueryResult = NonNullable<Awaited<ReturnType<typeof getPrivacyPolicy>>>
export type GetPrivacyPolicyQueryError = ErrorType<unknown>



export function useGetPrivacyPolicy<TData = Awaited<ReturnType<typeof getPrivacyPolicy>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPrivacyPolicy>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetPrivacyPolicyQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const authResetPassword = (
    authResetPasswordBody: BodyType<AuthResetPasswordBody>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<void>(
      {url: `/auth/resetPassword`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: authResetPasswordBody, signal
    },
      options);
    }
  


export const getAuthResetPasswordMutationOptions = <TError = ErrorType<UnprocessableResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authResetPassword>>, TError,{data: BodyType<AuthResetPasswordBody>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof authResetPassword>>, TError,{data: BodyType<AuthResetPasswordBody>}, TContext> => {
    
const mutationKey = ['authResetPassword'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof authResetPassword>>, {data: BodyType<AuthResetPasswordBody>}> = (props) => {
          const {data} = props ?? {};

          return  authResetPassword(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AuthResetPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof authResetPassword>>>
    export type AuthResetPasswordMutationBody = BodyType<AuthResetPasswordBody>
    export type AuthResetPasswordMutationError = ErrorType<UnprocessableResponseResponse>

    export const useAuthResetPassword = <TError = ErrorType<UnprocessableResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authResetPassword>>, TError,{data: BodyType<AuthResetPasswordBody>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof authResetPassword>>,
        TError,
        {data: BodyType<AuthResetPasswordBody>},
        TContext
      > => {

      const mutationOptions = getAuthResetPasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const authValidateResetPasswordCode = (
    code: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<void>(
      {url: `/auth/passwordResetCodes/${code}/valid`, method: 'GET', signal
    },
      options);
    }
  

export const getAuthValidateResetPasswordCodeQueryKey = (code: string,) => {
    return [`/auth/passwordResetCodes/${code}/valid`] as const;
    }

    
export const getAuthValidateResetPasswordCodeQueryOptions = <TData = Awaited<ReturnType<typeof authValidateResetPasswordCode>>, TError = ErrorType<NotFoundResponseResponse>>(code: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof authValidateResetPasswordCode>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuthValidateResetPasswordCodeQueryKey(code);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof authValidateResetPasswordCode>>> = ({ signal }) => authValidateResetPasswordCode(code, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(code), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof authValidateResetPasswordCode>>, TError, TData> & { queryKey: QueryKey }
}

export type AuthValidateResetPasswordCodeQueryResult = NonNullable<Awaited<ReturnType<typeof authValidateResetPasswordCode>>>
export type AuthValidateResetPasswordCodeQueryError = ErrorType<NotFoundResponseResponse>



export function useAuthValidateResetPasswordCode<TData = Awaited<ReturnType<typeof authValidateResetPasswordCode>>, TError = ErrorType<NotFoundResponseResponse>>(
 code: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof authValidateResetPasswordCode>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getAuthValidateResetPasswordCodeQueryOptions(code,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const authRecoverPassword = (
    authRecoverPasswordBody: BodyType<AuthRecoverPasswordBody>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<UserWithTokenResponseResponse>(
      {url: `/auth/recoverPassword`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: authRecoverPasswordBody, signal
    },
      options);
    }
  


export const getAuthRecoverPasswordMutationOptions = <TError = ErrorType<UnprocessableResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authRecoverPassword>>, TError,{data: BodyType<AuthRecoverPasswordBody>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof authRecoverPassword>>, TError,{data: BodyType<AuthRecoverPasswordBody>}, TContext> => {
    
const mutationKey = ['authRecoverPassword'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof authRecoverPassword>>, {data: BodyType<AuthRecoverPasswordBody>}> = (props) => {
          const {data} = props ?? {};

          return  authRecoverPassword(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AuthRecoverPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof authRecoverPassword>>>
    export type AuthRecoverPasswordMutationBody = BodyType<AuthRecoverPasswordBody>
    export type AuthRecoverPasswordMutationError = ErrorType<UnprocessableResponseResponse>

    export const useAuthRecoverPassword = <TError = ErrorType<UnprocessableResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authRecoverPassword>>, TError,{data: BodyType<AuthRecoverPasswordBody>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof authRecoverPassword>>,
        TError,
        {data: BodyType<AuthRecoverPasswordBody>},
        TContext
      > => {

      const mutationOptions = getAuthRecoverPasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getAppBuyerTermsOfUse = (
    appId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<AgreementDocument>(
      {url: `/apps/${appId}/site/termsOfUse`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAppBuyerTermsOfUseQueryKey = (appId: string,) => {
    return [`/apps/${appId}/site/termsOfUse`] as const;
    }

    
export const getGetAppBuyerTermsOfUseQueryOptions = <TData = Awaited<ReturnType<typeof getAppBuyerTermsOfUse>>, TError = ErrorType<unknown>>(appId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAppBuyerTermsOfUse>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAppBuyerTermsOfUseQueryKey(appId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAppBuyerTermsOfUse>>> = ({ signal }) => getAppBuyerTermsOfUse(appId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(appId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAppBuyerTermsOfUse>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAppBuyerTermsOfUseQueryResult = NonNullable<Awaited<ReturnType<typeof getAppBuyerTermsOfUse>>>
export type GetAppBuyerTermsOfUseQueryError = ErrorType<unknown>



export function useGetAppBuyerTermsOfUse<TData = Awaited<ReturnType<typeof getAppBuyerTermsOfUse>>, TError = ErrorType<unknown>>(
 appId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAppBuyerTermsOfUse>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetAppBuyerTermsOfUseQueryOptions(appId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppBuyerPrivacyPolicy = (
    appId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<AgreementDocument>(
      {url: `/apps/${appId}/site/privacyPolicy`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAppBuyerPrivacyPolicyQueryKey = (appId: string,) => {
    return [`/apps/${appId}/site/privacyPolicy`] as const;
    }

    
export const getGetAppBuyerPrivacyPolicyQueryOptions = <TData = Awaited<ReturnType<typeof getAppBuyerPrivacyPolicy>>, TError = ErrorType<unknown>>(appId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAppBuyerPrivacyPolicy>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAppBuyerPrivacyPolicyQueryKey(appId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAppBuyerPrivacyPolicy>>> = ({ signal }) => getAppBuyerPrivacyPolicy(appId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(appId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAppBuyerPrivacyPolicy>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAppBuyerPrivacyPolicyQueryResult = NonNullable<Awaited<ReturnType<typeof getAppBuyerPrivacyPolicy>>>
export type GetAppBuyerPrivacyPolicyQueryError = ErrorType<unknown>



export function useGetAppBuyerPrivacyPolicy<TData = Awaited<ReturnType<typeof getAppBuyerPrivacyPolicy>>, TError = ErrorType<unknown>>(
 appId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAppBuyerPrivacyPolicy>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetAppBuyerPrivacyPolicyQueryOptions(appId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



