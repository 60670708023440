import { Box, Stack, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import IconButton from '@/components/Button';

export const Header = styled('header', {
  shouldForwardProp: (prop) => prop !== 'withSearch',
})<{ withSearch: boolean }>(({ theme, withSearch = false }) => ({
  display: 'grid',
  gridTemplateAreas: withSearch
    ? `
      'left left right'
      'middle middle middle'
      'tabs tabs tabs'
    `
    : `
      'left middle right'
      'tabs tabs tabs'
    `,
  gap: theme.spacing(2),
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(2),

  borderBottom: `2px solid ${theme.palette.colors.gray[200]}`,

  gridTemplateColumns: '1fr auto auto',

  [theme.breakpoints.up('md')]: {
    gridTemplateAreas: `
    'left middle right'
    'tabs tabs tabs'
  `,
  },
}));

export const Title = styled(Typography)<{ component: TypographyProps['component'] }>(() => ({
  display: 'inline-block',
  textOverflow: 'ellipsis',
  verticalAlign: 'middle',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '100%',
  lineHeight: 'normal',
}));

export const SecondaryAction = styled(IconButton)(({ theme }) => ({
  gap: '8px',
  padding: '8px',
  display: 'flex',
  borderRadius: '4px',
  justifyContent: 'flex-end',
  background: theme.palette.colors.gray[50],

  '&:hover': {
    background: theme.palette.colors.gray[50],
  },
}));

export const HeaderLeftContainer = styled(Stack)(({ theme }) => ({
  gridArea: `left`,
  justifyContent: 'center',
  width: '100%',
  paddingLeft: theme.spacing(4),
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const HeaderMiddleContainer = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'withSearch',
})<{ withSearch: boolean }>(({ theme, withSearch = false }) => ({
  gridArea: 'middle',
  flexDirection: 'row',
  alignContent: 'center',
  width: '100%',
  gap: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    ...(withSearch && {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    }),
  },
}));

export const HeaderRightContainer = styled(Stack)(({ theme }) => ({
  gridArea: 'right',
  width: '100%',
  maxWidth: '100%',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
  paddingRight: theme.spacing(6),

  [theme.breakpoints.down('md')]: {
    paddingRight: theme.spacing(4),
    gap: theme.spacing(1),
  },
}));

export const HeaderTabsContainer = styled(Stack)(({ theme }) => ({
  gridArea: 'tabs',
  marginBottom: `calc((2px + ${theme.spacing(2)}) * -1)`,
}));

export const Divider = styled(Box)(({ theme }) => ({
  width: '1px',
  height: '100%',
  padding: theme.spacing(1, 0),
  marginRight: theme.spacing(1),
  backgroundColor: theme.palette.grey[400],
}));
