import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ArtSection } from './styles';

import PlanneLogoDesktop from '@/assets/logos/planneLogoDesktop.svg';

const SideArt = () => {
  const { t } = useTranslation(['login']);
  const artKeys = ['login_side_art_platform_text', 'login_side_art_ecommerce_text', 'login_side_art_experience_text'];

  return (
    <ArtSection container alignItems='center' justifyContent='center'>
      <Grid item>
        <Grid container rowSpacing={5} direction='column' alignItems='flex-start'>
          <Grid item>
            <img src={PlanneLogoDesktop} alt='desktop logo' width={345} height={87} />
          </Grid>

          <Grid item>
            <section>
              {artKeys.map((art) => (
                <div key={art}>
                  <Typography variant='h4' color='colors.blue.800' sx={{ lineHeight: 2.3 }}>
                    {t(art)}
                  </Typography>
                </div>
              ))}
            </section>
          </Grid>
        </Grid>
      </Grid>
    </ArtSection>
  );
};

export default SideArt;
