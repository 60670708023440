import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';

import useGetSaleVouchers from '../useCases/useGetSaleVouchers';
import { useTranslation } from 'react-i18next';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import { AnalyticsEvents } from '@/hooks/analytics/enum/analyticsEvents';

const useLoadSaleVouchers = () => {
  const { request: getSaleVouchers } = useGetSaleVouchers();
  const { t } = useTranslation(['vouchers']);
  const { track } = useAnalytics();
  const { enqueueSnackbar } = useSnackbar();

  const useRequestQuery = (saleId: string) => {
    return useQuery({
      queryKey: ['sale-vouchers', saleId],
      queryFn: getSaleVouchers,
      enabled: !!saleId,
      staleTime: Infinity,
      throwOnError: (error) => {
        enqueueSnackbar(t('snackbars.could_not_get_sale_vouchers'), { variant: 'error' });
        track(AnalyticsEvents.GET_VOUCHERS_FAIL, { error });

        return false;
      },
    });
  };

  return [useRequestQuery];
};

export default useLoadSaleVouchers;
