import ValueWithLabel from '@/components/ValueWithLabel';
import { colors } from '@/theme';

type Props = {
  label: string;
  value: string;
};
export const PartnershipValueWithLabel = (props: Props) => {
  const { label, value } = props;

  return (
    <ValueWithLabel
      title={label}
      value={value}
      slotProps={{
        title: {
          overflow: 'hidden',
          variant: 'regularBold',
          color: colors.gray[500],
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          mr: 0.5,
        },
        value: {
          component: 'p',
          overflow: 'hidden',
          color: colors.gray[500],
          textOverflow: 'ellipsis',
          variant: 'regularRegular',
          whiteSpace: 'nowrap',
        },
        container: {
          flexDirection: 'row',
        },
      }}
    />
  );
};

export default PartnershipValueWithLabel;
