import { QueryFunctionContext } from '@tanstack/react-query';

import { useSellerApi } from '@/hooks/useSellerApi';
import { Voucher, VouchersApiGetSaleVouchersRequest } from '@/services/SellerApi';
import { RequiredError } from '@/services/SellerApi/base';
import removeDuplicates from '@/utils/removeDuplicates';

type Query = [key: string, saleId: string];

const useGetSaleVouchers = () => {
  const { vouchersApi } = useSellerApi();

  const request = async ({ queryKey, signal }: QueryFunctionContext<Query>): Promise<Voucher[] | undefined> => {
    const [_, saleId] = queryKey;

    const pageSize = 10;
    const payload: VouchersApiGetSaleVouchersRequest = {
      saleId,
      revokedStatus: 'all',
      limit: pageSize,
      include: [
        'product',
        'tariffs.tariff.type',
        'additionals.additional',
        'additionals.saleAdditional',
        'resources.saleResource.resource',
      ],
    };

    try {
      const { data, headers } = await vouchersApi.getSaleVouchers(
        {
          ...payload,
          offset: 0,
          limit: 1,
        },
        { signal },
      );

      const totalCount = parseInt(headers['x-pagination-total-count'] ?? '0');
      const totalPages = Math.ceil(totalCount / pageSize);

      const promises = [];

      for (let page = 0; page <= totalPages - 1; page++) {
        promises.push(
          vouchersApi.getSaleVouchers(
            {
              ...payload,
              offset: page * pageSize,
            },
            { signal },
          ),
        );
      }

      const dataArray = await Promise.all(promises);

      const allVouchers = dataArray.reduce((accumulator: Voucher[], response) => {
        return accumulator.concat(response.data);
      }, data);

      return removeDuplicates(allVouchers, 'id');
    } catch (error) {
      return error instanceof RequiredError ? [] : undefined;
    }
  };

  return { request };
};

export default useGetSaleVouchers;
