import {
  StyledTimeCellSelectRoot,
  StyledTimeCellSelectSkeleton,
} from '@/features/reservation/components/RescheduleForm/RescheduleDateTimeSelector/TimeCellSelect/styles';
import { TimeCellSelectProps } from '@/features/reservation/components/RescheduleForm/RescheduleDateTimeSelector/TimeCellSelect/types';

const TimeCellSelect = (props: TimeCellSelectProps) => {
  const { color = 'neutral', isLoading = false, selected = false, disabled = false, children, ...rest } = props;
  return !isLoading ? (
    <StyledTimeCellSelectRoot
      variant='contained'
      status={color}
      color={selected ? 'primary' : 'neutral'}
      disabled={disabled}
      {...rest}
    >
      {children}
    </StyledTimeCellSelectRoot>
  ) : (
    <StyledTimeCellSelectSkeleton variant='rectangular' />
  );
};

export default TimeCellSelect;
