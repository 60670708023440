import { Box, Typography, css, styled } from '@mui/material';

export const TariffContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '14px 15px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
  alignSelf: 'stretch',

  borderRadius: '4px',
  border: '1px solid' + theme.palette.colors.gray[400],
}));

export const Tariff = styled(Box)(
  () => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: '2px';
    align-self: stretch;
  `,
);

export const TariffDetails = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignSelf: 'stretch',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  gap: '16px',
}));

export const TariffInformation = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'stretch',
  alignItems: 'flex-start',
}));

export const DependentTariffError = styled(Typography)(({ theme }) => ({
  mt: theme.spacing(0.5),
  color: theme.palette.colors.red[700],
  background: theme.palette.colors.red[50],
  padding: '4px',
  borderRadius: '2px',
  alignSelf: 'stretch',
}));
