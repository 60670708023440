import { styled } from '@mui/material/styles';

export const SearchWrapper = styled('div')(({ theme }) => ({
  padding: '16px',
  backgroundColor: theme.palette.colors.gray[50],
  borderRadius: '4px',
  marginBottom: '16px',
}));

export const LogoutContainer = styled('div')(({ theme }) => ({
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: theme.spacing(4),
  position: 'fixed',
  backgroundColor: theme.palette.common.white,
  bottom: '0',
  left: '0',
  padding: theme.spacing(4),
}));

export const Divider = styled('div')(({ theme }) => ({
  width: '100%',
  height: '1px',
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.grey[400],
}));
