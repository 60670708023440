import { Box } from '@mui/material';
import { styled, css } from '@mui/material/styles';

export const ImageWrapper = styled(Box)(
  () => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(234, 235, 250, 0.3);
    border-radius: 100%;
    padding: 16px 18px;
  `,
);

export const StyledImage = styled('img')(({ width, height }) => ({
  width: width ?? '100%',
  height: height ?? '100%',
  objectFit: 'cover',
}));
