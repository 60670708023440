import { styled, css } from '@mui/material/styles';
import { Container as MUIContainer } from '@mui/material';

export const Container = styled(MUIContainer)(
  ({ theme }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: max-content;
    margin-top: 60px;
    padding: 8px;

    ${theme.breakpoints.up('sm')} {
      padding: 16px;
    }
  `,
);
