import { Stack } from '@mui/material';
import { Check } from '@untitled-ui/icons-react';
import { isSameWeek } from 'date-fns';
import { Fragment, useEffect, useState } from 'react';

import { useAgenda } from '@/features/agenda/hooks/useAgenda';
import { WeekCalendarItem } from '@/features/agenda/types';
import theme from '@/theme';

import { StyledCalendarWeekItem } from './styles';

interface WeekSlotProps {
  week: WeekCalendarItem;
  active?: boolean;
  onClick?: () => void;
}

const WeekSlot = ({ week, onClick, active = false }: WeekSlotProps) => {
  return (
    <StyledCalendarWeekItem active={active} onClick={onClick}>
      {active ? <Check color={theme.palette.colors.primary['500']} fontSize='1rem' /> : null}
      {`${week.firstDay.toLocaleDateString(undefined, { day: '2-digit', month: 'long' })} - ${week.lastDay.toLocaleDateString(undefined, { day: '2-digit', month: 'long' })}`}
    </StyledCalendarWeekItem>
  );
};

interface WeekCalendarProps {
  activeDate: Date;
}

const WeekCalendar = (props: WeekCalendarProps) => {
  const { activeDate } = props;
  const { getWeekDates, activeDate: currentDate, updateDate } = useAgenda();
  const [currentWeeks, setCurrentWeeks] = useState<WeekCalendarItem[]>(getWeekDates(activeDate));

  const onChangeSelectedWeek = (week: WeekCalendarItem) => {
    updateDate(week.firstDay);
  };

  useEffect(() => {
    setCurrentWeeks(getWeekDates(activeDate));
  }, [activeDate]);

  return (
    <Stack gap='0.5rem'>
      {currentWeeks.map((week) => (
        <Fragment key={week.firstDay.toISOString()}>
          <WeekSlot
            active={isSameWeek(currentDate, week.lastDay, { weekStartsOn: 0 })}
            week={week}
            onClick={() => onChangeSelectedWeek(week)}
          />
        </Fragment>
      ))}
    </Stack>
  );
};
export default WeekCalendar;
