import { format as formatFNS } from 'date-fns';

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const downloadQrCode = (qrCodeId: string, fileName?: string) => {
  const svg = document.getElementById(qrCodeId);
  const svgData = new XMLSerializer().serializeToString(svg!);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const img = new Image();
  img.onload = () => {
    canvas.width = img.width;
    canvas.height = img.height;
    ctx!.drawImage(img, 0, 0);
    const pngFile = canvas.toDataURL('image/png');
    const downloadLink = document.createElement('a');
    downloadLink.download = fileName ?? 'QRCode';
    downloadLink.href = `${pngFile}`;
    downloadLink.click();
  };
  img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
};

export const getAllQueryParams = () => {
  const params = new URLSearchParams(window.location.search);

  return Array.from(params.keys()).reduce((acc, val) => ({ ...acc, [val]: params.get(val) }), {});
};

export const serializeToQueryString = (obj: { [key: string]: string | undefined }) => {
  const str: string[] = [];

  Object.keys(obj).forEach((p) => {
    if (p in obj) {
      if (obj[p]) {
        str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p] as string)}`);
      }
    }
  });

  return str.join('&');
};

export const formatCurrency = (price: number) => price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

export const formatCents = (price: number) => formatCurrency(price / 100);

export const formatDate = (date: Date, format = 'dd/MM/yyyy') => formatFNS(date, format);

export const parseDate = (date?: string) => {
  if (!date) return undefined;

  return new Date(date);
};

export const scrollIntoElement = (selector: string) => {
  const element = document.querySelector(selector);

  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

export const removeSpecialCharacters = (text: string) => {
  return text.replace(/[^A-Z0-9]/g, '');
};

export const initialPaginationState = { page: 0, pageSize: 20 };
export const limitTags = 5;

export const newWindowAppNavigation = (appCode: string, href: string) => window.open(`/${appCode}/${href}`, '_blank');

export function getMonthViewDates(year: number, month: number) {
  const startDate = new Date(year, month, 1);

  const startDayOfWeek = startDate.getDay();

  const daysToSubtract = startDayOfWeek === 0 ? 0 : startDayOfWeek;

  const startOfMonthView = new Date(year, month, 1 - daysToSubtract);

  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const endOfMonthView = new Date(year, month, daysInMonth + (6 - startDayOfWeek));

  return {
    startDate: startOfMonthView,
    endDate: endOfMonthView,
  };
}

export const downloadBlobFile = (blob: Blob, fileName: string) => {
  const href = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const dateWithTimezone = (date: string) => {
  const dt = new Date(date);
  const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);

  return dtDateOnly;
};

export const formatWithoutTimezone = (date: Date) => formatDate(date, "yyy-MM-dd'T'HH:mm:ss'.00Z'");

export const randomString = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const getWhatsappUrl = (phone: string, text?: string) => {
  const params = {
    text,
  };

  return `https://wa.me/${phone.replace(/\D/g, '')}?${serializeToQueryString(params)}`;
};

export const isTimeInRange = (time: string, startTime: string, endTime: string): boolean => {
  // Convert time strings to Date objects
  const timeDate: Date = new Date(`2000-01-01 ${time}`);
  const startDateTime: Date = new Date(`2000-01-01 ${startTime}`);
  const endDateTime: Date = new Date(`2000-01-01 ${endTime}`);

  // Check if the time is between the start and end time
  return timeDate >= startDateTime && timeDate <= endDateTime;
};
