import { zodResolver } from '@hookform/resolvers/zod';
import { isAxiosError } from 'axios';
import { cpf } from 'cpf-cnpj-validator';
import { camelCase } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import EstablishmentForm from '@/components/CreateApp/EstablishmentForm';
import { schema as establishmentFormSchema } from '@/components/CreateApp/EstablishmentForm/schema';
import FinancialForm from '@/components/CreateApp/FinancialForm';
import { schema as financialFormSchema } from '@/components/CreateApp/FinancialForm/schema';
import { serializeFormData } from '@/components/CreateApp/helper';
import OwnerForm from '@/components/CreateApp/OwnerForm';
import { schema as ownerFormSchema } from '@/components/CreateApp/OwnerForm/schema';
import { EstablishmentTypeInitialValue, signupSteps } from '@/components/CreateApp/signupSteps';
import SuccessModalBody from '@/components/CreateApp/SuccessModalBody';
import Modal from '@/components/Modal';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import useAccount from '@/hooks/useAccount';
import useAuth from '@/contexts/Auth/hooks/useAuth';
import useErrorHandler from '@/hooks/useErrorHandler';
import { useSellerApi } from '@/hooks/useSellerApi';
import { ErrorTypes } from '@/interfaces/errorTypes';
import RegisterLayout from '@/layouts/RegisterLayout';
import { BankAccountType, BankOption, CreateAppParams, EstablishmentType } from '@/services/SellerApi';

export type DefaultValueFields = {
  identifier: string;
  establishmentType: EstablishmentType;
  fantasyName?: string;
  corporateName: string;
  contactPhone: string;
  openedAt: Date | null;
  bankIdentifier: string;
  bankCode: BankOption;
  bankAgency: string;
  bankAccount: string;
  bankAccountType: BankAccountType;
  expectedMonthlyRevenueCents: string;
  ownerName: string;
  ownerBirthdate: Date | null;
  ownerCpf: string;
  zipCode?: string;
  street?: string;
  number?: string;
  complement?: string;
  uf?: string;
  city?: string;
  district?: string;
};

type Fields = keyof DefaultValueFields;

export const CreateApp = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { track } = useAnalytics();

  const { t } = useTranslation(['createApp']);

  const { logout, user, refresh } = useAuth();

  const { refreshAccounts } = useAccount();

  const { accounts } = useAccount();
  const { appsApi } = useSellerApi();

  const { errorHandler } = useErrorHandler();

  const { t: translateGenericError } = useTranslation(['errors']);

  const [currentStep, setCurrentStep] = useState(signupSteps[1]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [state, setState] = useState<DefaultValueFields>({} as DefaultValueFields);

  const moveUserToStepWithError = useRef(false);
  const isLastStep = signupSteps[signupSteps.length - 1].step === currentStep.step;

  const establishmentForm = useForm<DefaultValueFields>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: { ...state, establishmentType: state.establishmentType || EstablishmentTypeInitialValue },
    resolver: zodResolver(establishmentFormSchema),
  });

  const financialForm = useForm<DefaultValueFields>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: { ...state, bankCode: state.bankCode || { code: '', shortName: '', longName: '' } },
    resolver: zodResolver(financialFormSchema),
  });

  const ownerForm = useForm<DefaultValueFields>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: state,
    resolver: zodResolver(ownerFormSchema(cpf.isValid(state?.identifier ?? ''))),
  });

  const onSubmit = async (data: CreateAppParams) => {
    try {
      const createAppParams = { ...data };
      createAppParams.contact.name = user?.name || '';

      await appsApi.createApp({ createAppParams });

      track('createApp/createAppRequest/success');

      await refresh();
      await refreshAccounts();

      setShowSuccessModal(true);
    } catch (err) {
      const pagePath = 'createAppPage/createAppRequest/error';
      const { errors: handlerErrors, status, statusText } = errorHandler(err, pagePath);

      if (handlerErrors) {
        handlerErrors.forEach(({ path, type, rule }) => {
          if (type === ErrorTypes.VALIDATION) {
            const field = camelCase(path!.split('.').slice(1).slice(-2).join(' ')) as Fields;
            const signupStepsCopy = signupSteps.map((step) => {
              if (step.step === 'ESTABLISHMENT_DATA') {
                return {
                  ...step,
                  fields: [...step.fields, 'merchantCategoryCode'],
                };
              }

              return step;
            });

            const stepWithError = signupStepsCopy.find((step) => step.fields.includes(field));
            const options = { message: t(`${rule}_${field}`) };

            if (!stepWithError) {
              return ownerForm.setError('root.serverError', options);
            }

            moveUserToStepWithError.current = true;

            switch (stepWithError.step) {
              case 'ESTABLISHMENT_DATA': {
                const newField =
                  (field as Fields | 'merchantCategoryCode') === 'merchantCategoryCode' ? 'establishmentType' : field;

                return establishmentForm.setError(newField, options);
              }
              case 'FINANCIAL_DATA':
                return financialForm.setError(field, options);
              case 'OWNER_DATA':
                return ownerForm.setError(field, options);
              default:
                return ownerForm.setError('root.serverError', options);
            }
          } else {
            ownerForm.setError('root.serverError', { message: translateGenericError(rule) });
          }
        });

        return track(pagePath, {
          message: handlerErrors.map(({ rule }) => translateGenericError(rule)).join(', '),
          status,
          statusText,
          error: isAxiosError(err) ? err.response?.data : err,
        });
      }

      const message = translateGenericError('generic_default_error');
      ownerForm.setError('root.genericError', { message });
    }
  };

  const handleCreateAppConfirm = useCallback(() => {
    if (location.state && location.state?.redirectTo) {
      const { redirectTo } = location.state;
      location.state.redirectTo = null;

      navigate(redirectTo, { replace: true });
    } else {
      navigate('/');
    }
    setShowSuccessModal(false);
  }, []);

  const getCurrentStepIndex = useCallback(() => {
    return signupSteps.indexOf(currentStep);
  }, [currentStep]);

  const handleNextStep = () => {
    if (isLastStep) return;

    setCurrentStep(signupSteps[getCurrentStepIndex() + 1]);
  };

  const onEstablishmentFormSubmit = (data: DefaultValueFields) => {
    handleNextStep();
    setState((previousState) => ({ ...previousState, ...data }));
  };

  const onFinancialFormSubmit = (data: DefaultValueFields) => {
    handleNextStep();
    setState((previousState) => ({ ...previousState, ...data }));
  };

  const onOwnerFormSubmit = async (data: DefaultValueFields) => {
    const newState = { ...state, ...data };

    await onSubmit(serializeFormData(newState) as CreateAppParams);
    setState(newState);
  };

  const handleGoBack = () => {
    if (getCurrentStepIndex() === 1) {
      if (accounts.length === 0) {
        logout();
        return;
      }
      navigate(-1);
      return;
    }

    setCurrentStep(signupSteps[getCurrentStepIndex() - 1]);
  };

  const { errors, loading } = useMemo(() => {
    const errors = {
      ...ownerForm.formState.errors,
      ...financialForm.formState.errors,
      ...establishmentForm.formState.errors,
    };

    const loading = ownerForm.formState.isSubmitting;

    return { errors, loading };
  }, [establishmentForm.formState, financialForm.formState, ownerForm.formState]);

  useEffect(() => {
    if (Object.keys(errors).length > 0 && moveUserToStepWithError.current) {
      moveUserToStepWithError.current = false;

      for (const step of signupSteps) {
        const fieldsWithError = step.fields.filter((field) => field in errors);
        if (fieldsWithError.length > 0) {
          const stepIndex = signupSteps.indexOf(step);
          setCurrentStep(signupSteps[stepIndex]);
          break;
        }
      }
    }
  }, [errors]);

  useEffect(() => {
    track('createApp/access');
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('create_app_page_title')}</title>
      </Helmet>

      <RegisterLayout
        loading={loading}
        onGoBack={handleGoBack}
        title={t('create_app_page_description')}
        currentStep={getCurrentStepIndex()}
        stepTitle={t(`${currentStep.title}`)}
        steps={signupSteps.map((_, index) => index)}
        onCloseAlert={() => ownerForm.clearErrors('root.serverError')}
        error={errors.root?.serverError?.message || errors.root?.genericError?.message}
        breakpoints={
          isLastStep && cpf.isValid(state.identifier ?? '')
            ? {
                xs: 12,
                sm: 11,
                md: 10,
              }
            : undefined
        }
      >
        {currentStep.step === 'ESTABLISHMENT_DATA' ? (
          <EstablishmentForm form={establishmentForm} onSubmit={onEstablishmentFormSubmit} />
        ) : null}

        {currentStep.step === 'FINANCIAL_DATA' ? (
          <FinancialForm defaultValues={state} form={financialForm} onSubmit={onFinancialFormSubmit} />
        ) : null}

        {currentStep.step === 'OWNER_DATA' ? (
          <OwnerForm form={ownerForm} onSubmit={onOwnerFormSubmit} defaultValues={state} />
        ) : null}
      </RegisterLayout>

      <Modal
        open={showSuccessModal}
        showCloseButton={false}
        acceptButtonText={t('code_step_confirm_email_text')}
        children={
          <SuccessModalBody
            onClose={handleCreateAppConfirm}
            description={t('confirmation_modal_title')}
            buttonText={t('confirmation_modal_accept_button')}
          />
        }
      />
    </>
  );
};

export default CreateApp;
