import { styled } from '@mui/material';

export const StyledSlotContainer = styled('div')(({ theme }) => ({
  borderBottom: '1px solid',
  borderColor: theme.palette.grey[200],
  [`&:last-child`]: {
    borderBottom: 'none',
  },
  [theme.breakpoints.up('md')]: {
    [`& .actions`]: {
      display: 'none',
    },

    [`&:hover .actions`]: {
      display: 'flex',
    },
  },
}));
