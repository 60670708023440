import { Stack, useMediaQuery } from '@mui/system';
import { ArrowLeft, ArrowRight } from '@untitled-ui/icons-react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/Button';
import theme from '@/theme';

type Props = {
  onTodayClick: () => void;
  isTodayDisabled: boolean;
  prev: () => void;
  next: () => void;
};

const TimeNavigationActions = (props: Props) => {
  const { onTodayClick, isTodayDisabled, prev, next } = props;
  const { t } = useTranslation(['agenda']);
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Fragment>
      <Button color='secondary' variant='outlined' onClick={onTodayClick} disabled={isTodayDisabled}>
        {t('date.today')}
      </Button>
      {!isTablet ? (
        <Stack direction='row' spacing={1}>
          <Button color='primary' variant='outlined' onClick={prev}>
            <ArrowLeft />
          </Button>

          <Button color='primary' variant='outlined' onClick={next}>
            <ArrowRight />
          </Button>
        </Stack>
      ) : null}
    </Fragment>
  );
};

export default TimeNavigationActions;
