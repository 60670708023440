import { isSameDay } from 'date-fns';

import { DateSlot } from '@/features/agenda/types';

const generateCalendarMonthDays = (activeDate: Date) => {
  const calendar: DateSlot[] = [];
  // First day of the active month
  const firstOfMonth = new Date(activeDate.getFullYear(), activeDate.getMonth(), 1);
  // Last day of the active month
  const lastOfMonth = new Date(activeDate.getFullYear(), activeDate.getMonth() + 1, 0);

  // Find the first day of the calendar (start with Sunday)
  const firstDayCalendar = new Date(firstOfMonth);
  while (firstDayCalendar.getDay() !== 0) {
    // 0 is Sunday
    firstDayCalendar.setDate(firstDayCalendar.getDate() - 1);
  }

  // Find the last day of the calendar (end with Saturday)
  const lastDayCalendar = new Date(lastOfMonth);
  while (lastDayCalendar.getDay() !== 6) {
    // 6 is Saturday
    lastDayCalendar.setDate(lastDayCalendar.getDate() + 1);
  }

  // Iterate from firstDayCalendar to lastDayCalendar included
  for (
    const currentDay = new Date(firstDayCalendar);
    currentDay <= lastDayCalendar;
    currentDay.setDate(currentDay.getDate() + 1)
  ) {
    // Clone the currentDay to prevent reference issues
    const day = new Date(currentDay);
    if (!calendar.find((date) => isSameDay(date.date, day))) {
      calendar.push({ date: day, total: { schedulings: 0, availabilities: 0 } });
    }
  }

  return calendar;
};

export default generateCalendarMonthDays;
