import { Grid } from '@mui/material';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Controller, UseFormReturn, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NotifiableError } from '@bugsnag/js';

import Button from '@/components/Button';
import DatePicker from '@/components/Form/DatePicker';
import Input from '@/components/Form/Input';
import PhoneInput from '@/components/Form/PhoneInput';
import Select from '@/components/Form/Select';
import { DefaultValueFields } from '@/pages/App/Create';
import { useSellerApi } from '@/hooks/useSellerApi';
import bugsnag from '@/services/bugsnag';
import { EstablishmentType, EstablishmentTypesApiGetEstablishmentTypesRequest } from '@/services/SellerApi';
import removeDuplicates from '@/utils/removeDuplicates';
import { EstablishmentTypeInitialValue } from '../signupSteps';

type Props = {
  onSubmit: (data: DefaultValueFields) => void;
  form: UseFormReturn<DefaultValueFields, unknown>;
};

const getFormType = (value: string) => {
  if (cpf.isValid(value)) return 'CPF';

  if (cnpj.isValid(value)) return 'CNPJ';

  return null;
};

export const EstablishmentForm = (props: Props) => {
  const { onSubmit, form } = props;
  const { t } = useTranslation(['createApp']);
  const { formState, register, control, handleSubmit } = form;
  const identifierFields = useWatch({ control, name: 'identifier' });
  const [formType, setFormType] = useState<'CNPJ' | 'CPF' | null>(() => getFormType(identifierFields));

  const [establishmentType, setEstablishmentType] = useState<EstablishmentType[]>([]);
  const [loadingEstablishmentType, setLoadingEstablishmentType] = useState(true);
  const { establishmentTypesApi } = useSellerApi();

  const { errors } = formState;
  const isCNPJ = formType === 'CNPJ';
  const isCPF = formType === 'CPF';

  const handleCNPJCPFChange = debounce((value: string) => {
    const formType = getFormType(value);
    setFormType(formType);
  }, 500);

  const getEstablishmentTypes = useCallback(async () => {
    const pageSize = 10;

    const payload: EstablishmentTypesApiGetEstablishmentTypesRequest = {
      limit: pageSize,
      offset: 0,
    };

    try {
      setLoadingEstablishmentType(true);

      const { data, headers } = await establishmentTypesApi.getEstablishmentTypes({
        ...payload,
        limit: 1,
      });

      const totalCount = parseInt(headers['x-pagination-total-count'] ?? '0');
      const totalPages = Math.ceil(totalCount / pageSize);

      const promises = [];

      for (let page = 0; page <= totalPages - 1; page++) {
        promises.push(
          establishmentTypesApi.getEstablishmentTypes({
            ...payload,
            offset: page * pageSize,
          }),
        );
      }

      const dataArray = await Promise.all(promises);

      const allPages = dataArray.reduce((accumulator: EstablishmentType[], response) => {
        return accumulator.concat(response.data);
      }, data);

      const otherOption: EstablishmentType = {
        mcc: 'other',
        id: 'other',
        name: t('establishment_type_other_name'),
        updatedAt: new Date().toLocaleDateString(),
        createdAt: new Date().toLocaleDateString(),
      };

      const options = [...removeDuplicates(allPages, 'id'), otherOption];
      setEstablishmentType(options);
    } catch (error) {
      bugsnag.notify(error as NotifiableError);
    } finally {
      setLoadingEstablishmentType(false);
    }
  }, []);

  useEffect(() => {
    getEstablishmentTypes();
  }, []);

  const isOptionEqualToValue = (option: EstablishmentType, value: EstablishmentType) => option.id === value.id;
  const getOptionLabel = (option: EstablishmentType) => option.name;
  const getOptionKey = (option: EstablishmentType) => option.id;

  return (
    <Grid
      container
      rowSpacing={2}
      component='form'
      alignItems='center'
      justifyContent='center'
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item xs={12}>
        <Controller
          name='identifier'
          control={control}
          render={({ field: { name, onChange, ...rest } }) => (
            <Input
              required
              fullWidth
              id={name}
              mask='cpf_cnpj'
              label={t('cnpj_cpf_identifier_label')}
              error={!!errors[`${name}`]}
              helperText={errors[`${name}`]?.message ?? t('cnpj_cpf_identifier_helper')}
              onChange={({ target }) => {
                onChange(target.value);
                handleCNPJCPFChange(target.value);
              }}
              {...rest}
            />
          )}
        />
      </Grid>

      {isCNPJ || isCPF ? (
        <Grid item xs={12}>
          <Controller
            control={control}
            name='establishmentType'
            render={({ field: { name, onChange, value, ...rest } }) => (
              <Select
                fullWidth
                required={isCNPJ || isCPF}
                id={name}
                openOnFocus={!!errors[`${name}`]}
                options={establishmentType}
                loading={loadingEstablishmentType}
                loadingText={t('loading_establishment_types')}
                label={t('establishment_type_label')}
                getOptionKey={(option) => getOptionKey(option as EstablishmentType)}
                getOptionLabel={(option) => getOptionLabel(option as EstablishmentType)}
                isOptionEqualToValue={(option, value) =>
                  isOptionEqualToValue(option as EstablishmentType, value as EstablishmentType)
                }
                error={!!errors[`${name}`]}
                helperText={errors[`${name}`]?.message}
                onChange={(_, value) => onChange(value || EstablishmentTypeInitialValue)}
                value={value}
                {...rest}
              />
            )}
          />
        </Grid>
      ) : null}

      {isCNPJ ? (
        <Grid item xs={12}>
          <Input
            fullWidth
            required={isCNPJ}
            id='fantasyName'
            label={t('fantasy_name_label')}
            error={!!errors['fantasyName']}
            helperText={errors['fantasyName']?.message}
            {...register('fantasyName')}
          />
        </Grid>
      ) : null}

      {isCNPJ || isCPF ? (
        <Grid item xs={12}>
          <Controller
            name='corporateName'
            control={control}
            render={({ field: { name, onChange, value, ...rest } }) => (
              <Input
                fullWidth
                required={isCNPJ || isCPF}
                id={name}
                error={!!errors[`${name}`]}
                value={value}
                helperText={errors[`${name}`]?.message}
                label={t(isCNPJ ? 'cnpj_corporate_name_label' : 'cpf_corporate_name_label')}
                onChange={({ target }) => onChange(isCNPJ ? target.value.toUpperCase() : target.value)}
                {...rest}
              />
            )}
          />
        </Grid>
      ) : null}

      {isCNPJ || isCPF ? (
        <Grid item xs={12}>
          <Controller
            name='contactPhone'
            control={control}
            render={({ field: { name, onChange, value, ...rest } }) => (
              <PhoneInput
                fullWidth
                id={name}
                label={t('phone_label')}
                required={isCNPJ || isCPF}
                error={!!errors[`${name}`]}
                helperText={errors[`${name}`]?.message}
                onChange={(value) => onChange(value ?? '')}
                value={value || '+55'}
                {...rest}
              />
            )}
          />
        </Grid>
      ) : null}

      {isCNPJ ? (
        <Grid item xs={12}>
          <Controller
            name='openedAt'
            control={control}
            render={({ field: { name, onChange, value, ...rest } }) => (
              <DatePicker
                required={isCNPJ}
                fullWidth
                value={value || null}
                label={t('openedAt_label')}
                error={!!errors[`${name}`]}
                helperText={errors[`${name}`]?.message}
                onChange={(date) => onChange(date)}
                {...rest}
              />
            )}
          />
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <Button type='submit' fullWidth variant='contained'>
          {t('submit_button')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default EstablishmentForm;
