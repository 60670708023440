import { Grid } from '@mui/material';

import { useTranslation } from 'react-i18next';

import {
  Benefit,
  BenefitList,
  BenefitListItem,
  BenefitsTitle,
  CtaText,
  CtaTitle,
  SubTitle,
  Title,
  Wrapper,
} from './styles';
import { AssociationModel } from '@/services/SellerApi';

type HowItWorksProps = {
  associationModel?: AssociationModel;
};

export const HowItWorks = (props: HowItWorksProps) => {
  const { associationModel } = props;

  const { t } = useTranslation(['partnership']);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Wrapper>
          <Title>{t('proposal.how_it_works.title')}</Title>

          <SubTitle>{t('proposal.how_it_works.subtitle')}</SubTitle>

          <BenefitsTitle>{t('proposal.how_it_works.benefitsTitle')}</BenefitsTitle>

          {associationModel === 'direct_purchase' ? (
            <BenefitList>
              <BenefitListItem>
                <Benefit>{t('proposal.how_it_works.direct_purchase_benefits.benefit_1')}</Benefit>
              </BenefitListItem>
              <BenefitListItem>
                <Benefit>{t('proposal.how_it_works.direct_purchase_benefits.benefit_2')}</Benefit>
              </BenefitListItem>
              <BenefitListItem>
                <Benefit>{t('proposal.how_it_works.direct_purchase_benefits.benefit_3')}</Benefit>
              </BenefitListItem>
              <BenefitListItem>
                <Benefit>{t('proposal.how_it_works.direct_purchase_benefits.benefit_4')}</Benefit>
              </BenefitListItem>
            </BenefitList>
          ) : (
            <BenefitList>
              <BenefitListItem>
                <Benefit>{t('proposal.how_it_works.affiliation_benefits.benefit_1')}</Benefit>
              </BenefitListItem>
              <BenefitListItem>
                <Benefit>{t('proposal.how_it_works.affiliation_benefits.benefit_2')}</Benefit>
              </BenefitListItem>
              <BenefitListItem>
                <Benefit>{t('proposal.how_it_works.affiliation_benefits.benefit_3')}</Benefit>
              </BenefitListItem>
            </BenefitList>
          )}

          <CtaTitle>{t('proposal.how_it_works.cta_title')}</CtaTitle>

          <CtaText>
            {t('proposal.how_it_works.cta_text_begin')} <strong>{t('proposal.how_it_works.cta_text_ingress')}</strong>{' '}
            {t('proposal.how_it_works.cta_text_end')}
          </CtaText>
        </Wrapper>
      </Grid>
    </Grid>
  );
};

export default HowItWorks;
