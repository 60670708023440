import { css, styled } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';

export const StyledOTPInput = styled(MuiOtpInput)(
  ({ theme }) => css`
    input {
      font-size: ${theme.typography.h4.fontSize};
      font-weight: ${theme.typography.h4.fontWeight};
      text-align: center;

      padding: 12px 0;

      ${theme.breakpoints.down('xs')} {
        font-size: ${theme.typography.h5.fontSize};
        font-weight: ${theme.typography.h5.fontWeight};
        padding: 8px 0;
      }
    }
  `,
);
