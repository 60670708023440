import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { Box, Chip, Grid, Link, Typography } from '@mui/material';
import { Cancel, CheckCircle, Share, Visibility, CloudDownload } from '@mui/icons-material';
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';

import {
  AssociationModel,
  Partnership,
  PartnershipStatus,
  PartnershipsApiGetAppValidArrangedPartnershipsRequest,
  PartnershipsApiGetAppValidProvidedPartnershipsRequest,
  PublicPartnershipItem,
} from '@/services/SellerApi';
import { createMask } from '@/utils/masks';

import { useSellerApi } from '@/hooks/useSellerApi';
import useAccount from '@/hooks/useAccount';
import useAnalytics from '@/hooks/analytics/useAnalytics';

import Table from '@/components/Table';
import ValueWithLabel from '@/components/ValueWithLabel';
import CopyToClipboardButton from '@/components/CopyToClipboardButton';
import Modal from '@/components/Modal';

import StatusChip from '@/components/Partnership/StatusChip';
import DivulgationQrCode from '@/components/Partnership/DivulgationQrCode';

import { Content } from './styles';
import { colors } from '@/theme';
import MobileMenu from './MobileMenu';
import { initialPaginationState, serializeToQueryString } from '@/utils';
import { useNavigate } from 'react-router-dom';
import DynamicChipList from '@/components/DynamicChipList';
import Loading from '@/components/Loading';
import { ViewType } from '@/layouts/PartnershipLayout';
import ExtendedGridActionsCellItem from '@/components/ExtendedGridActionsCellItem';
import useFilter from '@/hooks/useFilter';

type PartnershipListProps = {
  viewType: ViewType;
  currentTab: AssociationModel;
};

type PartnershipWithItems = {
  partnershipId: string;
  loading: boolean;
  partnershipItems: PublicPartnershipItem[];
};

export const PartnershipList = (props: PartnershipListProps) => {
  const { viewType, currentTab } = props;

  const { t } = useTranslation(['partnership']);
  const { selectedAccount } = useAccount();
  const { enqueueSnackbar } = useSnackbar();
  const { track } = useAnalytics();
  const navigate = useNavigate();

  const { partnershipsApi } = useSellerApi();

  const [partnershipQrCodeModalOpen, setPartnershipQrCodeModalOpen] = useState(false);

  const [loading, setLoading] = useState(true);
  const [partnerships, setPartnerships] = useState<Partnership[]>([]);
  const [selectedPartnership, setSelectedPartnership] = useState<Partnership | undefined>(undefined);

  const [rowCount, setRowCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState(initialPaginationState);
  const [partnershipWithItems, setPartnershipWithItems] = useState<PartnershipWithItems[]>([]);

  const { appliedFilters, search, isFiltersLoaded } = useFilter();

  const isAgent = viewType === 'agent';
  const isProvider = viewType === 'provider';

  const viewPartnership = (partnership: Partnership) => {
    track('partnershipList/viewPartnershipButtonClick', { partnershipId: partnership.id, partnership });

    navigate(`../../partnership/${partnership.id}`);
  };

  const acceptPartnership = async (partnership: Partnership) => {
    track('partnershipList/acceptPartnershipRequest', { partnershipId: partnership.id, partnership });

    try {
      setLoading(true);

      await partnershipsApi.approvePartnership({ partnershipId: partnership.id });

      getPartnerships();

      track('partnershipList/acceptPartnershipSuccess', { partnershipId: partnership.id, partnership });
    } catch (error) {
      setLoading(false);

      enqueueSnackbar(t('list.could_not_accept'));

      track('partnershipList/acceptPartnershipFail', { partnershipId: partnership.id, partnership, error });
    }
  };

  const declinePartnership = async (partnership: Partnership) => {
    track('partnershipList/declinePartnershipRequest', { partnershipId: partnership.id, partnership });

    try {
      setLoading(true);

      await partnershipsApi.declinePartnership({ partnershipId: partnership.id });

      getPartnerships();

      track('partnershipList/declinePartnershipSuccess', { partnershipId: partnership.id, partnership });
    } catch (error) {
      setLoading(false);

      enqueueSnackbar(t('list.could_not_decline'));

      track('partnershipList/declinePartnershipFail', { partnershipId: partnership.id, partnership, error });
    }
  };

  const handleStatusChange = (partnership: Partnership, status: string) => {
    track('partnershipList/statusClick', { partnershipId: partnership.id, partnership });

    switch (status) {
      case PartnershipStatus.Ongoing:
        acceptPartnership(partnership);
        break;
      case PartnershipStatus.Declined:
        declinePartnership(partnership);
        break;
      default:
        break;
    }
  };

  const getDivulgationLink = (partnership: Partnership) =>
    `${partnership.provider?.siteUrl}?ac=${partnership.affiliationCode}`;

  const getQRCodeFileName = (partnership: Partnership) => partnership.agent?.name;

  const shareOnWhatsapp = (partnership: Partnership) => {
    const affiliationLink = `${partnership.provider!.siteUrl}?ac=${partnership.affiliationCode}`;
    const message = `Acesse a parceria ${partnership.publicProposal!.name} através do link ${affiliationLink}`;

    const params = {
      text: message,
      ...(isProvider && partnership.agent?.contact?.phone ? { phone: partnership.agent!.contact!.phone } : {}),
    };

    window.open(`https://api.whatsapp.com/send?${serializeToQueryString(params)}`, '_blank')?.focus();

    track('partnershipList/shareOnWhatsappButtonClick', { partnershipId: partnership.id, partnership, message });
  };

  const downloadQrCode = (partnership: Partnership) => {
    track('partnershipList/downloadQrCodeButtonClick', { partnershipId: partnership.id, partnership });

    setSelectedPartnership(partnership);
    setPartnershipQrCodeModalOpen(true);
  };

  const getPartnershipItems = async (partnershipId: string) => {
    const { data } = await partnershipsApi.getPartnershipItems({
      partnershipId,
      include: ['product'],
      limit: paginationModel.pageSize,
      offset: paginationModel.page * paginationModel.pageSize,
    });

    return data;
  };

  const getPartnerships = async () => {
    try {
      setLoading(true);

      const payload: PartnershipsApiGetAppValidArrangedPartnershipsRequest &
        PartnershipsApiGetAppValidProvidedPartnershipsRequest = {
        ...appliedFilters,
        ...(search ? { search } : {}),
        associationModels: [currentTab],
        limit: paginationModel.pageSize,
        appId: selectedAccount?.appId || '',
        offset: paginationModel.page * paginationModel.pageSize,
        include: ['provider.info', 'agent.info', 'publicProposal.provider.info'],
      };

      const { data, headers } = isProvider
        ? await partnershipsApi.getAppValidProvidedPartnerships(payload)
        : await partnershipsApi.getAppValidArrangedPartnerships(payload);

      setPartnerships(data);

      if (currentTab === AssociationModel.DirectPurchase) {
        setPartnershipWithItems(data.map((p) => ({ loading: true, partnershipId: p.id, partnershipItems: [] })));
      }

      setRowCount(parseInt(headers['x-pagination-total-count']));
    } catch (error) {
      enqueueSnackbar(t('list.could_not_load_partnerships'), { variant: 'error' });

      track('partnershipList/retrievePartnershipListFail', { error });
    } finally {
      setLoading(false);
    }
  };

  const getActions = (partnership: Partnership) => [
    <ExtendedGridActionsCellItem
      key='action-view'
      href={`../../partnership/${partnership.id}`}
      component={Link}
      icon={<Visibility />}
      label={t('list.table.actions.view')}
      showInMenu
    />,
    ...(isProvider && partnership.currentStatus === 'pending'
      ? [
          <GridActionsCellItem
            key='action-accept'
            icon={<CheckCircle />}
            onClick={() => acceptPartnership(partnership)}
            label={t('list.table.actions.accept')}
            showInMenu
          />,
          <GridActionsCellItem
            key='action-decline'
            icon={<Cancel />}
            onClick={() => declinePartnership(partnership)}
            label={t('list.table.actions.decline')}
            showInMenu
          />,
        ]
      : []),
    ...(partnership.currentStatus === PartnershipStatus.Ongoing && currentTab === AssociationModel.Affiliation
      ? [
          <GridActionsCellItem
            key='action-download'
            icon={<CloudDownload />}
            onClick={() => downloadQrCode(partnership)}
            label={t('list.table.actions.download_qr_code')}
            showInMenu
          />,
          <GridActionsCellItem
            key='action-share'
            icon={<Share />}
            onClick={() => shareOnWhatsapp(partnership)}
            label={t('list.table.actions.share_on_whatsapp')}
            showInMenu
          />,
        ]
      : []),
  ];

  const DivulgationLink = ({ row }: { row: Partnership }) => (
    <Box display='flex' alignItems='center' sx={{ maxWidth: '100%' }}>
      <Box
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {getDivulgationLink(row)}
      </Box>

      <CopyToClipboardButton text={getDivulgationLink(row)} iconSize='small' color='default' />
    </Box>
  );

  const columns: GridColDef[] = [
    {
      field: 'currentStatus',
      headerName: t('list.table.columns.status'),
      width: 110,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => t(`status.${params.row.currentStatus}`),
      renderCell: (params: GridRenderCellParams) => (
        <StatusChip
          value={params.row.currentStatus}
          onChange={(status) => handleStatusChange(params.row, status)}
          canChange={isProvider}
        />
      ),
    },
    {
      field: 'agent.name',
      headerName:
        isProvider && currentTab === AssociationModel.DirectPurchase
          ? t('list.table.columns.agentName')
          : isProvider
            ? t('list.table.columns.affiliatName')
            : t('list.table.columns.providerName'),
      flex: 1,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => (isProvider ? params.row.agent.name : params.row.provider.name),
    },
    {
      field: 'publicProposal.name',
      headerName: t('list.table.columns.program'),
      maxWidth: 250,
      flex: 1,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.publicProposal.name,
    },
    ...(isProvider
      ? [
          {
            field: 'agent.contact.phone',
            headerName: t('list.table.columns.phone'),
            width: 150,
            sortable: false,
            valueGetter: (params: GridValueGetterParams) =>
              createMask({ mask: '+00 00 0 0000-0000' })(params.row.agent.contact.phone || ''),
          },
        ]
      : []),
    ...(currentTab === AssociationModel.Affiliation
      ? [
          {
            field: 'affiliationCode',
            headerName: t('list.table.columns.divulgation_link'),
            maxWidth: 350,
            flex: 1,
            sortable: false,
            renderCell: (params: GridRenderCellParams) =>
              params.row.currentStatus === 'ongoing' ? <DivulgationLink row={params.row} /> : null,
          },
        ]
      : [
          {
            field: 'products',
            headerName: t('list.table.columns.products'),
            renderCell: (params: GridRenderCellParams) => {
              const cellWidth = params.api.getColumn(params.field).computedWidth!;

              const currentPartnershipWithItems = partnershipWithItems.find((p) => p.partnershipId === params.row.id);

              if (currentPartnershipWithItems?.loading) {
                return <Loading />;
              }

              const products = currentPartnershipWithItems?.partnershipItems
                ?.map((item) => item.product?.name)
                .filter(Boolean);

              const isForAllProducts = currentPartnershipWithItems?.partnershipItems.some((item) => item.allProducts);

              if (!products || !products.length || isForAllProducts) {
                return (
                  <Chip
                    size='small'
                    color='primary'
                    variant='outlined'
                    sx={{ background: colors.blue[100], border: 'none' }}
                    label={isForAllProducts ? t('list.table.columns.all_products') : '-'}
                  />
                );
              }

              return <DynamicChipList list={products as string[]} containerWidth={cellWidth} />;
            },
            flex: 1,
            minWidth: 70,
            sortable: false,
          },
        ]),
    {
      field: 'actions',
      headerName: t('list.table.columns.actions'),
      type: 'actions',
      sortable: false,
      getActions: (params: GridRowParams) => getActions(params.row),
    },
  ];

  const updatePartnershipWithItems = async () => {
    const partnershipWithItemsData = await Promise.all(
      partnershipWithItems.map(async (p) => {
        const partnershipItems = await getPartnershipItems(p.partnershipId);
        return {
          ...p,
          loading: false,
          partnershipItems,
        };
      }),
    );

    setPartnershipWithItems(partnershipWithItemsData);
  };

  useEffect(() => {
    if (selectedAccount?.appId && isFiltersLoaded) {
      getPartnerships();
    }
  }, [paginationModel, appliedFilters, selectedAccount, search, currentTab, isFiltersLoaded]);

  useEffect(() => {
    if (partnerships.length && currentTab === AssociationModel.DirectPurchase && partnershipWithItems.length) {
      updatePartnershipWithItems();
    }
  }, [partnerships, currentTab]);

  return (
    <Content>
      <Grid container>
        <Grid item xs={12}>
          <Table
            rows={partnerships}
            columns={columns}
            loading={loading}
            rowCount={rowCount}
            paginationModel={paginationModel}
            paginationMode='server'
            disableColumnMenu
            onPaginationModelChange={(newPaginationModel) => setPaginationModel(newPaginationModel)}
            localeText={{
              noRowsLabel: t('list.no_partnershps'),
            }}
            renderMobile={({ row }) => (
              <Grid container rowGap={1} alignItems='center'>
                <Grid item xs={6.5}>
                  <ValueWithLabel
                    title={isProvider ? t('list.table.columns.agentName') : t('list.table.columns.providerName')}
                    value={isProvider ? row.agent.name : row.provider.name}
                  />
                </Grid>

                <Grid item xs={3.5} textAlign='right'>
                  <StatusChip
                    value={row.currentStatus}
                    onChange={(status) => handleStatusChange(row as Partnership, status)}
                    canChange={isProvider}
                  />
                </Grid>

                <Grid item xs={2} textAlign='right'>
                  <MobileMenu
                    partnership={row as Partnership}
                    isProvider={isProvider}
                    acceptPartnership={acceptPartnership}
                    declinePartnership={declinePartnership}
                    downloadQrCode={downloadQrCode}
                    isAgent={isAgent}
                    viewPartnership={viewPartnership}
                    shareOnWhatsapp={shareOnWhatsapp}
                  />
                </Grid>

                <Grid item xs={7}>
                  <ValueWithLabel title={t('list.table.columns.program')} value={row.publicProposal.name} />
                </Grid>
                {isProvider ? (
                  <Grid item xs={3}>
                    <ValueWithLabel
                      title={t('list.table.columns.phone')}
                      value={createMask({ mask: '+00 00 0 0000-0000' })(row.agent.contact.phone || '')}
                    />
                  </Grid>
                ) : null}

                <Grid item xs={12}>
                  <Typography variant='smallBold' color={colors.gray[700]}>
                    {t('list.table.columns.divulgation_link')}
                  </Typography>
                  {row.currentStatus === 'ongoing' ? <DivulgationLink row={row as Partnership} /> : null}
                </Grid>
              </Grid>
            )}
          />
        </Grid>
      </Grid>

      <Modal
        open={partnershipQrCodeModalOpen}
        onClose={() => {
          setPartnershipQrCodeModalOpen(false);
          setSelectedPartnership(undefined);
        }}
        showCloseButton={true}
        title='QR Code'
        acceptButtonText='Ok'
        onAccept={() => {
          setPartnershipQrCodeModalOpen(false);
          setSelectedPartnership(undefined);
        }}
      >
        {selectedPartnership ? (
          <DivulgationQrCode
            fileName={getQRCodeFileName(selectedPartnership)}
            divulgationLink={getDivulgationLink(selectedPartnership)}
          />
        ) : null}
      </Modal>
    </Content>
  );
};

export default PartnershipList;
