import { useTranslation } from 'react-i18next';

import ModalOnDesktopDrawerOnMobile from '@/components/Modal/ModalOnDesktopDrawerOnMobile';
import CustomRecurrenceForm from '@/features/agenda/components/RecurrenceModal/RecurrenceForm';
import { Recurrence } from '@/services/SellerApi';

interface RecurrenceModal {
  isOpen: boolean;
  currentRecurrence?: Recurrence;
  onClose: () => void;
  onRecurrenceChange: (recurrence: Recurrence) => void;
}

const RecurrenceModal = (props: RecurrenceModal) => {
  const { isOpen, currentRecurrence, onClose, onRecurrenceChange } = props;
  const { t } = useTranslation(['agenda']);

  return (
    <ModalOnDesktopDrawerOnMobile
      isOpen={isOpen}
      onClose={() => onClose()}
      title={t('recurrence.modal.title')}
      width='25rem'
    >
      <CustomRecurrenceForm onClose={onClose} onUpdated={onRecurrenceChange} scheduleRecurrence={currentRecurrence} />
    </ModalOnDesktopDrawerOnMobile>
  );
};

export default RecurrenceModal;
