import { zodResolver } from '@hookform/resolvers/zod';
import { Grid, Typography } from '@mui/material';
import { isAxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';

import Button from '@/components/Button';
import Input from '@/components/Form/Input';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import useAuth from '@/contexts/Auth/hooks/useAuth';
import useErrorHandler from '@/hooks/useErrorHandler';
import { ErrorTypes } from '@/interfaces/errorTypes';

import { schema } from './schema';

type FormParams = {
  email: string;
};

type Props = {
  onError: (error: string) => void;
  setLoading: (loading: boolean) => void;
  onSubmitEmail: (email: string) => void;
};

export const SendEmail = () => {
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const { resetPassword } = useAuth();
  const { errorHandler } = useErrorHandler();
  const { t } = useTranslation(['resetPassword']);
  const { t: translateGenericError } = useTranslation(['errors']);
  const { onError, setLoading, onSubmitEmail } = useOutletContext<Props>();

  const { register, handleSubmit, formState, setError } = useForm<FormParams>({
    mode: 'onSubmit',
    resolver: zodResolver(schema),
  });

  const { errors, isSubmitting } = formState;

  const onSubmit = async (data: FormParams) => {
    try {
      setLoading(true);
      const { email } = data;
      await resetPassword({ email });
      onSubmitEmail(email);
      navigate('code-validation');
    } catch (err) {
      const TrackPath = 'resetPasswordPage/sendEmailRequest/error';
      const { errors: handlerErrors, status, statusText } = errorHandler(err, TrackPath);

      if (handlerErrors) {
        handlerErrors.forEach(({ type, rule }) => {
          if (type === ErrorTypes.VALIDATION || type === ErrorTypes.NOT_FOUND) {
            setError('email', { message: t('email_step_validation_request_error') });
          } else {
            onError(translateGenericError(`${rule}`));
          }
        });

        track(TrackPath, {
          message: handlerErrors.map(({ rule }) => translateGenericError(rule)).join(', '),
          status,
          statusText,
          error: isAxiosError(err) ? err.response?.data : err,
        });

        return;
      }

      onError(translateGenericError('generic_default_error'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography color='colors.gray.500' variant='regularRegular'>
          {t('email_step_description')}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container rowSpacing={4} component='form' onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={12}>
            <Input
              fullWidth
              required
              id='email'
              label='Email'
              autoComplete='email'
              autoFocus
              error={!!errors['email']}
              helperText={errors['email']?.message as string}
              {...register('email')}
            />
          </Grid>
          <Grid item xs={12}>
            <Button disabled={isSubmitting} type='submit' fullWidth variant='contained'>
              {t('email_step_submit_button')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SendEmail;
