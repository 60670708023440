import { Box } from '@mui/material';
import { createElement } from 'react';

import { AgreementComponent, AgreementDocument, AgreementElement } from '@/services/SellerApi';

type Props = {
  document: AgreementDocument;
};

const Document = (props: Props) => {
  const { document } = props;

  return (
    <Box>
      {document.content.components.map((docComponent, idx) => (
        <DocComponent key={idx} component={docComponent} />
      ))}
    </Box>
  );
};

interface DocComponentProps {
  component: AgreementComponent;
}

const DocComponent = ({ component }: DocComponentProps) => {
  if (component.namedStyleType == 'TITLE') {
    return null;
  }

  const children = component.elements.map((docElement, idx) => <DocElement key={idx} element={docElement} />);

  let tagName = 'p';
  const props = {};

  if (component.namedStyleType == 'HEADING_1') {
    tagName = 'h2';
  }

  if (component.listId) {
    children.unshift(<span>- </span>);
  }

  return createElement(tagName, props, children);
};

function decodeUnicode(input: string) {
  return input.replaceAll('\v', '\n');
}

interface DocElementProps {
  element: AgreementElement;
}

const DocElement = ({ element }: DocElementProps) => {
  let tagName = element.bold ? 'strong' : 'span';
  const props: { [key: string]: string } = {};

  if (element.underline) {
    props['className'] = '';
  }

  if (element.linkUrl) {
    tagName = 'a';
    props['href'] = element.linkUrl;
    props['target'] = '_blank';
  }

  return createElement(tagName, props, decodeUnicode(element.text));
};

export type { Props as TermsProps };

export default Document;
