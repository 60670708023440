/* eslint-disable @typescript-eslint/ban-ts-comment */
import { DateTimePickerProps, DateTimePicker as MUIDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR as localeText } from '@mui/x-date-pickers/locales';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ptBR } from 'date-fns/locale';
import { forwardRef, Ref } from 'react';

import Input, { InputProps } from '@/components/Form/Input';

type Props = DateTimePickerProps<Date> & {
  name?: string;
  error?: boolean;
  required?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  onBlur?: () => void;
  autoComplete?: string;
  tooltipProps?: InputProps['tooltipProps'];
  placeholder?: string;
};

export const DateTimePicker = forwardRef((props: Props, ref: Ref<HTMLDivElement>) => {
  const {
    onChange,
    label,
    error = false,
    format = 'dd/MM/yyyy - HH:mm',
    name,
    fullWidth = false,
    helperText,
    value,
    required = false,
    onBlur,
    onClose,
    autoComplete = 'cc-csc', // workaround to prevent browser autofill
    tooltipProps,
    slotProps,
    slots,
    placeholder,
    ...rest
  } = props;

  return (
    <LocalizationProvider
      adapterLocale={ptBR}
      dateAdapter={AdapterDateFns}
      localeText={localeText.components.MuiLocalizationProvider.defaultProps.localeText}
    >
      <MUIDateTimePicker
        ref={ref}
        ampm={false}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        format={format}
        slotProps={{
          actionBar: {
            actions: ['today', 'clear', 'accept'],
          },
          dialog: {
            sx: {
              zIndex: 9999,
            },
          },
          textField: {
            InputProps: {
              autoComplete,
              onBlur,
              name,
            },
            label,
            error,
            fullWidth,
            helperText,
            required,
            placeholder,
            // @ts-ignore
            tooltipProps,
          },
          ...slotProps,
        }}
        slots={{
          textField: Input,
          ...slots,
        }}
        onClose={() => {
          if (onClose) {
            onClose();
          }
          if (onBlur) {
            onBlur();
          }
        }}
        timeSteps={{ minutes: 1 }}
        {...rest}
      />
    </LocalizationProvider>
  );
});

export default DateTimePicker;
