import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';

import IndeterminateCheckboxGroup from '@/components/IndeterminateCheckboxGroup';
import useFilter from '@/hooks/useFilter';

const CustomFilter = () => {
  const { selectedFilters, handleChangeFilters, schema } = useFilter();

  const { t } = useTranslation(['refundRequests']);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant='regularBold'>{t('filters.status.title')}</Typography>

        <IndeterminateCheckboxGroup
          label={t('filters.status.all')}
          options={schema!.status.options!}
          values={
            (selectedFilters?.status as string[])?.map(
              (status) => schema!.status.options!.find((option) => option.value === status)!,
            ) ?? []
          }
          onChange={(values) => {
            handleChangeFilters(
              'status',
              values.map((status) => status.value),
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

export default CustomFilter;
