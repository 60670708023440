import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

export const Content = styled(Box)(({ theme }) => ({
  borderBottom: '1px solid #D2D2D2',
  padding: '1.5rem 1.5rem 1rem 1.5rem',

  [theme.breakpoints.down('sm')]: {
    padding: '1rem',
  },
}));

export const ReservationData = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  flexGrow: 1,
}));

export const Tariffs = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '0.5rem',
}));

export const Title = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '0.5rem',

  marginBottom: '0.5rem',
}));
