import { Container, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledContainer = styled(Container)(() => ({
  minHeight: '100%',
}));

export const ProposalPaper = styled(Paper)(() => ({
  padding: '24px',
  minHeight: '500px',
  marginTop: '2rem',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
}));

export const ProviderLogo = styled('img')(() => ({
  maxWidth: '100%',
  marginBottom: '16px',
}));

export const ProviderName = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '16px',
  letterSpacing: 0,
  textAlign: 'center',
  marginBottom: '16px',
  color: theme.palette.colors.gray[700],
}));

export const PartnershipName = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '19px',
  letterSpacing: 0,
  textAlign: 'center',
  marginBottom: '16px',
  color: theme.palette.colors.gray[700],
}));
export const Title = styled(Typography)(({ theme }) => ({
  fontSize: '23px',
  fontWeight: 700,
  lineHeight: '27px',
  letterSpacing: 0,
  marginBottom: '16px',
  color: theme.palette.colors.gray[700],
}));
export const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '13px',
  fontWeight: 500,
  lineHeight: '15px',
  letterSpacing: 0,
  marginBottom: '24px',
  color: theme.palette.colors.gray[700],
}));

export const BenefitsTitle = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '19px',
  letterSpacing: 0,
  marginBottom: '10px',
  textAlign: 'left',
  width: '100%',
  color: theme.palette.colors.gray[700],
}));
export const BenefitList = styled('ul')(({ theme }) => ({
  paddingLeft: '28px',
  margin: 0,
  marginBottom: '24px',
  color: theme.palette.colors.gray[700],
}));
export const BenefitListItem = styled('li')(({ theme }) => ({
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '19px',
  letterSpacing: 0,
  color: theme.palette.colors.gray[700],
}));
export const Benefit = styled(Typography)(({ theme }) => ({
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '19px',
  letterSpacing: 0,
  color: theme.palette.colors.gray[700],
}));
export const CtaTitle = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '19px',
  letterSpacing: 0,
  width: '100%',
  color: theme.palette.colors.gray[700],
}));
export const CtaText = styled(Typography)(({ theme }) => ({
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '19px',
  letterSpacing: 0,
  marginBottom: '16px',
  color: theme.palette.colors.gray[700],
}));

export const DiscountExplanation = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '15px',
  letterSpacing: 0,
  marginBottom: '24px',
  color: theme.palette.colors.gray[700],

  [theme.breakpoints.down('md')]: {
    fontSize: '13px',
    lineHeight: '16px',
  },
}));

export const AuthenticateText = styled(Typography)(({ theme }) => ({
  ...theme.typography.largeSemiBold,
  marginBottom: '24px',
  display: 'block',
}));
