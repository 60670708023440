import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';

import useFilter from '@/hooks/useFilter';
import useAccount from '@/hooks/useAccount';
import EmptyPage from '@/components/EmptyPage';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import { useSellerApi } from '@/hooks/useSellerApi';
import { Tag, TagsApiGetAppTagsRequest } from '@/services/SellerApi';
import EmptyFilterPage from '@/components/FilterDrawer/EmptyFilterPage';
import AppTagsEmptyPageIcon from '@/assets/Icons/app-tags-empty-page-icon.svg';
import { Outlet, useNavigate } from 'react-router-dom';
import { Edit05, Plus, Trash01 } from '@untitled-ui/icons-react';
import Table, { TableColDef } from '@/components/Table';
import { GridActionsCellItem, GridRowParams } from '@mui/x-data-grid';
import ConfirmationModal from '@/components/Modal/ConfirmModal';
import ModalOnDesktopDrawerOnMobile from '@/components/Modal/ModalOnDesktopDrawerOnMobile';
import AppTagsForm from '../AppTagsForm';
import Preview from './MobilePreview';
import { colors } from '@/theme';
import { TagChip } from '../SaleTagsPopup';

export const SaleAppTagsList = () => {
  const { search } = useFilter();
  const { track } = useAnalytics();
  const { tagsApi } = useSellerApi();
  const { t } = useTranslation(['sales']);
  const { selectedAccount } = useAccount();

  const navigate = useNavigate();

  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedTag, setSelectedTag] = useState<Tag>();

  const [loading, setLoading] = useState(true);
  const [totalAppTags, setTotalAppTags] = useState(0);
  const [appTags, setAppTags] = useState<Tag[]>([]);
  const [openPreviewDrawer, setOpenPreviewDrawer] = useState(false);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 20 });

  const loadAppTags = useCallback(async () => {
    const limit = paginationModel.pageSize;
    const offset = paginationModel.page * paginationModel.pageSize;

    const payload: TagsApiGetAppTagsRequest = {
      appId: selectedAccount?.appId ?? '',
      limit,
      offset,
      ...(search ? { search } : {}),
    };

    try {
      setLoading(true);
      const { data, headers } = await tagsApi.getAppTags(payload);

      setAppTags(data);
      setTotalAppTags(parseInt(headers['x-pagination-total-count'] ?? '0'));
      track('AppTags/retrieveAppTagsSuccess', { payload });
    } catch (error) {
      enqueueSnackbar(t('messages.could_not_load_app_tags'), { variant: 'error' });
      track('AppTags/retrieveAppTagsFail', { payload });
    } finally {
      setLoading(false);
    }
  }, [paginationModel, selectedAccount?.appId, tagsApi, track, search]);

  const closeDeleteModal = () => {
    setSelectedTag(undefined);
    setIsDeleting(false);
  };

  const closeEditModal = () => {
    setSelectedTag(undefined);
    setIsEditing(false);
  };

  const deleteTag = async (tagId: string) => {
    const payload = { tagId };

    try {
      setLoading(true);
      await tagsApi.deleteTag(payload);

      enqueueSnackbar(t('messages.tag_deleted_successfully'), { variant: 'success' });

      closeDeleteModal();

      loadAppTags();

      setOpenPreviewDrawer(false);
    } catch (error) {
      enqueueSnackbar(t('messages.could_not_delete_tag'), { variant: 'error' });
      track('AppTags/deleteTagFail', { payload, error });
    } finally {
      setLoading(false);
    }
  };

  const actions = [
    {
      icon: <Edit05 />,
      label: t('app_tags.list.table.actions.edit'),
      onClick: (tag: Tag) => {
        setSelectedTag(tag);
        setIsEditing(true);
      },
    },
    {
      icon: <Trash01 />,
      onClick: (tag: Tag) => {
        setSelectedTag(tag);
        setIsDeleting(true);
      },
      label: t('app_tags.list.table.actions.delete'),
    },
  ];

  const renderTag = (tag: Tag) => <TagChip label={tag.name} sx={{ cursor: 'default' }} />;

  const getActions = (tag: Tag) =>
    actions.map((action) => (
      <GridActionsCellItem key={action.label} showInMenu {...action} onClick={() => action.onClick(tag)} />
    ));

  const columns: TableColDef[] = [
    {
      flex: 1,
      field: 'name',
      sortable: false,
      renderAsLeftTitleOnMobile: true,
      headerName: t('app_tags.list.table.header.name'),
      renderCell: (params) => renderTag(params.row),
    },
    {
      width: 60,
      type: 'actions',
      sortable: false,
      field: 'actions',
      headerName: t('app_tags.list.table.header.actions'),
      getActions: (params: GridRowParams) => getActions(params.row),
    },
  ];

  useEffect(() => {
    loadAppTags();
  }, [paginationModel, search]);

  return (
    <Fragment>
      {appTags.length ? (
        <Grid
          item
          xs={12}
          paddingX={{ xs: 0 }}
          sx={{
            '& [data-mobile-identifier="mobile-table-row"]:nth-of-type(even)': {
              background: colors.gray[50],
            },
          }}
        >
          <Table
            rows={appTags}
            columns={columns}
            loading={loading}
            disableColumnMenu
            rowCount={totalAppTags}
            paginationMode='server'
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            onMobileClick={({ row }) => {
              setSelectedTag(row as unknown as Tag);
              setOpenPreviewDrawer(true);
            }}
          />

          <ConfirmationModal
            isOpen={isDeleting}
            onClose={closeDeleteModal}
            title={t('app_tags.modal.delete.title')}
            onConfirm={() => deleteTag(selectedTag!.id)}
          >
            <Typography component='p' variant='largeSemiBold' mb={3}>
              {t('app_tags.modal.delete.description')}
            </Typography>
          </ConfirmationModal>

          <ModalOnDesktopDrawerOnMobile
            isOpen={isEditing}
            onClose={closeEditModal}
            title={t('app_tags.modal.edit.title')}
          >
            <AppTagsForm
              onCancel={closeEditModal}
              onSuccess={() => {
                closeEditModal();
                setOpenPreviewDrawer(false);

                loadAppTags();
              }}
              tag={selectedTag}
            />
          </ModalOnDesktopDrawerOnMobile>

          <Preview
            actions={actions}
            tag={selectedTag}
            open={openPreviewDrawer}
            renderTag={renderTag}
            onClose={() => {
              setSelectedTag(undefined);
              setOpenPreviewDrawer(false);
            }}
          />
        </Grid>
      ) : null}

      {!appTags.length && !loading ? (
        <Grid item xs={12} alignContent='center' justifyContent='center' mt={3}>
          {!search ? (
            <EmptyPage
              showCicleBelowIcon={false}
              Icon={<img src={AppTagsEmptyPageIcon} alt='Icon' />}
              description={t('messages.no_app_tags_found')}
              buttonText={
                <Box display='flex' alignItems='center' gap={1}>
                  <Plus />
                  {t('app_tags.create_app_tag')}
                </Box>
              }
              onClick={() => navigate('create')}
            />
          ) : null}

          {search ? <EmptyFilterPage /> : null}
        </Grid>
      ) : null}

      {!appTags.length && loading ? (
        <Grid item xs={12} textAlign='center'>
          <Box display='flex' alignItems='center' justifyContent='center' height='60vh' width='100%'>
            <CircularProgress color='primary' />
          </Box>
        </Grid>
      ) : null}

      <Outlet context={{ refreshAppTags: loadAppTags }} />
    </Fragment>
  );
};

export default SaleAppTagsList;
