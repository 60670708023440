import { z } from 'zod';
import { TFunction } from 'i18next';
import { precisePercentageMultiplication } from '@/common/partnership';

export const schema = (t: TFunction<'partnership'[], undefined>, maxPercentageDiscount?: number | null) => {
  const requiredFiledMessage = t('detail.coupon_list.edit_form.required_field');

  const noRequiredField = z.string().optional();
  const requiredField = z.string({ required_error: requiredFiledMessage }).nonempty({ message: requiredFiledMessage });

  return z
    .object({
      code: requiredField,
      discountPercentage: noRequiredField,
      isPartnershipDefault: z.boolean().optional(),
    })
    .superRefine((data, ctx) => {
      if (!data.discountPercentage) {
        return ctx.addIssue({
          path: ['discountPercentage'],
          code: z.ZodIssueCode.custom,
          message: requiredFiledMessage,
        });
      }

      if (data.discountPercentage && (Number(data.discountPercentage) < 0 || Number(data.discountPercentage) > 100)) {
        return ctx.addIssue({
          path: ['discountPercentage'],
          code: z.ZodIssueCode.custom,
          message: t('detail.coupon_list.edit_form.invalid_percentage'),
        });
      }

      if (
        maxPercentageDiscount &&
        data.discountPercentage &&
        Number(data.discountPercentage) > maxPercentageDiscount * 100
      ) {
        return ctx.addIssue({
          path: ['discountPercentage'],
          code: z.ZodIssueCode.custom,
          message: t('detail.coupon_list.edit_form.max_percentage', {
            value: precisePercentageMultiplication(maxPercentageDiscount),
          }),
        });
      }
    })
    .transform((data) => ({
      code: data.code,
      discountPercentage: Number(data.discountPercentage),
      isPartnershipDefault: data.isPartnershipDefault,
    }));
};
