import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';

import theme from '@/theme';
import ListCartItems from './ListCartItems';
import { formatCents } from '@/utils';
import usePartnershipPortalCart from '@/hooks/usePartnershipPortalCart';
import { StyledBox } from './styles';
import { IconFileCheck } from '@tabler/icons-react';
import { CartItemWithId } from '@/contexts/PartnershipPortalCartContext';

type Props = {
  items: CartItemWithId[];
};

export const PurchaseSummary = (props: Props) => {
  const { items } = props;
  const { t } = useTranslation(['showCaseCheckout']);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { calculateTotalPriceOfAllCartItems } = usePartnershipPortalCart();

  return (
    <Grid container>
      <Grid item xs={12}>
        <StyledBox>
          <Box
            display='flex'
            width='100%'
            flexDirection={isMobile ? 'row' : 'column'}
            alignItems='center'
            justifyContent={isMobile ? 'flex-start' : undefined}
            sx={{ mb: '16px' }}
          >
            <IconFileCheck
              size={36}
              color={theme.palette.primary[500]}
              style={{ marginRight: isMobile ? '9px' : undefined }}
            />

            <Typography variant='h6' color={theme.palette.colors.gray[700]} textAlign='center'>
              {t('purchase_summary.title')}
            </Typography>
          </Box>

          <Box
            display='flex'
            width='100%'
            flexDirection={isMobile ? 'row' : 'column'}
            alignItems='center'
            justifyContent={isMobile ? 'space-between' : undefined}
          >
            <Typography variant='regularSemiBold' color={theme.palette.colors.gray[700]}>
              {t('purchase_summary.total')}
            </Typography>

            <Typography variant='h6' color={theme.palette.colors.gray[700]}>
              {formatCents(calculateTotalPriceOfAllCartItems(items))}
            </Typography>
          </Box>
        </StyledBox>
      </Grid>

      <ListCartItems items={items} />
    </Grid>
  );
};

export default PurchaseSummary;
