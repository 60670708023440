import useAccount from '@/hooks/useAccount';
import analytics from '@/services/analytics';

import { AnalyticsEvents } from './enum/analyticsEvents';
import getExtraAccountInformation from './utils/getExtraAccountInformation';

interface UseAnalyticsTrack {
  (ev: AnalyticsEvents, data?: object): void;
  /** @deprecated
   * Use track with AnalyticsEvents enum instead
   */
  (ev: string, data?: object): void;
}

export const useAnalytics = () => {
  const account = useAccount();

  const track: UseAnalyticsTrack = (ev: AnalyticsEvents | string, data?: object): void => {
    analytics.track(ev, getExtraAccountInformation(data || {}, account));
  };

  const identify = (id: string, data?: object) => {
    analytics.identify(id, data);
  };

  const reset = () => {
    analytics.reset();
  };

  return { track, identify, reset };
};

export default useAnalytics;
