import { zodResolver } from '@hookform/resolvers/zod';
import { AlertTitle, Box, CircularProgress, Divider, Grid, Link, Stack, Typography } from '@mui/material';
import { IconAlertTriangle, IconCalendarWeek, IconDeviceFloppy, IconInfoCircle } from '@tabler/icons-react';
import { X } from '@untitled-ui/icons-react';
import { enqueueSnackbar } from 'notistack';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import CustomDatePicker from '@/components/Form/DatePicker';
import ProductSelector from '@/components/ProductSelector';
import TariffGroupSelector from '@/components/TariffGroupSelector';
import ReservationForm from '@/features/agenda/features/schedulingForm/components/CreateAndEditSchedulingForm/ReservationForm';
import schedulingFormSchema from '@/features/agenda/features/schedulingForm/components/CreateAndEditSchedulingForm/schema';
import TimeRange from '@/features/agenda/features/schedulingForm/components/CreateAndEditSchedulingForm/TimeRange';
import RecurrenceButton from '@/features/agenda/components/RecurrenceModal/RecurrenceButton';
import useProductSchedulingMutation from '@/features/agenda/features/schedulingForm/mutations/useProductSchedulingMutation';
import { SchedulingFields } from '@/features/agenda/features/schedulingForm/types/formTypes';
import schedulingToFormFields from '@/features/agenda/features/schedulingForm/utils/schedulingToFormFields';
import useErrorHandler from '@/hooks/useErrorHandler';
import { ErrorTypes } from '@/interfaces/errorTypes';
import { Product, Scheduling, Recurrence, SellingMode, TariffGroup, UpdateRuleEnum, App } from '@/services/SellerApi';
import apiDateToDateObject from '@/utils/apiDateToDateObject';
import useAccount from '@/hooks/useAccount';
import intervalTimeOptions from '@/features/agenda/utils/intervalTimeOptions';
import theme from '@/theme';
import { UpdateRulesProps } from '@/features/agenda/types';
import { AnalyticsEvents } from '@/hooks/analytics/enum/analyticsEvents';
import useAnalytics from '@/hooks/analytics/useAnalytics';
// const LazyRecurrenceSelectorModal = lazy(() => import('@/features/agenda/components/RecurrenceSelector'));
// const LazyDateConflictAlert = lazy(
//   () =>
//     import('@/features/agenda/features/schedulingForm/components/CreateAndEditSchedulingForm/DateConflictAlert'),
// );
import LazyRecurrenceSelectorModal from '@/features/agenda/components/RecurrenceSelector';
import LazyDateConflictAlert from '@/features/agenda/features/schedulingForm/components/CreateAndEditSchedulingForm/DateConflictAlert';

type Props = {
  scheduling: Scheduling | null;
};

export const CreateAndEditSchedulingForm = (params: Props) => {
  const { scheduling } = params;
  const { errorHandler } = useErrorHandler();
  const navigate = useNavigate();
  const { selectedAccount } = useAccount();
  const { track } = useAnalytics();

  const { t } = useTranslation(['scheduling-form']);

  const [conflictDates, setConflictDates] = useState<Date[]>([]);
  const [updateRuleIsOpen, setUpdateRuleIsOpen] = useState<boolean>(false);
  const [dataToUpdate, setDataToUpdate] = useState<SchedulingFields | undefined>(undefined);
  const [isResolvingConflicts, setIsResolvingConflicts] = useState(false);

  const [useMutation] = useProductSchedulingMutation();

  const {
    mutate,
    isPending,
    isSuccess,
    isError,
    error: errorResponse,
    data: createResponse,
    variables,
  } = useMutation();

  const updateRules = [
    UpdateRuleEnum.All,
    UpdateRuleEnum.ThisOnly,
    UpdateRuleEnum.ThisAndFollowing,
    UpdateRuleEnum.ThisAndFollowingUntil,
  ];

  const isEdit = !!scheduling;
  const minimumDate = useMemo(() => {
    if (scheduling) {
      return apiDateToDateObject(scheduling.beginDate);
    }
    const today = new Date();
    return new Date(today.setDate(today.getDate() + 1));
  }, [scheduling]);

  const schedulingsValue = useMemo(
    () =>
      scheduling ? { id: scheduling.id, ...schedulingToFormFields(scheduling), overrideConflicts: false } : undefined,
    [scheduling],
  );

  const intervalMinutes = useMemo(
    () => selectedAccount?.app?.minuteSetInterval || 30,
    [selectedAccount?.app?.minuteSetInterval],
  );

  const interval = useMemo(() => intervalTimeOptions(intervalMinutes, true)[0], [intervalMinutes]);

  const getDefaultValue = () => {
    if (!isEdit) {
      return {
        id: null,
        product: {} as Product,
        tariffGroup: {} as TariffGroup,
        beginDate: new Date(),
        recurrence: {} as Recurrence,
        consumableFromTime: '00:00',
        consumableToTime: interval,
        durationMinutes: interval,
        calendarIntervalMinutes: interval,
        antecedenceHoursReferenceTime: undefined,
        antecedenceHours: 0,
        antecedenceMinutes: 0,
        antecedenceDays: 0,
        antecedenceTime: false,
        overrideConflicts: false,
      } as unknown as SchedulingFields;
    }

    return {};
  };

  const resolver = useMemo(
    () =>
      zodResolver(
        schedulingFormSchema(
          t,
          selectedAccount?.app || ({} as App),
          conflictDates,
          isResolvingConflicts,
          intervalMinutes,
        ),
      ),
    [selectedAccount, conflictDates, isResolvingConflicts, interval],
  );

  const schedulingForm = useForm<SchedulingFields>({
    mode: 'onSubmit',
    values: schedulingsValue,
    reValidateMode: 'onBlur',
    resolver,
    defaultValues: getDefaultValue(),
  });

  const { handleSubmit, formState, control, setValue, setError, resetField, watch } = schedulingForm;

  const onRecurrenceChange = (recurrence: Recurrence) => {
    setValue('recurrence', recurrence);
  };

  const productWatch = watch('product');
  const recurrenceWatch = watch('recurrence');
  const tariffGroupWatch = watch('tariffGroup');

  const { errors, isSubmitting } = formState;

  const isWithDateAndTime = useMemo(
    () => productWatch?.sellingMode === SellingMode.WithDateAndTime,
    [productWatch?.sellingMode],
  );

  const onUpdateSaveCancel = () => {
    setUpdateRuleIsOpen(false);
  };

  const onUpdateSave = (data: UpdateRulesProps) => {
    setUpdateRuleIsOpen(false);
    mutate({
      id: scheduling!.id,
      ...dataToUpdate!,
      updateRule: data.rule,
      ruleUntilDate: data.ruleUntilDate ? format(data.ruleUntilDate, 'yyyy-MM-dd') : undefined,
    });
  };

  const submit = (payload: SchedulingFields) => {
    if (isEdit) {
      setDataToUpdate(payload);
      setUpdateRuleIsOpen(true);
    } else {
      mutate(payload);
    }
  };

  const trackCreatedEdited = (error?: unknown) => {
    const event = isEdit ? AnalyticsEvents.SCHEDULING_EDITED : AnalyticsEvents.SCHEDULING_CREATED;
    const schedulingData = isEdit ? { old: scheduling, new: createResponse?.data } : { new: createResponse };
    track(event, {
      ...schedulingData,
      payload: variables,
      error,
    });
  };

  useEffect(() => {
    if (isSuccess && !isPending) {
      trackCreatedEdited();

      const messageKey = isEdit
        ? 'snackbar.scheduling_updated_successfully'
        : 'snackbar.scheduling_created_successfully';

      enqueueSnackbar(t(messageKey), {
        variant: 'success',
      });

      navigate(`../agenda/schedulings/${createResponse?.data.id}`);
    }
    setUpdateRuleIsOpen(false);
  }, [isSuccess, isPending]);

  useEffect(() => {
    if (isError) {
      reportError(errorResponse);
      const { errors } = errorHandler(errorResponse);
      let messageKey = isEdit ? t('snackbar.could_not_update_schedule') : t('snackbar.could_not_create_schedule');

      if (errors && Array.isArray(errors)) {
        let messageTitle = undefined;
        errors.forEach((error) => {
          if (error.type === ErrorTypes.VALIDATION && error.rule === 'conflictDates') {
            messageKey = t('snackbar.conflict_dates');
            messageTitle = t('snackbar.conflict_dates_title');
            if (Array.isArray(error.extra?.dates)) {
              setConflictDates(error.extra.dates.map((datesString) => apiDateToDateObject(datesString)));
            }

            enqueueSnackbar(messageKey, {
              variant: 'error',
              title: messageTitle,
            });
            return;
          }

          if (error.path) {
            const message = t(`snackbar.fields.${error.path}.${error.rule}`);

            setError(error.path, {
              type: 'manual',
              message,
            });

            enqueueSnackbar(message, {
              variant: 'error',
            });
          } else {
            enqueueSnackbar(messageKey, {
              variant: 'error',
            });
          }
        });

        trackCreatedEdited(errors);
      }
    }
  }, [isError, errorResponse]);

  return (
    <>
      <Grid container component='form' spacing={6} position='relative' onSubmit={handleSubmit(submit)}>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            defaultValue={undefined}
            name='product'
            render={({ field: { name, onChange, ...rest } }) => (
              <ProductSelector
                sellingModes={[SellingMode.WithDateOnly, SellingMode.WithDateAndTime]}
                openOnFocus={!!errors[`${name}`]}
                error={!!errors[`${name}`]}
                label={t('product')}
                placeholder={t('product_placeholder')}
                helperText={(errors[`${name}`]?.message as string) ?? ''}
                disabled={isEdit}
                onChange={(value) => {
                  onChange(value);
                  resetField('tariffGroup');
                  resetField('antecedenceTime');
                }}
                name={name}
                {...rest}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Stack sx={{ gap: '0.5rem' }}>
            <Typography variant='h6'>{t('form.tariff_group')}</Typography>
            <Divider />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'flex-start',
              gap: theme.spacing(2),
            }}
          >
            <IconInfoCircle size={24} style={{ flexShrink: 0, color: theme.palette.grey[700] }} />

            <Box>
              <Typography
                variant='smallSemiBold'
                sx={{ fontWeight: { xs: 400, md: 600 }, lineHeight: '1rem', maxWidth: '1048px' }}
                color={theme.palette.colors.gray[700]}
                component='p'
              >
                <Trans
                  t={t}
                  i18nKey='form.tariff_group_info'
                  components={{
                    Link: productWatch?.id ? (
                      <Link
                        href={`/${selectedAccount?.app?.code}/products/${productWatch?.id}/tariff-groups`}
                        target='_blank'
                      />
                    ) : (
                      <span />
                    ),
                  }}
                />
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={8} lg={6}>
          <Controller
            control={control}
            defaultValue={undefined}
            name='tariffGroup'
            render={({ field: { onChange, value } }) => (
              <TariffGroupSelector
                productId={productWatch?.id}
                disabled={!productWatch?.id}
                onChange={(value: TariffGroup | undefined) => onChange(value)}
                value={value}
                disabledMessage={t('form.tariff_group_disabled_message')}
              />
            )}
          />
        </Grid>

        {tariffGroupWatch?.deletedAt ? (
          <Grid item xs={12} sx={{ gap: '0.5rem' }}>
            <Alert
              icon={<IconAlertTriangle fontSize='inherit' color={theme.palette.colors.warning[700]} />}
              severity='warning'
              sx={{ background: theme.palette.colors.warning[100], color: theme.palette.colors.warning[700] }}
              data-testid='archived-tariff-warning'
            >
              <AlertTitle sx={{ ...theme.typography.regularBold }}>{t('tariff_group_archived.title')}</AlertTitle>

              <Typography variant='regularRegular' component='p'>
                <Trans t={t} i18nKey='tariff_group_archived.description' />
              </Typography>
            </Alert>
          </Grid>
        ) : null}

        <Grid item xs={12} sx={{ gap: '0.5rem' }}>
          <Stack sx={{ gap: '0.5rem' }}>
            <Typography variant='h6'>{t('form.occurrence')}</Typography>
            <Divider />
          </Stack>
        </Grid>

        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            defaultValue={undefined}
            name='beginDate'
            render={({ field: { value, name, onChange, ...rest } }) => (
              <CustomDatePicker
                name={name}
                format='dd/MM/yyyy'
                closeOnSelect
                value={value || null}
                error={!!errors[`${name}`]}
                fullWidth
                minDate={minimumDate}
                onChange={(date) => {
                  onChange(date as Date | undefined);
                }}
                label={t('form.begin_date')}
                helperText={(errors[`${name}`]?.message as string) ?? ''}
                tooltipProps={{
                  placement: 'top',
                  title: t('form.begin_date_tooltip'),
                }}
                {...rest}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <RecurrenceButton recurrence={recurrenceWatch} onRecurrenceChange={onRecurrenceChange} />
        </Grid>

        {conflictDates.length > 0 ? (
          <Suspense fallback={null}>
            <Grid item xs={12}>
              <LazyDateConflictAlert
                dates={conflictDates}
                schedulingForm={schedulingForm}
                loading={isPending}
                onStartResolving={() => setIsResolvingConflicts(true)}
                onCancelResolving={() => setIsResolvingConflicts(false)}
              />
            </Grid>
          </Suspense>
        ) : null}

        {isWithDateAndTime ? <TimeRange product={productWatch} control={control} formState={formState} /> : null}
        <Grid item xs={12} sx={{ gap: '0.5rem' }}>
          <Stack sx={{ gap: '0.5rem' }}>
            <Typography variant='h6'>{t('form.reservation.title')}</Typography>
            <Divider />
          </Stack>
        </Grid>

        <ReservationForm product={productWatch} form={schedulingForm} isEdit={isEdit} />

        <Grid item xs={12}>
          <Stack alignItems='center' gap={1} flexDirection='row'>
            <Button
              color='primary'
              variant='outlined'
              disabled={isPending || isSubmitting}
              startIcon={<X />}
              sx={{ marginLeft: 'auto' }}
              href='../agenda'
            >
              {t('buttons.cancel')}
            </Button>

            <Button
              type='submit'
              color='primary'
              variant='contained'
              disabled={isPending || isSubmitting}
              sx={{ padding: '0.75rem 1rem', width: '87px' }}
              startIcon={<IconDeviceFloppy />}
            >
              {t('buttons.save')}
            </Button>
          </Stack>
        </Grid>

        {isPending ? (
          <Grid item xs={12} textAlign='center' zIndex={999999} position='absolute' left='50%' top='50%'>
            <Box display='flex' alignItems='center' justifyContent='center' width='100%'>
              <CircularProgress color='primary' />
            </Box>
          </Grid>
        ) : null}
      </Grid>

      <Suspense fallback={null}>
        {isEdit ? (
          <LazyRecurrenceSelectorModal
            title={
              <Stack flexDirection='row' alignItems='center' gap='0.5rem'>
                <IconCalendarWeek /> {t('update-rule-modal.title')}
              </Stack>
            }
            description={`${t('update-rule-modal.description')}:`}
            acceptedUpdateRules={updateRules}
            isOpen={updateRuleIsOpen}
            onClose={onUpdateSaveCancel}
            onCancel={onUpdateSaveCancel}
            onConfirm={(data: UpdateRulesProps) => onUpdateSave(data)}
            initialDate={scheduling ? apiDateToDateObject(scheduling.appliesAt) : undefined}
          />
        ) : null}
      </Suspense>
    </>
  );
};

export default CreateAndEditSchedulingForm;
