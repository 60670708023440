import { styled } from '@mui/material/styles';
import AppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { Link } from '@mui/material';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  drawerWidth?: number;
}
const unsafeProps: PropertyKey[] = ['open', 'drawerWidth'];
export const StyledMuiAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => !unsafeProps.includes(prop),
})<AppBarProps>(({ theme, open, drawerWidth }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const LogoContainer = styled(Link, {
  shouldForwardProp: () => true,
})(() => ({
  width: '100%',
  maxWidth: '194px',
  display: 'flex',
}));

export const Logo = styled('img', {
  shouldForwardProp: () => true,
})(() => ({
  width: '100%',
  height: '100%',
}));

export const Content = styled('div', {
  shouldForwardProp: () => true,
})(() => ({
  flexGrow: 1,
}));
