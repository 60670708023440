import { format } from 'date-fns';
import { enqueueSnackbar } from 'notistack';
import { NotifiableError } from '@bugsnag/js';
import { useTranslation } from 'react-i18next';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { AccordionDetails, Alert, Box, Grid, Link } from '@mui/material';
import { FlagImage } from 'react-international-phone';

import theme from '@/theme';
import Button from '@/components/Button';
import Bugsnag from '@/services/bugsnag';
import Loading from '@/components/Loading';
import { createMask } from '@/utils/masks';
import { useSellerApi } from '@/hooks/useSellerApi';
import { Customer, Sale, SalesApiGetCustomerSalesRequest } from '@/services/SellerApi';
import ValueWithLabel, { ValueWithLabelProps } from '@/components/ValueWithLabel';
import { slotProps } from '@/features/sales/components/SaleDetail/style';
import { ClockFastForward } from '@untitled-ui/icons-react';
import ResponsiveTable, { TableColDef } from '@/components/Table';
import { GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { getAggregatedVouchersValueAndStatus } from '@/features/sales/components/utils';
import { StyledChip } from '@/components/Partnership/StatusChip/style';
import { formatCents, getWhatsappUrl, initialPaginationState } from '@/utils';

import Chip from '@/components/Chip';
import useAccount from '@/hooks/useAccount';
import SaleStatusChip from '@/features/sales/components/SaleStatusChip';
import { AccordionSummary, CustomerInfo, Accordion, Content, CoutryInfoWrapper } from './style';
import { findCountry, getCustomerDocumentFormatted } from '@/common/customer';

const customSlotProps: ValueWithLabelProps['slotProps'] = {
  ...slotProps,
  title: {
    ...slotProps?.title,
    variant: 'regularBold',
  },
  value: {
    ...slotProps?.value,
    variant: 'regularRegular',
  },
};

interface Props {
  customerId?: string;
  onClose: () => void;
}

export const CustomerDetail = (props: Props) => {
  const { customerId } = props;

  const { t } = useTranslation(['customer']);

  const { salesApi, customersApi } = useSellerApi();
  const { hasPermission, selectedAccount } = useAccount();

  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState<Customer | null>(null);

  const [customerSales, setCustomerSales] = useState<Sale[]>([]);
  const [customerSalesTotal, setCustomerSalesTotal] = useState(0);
  const [loadingCustomerSales, setLoadingCustomerSales] = useState(true);

  const [expanded, setExpanded] = useState<boolean>(true);
  const [paginationModel, setPaginationModel] = useState(initialPaginationState);

  const customerCountry = useMemo(() => {
    if (!customer?.countryCode) return undefined;

    return findCountry(customer.countryCode);
  }, [customer]);

  const customerStatus = useMemo(() => {
    if (customer?.hasAttachedBuyer) {
      return {
        label: 'authenticated',
        colors: {
          font: theme.palette.colors.green[700],
          background: theme.palette.colors.green[100],
        },
      };
    }

    return {
      label: 'not_authenticated',
      colors: {
        font: theme.palette.colors.gray[900],
        background: theme.palette.colors.gray[100],
      },
    };
  }, [customer?.hasAttachedBuyer]);

  const getCustomer = async (customerId: string) => {
    setLoading(true);

    try {
      const { data } = await customersApi.getCustomer({ customerId });

      setCustomer(data);
    } catch (error) {
      enqueueSnackbar(t('snackbars.could_not_load_customer'), { variant: 'error' });

      Bugsnag.notify(error as NotifiableError);
    } finally {
      setLoading(false);
    }
  };

  const getCustomerSales = async (customerId: string, limit?: number, offset?: number) => {
    setLoadingCustomerSales(true);

    const payload: SalesApiGetCustomerSalesRequest = {
      customerId,
      limit: limit,
      offset: offset,
      include: ['aggregatedVouchers'],
    };

    try {
      const { data, headers } = await salesApi.getCustomerSales(payload);

      setCustomerSales(data);
      setCustomerSalesTotal(parseInt(headers['x-pagination-total-count'] ?? '0'));
    } catch (error) {
      enqueueSnackbar(t('snackbars.could_not_load_customer_sales'), { variant: 'error' });

      Bugsnag.notify(error as NotifiableError);
    } finally {
      setLoadingCustomerSales(false);
    }
  };

  const renderAggregatedVouchers = (sale: Sale) => {
    const { status, value } = getAggregatedVouchersValueAndStatus(sale);

    return <StyledChip label={value} color={status} size='small' />;
  };

  const getCustomerCreatedAtFormatted = (createdAt: string) => {
    const [day, month, year, time] = format(new Date(createdAt), 'dd/MMMM/yyyy/HH:mm').split('/');

    return { day, month, year, time };
  };

  const getSaleUrl = (saleId: string) => {
    return `/${selectedAccount?.app?.code}/sales/${saleId}`;
  };

  const columns: TableColDef[] = [
    {
      flex: 1,
      sortable: false,
      headerName: t('details.table.code'),
      field: 'code',
      enableOnMobile: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Link
            target='_blank'
            variant='smallBold'
            href={getSaleUrl(params.row.id)}
            onClick={(e) => e.stopPropagation()}
            sx={{ cursor: 'pointer', color: theme.palette.colors.blue[500], textDecoration: 'underline' }}
          >
            {params.row.code}
          </Link>
        );
      },
    },
    {
      flex: 1,
      sortable: false,
      field: 'currentState',
      renderAsRightTitleOnMobile: true,
      headerName: t('details.table.status'),
      renderCell: (params: GridRenderCellParams) => (
        <SaleStatusChip
          size='small'
          status={params.row.currentState}
          label={t(`status.${params.row.currentState}`, { ns: 'sales' })}
        />
      ),
    },
    {
      field: 'amountCents',
      headerName: t('details.table.total_value'),
      valueGetter: (params: GridValueGetterParams) => formatCents(params.row.amountCents ?? 0),
      sortable: false,
      enableOnMobile: true,
      width: 92,
    },
    {
      field: 'aggregatedVouchers',
      headerName: t('details.table.vouchers'),
      renderCell: (params: GridRenderCellParams) => renderAggregatedVouchers(params.row),
      maxWidth: 80,
      sortable: false,
      enableOnMobile: true,
    },
    {
      field: 'createdAt',
      headerName: t('details.table.sale_date'),
      valueGetter: (params: GridValueGetterParams) => format(new Date(params.row.createdAt), 'dd/MM/yyyy - HH:mm'),
      sortable: false,
      flex: 1,
      enableOnMobile: true,
    },
  ];

  useEffect(() => {
    if (customerId) {
      getCustomer(customerId);
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (customerId && hasPermission('sales', 'GetCustomerSales')) {
      getCustomerSales(customerId, paginationModel.pageSize, paginationModel.page * paginationModel.pageSize);
    }
  }, [paginationModel]);

  return (
    <Fragment>
      {loading ? <Loading /> : null}

      {!loading && customer ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Content>
              <Box>
                <Chip
                  label={t(`details.${customerStatus.label}`)}
                  color={{
                    font: customerStatus.colors.font,
                    background: customerStatus.colors.background,
                  }}
                />
              </Box>

              <CustomerInfo>
                <ValueWithLabel
                  slotProps={customSlotProps}
                  title={t('details.name')}
                  value={`${customer.firstName} ${customer.lastName}`}
                />

                {customerCountry ? (
                  <ValueWithLabel
                    slotProps={customSlotProps}
                    title={t('details.country')}
                    value={
                      <CoutryInfoWrapper>
                        <FlagImage iso2={customerCountry.alpha2.toLowerCase()} />

                        {customerCountry.name}
                      </CoutryInfoWrapper>
                    }
                  />
                ) : null}

                {customer.identity ? (
                  <ValueWithLabel
                    slotProps={customSlotProps}
                    title={t('details.document')}
                    value={`${t(`details.document_type.${customer.identityType ?? 'identity'}`)} - ${getCustomerDocumentFormatted(customer.identity)}`}
                  />
                ) : null}

                <ValueWithLabel
                  slotProps={customSlotProps}
                  title={t('details.email')}
                  value={
                    <Link variant='regularRegular' href={`mailto:${customer.email}`}>
                      {customer.email}
                    </Link>
                  }
                />

                {customer.phone ? (
                  <ValueWithLabel
                    slotProps={customSlotProps}
                    title={t('details.phone')}
                    value={
                      <Link variant='regularRegular' href={getWhatsappUrl(customer.phone)} target='_blank'>
                        {createMask({ mask: '+00 00 0 0000-0000' })(customer.phone)}
                      </Link>
                    }
                  />
                ) : null}
              </CustomerInfo>
            </Content>
          </Grid>

          <Grid item xs={12}>
            <ValueWithLabel
              slotProps={{
                ...customSlotProps,
                title: { variant: 'smallBold' },
                value: { variant: 'smallRegular' },
              }}
              title={t('details.created_at')}
              value={t('details.created_at_value', {
                ...getCustomerCreatedAtFormatted(customer.createdAt),
              })}
            />
          </Grid>

          {hasPermission('sales', 'GetCustomerSales') ? (
            <Grid item xs={12}>
              <Accordion
                elevation={0}
                expanded={expanded}
                disabled={!customerSales.length}
                onChange={(_, value) => setExpanded(value)}
              >
                <AccordionSummary expanded={expanded}>
                  <Button
                    fullWidth
                    variant='text'
                    startIcon={<ClockFastForward />}
                    color={expanded ? 'primary' : 'secondary'}
                    sx={{ color: expanded ? theme.palette.colors.blue[500] : theme.palette.colors.gray[700] }}
                  >
                    {t('details.sold_items_button')}
                  </Button>
                </AccordionSummary>

                <AccordionDetails sx={{ padding: '0px !important' }}>
                  <ResponsiveTable
                    autoHeight
                    columns={columns}
                    disableColumnMenu
                    rows={customerSales}
                    paginationMode='server'
                    rowCount={customerSalesTotal}
                    loading={loadingCustomerSales}
                    paginationModel={paginationModel}
                    onPaginationModelChange={(newPaginationModel) => setPaginationModel(newPaginationModel)}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          ) : null}
        </Grid>
      ) : null}

      {!loading && !customer ? <Alert severity='error'>{t('snackbars.could_not_load_customer')}</Alert> : null}
    </Fragment>
  );
};

export default CustomerDetail;
