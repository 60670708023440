import { Tooltip as MUITooltip, TooltipProps } from '@mui/material';

interface Props extends TooltipProps {
  width?: number | string;
  textAlign?: 'left' | 'center' | 'right';
}

export const Tooltip = (props: Props) => {
  const { width = 191, textAlign = 'center', PopperProps, ...rest } = props;

  return (
    <MUITooltip
      arrow
      placement='top'
      enterTouchDelay={0}
      leaveTouchDelay={60000}
      PopperProps={{ style: { zIndex: 99999, width, textAlign }, ...PopperProps }}
      {...rest}
    />
  );
};

export default Tooltip;
