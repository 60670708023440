import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';

import theme from '@/theme';
import ListCartItems from './ListCartItems';
import { formatCents } from '@/utils';
import usePartnershipPortalCart from '@/hooks/usePartnershipPortalCart';
import { StyledBox } from './styles';
import PurchaseButton from './PurchaseButton';
import { ShoppingCart } from './ShoppingCart';

export const SideCart = () => {
  const { t } = useTranslation(['productShowCase']);

  const { cartItems, calculateTotalPriceOfAllCartItems } = usePartnershipPortalCart();

  return (
    <Grid container>
      <Grid item xs={12}>
        <StyledBox>
          <ShoppingCart />

          <Typography variant='h6' color={theme.palette.colors.gray[700]}>
            {t('cart.title')}
          </Typography>

          <Typography variant='regularSemiBold' color={theme.palette.colors.gray[700]}>
            {t('cart.subtotal')}
          </Typography>

          <Typography variant='h6' color={theme.palette.colors.gray[700]}>
            {formatCents(calculateTotalPriceOfAllCartItems(cartItems))}
          </Typography>

          <PurchaseButton href='checkout'>{t('cart.finish_button')}</PurchaseButton>
        </StyledBox>
      </Grid>

      <ListCartItems />
    </Grid>
  );
};

export default SideCart;
