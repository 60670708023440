import { Checkbox, Stack, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useMemo, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import IndeterminateCheckboxGroup from '@/components/IndeterminateCheckboxGroup';
import { AgendaConfigState, AgendaConfigFields, ViewDataType } from '@/features/agenda/types';
import theme from '@/theme';
import { AvailabilityTypeEnum } from '@/services/sellerApiQuery/model';
import { IndeterminateCheckboxOption } from '@/components/IndeterminateCheckboxGroup/types';
import { FilterContent } from '@/components/FilterDrawer/styles';
import FilterActions from '@/components/FilterDrawer/FilterActions';
import ProductSelector from '@/features/products/components/ProductSelector';
import { FilterOption } from '@/interfaces/filterContext';
import { useConfig } from '@/features/config/useConfig';
import useFilter from '@/hooks/useFilter';
import updateViewDataTypes from '@/features/agenda/utils/updateViewDataTypes';
import normalizeArray from '@/utils/normalizeArray';

interface AvailabilityTypeOption extends IndeterminateCheckboxOption {
  label: string;
  value: AvailabilityTypeEnum | string;
}

const AgendaPageFilters = () => {
  const { config, defaultConfig, patchConfig, clearConfig } = useConfig<AgendaConfigState>();
  const { closeFilters } = useFilter();

  const { t } = useTranslation(['agenda']);

  const [selectedFilters, setSelectedFilters] = useState<Partial<AgendaConfigFields>>(config);

  const availabilityTypes = Object.values(AvailabilityTypeEnum).map((value) => ({
    label: t(`filter.availability_type.${value}`),
    value,
  }));

  const totalOfSelectedFilters = useMemo(
    () => Object.values(selectedFilters).filter((value) => value || (Array.isArray(value) && value.length > 0)).length,
    [selectedFilters],
  );

  const totalOfFilters = useMemo(
    () => Object.values(config).filter((value) => value || (Array.isArray(value) && value.length > 0)).length,
    [config],
  );

  const viewDataTypes = useMemo(() => selectedFilters?.viewDataType ?? [], [selectedFilters]);

  const productsSelected = useMemo(() => selectedFilters?.products || config?.products, [selectedFilters, config]);

  const isAvailabilityTypeSelected = useMemo(
    () => selectedFilters?.viewDataType?.includes(ViewDataType.AVAILABILITIES),
    [selectedFilters],
  );

  const isScheduleTypeSelected = useMemo(
    () => selectedFilters?.viewDataType?.includes(ViewDataType.SCHEDULING),
    [selectedFilters],
  );

  const availabilityTypesSelect = useMemo(
    () => availabilityTypes.filter((option) => selectedFilters?.availabilityType?.includes(option.value)),
    [selectedFilters],
  );

  const toggleViewDataType = (data: ViewDataType[]) => {
    const value = data.length === 0 ? viewDataTypes : data;

    let filters: Partial<AgendaConfigFields> = {
      viewDataType: value,
    };

    if (!value.includes(ViewDataType.AVAILABILITIES) && (selectedFilters?.availabilityType?.length ?? 0) > 0) {
      filters = {
        ...filters,
        availabilityType: [],
      };
    }

    setSelectedFilters({
      ...selectedFilters,
      ...filters,
    });
  };

  const checkMinimalOneSelected = (checked: boolean, type: ViewDataType) => {
    const newViewDataType = updateViewDataTypes(checked, type, viewDataTypes);

    toggleViewDataType(newViewDataType);
  };

  const handlerAvailabilityType = (options: AvailabilityTypeOption[]) => {
    let filters: Partial<AgendaConfigFields> = {};

    if (options.length > 0) {
      const viewDataTypesAsArray = normalizeArray<ViewDataType>(viewDataTypes);
      filters = {
        availabilityType: options.map((o) => o.value as AvailabilityTypeEnum),
        viewDataType: [...viewDataTypesAsArray],
      };

      if (!viewDataTypesAsArray.includes(ViewDataType.AVAILABILITIES)) {
        filters = {
          ...filters,
          viewDataType: [...viewDataTypesAsArray, ViewDataType.AVAILABILITIES],
        };
      }
    } else {
      filters = {
        availabilityType: [],
      };
    }
    setSelectedFilters({
      ...selectedFilters,
      ...filters,
    });
  };

  const handlerProductFilterChange = (_: unknown, value: unknown) => {
    const newValue = value as FilterOption[];

    setSelectedFilters({
      ...selectedFilters,
      products: newValue.map((p) => p.value),
    });
  };

  const handleApplyFilter = () => {
    patchConfig(selectedFilters);
    closeFilters();
  };

  const handleResetFilters = () => {
    clearConfig();
    setSelectedFilters(defaultConfig);
    closeFilters();
  };

  return (
    <Fragment>
      <FilterContent
        sx={{
          flexDirection: 'column',
          display: 'flex',
          flex: 1,
          gap: theme.spacing(3),
          padding: { xs: theme.spacing(2), lg: theme.spacing(3) },
        }}
      >
        <Stack sx={{ flexDirection: 'column', gap: theme.spacing(2) }}>
          <ProductSelector value={productsSelected} onChange={handlerProductFilterChange} />

          <Stack sx={{ flexDirection: 'column' }}>
            <Typography variant='smallSemiBold'>{t('filter.view.title')}</Typography>

            <FormControlLabel
              label={t('filter.view.schedulings')}
              sx={{ display: 'flex', padding: 0, ml: '-11px' }}
              control={
                <Checkbox
                  checked={isScheduleTypeSelected}
                  onChange={(_, checked) => checkMinimalOneSelected(checked, ViewDataType.SCHEDULING)}
                />
              }
            />

            <IndeterminateCheckboxGroup
              label={t('filter.view.availabilities')}
              options={availabilityTypes}
              values={availabilityTypesSelect ?? []}
              allChecked={isAvailabilityTypeSelected}
              allCheckedChange={(checked) => checkMinimalOneSelected(checked, ViewDataType.AVAILABILITIES)}
              onChange={(option) => handlerAvailabilityType(option)}
            />
          </Stack>
        </Stack>
      </FilterContent>
      <FilterActions
        handleApplyFilter={() => handleApplyFilter()}
        handleCloseFilters={() => closeFilters()}
        handleResetFilters={() => handleResetFilters()}
        selectedFiltersLength={totalOfSelectedFilters}
        appliedFiltersLength={totalOfFilters}
      />
    </Fragment>
  );
};

export default AgendaPageFilters;
