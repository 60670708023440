import Bugsnag from '@/services/bugsnag';
import { AxiosRequestConfig, GenericAbortSignal } from 'axios';

type BasePayload = {
  limit?: number;
  offset?: number;
};

type Params<P extends BasePayload, T> = {
  apiMethod: (payload: P, options?: AxiosRequestConfig & { returnResponse?: boolean }) => Promise<T[]>;
  initialPayload?: Partial<P>;
  pageSize?: number;
  requestDelay?: number;
  signal: GenericAbortSignal;
};

const buildRequestWithAllPages = async <T, P extends BasePayload>(
  params: Params<P, T>,
): Promise<T[] | unknown | undefined> => {
  const { apiMethod, initialPayload, pageSize = 100, signal, requestDelay } = params;
  let fetchedData: T[] = [];
  let offset = 0;
  let hasMore = true;

  const payload: P = {
    ...initialPayload,
  } as P;

  try {
    while (hasMore) {
      const data = await apiMethod({ ...payload, limit: pageSize, offset } as P, { signal });

      fetchedData = fetchedData.concat(data);

      offset += pageSize;
      hasMore = data.length === pageSize;

      if (requestDelay) {
        await new Promise((resolve) => setTimeout(resolve, requestDelay));
      }
    }

    return fetchedData;
  } catch (error) {
    const isCancelledError =
      error &&
      Object.prototype.hasOwnProperty.call(error, 'code') &&
      (error as { code: string }).code === 'ERR_CANCELED';
    if (!isCancelledError) {
      Bugsnag.notify(error as Error);
      console.error(error);
      return { error };
    }
  }
};

export default buildRequestWithAllPages;
