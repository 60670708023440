import { zodResolver } from '@hookform/resolvers/zod';
import { Box, CircularProgress, Grid, useMediaQuery } from '@mui/material';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '@/components/Button';
import Input from '@/components/Form/Input';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import useAccount from '@/hooks/useAccount';
import useErrorHandler from '@/hooks/useErrorHandler';
import { useSellerApi } from '@/hooks/useSellerApi';
import { ErrorTypes } from '@/interfaces/errorTypes';
import { CreateAppTagParams, Tag, TagsApiUpdateTagRequest } from '@/services/SellerApi';
import theme from '@/theme';

import { schema } from './schema';

type Props = {
  tag?: Tag;
  onCancel: () => void;
  onSuccess?: () => void;
  onFail?: (error: AxiosError) => void;
};

export const AppTagsForm = (props: Props) => {
  const { onCancel, tag, onFail, onSuccess } = props;

  const { track } = useAnalytics();
  const { tagsApi } = useSellerApi();
  const { selectedAccount } = useAccount();
  const { errorHandler, ErrorValidationRules } = useErrorHandler();
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation(['sales']);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { handleSubmit, formState, control, setError } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: zodResolver(schema(t)),
    defaultValues: { name: tag?.name ?? '' },
  });

  const { errors, isSubmitting } = formState;

  const verifyUniqueError = (error: AxiosError) => {
    const { errors } = errorHandler(error);

    if (errors?.length && errors[0].type === ErrorTypes.VALIDATION) {
      if (errors[0].path?.split('.').slice(-1).join('') === 'name' && errors[0].rule === ErrorValidationRules.Unique) {
        setError('name', { message: t('app_tags.modal.form.validation_error.already_exists') });
      }
    }
  };

  const createAppTag = async (payload: CreateAppTagParams) => {
    try {
      setLoading(true);
      await tagsApi.createAppTag({ appId: selectedAccount?.appId ?? '', createAppTagParams: payload });

      enqueueSnackbar(t('messages.app_tags_created_successfully'), {
        variant: 'success',
      });

      track('appTags/createAppTagSuccess', { payload });

      onSuccess?.();
    } catch (error) {
      verifyUniqueError(error as AxiosError);

      enqueueSnackbar(t('messages.could_not_create_app_tag'), { variant: 'error' });
      track('appTags/createAppTagnFail', { error });

      onFail?.(error as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  const editCoupom = async (createAppTagParams: CreateAppTagParams, tagId: string) => {
    const payload: TagsApiUpdateTagRequest = { tagId, createAppTagParams };

    try {
      setLoading(true);
      await tagsApi.updateTag(payload);
      track('AppTags/updateAppTagSuccess', { payload });

      enqueueSnackbar(t('messages.app_tags_updated_successfully'), { variant: 'success' });

      onSuccess?.();
    } catch (error) {
      verifyUniqueError(error as AxiosError);

      enqueueSnackbar(t('messages.could_not_update_app_tag'), { variant: 'error' });

      track('AppTags/updateAppTagFail', { error });

      onFail?.(error as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  const submit = (payload: FieldValues) => {
    if (tag?.id) {
      editCoupom(payload as CreateAppTagParams, tag.id);
      return;
    }

    createAppTag(payload as CreateAppTagParams);
  };

  return (
    <Grid container component='form' spacing={3} position='relative' onSubmit={handleSubmit(submit)}>
      <Grid item xs={12}>
        <Controller
          name='name'
          control={control}
          render={({ field: { onChange, value, name, ...rest } }) => (
            <Input
              name={name}
              value={value || ''}
              onChange={onChange}
              fullWidth
              id='name'
              required
              label={t('app_tags.modal.form.input_label')}
              autoFocus
              error={!!errors['name']}
              helperText={errors['name']?.message as string}
              {...rest}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Box display='flex' alignItems='center' gap={1} flexDirection='row'>
          <Button
            type='submit'
            color='primary'
            variant='contained'
            fullWidth={isMobile}
            disabled={loading || isSubmitting}
            sx={{ padding: '0.75rem 1rem', width: isMobile ? '100%' : '87px' }}
          >
            {t('app_tags.modal.form.submit_button')}
          </Button>

          <Button
            color='primary'
            variant='outlined'
            onClick={onCancel}
            fullWidth={isMobile}
            disabled={loading || isSubmitting}
          >
            {t('app_tags.modal.form.cancel_button')}
          </Button>
        </Box>
      </Grid>

      {loading ? (
        <Grid item xs={12} textAlign='center' zIndex={999999} position='absolute' left='50%' top='50%'>
          <Box display='flex' alignItems='center' justifyContent='center' width='100%'>
            <CircularProgress color='primary' />
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default AppTagsForm;
