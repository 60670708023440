import { Box, buttonClasses, styled, Typography } from '@mui/material';

import Button from '@/components/Button';

export const StyledMonthSelectorTitle = styled(Typography)(({ theme }) => ({
  textTransform: 'capitalize',
  display: 'flex',
  flexDirection: 'row',
  columnGap: '0.5rem',

  [theme.breakpoints.down('md')]: {
    fontSize: '0.8125rem',
  },
}));

export const StyledMonthSelectorButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: '0.5rem',
    backgroundColor: theme.palette.colors.gray['50'],
    color: theme.palette.colors.gray['700'],
    [`&.${buttonClasses.outlined}`]: {
      borderColor: theme.palette.colors.gray['50'],
    },
  },
}));

export const ClearButton = styled(Box)(({ theme }) => ({
  color: theme.palette.colors.primary['500'],
  backgroundColor: 'transparent',
  border: '0',
  padding: '0',
}));
