import { colors } from '@/theme';
import { Stack, styled } from '@mui/material';

export const StyledMobileHeader = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  position: 'fixed',
  width: '100%',
  top: 0,
  zIndex: 0,
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(4),
  borderBottomWidth: 2,
  borderBottomStyle: 'solid',
  borderColor: colors.gray[200],
}));

export const StyledMobileHeaderRoot = styled('div')(() => ({
  ['@media (max-height: 750px)']: {
    ['&.with-header']: {
      paddingTop: '4rem',
    },
  },
}));
