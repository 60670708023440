import { QueryFunctionContext } from '@tanstack/react-query';

import { useSellerApi } from '@/hooks/useSellerApi';
import { Availability, SchedulingsApiGetSchedulingRelatedAvailabilitiesRequest } from '@/services/SellerApi';
import { RequiredError } from '@/services/SellerApi/base';

type Query = [key: string, schedulingId: string | null];

const useGetRelatedAvailabilitiesOfAggregation = () => {
  const { schedulingsApi } = useSellerApi();

  const request = async ({ queryKey, signal }: QueryFunctionContext<Query>): Promise<Availability[] | undefined> => {
    const [_, schedulingId] = queryKey;

    const pageSize = 10;
    const payload: SchedulingsApiGetSchedulingRelatedAvailabilitiesRequest = {
      schedulingId: schedulingId as string,
      include: [
        'products',
        'productTariffTypes.tariffType',
        'productTariffTypes.product',
        'tariffs.type',
        'tariffs.group',
        'tariffs.product',
        'additionals.product',
      ],
    };

    try {
      const { data, headers } = await schedulingsApi.getSchedulingRelatedAvailabilities(
        { ...payload, offset: 0, limit: 1 },
        { signal },
      );

      const totalCount = parseInt(headers['x-pagination-total-count'] ?? '0');
      const totalPages = Math.ceil(totalCount / pageSize);

      const promises = [];

      for (let page = 0; page <= totalPages - 1; page++) {
        promises.push(
          schedulingsApi.getSchedulingRelatedAvailabilities(
            {
              ...payload,
              offset: page * pageSize,
            },
            { signal },
          ),
        );
      }

      const dataArray = await Promise.all(promises);

      const allPages = dataArray.reduce((accumulator: Availability[], response) => {
        return accumulator.concat(response.data);
      }, data);

      return allPages;
    } catch (error) {
      console.error(error);
      return error instanceof RequiredError ? [] : undefined;
    }
  };

  return { request };
};

export default useGetRelatedAvailabilitiesOfAggregation;
