import { Box, Stack, Typography } from '@mui/material';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '@/components/Alert';
import SchedulingAvailabilityRelated from '@/features/agenda/components/SchedulingModal/SchedulingModalContent/SchedulingAvailabilityRelated';
import SchedulingModalDetails from '@/features/agenda/components/SchedulingModal/SchedulingModalContent/SchedulingModalDetails';
import useAccount from '@/hooks/useAccount';
import { PolicyRuleNamespaceEnum, Scheduling } from '@/services/SellerApi';
import theme from '@/theme';
import { dateWithTimezone } from '@/utils';
import getRightSchedulingTariffGroupFromApi from '@/features/agenda/utils/getRightSchedulingTariffGroupFromApi';

interface SchedulingModalProps {
  scheduling: Scheduling;
}

const SchedulingModalContent = (props: SchedulingModalProps) => {
  const { scheduling } = props;
  const { hasPermission } = useAccount();
  const { t } = useTranslation(['agenda'], { keyPrefix: 'modal' });

  const isTariffGroupArchived = useMemo(
    () => getRightSchedulingTariffGroupFromApi(scheduling)?.deletedAt,
    [scheduling],
  );

  const schedulingDateString = useMemo(() => {
    if (scheduling && scheduling.appliesAt) {
      const dataString = dateWithTimezone(scheduling.appliesAt).toLocaleDateString(undefined, {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      });

      return dataString.charAt(0).toUpperCase() + dataString.slice(1);
    }
    return '';
  }, [scheduling]);

  return (
    <Fragment>
      <Stack flexDirection='column' justifyContent='space-between' sx={{ paddingBottom: theme.spacing(4) }}>
        <Typography variant='regularRegular'>{schedulingDateString}</Typography>
      </Stack>

      <SchedulingModalDetails scheduling={scheduling} />

      {isTariffGroupArchived ? (
        <Alert severity='warning' sx={{ marginTop: theme.spacing(3), borderRadius: '0.5rem' }}>
          <Stack sx={{ gap: theme.spacing(2) }}>
            <Typography variant='regularBold'>{t('scheduling.tariff_group_archived.title')}</Typography>
            <Box>{t('scheduling.tariff_group_archived.description')}</Box>
          </Stack>
        </Alert>
      ) : null}

      {hasPermission(PolicyRuleNamespaceEnum.Availabilities, 'GetSchedulingRelatedAvailabilities') ? (
        <SchedulingAvailabilityRelated scheduling={scheduling} />
      ) : null}
    </Fragment>
  );
};

export default SchedulingModalContent;
