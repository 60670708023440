import { TFunction } from 'i18next';
import { cnpj, cpf } from 'cpf-cnpj-validator';

import { z } from 'zod';
import { removeMask } from '@/utils/masks';
import { IdentityTypeEnum } from '@/services/SellerApi';
import { MERCOSUL_COUNTRY_CODE } from '@/common/countries';

export const schema = (t: TFunction<'payment'[]>) => {
  const requiredMessage = t('customer_form.errors.required_field');

  const requiredStringField = z.string({ required_error: requiredMessage }).nonempty({ message: requiredMessage });

  return z
    .object({
      firstName: requiredStringField,
      lastName: requiredStringField,
      email: requiredStringField,
      phone: requiredStringField.superRefine((value, ctx) => {
        if (!value) return;

        if (!removeMask(value)) {
          return ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: requiredMessage,
          });
        }

        // the real phone number after DDI
        if (!value.split(' ')[1]) {
          return ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('customer_form.errors.invalid_phone'),
          });
        }
      }),
      identity: requiredStringField,
      nationality: z
        .object(
          {
            alpha2: requiredStringField,
            alpha3: requiredStringField,
            code: requiredStringField,
            name: requiredStringField,
            emoji: requiredStringField,
            unicode: requiredStringField,
            dialCode: requiredStringField,
            image: requiredStringField,
          },
          { required_error: requiredMessage, invalid_type_error: t('customer_form.errors.invalid_country') },
        )
        .refine((value) => !!value?.alpha3, {
          message: requiredMessage,
        }),
      identityType: z.string().optional(),
      corporateName: z.string().optional(),
    })
    .superRefine(({ identityType, identity, nationality, corporateName }, ctx) => {
      const isBrazil = nationality.alpha3 === MERCOSUL_COUNTRY_CODE.BRAZIL;
      const isValidBrazilianIdentity = cpf.isValid(identity) || cnpj.isValid(identity);

      if (isBrazil && !isValidBrazilianIdentity) {
        ctx.addIssue({
          path: ['identity'],
          code: z.ZodIssueCode.custom,
          message: t('customer_form.errors.invalid_document'),
        });
      }

      if (cnpj.isValid(identity) && !corporateName) {
        ctx.addIssue({
          path: ['corporateName'],
          code: z.ZodIssueCode.custom,
          message: requiredMessage,
        });
      }

      if (identityType === IdentityTypeEnum.Cpf && !cpf.isValid(identity)) {
        ctx.addIssue({
          path: ['identity'],
          code: z.ZodIssueCode.custom,
          message: t('customer_form.errors.invalid_document'),
        });
      }

      if (identityType === IdentityTypeEnum.Cnpj && !cnpj.isValid(identity)) {
        ctx.addIssue({
          path: ['identity'],
          code: z.ZodIssueCode.custom,
          message: t('customer_form.errors.invalid_document'),
        });
      }
    })
    .transform((data) => {
      const hasIdentityType = !!data.identityType;
      const brazilianIdentityType = cpf.isValid(data.identity) ? IdentityTypeEnum.Cpf : IdentityTypeEnum.Cnpj;
      const countryCode = data.nationality?.alpha3;

      return {
        countryCode,
        firstName: data.firstName.trim(),
        lastName: data.lastName.trim(),
        email: data.email.trim(),
        phone: data.phone.replace(/[^0-9+]/g, ''),
        identity: data.identity.replace(/[^0-9a-zA-Z]/g, ''),
        identityType: hasIdentityType ? data.identityType : brazilianIdentityType,
        corporateName: data.corporateName,
      };
    });
};
