import { useEffect, useState } from 'react';

import SearchInput from '@/components/Form/SearchInput';
import { useTranslation } from 'react-i18next';
import useFilter from '@/hooks/useFilter';

type Props = {
  placeholder?: string;
};

export const Search = (props: Props) => {
  const { t } = useTranslation(['ui']);
  const { placeholder = t('searchInput.placeholder') } = props;

  const { onSearch, search: filterSearch } = useFilter();

  const [search, setSearch] = useState(filterSearch ?? '');

  const handleSearch = (search: string) => {
    const trimmedSearch = search.trim();
    onSearch(trimmedSearch);
    setSearch(trimmedSearch);
  };

  useEffect(() => {
    setSearch(filterSearch ?? '');
  }, [filterSearch]);

  return (
    <SearchInput
      value={search}
      onSearch={() => handleSearch(search)}
      onChange={(e) => setSearch(e.target.value)}
      onClear={() => {
        setSearch('');
        onSearch(undefined);
      }}
      placeholder={placeholder}
    />
  );
};

export default Search;
