import { FormControlLabel, FormHelperText } from '@mui/material';
import { css, styled } from '@mui/material/styles';

export const StyledCheckbox = styled(FormControlLabel)(
  ({ theme }) => css`
    span:first-of-type {
      padding: 0 8px;
    }

    .MuiTypography-root.MuiFormControlLabel-label {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: ${theme.palette.colors.gray[700]};
    }
  `,
);

export const HelperText = styled(FormHelperText)(
  () => css`
    margin-left: 0;
    margin-top: 4px;
  `,
);
