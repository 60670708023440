/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Planne Seller API
 * OpenAPI spec version: 0.1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  GetReservationParams,
  GetReservationTariffsParams,
  GetSaleReservationsParams,
  NotFoundResponseResponse,
  RescheduleReservationParams,
  Reservation,
  ReservationTariff,
  SecureRescheduleReservationParams,
  UnprocessableResponseResponse
} from '../../model'
import { customInstance } from '../../mutator/custom-instance';
import type { ErrorType, BodyType } from '../../mutator/custom-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const getSaleReservations = (
    saleId: string,
    params?: GetSaleReservationsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<Reservation[]>(
      {url: `/sales/${saleId}/reservations`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetSaleReservationsQueryKey = (saleId: string,
    params?: GetSaleReservationsParams,) => {
    return [`/sales/${saleId}/reservations`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSaleReservationsQueryOptions = <TData = Awaited<ReturnType<typeof getSaleReservations>>, TError = ErrorType<NotFoundResponseResponse>>(saleId: string,
    params?: GetSaleReservationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSaleReservations>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSaleReservationsQueryKey(saleId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSaleReservations>>> = ({ signal }) => getSaleReservations(saleId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(saleId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSaleReservations>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSaleReservationsQueryResult = NonNullable<Awaited<ReturnType<typeof getSaleReservations>>>
export type GetSaleReservationsQueryError = ErrorType<NotFoundResponseResponse>



export function useGetSaleReservations<TData = Awaited<ReturnType<typeof getSaleReservations>>, TError = ErrorType<NotFoundResponseResponse>>(
 saleId: string,
    params?: GetSaleReservationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSaleReservations>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetSaleReservationsQueryOptions(saleId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getReservation = (
    reservationId: string,
    params?: GetReservationParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<Reservation>(
      {url: `/reservations/${reservationId}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetReservationQueryKey = (reservationId: string,
    params?: GetReservationParams,) => {
    return [`/reservations/${reservationId}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetReservationQueryOptions = <TData = Awaited<ReturnType<typeof getReservation>>, TError = ErrorType<NotFoundResponseResponse>>(reservationId: string,
    params?: GetReservationParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getReservation>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetReservationQueryKey(reservationId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getReservation>>> = ({ signal }) => getReservation(reservationId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(reservationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getReservation>>, TError, TData> & { queryKey: QueryKey }
}

export type GetReservationQueryResult = NonNullable<Awaited<ReturnType<typeof getReservation>>>
export type GetReservationQueryError = ErrorType<NotFoundResponseResponse>



export function useGetReservation<TData = Awaited<ReturnType<typeof getReservation>>, TError = ErrorType<NotFoundResponseResponse>>(
 reservationId: string,
    params?: GetReservationParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getReservation>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetReservationQueryOptions(reservationId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getReservationTariffs = (
    reservationId: string,
    params?: GetReservationTariffsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ReservationTariff[]>(
      {url: `/reservations/${reservationId}/tariffs`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetReservationTariffsQueryKey = (reservationId: string,
    params?: GetReservationTariffsParams,) => {
    return [`/reservations/${reservationId}/tariffs`, ...(params ? [params]: [])] as const;
    }

    
export const getGetReservationTariffsQueryOptions = <TData = Awaited<ReturnType<typeof getReservationTariffs>>, TError = ErrorType<NotFoundResponseResponse>>(reservationId: string,
    params?: GetReservationTariffsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getReservationTariffs>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetReservationTariffsQueryKey(reservationId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getReservationTariffs>>> = ({ signal }) => getReservationTariffs(reservationId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(reservationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getReservationTariffs>>, TError, TData> & { queryKey: QueryKey }
}

export type GetReservationTariffsQueryResult = NonNullable<Awaited<ReturnType<typeof getReservationTariffs>>>
export type GetReservationTariffsQueryError = ErrorType<NotFoundResponseResponse>



export function useGetReservationTariffs<TData = Awaited<ReturnType<typeof getReservationTariffs>>, TError = ErrorType<NotFoundResponseResponse>>(
 reservationId: string,
    params?: GetReservationTariffsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getReservationTariffs>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetReservationTariffsQueryOptions(reservationId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const rescheduleReservation = (
    reservationId: string,
    rescheduleReservationParams: BodyType<RescheduleReservationParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<Reservation>(
      {url: `/reservations/${reservationId}/reschedule`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: rescheduleReservationParams, signal
    },
      options);
    }
  


export const getRescheduleReservationMutationOptions = <TError = ErrorType<NotFoundResponseResponse | UnprocessableResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof rescheduleReservation>>, TError,{reservationId: string;data: BodyType<RescheduleReservationParams>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof rescheduleReservation>>, TError,{reservationId: string;data: BodyType<RescheduleReservationParams>}, TContext> => {
    
const mutationKey = ['rescheduleReservation'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof rescheduleReservation>>, {reservationId: string;data: BodyType<RescheduleReservationParams>}> = (props) => {
          const {reservationId,data} = props ?? {};

          return  rescheduleReservation(reservationId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RescheduleReservationMutationResult = NonNullable<Awaited<ReturnType<typeof rescheduleReservation>>>
    export type RescheduleReservationMutationBody = BodyType<RescheduleReservationParams>
    export type RescheduleReservationMutationError = ErrorType<NotFoundResponseResponse | UnprocessableResponseResponse>

    export const useRescheduleReservation = <TError = ErrorType<NotFoundResponseResponse | UnprocessableResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof rescheduleReservation>>, TError,{reservationId: string;data: BodyType<RescheduleReservationParams>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof rescheduleReservation>>,
        TError,
        {reservationId: string;data: BodyType<RescheduleReservationParams>},
        TContext
      > => {

      const mutationOptions = getRescheduleReservationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const secureRescheduleReservation = (
    reservationId: string,
    secureRescheduleReservationParams: BodyType<SecureRescheduleReservationParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<Reservation>(
      {url: `/reservations/${reservationId}/secureReschedule`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: secureRescheduleReservationParams, signal
    },
      options);
    }
  


export const getSecureRescheduleReservationMutationOptions = <TError = ErrorType<NotFoundResponseResponse | UnprocessableResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof secureRescheduleReservation>>, TError,{reservationId: string;data: BodyType<SecureRescheduleReservationParams>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof secureRescheduleReservation>>, TError,{reservationId: string;data: BodyType<SecureRescheduleReservationParams>}, TContext> => {
    
const mutationKey = ['secureRescheduleReservation'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof secureRescheduleReservation>>, {reservationId: string;data: BodyType<SecureRescheduleReservationParams>}> = (props) => {
          const {reservationId,data} = props ?? {};

          return  secureRescheduleReservation(reservationId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SecureRescheduleReservationMutationResult = NonNullable<Awaited<ReturnType<typeof secureRescheduleReservation>>>
    export type SecureRescheduleReservationMutationBody = BodyType<SecureRescheduleReservationParams>
    export type SecureRescheduleReservationMutationError = ErrorType<NotFoundResponseResponse | UnprocessableResponseResponse>

    export const useSecureRescheduleReservation = <TError = ErrorType<NotFoundResponseResponse | UnprocessableResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof secureRescheduleReservation>>, TError,{reservationId: string;data: BodyType<SecureRescheduleReservationParams>}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof secureRescheduleReservation>>,
        TError,
        {reservationId: string;data: BodyType<SecureRescheduleReservationParams>},
        TContext
      > => {

      const mutationOptions = getSecureRescheduleReservationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    