import Analytics from 'analytics';
import mixpanelPlugin from '@analytics/mixpanel';

const mixpanelToken = import.meta.env.VITE_MIXPANEL_TOKEN;
const plugins = [];

if (mixpanelToken) {
  plugins.push(mixpanelPlugin({ token: mixpanelToken }));
}

export const analytics = Analytics({
  app: 'planne-dashboard',
  plugins,
});

export default analytics;
