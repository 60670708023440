import { Chip as MUIChip, css, styled } from '@mui/material';

export const Chip = styled(MUIChip)(
  () => css`
    max-width: 250px;

    @media (max-width: 300px) {
      max-width: 162px;
    }
  `,
);
