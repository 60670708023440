import { styled } from '@mui/material/styles';
import WarningIcon from '@mui/icons-material/Warning';
import { Typography } from '@mui/material';

export const Warning = styled(WarningIcon)(({ theme }) => ({
  color: theme.palette.colors.warning[400],
  width: '6rem',
  height: 'auto',
}));

export const Message = styled(Typography)(() => ({
  textAlign: 'center',
  marginBottom: '1rem',
  color: 'black',
}));

export const Description = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: '2rem',
  fontSize: '1rem',
  color: theme.palette.colors.gray[700],
  fontWeight: '400',
  lineHeight: '19px',
}));

export const TransactionErrorAlertModalTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  marginLeft: '2rem',
  textAlign: 'center',
}));
