import { CardContent, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

export const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(4),
}));

export const StyledContent = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2), 
  position: 'relative'
}));

export const HeaderContainer = styled(Stack)(() => ({
  flexDirection: 'row', 
  justifyContent: 'space-between',
}));

export const TitleContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row', 
  justifyContent: 'center', 
  alignContent: 'center', 
  gap: theme.spacing(2)
}));

export const SeatMapTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.gray[500],
  marginTop: theme.spacing(2),
  ...theme.typography.smallSemiBold,
}));

export const SeatMapTotalCapacity = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.gray[700],
  ...theme.typography.smallBold,
}));
