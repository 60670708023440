import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import AccountSelector from '@/components/AccountSelector';
import ModalTitle from '@/components/AccountSelector/ModalTitle';
import Modal from '@/components/Modal';
import { GenericModalProps } from '@/components/Modal/types';
import useAccount from '@/hooks/useAccount';

const AccountSelectorModal = (props: Partial<GenericModalProps>) => {
  const { ...rest } = props;
  const { appCode } = useParams();

  const { accountSelectorOpen, setAccountSelectorOpen, selectAccountWithCode, initialized } = useAccount();
  const modalRef = useRef(null);

  useEffect(() => {
    if (initialized && appCode) {
      selectAccountWithCode(appCode);
    }
  }, [initialized, appCode]);

  return (
    <Modal
      title={<ModalTitle />}
      open={accountSelectorOpen}
      onClose={() => setAccountSelectorOpen(false)}
      width={775}
      showCloseButton={false}
      sx={{ '& h2': { paddingRight: 0 } }}
      ref={modalRef}
      {...rest}
    >
      <AccountSelector
        parentRef={modalRef}
        onSelect={() => {
          setAccountSelectorOpen(false);
        }}
      />
    </Modal>
  );
};

export default AccountSelectorModal;
