import { styled } from '@mui/material/styles';
import { TablePagination as MUITablePagination, TablePaginationProps } from '@mui/material';
import { ElementType } from 'react';

type MUITablePaginationProps = TablePaginationProps & { component: ElementType };

export const TablePagination = styled(MUITablePagination)<MUITablePaginationProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',

  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
    justifyContent: 'center',
  },

  '& .MuiInputBase-root': {
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginRight: '0.875rem',
    },
  },

  '& .MuiToolbar-root': {
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },

  '& .MuiTablePagination-selectLabel': {
    color: theme.palette.colors.blue[500],
    padding: '0.5rem',
    borderRadius: '4px',
    fontSize: '1rem',
    border: `1px solid ${theme.palette.colors.blue[500]}`,
    display: 'none',

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      padding: '0.625rem 1rem',
    },
  },

  '& .MuiTablePagination-displayedRows': {
    color: theme.palette.colors.white[600],
    borderRadius: '4px',
    background: theme.palette.colors.blue[500],
    fontSize: '1rem',
    padding: '0.5rem',

    [theme.breakpoints.up('md')]: {
      padding: '0.625rem 1rem',
    },
  },

  '& .MuiSelect-select': {
    border: `1px solid ${theme.palette.colors.blue[500]}`,
    borderRadius: '4px',
    color: theme.palette.colors.blue[500],
    padding: '0.5rem',

    [theme.breakpoints.up('md')]: {
      padding: '0.625rem 1rem',
    },
  },

  '& .MuiTablePagination-actions': {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',

    [theme.breakpoints.down('md')]: {
      marginLeft: '0.75rem !important',
    },

    button: {
      border: `1px solid ${theme.palette.colors.blue[500]}`,
      borderRadius: '4px',
      color: theme.palette.colors.blue[500],
      padding: '0.5rem',

      [theme.breakpoints.up('md')]: {
        padding: '0.625rem 1rem',
      },
    },

    'button:disabled': {
      cursor: 'not-allowed',
      background: theme.palette.colors.blue[50],
      color: theme.palette.colors.blue[200],
      border: 'none',
    },
  },
}));
