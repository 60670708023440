import { AccountContextType } from '@/contexts/AccountContext';

const getExtraAccountInformation = (data: object, account: AccountContextType) => {
  const { selectedAccount } = account;

  if (!selectedAccount) {
    return data;
  }

  const { planId: app_planId, code: app_code, name: app_name, id: app_id } = selectedAccount.app || {};
  const roleId = selectedAccount.role?.id;

  return {
    ...data,
    roleId,
    app_planId,
    app_code,
    app_name,
    app_id,
  };
};

export default getExtraAccountInformation;
