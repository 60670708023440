import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Edit05 } from '@untitled-ui/icons-react';
import { Grid, Paper, Typography, Chip, useMediaQuery } from '@mui/material';

import Button from '@/components/Button';
import { Coupon, Partnership } from '@/services/SellerApi';
import ModalOnDesktopDrawerOnMobile from '@/components/Modal/ModalOnDesktopDrawerOnMobile';
import PartnershipCouponForm from '../PartnershipCouponForm';
import theme, { colors } from '@/theme';
import { IconLink } from '@tabler/icons-react';

type Props = {
  coupon: Coupon;
  loading: boolean;
  isProvider: boolean;
  partnership: Partnership;
  onUpdateCoupon: (coupon: Coupon) => void;
};

export const PartnershipCouponCard = (props: Props) => {
  const { loading, coupon, isProvider, partnership, onUpdateCoupon } = props;

  const { t } = useTranslation(['partnership']);

  const [showCouponModal, setShowCouponModal] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Paper elevation={2} sx={{ p: { xs: 2, md: 4 }, opacity: loading ? 0.5 : 1 }}>
        <Grid container spacing={{ xs: 2, md: 3 }} alignItems='center' justifyContent='space-between'>
          <Grid item flexGrow={1}>
            <Grid container alignItems='center' spacing={2}>
              <Grid item xs={4} sm={3} md={3} lg={2} xl={1.5}>
                <Typography variant='smallBold' component='p' color={colors.gray[500]}>
                  {t('detail.coupon_list.card.code_label')}
                </Typography>

                <Typography
                  variant='smallRegular'
                  component='p'
                  color={colors.gray[500]}
                  sx={{ wordWrap: 'break-word' }}
                >
                  {coupon.code}
                </Typography>
              </Grid>

              <Grid item xs={5} sm={3} md={3} lg={2} xl={1.5}>
                <Typography variant='smallBold' component='p' color={colors.gray[500]}>
                  {t('detail.coupon_list.card.discount_label')}
                </Typography>

                <Typography variant='smallRegular' component='p' color={colors.gray[500]}>
                  {coupon.discountPercentage}%
                </Typography>
              </Grid>

              {coupon.isPartnershipDefault ? (
                <Grid item xs={3} sm={4} md={5} lg={3} xl={2}>
                  <Chip
                    size='small'
                    color='primary'
                    variant='outlined'
                    icon={!isMobile ? <IconLink size={21} /> : undefined}
                    sx={{
                      background: colors.blue[100],
                      border: 'none',
                      borderRadius: '4px',
                    }}
                    label={!isMobile ? t('detail.coupon_list.card.linked_coupon') : <IconLink size={21} />}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>

          {!isProvider ? (
            <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
              <Button
                color='primary'
                variant='outlined'
                onClick={() => setShowCouponModal(true)}
                sx={{ gap: 0.5 }}
                fullWidth
              >
                <Edit05 />

                <Typography>{t('detail.coupon_list.card.edit_button_label')}</Typography>
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Paper>

      <ModalOnDesktopDrawerOnMobile
        isOpen={showCouponModal}
        onClose={() => setShowCouponModal(false)}
        title={t('detail.coupon_list.edit_form.title')}
        width={667}
      >
        <PartnershipCouponForm
          coupon={coupon}
          onSuccess={() => {
            onUpdateCoupon(coupon);
            setShowCouponModal(false);
          }}
          onCancel={() => {
            setShowCouponModal(false);
          }}
          partnership={partnership}
        />
      </ModalOnDesktopDrawerOnMobile>
    </>
  );
};

export default PartnershipCouponCard;
