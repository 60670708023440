import { Box, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import AgendaHoverDetails from '@/features/agenda/components/SchedulingCalendar/AgendaHoverDetails';
import { StyledCard } from '@/features/agenda/components/SchedulingCalendar/AgendaWeeklyView/WeeklySlot/styles';
import { Scheduling } from '@/services/SellerApi';
import theme from '@/theme';
import { StyledAvailabilityCard } from '@/features/agenda/components/SchedulingCalendar/styles';
import { IconAlertTriangle, IconCircleCheck } from '@tabler/icons-react';
import { StyledCardLink } from '@/features/agenda/components/SchedulingCalendar/AgendaSlot/styles';
import isSchedulingForAllDay from '@/utils/isSchedulingForAllDay';
import getRightSchedulingTariffGroupFromApi from '@/features/agenda/utils/getRightSchedulingTariffGroupFromApi';

interface Props {
  scheduling: Scheduling;
}

const SlotTypeScheduling = (props: Props) => {
  const { scheduling } = props;
  const { search } = useLocation();
  const { t } = useTranslation(['agenda']);

  const getReferenteEndTime = useMemo(() => {
    if (isSchedulingForAllDay(scheduling)) {
      return t('all_day');
    }
    if (scheduling.consumableFromTime && scheduling.consumableToTime) {
      return `${scheduling.consumableFromTime} - ${scheduling.consumableToTime}`;
    }
  }, [scheduling]);

  const name = useMemo(() => scheduling.product?.internalName ?? scheduling.product?.name, [scheduling.product]);
  const tariffGroup = useMemo(() => getRightSchedulingTariffGroupFromApi(scheduling), [scheduling]);
  const isSchedulingTariffGroupUndefined = useMemo(() => !scheduling.tariffGroup, [scheduling]);
  const isTariffGroupArchived = useMemo(() => !!tariffGroup?.deletedAt, [tariffGroup]);

  const principalIcon = useMemo(() => {
    if (isSchedulingTariffGroupUndefined && !isTariffGroupArchived) {
      return (
        <Stack>
          <IconCircleCheck size='0.875rem' />
        </Stack>
      );
    }
    if (isTariffGroupArchived) {
      return (
        <Stack>
          <IconAlertTriangle size='0.875rem' />
        </Stack>
      );
    }
  }, [isSchedulingTariffGroupUndefined, isTariffGroupArchived]);

  return (
    <AgendaHoverDetails availability={undefined} scheduling={scheduling}>
      <StyledCardLink href={`./schedulings/${scheduling.id}${search}`}>
        <StyledAvailabilityCard>
          <StyledCard.Title.Text>
            <Typography
              variant='smallRegular'
              sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', gap: theme.spacing(1) }}
            >
              {name ? (
                <Typography
                  variant='smallSemiBold'
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  {scheduling.product?.internalName ?? scheduling.product?.name}
                </Typography>
              ) : null}
              {getReferenteEndTime ? (
                <Box
                  component='span'
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  {getReferenteEndTime}
                </Box>
              ) : null}
              {tariffGroup?.name ? (
                <Stack
                  sx={{
                    gap: theme.spacing(1),
                    flexDirection: 'row',
                    alignItems: 'center',
                    minWidth: 0,
                    color: isTariffGroupArchived ? theme.palette.colors.warning[700] : 'inherit',
                  }}
                >
                  {principalIcon}
                  <Box
                    sx={{
                      alignItems: 'center',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    {tariffGroup?.name}
                  </Box>
                </Stack>
              ) : null}
            </Typography>
          </StyledCard.Title.Text>
        </StyledAvailabilityCard>
      </StyledCardLink>
    </AgendaHoverDetails>
  );
};

export default SlotTypeScheduling;
