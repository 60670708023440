import { useQuery } from '@tanstack/react-query';

import useGetAllProductTariffGroups from '@/usecases/useGetAllTariffGroups';

const useLoadProductTariffGroupsQuery = () => {
  const { request: getAllTariffGroups } = useGetAllProductTariffGroups();

  const useRequestQuery = (productId?: string, active = true) => {
    return useQuery({
      queryKey: ['product-tariff-group', productId!],
      queryFn: getAllTariffGroups,
      enabled: !!productId && active,
      staleTime: 1000,
    });
  };

  return [useRequestQuery];
};

export default useLoadProductTariffGroupsQuery;
