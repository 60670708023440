import { ElementType } from 'react';
import { Box, ListItem, Typography, TypographyProps, css, styled } from '@mui/material';

export const ProviderContent = styled(Box)(() => ({
  display: 'flex',
  gap: 8,
  flexDirection: 'row',
  alignItems: 'center',

  '& .MuiCustomImage-root': {
    padding: '0px 4px',
  },
}));

type CustomTypographyProps = TypographyProps & { component?: ElementType };

export const ProductName = styled(Typography)<CustomTypographyProps>(
  ({ theme }) => css`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    color: ${theme.palette.colors.gray[800]};
  `,
);

export const ProviderName = styled(ProductName)({});

export const TariffWrapper = styled(Box)(({ theme }) => ({
  fontWeight: 400,
  display: 'flex',
  fontSize: '11px',
  fontStyle: 'normal',
  lineHeight: 'normal',
  flexDirection: 'column',
  color: theme.palette.colors.gray[700],
}));

export const SubtotalWrapper = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  gap: '10px',
  flexDirection: 'column',
  paddingLeft: '0px',
  paddingRight: '0px',
  alignItems: 'flex-start',

  paddingBottom: theme.spacing(2),
  marginBottom: theme.spacing(1),
  borderBottom: '1px solid ',
  borderColor: theme.palette.colors.gray[200],
}));

export const InvalidProductWrapper = styled(Typography)(({ theme }) => ({
  mt: theme.spacing(0.5),
  color: theme.palette.colors.red[700],
  background: theme.palette.colors.red[50],
  padding: '4px',
  borderRadius: '2px',
  alignSelf: 'stretch',
}));
