import { StyledTimeContainer } from '@/features/reservation/components/RescheduleForm/RescheduleDateTimeSelector/styles';
import TimeCellSelect from '@/features/reservation/components/RescheduleForm/RescheduleDateTimeSelector/TimeCellSelect';
import { Grid2 } from '@planne-software/uni/mui/material';

type Props = {
  totalSlots?: number;
};
const TimeCellSelectSkeleton = (props: Props) => {
  const { totalSlots = 7 } = props;
  return (
    <StyledTimeContainer>
      <Grid2 container sx={{ gap: '0.2rem' }}>
        {Array.from({ length: totalSlots }).map((_, index) => (
          <Grid2 key={index}>
            <TimeCellSelect isLoading />
          </Grid2>
        ))}
      </Grid2>
    </StyledTimeContainer>
  );
};

export default TimeCellSelectSkeleton;
