import { useMemo } from 'react';
import { Box, useMediaQuery } from '@mui/material';

import theme from '@/theme';
import useUI from '@/hooks/useUI';
import MenuItem from '../MenuItem';
import { useSidebar } from '@/hooks/useSidebar';
import NestedMenuItem from '../NestedMenuItem';
import { NestedMenuItemProps } from '../menuStructure';
import { Popover } from './style';

interface Props {
  open: boolean;
  hasPinedPopover: boolean;
  item: NestedMenuItemProps;
  handlePopoverClose: () => void;
  onClick: (value: string) => void;
  changePinPopover: (value: boolean) => void;
  anchor: { element: null | HTMLElement; menuItem: NestedMenuItemProps | null };
  handlePopoverOpen: (event: React.MouseEvent<HTMLElement>, item: NestedMenuItemProps) => void;
}

export const ModuleItem = (props: Props) => {
  const { item, open, onClick, handlePopoverClose, handlePopoverOpen, hasPinedPopover, anchor, changePinPopover } =
    props;

  const { isDrawerExpanded } = useUI();
  const { canView, isActive } = useSidebar();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const onlyShowIcon = useMemo(() => !isMobile && !isDrawerExpanded, [isMobile, isDrawerExpanded]);

  const canRenderNewPopover = () => {
    const event = document.getElementById('fixed-popover');

    return event === null && onlyShowIcon && !hasPinedPopover;
  };

  return (
    <Box
      onMouseEnter={(event) => {
        if (canRenderNewPopover()) {
          handlePopoverOpen(event, item);
        }
      }}
      onMouseLeave={() => {
        if (!hasPinedPopover) {
          handlePopoverClose();
        }
      }}
    >
      <NestedMenuItem
        key={item.code}
        open={open && !onlyShowIcon}
        selected={open || (onlyShowIcon && !!item.children?.some((child) => isActive(child.to)))}
        onClick={(event) => {
          if (onlyShowIcon) {
            handlePopoverOpen(event, item);
            changePinPopover(true);
          } else {
            onClick(item.code);
          }
        }}
        text={item.text}
        icon={item.icon}
        onlyShowIcon={onlyShowIcon}
      >
        {item.children?.map((child) =>
          canView(child) ? (
            <MenuItem
              key={child.code}
              text={child.text}
              level={2}
              to={child.to}
              target={child.target}
              isActive={isActive(child.to)}
              icon={child.icon}
              onlyShowIcon={onlyShowIcon}
            />
          ) : null,
        )}
      </NestedMenuItem>

      {anchor.menuItem && anchor.menuItem.code === item.code ? (
        <Popover
          anchorEl={anchor.element}
          hasPinedPopover={hasPinedPopover}
          open={hasPinedPopover || Boolean(anchor.element)}
          id={hasPinedPopover ? 'fixed-popover' : 'mouse-over-popover'}
          anchorOrigin={{
            vertical: -56,
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <NestedMenuItem
            open
            selected={false}
            showExpandIcon={false}
            key={anchor.menuItem.code}
            text={anchor.menuItem.text}
            icon={anchor.menuItem.icon}
            sx={{
              backgroundColor: theme.palette.secondary.main,
              '&:hover': { backgroundColor: theme.palette.secondary.main },
            }}
          >
            {anchor.menuItem.children?.map((child) =>
              canView(child) ? (
                <MenuItem
                  key={child.code}
                  text={child.text}
                  level={2}
                  to={child.to}
                  target={child.target}
                  onClick={handlePopoverClose}
                  isActive={isActive(child.to)}
                  icon={child.icon}
                  onlyShowIcon={onlyShowIcon}
                  sx={{ height: 'unset' }}
                />
              ) : null,
            )}
          </NestedMenuItem>
        </Popover>
      ) : null}
    </Box>
  );
};

export default ModuleItem;
