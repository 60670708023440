import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { v4 as UUID } from 'uuid';

import useUI from '@/hooks/useUI';
import useAuth from '@/contexts/Auth/hooks/useAuth';
// import useAnalytics from '@/hooks/useAnalytics';

import { Iframe, IframeContainer } from './styles';
import { getAllQueryParams, serializeToQueryString } from '@/utils';
import RefundRequestForm from '@/components/RefundRequests/RefundRequestForm';
import ModalOnDesktopDrawerOnMobile from '@/components/Modal/ModalOnDesktopDrawerOnMobile';
import Loading from '@/components/BackdropLoading';
import CustomerDetailWithCloseButton from '@/components/CustomerDetail/CustomerWithCloseButton';
import accessTokenManager from '@/contexts/Auth/AccessTokenManager';

export const ExternalPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { accessToken, isAccessTokenExpired } = useAuth();
  const { toggleDrawer } = useUI();

  const [refundRequestEventData, setRefundRequestEventData] = useState<{ saleId: string } | undefined>();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const [customerId, setCustomerId] = useState<string>();

  const { t } = useTranslation(['refundRequests']);
  // const { track } = useAnalytics();

  const { VITE_LEGACY_DASHBOARD_URL, VITE_NODE_ENV } = import.meta.env;

  const postMessageOrigin = VITE_NODE_ENV === 'development' ? '*' : VITE_LEGACY_DASHBOARD_URL;

  const baseUrl = VITE_LEGACY_DASHBOARD_URL;

  const buildPath = (path: string, newToken?: string) => {
    const queryParams = {
      ...getAllQueryParams(),
      ...(newToken ? { token: newToken } : {}),
    };

    return `${baseUrl}${path}?${serializeToQueryString(queryParams)}`;
  };

  const pathname = location.pathname;

  const [currentPath, setCurrentPath] = useState('/');
  const [iframeSrc, setIframeSrc] = useState('');
  const [iframeRandomKey, setIframeRandomKey] = useState(UUID());

  const iframe = useRef<HTMLIFrameElement>(null);

  const removeTokenFromPath = (path: string) => {
    const url = new URL(`http://domain${path}`);

    url.searchParams.delete('token');
    url.searchParams.delete('nd');

    return `${url.pathname}${url.search}`;
  };

  const verifyAuthentication = () => {
    if (isAccessTokenExpired()) {
      accessTokenManager.clearToken();
      window.location.href = '/login?expiredConnection=&redirectTo=' + window.location.pathname;
    }
  };

  const refreshIframe = () => {
    if (iframe.current) {
      setIframeSrc(buildPath(pathname, accessToken));
      setIframeRandomKey(UUID());
    }
  };

  useEffect(() => {
    const listener = (event: MessageEvent) => {
      const { data } = event.data;
      if (event?.data?.event_id === 'navigate') {
        // track(`externalPage/${data.routeName}/access`);

        if (removeTokenFromPath(data.route) !== pathname) {
          setLoading(true);

          verifyAuthentication();

          setCurrentPath(data.route);

          navigate(removeTokenFromPath(data.route));
        }
      }

      if (event?.data?.event_id === 'request_sale_refund') {
        setOpenModal(true);
        setRefundRequestEventData(data);
      }

      if (event?.data?.event_id === 'loaded') {
        setLoading(false);
      }

      if (event?.data?.event_id === 'customer_details') {
        setCustomerId(data.customerId);
      }

      if (event?.data?.event_id === 'sidebar_toggle') {
        toggleDrawer();
      }
    };

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, [location, accessToken]);

  useEffect(() => {
    const pathname = location.pathname + location.search;

    if (iframe.current && removeTokenFromPath(currentPath) !== pathname) {
      setLoading(true);

      verifyAuthentication();

      setCurrentPath(location.pathname);

      setIframeSrc(buildPath(location.pathname, accessToken));
    }
  }, [location, accessToken]);

  useEffect(() => {
    if (iframe.current) {
      iframe.current.contentWindow!.postMessage(
        {
          event_id: 'update_access_token',
          data: {
            accessToken,
          },
        },
        postMessageOrigin,
      );
    }
  }, [accessToken]);

  return (
    <>
      <Helmet>
        <title>Planne Dashboard</title>
      </Helmet>

      <IframeContainer>
        {loading ? <Loading /> : null}

        <Iframe
          ref={iframe}
          allow='autoplay; camera; microphone'
          id='iframe'
          title='description'
          key={iframeRandomKey}
          src={iframeSrc}
        />
      </IframeContainer>

      <ModalOnDesktopDrawerOnMobile
        width={564}
        title={t('modal.title')}
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
      >
        <RefundRequestForm
          saleId={refundRequestEventData?.saleId}
          onCancel={() => {
            setOpenModal(false);
          }}
          onSuccess={() => {
            setOpenModal(false);
            refreshIframe();
          }}
        />
      </ModalOnDesktopDrawerOnMobile>

      <ModalOnDesktopDrawerOnMobile
        width={643}
        isOpen={!!customerId}
        onClose={() => setCustomerId(undefined)}
        title={t('details.title', { ns: 'customer' })}
      >
        <CustomerDetailWithCloseButton customerId={customerId} onClose={() => setCustomerId(undefined)} />
      </ModalOnDesktopDrawerOnMobile>
    </>
  );
};

export default ExternalPage;
