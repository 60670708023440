import { styled } from '@mui/material';

export interface StyledAvailabilityCardProps {
  full?: boolean;
}
const unsafeProps: PropertyKey[] = ['full'];

export const StyledAvailabilityCard = styled('span', {
  shouldForwardProp: (prop) => !unsafeProps.includes(prop),
})<StyledAvailabilityCardProps>(({ theme, full = false }) => ({
  ...theme.typography.regularRegular,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(1),
  borderRadius: theme.radius.sm,
  backgroundColor: theme.palette.colors.primary[50],
  color: theme.palette.colors.primary[700],

  '&:hover': {
    backgroundColor: theme.palette.colors.primary[100],
  },

  ...(full && {
    backgroundColor: theme.palette.colors.error[50],
    color: theme.palette.colors.error[600],
    border: `1px solid ${theme.palette.colors.error[400]}`,
    '&:hover': {
      backgroundColor: theme.palette.colors.error[100],
    },
  }),
}));
