import { Box } from '@mui/material';
import { ReactNode, useMemo } from 'react';

import Tooltip from '@/components/Tooltip';
import TooltipTitle from '@/features/agenda/components/SchedulingCalendar/AgendaHoverDetails/AgendaHoverDetailsContent/TooltipTitle';
import { Availability, AvailabilityTypeEnum, Scheduling } from '@/services/SellerApi';
import getRightSchedulingTariffGroupFromApi from '@/features/agenda/utils/getRightSchedulingTariffGroupFromApi';

type Props = {
  scheduling: Scheduling | undefined;
  availability: Availability | undefined;
  children: ReactNode;
};
const AgendaHoverDetails = (props: Props) => {
  const { children, scheduling, availability } = props;

  const disabled = useMemo(() => {
    if (availability) {
      return availability.type === AvailabilityTypeEnum.App;
    }
    if (scheduling) {
      return !getRightSchedulingTariffGroupFromApi(scheduling);
    }
    return false;
  }, [scheduling, availability]);

  return !disabled ? (
    <Tooltip
      arrow
      enterTouchDelay={400}
      leaveTouchDelay={600}
      title={<TooltipTitle availability={availability} scheduling={scheduling} />}
      PopperProps={{ style: { zIndex: 99999, minWidth: 190, textAlign: 'center' } }}
      {...props}
    >
      <Box>{children}</Box>
    </Tooltip>
  ) : (
    <Box>{children}</Box>
  );
};

export default AgendaHoverDetails;
