import theme from '@/theme';
import { ImageWrapper } from '@/components/Image/styles';
import { ValueWithLabelProps } from '@/components/ValueWithLabel';
import { Box, CardContent as MUICardContent, styled, CardMedia as MUICardMedia, Card as MUICard } from '@mui/material';

export const FlexRow = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
}));

export const CardContent = styled(MUICardContent)(() => ({
  padding: 0,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '0px !important',
}));

export const CardContentInfo = styled(Box)(({ theme }) => ({
  flex: 1,
  gap: '16px',
  width: '100%',
  display: 'flex',
  marginBottom: '4px',
  paddingBottom: '8px',
  flexDirection: 'column',
  borderBottom: '1px solid',
  borderBottomColor: theme.palette.colors.gray[200],
}));

export const ProducNameAndDateWrapper = styled(FlexRow)(({ theme }) => ({
  justifyContent: 'space-between',
  gap: '32px',

  [theme.breakpoints.down('sm')]: {
    alignItems: 'flex-start',
    flexWrap: 'unset',
  },
}));

export const CardMedia = styled(MUICardMedia)(({ theme }) => ({
  borderRadius: '4px',
  overflow: 'hidden',
  flexShrink: 0,
  width: '187px',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  position: 'relative',

  [theme.breakpoints.down('md')]: {
    height: '164px !important',
    width: '100%',
  },
}));

export const Card = styled(MUICard)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2),
  gap: theme.spacing(3),
  flexDirection: 'row',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const StyledImage = styled('img')<{ hasNoImage?: boolean }>(({ theme, hasNoImage }) => ({
  flexShrink: 0,
  height: '100%',

  ...(hasNoImage
    ? {
        width: '100%',
        maxWidth: '90%',
        display: 'flex',
        objectFit: 'contain',
        alignItems: 'center',
        justifyContent: 'center',
      }
    : {
        display: 'block',
        position: 'absolute',
        top: '50%',
        left: '50%',
        minHeight: '100%',
        minWidth: '100%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        objectFit: 'cover',

        [theme.breakpoints.down('sm')]: {
          maxWidth: '100%',
        },
      }),
}));

export const StyledImageWrapper = styled(ImageWrapper)(() => ({
  width: '100%',
  height: 'fit-content',
}));

export const slotProps: ValueWithLabelProps['slotProps'] = {
  container: {
    gap: 0.5,
    flexDirection: 'row',
  },
  title: {
    variant: 'regularSemiBold',
    color: theme.palette.colors.gray[800],
  },
  value: {
    variant: 'regularRegular',
    color: theme.palette.colors.gray[800],
  },
};
