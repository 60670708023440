import { Stack, styled, Typography } from '@mui/material';

export const TimesAndQuantityWrapper = styled(Stack)(({ theme }) => ({
  width: '100%',
  gap: theme.spacing(4),
  alignItems: 'flex-start',
}));

export const Container = styled(Stack)(({ theme }) => ({
  padding: '1rem',
  borderRadius: '0.5rem',
  background: theme.palette.colors.gray[50],
  position: 'relative',
  gap: theme.spacing(4),
  width: '100%',
  marginLeft: '1.5rem',
}));

export const ReplicateQuantity = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  textAlign: 'right',
  alignSelf: 'flex-end',
  textDecoration: 'underline',
  color: theme.palette.colors.blue[500],
}));
