import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import RefundRequestForm from '@/components/RefundRequests/RefundRequestForm';
import ModalOnDesktopDrawerOnMobile from '@/components/Modal/ModalOnDesktopDrawerOnMobile';

interface OutletContextProps {
  onSuccess: () => void;
}

export const SaleRefundRequestModal = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['sales']);

  const { saleId } = useParams<{ saleId: string }>();
  const [openModal, setOpenModal] = useState(!!saleId);
  const { onSuccess } = useOutletContext<OutletContextProps>();

  const close = () => {
    setOpenModal(false);

    navigate('..', { replace: true });
  };
  const success = () => {
    close();
    onSuccess();
  };

  return (
    <ModalOnDesktopDrawerOnMobile isOpen={openModal} onClose={close} title={t('modal.title')} width={564}>
      <RefundRequestForm onSuccess={success} saleId={saleId ?? ''} onCancel={close} />
    </ModalOnDesktopDrawerOnMobile>
  );
};

export default SaleRefundRequestModal;
