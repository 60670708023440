import DateAndActions from '@/features/agenda/components/DateAndActions';
import SchedulingCalendar from '@/features/agenda/components/SchedulingCalendar';
import { AgendaConfigState } from '@/features/agenda/types';
import useAccount from '@/hooks/useAccount';
import PageLayout from '@/layouts/PageLayout';
import { IPageLayoutActions } from '@/layouts/PageLayout/PageLayoutTypes';
import { PolicyRuleNamespaceEnum } from '@/services/SellerApi';
import { useConfig } from '@/features/config/useConfig';
import { Stack } from '@mui/material';
import { IconBarrierBlock, IconCalendarMonth, IconPlus } from '@tabler/icons-react';
import { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

// const AgendaPageFilters = lazy(() => import('@/features/agenda/components/AgendaPageFilters'));
import AgendaPageFilters from '@/features/agenda/components/AgendaPageFilters';

const LazyFilterFields = () => {
  return (
    <Suspense fallback={null}>
      <AgendaPageFilters />
    </Suspense>
  );
};

const AgendaPageWithConfig = () => {
  const { hasPermission } = useAccount();
  const { config } = useConfig<AgendaConfigState>();

  const filters = useMemo(() => {
    return {
      viewDataType: config.viewDataType,
      products: config.products,
      availabilityType: config.availabilityType,
    };
  }, [config]);

  const { t } = useTranslation(['agenda']);

  const canCreateNewItem = useMemo(() => {
    return (
      hasPermission(PolicyRuleNamespaceEnum.Schedulings, 'CreateProductScheduling') ||
      hasPermission(PolicyRuleNamespaceEnum.Availabilities, 'CreateAppAvailability')
    );
  }, [hasPermission]);

  const actions: IPageLayoutActions = {
    primary: {
      label: t('header.actions.new_scheduling_or_limit'),
      Icon: <IconPlus />,
      children: [
        {
          href: './schedulings/new',
          label: t('header.actions.new_scheduling'),
          Icon: <IconCalendarMonth />,
        },
        {
          href: './availabilities/new',
          label: t('header.actions.new_limit'),
          Icon: <IconBarrierBlock />,
        },
      ],
    },
  };

  return (
    <PageLayout
      title={t('page_title')}
      showFilterButton
      showSearchInput={false}
      showFilterActions={false}
      actions={canCreateNewItem ? actions : undefined}
      slots={{
        FilterFields: LazyFilterFields,
      }}
      slotProps={{
        filterFields: {
          filters,
        },
      }}
    >
      <Stack sx={{ width: '100%' }}>
        <DateAndActions />
        <SchedulingCalendar />

        <Outlet />
      </Stack>
    </PageLayout>
  );
};

export default AgendaPageWithConfig;
