import { Skeleton } from '@mui/material';
import { styled } from '@mui/system';

import { StyledCalendarItemBase } from '@/features/agenda/components/DateAndActions/MonthSelector/SelectMonthOrWeekModal/styles';
import { colors } from '@/theme';

type StyledCalendarWeekItemProps = {
  active?: boolean;
};

const unsafeProps: PropertyKey[] = ['active'];

export const StyledCalendarWeekItem = styled(StyledCalendarItemBase, {
  shouldForwardProp: (prop) => !unsafeProps.includes(prop),
})<StyledCalendarWeekItemProps>(({ active = false }) => ({
  flexDirection: 'row',
  justifyContent: 'flex-start',
  backgroundColor: colors.gray['50'],
  gap: '0.5rem',

  ...(active && {
    backgroundColor: colors.primary['50'],
    color: colors.primary['500'],
  }),
}));

export const StyledCalendarWeekSkeleton = styled(Skeleton)(() => ({
  borderRadius: '4px',
}));
