import { LabelAndPrice } from '@/features/agenda/types';
import { Additional, Availability } from '@/services/SellerApi';

const getAdditionalTitleAndPrice = (additional: Additional): LabelAndPrice | undefined => {
  if (additional.title) {
    return {
      id: `additional-${additional.id}`,
      label: additional.title,
    };
  }
  return undefined;
};

const getAvailabilityAdditionalsLabelPrice = (availability: Availability): LabelAndPrice[] | undefined => {
  const additionals = availability.additionals?.map(getAdditionalTitleAndPrice).filter(Boolean);

  if (additionals && additionals.length > 0) {
    return additionals as LabelAndPrice[];
  }

  return undefined;
};

export default getAvailabilityAdditionalsLabelPrice;
