import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import PageLayout from '@/layouts/PageLayout';
import FilterProvider from '@/contexts/FilterContext';
import CommissionList from '@/components/Partnership/Commissions/CommissionList';
import FilterFields from '@/components/Partnership/Commissions/Filters';
import useCommissionFilterSchema from '@/components/Partnership/Commissions/Filters/useCommissionFilterSchema';

export const CanceledCommissions = () => {
  const { t } = useTranslation(['commissions']);

  const breadcrumbs = [
    {
      label: 'Comissões',
      to: '../partnership/commissions',
    },
    {
      label: 'Vendas canceladas',
      to: '.',
    },
  ];

  const schema = useCommissionFilterSchema(t);

  return (
    <FilterProvider schema={schema} contextName='commissionsCanceled'>
      <Helmet>
        <title>{t('commissions_page_title')}</title>
      </Helmet>

      <PageLayout
        breadcrumbs={breadcrumbs}
        title={t('canceled_sales.title')}
        showGoBackButton
        slots={{ FilterFields }}
        showSearchInput={false}
      >
        <CommissionList currentState={['payment_voided', 'payment_chargeback', 'canceled']} />
      </PageLayout>
    </FilterProvider>
  );
};

export default CanceledCommissions;
