import { Outlet } from 'react-router-dom';
import { Stack } from '@mui/material';
import { SaleItems } from './SaleItems';
import { useSaleDetails } from '@/features/sales/hook/useSaleDetails';

export const Items = () => {
  const { refetchSale, sale, saleItemsData } = useSaleDetails();

  return (
    <Stack px={{ xs: 2, md: 0 }}>
      <SaleItems sale={sale} saleItemsData={saleItemsData} />

      <Outlet context={{ ...sale, saleId: sale.id, onSuccess: refetchSale }} />
    </Stack>
  );
};

export default Items;
