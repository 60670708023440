import { Navigate, useLocation } from 'react-router-dom';

import Loading from '@/components/BackdropLoading';
import useAccount from '@/hooks/useAccount';
import { AppPolicyRules } from '@/interfaces/rules';
import Unauthorized from '@/pages/Unauthorized';
import { PolicyRule as ApiPolicyRule } from '@/services/SellerApi';
import validatePolicies from '@/utils/validatePolicies';
import { AuthStatus } from '@/interfaces/auth';
import { useMemo } from 'react';
import useAuth from '@/contexts/Auth/hooks/useAuth';
import useFeatureFlag, { DefaultFeatureFlags } from '@/hooks/useFeatureFlag';

interface ProtectedRouterFunction {
  (props: {
    element: JSX.Element;
    policies?: AppPolicyRules;
    needAccount?: boolean;
    featureFlag?: DefaultFeatureFlags;
  }): JSX.Element;
  /**
   * @deprecated
   * Use AppPolicyRules instead. Set a object with AND or OR key with an array of ApiPolicyRule
   */
  (props: {
    element: JSX.Element;
    policies?: ApiPolicyRule[];
    needAccount?: boolean;
    featureFlag?: DefaultFeatureFlags;
  }): JSX.Element;
}

const ProtectedRoute: ProtectedRouterFunction = (props) => {
  const { element, policies = [], needAccount = true, featureFlag } = props;

  const location = useLocation();

  const { accessToken, status: authStatus } = useAuth();
  const { initialized: accountsInitialized, hasPermission, accounts } = useAccount();
  const { isFeatureEnabled, isReady } = useFeatureFlag();

  const hasFeatureFlagEnabled = useMemo(
    () => !featureFlag || isFeatureEnabled(featureFlag),
    [isFeatureEnabled, featureFlag, isReady],
  );

  const unnecessaryAccount = useMemo(() => !needAccount, [needAccount]);

  const canAccessPolicy = useMemo(() => validatePolicies(policies, hasPermission), [policies, hasPermission]);

  const isUserPending = useMemo(() => authStatus === AuthStatus.USER_PENDING, [authStatus]);

  const isAuthenticatedAndNotInitialized = useMemo(
    () => authStatus === AuthStatus.AUTHENTICATED && !accountsInitialized,
    [authStatus, accountsInitialized],
  );

  if (isUserPending || isAuthenticatedAndNotInitialized) {
    return <Loading />;
  }

  if (authStatus === AuthStatus.UNAUTHENTICATED) {
    if (!accessToken) {
      return <Navigate to='/login' replace state={{ redirectTo: location }} />;
    }
    return <Loading />;
  }

  if (authStatus === AuthStatus.AUTHENTICATED && accountsInitialized) {
    if (accounts.length === 0 && location.pathname !== '/app/create') {
      return <Navigate to='/app/create' replace />;
    }

    if (unnecessaryAccount) {
      return element;
    }

    if (!canAccessPolicy) {
      return <Unauthorized />;
    }

    if (!isReady) {
      return <Loading />;
    }

    if (isReady && !hasFeatureFlagEnabled) {
      return <Unauthorized />;
    }

    return element;
  }

  return <Unauthorized />;
};

export default ProtectedRoute;
