import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import useAnalytics from '@/hooks/analytics/useAnalytics';

import { useTranslation } from 'react-i18next';
import FilterProvider from '@/contexts/FilterContext';
import PartnershipLayout from '@/layouts/PartnershipLayout';
import { Outlet, useNavigate } from 'react-router-dom';
import { AssociationModel, PartnershipStatus } from '@/services/SellerApi';
import usePartnershipFilterSchema from '@/components/Partnership/PartnershipList/usePartnershipFilterSchema';

export const PartnershipProviders = () => {
  const { track } = useAnalytics();
  const { t } = useTranslation(['partnership']);

  const navigate = useNavigate();

  useEffect(() => {
    navigate(AssociationModel.Affiliation);
    track('partnershipProviders/access');
  }, []);

  const filterSchema = usePartnershipFilterSchema(t, 'provider');

  const defaultValues = {
    currentStatus: [PartnershipStatus.Pending, PartnershipStatus.Ongoing, PartnershipStatus.Declined],
  };

  return (
    <FilterProvider schema={filterSchema} defaultValue={defaultValues} contextName='providers'>
      <Helmet>
        <title>{t('providers_page_title')}</title>
      </Helmet>

      <PartnershipLayout viewType='agent'>
        <Outlet />
      </PartnershipLayout>
    </FilterProvider>
  );
};

export default PartnershipProviders;
