import { QueryFunctionContext } from '@tanstack/react-query';

import { AnalyticsEvents } from '@/hooks/analytics/enum/analyticsEvents';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import { GetReservationRescheduleSchedulingsParams, RescheduleScheduling } from '@/services/sellerApiQuery/model';
import { getReservationRescheduleSchedulings } from '@/services/sellerApiQuery/api/schedulings/schedulings';
import buildRequestWithAllPages from '@/utils/buildRequestWithAllPages';
import { AxiosError } from 'axios';

type Query = [key: string, reservationId: string, rescheduleFrom: string, rescheduleUntil: string];
export interface GetAllReservationRescheduleSchedulingsResponse {
  data: RescheduleScheduling[];
  totalReservations: number;
}

const useGetAllReservationRescheduleSchedulings = () => {
  const { track } = useAnalytics();

  const request = async ({
    queryKey,
    signal,
  }: QueryFunctionContext<Query>): Promise<GetAllReservationRescheduleSchedulingsResponse> => {
    const [_, reservationId, rescheduleFrom, rescheduleUntil] = queryKey;

    try {
      const allSchedulingOptions = await buildRequestWithAllPages<
        RescheduleScheduling,
        GetReservationRescheduleSchedulingsParams
      >({
        apiMethod: (payload, options) => getReservationRescheduleSchedulings(reservationId, payload, options),
        initialPayload: {
          rescheduleFrom,
          rescheduleUntil,
        },
        signal,
      });

      if (allSchedulingOptions && 'length' in (allSchedulingOptions as RescheduleScheduling[])) {
        const data = allSchedulingOptions as RescheduleScheduling[];
        return { data, totalReservations: data.length };
      }

      return { data: [], totalReservations: 0 };
    } catch (error) {
      if (error instanceof AxiosError && error.code !== 'ERR_CANCELED') {
        console.error(error);
      }
      track(AnalyticsEvents.GET_RESERVATION_RESCHEDULE_SCHEDULINGS_FAIL, { error });
      return { data: [], totalReservations: 0 };
    }
  };

  return { request };
};

export default useGetAllReservationRescheduleSchedulings;
