import { Container, Grid } from '@mui/material';
import { Form } from '@/features/auth/components/Login/Form';
import SideArt from '@/features/auth/components/Login/SideArt';
import PlanneVectorLogo from '@/assets/logos/planneVectorLogo.svg';
import PlanneLogoMobile from '@/assets/logos/planneLogoMobile.svg';

export const Login = () => {
  return (
    <Grid container component='main' sx={{ height: '100vh' }}>
      <Grid md={6} item display={{ xs: 'none', md: 'flex' }}>
        <SideArt />
      </Grid>

      <Grid item xs={12} md={6} sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
        <Container>
          <Grid container rowSpacing={4} justifyContent='center'>
            <Grid xs={12} item display={{ xs: 'none', md: 'block' }} sx={{ textAlign: 'center' }}>
              <img src={PlanneVectorLogo} alt='login logo' />
            </Grid>

            <Grid item display={{ xs: 'block', md: 'none' }} xs={12} sx={{ textAlign: 'center' }}>
              <img src={PlanneLogoMobile} alt='login logo' />
            </Grid>

            <Grid item xs={12} sm={8} md={9} lg={8} xl={6}>
              <Form />
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Login;
