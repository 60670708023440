import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import PageLayout from '@/layouts/PageLayout';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import FilterProvider from '@/contexts/FilterContext';
import { CouponList } from '@/features/coupons/components/CouponList';
import { Tooltip, Typography } from '@mui/material';
import { HelpCircle } from '@untitled-ui/icons-react';
import useQueryString from '@/hooks/useQueryString';

// eslint-disable-next-line react-refresh/only-export-components
export const CouponTabType = {
  MY_COUPONS: 'my_coupons',
  AFFILIATION_COUPONS: 'affiliation_coupons',
};
import CouponFilterFields from '@/features/coupons/components/CouponFilterFields';
import { useCouponFilterSchema } from './useCouponFilterSchema';
import { IconArchive, IconPlus } from '@tabler/icons-react';

export const Coupons = () => {
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const { t } = useTranslation(['coupons']);

  const { tab } = useQueryString('tab');

  const [currentTab, setCurrentTab] = useState(tab ?? CouponTabType.MY_COUPONS);

  const belongsToPartnership = useMemo(() => {
    const url = new URL(window.location.href);

    const filteredSearchParams = url.search
      .split('&')
      .filter((param) => !param.includes('tab'))
      .join('&');

    navigate(`?tab=${currentTab}${filteredSearchParams ? '&' + filteredSearchParams : ''}`);

    return currentTab === CouponTabType.AFFILIATION_COUPONS;
  }, [currentTab]);

  const tabs = [
    {
      label: t('coupon_list.page_layout.my_coupons'),
      value: CouponTabType.MY_COUPONS,
    },

    {
      label: (
        <Tooltip
          arrow
          placement='top'
          enterTouchDelay={0}
          leaveTouchDelay={10000}
          title={t('coupon_list.page_layout.affiliation_coupons_tooltip')}
          PopperProps={{ style: { maxWidth: 168, textAlign: 'center' } }}
        >
          <Typography component='p' sx={{ display: 'flex', alignItems: 'center', gap: 1, whiteSpace: 'nowrap' }}>
            {t('coupon_list.page_layout.affiliation_coupons')}

            <HelpCircle />
          </Typography>
        </Tooltip>
      ),
      value: CouponTabType.AFFILIATION_COUPONS,
    },
  ];

  const filterSchema = useCouponFilterSchema(t);

  useEffect(() => {
    track('couponsPage/access');
  }, []);

  return (
    <FilterProvider schema={filterSchema} contextName='coupons'>
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>

      <PageLayout
        title={t('coupon_list.page_layout.title')}
        actions={{
          ...(belongsToPartnership
            ? {}
            : {
                secondary: [
                  {
                    href: 'canceled',
                    Icon: <IconArchive />,
                    title: t('coupon_list.page_layout.canceled_coupons'),
                  },
                ],
                primary: {
                  href: 'create',
                  Icon: <IconPlus />,
                  label: t('coupon_list.page_layout.new_coupon'),
                  title: t('coupon_list.page_layout.new_coupon'),
                },
              }),
        }}
        slotProps={{
          searchInput: {
            placeholder: t('filter.search_input_placeholder'),
          },
          tab: {
            root: {
              value: currentTab,
              onChange: (_, newValue) => setCurrentTab(newValue),
              variant: 'fullWidth',
            },
            tabs,
          },
        }}
        slots={{ FilterFields: CouponFilterFields }}
      >
        <CouponList belongsToPartnership={belongsToPartnership} />
      </PageLayout>
    </FilterProvider>
  );
};

export default Coupons;
