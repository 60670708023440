import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Checkbox from '@/components/Form/Checkbox';
import { AgendaConfigState, ViewDataType } from '@/features/agenda/types';
import { useConfig } from '@/features/config/useConfig';
import updateViewDataTypes from '@/features/agenda/utils/updateViewDataTypes';

const ToggleViewer = () => {
  const { config, patchConfig } = useConfig<AgendaConfigState>();
  const { t } = useTranslation(['agenda']);

  const viewTypes = (config?.viewDataType as ViewDataType[]) ?? [];

  const toggleViewType = (data: ViewDataType[]) => {
    const value = data.length === 0 ? viewTypes : data;

    patchConfig({
      viewDataType: value,
    });
  };

  const checkClick = (checked: boolean, type: ViewDataType) => {
    const newViewType = updateViewDataTypes(checked, type, viewTypes);

    toggleViewType(newViewType);
  };

  const renderCheckbox = (type: ViewDataType) => (
    <Checkbox
      label={t(`view_data_type.${type}`)}
      disableRipple
      checked={viewTypes?.includes(type)}
      onChange={(e) => checkClick(e.target.checked, type)}
    />
  );

  return (
    <Stack sx={{ flexDirection: 'row' }}>
      {renderCheckbox(ViewDataType.SCHEDULING)}

      {renderCheckbox(ViewDataType.AVAILABILITIES)}
    </Stack>
  );
};

export default ToggleViewer;
