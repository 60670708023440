import {
  StyledDay,
  StyledDayCell,
  StyledDayFooter,
  StyledWeekday,
} from '@/features/reservation/components/RescheduleForm/RescheduleDateTimeSelector/CalendarSelector/HorizontalCalendar/DayCell/styles';
import { DayCellProps } from '@/features/reservation/components/RescheduleForm/RescheduleDateTimeSelector/CalendarSelector/HorizontalCalendar/DayCell/types';
import { format, isSameDay } from 'date-fns';
import clsx from 'clsx';
import { useMemo, forwardRef } from 'react';
import { DotIcon } from '@planne-software/uni/DatePicker';
import DayCellSkeleton from '@/features/reservation/components/RescheduleForm/RescheduleDateTimeSelector/CalendarSelector/HorizontalCalendar/DayCell/DayCellSkeleton';
import { useTranslation } from 'react-i18next';

const DayCell = forwardRef<HTMLButtonElement, DayCellProps>((props, ref) => {
  const {
    day,
    selected = false,
    disabled,
    isLoading,
    isFirstVisibleCell,
    isLastVisibleCell,
    onDaySelect,
    outsideCurrentMonth,
    color = 'neutral',
  } = props;

  const { i18n } = useTranslation();

  const today = new Date();

  const isToday = useMemo(() => isSameDay(day, today), [day, today]);

  return isLoading ? (
    <DayCellSkeleton />
  ) : (
    <StyledDayCell
      ref={ref}
      color={color}
      disabled={disabled}
      onClick={() => onDaySelect(day)}
      className={clsx({
        today: isToday,
        selected,
        first: isFirstVisibleCell,
        last: isLastVisibleCell,
        outside: outsideCurrentMonth,
      })}
    >
      <StyledWeekday>{day.toLocaleDateString(i18n.language, { weekday: 'short' }).replace('.', '')}</StyledWeekday>
      <StyledDay>{day.getDate()}</StyledDay>
      <StyledDayFooter>
        {format(day, 'MMM/yy')}
        {isToday ? <DotIcon selected={selected} /> : null}
      </StyledDayFooter>
    </StyledDayCell>
  );
});

export default DayCell;
