import Bugsnag from '@/services/bugsnag';
import React, { ComponentType, ErrorInfo, ReactNode, useMemo } from 'react';
import { FallbackProps } from 'react-error-boundary';

import * as DevelopmentErrorBoundary from 'react-error-boundary';
import ErrorTechnical from '../ErrorTechnical';

interface BugsnagFallbackProps {
  error: Error;
  info: ErrorInfo;
  clearError: () => void;
}

interface ErrorBoundaryProps {
  children: ReactNode;
  FallbackComponent?: ComponentType<BugsnagFallbackProps | FallbackProps>;
}

const ErrorBoundary = (props: ErrorBoundaryProps): JSX.Element | null => {
  const { children } = props;
  let { FallbackComponent } = props;

  const BugsnagErrorBoundary = useMemo(
    () => (import.meta.env.VITE_BUGSNAG_API_KEY ? Bugsnag.getPlugin('react')?.createErrorBoundary(React) : null),
    [],
  );

  if (!FallbackComponent) {
    FallbackComponent = ({ ...rest }) => <ErrorTechnical hasHeader={false} {...rest} />;
  }

  if (BugsnagErrorBoundary) {
    return (
      <BugsnagErrorBoundary FallbackComponent={FallbackComponent as ComponentType<BugsnagFallbackProps>}>
        {children}
      </BugsnagErrorBoundary>
    );
  }

  return (
    <DevelopmentErrorBoundary.ErrorBoundary FallbackComponent={FallbackComponent as ComponentType<FallbackProps>}>
      {children}
    </DevelopmentErrorBoundary.ErrorBoundary>
  );
};

export default ErrorBoundary;
