import { colors } from '@/theme';
import { Typography, styled } from '@mui/material';

export const SectionTitle = styled(Typography)(() => ({
  borderBottom: `1px solid ${colors.gray[300]}`,
  alignSelf: 'stretch',
  display: 'flex',
  padding: '8px 0px',
  alignItems: 'flex-start',
}));
