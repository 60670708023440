import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FC, useMemo } from 'react';

import theme from '@/theme';
import { IndeterminateCheckboxProps, IndeterminateCheckboxOption } from '@/components/IndeterminateCheckboxGroup/types';

const IndeterminateCheckboxGroup: FC<IndeterminateCheckboxProps> = (props) => {
  const { label, options, onChange, values } = props;
  const { allChecked } = 'allChecked' in props ? props : { allChecked: undefined };

  const handleCheckAll = () => {
    if ('allCheckedChange' in props) {
      const { allCheckedChange } = props;
      allCheckedChange(!allChecked);
      return;
    }
    const isChecked = values.length === options.length;

    if (isChecked) {
      onChange([]);
      return;
    }

    onChange(options);
  };

  const handleCheck = (option: IndeterminateCheckboxOption, checked: boolean) => {
    if (checked) {
      onChange([...values, option]);
      return;
    }

    onChange(values.filter((v) => v.value !== option.value));
  };

  const isChecked = (option: IndeterminateCheckboxOption) => {
    return values.some((v) => v.value === option.value);
  };

  const isAllChecked = useMemo(
    () => (allChecked !== undefined ? allChecked : values.length === options.length),
    [allChecked, values, options],
  );
  const isIndeterminate = useMemo(() => values.length > 0 && !isAllChecked, [values, isAllChecked]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <FormControlLabel
        label={label}
        control={<Checkbox checked={isAllChecked} indeterminate={isIndeterminate} onChange={handleCheckAll} />}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', ml: theme.spacing(4) }}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            label={option.label}
            control={
              <Checkbox
                sx={{ padding: theme.spacing(1) }}
                checked={isChecked(option)}
                onChange={(_, checked) => handleCheck(option, checked)}
              />
            }
          />
        ))}
      </Box>
    </Box>
  );
};

export default IndeterminateCheckboxGroup;
