import { useLocation } from 'react-router-dom';

import { MenuItemPolicyProps, MenuItemProps } from '@/components/SideBar/menuStructure';
import { AppPolicyRules } from '@/interfaces/rules';
import validatePolicies from '@/utils/validatePolicies';

import useAccount from './useAccount';
import useFeatureFlag from './useFeatureFlag';

/**
 * @deprecated
 * Use validatePolicies from '@/utils/validatePolicies' instead
 */
const legacyValidation = (
  policies: MenuItemPolicyProps[] | AppPolicyRules | undefined,
  hasPermission: (namespace: string, action: string) => boolean,
) => {
  if (!policies) {
    return true;
  }
  if (Array.isArray(policies)) {
    return policies.every((policy) => hasPermission(policy.namespace, policy.action));
  }

  return validatePolicies(policies, hasPermission);
};

export const useSidebar = () => {
  const location = useLocation();
  const { isFeatureEnabled, isReady } = useFeatureFlag();
  const { hasPermission, selectedAccount } = useAccount();

  const isActive = (to: string | undefined) => {
    if (to) {
      const path = location.pathname.replace(/^.+?[/]/, '');

      const targetPathLevel = to.split('/').length;
      const pathStringByLevel = path.split('/').slice(0, targetPathLevel).join('/');

      return pathStringByLevel === to;
    }
    return false;
  };

  const canView = (menuItem: MenuItemProps) => {
    const policyOK = legacyValidation(menuItem.policies, hasPermission);
    let featureFlagEnabled = !menuItem.featureFlag;

    if (menuItem.featureFlag && isReady) {
      featureFlagEnabled = isFeatureEnabled(menuItem.featureFlag);
    }

    if (selectedAccount?.app?.plan?.sellerType === 'agent') {
      return !!menuItem.showToAgent;
    }

    return policyOK && featureFlagEnabled;
  };

  return {
    isActive,
    canView,
  };
};
