import { Stack, Typography } from '@mui/material';
import { IconEdit, IconEye } from '@tabler/icons-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Chip from '@/components/Chip';
import { StyledAction } from '@/features/agenda/components/SchedulingCalendar/AgendaDayPopover/AgendaDayPopoverContent/AgendaDayPopoverList/AgendaDayPopoverSlot/styles';
import { StyledSlotAvailabilities } from '@/features/agenda/components/SchedulingCalendar/AgendaSlot/styles';
import { getAvailabilityQuantitiesText } from '@/features/agenda/utils/getAvailabilityQuantitiesText';
import useAccount from '@/hooks/useAccount';
import { Availability, PolicyRuleNamespaceEnum } from '@/services/SellerApi';

interface Props {
  availability: Availability;
}

const PopoverAvailabilitySlot = (props: Props) => {
  const { availability } = props;
  const { t } = useTranslation(['agenda']);
  const { hasPermission } = useAccount();
  const { search } = useLocation();
  const quantity = useMemo(() => getAvailabilityQuantitiesText(availability), [availability]);

  return (
    <StyledSlotAvailabilities>
      <Chip label={t(`filter.availability_type.${availability.type}`)} size='small' />

      {availability.quantity && availability.occupation?.occupiedAmount ? (
        <Typography variant='smallBold'>{quantity}</Typography>
      ) : null}

      {hasPermission(PolicyRuleNamespaceEnum.Availabilities, 'GetAvailability') ? (
        <Stack
          sx={{
            minHeight: 40,
            minWidth: 56,
            height: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '0.5rem',
          }}
        >
          <StyledAction href={`./availabilities/${availability.id}${search}`} className='actions'>
            <IconEye />
          </StyledAction>
          <StyledAction href={`./availabilities/${availability.id}/edit`} className='actions'>
            <IconEdit />
          </StyledAction>
        </Stack>
      ) : null}
    </StyledSlotAvailabilities>
  );
};

export default PopoverAvailabilitySlot;
