import { useLocation } from 'react-router-dom';

/**
 * @deprecated use `useRouteQueryParams` or `useSearchParams` from react-router-dom instead
 * @param key string or array of strings
 * @returns
 */
export function useQueryString<ParamsOrKey extends { [key: string]: string | null }>(
  key: string | string[],
): ParamsOrKey {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  if (Array.isArray(key)) {
    return key.reduce((acc, cur) => {
      acc[cur] = query.get(cur);
      return acc;
    }, {} as { [key: string]: string | null }) as ParamsOrKey;
  }

  return {
    [key]: query.get(key),
  } as ParamsOrKey;
}

export default useQueryString;
