/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Planne Seller API
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery
} from '@tanstack/react-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  User
} from '../../model'
import { customInstance } from '../../mutator/custom-instance';
import type { ErrorType } from '../../mutator/custom-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const getOwnUser = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<User>(
      {url: `/users/me`, method: 'GET', signal
    },
      options);
    }
  

export const getGetOwnUserQueryKey = () => {
    return [`/users/me`] as const;
    }

    
export const getGetOwnUserQueryOptions = <TData = Awaited<ReturnType<typeof getOwnUser>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOwnUser>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOwnUserQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOwnUser>>> = ({ signal }) => getOwnUser(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOwnUser>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOwnUserQueryResult = NonNullable<Awaited<ReturnType<typeof getOwnUser>>>
export type GetOwnUserQueryError = ErrorType<unknown>



export function useGetOwnUser<TData = Awaited<ReturnType<typeof getOwnUser>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOwnUser>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetOwnUserQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



