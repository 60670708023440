import theme from '@/theme';

import { Link, Typography, BreadcrumbsProps, Breadcrumbs as BaseBreadcrumbs } from '@mui/material';

type BreadcrumbItemProps = {
  label: string;
  to?: string;
};

type Props = Omit<BreadcrumbsProps, 'children'> & {
  breadcrumbsItem: Array<BreadcrumbItemProps>;
};

const Breadcrumbs = (props: Props) => {
  const { breadcrumbsItem, ...rest } = props;

  return (
    <BaseBreadcrumbs aria-label='breadcrumb' sx={{ lineHeight: 'normal', fontSize: '0.6875rem' }} {...rest}>
      {breadcrumbsItem.map(({ label, to }, index) => {
        const last = index === breadcrumbsItem.length - 1;

        return last ? (
          <Typography
            variant='extraSmallSemiBold'
            key={`breadcrumb-${index}-${to}`}
            color={theme.palette.colors.blue[900]}
          >
            {label}
          </Typography>
        ) : (
          <Link
            href={to}
            variant='extraSmallRegular'
            key={`breadcrumb-${index}-${to}`}
            sx={{
              textDecoration: 'none',
              color: theme.palette.colors.gray[400],
              '&:hover': { color: theme.palette.primary[500], textDecoration: 'underline' },
            }}
          >
            {label}
          </Link>
        );
      })}
    </BaseBreadcrumbs>
  );
};

export type { Props as BreadcrumbsProps, BreadcrumbItemProps };
export default Breadcrumbs;
