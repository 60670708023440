import theme from '@/theme';
import { Skeleton, Theme } from '@mui/material';
import { Stack, useMediaQuery } from '@mui/system';
import { Fragment } from 'react';

const SlotSkeleton = () => {
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const variant = !isTablet ? 'rectangular' : 'rounded';
  const height = isTablet ? '0.5rem' : '3.4375rem';
  const width = isTablet ? '0.5rem' : '100%';
  const direction = isTablet ? 'row' : 'column';
  return (
    <Stack sx={{ gap: theme.spacing(0.5), flexDirection: direction, alignItems: 'flex-end', flexWrap: 'wrap' }}>
      <Skeleton variant={variant} height={height} width={width} />
      <Skeleton variant={variant} height={height} width={width} />
      <Skeleton variant={variant} height={height} width={width} />
      {!isTablet ? (
        <Skeleton variant='text' width='33%' />
      ) : (
        <Fragment>
          <Skeleton variant={variant} height={height} width={width} />
          <Skeleton variant={variant} height={height} width={width} />
        </Fragment>
      )}
    </Stack>
  );
};

export default SlotSkeleton;
