import { Stack, useMediaQuery } from '@mui/system';
import { isSameMonth, isSameWeek } from 'date-fns';
import { Suspense, useMemo } from 'react';

import TimeNavigationActions from '@/features/agenda/components/DateAndActions/Actions';
import { useAgenda } from '@/features/agenda/hooks/useAgenda';
import theme from '@/theme';
import { ViewType } from '@/features/agenda/types';

import MonthSelector from './MonthSelector';
import RangeSelector from './RangeSelector';
import { StyleDateAndActionsContainer } from './styles';

// const LazyToggleViewer = lazy(() => import('./ToggleViewer'));
// const LazyProductSelector = lazy(() => import('./HotProductSelector'));

import LazyToggleViewer from './ToggleViewer';
import LazyProductSelector from './HotProductSelector';

const DateAndActions = () => {
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const { activeDate: date, calendarView: view, next, prev, updateDate } = useAgenda();

  const setDateToToday = () => {
    updateDate(new Date());
  };

  const isTodayDisabled = useMemo(() => {
    const today = new Date();
    if (view === ViewType.MONTH) {
      return isSameMonth(today, date);
    } else if (view === ViewType.WEEK) {
      return isSameWeek(today, date, { weekStartsOn: 0 });
    }

    return false;
  }, [date, view]);

  return (
    <StyleDateAndActionsContainer>
      <Stack
        sx={{ flexDirection: 'row', gap: theme.spacing(3), alignItems: 'center', width: isTablet ? '100%' : 'auto' }}
      >
        <TimeNavigationActions
          onTodayClick={setDateToToday}
          isTodayDisabled={isTodayDisabled}
          prev={prev}
          next={next}
        />

        <MonthSelector />
      </Stack>

      {!isTablet ? (
        <Stack
          sx={{
            flexGrow: 1,
            justifyContent: 'flex-end',
            flexDirection: 'row',
            alignItems: 'center',
            gap: theme.spacing(2),
          }}
        >
          <Suspense fallback={null}>
            <Stack
              sx={{
                flexGrow: 1,
                justifyContent: 'flex-end',
                flexDirection: 'row',
                alignItems: 'center',
                display: { lg: 'flex', xs: 'none' },
              }}
            >
              <LazyToggleViewer />

              <Stack sx={{ flexGrow: 1, maxWidth: '264px' }}>
                <LazyProductSelector />
              </Stack>
            </Stack>
          </Suspense>

          <RangeSelector />
        </Stack>
      ) : null}
    </StyleDateAndActionsContainer>
  );
};

export default DateAndActions;
