import { useNavigate, useParams } from 'react-router-dom';
import RescheduleForm from '@/features/reservation/components/RescheduleForm';
import ModalOnDesktopDrawerOnMobile from '@/components/Modal/ModalOnDesktopDrawerOnMobile';
import { useSaleDetails } from '@/features/sales/hook/useSaleDetails';

type Props = {
  onCancel?: () => void;
  onSuccess?: () => void;
  onFail?: () => void;
};

const RescheduleModal = (props: Props) => {
  const navigate = useNavigate();

  const { refetchSaleItems } = useSaleDetails();

  const {
    onCancel = () => navigate('..'),
    onSuccess = () => {
      navigate('..');
    },
  } = props;

  const { reservationId } = useParams();

  const handleSuccess = async () => {
    await refetchSaleItems();
    onSuccess();
  };

  return (
    <ModalOnDesktopDrawerOnMobile
      width={734}
      anchor='bottom'
      isOpen={true}
      showCloseButton={false}
      slotProps={{
        Content: { sx: { padding: '0 !important' } },
        ModalPaper: { sx: { padding: '0 !important' } },
      }}
    >
      {reservationId ? (
        <RescheduleForm reservationId={reservationId} onCancel={() => onCancel()} onSuccess={() => handleSuccess()} />
      ) : null}
    </ModalOnDesktopDrawerOnMobile>
  );
};

export default RescheduleModal;
