import { zodResolver } from '@hookform/resolvers/zod';
import { Box, FormHelperText, Grid, Link, Typography } from '@mui/material';
import { isAxiosError } from 'axios';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';

import Button from '@/components/Button';
import Modal from '@/components/Modal';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import useAuth from '@/contexts/Auth/hooks/useAuth';
import { useErrorHandler } from '@/hooks/useErrorHandler';
import { ErrorTypes } from '@/interfaces/errorTypes';
import { maskEmail } from '@/utils/masks';

import { schema } from './schema';
import { StyledOTPInput } from './styles';

type Props = {
  email: string;
  onChangeEmail: () => void;
  onError: (error: string) => void;
  setLoading: (loading: boolean) => void;
};

type FormProps = {
  code: string;
};

export const CodeValidation = () => {
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const { errorHandler } = useErrorHandler();
  const { t } = useTranslation(['resetPassword']);
  const [openModal, setOpenModal] = useState(false);
  const { t: translateGenericError } = useTranslation(['errors']);
  const { resetPasswordCodeValidStatus, resetPassword } = useAuth();
  const { email, onChangeEmail, onError, setLoading } = useOutletContext<Props>();

  const { handleSubmit, formState, setError, control, reset } = useForm<FormProps>({
    mode: 'onSubmit',
    resolver: zodResolver(schema),
  });

  const { errors, isSubmitting, isValid: isFormValid } = formState;

  const onSubmit = async (data: FormProps) => {
    try {
      const { code } = data;
      setLoading(true);
      await resetPasswordCodeValidStatus(code);
      navigate('../new-password?code=' + code, { replace: true });
    } catch (err) {
      const TrackPath = 'resetPasswordPage/sendCodeRequest/error';
      const { errors: handlerErrors, status, statusText } = errorHandler(err, TrackPath);

      if (handlerErrors) {
        handlerErrors.forEach(({ type }) => {
          if (type === ErrorTypes.VALIDATION || type === ErrorTypes.NOT_FOUND) {
            setError('code', { message: t('code_step_invalid_code') });
          } else {
            onError(translateGenericError('generic_default_error'));
          }
        });

        track(TrackPath, {
          message: handlerErrors.map(({ rule }) => translateGenericError(rule)).join(', '),
          status,
          statusText,
          error: isAxiosError(err) ? err.response?.data : err,
        });

        return;
      }

      onError(translateGenericError('generic_default_error'));
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => setOpenModal(false);

  const handleChangeEmail = () => {
    onChangeEmail();
    handleCloseModal();
  };

  const handleAccept = async () => {
    setLoading(true);
    await resetPassword({ email });
    setLoading(false);
    reset();
    handleCloseModal();
  };

  return (
    <>
      <Grid item xs={12} sx={{ marginBottom: 2 }}>
        <EmailText email={email ?? ''} />
      </Grid>

      <Grid item xs={12}>
        <Grid container rowSpacing={6} component='form' onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={12}>
            <Controller
              name='code'
              control={control}
              rules={{ validate: (value) => value.length === 6 }}
              render={({ field, fieldState }) => (
                <Box>
                  <StyledOTPInput sx={{ gap: 1, fontSize: '' }} {...field} length={6} />
                  {fieldState.invalid ? (
                    <FormHelperText error={!!errors['code']}>{errors['code']?.message}</FormHelperText>
                  ) : null}
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button disabled={!isFormValid || isSubmitting} type='submit' fullWidth variant='contained'>
              {t('code_steps_submit_button')}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography color='colors.gray.500' variant='regularRegular'>
          {t('code_step_do_not_receive_code')}{' '}
          <Link variant='regularBold' sx={{ cursor: 'pointer' }} onClick={() => setOpenModal(true)}>
            {t('code_step_do_not_receive_code_link')}
          </Link>
        </Typography>
      </Grid>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        onCancel={handleChangeEmail}
        onAccept={handleAccept}
        showCloseButton
        showAcceptButton
        showCancelButton
        title={t('steps_title')}
        cancelButtonText={t('code_step_change_email_text')}
        acceptButtonText={t('code_step_confirm_email_text')}
      >
        <EmailText email={email} />
      </Modal>
    </>
  );
};

const EmailText = ({ email }: { email?: string }) => {
  const { t } = useTranslation(['resetPassword']);

  return (
    <Typography color='colors.gray.500' variant='regularRegular'>
      {t('code_step_description')}{' '}
      <Typography variant='regularBold' component='strong'>
        {maskEmail(email ?? '')}
      </Typography>
    </Typography>
  );
};

export default CodeValidation;
