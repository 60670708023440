import { TimeIntervalStatus } from '@/features/reservation/types';
import { RescheduleScheduling, RescheduleSchedulingAvailability } from '@/services/sellerApiQuery/model';
import apiDateToDateObject from '@/utils/apiDateToDateObject';
import { isSameDay } from 'date-fns';

const getActiveSchedulingDay = (data: RescheduleScheduling[], scheduleDate: Date | number) => {
  return data.filter((scheduling) => isSameDay(apiDateToDateObject(scheduling.date), scheduleDate));
};

const getActiveDayTimes = (activeSchedulingDay: RescheduleScheduling[]) => {
  return activeSchedulingDay.reduce((acc, scheduling) => {
    if (!scheduling.scheduleAvailabilities) return acc;
    const availableTimes = scheduling.scheduleAvailabilities.filter((ast) => ast.available);
    return [...acc, ...availableTimes];
  }, [] as RescheduleSchedulingAvailability[]);
};

const isTimeInActiveDayTimes = (activeDayTimes: RescheduleSchedulingAvailability[], time: string) => {
  return activeDayTimes.find((availableTime) => availableTime.time === time);
};

const isTimeAvailable = (activeDayTimes: RescheduleSchedulingAvailability[], time: string) => {
  return activeDayTimes.some((availableTime) => availableTime.time === time && availableTime.available);
};

const getIntervalStatus = (activeDayTimes: RescheduleSchedulingAvailability[], time: string) => {
  const hasReservation = activeDayTimes.find((availableTime) => availableTime.time === time);

  if (!hasReservation) return TimeIntervalStatus.WITHOUT_SCHEDULING;

  const isAvailable = activeDayTimes.find((availableTime) => availableTime.time === time && availableTime.available);
  return isAvailable ? TimeIntervalStatus.AVAILABLE : TimeIntervalStatus.UNAVAILABLE;
};

/**
 * Retrieves available times for scheduling based on the provided date, intervals, and scheduling data.
 *
 * @param {Date | number} scheduleDate - The date for which to retrieve available times.
 * @param {string[]} intervals - An array of time intervals to check for availability.
 * @param {boolean} isChaosMode - A flag indicating whether to bypass availability checks.
 * @param {RescheduleScheduling[]} [data] - Optional scheduling data to check against.
 * @returns {Array<{ label: string, available: boolean, status: TimeIntervalStatus }>} An array of available times with their status.
 */
const getAvailableTimes = (
  scheduleDate: Date | number,
  intervals: string[],
  isChaosMode: boolean,
  schedulings?: RescheduleScheduling[],
): Array<{ label: string; available: boolean; status: TimeIntervalStatus }> => {
  if (!schedulings || !Array.isArray(schedulings)) return [];

  const activeSchedulingDay = getActiveSchedulingDay(schedulings, scheduleDate);
  if (!activeSchedulingDay.length && !isChaosMode) return [];

  const activeDayTimes = getActiveDayTimes(activeSchedulingDay);

  const availableTimes = intervals
    .filter((time) => isChaosMode || isTimeInActiveDayTimes(activeDayTimes, time))
    .map((time) => ({
      label: time,
      available: !isTimeAvailable(activeDayTimes, time),
      status: getIntervalStatus(activeDayTimes, time),
    }));

  return availableTimes;
};

export default getAvailableTimes;
