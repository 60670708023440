import { TFunction } from 'i18next';
import { endOfDay, lastDayOfMonth, parse } from 'date-fns';
import cardValidator from 'card-validator';
import { cnpj, cpf } from 'cpf-cnpj-validator';

import { z } from 'zod';
import { PaymentOption, PaymentCardParams } from '@/services/SellerApi';

export const schema = (t: TFunction<'payment'[]>, paymentOption?: PaymentOption) => {
  const requiredMessage = t('errors.required_field');

  const requiredStringField = z.string({ required_error: requiredMessage }).nonempty({ message: requiredMessage });

  return z
    .object({
      name: requiredStringField,
      identification: requiredStringField.refine(
        (value) => cpf.isValid(value) || cnpj.isValid(value),
        t('errors.invalid_identification'),
      ),
      cardNumber: requiredStringField
        .refine((number) => {
          const cardInfo = cardValidator.number(number);

          if (cardInfo.card === null) {
            return false;
          }
          const isValid = cardInfo.isValid && cardInfo.isPotentiallyValid;

          return isValid;
        }, t('errors.invalid_card'))
        .refine((number) => {
          const cardInfo = cardValidator.number(number);

          if (cardInfo.card === null) {
            return false;
          }

          const brand = cardInfo.card.type === 'american-express' ? 'amex' : cardInfo.card?.type;

          return paymentOption?.brands?.map((b) => b.code).includes(brand);
        }, t('errors.invalid_card_brand')),
      cvv: requiredStringField,
      validity: requiredStringField.refine((date) => {
        const d = endOfDay(lastDayOfMonth(parse(date, 'MM/yy', new Date())));
        const currentDate = new Date();

        return d.getTime() >= currentDate.getTime();
      }, t('errors.past_date')),
      installment: z
        .object({
          amountCents: z.number().min(1).max(999999999),
          division: z.number().min(1).max(999999999),
          installmentCents: z.number().min(1).max(999999999),
        })
        .required(),
      postalCode: requiredStringField.refine((postalCode) => {
        return postalCode?.replace(/\W/g, '').length === 8;
      }, t('errors.invalid_postal_code')),
    })
    .transform((data): PaymentCardParams => {
      const validityDate = parse(data.validity, 'MM/yy', new Date());

      return {
        cardNumber: data.cardNumber.replace(/\s/g, ''),
        holder: data.name,
        holderIdentity: data.identification.replace(/\D/g, ''),
        installments: data.installment.division,
        securityCode: data.cvv,
        validityMonth: (validityDate.getMonth() + 1).toString(),
        validityYear: validityDate.getFullYear().toString(),
        postalCode: data.postalCode.replace(/\W/g, ''),
      };
    });
};
