import { format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { NotifiableError } from '@bugsnag/js';
import { useTranslation } from 'react-i18next';
import { Alert, Box, Typography } from '@mui/material';
import { Rocket01, ShoppingCart01 } from '@untitled-ui/icons-react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { formatCents } from '@/utils';
import Button from '@/components/Button';
import useAccount from '@/hooks/useAccount';
import { useSellerApi } from '@/hooks/useSellerApi';
import { CartItem } from '@/contexts/PartnershipPortalCartContext';
import theme from '@/theme';
import Bugsnag from '@/services/bugsnag';
import { calculatePriceBasedOnThePartnershipCommission } from '@/common/partnership';
import ModalOnDesktopDrawerOnMobile from '@/components/Modal/ModalOnDesktopDrawerOnMobile';
import ReservationForm, { ReservationFormProps } from '@/components/ProductShowCase/ReservationForm';
import { ArrangedProduct, AssociationModel, ProductsApiGetArrangedProductRequest } from '@/services/SellerApi';
import { Loading } from '@/components/Loading';

interface OutletContextType {
  onAddToCart: (cartItem: CartItem) => void;
  onPurchaseNow: (cartItem: CartItem) => void;
}

export const ProductShowCaseReservationForm = () => {
  const { onAddToCart, onPurchaseNow } = useOutletContext<OutletContextType>();

  const { productId } = useParams<{ productId: string }>();

  const { t } = useTranslation(['productShowCase']);
  const navigate = useNavigate();

  const { selectedAccount } = useAccount();
  const { productsApi } = useSellerApi();
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(true);
  const [value, setValue] = useState<ReservationFormProps['value']>();
  const [arrangedProduct, setArrangedProduct] = useState<ArrangedProduct | null>(null);

  const close = () => {
    setOpenModal(false);

    navigate('..', { replace: true });
  };

  const mountCartItem = (): CartItem => {
    const cartItem: CartItem = {
      arrangedProduct: {
        product: arrangedProduct!.product,
        productId: arrangedProduct!.productId,
        partnershipItem: arrangedProduct?.partnershipItem,
        partnershipItemId: arrangedProduct?.partnershipItemId,
      },
      scheduleTime: value?.time ?? undefined,
      scheduleDate: value?.date ? format(value.date, 'yyyy-MM-dd') : undefined,
      tariffs: value!.tariffs.map((selectedTariff) => ({
        tariff: {
          ...selectedTariff,
          priceCents: selectedTariff.finalPriceCents,
        },
        quantity: selectedTariff.selectedQuantity,
      })),
      requiresSeatSelection: value!.requiresSeatSelection,
    };

    return cartItem;
  };

  const addToCart = (callback: OutletContextType['onAddToCart']) => {
    if (!value?.tariffs?.length) {
      return;
    }

    const cartItem: CartItem = mountCartItem();

    callback(cartItem);
  };

  const getArrangedProduct = async () => {
    setLoading(true);

    const payload: ProductsApiGetArrangedProductRequest = {
      appId: selectedAccount!.appId,
      arrangedProductId: productId!,
      include: [
        'partnershipItem',
        'partnershipItem.partnership',
        'partnershipItem.partnership.agent',
        'partnershipItem.partnership.provider',
      ],
      associationModels: [AssociationModel.DirectPurchase],
    };

    try {
      const { data } = await productsApi.getArrangedProduct(payload);

      setArrangedProduct(data);
    } catch (error) {
      enqueueSnackbar(t('snackbar.could_not_load_arranged_product'), { variant: 'error' });

      Bugsnag.notify(error as NotifiableError);
    } finally {
      setLoading(false);
    }
  };

  const subtotal = useMemo(() => {
    if (!value?.tariffs?.length) {
      return 0;
    }

    const subtotalWithoutDiscount = value?.tariffs?.reduce(
      (acc, curr) => acc + curr.finalPriceCents * curr.selectedQuantity,
      0,
    );

    return arrangedProduct?.partnershipItem
      ? calculatePriceBasedOnThePartnershipCommission(subtotalWithoutDiscount, arrangedProduct?.partnershipItem)
      : subtotalWithoutDiscount;
  }, [value?.tariffs]);

  useEffect(() => {
    if (productId && selectedAccount?.appId) {
      getArrangedProduct();
    }
  }, [selectedAccount?.appId, productId]);

  const disableActions = !value?.tariffs?.length || value?.tariffs?.every((tariff) => tariff.isDependent);

  return (
    <ModalOnDesktopDrawerOnMobile
      onClose={close}
      isOpen={openModal && !!productId}
      title={t('modal.title', { productName: arrangedProduct?.product.name ?? '' })}
      slotProps={{ Content: { sx: { marginBottom: '0 !important', position: 'relative' } } }}
    >
      {loading ? <Loading /> : null}

      {!loading && arrangedProduct ? (
        <Box display='flex' flexDirection='column' gap={3} height='100%'>
          <ReservationForm
            onChange={setValue}
            product={arrangedProduct.product}
            partnershipItem={arrangedProduct.partnershipItem}
          />

          <Box sx={{ flexDirection: 'column', gap: 1, display: 'flex' }}>
            <Box
              width='100%'
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant='regularBold' color={theme.palette.common.black}>
                {t('modal.subtotal_label')}
              </Typography>

              <Typography variant='h5' color={theme.palette.common.black}>
                {formatCents(subtotal)}
              </Typography>
            </Box>

            <Button
              fullWidth
              color='primary'
              variant='outlined'
              sx={{ display: 'flex', gap: 1 }}
              disabled={disableActions}
              onClick={() => addToCart(onAddToCart)}
            >
              <ShoppingCart01 />
              {t('modal.add_to_cart_button')}
            </Button>

            <Button
              fullWidth
              color='primary'
              variant='contained'
              sx={{ display: 'flex', gap: 1 }}
              disabled={disableActions}
              onClick={() => addToCart(onPurchaseNow)}
            >
              <Rocket01 />
              {t('modal.purchase_now_button')}
            </Button>
          </Box>
        </Box>
      ) : null}

      {!loading && !arrangedProduct ? (
        <Alert severity='error'>{t('snackbar.could_not_load_arranged_product')}</Alert>
      ) : null}
    </ModalOnDesktopDrawerOnMobile>
  );
};

export default ProductShowCaseReservationForm;
