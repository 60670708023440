import { FilterOption } from '@/interfaces/filterContext';
import ProductSelector from '@/features/products/components/ProductSelector';
import { useConfig } from '@/features/config/useConfig';
import { AgendaConfigState } from '@/features/agenda/types';
import { useLoaderData } from 'react-router-dom';
import { useMemo } from 'react';
import { Product } from '@/services/sellerApiQuery/model';

const HotProductSelector = () => {
  const { config, patchConfig } = useConfig<AgendaConfigState>();
  const routerData = useLoaderData();
  const products = useMemo(() => {
    const productsFromUrl = routerData as Product[];
    if (productsFromUrl) {
      return productsFromUrl;
    }
    return [];
  }, [routerData]);

  const handleProductFilterChange = (_: unknown, value: unknown) => {
    const newValue = value as FilterOption[];
    const products = newValue.length > 0 ? newValue.map((p) => p.value) : [];
    patchConfig({
      products,
    });
  };
  return <ProductSelector value={config.products} onChange={handleProductFilterChange} initialState={products} />;
};

export default HotProductSelector;
