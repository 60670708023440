import usePartnershipPortalCart from '@/hooks/usePartnershipPortalCart';
import theme from '@/theme';
import { Badge } from '@mui/material';
import { IconShoppingCart } from '@tabler/icons-react';

interface Props {
  size?: number;
}

export const ShoppingCart = (props: Props) => {
  const { size = 36 } = props;

  const { cartItems } = usePartnershipPortalCart();

  const hasProductsOnCart = cartItems.length > 0;

  return (
    <Badge badgeContent={hasProductsOnCart ? cartItems.length : undefined} color='error'>
      <IconShoppingCart color={theme.palette.colors.blue[500]} size={size} />
    </Badge>
  );
};
