import { SchedulingFields } from '@/features/agenda/features/schedulingForm/types/formTypes';
import { formFieldsToApiFields } from '@/features/agenda/features/schedulingForm/utils/formFieldsToApiFields';
import { useSellerApi } from '@/hooks/useSellerApi';
import {
  CreateSchedulingParams,
  SchedulingsApiCreateProductSchedulingRequest,
  SchedulingsApiUpdateSchedulingRequest,
  UpdateRuleEnum,
  UpdateSchedulingParams,
} from '@/services/SellerApi';

const useCreateUpdateScheduling = () => {
  const { schedulingsApi } = useSellerApi();

  const createRequest = async (fieldsData: SchedulingFields) => {
    const createSchedulingParams = formFieldsToApiFields<CreateSchedulingParams>(fieldsData);
    const createPayload: SchedulingsApiCreateProductSchedulingRequest = {
      productId: fieldsData.product.id,
      createSchedulingParams,
    };
    return schedulingsApi.createProductScheduling(createPayload);
  };

  const updateRequest = async (fieldsData: SchedulingFields) => {
    const updateSchedulingParams = formFieldsToApiFields<UpdateSchedulingParams>(fieldsData);

    const schedulingId = fieldsData.id as string;
    const rule = fieldsData.updateRule as UpdateRuleEnum;
    const ruleUntilDate = fieldsData.ruleUntilDate;

    const updatePayload: SchedulingsApiUpdateSchedulingRequest = {
      schedulingId,
      updateSchedulingParams: {
        ...updateSchedulingParams,
        rule,
        ruleUntilDate,
      },
    };

    return schedulingsApi.updateScheduling(updatePayload);
  };

  const request = async (fieldsData: SchedulingFields) => {
    if (!fieldsData.id) {
      return createRequest(fieldsData);
    }

    return updateRequest(fieldsData);
  };
  return { request };
};

export default useCreateUpdateScheduling;
