import { addMinutes, format } from 'date-fns';
import parseTimeStringToHourAndMinutes from './parseTimeStringToHourAndMinutes';

type Intervals = { from: string; to: string };

const generateTimeIntervals = (startTime: string, endTime: string, intervalMinutes: number): Intervals[] => {
  const [startHour, startMinute] = parseTimeStringToHourAndMinutes(startTime);
  const [endHour, endMinute] = parseTimeStringToHourAndMinutes(endTime);

  const startDate = new Date(0, 0, 0, startHour, startMinute);
  const endDate = new Date(0, 0, 0, endHour, endMinute);

  const timeIntervals: Intervals[] = [];
  let currentTime = startDate;

  while (currentTime < endDate) {
    timeIntervals.push({
      from: format(currentTime, 'HH:mm'),
      to: format(addMinutes(currentTime, intervalMinutes), 'HH:mm'),
    });
    currentTime = addMinutes(currentTime, intervalMinutes);
  }

  return timeIntervals;
};

export default generateTimeIntervals;
