import { zodResolver } from '@hookform/resolvers/zod';
import { Stack, Typography } from '@mui/material';
import { IconDeviceFloppy } from '@tabler/icons-react';
import { X } from '@untitled-ui/icons-react';
import { format } from 'date-fns';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '@/components/Button';
import CustomDatePicker from '@/components/Form/DatePicker';
import Select from '@/components/Form/Select';
import { AmountInput } from './styles';
import { RecurrenceWeekdayEnum, Recurrence, RecurrenceTypeEnum } from '@/services/SellerApi';
import theme from '@/theme';
import apiDateToDateObject from '@/utils/apiDateToDateObject';
import WeekDaysSelect from '@/components/Form/WeekDaysSelect';
import recurrenceFormSchema from './schema';

type Props = {
  startDate?: Date;
  scheduleRecurrence?: Recurrence;
  onClose: () => void;
  onUpdated: (recurrence: Recurrence) => void;
};

type RecurrenceTypeSelectOption = {
  label: string;
  value: RecurrenceTypeEnum;
};

type FormFields = {
  amount: number;
  type: RecurrenceTypeEnum;
  weekdays: RecurrenceWeekdayEnum[];
  endDate: Date;
};

const CustomRecurrenceForm = (props: Props) => {
  const { onClose, scheduleRecurrence, startDate = new Date(), onUpdated } = props;
  const { t } = useTranslation(['agenda']);
  const getDefaultValue = () => {
    return {
      amount: 1,
      type: RecurrenceTypeEnum.Days,
      weekdays: [],
      endDate: startDate,
    };
  };

  const submit = (data: FormFields) => {
    const dateFormatted = format(data.endDate, 'yyyy-MM-dd');

    onUpdated({
      ...data,
      endDate: dateFormatted,
      ...(data.type !== RecurrenceTypeEnum.Weeks && { weekdays: [] }),
    });

    onClose();
  };

  const { handleSubmit, formState, setValue, control, watch } = useForm<FormFields>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: zodResolver(recurrenceFormSchema(t, startDate)),
    defaultValues: getDefaultValue(),
  });

  const typeWatch = watch('type');
  const isTypeWeeks = useMemo(() => (typeWatch as RecurrenceTypeEnum) === RecurrenceTypeEnum.Weeks, [typeWatch]);

  const weekdaysWatch = watch('weekdays') as RecurrenceWeekdayEnum[];

  const { errors } = formState;

  const options = useMemo(() => {
    return Object.values(RecurrenceTypeEnum).map((value) => ({
      label: t(`recurrence.modal.${value}`),
      value,
    }));
  }, []);

  useEffect(() => {
    const weeks = scheduleRecurrence?.weekdays?.map((day) => day.toLocaleLowerCase()) as RecurrenceWeekdayEnum[];

    setValue('amount', scheduleRecurrence?.amount || 1);
    setValue('type', scheduleRecurrence?.type || RecurrenceTypeEnum.Days);
    setValue('weekdays', weeks || []);

    if (scheduleRecurrence?.endDate) {
      setValue('endDate', apiDateToDateObject(scheduleRecurrence.endDate));
    }
  }, [scheduleRecurrence]);

  return (
    <Stack
      component='form'
      sx={{ gap: theme.spacing(4) }}
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit(submit)();
      }}
    >
      <Stack sx={{ flexDirection: 'row', gap: theme.spacing(4), alignItems: 'center' }}>
        <Typography variant='largeSemiBold' sx={{ width: '100%', textWrap: 'nowrap' }}>
          {t('recurrence.modal.repeat_every')}:
        </Typography>
        <Stack sx={{ maxWidth: '2.625rem' }}>
          <Controller
            control={control}
            name='amount'
            render={({ field: { onChange, value, name, ...rest } }) => (
              <AmountInput
                type='number'
                error={!!errors[`${name}`]}
                onChange={onChange}
                value={value}
                mask='twoDigits'
                {...rest}
              />
            )}
          />
        </Stack>
        <Controller
          control={control}
          name='type'
          render={({ field: { onChange, name, value, ...rest } }) => (
            <Select
              disableClearable
              fullWidth
              openOnFocus
              value={value}
              onChange={(_, newValue) => onChange((newValue as RecurrenceTypeSelectOption).value)}
              options={options}
              error={!!errors[`${name}`]}
              getOptionLabel={(option) => {
                if (!option) return '';
                if (typeof option === 'object') {
                  const { label } = option as RecurrenceTypeSelectOption;
                  return label;
                } else {
                  return t(`recurrence.modal.${option as unknown as string}`);
                }
              }}
              isOptionEqualToValue={(option, currentValue) => {
                const optionValue = (option as RecurrenceTypeSelectOption).value as string;
                return optionValue === currentValue;
              }}
              placeholder={t('recurrence.modal.select')}
              {...rest}
            />
          )}
        />
      </Stack>

      {errors.amount && <Typography>{errors.amount.message}</Typography>}

      {isTypeWeeks && (
        <Stack sx={{ flexDirection: 'column', width: '100%', gap: theme.spacing(4) }}>
          <Typography variant='largeSemiBold'>{t('recurrence.modal.repeat_on')}:</Typography>
          <Controller
            control={control}
            name='weekdays'
            render={({ field: { onChange } }) => <WeekDaysSelect value={weekdaysWatch} onChange={onChange} />}
          />
        </Stack>
      )}
      {errors.weekdays && <Typography>{errors.weekdays.message}</Typography>}

      <Stack sx={{ flexDirection: 'row', gap: theme.spacing(4), alignItems: 'center' }}>
        <Typography variant='largeSemiBold'>{t('recurrence.modal.to')}:</Typography>
        <Controller
          control={control}
          name='endDate'
          render={({ field: { onChange, value, name, ...rest } }) => (
            <CustomDatePicker
              fullWidth
              value={value as unknown as Date}
              format='dd/MM/yyyy'
              minDate={startDate}
              onChange={(value) => onChange(value)}
              error={!!errors[`${name}`]}
              helperText={errors[`${name}`]?.message as string}
              closeOnSelect
              {...rest}
            />
          )}
        />
      </Stack>

      <Stack sx={{ flexDirection: 'row-reverse', gap: theme.spacing(3), marginTop: theme.spacing(4) }}>
        <Button variant='contained' color='primary' type='submit'>
          <IconDeviceFloppy />
          {t('recurrence.buttons.save')}
        </Button>
        <Button variant='outlined' color='secondary' onClick={onClose}>
          <X /> {t('recurrence.buttons.cancel')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default CustomRecurrenceForm;
