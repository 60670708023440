import Input from '@/components/Form/Input';
import {
  Sale,
  Voucher,
  VouchersApiConsumeVoucherExceptionallyRequest,
  VouchersApiConsumeVoucherRequest,
} from '@/services/SellerApi';
import { Alert, Stack, Typography, useMediaQuery } from '@mui/material';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Loading from '@/components/Loading';
import useErrorHandler from '@/hooks/useErrorHandler';
import { useSellerApi } from '@/hooks/useSellerApi';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import { enqueueSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { debounce } from 'lodash';
import Button from '@/components/Button';
import theme from '@/theme';
import Bugsnag from '@/services/bugsnag';
import { NotifiableError } from '@bugsnag/js';
import Toast from '@/components/Toast';
import { AnalyticsEvents } from '@/hooks/analytics/enum/analyticsEvents';
import useAccount from '@/hooks/useAccount';

interface ConsumeVoucherFormProps {
  voucher?: Voucher | null;
  sale?: Partial<Sale> | null;
  onSuccess?: () => void;
  onFail?: (error: unknown) => void;
  exceptional: boolean;
  onCancel: () => void;
}

type ConsumingVoucherState = 'loading' | 'success' | 'error' | undefined;

export const ConsumeVoucherForm = (props: ConsumeVoucherFormProps) => {
  const { voucher, exceptional, onCancel, onSuccess, onFail, sale } = props;

  const SECRET_CODE_LENGTH = 6;

  const { hasPermission } = useAccount();
  const { track } = useAnalytics();
  const { vouchersApi } = useSellerApi();
  const { t } = useTranslation(['vouchers']);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [secretCode, setSecretCode] = useState('');
  const { errorHandler, extractFieldNameFromPath } = useErrorHandler();

  const [consumingVoucherState, setConsumingVoucherState] = useState<ConsumingVoucherState>();

  const inputRef = useRef<HTMLInputElement>(null);

  const hasActiveRefundRequests = sale?.aggregatedRefundRequests?.open && sale?.aggregatedRefundRequests?.open > 0;

  const consumeVoucherExceptionally = async (voucherId: string) => {
    setConsumingVoucherState('loading');
    const payload: VouchersApiConsumeVoucherExceptionallyRequest = {
      voucherId,
    };

    try {
      await vouchersApi.consumeVoucherExceptionally(payload);

      enqueueSnackbar(t('snackbars.voucher_consumed_exceptionally'), { variant: 'success' });

      setConsumingVoucherState('success');

      track(AnalyticsEvents.VOUCHER_CONSUME_EXCEPTIONALLY_SUCCESS, { payload: { ...payload, code: voucher?.code } });

      onSuccess?.();
    } catch (error) {
      onFail?.(error);

      enqueueSnackbar(t('snackbars.could_not_consume_voucher'), { variant: 'error' });
      track(AnalyticsEvents.VOUCHER_CONSUME_EXCEPTIONALLY_FAIL, {
        payload: { ...payload, code: voucher?.code },
        error,
      });
      Bugsnag.notify(error as NotifiableError);
    }
  };

  const consumeVoucher = async (voucherId: string, secret: string) => {
    setConsumingVoucherState('loading');
    const payload: VouchersApiConsumeVoucherRequest = {
      voucherId,
      consumeVoucherParams: {
        secret,
      },
    };

    try {
      await vouchersApi.consumeVoucher(payload);

      enqueueSnackbar(t('snackbars.voucher_consumed'), { variant: 'success' });

      setConsumingVoucherState('success');

      track(AnalyticsEvents.VOUCHER_CONSUME_SUCCESS, { payload: { ...payload, code: voucher?.code } });

      onSuccess?.();
    } catch (error) {
      const { errors } = errorHandler(error as AxiosError);

      if (errors && errors.length) {
        errors.forEach(({ path }) => {
          const field = extractFieldNameFromPath(path!);

          if (field === 'secret') {
            setConsumingVoucherState('error');
          }
        });
      }

      onFail?.(error);

      enqueueSnackbar(t('snackbars.could_not_consume_voucher'), { variant: 'error' });
      track(AnalyticsEvents.VOUCHER_CONSUME_FAIL, { payload: { ...payload, code: voucher?.code }, error });
      Bugsnag.notify(error as NotifiableError);
    }
  };

  const handleAcceptExceptionalConsumption = async () => {
    await consumeVoucherExceptionally(voucher!.id);
    onSuccess?.();
  };

  useEffect(() => {
    if (secretCode.length >= SECRET_CODE_LENGTH && voucher) {
      debounce(() => consumeVoucher(voucher.id, secretCode), 500)();
    }
  }, [secretCode]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const loading = consumingVoucherState === 'loading';

  return (
    <Stack component='form' direction='column' spacing={3}>
      {hasActiveRefundRequests ? (
        <Toast state='warning'>
          <span>
            <Trans
              i18nKey={t('exceptional_consumption_modal.refund_request_warning')}
              components={{ strong: <strong /> }}
            />
          </span>
        </Toast>
      ) : null}
      {exceptional ? (
        <Fragment>
          <Typography>{t('exceptional_consumption_modal.description')}</Typography>

          {loading ? <Loading /> : null}

          <Stack direction='row' justifyContent='flex-start' spacing={1} alignItems='center'>
            <Button
              variant='contained'
              onClick={handleAcceptExceptionalConsumption}
              disabled={loading || !hasPermission('vouchers', 'ConsumeVoucherExceptionally')}
              fullWidth={isMobile}
            >
              {t('exceptional_consumption_modal.actions.accept')}
            </Button>

            <Button variant='outlined' onClick={onCancel} disabled={loading} fullWidth={isMobile}>
              {t('exceptional_consumption_modal.actions.cancel')}
            </Button>
          </Stack>
        </Fragment>
      ) : (
        <Fragment>
          <Input
            fullWidth
            type='text'
            value={secretCode}
            inputRef={inputRef}
            label={t('consume_modal.input_label')}
            onChange={(event) =>
              setSecretCode((previous) => {
                return event.target.value.length > SECRET_CODE_LENGTH ? previous : event.target.value;
              })
            }
          />

          {consumingVoucherState === 'success' ? (
            <Alert severity='success'>{t('consume_modal.alert.success_consumption')}</Alert>
          ) : null}

          {consumingVoucherState === 'error' ? (
            <Alert severity='error'>{t('consume_modal.alert.error_consumption')}</Alert>
          ) : null}

          {loading ? <Loading /> : null}
        </Fragment>
      )}
    </Stack>
  );
};

export default ConsumeVoucherForm;
