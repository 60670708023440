import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.gray[800],
  marginBottom: '1rem',
}));

export const TermsBox = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  width: '100%',
  backgroundColor: theme.palette.colors.gray[50],
  padding: theme.spacing(4),

  '& p': {
    ...theme.typography.smallRegular,
    color: theme.palette.colors.gray[600],
  },
}));
