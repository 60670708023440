import { Card } from '@mui/material';
import { IconArmchair } from '@tabler/icons-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Chip from '@/components/Chip';
import Tooltip from '@/components/Tooltip';
import {
  HeaderContainer,
  SeatMapTitle,
  SeatMapTotalCapacity,
  StyledCardContent,
  StyledContent,
  TitleContainer,
  Container,
} from './styles';
import { SeatMapOverview } from '@/services/sellerApiQuery/model';

type Props = {
  seatMapOverview: SeatMapOverview;
};

const SeatMapCard = ({ seatMapOverview }: Props) => {
  const { t } = useTranslation(['agenda']);

  const getTotalSeatMaps = useMemo(() => {
    return seatMapOverview?.areas.reduce((acc, area) => {
      const capacity = area.slots.reduce((acc2, slot) => {
        return acc2 + (slot.resource?.capacity ?? 0);
      }, 0);
      return acc + capacity;
    }, 0);
  }, [seatMapOverview]);

  return (
    <Container>
      <Card>
        <StyledCardContent>
          <StyledContent>
            <HeaderContainer>
              <TitleContainer>
                <IconArmchair />
                <Chip label={t('modal.scheduling.availability-related.has-seat-map.title')} size='small' />
              </TitleContainer>
              <Tooltip title={t('modal.scheduling.availability-related.has-seat-map.capacity_info')} width='auto'>
                <SeatMapTotalCapacity>
                  {t('modal.scheduling.availability-related.has-seat-map.total_capacity')} {getTotalSeatMaps}
                </SeatMapTotalCapacity>
              </Tooltip>
            </HeaderContainer>
            <SeatMapTitle>{seatMapOverview?.name}</SeatMapTitle>
          </StyledContent>
        </StyledCardContent>
      </Card>
    </Container>
  );
};

export default SeatMapCard;
