import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconChevronDown } from '@tabler/icons-react';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Stack, Typography } from '@mui/material';

import theme from '@/theme';
import { TicketIcon } from '@/features/sales/pages/Vouchers/style';
import ConsumeCell from '../ConsumeCell';
import MobileMenu from '@/components/MobileMenu';
import { PreviewItem } from '@/components/PreviewDrawer';
import RevokedVoucherButton from '../RevokedVoucherButton';
import { AccordionSummaryContent, MobileVoucherDetail, MobileVoucherWrapper } from './style';
import { Voucher } from '@/services/SellerApi';
import PreviewItemChips from '@/components/PreviewItemChips';
import { Actions } from '@/features/sales/pages/Vouchers';
import { getProductSellingModeDisplay } from '@/common/product';
import {
  getAdditionalLabels,
  getTariffsQuantityLabels,
  getResourceLabels,
  sortVouchersByPredecessorId,
} from '@/common/vouchers';

interface Props {
  actions: Actions;
  vouchers: Voucher[];
  consumeVoucher: (voucher: Voucher) => void;
}

export const SaleVoucherMobileView = (props: Props) => {
  const { actions, vouchers, consumeVoucher } = props;

  const { t } = useTranslation(['vouchers']);

  const [isExpanded, setIsExpanded] = useState(true);
  const [shouldShowRevokedVouchers, setShouldShowRevokedVouchers] = useState(false);

  const productName = vouchers[0].product?.internalName ?? vouchers[0].product?.name ?? '';
  const hasResources = vouchers[0].resources?.length ?? 0 > 0;
  const hasAdditionals = vouchers[0].additionals?.length ?? 0 > 0;
  const hasRevokedVouchers = vouchers.some((voucher) => !!voucher.revokedAt);

  const getVouchers = () => {
    return shouldShowRevokedVouchers
      ? sortVouchersByPredecessorId(vouchers)
      : vouchers.filter((voucher) => !voucher.revokedAt);
  };

  return (
    <Accordion elevation={3} expanded={isExpanded} onChange={() => setIsExpanded((state) => !state)}>
      <AccordionSummary id='mobile-voucher-header' aria-controls='mobile-vouchers' expandIcon={<IconChevronDown />}>
        <AccordionSummaryContent direction='row'>
          <Stack direction='row' gap={1} alignItems='flex-start'>
            <TicketIcon />

            <Stack>
              <Typography variant='largeBold' sx={{ whiteSpace: 'wrap' }}>
                {productName}
              </Typography>
              <Typography variant='smallRegular' sx={{ whiteSpace: 'wrap' }}>
                {getProductSellingModeDisplay(
                  vouchers[0].product!.sellingMode,
                  vouchers[0].scheduleDate,
                  vouchers[0].scheduleTime,
                )}
              </Typography>
            </Stack>
          </Stack>

          {hasRevokedVouchers ? (
            <RevokedVoucherButton
              onClick={(e) => {
                e.stopPropagation();
                setShouldShowRevokedVouchers((state) => !state);
              }}
              shouldShowRevoked={shouldShowRevokedVouchers}
            />
          ) : null}
        </AccordionSummaryContent>
      </AccordionSummary>

      <Divider orientation='horizontal' sx={{ color: theme.palette.colors.gray[200] }} />

      <AccordionDetails sx={{ padding: 0 }}>
        {getVouchers().length ? (
          <Fragment>
            {vouchers.map((voucher) => (
              <MobileVoucherWrapper
                key={voucher.id}
                sx={{
                  background: voucher.revokedAt ? theme.palette.colors.gray[50] : theme.palette.common.white,
                }}
              >
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <Typography
                    color={voucher.revokedAt ? theme.palette.colors.gray[400] : theme.palette.colors.gray[700]}
                    variant='regularBold'
                  >
                    {voucher.code}
                  </Typography>

                  {voucher.revokedAt ? (
                    <ConsumeCell voucher={voucher} consumeVoucher={consumeVoucher} />
                  ) : (
                    <MobileMenu
                      items={actions(voucher).map((action) => ({
                        ...action,
                        icon: action.icon,
                        label: action.label,
                        disabled: !!action.disabled,
                      }))}
                    />
                  )}
                </Stack>

                {!voucher.revokedAt ? (
                  <Stack>
                    <ConsumeCell voucher={voucher} consumeVoucher={consumeVoucher} />
                  </Stack>
                ) : null}

                <MobileVoucherDetail isRevoked={!!voucher.revokedAt}>
                  <PreviewItemChips list={getTariffsQuantityLabels(voucher.tariffs)} title={t('table.tariff')} />

                  {hasResources ? (
                    <PreviewItemChips list={getResourceLabels(voucher.resources)} title={t('table.resource')} />
                  ) : null}

                  {hasAdditionals ? (
                    <PreviewItemChips list={getAdditionalLabels(voucher.additionals)} title={t('table.additionals')} />
                  ) : null}

                  <PreviewItem
                    title={t('table.schedule')}
                    content={getProductSellingModeDisplay(
                      voucher.product!.sellingMode,
                      voucher.scheduleDate,
                      voucher.scheduleTime,
                    )}
                  />
                </MobileVoucherDetail>
              </MobileVoucherWrapper>
            ))}
          </Fragment>
        ) : (
          <Stack p={2} justifyContent='center' alignItems='center'>
            <Typography variant='smallRegular'>{t('table.no_active_vouchers')}</Typography>
          </Stack>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default SaleVoucherMobileView;
