import { DetailedSaleItem } from '@/services/SellerApi';
import { formatCents } from '@/utils';
import { Typography, TypographyProps } from '@mui/material';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

export const CellText = (props: TypographyProps) => {
  const { sx, ...rest } = props;

  return (
    <Typography
      variant='extraSmallSemiBold'
      py={1}
      sx={{
        display: 'block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        ...sx,
      }}
      {...rest}
    />
  );
};

interface Props {
  cell?: TypographyProps;
  saleItem: DetailedSaleItem;
}
interface BaseComponent extends Props {
  tariffValuePath: string;
  resourceValuePath: string;
  additionalValuePath: string;
  type?: 'text' | 'currency';
}

const BaseComponent = (props: BaseComponent) => {
  const { cell, saleItem, additionalValuePath, resourceValuePath, tariffValuePath, type = 'text' } = props;

  return (
    <>
      {saleItem.tariffs?.map((tariff, idx) => (
        <CellText key={tariff.id} pt={idx === 0 ? 0 : undefined} {...cell}>
          {type === 'text' ? get(tariff, tariffValuePath) : formatCents(get(tariff, tariffValuePath))}
        </CellText>
      ))}

      {saleItem.resources?.map((resource) => (
        <CellText key={resource.id} {...cell}>
          {type === 'text' ? get(resource, resourceValuePath) : formatCents(get(resource, resourceValuePath))}
        </CellText>
      ))}

      {saleItem.additionals?.map((additional) => (
        <CellText key={additional.id} {...cell}>
          {type === 'text' ? get(additional, additionalValuePath) : formatCents(get(additional, additionalValuePath))}
        </CellText>
      ))}
    </>
  );
};
const Description = (props: Props) => {
  return (
    <BaseComponent
      {...props}
      resourceValuePath='resource.name'
      tariffValuePath='tariff.type.name'
      additionalValuePath='additional.title'
    />
  );
};

const Quantity = (props: Props) => {
  return (
    <BaseComponent
      {...props}
      tariffValuePath='quantity'
      resourceValuePath='resource.capacity'
      additionalValuePath='unitQuantity'
    />
  );
};

const UnityValue = (props: Props) => {
  return (
    <BaseComponent
      {...props}
      tariffValuePath='unitPriceCents'
      resourceValuePath='priceCents'
      additionalValuePath='unitPriceCents'
      type='currency'
    />
  );
};

const Subtotal = (props: Props) => {
  const { cell, saleItem } = props;

  return (
    <>
      {saleItem.tariffs?.map((t, idx) => (
        <CellText key={t.id} pt={idx === 0 ? 0 : undefined} {...cell}>
          {formatCents(t.unitPriceCents * t.quantity)}
        </CellText>
      ))}

      {saleItem.resources?.map((r) => (
        <CellText key={r.id} {...cell}>
          {formatCents(r.priceCents * (r.resource?.capacity ?? 0))}
        </CellText>
      ))}

      {saleItem.additionals?.map((additional) => (
        <CellText key={additional.id} {...cell}>
          {formatCents(additional.unitPriceCents * additional.unitQuantity)}
        </CellText>
      ))}
    </>
  );
};

const Type = (props: Props) => {
  const { t } = useTranslation(['receipt']);

  const { cell, saleItem } = props;

  return (
    <>
      {saleItem.tariffs?.map((tariff, idx) => (
        <CellText key={tariff.id} pt={idx === 0 ? 0 : undefined} {...cell}>
          {t('table.body.tariff')}
        </CellText>
      ))}

      {saleItem.resources?.map((resource) => (
        <CellText key={resource.id} {...cell}>
          {t('table.body.resource')}
        </CellText>
      ))}

      {saleItem.additionals?.map((additional) => (
        <CellText key={additional.id} {...cell}>
          {t('table.body.additionals')}
        </CellText>
      ))}
    </>
  );
};

const CellsMounter = {
  Description,
  Type,
  Quantity,
  UnityValue,
  Subtotal,
};

export default CellsMounter;
