import { Grid } from '@mui/material';
import { useLocation, useNavigate, Location } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '@/components/Button';
import useAuth from '@/contexts/Auth/hooks/useAuth';
import { LogIn02, LogOut02 } from '@untitled-ui/icons-react';
import { AuthStatus } from '@/interfaces/auth';
import { useMemo } from 'react';

type AuthenticationButtonsProps = {
  shouldReturn?: boolean;
  redirectTo?: Location;
  fullWidth?: boolean;
};

export default function AuthenticationButtons(props: AuthenticationButtonsProps) {
  const { shouldReturn = false, redirectTo, fullWidth = false } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const { status, logout } = useAuth();

  const isLogged = useMemo(() => status === AuthStatus.AUTHENTICATED, [status]);

  const { t } = useTranslation(['ui']);

  const getOptions = () => {
    if (shouldReturn) {
      return {
        state: {
          redirectTo: redirectTo ?? location,
        },
      };
    }

    return {};
  };

  const handleLogin = () => {
    navigate('/login', getOptions());
  };

  const handleSignup = () => {
    navigate('/signup', getOptions());
  };

  const handleLogout = () => {
    navigate('/login', { replace: true });
    logout();
  };

  return (
    <Grid container spacing={1} justifyContent='flex-end'>
      {!isLogged ? (
        <>
          <Grid item xs={fullWidth ? 6 : undefined}>
            <Button variant='contained' color='primary' fullWidth onClick={() => handleLogin()} startIcon={<LogIn02 />}>
              {t('login_button')}
            </Button>
          </Grid>

          <Grid item xs={fullWidth ? 6 : undefined}>
            <Button variant='outlined' color='primary' fullWidth onClick={() => handleSignup()} startIcon={<LogIn02 />}>
              {t('signup_button')}
            </Button>
          </Grid>
        </>
      ) : (
        <Grid item xs={fullWidth ? 3 : undefined}>
          <Button variant='contained' color='primary' fullWidth onClick={() => handleLogout()} startIcon={<LogOut02 />}>
            {t('logout_button')}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
