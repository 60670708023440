import theme from '@/theme';
import { Box, Paper, Typography, TypographyProps } from '@mui/material';
import { styled, css } from '@mui/material/styles';
import { ElementType } from 'react';

export const IconWrapper = styled(Box)<{ size: number }>(
  ({ theme, size }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: ${theme.palette.colors.blue[50]};
    border-radius: 100%;
    width: ${size}px;
    height: ${size}px;
  `,
);

export const ProviderContent = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.colors.gray[100]}`,
  borderBottom: `1px solid ${theme.palette.colors.gray[100]}`,
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    padding: '4px 0',
  },
}));

export const ProviderImageWrapper = styled(Box)(({ theme }) => ({
  width: 32,
  height: 32,
  borderRadius: 4,
  background: theme.palette.colors.gray[50],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  flexShrink: 0,

  img: {
    maxWidth: '100%',
  },
}));

export const Image = styled('img')(() => ({
  maxWidth: '100%',
}));

export const ThumbnailWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '195px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.colors.gray[50],
  overflow: 'hidden',

  '& img': {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },

  [theme.breakpoints.down('sm')]: {
    width: '105px',
    height: '105px',
    minWidth: '105px',
    minHeight: '105px',
  },
}));

type CustomTypographyProps = TypographyProps & { component?: ElementType };

export const ProductName = styled(Typography)<CustomTypographyProps>(({ theme }) => ({
  ...theme.typography.largeBold,
  webkitLineClamp: 2,
  webkitBoxOrient: 'vertical',
  display: '-webkit-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: theme.palette.colors.gray[800],

  [theme.breakpoints.down('sm')]: {
    ...theme.typography.regularBold,
    lineHeight: '19px',
    marginTop: 'auto',
  },
}));

export const ProviderName = styled(Typography)<CustomTypographyProps>(({ theme }) => ({
  ...theme.typography.smallBold,
  webkitLineClamp: 2,
  webkitBoxOrient: 'vertical',
  display: '-webkit-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: theme.palette.colors.gray[800],

  [theme.breakpoints.down('sm')]: {
    ...theme.typography.extraSmallSemiBold,
    lineHeight: '13px',
  },
}));

export const Container = styled(Paper)(() => ({
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',

  [theme.breakpoints.up('md')]: {
    maxWidth: '100%',
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
    cursor: 'pointer',
  },
}));

export const CardInfo = styled(Box)(() => ({
  gap: '8px',
  padding: '16px',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.down('sm')]: {
    gap: '4px',
    padding: '8px',
  },
}));

export const PriceContent = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '4px',
  },
}));

export const Price = styled(Typography)(() => ({
  [theme.breakpoints.down('sm')]: {
    lineHeight: '19px',
  },
}));
