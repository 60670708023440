import { styled, TableFooter as BaseTableFooter, Stack, Typography } from '@mui/material';

export const TableFooter = styled(BaseTableFooter)(({ theme }) => ({
  background: theme.palette.colors.gray[100],
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  borderTop: '2px solid' + theme.palette.colors.gray[200],
}));

export const StackBetween = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const StackFlexEnd = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'flex-end',
  spacing: theme.spacing(0.5),
  justifyContent: 'flex-end',
}));

export const PriceSummaryTableCellText = styled(Typography)(({ theme }) => ({
  whiteSpace: 'nowrap',
  fontWeight: 500,
  padding: theme.spacing(0),
}));
