import { AvailabilityTypeEnum } from '@/services/SellerApi';
import { isValid } from 'date-fns';
import { TFunction } from 'i18next';
import { z } from 'zod';
import { CreateUpdateAvailabilityParams } from '../../types/formTypes';

const availabilityFormSchema = (t: TFunction<'agenda'[], undefined>, isEdit: boolean) => {
  const requiredFiledMessage = t('form.validation.required_field');

  const validateTimeStartEnd = (data: CreateUpdateAvailabilityParams, ctx: z.RefinementCtx) => {
    if (data.fromTime && data.toTime) {
      const fromTime = parseInt(data.fromTime.replace(':', ''));
      const toTime = parseInt(data.toTime.replace(':', ''));

      if (fromTime > toTime) {
        ctx.addIssue({
          path: ['fromTime'],
          code: z.ZodIssueCode.custom,
          message: t('form.validation.time_start_end'),
        });
      }
    }
  };

  const validateProducts = (data: CreateUpdateAvailabilityParams, ctx: z.RefinementCtx) => {
    if (data.type === AvailabilityTypeEnum.Products && !data.products?.length) {
      ctx.addIssue({
        path: ['products'],
        code: z.ZodIssueCode.custom,
        message: t('form.validation.required_product'),
      });
    }
  };

  const validateAdditionals = (data: CreateUpdateAvailabilityParams, ctx: z.RefinementCtx) => {
    if (data.type === AvailabilityTypeEnum.Additionals && !data.additionals?.length) {
      ctx.addIssue({
        path: ['additionals'],
        code: z.ZodIssueCode.custom,
        message: t('form.validation.required_additional'),
      });
    }
  };

  const validateTariffs = (data: CreateUpdateAvailabilityParams, ctx: z.RefinementCtx) => {
    if (data.type === AvailabilityTypeEnum.Tariffs && !data.tariffs?.length) {
      ctx.addIssue({
        path: ['tariffs'],
        code: z.ZodIssueCode.custom,
        message: t('form.validation.required_tariff'),
      });
    }
  };

  const validateProductTariffTypes = (data: CreateUpdateAvailabilityParams, ctx: z.RefinementCtx) => {
    if (data.type === AvailabilityTypeEnum.ProductTariffTypes && !data.productTariffTypes?.length) {
      ctx.addIssue({
        path: ['productTariffTypes'],
        code: z.ZodIssueCode.custom,
        message: t('form.validation.required_tariff_type'),
      });
    }
  };

  const validateDateRequired = (data: CreateUpdateAvailabilityParams, ctx: z.RefinementCtx) => {
    if (!isEdit) {
      if (!data.beginDate) {
        ctx.addIssue({
          path: ['beginDate'],
          code: z.ZodIssueCode.custom,
          message: requiredFiledMessage,
        });
      }
    }
  };

  const validateTimesAndQuantities = (data: CreateUpdateAvailabilityParams, ctx: z.RefinementCtx) => {
    // validate if there are times when the from is greater than the to, and if the quantity is less than 0
    data.timesAndQuantities.forEach((timeAndQuantity, index) => {
      const fromTime = parseInt(timeAndQuantity.fromTime.replace(':', ''));
      const toTime = parseInt(timeAndQuantity.toTime.replace(':', ''));

      if (fromTime >= toTime) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('form.validation.time_start_end'),
          path: ['timesAndQuantities', index, 'toTime'],
        });
      }

      if (timeAndQuantity.quantity < 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('form.validation.minimum_quantity'),
          path: ['timesAndQuantities', index, 'quantity'],
        });
      }
    });

    // validate if there are same intervals
    const times = data.timesAndQuantities.map(
      (timeAndQuantity) => `${timeAndQuantity.fromTime}-${timeAndQuantity.toTime}`,
    );

    const firstRepeatedTimeIdx = times.findIndex((time, idx) => times.indexOf(time) !== idx);

    if (firstRepeatedTimeIdx !== -1) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('form.validation.duplicated_time'),
        path: ['timesAndQuantities', firstRepeatedTimeIdx, 'fromTime'],
      });
    }
  };

  const superRefine = (data: CreateUpdateAvailabilityParams, ctx: z.RefinementCtx) => {
    validateProducts(data, ctx);
    validateAdditionals(data, ctx);
    validateTariffs(data, ctx);
    validateProductTariffTypes(data, ctx);
    validateTimeStartEnd(data, ctx);
    validateDateRequired(data, ctx);
    validateTimesAndQuantities(data, ctx);
  };

  return z
    .object({
      type: z.string({ required_error: requiredFiledMessage }),
      beginDate: z
        .date({ required_error: requiredFiledMessage })
        .refine((date) => isValid(date), {
          message: t('form.validation.invalid_date'),
        })
        .optional(),
      appliesAt: z
        .date({ required_error: requiredFiledMessage })
        .refine((date) => isValid(date), {
          message: t('form.validation.invalid_date'),
        })
        .optional(),

      timesAndQuantities: z.array(
        z.object({
          fromTime: z.string({ required_error: requiredFiledMessage }),
          toTime: z.string({ required_error: requiredFiledMessage }),
          quantity: z.coerce
            .number({ invalid_type_error: requiredFiledMessage })
            .int()
            .min(0, { message: t('form.validation.minimum_quantity') }),
        }),
      ),
      products: z.array(z.object({ id: z.string() })).optional(),
      tariffs: z.array(z.object({ id: z.string() })).optional(),
      additionals: z.array(z.object({ id: z.string() })).optional(),
      productTariffTypes: z.array(z.object({ productId: z.string(), tariffTypeId: z.string() })).optional(),
      recurrence: z.unknown().optional(),
    })
    .superRefine((data, ctx) => superRefine(data as CreateUpdateAvailabilityParams, ctx))
    .transform((data) => {
      return {
        type: data.type,
        recurrence: data.recurrence,
        beginDate: data.beginDate ?? undefined,
        appliesAt: data.appliesAt ?? undefined,
        timesAndQuantities: data.timesAndQuantities.map((timeAndQuantity) => ({
          fromTime: timeAndQuantity.fromTime,
          quantity: timeAndQuantity.quantity,
          toTime: timeAndQuantity.toTime === '23:59' ? '24:00' : timeAndQuantity.toTime,
        })),
        productIds: data.products?.length ? data.products.map((product) => product.id) : undefined,
        tariffIds: data.tariffs?.length ? data.tariffs.map((tariff) => tariff.id) : undefined,
        additionalIds: data.additionals?.length ? data.additionals.map((additional) => additional.id) : undefined,
        productTariffTypes: data.productTariffTypes?.length
          ? data.productTariffTypes.map((productTariffType) => ({
              productId: productTariffType.productId,
              tariffTypeId: productTariffType.tariffTypeId,
            }))
          : undefined,
      };
    });
};

export default availabilityFormSchema;
