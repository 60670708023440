import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress, Grid, Typography, useMediaQuery } from '@mui/material';
import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import theme, { colors } from '@/theme';
import Alert from '@/components/Alert';
import Input from '@/components/Form/Input';
import SaleDatailItem from './SaleDatailItem';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import { formatCents, formatDate } from '@/utils';
import { useSellerApi } from '@/hooks/useSellerApi';
import { RefundRequestInsights, Sale, SaleStateEnum, SalesApiGetSaleRequest } from '@/services/SellerApi';
import { MAX_OBSERVATION_LENGTH, schema } from './schema';
import Button from '@/components/Button';
import BackdropLoading from '@/components/BackdropLoading';
import Tooltip from '@/components/Tooltip';
import isSaleNonRefundable from '@/features/sales/utils/isSaleNonRefundable';

type Props = {
  saleId?: string;
  onCancel: () => void;
  onSuccess?: () => void;
  onError?: (error: AxiosError) => void;
};

export const RefundRequestForm = (props: Props) => {
  const { saleId, onCancel, onSuccess, onError } = props;

  const { track } = useAnalytics();
  const [sale, setSale] = useState<Sale>();
  const { t } = useTranslation(['refundRequests']);
  const { refundRequestsApi, salesApi } = useSellerApi();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoadingInsights, setIsLoadingInsights] = useState(true);
  const [loading, setLoading] = useState(true);
  const [refundRequestInsights, setRefundRequestInsights] = useState<RefundRequestInsights>();

  const { register, formState, handleSubmit, watch } = useForm({
    mode: 'onSubmit',
    resolver: zodResolver(schema),
  });
  const { errors, isSubmitting } = formState;

  const isSalePaymentComplete = useMemo(() => {
    if (!sale) return undefined;

    return sale.currentState === SaleStateEnum.PaymentComplete;
  }, [sale]);

  const isNonRefundable = useMemo(() => isSaleNonRefundable(sale), [sale]);

  const handleClose = () => onCancel();

  const onSubmit = async ({ observation }: { observation?: string }) => {
    const payload = {
      saleId: saleId!,
      refundRequestParams: {
        observation,
      },
    };

    try {
      setLoading(true);
      await refundRequestsApi.createSaleRefundRequest(payload);

      track('refundRequests/createRequestRefundSuccess', { payload });
      enqueueSnackbar(t('modal.create_request_refund'), { variant: 'success' });
      onSuccess?.();
    } catch (error) {
      onError?.(error as AxiosError);
      track('refundRequests/createRequestRefundFail', { payload });
      enqueueSnackbar(t('modal.could_not_create_request_refund'), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const validateRefundRequest = useCallback(
    async (id: string) => {
      const payload = { saleId: id };

      try {
        setIsLoadingInsights(true);
        const { data } = await refundRequestsApi.getSaleRefundInsights(payload);
        setRefundRequestInsights({ ...data });

        track('refundRequests/retrieveRequestRefundInsightsSuccess', { payload });
      } catch (error) {
        onError?.(error as AxiosError);
        enqueueSnackbar(t('modal.could_not_load_request_refund_insights'), { variant: 'error' });
        track('refundRequests/retrieveRequestRefundInsightsFail', { payload });
      } finally {
        setIsLoadingInsights(false);
      }
    },
    [setIsLoadingInsights, refundRequestsApi, saleId],
  );

  const getSale = useCallback(async (id: string) => {
    const payload: SalesApiGetSaleRequest = {
      saleId: id,
      include: ['customer', 'aggregatedVouchers', 'decisiveTransaction', 'order.decisiveTransaction'],
    };

    try {
      setLoading(true);
      const { data } = await salesApi.getSale(payload);
      setSale(data);

      track('refundRequests/retrieveRequestRefundItemSuccess', { payload });
    } catch (error) {
      onError?.(error as AxiosError);

      enqueueSnackbar(t('modal.could_not_load_request_refund_item'), { variant: 'error' });
      track('refundRequests/retrieveRequestRefundItemFail', { payload });
    } finally {
      setLoading(false);
    }
  }, []);

  const getInsightsStatus = useCallback(() => {
    if (!saleId) {
      return { text: 'could_not_find_sale_id', color: colors.red[500] };
    }

    if (isSalePaymentComplete === false) {
      return { text: 'sale_payment_not_complete', color: colors.red[500] };
    }

    if (!refundRequestInsights!.isCreationDateInsidePeriod) {
      return { text: 'validation_creation_date', color: colors.red[500] };
    }

    if (refundRequestInsights!.hasConsumedVouchers) {
      return { text: 'validation_consummed_voucher', color: colors.yellow[500] };
    }

    return { text: 'validation_success', color: colors.green[700] };
  }, [refundRequestInsights, isSalePaymentComplete]);

  const getConsummedVouchers = (sale: Sale) => {
    if (!sale?.aggregatedVouchers) {
      return '-';
    }

    const { consumedVouchersCount = 0, vouchersCount = 0 } = sale.aggregatedVouchers;

    return `${consumedVouchersCount}/${vouchersCount}`;
  };

  const canCreateRefundRequest = useMemo(
    () => sale && refundRequestInsights?.isCreationDateInsidePeriod && isSalePaymentComplete && saleId,
    [sale, refundRequestInsights],
  );

  useEffect(() => {
    if (saleId) {
      getSale(saleId);
      validateRefundRequest(saleId);
    }
  }, [saleId]);

  return (
    <Grid container gap={3} component='form' onSubmit={handleSubmit(onSubmit)}>
      {canCreateRefundRequest ? (
        <Grid item xs={12}>
          <Typography variant='largeMedium' color={colors.gray[700]}>
            {t('modal.request_refund_value_and_client_name', {
              value: formatCents(sale!.amountCents ?? 0),
              clientName: `${sale!.customer?.firstName} ${sale!.customer?.lastName}`,
            })}
          </Typography>
        </Grid>
      ) : null}

      {isLoadingInsights && !loading ? (
        <Grid item xs={12}>
          <Box display='flex' alignItems='center' width='100%' justifyContent='center'>
            <CircularProgress />
          </Box>
        </Grid>
      ) : null}

      {!isLoadingInsights && (refundRequestInsights || isSalePaymentComplete === false || !saleId) ? (
        <Grid item xs={12}>
          <Box display='flex' flexDirection='column'>
            <Typography variant='largeBold' color={getInsightsStatus().color}>
              {t(`modal.${getInsightsStatus().text}`)}
            </Typography>

            {!refundRequestInsights?.isCreationDateInsidePeriod ? (
              <Typography variant='regularRegular' color={colors.gray[700]}>
                {t('modal.validation_creation_date_helper')}
              </Typography>
            ) : null}
          </Box>
        </Grid>
      ) : null}

      {loading && !sale ? (
        <Grid item xs={12}>
          <Box display='flex' alignItems='center' width='100%' justifyContent='center'>
            <CircularProgress />
          </Box>
        </Grid>
      ) : null}

      {canCreateRefundRequest && !loading ? (
        <Grid item xs={12}>
          <Box display='flex' gap={2} flexDirection='row' alignItems='center' justifyContent='flex-start'>
            <Box display='flex' gap={0.5} flexDirection='column' justifyContent='flex-start' alignItems='flex-start'>
              <SaleDatailItem
                title={t('modal.client_name')}
                value={`${sale!.customer?.firstName} ${sale!.customer?.lastName}`}
              />

              <SaleDatailItem title={t('modal.sale_date')} value={formatDate(new Date(sale!.createdAt))} />
            </Box>

            <Box display='flex' gap={0.5} flexDirection='column' justifyContent='flex-start' alignItems='flex-start'>
              <SaleDatailItem title={t('modal.sale_amount')} value={formatCents(sale?.amountCents ?? 0)} />

              <SaleDatailItem title={t('modal.consummed_voucher')} value={getConsummedVouchers(sale!)} />
            </Box>
          </Box>
        </Grid>
      ) : null}

      {canCreateRefundRequest ? (
        <Grid item xs={12}>
          <Alert message={t('modal.alert_message')} severity='warning' />
        </Grid>
      ) : null}

      {canCreateRefundRequest ? (
        <Grid item xs={12}>
          <Input
            multiline
            fullWidth
            minRows={2}
            maxRows={6}
            id='observation'
            {...register('observation')}
            label={t('modal.text_field_label')}
            error={!!errors['observation']}
            helperText={
              (errors['observation']?.message as string) ??
              `${watch('observation')?.length ?? 0}/${MAX_OBSERVATION_LENGTH}`
            }
            tooltipProps={{
              placement: 'left',
              title: t('modal.text_field_tooltip_description'),
            }}
          />
        </Grid>
      ) : null}

      {canCreateRefundRequest ? (
        <Grid item xs={12}>
          <Box display='flex' alignItems='center' gap={1} flexDirection='row'>
            <Tooltip
              arrow
              placement='top'
              enterTouchDelay={0}
              leaveTouchDelay={60000}
              title={isNonRefundable ? t('modal.no_refund_request_tooltip') : null}
              PopperProps={{ style: { zIndex: 99999, textAlign: 'center' } }}
            >
              <span>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  fullWidth={isMobile}
                  disabled={isSubmitting || loading || isNonRefundable}
                >
                  {t('modal.accept_button_text')}
                </Button>
              </span>
            </Tooltip>

            <Button variant='outlined' color='primary' onClick={handleClose} fullWidth={isMobile}>
              {t('modal.cancel_button_text')}
            </Button>
          </Box>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Button variant='contained' color='primary' onClick={handleClose} fullWidth={isMobile}>
            {t('modal.accept_button_text_ok')}
          </Button>
        </Grid>
      )}

      {isSubmitting ? <BackdropLoading /> : null}
    </Grid>
  );
};

export default RefundRequestForm;
