import { Stack } from '@mui/system';
import { IconCircleFilled, IconPlus } from '@tabler/icons-react';
import { useMemo } from 'react';

import { SlotsPreviewProps } from '@/features/agenda/components/SchedulingCalendar/AgendaSlot/types';
import { AgendaConfigState, ViewDataType } from '@/features/agenda/types';
import { colors } from '@/theme';
import { useConfig } from '@/features/config/useConfig';

const SlotsPreviewMobile = (props: SlotsPreviewProps) => {
  const { availabilities, schedulings } = props;

  const { config } = useConfig<AgendaConfigState>();
  const viewDataType = useMemo(() => config?.viewDataType, [config]);

  const totalOfItems = useMemo(() => {
    let total = 0;
    if (viewDataType?.includes(ViewDataType.AVAILABILITIES)) {
      total += availabilities?.length ?? 0;
    }
    if (viewDataType?.includes(ViewDataType.SCHEDULING)) {
      total += schedulings?.length ?? 0;
    }
    return total;
  }, [availabilities, schedulings, viewDataType]);

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
        justifyContent: 'flex-start',
        gap: '0.1rem',
      }}
    >
      {viewDataType?.includes(ViewDataType.AVAILABILITIES) &&
        availabilities?.map((availability) => (
          <IconCircleFilled key={availability.id} width='0.625rem' height='0.625rem' color={colors.primary[300]} />
        ))}
      {viewDataType?.includes(ViewDataType.SCHEDULING) &&
        schedulings?.map((scheduling) => (
          <IconCircleFilled key={scheduling.id} width='0.625rem' height='0.625rem' color={colors.gray[500]} />
        ))}
      {totalOfItems > 2 ? <IconPlus width='0.625rem' height='0.625rem' color={colors.primary.main} /> : null}
    </Stack>
  );
};

export default SlotsPreviewMobile;
