import { FilterOption } from '@/contexts/FilterContext';
import useAccount from '@/hooks/useAccount';
import { useSellerApi } from '@/hooks/useSellerApi';
import { Product, ProductsApiGetAppProductsRequest } from '@/services/SellerApi';
import { TFunction } from 'i18next';
import { useEffect, useState } from 'react';

export const useCouponFilterSchema = (t: TFunction<[string], undefined>) => {
  const { selectedAccount } = useAccount();

  const { productsApi } = useSellerApi();

  const [products, setProducts] = useState<FilterOption[]>([]);
  const [loadingProducts, setLoadingProducts] = useState(true);

  const getAppProducts = async (appId: string) => {
    setLoadingProducts(true);

    let page = 0;
    const limit = 100;
    let keepRunning = true;
    const allProducts: Product[] = [];

    try {
      while (keepRunning) {
        const payload: ProductsApiGetAppProductsRequest = {
          appId,
          limit: limit,
          offset: page * limit,
        };

        const { data, headers } = await productsApi.getAppProducts(payload);

        allProducts.push(...data);
        page++;

        const totalOfProducts = Number(headers['x-pagination-total-count'] ?? '0');

        if (allProducts.length >= totalOfProducts) {
          keepRunning = false;
          break;
        }
      }

      const sortedProducts = allProducts.sort((a, b) => a.name!.localeCompare(b.name!));

      setProducts(
        sortedProducts.map((product) => ({ label: product.internalName ?? product.name, value: product.id })),
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProducts(false);
    }
  };

  useEffect(() => {
    if (selectedAccount?.appId) {
      getAppProducts(selectedAccount.appId);
    }
  }, [selectedAccount?.appId]);

  return {
    productIds: {
      type: 'array',
      title: t('filter.product_selector_label'),
      loading: loadingProducts,
      options: products,
    },
    validSince: {
      type: 'date',
      format: 'dd/MM/yyyy',
      title: t('filter.since.label'),
      tooltip: t('filter.since.label'),
    },
    validUntil: {
      type: 'date',
      format: 'dd/MM/yyyy',
      title: t('filter.until.label'),
      tooltip: t('filter.until.label'),
    },
  };
};

export default useCouponFilterSchema;
