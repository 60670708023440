import { Grid } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { cnpj } from 'cpf-cnpj-validator';

import Button from '@/components/Button';
import Input from '@/components/Form/Input';
import Select from '@/components/Form/Select';
import { useSellerApi } from '@/hooks/useSellerApi';
import { DefaultValueFields } from '@/pages/App/Create';
import { BankAccountType, BankOption } from '@/services/SellerApi';
import RadioButtonsGroup, { RadioOptionProps } from '@/components/Form/RadioButtonsGroup';
import theme from '@/theme';
import { formatCents } from '@/utils';

type Props = {
  defaultValues: DefaultValueFields;
  onSubmit: (data: DefaultValueFields) => void;
  form: UseFormReturn<DefaultValueFields, unknown>;
};

export const FinancialForm = (props: Props) => {
  const { onSubmit, form, defaultValues } = props;

  const { appsApi } = useSellerApi();
  const { t } = useTranslation(['createApp']);
  const [isLoading, setIsLoading] = useState(false);
  const [bankOptions, setBankOptions] = useState<BankOption[]>([]);

  const { formState, register, setValue, handleSubmit, control, watch } = form;
  const { errors } = formState;

  const getBankOptions = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await appsApi.getBankOptions();
      const formatedOptions = data.sort((a, b) => a.code.localeCompare(b.code));

      setBankOptions(formatedOptions);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getBankOptions();

    if (defaultValues?.identifier) {
      setValue('bankIdentifier', defaultValues.identifier, { shouldValidate: true });
    }
  }, []);

  const getOptionLabel = (option: BankOption) => (option.code ? `${option.code} - ${option.shortName}` : '');
  const isIdentifierCnpj = useMemo(() => cnpj.isValid(watch('bankIdentifier')), [watch('bankIdentifier')]);

  const radioOptionSlotProps = {
    slotProps: { typography: { variant: 'smallRegular', color: theme.palette.colors.gray[700] } },
    size: 'small',
  };

  const radioOptions = [
    {
      value: BankAccountType.ContaCorrente,
      label: t('bank_account_type.conta_corrente'),
      ...radioOptionSlotProps,
    },
    {
      value: BankAccountType.Poupanca,
      label: t('bank_account_type.poupanca'),
      ...radioOptionSlotProps,
    },
  ];

  return (
    <Grid
      container
      rowSpacing={2}
      component='form'
      alignItems='center'
      justifyContent='center'
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item xs={12}>
        <Input
          required
          fullWidth
          mask='cpf_cnpj'
          id='bankIdentifier'
          disabled={!!defaultValues?.identifier}
          label={t('cnpj_cpf_bank_identifier_label')}
          error={!!errors['bankIdentifier']}
          helperText={errors['bankIdentifier']?.message ?? t('bank_cnpj_cpf_identifier_helper')}
          {...register('bankIdentifier')}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name='bankCode'
          control={form.control}
          render={({ field: { name, value, onChange, ...rest } }) => (
            <Select
              required
              fullWidth
              id={name}
              value={value}
              loading={isLoading}
              defaultValue={value}
              options={bankOptions}
              error={!!errors[`${name}`]}
              label={t('bank_code_label')}
              helperText={errors[`${name}`]?.message}
              loadingText={t('loading_bank_options')}
              openOnFocus={!errors[`${name}`]?.message}
              onChange={(_, value) => onChange((value as BankOption) || { code: '', shortName: '', longName: '' })}
              getOptionLabel={(option) => getOptionLabel(option as BankOption)}
              {...rest}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Input
          fullWidth
          required
          id='bankAgency'
          mask='bankAgency'
          label={t('bank_agency_label')}
          error={!!errors['bankAgency']}
          helperText={errors['bankAgency']?.message}
          {...register('bankAgency')}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name='bankAccountType'
          control={control}
          defaultValue={defaultValues.bankAccountType ?? BankAccountType.ContaCorrente}
          render={({ field: { onChange, value, name, ...rest } }) => (
            <RadioButtonsGroup
              required
              id={name}
              name={name}
              value={value}
              onChange={onChange}
              error={!!errors[`${name}`]}
              label={t('bank_account_type.label')}
              helperText={errors[`${name}`]?.message as string}
              sx={{ margin: '-8px 0px' }}
              row
              options={radioOptions as RadioOptionProps[]}
              {...rest}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Input
          fullWidth
          required
          id='bankAccount'
          mask='bankAccount'
          label={t('bank_account_label')}
          error={!!errors['bankAccount']}
          helperText={errors['bankAccount']?.message || t('bank_account_helper')}
          {...register('bankAccount')}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name='expectedMonthlyRevenueCents'
          control={control}
          render={({ field: { name, value = '', onChange, ...rest } }) => (
            <Input
              fullWidth
              required
              id={name}
              label={t(isIdentifierCnpj ? 'expected_monthly_average_revenue_label' : 'expected_monthly_revenue_label')}
              error={!!errors[`${name}`]}
              helperText={errors[`${name}`]?.message}
              tooltipProps={{ title: t('expected_monthly_revenue_tooltip'), placement: 'top' }}
              {...rest}
              value={value}
              onChange={(e) => {
                const numberValue = e.target.value.replace(/\D/g, '');

                e.target.value = numberValue ? formatCents(Number(numberValue)) : '';
                onChange(e);
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} mt={2}>
        <Button type='submit' fullWidth variant='contained'>
          {t('submit_button')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default FinancialForm;
