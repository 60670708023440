import { FilterOption } from '@/contexts/FilterContext';
import useAccount from '@/hooks/useAccount';
import { useSellerApi } from '@/hooks/useSellerApi';
import { ArrangedProvidersApiGetAppArrangedProvidersRequest, PublicApp } from '@/services/SellerApi';
import { TFunction } from 'i18next';
import { useEffect, useState } from 'react';
import Bugsnag from '@/services/bugsnag';
import { NotifiableError } from '@bugsnag/js';

export const useCommissionFilterSchema = (t: TFunction<[string], undefined>) => {
  const { selectedAccount } = useAccount();
  const { arrangedProvidersApi } = useSellerApi();

  const [loadingProviders, setLoadingProviders] = useState(true);
  const [providers, setProviders] = useState<FilterOption[]>([]);

  const getAppArrangedProviders = async (appId: string) => {
    setLoadingProviders(true);

    let page = 0;
    const limit = 100;
    let keepRunning = true;
    const allProviders: PublicApp[] = [];

    try {
      while (keepRunning) {
        const payload: ArrangedProvidersApiGetAppArrangedProvidersRequest = {
          appId,
          limit: limit,
          offset: page * limit,
        };

        const { data, headers } = await arrangedProvidersApi.getAppArrangedProviders(payload);

        allProviders.push(...data);
        page++;

        const totalOfProducts = Number(headers['x-pagination-total-count'] ?? '0');

        if (allProviders.length >= totalOfProducts) {
          keepRunning = false;
          break;
        }
      }

      const sortedProviders = allProviders.sort((a, b) => a.name!.localeCompare(b.name!));

      setProviders(sortedProviders.map((provider) => ({ label: provider.name, value: provider.id })));
    } catch (error) {
      Bugsnag.notify(error as NotifiableError);
    } finally {
      setLoadingProviders(false);
    }
  };

  useEffect(() => {
    if (selectedAccount?.appId) {
      getAppArrangedProviders(selectedAccount.appId);
    }
  }, [selectedAccount?.appId]);

  return {
    providerIds: {
      type: 'array',
      title: t('filter.provider_label'),
      loading: loadingProviders,
      options: providers,
    },
    since: {
      type: 'date',
      format: 'dd/MM/yyyy',
      title: t('filter.sale_date_since_label'),
      tooltip: t('filter.sale_date_since_label'),
    },
    until: {
      type: 'date',
      format: 'dd/MM/yyyy',
      title: t('filter.sale_date_until_label'),
      tooltip: t('filter.sale_date_until_label'),
    },
    sinceScheduleDate: {
      type: 'date',
      format: 'dd/MM/yyyy',
      title: t('filter.schedule_date_since_label'),
      tooltip: t('filter.schedule_date_since_label'),
    },
    untilScheduleDate: {
      type: 'date',
      format: 'dd/MM/yyyy',
      title: t('filter.schedule_date_until_label'),
      tooltip: t('filter.schedule_date_until_label'),
    },
  };
};

export default useCommissionFilterSchema;
