import { Grid, Hidden } from '@mui/material';
import { Container, SideContent, SideContentWrapper } from './styles';
import ErrorBoundary from '@/components/Error/ErrorBoundary';
import ErrorTechnical from '@/components/Error/ErrorTechnical';

type ShowCaseLayoutProps = {
  children: React.ReactNode;
  sideContent?: React.ReactNode;
};

export const ShowCaseLayout = (props: ShowCaseLayoutProps) => {
  const { children, sideContent } = props;

  return (
    <ErrorBoundary FallbackComponent={ErrorTechnical}>
      <Container container>
        <Grid item xs>
          {children}
        </Grid>

        {sideContent ? (
          <>
            <Hidden smDown>
              <SideContentWrapper item sm={4} md={3} xl={2}>
                <SideContent>{sideContent}</SideContent>
              </SideContentWrapper>
            </Hidden>
          </>
        ) : null}
      </Container>
    </ErrorBoundary>
  );
};

export default ShowCaseLayout;
