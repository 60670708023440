import { ElementType } from 'react';
import { styled } from '@mui/material/styles';
import { ListItemButton } from '@mui/material';
import { LinkProps } from 'react-router-dom';

interface ListItemButtonLink extends LinkProps {
  level: number;
  component: ElementType;
  onlyShowIcon?: boolean;
}

const unsafeProps: PropertyKey[] = ['level', 'onlyShowIcon'];
export const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => !unsafeProps.includes(prop),
})<ListItemButtonLink>(({ theme, level, onlyShowIcon }) => ({
  color: '#CCCCCC',
  height: level > 1 ? 52 : 56,
  marginBottom: level > 1 ? 0 : 6,

  borderLeftColor: '#FFFFFF',
  borderLeftWidth: 0,
  borderLeftStyle: 'solid',

  transition: theme.transitions.create(['border-left-width'], {
    easing: theme.transitions.easing.easeIn,
    duration: 50,
  }),

  justifyContent: onlyShowIcon ? 'initial' : 'center',
  ...(onlyShowIcon && {
    '&:hover': {
      background: theme.palette.primary[500],
    },
  }),

  '& .MuiListItemIcon-root': {
    color: '#CCCCCC',
    minWidth: 38,
  },

  '&.Mui-selected, .Mui-selected:hover': {
    '&.MuiListItemButton-root:hover': {
      backgroundColor: level > 1 ? theme.palette.primary[500] : theme.palette.secondary.main,
    },

    '& .MuiListItemIcon-root': {
      color: '#FFFFFF',
    },

    color: '#FFFFFF',

    borderLeftWidth: 4,
    paddingLeft: 12,
    backgroundColor: level > 1 ? theme.palette.primary[500] : theme.palette.secondary.main,
  },
}));
