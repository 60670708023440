import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useAccount from '@/hooks/useAccount';
import Loading from '@/components/BackdropLoading';
import useAuth from '@/contexts/Auth/hooks/useAuth';
import { AuthStatus } from '@/interfaces/auth';

export const Base = () => {
  const navigate = useNavigate();

  const { accessToken, status: authStatus } = useAuth();
  const { initialized, accounts, selectedAccount } = useAccount();

  useEffect(() => {
    if (initialized && !accounts.length) {
      navigate('/app/create');
    }

    if (initialized && accounts.length && selectedAccount) {
      navigate(`/${selectedAccount?.app?.code}`);
    }

    if (authStatus === AuthStatus.UNAUTHENTICATED && !accessToken) {
      navigate('/login');
    }
  }, [authStatus, initialized]);

  return <Loading />;
};

export default Base;
