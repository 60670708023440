import { v4 as uuid } from 'uuid';
import { createContext, useState, useCallback, useEffect, ReactNode } from 'react';

import {
  ArrangedProduct,
  DetailedScheduling,
  DetailedTariffGroup,
  ProductsApiGetArrangedProductRequest,
  SchedulingsApiGetArrangedProductDetailedSchedulingsRequest,
  SellingMode,
  Tariff,
  TariffGroupsApiGetArrangedProductDetailedDefaultTariffGroupRequest,
  TariffScheduleUnitPrice,
} from '@/services/SellerApi';
import { AxiosError } from 'axios';
import { useSellerApi } from '@/hooks/useSellerApi';
import useAccount from '@/hooks/useAccount';
import { isBefore, startOfDay } from 'date-fns';
import { Outlet } from 'react-router-dom';
import { calculatePriceBasedOnThePartnershipCommission } from '@/common/partnership';

interface ItemTariff {
  tariff: Tariff;
  quantity: number;
}

export interface CartItem {
  tariffs: ItemTariff[];
  scheduleDate?: string;
  scheduleTime?: string;
  arrangedProduct: ArrangedProduct;
  requiresSeatSelection: boolean;
}

export interface CartItemWithId extends CartItem {
  id: string;
  isValid: boolean;
}

interface Cart {
  appId: string;
  checkoutId: string;
  items: CartItemWithId[];
}

interface PartnershipPortalCartContextType {
  loadingCart: boolean;
  cartItems: CartItemWithId[];
  checkoutId: string;
  removeItemFromCart: (id: string) => void;
  addItemToCart: (item: CartItem) => void;
  calculateTotalPriceOfAllCartItems: (ci: CartItemWithId[]) => number;
  calculateCartItemTotalPrice: (item: CartItem | CartItemWithId) => number;
  getCartItemsSelectedQuantity: (producId: string, date: string, time?: string) => number;
  clearCart: () => void;
}

// eslint-disable-next-line react-refresh/only-export-components
export const PartnershipPortalCartContext = createContext<PartnershipPortalCartContextType | undefined>(undefined);

interface Props {
  children: ReactNode;
}

export const PartnershipPortalCartProvider = (props: Props) => {
  const { children } = props;
  const LOCAL_STORAGE_KEY = '@PLANNE_APP/PartnershipPortalCart';

  const [loadingCart, setLoadingCart] = useState(true);
  const [cartItems, setCartItems] = useState<CartItemWithId[]>([]);
  const [checkoutId, setCheckoutId] = useState<string>(uuid());

  const { selectedAccount } = useAccount();
  const { productsApi, schedulingsApi, tariffGroupsApi } = useSellerApi();

  const addItemToCart = useCallback((item: CartItem) => {
    const newItem = { ...item, id: uuid(), isValid: true };

    setCartItems((prevItems) => {
      const newItems = [...prevItems, newItem];

      return newItems;
    });
  }, []);

  const removeItemFromCart = useCallback((id: string) => {
    setCartItems((prevItems) => {
      const newItems = prevItems.filter((item) => item.id !== id);

      return newItems;
    });
  }, []);

  const reduceQuantity = (items: CartItem[]) =>
    items.reduce(
      (accumulator, currentItem) => currentItem.tariffs.reduce((acc, cur) => acc + cur.quantity, accumulator),
      0,
    );

  const getCartItemsSelectedQuantity = (producId: string, date: string, time?: string) => {
    const cartItemsWithTheSameDate = cartItems.filter((cartItem) => {
      if (time) {
        return (
          cartItem.scheduleDate === date &&
          cartItem.scheduleTime === time &&
          cartItem.arrangedProduct.productId === producId
        );
      }

      return cartItem.scheduleDate === date && cartItem.arrangedProduct.productId === producId;
    });

    return reduceQuantity(cartItemsWithTheSameDate);
  };

  const calculateCartItemTotalPrice = (item: CartItem) => {
    const price = item.tariffs.reduce((acc, cur) => {
      const priceWithDiscount = calculatePriceBasedOnThePartnershipCommission(
        cur.tariff.priceCents,
        item.arrangedProduct.partnershipItem!,
      );

      return acc + cur.quantity * priceWithDiscount;
    }, 0);

    return price;
  };

  const calculateTotalPriceOfAllCartItems = (ci: CartItemWithId[]) =>
    ci.reduce((acc, cur) => acc + calculateCartItemTotalPrice(cur), 0);

  const getArrangedProduct = async (arrangedProductId: string) => {
    const payload: ProductsApiGetArrangedProductRequest = {
      arrangedProductId,
      appId: selectedAccount?.appId ?? '',
      include: ['partnershipItem', 'partnershipItem.partnership', 'partnershipItem.partnership.provider'],
      associationModels: ['direct_purchase']
    };

    try {
      const { data } = await productsApi.getArrangedProduct(payload);

      return data;
    } catch (error) {
      return error as AxiosError;
    }
  };

  const getArrangedProductDetailedSchedulings = async (
    arrangedProductId: string,
    since: string,
    until: string,
  ): Promise<DetailedScheduling[] | AxiosError> => {
    const payload: SchedulingsApiGetArrangedProductDetailedSchedulingsRequest = {
      arrangedProductId,
      appId: selectedAccount?.appId ?? '',
      include: ['finalTariffGroup.tariffs.type'],
      since,
      until,
    };

    try {
      const { data } = await schedulingsApi.getArrangedProductDetailedSchedulings(payload);

      return data;
    } catch (error) {
      return error as AxiosError;
    }
  };

  const getArrangedProductDetailedDefaultTariffGroup = async (
    arrangedProductId: string,
  ): Promise<DetailedTariffGroup | AxiosError> => {
    const payload: TariffGroupsApiGetArrangedProductDetailedDefaultTariffGroupRequest = {
      arrangedProductId,
      appId: selectedAccount?.appId ?? '',
    };

    try {
      const { data } = await tariffGroupsApi.getArrangedProductDetailedDefaultTariffGroup(payload);

      return data;
    } catch (error) {
      return error as AxiosError;
    }
  };

  const isRequestFailed = (error: AxiosError) => {
    if (error?.name === 'AxiosError' && !String(error?.response?.status ?? '').startsWith('2')) {
      return true;
    }

    return false;
  };

  const getUpdatedTariffs = (
    localCartItem: CartItemWithId,
    remoteTariff: DetailedTariffGroup,
    scheduleSup?: TariffScheduleUnitPrice,
  ) => {
    const tariffGroup: DetailedTariffGroup = remoteTariff;
    return localCartItem.tariffs.map((tariff) => {
      const currentTariff = tariffGroup.tariffs.find((t) => t.id === tariff.tariff.id);
      const finalTariff = currentTariff ? { ...tariff, tariff: currentTariff } : { ...tariff };

      if (scheduleSup) {
        for (const tariffSup of scheduleSup.tariffs) {
          if (tariffSup.tariffId === currentTariff?.id) {
            finalTariff.tariff.priceCents = tariffSup.priceCents;
            break;
          }
        }
      }

      return finalTariff;
    });
  };

  const revalidateCartItems = async (items: CartItemWithId[]) => {
    const revalidatedCartItems: CartItemWithId[] = [];

    for (const cartItem of items) {
      const res = await getArrangedProduct(cartItem.arrangedProduct.productId);

      // If the request fails, the item is not valid
      if (isRequestFailed(res as AxiosError)) {
        revalidatedCartItems.push({ ...cartItem, isValid: false });
        continue;
      }

      const updatedArrangedProduct = res as ArrangedProduct;

      // If the item is without date, the item is valid
      if (cartItem.arrangedProduct.product.sellingMode === SellingMode.WithoutDate) {
        const defaultTariffGroup = await getArrangedProductDetailedDefaultTariffGroup(
          cartItem.arrangedProduct.productId,
        );

        if (isRequestFailed(defaultTariffGroup as AxiosError)) {
          revalidatedCartItems.push({ ...cartItem, isValid: false });
          continue;
        }

        revalidatedCartItems.push({
          ...cartItem,
          isValid: true,
          arrangedProduct: updatedArrangedProduct,
          tariffs: getUpdatedTariffs(cartItem, defaultTariffGroup as DetailedTariffGroup),
        });

        continue;
      }

      // If the item scheduleDate is in the past, the item is not valid
      if (isBefore(startOfDay(new Date(cartItem.scheduleDate!)), startOfDay(new Date()))) {
        revalidatedCartItems.push({ ...cartItem, isValid: false });
        continue;
      }

      const productDetailedScheduling = await getArrangedProductDetailedSchedulings(
        cartItem.arrangedProduct.productId,
        cartItem.scheduleDate!,
        cartItem.scheduleDate!,
      );

      // If the request fails, the item is not valid
      if (isRequestFailed(productDetailedScheduling as AxiosError)) {
        revalidatedCartItems.push({ ...cartItem, isValid: false });
        continue;
      }

      const detailedSchedulings = productDetailedScheduling as DetailedScheduling[];

      // If the product has no detailed schedulings for the schedule date, the item is not valid
      if (!detailedSchedulings.length) {
        revalidatedCartItems.push({ ...cartItem, isValid: false });
        continue;
      }

      const isScheduleNotAvailable = cartItem.tariffs.some((tariff) => {
        const detailedScheduling = detailedSchedulings[0];

        const currentTariff = detailedScheduling.finalTariffGroup.tariffs.find((t) => t.id === tariff.tariff.id);

        // if the tariff in user local storage is not found,  the item is not valid
        if (!currentTariff) return true;

        if (cartItem.arrangedProduct.product.sellingMode === SellingMode.WithDateOnly) {
          const availableScheduleAmount =
            detailedScheduling.occupationRates?.scheduleQuantities[0]?.occupation?.availableAmount;

          // If the product has infinite available amount, the item is valid
          if (availableScheduleAmount === null) {
            return false;
          }

          // If the selected quantity is greater than the available amount, the item is not valid
          if (tariff.quantity > availableScheduleAmount) {
            return true;
          }
        }

        if (cartItem.arrangedProduct.product.sellingMode === SellingMode.WithDateAndTime) {
          const scheduleQuantity = detailedScheduling.occupationRates.scheduleQuantities.find(
            (s) => s.time === cartItem.scheduleTime,
          );

          if (!scheduleQuantity) {
            return true;
          }

          const availableAmount = scheduleQuantity?.occupation.availableAmount;

          // If the product has infinite available amount, the item is valid
          if (availableAmount === null) {
            return false;
          }

          return availableAmount !== null && tariff.quantity > availableAmount;
        }

        return false;
      });

      if (isScheduleNotAvailable) {
        revalidatedCartItems.push({ ...cartItem, isValid: false });

        continue;
      }

      const scheduleSup = detailedSchedulings[0].tariffScheduleUnitPrices.find(
        (tsup) => tsup.time === cartItem.scheduleTime,
      );

      revalidatedCartItems.push({
        ...cartItem,
        isValid: true,
        arrangedProduct: updatedArrangedProduct,
        tariffs: getUpdatedTariffs(cartItem, detailedSchedulings[0].finalTariffGroup, scheduleSup),
      });
    }

    return revalidatedCartItems;
  };

  const clearCart = () => {
    setCartItems([]);
  };

  const saveCartIntoLocalStorage = () => {
    const storedCartItems = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) ?? '[]') as Cart[];

    const appCartIndex = storedCartItems.findIndex((cart: Cart) => cart.appId === selectedAccount?.appId);

    if (appCartIndex !== -1) {
      storedCartItems[appCartIndex] = {
        ...storedCartItems[appCartIndex],
        items: cartItems,
      };
    } else {
      const objToSave = {
        checkoutId: checkoutId ?? uuid(),
        appId: selectedAccount!.appId!,
        items: cartItems,
      };
      storedCartItems.push(objToSave);
    }

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(storedCartItems));
  };

  const loadCartFromLocalStorage = async () => {
    setLoadingCart(true);
    const storedCartItems = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) ?? '[]') as Cart[];

    const appCartIndex = storedCartItems.findIndex((cart: Cart) => cart.appId === selectedAccount?.appId);

    if (appCartIndex !== -1) {
      const validatedItems = await revalidateCartItems(storedCartItems[appCartIndex].items);

      setCheckoutId(storedCartItems[appCartIndex].checkoutId);
      setCartItems(validatedItems);
    }

    setLoadingCart(false);
  };

  const removeCartFromLocalStorage = () => {
    const storedCartItems = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) ?? '[]') as Cart[];

    const appCartIndex = storedCartItems.findIndex((cart: Cart) => cart.appId === selectedAccount?.appId);

    if (appCartIndex !== -1) {
      storedCartItems.splice(appCartIndex, 1);
    }

    if (storedCartItems.length === 0) {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    } else {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(storedCartItems));
    }
  };

  useEffect(() => {
    loadCartFromLocalStorage();

    setLoadingCart(false);
  }, [selectedAccount?.appId]);

  useEffect(() => {
    if (cartItems.length === 0) {
      removeCartFromLocalStorage();
    } else {
      saveCartIntoLocalStorage();
    }
  }, [cartItems]);

  return (
    <PartnershipPortalCartContext.Provider
      value={{
        cartItems,
        loadingCart,
        checkoutId,
        addItemToCart,
        removeItemFromCart,
        calculateCartItemTotalPrice,
        calculateTotalPriceOfAllCartItems,
        getCartItemsSelectedQuantity,
        clearCart,
      }}
    >
      {children}
    </PartnershipPortalCartContext.Provider>
  );
};

export const PartnershipPortalCartRoutesProvider = () => {
  return (
    <PartnershipPortalCartProvider>
      <Outlet />
    </PartnershipPortalCartProvider>
  );
};
