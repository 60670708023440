import { useEffect, useRef, useState } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { Grid, Typography, Hidden, Box } from '@mui/material';

import useAnalytics from '@/hooks/analytics/useAnalytics';
import { useSellerApi } from '@/hooks/useSellerApi';
import useAccount from '@/hooks/useAccount';

import Button from '@/components/Button';

import { Account, AssociationModel, PublicPartnershipProposal } from '@/services/SellerApi';
import {
  DiscountExplanation,
  PartnershipName,
  ProposalPaper,
  ProviderLogo,
  ProviderName,
  StyledContainer,
  Benefit,
  BenefitList,
  BenefitListItem,
  BenefitsTitle,
  CtaTitle,
  CtaText,
  SubTitle,
  Title,
  AuthenticateText,
} from './styles';
import AuthenticationButtons from '@/components/AuthenticationButtons';
import useAuth from '@/contexts/Auth/hooks/useAuth';
import Modal from '@/components/Modal';
import Loading from '@/components/BackdropLoading';
import PartnershipItemList from '@/components/Partnership/ItemList';
import { Helmet } from 'react-helmet-async';
import useQueryString from '@/hooks/useQueryString';
import SideArtLayout from '@/layouts/SideArtLayout';
import HowItWorks from './HowItWorks';
import AccountSelector from '@/components/AccountSelector';
import { AuthStatus } from '@/interfaces/auth';

export const ProgramProposal = () => {
  const { code } = useParams();

  const modalRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { track } = useAnalytics();

  const { accounts } = useAccount();
  const { status } = useAuth();
  const { partnershipsApi } = useSellerApi();

  const { t } = useTranslation(['partnership']);

  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [proposal, setProposal] = useState<PublicPartnershipProposal | undefined>(undefined);
  const [authModalOpen, setAuthModalOpen] = useState<boolean>(false);
  const [accountSelectorOpen, setAccountSelectorOpen] = useState<boolean>(false);
  const [mainAssociationModel, setMainAssociationModel] = useState<AssociationModel | undefined>(undefined);

  const { autoJoinProposal } = useQueryString('autoJoinProposal');

  const getPublicProposal = async (shareCode: string) => {
    try {
      setLoading(true);
      const { data } = await partnershipsApi.getPublicPartnershipProposal({
        publicPartnershipProposalCode: shareCode,
        include: ['provider.info'],
      });

      setProposal(data);

      await getMainProgramAssociationModel(shareCode);
    } catch (error) {
      setError(t('proposal.proposal_error'));

      track('partnershipProposal/retrievePartnershipProposalFail', { proposalCode: shareCode, error });
    } finally {
      setLoading(false);
    }
  };

  const getMainProgramAssociationModel = async (shareCode: string) => {
    try {
      setLoading(true);
      const { data } = await partnershipsApi.getPublicPartnershipProposalItems({
        publicPartnershipProposalCode: shareCode,
        include: ['product'],
        limit: 1,
        offset: 0,
      });

      setMainAssociationModel(data[0].associationModels[0]);
    } catch (error) {
      enqueueSnackbar(t('item_list.could_not_load_association_model'));
    } finally {
      setLoading(false);
    }
  };

  const acceptProposal = async (proposal: PublicPartnershipProposal, account: Account) => {
    const eventMetadata = {
      proposalCode: code,
      proposalId: proposal.id,
      proposal,
      appId: account.app?.id,
    };
    track('partnershipProposal/acceptProposalRequest', eventMetadata);

    try {
      setLoading(true);

      await partnershipsApi.createAppArrangedPartnership({
        appId: account.app!.id,
        createArrangedPartnershipParams: { proposalId: proposal.id },
        include: ['provider.info', 'agent.info', 'publicProposal.provider.info'],
      });

      track('partnershipProposal/acceptProposalSuccess', eventMetadata);

      navigate(`/${account?.app?.code}/partnership/providers`);
    } catch (error) {
      enqueueSnackbar(t('proposal.ingress_proposal_error'), { variant: 'error' });

      track('partnershipProposal/acceptProposalFail', { ...eventMetadata, error });
    } finally {
      setLoading(false);
    }
  };

  const handleAcceptProposal = async () => {
    track('partnershipProposal/acceptProposalButtonClick', { proposalCode: code, proposalId: proposal?.id, proposal });

    if (status !== AuthStatus.AUTHENTICATED) {
      track('partnershipProposal/authenticationModalShow', { proposalCode: code, proposalId: proposal?.id, proposal });
      setAuthModalOpen(true);

      return;
    }

    if (accounts.length > 1) {
      track('partnershipProposal/AccountSelectionModalShow', {
        proposalCode: code,
        proposalId: proposal?.id,
        proposal,
      });
      setAccountSelectorOpen(true);
    } else {
      await acceptProposal(proposal!, accounts[0]);
    }
  };

  const onSelectAccount = async (account: Account) => {
    await acceptProposal(proposal!, account);

    setAccountSelectorOpen(false);
  };

  useEffect(() => {
    track('partnershipProposal/access', { proposalCode: code, proposal });

    if (code) {
      getPublicProposal(code);
    }
  }, []);

  useEffect(() => {
    if (autoJoinProposal && proposal && code) {
      handleAcceptProposal();
    }
  }, [proposal]);

  return (
    <SideArtLayout artContent={<HowItWorks associationModel={mainAssociationModel} />} showProvidedBy>
      <Helmet>
        <title>{t('proposal.page_title')}</title>
      </Helmet>

      <StyledContainer>
        <Grid container justifyContent='center' alignItems='center'>
          {error ? (
            <Grid item xs={12} sx={{ mt: 20 }}>
              <Typography variant='h5' align='center'>
                {error}
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={12} sm={10} md={12} lg={8}>
              <Hidden mdUp>
                <Title>{t('proposal.how_it_works.title')}</Title>

                <SubTitle>{t('proposal.how_it_works.subtitle')}</SubTitle>
              </Hidden>

              <ProposalPaper>
                {loading ? <Loading /> : null}

                <Grid container justifyContent='center' alignItems='center'>
                  <Grid item>
                    <ProviderLogo src={proposal?.provider?.logoUrl || ''} />
                  </Grid>

                  <Grid item xs={12}>
                    <ProviderName>{proposal?.provider?.name}</ProviderName>
                  </Grid>

                  {proposal?.name && proposal?.name !== '' ? (
                    <Grid item xs={12}>
                      <PartnershipName>{proposal?.name}</PartnershipName>
                    </Grid>
                  ) : null}

                  <Hidden mdUp>
                    <BenefitsTitle>{t('proposal.how_it_works.benefitsTitle')}</BenefitsTitle>

                    {mainAssociationModel === 'direct_purchase' ? (
                      <>
                        <BenefitList>
                          <BenefitListItem>
                            <Benefit>{t('proposal.how_it_works.direct_purchase_benefits.benefit_1')}</Benefit>
                          </BenefitListItem>
                          <BenefitListItem>
                            <Benefit>{t('proposal.how_it_works.direct_purchase_benefits.benefit_2')}</Benefit>
                          </BenefitListItem>
                          <BenefitListItem>
                            <Benefit>{t('proposal.how_it_works.direct_purchase_benefits.benefit_3')}</Benefit>
                          </BenefitListItem>
                          <BenefitListItem>
                            <Benefit>{t('proposal.how_it_works.direct_purchase_benefits.benefit_4')}</Benefit>
                          </BenefitListItem>
                        </BenefitList>
                      </>
                    ) : (
                      <>
                        <BenefitList>
                          <BenefitListItem>
                            <Benefit>{t('proposal.how_it_works.affiliation_benefits.benefit_1')}</Benefit>
                          </BenefitListItem>
                          <BenefitListItem>
                            <Benefit>{t('proposal.how_it_works.affiliation_benefits.benefit_2')}</Benefit>
                          </BenefitListItem>
                          <BenefitListItem>
                            <Benefit>{t('proposal.how_it_works.affiliation_benefits.benefit_3')}</Benefit>
                          </BenefitListItem>
                        </BenefitList>
                      </>
                    )}

                    <CtaTitle>{t('proposal.how_it_works.cta_title')}</CtaTitle>
                    <CtaText>
                      {t('proposal.how_it_works.cta_text_begin')}{' '}
                      <strong>{t('proposal.how_it_works.cta_text_ingress')}</strong>{' '}
                      {t('proposal.how_it_works.cta_text_end')}
                    </CtaText>
                  </Hidden>

                  {proposal?.maxPercentageDiscount ? (
                    <Grid item xs={12}>
                      <DiscountExplanation>{t('proposal.discount_explanation')}</DiscountExplanation>
                    </Grid>
                  ) : null}

                  <Grid item xs={12}>
                    <PartnershipItemList
                      proposal={proposal}
                      proposalShareCode={code}
                      columnsToRender={
                        mainAssociationModel == 'affiliation'
                          ? ['items', 'commission', 'maxPercentageDiscount']
                          : ['items']
                      }
                    />
                  </Grid>
                </Grid>

                <Box flexGrow={1}></Box>

                <Grid container>
                  <Grid item xs={12}>
                    <Button variant='contained' color='primary' fullWidth onClick={() => handleAcceptProposal()}>
                      {t('proposal.ingress_proposal')}
                    </Button>
                  </Grid>
                </Grid>
              </ProposalPaper>
            </Grid>
          )}
        </Grid>

        <Modal
          open={authModalOpen}
          onClose={() => setAuthModalOpen(false)}
          title={t('proposal.authenticate_title')}
          width={400}
        >
          <AuthenticateText>{t('proposal.authenticate_text')}</AuthenticateText>

          <AuthenticationButtons shouldReturn redirectTo={{ ...location, search: `autoJoinProposal=true` }} fullWidth />
        </Modal>

        <Modal
          open={accountSelectorOpen}
          onClose={() => setAccountSelectorOpen(false)}
          title={t('proposal.account_selection_title')}
          showCancelButton={false}
          showAcceptButton={false}
          width={775}
          ref={modalRef}
        >
          <AccountSelector
            parentRef={modalRef}
            onSelect={(account) => onSelectAccount(account)}
            navigateAfterSelect={false}
          />
        </Modal>
      </StyledContainer>
    </SideArtLayout>
  );
};

export default ProgramProposal;
