import { Box, Divider, IconButton, Paper } from '@mui/material';
import { SearchMd, XClose } from '@untitled-ui/icons-react';
import { ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from './styles';

type Props = {
  value?: string;
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  onSearch: () => void;
  onClear?: () => void;
};

export const SearchInput = (props: Props) => {
  const { t } = useTranslation(['ui']);

  const { placeholder = t('searchInput.placeholder'), value, onChange, onSearch, onClear } = props;

  return (
    <Paper elevation={0} sx={{ borderRadius: '4px', overflow: 'hidden', flex: 1 }}>
      <Input
        fullWidth
        value={value}
        autoComplete='cc-csc'
        onChange={onChange}
        placeholder={placeholder}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSearch();
          }
        }}
        InputProps={{
          endAdornment: (
            <Box display='flex' alignItems='center' gap={1}>
              {value ? (
                <IconButton onClick={onClear}>
                  <XClose />
                </IconButton>
              ) : undefined}

              <Box display='flex' alignItems='center' gap={1}>
                <Divider sx={{ height: 28 }} orientation='vertical' />

                <IconButton onClick={onSearch} {...(value ? { color: 'primary' } : {})}>
                  <SearchMd />
                </IconButton>
              </Box>
            </Box>
          ),
        }}
      />
    </Paper>
  );
};

export default SearchInput;
