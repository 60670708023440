import { PublicPartnershipItem, PartnershipCommissionType } from '@/services/SellerApi';

export const calculatePriceBasedOnThePartnershipCommission = (
  price: number,
  partnershipItem: PublicPartnershipItem,
) => {
  const { commissionType, commissionAmountCents, commissionPercentageFee } = partnershipItem;

  let discount = 0;

  if (commissionType === PartnershipCommissionType.AmountCents) {
    if (!commissionAmountCents) {
      throw new Error('For commissionType equal amount_cents, commissionAmountCents is required');
    }

    discount = commissionAmountCents!;
  }

  if (commissionType === PartnershipCommissionType.PercentageFee) {
    if (commissionPercentageFee === 0) {
      return price;
    }

    if (!commissionPercentageFee) {
      throw new Error('For commissionType equal percentage_fee, commissionPercentageFee is required');
    }

    discount = price * commissionPercentageFee;
  }

  return price - discount;
};

export const precisePercentageMultiplication = (value: number) => {
  const percentageValue = value * 100;

  const decimalPlaces = value.toString().split('.')[1]?.length || 0;

  return parseFloat(percentageValue.toFixed(decimalPlaces));
};
