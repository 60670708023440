import 'react-international-phone/style.css';

import { InputAdornment } from '@mui/material';
import { usePhoneInput } from 'react-international-phone';

import { DdiSelector } from './DdiSelector';
import Input, { InputProps } from '../Form/Input';
import { countries } from '@/common/phoneInput';

interface PhoneInputProps extends Omit<InputProps, 'onChange' | 'value'> {
  value?: string;
  onChange?: (value: string) => void;
}

const PhoneInput = (props: PhoneInputProps) => {
  const { value, onChange, ...rest } = props;

  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
    value,
    defaultCountry: 'br',
    countries: countries,
    onChange: (data) => {
      onChange?.(data.inputValue);
    },
  });

  return (
    <Input
      variant='outlined'
      color='primary'
      value={inputValue}
      onChange={handlePhoneValueChange}
      type='tel'
      inputRef={inputRef}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start' sx={{ marginRight: '2px', marginLeft: '-8px' }}>
            <DdiSelector country={country} onChange={setCountry} />
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};

export default PhoneInput;
