import { Button as MUIButton, buttonClasses, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const unsafeProps: PropertyKey[] = ['Icon'];

const Button = (props: ButtonProps) => <StyledButton {...props} />;

const StyledButton = styled(MUIButton, {
  shouldForwardProp: (prop) => !unsafeProps.includes(prop),
})(({ theme }) => ({
  padding: '10px',
  borderRadius: '4px',
  textTransform: 'none',
  fontWeight: 400,
  fontSize: '16px',
  minWidth: 'auto',
  height: '40px',

  [`&.${buttonClasses.colorSecondary}`]: {
    color: theme.palette.colors.primary[200],
    borderColor: theme.palette.colors.primary[50],
    [`&.${buttonClasses.disabled}`]: {
      backgroundColor: theme.palette.colors.primary[50],
    },
  },

  [`&.${buttonClasses.outlined}`]: {
    borderColor: theme.palette.colors.primary[500],
    [`&.${buttonClasses.colorSecondary}`]: {
      borderColor: theme.palette.colors.primary[500],
      color: theme.palette.colors.primary[500],
    },
    [`&.${buttonClasses.disabled}`]: {
      cursor: 'not-allowed !important',
      borderColor: theme.palette.colors.primary[50],
      color: theme.palette.text.disabled,
      backgroundColor: theme.palette.colors.primary[200],
      [`&.${buttonClasses.colorSecondary}`]: {
        color: theme.palette.colors.primary[200],
        backgroundColor: theme.palette.colors.primary[50],
      },
    },
  },

  [`&.${buttonClasses.sizeSmall}`]: {
    padding: '0.625rem 1rem',
    fontSize: '1rem',
  },
}));

export default Button;
