import { Chip as MuiChip, chipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

type ChipProps = {
  fontColor?: string;
  backgroundColor?: string;
};

const unsafeProps: PropertyKey[] = ['fontColor', 'backgroundColor'];

export const StyledChip = styled(MuiChip, {
  shouldForwardProp: (prop) => !unsafeProps.includes(prop),
})<ChipProps>(({ theme, fontColor, backgroundColor }) => ({
  background: theme.palette.colors.blue[100],
  border: 'none',
  lineHeight: '12px',
  color: fontColor,
  backgroundColor: backgroundColor,

  [`&.${chipClasses.sizeSmall} .${chipClasses.label}`]: {
    padding: '0.1875rem 0.5rem',
    ...theme.typography.extraSmallRegular,
  },
}));
