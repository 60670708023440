import { MenuItem, Typography } from '@mui/material';
import { CountryIso2, defaultCountries, FlagImage, parseCountry, ParsedCountry } from 'react-international-phone';

import { Select } from './styles';

interface Props {
  country: ParsedCountry;
  onChange: (country: CountryIso2) => void;
}

export const DdiSelector = ({ country, onChange }: Props) => {
  return (
    <Select
      MenuProps={{
        sx: {
          height: '300px',
          width: '360px',
          top: '10px',
          left: '-34px',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
      value={country.iso2}
      onChange={(e) => onChange(e.target.value as CountryIso2)}
      renderValue={(value) => <FlagImage iso2={value as CountryIso2} style={{ display: 'flex' }} />}
    >
      {defaultCountries.map((c) => {
        const country = parseCountry(c);
        return (
          <MenuItem key={country.iso2} value={country.iso2}>
            <FlagImage iso2={country.iso2} style={{ marginRight: '8px' }} />
            <Typography marginRight='8px'>{country.name}</Typography>
            <Typography color='gray'>+{country.dialCode}</Typography>
          </MenuItem>
        );
      })}
    </Select>
  );
};
