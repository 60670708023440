import { Dispatch, SetStateAction } from 'react';
import { Grid, Box, Link } from '@mui/material';
import { SwitchHorizontal01 } from '@untitled-ui/icons-react';
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Visibility } from '@mui/icons-material';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { formatCents } from '@/utils';
import Table from '@/components/Table';
import { RefundRequest, SaleStateEnum } from '@/services/SellerApi';
import { StyledChip } from '@/components/Partnership/StatusChip/style';
import ValueWithLabel from '@/components/ValueWithLabel';
import MobileMenu from './MobileMenu';
import ExtendedGridActionsCellItem from '@/components/ExtendedGridActionsCellItem';
import useAccount from '@/hooks/useAccount';

type Status = SaleStateEnum | 'denied' | 'approved';

type Props = {
  totalRefunds: number;
  data: RefundRequest[];
  loading: boolean;
  paginationModel: {
    page: number;
    pageSize: number;
  };
  setPaginationModel: Dispatch<
    SetStateAction<{
      page: number;
      pageSize: number;
    }>
  >;
};

const RefundTable = (props: Props) => {
  const { totalRefunds, data, loading, paginationModel, setPaginationModel } = props;

  const { hasPermission } = useAccount();

  const navigate = useNavigate();
  const { t } = useTranslation(['refundRequests']);

  const handleGoToSale = (saleId: string) => {
    navigate(`../sales/${saleId}`);
  };

  const handleRequestRefund = (saleId: string) => {
    navigate(`create/${saleId}`);
  };

  const convertStatus = (status: Status) => {
    switch (status) {
      case SaleStateEnum.Created:
      case SaleStateEnum.Pending:
        return 'info';
      case SaleStateEnum.Expired:
      case SaleStateEnum.PaymentVoided:
        return 'warning';
      case SaleStateEnum.PaymentComplete:
      case 'approved':
        return 'success';
      case SaleStateEnum.PaymentChargeback:
      case SaleStateEnum.Canceled:
      case 'denied':
        return 'error';
    }
  };

  const getActions = (refund: RefundRequest) => [
    <ExtendedGridActionsCellItem
      key='sale-view'
      href={`../sales/${refund.saleId}`}
      component={Link}
      icon={<Visibility />}
      label={t('table.actions.view_sale')}
      showInMenu
    />,
    ...(refund.status === 'denied' && hasPermission('refundRequests', 'CreateSaleRefundRequest')
      ? [
          <GridActionsCellItem
            key='sale-request-refund'
            icon={<SwitchHorizontal01 />}
            onClick={() => handleRequestRefund(refund.sale!.id)}
            label={t('table.actions.request_refund')}
            showInMenu
          />,
        ]
      : []),
  ];

  const columns: GridColDef[] = [
    {
      flex: 1,
      field: 'sale.code',
      headerName: t('table.sale.code'),
      valueGetter: (params: GridValueGetterParams) => params.row.sale.code,
      sortable: false,
    },
    {
      field: 'sale.customer',
      headerName: t('table.sale.customers'),
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.sale.customer.firstName} ${params.row.sale.customer.lastName}`,
      sortable: false,
      flex: 1,
    },
    {
      field: 'sale.currentState',
      headerName: t('table.sale.sale_status'),
      renderCell: (params: GridRenderCellParams) => (
        <StyledChip
          label={t(`table.sale.status.${params.row.sale.currentState}`)}
          color={convertStatus(params.row.sale.currentState)}
          size='small'
        />
      ),
      flex: 1,
      sortable: false,
    },
    {
      field: 'status',
      headerName: t('table.refund.title'),
      renderCell: (params: GridRenderCellParams) => (
        <StyledChip
          label={t(`table.refund.status.${params.row.status}`)}
          color={convertStatus(params.row.status)}
          size='small'
        />
      ),
      sortable: false,
      flex: 1,
    },
    {
      field: 'sale.amountCents',
      headerName: t('table.sale.sale_amount_cents'),
      valueGetter: (params: GridValueGetterParams) => formatCents(params.row.sale.amountCents ?? 0),
      sortable: false,
      flex: 1,
    },
    {
      field: 'sale.aggregatedVouchers',
      headerName: t('table.sale.aggregation_voucher'),
      valueGetter: (params: GridValueGetterParams) => {
        const { consumedVouchersCount, vouchersCount } = params.row.sale.aggregatedVouchers;

        return `${consumedVouchersCount ?? 0}/${vouchersCount ?? 0}`;
      },
      sortable: false,
      flex: 1,
    },
    {
      field: 'updatedAt',
      headerName: t('table.last_update'),
      valueGetter: (params: GridValueGetterParams) => format(new Date(params.row.updatedAt), 'dd/MM/yyyy'),
      sortable: false,
      flex: 1,
    },
    {
      field: 'actions',
      headerName: t('table.actions.title'),
      type: 'actions',
      sortable: false,
      getActions: (params: GridRowParams) => getActions(params.row),
    },
  ];

  return (
    <Grid item xs={12} paddingX={{ xs: 2, sm: 0 }}>
      <Table
        rows={data}
        rowCount={totalRefunds}
        columns={columns}
        paginationModel={paginationModel}
        paginationMode='server'
        disableColumnMenu
        onPaginationModelChange={(newPaginationModel) => setPaginationModel(newPaginationModel)}
        loading={loading}
        renderMobile={({ row }) => (
          <Grid container spacing={1} alignItems='center'>
            <Grid item xs={12}>
              <Box display='flex' alignItems='center' justifyContent='space-between'>
                <Box display='flex' alignItems='center' gap={2}>
                  <ValueWithLabel title='#' value={row.sale.code} />

                  <ValueWithLabel
                    title={t('table.sale.customers')}
                    value={`${row.sale.customer.firstName} ${row.sale.customer.lastName}`}
                  />
                </Box>

                <MobileMenu
                  onRequestRefund={() => handleRequestRefund(row.saleId)}
                  onViewRefund={() => handleGoToSale(row.saleId)}
                  isAvailableForRefund={row.status === 'denied'}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box display='flex' alignItems='center' gap={2}>
                <ValueWithLabel
                  title={t('table.sale.sale_status')}
                  value={
                    <StyledChip
                      label={t(`table.sale.status.${row.sale.currentState}`)}
                      color={convertStatus(row.sale.currentState)}
                      size='small'
                    />
                  }
                />

                <ValueWithLabel
                  title={t('table.refund.title')}
                  value={
                    <StyledChip
                      label={t(`table.refund.status.${row.status}`)}
                      color={convertStatus(row.status)}
                      size='small'
                    />
                  }
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box display='flex' alignItems='center' gap={2}>
                <ValueWithLabel
                  title={t('table.sale.sale_amount_cents')}
                  value={formatCents(row.sale.amountCents ?? 0)}
                />

                <ValueWithLabel
                  title={t('table.sale.aggregation_voucher')}
                  value={`${row.sale?.aggregatedVouchers?.consumedVouchersCount ?? 0}/${
                    row.sale?.aggregatedVouchers?.vouchersCount ?? 0
                  }`}
                />

                <ValueWithLabel
                  title={t('table.last_update')}
                  value={format(new Date(row.updatedAt), 'dd/MM/yyyy - HH:mm')}
                />
              </Box>
            </Grid>
          </Grid>
        )}
      />
    </Grid>
  );
};

export default RefundTable;
