import { Fragment } from 'react';
import ModalOnDesktopDrawerOnMobile from '@/components/Modal/ModalOnDesktopDrawerOnMobile';
import { DirectSale, DirectSaleTransaction } from '@/services/SellerApi';
import { Box } from '@mui/material';
import PixPaymentForm from '../PixPaymentForm';
import CardPaymentForm from '../CardPaymentForm';
import PaymentSuccessMessage from '@/pages/ShowCaseCheckoutPage/SaleList/SaleCard/PaymentSuccessMessage';
import { useTranslation } from 'react-i18next';
import PixIcon from '@/assets/Icons/pix-01.svg';
import CreditCardIcon from '@/assets/Icons/credit-card-02.svg';
import { IconReceipt } from '@tabler/icons-react';
import PostPaidForm from '../PostPaidForm';

export interface PaymentModalsProps {
  directSale?: DirectSale;
  openPaymentSuccess: boolean;
  openPixPaymentModal: boolean;
  openCreditCardPaymentModal: boolean;
  openPostPaidPaymentModal: boolean;
  missingPaymentCount: number;
  onClosePaymentSuccess: () => void;
  onClosePixPaymentModal: () => void;
  onCloseCreditCardPaymentModal: () => void;
  onClosePostPaidPaymentModal: () => void;
  handlePaymentSuccess: (transaction: DirectSaleTransaction) => void;
}
export const PaymentModals = (props: PaymentModalsProps) => {
  const {
    directSale,
    openPaymentSuccess,
    openPixPaymentModal,
    handlePaymentSuccess,
    openCreditCardPaymentModal,
    openPostPaidPaymentModal,
    onClosePaymentSuccess,
    onClosePixPaymentModal,
    onCloseCreditCardPaymentModal,
    onClosePostPaidPaymentModal,
    missingPaymentCount,
  } = props;

  const { t } = useTranslation(['payment']);

  return (
    <Fragment>
      <ModalOnDesktopDrawerOnMobile
        width={455}
        anchor='bottom'
        isOpen={openPixPaymentModal}
        title={
          <Box display='flex' alignItems='flex-start'>
            <img src={PixIcon} style={{ marginRight: '16px', marginTop: '8px' }} />
            {t('modals.pix.title')}
          </Box>
        }
        onClose={onClosePixPaymentModal}
      >
        {directSale ? (
          <PixPaymentForm
            saleId={directSale.id}
            onSuccess={(transaction) => {
              handlePaymentSuccess(transaction);
            }}
            onCloseErrorModal={onClosePixPaymentModal}
          />
        ) : null}
      </ModalOnDesktopDrawerOnMobile>

      <ModalOnDesktopDrawerOnMobile
        width={455}
        anchor='bottom'
        isOpen={openCreditCardPaymentModal}
        title={
          <Box display='flex' alignItems='flex-start'>
            <img src={CreditCardIcon} style={{ marginRight: '16px', marginTop: '3px' }} />
            {t('modals.credit_card.title')}
          </Box>
        }
        onClose={onCloseCreditCardPaymentModal}
      >
        {directSale ? (
          <CardPaymentForm saleId={directSale.id} onSuccess={(transaction) => handlePaymentSuccess(transaction)} />
        ) : null}
      </ModalOnDesktopDrawerOnMobile>

      <ModalOnDesktopDrawerOnMobile
        width={455}
        anchor='bottom'
        isOpen={openPostPaidPaymentModal}
        title={
          <Box display='flex' alignItems='flex-start'>
            <IconReceipt size='64px' style={{ marginRight: '16px', marginTop: '3px' }} />

            {t('modals.post_paid.title')}
          </Box>
        }
        onClose={onClosePostPaidPaymentModal}
      >
        {directSale ? (
          <PostPaidForm
            saleId={directSale.id}
            directSale={directSale}
            onSuccess={(transaction) => {
              handlePaymentSuccess(transaction);
            }}
            onCancel={onClosePostPaidPaymentModal}
          />
        ) : null}
      </ModalOnDesktopDrawerOnMobile>

      <ModalOnDesktopDrawerOnMobile
        width={455}
        anchor='bottom'
        isOpen={openPaymentSuccess}
        title={
          <Box display='flex' alignItems='flex-start'>
            {t('modals.success.title')}
          </Box>
        }
        onClose={onClosePaymentSuccess}
      >
        {directSale ? (
          <PaymentSuccessMessage
            saleId={directSale.id}
            missingPaymentCount={missingPaymentCount}
            onFinishPurchases={onClosePaymentSuccess}
          />
        ) : null}
      </ModalOnDesktopDrawerOnMobile>
    </Fragment>
  );
};
