import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAnalytics from '@/hooks/analytics/useAnalytics';
import { Alert, AlertTitle, Grid } from '@mui/material';
import { DirectSale, DirectSaleTransaction } from '@/services/SellerApi';
import { SaleCard } from './SaleCard';
import Button from '@/components/Button';
import { IconArrowRight } from '@tabler/icons-react';
import useAccount from '@/hooks/useAccount';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DirectSalesCreationResult } from '..';

type DirectSaleWithTransaction = {
  sale: DirectSale;
  transaction?: DirectSaleTransaction;
};

type Props = {
  salesResults: DirectSalesCreationResult[];
};

export const SaleList = (props: Props) => {
  const { salesResults } = props;

  const { track } = useAnalytics();
  const { t } = useTranslation(['showCaseCheckout', 'sales']);

  const { selectedAccount } = useAccount();

  const [sales, setSales] = useState<DirectSaleWithTransaction[]>([]);

  const loadSales = () => {
    const salesWithPaymentOptions = salesResults
      .filter((saleResult) => saleResult.status === 'fulfilled')
      .map((saleResult) => {
        return {
          sale: saleResult.value!,
        };
      });

    setSales(salesWithPaymentOptions);

    track('showCaseCheckoutPage/salesGenerated', { sales: salesWithPaymentOptions });
  };

  const failures = salesResults.filter((saleResult) => saleResult.status === 'rejected');

  const handleOnPayment = (saleId: string, transaction: DirectSaleTransaction) => {
    const updatedSales = sales.map((sale) => {
      if (sale.sale.id === saleId) {
        return {
          ...sale,
          transaction,
        };
      }

      return sale;
    });

    setSales(updatedSales);
  };

  const missingPaymentCount = sales.filter((sale) => !sale.transaction).length;

  useEffect(() => {
    loadSales();
  }, []);

  return (
    <Grid container spacing={2} padding={{ xs: 2, md: 3, lg: 4 }}>
      {missingPaymentCount ? (
        <Grid item xs={12}>
          <Alert severity='warning' icon={<InfoOutlinedIcon />}>
            <AlertTitle>{t('payment.missing_purchases_title', { count: missingPaymentCount })}</AlertTitle>

            {t('payment.missing_purchases_description')}
          </Alert>
        </Grid>
      ) : null}

      {failures.map((failure) => (
        <Grid key={failure.items[0].id} item xs={12}>
          <Alert severity='error'>
            <AlertTitle>
              {t('failure.title', {
                providerName: failure.items[0].arrangedProduct?.partnershipItem?.partnership?.provider?.name ?? '',
              })}
            </AlertTitle>

            {t('failure.message')}
            <ul>
              {failure.error!.errors?.map((error, index) => (
                <li key={index}>{t(`creation_errors.${error.type}`, { ns: 'sales' })}</li>
              ))}
            </ul>
          </Alert>
        </Grid>
      ))}

      {sales.map((sale) => (
        <Grid key={sale.sale.id} item xs={12}>
          <SaleCard saleId={sale.sale.id} onPayment={handleOnPayment} missingPaymentCount={missingPaymentCount} />
        </Grid>
      ))}

      <Grid item xs={12}>
        <Button
          variant='contained'
          color='primary'
          href={`/${selectedAccount?.app?.code}/partnership/directSales`}
          endIcon={<IconArrowRight />}
        >
          {t('payment.go_to_my_purchases')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default SaleList;
