interface WeekDaysFunction {
  (returnDates?: boolean, date?: Date, format?: Intl.DateTimeFormatOptions): string[] | Date[];
}

const generateWeekDays: WeekDaysFunction = (returnDates = false, date, format) => {
  const defaultFormat = { weekday: 'short' } as Intl.DateTimeFormatOptions;
  const now = date || new Date();
  const dayOfWeek = now.getDay();

  const diffToSunday = dayOfWeek % 7;
  const sunday = new Date(now);
  sunday.setDate(now.getDate() - diffToSunday);

  const dates = Array.from({ length: 7 }, (_, i) => {
    const date = new Date(sunday);
    date.setDate(sunday.getDate() + i);
    return date;
  });

  if (returnDates) {
    return dates as Date[];
  }

  return dates.map((date) => date.toLocaleDateString('default', format ?? defaultFormat)) as string[];
};

export default generateWeekDays;
