import { ReactNode } from 'react';

import { Icon, SxProps, Typography } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { StyledListItemButton } from './styles';
import { NavLink } from 'react-router-dom';
import useUI from '@/hooks/useUI';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import { AnalyticsEvents } from '@/hooks/analytics/enum/analyticsEvents';

interface MenuItemProps {
  to?: string;
  text: string;
  icon?: ReactNode;
  level?: number;
  target?: string;
  isActive?: boolean;
  onlyShowIcon?: boolean;
  track?: AnalyticsEvents;
  onClick?: () => void;
  sx?: SxProps;
}

export default function MenuItem(props: MenuItemProps) {
  const { track: triggerTrack } = useAnalytics();

  const {
    to,
    text,
    icon = <Icon />,
    level = 1,
    target = '_self',
    isActive = false,
    onlyShowIcon = false,
    track,
    onClick = () => {},
    sx,
  } = props;

  const { shouldCloseDrawerAfterNavigate, setDrawerOpen } = useUI();

  const handleListItemClick = () => {
    if (shouldCloseDrawerAfterNavigate) {
      setDrawerOpen(false);
    }
    if (track) {
      triggerTrack(track);
    }
    onClick();
  };

  return (
    <StyledListItemButton
      to={to ?? '#'}
      selected={isActive}
      level={level}
      target={target}
      component={NavLink}
      onlyShowIcon={onlyShowIcon}
      role='button'
      onClick={handleListItemClick}
      sx={sx}
    >
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: onlyShowIcon ? 3 : 'auto',
          justifyContent: onlyShowIcon ? 'center' : 'initial',
        }}
      >
        {icon}
      </ListItemIcon>

      <ListItemText primary={<Typography variant='smallSemiBold'>{text}</Typography>} />
    </StyledListItemButton>
  );
}
