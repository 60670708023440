import { Fragment, useCallback, useMemo } from 'react';

import { StyledAvailabilitySubItens } from '@/features/agenda/components/AvailabilitySubItens/styles';
import { Additional, Availability, Product, ProductTariffType, Tariff } from '@/services/SellerApi';

import AvailabilitySubItem from './AvailabilitySubItem/index';

type Item = {
  id: string | number;
  type: string;
  content: ProductTariffType | Tariff | Additional | Product;
};

const AvailabilitySubItens = (props: { availability: Availability }) => {
  const { availability } = props;
  const listOfItens = useMemo(() => {
    const itens: Item[] = [];

    availability.products?.forEach((product) => {
      itens.push({ id: `product-${product.id}`, content: product, type: 'product' });
    });

    availability.additionals?.forEach((additional) => {
      itens.push({ id: `additional-${additional.id}`, content: additional, type: 'additional' });
    });

    availability.tariffs?.forEach((tariff) => {
      itens.push({ id: `tariff-${tariff.id}`, content: tariff, type: 'tariff' });
    });

    availability.productTariffTypes?.forEach((productTariffType, index) => {
      itens.push({
        id: productTariffType.tariffType?.name
          ? `productTariffType-${index}-${productTariffType.tariffType?.name}-${productTariffType.product?.name}`
          : `productTariffType-${productTariffType.tariffType?.id}`,
        type: 'productTariffType',
        content: productTariffType,
      });
    });

    return itens;
  }, [availability]);

  const itemRender = useCallback((item: Item) => {
    if (item.type === 'product') {
      return <AvailabilitySubItem product={item.content as Product} />;
    }
    if (item.type === 'additional') {
      return <AvailabilitySubItem additional={item.content as Additional} />;
    }
    if (item.type === 'tariff') {
      return <AvailabilitySubItem tariff={item.content as Tariff} />;
    }
    if (item.type === 'productTariffType') {
      return <AvailabilitySubItem productTariffType={item.content as ProductTariffType} />;
    }
    return null;
  }, []);

  return (
    <StyledAvailabilitySubItens>
      {listOfItens.map((item) => (
        <Fragment key={item.id}>{itemRender(item)}</Fragment>
      ))}
    </StyledAvailabilitySubItens>
  );
};

export default AvailabilitySubItens;
