import { Grid, Stack } from '@mui/material';
import { forwardRef, Fragment, Ref, useEffect, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Checkbox from '@/components/Form/Checkbox';
import Input from '@/components/Form/Input';
import SelectInputTime from '@/features/agenda/features/schedulingForm/components/SelectInputTime';
import { SchedulingFields } from '@/features/agenda/features/schedulingForm/types/formTypes';
import { Product, SellingMode } from '@/services/SellerApi';
import theme from '@/theme';
import intervalTimeOptions from '@/features/agenda/utils/intervalTimeOptions';
import useAccount from '@/hooks/useAccount';
import formatNumberToTimeString from '../../../utils/formatNumberToTimeString';
import getMinutesHoursDaysFromMinutes from '@/features/agenda/utils/getMinutesHoursDaysFromMinutes';

type Props = {
  product: Product;
  form: UseFormReturn<SchedulingFields, unknown, undefined>;
  isEdit: boolean;
};
const ReservationForm = forwardRef((props: Props, ref: Ref<HTMLDivElement>) => {
  const { product, form, isEdit } = props;

  const { t } = useTranslation(['scheduling-form']);
  const { selectedAccount } = useAccount();

  const { control, formState, watch, setValue } = form;

  const antecedenceTimeWatch = watch('antecedenceTime');

  const interval = useMemo(
    () => selectedAccount?.app?.minuteSetInterval || 30,
    [selectedAccount?.app?.minuteSetInterval],
  );

  const fullTimeOptions = useMemo(() => {
    return intervalTimeOptions(interval, true, true);
  }, [interval]);

  const minimumAdvancePurchaseOptions = intervalTimeOptions(30);

  const { errors } = formState;

  const isProductDateOnly = useMemo(() => product?.sellingMode === SellingMode.WithDateOnly, [product]);

  const getLastReferenceTime = () => {
    const options = intervalTimeOptions(interval);
    const lastOption = options[options.length - 2];
    return lastOption;
  };

  const handleChangeAntecedenceTime = (checked: boolean) => {
    if (!checked) {
      setValue('antecedenceHours', 0);
      setValue('antecedenceDays', 0);
      setValue('antecedenceMinutes', 0);
    }

    if (isProductDateOnly) {
      setValue('antecedenceHoursReferenceTime', getLastReferenceTime());
    } else {
      setValue('antecedenceHoursReferenceTime', undefined);
    }
  };

  const handleKeyDownAntecedenceTime = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === '.') {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (!product) return;

    if (!isEdit) {
      setValue(
        'durationMinutes',
        product?.durationMinutes ? formatNumberToTimeString(product?.durationMinutes) : fullTimeOptions[0],
      );

      if ((product.antecedenceMinutes ?? 0) > 0) {
        setValue('antecedenceTime', true);

        const { days, hours, minutes } = getMinutesHoursDaysFromMinutes(product?.antecedenceMinutes || 0);
        setValue('antecedenceDays', days);
        setValue('antecedenceHours', hours);
        setValue('antecedenceMinutes', minutes);
      }
    }
  }, [product]);

  return (
    <Fragment>
      <Grid item xs={12} md={4}>
        <Controller
          name='durationMinutes'
          control={control}
          render={({ field: { value = null, ...rest } }) => (
            <SelectInputTime
              label={t('form.reservation.duration')}
              options={fullTimeOptions}
              tooltipTitle={t('form.reservation.duration_tooltip')}
              errors={errors}
              value={value}
              {...rest}
              ref={ref}
            />
          )}
        />
      </Grid>
      {!isProductDateOnly && (
        <Grid item xs={12} md={4}>
          <Controller
            name='calendarIntervalMinutes'
            control={control}
            render={({ field: { value = null, ...rest } }) => (
              <SelectInputTime
                label={t('form.reservation.calendar_interval')}
                options={fullTimeOptions}
                tooltipTitle={t('form.reservation.calendar_interval_tooltip')}
                value={value}
                errors={errors}
                {...rest}
                ref={ref}
              />
            )}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Stack sx={{ gap: '5px' }}>
          <Controller
            name='antecedenceTime'
            defaultValue={false}
            control={control}
            render={({ field: { value, onChange, ...rest } }) => (
              <Checkbox
                label={t('form.reservation.minimum_advance_purchase')}
                checked={value}
                tooltipProps={{
                  title: t('form.reservation.minimum_advance_purchase_tooltip'),
                  placement: 'top',
                }}
                onChange={(e) => {
                  onChange(e.target.checked);
                  handleChangeAntecedenceTime(e.target.checked);
                }}
                {...rest}
              />
            )}
          />
        </Stack>
      </Grid>

      {antecedenceTimeWatch ? (
        <Grid item xs={12} md={4}>
          <Stack
            sx={{
              flexDirection: 'row',
              gap: theme.spacing(2),
              alignItems: 'flex-start',
              height: '100%',
            }}
          >
            <Controller
              name='antecedenceDays'
              defaultValue={0}
              control={control}
              render={({ field: { name, ...rest } }) => (
                <Input
                  type='number'
                  name={name}
                  size='medium'
                  label={t('form.reservation.antecedence_time_label.days')}
                  error={!!errors[name]}
                  helperText={errors[name]?.message as string}
                  onKeyDown={handleKeyDownAntecedenceTime}
                  {...rest}
                  ref={ref}
                />
              )}
            />

            <Controller
              name='antecedenceHours'
              defaultValue={0}
              control={control}
              render={({ field: { name, ...rest } }) => (
                <Input
                  type='number'
                  label={t('form.reservation.antecedence_time_label.hours')}
                  name={name}
                  size='medium'
                  error={!!errors[name]}
                  helperText={errors[name]?.message as string}
                  {...rest}
                  onKeyDown={handleKeyDownAntecedenceTime}
                  ref={ref}
                />
              )}
            />

            <Controller
              name='antecedenceMinutes'
              defaultValue={0}
              control={control}
              render={({ field: { name, ...rest } }) => (
                <Input
                  type='number'
                  label={t('form.reservation.antecedence_time_label.minutes')}
                  name={name}
                  size='medium'
                  error={!!errors[name]}
                  helperText={errors[name]?.message as string}
                  onKeyDown={handleKeyDownAntecedenceTime}
                  {...rest}
                  ref={ref}
                />
              )}
            />
          </Stack>
        </Grid>
      ) : null}

      {antecedenceTimeWatch && isProductDateOnly ? (
        <Grid item xs={12} md={4}>
          <Stack sx={{ flexDirection: 'row', gap: theme.spacing(2) }}>
            <Controller
              name='antecedenceHoursReferenceTime'
              control={control}
              render={({ field: { value = null, ...rest } }) => (
                <SelectInputTime
                  label={t('form.reservation.minimum_time_purchase_label')}
                  size='medium'
                  options={minimumAdvancePurchaseOptions}
                  value={value}
                  errors={errors}
                  {...rest}
                  ref={ref}
                />
              )}
            />
          </Stack>
        </Grid>
      ) : null}
    </Fragment>
  );
});

export default ReservationForm;
