import { styled } from '@mui/material';

import Input from '@/components/Form/Input';

export const AmountInput = styled(Input)(() => ({
  MozAppearance: 'textfield',

  '& ::-webkit-inner-spin-button, & ::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
}));
