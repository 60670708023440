import { AuthAccessTokenKey } from '@/contexts/Auth/constants/accessTokenKey';
import { AccessToken } from '@/services/sellerApiQuery/model';

class AccessTokenManager {
  private static instance: AccessTokenManager | null = null;
  private tokenKey = AuthAccessTokenKey;
  private expiryKey = '@planne/tokenExpiry';

  private constructor() {}

  public static getInstance(): AccessTokenManager {
    if (!AccessTokenManager.instance) {
      AccessTokenManager.instance = new AccessTokenManager();
    }
    return AccessTokenManager.instance;
  }

  public setToken(accessToken: AccessToken): void {
    const { token, expiresAt } = accessToken;
    const expiryTime = new Date(expiresAt).getTime();
    localStorage.setItem(this.tokenKey, token);
    localStorage.setItem(this.expiryKey, expiryTime.toString());
  }

  public getToken(): string | null {
    const expiryTime = parseInt(localStorage.getItem(this.expiryKey) || '0', 10);
    if (new Date().getTime() > expiryTime) {
      this.clearToken();
      return null;
    }
    return localStorage.getItem(this.tokenKey);
  }

  public clearToken(): void {
    localStorage.removeItem(this.tokenKey);
    localStorage.removeItem(this.expiryKey);
  }
}

const accessTokenManager = AccessTokenManager.getInstance();
export default accessTokenManager;
