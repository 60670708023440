import { Box, Link, Stack, Typography } from '@mui/material';
import { Suspense, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IconPlus } from '@tabler/icons-react';

import Alert from '@/components/Alert';
import Loading from '@/components/Loading';
import useLoadRelatedAvailabilitiesQuery from '@/features/agenda/queries/useLoadRelatedAvailabilitiesQuery';
import { Availability, AvailabilityTypeEnum, Scheduling } from '@/services/SellerApi';
import theme from '@/theme';
import { serializeToQueryString } from '@/utils';
import removeDuplicates from '@/utils/removeDuplicates';
import getRightSchedulingTariffGroupFromApi from '@/features/agenda/utils/getRightSchedulingTariffGroupFromApi';
import SeatMapCard from './SeatMapCard';
import { useGetSchedulingSeatMapOverview } from '@/services/sellerApiQuery/api/seat-maps/seat-maps';
import Button from '@/components/Button';

// const AvailabilityCardLazy = lazy(() => import('./AvailabilityCard'));
import AvailabilityCardLazy from './AvailabilityCard';

type Props = {
  scheduling: Scheduling;
};

const SchedulingAvailabilityRelated = (props: Props) => {
  const { scheduling } = props;
  const [availabilitiesQuery] = useLoadRelatedAvailabilitiesQuery();
  const { data: availabilities, isLoading } = availabilitiesQuery(scheduling.id || '', true);
  const { t } = useTranslation(['agenda'], { keyPrefix: 'modal.scheduling.availability-related' });

  const deduplicatedAvailabilities = useMemo(
    () => removeDuplicates<Availability>(availabilities || [], 'id'),
    [availabilities],
  );

  const seatMapId = useMemo(() => getRightSchedulingTariffGroupFromApi(scheduling)?.seatMapId, [scheduling]);
  const hasAvailability = useMemo(() => deduplicatedAvailabilities?.length > 0, [deduplicatedAvailabilities]);
  const showNoAvailabilityAlert = useMemo(() => !hasAvailability && !seatMapId, [hasAvailability, seatMapId]);

  const { data: seatMapOverview, isLoading: isLoadingSeatMapOverview } = useGetSchedulingSeatMapOverview(
    props.scheduling.id,
    {
      scheduleTime: props.scheduling.consumableFromTime ?? '',
    },
  );

  const newAvailabilityParams = useMemo(() => {
    const params = {
      type: AvailabilityTypeEnum.Products,
      productIds: scheduling.productId,
      beginDate: scheduling.appliesAt,
      fromTime: scheduling.consumableFromTime ?? undefined,
      toTime: scheduling.consumableToTime ?? undefined,
      recurrence: scheduling.recurrence ? JSON.stringify(scheduling.recurrence) : undefined,
      calendarIntervalMinutes: scheduling?.calendarIntervalMinutes
        ? String(scheduling.calendarIntervalMinutes)
        : undefined,
    };

    return serializeToQueryString(params);
  }, [scheduling]);

  return (
    <Stack sx={{ paddingTop: theme.spacing(4) }}>
      <Stack sx={{ paddingY: '0.5rem', borderBottom: `1px solid ${theme.palette.grey[300]}` }}>
        <Typography variant='regularBold' data-testid='modal-title'>
          {t('title')}
        </Typography>
      </Stack>

      {isLoading ? (
        <Stack sx={{ marginTop: theme.spacing(3), alignItems: 'center' }}>
          <Loading showLabel={true} />
        </Stack>
      ) : null}

      {seatMapOverview && !isLoadingSeatMapOverview ? <SeatMapCard seatMapOverview={seatMapOverview} /> : null}

      {hasAvailability && !isLoading ? (
        <Suspense fallback={<Loading />}>
          <Stack sx={{ paddingTop: theme.spacing(3), gap: theme.spacing(4) }}>
            <Button
              fullWidth
              color='primary'
              variant='outlined'
              startIcon={<IconPlus />}
              href={`../availabilities/new?${newAvailabilityParams}`}
            >
              {t('new_availability_button')}
            </Button>

            {deduplicatedAvailabilities?.map((availability: Availability) => (
              <AvailabilityCardLazy key={availability.id} availability={availability} />
            ))}
          </Stack>
        </Suspense>
      ) : null}

      {showNoAvailabilityAlert && !isLoading ? (
        <Alert severity='warning' sx={{ marginTop: theme.spacing(3), borderRadius: '0.5rem' }}>
          <Stack sx={{ gap: theme.spacing(2) }}>
            <Typography variant='regularBold'>{t('no-availability.title')}</Typography>
            <Box>
              <Trans
                i18nKey={t('no-availability.description')}
                components={{
                  anchor: <Link href={`../availabilities/new?${newAvailabilityParams}`}>EMPTY</Link>,
                }}
              />
            </Box>
          </Stack>
        </Alert>
      ) : null}
    </Stack>
  );
};

export default SchedulingAvailabilityRelated;
