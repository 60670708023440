import theme from '@/theme';
import { Box, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ModalOnDesktopDrawerOnMobile from './ModalOnDesktopDrawerOnMobile';
import { ReactNode } from 'react';
import Button from '@/components/Button';

type Props = {
  children?: ReactNode;
  onConfirm: () => void;
  onClose: () => void;
  loading?: boolean;
  isOpen: boolean;
  title: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
};
export const ConfirmationModal = (props: Props) => {
  const { loading = false, onClose, onConfirm, children, isOpen, title, confirmButtonText, cancelButtonText } = props;

  const { t } = useTranslation(['ui']);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ModalOnDesktopDrawerOnMobile isOpen={isOpen} onClose={onClose} title={title}>
      {children}

      <Box display='flex' alignItems='center' gap={1} flexDirection='row'>
        <Button color='primary' variant='contained' fullWidth={isMobile} disabled={loading} onClick={onConfirm}>
          {confirmButtonText ?? t('modal.yes')}
        </Button>

        <Button color='primary' variant='outlined' onClick={onClose} fullWidth={isMobile} disabled={loading}>
          {cancelButtonText ?? t('modal.no')}
        </Button>
      </Box>
    </ModalOnDesktopDrawerOnMobile>
  );
};

export default ConfirmationModal;
