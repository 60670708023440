import { AxiosResponse } from 'axios';

import { RequiredError } from '@/services/SellerApi/base';

type BasePayload = {
  limit?: number;
  offset?: number;
};

type Params<P extends BasePayload, T> = {
  apiMethod: (payload: P, options?: { signal: AbortSignal }) => Promise<AxiosResponse<T[]>>;
  initialPayload?: Partial<P>;
  pageSize: number;
  requestDelay?: number;
  signal: AbortSignal;
};

const createPaginatedRequest = async <T, P extends BasePayload>(params: Params<P, T>): Promise<T[] | undefined> => {
  const { apiMethod, initialPayload, pageSize, signal, requestDelay } = params;
  let delay = 0;
  const delayIncrement = requestDelay ?? 0;

  const payload: P = {
    ...initialPayload,
    limit: pageSize,
    offset: 0,
  } as P;

  try {
    const { data, headers } = await apiMethod({ ...payload, offset: 0, limit: 1 } as P);

    const totalCount = parseInt(headers['x-pagination-total-count'] ?? '0');
    const totalPages = Math.ceil(totalCount / pageSize);

    const promises = [];

    for (let page = 0; page < totalPages; page++) {
      promises.push(
        new Promise<AxiosResponse<T[]>>((resolve) => {
          setTimeout(() => {
            resolve(
              apiMethod(
                {
                  ...payload,
                  offset: page * pageSize,
                } as P,
                { signal },
              ),
            );
          }, delay);
          delay += delayIncrement;
        }),
      );
    }

    const dataArray = await Promise.all(promises);

    const allPages = dataArray.reduce((accumulator: T[], response) => {
      return accumulator.concat(response.data);
    }, data);

    return allPages;
  } catch (error) {
    console.error(error);
    return error instanceof RequiredError ? [] : undefined;
  }
};

export default createPaginatedRequest;
