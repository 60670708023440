import { get } from 'lodash';
import { Grid } from '@mui/material';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import { Control, Controller, FieldErrors, useFieldArray, useWatch } from 'react-hook-form';

import theme from '@/theme';
import Button from '@/components/Button';
import Input from '@/components/Form/Input';
import useBreakpoints from '@/hooks/useBreakpoints';
import { TYPE_OF_TIMES } from '@/features/agenda/features/availability/utils/constants';
import { CreateUpdateAvailabilityParams } from '@/features/agenda/features/availability/types/formTypes';

import TimeSelectors from '../TimeSelectors';
import parseTimeStringToHourAndMinutes from '@/features/agenda/utils/parseTimeStringToHourAndMinutes';
import { Container, ReplicateQuantity, TimesAndQuantityWrapper } from './styles';

interface Props {
  timeOptions: string[];
  endTimeOptions: string[];
  formValues: ReturnType<typeof useWatch<CreateUpdateAvailabilityParams>>;
  errors: FieldErrors<CreateUpdateAvailabilityParams>;
  control: Control<CreateUpdateAvailabilityParams, unknown>;
  isEdit: boolean;
  onReplicateQuantity: () => void;
}

const DynamicTime = (props: Props) => {
  const { control, endTimeOptions, errors, formValues, timeOptions, isEdit, onReplicateQuantity } = props;

  const { t } = useTranslation(['availability']);
  const { isMobile, isTablet } = useBreakpoints();

  const { fields, append, remove } = useFieldArray({ control, name: 'timesAndQuantities' });

  const isUniqueTime = useMemo(() => formValues.timeType === TYPE_OF_TIMES.unique, [formValues.timeType]);

  const addNewTimeAndQuantity = () => {
    const lastIdx = fields.length - 1;

    const lastTime = formValues.timesAndQuantities![lastIdx];

    const [fromTimeHour, fromTimeMinutes] = parseTimeStringToHourAndMinutes(lastTime.fromTime as string);
    const [toTimeHour, toTimeMinutes] = parseTimeStringToHourAndMinutes(lastTime.toTime as string);

    let nextHour = 2 * toTimeHour - fromTimeHour;
    let nextMinutes = 2 * toTimeMinutes - fromTimeMinutes;

    while (nextMinutes >= 60) {
      nextHour += 1;
      nextMinutes -= 60;
    }

    if (nextMinutes < 0) {
      nextHour -= 1;
      nextMinutes += 60;
    }

    if (nextHour >= 24) {
      nextHour = 23;
      nextMinutes = 59;
    }

    const guessNextToTime = `${String(nextHour).padStart(2, '0')}:${String(nextMinutes).padStart(2, '0')}`;

    const newToTimeIdx = endTimeOptions.indexOf(guessNextToTime);
    const newToTimeIdxFallback = endTimeOptions.indexOf(lastTime.toTime as string) + 1;

    append({
      quantity: lastTime.quantity!,
      fromTime: lastTime.toTime!,
      toTime: endTimeOptions[newToTimeIdx >= 0 ? newToTimeIdx : newToTimeIdxFallback],
    });
  };

  return (
    <Fragment>
      {fields.map((item, itemIdx) => (
        <Container direction='column' key={item.id} marginBottom={itemIdx === fields.length - 1 ? 0 : 4}>
          <TimesAndQuantityWrapper direction={{ xs: 'column', md: 'row' }}>
            <TimeSelectors
              errors={errors}
              fieldItem={item}
              control={control}
              fieldIndex={itemIdx}
              timeOptions={timeOptions}
              endTimeOptions={endTimeOptions}
              isEdit={isEdit}
            />

            <Controller
              name={`timesAndQuantities[${itemIdx}].quantity`}
              control={control}
              render={({ field: { onChange, value = '', name, ...rest } }) => (
                <Input
                  type='number'
                  fullWidth
                  name={name}
                  value={value}
                  label={t('form.quantity')}
                  onChange={({ target }) => onChange(target.value)}
                  error={!!get(errors, name)}
                  helperText={get(errors, name)?.message as string}
                  onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                  {...rest}
                />
              )}
            />

            <Button
              color='secondary'
              variant='outlined'
              fullWidth={isTablet || isMobile}
              disabled={isUniqueTime || fields.length === 1 || isEdit}
              startIcon={<IconTrash size={24} color='currentColor' />}
              onClick={() => (fields.length > 1 ? remove(itemIdx) : undefined)}
              sx={{
                ...(!isMobile && !isTablet ? { alignSelf: 'flex-start', marginTop: theme.spacing(8) } : {}),
              }}
            >
              {t('form.exclude_button_label')}
            </Button>
          </TimesAndQuantityWrapper>

          {itemIdx === 0 && Boolean(formValues?.timesAndQuantities?.[0]?.quantity) ? (
            <ReplicateQuantity variant='smallSemiBold' onClick={onReplicateQuantity}>
              {t('form.replicate_quantity')}
            </ReplicateQuantity>
          ) : null}
        </Container>
      ))}

      <Grid item xs={12}>
        <Button
          color='secondary'
          variant='outlined'
          onClick={addNewTimeAndQuantity}
          disabled={isUniqueTime || isEdit}
          startIcon={<IconPlus color='currentColor' />}
        >
          {t('form.add_times_and_quantity_button_label')}
        </Button>
      </Grid>
    </Fragment>
  );
};

export default DynamicTime;
