import { useSearchParams } from 'react-router-dom';

export function useRouteQueryParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const setUrlParams = (param: Record<string, string>) => {
    const currentParams = new URLSearchParams(searchParams);
    Object.entries(param).forEach(([key, value]) => {
      value ? currentParams.set(key, value) : currentParams.delete(key);
    });

    setSearchParams(currentParams);
  };

  const deleteUrlParams = (param: string[]) => {
    const currentParams = new URLSearchParams(searchParams);
    param.forEach((key) => {
      currentParams.delete(key);
    });

    setSearchParams(currentParams);
  };

  const updateUrlParams = (param: Record<string, string | string[] | undefined>, oldKeys?: string[]) => {
    const currentParams = new URLSearchParams(searchParams);

    Object.keys(param).forEach((key) => {
      const values = currentParams.getAll(key);
      if (values.length > 1) {
        values.forEach((_) => {
          currentParams.delete(key);
        });
      }
    });

    // Make params value unique
    Object.entries(param).forEach(([key, value]) => {
      const newValue = Array.isArray(value)
        ? value.reduce((acc, v) => (acc.includes(v) ? acc : [...acc, v]), [] as string[])
        : value;
      currentParams.set(key, newValue as unknown as string);
    });

    Object.entries(param).forEach(([key, value]) => {
      value ? currentParams.set(key, value as unknown as string) : currentParams.delete(key);
    });

    if (oldKeys) {
      oldKeys.filter((key) => !Object.keys(param).includes(key)).forEach((key) => currentParams.delete(key));
    }

    setSearchParams(currentParams);
  };

  return {
    searchParams,
    setUrlParams,
    deleteUrlParams,
    updateUrlParams,
  };
}

export default useRouteQueryParams;
