import { Additional } from '@/services/SellerApi';
import { Divider, Paper, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import Chip from '@/components/Chip';
import Checkbox from '@/components/Form/Checkbox';
import { AdditionalsContainer } from './styles';
import { Fragment } from 'react';
import { ProductWithItems } from '../ProductItemPicker';

interface Props {
  product: ProductWithItems<Additional, Additional>;
  additionals: Additional[];
  selectedAdditionals?: Additional[];
  onClick?: (additional: Additional, product: ProductWithItems<Additional, Additional>) => void;
  disabled?: boolean;
}

const AdditionalCard = (props: Props) => {
  const { product, additionals, selectedAdditionals = [], onClick = () => {}, disabled = false } = props;

  const isAdditionalSelected = (additional: Additional) => {
    return selectedAdditionals.some((selectedAdditional) => selectedAdditional.id === additional.id);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    additional: Additional,
    product: ProductWithItems<Additional, Additional>,
  ) => {
    event.preventDefault();

    if (!disabled) {
      onClick(additional, product);
    }
  };

  return (
    <Paper component={Stack} elevation={3} sx={{ p: '0.5rem' }}>
      <Stack flexDirection='row' justifyContent='space-between' alignItems='center' sx={{ mb: '0.5rem' }}>
        <Typography variant='smallBold'>{product.internalName ?? product.name}</Typography>
      </Stack>

      <AdditionalsContainer>
        {additionals?.map((additional, index) => (
          <Fragment key={`additional-card-${product.id}-${additional.id}`}>
            <Checkbox
              disableRipple
              key={additional.id}
              checked={isAdditionalSelected(additional)}
              label={<Chip size='small' label={additional.title} />}
              onClick={(e) => handleClick(e, additional, product)}
              disabled={disabled}
            />

            {index < additionals.length - 1 ? <Divider sx={{ my: '0.5rem' }} /> : null}
          </Fragment>
        ))}
      </AdditionalsContainer>
    </Paper>
  );
};

export default AdditionalCard;
