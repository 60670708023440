import { useState } from 'react';

import { ContentCopy } from '@mui/icons-material';
import { Button, IconButton, Tooltip } from '@mui/material';

import { copyToClipboard } from '@/utils';
import { useTranslation } from 'react-i18next';

type CopyToClipboardButtonProps = {
  text: string;
  showText?: boolean;
  buttonSize?: 'small' | 'medium' | 'large';
  iconSize?: 'small' | 'medium' | 'large';
  color?: 'primary' | 'inherit' | 'default' | 'error' | 'success' | 'info' | 'warning' | 'secondary';
  onCopy?: () => void;
};

export const CopyToClipboardButton = (props: CopyToClipboardButtonProps) => {
  const { text, showText, buttonSize = 'small', iconSize = 'medium', color = 'primary', onCopy } = props;

  const { t } = useTranslation(['ui']);

  const [showTooltip, setShowTooltip] = useState(false);

  const handleButtonClick = () => {
    copyToClipboard(text);

    onCopy?.();

    setShowTooltip(true);

    setTimeout(() => {
      setShowTooltip(false);
    }, 2000);
  };

  return (
    <Tooltip title={t('copy_to_clipboard')} placement='top' arrow>
      <Tooltip
        open={showTooltip}
        title={t('copied_to_clipboard')}
        leaveDelay={1500}
        placement='top'
        onClose={() => setShowTooltip(false)}
        arrow
      >
        {showText ? (
          <Button onClick={() => handleButtonClick()} startIcon={<ContentCopy />} sx={{ textTransform: 'none' }}>
            {t('copy_to_clipboard')}
          </Button>
        ) : (
          <IconButton
            aria-label='toggle password visibility'
            onClick={() => handleButtonClick()}
            edge='end'
            color={color}
            size={buttonSize}
          >
            <ContentCopy fontSize={iconSize} />
          </IconButton>
        )}
      </Tooltip>
    </Tooltip>
  );
};

export default CopyToClipboardButton;
