import { StyledAvailabilityCardProps } from '@/features/agenda/components/SchedulingCalendar/styles';
import { Link, Stack, styled } from '@mui/material';

const StyledCardRoot = styled(Link)(({ theme }) => ({
  display: 'flex',
  textDecoration: 'none',
  flexDirection: 'column',
  padding: theme.spacing(2),
  gap: theme.spacing(1),
  borderRadius: theme.radius.sm,
  backgroundColor: theme.palette.colors.white[100],
  color: 'inherit',
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: `0px 45px 12px 0px rgba(87, 87, 87, 0.00), 0px 29px 11px 0px rgba(87, 87, 87, 0.01), 0px 16px 10px 0px rgba(87, 87, 87, 0.05), 0px 7px 7px 0px rgba(87, 87, 87, 0.09), 0px 2px 4px 0px rgba(87, 87, 87, 0.10)`,
}));

const StyledCardTitleText = styled('h3', {
  shouldForwardProp: (prop) => prop !== 'full',
})<StyledAvailabilityCardProps>(({ theme, full = false }) => ({
  ...theme.typography.extraSmallBold,
  color: theme.palette.colors.primary[700],
  margin: 0,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%',

  ...(full && {
    color: theme.palette.colors.error[600],
  }),
}));

const StyledCardTitleOccupation = styled(StyledCardTitleText)(() => ({
  width: 'auto',
  textOverflow: 'unset',
  overflow: 'unset',
}));

const StyledCardItems = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
  margin: 0,
}));

const StyledCardItem = styled('li')(({ theme }) => ({
  ...theme.typography.extraSmallRegular,
  display: 'flex',
  listStyle: 'none',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  width: '100%',
}));

export const StyledCard = {
  Root: StyledCardRoot,
  Title: {
    Text: StyledCardTitleText,
    Occupation: StyledCardTitleOccupation,
  },
  Items: StyledCardItems,
  Item: StyledCardItem,
};
