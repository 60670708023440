import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const Message = styled(Typography)(({ theme }) => ({
  marginBottom: '24px',
  fontSize: '1rem',
  color: theme.palette.colors.gray[800],
  fontWeight: '400',
  lineHeight: '19px',
}));
