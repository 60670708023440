import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { Fragment, ReactNode, useEffect, useMemo } from 'react';
import { Hidden, Stack, Typography } from '@mui/material';

import { Header } from './style';
import Loading from '@/components/Loading';
import EmptyPage from '@/components/EmptyPage';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import SaleStatusChip from '@/features/sales/components/SaleStatusChip';
import ReceiptDesktopView from '@/features/sales/components/Receipt/ReceiptDesktopView';
import ReceiptMobileView from '@/features/sales/components/Receipt/ReceiptMobileView';
import { formatCents } from '@/utils';
import Tooltip from '@/components/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import theme from '@/theme';
import { uniqBy } from 'lodash';
import { useSaleDetails } from '@/features/sales/hook/useSaleDetails';

export interface ReceiptPriceSummary {
  title: ReactNode;
  value: ReactNode;
}

export const Receipt = () => {
  const { track } = useAnalytics();
  const { t } = useTranslation(['receipt']);
  const { sale, refetchSale, saleReceiptData } = useSaleDetails();
  const { data: saleItems = [], isLoading: loading } = saleReceiptData;

  const { order, coupon } = sale;
  const decisiveTransaction = order?.decisiveTransaction || sale.decisiveTransaction;

  const priceSummary = useMemo(() => {
    const saleItemsSubtotal = saleItems.reduce((acc, item) => acc + item.priceCents, 0);

    return [
      {
        title: t('table.body.sale.items_subtotal'),
        value: formatCents(saleItemsSubtotal),
      },
      ...(coupon
        ? [
            {
              title: t('table.body.sale.coupon_discount.title'),
              value: (
                <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                  (-) {formatCents(coupon.discountedAmountCents)}
                  <Tooltip
                    title={t(`table.body.sale.coupon_discount.tooltip.${coupon.usageType}`, {
                      productsName: uniqBy(coupon.products, 'id')
                        .map((product) => product.product?.internalName ?? product.product?.name)
                        .join(', '),
                    })}
                    width={190}
                  >
                    <HelpOutlineIcon
                      fontSize='small'
                      sx={{ color: theme.palette.colors.gray[400], cursor: 'pointer', ml: 0.5 }}
                    />
                  </Tooltip>
                </Typography>
              ),
            },
          ]
        : []),

      ...(decisiveTransaction?.paymentMethodDiscountPercentage
        ? [
            {
              title: t('table.body.sale.payment_method_discount'),
              value: `(-) ${formatCents(
                (decisiveTransaction.paymentMethodDiscountPercentage / 100) * sale.partialAmountCents,
              )}`,
            },
          ]
        : []),

      ...(decisiveTransaction?.paymentMethodInterestRateCents
        ? [
            {
              title: t('table.body.sale.credit_card_interest'),
              value: `(+) ${formatCents(decisiveTransaction.paymentMethodInterestRateCents)}`,
            },
          ]
        : []),
    ];
  }, [saleItems, decisiveTransaction, coupon]);

  useEffect(() => {
    track('Receipt/access', { saleId: sale.id });
  }, []);

  return (
    <Fragment>
      {loading ? (
        <Stack mt={4}>
          <Loading />
        </Stack>
      ) : null}

      {!loading && sale && saleItems ? (
        <Stack spacing={3} mt={{ xs: 2, md: 1 }} px={{ xs: 2, sm: 0 }}>
          <Stack spacing={1}>
            <Header direction='row'>
              <Typography variant='h6'>{t('header.title')}</Typography>

              <SaleStatusChip status={sale.currentState} label={t(`status.${sale.currentState}`, { ns: 'sales' })} />
            </Header>
          </Stack>

          <Hidden mdDown>
            <ReceiptDesktopView priceSummary={priceSummary} sale={sale} saleItems={saleItems} />
          </Hidden>

          <Hidden mdUp>
            <ReceiptMobileView priceSummary={priceSummary} sale={sale} saleItems={saleItems} />
          </Hidden>
        </Stack>
      ) : null}

      {!loading && !sale ? (
        <Stack mt={4}>
          <EmptyPage description={t('empty_page.description')} />
        </Stack>
      ) : null}

      <Outlet context={{ ...sale, saleId: sale.id, onSuccess: refetchSale }} />
    </Fragment>
  );
};

export default Receipt;
