import { Fragment } from 'react';
import { get } from 'lodash';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import Select from '@/components/Form/Select';
import { useBreakpoints } from '@/hooks/useBreakpoints';
import { CreateUpdateAvailabilityParams } from '@/features/agenda/features/availability/types/formTypes';

interface TimeSelectorProps {
  errors: FieldErrors<CreateUpdateAvailabilityParams>;
  control: Control<CreateUpdateAvailabilityParams, unknown>;
  timeOptions: string[];
  endTimeOptions: string[];
  fieldIndex: number;
  fieldItem: Record<'id', string>;
  isEdit: boolean;
}

const TimeSelectors = (props: TimeSelectorProps) => {
  const { errors, control, timeOptions, endTimeOptions, fieldIndex, isEdit } = props;

  const { t } = useTranslation(['availability']);
  const { isMobile, isTablet } = useBreakpoints();

  const selectors = (
    <Fragment>
      <Controller
        name={`timesAndQuantities[${fieldIndex}].fromTime`}
        control={control}
        render={({ field: { onChange, value, name, ...rest } }) => (
          <Select
            name={name}
            label={t('form.from_time')}
            options={timeOptions}
            fullWidth
            disableClearable
            value={value}
            onChange={(_, value) => onChange(value)}
            error={!!get(errors, name)}
            helperText={get(errors, name)?.message as string}
            disabled={isEdit}
            aria-disabled={isEdit}
            {...rest}
          />
        )}
      />

      <Controller
        name={`timesAndQuantities[${fieldIndex}].toTime`}
        control={control}
        render={({ field: { onChange, value, name, ...rest } }) => (
          <Select
            name={name}
            label={t('form.to_time')}
            options={endTimeOptions}
            fullWidth
            disableClearable
            value={value}
            error={!!get(errors, name)}
            onChange={(_, value) => onChange(value)}
            helperText={get(errors, name)?.message as string}
            disabled={isEdit}
            aria-disabled={isEdit}
            {...rest}
          />
        )}
      />
    </Fragment>
  );

  if (isTablet || isMobile) {
    return (
      <Stack width='100%' direction='row' gap={4} alignItems='flex-start'>
        {selectors}
      </Stack>
    );
  }

  return selectors;
};

export default TimeSelectors;
