import { styled } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';

export const StyledListItemButton = styled(ListItemButton, { shouldForwardProp: (prop) => prop !== 'onlyShowIcon' })<{
  onlyShowIcon?: boolean;
}>(({ theme, onlyShowIcon }) => ({
  color: '#CCCCCC',
  height: 56,
  position: 'relative',

  borderLeftColor: theme.palette.secondary[600],
  borderLeftWidth: 0,
  borderLeftStyle: 'solid',

  justifyContent: onlyShowIcon ? 'initial' : 'center',
  ...(onlyShowIcon && {
    '&:hover': {
      background: theme.palette.primary[500],
    },
  }),

  transition: theme.transitions.create(['border-left-width', 'padding-left'], {
    easing: theme.transitions.easing.easeIn,
    duration: 50,
  }),

  '& .MuiListItemIcon-root': {
    color: '#CCCCCC',
    minWidth: 38,
  },

  '&.Mui-selected, .Mui-selected:hover': {
    '&.MuiListItemButton-root:hover': {
      backgroundColor: theme.palette.secondary.main,
    },

    '& .MuiListItemIcon-root': {
      color: '#FFFFFF',
    },

    color: '#FFFFFF',
    borderLeftWidth: 4,
    paddingLeft: 12,
    backgroundColor: theme.palette.secondary.main,
  },

  ...(onlyShowIcon && {
    '&:hover::before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      right: 0,
      transform: 'translateY(-50%)',
      borderStyle: 'solid',
      borderWidth: '12px 16px 12px 0',
      borderColor: `transparent ${theme.palette.primary[600]} transparent transparent`,
    },
  }),
}));

export const NestedList = styled(List, { shouldForwardProp: () => true })(({ theme }) => ({
  backgroundColor: theme.palette.primary[600],
}));
