import { css, styled } from '@mui/material/styles';
import { Stepper as MUIStepper, Step, StepLabel } from '@mui/material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

export const StyledStepConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    left: '-50%',
    right: '50%',
    top: '50%',
    transform: 'translate(0,-50%)',
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 10,
    background: theme.palette.colors.blue[100],
    border: `1px solid ${theme.palette.colors.blue[500]}`,
  },
}));

export const StepCircle = styled('div')<{
  completed?: boolean;
  active?: boolean;
}>(({ theme, active }) => ({
  backgroundColor: theme.palette.colors.blue[100],
  color: theme.palette.colors.blue[500],
  height: 32,
  width: 32,
  display: 'flex',
  borderRadius: '50%',
  border: `1px solid ${theme.palette.colors.blue[500]}`,
  justifyContent: 'center',
  alignItems: 'center',

  ...(active && {
    background: theme.palette.colors.blue[500],
    color: 'white',
    height: 40,
    width: 40,
    fontSize: 23,
    lineHeight: '36px',
    display: 'inline',
    textAlign: 'center',
  }),
}));

export const StyledStepLabel = styled(StepLabel)(
  () => css`
    & .MuiStepLabel-iconContainer {
      z-index: 1;
    }
  `,
);

export const StyledStep = styled(Step)(
  () => css`
    flex: none;
    padding: 0 8px;
  `,
);
export const StyledStepper = styled(MUIStepper)(
  () => css`
    justify-content: center;
    align-items: center;
  `,
);
