import { Box, Stack, Typography } from '@mui/material';
import { IconCircleFilled, IconEdit, IconEye } from '@tabler/icons-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { StyledAction } from '@/features/agenda/components/SchedulingCalendar/AgendaDayPopover/AgendaDayPopoverContent/AgendaDayPopoverList/AgendaDayPopoverSlot/styles';
import { StyledSlotTypeBase } from '@/features/agenda/components/SchedulingCalendar/AgendaSlot/styles';
import useAccount from '@/hooks/useAccount';
import { PolicyRuleNamespaceEnum, Scheduling } from '@/services/SellerApi';
import isSchedulingForAllDay from '@/utils/isSchedulingForAllDay';
import getRightSchedulingTariffGroupFromApi from '@/features/agenda/utils/getRightSchedulingTariffGroupFromApi';

type Props = {
  scheduling: Scheduling;
};

const PopoverSchedulingSlot = (props: Props) => {
  const { scheduling } = props;
  const { hasPermission } = useAccount();
  const { search } = useLocation();
  const { t } = useTranslation(['agenda']);

  const getReferenteEndTime = useMemo(() => {
    if (isSchedulingForAllDay(scheduling)) {
      return t('all_day');
    }
    if (scheduling.consumableFromTime && scheduling.consumableToTime) {
      return `${scheduling.consumableFromTime} - ${scheduling.consumableToTime}`;
    }
  }, [scheduling]);

  const name = useMemo(() => scheduling.product?.internalName ?? scheduling.product?.name, [scheduling.product]);
  const tariffGroupName = useMemo(() => getRightSchedulingTariffGroupFromApi(scheduling)?.name, [scheduling]);

  return (
    <StyledSlotTypeBase sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
      <Stack sx={{ maxWidth: 'calc(100% - 60px)' }}>
        <Typography
          variant='smallBold'
          sx={{
            display: 'flex',
            gap: '0.25rem',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          <Box
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {name}
          </Box>
        </Typography>

        <Typography
          variant='extraSmallRegular'
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: '0.25rem',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          <Stack sx={{ whiteSpace: 'nowrap' }}>{getReferenteEndTime}</Stack>
          {tariffGroupName ? <IconCircleFilled width='0.3125rem' height='0.3125rem' /> : null}
          <Stack sx={{ flex: 1, minWidth: 0 }}>
            <Box
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {tariffGroupName}
            </Box>
          </Stack>
        </Typography>
      </Stack>

      {hasPermission(PolicyRuleNamespaceEnum.Schedulings, 'GetScheduling') ? (
        <Stack
          sx={{
            minHeight: 40,
            minWidth: 56,
            flexDirection: 'row',
            alignItems: 'center',
            gap: '0.5rem',
          }}
        >
          <StyledAction className='actions' href={`./schedulings/${scheduling.id}${search}`}>
            <IconEye />
          </StyledAction>
          <StyledAction href={`./schedulings/${scheduling.id}/edit`} className='actions'>
            <IconEdit />
          </StyledAction>
        </Stack>
      ) : null}
    </StyledSlotTypeBase>
  );
};

export default PopoverSchedulingSlot;
