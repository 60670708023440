import { ChipOwnProps } from '@mui/material';

import { StyledChip } from './styles';

export type ChipProps = Omit<ChipOwnProps, 'color'> & {
  color?: {
    font?: string;
    background?: string;
  };
};

export const Chip = (props: ChipProps) => {
  const { color, ...rest } = props;
  return <StyledChip fontColor={color?.font} backgroundColor={color?.background} {...rest} />;
};

export default Chip;
