import { XClose } from '@untitled-ui/icons-react';
import { Grid, Typography, Box, Drawer, SxProps } from '@mui/material';

import { colors } from '@/theme';
import Button from '@/components/Button';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  onClose: () => void;
  open: boolean;
  title?: ReactNode;
  anchor?: 'bottom' | 'left' | 'right' | 'top';
  showCloseButton?: boolean;
  slotProps?: {
    ModalPaper?: {
      sx?: SxProps;
    };
    Content: {
      sx?: SxProps;
    };
  };
};

type PreviewItemProps = {
  title: ReactNode;
  content: string | (() => JSX.Element | string);
  sx?: SxProps;
};
export const PreviewItem = (props: PreviewItemProps) => (
  <Box
    gap={1}
    padding='4px'
    display='flex'
    alignItems='center'
    sx={{ ...props.sx }}
    justifyContent='space-between'
    className='preview-item'
  >
    <Typography variant='smallRegular' color={colors.gray[700]}>
      {props.title}
    </Typography>

    {typeof props.content === 'function' ? (
      props.content()
    ) : (
      <Typography variant='smallSemiBold' color={colors.gray[700]}>
        {props.content}
      </Typography>
    )}
  </Box>
);

const PreviewDrawer = (props: Props) => {
  const { children, onClose, open, title, anchor = 'bottom', showCloseButton = true, slotProps } = props;

  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={onClose}
      sx={{ overflow: 'scroll', zIndex: 9999, ...slotProps?.ModalPaper?.sx }}
    >
      <Box sx={{ background: colors.white[600], padding: 2, borderRadius: '4px', ...slotProps?.Content.sx }}>
        {title || showCloseButton ? (
          <Grid container mb={2}>
            <Grid item xs={12}>
              <Box display='flex' alignItems='center' justifyContent='space-between'>
                {title ? (
                  <Typography variant='h6' color={colors.gray[800]}>
                    {title}
                  </Typography>
                ) : null}

                {showCloseButton ? (
                  <Button variant='text' sx={{ color: colors.gray[800] }} onClick={onClose}>
                    <XClose />
                  </Button>
                ) : null}
              </Box>
            </Grid>
          </Grid>
        ) : null}
        {children}
      </Box>
    </Drawer>
  );
};

export type { Props as PreviewDrawerProps };
export default PreviewDrawer;
