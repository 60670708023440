import { Box, Card as MuiCard, Typography, styled } from '@mui/material';

export const Card = styled(MuiCard)(() => ({
  padding: '1rem',
}));

export const SaleStatus = styled(Box)(() => ({
  marginBottom: '8px',
}));

export const SaleIndex = styled(Typography)(({ theme }) => ({
  marginRight: '8px',
  display: 'block',
  color: theme.palette.colors.gray[700],
}));

export const Product = styled(Typography)(({ theme }) => ({
  marginBottom: '8px',
  display: 'block',
  color: theme.palette.colors.gray[700],
}));

export const Total = styled(Typography)(({ theme }) => ({
  display: 'block',
  color: theme.palette.colors.gray[700],
}));

export const Amount = styled(Typography)(({ theme }) => ({
  display: 'block',
  color: theme.palette.colors.gray[700],
  lineHeight: '27px',
}));
