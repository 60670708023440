import { Box, styled } from '@mui/material';
import Button from '@/components/Button';

export const StyledBox = styled(Box)(() => ({
  gap: 1,
  width: '100%,',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const FinishPurchaseButton = styled(Button)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '16px',
  color: theme.palette.colors.gray[900],
  backgroundColor: theme.palette.colors.green[400],

  '&:disabled': {
    color: theme.palette.colors.gray[400],
    backgroundColor: theme.palette.colors.green[100],
  },

  '&:hover': {
    backgroundColor: theme.palette.colors.green[500],
  },
}));
