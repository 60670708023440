import { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledChip } from './style';
import { PartnershipStatus } from '@/services/SellerApi';

type StatusChipProps = {
  value: string;
  onChange?: (value: string) => void;
  canChange?: boolean;
};

export const StatusChip = (props: StatusChipProps) => {
  const { value, onChange, canChange = false } = props;

  const { t } = useTranslation(['partnership']);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (value === PartnershipStatus.Pending && canChange) {
      setAnchorEl(event.currentTarget);
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'pending':
        return 'warning';
      case 'ongoing':
        return 'success';
      case 'declined':
        return 'error';
      case 'deactivated':
        return 'default';
      default:
        return 'warning';
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (newValue: string) => {
    if (onChange !== undefined) {
      onChange(newValue);
    }
    handleClose();
  };

  return (
    <>
      <StyledChip
        onClick={(event: React.MouseEvent<HTMLDivElement>) => handleClick(event)}
        label={t(`status.${value}`)}
        color={getStatusColor(value)}
      />

      <Menu id='basic-menu' anchorEl={anchorEl} open={open} onClose={handleClose}>
        {value === PartnershipStatus.Pending
          ? [
              <MenuItem
                value={PartnershipStatus.Ongoing}
                onClick={() => handleMenuClick(PartnershipStatus.Ongoing)}
                key={PartnershipStatus.Ongoing}
              >
                {t('status.ongoing')}
              </MenuItem>,

              <MenuItem
                value={PartnershipStatus.Declined}
                onClick={() => handleMenuClick(PartnershipStatus.Declined)}
                key={PartnershipStatus.Declined}
              >
                {t('status.declined')}
              </MenuItem>,
            ]
          : null}
      </Menu>
    </>
  );
};

export default StatusChip;
