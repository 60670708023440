import { Sale } from '@/services/SellerApi';
import { StyledChipProps } from '@/components/Partnership/StatusChip/style';

export const getAggregatedVouchersValueAndStatus = (
  sale: Sale,
): { value: string; status: Partial<StyledChipProps['color']> } => {
  const { aggregatedVouchers } = sale;

  if (!aggregatedVouchers) {
    return { value: '0/0', status: 'default' };
  }

  const { consumedVouchersCount, vouchersCount } = aggregatedVouchers;
  let status: Partial<StyledChipProps['color']> = 'info';

  if (consumedVouchersCount === 0) {
    status = 'default';
  }

  if (consumedVouchersCount > 0 && consumedVouchersCount === vouchersCount) {
    status = 'success';
  }

  return {
    status,
    value: `${consumedVouchersCount}/${vouchersCount}`,
  };
};
