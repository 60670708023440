import { styled, Select as BaseSelect } from '@mui/material';

type ExtraSelectProps = {
  hasError?: boolean;
};

export const Select = styled(BaseSelect, {
  shouldForwardProp: (prop) => prop !== 'hasError',
})<ExtraSelectProps>(({ theme, hasError = false }) => ({
  width: 'max-content',
  borderRight: '1px solid',
  borderColor: hasError ? theme.palette.error.main : theme.palette.grey[400],
  borderRadius: '0px',

  fieldset: {
    display: 'none',
  },
  '&.Mui-focused:has(div[aria-expanded="false"])': {
    fieldset: {
      display: 'block',
    },
  },
  '.MuiSelect-select': {
    padding: '8px',
    paddingRight: '24px !important',
  },
  svg: {
    right: 0,
  },
}));
