import { styled, css } from '@mui/material/styles';
import Loading from '../Loading';

type Props = {
  position?: 'absolute' | 'relative';
  opacity?: number;
};

const Backdrop = styled('div', { shouldForwardProp: (prop) => prop !== 'position' })<Props>(
  ({ position = 'absolute', opacity = 0.9 }) => css`
    position: ${position};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(217, 217, 217, ${opacity});
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  `,
);

const BackdropLoading = (props: Props) => {
  const { position = 'absolute', opacity = 0.9 } = props;
  return (
    <Backdrop position={position} opacity={opacity}>
      <Loading />
    </Backdrop>
  );
};

export default BackdropLoading;
