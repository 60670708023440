import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';

import { formatCents } from '@/utils';
import Button from '@/components/Button';
import { ArrangedSaleItem } from '@/services/SellerApi';
import ValueWithLabel from '@/components/ValueWithLabel';
import PreviewDrawer from '@/components/PreviewDrawer';

type Props = {
  onClose: () => void;
  selectedCommission: ArrangedSaleItem | null;
  getFormattedCommission: (date: string | null | undefined) => string;
  getFormattedScheduleDate: (date?: string) => string;
  getCommisionPercentageFee: (commission: ArrangedSaleItem) => string;
};

const CommissionDrawer = (props: Props) => {
  const { selectedCommission, onClose, getCommisionPercentageFee, getFormattedCommission, getFormattedScheduleDate } =
    props;

  const { t } = useTranslation(['commissions']);

  return (
    <PreviewDrawer
      anchor='bottom'
      open={!!selectedCommission}
      onClose={onClose}
      title={`# ${selectedCommission?.sale?.position}`}
    >
      {selectedCommission ? (
        <Grid container gap={2}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={4.5}>
                <ValueWithLabel title={t('table.product')} value={selectedCommission.product?.name || '-'} />
              </Grid>

              <Grid item xs={4}>
                <ValueWithLabel
                  title={t('table.provider')}
                  value={selectedCommission.partnershipItem!.partnership?.provider?.name || '-'}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={4.5}>
                <ValueWithLabel
                  title={t('table.sale_value')}
                  value={formatCents(selectedCommission.decisiveTransactionValues!.totalAmountCents || 0)}
                />
              </Grid>

              <Grid item xs={4}>
                <ValueWithLabel title={t('table.commission')} value={getCommisionPercentageFee(selectedCommission!)} />
              </Grid>

              <Grid item xs={3.5}>
                <ValueWithLabel
                  title={t('table.commission_value')}
                  value={formatCents(selectedCommission.decisiveTransactionValues!.commissionedAmountCents ?? 0)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={4.5}>
                <ValueWithLabel
                  title={t('table.sale_date')}
                  value={getFormattedCommission(selectedCommission.sale!.createdAt)}
                />
              </Grid>

              <Grid item xs={7}>
                <ValueWithLabel
                  title={t('table.schedule_date')}
                  value={getFormattedScheduleDate(selectedCommission?.scheduleDate ?? undefined)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Button variant='contained' color='primary' onClick={onClose} fullWidth>
              <Typography variant='regularRegular'>{t('table.confirm_modal_button')}</Typography>
            </Button>
          </Grid>
        </Grid>
      ) : null}
    </PreviewDrawer>
  );
};

export default CommissionDrawer;
