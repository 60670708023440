import { Fragment } from 'react';

import AvailabilityWithPrice from '@/features/agenda/components/SchedulingCalendar/AgendaHoverDetails/AgendaHoverDetailsContent/TooltipTitle/AvailabilityWithPrice';
import SchedulingWithPrice from '@/features/agenda/components/SchedulingCalendar/AgendaHoverDetails/AgendaHoverDetailsContent/TooltipTitle/SchedulingWithPrice';
import { Availability, Scheduling } from '@/services/SellerApi';
import getRightSchedulingTariffGroupFromApi from '@/features/agenda/utils/getRightSchedulingTariffGroupFromApi';

type Props = {
  scheduling: Scheduling | undefined;
  availability: Availability | undefined;
};

const TooltipTitle = (props: Props) => {
  const { scheduling, availability } = props;

  if (scheduling) {
    const { product } = scheduling;
    const tariffGroup = getRightSchedulingTariffGroupFromApi(scheduling);
    return product && tariffGroup ? <SchedulingWithPrice product={product} tariffGroup={tariffGroup} /> : <Fragment />;
  }

  if (availability) {
    return <AvailabilityWithPrice availability={availability} />;
  }

  return <Fragment />;
};

export default TooltipTitle;
