import { styled } from '@mui/material';

export const WeeklyDayContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  width: '100%',
}));

export const StyledMobileMoreButton = styled('button')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  backgroundColor: 'transparent',
  border: 0,
  borderRadius: 0,
  cursor: 'pointer',
  [theme.breakpoints.up('md')]: {
    cursor: 'initial',
  },
}));
