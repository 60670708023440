import { IconButton, useMediaQuery } from '@mui/material';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { Close } from '@mui/icons-material';

import theme from '@/theme';
import SnackbarContent from '@/components/SnackbarContent';

type Props = {
  children: React.ReactNode;
};

declare module 'notistack' {
  interface OptionsObject {
    title?: string;
  }
}

export const CustomSnackbarProvider = (props: Props) => {
  const { children } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <SnackbarProvider
      Components={{
        success: SnackbarContent,
        error: SnackbarContent,
      }}
      hideIconVariant
      anchorOrigin={isMobile ? { vertical: 'bottom', horizontal: 'center' } : undefined}
      maxSnack={3}
      action={(snackbarId) => (
        <IconButton onClick={() => closeSnackbar(snackbarId)}>
          <Close />
        </IconButton>
      )}
      autoHideDuration={3000}
    >
      {children}
    </SnackbarProvider>
  );
};

export default CustomSnackbarProvider;
