import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import PageLayout from '@/layouts/PageLayout';
import FilterProvider from '@/contexts/FilterContext';
import { useEffect } from 'react';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import AppTagsList from '@/features/sales/components/AppTagsList';
import { Grid } from '@mui/material';
import { Plus } from '@untitled-ui/icons-react';

export const SaleAppTags = () => {
  const { track } = useAnalytics();
  const { t } = useTranslation(['sales']);

  const breadcrumbs = [
    {
      label: t('app_tags.breadcrumbs.sales'),
      to: '../sales',
    },
    {
      label: t('app_tags.breadcrumbs.tags'),
      to: '.',
    },
  ];

  useEffect(() => {
    track('saleAppTags/access');
  }, []);

  return (
    <FilterProvider contextName='saleTags'>
      <Helmet>
        <title>{t('app_tags.title')}</title>
      </Helmet>

      <PageLayout
        breadcrumbs={breadcrumbs}
        title={t('app_tags.title')}
        showGoBackButton
        slotProps={{
          searchInput: { placeholder: t('filter.tags.search_input_placeholder') },
        }}
        showFilterButton={false}
        actions={{
          primary: {
            title: t('page_layout.new_tag'),
            label: t('page_layout.new_tag'),
            Icon: <Plus />,
            href: 'create',
          },
        }}
      >
        <Grid container paddingY={{ xs: 2, sm: 4 }} paddingX={{ xs: 0, sm: 4 }}>
          <AppTagsList />
        </Grid>
      </PageLayout>
    </FilterProvider>
  );
};

export default SaleAppTags;
