import { Suspense } from 'react';

import AgendaHoverDetails from '@/features/agenda/components/SchedulingCalendar/AgendaHoverDetails';
import WeeklySlotSkeleton from '@/features/agenda/components/SchedulingCalendar/AgendaWeeklyView/WeeklySlot/WeeklySlotSkeleton';
import { Availability, Scheduling } from '@/services/SellerApi';

// const LazyWeeklySlotScheduling = lazy(
//   () => import('@/features/agenda/components/SchedulingCalendar/AgendaWeeklyView/WeeklySlot/WeeklySlotScheduling'),
// );
// const LazyWeeklySlotAvailability = lazy(
//   () =>
//     import('@/features/agenda/components/SchedulingCalendar/AgendaWeeklyView/WeeklySlot/WeeklySlotAvailability'),
// );
import LazyWeeklySlotScheduling from '@/features/agenda/components/SchedulingCalendar/AgendaWeeklyView/WeeklySlot/WeeklySlotScheduling';
import LazyWeeklySlotAvailability from '@/features/agenda/components/SchedulingCalendar/AgendaWeeklyView/WeeklySlot/WeeklySlotAvailability';

type Props = {
  scheduling?: Scheduling;
  availability?: Availability;
};

const WeeklySlot = (props: Props) => {
  const { scheduling, availability } = props;

  return (
    <Suspense fallback={<WeeklySlotSkeleton />}>
      <AgendaHoverDetails scheduling={scheduling} availability={availability}>
        {scheduling ? <LazyWeeklySlotScheduling scheduling={scheduling} /> : null}
        {availability ? <LazyWeeklySlotAvailability availability={availability} /> : null}
      </AgendaHoverDetails>
    </Suspense>
  );
};

export default WeeklySlot;
