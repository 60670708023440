import { Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Chip from '@/components/Chip';
import { RecurrenceDetails } from '@/features/agenda/components/SchedulingModal/SchedulingModalContent/SchedulingModalDetails/RecurrenceDetails';
import { SchedulingModalItem } from '@/features/agenda/components/SchedulingModal/types';
import { InfoItem, InfoList } from '@/features/agenda/styles/infoList';
import buildMinutesHoursDaysString from '@/features/agenda/utils/buildMinutesHoursDaysString';
import { Scheduling } from '@/services/SellerApi';
import isSchedulingForAllDay from '@/utils/isSchedulingForAllDay';
import TariffGroupDetails from '@/features/agenda/components/SchedulingModal/SchedulingModalContent/SchedulingModalDetails/TariffGroupDetails';
import getRightSchedulingTariffGroupFromApi from '@/features/agenda/utils/getRightSchedulingTariffGroupFromApi';

type Props = {
  scheduling: Scheduling | null;
};

const SchedulingModalDetails = (props: Props) => {
  const { scheduling } = props;
  const { t } = useTranslation(['agenda'], { keyPrefix: 'modal' });
  const isForAllDay = useMemo(() => scheduling && isSchedulingForAllDay(scheduling), [scheduling]);

  const itemDetails = useMemo(() => {
    const details = [];
    if (scheduling) {
      if (scheduling.product?.name) {
        details.push({
          title: `${t('product')}:`,
          value: scheduling.product?.internalName ?? scheduling.product?.name,
        });
      }
      if (scheduling.product?.sellingMode) {
        details.push({
          title: `${t('scheduling.selling_mode')}:`,
          value: (
            <Chip
              label={t(`scheduling.selling_modes.${scheduling.product?.sellingMode}`)}
              size='small'
              sx={{ padding: '0.5rem', marginLeft: 'auto' }}
            />
          ),
        });
      }

      if (getRightSchedulingTariffGroupFromApi(scheduling)?.name) {
        details.push({
          title: `${t('tariff_group')}:`,
          value: <TariffGroupDetails scheduling={scheduling} />,
        });
      }

      if (scheduling.durationMinutes) {
        details.push({
          title: `${t('reservation_duration')}:`,
          value: buildMinutesHoursDaysString(scheduling.durationMinutes),
        });
      }

      if (scheduling.calendarIntervalMinutes) {
        details.push({
          title: `${t('select_time_interval')}:`,
          value: buildMinutesHoursDaysString(scheduling.calendarIntervalMinutes),
        });
      }

      if (scheduling.consumableFromTime && scheduling.consumableToTime && !isForAllDay) {
        details.push({
          title: `${t('time_range')}:`,
          value: `${scheduling.consumableFromTime} - ${scheduling.consumableToTime}`,
        });
      }

      if (isForAllDay) {
        details.push({
          title: `${t('time_range')}:`,
          value: t('scheduling.all_day'),
        });
      }

      if (scheduling.antecedenceMinutes) {
        details.push({
          title: `${t('min_antecedence')}:`,
          value: buildMinutesHoursDaysString(scheduling.antecedenceMinutes),
        });
      }

      if (scheduling.recurrence) {
        details.push({
          title: `${t('recurrence_at')}:`,
          testID: `recurrence-item-${scheduling.recurrence.type}`,
          value: <RecurrenceDetails scheduling={scheduling} />,
        });
      }
    }

    return details;
  }, [scheduling, t]);

  return (
    <Stack>
      <InfoList>
        {itemDetails?.map((item: SchedulingModalItem) => (
          <InfoItem key={item.title?.toString()} data-testid={item.testID} sx={{ alignItems: 'flex-start' }}>
            <Typography variant='smallBold' sx={{ textWrap: 'nowrap' }}>
              {item.title}
            </Typography>
            <Typography
              variant='smallSemiBold'
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.25rem',
                flexWrap: 'wrap',
              }}
            >
              {item.value}
            </Typography>
          </InfoItem>
        ))}
      </InfoList>
    </Stack>
  );
};

export default SchedulingModalDetails;
