import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import useGetAllReservationRescheduleSchedulings from '@/features/reservation/useCases/useGetAllReservationRescheduleSchedulings';
import { format } from 'date-fns';

type DaysToPreFetch = {
  from: Date;
  to: Date;
};

type PreFetchBatches = Array<DaysToPreFetch> | false;

type Query = [string, string, string, string];

export const getUseLoadReservationRescheduleSchedulingsQueryKey = (
  reservationId: string,
  rescheduleFrom?: string,
  rescheduleUntil?: string,
): Query => {
  const query = [`/reservations/${reservationId}/rescheduleSchedulings`, reservationId];
  if (rescheduleFrom) query.push(rescheduleFrom);
  if (rescheduleUntil) query.push(rescheduleUntil);
  return query as Query;
};

const useLoadReservationRescheduleSchedulings = () => {
  const { request: getReservations } = useGetAllReservationRescheduleSchedulings();
  const queryClient = useQueryClient();

  const getPreFetchDays = (days: DaysToPreFetch, reservationId: string) => {
    if (!days) return;

    const { from, to } = days;

    queryClient.prefetchQuery({
      queryKey: getUseLoadReservationRescheduleSchedulingsQueryKey(
        reservationId,
        format(from, 'yyyy-MM-dd'),
        format(to, 'yyyy-MM-dd'),
      ),
      queryFn: getReservations,
      initialData: keepPreviousData,
      staleTime: Infinity,
    });
  };

  const useRequestQuery = (
    reservationId: string,
    rescheduleFrom: string,
    rescheduleUntil: string,
    preFetchBatches: PreFetchBatches,
  ) => {
    const query = useQuery({
      queryKey: getUseLoadReservationRescheduleSchedulingsQueryKey(reservationId, rescheduleFrom, rescheduleUntil),
      queryFn: getReservations,
      enabled: !!reservationId,
      staleTime: Infinity,
      placeholderData: keepPreviousData,
    });

    if (preFetchBatches) {
      preFetchBatches.forEach((batch) => {
        getPreFetchDays(batch, reservationId);
      });
    }

    return query;
  };

  return [useRequestQuery];
};

export default useLoadReservationRescheduleSchedulings;
