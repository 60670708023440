import { Button, buttonClasses } from '@planne-software/uni/Button';
import { Skeleton, styled, Tokens } from '@planne-software/uni/mui/material';
import { TimeCellSelectColor } from './types';

type TokenKey = keyof Tokens['colors'];

type TokenColor = {
  text: TokenKey;
  textDisabled: TokenKey;
  border: TokenKey;
  fill: TokenKey;
  fillDisabled: TokenKey;
};

const colorToken: Record<string, TokenColor> = {
  success: {
    text: 'text-color-success-strongest',
    textDisabled: 'text-color-neutral-base',
    border: 'border-color-success-strong',
    fill: 'fill-color-success-softest',
    fillDisabled: 'fill-color-neutral-softer',
  },
  error: {
    text: 'text-color-alert-strongest',
    textDisabled: 'text-color-neutral-base',
    border: 'border-color-alert-strong',
    fill: 'fill-color-alert-softest',
    fillDisabled: 'fill-color-neutral-softer',
  },
  neutral: {
    text: 'text-color-neutral-strongest',
    textDisabled: 'text-color-neutral-strongest',
    border: 'border-color-neutral-strong',
    fill: 'fill-color-neutral',
    fillDisabled: 'fill-color-neutral-softer',
  },
  warning: {
    text: 'text-color-warning-strongest',
    textDisabled: 'text-color-neutral-base',
    border: 'border-color-warning-strong',
    fill: 'fill-color-warning-softest',
    fillDisabled: 'fill-color-neutral-softer',
  },
};

export const StyledTimeCellSelectRoot = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'status',
})<{ status: TimeCellSelectColor }>(({ theme, status }) => ({
  ...theme.tokens.typography['text-sm-medium'],
  width: '100%',
  [`&.${buttonClasses.neutral}.${buttonClasses.outlined}, &.${buttonClasses.primary}`]: {
    color: theme.tokens.colors[colorToken[status].text],
    padding: theme.tokens.spacing['spacing-1xmini'],
    borderRadius: theme.tokens.radius['border-radius-xs'],
    border: `1px solid ${theme.tokens.colors[colorToken[status].border]}`,
    backgroundColor: theme.tokens.colors[colorToken[status].fill],

    [`&.${buttonClasses.primary}`]: {
      color: theme.tokens.colors['text-color-neutral'],
    },

    [`&.${buttonClasses.disabled}`]: {
      color: theme.tokens.colors[colorToken[status].textDisabled],
      backgroundColor: theme.tokens.colors[colorToken[status].fillDisabled],
    },
  },
}));

export const StyledTimeCellSelectSkeleton = styled(Skeleton)(({ theme }) => ({
  display: 'flex',
  overflow: 'hidden',
  justifyContent: 'center',
  width: 'fit-content',
  height: 'fit-content',
  alignItems: 'center',
  padding: theme.tokens.spacing['spacing-1xmini'],
  borderRadius: theme.tokens.radius['border-radius-xs'],
  backgroundColor: theme.tokens.colors['fill-color-neutral-softer'],
  border: `1px solid transparent`,

  '&::before': {
    ...theme.tokens.typography['text-sm-medium'],
    color: 'transparent',
    content: '"00:00"',
    width: 'inherit',
    borderRadius: theme.tokens.radius['border-radius-mini'],
    backgroundColor: theme.tokens.colors['fill-color-neutral-soft'],
  },
}));
