import { styled } from '@mui/material/styles';

export const Bar = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  width: '50%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  padding: '16px',
  height: '80px',

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const Logo = styled('img')(() => ({
  height: '45px',
}));
