/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Planne Seller API
 * OpenAPI spec version: 0.1.0
 */

export type SellingMode = typeof SellingMode[keyof typeof SellingMode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SellingMode = {
  with_date_and_time: 'with_date_and_time',
  with_date_only: 'with_date_only',
  without_date: 'without_date',
} as const;
