import { StyledItem, StyledList } from '@/features/agenda/components/AvailabilityItensList/styles';
import isAvailabilityContentArchived from '@/features/agenda/utils/isAvailabilityContentArchived';
import {
  Additional,
  Availability,
  AvailabilityTypeEnum,
  Product,
  ProductTariffType,
  Tariff,
} from '@/services/SellerApi';
import theme from '@/theme';
import { Box, Stack, Typography } from '@mui/material';
import { IconAlertTriangle } from '@tabler/icons-react';
import { useCallback, useMemo } from 'react';

type Props = {
  availability: Availability;
};

const AvailabilityItensList = (props: Props) => {
  const { availability } = props;
  const isArchived = useMemo(() => isAvailabilityContentArchived(availability).isSomeArchived, [availability]);
  const getItemKey = (item: Product | ProductTariffType | Additional | Tariff) => {
    if (
      [AvailabilityTypeEnum.Products, AvailabilityTypeEnum.Additionals, AvailabilityTypeEnum.Tariffs].includes(
        availability.type as 'per_products' | 'per_tariffs' | 'per_additionals',
      )
    ) {
      return `${availability.type}-${(item as Product | Additional | Tariff).id}`;
    }

    return `tariffTypeId-${(item as ProductTariffType).tariffTypeId}-${(item as ProductTariffType).productId}`;
  };

  const itemNameData = useCallback(
    (item: Product | ProductTariffType | Additional | Tariff) => {
      if (availability.type === AvailabilityTypeEnum.Products) {
        const product = item as Product;
        return { title: product.internalName ?? product.name, inactive: !product.active };
      }

      if (availability.type === AvailabilityTypeEnum.Additionals) {
        return { title: (item as Additional).title, inactive: !(item as Additional).product?.active };
      }

      if (availability.type === AvailabilityTypeEnum.Tariffs) {
        return { title: (item as Tariff).type?.name, inactive: (item as Tariff).deletedAt };
      }

      if (availability.type === AvailabilityTypeEnum.ProductTariffTypes) {
        const productTariffType = item as ProductTariffType;
        return { title: productTariffType.tariffType?.name, inactive: false };
      }

      return null;
    },
    [availability.type],
  );

  const itens = useMemo(() => {
    if (!availability.products) {
      return null;
    }

    return {
      [AvailabilityTypeEnum.Products]: availability.products,
      [AvailabilityTypeEnum.Additionals]: availability.additionals,
      [AvailabilityTypeEnum.Tariffs]: availability.tariffs,
      [AvailabilityTypeEnum.ProductTariffTypes]: availability.productTariffTypes,
      [AvailabilityTypeEnum.App]: [],
    };
  }, [availability]);

  const itemSelected = useMemo(() => (itens ? itens[availability.type] : null), [itens, availability.type]);
  const limitedItens = useMemo(() => (itemSelected ? itemSelected.slice(0, 3) : null), [itemSelected]);

  const isSomeItemSelectedInactive = useMemo(() => {
    if (!itemSelected) {
      return false;
    }

    return itemSelected.some((item) => itemNameData(item)?.inactive);
  }, [itemSelected]);

  const hasItems = useMemo(
    () => itemSelected && itemSelected?.length > 0 && limitedItens,
    [itemSelected, limitedItens],
  );

  if (!hasItems) {
    return null;
  }

  return (
    <Stack sx={{ maxWidth: '100%' }}>
      <StyledList>
        {limitedItens!.map((item) => (
          <StyledItem
            key={getItemKey(item)}
            sx={{
              color: itemNameData(item)?.inactive ? theme.palette.colors.warning[700] : 'inherit',
            }}
          >
            •{' '}
            <Box
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {itemNameData(item)?.title}
            </Box>
            {itemNameData(item)?.inactive ? (
              <Stack>
                <IconAlertTriangle size='0.5rem' />
              </Stack>
            ) : null}
          </StyledItem>
        ))}
      </StyledList>
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          color: isArchived && !isSomeItemSelectedInactive ? theme.palette.colors.warning[700] : 'inherit',
        }}
      >
        {limitedItens && limitedItens.length < itemSelected!.length ? (
          <Typography variant='extraSmallRegular'>{itemSelected!.length - limitedItens.length}+</Typography>
        ) : null}
        {isArchived && !isSomeItemSelectedInactive ? (
          <Stack>
            <IconAlertTriangle size='0.812rem' />
          </Stack>
        ) : null}
      </Stack>
    </Stack>
  );
};

export default AvailabilityItensList;
