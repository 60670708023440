const formatMidnightString = (time: string, reverse = false) => {
  if (time.startsWith('24') && !reverse) {
    return '23:59';
  }
  if (time === '23:59' && reverse) {
    return '24:00';
  }
  return time;
};

export default formatMidnightString;
