import { Stack, styled, TableBody as BaseTableBody } from '@mui/material';

export const Header = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const TableBody = styled(BaseTableBody)(() => ({
  '& .MuiTableCell-root': { borderBottom: 'none' },
}));
