import { useState } from 'react';
import { Menu, MenuItem, SvgIconTypeMap, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button from '@/components/Button';
import { DotsVertical } from '@untitled-ui/icons-react';
import { Partnership } from '@/services/SellerApi';
import { Cancel, CheckCircle, CloudDownload, Share, Visibility } from '@mui/icons-material';
import { colors } from '@/theme';
import { OverridableComponent } from '@mui/material/OverridableComponent';

type MobileMenuProps = {
  isAgent: boolean;
  isProvider: boolean;
  partnership: Partnership;
  downloadQrCode: (partnership: Partnership) => void;
  shareOnWhatsapp: (partnership: Partnership) => void;
  viewPartnership: (partnership: Partnership) => void;
  acceptPartnership: (partnership: Partnership) => void;
  declinePartnership: (partnership: Partnership) => void;
};

export const MobileMenu = (props: MobileMenuProps) => {
  const {
    isAgent,
    isProvider,
    partnership,
    downloadQrCode,
    shareOnWhatsapp,
    viewPartnership,
    acceptPartnership,
    declinePartnership,
  } = props;

  const { t } = useTranslation(['partnership']);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button onClick={handleClick} variant='outlined' color='primary' sx={{ padding: '0.5rem' }}>
        <DotsVertical />
      </Button>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} sx={{ '& .MuiList-root ': { padding: 0 } }}>
        <Item Icon={Visibility} label={t('list.table.actions.view')} onClick={() => viewPartnership(partnership)} />

        {isProvider && partnership.currentStatus === 'pending' ? (
          <>
            <Item
              Icon={CheckCircle}
              onClick={() => acceptPartnership(partnership)}
              value='pending'
              label={t('list.table.actions.accept')}
            />

            <Item
              Icon={Cancel}
              onClick={() => declinePartnership(partnership)}
              label={t('list.table.actions.decline')}
            />
          </>
        ) : null}

        {isAgent && partnership.currentStatus === 'ongoing' ? (
          <Item
            onClick={() => downloadQrCode(partnership)}
            Icon={CloudDownload}
            label={t('list.table.actions.download_qr_code')}
          />
        ) : null}

        {partnership.currentStatus === 'ongoing' ? (
          <Item
            Icon={Share}
            onClick={() => shareOnWhatsapp(partnership)}
            label={t('list.table.actions.share_on_whatsapp')}
          />
        ) : null}
      </Menu>
    </>
  );
};

type ItemProps = {
  onClick: () => void;
  Icon: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & { muiName: string };
  label: string;
  value?: string;
};
const Item = (props: ItemProps) => {
  const { label, onClick, Icon, value } = props;

  return (
    <MenuItem value={value} onClick={onClick} sx={{ display: 'flex', gap: 1 }}>
      <Icon sx={{ color: colors.gray[500] }} />
      <Typography variant='regularRegular' color={colors.gray[500]}>
        {label}
      </Typography>
    </MenuItem>
  );
};

export default MobileMenu;
