import { useContext } from 'react';

import { Context } from '../context/saleDetailContext';

export const useSaleDetails = () => {
  const ctx = useContext(Context);

  if (!ctx) {
    throw new Error('useSaleDetails must be used within a SaleDetailContextProvider');
  }

  return ctx;
};
