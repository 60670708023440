import { zodResolver } from '@hookform/resolvers/zod';
import { Box, CircularProgress, Grid, Typography, useMediaQuery } from '@mui/material';
import { AxiosError } from 'axios';
import { isValid, parseISO, setHours, setMinutes } from 'date-fns';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { Control, Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '@/components/Button';
import DateTimePicker from '@/components/Form/DateTimePicker';
import Input from '@/components/Form/Input';
import RadioButtonsGroup from '@/components/Form/RadioButtonsGroup';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import useAccount from '@/hooks/useAccount';
import useErrorHandler from '@/hooks/useErrorHandler';
import { useSellerApi } from '@/hooks/useSellerApi';
import { ErrorTypes } from '@/interfaces/errorTypes';
import { Coupon, BasicCouponProduct, CouponType, CreateCouponParams, CouponProduct } from '@/services/SellerApi';
import theme from '@/theme';
import { dateWithTimezone, formatCents } from '@/utils';

import ProductSelector from './ProductSelector';
import { schema } from './schema';
import { Actions, DividerWithTitle } from './styles';
import RestrictionsByDateOfUse from './RestrictionsByDateOfUse';

type Props = {
  coupon?: Coupon;
  couponProducts: CouponProduct[];
  onSuccess?: () => void;
  onCancel?: () => void;
  onFail?: (error: AxiosError) => void;
};

export const CouponForm = (props: Props) => {
  const { coupon, couponProducts, onFail, onSuccess = () => {}, onCancel = () => {} } = props;

  const { track } = useAnalytics();
  const { couponsApi } = useSellerApi();
  const { selectedAccount } = useAccount();
  const { errorHandler, extractFieldNameFromPath } = useErrorHandler();
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation(['coupons']);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getDiscountAmountCents = (coupon: Coupon) => {
    if (coupon.discountAmountCents) {
      return coupon.discountAmountCents / 100;
    }

    return undefined;
  };

  const getDiscountPercentage = (coupon: Coupon) => {
    if (coupon.discountPercentage) {
      return coupon.discountPercentage;
    }

    return undefined;
  };

  const getUsageRestrictions = (coupon: Coupon) => {
    if (!coupon.usageRestrictions) {
      return undefined;
    }

    return coupon.usageRestrictions.map((restriction) => ({
      date: restriction.date ? dateWithTimezone(restriction.date) : null,
      fromDate: restriction.fromDate ? new Date(restriction.fromDate) : null,
      toDate: restriction.toDate ? new Date(restriction.toDate) : null,
      weekdays: restriction.weekdays ?? null,
      type: {
        label: t(`coupon_form.restrictions_by_date_of_use.type.${restriction.type}`),
        value: restriction.type,
      },
    }));
  };

  const getDefaultValue = () => {
    if (coupon) {
      return {
        code: coupon.code,
        type: coupon.type,
        discountAmountCents: getDiscountAmountCents(coupon),
        discountPercentage: getDiscountPercentage(coupon),
        maxUse: String(coupon.maxUse) ?? undefined,
        maxUsePerUser: String(coupon.maxUsePerUser) ?? undefined,
        usageType:
          coupon.usageType === 'general'
            ? 'general'
            : coupon.constraintType === 'include'
              ? 'include_product'
              : 'exclude_product',
        products: couponProducts.map((cp) => cp.product) || [],
        description: coupon.description,
        minAmountCents: coupon.minAmountCents ? formatCents(coupon.minAmountCents) : undefined,
        availableAt: coupon.availableAt ? parseISO(coupon.availableAt) : undefined,
        expiresAt: coupon.expiresAt ? parseISO(coupon.expiresAt) : undefined,
        usageRestrictions: getUsageRestrictions(coupon),
      };
    }

    return {
      type: CouponType.Percentage,
    };
  };

  const { register, handleSubmit, formState, watch, control, setError } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: zodResolver(schema(t)),
    defaultValues: getDefaultValue(),
  });

  const { errors, isSubmitting } = formState;

  const handleErrors = (error: AxiosError, ctx: 'create' | 'update') => {
    const { errors } = errorHandler(error);

    let messageKey = `snackbar.could_not_${ctx}_coupon`;

    if (errors?.length) {
      errors.forEach(({ type, path, rule }) => {
        if (type === ErrorTypes.VALIDATION) {
          const field = extractFieldNameFromPath(path!, -1);

          messageKey = `coupon_form.validation.api_errors.${field}.${rule}`;

          setError(path! as 'code', { message: t(messageKey) }, { shouldFocus: true });
        }
      });
    }

    enqueueSnackbar(t(messageKey), { variant: 'error' });
    track(`Coupons/${ctx}CouponFail`, { error });
    onFail?.(error);
  };

  const createCoupon = async (payload: CreateCouponParams) => {
    try {
      setLoading(true);
      await couponsApi.createAppCoupon({
        appId: selectedAccount?.appId ?? '',
        createCouponParams: payload,
      });

      enqueueSnackbar(t('snackbar.coupon_created_successfully'), {
        variant: 'success',
      });

      track('Coupons/createCouponSuccess', { payload });

      onSuccess();
    } catch (error) {
      handleErrors(error as AxiosError, 'create');
    } finally {
      setLoading(false);
    }
  };

  const editCoupom = async (createCouponParams: CreateCouponParams, couponId: string) => {
    const payload = {
      couponId,
      createCouponParams,
    };

    try {
      setLoading(true);
      await couponsApi.updateCoupon(payload);

      track('Coupons/updateCouponSuccess', { payload });

      enqueueSnackbar(t('snackbar.coupon_updated_successfully'), {
        variant: 'success',
      });

      onSuccess();
    } catch (error) {
      handleErrors(error as AxiosError, 'update');
    } finally {
      setLoading(false);
    }
  };

  const submit = (payload: FieldValues) => {
    if (coupon?.id) {
      editCoupom(payload as CreateCouponParams, coupon.id);
      return;
    }

    createCoupon(payload as CreateCouponParams);
  };

  const handleChangeValidate =
    (callback: (date: Date | undefined) => void, field: 'availableAt' | 'expiresAt') => (date: Date | null) => {
      if (date && isValid(date) && !watch(field)) {
        return callback(setHours(setMinutes(date, 59), 23));
      }

      callback(date as Date | undefined);
    };

  return (
    <Grid container component='form' spacing={3} position='relative' onSubmit={handleSubmit(submit)}>
      <Grid item xs={12}>
        <Controller
          name='code'
          control={control}
          render={({ field: { onChange, value, name, ...rest } }) => (
            <Input
              name={name}
              value={value || ''}
              onChange={(e) => {
                e.target.value = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
                onChange(e);
              }}
              fullWidth
              id='code'
              required
              label={t('coupon_form.code_label')}
              autoFocus
              error={!!errors['code']}
              helperText={errors['code']?.message as string}
              {...rest}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Controller
              name='type'
              control={control}
              render={({ field: { onChange, value, name, ...rest } }) => (
                <RadioButtonsGroup
                  required
                  id='type'
                  name={name}
                  value={value || ''}
                  onChange={onChange}
                  error={!!errors['type']}
                  label={t('coupon_form.coupon_type_label')}
                  helperText={errors['type']?.message as string}
                  row
                  options={[
                    { value: CouponType.Percentage, label: t('coupon_form.percentage_label') },
                    { value: CouponType.Nominal, label: t('coupon_form.nominal_label') },
                  ]}
                  {...rest}
                />
              )}
            />
          </Grid>

          {watch('type') === CouponType.Nominal ? (
            <Grid item xs={12} md={6}>
              <Input
                fullWidth
                id='discountAmountCents'
                type='number'
                inputProps={{ step: '0.01', inputMode: 'numeric' }}
                required
                label={t('coupon_form.discount_amount_cents_label')}
                autoFocus
                error={!!errors['discountAmountCents']}
                helperText={errors['discountAmountCents']?.message as string}
                {...register('discountAmountCents', { shouldUnregister: true })}
              />
            </Grid>
          ) : null}

          {watch('type') === CouponType.Percentage ? (
            <Grid item xs={12} md={6}>
              <Input
                fullWidth
                id='discountPercentage'
                type='number'
                inputProps={{ step: '0.01', inputMode: 'numeric' }}
                required
                label={t('coupon_form.discount_percentage_label')}
                autoFocus
                error={!!errors['discountPercentage']}
                helperText={errors['discountPercentage']?.message as string}
                {...register('discountPercentage', { shouldUnregister: true })}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Controller
          name='description'
          control={control}
          render={({ field: { name, ...rest } }) => (
            <Input
              {...rest}
              multiline
              fullWidth
              minRows={2}
              maxRows={6}
              id='description'
              name={name}
              placeholder={t('coupon_form.observation.placeholder')}
              label={t('coupon_form.observation.label')}
              error={!!errors[name]}
              helperText={(errors[name]?.message as string) ?? t('coupon_form.observation.helper_text')}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <DividerWithTitle variant='regularBold'>{t('coupon_form.restriction_session_label')}</DividerWithTitle>
      </Grid>

      <Grid item xs={12}>
        <Box display='flex' flexDirection='column' gap={1}>
          <Controller
            name='usageType'
            control={control}
            defaultValue='general'
            render={({ field: { onChange, value, name, ...rest } }) => (
              <RadioButtonsGroup
                required
                id={name}
                name={name}
                value={value || ''}
                onChange={onChange}
                error={!!errors[`${name}`]}
                label={t('coupon_form.coupon_usage_type_label')}
                helperText={errors[`${name}`]?.message as string}
                row
                options={[
                  { value: 'general', label: t('coupon_form.usage_type_general_label') },
                  { value: 'include_product', label: t('coupon_form.usage_type_product_include_label') },
                  { value: 'exclude_product', label: t('coupon_form.usage_type_product_exclude_label') },
                ]}
                {...rest}
              />
            )}
          />

          {['exclude_product', 'include_product'].includes(watch('usageType')) ? (
            <Controller
              control={control}
              name='products'
              defaultValue={[]}
              render={({ field: { name, onChange, ...rest } }) => (
                <ProductSelector
                  openOnFocus={!!errors[`${name}`]}
                  error={!!errors[`${name}`]}
                  helperText={(errors[`${name}`]?.message as string) ?? ''}
                  onChange={(value) => onChange(value as BasicCouponProduct[])}
                  name={name}
                  {...rest}
                />
              )}
            />
          ) : null}
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name='maxUse'
              render={({ field: { onChange, value, name, ...rest } }) => (
                <Input
                  fullWidth
                  id={name}
                  type='number'
                  value={value || ''}
                  onChange={(e) => {
                    e.target.value = Number(e.target.value ?? 0) < 1 ? '' : e.target.value;
                    onChange(e);
                  }}
                  error={!!errors[`${name}`]}
                  label={t('coupon_form.max_use_label')}
                  helperText={errors[`${name}`]?.message as string}
                  tooltipProps={{
                    placement: 'left',
                    title: t('coupon_form.tooltip.max_use'),
                  }}
                  {...rest}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name='maxUsePerUser'
              render={({ field: { onChange, value, name, ...rest } }) => (
                <Input
                  fullWidth
                  type='number'
                  id={name}
                  value={value || ''}
                  onChange={(e) => {
                    e.target.value = Number(e.target.value ?? 0) < 1 ? '' : e.target.value;
                    onChange(e);
                  }}
                  label={t('coupon_form.max_use_per_client_label')}
                  error={!!errors[`${name}`]}
                  tooltipProps={{
                    placement: 'left',
                    title: t('coupon_form.tooltip.max_use_per_client'),
                  }}
                  helperText={errors[`${name}`]?.message as string}
                  {...rest}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name='availableAt'
              render={({ field: { onChange, value, name, ...rest } }) => (
                <DateTimePicker
                  fullWidth
                  value={value || null}
                  error={!!errors[`${name}`]}
                  onChange={handleChangeValidate(onChange, 'availableAt')}
                  label={t('coupon_form.validate.from.label')}
                  placeholder={t('coupon_form.validate.from.placeholder')}
                  helperText={errors[`${name}`]?.message as string}
                  {...rest}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name='expiresAt'
              render={({ field: { onChange, value, name, ...rest } }) => (
                <DateTimePicker
                  fullWidth
                  value={value || null}
                  error={!!errors[`${name}`]}
                  onChange={handleChangeValidate(onChange, 'expiresAt')}
                  label={t('coupon_form.validate.to.label')}
                  placeholder={t('coupon_form.validate.to.placeholder')}
                  helperText={errors[`${name}`]?.message as string}
                  {...rest}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Controller
          name='minAmountCents'
          control={control}
          render={({ field: { name, value = '', onChange, ...rest } }) => (
            <Input
              fullWidth
              id={name}
              label={t('coupon_form.min_amount_cents.label')}
              error={!!errors[name]}
              helperText={errors[name]?.message}
              {...rest}
              value={value}
              tooltipProps={{
                placement: 'left',
                title: t('coupon_form.tooltip.min_amount_cents'),
              }}
              onChange={(e) => {
                const numberValue = e.target.value.replace(/\D/g, '');

                e.target.value = numberValue ? formatCents(Number(numberValue)) : '';

                onChange(e);
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <DividerWithTitle variant='regularBold'>
          {t('coupon_form.restrictions_by_date_of_use.title')}

          <Typography variant='regularMedium' ml={1} color={theme.palette.colors.gray[500]}>
            {t('coupon_form.restrictions_by_date_of_use.optional')}
          </Typography>
        </DividerWithTitle>
      </Grid>

      <Grid item xs={12}>
        <RestrictionsByDateOfUse control={control as unknown as Control} />
      </Grid>

      <Grid item xs={12} sx={{ textAlign: 'end' }}>
        <Actions direction={{ xs: 'column-reverse', sm: 'row' }}>
          <Button
            color='primary'
            variant='outlined'
            onClick={onCancel}
            fullWidth={isMobile}
            disabled={loading || isSubmitting}
          >
            {t('coupon_form.cancel_button')}
          </Button>

          <Button
            type='submit'
            color='primary'
            variant='contained'
            fullWidth={isMobile}
            disabled={loading || isSubmitting}
            sx={{ padding: '0.75rem 1rem', width: isMobile ? '100%' : '87px' }}
          >
            {t('coupon_form.submit_button')}
          </Button>
        </Actions>
      </Grid>

      {loading ? (
        <Grid item xs={12} textAlign='center' zIndex={999999} position='absolute' left='50%' top='50%'>
          <Box display='flex' alignItems='center' justifyContent='center' width='100%'>
            <CircularProgress color='primary' />
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default CouponForm;
