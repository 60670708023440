import { DEFAULT_VIEW } from '@/contexts/Calendar/constants';
import { ICalendarContext } from '@/contexts/Calendar/types';
import { createContext } from 'react';

export const CalendarContext = createContext<ICalendarContext>({
  activeDate: new Date(),
  calendarView: DEFAULT_VIEW,
  firstDayOfRange: new Date(),
  lastDayOfRange: new Date(),
  updateDate: () => {},
  updateView: () => {},
  getMonthString: () => '',
  getWeekDates: () => [],
  next: () => {},
  prev: () => {},
});
