import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';


export const AlertText = styled(Typography)(({ theme }) => ({
  background: theme.palette.colors.blue[100],
  borderRadius: '4px',
  padding: '1rem',
  display: 'flex',
  alignSelf: 'stretch',
  alignItems: 'center',
  color: theme.palette.colors.blue[500],
}));
