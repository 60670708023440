import { useEffect } from 'react';
import { Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Plus, Tag01 } from '@untitled-ui/icons-react';

import PageLayout from '@/layouts/PageLayout';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import SalesList from '@/features/sales/components/SalesList';
import FilterProvider from '@/contexts/FilterContext';
import FilterFields from '@/features/sales/components/FilterFields';
import { SaleStateEnum } from '@/services/SellerApi';
import useSaleFilterSchema from './filterSchema';

export const Sales = () => {
  const { track } = useAnalytics();
  const { t } = useTranslation(['sales']);

  useEffect(() => {
    track('Sales/access');
  }, []);

  const defaultValues = {
    currentState: [SaleStateEnum.PaymentComplete],
  };

  const filterSchema = useSaleFilterSchema(t);

  return (
    <FilterProvider schema={filterSchema} defaultValue={defaultValues} contextName='sales'>
      <Helmet>
        <title>{t('sales_page_title')}</title>
      </Helmet>

      <PageLayout
        title={t('title')}
        slots={{ FilterFields }}
        slotProps={{ searchInput: { placeholder: t('filter.searchbox.placeholder') } }}
        actions={{
          primary: {
            Icon: <Plus />,
            title: t('page_layout.new_sale'),
            label: t('page_layout.new_sale'),
            href: '../direct-links/sale/create',
          },
          secondary: [
            {
              href: 'tags',
              Icon: <Tag01 />,
              title: t('page_layout.tags_label'),
            },
          ],
        }}
      >
        <Grid container paddingY={2} paddingX={{ xs: 0, sm: 4, md: 6 }}>
          <SalesList />
        </Grid>
      </PageLayout>
    </FilterProvider>
  );
};

export default Sales;
