import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Ticket02 } from '@untitled-ui/icons-react';
import { Box, CircularProgress, Grid } from '@mui/material';

import { Coupon, Partnership, PartnershipsApiGetPartnershipCouponsRequest } from '@/services/SellerApi';
import Pagination from '@/components/Pagination';
import { enqueueSnackbar } from 'notistack';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import { useSellerApi } from '@/hooks/useSellerApi';

import { CouponsTitle, PaginationContainer } from './styles';
import { scrollIntoElement } from '@/utils';
import PartnershipCouponCard from './PartnershipCouponCard';

type Props = {
  partnership: Partnership;
  partnershipId: string;
  isProvider: boolean;
  maxPercentageDiscount?: number | null;
  parentLoading?: boolean;
};

export const PartnershipCouponList = (props: Props) => {
  const { partnership, partnershipId, isProvider, parentLoading } = props;

  const { track } = useAnalytics();
  const { partnershipsApi } = useSellerApi();
  const { t } = useTranslation(['partnership']);

  const [loading, setLoading] = useState(true);

  const [partnershipCoupons, setPartnershipCoupons] = useState<Coupon[]>();

  const [totalPartnershipCoupons, setTotalPartnershipCoupons] = useState(0);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 20 });

  const getPartnershipCoupons = async () => {
    const payload: PartnershipsApiGetPartnershipCouponsRequest = {
      partnershipId,
      limit: paginationModel.pageSize,
      offset: paginationModel.page * paginationModel.pageSize,
    };

    try {
      setLoading(true);
      const { data, headers } = await partnershipsApi.getPartnershipCoupons(payload);

      setPartnershipCoupons(data);
      setTotalPartnershipCoupons(parseInt(headers['x-pagination-total-count'] ?? '0'));
    } catch (error) {
      enqueueSnackbar(t('detail.could_not_load_arranged_partnership_coupons'), { variant: 'error' });

      track('partnershipDetail/retrieveArrangedPartnershipCouponsFail', { payload });
    } finally {
      setLoading(false);
    }
  };

  const handleScrollToTop = () => scrollIntoElement('#coupon-list-title');

  useEffect(() => {
    getPartnershipCoupons();
  }, [paginationModel]);

  return (
    <Fragment>
      {loading || partnershipCoupons?.length ? (
        <Grid item xs={12}>
          <Grid container spacing={1} position='relative'>
            <Grid item xs={12} display='flex' alignItems='center' flexDirection='row' gap={1}>
              <CouponsTitle id='coupon-list-title' variant='regularBold'>
                <Ticket02 />

                {t('detail.coupon_list.title')}
              </CouponsTitle>
            </Grid>

            {partnershipCoupons?.length ? (
              <Grid item xs={12}>
                <Grid container position='relative'>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      {partnershipCoupons.map((coupon) => (
                        <Grid item xs={12} key={coupon.id}>
                          <PartnershipCouponCard
                            coupon={coupon}
                            isProvider={isProvider}
                            partnership={partnership}
                            loading={loading}
                            onUpdateCoupon={getPartnershipCoupons}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>

                  {totalPartnershipCoupons > paginationModel.pageSize ? (
                    <PaginationContainer item xs={12}>
                      <Pagination
                        page={paginationModel.page}
                        count={totalPartnershipCoupons}
                        rowsPerPageOptions={[5, 10, 20, 50]}
                        rowsPerPage={paginationModel.pageSize}
                        onRowsPerPageChange={(event) => {
                          handleScrollToTop();
                          setPaginationModel({ pageSize: parseInt(event.target.value, 10), page: 0 });
                        }}
                        onPageChange={(_, newPage) => {
                          handleScrollToTop();
                          setPaginationModel({ ...paginationModel, page: newPage });
                        }}
                      />
                    </PaginationContainer>
                  ) : null}
                </Grid>
              </Grid>
            ) : null}

            {loading && !parentLoading ? (
              <Grid item xs={12} position='absolute' width='100%' height='100%'>
                <Box display='flex' alignItems='center' justifyContent='center' width='100%' height='156px'>
                  <CircularProgress />
                </Box>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      ) : null}
    </Fragment>
  );
};

export default PartnershipCouponList;
