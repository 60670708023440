import Chip from '@/components/Chip';
import theme from '@/theme';

interface Props {
  installmentNumber: number;
  installmentTotal: number;
}

export const InstallmentChip = (props: Props) => {
  const { installmentNumber, installmentTotal } = props;

  return (
    <Chip
      size='small'
      variant='outlined'
      label={`${installmentNumber} / ${installmentTotal}`}
      color={{
        font: theme.palette.colors.blue[500],
        background: theme.palette.colors.blue[100],
      }}
    />
  );
};

export default InstallmentChip;
