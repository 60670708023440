import { Box, Stack, Typography } from '@mui/material';
import { IconBarrierBlock } from '@tabler/icons-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import AgendaHoverDetails from '@/features/agenda/components/SchedulingCalendar/AgendaHoverDetails';
import isAvailabilityOccupationFull from '@/features/agenda/utils/availability/isAvailabilityOccupationFull';
import { getAvailabilityQuantitiesText } from '@/features/agenda/utils/getAvailabilityQuantitiesText';
import { Availability } from '@/services/SellerApi';
import AvailabilityItensList from '@/features/agenda/components/AvailabilityItensList';
import theme from '@/theme';
import { StyledAvailabilityCard } from '@/features/agenda/components/SchedulingCalendar/styles';
import { StyledCardLink } from '@/features/agenda/components/SchedulingCalendar/AgendaSlot/styles';

interface SlotTypeAvailabilityProps {
  availability: Availability;
}

const SlotTypeAvailability = (props: SlotTypeAvailabilityProps) => {
  const { availability } = props;
  const { t } = useTranslation(['agenda']);
  const { search } = useLocation();
  const isOccupationFull = useMemo(() => isAvailabilityOccupationFull(availability), [availability]);
  const occupation = useMemo(() => getAvailabilityQuantitiesText(availability), [availability]);

  return (
    <AgendaHoverDetails availability={availability} scheduling={undefined}>
      <StyledCardLink href={`./availabilities/${availability.id}${search}`}>
        <StyledAvailabilityCard full={isOccupationFull} sx={{ flexDirection: 'column', alignItems: 'start' }}>
          <Stack sx={{ flexDirection: { xs: 'column', lg: 'row' }, width: '100%', gap: theme.spacing(0.5) }}>
            <Stack>
              <IconBarrierBlock width={16} height={16} />
            </Stack>

            <Typography
              variant='extraSmallSemiBold'
              component='span'
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', lg: 'row' },
                justifyContent: 'space-between',
                width: { md: '100%', lg: '85%' },
              }}
            >
              <Box sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                {t(`filter.availability_type.${availability.type}`)}
              </Box>
              {occupation ? <span>{`(${occupation})`}</span> : null}
            </Typography>
          </Stack>
          <AvailabilityItensList availability={availability} />
        </StyledAvailabilityCard>
      </StyledCardLink>
    </AgendaHoverDetails>
  );
};

export default SlotTypeAvailability;
