import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';

import { useSellerApi } from '@/hooks/useSellerApi';

import { ItemImage } from './styles';

import {
  Partnership,
  PartnershipCommissionType,
  PublicPartnershipItem,
  PublicPartnershipProposal,
} from '@/services/SellerApi';
import { Box, Chip, Tooltip, Typography } from '@mui/material';
import { colors } from '@/theme';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Table from '@/components/Table';
import { formatCents } from '@/utils';
import { precisePercentageMultiplication } from '@/common/partnership';

type ColumnsToRender = 'items' | 'price' | 'commission' | 'maxPercentageDiscount';
type PartnershipItemListProps = {
  proposal?: PublicPartnershipProposal;
  partnership?: Partnership;
  proposalShareCode?: string;
  parentLoading?: boolean;
  columnsToRender?: ColumnsToRender[];
  onPartnershipItemListLoaded?: (partnershipItems: PublicPartnershipItem[]) => void;
};

export const PartnershipItemList = (props: PartnershipItemListProps) => {
  const {
    proposal,
    partnership,
    proposalShareCode,
    parentLoading,
    onPartnershipItemListLoaded = () => {},
    columnsToRender = ['items', 'commission'],
  } = props;

  const { partnershipsApi } = useSellerApi();

  const { t } = useTranslation(['partnership']);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);
  const [programItems, setProgramItems] = useState<PublicPartnershipItem[]>([]);

  const [rowCount, setRowCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });

  const getPartnershipItems = async (shareCode: string) => {
    try {
      setLoading(true);
      const { data, headers } = await partnershipsApi.getPublicPartnershipProposalItems({
        publicPartnershipProposalCode: shareCode,
        include: ['product', 'product.defaultTariffGroupPriceInfo'],
        limit: paginationModel.pageSize,
        offset: paginationModel.page * paginationModel.pageSize,
      });

      setRowCount(parseInt(headers['x-pagination-total-count']));

      setProgramItems(data);
      onPartnershipItemListLoaded(data);
    } catch (error) {
      enqueueSnackbar(t('item_list.could_not_load_partnership_items'));
    } finally {
      setLoading(false);
    }
  };

  const getFormattedComission = (partnershipItem: PublicPartnershipItem) => {
    switch (partnershipItem.commissionType) {
      case 'amount_cents':
        const value = partnershipItem.commissionAmountCents! / 100;

        return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      case 'percentage_fee':
        return `${precisePercentageMultiplication(partnershipItem.commissionPercentageFee!)}%`;
      default:
        return '';
    }
  };

  const getFormattedDiscount = () => {
    const maxDisocunt = proposal?.maxPercentageDiscount ?? 0;

    return `${t('item_list.discount_until')} ${precisePercentageMultiplication(maxDisocunt)}%`;
  };

  const providerLogoUrl = proposal?.provider?.logoUrl ?? partnership?.provider?.logoUrl ?? '';
  const proposalCode = proposalShareCode ?? proposal?.shareCode ?? partnership?.publicProposal?.shareCode ?? '';

  const getProductPrice = (partnershipItem: PublicPartnershipItem) => {
    const discountedPriceCents = (value: number) => {
      if (partnershipItem.commissionType === PartnershipCommissionType.AmountCents) {
        return value - partnershipItem.commissionAmountCents!;
      }

      return value - partnershipItem.commissionPercentageFee! * value;
    };

    const tariffRange = partnershipItem!.product!.defaultTariffGroupPriceInfo!;
    const maxPrice =
      discountedPriceCents(tariffRange.maxPriceCents) >= 0 ? discountedPriceCents(tariffRange.maxPriceCents) : 0;
    const minPrice =
      discountedPriceCents(tariffRange.minPriceCents) >= 0 ? discountedPriceCents(tariffRange.minPriceCents) : 0;

    return `${formatCents(minPrice)} - ${formatCents(maxPrice).replace('R$', '')}`;
  };

  const columns = [
    {
      field: 'items',
      flex: 1,
      sortable: false,
      renderAsLeftTitleOnMobile: true,
      renderHeader: () => {
        return (
          <Typography variant='smallRegular' color={colors.gray[700]}>
            {t('item_list.title')}
          </Typography>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box display='flex' alignItems='center' gap={1}>
            {(params.row.allProducts && providerLogoUrl) || params.row.product?.thumbnailUrl ? (
              <Box width='66px' sx={{ display: { xs: 'none', sm: 'block' } }}>
                {params.row.allProducts ? (
                  <ItemImage src={providerLogoUrl} />
                ) : (
                  <ItemImage src={params.row.product?.thumbnailUrl} />
                )}
              </Box>
            ) : null}

            <Typography variant='smallBold' color={colors.gray[700]}>
              {params.row.allProducts ? 'Todos os produtos' : params.row.product.name}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'commission',
      sortable: false,
      enableOnMobile: true,
      renderHeader: () => (
        <Typography
          variant='smallRegular'
          color={colors.gray[700]}
          component='div'
          display='flex'
          alignItems='center'
          gap={0.5}
        >
          {t('item_list.comission')}

          <Tooltip
            arrow
            placement='top'
            enterTouchDelay={0}
            leaveTouchDelay={60000}
            PopperProps={{ style: { width: 210 } }}
            title={t('item_list.comission_tooltip')}
          >
            <HelpOutlineIcon sx={{ color: colors.gray[400], cursor: 'pointer' }} fontSize='small' />
          </Tooltip>
        </Typography>
      ),
      valueGetter: (params: GridValueGetterParams) => getFormattedComission(params.row),
    },
    {
      field: 'price',
      sortable: false,
      enableOnMobile: true,
      renderHeader: () => t('item_list.price'),
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Chip
            size='small'
            color='primary'
            variant='outlined'
            sx={{ background: colors.blue[100], border: 'none' }}
            label={params.row.allProducts ? '-' : getProductPrice(params.row)}
          />
        );
      },
    },
    ...(proposal?.maxPercentageDiscount
      ? [
          {
            field: 'maxPercentageDiscount',
            sortable: false,
            enableOnMobile: true,
            renderHeader: () => {
              return (
                <Typography variant='smallRegular' color={colors.gray[700]}>
                  {t('item_list.discount')}
                </Typography>
              );
            },
            valueGetter: () => getFormattedDiscount(),
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (proposalCode) {
      getPartnershipItems(proposalCode);
    }
  }, [paginationModel, proposalCode]);

  return (
    <Table
      columns={columns.filter((column) => columnsToRender?.includes(column.field as ColumnsToRender))}
      rows={programItems}
      rowCount={rowCount}
      paginationModel={paginationModel}
      paginationMode='server'
      onPaginationModelChange={(newPaginationModel) => setPaginationModel(newPaginationModel)}
      loading={loading && !parentLoading}
      disableColumnMenu
      autoHeight
      sx={{ paddingX: 0, border: 'none' }}
    />
  );
};

export default PartnershipItemList;
