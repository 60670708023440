import { Box, Grid } from '@mui/material';
import { cpf } from 'cpf-cnpj-validator';
import { useLayoutEffect, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Loading from '@/components/BackdropLoading';
import Button from '@/components/Button';
import DatePicker from '@/components/Form/DatePicker';
import Input from '@/components/Form/Input';
import useAuth from '@/contexts/Auth/hooks/useAuth';
import { DefaultValueFields } from '@/pages/App/Create';
import BrasilApi from '@/services/BrasilApi';
import { colors } from '@/theme';
import { removeMask } from '@/utils/masks';

import { SectionTitle } from './styles';

type Props = {
  onSubmit: (data: DefaultValueFields) => void;
  form: UseFormReturn<DefaultValueFields, unknown>;
  defaultValues: DefaultValueFields;
};

export const OwnerForm = (props: Props) => {
  const { onSubmit, form, defaultValues } = props;
  const { t } = useTranslation(['createApp']);
  const { user } = useAuth();
  const [loadingAddress, setLoadingAddress] = useState(false);

  const { formState, register, handleSubmit, control, setValue } = form;

  const { errors, isValid } = formState;
  const isIdentityTypeCpf = cpf.isValid(defaultValues?.identifier ?? '');

  const getUserAddress = async (cep: string) => {
    setLoadingAddress(true);

    try {
      const { data } = await BrasilApi.getAddressByCep({ cep });

      if (data?.cep) {
        setValue('uf', data.state, { shouldValidate: true });
        setValue('city', data.city, { shouldValidate: true });
        setValue('street', data.street, { shouldValidate: true });
        setValue('district', data.neighborhood, { shouldValidate: true });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingAddress(false);
    }
  };

  useLayoutEffect(() => {
    if (defaultValues?.identifier && isIdentityTypeCpf) {
      setValue('ownerCpf', defaultValues.identifier, { shouldValidate: true });
    }

    if (user?.name && isIdentityTypeCpf) {
      setValue('ownerName', user.name, { shouldValidate: true });
    }
  }, []);

  return (
    <Grid
      container
      rowSpacing={2}
      component='form'
      alignItems='center'
      justifyContent='center'
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item xs={12}>
        <Box display='flex' gap={3} alignItems='flex-start' flexDirection={{ xs: 'column', sm: 'row' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SectionTitle variant='regularBold' color={colors.gray[800]}>
                {t('final_step_data.owner_data_title')}
              </SectionTitle>
            </Grid>

            <Grid item xs={12}>
              <Input
                fullWidth
                required
                id='ownerName'
                label={t('owner_name_label')}
                error={!!errors['ownerName']}
                helperText={errors['ownerName']?.message}
                tooltipProps={{
                  placement: 'right-start',
                  title: t('owner_name_tooltip_description'),
                }}
                {...register('ownerName')}
              />
            </Grid>

            <Grid item xs={12}>
              <Input
                required
                fullWidth
                mask='cpf'
                label='CPF'
                id='ownerCpf'
                error={!!errors['ownerCpf']}
                helperText={errors['ownerCpf']?.message}
                {...register('ownerCpf')}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name='ownerBirthdate'
                render={({ field: { onChange, value, name, ...rest } }) => (
                  <DatePicker
                    required
                    fullWidth
                    value={value || null}
                    {...register(name)}
                    error={!!errors[`${name}`]}
                    label={t('owner_birthdate_label')}
                    helperText={errors[`${name}`]?.message}
                    onChange={(date) => onChange(date)}
                    {...rest}
                  />
                )}
              />
            </Grid>
          </Grid>

          {isIdentityTypeCpf ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SectionTitle variant='regularBold' color={colors.gray[800]}>
                  {t('final_step_data.address.title')}
                </SectionTitle>
              </Grid>

              <Grid item xs={12} mt='6px'>
                <Input
                  fullWidth
                  required
                  id='zipCode'
                  mask='cep'
                  label={t('final_step_data.address.zip_code_field_label')}
                  error={!!errors['zipCode']}
                  helperText={errors['zipCode']?.message}
                  {...register('zipCode')}
                  onBlur={(e) => {
                    getUserAddress(removeMask(e.target.value ?? ''));
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Input
                      required
                      fullWidth
                      label={t('final_step_data.address.city_field_label')}
                      id='city'
                      error={!!errors['city']}
                      helperText={errors['city']?.message}
                      {...register('city')}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Input
                      required
                      fullWidth
                      label={t('final_step_data.address.uf_field_label')}
                      id='uf'
                      error={!!errors['uf']}
                      helperText={errors['uf']?.message}
                      {...register('uf')}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Input
                  fullWidth
                  required
                  id='street'
                  label={t('final_step_data.address.street_field_label')}
                  error={!!errors['street']}
                  helperText={errors['street']?.message}
                  {...register('street')}
                />
              </Grid>

              <Grid item xs={12}>
                <Input
                  fullWidth
                  required
                  id='district'
                  label={t('final_step_data.address.district_field_label')}
                  error={!!errors['district']}
                  helperText={errors['district']?.message}
                  {...register('district')}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Input
                      required
                      fullWidth
                      label={t('final_step_data.address.number_field_label')}
                      id='number'
                      error={!!errors['number']}
                      helperText={errors['number']?.message}
                      {...register('number')}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Input
                      fullWidth
                      label={t('final_step_data.address.complement_field_label')}
                      id='complement'
                      error={!!errors['complement']}
                      helperText={errors['complement']?.message}
                      {...register('complement')}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Button type='submit' fullWidth variant='contained' disabled={!isValid}>
          {t('final_step_data.submit_button')}
        </Button>
      </Grid>

      {loadingAddress ? <Loading /> : null}
    </Grid>
  );
};

export default OwnerForm;
