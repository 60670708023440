import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Box, Grid, Typography } from '@mui/material';

import theme from '@/theme';
import useAccount from '@/hooks/useAccount';
import { useSellerApi } from '@/hooks/useSellerApi';
import { getMonthViewDates, isTimeInRange } from '@/utils';
import ReservationCalendar from '@/components/PartnershipPortal/ReservationCalendar';
import ReservationTimeSelector from '@/components/PartnershipPortal/ReservationTimeSelector';
import ReservationTariffSelector, {
  SelectedTariffsType,
} from '@/components/PartnershipPortal/ReservationTariffSelector';
import {
  SellingMode,
  ScheduleTime,
  DetailedScheduling,
  DateAndTimeScheduleQuantity,
  Tariff,
  DateAndTimeTariffScheduleQuantity,
  TariffGroupsApiGetArrangedProductDetailedDefaultTariffGroupRequest,
  DetailedTariffGroup,
  SchedulingsApiGetArrangedProductDetailedSchedulingsRequest,
  TariffScheduleUnitPrice,
  PublicPartnershipItem,
  PublicProduct,
} from '@/services/SellerApi';
import usePartnershipPortalCart from '@/hooks/usePartnershipPortalCart';
import { enqueueSnackbar } from 'notistack';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import { calculatePriceBasedOnThePartnershipCommission } from '@/common/partnership';
import Bugsnag from '@/services/bugsnag';
import { NotifiableError } from '@bugsnag/js';
import { debounce } from '@/utils/debounce';
export interface ModifiedScheduleTime extends ScheduleTime {
  occupation: DateAndTimeScheduleQuantity['occupation'];
  isNotAvailable: boolean;
}

interface Params {
  time?: string;
  date?: Date;
  tariffs: SelectedTariffsType[];
  requiresSeatSelection: boolean;
}

export interface ReservationFormProps {
  value?: Params;
  product: PublicProduct;
  onChange: (params: Params) => void;
  partnershipItem?: PublicPartnershipItem | null;
}

type AggregatedDetailedSchedulings = { [key: string]: DetailedScheduling[] };

export const ReservationForm = (props: ReservationFormProps) => {
  const { product, partnershipItem, onChange, value } = props;

  const { t } = useTranslation(['PartnershipPortalReservationForm']);

  const { track } = useAnalytics();
  const { selectedAccount } = useAccount();
  const { schedulingsApi, tariffGroupsApi } = useSellerApi();

  const schedulingDataAutoLoadThreshold = 3;
  const [loading, setLoading] = useState(false);
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [autoLoadRemainingAttempts, setAutoLoadRemainingAttempts] = useState(schedulingDataAutoLoadThreshold);

  const [selectedTime, setSelectedTime] = useState<string | undefined>(value?.time);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(value?.date ? new Date(value.date) : undefined);
  const [selectedTariffs, setSelectedTariffs] = useState<SelectedTariffsType[]>(value?.tariffs || []);
  const [requiresSeatSelection, setRequiresSeatSelection] = useState<boolean>(false);
  const [detailedSchedulings, setDetailedSchedules] = useState<AggregatedDetailedSchedulings>({});
  const [defaultTariffGroup, setDefaultTariffGroup] = useState<DetailedTariffGroup>();
  const controller = useRef<AbortController | null>(null);
  const loadedDates = useRef(new Set<string>()).current;

  const { getCartItemsSelectedQuantity } = usePartnershipPortalCart();

  const isDateLoaded = (key: string) => {
    return loadedDates.has(key);
  };

  const addDateToLoadedDates = (key: string) => {
    loadedDates.add(key);
  };

  const getArrangedProductDetailedSchedules = async (
    payload: SchedulingsApiGetArrangedProductDetailedSchedulingsRequest,
    abortController: AbortController,
  ) => {
    return await schedulingsApi.getArrangedProductDetailedSchedulings(payload, {
      signal: abortController.signal,
    });
  };

  const getAllArrangedProductDetailedSchedules = async (since: string, until: string) => {
    const dateCacheKey = `${since}-${until}`;

    if (isDateLoaded(dateCacheKey)) {
      return;
    }
    setLoading(true);
    const limit = 10;

    const payload: SchedulingsApiGetArrangedProductDetailedSchedulingsRequest = {
      arrangedProductId: product!.id,
      appId: selectedAccount!.appId!,
      include: ['finalTariffGroup.tariffs.type'],
      since,
      until,
      limit,
    };

    try {
      controller.current = new AbortController();

      // make the first request just to know how many pages we need to fetch
      const { headers } = await getArrangedProductDetailedSchedules(
        {
          ...payload,
          offset: 0,
          limit: 1,
        },
        controller.current,
      );

      const totalCount = parseInt(headers['x-pagination-total-count'] ?? '0');
      const totalPages = Math.ceil(totalCount / limit);

      const promises = [];

      // make requests for the total pages
      for (let page = 0; page <= totalPages - 1; page++) {
        promises.push(
          getArrangedProductDetailedSchedules(
            {
              ...payload,
              offset: page * limit,
            },
            controller.current,
          ),
        );
      }

      // wait for all the requests to finish
      const dataArray = await Promise.all(promises);

      // merge all the data into one array
      const allPages = dataArray.reduce((accumulator: DetailedScheduling[], response) => {
        return accumulator.concat(response.data);
      }, []);

      // filter out the available dates
      for (const detailedScheduling of allPages) {
        if (reservationDayIsAvailable(detailedScheduling)) {
          if (detailedScheduling.date in detailedSchedulings) {
            detailedSchedulings[detailedScheduling.date] = [
              ...detailedSchedulings[detailedScheduling.date],
              detailedScheduling,
            ];
          } else {
            detailedSchedulings[detailedScheduling.date] = [detailedScheduling];
          }
        }
      }

      // set the state with the new data
      setDetailedSchedules(detailedSchedulings);

      addDateToLoadedDates(dateCacheKey);
      setLoading(false);
    } catch (error) {
      if ((error as Error)?.name !== 'CanceledError') {
        enqueueSnackbar(t('snackbar.could_not_load_scheduling'), { variant: 'error' });

        track('productShowCaseReservationForm/retrieveArrangedProductDetailedSchedulingsFail', { payload });
        Bugsnag.notify(error as NotifiableError);
        setLoading(false);
      }
    }
  };

  const getArrangedProductDetailedDefaultTariffGroup = async () => {
    const payload: TariffGroupsApiGetArrangedProductDetailedDefaultTariffGroupRequest = {
      appId: selectedAccount!.appId!,
      arrangedProductId: product!.id,
      include: ['tariffs.type', 'tariffs.group'],
    };

    try {
      setLoading(true);
      const { data } = await tariffGroupsApi.getArrangedProductDetailedDefaultTariffGroup(payload);

      setDefaultTariffGroup(data);
      track('productShowCaseReservationForm/retrieveDefaultTariffGroupSuccess', { payload });
    } catch (error) {
      enqueueSnackbar(t('snackbar.could_not_load_default_tariff_group'), { variant: 'error' });

      track('productShowCaseReservationForm/retrieveDefaultTariffGroupFail', { payload });
      Bugsnag.notify(error as NotifiableError);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (date: Date) => format(date, 'yyyy-MM-dd');

  const onOpenCalendar = () => {
    const date = new Date();
    const { startDate, endDate } = getMonthViewDates(date.getFullYear(), date.getMonth());

    getAllArrangedProductDetailedSchedules(formatDate(startDate), formatDate(endDate));
  };

  const findDetailedScheduling = (date: string, time?: string): DetailedScheduling => {
    if (time) {
      const t = detailedSchedulings![date].find((ds) =>
        isTimeInRange(time, ds.consumableFromTime!, ds.consumableToTime!),
      )!;

      return t;
    }

    return detailedSchedulings![date][0];
  };

  const findDateDetailedScheduling = (date: string): DetailedScheduling[] => {
    return detailedSchedulings![date];
  };

  const navigateToNextMonth = () => {
    const nextMonthButton = document.getElementById('nextMonthButton');

    if (nextMonthButton) {
      nextMonthButton.click();
    }
  };

  const onMonthChange = (currentDate: Date | null) => {
    if (!currentDate) {
      return;
    }

    const { startDate, endDate } = getMonthViewDates(currentDate.getFullYear(), currentDate.getMonth());

    getAllArrangedProductDetailedSchedules(formatDate(startDate), formatDate(endDate));
  };
  const isScheduleAvailable = (schedule: ModifiedScheduleTime, currentSelectedDate: string) => {
    // Always is available when the available amount is null
    if (schedule?.occupation?.availableAmount === null) {
      return true;
    }

    if (schedule.occupation.availableAmount <= 0) {
      return false;
    }

    const selectedAvailableQuantity = getCartItemsSelectedQuantity(product.id, currentSelectedDate, schedule.time);

    if (selectedAvailableQuantity >= schedule.occupation.availableAmount) {
      return false;
    }

    return true;
  };

  const generateTariffRemaining = (
    totalSelectedTariffQuantities: number,
    globalLimit?: number | null,
    tariffLimit?: number | null,
  ): number => {
    const hasGlobalLimit = globalLimit !== null;
    const hasTariffLimit = tariffLimit !== null;

    // General occupation is unlimited or checkbox "limit_product_availability" is checked.
    // Consider only the tariff occupation
    if (hasTariffLimit && (!hasGlobalLimit || globalLimit === 0)) {
      return tariffLimit! - totalSelectedTariffQuantities;
    }

    if (hasGlobalLimit && totalSelectedTariffQuantities > globalLimit!) {
      return 0;
    }

    // Infinite availability
    if (!hasGlobalLimit && !hasTariffLimit) {
      return Infinity;
    }

    if (hasGlobalLimit && !hasTariffLimit) {
      return globalLimit! - totalSelectedTariffQuantities;
    }

    if (tariffLimit === undefined) {
      return 0;
    }

    const minimumValue = Math.min(
      globalLimit! - totalSelectedTariffQuantities,
      tariffLimit! - totalSelectedTariffQuantities,
    );

    return minimumValue;
  };

  const getTariffRemainingQuantityForDateOnlyProduct = (
    selectedDateDetails: DetailedScheduling,
    selectedTariffQuantities: number,
    tariffScheduleQuantities: DateAndTimeTariffScheduleQuantity,
    date: Date,
  ) => {
    const tariffOccupationAmountAvailable =
      tariffScheduleQuantities!.occupationSchedule[0]?.occupation?.availableAmount;

    const cartItemSelectedTariffQuantities = getCartItemsSelectedQuantity(product.id, formatDate(date));
    const generalOccupation = selectedDateDetails.occupationRates.scheduleQuantities[0]?.occupation?.availableAmount;

    return generateTariffRemaining(
      cartItemSelectedTariffQuantities + selectedTariffQuantities,
      generalOccupation,
      tariffOccupationAmountAvailable,
    );
  };

  const calculateRemainingTariffQuantity = (tariff: Tariff) => {
    if (product.sellingMode === SellingMode.WithoutDate) {
      return Infinity;
    }

    const remainingTariffQuantity = 0;

    if (!selectedDate) {
      return remainingTariffQuantity;
    }

    const selectedDateDetails = findDetailedScheduling(formatDate(selectedDate), selectedTime)!;

    const tariffScheduleQuantities = selectedDateDetails.occupationRates.tariffScheduleQuantities.find(
      (tsq) => tsq.tariffId === tariff.id,
    )!;

    const selectedTariffQuantities = selectedTariffs
      .filter((st) => st.id === tariff.id)
      .reduce((accumulator, currentSelectedTariff) => accumulator + currentSelectedTariff.selectedQuantity, 0);

    if (product.sellingMode === SellingMode.WithDateOnly) {
      return getTariffRemainingQuantityForDateOnlyProduct(
        selectedDateDetails,
        selectedTariffQuantities,
        tariffScheduleQuantities,
        selectedDate,
      );
    }

    if (!selectedTime) {
      return remainingTariffQuantity;
    }

    const tariffOccupation = tariffScheduleQuantities!.occupationSchedule.find((sq) => sq.time === selectedTime);

    const cartItemSelectedTariffQuantities = getCartItemsSelectedQuantity(
      product.id,
      formatDate(selectedDate),
      selectedTime,
    );

    const generalOccupation = selectedDateDetails.occupationRates.scheduleQuantities.find(
      (sq) => sq.time === selectedTime,
    );

    return generateTariffRemaining(
      selectedTariffQuantities + cartItemSelectedTariffQuantities,
      generalOccupation!.occupation?.availableAmount,
      tariffOccupation!.occupation?.availableAmount,
    );
  };

  const reservationDayIsAvailable = (detailedScheduling: DetailedScheduling) => {
    return detailedScheduling.occupationRates.tariffScheduleQuantities?.some((tsq) =>
      tsq.occupationSchedule.some((os) => {
        const date = detailedScheduling.date;

        if (os.occupation.availableAmount === null) {
          return true;
        }

        const selectedQuantity = getCartItemsSelectedQuantity(product.id, date, os.time ?? undefined);
        return selectedQuantity < os.occupation.availableAmount;
      }),
    );
  };

  const getTariffsWithCalculatedPriceAndRemainingQuantity = (
    tariffs: Tariff[],
    scheduleSup?: TariffScheduleUnitPrice,
  ) => {
    return tariffs.map((tariff) => {
      let finalPriceCents = tariff.priceCents;

      if (scheduleSup) {
        for (const tariffSup of scheduleSup.tariffs) {
          if (tariffSup.tariffId === tariff.id) {
            finalPriceCents = tariffSup.priceCents;
            break;
          }
        }
      }

      return {
        ...tariff,
        remaingQuantityToSelect: calculateRemainingTariffQuantity(tariff),
        finalPriceCents,
        priceBasedOnThePartnershipCommission: partnershipItem
          ? calculatePriceBasedOnThePartnershipCommission(finalPriceCents, partnershipItem)
          : finalPriceCents,
      };
    });
  };

  const scheduleTimes: ModifiedScheduleTime[] = useMemo(() => {
    if (!selectedDate) {
      return [];
    }

    const selectedDateDetails = findDateDetailedScheduling(formatDate(selectedDate));

    if (!selectedDateDetails) {
      return [];
    }

    // Merging all the equal tariff occupationSchedule objects into one and and parsing them into an array of objects. ex.:({minutes, time, occupation})
    const schedules = selectedDateDetails.reduce((acc: ModifiedScheduleTime[], dateDetails: DetailedScheduling) => {
      // Reduce over tariffScheduleQuantities to merge all occupationSchedule objects into one
      const mergedOccupationSchedule = dateDetails.occupationRates.tariffScheduleQuantities.reduce(
        (tariffScheduleQuantitiesAccumulator, currentTariffScheduleQuantity) =>
          // Reduce over occupationSchedule to merge all time entries into one object
          currentTariffScheduleQuantity.occupationSchedule.reduce(
            (occupationScheduleAccumulator, currentOccupationSchedule) => ({
              ...occupationScheduleAccumulator,
              // Create an object with time as key and the corresponding schedule as value
              [currentOccupationSchedule.time!]: currentOccupationSchedule,
            }),
            tariffScheduleQuantitiesAccumulator,
          ),
        // Initial empty object for the outer reduce
        {},
      );

      const modifiedScheduleTimes = Object.values(mergedOccupationSchedule) as ModifiedScheduleTime[];
      return [...acc, ...modifiedScheduleTimes];
    }, []);

    return schedules.map((schedule) => ({
      ...schedule,
      isNotAvailable: !isScheduleAvailable(schedule, formatDate(selectedDate)),
    }));
  }, [selectedDate]);

  const tariffGroup = useMemo(() => {
    if (product.sellingMode === SellingMode.WithoutDate && defaultTariffGroup) {
      return {
        ...defaultTariffGroup,
        calculatedTariffs: getTariffsWithCalculatedPriceAndRemainingQuantity(defaultTariffGroup.tariffs!),
      };
    }

    if (!selectedDate || (product.sellingMode === SellingMode.WithDateAndTime && !selectedTime)) {
      return;
    }

    const selectedDateDetails = findDetailedScheduling(formatDate(selectedDate), selectedTime);
    const scheduleSup = selectedDateDetails.tariffScheduleUnitPrices.find((tsup) => tsup.time === selectedTime);

    return {
      ...selectedDateDetails?.finalTariffGroup,
      calculatedTariffs: getTariffsWithCalculatedPriceAndRemainingQuantity(
        selectedDateDetails?.finalTariffGroup?.tariffs,
        scheduleSup,
      ),
    };
  }, [selectedDate, selectedTime, defaultTariffGroup, product.sellingMode]);

  // Reset the selectedTariffs when the tariffGroup changes
  useEffect(() => {
    setSelectedTariffs([]);
  }, [tariffGroup]);

  useEffect(() => {
    if (autoLoadRemainingAttempts > 0 && !loading && !Object.keys(detailedSchedulings!).length && isDatePickerVisible) {
      navigateToNextMonth();
      setAutoLoadRemainingAttempts(autoLoadRemainingAttempts - 1);
    }
  }, [autoLoadRemainingAttempts, loading, detailedSchedulings]);

  useEffect(() => {
    loadedDates.clear();
    if (product && product.sellingMode === SellingMode.WithoutDate) {
      getArrangedProductDetailedDefaultTariffGroup();
    }
  }, []);

  useEffect(() => {
    onChange({
      date: selectedDate,
      time: selectedTime,
      tariffs: selectedTariffs,
      requiresSeatSelection: requiresSeatSelection,
    });
  }, [selectedDate, selectedTime, selectedTariffs]);

  if (!product) {
    return <Alert severity='error'>{t('product_not_found')}</Alert>;
  }

  return (
    <Grid container spacing={3} component='form' position='relative'>
      {product.sellingMode === SellingMode.WithDateAndTime || product.sellingMode === SellingMode.WithDateOnly ? (
        <Grid item xs={12}>
          <ReservationCalendar
            loading={loading}
            value={value?.date}
            open={isDatePickerVisible}
            onOpen={() => {
              onOpenCalendar();
              setIsDatePickerVisible(true);
            }}
            onClose={() => {
              setIsDatePickerVisible(false);
              setAutoLoadRemainingAttempts(schedulingDataAutoLoadThreshold);
            }}
            onChange={(value) => {
              setSelectedDate(value ?? undefined);

              if (value) {
                const ds = findDetailedScheduling(formatDate(value), selectedTime);
                setRequiresSeatSelection(ds?.requiresSeatSelection ?? false);
              }
            }}
            onMonthChange={(month: Date | null) => {
              controller.current?.abort();
              debounce(onMonthChange, 500)(month);
            }}
            label={t('date_label')}
            dates={Object.keys(detailedSchedulings)}
            validateDay={(date) => reservationDayIsAvailable(findDetailedScheduling(date))}
            views={['year', 'month', 'day']}
          />
        </Grid>
      ) : null}

      {scheduleTimes?.length && product.sellingMode === SellingMode.WithDateAndTime ? (
        <Grid item xs={12}>
          <Box display='flex' flexDirection='column' gap={1.75} sx={{ overflow: 'hidden', width: '100%' }}>
            <Typography variant='smallSemiBold' color={theme.palette.colors.gray[700]}>
              {t('time_label')}
            </Typography>

            <ReservationTimeSelector value={selectedTime} onChange={setSelectedTime} scheduleTimes={scheduleTimes} />
          </Box>
        </Grid>
      ) : null}

      {tariffGroup?.tariffs?.length ? (
        <Grid item xs={12}>
          <Box display='flex' flexDirection='column' gap={1.75}>
            <Typography variant='smallSemiBold' color={theme.palette.colors.gray[700]}>
              {t('tariff_label')}
            </Typography>

            <ReservationTariffSelector
              selectedTariffs={selectedTariffs}
              onChangeSelectedTariffs={setSelectedTariffs}
              tariffs={tariffGroup.calculatedTariffs}
            />
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default ReservationForm;
