import { Typography, styled } from '@mui/material';

export const Container = styled(Typography)(({ theme }) => ({
  gap: '6px',
  display: 'flex',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  flexDirection: 'row',
  alignItems: 'center',
  textOverflow: 'ellipsis',
  fontSize: '0.875rem',
  fontWeight: 500,
  color: theme.palette.colors.gray[700],
}));
