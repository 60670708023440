import getNestedValue from '@/features/config/context/utils/getNestedValue';
import getOrSetObjectByPath from '@/features/config/context/utils/getOrSetObjectByPath';
import getUrlSearch from '@/features/config/context/utils/getUrlSearch';
import isSearchParamsEmpty from '@/features/config/context/utils/isSearchParamsEmpty';
import { ConfigContextStore } from '@/features/config/types/config';
type Params<State> = ConfigContextStore<State> & {
  newValue: { state: State };
};

const updateStateAndPersist = <State>(params: Params<State>) => {
  const { localStorageKey, newValue, keys } = params;
  const { state } = newValue;

  const searchParams = getUrlSearch();

  const urlStateKeys = keys.filter((param) => param.urlKey);

  const stateToSaveOnUrl: Record<string, unknown> = {};

  urlStateKeys.forEach((key) => {
    const stateObject = getNestedValue(state, key.stateKey);
    if (stateObject) {
      if (key.urlKey) {
        stateToSaveOnUrl[key.urlKey] = stateObject;
      }
    }
  });

  Object.keys(stateToSaveOnUrl).forEach((key) => {
    if (isSearchParamsEmpty(stateToSaveOnUrl[key])) {
      searchParams.delete(key);
      return;
    }

    searchParams.set(key, stateToSaveOnUrl[key] as string);
  });

  window.history.replaceState(null, '', `?${searchParams.toString()}`);

  let stateToSaveOnLocalStorage: Record<string, unknown> = {};

  const keysToPersistOnStorage = keys.filter((param) => param.persistOnLocalStorage);

  keysToPersistOnStorage.forEach((key) => {
    const obj = getOrSetObjectByPath(state, key.stateKey);

    stateToSaveOnLocalStorage = { ...stateToSaveOnLocalStorage, ...obj };
  });

  if (Object.keys(stateToSaveOnLocalStorage).length > 0) {
    const stateToSaveString = JSON.stringify({ state: stateToSaveOnLocalStorage });
    localStorage.setItem(localStorageKey, stateToSaveString);
  }

  return newValue;
};

export default updateStateAndPersist;
