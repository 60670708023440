import { useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

import AuthenticationButtons from '@/components/AuthenticationButtons';

import PlanneLogoSymbol from '@/assets/logos/planneVectorLogo.svg';

import theme from '@/theme';

import { Bar, Logo } from './styles';

export const HeaderBar = () => {
  const location = useLocation();
  const showHeaderBar = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Bar>
      <div>{showHeaderBar ? <Logo src={PlanneLogoSymbol} alt='Planne' /> : null}</div>

      <AuthenticationButtons shouldReturn redirectTo={location} />
    </Bar>
  );
};

export default HeaderBar;
