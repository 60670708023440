import { TimeObject } from '@/features/agenda/types';

const getMinutesHoursDaysFromMinutes = (minutes: number): TimeObject => {
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const remainingHours = hours % 24;
  const remainingMinutes = minutes % 60;

  return {
    days,
    hours: remainingHours,
    minutes: remainingMinutes,
  };
};

export default getMinutesHoursDaysFromMinutes;
