import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import QRCode from 'react-qr-code';

export const TextButton = styled(Button)(() => ({
  textTransform: 'none',
  color: 'black',
  paddingLeft: '4px',

  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export const QrCode = styled(QRCode)(() => ({
  display: 'block',
  height: 'auto',
  maxWidth: '100%',
  width: '100%',
  marginRight: '8px',
  border: '1px solid #000000',
  padding: '8px',
}));
