import { Grid, IconButton, Stack, Typography } from '@mui/material';
import { IconCalendar, IconPlus, IconTrash } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Control, Controller, useFieldArray, useFormState, useWatch } from 'react-hook-form';
import { get } from 'lodash';

import Button from '@/components/Button';
import theme from '@/theme';
import Select from '@/components/Form/Select';
import { CouponUsageRestrictionsTypeEnum, RecurrenceWeekdayEnum } from '@/services/SellerApi';
import { RestrictionContainer } from './styles';
import DateTimePicker from '@/components/Form/DateTimePicker';
import CustomDatePicker from '@/components/Form/DatePicker';
import WeekDaysSelect from '@/components/Form/WeekDaysSelect';
import Input from '@/components/Form/Input';
import ModalOnDesktopDrawerOnMobile from '@/components/Modal/ModalOnDesktopDrawerOnMobile';
import { Fragment, useMemo, useState } from 'react';
import { getWeekDaysValue } from '@/features/coupons/common';

interface Props {
  control: Control;
}

interface ModalProps {
  isOpen: boolean;
  callback: (days: RecurrenceWeekdayEnum[]) => void;
  value?: RecurrenceWeekdayEnum[] | null;
}

const initialRestrictionValue = {
  type: null,
  fromDate: null,
  toDate: null,
  date: null,
  weekdays: [],
};

const RestrictionsByDateOfUse = (props: Props) => {
  const { control } = props;

  const { t } = useTranslation(['coupons']);
  const [modal, setModal] = useState<ModalProps | undefined>(undefined);

  const { fields, append, remove, update } = useFieldArray({ name: 'usageRestrictions', control });

  const formValues = useWatch({ control });
  const { errors } = useFormState({ control });

  const restrictionTypes = useMemo(
    () =>
      Object.values(CouponUsageRestrictionsTypeEnum).map((value) => ({
        label: t(`coupon_form.restrictions_by_date_of_use.type.${value}`),
        value,
      })),
    [t, CouponUsageRestrictionsTypeEnum],
  );

  const isOptionEqualToValue = (option: unknown, selectedValue: unknown) =>
    (option as (typeof restrictionTypes)[0]).value === (selectedValue as (typeof restrictionTypes)[0]).value;

  const handleChangeType = (value: unknown, index: number, callback: (param: unknown) => void) => {
    const restriction = formValues?.usageRestrictions?.[index];

    if (restriction && JSON.stringify(value) !== JSON.stringify(restriction.type)) {
      const updatedObject = {
        ...initialRestrictionValue,
        type: value,
      };

      update(index, updatedObject);
    }

    callback(value);
  };

  const handleChangeWeekDays = (days: RecurrenceWeekdayEnum[]) => {
    if (modal) {
      modal.callback(days);
      setModal({ ...modal, value: days });
    }
  };

  return (
    <Fragment>
      <Grid container spacing={4} mb={2}>
        <Grid item xs={12}>
          <Typography variant='smallRegular' color={theme.palette.colors.gray[500]}>
            {t('coupon_form.restrictions_by_date_of_use.description')}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={4}>
            {fields.map((field, index) => (
              <Grid item xs={12} key={field.id}>
                <RestrictionContainer>
                  <Stack direction='row' sx={{ justifyContent: 'space-between', gap: theme.spacing(2) }}>
                    <Typography variant='regularBold' color={theme.palette.colors.gray[800]}>
                      {t('coupon_form.restrictions_by_date_of_use.restriction', { number: index + 1 })}
                    </Typography>

                    <IconButton onClick={() => remove(index)} aria-label='delete'>
                      <IconTrash />
                    </IconButton>
                  </Stack>

                  <Controller
                    name={`usageRestrictions[${index}].type`}
                    control={control}
                    render={({ field: { name, value, onChange, ...rest } }) => {
                      const error = get(errors, name);

                      return (
                        <Select
                          fullWidth
                          id={name}
                          options={restrictionTypes}
                          isOptionEqualToValue={isOptionEqualToValue}
                          onChange={(_, value) => handleChangeType(value, index, onChange)}
                          value={value}
                          error={!!error}
                          helperText={error?.message as string}
                          label={t('coupon_form.restrictions_by_date_of_use.type.label')}
                          placeholder={t('coupon_form.restrictions_by_date_of_use.type.placeholder')}
                          {...rest}
                        />
                      );
                    }}
                  />

                  {formValues?.usageRestrictions?.[index]?.type?.value === CouponUsageRestrictionsTypeEnum.Range ? (
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Controller
                          control={control}
                          name={`usageRestrictions[${index}].fromDate`}
                          render={({ field: { onChange, value, name, ...rest } }) => {
                            const error = get(errors, name);

                            return (
                              <DateTimePicker
                                fullWidth
                                error={!!error}
                                onChange={onChange}
                                value={value || null}
                                label={t('coupon_form.restrictions_by_date_of_use.fromDate.label')}
                                placeholder={t('coupon_form.restrictions_by_date_of_use.fromDate.placeholder')}
                                helperText={error?.message as string}
                                {...rest}
                              />
                            );
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Controller
                          control={control}
                          name={`usageRestrictions[${index}].toDate`}
                          render={({ field: { onChange, value, name, ...rest } }) => {
                            const error = get(errors, name);

                            return (
                              <DateTimePicker
                                fullWidth
                                value={value || null}
                                error={!!error}
                                onChange={onChange}
                                label={t('coupon_form.restrictions_by_date_of_use.toDate.label')}
                                placeholder={t('coupon_form.restrictions_by_date_of_use.toDate.placeholder')}
                                helperText={error?.message as string}
                                {...rest}
                              />
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  ) : null}

                  {formValues?.usageRestrictions?.[index]?.type?.value === CouponUsageRestrictionsTypeEnum.DateOnly ? (
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name={`usageRestrictions[${index}].date`}
                        render={({ field: { onChange, value, name, ...rest } }) => {
                          const error = get(errors, name);

                          return (
                            <CustomDatePicker
                              fullWidth
                              value={value || null}
                              error={!!error}
                              onChange={onChange}
                              label={t('coupon_form.restrictions_by_date_of_use.date.label')}
                              placeholder={t('coupon_form.restrictions_by_date_of_use.date.placeholder')}
                              helperText={error?.message as string}
                              format='dd/MM/yyyy'
                              {...rest}
                            />
                          );
                        }}
                      />
                    </Grid>
                  ) : null}

                  {formValues?.usageRestrictions?.[index]?.type?.value === CouponUsageRestrictionsTypeEnum.WeekDays ? (
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name={`usageRestrictions[${index}].weekdays`}
                        render={({ field: { onChange, value = [], name } }) => {
                          const error = get(errors, name);

                          return (
                            <Input
                              fullWidth
                              value={getWeekDaysValue(value, t)}
                              error={!!error}
                              aria-readonly
                              onClick={() => setModal({ isOpen: true, callback: onChange, value })}
                              label={t('coupon_form.restrictions_by_date_of_use.week_days.label')}
                              placeholder={t('coupon_form.restrictions_by_date_of_use.week_days.placeholder')}
                              helperText={error?.message as string}
                              InputProps={{
                                endAdornment: <IconCalendar size={20} />,
                              }}
                            />
                          );
                        }}
                      />
                    </Grid>
                  ) : null}
                </RestrictionContainer>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Button
            color='secondary'
            variant='outlined'
            onClick={() => append(initialRestrictionValue)}
            startIcon={<IconPlus color='currentColor' />}
          >
            {t('coupon_form.restrictions_by_date_of_use.add_session_button')}
          </Button>
        </Grid>
      </Grid>

      {modal && modal.isOpen ? (
        <ModalOnDesktopDrawerOnMobile
          width='25rem'
          isOpen={modal.isOpen}
          onClose={() => setModal(undefined)}
          title={<Fragment />}
          showCloseButton={false}
        >
          <WeekDaysSelect value={modal.value} onChange={handleChangeWeekDays} />
        </ModalOnDesktopDrawerOnMobile>
      ) : null}
    </Fragment>
  );
};

export default RestrictionsByDateOfUse;
