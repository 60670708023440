import { styled } from '@mui/system';

export const InfoList = styled('div')(() => ({
  padding: '1rem 0',
}));

export const InfoItem = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0.5rem 0',
  gap: theme.spacing(1),

  '&:not(:last-of-type)': {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
}));
