import { QueryFunctionContext } from '@tanstack/react-query';

import { useSellerApi } from '@/hooks/useSellerApi';
import { SchedulingDatePreview, SchedulingsApiGetAppAggregatedSchedulingsRequest } from '@/services/SellerApi';
import { RequiredError } from '@/services/SellerApi/base';

type Query = [
  key: string,
  appId: string | null,
  since: string,
  until: string,
  previewSize: number | undefined,
  productsId: string[],
];

const useGetAllAggregatedSchedulings = () => {
  const { schedulingsApi } = useSellerApi();

  const request = async ({
    queryKey,
    signal,
  }: QueryFunctionContext<Query>): Promise<SchedulingDatePreview[] | undefined> => {
    const [_, appId, since, until, previewSize, productsId] = queryKey;
    if (!since || !until) {
      return;
    }

    const pageSize = 10;
    const payload: SchedulingsApiGetAppAggregatedSchedulingsRequest = {
      appId: (appId as string) ?? '',
      since: since as string,
      until: until as string,
      limit: pageSize,
      offset: 0,
      include: [
        'schedulings.product',
        'schedulings.tariffGroup.priceInfo',
        'schedulings.product.defaultTariffGroupPriceInfo',
        'schedulings.finalTariffGroup.priceInfo',
      ],
      ...(previewSize ? { previewCount: previewSize as number } : {}),
      ...(productsId && productsId.length > 0 ? { productIds: productsId } : {}),
    };

    try {
      const { data, headers } = await schedulingsApi.getAppAggregatedSchedulings({ ...payload, offset: 0, limit: 1 });

      const totalCount = parseInt(headers['x-pagination-total-count'] ?? '0');
      const totalPages = Math.ceil(totalCount / pageSize);

      const promises = [];

      for (let page = 0; page <= totalPages - 1; page++) {
        promises.push(
          schedulingsApi.getAppAggregatedSchedulings(
            {
              ...payload,
              offset: page * pageSize,
              ...(productsId ? { productsId } : {}),
            },
            { signal },
          ),
        );
      }

      const dataArray = await Promise.all(promises);

      const allPages = dataArray.reduce((accumulator: SchedulingDatePreview[], response) => {
        return accumulator.concat(response.data);
      }, data);

      return allPages;
    } catch (error) {
      return error instanceof RequiredError ? [] : undefined;
    }
  };

  return { request };
};

export default useGetAllAggregatedSchedulings;
