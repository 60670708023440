import { format } from 'date-fns';
import { cpf } from 'cpf-cnpj-validator';

import { removeMask } from '@/utils/masks';
import { DefaultValueFields } from '@/pages/App/Create';

export const serializeFormData = (formData: DefaultValueFields) => {
  const getIdentifierType = (identifier: string) => (cpf.isValid(identifier) ? 'cpf' : 'cnpj');

  const establishmentType = formData.establishmentType;
  const establishmentTypeId = establishmentType.id === 'other' ? null : establishmentType.id;
  const merchantCategoryCode = establishmentTypeId ? establishmentType.mcc : null;

  return {
    establishmentTypeId,
    merchantCategoryCode,
    establishmentType: establishmentTypeId ? null : formData.establishmentType.name,
    openedAt: formData.openedAt ? format(formData.openedAt, 'yyyy-MM-dd') : null,
    corporateName: formData.corporateName,
    identifier: removeMask(formData.identifier),
    identifierType: getIdentifierType(formData.identifier),
    fantasyName: cpf.isValid(formData.identifier) ? formData.corporateName : formData?.fantasyName,
    expectedMonthlyRevenueCents: Number(formData.expectedMonthlyRevenueCents.replace(/\D/g, '')),

    contact: {
      phone: `+${removeMask(formData.contactPhone)}`,
    },

    bank: {
      code: formData.bankCode.code,
      agency: removeMask(formData.bankAgency),
      account: removeMask(formData.bankAccount),
      identifier: removeMask(formData.bankIdentifier),
      identifierType: getIdentifierType(formData.bankIdentifier),
      accountType: formData.bankAccountType,
    },

    owner: {
      name: formData.ownerName,
      cpf: removeMask(formData.ownerCpf),
      birthdate: format(formData.ownerBirthdate as Date, 'yyyy-MM-dd'),
    },

    ...(cpf.isValid(formData.identifier)
      ? {
          address: {
            zipCode: removeMask(formData.zipCode!),
            street: formData.street,
            number: formData.number,
            complement: formData?.complement,
            uf: formData.uf,
            city: formData.city,
            district: formData.district,
          },
        }
      : {}),
  };
};
