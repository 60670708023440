import { forwardRef, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { AutocompleteChangeDetails, AutocompleteChangeReason } from '@mui/material';

import countries from '@/data/countries.json';
import Select, { SelectProps } from '../Form/Select';
import { CountryData } from '@/common/countries';
import { ImageWrapper } from './styles';

interface NationalitySelectorProps extends Omit<SelectProps, 'options' | 'value'> {
  value?: CountryData;
}

const NationalitySelector = forwardRef((props: NationalitySelectorProps, inputRef: React.Ref<HTMLSelectElement>) => {
  const { label, name, value, onChange, ...rest } = props;

  const [internalValue, setInternalValue] = useState(value);

  const sortedCountries = useMemo(() => countries.toSorted((a, b) => a.name.localeCompare(b.name)), [countries]);

  const selectedCountry = useMemo(
    () => countries.find((country) => country.alpha3 === internalValue?.alpha3) ?? null,
    [internalValue, countries],
  );

  const handleChange = (
    event: SyntheticEvent,
    eventValue: unknown,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<unknown> | undefined,
  ) => {
    setInternalValue(eventValue as CountryData);

    if (onChange) {
      onChange(event, eventValue, reason, details);
    }
  };

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <Select
      color='primary'
      variant='outlined'
      value={selectedCountry}
      inputRef={inputRef}
      label={label}
      name={name}
      options={sortedCountries}
      onChange={handleChange}
      getOptionLabel={(option) => (option as CountryData)?.name ?? ''}
      getOptionKey={(option) => (option as CountryData)!.code}
      InputProps={{
        startAdornment: (
          <ImageWrapper error={rest.error}>
            {selectedCountry?.image ? (
              <img
                loading='lazy'
                alt={selectedCountry?.name}
                src={selectedCountry?.image}
                style={{ width: '28px', height: '28px' }}
              />
            ) : null}
          </ImageWrapper>
        ),
      }}
      {...rest}
    />
  );
});

export default NationalitySelector;
