import { RescheduleScheduling } from '@/services/sellerApiQuery/model';
import apiDateToDateObject from '@/utils/apiDateToDateObject';
import { isSameDay } from 'date-fns';

const checkRescheduleAvailable = (day: Date, reschedules: RescheduleScheduling[]) => {
  return reschedules.some((schedulings) => {
    const schedulingDate = apiDateToDateObject(schedulings.date);
    return isSameDay(schedulingDate, day);
  });
};

export default checkRescheduleAvailable;
