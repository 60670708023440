import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatISO, isValid } from 'date-fns';

import ComposedDatePicker from '@/components/ComposedDatePicker';
import IndeterminateCheckboxGroup from '@/components/IndeterminateCheckboxGroup';
import Select from '@/components/Form/Select';
import useFilter from '@/hooks/useFilter';
import { FilterOption } from '@/contexts/FilterContext';

export const DirectSalesFilterFields = () => {
  const { selectedFilters, handleChangeFilters, schema } = useFilter();
  const { t } = useTranslation(['directSales']);

  const parseDate = (date?: string | null) => {
    if (!date) return null;

    return new Date(date);
  };

  return (
    <Box display='flex' gap={2} flexDirection='column'>
      <Box display='flex' flexDirection='column' gap={{ xs: 1, md: 2 }}>
        <Typography variant='regularBold'>{t('filter.status.title')}</Typography>

        <IndeterminateCheckboxGroup
          label={t('status.all', { ns: 'sales' })}
          options={schema!.currentState.options!}
          values={
            (selectedFilters?.currentState as string[])?.map(
              (status) => schema!.currentState.options!.find((option) => option.value === status)!,
            ) ?? []
          }
          onChange={(values) => {
            handleChangeFilters(
              'currentState',
              values.map((status) => status.value),
            );
          }}
        />
      </Box>

      <ComposedDatePicker
        since={parseDate(selectedFilters?.since as string)}
        until={parseDate(selectedFilters?.until as string)}
        onChange={(date, key) => {
          if (isValid(date)) {
            handleChangeFilters(key, formatISO(date as Date));
          }
        }}
        slotProps={{
          wrapper: {
            gap: 2,
          },
          until: { label: t('filter.period.until.label') },
          since: { label: t('filter.period.since.label') },
        }}
      />

      <Select
        fullWidth
        openOnFocus
        multiple
        options={schema!.appIds.options!}
        loading={schema!.appIds.loading!}
        label={t('filter.provider_selector')}
        getOptionLabel={(option) => (option as FilterOption).label}
        filterSelectedOptions
        isOptionEqualToValue={(option, value) => (option as FilterOption).value === value}
        onChange={(_, value) => {
          const newValue = value as FilterOption[];

          handleChangeFilters(
            'appIds',
            newValue.map((p) => p.value),
          );
        }}
        value={
          (selectedFilters?.appIds as string[])?.map(
            (appId) => schema!.appIds.options!.find((option) => option.value === appId)!,
          ) ?? []
        }
      />

      <Select
        multiple
        fullWidth
        openOnFocus
        filterSelectedOptions
        options={schema!.productIds.options!}
        loading={schema!.productIds.loading!}
        label={t('filter.product_selector')}
        loadingText={t('filter.products_ids_loading_text')}
        getOptionLabel={(option) => (option as FilterOption).label}
        isOptionEqualToValue={(option, value) => (option as FilterOption).value === value}
        onChange={(_, value) => {
          const newValue = value as FilterOption[];

          handleChangeFilters(
            'productIds',
            newValue.map((p) => p.value),
          );
        }}
        value={
          (selectedFilters?.productIds as string[])?.map(
            (appId) => schema!.productIds.options!.find((option) => option.value === appId)!,
          ) ?? []
        }
      />
    </Box>
  );
};

export default DirectSalesFilterFields;
