function getDateStartAndEndString(start: Date, end: Date, shortFormat = false): string {
  const dateFormat: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: shortFormat ? '2-digit' : 'numeric',
  };
  return `${start.toLocaleDateString(undefined, dateFormat)} - ${end.toLocaleDateString(undefined, dateFormat)}`;
}

export default getDateStartAndEndString;
