import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

import useGetSaleItems from '../useCases/useGetSaleItems';
import useAnalytics from '@/hooks/analytics/useAnalytics';
import Bugsnag from '@/services/bugsnag';
import { NotifiableError } from '@bugsnag/js';
import { AnalyticsEvents } from '@/hooks/analytics/enum/analyticsEvents';

const useLoadSaleItems = () => {
  const { request: geSaleItems } = useGetSaleItems();
  const { t } = useTranslation(['saleDetails']);
  const { track } = useAnalytics();

  const useRequestQuery = (saleId: string, limit: number, offset: number) => {
    return useQuery({
      queryKey: ['sale-items', saleId, limit, offset],
      queryFn: geSaleItems,
      enabled: !!saleId,
      staleTime: Infinity,
      placeholderData: keepPreviousData,
      throwOnError: (error, query) => {
        track(AnalyticsEvents.SALE_GET_DETAILED_ITEMS_FAIL, { query, error });
        enqueueSnackbar(t('snackbars.could_not_get_sale_items'), { variant: 'error' });

        Bugsnag.notify(error as NotifiableError);
        return false;
      },
    });
  };

  return [useRequestQuery];
};

export default useLoadSaleItems;
